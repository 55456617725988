/* @flow */

import React from 'react';
import { View, Image, StyleSheet, Text } from 'react-native';
import { style, color } from '~/common';
import { StatusBadge } from '~/components';
import Images from '@assets/Images';
import Touchable from 'react-native-platform-touchable';
import ActivityTypes from '../ActivityTypes';
import { fonts } from '../../../utils/Fonts';
import translate from '../../../locales';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    color: '#000',
    fontSize: 16,
    fontFamily: fonts.QuicksandBold,
  },
  iconContainer: {
    width: 35,
    height: 35,
    borderRadius: 25,
    backgroundColor: color.primaryColorLightest,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  icon: {
    width: 24,
    height: 24,
    tintColor: color.primaryColorLight,
  },
  badgeContainer: {
    width: 22,
    height: 22,
    borderRadius: 12,
    backgroundColor: color.grayContainerDark,
    justifyContent: 'center',
    alignItems: 'center',
  },
  greenBadgeContainer: {
    width: 22,
    height: 22,
    borderRadius: 12,
    backgroundColor: color.stripGreenDark,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconBadgeCheck: {
    width: 18,
    height: 18,
    tintColor: '#fff',
  },
  pressable: {
    backgroundColor: '#fff',
    paddingHorizontal: 16,
    padding: 12,
    minHeight: 20,
    flex: 1,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#DADCE0',
    marginTop: 8,
    marginBottom: 10,
    marginHorizontal: 10,
    overflow: 'hidden',
  },
});

function getTitle(type) {
  return ActivityTypes[type].title;
}

function getIcon(type) {
  const activityType = ActivityTypes[type];
  return activityType ? activityType.icon : Images.iconDotsHorizontal;
}

const ActivityListItem = ({ finished, item, onPress, required }) => (
  <Touchable
    onPress={() => onPress(item)}
    style={[styles.pressable, item.blocked ? { opacity: 0.5 } : {}]}>
    <View style={styles.container}>
      <>
        <View>
          <View style={styles.iconContainer}>
            <Image source={getIcon(item.kind)} style={styles.icon} />
          </View>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={styles.title}>{item.name}</Text>
          {required ? (
            <StatusBadge
              baseColor={color.warningColor}
              text={`${translate('required')}`}
              icon={Images.iconLock}
            />
          ) : null}
          {item?.answer?.readableDate ? (
            <Text>{item.answer.readableDate}</Text>
          ) : null}
        </View>
        <View>
          <View
            style={
              finished ? styles.greenBadgeContainer : styles.badgeContainer
            }>
            <Image source={Images.iconCheck} style={styles.iconBadgeCheck} />
          </View>
        </View>
      </>
    </View>
  </Touchable>
);

ActivityListItem.propTypes = {
    item: PropTypes.object.isRequired,
    onPress: PropTypes.func.isRequired,
    finished: PropTypes.bool.isRequired,
};

export default ActivityListItem;
