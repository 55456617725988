import GeneralModule from '~/modules/GeneralModule';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { emp2long } from '~/utils/FastSellerUtils';
import { get } from 'lodash';

export const NEW_ACTIVITIES_FETCH_REQUEST = 'NEW_ACTIVITIES_FETCH_REQUEST';
export const NEW_ACTIVITIES_FETCH_SUCCESS = 'NEW_ACTIVITIES_FETCH_SUCCESS';
export const NEW_ACTIVITIES_FETCH_FAILURE = 'NEW_ACTIVITIES_FETCH_FAILURE';

const initialState = {
  type: '',
  loading: true,
  erro: false,
  payload: [],
};

const getNewActivities = async () => {
  const groupId = emp2long('ATIV');
  const id = emp2long('NOVA');

  const data = await new Promise((resolve, reject) =>
    GeneralModule.getCustomList(
      `${groupId}`,
      `${id}`,
      '{}',
      false,
      null,
      resolve,
      reject,
    ),
  );

  const items = JSON.parse(data);

  const activities = get(items, 'data', [])
    .filter(activity => activity.tipo !== 'location')
    .map(activity => {
      const required = activity.obrigatoria;

      let isToBocked = false;

      let isSectionAnswered = false;

      return {
        ...activity,
        id: parseInt(activity.id, 10),
        name: activity.nome,
        required: Boolean(Number(required)),
        kind: activity.tipo,
        kind_key: activity.tipo,
        answer: null,
        blocked: isToBocked,
        isSectionAnswered,
      };
    });
  return activities;
};

export const getListNewActivities = createAsyncThunk(
  'newActivitiesFetch/getListNewActivities',
  async () => {
    try {
      return await getNewActivities();
    } catch (err) {
      return [];
    }
  },
);

const activitiesFetch = createSlice({
  name: 'newActivitiesFetch',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getListNewActivities.pending, state => {
      state.type = NEW_ACTIVITIES_FETCH_REQUEST;
      state.loading = true;
    });
    builder.addCase(getListNewActivities.fulfilled, (state, action) => {
      state.type = NEW_ACTIVITIES_FETCH_SUCCESS;
      state.loading = false;
      state.erro = false;
      state.payload = action.payload;
    });
    builder.addCase(getListNewActivities.rejected, (state, action) => {
      state.type = NEW_ACTIVITIES_FETCH_FAILURE;
      state.loading = false;
      state.erro = true;
      state.payload = action.payload;
    });
  },
});

const { reducer } = activitiesFetch;

export default reducer;
