/* @flow */
import { createSlice } from '@reduxjs/toolkit';

export const MAKE_ORDER_MODULE = 'MAKE_ORDER_MODULE';
export const MODULE_SALE = 'MODULE_SALE';
export const MODULE_ACTIVITIES = 'MODULE_ACTIVITIES';

const initialState = {
  payload: {},
};

const makeOrderModuleSlice = createSlice({
  name: 'makeOrderModule',
  initialState,
  reducers: {
    setModuleCurrent: (state, action) => {
      const { payload } = action;
      state.payload = payload;
    },
  },
  extraReducers: builder => {},
});

const { actions, reducer } = makeOrderModuleSlice;

export const { setModuleCurrent } = actions;

export default reducer;
