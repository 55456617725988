import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import OrdersModule from '~/modules/OrdersModule';
import {
  ORDERS_DELETE_FETCH_FAILURE,
  ORDERS_DELETE_FETCH_REQUEST,
  ORDERS_DELETE_FETCH_SUCCESS,
  orderDeleteFetch,
} from './orderDeleteSlice';

export const ORDERS_FETCH_REQUEST = 'ORDERS_FETCH_REQUEST';
export const ORDERS_FETCH_SUCCESS = 'ORDERS_FETCH_SUCCESS';
export const ORDERS_FETCH_FAILURE = 'ORDERS_FETCH_FAILURE';

const getOrders = (payload, offset) => {
  const { chaveCliente, statudPedido, statusFiltro } = payload;

  return new Promise(async (resolve, reject) => {
    const pagination = { page: 0, offset, active: true };

    let active = true;
    let data = '';

    try {
      while (active) {
        const resp = await OrdersModule.listOrders(
          chaveCliente,
          statudPedido,
          statusFiltro,
          JSON.stringify(pagination),
        );
        data = `${data}${resp}`;

        if (!(resp.length > 0)) {
          active = false;
        }

        pagination.page += 1;
      }
      resolve(data);
    } catch (error) {
      reject(error.message);
    }
  });
};

export const getListOrders = createAsyncThunk(
  'orders/getListOrders',
  async (payload, { getState }) => {
    const state = getState();
    const offset = state.configLibFetch.payload.tam_memoria_paginacao;
    try {
      if (!offset) {
        throw new Error('Invalid offset');
      }
      const data = await getOrders(payload, offset);
      const orders = JSON.parse(data);
      return orders;
    } catch (error) {
      console.tron.log('error', error);
      throw new Error(error.message);
    }
  },
);

const initialState = {
  type: '',
  loading: true,
  erro: false,
  payload: {
    stringParamsBindQuery: JSON.stringify({
      pk_cliente: '0',
      u_status_situacao_pedido: '-1',
      u_status_filtro: '-1',
    }),
    uIdQuery: '1346716743',
    uIdGroupQuery: '1346850898',
  },
  copy: {},
  errors: [],
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getListOrders.pending, state => {
      state.type = ORDERS_FETCH_REQUEST;
      state.loading = true;
    });
    builder.addCase(getListOrders.fulfilled, (state, action) => {
      state.type = ORDERS_FETCH_SUCCESS;
      state.loading = false;
      state.erro = false;
      state.payload = action.payload;
    });
    builder.addCase(getListOrders.rejected, (state, action) => {
      state.type = ORDERS_FETCH_FAILURE;
      state.loading = false;
      state.erro = true;
      state.payload = action.payload;
    });
    builder.addCase(orderDeleteFetch.pending, state => {
      state.type = ORDERS_DELETE_FETCH_REQUEST;
      state.loading = true;
    });
    builder.addCase(orderDeleteFetch.fulfilled, (state, action) => {
      state.type = ORDERS_DELETE_FETCH_SUCCESS;
      state.loading = false;
      state.erro = false;
      state.payload = action.payload;
    });
    builder.addCase(orderDeleteFetch.rejected, (state, action) => {
      state.type = ORDERS_DELETE_FETCH_FAILURE;
      state.loading = false;
      state.erro = true;
      state.payload = action.payload;
    });
  },
});

const { reducer } = ordersSlice;

export default reducer;
