/* @flow */

import React, {
  useReducer,
  useRef,
  useState,
  useEffect,
  useMemo,
  forwardRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View, Text, Image, ActivityIndicator } from 'react-native';
import Images from '@assets/Images';
import SelectPicker from '../SelectPicker';
import ClientModule from '../../modules/ClientsModule';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import find from 'lodash/find';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import template from 'lodash/template';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import omit from 'lodash/omit';

import translate from '~/locales';
import { color } from '~/common';

import {
  SCREEN_PRICE_TERM_CONDITIONS,
  SCREEN_RESUME_ORDER,
} from '~/screens/screens';
import CacheDataController from '~/utils/CacheDataController';
import { emp2long } from '~/utils/FastSellerUtils';
import listGeneric from '~/services/resources/libFastSeller/listGeneric';
import axios from 'axios';
import getQueryResult from '~/services/resources/libFastSeller/genericQuery';
import saleConditions from '~/services/resources/libFastSeller/saleConditions';

import ConditionSaleCache from '~/cache/ConditionSaleCache';
import { abort, getProducts, preLoading } from '~/store/productsFetch/slice';
import { getProductsHome } from '~/store/productsHome/slice';

import { updateShipping } from '~/store/ordersFetch/updateShippingSlice';
import { orderClient } from '~/store/ordersFetch/orderFetchSlice';

import { stylesLabelProntaEntrega, styles } from './styles';
import { createNotifyError } from '~/components/Web/ToastNotify';
import ModalOverlay from '~/components/Web/ModalOverLay/index.web';

export const SHIPPING_TYPES = {
  FASTSELLER: 'fastseller',
  API: 'api',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SELECT_SALE_ORGANIZATION':
      return {
        ...state,
        isEditing: true,
        selectedSaleOrganization: action.payload.item,
        invalidConditions: false,
      };
    case 'SELECT_SALE_TYPE':
      return {
        ...state,
        isEditing: true,
        selectedSaleType: action.payload.item,
        invalidConditions: false,
      };
    case 'SELECT_PAYMENT_TYPE':
      return {
        ...state,
        isEditing: true,
        selectedPaymentType: action.payload.item,
        invalidConditions: false,
      };
    case 'SELECT_PRICE_TABLE':
      return {
        ...state,
        isEditing: true,
        selectedPriceTable: action.payload.item,
        invalidConditions: false,
      };
    case 'SELECT_DEADLINE':
      return {
        ...state,
        isEditing: true,
        selectedDeadline: action.payload.item,
        invalidConditions: false,
      };
    case 'SELECT_CUSTOMERS_INVOICE':
      return {
        ...state,
        isEditing: true,
        selectedCustomersInvoice: action.payload.item,
        invalidConditions: false,
      };
    case 'SELECT_SHIPPING':
      return {
        ...state,
        isEditing: true,
        selectedShipping: action.payload.item,
      };
    case 'SELECT_SHIPPING_COMPANY':
      return {
        ...state,
        ...action.payload,
        selectedShippingCompany: action.payload.item,
      };
    case 'SET_SALE_CONDITION':
      return {
        ...state,
        isEditing: false,
        currentSaleCondition: action.payload,
      };
    case 'TOGGLE_EDITION':
      return { ...state, isEditing: action.payload };

    case 'GENERIC_UPDATE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const defaultState = {
  salesOrganization: [],
  selectedSaleOrganization: {},
  saleTypes: [],
  selectedSaleType: {},
  paymentTypes: [],
  selectedPaymentType: {},
  priceTables: [],
  selectedPriceTable: {},
  deadlines: [],
  selectedDeadline: {},
  isEditing: false,
  invalidConditions: false,
  conditions: {},
  customersInvoices: [],
  selectedCustomersInvoice: {},
  shippings: [],
  selectedShipping: {},
  shippingCompanies: [],
  selectedShippingCompany: {},
  currentSaleCondition: {},
};

const SalesCondition = forwardRef(
  (
    {
      parentComponentId,
      onToggleEdition,
      onChangeConditions,
      onClientChange,
      onChangeState,
    },
    ref,
  ) => {
    const configLib = useSelector(state => state.configLibFetch);
    const client = useSelector(state => state.clientCurrent);
    const cart = useSelector(state => state.orderFetch);

    const [state, dispatch] = useReducer(reducer, defaultState);

    const [loading, setLoading] = useState(true);

    const reduxDispatch = useDispatch();

    const defaultSalesCondition = useRef({});

    const lastSelectedShipping = useRef(null);

    const {
      salesOrganization = [],
      saleTypes = [],
      selectedSaleOrganization = {},
      selectedSaleType = {},
      paymentTypes = [],
      selectedPaymentType = {},
      priceTables = [],
      selectedPriceTable = {},
      deadlines = [],
      selectedDeadline = {},
      conditions = {},
      customersInvoices = [],
      selectedCustomersInvoice = {},
      shippings = [],
      selectedShipping = {},
      shippingCompanies = [],
      selectedShippingCompany = {},
      currentSaleCondition = {},
      invalidConditions = false,
    } = state;

    const currentSaleConditionRef = useRef(currentSaleCondition);

    const showPriceConditions = get(
      configLib.payload,
      'listagem_campos_visibilidade.product.show_price_conditions',
      false,
    );

    const existsApiShipping = shippings.some(
      shipping => shipping.type === 'api',
    );

    const produtos = get(cart, 'payload.produtos') || [];

    const showCustomersInvoice =
      Array.isArray(customersInvoices) && customersInvoices.length > 0;

    const showShipping = !!(
      get(cart.payload, 'frete.habilitado', false) &&
      shippings.length > 0 &&
      (produtos.length > 0 || !existsApiShipping)
    );

    const showShippingCompanies =
      shippingCompanies.length > 0 && produtos.length > 0;

    const getDefaultSalesCondition = async (params = {}) => {
      if (isEmpty(defaultSalesCondition.current)) {
        const defaultCondition = await ClientModule.getSaleConditionDefault(
          JSON.stringify({ ...params }),
        );
        return JSON.parse(defaultCondition) || {};
      }
      return defaultSalesCondition.current;
    };

    const convertToCombAttr = condSaleAttr => {
      const combAttr = {
        orgvenda: 'orgvenda',
        tipovenda: 'tipovenda',
        tabelapreco: 'tabelapreco',
        meios: 'codmeio',
        codprazo: 'codprazo',
        clientes_fatura: 'clientefatura',
      };
      return combAttr[condSaleAttr];
    };

    const sortByLabel = (a, b) => {
      if (a.label < b.label) {
        return -1;
      } else if (a.label > b.label) {
        return 1;
      }
      return 1;
    };

    const getDynamicList = (conditionsSales, attr, dependencies = []) => {
      const combSales = conditionsSales.combinacoes;
      const combAttr = convertToCombAttr(attr);
      let listAttr = new Set();
      if (!dependencies.length) {
        listAttr = new Set(combSales.map(comb => comb[attr]));
      } else {
        let combSalesFiltered = combSales;
        for (const dpcy of dependencies) {
          const dpcyAttr = convertToCombAttr(dpcy.attr);
          combSalesFiltered = combSalesFiltered.filter(
            comb => comb[dpcyAttr] === dpcy.value,
          );
        }
        listAttr = new Set(combSalesFiltered.map(comb => comb[combAttr]));
      }

      const dynamicList = filter(conditionsSales[attr], ElemtAttr =>
        listAttr.has(ElemtAttr.pkey),
      );

      return dynamicList
        .map(elemnt => ({ label: elemnt.descricao, value: elemnt.pkey }))
        .sort(sortByLabel);
    };

    const getParamsSelected = (list, itemSelected, forceFirst = false) => {
      const selected = find(list, item => item.value === itemSelected);
      if (selected) {
        return selected;
      } else if (forceFirst && list.length) {
        return list[0];
      } else {
        dispatch({ type: 'TOGGLE_EDITION', payload: true });
        return {
          label: `${translate('select')} ${translate('theOption')}`,
        };
      }
    };

    const getListAndSelected = (
      conditionsSales,
      listType,
      dependencies,
      shown,
      forceFirst = false,
    ) => {
      const list = getDynamicList(conditionsSales, listType, dependencies);
      const selected = getParamsSelected(list, shown, forceFirst);
      return [list, selected];
    };

    const onChangeSaleOrganization = async item => {
      if (item.value !== selectedSaleOrganization.value) {
        dispatch({
          type: 'SELECT_SALE_ORGANIZATION',
          payload: {
            item,
          },
        });

        defaultSalesCondition.current = await getDefaultSalesCondition({
          orgvenda: item.value,
          codcliente: client.current.pkey,
        });

        nextDependency({
          type: 'tipovenda',
          dependencies: [
            {
              attr: 'orgvenda',
              value: item.value,
            },
          ],
          caseTypeInvalid: 'salesTypeInvalid',
          listSetKey: 'saleTypes',
          selectedSetKey: 'selectedSaleType',
          onChange: onChangeTypeSale,
          shown: defaultSalesCondition.current,
        });
      }
    };

    const onChangeTypeSale = async (item, dependencies = []) => {
      if (item.value !== selectedSaleType.value || dependencies.length) {
        dispatch({
          type: 'SELECT_SALE_TYPE',
          payload: {
            item,
          },
        });

        defaultSalesCondition.current = await getDefaultSalesCondition({
          orgvenda: selectedSaleOrganization.value,
          codcliente: client.current.pkey,
          tipovenda: item.value,
        });

        nextDependency({
          type: 'meios',
          dependencies: dependencies.concat({
            attr: 'tipovenda',
            value: item.value,
          }),
          listSetKey: 'paymentTypes',
          selectedSetKey: 'selectedPaymentType',
          onChange: onChangeMeioSale,
          shown: defaultSalesCondition.current,
        });
      }
    };

    const onChangeMeioSale = async (item, dependencies = []) => {
      if (item.value !== selectedPaymentType.value || dependencies.length) {
        dispatch({
          type: 'SELECT_PAYMENT_TYPE',
          payload: {
            item,
          },
        });

        defaultSalesCondition.current = await getDefaultSalesCondition({
          orgvenda: selectedSaleOrganization.value,
          tipovenda: selectedSaleType.value,
          codcliente: client.current.pkey,
          tabelapreco: selectedPriceTable.value,
          codmeio: item.value,
        });

        nextDependency({
          type: 'tabelapreco',
          dependencies: dependencies.concat({
            attr: 'meios',
            value: item.value,
          }),
          listSetKey: 'priceTables',
          selectedSetKey: 'selectedPriceTable',
          onChange: onChangeTablePriceSale,
          shown: defaultSalesCondition.current,
        });
      }
    };

    const onChangeTablePriceSale = async (item, dependencies = []) => {
      if (item.value !== selectedPriceTable.value || dependencies.length) {
        dispatch({
          type: 'SELECT_PRICE_TABLE',
          payload: {
            item,
          },
        });

        defaultSalesCondition.current = await getDefaultSalesCondition({
          orgvenda: selectedSaleOrganization.value,
          codcliente: client.current.pkey,
          tabelapreco: item.value,
        });

        const listDefaultNextDependencies = [
          {
            attr: 'meios',
            value: selectedPaymentType.value,
          },
          {
            attr: 'tabelapreco',
            value: item.value,
          },
        ];

        const nextListDependencies = dependencies.length
          ? dependencies
          : listDefaultNextDependencies;

        nextDependency({
          type: 'codprazo',
          dependencies: nextListDependencies,
          listSetKey: 'deadlines',
          selectedSetKey: 'selectedDeadline',
          onChange: onChangePrazoSale,
          shown: defaultSalesCondition.current,
        });
      }
    };

    const onChangePrazoSale = item => {
      if (item.value !== selectedDeadline.value) {
        dispatch({
          type: 'SELECT_DEADLINE',
          payload: {
            item,
          },
        });
      }
      /**
       * Reatribuido false para evitar chamadas desnecessarias da condicao de venda
       * padrao para cada alteracao dos parametros de venda.
       * */
      defaultSalesCondition.current = {};
    };

    const onChangeShippingCompany = item => {
      if (item.value !== selectedShippingCompany.value) {
        dispatch({
          type: 'SELECT_SHIPPING_COMPANY',
          payload: {
            isEditing: true,
            item: item,
          },
        });
      }
    };

    const onPressConditionsPriceTerm = (params = {}) => {
      const { product } = params;

      const listProducts = product ? [product] : produtos;

      this.props.navigation.navigate('ConditionsSalesChid', {
        products: listProducts,
      });
    };

    const onChangeCustomerInvoice = item => {
      if (item.value !== selectedCustomersInvoice.value) {
        dispatch({
          type: 'SELECT_CUSTOMERS_INVOICE',
          payload: {
            item,
          },
        });
      }
    };

    const onChangeShipping = item => {
      if (item.value !== selectedShipping.value) {
        CacheDataController.removeItem(
          `@shipping_companies_${selectedShipping.value}`,
        );
        if (!isEqual(lastSelectedShipping.current, item)) {
          dispatch({
            type: 'SELECT_SHIPPING',
            payload: {
              item,
            },
          });
        }

        lastSelectedShipping.current = item;

        switch (item.type) {
          case SHIPPING_TYPES.FASTSELLER: {
            listShippingCompaniesByFastseller(item);
            return;
          }
          case SHIPPING_TYPES.API: {
            listShippingCompaniesByAPI(item);
            return;
          }
          default:
            dispatch({
              type: 'GENERIC_UPDATE',
              payload: {
                selectedShipping: item,
                shippingCompanies:
                  shippingCompanies.length > 0 ? [] : shippingCompanies,
              },
            });

            break;
        }
      }
    };

    const getSaleType = (conditionsSales, orgvenda) => {
      const saleTypeCombine = conditionsSales.combinacoes
        .filter(comb => comb.orgvenda === orgvenda)
        .map(comb => comb.tipovenda);

      const items = conditionsSales.tipovenda.filter(tipo =>
        saleTypeCombine.includes(tipo.pkey),
      );

      const listTypeSales = items.map(sale => ({
        label: sale.descricao,
        value: sale.pkey,
      }));

      return listTypeSales.sort(sortByLabel);
    };

    const getPaymentMethod = (conditionsSales, orgvenda, typeSale) => {
      const paymentMethodCombine = conditionsSales.combinacoes
        .filter(
          comb => comb.orgvenda === orgvenda && comb.tipovenda === typeSale,
        )
        .map(comb => comb.codmeio);

      const items = conditionsSales.meios.filter(meio =>
        paymentMethodCombine.includes(meio.pkey),
      );

      const listTypeMeios = items.map(meio => ({
        label: meio.descricao,
        value: meio.pkey,
      }));

      return listTypeMeios.sort(sortByLabel);
    };

    const getDeadline = (conditionsSales, orgvenda, typeSale, meioSale) => {
      const deadlineCombine = conditionsSales.combinacoes
        .filter(
          comb =>
            comb.orgvenda === orgvenda &&
            comb.tipovenda === typeSale &&
            comb.codmeio === meioSale,
        )
        .map(comb => comb.codprazo);

      const items = conditionsSales.codprazo.filter(prazo =>
        deadlineCombine.includes(prazo.pkey),
      );

      const listPrazos = items.map(prazo => ({
        label: prazo.descricao,
        value: prazo.pkey,
      }));

      return listPrazos.sort(sortByLabel);
    };

    const getPriceTable = (conditionsSales, orgvenda, typeSale, meioSale) => {
      const priceTableCombine = conditionsSales.combinacoes
        .filter(
          comb =>
            comb.orgvenda === orgvenda &&
            comb.tipovenda === typeSale &&
            comb.codmeio === meioSale,
        )
        .map(comb => comb.tabelapreco);

      const items = conditionsSales.tabelapreco.filter(tablePrice =>
        priceTableCombine.includes(tablePrice.pkey),
      );

      const listTablePrices = items.map(tablePrice => ({
        label: tablePrice.descricao,
        value: tablePrice.pkey,
      }));

      return listTablePrices.sort(sortByLabel);
    };

    const refreshProducts = (codcliente, codigo, condvenda) => {
      const payload = {
        codcliente,
        nivel: '0',
        codGroup: '0',
        codOrder: codigo,
        condvenda,
      };
      reduxDispatch(abort());
      reduxDispatch(preLoading());
      setTimeout(() => {
        reduxDispatch(getProductsHome(payload));
        reduxDispatch(getProducts(payload));
      }, 2500);
    };

    const listShippingCompaniesByFastseller = async item => {
      setLoading(true);
      const { frete } = cart.payload;
      const pkeyClient = get(client, 'current.pkey', '0');

      const tamMemory = get(configLib.payload, 'tam_memoria_paginacao');
      const listId = emp2long('TRNS');
      const params = {
        pk_transportadora: 0,
        u_bit_frete_ativo: item.bit,
        pk_cliente: pkeyClient,
      };

      try {
        const resp = await listGeneric(
          `${listId}`,
          `${listId}`,
          params,
          tamMemory,
        );
        let shippingCompanies = [];
        if (resp.data && resp.data.length > 0) {
          shippingCompanies = resp.data.map(i => ({
            label: `${i.descricao}`,
            value: `${i.pkey}`,
            ...i,
          }));
        }

        const shippingCompany = shippingCompanies.find(
          shipp => shipp.pkey === frete.transportadora,
        );
        let selectedShippingCompany = shippingCompanies[0];
        if (shippingCompany) {
          selectedShippingCompany = shippingCompany;
        }

        if (typeof selectedShippingCompany === 'undefined') {
          selectedShippingCompany = null;
        }
        dispatch({
          type: 'SELECT_SHIPPING_COMPANY',
          payload: {
            shippingCompanies,
            item: selectedShippingCompany,
          },
        });
        lastSelectedShipping.current = item;
        CacheDataController.saveData(
          `@shipping_companies_${item.value}`,
          shippingCompanies,
        );
      } catch (e) {
        createNotifyError(translate('errorOnLoadShippingCompanies'));
      } finally {
        setLoading(false);
      }
    };

    const listShippingCompaniesByAPI = async item => {
      setLoading(true);
      const cartId = get(cart, 'payload.codigo');
      const shippingCompanyOrder = get(cart, 'payload.frete.transportadora');
      const clientPkey = get(client, 'current.pkey', '');
      const externalApi = get(
        configLib.payload,
        'configuracoes.shipping.external_api',
        {},
      );
      const credentials = get(externalApi, 'credentials', {});
      let { token } = externalApi;

      try {
        if (!externalApi.token && credentials) {
          const { data } = await axios.post(
            credentials.url,
            JSON.stringify(credentials.login),
          );

          ({ [credentials.response_token_field]: token } = data);
        }

        const params = {
          pk_pedido: cartId,
          pk_cliente: clientPkey,
        };

        const bodyTvar =
          (await getQueryResult(
            externalApi.request_body_tvar,
            JSON.stringify(params),
          )) || '[]';

        const [reqBody] = JSON.parse(bodyTvar);

        Object.keys(reqBody).forEach(key => {
          const isJSONField =
            String(reqBody[key]).includes('[') ||
            String(reqBody[key]).includes('{');
          if (isJSONField) {
            reqBody[key] = JSON.parse(reqBody[key]);
          }
        });

        const headers = {
          Authorization: `${externalApi.token_type} ${token}`,
        };

        const { data } = await axios.post(
          externalApi.url,
          { ...reqBody },
          { headers },
        );

        let sCompanies = [];

        if (Array.isArray(data)) {
          sCompanies = data;
        } else {
          ({ [externalApi.response_field]: sCompanies = [] } = data || {});
        }

        const companies = sCompanies.map(company => {
          const { response_map_fields: fields } = externalApi;
          let label = '';

          if (fields?.label?.template && isArray(fields?.label?.keys)) {
            const compiled = template(fields.label.template, {
              interpolate: /{{([\s\S]+?)}}/g,
            });
            label = compiled(
              fields.label.keys.reduce(
                (a, v) => ({ ...a, [v]: company[v] }),
                {},
              ),
            );
          }

          return {
            label,
            value: `${company[fields.value]}`,
            price: `${company[fields.price]}`,
            ...company,
          };
        });

        const sCompany = companies.find(
          cp => cp.value === shippingCompanyOrder,
        );
        let selectedShippingCompany = sCompany;

        if (!sCompany) {
          [selectedShippingCompany] = companies;
        }
        dispatch({
          type: 'SELECT_SHIPPING_COMPANY',
          payload: {
            shippingCompanies,
            item: selectedShippingCompany,
          },
        });

        CacheDataController.saveData(
          `@shipping_companies_${item.value}`,
          companies,
        );
        lastSelectedShipping.current = item;
      } catch (err) {
        dispatch({
          type: 'GENERIC_UPDATE',
          payload: {
            shippingSelected: shippings[0] ?? {},
            shippingCompanies: [],
          },
        });
        alert(
          `${translate('errorListSC')}\n\n${translate(
            'errorInternetConnection',
          )}`,
        );
      } finally {
        setLoading(false);
      }
    };

    const nextDependency = params => {
      const {
        type,
        dependencies,
        listSetKey,
        selectedSetKey,
        onChange,
        shown,
      } = params;

      const [listType, typeSelected] = getListAndSelected(
        conditions,
        type,
        dependencies,
        shown[type],
        true,
      );

      const conditionObject = {};
      conditionObject[listSetKey] = listType;
      conditionObject[selectedSetKey] = typeSelected;
      dispatch({ type: 'GENERIC_UPDATE', payload: conditionObject });

      onChange?.(
        typeSelected,
        dependencies.concat({
          attr: type,
          value: typeSelected.value,
        }),
      );
    };

    const renderLabelProntaEntrega = () => (
      <View style={stylesLabelProntaEntrega.container}>
        <Image
          style={stylesLabelProntaEntrega.iconTruck}
          source={Images.iconTruck}
        />
        <Text style={stylesLabelProntaEntrega.text}>{`${translate(
          'promptDelivery',
        )}`}</Text>
      </View>
    );

    const setSaleCondition = value => {
      dispatch({ type: 'SET_SALE_CONDITION', payload: value });
    };

    const getCustomersInvoice = conditionsSales => {
      const items = conditionsSales.clientes_fatura.map(cli => ({
        label: cli.descricao,
        value: cli.pkey,
        sequence: cli.ordem,
      }));

      return items.sort((a, b) => a.sequence > b.sequence);
    };

    useEffect(
      () => {
        currentSaleConditionRef.current = currentSaleCondition;
      },
      [currentSaleCondition],
    );

    const listConditionsSales = async cli => {
      if (!cli?.pkey) {
        return;
      }
      setLoading(true);

      let saleConditionInvalid = false;

      try {
        const conditionsSales = await saleConditions(cli.pkey);

        const {
          orgvenda: orgvendaPkey,
          tipovenda: tipovendaPkey,
          codmeio: meioPkey,
          codprazo: codprazoPkey,
          tabelapreco: tabelaprecoPkey,
          clientefatura,
        } = currentSaleConditionRef.current;

        const dependencies = [];
        const [
          salesOrganizationItems,
          selectedSaleOrganizationItem,
        ] = getListAndSelected(
          conditionsSales,
          'orgvenda',
          dependencies,
          orgvendaPkey,
        );

        saleConditionInvalid =
          saleConditionInvalid || !selectedSaleOrganizationItem.value;

        dependencies.push({
          attr: 'orgvenda',
          value: selectedSaleOrganizationItem.value,
        });
        const [saleTypesItems, selectedSaleTypeItem] = getListAndSelected(
          conditionsSales,
          'tipovenda',
          dependencies,
          tipovendaPkey,
        );

        saleConditionInvalid =
          saleConditionInvalid || !selectedSaleTypeItem.value;

        dependencies.push({
          attr: 'tipovenda',
          value: selectedSaleTypeItem.value,
        });

        const [paymentTypesItems, selectedPaymentTypeItem] = getListAndSelected(
          conditionsSales,
          'meios',
          dependencies,
          meioPkey,
        );

        saleConditionInvalid =
          saleConditionInvalid || !selectedPaymentTypeItem.value;

        dependencies.push({
          attr: 'meios',
          value: selectedPaymentTypeItem.value,
        });

        const [priceTablesItems, selectedPriceTableItem] = getListAndSelected(
          conditionsSales,
          'tabelapreco',
          dependencies,
          tabelaprecoPkey,
        );

        saleConditionInvalid =
          saleConditionInvalid || !selectedPriceTableItem.value;

        dependencies.push({
          attr: 'tabelapreco',
          value: selectedPriceTableItem.value,
        });

        const [deadlinesItems, selectedDeadlineItem] = getListAndSelected(
          conditionsSales,
          'codprazo',
          dependencies,
          codprazoPkey,
        );

        saleConditionInvalid =
          saleConditionInvalid || !selectedDeadlineItem.value;

        const customersInvoiceItems = getCustomersInvoice(conditionsSales);

        let selectedCustomersInvoiceItem = {};
        if (customersInvoiceItems.length) {
          selectedCustomersInvoiceItem = getParamsSelected(
            customersInvoiceItems,
            clientefatura,
            true,
          );

          saleConditionInvalid =
            saleConditionInvalid || !selectedCustomersInvoiceItem.value;
        }

        const conditionSaleItensSelected = {
          selectedSaleOrganization: selectedSaleOrganizationItem,
          selectedSaleType: selectedSaleTypeItem,
          selectedPaymentType: selectedPaymentTypeItem,
          selectedDeadline: selectedDeadlineItem,
          selectedPriceTable: selectedPriceTableItem,
          selectedCustomersInvoice: selectedCustomersInvoiceItem,
        };

        const conditionSaleListSelected = {
          salesOrganization: salesOrganizationItems,
          saleTypes: saleTypesItems,
          paymentTypes: paymentTypesItems,
          deadlines: deadlinesItems,
          priceTables: priceTablesItems,
          customersInvoices: customersInvoiceItems,
        };

        dispatch({
          type: 'GENERIC_UPDATE',
          payload: {
            conditions: conditionsSales,
            ...conditionSaleItensSelected,
            ...conditionSaleListSelected,
            invalidConditions: Boolean(saleConditionInvalid),
          },
        });

        await ConditionSaleCache.reset({
          ...conditionSaleItensSelected,
          ...conditionSaleListSelected,
          invalidConditions: Boolean(saleConditionInvalid),
        });
      } catch {
        createNotifyError(translate('notListConditionsSales'));
      } finally {
        setLoading(false);
      }
    };

    const loadShippings = async () => {
      const { frete } = cart.payload;
      const pk_frete = get(cart.payload, 'frete.tipo', '0');

      const pkShippingCompany = get(cart.payload, 'frete.transportadora', null);
      const tamMemory = get(configLib.payload, 'tam_memoria_paginacao');
      const groupId = emp2long('FRET');
      const id = groupId;
      const params = { pk_frete };

      try {
        const resp = await listGeneric(groupId, id, params, tamMemory);
        const shippingsItems = resp.data.map(shipping => ({
          value: shipping.ord,
          label: shipping.valor,
          bit: shipping.v1,
          ...shipping,
        }));

        const shipping = shippingsItems.find(shi => shi.value === frete.tipo);

        let shippingItem = shippingsItems[0];
        if (shipping) {
          shippingItem = shipping;
        }

        lastSelectedShipping.current = shippingItem;
        let shippingCompanyItems = [];

        if (pkShippingCompany) {
          const shippingCompaniesCache = await CacheDataController.getData(
            `@shipping_companies_${shippingItem.value}`,
          );

          if (shippingCompaniesCache && shippingCompaniesCache.length > 0) {
            shippingCompanyItems = shippingCompaniesCache;
          }
        }

        onChangeShipping(shippingItem);
        lastSelectedShipping.current = shippingItem;
        dispatch({
          type: 'GENERIC_UPDATE',
          payload: {
            shippings: shippingsItems,
            selectedShipping: shippingItem,
          },
        });
        if (shippingCompanyItems.length > 0) {
          const companySelected = shippingCompanyItems.find(
            comp => comp.value === pkShippingCompany,
          );
          dispatch({
            type: 'SELECT_SHIPPING_COMPANY',
            payload: {
              shippingCompanies: shippingCompanyItems,
              item: companySelected || shippingCompanyItems[0],
            },
          });
        }
      } catch (errors) {
        const jssonArray = JSON.parse(errors);
        alert(jssonArray[0].mensagem);
      }
    };

    const updateConditionSale = newOrder => {
      let updateCondition = false;

      const { payload } = newOrder;
      const { condvenda = {}, codigo, frete } = payload;
      const {
        orgvenda = '',
        tipovenda,
        codmeio,
        codprazo,
        tabelapreco,
        codcliente,
      } = condvenda;
      const currentCondSale = currentSaleCondition;

      if (currentCondSale) {
        if (currentCondSale.orgvenda !== orgvenda) {
          const orgSaleSelected = salesOrganization.find(
            org => org.value === orgvenda,
          );

          const orgSaleSelectedValue = get(orgSaleSelected, 'value', '');

          const listTypeSale = getSaleType(conditions, orgSaleSelectedValue);

          let typeSaleSelected = listTypeSale.find(
            sale => sale.value === tipovenda,
          );

          if (typeSaleSelected === undefined) {
            typeSaleSelected = {};
          }

          const listMeioSale = getPaymentMethod(
            conditions,
            orgSaleSelectedValue,
            typeSaleSelected.value,
          );

          let meioSaleSelected = listMeioSale.find(
            meio => meio.value === codmeio,
          );

          if (meioSaleSelected === undefined) {
            meioSaleSelected = {};
          }

          let prazoSaleSelected = conditions.codprazo.find(
            prazo => prazo.pkey === condvenda.codprazo,
          );

          if (prazoSaleSelected === undefined) {
            prazoSaleSelected = {};
          } else {
            prazoSaleSelected = {
              label: prazoSaleSelected.descricao,
              value: prazoSaleSelected.pkey,
            };
          }

          const listPrazoSale = getDeadline(
            conditions,
            orgSaleSelectedValue,
            typeSaleSelected.value,
            meioSaleSelected.value,
          );

          let tablePriceSelected = conditions.tabelapreco.find(
            tablePrice => tablePrice.pkey === condvenda.tabelapreco,
          );

          if (tablePriceSelected === undefined) {
            tablePriceSelected = {};
          } else {
            tablePriceSelected = {
              label: tablePriceSelected.descricao,
              value: tablePriceSelected.pkey,
            };
          }

          const listTablePriceSale = getPriceTable(
            conditions,
            orgSaleSelectedValue,
            typeSaleSelected.value,
            meioSaleSelected.value,
          );

          refreshProducts(codcliente, codigo, condvenda);

          updateCondition = true;
          dispatch({
            type: 'GENERIC_UPDATE',
            payload: {
              selectedSaleOrganization: orgSaleSelected,
              saleTypes: listTypeSale,
              selectedSaleType: typeSaleSelected,
              paymentTypes: listMeioSale,
              selectedPaymentType: meioSaleSelected,
              deadlines: listPrazoSale,
              selectedDeadline: prazoSaleSelected,
              priceTables: listTablePriceSale,
              selectedPriceTable: tablePriceSelected,
            },
          });
        } else if (currentCondSale.tipovenda !== tipovenda) {
          const typeSaleSelected = saleTypes.find(
            sale => sale.value === tipovenda,
          );

          const listMeioSale = getPaymentMethod(
            conditions,
            orgvenda,
            typeSaleSelected.value,
          );

          let meioSaleSelected = listMeioSale.find(
            meio => meio.value === codmeio,
          );

          if (meioSaleSelected === undefined) {
            meioSaleSelected = {};
          }

          let prazoSaleSelected = conditions.codprazo.find(
            prazo => prazo.pkey === condvenda.codprazo,
          );

          if (prazoSaleSelected === undefined) {
            prazoSaleSelected = {};
          } else {
            prazoSaleSelected = {
              label: prazoSaleSelected.descricao,
              value: prazoSaleSelected.pkey,
            };
          }

          const listPrazoSale = getDeadline(
            conditions,
            orgvenda,
            typeSaleSelected.value,
            meioSaleSelected.value,
          );

          let tablePriceSelected = conditions.tabelapreco.find(
            tablePrice => tablePrice.pkey === condvenda.tabelapreco,
          );

          if (tablePriceSelected === undefined) {
            tablePriceSelected = {};
          } else {
            tablePriceSelected = {
              label: tablePriceSelected.descricao,
              value: tablePriceSelected.pkey,
            };
          }

          const listTablePriceSale = getPriceTable(
            conditions,
            orgvenda,
            typeSaleSelected.value,
            meioSaleSelected.value,
          );

          refreshProducts(codcliente, codigo, condvenda);

          updateCondition = true;
          dispatch({
            type: 'GENERIC_UPDATE',
            payload: {
              selectedSaleType: typeSaleSelected,
              paymentTypes: listMeioSale,
              selectedPaymentType: meioSaleSelected,
              deadlines: listPrazoSale,
              selectedDeadline: prazoSaleSelected,
              priceTables: listTablePriceSale,
              selectedPriceTable: tablePriceSelected,
            },
          });
        } else if (currentCondSale.codmeio !== codmeio) {
          const meioSaleSelected = paymentTypes.find(
            meio => meio.value === codmeio,
          );

          let prazoSaleSelected = conditions.codprazo.find(
            prazo => prazo.pkey === condvenda.codprazo,
          );

          if (prazoSaleSelected === undefined) {
            prazoSaleSelected = {};
          } else {
            prazoSaleSelected = {
              label: prazoSaleSelected.descricao,
              value: prazoSaleSelected.pkey,
            };
          }

          const listPrazoSale = getDeadline(
            conditions,
            orgvenda,
            selectedSaleType.value,
            meioSaleSelected.value,
          );

          let tablePriceSelected = conditions.tabelapreco.find(
            tablePrice => tablePrice.pkey === condvenda.tabelapreco,
          );

          if (tablePriceSelected === undefined) {
            tablePriceSelected = {};
          } else {
            tablePriceSelected = {
              label: tablePriceSelected.descricao,
              value: tablePriceSelected.pkey,
            };
          }

          const listTablePriceSale = getPriceTable(
            conditions,
            orgvenda,
            selectedSaleType.value,
            meioSaleSelected.value,
          );

          refreshProducts(codcliente, codigo, condvenda);

          updateCondition = true;

          dispatch({
            type: 'GENERIC_UPDATE',
            payload: {
              selectedPaymentType: meioSaleSelected,
              deadlines: listPrazoSale,
              selectedDeadline: prazoSaleSelected,
              priceTables: listTablePriceSale,
              selectedPriceTable: tablePriceSelected,
            },
          });
        } else if (currentCondSale.codprazo !== codprazo) {
          let prazoSaleSelected = conditions.codprazo.find(
            prazo => prazo.pkey === condvenda.codprazo,
          );

          if (prazoSaleSelected === undefined) {
            prazoSaleSelected = {};
          } else {
            prazoSaleSelected = {
              label: prazoSaleSelected.descricao,
              value: prazoSaleSelected.pkey,
            };
          }

          const listPrazoSale = getDeadline(
            conditions,
            orgvenda,
            selectedSaleType.value,
            selectedPaymentType.value,
          );

          let tablePriceSelected = conditions.tabelapreco.find(
            tablePrice => tablePrice.pkey === condvenda.tabelapreco,
          );

          if (tablePriceSelected === undefined) {
            tablePriceSelected = {};
          } else {
            tablePriceSelected = {
              label: tablePriceSelected.descricao,
              value: tablePriceSelected.pkey,
            };
          }

          const listTablePriceSale = getPriceTable(
            conditions,
            orgvenda,
            selectedSaleType.value,
            selectedPaymentType.value,
          );

          refreshProducts(codcliente, codigo, condvenda);

          updateCondition = true;
          dispatch({
            type: 'GENERIC_UPDATE',
            payload: {
              deadlines: listPrazoSale,
              selectedDeadline: prazoSaleSelected,
              priceTables: listTablePriceSale,
              selectedPriceTable: tablePriceSelected,
            },
          });
        } else if (currentCondSale.tabelapreco !== tabelapreco) {
          let prazoSaleSelected = conditions.codprazo.find(
            prazo => prazo.pkey === condvenda.codprazo,
          );

          if (prazoSaleSelected === undefined) {
            prazoSaleSelected = {};
          } else {
            prazoSaleSelected = {
              label: prazoSaleSelected.descricao,
              value: prazoSaleSelected.pkey,
            };
          }

          const listPrazoSale = getDeadline(
            conditions,
            orgvenda,
            selectedSaleType.value,
            selectedPaymentType.value,
          );

          let tablePriceSelected = conditions.tabelapreco.find(
            tablePrice => tablePrice.pkey === condvenda.tabelapreco,
          );

          if (tablePriceSelected === undefined) {
            tablePriceSelected = {};
          } else {
            tablePriceSelected = {
              label: tablePriceSelected.descricao,
              value: tablePriceSelected.pkey,
            };
          }

          const listTablePriceSale = getPriceTable(
            conditions,
            orgvenda,
            selectedSaleType.value,
            selectedPaymentType.value,
          );

          refreshProducts(codcliente, codigo, condvenda);

          updateCondition = true;
          dispatch({
            type: 'GENERIC_UPDATE',
            payload: {
              deadlines: listPrazoSale,
              selectedDeadline: prazoSaleSelected,
              priceTables: listTablePriceSale,
              selectedPriceTable: tablePriceSelected,
            },
          });
        } else if (selectedShipping.value !== frete.tipo) {
          let shippingCompanies = [];

          lastSelectedShipping.current = selectedShipping;
          if (frete.transportadora) {
            CacheDataController.getData(
              `@shipping_companies_${frete.tipo}`,
            ).then(data => {
              if (data && data.length > 0) {
                shippingCompanies = data;
              }
            });
          }

          updateCondition = true;

          if (selectedShipping && shippingCompanies.length > 0) {
            const companySelected = shippingCompanies.find(
              comp => comp.value === frete.transportadora,
            );
            dispatch({
              type: 'GENERIC_UPDATE',
              payload: {
                shippingCompanies,
                selectedShippingCompany:
                  companySelected || shippingCompanies[0],
              },
            });
          }
        }

        if (currentCondSale.codcliente !== codcliente) {
          if (!updateCondition) {
            refreshProducts(codcliente, codigo, condvenda);
          }
          onClientChange?.();
          updateCondition = true;
        }

        if (
          isEqual(
            omit(currentCondSale, 'clientefatura'),
            omit(condvenda, 'clientefatura'),
          ) &&
          !updateCondition
        ) {
          refreshProducts(codcliente, codigo, condvenda);
          updateCondition = true;
        }
        return updateCondition;
      }
      onChangeConditions?.(false);
    };

    const applyConditionSale = async () => {
      onChangeConditions?.(true);

      if (invalidConditions) {
        createNotifyError(translate('salesConditionInvalid'));
        return false;
      }

      const currentCart = cart.payload;

      const { combinacoes } = conditions;
      const { orcamento } = currentCart;

      const cartId = currentCart.codigo.toString();
      const clientCurrent = client.current;

      const condvenda = find(
        combinacoes,
        comb =>
          comb.codcliente === clientCurrent.pkey &&
          comb.orgvenda === selectedSaleOrganization.value &&
          comb.tipovenda === selectedSaleType.value &&
          comb.codmeio === selectedPaymentType.value &&
          comb.codprazo === selectedDeadline.value &&
          comb.tabelapreco === selectedPriceTable.value,
      );

      if (!condvenda) {
        createNotifyError(translate('salesConditionInvalid'));
        await applyPreviousConditions();
      } else {
        setSaleCondition(condvenda);
        const payloadOrderClient = {
          codigo: cartId,
          pkeyClient: clientCurrent.pkey,
          condvenda: {
            ...condvenda,
            clientefatura: selectedCustomersInvoice.value || 0,
          },
          orcamento,
          screen: SCREEN_RESUME_ORDER,
        };
        await reduxDispatch(
          updateShipping({
            shipping: JSON.stringify(selectedShipping),
            company:
              selectedShippingCompany !== null
                ? JSON.stringify({
                    id: selectedShippingCompany.value,
                    price: selectedShippingCompany.price,
                  })
                : selectedShippingCompany,
          }),
        );
        reduxDispatch(orderClient(payloadOrderClient));
        ConditionSaleCache.reset({
          selectedSaleOrganization,
          salesOrganization,
          selectedSaleType,
          saleTypes,
          selectedPaymentType,
          paymentTypes,
          selectedDeadline,
          deadlines,
          selectedPriceTable,
          priceTables,
          selectedCustomersInvoice,
          customersInvoices,
        });
      }
      dispatch({ type: 'TOGGLE_EDITION', payload: false });
    };

    const applyPreviousConditions = async () => {
      const condSaleCache = await ConditionSaleCache.get();
      dispatch({
        type: 'GENERIC_UPDATE',
        payload: {
          ...condSaleCache,
          isEditing: condSaleCache.saleConditionInvalid,
        },
      });
    };

    useEffect(
      () => {
        onToggleEdition?.(state.isEditing);
      },
      [state.isEditing, onToggleEdition],
    );

    useEffect(
      () => {
        onChangeState?.(state);
      },
      [onChangeState, state],
    );

    useMemo(() => {
      setSaleCondition(cart.payload.condvenda);
      try {
        loadShippings();
      } catch (err) {
        console.error('Error when load shippings', err);
        createNotifyError(translate('errorOnLoadShipping'));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (ref) {
      ref.current = {
        listConditionsSales,
        setSaleCondition,
        applyPreviousConditions,
        applyConditionSale,
        updateConditionSale,
        listShippingCompaniesByAPI,
        state,
      };
    }

    return (
      <>
        <ModalOverlay visible={loading} closeOnTouchOutside />
        <ActivityIndicator
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            zIndex: 9999,
          }}
          animating={loading}
          size="large"
        />
        <View style={[styles.sectionContainer, { paddingHorizontal: 0 }]}>
          <View>
            <Text style={[styles.subheader, { margin: 16 }]}>{`${translate(
              'salesCondition',
            )}`}</Text>
          </View>
          <View style={{ paddingHorizontal: 16, paddingBottom: 16 }}>
            <SelectPicker
              selected={selectedSaleOrganization}
              style={{ margin: 0 }}
              title={`${translate('salesOrganization')}`}
              items={salesOrganization}
              onChangeValue={onChangeSaleOrganization}
            />

            <SelectPicker
              selected={selectedSaleType}
              style={{ margin: 0, marginTop: 16 }}
              title={`${translate('salesType')}`}
              items={saleTypes}
              onChangeValue={onChangeTypeSale}
              color={!selectedSaleOrganization.value ? color.grayMid : '#000'}
            />

            {isObject(cart.payload) && cart.payload?.pronta_entrega
              ? renderLabelProntaEntrega()
              : null}

            <SelectPicker
              selected={selectedPriceTable}
              style={{ margin: 0, marginTop: 16 }}
              title={`${translate('priceTable')}`}
              items={priceTables}
              onChangeValue={onChangeTablePriceSale}
              color={!selectedSaleType.value ? color.grayMid : '#000'}
            />

            <SelectPicker
              selected={selectedPaymentType}
              style={{ margin: 0, marginTop: 16 }}
              title={`${translate('middle')}`}
              items={paymentTypes}
              onChangeValue={onChangeMeioSale}
              color={!selectedPriceTable.value ? color.grayMid : '#000'}
            />

            <SelectPicker
              selected={selectedDeadline}
              style={{ margin: 0, marginTop: 16 }}
              title={`${translate('deadline')}`}
              items={deadlines}
              onChangeValue={onChangePrazoSale}
              showPriceTermConditionsBtn
              onPressConditionsPriceTerm={onPressConditionsPriceTerm}
              showPriceConditions={showPriceConditions}
              color={!selectedPaymentType.value ? color.grayMid : '#000'}
            />
            {!!showCustomersInvoice && (
              <SelectPicker
                selected={selectedCustomersInvoice}
                style={{ margin: 0, marginTop: 16 }}
                title={`${translate('customersInvoice')}`}
                items={customersInvoices}
                onChangeValue={onChangeCustomerInvoice}
                color={!selectedCustomersInvoice.value ? color.grayMid : '#000'}
              />
            )}

            {showShipping && (
              <SelectPicker
                selected={selectedShipping}
                style={{ margin: 0, marginTop: 16 }}
                title={`${translate('shipping')}`}
                items={shippings}
                onChangeValue={onChangeShipping}
              />
            )}
            {showShippingCompanies && (
              <SelectPicker
                selected={selectedShippingCompany}
                style={{ margin: 0, marginTop: 16 }}
                title={`${translate('shippingCompany')}`}
                items={shippingCompanies}
                onChangeValue={onChangeShippingCompany}
              />
            )}
          </View>
        </View>
      </>
    );
  },
);

export default SalesCondition;
