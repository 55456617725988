/* @flow */

import React, { Component } from 'react';
import { View, Text, StyleSheet, Platform } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import color from '~/common/color';
import { fonts } from "../utils/Fonts";

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  outsideContainer: {
    borderRadius: 50,
    overflow: 'hidden',
  },
  outsideContainer_flat: {
    borderRadius: 0,
  },
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color.accentColor,
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 50,
    elevation: 2,
  },
  container_big: {
    backgroundColor: color.accentColor,
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  container_small: {
    paddingHorizontal: 16,
    paddingVertical: 4,
  },
  container_flat: {
    backgroundColor: Platform.OS === 'ios' ? color.accentColorLightest : 'transparent',
    elevation: 0,
    borderRadius: Platform.OS === 'ios' ? 50 : 0,
  },
  container_outline: {
    backgroundColor: 'transparent',
    borderColor: color.accentColor,
    borderWidth: 1,
    elevation: 0,
  },
  disabled: {
    backgroundColor: color.grayContainerDark,
  },
  title_disabled: {
    color: color.grayMid,
    fontFamily: fonts.QuicksandBold,
  },
  title_flat: {
    color: color.accentColor,
    fontFamily: fonts.QuicksandBold,
  },
  title_big: {
    fontSize: 16,
  },
  title_small: {
    fontSize: 13,
  },
  title: {
    fontFamily: fonts.QuicksandBold,
    color: '#FFF',
    fontWeight: 'bold',
  },
});

class Button extends Component {
  state = {};
  render() {
    const {
      title,
      big,
      disabled,
      small,
      flat,
      style,
      containerStyle,
      textStyle,
      color,
      outline,
      normalCase,
    } = this.props;
    return (
      <View style={[
        styles.outsideContainer,
        flat ? styles.outsideContainer_flat : {},
        containerStyle,
      ]}
      >
        <Touchable
          disabled={disabled}
          onPress={() => {
            if (this.props.onPress) this.props.onPress();
          }}
        >
          <View style={[
            styles.container,
            big ? styles.container_big : {},
            small ? styles.container_small : {},
            flat ? styles.container_flat : {},
            disabled ? styles.disabled : {},
            flat && disabled ? { backgroundColor: 'transparent' } : {},
            outline ? styles.container_outline : {},
            style,
          ]}
          >
            <Text
              ellipsize="tail"
              numberOfLines={1}
              style={[
                styles.title,
                flat || outline ? styles.title_flat : {},
                big ? styles.title_big : {},
                small ? styles.title_small : {},
                disabled ? styles.title_disabled : {},
                color ? { color: color } : {},
                textStyle,
            ]}
            >
              {normalCase ? title || 'ENTRAR' : title.toUpperCase() || 'ENTRAR'}
            </Text>
          </View>
        </Touchable>
      </View>
    );
  }
}

Button.propTypes = {
  onPress: PropTypes.func.isRequired,
  title: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.any,
  containerStyle: PropTypes.any,
  big: PropTypes.bool,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  flat: PropTypes.bool,
  outline: PropTypes.bool,
  normalCase: PropTypes.bool,
  textStyle: PropTypes.any,
};

export default Button;
