import React, { Component } from 'react';
import { View } from 'react-native';
import { FlashList } from '@shopify/flash-list';
import ProductItem from './components/ProductItem';
import EmptyView from '../../components/EmptyView';
import { Search } from '~/components';
import Images from '@assets/Images';
import { style } from '../../common';
import translate from '../../locales';
import Touchable from 'react-native-platform-touchable';

import onPressHelper from '~/utils/onPressHelper';

import PropTypes from 'prop-types';
class Catalog extends Component {
  navigateToCatalogProdDetail = item => {
    this.props.navigation.navigate('CatalogProdDetail', {
      pk_produto: item.pkey,
      configLib: this.props.configLib,
      showMultiTabs: this.props.showMultiTabs,
    });
  };

  onPressItem = onPressHelper.debounce(
    item => this.navigateToCatalogProdDetail(item),
    1000,
  );

  keyExtractor = item => item.codproduto;
  renderItem = ({ item }) => {
    const { config, showMultiTabs, showProductAsHTML } = this.props;
    return (
      <Touchable
        disabled={!showMultiTabs}
        onPress={() => {
          this.onPressItem(item);
        }}
        style={style.touchable}>
        <ProductItem
          product={item}
          config={config}
          showProductAsHTML={showProductAsHTML}
        />
      </Touchable>
    );
  };

  render() {
    const { catalog, showSearch, onChangeText } = this.props;

    return (
      <View style={style.container}>
        {showSearch && <Search onChangeText={onChangeText} />}
        {catalog && catalog.length > 0 ? (
          <FlashList
            keyExtractor={this.keyExtractor}
            renderItem={this.renderItem}
            data={catalog}
            estimatedItemSize={120}
          />
        ) : (
          <EmptyView
            icon={Images.iconProduct}
            message={`${translate('noProducts')}`}
          />
        )}
      </View>
    );
  }
}

Catalog.propTypes = {
  catalog: PropTypes.array,
  config: PropTypes.object,
  showSearch: PropTypes.bool,
  onChangeText: PropTypes.func,
  showProductAsHTML: PropTypes.bool,
  showMultiTabs: PropTypes.bool,
  configLib: PropTypes.object,
  componentId: PropTypes.any,
  navigation: PropTypes.object,
};

export default Catalog;
