/* @flow */

import React, { Component } from 'react';
import { Image, StyleSheet, Text, View, Alert } from 'react-native';
import _ from 'lodash';

import { color, style } from '~/common';
import Images from '@assets/Images';
import Touchable from 'react-native-platform-touchable';
import { fonts } from '../../../utils/Fonts';
import MoreInfo from '../../../../components_base/src/components/MoreInfo/MoreInfo';
import PopupMenu from '../../../components/PopupMenu';
import ReportsModule from '../../../modules/ReportsModule';
import executeActionOrOpenScreenMessage from '../../../utils/messages';
import exportReport from './../../../utils/report';
import translate from '../../../locales';
import HTML from 'react-native-render-html';
import { CalculatedValue } from '~/../components_base/src/components';

import PropTypes from 'prop-types';
import { SCREEN_WEBVIEW } from '~/screens/screens';

const MORE_INFOS = `${translate('moreInfo')}`;
const PDF = `${translate('exportToPDF')}`;
const SHARE_ORDER = 'Compartilhar pedido';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    paddingHorizontal: 16,
    paddingBottom: 0,
    padding: 12,
    paddingVertical: 16,
    minHeight: 20,
    flex: 1,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#DADCE0',
    marginTop: 8,
    marginHorizontal: 10,
  },
  number: {
    color: color.grayMid,
    fontSize: 14,
    marginRight: 6,
    fontFamily: fonts.QuicksandBold,
  },
  clientName: {
    color: '#000',
    fontSize: 16,
    fontFamily: fonts.QuicksandBold,
    marginRight: 15,
  },
  price: {
    color: '#000',
    fontSize: 18,
  },
  title: {
    color: color.grayMid,
    fontSize: 12,
  },
  titleBlack: {
    color: 'rgb(0,0,0)',
    fontSize: 12,
  },
  date: {
    textAlign: 'right',
    fontSize: 12,
    fontFamily: fonts.QuicksandItalic,
    color: color.grayMid,
  },
  orderActions: {
    borderTopColor: color.grayContainerDark,
    borderTopWidth: 1,
    marginTop: 5,
    // flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  orderActionContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    paddingHorizontal: 10,
  },
  orderActionIcon: {
    width: 18,
    height: 18,
    tintColor: color.primaryColor,
    marginRight: 10,
  },
  orderActionText: {
    color: color.primaryColor,
    fontSize: 14,
  },
  popupMenu: {
    position: 'absolute',
    right: -10,
    top: -12,
    bottom: 0,
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingVertical: 12,
    paddingHorizontal: 6,
  },
  dateContainer: {
    marginTop: 8,
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
});

const OrderAction = ({ text, icon, onPress }) => (
  <Touchable onPress={onPress}>
    <View style={styles.orderActionContainer}>
      <Image style={styles.orderActionIcon} source={icon} />
      <Text style={styles.orderActionText}>{text}</Text>
    </View>
  </Touchable>
);

class OrderListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      modalMoreInfosVisible: false,
    };
  }

  componentDidMount() {
    const { configExportReport, showOrderHeaderAsHTML, order } = this.props;
    const enabled = _.get(configExportReport, 'enabled');
    const actions = [];

    if (enabled) {
      actions.push(PDF);
    }
    if (!showOrderHeaderAsHTML) {
      actions.push(MORE_INFOS);
    }

    this.setState({ actions });
  }

  onSharePress = () => {
    if (this.props.onSharePress) {
      this.props.onSharePress(this.props.order);
    }
  };

  onPress = () => {
    if (this.props.onPress) {
      this.props.onPress(this.props.order);
    }
  };

  onCopyPress = () => {
    if (this.props.onCopyPress) {
      this.props.onCopyPress(this.props.order);
    }
  };

  onDeletePress = () => {
    if (this.props.onDeletePress) {
      this.props.onDeletePress(this.props.order);
    }
  };

  onEditPress = () => {
    if (this.props.onEditPress) {
      this.props.onEditPress(this.props.order);
    }
  };

  getValorFromChave(chave, infoAdicionais) {
    let valor;
    for (let i = 0; i < infoAdicionais.length; ++i) {
      if (infoAdicionais[i].chave === chave) {
        valor = infoAdicionais[i].valor;
        break;
      }
    }
    return valor;
  }

  onMoreInfosPress() {
    this.setState({ modalMoreInfosVisible: true });
  }

  openReport(report_url, report) {
    // alert('Não é possível gerar relatório no momento.');
    this.props.navigation.navigate({
      name: SCREEN_WEBVIEW.name,
      params: {
        name: report.descricao || 'Relatório',
        key: 'report',
        final_url: true,
        url: report_url
      },
    });
  }

  async onExportOrderPdf() {
    // alert('Não é possível gerar relatório no momento.');
    const { order, configExportReport } = this.props;
    const {
      numero_pedido: numeroDoPedido,
      codigo_interno: codigoInterno,
    } = order;
    const pkey_report = _.get(configExportReport, 'pkey_report');

    if (!(pkey_report && pkey_report !== '0')) {
      Alert.alert(
        `${translate('exportPDFOrder')}`,
        `${translate('invalidExportPDFOrder')}`,
      );
      return;
    }

    try {
      const paramsReport = {};
      let report = await ReportsModule.getReport(pkey_report);
      report = JSON.parse(report);
      const {
        params: [firstIndex, secondIndex],
      } = report;

      if (firstIndex && secondIndex) {
        paramsReport[firstIndex.name_bind] = codigoInterno;
        paramsReport[secondIndex.name_bind] = numeroDoPedido;
      } else if (firstIndex) {
        paramsReport[firstIndex.name_bind] = numeroDoPedido;
      }

      await exportReport(pkey_report, JSON.stringify(paramsReport), 'pdf',(responseReport) => {
        if (responseReport.status === 200) {
          this.openReport(responseReport.data.report_url, report)
        }
      });
    } catch (err) {
      const errors =
        typeof err.message === 'string' ? JSON.parse(err.message) : err;
      executeActionOrOpenScreenMessage(errors);
    }
  }

  onPressPopUp = (eventName, index) => {
    if (eventName !== 'itemSelected') {
      return;
    }
    if (this.state.actions[index] === MORE_INFOS) {
      this.onMoreInfosPress();
    } else if (this.state.actions[index] === PDF) {
      this.onExportOrderPdf();
    } else if (this.state.actions[index] === SHARE_ORDER) {
      this.onSharePress();
    }
  };

  renderOrderHeader = () => {
    const { showOrderHeaderAsHTML, order } = this.props;
    let situacaoColor = order.cor;
    if (!situacaoColor) {
      situacaoColor = '0,0,0';
    }

    if (showOrderHeaderAsHTML && order.html) {
      return (
        <View>
          <HTML source={{ html: order.html }} />
        </View>
      );
    }

    return (
      <View>
        <View style={style.rowDirection}>
          <Text style={styles.number}>
            {order.codigo_interno !== '0'
              ? order.codigo_interno
              : order.numero_pedido}
          </Text>
        </View>
        <Text style={styles.clientName}>{order.cliente}</Text>
        <CalculatedValue value={order.valor} style={styles.price} />
        <Text style={styles.title}>
          {`${translate('situation')}`}:
          <Text style={[styles.titleBlack, { color: `rgb(${situacaoColor})` }]}>
            {order.estado_valor}
          </Text>
        </Text>
      </View>
    );
  };

  render() {
    const { order } = this.props;

    const orderActionsView = (
      <View style={styles.orderActions}>
        {order.orcamento ? (
          <OrderAction
            onPress={this.onDeletePress}
            text={`${translate('delete')}`}
            icon={Images.iconDelete}
          />
        ) : null}
        {order.orcamento ? (
          <OrderAction
            onPress={this.onEditPress}
            text={`${translate('edit')}`}
            icon={Images.iconEdit}
          />
        ) : null}
        {order.orcamento ? (
          <OrderAction
            onPress={this.onSharePress}
            text={`${translate('share')}`}
            icon={Images.iconShare}
          />
        ) : null}
        <OrderAction
          onPress={this.onCopyPress}
          text={`${translate('copy')}`}
          icon={Images.iconDuplicate}
        />
      </View>
    );

    return (
      <Touchable
        onPress={this.onPress}
        style={styles.container}>
        <View>
          {this.renderOrderHeader()}
          <View style={styles.dateContainer}>
            <Text style={styles.date}>{`${translate('orderStartedAt')}:`}</Text>
            <Text style={[styles.date, { marginLeft: 8 }]}>
              {order.data.replace('-', '/').replace('-', '/')}
            </Text>
          </View>
          {orderActionsView}
          <View style={styles.popupMenu}>
            <PopupMenu
              actions={this.state.actions}
              onPress={this.onPressPopUp}
            />
            <View style={{ flex: 1 }} />
          </View>
          <MoreInfo
            modalMoreInfosVisible={this.state.modalMoreInfosVisible}
            htmlContent={order.html}
            onModalClose={() => {
              this.setState({ modalMoreInfosVisible: false });
            }}
          />
        </View>
      </Touchable>
    );
  }
}

OrderListItem.propTypes = {
    order: PropTypes.object.isRequired,
    configExportReport: PropTypes.object.isRequired,
    showOrderHeaderAsHTML: PropTypes.bool.isRequired,
    onPress: PropTypes.func.isRequired,
    onCopyPress: PropTypes.func.isRequired,
    onDeletePress: PropTypes.func.isRequired,
    onEditPress: PropTypes.func.isRequired,
};

export default OrderListItem;
