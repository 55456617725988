import ActivitiesModule from '~/modules/ActivitiesModule';
import ProductsModule from '~/modules/ProductsModule';
import store from '~/store';
import { finishCart } from '~/store/ordersFetch/finishCartSlice';
import { cancelOrderActive, saveCart } from '~/store/ordersFetch/utilsLib';

const methods = {
  lib: {
    justify: 'justify',
    inventory: {
      save_collect: ActivitiesModule.saveCollect,
      remove_collect: ActivitiesModule.removeCollect,
      start_inventory: ActivitiesModule.startInventory,
      finish_inventory: ActivitiesModule.finishInventory,
    },
    products: {
      fix_family_scale: ProductsModule.corrigirEscalaFamilia,
    },
  },
  internal: {
    orders: {
      finish_cart: (...params) => {
        store.dispatch(finishCart(...params));
      },
      save_cart: (...params) => store.dispatch(saveCart(...params)),
      order_cancel: (...params) => store.dispatch(cancelOrderActive(...params)),
    },
  },
};

export default new class Action {
  callLibMethod(success, error, type, method, ...params) {
    return new Promise((resolve, reject) => {
      if (params) {
        methods.lib[type][method](...params, resolve, reject);
      } else {
        methods.lib[type][method](resolve, reject);
      }
    })
      ?.then(data => {
        success?.(JSON.parse(data || '{}'));
      })
      ?.catch(err => {
        error?.(JSON.parse(err.message || '{}'));
      });
  }
  callInternalMethod(success, error, type, method, ...params) {
    let promise = methods.internal[type][method];

    promise(...params)
      ?.then(data => {
        success?.(JSON.parse(data || '{}'));
      })
      ?.catch(err => {
        error?.(JSON.parse(err.message || '{}'));
      });
  }
}();
