/* eslint-disable no-nested-ternary */
import _ from 'lodash';

export default class TextUtils {
  static slugfy(text) {
    let fText = '';
    if (typeof text === 'object') {
      // Get only values from object and convert to string
      text = Object.values(text).join(' ');
    }
    if (text !== undefined) {
      fText = String(text)
        .toLowerCase()
        .trim()
        .replace(/\s+/g, ' ');
    }
    return fText;
  }

  static compareStringWithNumber(a, b) {
    let x = a.match(/(\d+)/g);
    let y = b.match(/(\d+)/g);

    if (x && y) {
      return Number(x[0]) - Number(y[0]);
    } else if (x) {
      return 1;
    } else if (y) {
      return -1;
    }
    x = _.deburr(a);
    y = _.deburr(b);
    return x < y ? -1 : x > y ? 1 : 0;
  }

  static validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  static toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
    );
  }

  static mtel(v) {
    let res = v;
    res = res.replace(/\D/g, '');
    res = res.replace(/^(\d{2})(\d)/g, '($1) $2');
    res = res.replace(/(\d)(\d{4})$/, '$1-$2');
    return res;
  }

  static isJSONString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  static validateCPF(strCPF) {
    let Soma;
    let Resto;
    Soma = 0;
    strCPF = strCPF.replace('-', '').replace('.', '');
    if (strCPF === '00000000000') {
      return false;
    }

    for (let i = 1; i <= 9; i++) {
      Soma += parseInt(strCPF.substring(i - 1, i), 2) * (11 - i);
    }
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) {
      Resto = 0;
    }
    if (Resto !== parseInt(strCPF.substring(9, 10), 2)) {
      return false;
    }

    Soma = 0;
    for (let i = 1; i <= 10; i++) {
      Soma += parseInt(strCPF.substring(i - 1, i), 2) * (12 - i);
    }
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) {
      Resto = 0;
    }
    if (Resto !== parseInt(strCPF.substring(10, 11), 2)) {
      return false;
    }
    return true;
  }

  static cpfMask(valor) {
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }

  static cnpjMask(valor) {
    return valor.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      '$1.$2.$3/$4-$5',
    );
  }

  static capitalize(str) {
    let capitalizeStr = str;
    capitalizeStr = capitalizeStr.replace(/[^a-zA-Z+]+/gi, '');
    capitalizeStr = capitalizeStr.replace(/[A-Z]/g, u => u.toLowerCase());
    capitalizeStr = capitalizeStr.replace(/^[a-z]/, u => u.toUpperCase());
    return capitalizeStr;
  }
}
