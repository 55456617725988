import LoginFactory from './Login/LoginFactory';

import axios from 'axios';
import CacheAccount from './Cache/CacheAccount';
import ValidadeLoginFactory from './ValidadeLogin/ValidadeLoginFactory';
import { ATIVIDADES, POLIEQUIPES } from './escopeApps';
import STAGING from '../../services/resources/config';
import SQLiteHelper from '../../database/SQLiteHelper';
import CacheAtividades from './Cache/CacheAtividades';
import CachePolistore from './Cache/CachePolistore';

import NetInfo from '@react-native-community/netinfo';
import translate from '../../locales';
import AsyncStorage from '@react-native-async-storage/async-storage';

const URL_LOGIN = `https://process-server${STAGING}.polisuite.com.br/v1/core/auth/login/`;
const URL_CHECK = `https://process-server${STAGING}.polisuite.com.br/v1/core/auth/check/`;

class Account {
  constructor(sucess, error) {
    this.sucess = sucess;
    this.error = error;
    this.cacheAccount = new CacheAccount();
  }

  getDataAccount(login, password) {
    const loginSplit = login.split('@');
    const username = loginSplit[0];
    const companyCode =
      loginSplit && loginSplit.length > 1
        ? loginSplit[1].split('.')[0].toUpperCase()
        : '';
    return {
      user: {
        company: {
          company_code: companyCode,
          name: companyCode,
        },
        username,
      },
      apps: [POLIEQUIPES, ATIVIDADES],
      username,
      login,
      password,
    };
  }

  async login(username, password) {
    try {
      const dataAccountOld = await this.cacheAccount.getDataAccount();
      const dataAccount = this.getDataAccount(username, password);
      await this.cacheAccount.setDataAccount(dataAccount);

      const logins = LoginFactory.createLoginFactory(
        username,
        password,
        dataAccount,
      );

      const executeLogins = [];
      logins.forEach(login => {
        executeLogins.push(login.executeLogin());
      });

      Promise.all(executeLogins)
        .then(async () => {
          if (
            dataAccountOld &&
            dataAccount.user &&
            dataAccountOld.user.email !== dataAccount.user.email
          ) {
            if (dataAccount.apps && dataAccount.apps.includes(ATIVIDADES)) {
              const cacheAtividades = new CacheAtividades();
              await cacheAtividades.setData({});
            }
          }

          const data = await this.getDataProcess(username, password);

          if (data) {
            await this.cacheAccount.setDataAccount(data);
            this.sucess();
          } else {
            AsyncStorage.setItem('@TOKEN_AUTH_USER', '');
          }
        })
        .catch(error => {
          this.error(error.response?.data?.msg || error);
        });
    } catch (error) {
      if (error) {
        let mensagem = `${translate('invalidCredentials')}`;
        if (error.data && error.data.length > 0) {
          mensagem = error.data[Object.keys(error.data)[0]];
        } else if (typeof error === 'string') {
          mensagem = error;
        }
        this.error(mensagem);
      } else {
        const isConnected = await NetInfo.isConnected.fetch();
        if (isConnected) {
          this.error(`${translate('criticalError')}`);
        } else {
          this.error(`${translate('noInternetConnection')}`);
        }
      }
    }
  }

  async validateLogin() {
    const dataAccount = await this.cacheAccount.getDataAccount();

    if (dataAccount && dataAccount.apps) {
      try {
        this.validadeLoginsLocal(dataAccount);
      } catch (error) {
        if (error) {
          // sem conexão
          this.cacheAccount.setDataAccount(error);
          this.error();
        } else {
          this.validadeLoginsLocal(dataAccount);
        }
      }
    } else {
      this.error();
    }
  }

  getDataProcess = async (username, password) => {
    const usernameSplit = username.split('@');
    const user = usernameSplit[0];
    const companyCode =
      usernameSplit && usernameSplit.length > 1
        ? usernameSplit[1].split('.')[0].toUpperCase()
        : '';

    const cachePolistore = new CachePolistore();
    const dataSignInToParse = await cachePolistore.getParamsSignIn();
    const dataSignIn = JSON.parse(dataSignInToParse);
    const { rca_nomeusuario: firstName } = dataSignIn;
    const dataAccount = this.getDataAccount(username, password);
    const { username: userName } = dataAccount;
    const firstNameSplited = userName.split(/[-_,.\s]/);
    dataAccount.user.comesFromLogin = !firstName;
    dataAccount.user.first_name = firstName || firstNameSplited[0];

    const data = { ...dataAccount };
    const dataForProcess = {
      username: user,
      password,
      company_code: companyCode,
    };
    try {
      const response = await axios.post(URL_LOGIN, dataForProcess);
      if (response.status === 200) {
        const { token } = response.data;
        return {
          token,
          ...data,
        };
      }
      // return data;
    } catch (error) {
      this.error('Erro realizar login! Verifique seu usuário e senha.');
      // return data;
    }
  };

  checkToken = async dataAccount => {
    const isConnected = await NetInfo.isConnected.fetch();
    const data = { token: dataAccount.token };

    if (isConnected) {
      const result = await axios({
        url: URL_CHECK,
        method: 'post',
        data,
      });
      return result;
    }
    return null;
  };

  validadeLoginsLocal(dataAccount) {
    const validadeLogins = ValidadeLoginFactory.createValidadeLogin(
      dataAccount,
    );

    const executeValidadeLogins = [];
    validadeLogins.forEach(validade => {
      executeValidadeLogins.push(validade.validadeLogin());
    });

    Promise.all(executeValidadeLogins)
      .then(() => this.sucess())
      .catch(() => this.error());
  }
}

export default Account;
