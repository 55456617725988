import { StyleSheet } from 'react-native';
import { color, size } from '~/common';


const styles = StyleSheet.create({
  container: {
    padding: size.horizontalSpacing,
    borderBottomColor: color.grayDark,
    borderBottomWidth: 1,
    backgroundColor: '#FFF',
  },
  title: {
    color: color.primaryColor,
    fontSize: size.textLittleBig,
    fontWeight: '500',
    marginBottom: size.horizontalSpacing,
  },
});

export default styles;
