export default {
  AccessSolicitation: 'Solicitud de acceso',
  app_name: 'PoliEquipes App',
  Attributes: 'Atributos',
  CampaignReceived: '¡Nueva promoción!',
  cancel: 'Cancelar',
  Characteristics: 'Características',
  Choose_Filters: 'Elegir filtros',
  complementary: 'Complementario',
  conditionsSalesChild: 'Condiciones de Venta para Niños',
  hello: 'Hola',
  moreProductsScreen: 'Más Productos',
  no: 'No',
  noOpenFilePDF: 'No es posible abrir el archivo pdf.',
  normal: 'Normal',
  NotifyProductReceived: 'Llegó el producto que querías',
  chooseCopyType: 'Orden de copia',
  questionCopyComplementary: '¿Qué tipo de copia quieres hacer?',
  registerAgain: 'Reinscripción',
  creditAdjustment: 'Actualizar registro',
  shipping: 'Flete',
  shippingCompany: 'Transportadora',
  shippingEstimate: 'Cálculo de frete',
  lostShippingMessage:
    'Si sale, perderá la información de envío que ya ha sido calculada.',
  stay: 'Ficar',
  connectionError: 'Error de conexión.',
  errorListSC: 'No fue posible enumerar las compañías navieras.',
  recalculateShipping: 'Recalcular envío',
  priceOrderChangeMSG:
    '¡El precio total del pedido ha sido modificado!. \nEs necesario calcular el flete.',
  recalculate: 'Recalcular',
  waitForSync: 'Esperando sincronización',
  syncInBackground: 'Sincronizando en segundo plano',
  syncBackground:
    'Sincronização executando em segundo plano, aguarde a sincronização ser finalizada.',
  yes: 'Sí',
  yesAll: 'Si para todos',
  yesSave: 'Sí, guarda',
  yesConfirm: 'Sí, confirmar',
  yesRemove: 'Sí, eliminar',
  noRemove: 'No, eliminar',
  yesDiscard: 'Sí, descartar',
  yesCancel: 'Sí, cancelar',
  yesClean: 'Si, limpio',
  yesApply: 'Sí, aplicar',
  SolicitateAccess: 'Solicitar acceso',
  Stores: 'Tiendas',
  Orders: 'Pedidos',
  order_successful: '¡Pedido realizado correctamente!',
  Notifications: 'Notificaciones',
  NotificationsDetail: 'Detalle de la notificación',
  noNotifications: 'No notificaciones',
  Registrations: 'Registros',
  priceNotAvailable: 'Precio no disponible',
  productNotAvailable: 'Producto no disponible',
  Security: 'La seguridad',
  Customers: 'Clientes',
  Profile: 'Perfil',
  Pay: 'Pagar',
  Buy: 'Comprar',
  Confirm_Buy: 'Finalizar orden',
  Confirm: 'Confirme',
  Register: 'Registrar',
  continue: 'Seguir',
  Close: 'Cerrar',
  Confirm_your_Buy: 'Confirma tu compra',
  Cart: 'Carrito',
  Promotions: 'Promociones',
  Sort: 'Orden',
  Home: 'Comienzo',
  My_Cards: 'Mis cartas',
  Settings: 'Ajustes',
  My_Stores: 'Tiendas visitadas',
  Near_Stores: 'Tiendas cercanas',
  See_All: 'Ver todo',
  Search_For: 'Buscar',
  search: 'Busca...',
  StoreSolicitation: 'Solicitar acceso',
  See_on_Map: 'Ver en el mapa',
  Enter_or_SignUp: 'Iniciar sesión o registrarse',
  Enter_with_your_credentials: 'Ingrese sus credenciales',
  logOut: 'Salir',
  youReallyWantLeave: '¿De verdad quieres irte?',
  really_confirm_order:
    '¿Realmente quieres irte? Cuando haces clic en Confirmar, tu pedido será enviado y ya no podrás cambiarlo. \nQuieres confirmar',
  Order: 'Orden',
  no_orders_found: 'No se encontraron pedidos',
  no_campaigns_found: 'No se encontraron campañas',
  noStores: 'Sin tienda.',
  noStoresMaybeSearch:
    'Es posible que no tenga acceso a ninguna tienda. \nIntente buscar una tienda y solicite acceso en la pestaña "Buscar"',
  noStoresUseSearch:
    'Ingrese el nombre de la tienda en el campo "Buscar tiendas ... " y haga clic en "Buscar " para buscar tiendas.',
  toAccessStore:
    'Para acceder a los productos de la tienda y realizar pedidos, esta tienda debe aceptarte como cliente. \n\nPara solicitar su acceso, haga clic en el botón "Solicitar acceso ", ingrese el CNPJ o CPF de la empresa para la que desea realizar pedidos y confirme.',
  Solicitations: 'Peticiones',
  Show_QRCode: 'Mostrar código QR',
  Change_Password: 'Cambiar contraseña',
  MyWallet: 'Mi billetera',
  INFORMATION: 'INFORMACIÓN',
  Payment_Term: 'Fecha límite',
  Payment: 'Pago',
  Buyer: 'Comprador',
  Conditions: 'Condiciones',
  IntegrationStatus: 'Estado de integración',
  PaymentStatus: 'Estado de pago',
  original_price: 'precio original',
  Messages: 'Mensajes',
  items: 'Artículos',
  Identity: 'Identidad',
  BasicInfo: 'Informaciones básicas',
  EnterYourName: 'Su nombre',
  ExhibitionName: 'Nombre de exibición',
  hide: 'Esconder',
  identityNumber: 'CUIT',
  cnpj: 'NIN',
  addition: 'Adición',
  reduction: 'Reducción',
  initial: 'Inicial',
  taxInfo: 'Información fiscal',
  priceScale: 'ESCALA DE PRECIOS',
  scale: 'Escala',
  taxes: 'IMPUESTOS',
  name: 'Nombre',
  CompleteName: 'Nombre completo',
  QR_CODE: 'CÓDIGO QR',
  Info: 'Info',
  Information: 'Información',
  Description: 'Descripción',
  ComplementaryInformations: 'Informaciones complementarias',
  informations: 'Informaciónes',
  details: 'Detalles',
  Alerts: 'Alertas',
  My_Orders: 'Mis pedidos',
  Create_Route: 'Crear ruta',
  created_at: 'Creado en',
  Store: 'Tienda',
  Campaigns: 'Campañas',
  Category: 'Categoría',
  Categories: 'Categorias',
  Campaign: 'Campaña',
  Filter: 'Filtrar',
  Filter_By_Category: 'Filtrar por categoría',
  cleanFilter: 'Filtro claro',
  login: 'Iniciar sesión',
  tryAgain: 'Intentar nuevamente',
  noMessages: 'No hay mensajes',
  emptyCart: 'Su cesta está vacía',
  Open: 'Abierto',
  Waiting_Acceptance: 'Esperando aprobación',
  Waiting_Response: 'Esperando respuesta',
  wait: 'Aguarde',
  Canceled: 'Cancelado',
  Done: 'Concluído',
  Expired: 'Caducado',
  Show_Archived: 'Ver archivado',
  Only_Near_Stores: 'Solo tiendas cercanas',
  Send: 'Enviar',
  Address: 'Dirección',
  Street: 'Calle',
  Number: 'Número',
  City: 'Ciudad',
  District: 'Barrio',
  Complement: 'Complemento',
  ZipCode: 'Codigo postal', // dúvida
  Enter: 'Entrar',
  EditProfile: 'Editar Perfil',
  ENTER: 'ENTRAR',
  Email: 'Correo electrónico',
  password: 'Contraseña',
  NewPassword: 'Nueva contraseña',
  CardNumber: 'Numero de tarjeta',
  CardCPF: 'CPF do Titular (só números)', // dúvida
  CardHolder: 'Nombre del titular (como en la tarjeta)',
  CardValidDate: 'Duracion (Ex: 01/2018)',
  CVV: 'CVV',
  AddCard: 'Agregar tarjeta',
  CardInfo: 'INFORMACIÓN DE LA TARJETA',
  aditionalInformations: 'Informaciones adicionales',
  viewCart: 'Ver carrito',
  syncInfo: 'Sincronizando información',
  contactingTheServer: 'Contactando con el servidor',
  weFoundYourInformation: 'Encontramos tu información',
  completingTheSettings: 'Completando la configuración',
  weAreSendingYourInformations: 'Enviamos sus informaciones',
  weAreUpdatingYourData:
    '¡Espere un poco más! Todavía estamos actualizando tus datos.\n\nSigue el progreso de tu actualización en el menú Perfil.',
  WeAreBringingYourInformation: 'Estamos trayendo tu información',
  TypeNewPasswordAndConfirm: 'Ingrese la nueva contraseña y confirme.',
  Confirm_Password: 'Confirmar seña',
  Create: 'Crear',
  changePrice: 'CAMBIO DE PRECIO',
  add: 'Añadir',
  Choose: 'Elegir',
  phone: 'Teléfono',
  phones: 'Los telefonos',
  Cancel: 'Cancelar',
  status: 'Estado',
  State: 'Estado',
  Minimum: 'Mínimo',
  Maximum: 'Máximo',
  upUntil: 'Hasta',
  Visited_Stores: 'Tiendas visitadas',
  no_near_store_found: 'No se encontraron tiendas cercanas',
  no_title: 'Sin titulo',
  didnt_visit_any_store: 'Aún no has visitado ninguna tienda',
  loading_visited_stores: 'Cargando tiendas visitadas',
  loading_near_stores: 'Cargando tiendas cercanas',
  loading: 'Carregando',
  loadingStores: 'Carregando tiendas...',
  loadingCustomers: 'Carregando registros...',
  loadingCart: 'Carregando carrito...',
  not_informed: 'No informado',
  Total: 'Total',
  save: 'Guardar',
  saving: 'Guardando...',
  Price_Conditions: 'Condiciones de precio',
  you_nedd_to_set_email_and_password:
    'Debe ingresar correo electrónico y contraseña.',
  Companies_You_Can_Buy_From: 'Empresas a las que puede comprar',
  error_login: 'Se produjo un error al realizar la autenticación',
  error_load_categories: 'Hubo un error al cargar las categorías.',
  error_load_stores: 'Se produjo un error al cargar las tiendas.',
  error_load_customers: 'Hubo un error al cargar las entradas.',
  error_load_orders: 'Hubo un error al cargar pedidos.',
  YouWillBeNotified: 'Eliminar producto de la lista de espera',
  NotifyMe: 'Dime cuando llegas',
  version: 'Versión',
  versionUpdate: 'Actualización de versión',
  update: 'Actualizar',
  updateLater: 'Actualizar más tarde',
  lastDataUpdate: 'Última actualización de datos',
  lastDataUpload: 'Último envio de dados',
  today: 'Hoy',
  moreInfo: 'Mas informaciones',
  image: 'Imagem',
  goTo: 'Ir',
  newRegister: 'Nuevo registro',
  yourLocation: 'Tu ubicación',
  yourCurrentLocation: 'Su ubicación actual',
  message: 'Mensaje',
  unabledLocation: 'Ubicación deshabilitada. \nHaga clic aquí para habilitar.',
  errorRadiusClientRequiredGPS: 'El radio del cliente no está definido.',
  errorPointRequiredGPS:
    'No pudimos obtener su ubicación. Recogida de puntos obligatoria.',
  errorAreaClientGPS: 'Estás lejos del área de clientes',
  outOfPlace: 'No estás en el sitio',
  inPlace: 'Estas en el lugar',
  inThisPlace: 'Estás en esta ubicación',
  location: 'Ubicación',
  openActivity: 'Abrir actividad',
  atention: 'Atención',
  changeLocation: 'Cambiar locación',
  cantSaveAnswer: 'Esta actividad no se pudo guardar.',
  pleaseTryAgain: 'Inténtalo de nuevo',
  title: 'Titulos',
  pluralAddress: 'Direcciónes',
  totalLimitCredit: 'Límite de crédito total',
  openCreditLimit: 'Límite de crédito abierto',
  creditLimitBalance: 'Saldo del límite de crédito',
  company: 'Razón social',
  companyName: 'Nombre de la empresa',
  code: 'Código',
  fantasy: 'Fantasia',
  change: 'Cambio',
  contact: 'Contacto',
  reason: 'Razón',
  utterance: 'Emisión',
  dueDate: 'Vencimento',
  portion: 'Parte',
  noCustomerLocation: 'Cliente sin ubicación registrada.',
  updatedAt: 'Actualizado en',
  precisionOf: 'Exactitud de',
  copy: 'Copiar',
  from: 'Desde',
  orderStartedAt: 'El pedido comenzó el',
  situation: 'Situación',
  edit: 'Para editar',
  delete: 'Eliminar',
  yesDelete: 'Sí, eliminar',
  dataUpdate: 'Actualización de datos',
  or: 'o',
  and: 'y',
  before: 'antes',
  sendPhotoOnlyWifi: 'Sube fotos solo con WiFi',
  avaliate: 'Para evaluar',
  takePicture: 'Tomar la foto',
  selectFromGallery: 'Seleccionar de la galería',
  theFieldsAreRequired: 'Los campos son obligatorios',
  putTheValue: 'Ingrese el valor',
  dateRangeBeforeError: 'Ingrese una fecha anterior a la final.',
  dateRangeAfterError: 'Ingrese una fecha posterior a la inicial.',
  beginDate: 'Fecha de inicio',
  endDate: 'Fecha final',
  generateReport: 'Generar informe',
  youDontHaveFilters: 'No tienes filtros.',
  salesman: 'Vendedor',
  released: 'Liberado',
  clientBlocked: 'Cliente Bloqueado',
  noCustomersThisDay: 'No hay clientes para este día',
  noCustomers: 'No se encontraron clientes',
  noItems: 'No hay artículos',
  registeredAt: 'Registrado en',
  needCatchPhotoBeforeAndAfter:
    'Es necesario capturar la foto de antes y después para guardar.',
  putSomeDataToSave: 'Complete alguna información para guardar esta actividad',
  productName: 'Nombre del producto',
  selectADate: 'Seleccionar una fecha',
  indeterminatedPlace: 'Ubicación indeterminada',
  kilometers: 'Kilómetros',
  kilometer: 'Kilómetro',
  meter: 'metro',
  meters: 'metros',
  locationDistance: 'Usted está a',
  required: 'Necesario',
  nonRequired: 'No requerido',
  process: 'Proceso',
  discard: 'Descartar',
  wantSave: '¿Quieres guardar?',
  wantSaveTime: '¿Quieres ahorrar tiempo?',
  exportToPDF: 'Visualizar PDF',
  saveStopwatchRunning:
    '\nEl tiempo capturado no se ha guardado.\nAl salir sin guardar se perderá el tiempo.',
  stopwatchPaused: 'Cronómetro en pausa',
  errorSaveStopwatch:
    '¡Necesita capturar el tiempo de actividad antes de ahorrar!',
  openSettings: 'Configuración abierta',
  exportPDFOrder: 'Exportación de pedido PDF',
  invalidExportPDFOrder:
    'No se puede exportar el pedido a PDF.\n\nLa configuración para exportar el pedido a PDF no es válida.',
  messageAccesCameraTitle: 'Permiso para usar la cámara.',
  messageAccesCamera: 'Para continuar, necesitamos acceso a su cámara.',
  messageAccesLocation: 'Para continuar, necesitamos acceso a su localización.',
  messageAccessCameraAndLocation:
    'Para continuar, necesitamos acceso a su cámara y localización.',
  messageAccesCameraLocationAndFiles:
    'Para continuar, necesitamos acceso a su cámara, archivos y medios',
  messageAccessPhotosAndVideos:
    'Para continuar, necesitamos acceso a sus fotos y videos.',
  discardChanges: 'Descartar los cambios',
  discardChangesInForm:
    'Algunos campos están llenos.\nAl hacer clic en descartar, los cambios se perderán.\n\n¿Desea descartarlos?',
  discardRegistration: 'Descartar registro',
  discardChangesInFormAddClient:
    '\nNo se guardó el registro del cliente.\nAl salir sin guardar, se perderá la información.\n\n¿Quiere salir y descartar el registro?',
  fillSchedulingDate: 'Por favor complete la fecha de la cita',
  changesSavedSuccesfully: 'Los cambios se guardaron exitosamente.',
  pendingCheckoutInClient: 'Check-out pendiente en el cliente',
  answerToContinue: 'Responde para continuar',
  errorActivityInProgress:
    'En proceso. Finalícelo para acceder a otras actividades.',
  activitiesProgress: 'Progreso de actividades',
  processWithoutDescription: 'Proceso sin descripción',
  unnamedProcess: 'Proceso sin nombre',
  timerAlreadyRegistered: '¡Tiempo ya registrado! \nNo puede editarlo.',
  activitiesSequenceRequired: 'Responder a las actividades en secuencia',
  itsNecessaryToAnswer: 'Es necesario responder',
  cantFindTaskDependency: 'No se encontró actividad dependiente.',
  contactSupport: 'Soporte de contacto',
  taskAlreadyAnswered: 'Esta actividad ya ha sido respondida',
  competitorAchievement: 'Logro de la competencia',
  campaingTitle: 'Título de la campaña',
  campaingNumber: 'Número de campaña',
  addClient: 'Agregar cliente',
  noProducts: 'Sin productos',
  removeSelectedItems: 'Eliminar productos seleccionados',
  minPrice: 'El precio más bajo',
  saveBudget: 'Ahorrar presupuesto',
  saveOrderAsBudget:
    'No es posible realizar una devolución sin finalizar el pedido o guardarlo como presupuesto.\n\n¿Quieres guardar este carrito como una cotización?',
  cancelOrder: 'Cancelar orden',
  wantDiscardYourChanges:
    'Su pedido no se guardará y todos los artículos de este pedido se descartarán.\n\n¿Desea cancelar su pedido?',
  wantRemoveSelectedItems:
    '¿Realmente quieres eliminar los artículos seleccionados?',
  wantRemoveImage:
    'Se eliminará la foto seleccionada.\n\n¿Realmente quieres eliminar tu foto?',
  removeImage: 'Quitar foto',
  yourChangesWillBeLost:
    'Al devolver sus cambios se perderán. ¿Quieres volver de todos modos?',
  orderBy: 'Ordenar Por',
  saleHistory: 'Historial de ventas',
  productsSuggestion: 'Sugerencia de producto',
  noProductsAndCategories: 'Aún no tienes productos y categorías',
  newCart: 'Nuevo Carrito',
  alreadyExistsOpenedCart: 'Ya hay un carrito abierto. ¡Finaliza tu carrito!',
  inStock: 'En stock',
  inStockLCase: 'en stock',
  stock: 'Stock',
  noStock: 'No hay stock',
  unavailableCombos: 'Combos no disponibles',
  noFinancialTitlesEnvironmental: 'Sin valores financieros ambientales',
  ascending: 'Creciente',
  descending: 'Descendiendo',
  noFinancingTitles: 'Sin valores financieros.',
  financing: 'Financiero',
  goToHome: 'Ir a la inicio',
  succesfullyFinishOrder: '¡Su orden ha sido completada satisfactoriamente!',
  noInformation: 'Sin información.',
  orderPending: 'Encontramos un pedido pendiente',
  wantContinueEditingOrder:
    'Lo tenemos todo bajo control, recuperamos el pedido por ti.',
  resolvingPendingOrder:
    'Para iniciar un nuevo pedido, es necesario resolver este asunto pendiente. ¿Qué es lo que quieres hacer?',
  client: 'Cliente',
  hasPendingOrder: 'Hay un pedido pendiente',
  saveOrderBudget: 'Guardar pedido como cotización',
  doYouWantDeleteOrder: '¿Quieres eliminar el pedido?',
  onceConfirmAction: 'Una vez confirmada la acción, no se puede deshacer.',
  decideLater: 'Decidir más tarde',
  yesDeletePendingOrder: 'Sí, eliminar',
  deleteOrder: 'Eliminar orden',
  restoreOrder: 'Reestablecer el orden',
  cantFindModule: 'No se pudo encontrar el módulo: ',
  doDayClosure: 'Cerrar el dia',
  wantToDoDayClosure: '¿De verdad quieres cerrar el día?',
  needToDoLoginAgain: 'Debes iniciar sesión nuevamente.',
  failedToSendFile: '¡Error al cargar el archivo!',
  moduleNotFound: 'Módulo no encontrado',
  ediModuleNotFound: '¡Módulo EDI no encontrado!',
  youDontHaveModules: 'No tienes módulos.',
  incentiveAvailable: 'Incentivo disponible',
  applyIncentive: '¿Quieres aplicar el incentivo?',
  incentiveApplied: '¡Ya se ha aplicado el incentivo!',
  file: 'Archivo',
  titles: 'Títulos',
  deleteSyncronizedPhotoToFreeSpace:
    'Elimina las fotos ya sincronizadas para liberar espacio: ',
  invalidTokenLogin: 'Token de acceso no válido',
  typeLoginAndPasswordToLogin:
    'Ingrese correo electrónico y contraseña para iniciar sesión',
  authenticating: 'Autenticando...',
  loginEnter: 'INICIAR SESIÓN',
  somePointsNeedsYourAtention: 'Algunos puntos necesitan tu atención!',
  observationAdded: 'Notas guardadas con éxito.',
  thereAreNotObservationsToThisProduct: 'No hay comentarios para este producto',
  observations: 'Comentarios',
  orderNumber: 'Numéro del pedido',
  date: 'Fecha',
  dateToSend: 'Fecha de envío',
  customer: 'Cliente',
  billedItems: 'Artículos facturados',
  partial: 'Parcial',
  partialBilled: 'Facturado parcial',
  liquid: 'Líquido',
  billedLiquid: 'Líquido parcial',
  taxSubstituiton: 'Sustitución tributaria',
  IPI: 'IPI', // dúvida
  amountOfBoxes: 'Cantidad de cajas',
  amountOfBilledBoxes: 'Cantidad de cajas facturadas',
  salesOrganization: 'Organización de ventas',
  salesType: 'Tipo de venta',
  salesTypeInvalid: 'Tipo de venta no válido',
  salesCondition: 'Condiciones de venta',
  middle: 'Medio',
  middleInvalid: 'Medio no válido',
  paymentOptions: 'Medios de pago',
  priceTable: 'Lista de precios',
  priceTableInvalid: 'Lista de precios no válido',
  funds: 'Verba',
  promptDelivery: 'Entrega rápida',
  gifts: 'Regalos',
  deadline: 'Fecha límite',
  deadlineInvalid: 'Fecha límite no válido',
  budget: 'Presupuesto',
  errorCopyOrder: 'Error al copiar el pedido.',
  thereAreAOpenedClientOrCart: 'Ya hay un cliente o carrito abierto.',
  deleteCart: 'Eliminar carrito',
  wantDeleteThisCart: '¿Está seguro de que desea eliminar este carrito?',
  afterDeleteCanNotGoback:
    'Después de eliminar, no podrá deshacer esta acción..',
  select: 'Seleccione',
  requestedQuantity: 'Cantidad requerida',
  billedQuantity: 'Cantidad facturada',
  requestedPrice: 'Precio solicitado',
  billedPrice: 'Precio facturado',
  requestedTotal: 'Total solicitado',
  billedTotal: 'Total facturado',
  discountRequested: 'Descuento solicitado',
  discount: 'Descuento',
  increase: 'Adición',
  billedDiscount: 'Descuento facturado',
  noneFiles: 'Sin archivos',
  dateSave: 'Fecha guardada',
  printDate: 'Fecha de impresion',
  removeItem: 'Remover el artículo',
  conditionsPriceTerm: 'Condiciones de precio',
  noPriceTermConditions: 'Sin condiciones de precio y plazo',
  remove: 'Remover',
  contains: 'Contiene',
  exitPoliEquipes: 'Dejar el PoliEquipes',
  lostChangesOnExit: 'Cuando te vayas, tus cambios se perderán.',
  lostChangesOnExitAccount:
    'Al salir, se perderán las fotos no sincronizadas. \n\nIngrese la palabra Confirme en el campo a continuación y haga clic en confirmar.',
  errorExitPoliEquipes: 'Error al salir de PoliEquipes',
  synchronize: 'Sincronizar',
  swapAccount: 'Cambiar cuenta',
  noneProspects: 'No se encontraron prospectos',
  printManagerNotInstaller: 'Módulo de impresión no instalado.',
  noneReports: 'Sin informes',
  finishOrder: 'Orden completa',
  clickConfirmToSendYourOrder:
    'Al hacer clic en confirmar, tu pedido será enviado y ya no podrás cambiarlo.\n\n¿Quieres confirmar?',
  noneRewards: 'No hay recompensas disponibles',
  processBlocked:
    'Ya hay un proceso iniciado en otro cliente. Finalizar las actividades del proceso ya iniciado.',
  yourVersionMustBeUpdated: 'Tu versión debe actualizarse.',
  avaiableVersion:
    'Hay una nueva versión disponible. ¿Quieres actualizar la versión ahora?',
  after: 'Después',
  openLocaleSettings: 'Configuración de idioma',
  openLocaleSettingsDescription: 'Configure el idioma de su aplicación.',
  errorDeviceNotAuthorized:
    'El equipo ya no está autorizado para la conexión. El sistema se apagará. Póngase en contacto con la empresa.',
  completeYourChangesToSync: 'Complete sus cambios para sincronizar.',
  errorUploadGetSerial:
    '¡Error al enviar su información! No pudimos recuperar su última serie.',
  errorUploadToServer:
    '¡Error al enviar su información! El servidor no recibió su información.',
  errorDownloadFromServer: '¡Error al recibir su información!',
  errorProcessTable: 'Tablas de procesamiento de errores',
  errorInternetConnection:
    'Comprueba tu conexión a Internet y vuelve a intentarlo.',
  activitiesScreen: 'Atividades',
  clientsScreen: 'Clientes',
  offRouteCustomersScreen: 'Clientes fuera de ruta',
  tradeScreen: 'Trade',
  addClientScreen: 'Agregar cliente',
  addProspectScreen: 'Agregar prospect',
  newClientsScreen: 'Nuevos clientes',
  prospectsScreen: 'Prospects',
  enviromentScreen: 'Ambiental',
  catalogScreen: 'Catálogo',
  productObservationsScreen: 'Notas del producto',
  orderObservationsScreen: 'Orden observaciones',
  productDetailScreen: 'Detalles del producto',
  imageScreen: 'Imagen',
  cartScreen: 'Productos',
  orderScreen: 'Pedidos',
  orderDetailsScreen: 'Detalles del pedido',
  orderResumeScreen: 'Resumen del pedido',
  resume: 'Resumen',
  finishScreen: 'Pedido completado',
  titlesScreen: 'Títulos',
  clientDetailsScreen: 'Detalles del cliente',
  goToClient: 'Ir al cliente',
  itineraryScreen: 'Guión',
  loginScreen: 'Iniciar sesión',
  accountsLoginScreen: 'Cuentas',
  homeScreen: 'Comienzo',
  bonificationsScreen: 'Bonificaciones',
  profileScreen: 'Perfil',
  lightBoxScreen: 'LightBox',
  compoundProductScreen: 'Producto compuesto',
  promotionDetailsScreen: 'Detalles de la promoción',
  generationFormScreen: 'Formulario de generación',
  reportsScreen: 'Informes',
  combosScreen: 'Combs',
  comboDetailScreen: 'Detalles de lo Combo',
  filtersScreen: 'Filtros',
  updateVersionScreen: 'Actualización de versión',
  messagesScreen: 'Publicaciones',
  rewardsScreen: 'Recompensas',
  printScreen: 'Impresión',
  gamificationScreen: 'gamification',
  managementScreen: 'management',
  goalsScreen: 'goals',
  screenPriceTermConditions: 'Condiciones de precio y plazo',
  waitALittleLonger: 'Espere un poco más',
  thisFieldIsRequired: 'Este campo es requerido',
  maxSizeIs: 'Los archivos {{files}} deben ser como máximo {{maxSize}}MB',
  typedIsNotValid: 'escrito no es válido',
  fieldIsNotValid: 'El campo no es válido',
  changeOcurred: 'Se produjo un cambio',
  scriptChange: '¡Ha habido un cambio en tu guión!',
  criticalError: 'Hubo un error crítico. Soporte de contacto.',
  invalidCredentials:
    'Credenciales no válidas. Verifique su nombre de usuario y contraseña.',
  paramsError:
    'Tu acción no se puede realizar por falta de parametrización. Soporte de contacto.',
  noInternetConnection: 'Sin conexión a Internet',
  unavailableGPS: 'GPS no disponible',
  unavailableActivities: 'Lista de actividades no disponible',
  releaseActivity: 'Realice un nuevo registro para liberar esta actividad.',
  alertPhotoActivity:
    'Hiciste fotos pero no guardaste la actividad. Si hace clic en salir, las fotos se perderán. ¿Quieres salir?',
  getOut: 'Salir',
  respond: 'Responder',
  lookAnswer: 'Mira la respuesta',
  syncPendingPhotos: 'Número de fotos pendientes para sincronizar',
  filesSuccessfullySynced: 'Arquivos sincronizados correctamente (:',
  errorSyncPhotos: 'No se pueden sincronizar tus fotos. ):',
  connectionErrorWhenSyncPhotos:
    'Error al enviar fotos. Verifique su conexión a internet e inténtelo nuevamente. COD001',
  connectionWifiOnlyWhenSyncPhotos:
    'Error al enviar fotos. Asegúrese de estar conectado a una red WIFI. COD002',
  syncPhotosGeneric:
    'Error al enviar fotos. Ocurrió un error inesperado. Si el problema persiste, comuníquese con el soporte. COD003',
  notCompleteSyncFiles:
    'Error al enviar una o varias arquivos. Si el problema persiste, comuníquese con el soporte. COD004',

  photosSuccessfullyDeleted: 'Fotos borradas con éxito (:',
  errorDeletePhotos: 'Ninguna foto encontrada en el dispositivo :(',
  pressToSync: 'Pressione para sincronizar',
  errorConnectionGPS:
    'Su punto ha sido recopilado pero no tiene conexión. Haga clic aquí para abrir la configuración.',
  generalInfo: 'Informaciones generales',
  CollectingPoints: 'Recopilación de puntos GPS',
  clickToLogin: 'clica para fazer inicio de sesión',
  activityRequiredNotDone: 'Realice todas las actividades requeridas primero',
  clearSelectedProducts: 'claro seleccionado',
  removeProductsAndKeepCopyOrder: 'Copiar de todos modos',
  someProductsWillNotBeAdded: 'Hay productos que no se agregarán.',
  usernameAndPasswordMustNotBeEmpty:
    'El nombre de usuario y contraseña no debería estar vacíos',
  nullOrInvalidCode: 'Código nulo ou inválido',
  codeMustHaveOnlyFourDigits: 'Codigo deve ter solo 4 digitos',
  yesRequest: 'Si, solicitud',
  offRouteService: 'Servicio todoterreno',
  offRouteCustomerService:
    'está fuera de tu ruta.\n\n¿Quieres solicitar asistencia?',
  ClientOffRoute: 'fuera rute',
  changingCustomerLocation: 'Está cambiando la ubicación del cliente.',
  wantToSubmit: '¿Quieres enviar una solicitud?',
  sendRequest: 'Solicitud enviada',
  successSendRequest: 'Solicitud enviada con éxito!',
  errorSendRequest: 'No se pudo enviar la solicitud!',
  startingPointScreen: 'Punto de partida',
  creatingStartingPoint: 'Estás creando tu punto de partida.',
  changeCustomerLocation: 'Cambiar la ubicación del cliente',
  expectedTime: 'Tiempo esperado',
  visitTime: 'Tiempo de visita',
  displacementTime: 'Tiempo de viaje',
  contactUs: 'Hable Con Nosotros',
  contactUsDescription: '¿Preguntas o sugerencias? Háblanos.',
  contactAndSocialNetworks: 'Contactos y Redes Sociales',
  salesConditionInvalid: 'Condiciones de venta no válidas',
  selectLeastOneSaleCondition: 'Seleccione al menos una condición de venta',
  invalidUsername: 'Nombre de usuario inválido',
  customersInvoice: 'Cliente Factura',
  clear: 'Limpio',
  clearFields: 'Borrar campos',
  wantToClearFields: '¿Desea borrar los campos del formulario?',
  createAddress: 'Nuevo direccíon',
  noTabs: 'sin fichas...',
  noHTML: 'No hay plantilla para mostrar',
  backgroundTrackingTitle: 'PoliEquipes - Monitoreo GPS',
  backgroundTrackingMessage: 'Recopilación de puntos de ubicación',
  currency: 'EUR',
  currencySymbol: '€',
  separatorCurrency: ',',
  delimiterdelimiterCurrency: '.',
  comboRemoved: 'Combo eliminado!',
  ComboSuccessfullyRemoved: 'Combo eliminado con éxito!',
  comboAlertExit:
    '¿Quieres salir sin aplicar el Combo? Al salir, se retirarán los productos que se encuentren en el carrito.',
  combs: 'Combs',
  period: 'Período',
  sharedBalance: 'Saldo compartido',
  apply: 'Aplicar',
  methodology: 'Metodología',
  fieldsWithAreMandatory: 'Los campos con * son obligatorios',
  errorOpeningOrderNote: 'Nota de pedido de apertura de error',
  ErrorSavingOrderNote: 'error al guardar nota de pedido',
  addrAlternativeSucess: 'Localización guardada con éxito',
  addrCheckin: 'Localización de check-in',
  addrChange: 'Cambiar localización',
  addrCreate: 'Neuvo localización',
  SalesOpportunity: 'Oportunidad de ventas',
  DetailOpportunity: 'Detalle de la oportunidad de venta',
  noSalesOpportunity: 'Ninguna oportunidad de venta',
  source: 'Origen',
  sourceNumberOrder: 'Número de pedido de origen',
  saleOpportunityStartedAt: 'La oportunidad de venta empezó el',
  reasonCancel: 'Motivo de cancelacíon',
  dateCancel: 'Fecha de cancelacíon',
  cnpjCpf: 'CNPJ/CPF',
  ean: 'ean',
  quantity: 'Cantidad',
  accept: 'Acepta',
  enterReason: 'Ingresa un motivo',
  reject: 'Descartar',
  saleOpportunitityIsDone: 'Oportunidad de venta usada',
  openLink: 'Enlace abierto',
  ProblemAccessingThePage: 'Problema al acceder a la pagina',
  ProblemAccessingThePageDescription:
    'No se puede acceder a este recurso.\n\nPrueba a acceder de nuevo, si el error persiste contacta con soporte',
  cartDetailScreen: 'Produtos e Categorias',
  pendingOrderCanceled: 'Pedido pendiente cancelada',
  insertBy: 'Inserir por',
  applyDiscount: 'Aplicar descuento',
  goToAllDetail: 'Ver todos os detalhes',
  multipleof: 'Multiplo de',
  noFields: 'No campos',
  SeeAllProducts: 'Todos los productos',
  cannotOpenObservation:
    'Al editar una condición de venta, no es posible agregar notas al pedido',
  errorOnCancelOrder: '¡Error al cancelar el carrito!',
  locationPermissionTitle: 'Permiso de ubicación',
  locationPermissionMessage:
    'Habilite la configuración de ubicación para el seguimiento por GPS.',
  waitALittle: 'Espere un poco más, aún estamos cargando sus datos...',
  clientNotFound: '¡Cliente no encontrado! Cancelando carrito pendiente...',
  share: 'Compartilhar',
  poliInstallerIsNotInstalled: 'PoliInstaller no está instalado',
  doYouWantToInstallNow: '¿Quieres instalar ahora?',
  expenses: 'Gastos',
  expenseDetail: 'Detalles de lanzamiento',
  expensesForm: 'Nuevo lanzamiento',
  expenseSuccess: 'Lanzamiento guardado',
  expensesResume: 'Resumen de lanzamiento',
  theOption: 'la opcíon',
  discardSaleCondition: 'Descartar condicíon de venda',
  noChanges: 'Sin cambios',
  noChangesInForm: 'No hay cambios en el formulario',
  notListConditionsSales: 'No se pueden listar las condiciones de venta',
  noBarCodeFound: 'No se encontraron códigos de barras.',
  barCodeFound:
    '¡Código de barras detectado! Poniéndolo en el campo de búsqueda.',
  errorListExpenses: 'Error al enumerar tus gastos',
  in: 'Entradas',
  out: 'Afuera',
  cancelExpense: 'Cancelar lanzamiento',
  cancelExpenseMessage:
    'Si se cancela este lanzamiento, se perderá la información ingresada.\n\n¿Realmente quieres salir sin guardar?',
  exitWithoutSave: 'Salir sin guardar',
  errorListExpenseTypes: 'No se pueden enumerar los tipos de gastos',
  fieldDate: 'fecha de la nota',
  fieldType: 'Tipo de lanzamiento',
  fieldAmount: 'Valor total de la nota',
  subtractFromNote: 'Restar de la calificación',
  subtractFromNoteValue: 'Restar del valor de la nota',
  subtractValue: 'Valor a restar',
  fieldReason: 'Razón',
  camera: 'Cámara',
  saveExpense: 'Guardar lanzamiento',
  backToExpenses: 'Volver a lanzamientos',
  successSaveExpense: 'Lanzamiento guardado exitosamente',
  paymentDate: 'día de pago',
  place: 'Estabelecimiento',
  adjustValField: 'Ajuste de valor',
  reasonField: 'Motivo del ajuste',
  attachments: 'Archivos adjuntos',
  errorOnCreateExpense: 'Error al crear el gasto',
  emptyExpenses: '¡No hay lanzamientos para exhibir!',
  attachFile: 'Anexar compromiso',
  days: 'Días',
  errorGenerateReport: 'Error al generar informe',
  reportsOfLast: 'Informes del último',
  other: 'Otro',
  balance: 'Balance',
  emptyListToGeneratePdf: '¡No tienes gastos para generar el informe!',
  generateResume: 'Generar resúmenes',
  updateDatas: 'Actualización de datos',
  updateFailure: 'Error de actualización',
  showDetail: 'Ver detalles',
  quickActions: 'Acciones rápidas',
  sendPhotoOnlyWifiDescription:
    'Con esta opción habilitada, las fotos no se enviarán utilizando datos móviles.',
  photos: 'fotos',
  answerObservations: 'Responder observaciones',
  requiredObservations: 'Es obligatorio cumplimentar las observaciones.',
  applySalesConditions: 'Aplicar condiciones de venta',
  changeSalesConditions: 'Cambiar condiciones de venta',
  goToProducts: 'Ir a productos',
  lostChangeSalesCondition: 'Se perderán las condiciones de venta.',
  lostChangeSalesConditionMessage:
    'Cuando regresa sin guardar, pierde los cambios.',
  confirmLostChangeSalesCondition: '¿Estás seguro de que quieres volver?',
  yesBack: 'Si, regressa',
  unavailableConfig: 'Configuración no disponible',
  currentPlanNotAllowed:
    'El plan actual no permite configurar el pedido\nComuníquese con su supervisor.',
  loginRequired: 'Deberás iniciar sesión nuevamente',
  pleaseWait: 'Por favor, aguarde.',
  wePreparingYou: '¡Estamos preparando tudo pra você!',
  searchingYourInfos: 'Buscando sus informaciones...',
  loadingTables: 'Carregando tabelas...',
  organizingYourProducts: 'Organizando sus productos...',
  knowingYourClients: 'Identificando sus clientes...',
  mappingYourRoutes: 'Mapeando sus rotas...',
  unableOpenLink: 'No se puede abrir el enlace',
  waitingData: 'Por favor espera un poco más, estamos cargando los datos...',
  loadingAllProducts: 'Cargando todos los productos...',
  searchForProducts: 'Buscar productos...',
  findedOrders: 'Pedidos encontrados',
  totalFatured: 'Los ingresos totales',
  searchForOrders: 'Buscar pedidos...',
  searchForClients: 'Buscar clientes...',
  orderedBy: 'Ordenado por:',
  dateFrom: 'De',
  dateTo: 'Hasta',
  errorWhenLoadAccounts: 'No se pudieron cargar las cuentas.',
  errorWhenSwapAccount: 'No se pudo cambiar de cuenta.',
  swapAccountTitle: 'Cambiar cuenta',
  swapAccountSubTitle: '¿Realmente quieres cambiar de cuenta?',
  swapAccountDescription:
    'Al cambiar de usuario, se perderán las fotos no sincronizadas.\n\nIngrese la palabra Confirmar en el campo a continuación y haga clic en confirmar. ',
  yesSwap: 'Sí, cambiar',
  swap: 'Cambiar',
  removeAccountTitle: 'Eliminar cuenta',
  removeAccountDescription:
    '¿Desea eliminar esta cuenta de su dispositivo? Al hacerlo, deberá iniciar sesión nuevamente.',
  removeAccountSuccess: 'Cuenta eliminada exitosamente',
  errorOnCheckBase:
    'No se pudo verificar la base de datos. Póngase en contacto con el soporte si el error persiste.',
  errorOnRunLib:
    'No se pudo iniciar el sistema. Póngase en contacto con el soporte si el error persiste.',
  createActivity: 'Crear actividad',
  activityName: 'Nombre de la actividad',
  createActivityTitle: 'Crear actividad dinámica',
  createActivityDescription:
    'La actividad que seleccionaste se utilizará como base para crear una nueva, pero puedes elegir una descripción de tu preferencia para ella.',
  dynamic: 'Dinámica',
  activityCreatedSuccessfully: 'Actividad dinámica creada con éxito.',
  errorWhenCreateActivity:
    'Parece que algo salió mal durante la creación de esta actividad. Por favor, inténtelo nuevamente más tarde.',
  dynamicActivity: 'Actividad dinámica',
  activityCannotCreateWithNoProcess:
    'Solo es posible crear una actividad dinámica después de iniciar el proceso.',
  errorSavingPhoto: 'Se produjo un error al guardar la foto',
  moreTitleCat: 'más',
  categoriesScreen: 'Categorias',
  searchCategories: 'Buscar categorias...',
  errFilterCategories: 'Error al filtrar categorías.',
  noCategories: 'No hay categorías.',
  errOpenCategory: 'Error al abrir la categoría.',
  flightPlan: 'Plan de vuelo',
  flightPlanVerifyError:
    'No fue posible verificar las acciones, si el problema persiste comuníquese con soporte. Cod: PVOO-01',
  totalWithAdjust: 'Total con ajuste',
  listTitlesScreen: 'Seleccione uno o más títulos',
  errorListTitles: 'Error al enumerar los títulos.',
  errorFilterTitles: 'Error al filtrar los títulos.',
  collectionOfReceivables: 'Cuentas por cobrar',
  selectAtLeastOneTitle: 'Seleccione al menos un título.',
  moduleWorkOnlyInternet: 'El módulo solo funciona con Internet.',
  errorExtractTitles: 'Error al extraer los títulos.',
  errorRequest: 'Error al solicitar.',
  ocurredError: 'Ocurrió un error',
  errorProcessFileToSend: 'Error al procesar el archivo para enviar.',
  errorCommunicateWithServer: 'Error al comunicarse con el servidor.',
  maximumPrecisionReached:
    'La exactitud de su ubicación supera el límite permitido. Ajuste la configuración de su GPS para mejorar la precisión y, si el problema persiste, comuníquese con su supervisor.',
  reportingModule:
    'El módulo de informes no tiene almacenamiento ni permisos de archivos y medios',
  errorReportingModule: 'Se produjo un error al generar el informe.',
  reportingModuleNotInstalled: 'Módulo de informes no instalado.',
  poliReportsInstall: 'Instalación de PoliReports',
  noCode: 'Sin código',
  refTitle: 'Título',
  Kg: 'Kg',
  selectProducts: 'Seleccionar Productos',
  selecteds: 'elementos seleccionados',
  multiple: 'Múltiple',
  price: 'Precio',
  table: 'Tabla',
  new: 'Nuevo(a)',
  seeMore: 'Ver más',
  replace: 'Substituir',
  replaceMultipleDescription:
    'Cambia la cantidad actual por la Nueva cantidad multiplicada por el múltiplo del artículo.',
  addMultipleDescription:
    'Suma la cantidad actual del artículo + la nueva cantidad multiplicada por el múltiplo del artículo.',
  replaceDiscountDescription:
    'Cambie el descuento actual del artículo por el nuevo porcentaje de descuento.',
  addDiscountDescription:
    'Agrega descuento del artículo actual + Nuevo porcentaje de descuento',
  replacePriceDescription:
    'Cambie el precio actual del artículo por el precio nuevo.',
  addPriceDescription:
    'Agrega el precio actual del artículo + Nuevo precio del artículo',
  characterInvalid: 'Carácter inválido',
  outdatedData: 'Datos obsoletos',
  outdatedDataMessage:
    'Con la base de datos desactualizada, esta solicitud se guardará como una cotización.',
  outdatedDataDescription:
    'Actualice su base de datos y edite esta solicitud para completalo',
  back: 'Regressa',
  products: 'Productos',
  errorProcessFormData: 'Error al procesar los datos del formulario.',
  couldNotBeOpened: 'No puede ser abierto.',
  errorOpenPage: 'Error al abrir la página.',
  errorLoadPageContactSupport:
    'Hubo un error al cargar la página. Por favor contacte con soporte',
  codeHttpError: 'Código de error HTTP',
  errorMakeUrlPPM: 'Error al crear la URL de PPM.',
  sucessfullyRejectedOpportunity: 'Oportunidad rechazada exitosamente.',
  cannotTrigFlightPlanAlerts: 'No se pueden activar alertas de plan de vuelo',
  openCart: 'Orden abierta',
  errorOnLoadShipping:
    'Se produjo un error al cargar la carga. Si persiste, comuníquese con el soporte.',
  errorOnLoadShippingCompanies:
    'Se produjo un error al cargar los transportadores. Si persiste, comuníquese con el soporte.',
  UnableToLoadingListOfActivities:
    'No se puede cargar la lista de actividades.',
  UnableToOpenActivity:
    'No se puede abrir esta actividad.\nPor favor contacte al soporte.',
  titleNoUpToDate: 'Sin datos de actualización',
  descriptionNoUpToDate:
    '¡Esté siempre actualizado! Asegúrese de sincronizar sus PoliEquipes con el servidor.',
  ok: 'Ok',
  cantLoadProducts: 'No se pudieron cargar los productos. Inténtalo de nuevo',
  reload: 'Recargar',
  doYouReallyWantToCopyTheOrder: '¿Realmente quieres copiar el pedido?',
  howDoYouWantToCopyTheOrder: '¿Cómo quieres copiar el pedido?',
  ErrorProcessingCompanySettingsInformation:
    'Error al procesar la información de configuración de la empresa',
  comboSuccessApplied: 'Combo aplicado exitosamente',
  addItemsToApplyCombo: 'Agregar elementos para aplicar el combo.',
  deleteNotification: 'Eliminar la notificación?',
  doYouReallyWantToDeleteNotification:
    '¿Realmente quieres eliminar la notificación?',
  deleteNotificationSucess: '¡Notificación eliminada con éxito!',
  errorDeleteNotification: 'errorDeleteNotification',
  deleteAllNotitifications: 'Eliminar todas las notificaciones',
  doYouReallyWantdeleteAllNotification:
    '¿De verdad quieres eliminar todas las notificaciones?',
  removeNotification: 'Eliminar notificación',
  deleteAllSucess: '¡Eliminado con éxito!',
  talkingCameNews: 'Te avisaremos cuando lleguen novedades.',
  standardScale: 'Escala estándar',
  errorAddingCustomer: 'Error al agregar cliente',
  errorWhenSetProductActive: 'Error al configurar el producto activo',
  subTotal: 'Subtotal',
  contactOptions: 'Opciones de contacto',
  accessToYourFilesSettings:
    'Accede a la configuración de la aplicación y dale permiso de almacenamiento.',
  authorizedDiscount: 'Descuento autorizado',
  fillingObservationFieldRequired:
    'Rellenando el campo de observación obligatorio',
  requiredPhotos: 'Foto obligatoria',
  incompatible: 'Incompatible con',
  expressionRegularSettings: 'Expresión regular configurada',
  errorOnFieldVerification: 'Se produjo un error al verificar el campo.',
  itinenaryList: 'Lista',
  itinenaryMap: 'Mapa',
  selectImage: 'Seleccionar Imagen',
  choiceOption: 'Elija una opción: ',
  takePhoto: 'Tomar foto',
  choiceImageGallery: 'Elegir de la galería',
  deselectSelected: 'Deseleccionar seleccionado',
  seeCustomersNearTheRoute: 'Ver clientes cerca de la ruta',
  copyCart: 'Copiar una orden',
  attachmentAlert: 'Tamaño máximo por archivo: ',
  attachmentFile: 'Archivo',
  attachmentOpenFolder: 'Abrir carpeta',
  attachmentOpenCamera: 'Abrir la cámara',
  attachmentCamera: 'Cámara',
  ErrorSavingProspects: 'Error al guardar prospects. COD001',

  saleOpportunities: 'Oportunidades de ventas',
  saleOpportunityApproach: 'El promotor informó que este cliente tiene productos con alta probabilidad de compra.\n\n¿Desea agregar estos productos ahora?',
  later: 'Ver después',
  ignoreOpportunity: 'Ignorar oportunidad',
  insertOpportunity: 'Insertar',

  rejectSaleOpportunities: 'Rechazar oportunidades',
  rejectSaleOpportunitiesDescription: "Al hacer clic en 'Rechazar', rechazará {{opportunities}} oportunidad(es).",

  postponeSaleOpportunity: 'Posponer oportunidades',
  postponeSaleOpportunitiesDescription: 'Perderá la oportunidad de vender estos artículos en este pedido. Proporcione a continuación el motivo para no realizar esta venta ahora.\n\nSeguirán disponibles para el próximo pedido.',
  seeOpportunities: 'Ver oportunidades',
  requiredResolveOpportunity: 'Es obligatorio corregir para continuar',
  seeProducts: 'Ver productos',
  typeReason: 'Indique el motivo',
  existsPendingOpportunityProducts: 'Aún existen productos de oportunidad',
  addedItens: 'Artículos agregados',
  cod: 'Cód.: {{code}}',
  removeProduct: 'Eliminar producto',
  includeProduct: 'Incluir producto',
  lowerItens: 'artículos',
  lowerAdded: 'agregados',
  totalValue: 'Total: {{totalValue}}',

  hour: 'Hora',
  hours: 'Horas',
  minute: 'Minuto',
  minutes: 'Minutos',
  second: 'Segundo',
  seconds: 'Segundos',
  day: 'Día',
  days: 'Días',

  theJourneyWillStarts: 'El viaje comenzará en',
  theJourneyWillStartsDescription: 'Prepárate, pronto podrás iniciar tus actividades.',
  intervalStartsIn: 'Tu descanso comienza en {{minutes}} minutos',
  intervalStartsInWarn: 'Atención\n\nTu descanso comienza en 0{{minutes}}:00 minutos.',

  journeyInterval: 'Intervalo de la jornada',
  journeyIntervalDescription: 'Guarde ahora sus actividades o pedidos.\n\nLa APP cambiará a modo de visualización en 1 minuto.',
  iamAgree: 'Estoy al tanto',
  lunchBreak: 'Intervalo',
  lunchBreakDescription: 'Le avisaremos cuando termine el almuerzo.',
  noLunchBreak: 'Sin pausa para el almuerzo',
  noLunchBreakDescription: 'Prepárese, pronto podrá comenzar sus actividades.',
  opportunity: 'Oportunidad',
  journeyEndsIn: 'Su jornada termina en {{minutes}} minutos.',
  journeyEndsInWarn: 'Atención!\n\nSu jornada termina en 0{{minutes}}:00 minutos.',

  journeyEndsTitle: 'Muy bien, jornada finalizada',
  journeyEndsDescription: 'Mantén siempre todo en orden.\nEs importante sincronizar tus datos.',
  seeModules: 'Ver módulos',
  journey: 'Viaje',

  journeyEndsAlert: 'Fin del viaje',
  journeyEndsAlertDescription: 'Guarde sus actividades o solicitudes ahora.\n\nLa APLICACIÓN se cerrará en 1 minuto.',

  cnpjValue: 'CNPJ: {{value}}',
  createdWhen: 'Creada el {{value}}',
  shortCode: 'Cod.',
  ean: 'EAN',

  errorSavingProspects: 'Error al guardar prospects. COD001',
  emptyProspectsMessage: 'Tú no tienes prospectos asociados contigo..',
  errorsOnForm: 'El formulario contiene errores. Por favor, corríjalos y vuelva a intentarlo.',
  dayoffMessage: 'Hoy es un día libre',
  dayoffDescription: 'Elige otro día para ver tus visitas.',
  copyCartScreenTitle: 'Seleccionar pedido',
  osrmError: "No pudimos mostrar las coordenadas de las direcciones en el mapa.",
  loseInformation: "Perder información",
  doYouReallyWantToExit: "¿Realmente deseas salir?",
  onExitInformationWillBeLost: "Al salir, su información se perderá.",
  exitAndLose: "Salir y perder",
  agentMode: "Modo Agente",
  agentModeDescription: "Seleccione un agente para activar el modo y realizar actividades como el agente seleccionado.",
  selectAgent: "Seleccionar agente",
  managementMode: "Modo gestión",
  outOfArea: "Visita fuera del área",
  requestOutOfArea: "Solicitar liberación",
  releaseRequestSuccess: "¡Solicitud de liberación enviada con éxito!",
  releaseRequestError: "Ocurrió un error al solicitar la liberación.",

  clienteBloqueado: "Cliente bloqueado",
  requestBlockedClient: "Solicitar liberación de venta",
  releaseRequestSuccess: "¡Solicitud de liberación de venta enviada con éxito!",
  releaseRequestError: "Ocurrió un error al solicitar la liberación de venta.",
  waitALittleForMakeRequest: 'Su solicitud ha sido registrada. Por favor, espere un momento antes de intentarlo nuevamente.',
  requestDenied: 'Su solicitud ha sido rechazada. Por favor, espere un momento antes de intentarlo nuevamente.',
  changeClientTitle: 'Eliminar productos',
  changeClientSubTitle: 'Al cambiar de cliente, los productos añadidos podrían ser eliminados.',
  changeClientDescription: '¿Desea continuar?',
  changeClient: 'Cambiar cliente',
  important: 'Importante',
  all: 'Todo',
  selectCalendarDate: 'Seleccione una fecha en el calendario para continuar'
};
