/* @flow */

import React from 'react';
import {
  View,
  Text,
  StyleSheet,
} from 'react-native';

import Touchable from 'react-native-platform-touchable';

import ProgressBar from 'react-native-progress/Bar';

import PropTypes from 'prop-types';

import translate from '~/locales';
import { colors } from '../../../components_base/src/values';

const styles = StyleSheet.create({
  textProgress: {
    textAlign: 'center',
  },
  buttonHideProgress: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  textHideProgress: {
    fontWeight: '500',
    fontSize: 14,
    color: colors.primaryColorLight,
  },
});

function ProgressBarModal({
  progress,
  onHideProgress,
  text,
  indeterminate,
  showHideButton,
}) {
  return (
    <View>
      <Text style={styles.textProgress}>
        {text} {!indeterminate ? `${Math.floor(progress)}%` : null}
      </Text>
      
      <ProgressBar
          color={colors.primaryColorLight}
          styleAttr="Horizontal"
          indeterminate={indeterminate}
          progress={progress / 100}
        />

      {showHideButton && (
        <Touchable
          style={styles.buttonHideProgress}
          foreground={Touchable.SelectableBackground()}
          onPress={onHideProgress}>
          <Text style={styles.textHideProgress}>{`${translate('hide')}`}</Text>
        </Touchable>
      )}
    </View>
  );
}

ProgressBarModal.propTypes = {
  progress: PropTypes.number.isRequired,
  onHideProgress: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  indeterminate: PropTypes.bool.isRequired,
  showHideButton: PropTypes.bool.isRequired,
};

export default ProgressBarModal;
