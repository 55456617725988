export const databaseName = 'activities.db3';
export const databaseVersion = 4;
export const databaseDisplayName = 'activities';
export const databaseSize = 200000;

export const SupportedTypes = {
  TEXT: 'text',
  BOOLEAN: 'boolean',
  DATETIME: 'datetime',
  JSON: 'json',
  INTEGER: 'number',
  SYNC_STATUS: { value: 'STATUS', type: 'text', default_value: null },
};

const defaultFields = {
  id: {
    type: SupportedTypes.TEXT,
    primaryKey: true,
    default_value: null,
  },
  created_at: {
    type: SupportedTypes.DATETIME,
    default_value: null,
  },
  updated_at: {
    type: SupportedTypes.DATETIME,
    default_value: null,
  },
  deleted_at: {
    type: SupportedTypes.DATETIME,
    default_value: null,
  },
  deleted: {
    type: SupportedTypes.BOOLEAN,
    default_value: null,
  },
  sync_status: {
    type: SupportedTypes.SYNC_STATUS,
    default_value: 'pending_to_reserve',
  },
  sync_error: {
    type: SupportedTypes.TEXT,
    default_value: null,
  },
};

/* export const Tables = {
  version: {
    version_number: {
      type: SupportedTypes.INTEGER,
    },
  },
  user_task_lists: {
    ...defaultFields,
    execution_start_date: {
      type: SupportedTypes.DATETIME,
      default_value: null,
    },
    execution_end_date: {
      type: SupportedTypes.DATETIME,
      default_value: null,
    },
    status: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    user: {
      type: SupportedTypes.INTEGER,
      default_value: null,
    },
    task_list: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    extra_info: {
      type: SupportedTypes.JSON,
      default_value: null,
    },
  },
  task_lists: {
    ...defaultFields,
    name: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    description: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    created_by: {
      type: SupportedTypes.INTEGER,
      default_value: null,
    },
    company: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    setup: {
      type: SupportedTypes.JSON,
      default_value: null,
    },
  },
  photo_answers: {
    ...defaultFields,
    start_date: {
      type: SupportedTypes.DATETIME,
      default_value: null,
    },
    end_date: {
      type: SupportedTypes.DATETIME,
      default_value: null,
    },
    origin_date: {
      type: SupportedTypes.DATETIME,
      default_value: null,
    },
    extra_info: {
      type: SupportedTypes.JSON,
      default_value: null,
    },
    payload: {
      type: SupportedTypes.JSON,
      default_value: null,
    },
    created_by: {
      type: SupportedTypes.INTEGER,
      default_value: null,
    },
    user_task_list: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    task: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
  },
  answers: {
    ...defaultFields,
    start_date: {
      type: SupportedTypes.DATETIME,
      default_value: null,
    },
    end_date: {
      type: SupportedTypes.DATETIME,
      default_value: null,
    },
    status: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    sequence: {
      type: SupportedTypes.INTEGER,
      default_value: 0,
    },
    latitude: {
      type: SupportedTypes.DOUBLE,
      default_value: null,
    },
    longitude: {
      type: SupportedTypes.DOUBLE,
      default_value: null,
    },
    extra_info: {
      type: SupportedTypes.JSON,
      default_value: null,
    },
    payload: {
      type: SupportedTypes.JSON,
      default_value: null,
    },
    created_by: {
      type: SupportedTypes.INTEGER,
      default_value: null,
    },
    user_task_list: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    task: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
  },
  config: {
    ...defaultFields,
    display_name: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    url_stocker: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    token_stocker: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    logo: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    company: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    modules: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
  },
  custom_lists: {
    ...defaultFields,
    company: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    context: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    setup: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
  },
}; */

export const Tables = {
  version: {
    version_number: { type: SupportedTypes.INTEGER },
  },
  task_lists: {
    ...defaultFields,
    name: { type: SupportedTypes.TEXT },
    description: { type: SupportedTypes.TEXT },
    created_by: { type: SupportedTypes.INTEGER },
    company: { type: SupportedTypes.TEXT },
    setup: { type: SupportedTypes.JSON },
  },
  user_task_lists: {
    ...defaultFields,
    id: { type: SupportedTypes.TEXT, primaryKey: true, autoIncrement: true },
    execution_start_date: { type: SupportedTypes.DATETIME },
    execution_end_date: { type: SupportedTypes.DATETIME },
    status: { type: SupportedTypes.TEXT },
    user: { type: SupportedTypes.INTEGER },
    task_list: { type: SupportedTypes.TEXT },
    extra_info: { type: SupportedTypes.JSON },
  },
  photo_answers: {
    ...defaultFields,
    id: { type: SupportedTypes.TEXT, primaryKey: true, autoIncrement: true },
    start_date: { type: SupportedTypes.DATETIME },
    end_date: { type: SupportedTypes.DATETIME },
    origin_date: { type: SupportedTypes.DATETIME },
    extra_info: { type: SupportedTypes.JSON },
    payload: { type: SupportedTypes.JSON },
    created_by: { type: SupportedTypes.INTEGER },
    user_task_list: { type: SupportedTypes.TEXT },
    task: { type: SupportedTypes.TEXT },
  },
  answers: {
    ...defaultFields,
    id: { type: SupportedTypes.TEXT, primaryKey: true, autoIncrement: true },
    start_date: { type: SupportedTypes.DATETIME },
    end_date: { type: SupportedTypes.DATETIME },
    status: { type: SupportedTypes.TEXT },
    sequence: { type: SupportedTypes.INTEGER },
    latitude: { type: SupportedTypes.DOUBLE },
    longitude: { type: SupportedTypes.DOUBLE },
    extra_info: { type: SupportedTypes.JSON },
    payload: { type: SupportedTypes.JSON },
    created_by: { type: SupportedTypes.INTEGER },
    user_task_list: { type: SupportedTypes.TEXT },
    task: { type: SupportedTypes.TEXT },
  },
  config: {
    ...defaultFields,
    id: { type: SupportedTypes.TEXT, primaryKey: true, autoIncrement: true },
    display_name: { type: SupportedTypes.TEXT },
    url_stocker: { type: SupportedTypes.TEXT },
    token_stocker: { type: SupportedTypes.TEXT },
    logo: { type: SupportedTypes.TEXT },
    company: { type: SupportedTypes.TEXT },
    modules: { type: SupportedTypes.TEXT },
  },
  custom_lists: {
    ...defaultFields,
    id: { type: SupportedTypes.TEXT, primaryKey: true, autoIncrement: true },
    company: { type: SupportedTypes.TEXT },
    context: { type: SupportedTypes.TEXT },
    setup: { type: SupportedTypes.TEXT },
  },
};

export const Classes = {
  version: class Version {
    constructor({ version_number }) {
      this.version_number = version_number;
    }
  },
  task_lists: class TaskList {
    constructor({ name, description, created_by, company, setup }) {
      this.name = name;
      this.description = description;
      this.created_by = created_by;
      this.company = company;
      this.setup = setup;
    }
  },
  user_task_lists: class UserTaskList {
    constructor({
      execution_start_date,
      execution_end_date,
      status,
      user,
      task_list,
      extra_info,
    }) {
      this.execution_start_date = execution_start_date;
      this.execution_end_date = execution_end_date;
      this.status = status;
      this.user = user;
      this.task_list = task_list;
      this.extra_info = extra_info;
    }
  },
  photo_answers: class PhotoAnswer {
    constructor({
      start_date,
      end_date,
      origin_date,
      extra_info,
      payload,
      created_by,
      user_task_list,
      task,
    }) {
      this.start_date = start_date;
      this.end_date = end_date;
      this.origin_date = origin_date;
      this.extra_info = extra_info;
      this.payload = payload;
      this.created_by = created_by;
      this.user_task_list = user_task_list;
      this.task = task;
    }
  },
  answers: class Answer {
    constructor({
      start_date,
      end_date,
      status,
      sequence,
      latitude,
      longitude,
      extra_info,
      payload,
      created_by,
      user_task_list,
      task,
    }) {
      this.start_date = start_date;
      this.end_date = end_date;
      this.status = status;
      this.sequence = sequence;
      this.latitude = latitude;
      this.longitude = longitude;
      this.extra_info = extra_info;
      this.payload = payload;
      this.created_by = created_by;
      this.user_task_list = user_task_list;
      this.task = task;
    }
  },
  config: class Config {
    constructor({
      display_name,
      url_stocker,
      token_stocker,
      logo,
      company,
      modules,
    }) {
      this.display_name = display_name;
      this.url_stocker = url_stocker;
      this.token_stocker = token_stocker;
      this.logo = logo;
      this.company = company;
      this.modules = modules;
    }
  },
  custom_lists: class CustomList {
    constructor({ company, context, setup }) {
      this.company = company;
      this.context = context;
      this.setup = setup;
    }
  },
};
