import React from 'react';
import { View, FlatList } from 'react-native';
import {
  Placeholder,
  PlaceholderMedia,
  PlaceholderLine,
  Fade,
} from 'rn-placeholder';

import colors from '../../../common/color';

const backgroundGray = {
  backgroundColor: colors.gray,
};

const app = {
  ...backgroundGray,
  paddingHorizontal: 24,
  paddingVertical: 15,
  borderBottomColor: colors.primaryColorTable,
  borderBottomWidth: 2,
  flexDirection: 'row',
  alignItems: 'center',
};

const badgeContainer = {
  width: 32,
  height: 32,
  borderRadius: 16,
  backgroundColor: colors.primaryColorTable,
};

const divide = {
  marginLeft: 7,
  flex: 1,
};

const lineId = {
  backgroundColor: colors.primaryColorTable,
  marginLeft: 7,
  width: 40,
};

const lineName = {
  backgroundColor: colors.primaryColorTable,
  marginLeft: 7,
  width: '90%',
  height: 50,
};

const lineSubName = {
  backgroundColor: colors.primaryColorTable,
  marginLeft: 16,
  width: '70%',
};

const iconBox = {
  width: 12,
  height: 12,
  backgroundColor: colors.primaryColorTable,
  left: 5,
};

const iconPoint = {
  backgroundColor: colors.primaryColorTable,
  width: 5,
  height: 20,
  right: 3,
};

const iconStatus = {
  backgroundColor: colors.primaryColorTable,
  top: 8,
  width: 12,
  height: 12,
  borderRadius: 6,
  alignSelf: 'center',
};

export default function ClientsModulesPlaceholder() {
  return (
    <Placeholder Animation={Fade}>
      <FlatList
        data={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
        keyExtractor={() => Math.random()}
        renderItem={() => (
          <View style={app}>
            <View>
              <PlaceholderMedia style={badgeContainer} />
              <PlaceholderMedia style={iconStatus} />
            </View>
            <View style={divide}>
              <PlaceholderLine style={lineId} />
              <PlaceholderLine style={lineName} />
              <View style={{ flexDirection: 'row' }}>
                <PlaceholderLine style={iconBox} />
                <PlaceholderLine style={lineSubName} />
              </View>
            </View>
            <View>
              <PlaceholderLine style={iconPoint} />
            </View>
          </View>
        )}
      />
    </Placeholder>
  );
}
