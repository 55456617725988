import color from './color';
import Get from './Get';
import style from './style';
import Money from './Money';
import measures from './measures';
import size from './size';

const defaultShadow = {
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 3,
  },
  shadowOpacity: 0.27,
  shadowRadius: 4.65,
  elevation: 6,
};

export {
  color,
  style,
  Get,
  Money,
  measures,
  size,
  defaultShadow,
};
