import AsyncStorage from '@react-native-async-storage/async-storage';
import CredentialsCache from '~/utils/CredentialsCache';

class CacheAccount {
  async setDataAccount(data) {
    await AsyncStorage.setItem('@Session:dataAccount', JSON.stringify(data));

    CredentialsCache.addAccountCredentials(data);
  }

  async getDataAccount() {
    const data = await AsyncStorage.getItem('@Session:dataAccount');
    return JSON.parse(data);
  }

  async setUserDevice(data) {
    await AsyncStorage.setItem('@Session:userDevice', JSON.stringify(data));
  }

  async getUserDevice() {
    const data = await AsyncStorage.getItem('@Session:userDevice');
    return JSON.parse(data);
  }
}

export default CacheAccount;
