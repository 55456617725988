import { StyleSheet } from 'react-native';
import { color } from '~/common';
const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    paddingHorizontal: 10,
    paddingVertical: 16,
    borderTopWidth: 1,
    borderLeftWidth: 8,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderRadius: 4,
    marginVertical: 10,
  },
  text: {
    maxWidth: 450,
    fontSize: 14,
    color: color.neutral900,
  },
  strip: {
    width: 12,
    height: 24,
    position: 'absolute',
    top: 14,
    left: 14,
    bottom: 0,
    borderRadius: 10,
    marginTop: 6,
  },
});

export default styles;
