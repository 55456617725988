export default {
  TEXT: { name: 'STRG' },
  NUMBER: { name: 'NUMERO' },
  CNPJ: { name: 'CNPJ' },
  CPF: { name: 'CPF' },
  ZIP_CODE: { name: 'CEP' },
  MONEY: { name: 'DINHEIRO' },
  PERCENTAGE: { name: 'PERCENTUAL' },
  PHONE: { name: 'TELEFONE' },
  DATE: { name: 'DATE' },
  HOUR: { name: 'HORARIO' },
  DATE_HOUR: { name: 'DATA_HORARIO' },
  LIST: { name: 'LIST' },
};
