export default {
  AccessSolicitation: 'Solicitação de Acesso',
  app_name: 'PoliEquipes App',
  Attributes: 'Atributos',
  CampaignReceived: 'Nova Promoção!',
  cancel: 'Cancelar',
  Characteristics: 'Características',
  Choose_Filters: 'Escolher Filtros',
  complementary: 'Complementar',
  conditionsSalesChild: 'Condições de Venda Filhas',
  hello: 'Olá',
  moreProductsScreen: 'Mais Produtos',
  no: 'Não',
  noOpenFilePDF: 'Não é possivel abrir o arquivo pdf.',
  normal: 'Normal',
  NotifyProductReceived: 'O produto que você queria chegou',
  chooseCopyType: 'Copiar pedido',
  questionCopyComplementary: 'Que tipo de cópia deseja realizar?',
  shipping: 'Frete',
  shippingCompany: 'Transportadora',
  shippingEstimate: 'Cálculo de frete',
  lostShippingMessage:
    'Se você sair, irá perder as informações de frete que já foram calculadas',
  stay: 'Ficar',
  connectionError: 'Erro de conexão.',
  errorListSC: 'Não foi possível listar as transportadoras.',
  recalculateShipping: 'Recalcular frete',
  priceOrderChangeMSG:
    'O preço total do pedido foi alterado!. \nÉ necessário recalcular o frete.',
  recalculate: 'Recalcular',
  registerAgain: 'Recadastro',
  creditAdjustment: 'Atualizar cadastro',
  register: 'cadastro',
  activities: 'atividades',
  waitForSync: 'Aguardando sincronização',
  syncBackground:
    'Sincronização executando em segundo plano, aguarde a sincronização ser finalizada.',
  yes: 'Sim',
  yesAll: 'Sim para todos',
  yesSave: 'Sim, salvar',
  yesConfirm: 'Sim, confirmar',
  yesRemove: 'Sim, remover',
  noRemove: 'Não, remover',
  yesDiscard: 'Sim, descartar',
  yesCancel: 'Sim, cancelar',
  yesClean: 'Sim, limpar',
  yesApply: 'Sim, aplicar',
  SolicitateAccess: 'Solicitar acceso',
  Stores: 'Lojas',
  Orders: 'Pedidos',
  order_successful: 'Pedido realizado com sucesso!',
  Notifications: 'Notificações',
  NotificationsDetail: 'Detalhe da notificação',
  noNotifications: 'Sem notificações',
  Registrations: 'Cadastros',
  priceNotAvailable: 'Preço indisponível',
  productNotAvailable: 'Produto indisponível',
  Security: 'Segurança',
  Customers: 'Clientes',
  Profile: 'Perfil',
  Pay: 'Pagar',
  Buy: 'Comprar',
  Confirm_Buy: 'Concluir Pedido',
  Confirm: 'Confirmar',
  Register: 'Cadastrar',
  continue: 'Continuar',
  Close: 'Fechar',
  Confirm_your_Buy: 'Confirme sua compra',
  Cart: 'Carrinho',
  Promotions: 'Promoções',
  Sort: 'Ordenar',
  Home: 'Início',
  My_Cards: 'Meus Cartões',
  Settings: 'Configurações',
  My_Stores: 'Lojas Visitadas',
  Near_Stores: 'Lojas Próximas',
  See_All: 'Ver Todos',
  Search_For: 'Buscar',
  search: 'Buscar...',
  syncInBackground: 'Sincronizando em segundo plano',
  StoreSolicitation: 'Solicitar acesso',
  See_on_Map: 'Ver no Mapa',
  Enter_or_SignUp: 'Entre ou Cadastre-se',
  Enter_with_your_credentials: 'Entre com suas credenciais',
  logOut: 'Sair',
  youReallyWantLeave: 'Deseja realmente sair?',
  really_confirm_order:
    'Ao clicar em Confirmar seu pedido será enviado e você não poderá mais alterá-lo. \nDeseja confirmar?',
  Order: 'Pedido',
  no_orders_found: 'Nenhum pedido encontrado',
  no_campaigns_found: 'Nenhuma campanha encontrado',
  noStores: 'Nenhuma loja.',
  noStoresMaybeSearch:
    'Talvez você não possua acesso a nenhuma loja. \nTente buscar uma loja e solicitar o acesso na aba "Busca"',
  noStoresUseSearch:
    'Digite o nome da loja no campo "Buscar lojas..." e clique em "Buscar" para encontrar lojas.',
  toAccessStore:
    'Para acessar os produtos da loja e fazer pedidos, esta loja precisa aceitar você como cliente. \n\nPara solicitar seu acesso, clique no botão "Solicitar Acesso", digite o CNPJ ou CPF da empresa pela qual você quer fazer pedidos e confirme.',
  Solicitations: 'Solicitações',
  Show_QRCode: 'Exibir QRCode',
  Change_Password: 'Alterar Senha',
  MyWallet: 'Minha Carteira',
  INFORMATION: 'INFORMAÇÕES',
  Payment_Term: 'Prazo',
  Payment: 'Pagamento',
  Buyer: 'Comprador',
  Conditions: 'Condições',
  IntegrationStatus: 'Status de Integração',
  PaymentStatus: 'Status de Pagamento',
  original_price: 'preço original',
  Messages: 'Mensagens',
  items: 'Itens',
  Identity: 'Identidade',
  viewCart: 'Ver carrinho',
  syncInfo: 'Sincronizando informações',
  contactingTheServer: 'Entrando em contato com o servidor',
  weFoundYourInformation: 'Encontramos suas informações',
  completingTheSettings: 'Concluindo as configurações',
  weAreSendingYourInformations: 'Estamos enviando suas informações',
  weAreUpdatingYourData:
    'Aguarde mais um pouco! Ainda estamos atualizando seus dados.\n\nAcompanhe o andamento da sua atualização no menu Perfil.',
  WeAreBringingYourInformation: 'Estamos trazendo suas informações',
  BasicInfo: 'Informações Básicas',
  aditionalInformations: 'Informações adicionais',
  EnterYourName: 'Seu nome',
  ExhibitionName: 'Nome de Exibição',
  identityNumber: 'CPF',
  cnpj: 'CNPJ',
  name: 'Nome',
  CompleteName: 'Nome Completo',
  QR_CODE: 'QR CODE',
  QR_Code: 'QR Code',
  Info: 'Info',
  Information: 'Informação',
  Description: 'Descrição',
  ComplementaryInformations: 'Informações Complementares',
  informations: 'Informações',
  details: 'Detalhes',
  Alerts: 'Alertas',
  My_Orders: 'Meus Pedidos',
  Create_Route: 'Criar Rota',
  created_at: 'Criado em',
  Store: 'Loja',
  Campaigns: 'Campanhas',
  Category: 'Categoria',
  Categories: 'Categorias',
  Campaign: 'Campanha',
  Filter: 'Filtrar',
  Filter_By_Category: 'Filtrar por Categoria',
  cleanFilter: 'Limpar Filtro',
  login: 'Login',
  tryAgain: 'Tentar Novamente',
  noMessages: 'Sem Mensagens',
  emptyCart: 'Seu carrinho está vazio',
  Open: 'Aberto',
  Waiting_Acceptance: 'Aguardando Aprovação',
  Waiting_Response: 'Aguardando Resposta',
  wait: 'Aguarde',
  Canceled: 'Cancelado',
  Done: 'Concluído',
  Expired: 'Expirado',
  Show_Archived: 'Exibir Arquivadas',
  Only_Near_Stores: 'Somente lojas próximas',
  Send: 'Enviar',
  Address: 'Endereço',
  Street: 'Rua',
  Number: 'Número',
  City: 'Cidade',
  District: 'Bairro',
  Complement: 'Complemento',
  ZipCode: 'CEP',
  Enter: 'Entrar',
  EditProfile: 'Editar Perfil',
  ENTER: 'ENTRAR',
  Email: 'Email',
  password: 'Senha',
  NewPassword: 'Nova Senha',
  CardNumber: 'Número do Cartão',
  CardCPF: 'CPF do Titular (só números)',
  CardHolder: 'Nome do Titular (como no cartão)',
  CardValidDate: 'Validade (Ex: 01/2018)',
  CVV: 'CVV',
  AddCard: 'Adicionar Cartão',
  CardInfo: 'INFORMAÇÕES DO CARTÃO',
  TypeNewPasswordAndConfirm: 'Digite a nova senha e confirme.',
  Confirm_Password: 'Confirmar Senha',
  Create: 'Criar',
  add: 'Adicionar',
  titles: 'Títulos',
  Choose: 'Escolher',
  phone: 'Telefone',
  phones: 'Telefones',
  Cancel: 'Cancelar',
  status: 'Status',
  State: 'Estado',
  Minimum: 'Mínimo',
  Maximum: 'Máximo',
  initial: 'Inicial',
  addition: 'Adição',
  reduction: 'Redução',
  taxInfo: 'Info sobre impostos',
  priceScale: 'ESCALA DE PREÇOS',
  scale: 'Escala',
  taxes: 'IMPOSTOS',
  Visited_Stores: 'Lojas Visitadas',
  no_near_store_found: 'Nenhuma loja próxima encontrada',
  no_title: 'Sem Título',
  didnt_visit_any_store: 'Você ainda não visitou nenhuma loja',
  loading_visited_stores: 'Carregando lojas visitadas',
  loading_near_stores: 'Carregando lojas próximas',
  loading: 'Carregando',
  loadingStores: 'Carregando lojas...',
  loadingCustomers: 'Carregando cadastros...',
  loadingCart: 'Carregando carrinho...',
  not_informed: 'Não Informado',
  total: 'Total',
  save: 'Salvar',
  from: 'A partir de',
  Price_Conditions: 'Condições de preço',
  you_nedd_to_set_email_and_password: 'Você precisa digitar email e senha.',
  Companies_You_Can_Buy_From: 'Empresas de onde você pode comprar',
  error_login: 'Ocorreu um erro ao realizar a autenticação',
  error_load_categories: 'Ocorreu um erro ao carregar as categorias',
  error_load_stores: 'Ocorreu um erro ao carregar as lojas',
  error_load_customers: 'Ocorreu um erro ao carregar os cadastros',
  error_load_orders: 'Ocorreu um erro ao carregar os pedidos',
  YouWillBeNotified: 'Remover produto da lista de espera',
  NotifyMe: 'Avise-me quando chegar',
  version: 'Versão',
  versionUpdate: 'Atualização de versão',
  update: 'Atualizar',
  updateLater: 'Atualizar Depois',
  lastDataUpdate: 'Última atualização de dados',
  lastDataUpload: 'Último envio de dados',
  today: 'Hoje',
  moreInfo: 'Mais informações',
  image: 'Imagem',
  goTo: 'Ir até',
  newRegister: 'Novo Cadastro',
  yourLocation: 'Sua localização',
  yourCurrentLocation: 'Sua localização atual',
  message: 'Mensagem',
  unabledLocation: 'Localização desativada.\n Clique aqui para ativar.',
  errorRadiusClientRequiredGPS: 'Raio do cliente não está definido.',
  errorPointRequiredGPS:
    'Não foi possivel obter sua localização. Coleta de ponto obrigatória.',
  errorAreaClientGPS: 'Você está distante da área do cliente',
  outOfPlace: 'Você não está no local',
  inPlace: 'Você está no local',
  inThisPlace: 'Você está neste local',
  location: 'Localização',
  openActivity: 'Abrir atividade',
  atention: 'Atenção',
  changeLocation: 'Alterar localização',
  cantSaveAnswer: 'Não foi possível salvar esta atividade.',
  pleaseTryAgain: 'Tente novamente',
  title: 'Titulos',
  or: 'ou',
  and: 'e',
  before: 'antes',
  pluralAddress: 'Endereços',
  totalLimitCredit: 'Limite de crédito total',
  openCreditLimit: 'Limite de crédito em aberto',
  creditLimitBalance: 'Saldo do limite de crédito',
  companyName: 'Nome da Empresa',
  company: 'Razão Social',
  code: 'Código',
  fantasy: 'Fantasia',
  contact: 'Contato',
  change: 'Mudança',
  reason: 'Razão',
  utterance: 'Emissão',
  dueDate: 'Vencimento',
  portion: 'Parcela',
  noCustomerLocation: 'Cliente sem localização cadastrada.',
  updatedAt: 'Atualizado em',
  precisionOf: 'Precisão de',
  copy: 'Copiar',
  orderStartedAt: 'Pedido realizado em',
  situation: 'Situação',
  edit: 'Editar',
  delete: 'Deletar',
  yesDelete: 'Sim, deletar',
  dataUpdate: 'Atualização de Dados',
  sendPhotoOnlyWifi: 'Enviar fotos somente via WiFi',
  avaliate: 'Avaliar',
  takePicture: 'Tirar Foto',
  selectFromGallery: 'Selecionar da galeria',
  theFieldsAreRequired: 'Os campos são obrigatórios',
  putTheValue: 'Insira o valor',
  dateRangeBeforeError: 'Insira uma data anterior a final.',
  dateRangeAfterError: 'Insira uma data posterior a inicial.',
  beginDate: 'Data início',
  endDate: 'Data fim',
  generateReport: 'Gerar Relatório',
  youDontHaveFilters: 'Você não possui filtros.',
  salesman: 'Vendedor',
  released: 'Liberado',
  clientBlocked: 'Cliente Bloqueado',
  noCustomersThisDay: 'Sem clientes para este dia',
  noCustomers: 'Nenhum cliente encontrado',
  saving: 'Salvando...',
  noItems: 'Sem itens',
  registeredAt: 'Cadastrado em',
  needCatchPhotoBeforeAndAfter:
    'É necessário capturar foto de antes e depois para salvar.',
  putSomeDataToSave: 'Preencha alguma informação para salvar esta atividade',
  productName: 'Nome do Produto',
  selectADate: 'Selecione uma data',
  indeterminatedPlace: 'Local indeterminado',
  kilometers: 'Quilômetros',
  kilometer: 'Quilômetro',
  meter: 'metro',
  meters: 'metros',
  locationDistance: 'Você está a',
  required: 'Obrigatório',
  nonRequired: 'Não obrigatório',
  process: 'Processo',
  hide: 'Esconder',
  discard: 'Descartar',
  wantSave: 'Deseja salvar?',
  wantSaveTime: 'Deseja salvar o tempo?',
  exportToPDF: 'Visualizar PDF',
  saveStopwatchRunning:
    '\nO tempo capturado não foi salvo.\nAo sair sem salvar o tempo será perdido.',
  stopwatchPaused: 'Cronômetro pausado',
  errorSaveStopwatch:
    'É necessário capturar o tempo de atividade antes de salvar!',
  openSettings: 'Abrir Configurações',
  exportPDFOrder: 'Exportação de pedido PDF',
  invalidExportPDFOrder:
    'Não foi possivel exportar o pedido em PDF.\n\nConfiguração de exportação de pedido em PDF está inválida.',
  messageAccesCameraTitle: 'Permissão para usar a câmera',
  messageAccesCamera: 'Para continuar, precisamos de acesso a sua câmera',
  messageAccesLocation:
    'Para continuar, precisamos de acesso a sua localização',
  messageAccessCameraAndLocation:
    'Para continuar, precisamos de acesso a sua câmera e localização',
  messageAccesCameraLocationAndFiles:
    'Para continuar, precisamos de acesso a sua câmera, arquivos e mídia',
  messageAccessPhotosAndVideos:
    'Para continuar, precisamos de acesso às suas fotos e vídeos.',
  discardChanges: 'Descartar alterações',
  discardChangesInForm:
    'Alguns campos estão preenchidos.\nAo clicar em descartar suas alterações serão perdidas.\n\nDeseja descartar?',
  discardRegistration: 'Descartar cadastro',
  discardChangesInFormAddClient:
    '\nO cadastro do cliente não foi salvo.\nAo sair sem salvar, as informações serão perdidas.\n\nDeseja sair e descartar o cadastro?',
  fillSchedulingDate: 'Por favor preencher a data de agendamento',
  changesSavedSuccesfully: 'Alterações salvas com sucesso.',
  pendingCheckoutInClient: 'Check-out pendente no cliente',
  answerToContinue: 'Responda para continuar',
  errorActivityInProgress:
    'Em andamento. Finalize a mesma para acessar outras atividades.',
  activitiesProgress: 'Progresso de Atividades',
  processWithoutDescription: 'Processo sem descrição',
  unnamedProcess: 'Processo sem nome',
  timerAlreadyRegistered: 'Tempo já registrado!\n Não é possível editá-lo.',
  activitiesSequenceRequired: 'Responda as atividades na sequência',
  itsNecessaryToAnswer: 'É preciso responder',
  cantFindTaskDependency: 'Não foi encontrada a atividade dependente.',
  contactSupport: 'Entre em contato com o suporte',
  taskAlreadyAnswered: 'Essa atividade já foi respondida',
  competitorAchievement: 'Conquista do concorrent',
  campaingTitle: 'Título da campanha',
  campaingNumber: 'Campanha número',
  addClient: 'Adicionar Cliente',
  noProducts: 'Sem produtos',
  minPrice: 'Menor Preço',
  removeSelectedItems: 'Remover itens selecionados',
  saveBudget: 'Salvar orçamento',
  saveOrderAsBudget:
    'Não é possível voltar sem finalizar o pedido ou salvar como orçamento.\n\nDeseja salvar este carrinho como orçamento?',
  cancelOrder: 'Cancelar pedido',
  wantDiscardYourChanges:
    'O seu pedido não será salvo e todos os itens deste pedido serão descartados.\n\nDeseja cancelar o pedido?',
  wantRemoveImage:
    'A foto selecionada será removida.\n\nDeseja realmente remover a sua foto?',
  removeImage: 'Remover foto',
  wantRemoveSelectedItems:
    'Você deseja realmente remover os itens selecionados?',
  yourChangesWillBeLost:
    'Ao retornar suas alterações serão perdidas. Quer voltar mesmo assim?',
  orderBy: 'Ordenar Por',
  saleHistory: 'Histórico de vendas',
  productsSuggestion: 'Sugestão de produtos',
  noProductsAndCategories: 'Você ainda não possui produtos e categorias',
  newCart: 'Novo Carrino',
  alreadyExistsOpenedCart:
    'Já existe um carrinho aberto. Finalize seu carrinho!',
  inStock: 'Em estoque',
  inStockLCase: 'em estoque',
  stock: 'Estoque',
  noStock: 'Sem Estoque',
  unavailableCombos: 'Combos não disponíveis',
  noFinancialTitlesEnvironmental: 'Sem títulos financeiros ambientais',
  ascending: 'Crescente',
  descending: 'Decrescente',
  noFinancingTitles: 'Sem títulos financeiros.',
  financing: 'Financeiro',
  goToHome: 'Ir para o início',
  succesfullyFinishOrder: 'Seu pedido foi concluído com sucesso!',
  noInformation: 'Nenhuma Informação.',
  orderPending: 'Encontramos um pedido pendente',
  client: 'Cliente',
  hasPendingOrder: 'Existe um pedido pendente',
  saveOrderBudget: 'Salvar pedido como orçamento',
  doYouWantDeleteOrder: 'Deseja realmente excluir o pedido?',
  onceConfirmAction: 'Após confirmar, esta ação não poderá ser desfeita.',
  decideLater: 'Decidir depois',
  yesDeletePendingOrder: 'Sim, excluir',
  wantContinueEditingOrder: `Temos tudo sob controle, recuperamos 
o pedido para você.`,
  resolvingPendingOrder:
    'Para iniciar um novo pedido é necessário resolver esta pendência. O que deseja fazer?',
  deleteOrder: 'Excluir pedido',
  restoreOrder: 'Restaurar pedido',
  cantFindModule: 'Não foi possível encontrar o módulo: ',
  doDayClosure: 'Fazer fechamento do dia',
  wantToDoDayClosure: 'Você deseja realmente fazer o fechamento do dia?',
  needToDoLoginAgain: 'Você precisa fazer login novamente.',
  sendingFailed: 'Falha no envio',
  failedToSendFile: 'Falha ao enviar o arquivo!',
  moduleNotFound: 'Módulo não encontrado',
  ediModuleNotFound: 'Módulo EDI não encontrado!',
  youDontHaveModules: 'Você não possui módulos.',
  incentiveAvailable: 'Incentivo disponível',
  applyIncentive: 'Deseja aplicar o incentivo?',
  incentiveApplied: 'Incentivo já aplicado!',
  file: 'Arquivo',
  upUntil: 'Até',
  deleteSyncronizedPhotoToFreeSpace:
    'Apague fotos já sincronizadas para liberar um espaço de: ',
  invalidTokenLogin: 'Token de acesso inválido',
  typeLoginAndPasswordToLogin: 'Digite email e senha para fazer login',
  authenticating: 'Autenticando...',
  loginEnter: 'ENTRAR',
  somePointsNeedsYourAtention: 'Alguns pontos precisam da sua atenção!',
  observationAdded: 'Observações salvas com sucesso',
  thereAreNotObservationsToThisProduct:
    'Não existem observações para este produto',
  observations: 'Observações',
  orderNumber: 'Número do pedido',
  date: 'Data',
  dateToSend: 'Data para envio',
  customer: 'Cliente',
  billedItems: 'Itens faturados',
  partial: 'Parcial',
  partialBilled: 'Parcial Faturado',
  liquid: 'Líquido',
  billedLiquid: 'Líquido Parcial',
  taxSubstituiton: 'Substituição tributária',
  IPI: 'IPI', // dúvida
  amountOfBoxes: 'Quantidade de caixas',
  amountOfBilledBoxes: 'Quantidade de caixas faturadas',
  salesOrganization: 'Organização de venda',
  salesType: 'Tipo de Venda',
  salesTypeInvalid: 'Tipo de Venda inválido',
  salesCondition: 'Condições de venda',
  salesConditionInvalid: 'Condição de venda inválida',
  selectLeastOneSaleCondition: 'Selecione ao menos uma condição de venda',
  middle: 'Meio',
  middleInvalid: 'Meio Inválido',
  paymentOptions: 'Meios de Pagamento',
  priceTable: 'Tabela de preço',
  priceTableInvalid: 'Tabela de preço inválida',
  funds: 'Verba',
  promptDelivery: 'Pronta Entrega',
  gifts: 'Brindes',
  deadline: 'Prazo',
  deadlineInvalid: 'Prazo Inválido',
  budget: 'Orçamento',
  errorCopyOrder: 'Erro ao copiar pedido.',
  thereAreAOpenedClientOrCart: 'Já existe um cliente ou carrinho aberto.',
  deleteCart: 'Apagar carrinho',
  wantDeleteThisCart: 'Tem certeza que quer apagar este carrinho?',
  afterDeleteCanNotGoback:
    'Depois de apagar, você não poderá desfazer esta ação.',
  select: 'Selecione',
  requestedQuantity: 'Quantidade solicitada',
  billedQuantity: 'Quantidade faturada',
  requestedPrice: 'Preço solicitado',
  billedPrice: 'Preço faturado',
  requestedTotal: 'Total solicitado',
  billedTotal: 'Total faturado',
  discountRequested: 'Desconto solicitado',
  billedDiscount: 'Desconto Faturado',
  discount: 'Desconto',
  increase: 'Acréscimo',
  noneFiles: 'Sem Arquivos',
  dateSave: 'Data Salvo',
  printDate: 'Data de impressão',
  removeItem: 'Remover item',
  conditionsPriceTerm: 'Condições de Preço',
  noPriceTermConditions: 'Sem Condições de Preço e Prazo',
  remove: 'Remover',
  contains: 'Contém',
  exitPoliEquipes: 'Sair do PoliEquipes',
  lostChangesOnExit: 'Ao sair suas alterações serão perdidas',
  lostChangesOnExitAccount:
    'Ao sair as fotos não sincronizadas serão perdidas. \n\nDigite a palavra Confirmar no campo abaixo e clique em confirmar.',
  errorExitPoliEquipes: 'Erro ao sair do PoliEquipes',
  synchronize: 'Sincronizar',
  swapAccount: 'Trocar de conta',
  noneProspects: 'Nenhum prospect encontrado',
  printManagerNotInstaller: 'Módulo de Impressão não instalado.',
  noneReports: 'Sem relatórios',
  finishOrder: 'Concluir Pedido',
  clickConfirmToSendYourOrder:
    'Ao clicar em confirmar seu pedido será enviado e você não poderá mais alterá-lo.\n\nDeseja confirmar?',
  noneRewards: 'Não há recompensas disponíveis',
  processBlocked:
    'Já existe um processo iniciado em outro cliente. Finalize as atividades do processo já iniciado.',
  yourVersionMustBeUpdated: 'Sua versão deve ser atualizada.',
  avaiableVersion:
    'Existe uma nova versão disponível. Deseja atualizar a versão agora?',
  after: 'Depois',
  openLocaleSettings: 'Configuração de idioma',
  openLocaleSettingsDescription: 'Configure o idioma do seu aplicativo.',
  errorDeviceNotAuthorized:
    'Equipamento não é mais autorizado para conexão. O sistema será encerrado. Contacte a empresa.',
  completeYourChangesToSync: 'Conclua suas alterações para sincronizar.',
  errorUploadGetSerial:
    'Erro ao enviar suas informações! Não foi possível recuperar seu último serial.',
  errorUploadToServer:
    'Erro ao enviar suas informações! Servidor não recebeu suas informações.',
  errorDownloadFromServer: 'Erro ao receber suas informações!',
  errorProcessTable: 'Erro no processamento de tabelas',
  errorInternetConnection:
    'Por favor verifique sua conexão de internet e tente novamente',
  activitiesScreen: 'Atividades',
  clientsScreen: 'Clientes',
  offRouteCustomersScreen: 'Clientes fora de rota',
  tradeScreen: 'Trade',
  addClientScreen: 'Adicionar cliente',
  addProspectScreen: 'Adicionar prospect',
  newClientsScreen: 'Novos clientes',
  prospectsScreen: 'Prospects',
  enviromentScreen: 'Ambiental',
  catalogScreen: 'Catálogo',
  productObservationsScreen: 'Observações do produto',
  orderObservationsScreen: 'Observações do pedido',
  productDetailScreen: 'Detalhes do produto',
  imageScreen: 'Imagem',
  cartScreen: 'Produtos',
  changePrice: 'ALTERAR PREÇO',
  orderDetailsScreen: 'Detalhes do pedido',
  orderResumeScreen: 'Resumo do pedido',
  finishScreen: 'Pedido concluído',
  resume: 'Resumo',
  titlesScreen: 'Títulos',
  orderScreen: 'Pedidos',
  homeScreen: 'Home',
  clientDetailsScreen: 'Detalhes do cliente',
  goToClient: 'Ir para o cliente',
  itineraryScreen: 'Roteiro',
  loginScreen: 'Login',
  accountsLoginScreen: 'Contas',
  bonificationsScreen: 'Bonificações',
  profileScreen: 'Perfil',
  lightBoxScreen: 'LightBox',
  compoundProductScreen: 'Produto composto',
  promotionDetailsScreen: 'Detalhes da promoção',
  generationFormScreen: 'Formulário Geração',
  reportsScreen: 'Relatórios',
  combosScreen: 'Combos',
  comboDetailScreen: 'Detalhes do Combo',
  comboRemoved: 'Combo removido!',
  ComboSuccessfullyRemoved: 'Combo removido com sucesso!',
  comboAlertExit:
    'Deseja sair sem aplicar o Combo? Ao sair os produtos que estão no carrinho serão removidos.',
  filtersScreen: 'Filtros',
  updateVersionScreen: 'Atualização de Versão',
  messagesScreen: 'Mensagens',
  rewardsScreen: 'Recompensas',
  printScreen: 'Impressão',
  gamificationScreen: 'gamification',
  managementScreen: 'management',
  goalsScreen: 'goals',
  screenPriceTermConditions: 'Condições de Preço e Prazo',
  waitALittleLonger: 'Aguarde mais um pouco',
  thisFieldIsRequired: 'Este campo é obrigatório',
  maxSizeIs: 'Os arquivos {{files}} devem ser no máximo {{maxSize}}MB',
  typedIsNotValid: 'digitado não é válido',
  fieldIsNotValid: 'O campo não é válido',
  changeOcurred: 'Alteração ocorrida',
  scriptChange: 'Houve alteração no seu roteiro!',
  invalidCredentials: 'Credenciais inválidas. Verifique seu login e sua senha.',
  paramsError:
    'Sua ação não pode ser realizada por falta de Parametrização. Contate o suporte.',
  noInternetConnection: 'Sem conexão com a internet',
  unavailableGPS: 'GPS esta indisponível',
  unavailableActivities: 'Listagem de atividades indisponível',
  releaseActivity:
    'Faça um novo checkin para liberar a realização desta atividade.',
  alertPhotoActivity:
    'Você tirou fotos porém não salvou a atividade. Caso você clique em sair as fotos serão perdidas. Deseja sair?',
  getOut: 'Sair',
  respond: 'Responder',
  lookAnswer: 'Ver resposta',
  syncPendingPhotos: 'Quantidade de arquivos pendentes para sincronizar',
  syncPendingFiles: 'Arquivos pendentes de envio.',
  thereArePhotos: 'Existem arquivos de',
  photosWasntSent: 'que não foram enviadas.',
  pendingSend: 'pendentes de envio.',
  sendNow: 'Enviar agora',
  filesSuccessfullySynced: 'Arquivos sincronizados com sucesso!',
  photo: 'foto',
  errorSyncPhotos: 'Não foi possivel sincronizar as suas fotos',
  connectionErrorWhenSyncPhotos:
    'Falha ao enviar as fotos. Verifique se você está com conexão à internet e tente novamente. COD001',
  connectionWifiOnlyWhenSyncPhotos:
    'Falha ao enviar as fotos. Verifique se você está conectado a uma rede WIFI. COD002',
  syncPhotosGeneric:
    'Falha ao enviar as fotos. Um erro inesperado acontenceu, se persistir contate o suporte. COD003',
  notCompleteSyncFiles:
    'Falha ao enviar uma ou mais arquivos. Se o problema persistir contate o suporte. COD004',
  photosSuccessfullyDeleted: 'Fotos deletadas com sucesso',
  errorDeletePhotos: 'Nenhuma foto encontrada no dispositivo',
  pressToSync: 'Toque para sincronizar',
  errorConnectionGPS:
    'Seu ponto foi coletado mas você está sem conexão.Clique aqui para abrir as configurações.',
  send: 'Enviar',
  generalInfo: 'Informações gerais',
  CollectingPoints: 'Coletando Pontos do GPS',
  clickToLogin: 'Clique no botão acima para acessar.',
  activityRequiredNotDone: 'Faça todas as atividades obrigatórias primeiro',
  clearSelectedProducts: 'Limpar selecionados',
  removeProductsAndKeepCopyOrder: 'Copiar mesmo assim',
  someProductsWillNotBeAdded: 'Existem produtos que não serão adicionados.',
  usernameAndPasswordMustNotBeEmpty:
    'Nome de usuário e senha não devem ser vazios',
  nullOrInvalidCode: 'Código nulo ou inválido',
  codeMustHaveOnlyFourDigits: 'Código deve possuir apenas 4 digitos',
  yesRequest: 'Sim, solicitar',
  offRouteService: 'Atendimento fora de rota',
  offRouteCustomerService:
    'está fora da sua rota.\n\nDeseja solicitar um atendimento?',
  ClientOffRoute: 'fora de rota',
  changingCustomerLocation: 'Você está alterando a localização do cliente.',
  wantToSubmit: 'Deseja enviar uma solicitação?',
  sendRequest: 'Solicitação enviada',
  successSendRequest: 'Solicitação enviada com sucesso!',
  errorSendRequest: 'Não foi possível enviar a solicitação!',
  startingPointScreen: 'Ponto de partida',
  creatingStartingPoint: 'Você está criando seu ponto de partida.',
  changeCustomerLocation: 'Alterar a localização do cliente',
  expectedTime: 'Hora prevista',
  visitTime: 'Tempo visitação',
  displacementTime: 'Tempo deslocamento',
  contactUs: 'Fale Conosco',
  contactUsDescription: 'Dúvidas ou sugestões? Fale com a gente.',
  contactsAndSocialNetworks: 'Contatos e Redes Sociais',
  invalidUsername: 'Nome de usuário inválido',
  customersInvoice: 'Cliente Fatura',
  clear: 'Limpar',
  clearFields: 'Limpar campos',
  wantToClearFields: 'Deseja limpar os campos do formulário?',
  createAddress: 'Novo Endereço',
  noTabs: 'Sem abas...',
  noHTML: 'Sem template para exibição',
  backgroundTrackingTitle: 'PoliEquipes - Monitoramento GPS',
  backgroundTrackingMessage: 'Coletando pontos de localização',
  SalesOpportunity: 'Oportunidade de vendas',
  DetailOpportunity: 'Detalhes da oportunidade de vendas',
  noSalesOpportunity: 'Não existe oportunidade de venda',
  source: 'Origem',
  sourceNumberOrder: 'Número do pedido de origem',
  saleOpportunityStartedAt: 'A oportunidade de venda começou em',
  reasonCancel: 'Motivo do cancelamento',
  dateCancel: 'Data do cancelamento',
  cnpjCpf: 'CNPJ/CPF',
  ean: 'ean',
  accept: 'Aceitar',
  enterReason: 'Digite o motivo',
  reject: 'Rejeitar',
  saleOpportunitityIsDone: 'Oportunidade de Venda utilizada',
  combs: 'Combos',
  period: 'Período',
  sharedBalance: 'Saldo compartilhado',
  apply: 'Aplicar',
  methodology: 'Metodologia',
  currency: 'BRL',
  currencySymbol: 'R$',
  fieldsWithAreMandatory: 'Os campos com * são obrigatórios',
  errorOpeningOrderNote: 'Erro ao abrir observação do pedido',
  ErrorSavingOrderNote: 'Erro ao salvar observação do pedido',
  separatorCurrency: ',',
  delimiterCurrency: '.',
  addrAlternativeSucess: 'Endereço Salvo com sucesso',
  addrCheckin: 'Endereço de Check-in',
  addrChange: 'Alterar endereço',
  addrCreate: 'Novo endereço',
  openLink: 'Abrir link',
  ProblemAccessingThePage: 'Problema ao acessar a página',
  ProblemAccessingThePageDescription:
    'Não foi possível acessar este recurso.\n\nTente acessar novamente, caso o erro persista, entre em contato com o suporte',
  applyDiscount: 'Aplicar Desconto',
  insertBy: 'Inserir por',
  quantity: 'Quantidade',
  multipleof: 'múltiplo de',
  subtotal: 'Sub Total',
  goToAllDetail: 'Ver todos os detalhes',
  cartDetailScreen: 'Produtos e Categorias',
  pendingOrderCanceled: 'Pedido pendente cancelado',
  noFields: 'Sem campos',
  SeeAllProducts: 'Todos os produtos',
  cannotOpenObservation:
    'Durante a edição de uma condição de venda não é possível adicionar observações ao pedido',
  errorOnCancelOrder: 'Erro ao cancelar carrinho!',
  locationPermissionTitle: 'Permissão de localização',
  locationPermissionMessage:
    'Ative as configurações de localização para o monitoramento GPS.',
  waitALittle: 'Aguarde mais um pouco, ainda estamos carregando seus dados...',
  clientNotFound: 'Cliente não encontrado! Cancelando carrinho pendente...',
  share: 'Compartilhar',
  poliInstallerIsNotInstalled: 'O PoliInstaller não está instalado',
  doYouWantToInstallNow: 'Deseja instalar agora?',
  expenses: 'Despesas',
  expenseDetail: 'Detalhes do lançamento',
  expensesForm: 'Novo lançamento',
  expenseSuccess: 'Lançamento salvo',
  expensesResume: 'Resumo de lançamentos',
  theOption: 'a opção',
  discardSaleCondition: 'Descartar condição de venda',
  noChanges: 'Sem alterações',
  noChangesInForm: 'Não há alterações no formulário',
  notListConditionsSales: 'Nao foi possivel listar as condições de venda',
  noBarCodeFound: 'Nenhum código de barras encontrado.',
  barCodeFound: 'Código de barras detectado! Colocando-o no campo de busca',
  errorListExpenses: 'Erro ao listar as suas despesas',
  in: 'Entradas',
  out: 'Saídas',
  cancelExpense: 'Cancelar lançamento',
  cancelExpenseMessage:
    'Se este lançamento for cancelado, as informações digitadas serão perdidas.\n\nQuer mesmo sair sem salvar?',
  exitWithoutSave: 'Sair sem salvar',
  errorListExpenseTypes: 'Não foi possível listar os tipos de despesa',
  fieldDate: 'Data da nota',
  fieldType: 'Tipo do lançamento',
  fieldAmount: 'Valor total',
  subtractFromNote: 'Subtrair da nota',
  subtractFromNoteValue: 'Subtrair do valor da nota',
  subtractValue: 'Valor a subtrair',
  fieldReason: 'Motivo',
  camera: 'Câmera',
  saveExpense: 'Salvar lançamento',
  backToExpenses: 'Voltar para lançamentos',
  successSaveExpense: 'Lançamento salvo com sucesso',
  paymentDate: 'Data do pagamento',
  place: 'Estabelecimento',
  adjustValField: 'Ajuste no valor',
  reasonField: 'Motivo do ajuste',
  attachments: 'Anexos',
  errorOnCreateExpense: 'Erro ao criar despesa',
  emptyExpenses: 'Não há lançamentos para exibir!',
  attachFile: 'Anexar comprovante',
  days: 'Dias',
  errorGenerateReport: 'Erro ao gerar relatório',
  reportsOfLast: 'Relatório dos últimos',
  other: 'Outro',
  balance: 'Saldo',
  emptyListToGeneratePdf: 'Você não tem despesas para gerar o relatório!',
  generateResume: 'Gerar resumo',
  updateDatas: 'Atualização de dados',
  updateFailure: 'Falha de atualização',
  showDetail: 'Ver detalhes',
  quickActions: 'Ações rápidas',
  sendPhotoOnlyWifiDescription:
    'Com esta opção habilitada, as fotos não serão enviadas utilizando os dados móveis.',
  photos: 'fotos',
  answerObservations: 'Preencher observações',
  requiredObservations: 'É obrigatório preencher as observações',
  applySalesConditions: 'Aplicar condições de venda',
  changeSalesConditions: 'Alterar condições de venda',
  goToProducts: 'Ir para os produtos',
  lostChangeSalesCondition: 'As condições de venda serão perdidas.',
  lostChangeSalesConditionMessage:
    'Ao voltar sem salvar, você perde as alterações.',
  confirmLostChangeSalesCondition: 'Tem certeza que deseja voltar?',
  yesBack: 'Sim, voltar',
  unavailableConfig: 'Configuração indisponível',
  currentPlanNotAllowed:
    'O plano atual não permite configurar o pedido\nEntre em contato com o seu supervisor.',
  loginRequired: 'Será necessário realizar login novamente',
  pleaseWait: 'Por favor, aguarde.',
  wePreparingYou: 'Estamos preparando tudo pra você!',
  searchingYourInfos: 'Buscando suas informações...',
  loadingTables: 'Carregando tabelas...',
  organizingYourProducts: 'Organizando seus produtos...',
  knowingYourClients: 'Identificando seus clientes...',
  mappingYourRoutes: 'Mapeando suas rotas...',
  unableOpenLink: 'Não foi possível abrir o link',
  waitingData: 'Aguarde mais um pouco, estamos carregando os dados...',
  loadingAllProducts: 'Carregando todos os produtos...',
  searchForProducts: 'Buscar produtos...',
  findedOrders: 'Pedidos encontrados',
  totalFatured: 'Total de faturamento',
  searchForOrders: 'Buscar pedidos...',
  searchForClients: 'Buscar clientes...',
  orderedBy: 'Ordenado por:',
  dateFrom: 'De',
  dateTo: 'Até',
  errorWhenLoadAccounts: 'Não foi possível carregar as contas!',
  errorWhenSwapAccount: 'Não foi possível trocar de conta!',
  swapAccountTitle: 'Trocar conta',
  swapAccountSubTitle: 'Deseja realmente trocar de conta?',
  swapAccountDescription:
    'Ao trocar o usuário, as fotos não sincronizadas serão perdidas.\n\nDigite a palavra Confirmar no campo abaixo e clique em confirmar.',
  yesSwap: 'Sim, trocar',
  swap: 'Trocar',
  removeAccountTitle: 'Remover conta',
  removeAccountDescription:
    'Deseja remover esta conta do seu dispositivo, fazendo isso você irá precisar realizar o login novamente',
  removeAccountSuccess: 'Conta removida com sucesso!',
  errorOnCheckBase:
    'Não foi possível verificar a base de dados. Contate o suporte se o erro persistir.',
  errorOnRunLib:
    'Não foi possível iniciar o sistema. Contate o suporte se o erro persistir.',
  createActivity: 'Criar atividade',
  activityName: 'Nome da atividade',
  createActivityTitle: 'Criar atividade dinâmica',
  createActivityDescription:
    'A atividade você selecionou será utilizada com base para criar a uma nova, mas para isso você pode informar escolher uma descrição de sua preferência.',
  dynamic: 'Dinâmica',
  activityCreatedSuccessfully: 'Atividade dinâmica criada com sucesso.',
  errorWhenCreateActivity:
    'Parece que algo deu errado durante a criação dessa atividade, tente novamente mais tarde.',
  dynamicActivity: 'Atividade dinâmica',
  activityCannotCreateWithNoProcess:
    'Só é possivel criar uma atividade dinâmica após iniciar o processo.',
  errorSavingPhoto: 'Ocorreu um erro ao salvar a foto',
  moreTitleCat: 'mais',
  categoriesScreen: 'Categorias',
  searchCategories: 'Buscar categorias...',
  errFilterCategories: 'Erro ao filtrar categorias.',
  noCategories: 'Não há categorias.',
  errOpenCategory: 'Erro ao abrir categoria.',
  flightPlan: 'Plano de voo',
  flightPlanVerifyError:
    'Não foi possivel verificar as ações, se o problema persistir contate o suporte. Cod: PVOO-01',
  totalWithAdjust: 'Total com ajuste',
  listTitlesScreen: 'Selecione um ou mais títulos',
  errorListTitles: 'Erro ao listar títulos.',
  errorFilterTitles: 'Erro ao filtrar títulos.',
  collectionOfReceivables: 'Recebíveis',
  selectAtLeastOneTitle: 'Selecione ao menos um título.',
  moduleWorkOnlyInternet: 'Este módulo só funciona com internet.',
  errorExtractTitles: 'Erro ao extrair títulos.',
  errorRequest: 'Erro ao realizar a requisição.',
  ocurredError: 'Ocorreu um erro',
  errorProcessFileToSend: 'Erro ao processar arquivo para envio.',
  errorCommunicateWithServer: 'Erro ao se comunicar com o servidor.',
  maximumPrecisionReached:
    'Precisão da sua localização excede o limite permitido. Tente ir para um lugar externo e, se persistir o problema, procure seu supervisor.',
  reportingModule:
    'O módulo de relatórios não tem permissões de armazenamento ou arquivos e mídia',
  errorReportingModule: 'Ocorreu algum erro na geração do relatório.',
  reportingModuleNotInstalled: 'Módulo de Geração de relatórios não instalado.',
  poliReportsInstall: 'Instalar PoliReports',
  noCode: 'Sem código',
  refTitle: 'Título',
  Kg: 'Kg',
  selectProducts: 'Selecionar produtos',
  selecteds: 'item(s) selecionado(s)',
  multiple: 'Quantidade',
  price: 'Preço',
  table: 'Tabela',
  new: 'Novo(a)',
  seeMore: 'Ver mais',
  replace: 'Substituir',
  replaceMultipleDescription:
    'Troca a quantidade atual pela Nova quantidade vezes o multiplo do item.',
  addMultipleDescription:
    'Soma a quantidade atual do item + Nova quantidade vezes o múltiplo do item.',
  replaceDiscountDescription:
    'Troca o desconto atual do item pelo Novo desconto em porcentagem',
  addDiscountDescription:
    'Soma o desconto atual do item + Novo desconto em porcentagem',
  replacePriceDescription: 'Troca o preço atual do item pelo Novo preço',
  addPriceDescription: 'Soma o preço atual do item + Novo preço ao item',
  characterInvalid: 'Caractere inválido',
  outdatedData: 'Dados desatualizados',
  outdatedDataMessage:
    'Com a base de dados desatualizada este pedido será salvo como um orçamento.',
  outdatedDataDescription:
    'Atualize a sua base de dados e edite este pedido para finaliza-lo',
  back: 'Voltar',
  errorProcessFormData: 'Erro ao Processar dados do Formulário.',
  couldNotBeOpened: 'não pode ser aberto.',
  errorOpenPage: 'Erro ao abrir página.',
  errorLoadPageContactSupport:
    'Ocorreu um erro ao carregar a página. Por favor, contate o suporte do(a)',
  codeHttpError: 'Código do erro HTTP',
  errorMakeUrlPPM: 'Erro ao gerar URL para PPM.',
  products: 'Produtos',
  sucessfullyRejectedOpportunity: 'Oportunidade rejeitada com sucesso.',
  cannotTrigFlightPlanAlerts: 'Não é possível acionar alertas do plano de voo',
  openCart: 'Abrir pedido',
  errorOnLoadShipping:
    'Ocorreu um erro ao carregar os fretes. Se persistir contate o suporte.',
  errorOnLoadShippingCompanies:
    'Ocorreu um erro ao carregar as transportadoras. Se persistir contate o suporte.',
  UnableToLoadingListOfActivities:
    'Não foi possível carregar a lista de atividades.',
  UnableToOpenActivity:
    'Não foi possivel abrir essa atividade.\nPor favor contate o suporte.',
  titleNoUpToDate: 'Dados desatualizados',
  descriptionNoUpToDate:
    'Esteja sempre atualizado! Não deixe de sincronizar seu PoliEquipes com o servidor.',
  ok: 'Ok',
  cantLoadProducts: 'Não foi possível carregar os produtos. Tente novamente',
  reload: 'Recarregar',
  doYouReallyWantToCopyTheOrder: 'Deseja realmente copiar o pedido?',
  howDoYouWantToCopyTheOrder: 'Como deseja copiar o pedido?',
  ErrorProcessingCompanySettingsInformation:
    'Erro ao processar as informações de configurações da empresa',
  comboSuccessApplied: 'Combo aplicado com sucesso',
  addItemsToApplyCombo: 'Adicione itens para aplicar o combo.',
  deleteNotification: 'Deletar a notificação?',
  doYouReallyWantToDeleteNotification:
    'Deseja realmente deletar a notificação?',
  deleteNotificationSucess: 'Notificação deletada com sucesso!',
  errorDeleteNotification: 'Erro ao deletar notificaçao',
  deleteAllNotitifications: 'Deletar todas as notificações',
  doYouReallyWantdeleteAllNotification:
    'Deseja realmente deletar todas as notificações?',
  removeNotification: 'Excluir notificação',
  deleteAllSucess: 'Deletadas com Sucesso!',
  talkingCameNews: 'Te avisaremos quando chegar novidades',
  standardScale: 'Escala padrão',
  errorAddingCustomer: 'Erro ao adicionar cliente',
  errorWhenSetProductActive: 'Erro ao definir o produto ativo',
  subTotal: 'Sub total',
  contactOptions: 'Opções de contato',
  AccessToYourFilesSettings:
    'Acesse as configurações do app e dê permissão de armazenamento.',
  authorizedDiscount: 'Desconto autorizado',
  fillingObservationFieldRequired:
    'Preenchimento do campo observação obrigatório',
  requiredPhotos: 'Foto(s) obrigatória(s)',
  incompatible: 'Incompatível com',
  expressionRegularSettings: 'Expressão regular configurada',
  errorOnFieldVerification: 'Ocorreu um erro ao verificar o campo.',
  itinenaryList: 'Lista',
  itinenaryMap: 'Mapa',
  selectImage: 'Selecionar Imagem',
  choiceOption: 'Escolha uma opção: ',
  takePhoto: 'Tirar foto',
  choiceImageGallery: 'Escolher da galeria',
  deselectSelected: 'Desmarcar selecionados',
  seeCustomersNearTheRoute: 'Ver clientes próximos da rota',
  copyCart: 'Copiar um pedido',
  attachmentAlert: 'Tamanho máximo por arquivo: ',
  attachmentFile: 'Arquivo',
  attachmentOpenFolder: 'Abrir pasta',
  attachmentOpenCamera: 'Abrir a câmera',
  attachmentCamera: 'Câmera',
  ErrorSavingProspects: 'Erro ao salvar prospects. COD001',

  saleOpportunities: 'Oportunidade de vendas',
  saleOpportunityApproach:
    'O promotor informou que este cliente tem produtos com alta probabilidade de compra.\n\nDeseja inserir estes produtos agora?',
  later: 'Ver depois',
  ignoreOpportunity: 'Ignorar oportunidade',
  insertOpportunity: 'Inserir',

  rejectSaleOpportunities: 'Rejeitar oportunidades',
  rejectSaleOpportunitiesDescription:
    "Clicando em ''Rejeitar'' você irá rejeitar {{opportunities}} oportunidade(s).",

  postponeSaleOpportunity: 'Adiar oportunidades',
  postponeSaleOpportunitiesDescription: 'Você perderá a oportunidade de vender esses itens nesse pedido. Informe abaixo o motivo para não realizar essa venda agora.\n\nEles continuarão disponíveis para o próximo pedido.',
  seeOpportunities: 'Ver oportunidades',
  requiredResolveOpportunity: 'É obrigatório corrigir para continuar',
  seeProducts: 'Ver produtos',
  typeReason: 'Informe o motivo',
  existsPendingOpportunityProducts: "Ainda existem produtos de oportunidade",
  addedItens: 'Itens adicionados',
  cod: 'Cód.: {{code}}',
  removeProduct: 'Remover produto',
  includeProduct: 'Incluir produto',
  lowerItens: 'itens',
  lowerAdded: 'adicionados',
  totalValue: 'Total: {{totalValue}}',


  hour: 'Hora',
  hours: 'Horas',
  minute: 'Minuto',
  minutes: 'Minutos',
  second: 'Segundo',
  seconds: 'Segundos',
  day: 'Dia',
  days: 'Dias',

  theJourneyWillStarts: 'A jornada irá iniciar em',
  theJourneyWillStartsDescription: 'Prepare-se, em breve você poderá iniciar suas atividades.',
  intervalStartsIn: 'Seu intervalo começa em {{minutes}} minutos',
  intervalStartsInWarn: 'Atenção\n\nSeu intervalo começa em 0{{minutes}}:00 minutos.',

  journeyInterval: 'Intervalo da jornada',
  journeyIntervalDescription: 'Salve agora as suas atividades ou pedidos.\n\nO APP entrará em modo visualização em 1 minuto.',
  iamAgree: 'Estou ciente',
  lunchBreak: 'Intervalo',
  lunchBreakDescription: 'Avisaremos você quando terminar o almoço.',
  noLunchBreak: 'Sem pausa para o almoço',
  noLunchBreakDescription: 'Prepare-se, em breve você poderá iniciar suas atividades.',
  opportunity: 'Oportunidade',
  journeyEndsIn: 'Sua jornada encerra em {{minutes}} minutos.',
  journeyEndsInWarn: 'Atenção!\n\nSua jornada termina em 0{{minutes}}:00 minutos.',


  journeyEndsTitle: 'Muito bom, jornada finalizada',
  journeyEndsDescription: 'Deixe sempre tudo em ordem.\nÉ importante sincronizar seus dados.',
  seeModules: 'Ver módulos',
  journey: 'Jornada',

  journeyEndsAlert: 'Fim de jornada',
  journeyEndsAlertDescription: 'Salve agora as suas atividades ou pedidos.\n\nO APP irá encerrar em 1 minuto.',

  cnpjValue: 'CNPJ: {{value}}',
  createdWhen: 'Criada em {{value}}',
  shortCode: 'Cód.',
  ean: 'EAN',
  errorSavingProspects: 'Erro ao salvar prospects. COD001',
  emptyProspectsMessage: 'Você ainda não tem prospects associados a você.',
  errorsOnForm: 'O formulário contém erros. Por favor, corrija-os e tente novamente.',
  dayoffMessage: 'Hoje é dia de folga',
  dayoffDescription: 'Escolha outro dia para ver as suas visitas.',
  copyCartScreenTitle: 'Selecionar pedido',
  osrmError: 'Não conseguimos exibir as coordenadas das direções no mapa.',
  loseInformation: "Perder informações",
  doYouReallyWantToExit: "Deseja realmente sair?",
  onExitInformationWillBeLost: "Ao sair as suas informações serão perdidas.",
  exitAndLose: "Sair e perder",
  agentMode: "Modo agente",
  agentModeDescription: "Selecione um agente para ativar o modo e realizar atividades como o agente selecionado.",
  selectAgent: "Selecionar agente",
  managementMode: "Modo gestão",

  outOfArea: "Visita fora da área",
  requestOutOfArea: "Solicitar liberação",
  releaseRequestSuccess: "Solicitação de liberação enviada com sucesso!",
  releaseRequestError: "Ocorreu um erro ao solicitar a liberação.",

  blockedClient: "Cliente bloqueado",
  requestBlockedClient: "Solicitar liberação de venda",
  releaseRequestSuccess: "Solicitação de liberação de venda enviada com sucesso!",
  releaseRequestError: "Ocorreu um erro ao solicitar a liberação de venda.",
  waitALittleForMakeRequest: 'Sua solicitação foi registrada. Por favor, aguarde um momento antes de realizar uma nova tentativa.',
  requestDenied: 'Sua solicitação foi reprovada. Por favor, aguarde um momento antes de realizar uma nova tentativa.',
  changeClientTitle: 'Remover produtos',
  changeClientSubTitle: 'Ao trocar de cliente os produtos adicionados poderão ser removidos.',
  changeClientDescription: 'Deseja continuar?',
  changeClient: 'Trocar cliente',
  important: 'Importantes',
  all: 'Todas',
  selectCalendarDate: 'Selecione uma data no calendário para continuar'
};
