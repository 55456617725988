import { StyleSheet } from 'react-native';
import { color } from '~/common';
import { fonts } from '~/utils/Fonts';

const stylesOP = StyleSheet.create({
  wrapper: {
    padding: 10,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#1A1A1A',
    fontFamily: fonts.Quicksand,
  },
  containerInfoClient: {
    paddingTop: 5,
  },
  containerInfoItems: {
    paddingTop: 0,
  },
  infoClientTitle: {
    fontSize: 16,
    color: '#666666',
    fontFamily: fonts.Quicksand,
  },
  infoClientValue: {
    fontSize: 16,
    color: '#1A1A1A',
    fontFamily: fonts.Quicksand,
    fontWeight: '600',
  },
  infoItems: {
    fontSize: 16,
    color: '#666666',
    fontFamily: fonts.Quicksand,
  },
  containerInfoOrder: {
    paddingTop: 10,
  },
  rootBtns: {
    paddingTop: 24,
  },
  containerSaveOrder: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    right: 18,
    paddingTop: 14,
    paddingBottom: 12,
  },
  conatinerRestoreOrder: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    right: 18,
    paddingTop: 19,
    bottom: 18,
  },
  containerBtnDeleteOrder: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    right: 18,
    paddingTop: 20,
    bottom: 24,
  },
  textBtns: {
    fontSize: 18,
    color: color.primaryColor,
  },
});

export default stylesOP;
