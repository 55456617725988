import ClientModule from './../../../modules/ClientsModule';
import store from '../../../store';

export default async function saleconditions(pkeyClient) {
  const { configLibFetch } = store.getState();
  const { tam_memoria_paginacao } = configLibFetch.payload;
  const pagination = { page: 0, offset: tam_memoria_paginacao, active: true };

  let active = true;
  let data = '';
  try {
    while (active) {
      const resp = await ClientModule.conditionSaleValid(
        pkeyClient,
        JSON.stringify(pagination),
      );
      data = `${data}${resp}`;

      if (!(resp.length > 0)) {
        active = false;
      }

      pagination.page += 1;
    }

    return JSON.parse(data);
  } catch (error) {
    throw error.message;
  }
}
