import React from 'react';
import {View, Text, Image, TouchableOpacity, ActivityIndicator} from 'react-native';
import Images from '@assets/Images';
import { color } from '~/common';

import styles from './styles';
import translate from '~/locales';

import PropTypes from 'prop-types';

function ClientProcessItem({
                               process,
                               onPress,
                               progress,
                               activitiesLength,
                               activitiesAnswered,
                           }: Props) {
    return (
        <TouchableOpacity
            onPress={() => onPress(process)}
            style={styles.card}>
            <View>
                <View style={styles.cardTitleContainer}>
                    <Text style={styles.cardTitleText}>
                        {process.nome || `${translate('unnamedProcess')}.`}
                    </Text>
                    <Image
                        style={{ width: 40, height: 40 }}
                        source={Images.iconChevronRight}
                    />
                </View>
                <Text style={styles.descriptionText}>
                    &#9679;{' '}
                    {process.descricao || `${translate('processWithoutDescription')}.`}
                </Text>
                <View style={{ marginTop: 20 }}>
                    <Text style={styles.progressText}>
                        {`${translate(
                            'activitiesProgress',
                        )} - ${activitiesAnswered}/${activitiesLength}`}
                    </Text>

                </View>
            </View>
        </TouchableOpacity>
    );
}

ClientProcessItem.propTypes = {
    process: PropTypes.object.isRequired,
    onPress: PropTypes.func.isRequired,
    activitiesLength: PropTypes.number.isRequired,
    activitiesAnswered: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
};

export default ClientProcessItem;