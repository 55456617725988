import React from 'react';
import { Image, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import RegisterAgain from './RegisterAgain';
import {
    GENERATE_DATA_FINALLY,
    GENERATE_DATA_STARTED,
    SYNC_FINALLY,
} from '../../constants/Sync';
import { syncUpdateData, syncUpdateScreen } from '../../store/syncFetch/slice';
import { SCREEN_REGISTER_AGAIN } from '../screens';
import RegisterAgainLib from '../../services/resources/libFastSeller/RegisterAgainLib';
import { existsAnsweredField, formatQSQLDataSource } from '../../utils/Form';
import translate from '../../locales';
import getGpsConfigs from '../../utils/ConfigHelper';
import _ from 'lodash';
import { handlePermissions } from '~/store/permission/slice';
import Images from '../../../assets/Images';
import onPressHelper from '~/utils/onPressHelper';
import { showAlert } from '~/components/Web/Alert';
import { createNotifySuccess } from '~/components/Web/ToastNotify';

type Props = {
    client: any,
    syncFetch: any,
    configLib: any,
    dispatch: () => void,
};

class RegisterAgainContainer extends React.Component<void, Props, void> {
    constructor(props) {
        super(props);
        this.state = {
            form: [],
            dataForm: {},
            dataFormInit: null,
            visible: false,
            formIsValid: false,
            gpsConfig: {},
            location: {},
        };
        this.props.navigation.addListener('focus', () => {
            this.sendOpenEvent = true;
            // this.handleLocationGPS();
            this.setLeftButton();
        });
    }

    componentDidMount() {
        this.loadFormRegisterAgain();
    }

    componentDidUpdate(prevProps) {
        (() => {
            if (this.props.syncFetch.type === prevProps.syncFetch.type) {
                return;
            }
            if (
                this.props.syncFetch.payload.statusSync === SYNC_FINALLY &&
                this.props.syncFetch.payload.screen === SCREEN_REGISTER_AGAIN.name
            ) {
                this.props.dispatch(syncUpdateScreen(''));
                this.updateClient(this.stringJsonDataForm);
            }
        })();
    }

    navigationButtonPressed = ({ buttonId }) => {
        if (buttonId === 'backPress' || buttonId === 'RNN.hardwareBackButton') {
            this.handleBackPress();
        }
    };

    onLocationChange = location => {
        if (location && (location.latitude || location.latitude)) {
            this.setState({ location }, () => {
                if (this.sendOpenEvent) {
                    RegisterAgainLib.saveEventOpenRegisterAgain(
                        this.props.client.pkey,
                        JSON.stringify(this.state.location),
                    ).then(() => {
                        this.sendOpenEvent = false;
                    });
                }
            });
        } else if (!this.state.gpsConfig.requiredCollectGpsPoint) {
            if (this.sendOpenEvent) {
                RegisterAgainLib.saveEventOpenRegisterAgain(
                    this.props.client.pkey,
                    JSON.stringify(this.state.location),
                ).then(() => {
                    this.sendOpenEvent = false;
                });
            }
        }
    };

    getFormData = dataForm => {
        this.setState({ dataForm }, () => {
            if (!this.state.dataFormInit) {
                this.setState({ dataFormInit: dataForm });
            }
        });
    };

    setLeftButton = () => {
        this.props.navigation.setOptions({
            headerLeft: () => (
                <TouchableOpacity onPress={() => {
                    this.handleBackPress();
                }}
                >
                    <Image
                        source={Images.iconBack}
                        style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
                    />
                </TouchableOpacity>
            ),
        });
    };

    handleBackPress = () => {
        const { dataForm, dataFormInit } = this.state;
        const dataFormVerify = existsAnsweredField(dataForm);
        if (dataFormVerify && dataFormInit !== dataForm) {
            showAlert(`${translate('discardChanges')}`, `${translate('discardChangesInForm')}`, this.props.navigation.pop);

            return;
        }
        this.props.navigation.pop();
    };

    async loadFormRegisterAgain() {
        const { client } = this.props.route.params;
        try {
            const dataStr = await RegisterAgainLib.getParametersRegisterAgain(
                client.pkey,
            );
            const data = JSON.parse(dataStr);
            const dataFormStr = _.get(data, 'form_data', '[]');
            const form = JSON.parse(dataFormStr);

            this.setState({ form, visible: true });
        } catch (error) {
            const dataErro = JSON.parse(error);
            alert(dataErro[0].mensagem);
            this.setState({ visible: false });
        }
    }

    isFormValid = valid => {
        this.setState({ formIsValid: valid });
    };

    formatQSQLDataSource = dataSource => {
        const formattedDataSource = formatQSQLDataSource(dataSource);
        return formattedDataSource;
    };

    updateClientHandle = () => {
        const { syncFetch } = this.props;
        const { statusSync } = syncFetch.payload;

        this.setState({ visible: false });
        if (statusSync === SYNC_FINALLY) {
            this.updateClient();
        } else {
            this.props.dispatch(syncUpdateScreen(SCREEN_REGISTER_AGAIN.name));
        }
    };

    onPressUpdateClientHandle = onPressHelper.debounce(this.updateClientHandle);

    handleLocationGPS = () => {
        const { gpsConfig } = this.state;
        const { permission, dispatch } = this.props;
        const permissions = _.get(permission, 'payload.response', {});
        const getGpsConfig = getGpsConfigs(
            'required_in_registering_again',
            'notify_in_registering_again',
        );

        this.setState({ gpsConfig: getGpsConfig }, () => {
            if (permissions.location !== 'authorized') {
                const {
                    showPermissionLocation,
                    requiredCollectGpsPoint,
                    notifyOnSaving,
                } = gpsConfig;
                if (
                    showPermissionLocation ||
                    requiredCollectGpsPoint ||
                    notifyOnSaving
                ) {
                    dispatch(handlePermissions(['location']));
                }
            }
        });
    };

    updateClient = async () => {
        const { dispatch } = this.props;
        const { client } = this.props.route.params;
        const { dataForm, gpsConfig, location } = this.state;

        try {
            dispatch(syncUpdateData(GENERATE_DATA_STARTED));
            RegisterAgainLib.sendDataForm(
                { ...dataForm },
                client.pkey,
                gpsConfig,
                location,
            ).then((res) => {
                createNotifySuccess(`${translate('changesSavedSuccesfully')}`);
                this.props.navigation.pop();
            })
            .catch((error) => {
                console.log('error', error);
            });
        } catch (error) {
            const dataErro = typeof error === 'string' ? JSON.parse(error) : error;
            alert(dataErro[0]?.mensagem || translate('ocurredError'));
        } finally {
            this.setState({ visible: true }, () => {
                dispatch(syncUpdateData(GENERATE_DATA_FINALLY));
            });
        }
    };

    render() {
        const { form, visible, formIsValid, gpsConfig, location } = this.state;

        return (
            <RegisterAgain
                form={form}
                visible={visible}
                formIsValid={formIsValid}
                isFormValid={this.isFormValid}
                getFormData={this.getFormData}
                formatQSQLDataSource={this.formatQSQLDataSource}
                onSaveButtonPress={this.onPressUpdateClientHandle}
                gpsConfig={gpsConfig}
                location={location}
                onLocationChange={this.onLocationChange}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        syncFetch: state.syncFetch,
        configLib: state.configLib,
        permission: state.permissionsFetch,
    };
}

export default connect(mapStateToProps)(RegisterAgainContainer);