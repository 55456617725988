/* @flow */

import React, { Component } from 'react';
import { Text, TextInput, View } from 'react-native';
import _ from 'lodash';

import style from '../../../common/style';
import SegmentControl from '../components/SegmentControl';
import color from '../../../common/color';
import styles from './styles';
import { convertToFloat } from './utils';
import { fonts } from '../../../utils/Fonts';
import { getComponent } from '../../../components';

import PropTypes from 'prop-types';

class Discount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valueDiscountEdition: props.valueDiscount,
    };
  }

  UNSAFE_componentWillReceiveProps({ valueDiscount }) {
    if (valueDiscount === this.props.valueDiscount) {
      return;
    }
    if (valueDiscount !== this.state.valueDiscountEdition) {
      this.setState({ valueDiscountEdition: valueDiscount });
    }
  }

  onChangeDiscount = entry => {
    if (entry) {
      if (convertToFloat(entry) <= 100) {
        this.setState({
          valueDiscountEdition: `${entry}`,
        });
      }
    } else {
      this.setState(
        {
          valueDiscountEdition: convertToFloat(0),
        },
        () => this.inputRef.clear(),
      );
    }
  };

  onEndEditingDiscount = product => {
    const { valueDiscountIni, discountType } = this.props;
    const { valueDiscountEdition: valueDiscount } = this.state;

    if (valueDiscount !== valueDiscountIni) {
      const value = convertToFloat(valueDiscount);
      if (discountType === 0) {
        this.props.desconto(product, value);
      } else {
        this.props.acrescimo(product, value);
      }

      this.props.setDiscounts({
        valueDiscount: value,
        valueDiscountIni: convertToFloat(valueDiscount),
      });
    }
  };

  onPressDiscountType = discountType => {
    this.props.setDiscounts({ discountType });
    const { product, valueDiscount } = this.props;
    const value = convertToFloat(valueDiscount);

    if (discountType === 0) {
      this.props.desconto(product, value);
    } else {
      this.props.acrescimo(product, value);
    }
  };

  renderComponentDiscountIncrement(componentId) {
    const { discountType } = this.props;
    const ComponentItem = getComponent(componentId);
    const props = {
      onPress: this.onPressDiscountType,
      selectedTypeDiscount: discountType,
    };

    return <ComponentItem {...props} />;
  }

  render() {
    const {
      discountValues,
      product,
      updatingItem,
      configLib,
      setEditing,
    } = this.props;

    const configComponent = _.get(
      configLib.payload,
      'configuracoes.components_screen',
    );

    return (
      <View style={[style.spaceBetween, { marginTop: 16 }]}>
        <View style={[style.rowDirection, { flex: 1 }]}>
          <Text style={{ marginRight: 3, color: '#000' }}>% </Text>
          <TextInput
            ref={ref => {
              this.inputRef = ref;
            }}
            placeholder={'0.00'}
            keyboardType="numeric"
            editable={!updatingItem}
            style={[
              styles.input,
              styles.inputPrice,
              {
                fontFamily: fonts.Quicksand,
                marginRight: 10,
                textAlign: 'left',
                backgroundColor: '#fff',
                padding: 15,
                fontSize: 17,
                borderWidth: 1,
                borderRadius: 5,
              },
            ]}
            value={this.state.valueDiscountEdition}
            onChangeText={this.onChangeDiscount}
            onEndEditing={() => this.onEndEditingDiscount(product)}
            onFocus={() => {
              this.inputRef?.clear();
              if (setEditing) {
                setEditing(true, 'segment');
              }
            }}
            onBlur={() => this.onEndEditingDiscount(product)}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                this.onEndEditingDiscount(product);
              }
            }}
          />
        </View>
        {configComponent &&
        configComponent.product_detail_button_discount_increment ? (
          this.renderComponentDiscountIncrement(
            configComponent.product_detail_button_discount_increment,
          )
        ) : (
          <SegmentControl
            tabsContainerStyle={{ flex: 1, height: 36 }}
            activeTabStyle={{
              backgroundColor: updatingItem
                ? color.grayAlmostWhite
                : color.primaryColor,
            }}
            tabStyle={{
              borderColor: updatingItem
                ? color.grayAlmostWhite
                : color.primaryColor,
              paddingHorizontal: 3,
            }}
            tabTextStyle={{
              color: updatingItem ? color.grayAlmostWhite : color.primaryColor,
            }}
            values={discountValues}
            onTabPress={this.onPressDiscountType}
            selectedIndex={this.props.discountType}
            borderRadius={18}
            disabled={updatingItem}
          />
        )}
      </View>
    );
  }
}

Discount.propTypes = {
  configLib: PropTypes.object.isRequired,
  discountValues: PropTypes.object.isRequired,
  discountType: PropTypes.string.isRequired,
  valueDiscount: PropTypes.number.isRequired,
  valueDiscountIni: PropTypes.number.isRequired,
  product: PropTypes.object.isRequired,
  updatingItem: PropTypes.bool.isRequired,
  desconto: PropTypes.func.isRequired,
  acrescimo: PropTypes.func.isRequired,
  setDiscounts: PropTypes.func.isRequired,
  setEditing: PropTypes.func.isRequired,
};

export default Discount;
