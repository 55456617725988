import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ProductsModule from '~/modules/ProductsModule';

export const PRODUCTS_MAGNITUDES_REQUEST = 'PRODUCTS_MAGNITUDES_REQUEST';
export const PRODUCTS_MAGNITUDES_SUCCESS = 'PRODUCTS_MAGNITUDES_SUCCESS';
export const PRODUCTS_MAGNITUDES_FAILURE = 'PRODUCTS_MAGNITUDES_FAILURE';

export const getProductsMagnitudes = createAsyncThunk(
  'productsMagnitudes/getProductsMagnitudes',
  async payload => {
    try {
      const { orgvenda } = payload;
      const magnitudes = await new Promise((resolve, reject) => {
        ProductsModule.getProductsMagnitude(orgvenda, resolve, reject);
      });
      return magnitudes;
    } catch (error) {
      throw error;
    }
  },
);
const initialState = {
  type: '',
  loading: false,
  erro: false,
  payload: [],
};
const productsMagnitudesSlice = createSlice({
  name: 'productsMagnitudes',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getProductsMagnitudes.pending, state => {
        state.type = PRODUCTS_MAGNITUDES_REQUEST;
        state.loading = true;
      })
      .addCase(getProductsMagnitudes.fulfilled, (state, action) => {
        const payload = JSON.parse(action.payload);
        state.type = PRODUCTS_MAGNITUDES_SUCCESS;
        state.loading = false;
        state.payload = payload;
      })
      .addCase(getProductsMagnitudes.rejected, state => {
        state.type = PRODUCTS_MAGNITUDES_FAILURE;
        state.loading = false;
        state.erro = true;
      });
  },
});
const { reducer } = productsMagnitudesSlice;
export default reducer;
