import { createSlice } from '@reduxjs/toolkit';

export const SELECTED_TITLES = 'SELECTED_TITLES';

const initialState = {
    titles: [],
};

const selectedTitlesSlice = createSlice({
    name: 'selectedTitles',
    initialState,
    reducers: {
        getTitles: state => {
            return state.titles;
        },
        setTitle: (state, action) => {
            const { title } = action.payload;
            const index = state.titles.findIndex(item => item.numero === title.numero);
            if (index === -1) {
                state.titles.push(title);
            } else {
                state.titles.splice(index, 1);
            }
        },
        setClearTitles: state => {
            state.titles = [];
        },
    },
});

const { reducer, actions } = selectedTitlesSlice;

export const { getTitles, setTitle, setClearTitles } = actions;

export default reducer;
