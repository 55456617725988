import AsyncStorage from '@react-native-async-storage/async-storage';

class CacheApiV1 {
  getLastSerial() {
    return AsyncStorage.getItem('@Session:CacheApiV1LastSerial');
  }

  async setLastSerial(value) {
    await AsyncStorage.setItem('@Session:CacheApiV1LastSerial', value);
  }

  async getDownloadComplete() {
    const complete = await AsyncStorage.getItem('@Session:CacheApiV1DownloadComplete');
    if (complete && complete === 'true') {
      return true;
    }
    return false;
  }

  async setDownloadComplete(complete) {
    await AsyncStorage.setItem('@Session:CacheApiV1DownloadComplete', complete);
  }
}

export default new CacheApiV1();
