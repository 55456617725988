export default class BaseController {
  constructor() {
    this.repository = null;
  }

  get(callback) {
    this.repository.get(callback);
  }

  getById(Id) {
    return this.repository.getById(Id);
  }

  async getIdsToReserve() {
    return this.repository.getIdsToReserve();
  }

  getAllToSync() {
    return this.repository.getAllToSync();
  }

  async setSynchronizedSuccess(id) {
    return this.repository.setSynchronizedSuccess(id);
  }

  async setSynchronizedError(id, errorMsg) {
    return this.repository.setSynchronizedError(id, errorMsg);
  }

  insert(object) {
    return this.repository.insert(object);
  }

  update(object) {
    return this.repository.insert(object);
  }

  insertFromServer(list, key) {
    return this.repository.insertFromServer(list, key);
  }

  exists(id) {
    return this.repository.exists(id);
  }

  updateTempIdToReservedId(tempId, reservedId) {
    return this.repository.updateTempIdToReservedId(tempId, reservedId);
  }

  updateSyncStatus(id, status) {
    return this.repository.updateSyncStatus(id, status);
  }
}
