/* @flow */

import React, { Component } from 'react';
import { TextInput, View } from 'react-native';
import { style } from '~/common';
import Images from '@assets/Images';
import Masked from 'vanilla-masker';
import styles from './styles';
import Button from '../../../../components_base/src/components/Button';
import { getQtyFromStockAndSalesMultiple } from '../../../utils/ProductUtil';
import CacheDataController from '../../../utils/CacheDataController';
import _ from 'lodash';
import {
  getCurrencyFormatter,
  getPrecisionFromType,
  parseStrToFloat,
} from '~/utils/Currency';

import PropTypes from 'prop-types';

class Quantity extends Component {
  constructor(props) {
    super(props);
    this.addButton = props.addItem ? _.debounce(props.addItem, 1000) : () => {};
  }
  onChangeTextQuantity = entry => {
    const { product, ignoreStock } = this.props;
    const { estoque } = product;
    const moneyFormatter = getCurrencyFormatter('quantity', {
      noSymbolCurrency: true,
    });
    let value = entry;
    if (moneyFormatter.precision > 0) {
      value = Masked.toMoney(Masked.toNumber(value), moneyFormatter);
    }
    this.props.setQuantity(
      getQtyFromStockAndSalesMultiple(value, estoque, ignoreStock),
    );
  };

  onEndEditingQuantity = async () => {
    const { quantity: qtyP, product, ignoreStock, volumeFactor } = this.props;
    const { estoque } = this.props.product;
    let quantity = qtyP;
    if (String(quantity).includes('\r')) {
      quantity = Number(String(quantity).replace('\r', ''));
    }
    if (quantity !== product.quantidade) {
      const multiploVenda = volumeFactor;
      let qty = 0;
      const qtyDiv = quantity % multiploVenda;
      if (qtyDiv !== 0) {
        const qtyDivMult = quantity / multiploVenda;
        qty = qtyDivMult.toFixed(0) * multiploVenda;
      } else {
        qty = quantity;
      }
      if (qty < 0) {
        qty = 0;
      }
      if (qty > estoque && !ignoreStock) {
        qty = estoque;
      }
      await CacheDataController.saveData('@ADD_ITEM_REF', {
        product,
        quantity,
      });
      this.addButton(product, qty);
    }
  };

  async addRemoveClick(qty) {
    const quantity = this.props.quantity + qty;

    if (quantity >= 0) {
      const { product } = this.props;
      await CacheDataController.saveData('@ADD_ITEM_REF', {
        product,
        quantity,
      });
      this.props.setQuantity(quantity, () => {
        this.addButton(product, quantity);
      });
    }
  }

  render() {
    const {
      volumeFactor,
      quantity,
      product,
      updatingItem,
      ignoreStock,
      setEditing,
    } = this.props;

    const maxQuantity = ignoreStock ? undefined : product.estoque;
    const precision = getPrecisionFromType('quantity');
    const formattedQuantity =
      precision > 0 ? parseStrToFloat(quantity, precision) : quantity;
    return (
      <View style={style.spaceBetween}>
        <View style={[styles.containerQty, this.props.containerStyle]}>
          <View style={[style.spaceBetween]}>
            <Button
              iconLeft={Images.iconMinus}
              small
              disabled={updatingItem || quantity <= 0}
              iconButton
              onPress={() => this.addRemoveClick(-volumeFactor)}
            />
            <TextInput
              selectTextOnFocus
              value={`${formattedQuantity !== 'NaN' ? formattedQuantity : 0}`}
              editable={!updatingItem}
              keyboardType="numeric"
              onChangeText={this.onChangeTextQuantity}
              onEndEditing={this.onEndEditingQuantity}
              onFocus={() => {
                if (setEditing) {
                  setEditing(true, 'quantity');
                }
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    this.onEndEditingQuantity();
                }
            }}
              onBlur={this.onEndEditingQuantity}
              style={[
                styles.input,
                {
                  textAlign: 'center',
                  backgroundColor: 'transparent',
                  minWidth: 60,
                  maxWidth: 60,
                },
              ]}
            />
            <Button
              iconLeft={Images.iconPlus}
              small
              disabled={
                updatingItem ||
                (maxQuantity !== undefined && quantity >= maxQuantity)
              }
              iconButton
              onPress={() => this.addRemoveClick(volumeFactor)}
            />
          </View>
        </View>
      </View>
    );
  }
}

Quantity.propTypes = {
  volumeFactor: PropTypes.number,
  quantity: PropTypes.number,
  product: PropTypes.shape({
    estoque: PropTypes.number,
  }),
  setQuantity: PropTypes.func,
  addItem: PropTypes.func,
  containerStyle: PropTypes.any,
  updatingItem: PropTypes.bool,
  ignoreStock: PropTypes.bool,
  setEditing: PropTypes.func,
};

export default Quantity;
