import { Alert, Linking } from 'react-native';
import moment from 'moment';
import DeviceInfo from 'react-native-device-info';
import NavigationHelper from './screens/NavigationHelper';

import { store } from './store/index';
import CachePolistore from './controllers/Accounts/Cache/CachePolistore';
import { infoAPK } from './services/resources/VersionManager';
import CacheAPK from './cache/ApkCache';
import { URL_POLI_INSTALLER } from './constants/PoliequipesInstaller';
import { SCREEN_UPDATE_VERSION_APP } from './screens/screens';
import translate from './locales';
import { setAvailableUpdate } from './store/versionUpdate/slice';

const getDataAPKCache = async dataAPK => {};

const lastDataAPKServiceCache = async dataAPK => {};

const alertVersionUpdate = (requiredUpdateVersion, syncFinaly, dataAPK) => {};

const verifyAndUpdateVersion = (dataAPK, dataAPKCache, syncFinaly) => {};

export default async syncFinaly => {};
