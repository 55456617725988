import AsyncStorage from '@react-native-async-storage/async-storage';

class CachePolistore {
  async getLoginData() {
    const loginData = await AsyncStorage.getItem('@Session:loginData');
    return loginData;
  }

  async setLoginData(empresa, loginUsuario, senha) {
    await AsyncStorage.setItem('@Session:loginData', JSON.stringify({ empresa, loginUsuario, senha }));
  }

  async getParamSyncon() {
    const paramsSyncon = await AsyncStorage.getItem('@Session:paramsSyncon');
    return paramsSyncon;
  }

  async setParamSyncon(data) {
    const parseToString = typeof data === 'string' ? data : JSON.stringify(data);
    await AsyncStorage.setItem('@Session:paramsSyncon', parseToString);
    // Set timestamp breakpoint
    await AsyncStorage.setItem('@Session:paramsSyncon:timestamp', new Date().getTime().toString());
  }

  async isParamSynconExpired() {
    const timestamp = await AsyncStorage.getItem('@Session:paramsSyncon:timestamp');
    const now = new Date().getTime();
    const diff = now - timestamp;
    const diffInMinutes = Math.round(diff / 60000);
    return diffInMinutes > 30;
  }

  async getParamsSignIn() {
    const paramsSignIn = await AsyncStorage.getItem('@Session:paramsSignIn');
    return paramsSignIn;
  }

  async setParamsSignIn(data) {
    await AsyncStorage.setItem('@Session:paramsSignIn', data);
  }

  getDatalogLastSerial() {
    return AsyncStorage.getItem('@Session:datalogLastSerial');
  }

  async setDatalogLastSerial(value) {
    await AsyncStorage.setItem('@Session:datalogLastSerial', value);
  }
}

export default CachePolistore;
