import { Dimensions, Platform, StyleSheet } from 'react-native';
import { colors, sizes, defaultShadow } from '../../../src/values/index';
import { fonts } from '~/utils/Fonts';
import DeviceInfo from 'react-native-device-info';

const screenWidth = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;
const isTablet = Platform.OS === 'web' ? false : DeviceInfo.isTablet();

const cardCatContainerTablet = {
  flex: 1,
  borderRadius: 6,
  marginHorizontal: 20,
  marginVertical: 10,
  height: screenHeight / 8,
  width: 200,
  paddingVertical: 10,
  paddingHorizontal: 16,
  gap: 16,
};

const cardCatContainerNotTablet = {
  flex: 1,
  borderRadius: 6,
  alignItems: 'flex-start',
  justifyContent: 'center',
  marginHorizontal: 8,
  height: 80,
  width: 200,
  paddingVertical: 6,
  paddingHorizontal: 6,
  right: 10,
  gap: 8,
};

const btnMoreTablet = {
  flexDirection: 'row',
  alignItems: 'center',
  paddingHorizontal: 20,
  paddingVertical: 0,
  top: 3,
  borderRadius: 10,
  backgroundColor: 'rgba(0,0,0,0.6)',
  height: 30,
  width: 100,
};

const btnMoreNotTablet = {
  flexDirection: 'row',
  alignItems: 'center',
  paddingHorizontal: 5,
  paddingVertical: 3,
  top: 3,
  borderRadius: 10,
  backgroundColor: 'rgba(0,0,0,0.6)',
  marginTop: -25,
  right: 20,
};

const size = 80;

const baseContainer = {
  height: size,
  width: size,
  borderRadius: size / 2,
};

const styles = StyleSheet.create({
  rootContainer: {
    alignItems: 'center',
    marginHorizontal: 0,
    marginTop: 8,
    height: 130,
  },
  shadowContainer: {
    ...baseContainer,
    ...defaultShadow,
    backgroundColor: '#FFF',
  },
  overflowContainer: {
    ...baseContainer,
    overflow: 'hidden',
  },
  container: {
    ...baseContainer,
    backgroundColor: '#FFF',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: size / 2.5,
    height: size / 2.5,
    tintColor: colors.grayDark,
  },
  title: {
    alignItems: 'center',
    textAlign: 'center',
    fontSize: sizes.textVerySmall,
    color: colors.grayDark,
    maxWidth: size,
    marginTop: 10,
  },

  // CategoryItem
  cardCatContainer: {
    ...isTablet ? cardCatContainerTablet : cardCatContainerNotTablet,
  },
  containerCardTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    top: 20
  },
  cardCatTitleMore: {
    textAlign: 'left',
    left: 3,
    fontFamily: fonts.QuicksandBold,
    fontSize: isTablet ? sizes.textBig : 14,
    color: '#FFF',
    maxWidth: 120,
    bottom: 20,
  },
  cardCatTitleNoMore: {
    textAlign: 'left',
    left: 3,
    fontFamily: fonts.QuicksandBold,
    fontSize: isTablet ? sizes.textBig : 14,
    color: '#FFF',
    maxWidth: 120,
    bottom: 0,
  },
  popable: {
    width: isTablet ? 300 : 200,
  },
  contentPopable: {
    color: '#FFF',
    height: isTablet ? 100 : 70,
    width: isTablet ? 300 : 100,
    fontSize: isTablet ? sizes.textBig : sizes.textSmall,
    left: 5,
    padding: 3
  },
  btnMore: {
    ...isTablet ? btnMoreTablet : btnMoreNotTablet,
  },
  textBtnMore: {
    color: '#FFF',
    fontSize: isTablet ? sizes.textBig : 10,
  },
  containerSubTitle: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: isTablet ? screenWidth : '100%',
    top: isTablet ? 20 : 5,
    right: 3
  },
  subTitle: {
    color: '#FFFFFF',
    fontSize: isTablet ? 35 : 20,
    fontWeight: 'bold',
    zIndex: -1
  },

  // FooterCategories
  containerFooter: {
    top: isTablet ? 10 : 0,
    height: 80,
    width: 120,
    borderColor: '#D3D3D3',
    borderWidth: 0.5,
    borderRadius: 5,
    backgroundColor: '#E6E6E6'
  },
  btnContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  btnText: {
    color: '#505050',
    fontSize: isTablet ? sizes.textBig : sizes.textSmall,
    fontFamily: fonts.QuicksandBold,
    textAlign: 'center'
  }
});

export default styles;
