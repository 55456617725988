import React from 'react';
import { Text, Image, View } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import ProductImage from '../../../components_base/src/components/Image';
import Images from '../../../assets/Images';
import PropTypes from 'prop-types';
import getImageProd from '~/utils/GetAllImagesProds';

function ProductItem({ onRemove, product, configImageSouce }) {
  const sourceImage = getImageProd(product, configImageSouce);

  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
      }}>
      <ProductImage
        style={{ width: 45, height: 45, marginTop: 1 }}
        source={sourceImage}
        useCache={false}
        bottomBar
      />
      <Text style={{ width: '80%' }}>{product.nome}</Text>
      <Touchable onPress={() => onRemove(product)}>
        <Image source={Images.iconClose} style={{ width: 20, height: 20 }} />
      </Touchable>
    </View>
  );
}

ProductItem.propTypes = {
  onRemove: PropTypes.func,
  product: PropTypes.objectOf(PropTypes.any),
  configImageSouce: PropTypes.objectOf(PropTypes.any),
};

export default ProductItem;
