import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import OrdersModule from '~/modules/OrdersModule';

export const ORDER_SELECT_FETCH_REQUEST = 'ORDER_SELECT_FETCH_REQUEST';
export const ORDER_SELECT_FETCH_SUCCESS = 'ORDER_SELECT_FETCH_SUCCESS';
export const ORDER_SELECT_FETCH_FAILURE = 'ORDER_SELECT_FETCH_FAILURE';

const getOrder = payload => {
  const info = new Promise((resolve, reject) => {
    const { pkPedido } = payload;
    OrdersModule.detalhesPedido(pkPedido, resolve, reject);
  });
  const items = new Promise((resolve, reject) => {
    const { pkPedido } = payload;
    OrdersModule.listItensOrder(pkPedido, resolve, reject);
  });

  return Promise.all([info, items]);
};

export const getListOrder = createAsyncThunk(
  'order/getListOrder',
  async (payload, { getState }) => {
    try {
      const data = await getOrder(payload);
      const info = JSON.parse(data[0]);
      const items = JSON.parse(data[1]);
      return { info, items };
    } catch (error) {
      console.tron.log('error', error.message);
      throw new Error(error.message);
    }
  },
);

const initialState = {
  type: '',
  loading: false,
  erro: false,
  payload: {
    items: [],
    info: [],
  },
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getListOrder.pending, state => {
      state.type = ORDER_SELECT_FETCH_REQUEST;
      state.loading = true;
    });
    builder.addCase(getListOrder.fulfilled, (state, action) => {
      state.type = ORDER_SELECT_FETCH_SUCCESS;
      state.loading = false;
      state.erro = false;
      state.payload = action.payload;
    });
    builder.addCase(getListOrder.rejected, (state, action) => {
      state.type = ORDER_SELECT_FETCH_FAILURE;
      state.loading = false;
      state.erro = true;
      state.payload = action.payload;
    });
  },
});

const { reducer } = orderSlice;

export default reducer;
