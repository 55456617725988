/* @flow */

import React from 'react';
import {
  BackHandler,
  Image,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import Photo from './Photo';
import {launchImageLibrary} from 'react-native-image-picker';
import GPSManager from '../../../controllers/GPS/GPSManager';
import GpsHelper from '../../../utils/GpsHelper';
import Gps from '../../../../components_base/src/components/Gps/Gps';
import Images from '@assets/Images';
import _ from 'lodash';
import {
  savePhotoAnswer,
  SAVE_PHOTO_ANSWER_FETCH_FAILURE,
  SAVE_PHOTO_ANSWER_FETCH_SUCCESS,
} from '~/store/photoAnswerActivity/slice';
import { connect } from 'react-redux';
import CustomAlert from '../../../components/CustomAlert';
import AndroidOpenSettings from 'react-native-android-open-settings';
import { color } from '~/common';
import { fonts } from '../../../utils/Fonts';
import { Button } from '../../../../components_base/src/components';
import translate from '../../../locales';
import AddressAltCache from '~/cache/AddressAltCache';

import CachePolistore from '~/controllers/Accounts/Cache/CachePolistore';
import { syncPhotos } from '~/store/photosSync/slice';
import { createNotifyError, createNotifyInfo } from '~/components/Web/ToastNotify';
import { showAlert } from '~/components/Web/Alert';
import CacheAccount from '~/controllers/Accounts/Cache/CacheAccount';
import syncUploadPhotos from './UploadPhotosOnlyWeb.web';

type Props = {
  item: {
    kind_key: string,
    required: boolean,
    name: string,
    company: string,
    id: string,
  },
  photoAnswer: any,
  dateSelected: any,
  dispatch: any,
  saveAnswerActivity: (
    payloadAnswer: Object<any>,
    successSaveAnswer: Function<any>,
    failureSaveAnswer: Function<any>,
  ) => void,
  configLibFetch: any,
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    paddingHorizontal: 16,
    paddingVertical: 16,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    color: '#000',
    fontSize: 16,
    fontFamily: fonts.QuicksandBold,
  },
  rivalIcon: {
    width: 24,
    height: 24,
    tintColor: color.redBadge,
  },
  modal: {
    width: 100,
    height: 100,
  },
});

const S3 = 'https://s3.amazonaws.com/images.polisuite.process.com.br/media';

const BEFORE_AND_AFTER = 'before_and_after';

class PhotoContainer extends React.Component<Props, void> {
  constructor(props) {
    super(props);

    this.payloadAnswer = {};
    this.state = {
      photos: [],
      message: '',
      dateOpen: '',
      type: '',
      valueSelected: '',
      isSetup: false,
      showModal: false,
      extra_info: '',
      extra_components: '',
      loading: false,
      showBackPressAlert: false,
      afterSrc: null,
      beforeSrc: null,
      location: {
        accuracy: Infinity,
        altitude: null,
        heading: null,
        latitude: null,
        longitude: null,
        speed: 0.0,
        timestamp: 0,
      },
    };

    this.photos = {
      beforeSrc: [],
      afterSrc: [],
    };
    this.action = '';

    this.addressAltSelected = false;
    this.gpsManager = new GPSManager();
    this.cachePolistore = new CachePolistore();
  }

  async UNSAFE_componentWillMount() {
    const itemSetup = _.get(this.props.route.params.pushObject, 'item.setup', {});
    const activitySetup = JSON.parse(itemSetup);
    const action = _.get(activitySetup, 'action', '');

    if (action === 'before_and_after') {
      this.action = action;
      this.getBeforeAfterData();
    } else {
      const payloadPhoto = _.get(this.props.route.params.pushObject, 'item.answer.payload.photo');
      let photos = payloadPhoto && payloadPhoto.length > 0 ? payloadPhoto : [];
      const content = _.get(this.props.route.params.pushObject, 'item.answer.payload.content', '');
      let extra_info = _.get(
        this.props.route.params.pushObject,
        'item.answer.payload.extra_info',
        this.state.extra_info,
      );
      const startDate = _.get(
        this.props,
        'item.answer.start_date',
        new Date().toISOString(),
      );

      photos = photos.map(photo => ({ ...photo }));

      let setupType = '';

      let setup;
      if (this.props.route.params.pushObject.item.setup) {
        setup = JSON.parse(this.props.route.params.pushObject.item.setup);
      }

      if (setup && setup.type) {
        setupType = setup.type;
      }

      let { valueSelected, isSetup, extra_components } = this.state;

      if (setup && setup.extra_components) {
        isSetup = true;
        extra_components = setup.extra_components;
      }
      if (typeof extra_info !== 'undefined') {
        valueSelected = extra_info;
        extra_info = extra_info;
      }

      let fileExists = false;
      for (const photo of photos) {
        fileExists = photo.base64 ? true : false;
        if (!fileExists && !photo.deleted_at) {
          photo.deleted_at = new Date().toISOString();
        }
      }

      this.setState({
        photos,
        message: content,
        dateOpen: startDate,
        type: setupType,
        isSetup,
        extra_components,
        valueSelected,
        extra_info,
      });
    }
  }

  async componentDidMount() {
    if (!_.isObject(this.addressAltSelected)) {
      const event = _.get(this.props.route.params.pushObject, 'event', {});
      const { pkey: pkeyClient } = event;
      const addressAlt = await AddressAltCache.getAddressAlt(pkeyClient);
      this.addressAltSelected = addressAlt;
    }
  }

  getBeforeAfterData() {
    const payloadPhotoBefore = _.get(this.props.route.params.pushObject, 'item.answer.payload.before');
    const before =
      payloadPhotoBefore && payloadPhotoBefore.length > 0
        ? payloadPhotoBefore
        : [Images.iconCamera];
    const payloadPhotoAfter = _.get(this.props.route.params.pushObject, 'item.answer.payload.after');
    const after =
      payloadPhotoAfter && payloadPhotoAfter.length > 0
        ? payloadPhotoAfter
        : [Images.iconCamera];
    const content = _.get(this.props.route.params.pushObject, 'item.answer.payload.content', '');
    const startDate = _.get(
      this.props.route.params.pushObject,
      'item.answer.start_date',
      new Date().toISOString(),
    );

    const beforeData = before.slice(-1)[0];
    const afterData = after.slice(-1)[0];

    if (beforeData !== Images.iconCamera) {
      this.photos.beforeSrc.push(beforeData);
    }
    if (afterData !== Images.iconCamera) {
      this.photos.afterSrc.push(afterData);
    }

    this.setState({
      beforeSrc: beforeData,
      afterSrc: afterData,
      message: content,
      dateOpen: startDate,
    });
  }

  UNSAFE_componentWillReceiveProps({ photoAnswer }) {
    (async () => {
      if (photoAnswer.type === this.props.photoAnswer.type) {
        return;
      }
      if (photoAnswer.type !== SAVE_PHOTO_ANSWER_FETCH_SUCCESS) {
        return;
      }
      this.setState({ loading: false });
      this.props.route.params.pushObject.saveAnswerActivity(
        this.payloadAnswer,
        this.successSaveAnswer,
        this.failureSaveAnswer,
      );
      if (photoAnswer.payload) {
        await this.props.dispatch(syncPhotos());
      }
    })();

    (() => {
      if (photoAnswer.type === this.props.photoAnswer.type) {
        return;
      }
      if (photoAnswer.type !== SAVE_PHOTO_ANSWER_FETCH_FAILURE) {
        return;
      }
      this.setState({ loading: false }, () => {
        createNotifyError(translate('cantSaveAnswer') + '. ' + translate('pleaseTryAgain'));
      });
    })();
  }

  onChangeMessage = message => {
    this.setState({ message });
  };

  selectValue = value => {
    this.setState({ valueSelected: value });
  };

  uploadPayloadAnswer = () => {
    const { photos, beforeSrc, afterSrc, location } = this.state;
    const { item, event } = this.props.route.params.pushObject;
    const setup = JSON.parse(item.setup);

    const message = GpsHelper.validateRulesGPSClient(setup, event, location);
    if (!_.isEmpty(message)) {
      createNotifyInfo(message);
      this.setState({ loading: false });
      return;
    }

    const photosNotDeleted = photos.filter(ph => !ph.deleted_at);
    const isBeforeAfter = !!beforeSrc || !!afterSrc;
    let payload = {
      photo: photosNotDeleted,
      content: this.state.message,
      location,
      extra_info: this.state.valueSelected,
    };

    if (isBeforeAfter) {
      payload = {
        ...payload,
        photo: this.photos.beforeSrc.concat(this.photos.afterSrc),
        before: this.photos.beforeSrc,
        after: this.photos.afterSrc,
      };
    }

    // delete payload.photo.base64;
    payload.photo.forEach(photo => {
      delete photo.base64;
    });

    this.payloadAnswer = {
      dateSelected: this.props.route.params.pushObject.dateSelected,
      dateOpen: this.state.dateOpen,
      latitude: location.latitude,
      longitude: location.longitude,
      payload,
      taskId: this.props.route.params.pushObject.item.id,
    };
    syncUploadPhotos(this.payloadAnswer);
    this.props.dispatch(savePhotoAnswer(this.payloadAnswer));
  };

  onFinishTypeActionPhoto = () => {
    if (this.action === BEFORE_AND_AFTER) {
      const { message, beforeSrc, afterSrc } = this.state;

      const hasBeforeAfterPhoto = !!(
        !!beforeSrc &&
        beforeSrc !== Images.iconCamera &&
        !!afterSrc &&
        afterSrc !== Images.iconCamera
      );
      const hasMessage = message !== '';

      if (hasBeforeAfterPhoto || hasMessage) {
        this.uploadPayloadAnswer();
      } else {
        createNotifyInfo(translate('putSomeDataToSave'));
      }
    } else {
      const { isSetup, valueSelected, message } = this.state;
      const hasMessage = message !== '';
      const isTrue = item => item === true;

      const withSetup = [isSetup, valueSelected !== '', hasMessage].every(
        isTrue,
      );
      const withOutSetup = [!isSetup, hasMessage].every(isTrue);

      const isUploadPayloadAnswer = withSetup || withOutSetup;

      if (isUploadPayloadAnswer) {
        this.uploadPayloadAnswer();
      } else {
        createNotifyInfo(translate('putSomeDataToSave'));
      }
    }
  };

  onFinish = () => {
    this.setState({ loading: true });

    const { photos } = this.state;
    const payloadPhoto = _.get(this.props.route.params.pushObject, 'item.answer.payload.photo', []);
    const validPhotos = photos.filter(photo => !photo.deleted_at);

    const hasPhotos =
      validPhotos &&
      validPhotos.length > 0 &&
      payloadPhoto.length !== validPhotos.length; 

    if (hasPhotos) {
      this.uploadPayloadAnswer();
    } else {
      this.onFinishTypeActionPhoto();
    }

    this.setState({ loading: false });
  };

  handleBackPress = () => {
    const { photos } = this.state;
    const payloadPhoto = _.get(this.props.route.params.pushObject, 'item.answer.payload.photo', []);

    const hasPhotos =
      photos &&
      photos.length > 0 &&
      _.find(photos, photo => !photo.deleted_at) &&
      payloadPhoto.length !== photos.length;

    if (hasPhotos) {
      this.setState({
        showBackPressAlert: true,
      });
      return true;
    }
    this.popScreen();
    return false;
  };

  popScreen = () => this.props.navigation.pop();

  componentDidAppear() {
    this.backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      this.handleBackPress,
    );
  }

  componentDidDisappear() {
    this.backHandler?.remove();
  }

  navigationButtonPressed(event) {
    switch (event.buttonId) {
      case 'backPress':
        this.handleBackPress();
        break;
      default:
        break;
    }
  }

  openAppSettings = () => {
    this.setState({ showModal: false }, () => {
      AndroidOpenSettings.appDetailsSettings();
    });
  };

  onPressShowCamera = ({ actionType = '' }) => {
    const options = {
      mediaType: 'photo',
      // quality: PHOTO_QUALITY,
      // maxWidth: PHOTO_WIDTH,
      // maxHeight: PHOTO_HEIGTH,
      noData: true,
      storageOptions: {
        skipBackup: true,
        path: 'images',
      },
    };

    launchImageLibrary(options, async response => {
        if (response.didCancel) {
          console.log('User cancelled image picker');
        } else if (response.error) {
          this.setState({ showModal: true });
        } else if (response.customButton) {
          console.log('User tapped custom button: ', response.customButton);
        } else {
          const source = await this.getImgSource(response);
          if (actionType) {
            this.setState({ [actionType]: source }, () => {
                this.photos[actionType].push(this.state[actionType]);
            });
          } else {
            let { photos } = this.state;
  
            photos = [...photos];
            photos.unshift(source);
            this.setState({ photos, showBackPressAlert: false }, () => {
                console.log('this.state.photos', this.state.photos);
            });
          }
        }
      });

  };


  getfilePathServer = async fileName => {
    const { bucket_config: bucketConfig } = this.props.configLibFetch.payload;
    const url = _.get(bucketConfig, 'params.url', false);
    const tenant = _.get(bucketConfig, 'params.credential_secret_key', false);

    const dataUser = await new CacheAccount().getDataAccount() || null;

    const codmaq = dataUser?.user?.cod_maq || null;


    if (url && tenant && codmaq) {
      return `${url}/${codmaq}/${fileName}?tenant=${tenant}`;
    }

    return `${S3}/${fileName}`;
  };


  base64ToImageFile = async (base64String) => {
    try {
      let mimeType = '';
      let base64Data = '';
  
      // Verifica e remove o prefixo da string Base64, se houver
      if (base64String.startsWith('data:image/jpeg;base64,')) {
        mimeType = 'image/jpeg';
        base64Data = base64String.replace(/^data:image\/jpeg;base64,/, '');
      } else if (base64String.startsWith('data:image/jpg;base64,')) {
        mimeType = 'image/jpg';
        base64Data = base64String.replace(/^data:image\/jpg;base64,/, '');
      } else if (base64String.startsWith('data:image/png;base64,')) {
        mimeType = 'image/png';
        base64Data = base64String.replace(/^data:image\/png;base64,/, '');
      } else {
        throw new Error('Formato de imagem não suportado');
      }

      const response = await fetch(base64String); 
      const blob = await response.blob(); 
      return blob;
  
    } catch (error) {
      console.error('Erro ao converter Base64 para Blob:', error);
      return null;
    }
  };
  
  getImgSource = async (response, field = 'photo') => {
    try {
      const base64String = response.assets[0].uri;
      const base64ToFile = await this.base64ToImageFile(response.assets[0].uri);

      const fileType = base64ToFile?.type.split('/');

      const fileName = `${Date.now()}.${fileType[1]}`;
  
      return {
        uri: base64ToFile,
        isStatic: true,
        fileName: fileName,
        filePath: `file://fakepath/${fileName}`,
        type: base64ToFile?.type || 'image/jpeg',
        base64: base64String,
        srcInServer: await this.getfilePathServer(fileName),
      };
    } catch (error) {
      console.error('Erro ao obter a fonte da imagem:', error);
      return null;
    }
  };

  onRemovePhoto = index => {
    showAlert(translate('removeImage'), translate('wantRemoveImage'), () => {
        if (index === 'beforeSrc') {
            this.photos[index].pop();
            this.setState({ beforeSrc: null });
            return;
        }
        if (index === 'afterSrc') {
            this.photos[index].pop();
            this.setState({ afterSrc: null });
            return;
        }
    
        let { photos } = this.state;
        photos = [...photos];
        photos[index].deleted_at = new Date().toISOString();
        this.setState({ photos, showBackPressAlert: false });
        }
    );
  };

  successSaveAnswer = () => this.setState({ loading: false });
  failureSaveAnswer = () => this.setState({ loading: false });

  hideModal = () => this.setState({ showBackPressAlert: false });

  onLocationChange = location => {
    if (location) {
      this.setState({ location });
    }
  };

  renderGpsComponent() {
    const { location } = this.state;
    const { pkey: pkeyClient } = _.get(this.props, 'event', {});
    const gpsPlaces = this.addressAltSelected || this.props.event;

    return (
      <Gps
        places={[GpsHelper.parseGpsEvent(gpsPlaces)]}
        pkeyClient={pkeyClient}
        location={location || {}}
        onLocationChange={this.onLocationChange}
        showPermitionLocation
        useViewExpandable
      />
    );
  }

  render() {
    const {
      photos,
      message,
      extra_info,
      extra_components,
      loading,
      showBackPressAlert,
      beforeSrc,
      afterSrc,
    } = this.state;

    const itemSetup = _.get(this.props.route.params.pushObject, 'item.setup', {});
    const activitySetup = JSON.parse(itemSetup);
    const photoAction = _.get(activitySetup, 'action', '');

    return (
      <View>
        {this.state.type.localeCompare('rival') === 0 ? (
          <View style={styles.container}>
            <View>
              <Image source={Images.iconTrophy} style={styles.rivalIcon} />
            </View>
            <View style={{ flex: 1, padding: 4 }}>
              <Text style={styles.title}>{`${translate(
                'competitorAchievement',
              )}`}</Text>
            </View>
          </View>
        ) : null}

        <CustomAlert showModal={this.state.showModal} title="Oops!" noButtons>
          <Text
            style={[styles.loaderText, { marginTop: 16, marginBottom: 22 }]}>
            {`${translate('messageAccesCamera')}`}
          </Text>
          <Button
            title={`${translate('openSettings')}`}
            onPress={() => this.openAppSettings()}
          />
        </CustomAlert>

        <Photo
          photos={photos}
          onPressShowCamera={this.onPressShowCamera}
          onFinish={this.onFinish}
          onChangeMessage={this.onChangeMessage}
          onRemovePhoto={this.onRemovePhoto}
          message={message}
          extra_info={extra_info}
          extra_comp={extra_components}
          selectValue={this.selectValue}
          loading={loading}
          showBackPressAlert={showBackPressAlert}
          onPressExit={this.popScreen}
          hideModal={this.hideModal}
          photoAction={photoAction}
          beforeSrc={beforeSrc}
          afterSrc={afterSrc}
          gpsComponent={this.renderGpsComponent()}
          setup={activitySetup}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return {
    photoAnswer: state.savePhotoAnswer,
    syncFetch: state.syncFetch,
    configLibFetch: state.configLibFetch,
  };
}

export default connect(mapStateToProps)(PhotoContainer);
