import Repository from './Repository';

export default class AnswersRepository extends Repository {
  constructor() {
    super();
    this.tableName = 'answers';
  }

  deleteAll(callback) {
    this.db.transaction((tx) => {
      const sql = `DELETE FROM ${this.tableName};`;
      tx.executeSql(sql, [], callback);
    });
  }

  delete(answer, callback) {
    this.db.transaction((tx) => {
      const sql = `DELETE FROM ${this.tableName} WHERE id = ${answer.id};`;
      tx.executeSql(sql, [], callback);
    });
  }

  get(callback) {
    this.db.transaction((tx) => {
      const select = `SELECT ${this.getDefaultProjection(this.tableName, false)} FROM ${this.tableName} LIMIT 1;`;
      tx.executeSql(select, [], (tx, results) => {
        const answer = results.rows.item(0);
        callback(answer);
      }, this.errorDb);
    });
  }

  updateUserTaskTempIdToReservedId(tempId, reservedId) {
    return new Promise((resolve, reject) => {
      this.db.transaction((tx) => {
        const select = `UPDATE ${this.tableName} SET user_task_list = '${reservedId}', sync_status = 'pending_to_sync' WHERE user_task_list = '${tempId}';`;
        tx.executeSql(select, [], () => {
          resolve();
        }, (error) => {
          reject(error);
        });
      });
    });
  }

  getLastSequenceAnswered(userTaskList) {
    return new Promise((resolve, reject) => {
      this.db.transaction((tx) => {
        const select = `SELECT MAX(sequence) FROM ${this.tableName} WHERE user_task_list = '${userTaskList}' AND status = 'done';`;
        tx.executeSql(select, [], (tx, results) => {
          if (results.rows.length > 0) {
            resolve(results.rows.item(0));
          } else {
            resolve(0);
          }
        }, (error) => {
          reject(error);
        });
      });
    });
  }

  async getByUserTaskListAndTask(userTaskListId, taskId) {
    return new Promise((resolve, reject) => {
      this.db.transaction((tx) => {
        const select = `SELECT ${this.getDefaultProjection(this.tableName, false)} FROM ${this.tableName} WHERE strftime('%Y-%m-%d',datetime(start_date) , 'localtime') = strftime('%Y-%m-%d', 'now', 'localtime') AND user_task_list = '${userTaskListId}' AND task = '${taskId}';`;
        tx.executeSql(select, [], (tx, results) => {
          const { rows } = results;
          if (rows.length > 0) {
            const answer = results.rows.item(0);
            resolve(this.getValuesFromDatabase(answer));
          } else {
            resolve(null);
          }
        }, error => reject(error));
      });
    });
  }

  async getByUserTaskList(userTaskListId) {
    return new Promise((resolve, reject) => {
      this.db.transaction((tx) => {
        const select = `SELECT ${this.getDefaultProjection(this.tableName, false)} FROM ${this.tableName} WHERE user_task_list = '${userTaskListId}';`;
        tx.executeSql(select, [], (tx, results) => {
          const { rows } = results;
          const len = results.rows.length;
          const objects = [];
          for (let i = 0; i < len; i += 1) {
            objects.push(results.rows.item(i));
          }
          resolve(objects);
        }, error => reject(error));
      });
    });
  }

  successDb() {

  }

  errorDb(err) {
    console.tron.log('err db', err);
  }
}
