import { BackHandler, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import RNExitApp from 'react-native-exit-app';

const exitApp = async () => {
  if (Platform.OS === 'android') {
    BackHandler.exitApp();
    await AsyncStorage.clear();
  } else {
    RNExitApp.exitApp();
    await AsyncStorage.clear();
  }
};

export default exitApp;
