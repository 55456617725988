import types from '../../../components/Form/types';
import TextUtils from '../../../utils/TextUtils';
import _ from 'lodash';
import moment from 'moment';

export const transformNumber = value =>
  Number(value.replace(/\./gm, '').replace(',', '.'));

export function format(type, value) {
  if (type === types.MONEY.name && typeof value === 'string') {
    return transformNumber(value);
  }
  if (type === types.DATE.name && typeof value === 'string') {
    return moment(value, 'DD/MM/YYYY').unix();
  }
  return value;
}

const fieldFilter = (fn, name) => field => fn(field[name]);

const isBelow = (max, type) => value => format(type, value) <= max;

const isOver = (min, type) => value => min <= format(type, value);

const isEqual = (min, type) => value => min === format(type, value);

const isEqualCheck = values => value => {
  const contains = values.length > 0 ? values.includes(value) : true;
  return contains;
};

const isInRange = (range, type) => value => {
  if (range.indexOf(undefined) === -1) {
    return (
      isOver(format(type, range[0]))(format(type, value)) &&
      isBelow(format(type, range[1]))(format(type, value))
    );
  } else if (range[1] === undefined) {
    return isOver(format(type, range[0]))(format(type, value));
  }
  return isBelow(format(type, range[1]))(format(type, value));
};

const getFilterByType = type => {
  switch (type) {
    case types.MONEY.name:
    case types.NUMBER.name:
    case types.INT.name:
      return isBelow;
    case types.DATE.name:
      return isInRange;
    case types.LIST.name:
    case types.COMBO.name:
      return isEqual;
    case types.CHECKLIST.name:
      return isEqualCheck;
    default:
      return () => () => {};
  }
};

const filter = (list, fields) =>
  fields.reduce(
    (accumulator, field) =>
      accumulator.filter(
        fieldFilter(
          getFilterByType(field.type)(field.value, field.type),
          field.field,
        ),
      ),
    list,
  );

const applyRegex = (textCheck, fieldText, showProductAsHTML) => {
  try {
    const regex = new RegExp(textCheck, 'gi');
    if (showProductAsHTML) {
      const fText = _.replace(fieldText, '<[^>]*>', '');
      return regex.test(fText);
    }
    return regex.test(fieldText);
  } catch (error) {
    return false;
  }
};

const getFieldTextCombo = (obj, fields) => {
  let fieldText = '';
  fields.forEach(field => {
    const text = TextUtils.slugfy(_.deburr(obj[field]));
    fieldText += text;
  });
  return fieldText;
};

export const filterByTextFields = (list, fields, text) =>
  list.filter(obj =>
    fields.some(field => {
      const fieldText = _.isArray(field)
        ? getFieldTextCombo(obj, field)
        : TextUtils.slugfy(_.deburr(obj[field]));
      const textCheck = TextUtils.slugfy(_.deburr(text));
      const result = applyRegex(textCheck, fieldText, field === 'html');
      return result;
    }),
  );

export const getFieldsSearch = list =>
  list && list.length ? Object.keys(list[0]) : [];

export default filter;
