/* @flow */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ActivitiesModule from '../../modules/ActivitiesModule';

export const ADDRESS_ALTERNATIVE_REQUEST = 'ADDRESS_ALTERNATIVE_REQUEST';
export const ADDRESS_ALTERNATIVE_SUCCESS = 'ADDRESS_ALTERNATIVE_SUCCESS';
export const ADDRESS_ALTERNATIVE_FAILURE = 'ADDRESS_ALTERNATIVE_FAILURE';
export const ADDRESS_ALTERNATIVE_SELECTED = 'ADDRESS_ALTERNATIVE_SELECTED';

export const getAddressAlternative = createAsyncThunk(
  'addressAlternativeFetch/getAddressAlternative',
  async ({ pkeyClient }) => {
    let data = '';
    try {
      data = await ActivitiesModule.listAddressAlternative(pkeyClient);
    } catch (error) {
      console.tron.log(
        'PE_APP | file: slice.js:17 | ADDRESS_ALTERNATIVE_FAILURE =>',
        error.message,
      );
      throw error;
    }
    return data;
  },
);

const initialState = {
  type: '',
  loading: false,
  erro: false,
  payload: [],
};

const addressAlternativeFetch = createSlice({
  name: 'addressAlternativeFetch',
  initialState,
  reducers: {
    setAddressSelected: (state, action) => {
      state.type = ADDRESS_ALTERNATIVE_SELECTED;
      state.selected = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getAddressAlternative.pending, state => {
      state.type = ADDRESS_ALTERNATIVE_REQUEST;
      state.loading = true;
    });
    builder.addCase(getAddressAlternative.fulfilled, (state, action) => {
      const data = JSON.parse(action.payload);
      state.type = ADDRESS_ALTERNATIVE_SUCCESS;
      state.loading = false;
      state.erro = false;
      state.payload = data;
    });
    builder.addCase(getAddressAlternative.rejected, (state, action) => {
      state.type = ADDRESS_ALTERNATIVE_FAILURE;
      state.loading = false;
      state.erro = true;
      state.payload = action.payload;
    });
  },
});

const { actions, reducer } = addressAlternativeFetch;

export const { setAddressSelected } = actions;

export default reducer;
