import { createAsyncThunk } from '@reduxjs/toolkit';
import { cancelOrderActive, getOrder } from './utilsLib';

export const EDIT_CART_FETCH_REQUEST = 'EDIT_CART_FETCH_REQUEST';
export const EDIT_CART_FETCH_SUCCESS = 'EDIT_CART_FETCH_SUCCESS';
export const EDIT_CART_FETCH_FAILURE = 'EDIT_CART_FETCH_FAILURE';

export const editOrderFetch = createAsyncThunk(
  'orderFetch/editOrderFetch',
  async payload => {
    try {
      await cancelOrderActive();
      const val = await getOrder(payload, true);
      const order = JSON.parse(val);
      return order;
    } catch (error) {
      console.log('error', error);
      throw new Error(error.message);
    }
  },
);
