/* @flow */

import React, { Component } from 'react';
import { View, StyleSheet, Text, ScrollView } from 'react-native';

import MoreInfo from '../../../../components_base/src/components/MoreInfo/MoreInfo';
import ButtonIconInfo from '../../../components/ButtonsIcon/ButtonIconInfo';
import { style, color } from '~/common';
import { SubHeader } from '~/components';
import OrderInfo from './OrderInfo';
import { fonts } from '../../../utils/Fonts';
import translate from '../../../locales';
import HTML from 'react-native-render-html';
import { CalculatedValue } from '~/../components_base/src/components';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  number: {
    color: color.grayMid,
    fontFamily: fonts.QuicksandBold,
    fontSize: 14,
    marginRight: 6,
  },
  clientName: {
    color: '#000',
    fontSize: 16,
    fontFamily: fonts.QuicksandBold,
  },
  price: {
    color: '#000',
    fontSize: 18,
  },
  title: {
    color: color.grayMid,
    fontSize: 14,
  },
  titleBlack: {
    color: '#000',
    fontSize: 14,
  },
  subheader: {
    backgroundColor: color.grayContainer,
    marginHorizontal: 0,
    padding: 16,
  },
  dateContainer: {
    marginTop: 8,
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  date: {
    textAlign: 'right',
    fontSize: 12,
    fontFamily: fonts.QuicksandItalic,
    color: color.grayMid,
  },
});

class Info extends Component {
  state = {
    modalMoreInfosVisible: false,
  };

  renderOrderHeader = () => {
    const { showOrderDetailHeaderAsHTML, order, info } = this.props;
    if (showOrderDetailHeaderAsHTML && info.html) {
      return (
        <View style={{ width: '90%' }}>
          <HTML source={{ html: info.html }} />
        </View>
      );
    }

    return (
      <View style={{ width: '90%' }}>
        <Text style={styles.clientName}>{order?.cliente}</Text>
        <CalculatedValue value={order?.valor} style={styles.price} />
        <Text style={styles.title}>
          {`${translate('situation')}`}:
          <Text style={styles.titleBlack}>{order?.estado_valor}</Text>
        </Text>
      </View>
    );
  };

  renderHeader() {
    const { order, info, showOrderDetailHeaderAsHTML } = this.props;
    const renderButtonHtml = Boolean(info.html && !showOrderDetailHeaderAsHTML);

    return (
      <View>
        <View style={styles.container}>
          <View style={style.rowDirection}>
            <Text style={styles.number}>
              {order?.codigo_interno !== '0'
                ? order?.codigo_interno
                : order?.numero_pedido}
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            {this.renderOrderHeader()}
            {renderButtonHtml && (
              <ButtonIconInfo
                onPress={() => {
                  this.setState({ modalMoreInfosVisible: true });
                }}
              />
            )}
          </View>
          <View style={styles.dateContainer}>
            <Text style={styles.date}>{`${translate('orderStartedAt')}:`}</Text>
            <Text style={[styles.date, { marginLeft: 8 }]}>
              {order?.data.replace('-', '/').replace('-', '/')}
            </Text>
          </View>
        </View>
        <SubHeader style={styles.subheader}>{`${translate(
          'informations',
        )}`}</SubHeader>
      </View>
    );
  }

  renderBody() {
    const { info } = this.props;

    return (
      <View>
        <View style={{ flexDirection: 'row' }}>
          <OrderInfo
            info={`${translate('orderNumber')}`}
            value={info?.numpedido}
          />
          <OrderInfo info={`${translate('date')}`} value={info?.data} />
        </View>

        <View style={{ flexDirection: 'row' }}>
          <OrderInfo
            info={`${translate('dateToSend')}`}
            value={info?.data_envio}
          />
          <OrderInfo info={`${translate('customer')}`} value={info?.cliente} />
        </View>

        <View style={{ flexDirection: 'row' }}>
          <OrderInfo
            info={`${translate('items')}`}
            value={info?.valor_itens}
            type="calculated"
          />
          <OrderInfo
            info={`${translate('billedItems')}`}
            value={info?.valor_itens_faturados}
            type="calculated"
          />
        </View>

        <View style={{ flexDirection: 'row' }}>
          <OrderInfo
            info={`${translate('partial')}`}
            value={info?.valor_parcial}
            type="calculated"
          />
          <OrderInfo
            info={`${translate('partialBilled')}`}
            value={info?.valor_pedido_faturado}
            type="calculated"
          />
        </View>

        <View style={{ flexDirection: 'row' }}>
          <OrderInfo
            info={`${translate('liquid')}`}
            value={info.valor_liquido}
            type="calculated"
          />
          <OrderInfo
            info={`${translate('billedLiquid')}`}
            value={info.valor_liquido_faturado}
            type="calculated"
          />
        </View>

        <View style={{ flexDirection: 'row' }}>
          <OrderInfo
            info={`${translate('taxSubstituiton')}`}
            value={info.valor_subsTrib}
            type="calculated"
          />
          <OrderInfo info="IPI" value={info.valor_ipi} type="calculated" />
        </View>

        <View style={{ flexDirection: 'row' }}>
          <OrderInfo info="Desconto pé talão 1" value={info.desconto1} />
          <OrderInfo info="Desconto pé talão 2" value={info.desconto2} />
        </View>

        <View style={{ flexDirection: 'row' }}>
          <OrderInfo
            info={`${translate('amountOfBoxes')}`}
            value={info.valor_caixa}
            type="calculated"
          />
          <OrderInfo
            info={`${translate('amountOfBilledBoxes')}`}
            value={info.valor_caixa_faturada}
          />
        </View>

        <View style={{ flexDirection: 'row' }}>
          <OrderInfo info={`${translate('status')}`} value={info.status} />
          <OrderInfo info={`${translate('situation')}`} value={info.situacao} />
        </View>

        <SubHeader style={styles.subheader}>{`${translate(
          'salesCondition',
        )}`}</SubHeader>
        <View style={{ flexDirection: 'row' }}>
          <OrderInfo
            info={`${translate('salesOrganization')}`}
            value={info.org_venda}
          />
          <OrderInfo
            info={`${translate('salesType')}`}
            value={info.tipo_venda}
          />
        </View>

        <View style={{ flexDirection: 'row' }}>
          <OrderInfo
            info={`${translate('paymentOptions')}`}
            value={info.meio_pag}
          />
          <OrderInfo
            info={`${translate('priceTable')}`}
            value={info.tabela_preco}
          />
        </View>

        <View style={{ flexDirection: 'row' }}>
          <OrderInfo info={`${translate('deadline')}`} value={info.prazo} />
          <OrderInfo info={`${translate('budget')}`} value={info.orcamento} />
        </View>

        {!!(info && info.cliente_fatura) && (
          <View style={{ flexDirection: 'row' }}>
            <OrderInfo
              info={`${translate('customersInvoice')}`}
              value={info.cliente_fatura}
            />
          </View>
        )}

        <MoreInfo
          modalMoreInfosVisible={this.state.modalMoreInfosVisible}
          htmlContent={info.html}
          onModalClose={() => {
            this.setState({ modalMoreInfosVisible: false });
          }}
        />
      </View>
    );
  }

  render() {
    return (
      <ScrollView style={{ backgroundColor: '#FFF' }}>
        {this.renderHeader()}
        {this.renderBody()}
      </ScrollView>
    );
  }
}

Info.propTypes = {
  order: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  showOrderDetailHeaderAsHTML: PropTypes.bool.isRequired,
};

export default Info;
