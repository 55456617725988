import { createAsyncThunk } from '@reduxjs/toolkit';
import OrdersModule from '~/modules/OrdersModule';

export const ORDERS_DELETE_FETCH_REQUEST = 'ORDER_DELETE_FETCH_REQUEST';
export const ORDERS_DELETE_FETCH_SUCCESS = 'ORDER_DELETE_FETCH_SUCCESS';
export const ORDERS_DELETE_FETCH_FAILURE = 'ORDER_DELETE_FETCH_FAILURE';

const deleteOrder = order =>
  new Promise((resolve, reject) => {
    OrdersModule.cancelDeleteOrder(order.numero_pedido, resolve, reject);
  });

const getOrders = (payload, offset) => {
  const { chaveCliente, statudPedido, statusFiltro } = payload;

  return new Promise(async (resolve, reject) => {
    const pagination = { page: 0, offset, active: true };

    let active = true;
    let data = '';

    try {
      while (active) {
        const resp = await OrdersModule.listOrders(
          chaveCliente,
          statudPedido,
          statusFiltro,
          JSON.stringify(pagination),
        );
        data = `${data}${resp}`;

        if (!(resp.length > 0)) {
          active = false;
        }

        pagination.page += 1;
      }
      resolve(data);
    } catch (error) {
      reject(error.message);
    }
  });
};

export const orderDeleteFetch = createAsyncThunk(
  'orders/orderDeleteFetch',
  async (payload, { getState, dispatch }) => {
    try {
      const paramsBindQuery = {
        chaveCliente: '0',
        statudPedido: '-1',
        statusFiltro: '-1',
      };
      await deleteOrder(payload);

      const state = getState();
      const offset = state.configLibFetch.payload.tam_memoria_paginacao;
      try {
        const data = await getOrders(paramsBindQuery, offset);
        const orders = JSON.parse(data);
        return orders;
      } catch (error) {
        console.tron.log('error', error);
        throw new Error(error.message);
      }
    } catch (error) {
      console.tron.log('error', error);
      throw new Error(error.message);
    }
  },
);
