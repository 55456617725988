import React, { Component } from 'react';
import { Alert, BackHandler, Linking } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import RNFS from 'react-native-fs';
import Home from './Home';
import { connect } from 'react-redux';
import { setPushingScreen } from '../../store/pushingScreen/slice';
import {
  SCREEN_CLIENT_DETAIL,
  SCREEN_REPORTS,
  SCREEN_ORDERS,
  SCREEN_UPDATE_VERSION_APP,
  SCREEN_RESUME_ORDER,
  SCREEN_HOME,
  SCREEN_PRODUCT_DETAIL,
  SCREEN_COMPOUND_PRODUCT,
  SCREEN_CART,
} from '../screens';
import { CONFIG_LIB_SUCCESS } from '../../store/configLibFetch/slice';
import CacheAccount from '../../controllers/Accounts/Cache/CacheAccount';
import { listPhotos } from '~/store/photoAnswerActivity/listPhotosSlice';
import Sync from '../../services/resources/Sync';
import {
  SYNC_FINALLY,
} from '../../constants/Sync';
import _, { isEmpty } from 'lodash';
import AnswerController from '../../controllers/AnswerController';
import CacheModules from '../../utils/CacheModules';
import OrdersModule from '../../modules/OrdersModule';
import AccountsModule from '../../modules/AccountsModule';
import {
  MODULE_SALE,
  setModuleCurrent,
} from '../../store/makeOrderModule/action';
import {
  ORDER_FETCH_REQUEST,
  ORDER_FETCH_SUCCESS,
  orderClient,
  updateOrderScreen,
  updateStatusOpenOrder,
} from '../../store/ordersFetch/orderFetchSlice';
import setModuleIcon from './components/ModuleIconHelper';
import CacheTabs from '../../utils/CacheBottomTabs';
import { modulesConfig } from './components/HomeJSONUtils';
import translate from '../../locales';
import NotificationController from '~/controllers/notifications/NotificationController';
import Auth from '../../config/Auth';
import SQLiteHelper from '../../database/SQLiteHelper';
import verifyOrderPending from '../../utils/orderUtils';
import ServicesModule from '~/modules/ServicesModule';
import CacheDataController from '../../utils/CacheDataController';
import RouteSolicitationsResource, {
  KIND_REQUEST_STARTING_POINT,
} from '../../services/resources/RouteSolicitationsResource';
import { Navigation } from 'react-native-navigation';
import NavigationHelper from '../NavigationHelper';
import { getListClients } from '../../store/clientsFetch/slice';
import {
  setCurrentClient,
  setCurrentClientNoThunk,
} from '../../store/clientCurrent/slice';
import {
  showProgressSync,
  syncUpdateTimeAndStatus,
} from '../../store/syncFetch/slice';
import Snackbar from 'react-native-snackbar';
import { setSaleOpportunity } from '~/store/salesOpportunityFetch/slice';
import SaleOpportunityCache from '~/cache/SaleOpportunityCache';
import { getNotifications } from '~/store/notifications/slice';
import onPressHelper from '~/utils/onPressHelper';
import executeActionOrOpenScreenMessage from '~/utils/messages';
import { PRODUCTS_MAGNITUDES_SUCCESS } from '~/store/productsMagnitudes/slice';
import { updateItemFetch } from '~/store/ordersFetch/updateItemSlice';
import { currentScreenVisible } from '~/store/currentScreenVisible/slice';
import { configFetch } from '~/store/configLibFetch/slice';
import listGeneric from '~/services/resources/libFastSeller/listGeneric';
import { emp2long } from '~/utils/FastSellerUtils';
import { setBackRootShowModal } from '~/store/backRootShowModal/slice';

import PropTypes from 'prop-types';
import { createNotifyError, createNotifyInfo, createNotifySuccess } from '~/components/Web/ToastNotify';

import { handleKeyDown, setActionsMap } from '~/utils/KeyBindingsUtils';

class HomeContainer extends Component {
  constructor(props) {
    super(props);

    this.orderPending = {};

    this.state = {
      title: `${translate('syncInfo')}`,
      firstName: '',
      appModules: null,
      sizeAvailable: null,
      isInitialDownload: false,
      showFechamento: false,
      notSynchronizedPhotosSize: 0,
      notSynchronizedPhotosList: [],
      clients: [],
      hasOrderPending: false,
      totalOrderPending: 0,
      clientOrderPending: null,
      codClientOrderPending: null,
      config: {},
      showShortcuts: false,
    };
    this.photosToDelete = [];
    this.requestApproved = false;

    NotificationController.configure();

    this.props.navigation.addListener('focus', async () => {
      window.addEventListener('keydown', handleKeyDown);

      setActionsMap({
        Hoje: () => this.props.navigation.navigate('ItinenaryStack'),
        Clientes: () => this.props.navigation.navigate('ClientesStack'),
        Catalogo: () => this.props.navigation.navigate('Catalog'),
        Despesas: () => this.props.navigation.navigate('Expenses'),
        Pedidos: () => this.props.navigation.navigate('OrdersStack'),
        Relatorios: () => this.props.navigation.navigate('Reports'),
        Restaurar: () => this.openCart(true),
        Excluir: () => this.cancelActiveOrder(),
      });

      this.fetchUserName();
      this.verifyOrderPending();

      this.props.dispatch(
        currentScreenVisible({
          screen: this.props.componentId,
          screenName: SCREEN_HOME.name,
        }),
      );
    });

    this.props.navigation.addListener('blur', () => {
      window.removeEventListener('keydown', handleKeyDown);
    });
  }

  obtainUserDataLib = () =>
    new Promise((res, rej) => {
      AccountsModule.obtainUserData(res, rej);
    });

  async componentDidMount() {
    window.addEventListener('keydown', handleKeyDown);

    setActionsMap({
      Hoje: () => this.props.navigation.navigate('ItinenaryStack'),
      Clientes: () => this.props.navigation.navigate('ClientesStack'),
      Catalogo: () => this.props.navigation.navigate('Catalog'),
      Despesas: () => this.props.navigation.navigate('Expenses'),
      Pedidos: () => this.props.navigation.navigate('OrdersStack'),
      Relatorios: () => this.props.navigation.navigate('Reports'),
      Restaurar: () => this.openCart(true),
      Excluir: () => this.cancelActiveOrder(),
    });

    this.props.dispatch(getListClients());
    AsyncStorage.getItem('@objPayload').then((data) => {
      if (data) {
        const objPayload = JSON.parse(data);
        this.setState({ config: objPayload });
      }
    });
    const data = await CacheModules.getModules();
    this.filterModules(data);

    this.props.dispatch(listPhotos());
    this.props.dispatch(getNotifications());
    this.props.dispatch(configFetch());

    this.linkingEvent = Linking.addEventListener('url', this.handleOpenURL);

    const isInitialDownload = await Sync.isInitialDownload();
    this.setState({ isInitialDownload }, () => {
      Auth.getNotificationToken().then(async notificationToken => {
        if (notificationToken) {
          try {
            await NotificationController.registerOnServer(notificationToken);
          } catch (err) {
            console.log('ERR REGISTER NOTIFICATIONS', err.message);
          }
        }
      });
    });
  }

  formatName(name) {
    const parts = name.split('.');
    const capitalizedParts = parts.map(
      part => part.charAt(0).toUpperCase() + part.slice(1),
    );
    return capitalizedParts.join(' ');
  }

  fetchUserName = async () => {
    const userParamsData = await this.obtainUserDataLib();
    const userParamsDataParse = JSON.parse(userParamsData);
    const firstName = _.get(userParamsDataParse, 'rca_nomeusuario', '');
    const codMaq = _.get(userParamsDataParse, 'rca_codmaq', '');
    const dataAccount = await new CacheAccount().getDataAccount();
    const newDataAccount = {
      ...dataAccount,
      user: {
        ...dataAccount.user,
        first_name: firstName,
        cod_maq: codMaq,
      },
    };
    await new CacheAccount().setDataAccount(newDataAccount);
    this.setState({ firstName });
  };

  UNSAFE_componentWillReceiveProps({ syncFetch, clients, configLib, order }) {
    (async () => {
      if (configLib.type === this.props.configLib.type) {
        return;
      }

      if (configLib.type !== CONFIG_LIB_SUCCESS) {
        return;
      }

      const { payload } = configLib;
      const { configuracoes } = payload;
      const { modules } = configuracoes;
      if (modules) {
        CacheModules.setModules(modules);
      }
    })();

    (async () => {
      if (
        this.props.syncFetch.payload.statusSync === syncFetch.payload.statusSync
      ) {
        return;
      }
      if (syncFetch.payload.statusSync === SYNC_FINALLY) {
        const configuracoes = this.props.configLib?.payload?.configuracoes ?? {};
        const {
          modules,
          tabs,
          fast_delivery: fastDelivery,
        } = configuracoes;


        const cacheTabs = await CacheTabs.getTabs();
        const cacheModules = await CacheModules.getModules();
        if (modules) {
          CacheModules.setModules(modules);
        }
        if (tabs) {
          CacheTabs.setTabs(tabs);
        }
        if (
          this.state.isInitialDownload ||
          this.hasDataChange(cacheModules, modules) ||
          this.hasDataChange(cacheTabs, tabs)
        ) {
          // MainNav.startHomeByTabs(tabs, modules);
          // this.props.navigation.navigate('MyTabs');
        }
        this.setState({
          isInitialDownload: false,
          showFechamento: fastDelivery,
        }, () => {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
      }
    })();

    (async () => {
      if (order.type === this.props.order.type) {
        return;
      }
      if (this.props.order.type !== ORDER_FETCH_REQUEST) {
        return;
      }
      if (order.type === ORDER_FETCH_SUCCESS) {
        const currentScreen = this.props.currentScreenVisible.screen;
        const isVisibleScreen = await this.props.navigation.isFocused();

      if (!isVisibleScreen) {
        this.setState({ loading: false });
        return;
      }
        const { payload } = order;
        const { condvenda, codigo } = payload;
        const messages = _.get(payload, 'mensagens') || [];
        executeActionOrOpenScreenMessage(
          messages,
          () => this.actionOrderClientError(messages, codigo),
          () => this.actionOrderClientNotError(condvenda, codigo),
        );
      }
    })();
  }

  navigateToClientCurrent = client => {
    this.props.dispatch(setModuleCurrent(MODULE_SALE));
    NavigationHelper.push(
      this.props.componentId,
      SCREEN_CLIENT_DETAIL.name,
      _.get(client, 'current.nome', SCREEN_CLIENT_DETAIL.title),
      {
        clientCurrent: client.current,
        orderPending: this.orderPending,
      },
    );
  };

  componentWillUnmount() {
    this.linkingEvent?.remove();
  }

  actionOrderClientNotError = async (condvenda, codigo) => {
    const { currentConditionsSale } = this.state;
    const { productsMagnitudes, configLib } = this.props;
    const showResumeFirst = _.get(
      configLib,
      'configuracoes.exibir_cvenda_abertura',
      false,
    );

    if (productsMagnitudes.type !== PRODUCTS_MAGNITUDES_SUCCESS) {
      await this.saveEventOpenOrder(codigo);
      if (showResumeFirst) {
        this.showModalResume();
      }
      this.openCartScreen();
    } else {
      await this.saveEventOpenOrder(codigo);
      this.openCartScreen();
    }
    this.setState({ loading: false });
  };

  actionOrderClientError = async (messages, codigo) => {
    const messagesErrors = messages.filter(m => m.tipo === 'erro');
    const condSaleInvalid = messagesErrors.find(
      m => m.codigo === 'condicoes_venda_invalida',
    );
    if (condSaleInvalid && messagesErrors.length === 1) {
      try {
        this.showModalResume();
        await this.saveEventOpenOrder(codigo);
        this.openCartScreen();
      } catch (err) {
        console.log('actionOrderClientError | err =>', err);
      }
    } else {
      this.pushingScreen = false;
    }
  };

  saveEventOpenOrder(pkeyOrder) {
    const { location } = this.state;

    return new Promise((resolve, reject) =>
      OrdersModule.saveEventOpenOrder(
        pkeyOrder,
        JSON.stringify(location || {}),
        resolve,
        reject,
      ),
    );
  }

  showModalResume() {
    this.props.dispatch(setBackRootShowModal(this.props.componentId));
    /* Navigation.showModal({
      stack: {
        children: [
          {
            component: {
              name: SCREEN_RESUME_ORDER.name,
              passProps: {
                comesFromClientDetail: true,
                addItem: this.addItem,
                productClick: this.productClick,
              },
              options: {
                topBar: {
                  title: {
                    text: SCREEN_RESUME_ORDER.title,
                  },
                },
              },
            },
          },
        ],
      },
    }); */
    this.props.navigation.navigate('ResumeOrder', {
      comesFromClientDetail: true,
      addItem: this.addItem,
      productClick: this.productClick,
    });
  }

  addItem = (item, quantity) => {
    const { codcliente } = this.props.order.payload.condvenda;
    const itemCopy = Object.assign({}, item);
    itemCopy.quantidade = quantity;
    const payloadAddItem = {
      clientCode: codcliente,
      itemKey: itemCopy.chave.toString(),
      item: itemCopy,
    };

    this.props.dispatch(updateItemFetch(payloadAddItem));
    this.props.dispatch(updateOrderScreen(SCREEN_HOME.name));
  };

  productClick = item => {
    const pushingScreen = this.props.pushingScreen || {};
    if (pushingScreen.status) {
      return;
    }

    this.props.dispatch(setPushingScreen(true));

    const screen =
      item.tipo === 'produto'
        ? SCREEN_PRODUCT_DETAIL.name
        : SCREEN_COMPOUND_PRODUCT.name;
    NavigationHelper.showModal(screen, item.nome, {
      addItem: this.addItem,
      product: item,
      screen,
      pageDefault: 1,
      cameByShowModal: true,
    });
  };

  openCartScreen() {
    const originScreen = this.props.originScreen || this.props.componentId;
    this.props.dispatch(setModuleCurrent(''));
    this.props.dispatch(updateStatusOpenOrder(false));
    this.props.dispatch(setCurrentClientNoThunk(this.props.client.current));
    
    this.props.navigation.navigate('Cart', {
      screen: SCREEN_CLIENT_DETAIL.name,
      routeByitinerary: this.props.routeByitinerary,
      actualClient: this.props.client.current,
      order: {
        payload: this.orderPending,
      },
      originScreen,
    });
    this.props.dispatch(setBackRootShowModal(this.props.componentId));
  }

  onHideModal = () => {
    if (this.failureSendRequest) {
      this.failureSendRequest();
    }
    this.setState({ modalVisible: false });
  };

  onPressClearPhotos = async () => {};

  onPressSyncPhotos = async () => {};

  checkModule = (module) => {
    let screenModule;
    if (module === 'clients') {
      screenModule = 'ClientesStack';
    } else if (module === 'reports') {
      screenModule = 'Reports';
    } else if (module === 'titles') {
      screenModule = 'Titles';
    } else if (module === 'catalog_products') {
      screenModule = 'Catalog';
    } else if (module === 'orders') {
      screenModule = 'Orders';
    } else if (module === 'prospects') {
      screenModule = 'Prospects';
    } else if (module === 'itinerary') {
      screenModule = 'Itinenary';
    } else if (module === 'expenses') {
      screenModule = 'Expenses';
    } else if (module === 'SalesOpportunity') {
      screenModule = 'SalesOpportunity';
    } else {
      screenModule = 'Ops, not find module';
    }

    return `${screenModule}`;
  };

  onPressApp = (screen, title, tabIndex, props) => {
    const pushingScreen = this.props.pushingScreen || {};

    if (Object.keys(pushingScreen.status || {}).length) {
      return;
    }
    this.props.dispatch(setPushingScreen(true));
    if (typeof title === 'string' && screen === 'webview') {
      this.setCurrentModule(title.toUpperCase())
        .then(() => {})
        .catch(error => {
          throw error;
        });
    }
    if (typeof screen === 'string' && screen !== 'webview') {
      this.setCurrentModule(screen.toUpperCase())
        .then(() => {})
        .catch(error => {
          throw error;
        });
    }

    if (tabIndex) {
      const screenNavigate = this.checkModule(screen);
      this.props.navigation.navigate(`${screenNavigate}`);
    } else {
      if (screen === 'webview') {
        this.props.navigation.push(`${screen}`, {
          name: title,
          url: props.props.item.props.url,
          key: props.props.item.key,
        });
      } else {
        const screenNavigate = this.checkModule(screen);
        if (screenNavigate === 'Expenses') {
          this.props.navigation.navigate(`${screenNavigate}`, {
           navigation: this.props.navigation,
          });
        }
        this.props.navigation.navigate(`${screenNavigate}`);
      }
    }
  };

  onPressClickApp = onPressHelper.debounce(this.onPressApp);

  onPressFechamento = async () => {
    Alert.alert(
      `${translate('doDayClosure')}`,
      `${translate('wantToDoDayClosure')}`,
      [
        {
          style: 'cancel',
          text: `${translate('no')}`,
          onPress: () => {},
        },
        {
          style: 'default',
          text: `${translate('yes')}`,
          onPress: async () => {
            try {
              const closingOrderStringfy = await OrdersModule.closingOrder();
              const closingOrder = JSON.parse(closingOrderStringfy);
              if (closingOrder && closingOrder.length > 0) {
                const code = closingOrder[0].codigo;
                if (code === 'abrir_modulo_impressao') {
                  NavigationHelper.push(
                    this.props.componentId,
                    SCREEN_REPORTS.name,
                    SCREEN_REPORTS.title,
                  );
                } else if (code === 'abrir_modulo_pedido') {
                  NavigationHelper.push(
                    this.props.componentId,
                    SCREEN_ORDERS.name,
                    SCREEN_ORDERS.title,
                  );
                } else if (code === 'criar_pedido_recarga') {
                  // TODO: Ver necessidade atual
                } else {
                  const msg = closingOrder[0].mensagem;
                  Alert.alert('Oops!', msg);
                }
              }
            } catch (error) {
              Alert.alert('Oops!', error.message);
            }
          },
        },
      ],
    );
  };

  onCLickonPressFechamento = onPressHelper.debounce(this.onPressFechamento);

  onHideProgress = () => {
    this.setState({ downloadBase: false });
    this.props.dispatch(showProgressSync(false));
  };

  onPressUpdateVersion = () => {
    const { payload } = this.props.availableUpdate;
    NavigationHelper.showModal(
      SCREEN_UPDATE_VERSION_APP.name,
      SCREEN_UPDATE_VERSION_APP.title,
      {
        syncFinaly: payload.syncFinaly,
        requiredUpdateVersion: false,
        dataAPK: payload,
      },
    );
  };

  onPressNotifications = () => {
    this.props.navigation.navigate('Notifications', {
      notifications: this.props.notifications ?? [],
    });
  };

  onClickOnPressNotifications = onPressHelper.debounce(
    this.onPressNotifications,
  );

  onSaveStartingPoint = (
    { payload: { location, content } },
    success,
    failure,
  ) => {
    this.successSendRequest = success;
    this.failureSendRequest = failure;
    this.requestPayload = {
      ...location,
      content,
    };
    if (!this.requestApproved) {
      this.setState({ modalVisible: true });
    }
  };

  onPressFloatingAction = id => {
    switch (id) {
      case 'starting_point': {
        NavigationHelper.push(
          this.props.componentId,
          'activities.Location',
          `${translate('startingPointScreen')}`,
          {
            source: 'home',
            item: {},
            saveAnswerActivity: this.onSaveStartingPoint,
          },
        );
        break;
      }
      default:
        break;
    }
  };

  setCurrentModule(currentModule) {
    return new Promise((resolve, reject) => {
      AccountsModule.setCurrentModule(currentModule, resolve, reject);
    });
  }

  async getPhotosFromDB() {
    const answerController = new AnswerController();
    const photosSynchronized = await answerController.getPhotosSynchronized();
    const photosNotSynchronized = await answerController.getPhotoToSync();
    return {
      photosSynchronized,
      photosNotSynchronized,
    };
  }

  handleSendRequest = () => {
    this.setState({ requesting: true });
    RouteSolicitationsResource.sendSolicitation(
      0,
      KIND_REQUEST_STARTING_POINT,
      this.requestPayload,
    )
      .then(() => {
        this.successSendRequest();
        Snackbar.show({ text: `${translate('successSendRequest')}` });
      })
      .catch(() => {
        this.failureSendRequest();
        Snackbar.show({ text: `${translate('errorSendRequest')}` });
      })
      .finally(() => {
        this.setState({ requesting: false });
        NavigationHelper.pop(this.props.componentId);
      });
  };

  handleBackPress = () => {
    BackHandler.exitApp();
    return true;
  };

  getClientByPkey = async (pkey, isJoker = false) => {
    let clie = null;
    if (isJoker) {
      try {
        const tamMemory = _.get(
          this.props.configLib,
          'payload.tam_memoria_paginacao',
        );

        const resp = await listGeneric(
          emp2long('CCOR'),
          emp2long('CCPR'),
          {},
          tamMemory,
        );
        clie = resp?.data?.find(cli => cli.pkey === pkey);
      } catch (err) {
        console.log('ERROR GET CLIENTES_CORINGA =>', err.message);
      }
    } else {
      clie = this.props.clients.payload.find(cli => cli.pkey === pkey);
    }
    return clie;
  };

  openCart = async (goTo = false) => {
    const orderPending = this.orderPending;
    if (isEmpty(orderPending)) {
      return;
    }
    const { clients } = this.props;

    if (clients && clients.payload.length === 0) {
      this.props.dispatch(getListClients());
      createNotifyInfo('Aguarde, estamos carregando os clientes...');
      return;
    }

    const clientFind = await this.getClientByPkey(
      orderPending.condvenda.codcliente,
      orderPending.cliente_coringa,
    );

    this.props.dispatch(setCurrentClient(clientFind));

    const cartId = orderPending ? orderPending.codigo : '0';
    const condvenda = orderPending ? { ...orderPending.condvenda } : {};
    const orcamento = orderPending ? orderPending.orcamento : true;
    const payloadOrderClient = {
      codigo: cartId,
      pkeyClient: this.props.client?.current?.pkey,
      condvenda,
      orcamento,
    };
    this.props.dispatch(orderClient(payloadOrderClient));
  };

  cancelActiveOrder = async () => {
    const promiseCancelOrder = new Promise((resolve, reject) =>
      OrdersModule.cancelActiveCart(resolve, reject),
    );

    try {
      await promiseCancelOrder;
      this.props.dispatch(updateStatusOpenOrder(true));
      this.setState({ hasOrderPending: false });
      this.props.dispatch(setSaleOpportunity(false));
      SaleOpportunityCache.removeSaleOptnSelected();
      createNotifySuccess('Pedido cancelado com sucesso!');
    } catch (error) {
      createNotifyError(error);
    }
  };

  async verifyOrderPending() {
    const { hasPendingOrder, orderPending } = await verifyOrderPending();
    const { clients } = this.props;

    if (clients && clients.payload.length === 0) {
      this.props.dispatch(getListClients());
    }

    if (orderPending.total <= 0 || orderPending.codigo === '0') {
      const promiseCancelOrder = new Promise((resolve, reject) =>
        OrdersModule.cancelActiveCart(resolve, reject),
      );
      try {
        await promiseCancelOrder;
      } catch (error) {
        createNotifyInfo('Oops!', error);
      }
      try {
        this.props.dispatch(updateStatusOpenOrder(true));
        this.setState({ hasOrderPending: false });
        this.props.dispatch(setSaleOpportunity(false));
        SaleOpportunityCache.removeSaleOptnSelected();
      } catch (error) {
        console.log('error try update status open order', error);
      }
    } else if (hasPendingOrder) {
      const totalOrderToString = orderPending?.total?.toString();
      const totalOrder = totalOrderToString?.replace('.', ',');

      const totalOrderPending =
        orderPending.total && orderPending.total > 0 ? orderPending.total : 0;
      const clientName = orderPending.nome_cliente
        ? orderPending.nome_cliente
        : '';

      if (totalOrderPending > 0 && clientName !== '') {
        this.setState({
          hasOrderPending: true,
          totalOrderPending: totalOrder,
          codClientOrderPending: orderPending.condvenda.codcliente,
          clientOrderPending: clientName,
        });
        this.orderPending = orderPending;
      }
    } else {
      this.setState({ hasOrderPending: false });
      this.orderPending = orderPending;
    }
  }

  async handleSyncPhotos() {}

  hasDataChange(fromCache, fromService) {
    const isEqual =
      Array.isArray(fromCache) &&
      Array.isArray(fromService) &&
      !_.isEqual(fromCache, fromService);
    return isEqual;
  }

  handleOpenURL = async event => {
    const { url } = event;
    let [, paramsLink = '{}'] = url.split('param/') || [];
    paramsLink = JSON.parse(paramsLink) || {};
    if (paramsLink.code === 'logout') {
      SQLiteHelper.dropDatabase()
        .then(async () => {
          this.props.dispatch(syncUpdateTimeAndStatus(SYNC_FINALLY));
          ServicesModule.syncUpdateStatusService(SYNC_FINALLY);
          await AsyncStorage.clear();
          ServicesModule.stopSyncService();
          this.logoutLib();
        })
        .catch(() => {
          Alert.alert('Oops!', `${translate('errorExitPoliEquipes')}`);
        })
        .finally(async () => {
          await CacheDataController.saveData('@LOGOUT_REF', { logout: true });
          BackHandler.exitApp();
        });
    }
  };

  logoutLib = () =>
    new Promise((res, rej) => {
      AccountsModule.logout(res, rej);
    });

  async filterModules(modules) {
    const appModules = [];
    const tabs = await CacheTabs.getTabs();
    if (modules) {
      if (typeof modules[0].id !== 'undefined') {
        modules.forEach(module => {
          module.image = setModuleIcon(module.icon);
          appModules.push(module);
        });
      } else {
        modulesConfig.forEach(app => {
          if (
            modules.find(module => module === app.id || module === app.name)
          ) {
            appModules.push(app);
          }
        });
      }
    } else if (tabs) {
      tabs.forEach((tab, index) => {
        if (
          tab.screen === 'itinerary' ||
          tab.screen === 'clients' ||
          tab.screen === 'orders'
        ) {
          const modulesByTabs = modulesConfig.find(
            ({ id, name }) => id === tab.screen || name === tab.screen,
          );
          modulesByTabs.tabIndex = index;
          appModules.push(modulesByTabs);
        }
      });
    }

    this.setState({
      appModules,
    });
  }

  render() {
    const {
      title,
      firstName,
      sizeAvailable,
      isInitialDownload,
      hasOrderPending,
      showFechamento,
      notSynchronizedPhotosSize,
      notSynchronizedPhotosList,
      requesting,
      showShortcuts,
    } = this.state;

    const { syncFetch, configLib } = this.props;

    const { showProgress, progressData, statusSync } = syncFetch.payload;

    const { visible = false, uri = '' } = _.get(
      configLib.payload,
      'configuracoes.home_banner',
      {},
    );
    const homeActions = _.get(
      configLib.payload,
      'configuracoes.home_actions',
      [],
    );
    const payload = _.get(this.props, 'config.payload', {});
    const payloadNotifications =
      _.get(this.props, 'notifications.payload', []) || [];
    const hasAvailableUpdate = _.get(
      this.props,
      'availableUpdate.hasAvailableUpdate',
      false,
    );

    return (
      <Home
        showBanner={visible}
        bannerUrl={uri}
        onPressUpdateVersion={this.onPressUpdateVersion}
        appModules={this.state.appModules}
        onPressApp={this.onPressClickApp}
        onHideProgress={this.onHideProgress}
        config={this.state.config}
        progress={progressData.progress}
        textProgress={progressData.text}
        title={title}
        downloadBase={isInitialDownload && showProgress}
        syncFinally={statusSync === SYNC_FINALLY}
        firstName={firstName}
        notSynchronizedPhotosSize={notSynchronizedPhotosSize}
        notSynchronizedPhotosList={notSynchronizedPhotosList}
        sizeAvailable={sizeAvailable}
        onPressClearPhotos={this.onPressClearPhotos}
        onPressFechamento={this.onCLickonPressFechamento}
        showFechamento={showFechamento}
        availableUpdate={hasAvailableUpdate}
        onPressSyncPhotos={this.onPressSyncPhotos}
        requesting={requesting}
        onPressNotifications={this.onClickOnPressNotifications}
        notifications={payloadNotifications}
        hasOrderPending={hasOrderPending}
        onPressEditPendingOrder={() => this.openCart(true)}
        onPressCancelPendingOrder={this.cancelActiveOrder}
        onPressFloatingAction={this.onPressFloatingAction}
        modalVisible={this.state.modalVisible}
        onHideModal={this.onHideModal}
        handleSendRequest={this.handleSendRequest}
        homeActions={homeActions}
        totalOrderPending={this.state.totalOrderPending}
        clientOrderPending={this.state.clientOrderPending}
        visibleAlertSync={
          this.props.currentScreenVisible.screen === this.props.componentId
            ? syncFetch.payload.statusSync !== SYNC_FINALLY
            : false
        }
        showShortcuts={showShortcuts}
      />
    );
  }
}

HomeContainer.propTypes = {
    navigation: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    configLib: PropTypes.object.isRequired,
    componentId: PropTypes.string,
    syncFetch: PropTypes.object.isRequired,
    clients: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    notifications: PropTypes.object.isRequired,
    availableUpdate: PropTypes.object.isRequired,
    pushingScreen: PropTypes.object.isRequired,
    onChangeShowModalEdi: PropTypes.func.isRequired,
    injectedProp: PropTypes.object.isRequired,
    onSendFileEdi: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    pushingScreen: state.pushingScreen,
    availableUpdate: state.versionUpdate,
    notifications: state.notifications,
    syncFetch: state.syncFetch,
    config: state.configFetch,
    configLib: state.configLibFetch,
    clients: state.clientsFetch,
    client: state.clientCurrent,
    currentScreenVisible: state.currentScreenVisible,
    productsMagnitudes: state.productsMagnitudes,
    order: state.orderFetch,
  };
}

export default connect(mapStateToProps)(HomeContainer);
