/* @flow */

import React, { Component } from 'react';
import { View, Text, StyleSheet } from 'react-native';

import { color } from '~/common';
import { fonts } from '../../utils/Fonts';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
    padding: 2,
  },
  label: {
    color: color.grayDark,
  },
  label_main: {
    fontFamily: fonts.QuicksandBold,
    fontSize: 14,
  },
  value: {
    textAlign: 'right',
    color: color.grayDark,
  },
  value_main: {
    color: color.primaryColorLight,
    fontFamily: fonts.QuicksandBold,
    fontSize: 14,
  },
  value_money_positive: {
    color: '#4CAF50',
  },
  value_money_negative: {
    color: '#FF5252',
  },
  subvalue: {
    textAlign: 'right',
    fontSize: 12,
    color: color.grayDark,
  },
});

class Item extends Component {
  static defaultProps = {
    main: false,
    money: false,
    moneyPositiveOrNegative: true,
    subvalue: '',
  };

  render() {
    const {
      index, value, style, noColor, money, moneyPositiveOrNegative,
    } = this.props;

    let styleMoney = {};
    if (money) {
      styleMoney = moneyPositiveOrNegative ? styles.value_money_positive : styles.value_money_negative;
    }

    return (
      <View style={[
        styles.container,
        noColor ? { backgroundColor: color.grayAlmostWhite } : {},
        index % 2 === 0 ? {} : { backgroundColor: '#fff' },
        style,
      ]}
      >
        <Text style={[
          styles.label,
          noColor ? { color: 'black' } : {},
          value.main ? styles.label_main : {},
          { flex: 1 },
        ]}
        >
          {value.label}
        </Text>
        <View>
          <Text style={[
            styles.value,
            value.main ? styles.value_main : {},
            styleMoney,
            { flex: 1 },
          ]}
          >
            {value.value}
          </Text>
          {value.subvalue !== '' ? null : <Text style={styles.subvalue}>{value.subvalue}</Text>}
        </View>
      </View>
    );
  }
}

Item.propTypes = {
  value: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    subvalue: PropTypes.string,
  }).isRequired,
  main: PropTypes.bool,
  money: PropTypes.bool,
  moneyPositiveOrNegative: PropTypes.bool,
  index: PropTypes.number.isRequired,
};

export default Item;
