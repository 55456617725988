import * as config from '../../../../config/config';

const getMarkerColor = (cli) => {
  let color = config.Colors.primaryColorDarkest;

  if (cli.fora_de_rota) {
    ({ color } = config.ITINERARY_SITUATION.OUT_OF_ROUTE);
  } else if (cli.atendimento_atrasado === '1') {
    ({ color } = config.ITINERARY_SITUATION.LATE);
  }
  // TODO: retornar as outras cores;
  return color;
};

export default getMarkerColor;
