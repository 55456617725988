/* @flow */

import React, { Component } from 'react';
import { View, Text } from 'react-native';
import _ from 'lodash';
import ProductsModule from '~/modules/ProductsModule';

import ScaleOption from './ScaleOption';
import { SCALE_FETCH_SUCCESS, scale as scaleFetch } from '~/store/scale/slice';
import { ORDER_FETCH_SUCCESS } from '~/store/ordersFetch/orderFetchSlice';
import { UPDATE_ITEM_REQUEST } from '~/store/ordersFetch/updateItemSlice';
import {
    updateScaleProduct,
    SET_SCALE_REQUEST,
} from '~/store/ordersFetch/updateScaleProductSlice';

import { connect } from 'react-redux';
import { executeActionsMessage } from '../../../../utils/messages';
import { RadioGroupData } from '~/components';

import PropTypes from 'prop-types';

class Scale extends Component {
    constructor(props) {
        super(props);

        this.scaleSelected = -1;
        this.prevScaleSelected = -1;
        this.state = {
            selected: -1,
        };
    }

    componentDidMount() {
        const { product } = this.props;
        this.props.dispatch(scaleFetch(product?.chave));
    }

    componentDidUpdate(prevProps) {
        (() => {
            if (prevProps.scale.type === this.props.scale.type) {
                return;
            }
            if (this.props.scale.type !== SCALE_FETCH_SUCCESS) {
                return;
            }

            const selectedIndex = this.getSelectedScale();
            this.setState({ selected: selectedIndex });
        })();

        (() => {
            if (prevProps.order.type === this.props.order.type) {
                return;
            }
            if (this.props.order.type === UPDATE_ITEM_REQUEST) {
                return;
            }
            if (prevProps.order.type !== UPDATE_ITEM_REQUEST) {
                return;
            }
            if (this.props.order.type !== ORDER_FETCH_SUCCESS) {
                return;
            }

            const { payload } = this.props.order;
            const messages = _.get(payload, 'mensagens') || [];

            executeActionsMessage(messages, undefined, this.actionUpdateItemNotError);
        })();

        (() => {
            if (prevProps.order.type === this.props.order.type) {
                return;
            }
            if (prevProps.order.type !== SET_SCALE_REQUEST) {
                return;
            }
            if (this.props.order.type !== ORDER_FETCH_SUCCESS) {
                return;
            }

            const { payload } = this.props.order;
            const messages = _.get(payload, 'mensagens') || [];

            executeActionsMessage(messages, undefined, () =>
                this.setState({ selected: this.scaleSelected }),
            );
        })();
    }

    onSelect = async selectedValue => {
        if (selectedValue === this.state.selected) {
            return;
        }
        const {
            product,
            dispatch,
            scale,
            callback,
            forceQuantity = false,
        } = this.props;
        const { faixas } = scale.payload;
        const { chave } = product;
        const { quantidade } = product;
        const selectedIndex =
            faixas && faixas.findIndex(i => i.value === selectedValue);

        if (quantidade > 0 || forceQuantity) {
            dispatch(
                updateScaleProduct({
                    pkeyProduto: chave,
                    pkeyEscala: faixas[selectedIndex].pkey,
                    valor: faixas[selectedIndex].value,
                }),
            );
            this.scaleSelected = selectedIndex;
            this.prevScaleSelected = this.state.selected;
            if (this.prevScaleSelected !== selectedIndex) {
                this.setState({ selected: selectedIndex }, () => {
                    // addItem(itemCopy, faixas[selectedIndex].value || product.multiplo_venda);

                    const { multiplo_venda: multipleSale = 1 } = product;

                    if (callback) {
                        const faixaAnterior = faixas[this.prevScaleSelected];
                        const faixaSelecionada = faixas[selectedIndex];
                        const faixaLimite = faixas.length - 1;

                        let val = -1;
                        if (faixaSelecionada.value > faixaAnterior.value) {
                            val = faixaSelecionada.value;
                        } else {
                            const proximaFaixa = _.find(
                                faixas,
                                (f, idx) => idx > selectedIndex,
                            );
                            const faixa = proximaFaixa || faixas[faixaLimite];
                            val = Math.abs(faixa.value - multipleSale);
                        }
                        callback(val, selectedIndex);
                    }
                });
            }
        } else {
            const promise = new Promise((resolve, reject) =>
                ProductsModule.setScale(
                    chave.toString(),
                    faixas[selectedIndex].pkey.toString(),
                    faixas[selectedIndex].value,
                    false,
                    resolve,
                    reject,
                ),
            );

            let itemActivie = await promise;
            itemActivie = JSON.parse(itemActivie);
            const {
                preco,
                precobase,
                desconto,
                acrescimo,
                quantidade: quant,
            } = itemActivie;

            let prod = { ...this.props.product };
            prod = {
                ...prod,
                preco,
                precobase,
                desconto,
                acrescimo,
                quantidade: quant,
            };

            this.prevScaleSelected = this.state.selected;
            if (this.prevScaleSelected !== selectedIndex) {
                this.setState({ selected: selectedIndex }, () => {
                    const itemCopy = { ...prod, quantidade: faixas[selectedIndex].value };
                    // addItem(itemCopy, faixas[selectedIndex].value || product.multiplo_venda);

                    const { multiplo_venda: multipleSale = 1 } = itemCopy;

                    if (callback) {
                        const faixaAnterior = faixas[this.prevScaleSelected];
                        const faixaSelecionada = faixas[selectedIndex];
                        const faixaLimite = faixas.length - 1;

                        let val = -1;
                        if (faixaSelecionada.value > faixaAnterior.value) {
                            val = faixaSelecionada.value;
                        } else {
                            const proximaFaixa = _.find(
                                faixas,
                                (f, idx) => idx > selectedIndex,
                            );
                            const faixa = proximaFaixa || faixas[faixaLimite];
                            val = Math.abs(faixa.value - multipleSale);
                        }
                        callback(val, selectedIndex);
                    }
                });
            }
        }
    };

    getSelectedScale() {
        const scale = this.props.scale.payload;
        const { faixas = [] } = scale;

        const { scaleFromProductDetailModal = -1 } = this.props;

        let activeIndex = -1;

        if (faixas) {
            faixas.forEach((faixa, index) => {
                if (scaleFromProductDetailModal === index) {
                    activeIndex = scaleFromProductDetailModal;
                }
                if (faixa.selected) {
                    activeIndex = index;
                }
            });
        }

        return activeIndex;
    }

    actionUpdateItemNotError = () => {
        const { product, order } = this.props;
        const productFound =
            order.payload.produtos &&
            order.payload.produtos.find(p => p.chave === product.chave);

        if (productFound) {
            this.props.dispatch(scaleFetch(product.chave));
        }
    };

    keyExtractor = (item, index) => `${item.pkey}-${index}`;

    renderItem = ({ item, index }) => (
        <ScaleOption
            scale={item}
            onSelect={() => this.onSelect(index)}
            index={index}
            selected={this.state.selected}
        />
    );

    render() {
        const { selected } = this.state;
        const scale = this.props.scale.payload;
        const { faixas } = scale;
        const { loading: disabledButton } = this.props.order?.loading;

        return (
            <View>
                {faixas ? (
                    <RadioGroupData
                        disabled={disabledButton}
                        valueSelected={faixas[selected] && faixas[selected].value}
                        itens={[...faixas].map(i => ({
                            ...i,
                            label: i.value ? `A partir de ${i.value}` : 'Escala padrão',
                        }))}
                        onSelectItem={value => this.onSelect(value)}
                    />
                ) : <Text style={{ color: '#000' }}>Sem escalas</Text>}
            </View>
        );
    }
}

Scale.propTypes = {
    dispatch: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    scale: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,
    callback: PropTypes.func,
    editing: PropTypes.bool,
    scaleFromProductDetailModal: PropTypes.number,
    forceQuantity: PropTypes.bool,
};

function mapStateToProps(state) {
    return {
        scale: state.scale || {
            payload: {
                faixas: [],
            },
        },
        order: state.orderFetch,
    };
}

export default connect(mapStateToProps)(Scale);
