import { decode } from 'base-64';
export const BASE_URL = 'http://localhost:8000';
export const URL_LOGIN = 'https://process-server.polisuite.com.br/v1/core/auth/login/';

export const TOKEN_AUTH_USER = '@TOKEN_AUTH_USER';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const getAuthToken = async () => {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const token = params.get('token');

  if (token) {
    return token;
  }
  return await AsyncStorage.getItem(TOKEN_AUTH_USER);
};

export const getSessionPayload = async () => {
  const token = await getAuthToken();
  try {
    const payloadBase64 = token.split('.')[1];
    const decodedPayload = decode(payloadBase64);
    const jsonPayload = JSON.parse(decodedPayload);
    return jsonPayload;
  } catch (error) {
    console.error('Erro ao decodificar o JWT:', error);
    return null;
  }
};

export const isChildWindow = () => {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const token = params.get('token');
  return !!token;
};