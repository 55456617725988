// src/routes/RootNavigation.js
import { createRef } from 'react';
import { StackActions } from '@react-navigation/native';

export const navigationRef = createRef();

// Função para empurrar uma nova tela
export function push(...args) {
  navigationRef.current?.dispatch(StackActions.push(...args));
}

// Função para navegar para uma tela
export function navigate(...args) {
  navigationRef.current?.dispatch(StackActions.navigate(...args));
}

// Função para voltar
export function goBack() {
  navigationRef.current?.dispatch(StackActions.pop());
}
