import React from 'react';
import { FlatList, View } from 'react-native';
import {
  Placeholder,
  PlaceholderMedia,
  PlaceholderLine,
  Fade,
} from 'rn-placeholder';
import colors from '../../../common/color';

export default function HomeModulesPlaceholder() {
  const lineStyle = {
    backgroundColor: colors.gray,
    marginLeft: 7,
    width: 70,
  };

  const viewStyle = {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  };
  const app = {
    borderRadius: 10,
    width: 70,
    height: 70,
    margin: 8,
    marginTop: 30,
    backgroundColor: colors.gray,
  };
  return (
    <Placeholder Animation={Fade}>
      <FlatList
        contentContainerStyle={{
        alignItems: 'center',
        justifyContent: 'center',
      }}
        data={[0, 1, 2, 3, 4, 5, 6, 7]}
        horizontal={false}
        numColumns={4}
        renderItem={() => (
          <View style={viewStyle}>
            <View>
              <PlaceholderMedia style={app} />
              <PlaceholderLine style={lineStyle} />
            </View>
          </View>
        )}
      />
    </Placeholder>
  );
}
