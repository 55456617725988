import {CALL_GENERIC_PROPERTY} from "~/modules/WrappedModule";

const ConfigurationApiModule = (nameModule) => {
  const methodByAlias = {
    getConfigurations: 'obterConfiguracoes'
  };

  const genericProperties = {};
  Object.keys(methodByAlias).forEach((k) => genericProperties[k] = CALL_GENERIC_PROPERTY);

  return {
    getName: () => nameModule,
    methodByAlias,
    ...genericProperties,
  };
};

export default ConfigurationApiModule;
