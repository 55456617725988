import {
  CALL_GENERIC_PROPERTY,
  genericFuncWrapper,
} from '~/modules/WrappedModule';

const OrdersApiModule = nameModule => {
  const methodByAlias = {
    calcDiscountIncrement: 'calcularDescAcre',
    calcPrice: 'calcularPreco',
    startProdRest: 'iniciarProdRest',
    hasPendingOrder: 'contemPedidoPendente',
    closingOrder: 'fazerFechamento',
    updateShippingOrder: 'alterarFrete',
    getItemActive: 'obterItemAtivo',
    updateConditionsSalesChild: 'atualizarCondicoesFilhas',
    cancelActiveCart: 'cancelarCarrinhoAtivo',
    getCart: 'obterCarrinho',
    getCartByPkey: 'carrinho',
    copyTypeOrder: 'tipoCopiaCarrinho',
    copyOrder: 'copiarCarrinho',
    finishCart: 'concluirCarrinho',
    updateItem: 'atualizarItem',
    updateBonification: 'alterarBonificacao',
    validateSaveBonus: 'validarSalvarBonificacao',
    getPriceTable: 'obterPrecoTabelado',
    getPriceMin: 'obterPrecoMinimo',
    getPriceMax: 'obterPrecoMaximo',
    currentConditionsSale: 'condicoesVendaAtuais',
    detalhesPedido: 'detalhesPedido',
    listOrders: 'listarPedidos',
    listItensOrder: 'listarItensPedidos',
    getOrderObservation: 'obterObservacoesPedido',
    setOrderObservation: 'salvarObservacaoPedido',
    getFilterOrders: 'getFiltroPedidos',
    saleHistoryDonizete: 'historicoVendasDonizete',
    suggestionProductsDonizete: 'sugestaoDonizete',
    saveEventOpenOrder: 'eventoAbrirPedido',
    saveEventEditOrder: 'eventoEditarPedido',
    saveEventCloseOrder: 'eventoEncerrarPedido',
    applyOperationByGroup: 'aplicarOperacaoPorGrupo',
    applyGroupExclusion: 'excluirOperacaoPorGrupo',
    getAndUpdateItemActive: 'obterEAtualizarItemAtivo',
    applyDiscountTalao: 'aplicarDescontoTalao',
    saveOrderObservations: 'salvarObservacoesPedido',
    updateShippingOrderAndCompany: 'alterarFreteETransportadora',
    getObservationLink: 'obterObservacaoLink',
    eventSaveBudget: 'eventoSalvarOrcamento',
  };

  const getCartClientCoringa = async (
    codCart,
    codClient,
    condVenda,
    orcamento,
    codPreRegister,
  ) => {
    const obterCarrinhoClienteCoringa = await genericFuncWrapper(
      'obterCarrinhoClienteCoringa',
    );
    return obterCarrinhoClienteCoringa(
      codCart || '',
      codClient || '',
      condVenda || '',
      orcamento,
      codPreRegister,
    );
  };

  const cancelDeleteOrder = async (keyOrder, resolve, reject) => {
    return genericFuncWrapper('cancelarExcluirCarrinho')(
      keyOrder,
      '2',
      false,
      '',
      true,
      resolve,
      reject,
    );
  };

  const saveCart = async keyCart => {
    return genericFuncWrapper('salvarCarrinho')(keyCart || '');
  };

  const getLastCart = async (pkeyClient, resolve, reject) => {
    return genericFuncWrapper('ultimoCarrinho')(
      pkeyClient || '',
      resolve,
      reject,
    );
  };

  const genericProperties = {};
  Object.keys(methodByAlias).forEach(
    k => (genericProperties[k] = CALL_GENERIC_PROPERTY),
  );

  return {
    getName: () => nameModule,
    methodByAlias,
    getCartClientCoringa,
    cancelDeleteOrder,
    saveCart,
    getLastCart,
    ...genericProperties,
  };
};

export default OrdersApiModule;
