import React, { Component } from 'react';
import { View, Image } from 'react-native';
import Touchable from 'react-native-platform-touchable';

import styles from './styles';
import color from '../../../common/color';
import Images from '@assets/Images';

import PropTypes from 'prop-types';

class DecrementIncrementIcon extends Component {
    state = {
        typeDiscount: 0,
    };

    componentDidMount() {
        this.setState({ typeDiscount: this.props.selectedTypeDiscount });
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedTypeDiscount === prevProps.selectedTypeDiscount) return;
        if (this.props.selectedTypeDiscount === this.state.typeDiscount) return;

        this.setState({ typeDiscount: this.props.selectedTypeDiscount });
    }

    onPress = (typeDiscount) => {
        const { onPress } = this.props;
        const { typeDiscount: typeDiscountState } = this.state;

        if (onPress && typeDiscount !== typeDiscountState) {
            onPress(typeDiscount);
            this.setState({ typeDiscount });
        }
    }

    render() {
        const { typeDiscount } = this.state;

        return (
            <View style={styles.container}>
                <Touchable
                    style={[styles.buttonMinus,
                    typeDiscount === 0 ? { backgroundColor: color.primaryColor } : { backgroundColor: '#fff' }]}
                    onPress={() => this.onPress(0)}
                >
                    <Image
                        style={[styles.iconButton,
                        typeDiscount === 0 ? { tintColor: '#fff' } : { tintColor: color.primaryColor }]}
                        source={Images.iconMinus}
                    />
                </Touchable>
                <Touchable
                    style={[styles.buttonPlus,
                    typeDiscount === 1 ? { backgroundColor: color.primaryColor } : { backgroundColor: '#fff' }]}
                    onPress={() => this.onPress(1)}
                >
                    <Image
                        style={[styles.iconButton,
                        typeDiscount === 1 ? { tintColor: '#fff' } : { tintColor: color.primaryColor }]}
                        source={Images.iconPlus}
                    />
                </Touchable>
            </View>);
    }
}

DecrementIncrementIcon.propTypes = {
    onPress: PropTypes.func,
    selectedTypeDiscount: PropTypes.number,
};

export default DecrementIncrementIcon;
