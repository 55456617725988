/* @flow */

import React, { Component } from 'react';
import { View, StyleSheet, Platform, Text } from 'react-native';
import { FlashList } from '@shopify/flash-list';
import {
  EmptyView,
  Search,
  TitleListItem,
  Totalizers,
} from '../../../components';

import Images from '@assets/Images';
import { color, style } from '../../../common';
import Filter from './Filter';
import translate from '../../../locales';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  textContainer: {
    paddingTop: 6,
    paddingLeft: 16,
    paddingBottom: 6,
  },
  title: {
    color: Platform.OS === 'ios' ? color.grayDark : color.primaryColor,
    fontSize: 16,
    fontWeight: 'bold',
  },
});

class Titles extends Component {
  keyExtractor = item => item.pkey;

  renderItemTitle = ({ item }) => (
    <TitleListItem
      title={item}
      type="financing"
      onCLickTitle={() => this.props.onCLickTitle(item)}
      showItemAsHTML={this.props.showItemAsHTML}
    />
  );

  renderTitle() {
    const { title, sortedTitles } = this.props;

    if (sortedTitles && sortedTitles.length > 0) {
      return (
        <View style={styles.textContainer}>
          <Text style={styles.title}>{title}</Text>
        </View>
      );
    }

    return null;
  }

  renderHeader() {
    const { titles, totalizers, onListFiltered } = this.props;
    return (
      <View style={styles.container}>
        <Filter titles={titles} onListFiltered={onListFiltered} />
        <Totalizers totalizers={totalizers} />
        {this.renderTitle()}
      </View>
    );
  }

  render() {
    const { sortedTitles, showSearch, onChangeText } = this.props;

    return (
      <View style={style.container}>
        {!!showSearch && <Search onChangeText={onChangeText} />}
        {sortedTitles && sortedTitles.length > 0 ? (
          <FlashList
            estimatedItemSize={240}
            ListHeaderComponent={this.renderHeader()}
            keyExtractor={this.keyExtractor}
            renderItem={this.renderItemTitle}
            data={sortedTitles}
          />
        ) : (
          <EmptyView
            icon={Images.iconCoinBig}
            message={`${translate('noFinancingTitles')}`}
          />
        )}
      </View>
    );
  }
}

Titles.propTypes = {
  title: PropTypes.string,
  titles: PropTypes.array,
  sortedTitles: PropTypes.array,
  totalizers: PropTypes.object,
  onListFiltered: PropTypes.func,
  showSearch: PropTypes.bool,
  onChangeText: PropTypes.func,
  onCLickTitle: PropTypes.func,
  showItemAsHTML: PropTypes.bool,
};

export default Titles;
