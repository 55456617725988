import { StyleSheet } from 'react-native';
import { colors, sizes } from '../../values/index';
import { fonts } from "../../../../src/utils/Fonts";

const styles = StyleSheet.create({
  titleContainer: {
    marginBottom: sizes.verticalSpaceSeparatorNormal,
  },
  title: {
    color: colors.grayDark,
    fontSize: sizes.textLittleBig,
    fontFamily: fonts.QuicksandBold,
  },
});

export default styles;
