/* @flow */

import React from 'react';
import { View, ScrollView } from 'react-native';
import { style } from '~/common';
import { Button } from '~/components';
import translate from '../../../locales';
import TextInputScanner from '../components/TextInputScanner';

type Props = {
  item: {
    activityType: string,
    required: boolean,
  },
  message: string,
  loading: boolean,
  gpsComponent: any,
  onChangeMessage: () => void,
  onSaveFreeText: () => void,
};

class FreeText extends React.Component<Props, void> {
  render() {
    const {
      message,
      onChangeMessage,
      onSaveFreeText,
      loading,
      item,
    } = this.props;

    return (
      <ScrollView keyboardShouldPersistTaps="always">
        <View style={{ flex: 1 }}>{this.props.gpsComponent}</View>
        <View style={style.whiteContainer}>
          <TextInputScanner
            value={message}
            onChangeText={onChangeMessage}
            config={JSON.parse(item?.setup || '{}')?.input_config}
          />
          <Button
            disabled={loading}
            onPress={onSaveFreeText}
            title={loading ? `${translate('saving')}` : `${translate('save')}`}
            containerStyle={{ marginHorizontal: 16, marginBottom: 16 }}
          />
        </View>
      </ScrollView>
    );
  }
}

export default FreeText;
