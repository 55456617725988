// @flow

import React, { Component } from 'react';
import Product from './Product';
import { connect } from 'react-redux';
import {
  ORDER_FETCH_REQUEST,
  ORDER_FETCH_SUCCESS,
} from '~/store/ordersFetch/orderFetchSlice';
import { UPDATE_ITEM_REQUEST } from '~/store/ordersFetch/updateItemSlice';
import _ from 'lodash';
import CacheDataController from '../../../../src/utils/CacheDataController';

import PropTypes from 'prop-types';

class ProductContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatingItem: false,
      product: this.props.product,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.productCurrent &&
      nextProps.productCurrent.ean === nextProps.product.ean
    ) {
      return true;
    }
    if (nextProps.product.stock !== this.props.product.stock) {
      return true;
    }
    if (nextProps.product.quantity !== this.props.product.quantity) {
      return true;
    }
    if (nextProps.product.price !== this.props.product.price) {
      return true;
    }

    if (nextState.updatingItem !== this.state.updatingItem) {
      return true;
    }
    if (nextState.product.stock !== this.state.product.stock) {
      return true;
    }
    if (nextState.product.quantity !== this.state.product.quantity) {
      return true;
    }
    if (nextState.product.price !== this.state.product.price) {
      return true;
    }

    return false;
  }

  UNSAFE_componentWillReceiveProps({ sOrder }) {
    (() => {
      if (this.props.sOrder.type === sOrder.type) {
        return;
      }
      if (this.props.sOrder.type !== UPDATE_ITEM_REQUEST) {
        return;
      }
      if (sOrder.type === UPDATE_ITEM_REQUEST) {
        return;
      }
      if (sOrder.type !== ORDER_FETCH_SUCCESS) {
        return;
      }

      const { payload } = sOrder;
      this.setState({ updatingItem: false });
      this.setProduct(payload);
    })();
  }

  componentDidUpdate(prevProps, prevState) {
    (() => {
      if (!this.props.productCurrent) {
        return;
      }
      if (this.props.product.ean !== this.props.productCurrent.ean) {
        return;
      }
      if (this.props.product.quantity === prevProps.product.quantity) {
        return;
      }
      this.setState({ updatingItem: false });
    })();

    (() => {
      if (prevProps.sOrder.type === this.props.sOrder.type) {
        return;
      }
      if (this.props.sOrder.type === UPDATE_ITEM_REQUEST) {
        return;
      }
      if (prevProps.sOrder.type !== UPDATE_ITEM_REQUEST) {
        return;
      }
      if (this.props.sOrder.type !== ORDER_FETCH_SUCCESS) {
        return;
      }
      const { payload } = this.props.sOrder;
      this.setState({ updatingItem: false });
      this.setProduct(payload);
    })();

    (() => {
      if (this.props.product.quantity === prevProps.product.quantity) {
        return;
      }
      if (this.props.product.quantity === this.state.product.quantity) {
        return;
      }
      this.setState({ product: this.props.product });
    })();

    (() => {
      if (this.props.product.price === prevProps.product.price) {
        return;
      }
      if (this.props.product.price === this.state.product.price) {
        return;
      }
      this.setState({ product: this.props.product });
    })();

    (() => {
      if (this.props.sOrder.type === prevProps.sOrder.type) {
        return;
      }
      if (prevProps.sOrder.type !== ORDER_FETCH_REQUEST) {
        return;
      }
      if (this.props.sOrder.type !== ORDER_FETCH_SUCCESS) {
        return;
      }
      const order = this.props.sOrder.payload;
      this.setProduct(order);
    })();
  }

  setProduct(order) {
    const { product } = this.state;
    const { produtos: products } = order;

    let productUpdate = products.find(prod => prod.chave === product.id);
    if (productUpdate) {
      const {
        estoque,
        quantidade,
        html,
        menor_preco,
        multiplo_venda,
        preco,
        unidade,
      } = productUpdate;

      productUpdate = {
        ...this.state.product,
        stock: estoque,
        quantity: quantidade,
        moreInfo: html,
        minPrice: menor_preco,
        multiple: multiplo_venda,
        price: preco,
        unity: unidade,
      };

      this.setState({ product: productUpdate });
    } else {
      this.setState({ product: { ...this.props.product, quantity: 0 } });
    }
  }

  onChangeQuantity = async (quantity) => {
    const product = { ...this.state.product, quantity };
    await CacheDataController.saveData('@ADD_ITEM_REF', {
      product,
      quantity,
      type: this.props.productType || '',
    });
    this.setState({ updatingItem: true, product });
    const { addQuantity } = this.props;
    if (addQuantity) {
      addQuantity(product, quantity);
    }
  };

  render() {
    const { sOrder, type, liked, configLib, onPress, onPressLike } = this.props;
    const ignoraEstoque = _.get(
      sOrder,
      'payload.configuracoes.ignora_estoque',
      false,
    );
    const ignoraMultiploVenda = _.get(
      sOrder,
      'payload.configuracoes.ignora_multiplo_de_venda',
      false,
    );
    const configComponent = _.get(
      configLib,
      'payload.configuracoes.components_screen',
      {},
    );

    return (
      <Product
        type={type}
        product={this.state.product}
        liked={liked}
        onPress={onPress}
        onPressLike={onPressLike}
        onChangeQuantity={this.onChangeQuantity}
        updatingItem={this.state.updatingItem}
        ignoreVolumeFactor={ignoraMultiploVenda}
        ignoreStock={ignoraEstoque}
        configComponent={configComponent}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    configLib: state.configLibFetch,
    sOrder: state.orderFetch,
  };
}

ProductContainer.propTypes = {
    type: PropTypes.string,
    productCurrent: PropTypes.object,
    product: PropTypes.object,
    liked: PropTypes.bool,
    onPress: PropTypes.func,
    onPressLike: PropTypes.func,
    addQuantity: PropTypes.func,
    sOrder: PropTypes.object,
    configLib: PropTypes.object,
};

export default connect(mapStateToProps)(ProductContainer);
