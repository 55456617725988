import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Colors } from '../config/config';
import { fonts } from '~/utils/Fonts';

import HomeContainer from '../screens/Home/Home.Container';
import ClientDetailContainer from '../screens/ClientDetail/ClientDetail.Container';
import CartContainer from '../screens/Cart/Cart.Container';
import ResumeContainer from '../screens/Resume/Resume.Container';
import ReportFormContainer from '~/screens/Reports/form/ReportForm.Container';
import MessagesContainer from '../screens/Messages/Messages.Container';
import FinishContainer from '../screens/Finish/Finish.Container';
import ProfileContainer from '../screens/Profile/Profile.Container';
import ReportsContainer from '../screens/Reports/Reports.Container';
import TitlesContainer from '../screens/Financing/Titles/Titles.Container';
import WebviewContainer from '../screens/Webview/Webview.Container.web';
import CatalogContainer from '../screens/Catalog/Catalog.Container';
import CatalogProdDetail from '~/screens/Catalog/CatalogProdDetail';
import OrdersContainer from '../screens/Orders/Orders.Container';
import ProductDetailContainer from '../screens/ProductScreens/ProductDetail/ProductDetail.Container';
import ConditionsSalesChildContainer from '../screens/ConditionsSalesChild/ConditionsSalesChildContainer';
import ProductTabsContainer from '../screens/ProductScreens/ProductTabs/ProductTabsContainer';
import ObservationsContainer from '../screens/Resume/Observations/Observations.Container';
import OrderDetailContainer from '../screens/OrderDetail/OrderDetail.Container';
import ProspectsContainer from '../screens/Prospects/Prospects.Container';
import ActivitiesContainer from '../screens/Activities/Activities.Container';
import NotificationsContainer from '../screens/Notifications/Notifications.Container';
import TodayContainer from '../screens/Today/Today.Container';
import { SCREEN_REPORT_FORM } from '~/screens/screens';
import ExpensesContainer from '~/screens/Expenses/ExpensesContainer';
import SalesOpportunityContainer from '~/screens/SalesOpportunity/SalesOpportunity.Container';
import CombosContainer from '../screens/Combos/Combos.Container';
import ComboDetailContainer from '~/screens/ComboDetail/ComboDetail.Container';
import ProductObservationsContainer from '~/screens/Observations/Observations.Container';
import ConditionsSalesChidContainer from '~/screens/ConditionsSalesChild/ConditionsSalesChildContainer';
import ShareOrder from '~/screens/Cart/components/ShareOrder.web';
import ExpensesForm from '~/screens/Expenses/Form';
import ExpenseSuccess from '~/screens/Expenses/Success';
import ExpenseResume from '~/screens/Expenses/Resume';
import ExpenseDetail from '~/screens/Expenses/Detail';
import OrderObservations from '~/screens/Resume/Observations/Observations.Container';
import ActionModal from '~/components/OperationGroup/ActionModal';
import OperationGroupActions from '~/components/OperationGroup/Actions/OperationGroupActions';
import Categories from '~/screens/Categories/Categories';

const Stack = createStackNavigator();

function HomeStack() {
  return (
    <Stack.Navigator
      screenOptions={{
        title: 'Home',
        headerStyle: {
          backgroundColor: Colors.primaryColor,
          textAlign: 'center',
        },
        headerTitleAlign: 'center',
        headerTintColor: '#fff',
        headerTitleStyle: {
          fontFamily: `${fonts.QuicksandBold}`,
          fontWeight: 'bold',
        },
      }}>
      <Stack.Group>
      <Stack.Screen
        name="Home"
        options={{ headerShown: false }}
        component={HomeContainer}
      />
      <Stack.Screen
        name="ClientDetail"
        options={{ title: 'Detalhes do Cliente' }}
        component={ClientDetailContainer}
      />
      <Stack.Screen
        name="Cart"
        options={{ title: 'Produtos' }}
        component={CartContainer}
      />
      <Stack.Screen
        name="Resume"
        options={{ title: 'Resumo do pedido' }}
        component={ResumeContainer}
      />
      <Stack.Screen
        name="Messages"
        options={{ title: 'Mensagens', headerShown: false }}
        component={MessagesContainer}
      />
      <Stack.Screen
        name="Finish"
        options={{ title: 'Pedido concluído' }}
        component={FinishContainer}
      />
      <Stack.Screen
        name="Perfil"
        options={{ title: 'Perfil' }}
        component={ProfileContainer}
      />
      <Stack.Screen
        name="Reports"
        options={{ title: 'Relatórios' }}
        component={ReportsContainer}
      />
      <Stack.Screen
        name="Titles"
        options={{ title: 'Títulos' }}
        component={TitlesContainer}
      />
      <Stack.Screen
        name="webview"
        options={{ title: 'Webview' }}
        component={WebviewContainer}
      />
      <Stack.Screen
        name="Catalog"
        options={{ title: 'Catálogo' }}
        component={CatalogContainer}
      />
      <Stack.Screen
        name="CatalogProdDetail"
        options={{ title: 'Detalhes do Produto' }}
        component={CatalogProdDetail}
      />
      <Stack.Screen
        name="Orders"
        options={{ title: 'Pedidos' }}
        component={OrdersContainer}
      />
      <Stack.Screen
        name="ProductDetail"
        options={{ title: 'Detalhes do Produto' }}
        component={ProductDetailContainer}
      />
      <Stack.Screen
        name="ConditionsSalesChild"
        options={{ title: 'Condições de Venda' }}
        component={ConditionsSalesChildContainer}
      />
      <Stack.Screen
        name="ProductTabs"
        options={{ title: 'Mais Produtos', headerTitleAlign: 'left' }}
        component={ProductTabsContainer}
      />
      <Stack.Screen
        name="Observations"
        options={{ title: 'Observações do Pedido' }}
        component={ObservationsContainer}
      />
      <Stack.Screen
        name="OrderDetail"
        options={{ title: 'Detalhes do Pedido' }}
        component={OrderDetailContainer}
      />
      <Stack.Screen
        name="Prospects"
        options={{ title: 'Prospects' }}
        component={ProspectsContainer}
      />

      <Stack.Screen
        name="Notifications"
        options={{ title: 'Notificações' }}
        component={NotificationsContainer}
      />
      <Stack.Screen
        name="Itinenary"
        options={{ title: 'Hoje' }}
        component={TodayContainer}
      />
      <Stack.Screen
        name={SCREEN_REPORT_FORM.name}
        options={{ title: 'Relatório' }}
        component={ReportFormContainer}
      />
      <Stack.Screen
        name="Expenses"
        options={{ title: 'Despesas' }}
        component={ExpensesContainer}
      />
      <Stack.Screen
        name="SalesOpportunity"
        options={{ title: 'Oportunidades de Venda' }}
        component={SalesOpportunityContainer}
      />
      <Stack.Screen
        name="Combos"
        options={{ title: 'Combos' }}
        component={CombosContainer}
      />
      <Stack.Screen
        name="ComboDetail"
        options={{ title: 'Detalhes do Combo' }}
        component={ComboDetailContainer}
      />
      <Stack.Screen
        name="ProductObservation"
        options={{ title: 'Observações do produto' }}
        component={ProductObservationsContainer}
      />
      <Stack.Screen
        name="ConditionsSalesChid"
        options={{ title: 'Condições de Venda' }}
        component={ConditionsSalesChidContainer}
      />
      <Stack.Screen
        name="ShareOrder"
        options={{ title: 'Compartilhar Pedido' }}
        component={ShareOrder}
      />
      <Stack.Screen
        name="ExpensesForm"
        options={{ title: 'Nova Despesa' }}
        component={ExpensesForm}
      />
      <Stack.Screen
        name="ExpenseSuccess"
        options={{ title: 'Despesa Salva' }}
        component={ExpenseSuccess}
      />
      <Stack.Screen
        name="ExpenseResume"
        options={{ title: 'Resumo de despesas' }}
        component={ExpenseResume}
      />
      <Stack.Screen
        name="ExpenseDetail"
        options={{ title: 'Detalhes da despesa' }}
        component={ExpenseDetail}
      />
      <Stack.Screen
        name="OrderObservations"
        options={{ title: 'Observações do Pedido' }}
        component={OrderObservations}
      />
            <Stack.Screen
        name="Categories"
        options={{ title: 'Categorias' }}
        component={Categories}
      />
      </Stack.Group>
      <Stack.Group screenOptions={{ presentation: 'modal' }}>
      <Stack.Screen
        name='ActionModal'
        options={{ title: 'Operação' }}
        component={ActionModal}
      />
      <Stack.Screen
        name="OperationGroupActions"
        options={{ title: 'Operações' }}
        component={OperationGroupActions}
      />
      </Stack.Group>      
    </Stack.Navigator>
  );
}

export default HomeStack;
