import React from 'react';

import { IntlProvider, FormattedNumber } from 'react-intl';
import translate, { calendarLanguage as language } from '~/locales';

// Redux Imports
import { getPrecisionFromType, parseStrToFloat } from '~/utils/Currency';

import PropTypes from 'prop-types';

const NumberValue = ({
  style = {},
  value = 0,
  type = '',
  formatStyle = 'currency',
}) => {
  const precision = getPrecisionFromType(type);
  const parsedValue = parseStrToFloat(value, precision);

  return (
    <IntlProvider locale={`${language}`}>
      <FormattedNumber
        style={formatStyle}
        // formatStyle={formatStyle}
        currency={translate('currency')}
        value={parsedValue}
        minimumFractionDigits={precision}
        maximumFractionDigits={precision}
      />
    </IntlProvider>
  );
};

NumberValue.propTypes = {
    style: PropTypes.object,
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    formatStyle: PropTypes.string,
};

export default NumberValue;
