import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  FlatList,
  Modal,
  TouchableWithoutFeedback,
  TouchableOpacity,
  Linking,
  Platform,
} from 'react-native';
import color from '../common/color';
import Images from '../../assets/Images';
import { fonts } from '../utils/Fonts';
import linksBase from '~/utils/linksBase';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    width: '100%',
  },
  modalShadow: {
    flex: 1,
    backgroundColor: '#000000AA',
    justifyContent: 'flex-end',
  },
  modal: {
    backgroundColor: color.grayContainer,
    width: '100%',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    paddingHorizontal: 12,
    paddingVertical: 30,
  },
  gridLabel: {
    fontSize: 13,
    paddingTop: 10,
    color: color.grayDarkest,
    fontFamily: fonts.QuicksandMedium,
  },
  image: {
    alignSelf: 'center',
    height: 30,
    width: 30,
  },
  listTitle: {
    fontSize: 16,
    marginBottom: 20,
    color: color.grayTextInput,
    fontFamily: fonts.QuicksandBold,
  },
  separator: {
    borderBottomWidth: 0.3,
    borderBottomColor: color.grayA4,
  },
});

const icons = {
  phone: {
    icon: Images.iconPhoneFill,
    color: color.primaryColorLight,
  },
  whatsapp: {
    icon: Images.iconWhatsapp,
    color: color.whatsappGreenLight,
  },
  facebook: {
    icon: Images.iconFacebook,
    color: color.facebookBlue,
  },
  instagram: {
    icon: Images.iconInstagram,
    color: color.blueIOS,
  },
  email: {
    icon: Images.iconEmailFill,
    color: color.redBadge,
  },
};

class ContactUs extends Component {
  state = {
    show: false,
  }

  onPressContactOption = ({ type, setup = [] }) => {
    setup.forEach(({ key, value }) => {
      if (key === 'url') {
        let link = linksBase[type].supported;
        Linking.canOpenURL(link)
          .then((supported) => {
            if (!supported) {
              link = linksBase[type].store[Platform.OS];
              Linking.openURL(`${linksBase.storeBase[Platform.OS]}${link}`);
              return;
            }
            Linking.openURL(`${link}${value}`);
          }).catch((err) => console.error('An error occurred', err));
      }
    });
    this.close();
  };

  show = () => {
    this.setState({ show: true });
  }

  close = () => {
    this.setState({ show: false });
  }

  renderOutsideTouchable(onTouch) {
    const view = <View style={{ flex: 1, width: '100%' }} />;
    if (!onTouch) return view;

    return (
      <TouchableWithoutFeedback onPress={onTouch} style={{ flex: 1, width: '100%' }}>
        {view}
      </TouchableWithoutFeedback>
    );
  }

  render() {
    const { popupRef, title, contactUs } = this.props;
    const { show } = this.state;
    return (
      <Modal
        animationType="fade"
        transparent
        visible={show}
        onRequestClose={this.close}
        ref={popupRef}
      >
        <TouchableWithoutFeedback onPress={this.close} style={styles.modalContainer}>
          <View style={styles.modalShadow}>
            {this.renderOutsideTouchable()}
            <View style={styles.modal}>
              <Text style={styles.listTitle}>{title}</Text>
              <FlatList
                data={contactUs.filter(({ setup }) => setup[setup.length - 1].value !== '')}
                contentContainerStyle={{ justifyContent: 'space-around', flex: 1 }}
                horizontal
                renderItem={({ item }) => (
                  <TouchableOpacity
                    style={styles.separator}
                    activeOpacity={0.7}
                    onPress={() => this.onPressContactOption(item)}
                  >
                    <Image
                      source={icons[item.type].icon}
                      style={[styles.image, { tintColor: icons[item.type].color }]}
                    />
                    <Text style={styles.gridLabel}>{item.type.toUpperCase()}</Text>
                  </TouchableOpacity>
                )}
              />
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    );
  }
}

ContactUs.propTypes = {
  contactUs: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    setup: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })),
  })),
  popupRef: PropTypes.func,
  title: PropTypes.string,
};

export default ContactUs;
