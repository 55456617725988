import { Dimensions, StyleSheet } from 'react-native';

const width = Dimensions.get('window').width;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // padding: 20,
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  container: {
    width: width > 600 ? 680 : width * 0.9,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    bottom: 30,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  titleContainer: {
    alignItems: 'center',
    marginBottom: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  subTitleContainer: {
    alignItems: 'center',
    marginBottom: 20,
  },
  subTitle: {
    fontSize: 16,
    marginBottom: 20,
  },
  buttonsContainer: {
    flexDirection: 'row',
    top: 15,
    alignContent: 'center',
    justifyContent: 'flex-end',
  },
  buttonCancel: {
    marginRight: 20,
    elevation: 5,
    borderColor: 'red',
    borderWidth: 1,
    borderRadius: 10,
    padding: 5,
    width: 120,
  },
  buttonConfirm: {
    width: 120,
    marginLeft: 20,
    elevation: 5,
    borderColor: 'blue',
    borderWidth: 1,
    borderRadius: 10,
    padding: 5,
  },
  textCancel: {
    textAlign: 'center',
    color: 'red',
  },
  textConfirm: {
    textAlign: 'center',
    color: 'blue',
  },
});

export default styles;
