/* eslint-disable no-nested-ternary,react/prop-types */
import React, { Component } from 'react';
import _ from 'lodash';
import { BackHandler, Platform, Image, TouchableOpacity, View } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Navigation } from 'react-native-navigation';
import { compose } from 'recompose';
import {
  getProductFilters,
  PRODUCT_FILTERS_SUCCESS,
} from '../../store/productsFilters/slice';
import { setSaleOpportunity } from '~/store/salesOpportunityFetch/slice';
import CacheDataController from '../../utils/CacheDataController';
import {
  FAMILY_SCALE_VALIDATION,
  PRODUCTS_VERIFICATION
} from '~/utils/MessageCode';
import { CANCEL_CART_FETCH_REQUEST } from '~/store/ordersFetch/orderCancelSlice';
import {
  ORDER_FETCH_REQUEST,
  ORDER_FETCH_SUCCESS,
  ORDER_FETCH_FAILURE,
  updateOrderScreen,
} from '~/store/ordersFetch/orderFetchSlice';
import { UPDATE_SHIPPING_ORDER_REQUEST } from '~/store/ordersFetch/updateShippingSlice';
import { SAVE_CART_FETCH_REQUEST } from '~/store/ordersFetch/saveCartSlice';
import { APPLY_OPERATION_BY_GROUP_FETCH } from '~/store/ordersFetch/applyOperationByGroupSlice';
import { FINISH_CART_FETCH_REQUEST } from '~/store/ordersFetch/finishCartSlice';
import { REMOVE_MULTIPLE_ITEMS_FETCH } from '~/store/ordersFetch/removeMultipleItemsSlice';

import Cart from './Cart';
import actualCart from '../actualCart/actualCart';
import {
  SCREEN_CART,
  SCREEN_COMPOUND_PRODUCT,
  SCREEN_OBSERVATIONS,
  SCREEN_PRODUCT_DETAIL,
  SCREEN_RESUME_ORDER,
  SCREEN_SALES_CONDITION,
  SCREEN_SALES_CONDITIONS_CHILD,
  SCREEN_SHARE_ORDER,
} from '../screens';
import listsLib from '~/constants/ListsLib';

import executeActionOrOpenScreenMessage from '~/utils/messages';
import setFilters from '../filtersHOC/setFilters';
import Images from '@assets/Images';
import {
  CLIENT_CURRENT_FETCH_REQUEST,
  CLIENT_CURRENT_FETCH_SUCCESS,
} from '~/store/clientCurrent/slice';
import { PROMOTION_FETCH_REQUEST } from '~/store/promotionsFetch/slice';

import GPSManager from '~/controllers/GPS/GPSManager';
import GpsHelper from '~/utils/GpsHelper';
import OrdersModule from '../../modules/OrdersModule';
import { MODULE_ACTIVITIES } from '~/store/makeOrderModule/action';
import { getSourceImageProduct } from '~/utils/ProductUtil';
import translate from '~/locales';
import { setCurrentProduct } from '~/store/productCurrent/slice';
import { syncUpload } from '~/TaskSync';
// slices
import {
  getProducts,
  PRODUCT_FETCH_REQUEST,
  wrapPromise,
  popCod,
  pushCod,
  PRODUCT_FETCH_SUCCESS,
  abort,
  PRODUCT_PUSH_CODS,
  PRODUCT_POP_CODS,
} from '../../store/productsFetch/slice';
import {
  saleSuggestionProducts,
  SALE_SUGGESTION_PRODUCTS_REQUEST,
} from '../../store/saleSuggestionProducts/slice';
import {
  saleHistoryProducts,
  SALE_HISTORY_PRODUCTS_REQUEST,
} from '../../store/saleHistoryProducts/slice';
import {
  updateItemFetch,
  UPDATE_ITEM_REQUEST,
} from '../../store/ordersFetch/updateItemSlice';
import {
  getCartFetch,
  updateStatusOpenOrder,
} from '~/store/ordersFetch/orderFetchSlice';
import { removeMultipleItems } from '~/store/ordersFetch/removeMultipleItemsSlice';
import { applyOperationByGroup } from '~/store/ordersFetch/applyOperationByGroupSlice';
import { saveCart } from '~/store/ordersFetch/saveCartSlice';
import { clearCurrentTask } from '~/store/taskCurrent/slice';
import onPressHelper from '~/utils/onPressHelper';
import SaleOpportunityCache from '~/cache/SaleOpportunityCache';
import { listFieldsVisibility } from '~/utils/GlobalUtils';
import NavigationHelper from '../NavigationHelper';
import {
  getProductsHome,
  PRODUCTS_HOME_FETCH_SUCCESS,
} from '~/store/productsHome/slice';
import {
  filterByTextFields,
  getFieldsSearch,
} from '../Filter/components/filters';
import Snackbar from 'react-native-snackbar';
import CacheOrderActivity from '~/utils/CacheOrderActivity';
import NetInfo from '@react-native-community/netinfo';
import { navigateToAllCategories } from '../../../components_base/src/components/Categories/Categories';
import CategoryDetailParser from './utils/CategoryDetailParser';
import CachePolistore from '~/controllers/Accounts/Cache/CachePolistore';

import PropTypes from 'prop-types';
import { createNotifyError, createNotifyInfo, createNotifySuccess } from '~/components/Web/ToastNotify';
import { tasksSync } from '~/TaskSync.web';
import CacheAccount from '~/controllers/Accounts/Cache/CacheAccount';

const NAME = `${translate('nombre')} (A-Z)`;
const PRICE = `${translate('minPrice')}`;
const SORT_OPTIONS = [NAME, PRICE];
const PAGE_FACTOR = 10;
const PAGE_INITIAL = 1 * PAGE_FACTOR;
const FETCH_MORE_PRODUCTS = {
  saleHistory: 'productsSaleHistoryPage',
  productsSuggestion: 'productsSuggestionPage',
  categories: 'categoriesPage',
  products: 'productsPage',
};


class CartContainer extends Component {
  constructor(props) {
    super(props);
    this.gpsManager = new GPSManager();
    this.location = {};
    this.configCached = {};

    // Carousel Display Setup
    this.carouselType = {};

    this.codigo = '';
    this.productsPage = PAGE_INITIAL;
    this.categoriesPage = PAGE_INITIAL;
    this.productsSaleHistoryPage = PAGE_INITIAL;
    this.productsSuggestionPage = PAGE_INITIAL;
    this.productRef = {};
    this.quantityRef = 0;
    this.categoryIndex = [];
    this.actualAction = null;
    this.isVisibleOperationGroupActions = false;
    this.currentSource = 'cart';

    this.state = {
      showModalSort: false,
      categories: [],
      actualValue: NAME,
      categoryStack: [],
      loading: false,
      loadingSalesHistory: true,
      historyProducts: [],
      loadingSaleSuggestionProducts: true,
      suggestionProducts: [],
      selectedProducts: [],
      salesConditionsChild: { table: null, deadline: null },
      scrollToCategory: false,
      actualCategory: 0,
      goToTopScreen: false,
      onlyCategories: [],
      onlyProducts: [],
      isCombo: false,
      activeProductsGrouping: false,
    };
    // this.productsCategoriesPage = 1;
    this.productsPage = PAGE_INITIAL;
    this.categoriesPage = PAGE_INITIAL;
    this.productsSaleHistoryPage = PAGE_INITIAL;
    this.productsSuggestionPage = PAGE_INITIAL;
    this.productRef = {};
    this.quantityRef = 0;
    this.categoryIndex = [];
    this.actualAction = null;
    this.salesOpportunitySelected = props.salesOpportunity?.selected;
    this.allOnlyProductsRef = [];
    this.productsHomeRef = [];
    this.showAllActive = false;
    this.flatListRef = React.createRef();
    this.salesConditionsRef = React.createRef();

    this.props.navigation.addListener('focus', () => {
      this.pushingScreen = false;
      if (this.state.isCombo) {
          this.setState({ isCombo: false });
      }
      this.props.dispatch(updateOrderScreen(SCREEN_CART.name));
  });
  }

  parserCarousel(carousel) {
    for (const elemt of carousel) {
      this.carouselType[elemt] = true;
    }
  }

  UNSAFE_componentWillMount() {
    const { configLib } = this.props;
    const visibilityFieldsList = listFieldsVisibility(
      configLib.payload,
      'order',
    );

    const { show_carousel: showCarousel = [] } = visibilityFieldsList;
    if (showCarousel != null) {
      this.parserCarousel(showCarousel);
    }
  }

  componentDidMount() {
    this.setIconLeftHeader();
    this.getConfigCached();

    this.pushingScreen = false;
    this.handleAddButtons();
    this.fetchProducts();
    this.persistIfIsFromItinerary();
    this.openConditions();
  }

  fetchProducts() {
    const { order } = this.props;
    const { payload } = order;

    const messages = _.get(payload, 'mensagens', []);
    const errorCondSales = messages.find(
      m => m.tipo === 'erro' && m.codigo === 'condicoes_venda_invalida',
    );

    if (!errorCondSales) {
      const { condvenda, codigo } = payload;
      const { codcliente } = condvenda || {};
      this.codigo = codigo;

      if (!this.codigo) {
        // Probably looks like the cart was cancelled
        return;
      }

      const actualClient = this.props.route.params?.actualClient || this.props.client.current;
      const payloadGetProds = {
        codcliente: actualClient
          ? actualClient?.pkey
          : codcliente,
        nivel: '0',
        codGroup: '0',
        codOrder: codigo.toString(),
        condvenda,
        sortKey: this.getActualSortKey(),
      };

      this.props.dispatch(getProductsHome(payloadGetProds));
      this.props.dispatch(
        wrapPromise(this.props.dispatch(getProducts(payloadGetProds))),
      );
    }
  }

  componentDidUpdate(prevProps, nextProps) {
    if (
      prevProps.productsAndCategories?.loading !==
      nextProps.productsAndCategories?.loading ||
      prevProps.order?.generalLoading !== nextProps.order?.generalLoading ||
      prevProps.client.loading !== nextProps.client.loading ||
      prevProps.productsAndCategories?.preLoading !==
      nextProps.productsAndCategories?.preLoading
    ) {
      this.handleAddButtons();
    }
  }

  persistIfIsFromItinerary = () => {
    const { routeByitinerary } = this.props;
    const actualClient = this.props?.client?.current;
    const task = this.props?.taskCurrent?.current || false;

    if (task) {
      CacheOrderActivity.setOrderActivity(
        this.props.order.payload.codigo,
        actualClient?.dateSelected,
      );
    }
  };

  openConditions = () => {
    const { showConditions } = this.props;
    const { payload } = this.props.order;
    const messages = _.get(payload, 'mensagens', []);
    const filteredMsgs = messages.filter(
      m => m.codigo === 'condicoes_venda_invalida',
    );
    if (showConditions || filteredMsgs.length > 0) {
      this.openConditionSalesSheet();
    }
  };

  comesFromItinerary = () => {
    const activitiesComponentId = _.get(
      this.props.taskCurrent,
      'current.componentId',
      null,
    );

    const screenValue = this.props.screen || null;
    const originScreen = this.props.originScreen || null;
    const makeOrderModule = _.get(this.props.makeOrderModule, 'payload');

    return (
      activitiesComponentId &&
      makeOrderModule === MODULE_ACTIVITIES &&
      screenValue !== null &&
      originScreen !== null &&
      screenValue !== 'orders' &&
      originScreen === 'Activities'
    );
  };

  handleBackScreen = () => {
    const activitiesComponentId = _.get(
      this.props.taskCurrent,
      'current.componentId',
      null,
    );

    if (this.comesFromItinerary() || this.props.taskCurrent.current) {
      this.props.navigation.pop(3);
    } else {
      this.props.navigation.popToTop();
    }
  };

  fetchSalesSuggestionsAndHistory = (order, forceLoading = false) => {
    if (this.state.categoryStack.length === 0) {
      if (this.carouselType.sugestao_venda) {
        setTimeout(() => {
          this.props.dispatch(saleSuggestionProducts(order.payload.codigo));
        }, 100);
      } else if (forceLoading) {
        this.setState({ loadingSaleSuggestionProducts: false });
      }
      if (this.carouselType.historico_venda) {
        setTimeout(() => {
          this.props.dispatch(saleHistoryProducts(order.payload.codigo));
        }, 100);
      } else if (forceLoading) {
        this.setState({ loadingSalesHistory: false });
      }
    }
  };

  refetchProducts(client) {
    const { condvenda, codigo } = this.props.order;
    const { codcliente } = condvenda || {};

    const payloadGetProds = {
      codcliente: client || codcliente,
      nivel: '0',
      codGroup: '0',
      codOrder: codigo?.toString(),
      condvenda,
      sortKey: this.getActualSortKey(),
    };
    this.props.dispatch(getProducts(payloadGetProds));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    (() => {
      if (nextProps.order.type === this.props.order.type) {
        return;
      }
      if (this.props.order.type !== UPDATE_ITEM_REQUEST) {
        return;
      }
      if (nextProps.order.type !== ORDER_FETCH_SUCCESS) {
        return;
      }
      if (this.state.suggestionProducts?.length > 0) {
        const { payload } = nextProps.order;

        const productsOrder = _.get(payload, 'produtos', []);

        this.prodsOrderInSuggestion = this.state.suggestionProducts?.filter(
          ({ id }) => productsOrder?.some(product => product.chave === id),
        );

        this.updateQuantitySuggestionProds(productsOrder);
      }
      if (this.state.historyProducts?.length > 0) {
        const { payload } = nextProps.order;

        const productsOrder = _.get(payload, 'produtos', []);

        this.prodsOrderInSuggestion = this.state.historyProducts?.filter(
          ({ id }) => productsOrder?.some(product => product.chave === id),
        );

        this.updateQuantityHistoryProds(productsOrder);
      }
    })();
    (() => {
      if (
        nextProps.productsAndCategories.type ===
        this.props.productsAndCategories.type
      ) {
        return;
      }
      if (nextProps.productsAndCategories.type === PRODUCT_FETCH_REQUEST) {
        return;
      }
      this.setState({ loadingCategoriesProducts: false });
    })();

    (() => {
      if (nextProps.order.type === this.props.order.type) {
        return;
      }
      if (
        this.props.order.type !== APPLY_OPERATION_BY_GROUP_FETCH &&
        this.props.order.type !== REMOVE_MULTIPLE_ITEMS_FETCH
      ) {
        return;
      }
      this.setState({ loading: false });
      if (nextProps.order.type === ORDER_FETCH_SUCCESS) {
        const { payload } = nextProps.order;
        const msgs = _.get(payload, 'mensagens', []);
        executeActionOrOpenScreenMessage(msgs, null, () => {
          this.setState({ loading: false });
          this.fetchSalesSuggestionsAndHistory(nextProps.order);
          this.onCloseModal();
          createNotifySuccess('Operação aplicada com sucesso!');
        });
      }
    })();

    (() => {
      if (
        nextProps.productsAndCategories.type ===
        this.props.productsAndCategories.type
      ) {
        return;
      }
      if (
        nextProps.productsAndCategories.type === PRODUCT_FETCH_SUCCESS ||
        (nextProps.productsAndCategories.type === PRODUCT_PUSH_CODS &&
          this.props.productsAndCategories.type === PRODUCT_POP_CODS)
      ) {
        let allProductsAndCategories = nextProps.productsAndCategories.payload;
        let canUpdateProducts = false;
        if (
          nextProps.productsAndCategories.codGroupList &&
          nextProps.productsAndCategories.codGroupList.length > 0
        ) {
          const cods = nextProps.productsAndCategories.codGroupList;
          allProductsAndCategories = cods.reduce(
            (arr, code) => arr.find(o => o.codigo === code)?.filhos || [],
            allProductsAndCategories,
          );
          canUpdateProducts = true;
        } else {
          this.setState({ categoryStack: [] });
        }

        const showProductAsHTML = _.get(
          this.props.configLib,
          'payload.listagem_campos_visibilidade.product.show_html',
          false,
        );

        const [onlyCategories, onlyProducts] = _.partition(
          allProductsAndCategories,
          item => item.tipo === 'categoria',
        );

        this.allOnlyProductsRef = onlyProducts;

        this.setState(
          {
            categories: allProductsAndCategories,
            // sortedCategories: allProductsAndCategories,
            loading: false,
            onlyCategories: canUpdateProducts || this.showAllActive
              ? onlyCategories
              : this.state.onlyCategories,
            onlyProducts: canUpdateProducts || this.showAllActive
              ? onlyProducts
              : this.state.onlyProducts,
          },
          () => {
            this.productsRef = onlyProducts;
            const comboForSearch = [['nome', 'codigo']];
            this.props.setList(
              allProductsAndCategories,
              showProductAsHTML,
              comboForSearch,
            );
          },
        );
        this.pushingScreen = false;
        this.addProductsBySalesOpportunity();
      }
    })();

    (() => {
      if (nextProps.order.type === this.props.order.type) {
        return;
      }
      if (nextProps.order.type === CANCEL_CART_FETCH_REQUEST) {
        return;
      }
      if (this.props.order.type !== CANCEL_CART_FETCH_REQUEST) {
        return;
      }
      if (nextProps.order.type === ORDER_FETCH_SUCCESS) {
        const { payload } = nextProps.order;
        const messages = _.get(payload, 'mensagens', []);

        executeActionOrOpenScreenMessage(
          messages,
          this.actionCancelOrderError,
          this.actionCancelOrderNotError,
        );
      }
      if (nextProps.order.type === ORDER_FETCH_FAILURE) {
        createNotifyError(`${translate('atention')}. ${translate('errorOnCancelOrder')}`);
      }
    })();

    (async () => {
      if (nextProps.order.type === this.props.order.type) {
        return;
      }
      if (this.props.order.type !== SAVE_CART_FETCH_REQUEST) {
        return;
      }
      if (nextProps.order.type === ORDER_FETCH_SUCCESS) {
        const { order } = nextProps;
        const { payload } = order;
        const messages = _.get(payload, 'mensagens', []);

        const firstValidMessage = messages.find(msg =>
          [FAMILY_SCALE_VALIDATION, PRODUCTS_VERIFICATION].includes(msg.codigo),
        )?.codigo;


        const actionMessageNotErrorMapper = {
          [FAMILY_SCALE_VALIDATION]: this.updateCartAfterMessagesScreen,
          [PRODUCTS_VERIFICATION]: async () => {
            try {
              const result = await this.props.dispatch(saveCart(this.props.order.payload.codigo));
              const responseMessages = result?.payload?.mensagens ?? [];
              const responseMessage = responseMessages.some(msg =>
                msg.tipo === 'erro',
              )
              if (responseMessage)
                await this.updateCartData()
            } catch (err) {
              // ignore
            }
          }
        };

        const actionMessageErrorMapper = {
          [PRODUCTS_VERIFICATION]: async (option) => {
            if (option === 'no') await actionMessageNotErrorMapper[PRODUCTS_VERIFICATION]()
          },
        };


        const actionMessagesNotError =
          firstValidMessage in actionMessageNotErrorMapper
            ? actionMessageNotErrorMapper[firstValidMessage]
            : this.actionSaveOrderNotError;

        const actionMessagesError =
          firstValidMessage in actionMessageErrorMapper
            ? actionMessageErrorMapper[firstValidMessage]
            : () => {
              if (this.callbackSaveCartError) {
                this.callbackSaveCartError?.();
              }
            };

        executeActionOrOpenScreenMessage(
          messages,
          actionMessagesError,
          () => actionMessagesNotError(payload.codigo),
        );
      }
    })();

    (() => {
      if (nextProps.promotions.type === this.props.promotions.type) {
        return;
      }
      if (nextProps.promotions.type === PROMOTION_FETCH_REQUEST) {
        return;
      }
      this.pushingScreen = false;
    })();

    (() => {
      if (nextProps.historyProducts.type === this.props.historyProducts.type) {
        return;
      }
      if (nextProps.historyProducts.type === SALE_HISTORY_PRODUCTS_REQUEST) {
        return;
      }
      const historyProducts = this.getDataProducts(
        nextProps.historyProducts.payload,
        {
          type: 'saleHistory',
        },
      );
      this.saleHistoryRef = historyProducts;
      this.setState({
        historyProducts: historyProducts?.slice(0, 5),
        loadingSalesHistory: false,
      });
    })();

    (() => {
      if (nextProps.order.type === this.props.order.type) {
        return;
      }
      if (nextProps.order.type === FINISH_CART_FETCH_REQUEST) {
        return;
      }
      if (this.props.order.type !== FINISH_CART_FETCH_REQUEST) {
        return;
      }

      if (nextProps.order.type === ORDER_FETCH_SUCCESS) {
        const messages = _.get(nextProps.order, 'payload.mensagens', []);
        const messageError = messages.find(
          m =>
            m.tipo === 'erro' ||
            (m.tipo === 'question' && m.codigo === 'salv_pedc_tvar'),
        );

        if (!messageError) {
          // tasksSync();

          this.handleBackScreen();

          setTimeout(() => {
            // Timeout for work after the all screens are closed
            createNotifySuccess(`${translate('order_successful')}`);
          }, 200);
        } else {
          const isFocused = this.props.navigation.isFocused();
            if (!isFocused) {
            return;
          }

          const { order } = nextProps;
          const { payload } = order;

          const isFixedPriceScale = messages.some(
            msg => msg.codigo === FAMILY_SCALE_VALIDATION,
          );
          const actionMessagesNotError = isFixedPriceScale
            ? this.updateCartAfterMessagesScreen
            : this.actionSaveOrderNotError;

          executeActionOrOpenScreenMessage(messages, null, () =>
            actionMessagesNotError(payload.codigo),
          );
        }
      }
    })();
    (() => {
      if (nextProps.client.type === this.props.client.type) {
        return;
      }
      if (this.props.client.type !== CLIENT_CURRENT_FETCH_REQUEST) {
        return;
      }

      if (nextProps.client.type === CLIENT_CURRENT_FETCH_SUCCESS) {
        const clientPkey = nextProps.client.pkey;
        this.refetchProducts(clientPkey);
      }
    })();

    (() => {
      if (nextProps.productsHome.type === this.props.productsHome.type) {
        return;
      }
      if (this.showAllActive) {
        return;
      }
      if (nextProps.productsHome.type === PRODUCTS_HOME_FETCH_SUCCESS) {
        this.fetchSalesSuggestionsAndHistory(nextProps.order, true);
        let allProductsAndCategories = nextProps.productsHome.payload;

        const [onlyCategories, onlyProducts] = _.partition(
          allProductsAndCategories,
          item => item.tipo === 'categoria',
        );

        this.productsHomeRef = allProductsAndCategories;

        this.setState(
          {
            // categories: allProductsAndCategories,
            // sortedCategories: allProductsAndCategories,
            loading: false,
            onlyCategories,
            onlyProducts,
          },
          () => {
            // this.productsRef = onlyProducts;
            // const comboForSearch = [['nome', 'codigo']];
            // this.props.setList(
            //   allProductsAndCategories,
            //   showProductAsHTML,
            //   comboForSearch,
            // );
          },
        );
      }
    })();

    (() => {
      if (
        nextProps.suggestionProducts.type === this.props.suggestionProducts.type
      ) {
        return;
      }
      if (
        nextProps.suggestionProducts.type === SALE_SUGGESTION_PRODUCTS_REQUEST
      ) {
        return;
      }
      const suggestionProducts = this.getDataProducts(
        nextProps.suggestionProducts.payload,
        {
          type: 'productsSuggestion',
        },
      );
      this.productsSuggestionRef = suggestionProducts;
      this.setState({
        suggestionProducts: suggestionProducts?.slice(0, 5),
        loadingSaleSuggestionProducts: false,
      });
    })();

    (() => {
      if (nextProps.order.type === this.props.order.type) {
        return;
      }
      if (this.props.order.type !== UPDATE_ITEM_REQUEST) {
        return;
      }
      if (nextProps.order.type !== ORDER_FETCH_SUCCESS) {
        return;
      }
      if (nextProps.order.screen === SCREEN_RESUME_ORDER.name) {
        return;
      }

      const { payload } = nextProps.order;
      const messages = _.get(payload, 'mensagens', []);

      this.setState({ loading: false }, () => {
        if (messages.length) {
          const filteredMsgs = messages.some(
            m => m.codigo === 'sugestao_quantidade',
          )
            ? messages.map(m => ({
              ...m,
              onPressConfirm: this.applySuggestionQuantity,
              onPressCancel: this.applySuggestionQuantity,
            }))
            : messages;
          executeActionOrOpenScreenMessage(filteredMsgs, null, () => {
            if (messages.some(m => m.codigo === 'sugestao_quantidade')) {
              this.applySuggestionQuantity();
            }
          });
        }
      });
    })();

    (async () => {
      if (nextProps.order.type === this.props.order.type) {
        return;
      }
      if (this.props.order.type !== ORDER_FETCH_REQUEST &&
        this.props.order.type !== UPDATE_SHIPPING_ORDER_REQUEST) {
        return;
      }
      if (nextProps.order.type !== ORDER_FETCH_SUCCESS) {
        return;
      }
      if (this.props.client.type === CLIENT_CURRENT_FETCH_SUCCESS) {
        return;
      }
      if (nextProps.order.screen === SCREEN_RESUME_ORDER) {
        return;
      }
      const isFocused = this.props.navigation.isFocused();

      if (!isFocused) {
        return;
      }

      const { payload } = nextProps.order;
      const messages = _.get(payload, 'mensagens', []);

      executeActionOrOpenScreenMessage(messages, null, () => {
        if (this.salesConditionsRef.current !== null) {
          this.salesConditionsRef.current.updateConditionSale(nextProps.order);
        }
      });
    })();
  }

  componentWillUnmount() {
    const { activeProductsGrouping } = this.state;
    if (activeProductsGrouping) {
      this.clearAllProductGrouping();
    }
    this.setState({ loading: false });
    this.backHandler?.remove();
  }

  updateQuantitySuggestionProds = orderItems => {
    this.setState(prevState => {
      const updatedItems = prevState.suggestionProducts.map(stateItem => {
        const foundItem = orderItems.find(item => item.chave === stateItem.id);
        if (foundItem) {
          return { ...stateItem, quantity: foundItem.quantidade };
        }
        return { ...stateItem, quantity: 0 };
      });
      return { suggestionProducts: updatedItems };
    });
  };

  updateQuantityHistoryProds = orderItems => {
    this.setState(prevState => {
      const updatedItems = prevState.historyProducts.map(stateItem => {
        const foundItem = orderItems.find(item => item.chave === stateItem.id);
        if (foundItem) {
          return { ...stateItem, quantity: foundItem.quantidade };
        }
        return { ...stateItem, quantity: 0 };
      });
      return { historyProducts: updatedItems };
    });
  };

  async addProductsBySalesOpportunity() {
    const selected =
      this.salesOpportunitySelected ||
      (await SaleOpportunityCache.getSaleOptnSelected());

    if (selected) {
      const orgvenda = _.get(this.props.order?.payload?.condvenda, 'orgvenda');

      const opportunityProducts = _.get(selected, 'arr_produtos', []).filter(
        item =>
          item.u_pkey_orgvenda === undefined ||
          item.u_pkey_orgvenda === Number(orgvenda),
      );

      if (opportunityProducts.length > 0) {
        for (const product of opportunityProducts) {
          this.addItem(
            { ...product, chave: product.u_pkey },
            product.d_quantidade,
            true,
          );
        }
      }
    }
  }

  handleRegisterBackPress() {
    this.backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      this.handleBackPress,
    );
  }

  navigationButtonPressed({ buttonId, type, link }) {
    if (type === 'DeepLink') {
      const parts = link.split('/');
      if (parts[0] === 'tab1') {
        NavigationHelper.push(this.props.componentId, parts[1]);
      }
    }
    if (buttonId === 'showCombos' || buttonId === 'showCombosPedido') {
      this.setState({ isCombo: true });
    } else if (buttonId === 'RNN.back') {
      this.onPressHandleBackPress();
    }
  }

  handleBackPress = async () => {
    const isVisible = this.props.navigation.isFocused();
    const operationGroupContext = [
      'OperationGroupActionModalID',
      'OperationGroupActionsID',
    ].includes(this.props.currentScreenVisible.screen);

    if (
      !isVisible &&
      !operationGroupContext
    ) {
      return;
    }
    this.setState({ loading: true }, () => {
      this.pushingScreen = false;
    });
    if (this.state.showModalSort) {
      this.setState({ showModalSort: false });
    }
    if (this.state.selectedProducts.length > 0) {
      this.setState({ showBackPressAlert: true, loading: false });
    } else {
      this.popScreen();
    }
    this.updateActualCategory();

    return true;
  };

  onPressHandleBackPress = onPressHelper.debounce(this.handleBackPress);

  updateCartData = async () => {
    const cart = _.get(this.props, 'order.payload', '');
    const cartId = cart.codigo.toString();
    await this.props.dispatch(getCartFetch(cartId));
  }

  updateCartAfterMessagesScreen = () => {
    this.updateCartData();
    this.props.dispatch(setCurrentProduct({}));
  };

  onCloseModal = () => {
    this.setState({ showModalSort: false, showBackPressAlert: false });
  };

  onPressObservations = product => {
    this.props.navigation.navigate('ProductObservation', {
      product,
  });
  };

  onClickOnPressObservations = onPressHelper.debounce(this.onPressObservations);

  onPressConditionsSalesChild = product => {
    this.props.navigation.navigate('ConditionsSalesChid', {
      product,
  });
  };

  onPressClickConditionsSalesChild = onPressHelper.debounce(
    this.onPressConditionsSalesChild,
  );

  onListFiltered = list => {
    const [onlyProducts, onlyCategories] = _.partition(
      list,
      l => l.tipo === 'produto',
    );
    this.setState({
      onlyCategories,
      onlyProducts,
    });
  };

  getNextPageFetchMoreProducts = type => {
    if (type && Object.keys(FETCH_MORE_PRODUCTS).includes(type)) {
      return FETCH_MORE_PRODUCTS[type];
    }
    return 'productsPage';
  };

  getDataProducts(productsToSlice, { type }) {
    const page = this.getNextPageFetchMoreProducts(type);
    const pageSize = this[page] * PAGE_FACTOR;
    const productsSliced = productsToSlice.slice(0, pageSize);
    return productsSliced.map(product => {
      const {
        chave,
        codigo,
        nome,
        menor_preco: menorPreco,
        estoque,
        descritivo,
        multiplo_venda: multiploVenda,
        ean,
        tipo,
        html,
        unidade,
        unidade_macro: UnidadeMacro,
      } = product;

      const { quantity, price } = this.getDataInOrder(product);
      const image = getSourceImageProduct(product, this.configCached);

      return {
        ...product,
        id: chave,
        codigo,
        name: nome,
        price,
        minPrice: menorPreco,
        stock: estoque,
        description: descritivo,
        quantity,
        multiple: multiploVenda,
        ean,
        image,
        type: tipo,
        moreInfo: html,
        unity: unidade,
        unidade_macro: UnidadeMacro,
      };
    });
  }

  getConfigCached = async () => {
    const value = await AsyncStorage.getItem('@objPayload');
    if (value) {
      this.configCached = JSON.parse(value);
    }
  };

  getDataInOrder(product) {
    const { chave } = product;
    const { payload } = this.props.order;
    const prod = _.find(payload.produtos, o => o.chave === chave);

    if (prod) {
      const { preco, quantidade } = prod;
      return { quantity: quantidade, price: preco };
    }

    return { quantity: 0, price: product.preco };
  }

  getActualSortKey(value = this.state.actualValue) {
    return value === NAME ? 'nome' : 'preco';
  }

  sort = value => {
    const stringValue = SORT_OPTIONS[parseInt(value, 2)];
    const sortLength =
      Platform.OS === 'android' ? SORT_OPTIONS.length : SORT_OPTIONS.length - 1;

    if (stringValue !== this.state.actualValue && value !== sortLength) {
      const key = this.getActualSortKey(stringValue);
      const itens = Object.assign([], this.state.categories);
      const sortedItens = _.sortBy(itens, [
        item => (item.tipo === 'categoria' ? -1 : 0),
        key,
        'nome',
      ]);

      this.setState({
        onlyCategories: sortedItens.filter(i =>
          ['categoria', 'grupo'].includes(i.tipo),
        ),
        onlyProducts: sortedItens.filter(i => i.tipo === 'produto'),
        actualValue: stringValue,
        showModalSort: false,
      });
    }
  };

  recalculatePrice = async (values, item, callback = () => { }) => {
    const { price, discountType, valueDiscount, quantity } = values;
    try {
      let data;

      if (valueDiscount !== 0) {
        data = await RTNFastSeller?.calcDiscountIncrement(
          item.chave.toString(),
          `${valueDiscount}`.toString(),
          discountType,
        );
      } else {
        data = await OrdersModule?.calcPrice(
          item.chave.toString(),
          `${price}`.toString(),
        );
      }
      data = JSON.parse(data);

      const { acrescimo, desconto } = data;

      const product = {
        ...item,
        preco: price,
        acrescimo,
        desconto,
        quantidade: quantity,
        precototal: quantity * price,
        from_recalculate: true,
      };

      this.setState({ productDisplayedModal: product }, () => callback());
    } catch (error) {
      const messages = JSON.parse(error.message);
      executeActionOrOpenScreenMessage(messages);
    }
  };

  productClick = item => {
    if(!this.props.productsHome?.loading) {
      const screen =
      item.tipo === 'produto'
        ? SCREEN_PRODUCT_DETAIL.name
        : SCREEN_COMPOUND_PRODUCT.name;

        this.props.navigation.navigate(screen, {
          addItem: this.addItem,
          product: item,
          pageDefault: 0,
      });
    }
  };

  onPressProductCLick = onPressHelper.debounce(this.productClick);

  parseData(data) {
    return data.map(item => {
      if (item.tipo === 'categoria' || item.tipo === 'grupo') {
        return item;
      }

      let produto = Object.assign({}, item);
      const { order } = this.props;
      const items = order.payload.produtos || [];
      const produtoInOrder = _.find(items, ['chave', item.chave.toString()]);

      if (produtoInOrder) {
        produto.precototal = produtoInOrder.precototal;
        if (produtoInOrder.preco > 0) {
          const { type_magnitudes: typeMagnitudes } = produto;
          produto = Object.assign({}, produtoInOrder);
          produto.type_magnitudes = typeMagnitudes;
        }
      } else {
        produto.quantidade = 0;
        produto.precototal = 0;
      }
      return produto;
    });
  }

  categoryClick = (item, source = 'cart') => {
    this.currentSource = source;
    this.categoryIndex.push(item.categoryIndex);

    this.setState(
      state => {
        state.categoryStack.push(item);
        return {
          categoryStack: state.categoryStack,
          loading: true,
          scrollToCategory: true,
          actualCategory: 0,
        };
      },
      () => {
        const { order } = this.props;
        const { payload } = order;
        const { codigo } = payload;
        const { condvenda } = payload;
        const { codcliente } = condvenda;
        const nivel = this.state.categoryStack.length.toString();

        const cods = this.state.categoryStack.map(cat => cat.codigo);
        const categories = cods.reduce(
          (arr, code) => arr.find(o => o.codigo === code)?.filhos,
          this.props.productsAndCategories.payload,
        );
        if (categories && categories.length > 0) {
          this.setState(
            {
              categories,
              onlyCategories: categories.filter(i =>
                ['categoria', 'grupo'].includes(i.tipo),
              ),
              onlyProducts: categories.filter(i => i.tipo === 'produto'),
              loading: false,
              scrollToCategory: true,
            },
            () => {
              this.props.setList(categories);
            },
          );
          this.props.dispatch(pushCod(item.codigo));
        } else {
          const payloadGetProds = {
            codcliente: this.props.actualClient
              ? this.props.actualClient.pkey
              : codcliente,
            nivel: nivel,
            codGroup: item.codigo,
            codOrder: codigo.toString(),
            condvenda,
            sortKey: this.getActualSortKey(),
          };
          this.props.dispatch(
            wrapPromise(this.props.dispatch(getProducts(payloadGetProds))),
          );
        }
        this.props.navigation.setOptions({
          title: item.nome,
      });
      },
    );
  };

  onPressCategoryClick = onPressHelper.debounce(this.categoryClick);

  clearAllProductGroupingAfterRemoveItem(removed) {
    const { activeProductsGrouping } = this.state;
    if (removed && activeProductsGrouping) {
      this.clearAllProductGrouping();
    }
  }

  addItem = async (
    item,
    quantity,
    ignoreQtdSuggestion = false,
    fromModal = false,
  ) => {
    const { order, currentProduct } = this.props;

    const { codcliente } = order.payload.condvenda;
    let itemCopy = Object.assign({}, item);
    itemCopy.quantidade = quantity;
    if (
      !fromModal &&
      currentProduct.payload &&
      currentProduct.payload.chave === item.chave
    ) {
      const {
        preco,
        precobase,
        desconto,
        acrescimo,
        estoque,
      } = currentProduct.payload;
      itemCopy = {
        ...itemCopy,
        preco,
        precobase,
        desconto,
        acrescimo,
        estoque,
      };
      const payloadAddItem = {
        clientCode: codcliente,
        itemKey: itemCopy.chave.toString(),
        item: itemCopy,
        ignoreQtdSuggestion: ignoreQtdSuggestion,
      };
      this.props.dispatch(updateItemFetch(payloadAddItem));
    } else {
      const payloadAddItem = {
        clientCode: codcliente,
        itemKey: itemCopy.chave.toString(),
        item: itemCopy,
        ignoreQtdSuggestion: ignoreQtdSuggestion,
      };
      this.props.dispatch(updateItemFetch(payloadAddItem));
    }
    this.props.dispatch(updateOrderScreen(SCREEN_CART.name));

    this.clearAllProductGroupingAfterRemoveItem(quantity === 0);
  };

  popScreen() {
    if (this.state.categoryStack.length > 0) {
      this.setState({ goToTopScreen: true });
      this.popScreenCurrent();
    } else {
      if (this.showAllActive) {
        this.setState({ loading: false }, () => {
          this.props.navigation.setOptions({
            title: translate('cartDetailScreen'),
        });
          this.onBackShowAll?.();
          this.showAllActive = false;
        });
      } else {
        this.popScreenPrev();
      }
    }
    this.currentSource = 'cart';
    this.setState({ goToTopScreen: false });
  }

  popScreenCurrent() {
    this.setState(
      state => {
        const { categoryStack } = state;
        categoryStack.pop();

        return {
          categoryStack,
          loading: true,
          actualCategory: this.categoryIndex[this.categoryIndex.length - 1],
          scrollToCategory: false,
        };
      },
      () => {
        const { categoryStack } = this.state;
        const category = categoryStack[categoryStack.length - 1];

        if (category) {
          this.props.navigation.setOptions({
            title: category.nome,
        });
        } else {
          this.props.navigation.setOptions({
            title: translate('cartDetailScreen'),
        });
        }

        this.categoryIndex.pop();
        const cods = categoryStack.map(cat => cat.codigo);
        const allProducts = this.props.productsAndCategories.payload;
        const products = cods.length !== 0 ? allProducts : this.productsHomeRef;
        const productsAndCategories = cods.reduce(
          (arr, code) => arr.find(o => o.codigo === code).filhos,
          products,
        );

        const [onlyCategories, onlyProducts] = _.partition(
          productsAndCategories,
          item => ['categoria', 'grupo'].includes(item.tipo),
        );

        if (cods.length === 0) {
          const allOnlyProducts = _.filter(
            allProducts,
            item => item.tipo !== 'categoria',
          );
          this.allOnlyProductsRef = allOnlyProducts;
        }

        if (this.currentSource === 'all' && !category) {
          navigateToAllCategories(
            this.props.componentId,
            CategoryDetailParser.categoriesfromJsonLib(onlyCategories),
            this.onPressCategoryClick,
          );
        }

        this.setState(
          {
            categories: productsAndCategories,
            onlyCategories,
            onlyProducts,
          },
          () => {
            this.props.setList(productsAndCategories);
            this.setState({
              loading: false,
              scrollToCategory: true,
            });
          },
        );
      },
    );
    this.props.dispatch(popCod());
  }

  popScreenPrev() {
    this.setState({
      loading: true,
      scrollToCategory: false,
      actualCategory: 0,
    });

    const order = _.get(this.props.order, 'payload');
    const isLoading = _.get(this.props, 'order.loading', true);

    if (!isLoading) {
      if (order.total > 0.0) {
        this.setState({ loading: false }, () => {
          alert('Para sair do pedido, é necessário escolher uma opção. \n\nClique no botão flutuante no canto da tela e escolha o que deseja fazer.');
        });
      } else {
        this.cancelActiveOrder();
      }
    }
    this.setState({ loading: false });
  }

  saveCartBudget = async (callbackSuccess, callbackError) => {
    const task = this.props?.taskCurrent?.current?.item;
    this.callbackSaveCartSucces = callbackSuccess;
    this.callbackSaveCartError = callbackError;

    if (task && this.comesFromItinerary()) {
      const client = this.props.client.current;
      const setup = task.setup ? JSON.parse(task.setup) : {};
      try {
        const location = await this.gpsManager.getCurrentPosition();
        this.location = { ...location.coords, timestamp: location.timestamp };
        const message = GpsHelper.validateRulesGPSClient(
          setup,
          client,
          this.location,
        );
        if (!_.isEmpty(message)) {
          createNotifyInfo(`${translate('atention')}. ${err}`);
          return;
        }
      } catch (err) {
        createNotifyInfo(`${translate('atention')}. ${err}`);
        return;
      }
    }

    this.props.dispatch(saveCart(this.props.order.payload.codigo));
  };

  handleAddButtons() {
    const payload = _.get(this.props.configLib, 'payload');
    const rightButtons = [];
    const { componentId, productsAndCategories, order, client } = this.props;

    const disableButtons =
      productsAndCategories?.loading ||
      order?.generalLoading ||
      client?.loading ||
      productsAndCategories?.preLoading;

      if (!disableButtons) {
        this.props.navigation.setOptions({
            headerRight: () => (
            <View style={{ flexDirection: 'row' }}>
                <View>
                <TouchableOpacity
                    onPress={() => {
                    this.props.navigation.navigate('ProductTabs');
                    }}
                >
                    <Image
                    source={Images.iconBulletedList}
                    style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 10 }}
                    />
                </TouchableOpacity>
                </View>
            </View>
            ),
        });
    }

    if (payload.configuracoes) {
      const confOrder = payload.configuracoes;

      if (confOrder.lists_visibles) {
        if (confOrder.lists_visibles.includes(listsLib.COMBOS_DISPONIVEIS)) {
          this.props.navigation.setOptions({
            headerRight: () => (
                <View style={{ flexDirection: 'row' }}>
                <View>
                <TouchableOpacity
                    onPress={() => {
                        this.setState({ isCombo: true }, () => {
                            this.props.navigation.navigate('Combos', {
                                comboType: 'combos',
                            });
                        });
                    }
                    }
                >
                    <Image
                        source={Images.iconStar}
                        style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 20 }}
                    />
                </TouchableOpacity>
                </View>
                <View>
                  <TouchableOpacity onPress={() => {
                this.props.navigation.navigate('ProductTabs');
              }}
                  >
                    <Image
                      source={Images.iconBulletedList}
                      style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 10 }}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            )
        });
        }
        if (
          confOrder.lists_visibles.includes(listsLib.COMBOS_PEDIDO_DISPONIVEIS)
        ) {
          this.props.navigation.setOptions({
            headerRight: () => (
                <View style={{ flexDirection: 'row' }}>
                <View>
                <TouchableOpacity
                    onPress={() => {
                        this.setState({ isCombo: true });
                    }
                    }
                >
                    <Image
                        source={Images.appOrders}
                        style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 20 }}
                    />
                </TouchableOpacity>
                </View>
                <View>
                  <TouchableOpacity onPress={() => {
                this.props.navigation.navigate('ProductTabs');
              }}
                  >
                    <Image
                      source={Images.iconBulletedList}
                      style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 10 }}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            )
        });
        }
      }
    }
    // NavigationHelper.setRightButtons(componentId, rightButtons);
  }

  eventSaveBudget(pkeyOrder) {
    return new Promise((resolve, reject) =>
      OrdersModule?.eventSaveBudget(
        `${pkeyOrder}`,
        JSON.stringify(this.location || {}),
        resolve,
        reject,
      ),
    );
  }

  addHistoryProduct = (product, quantity, ignoreQtdSuggestion = false) => {
    const { payload } = this.props.historyProducts;
    const productFound = payload.find(prod => prod.codigo === product.codigo);
    this.addItem(productFound, quantity, ignoreQtdSuggestion);
  };

  onPressAddHistoryProduct = onPressHelper.debounce(this.addHistoryProduct);

  addSuggestionProduct = (product, quantity, ignoreQtdSuggestion = false) => {
    const { payload } = this.props.suggestionProducts;
    const productFound = payload.find(prod => prod.codigo === product.codigo);
    this.addItem(productFound, quantity, ignoreQtdSuggestion);
  };

  onPressAddSuggestionProduct = onPressHelper.debounce(
    this.addSuggestionProduct,
  );

  clickProductInHistoryProduct = product => {
    const { payload } = this.props.historyProducts;
    const productFound = payload.find(p => p.codigo === product.codigo);
    this.productClick(productFound);
  };

  onPressClickProductInHistoryProduct = onPressHelper.debounce(
    this.clickProductInHistoryProduct,
  );

  clickProductInSuggestionProduct = product => {
    const { payload } = this.props.suggestionProducts;
    const productFound = payload.find(p => p.codigo === product.codigo);
    this.productClick(productFound);
  };

  onPressclickProductInSuggestionProduct = onPressHelper.debounce(
    this.clickProductInSuggestionProduct,
  );

  fetchMoreProducts = ({ type }) => {
    const page = this.getNextPageFetchMoreProducts(type);
    this[page] += PAGE_FACTOR;
    this.setState(state => ({ loadingFetchMore: !state.loadingFetchMore }));
  };

  updateActualCategory() {
    if (this.categoryIndex.length > 0) {
      const newActualCategory = this.categoryIndex[
        this.categoryIndex.length - 1
      ];
      this.setState({
        actualCategory: newActualCategory,
        scrollToCategory: false,
      });
    } else {
      this.setState(
        {
          actualCategory: 0,
          scrollToCategory: false,
        },
        () => {
          this.productsRef = this.state.onlyProducts;
        },
      );
    }
  }

  applySuggestionQuantity = async (option, params = {}) => {
    this.setState({ loading: true });
    const { quantidade_sugerida: suggestion, target: targetProduct } = params;
    const productRefCache = await CacheDataController.getData('@ADD_ITEM_REF');

    let toApplyProduct = productRefCache;

    if (
      targetProduct &&
      productRefCache?.product.codigo !== targetProduct?.product?.codigo
    ) {
      toApplyProduct = targetProduct;
    }
    if (!toApplyProduct) {
      this.setState({ loading: false });
      return;
    }

    const { product: productRef, quantity: quantityRef, type = '' } =
      toApplyProduct || {};

    if (option === 'yes') {
      switch (type) {
        case 'history':
          this.addHistoryProduct(productRef, suggestion || quantityRef);
          break;
        case 'suggestion':
          this.addSuggestionProduct(productRef, suggestion || quantityRef);
          break;
        default:
          this.addItem(productRef, suggestion || quantityRef);
          break;
      }
    } else {
      switch (type) {
        case 'history':
          this.addHistoryProduct(productRef, quantityRef, true);
          break;
        case 'suggestion':
          this.addSuggestionProduct(productRef, quantityRef, true);
          break;
        default:
          this.addItem(productRef, quantityRef, true);
          break;
      }
    }
    await CacheDataController.removeItem('@ADD_ITEM_REF');
  };

  actionCancelOrderError = () => {
    this.pushingScreen = false;
  };

  actionCancelOrderNotError = () => {
    setTimeout(() => {
      this.props.dispatch(updateStatusOpenOrder(true));
      this.props.dispatch(setCurrentProduct({}));
    }, 100);
    this.backToRoot();
  };

  cancelActiveOrder = async () => {
    const promiseCancelOrder = new Promise((resolve, reject) =>
      OrdersModule?.cancelActiveCart(resolve, reject),
    );

    try {
      this.props.dispatch(abort());
      this.props.dispatch(updateStatusOpenOrder(true));
      await promiseCancelOrder;
      this.backToRoot();
      this.props.dispatch(setCurrentProduct({}));
      this.props.dispatch(setSaleOpportunity(false));
      SaleOpportunityCache.removeSaleOptnSelected();
      this.props.dispatch(clearCurrentTask());
    } catch (error) {
      createNotifyError(`${translate('atention')}.${translate('errorOnCancelOrder')}`);
    }
  };

  onPressCancelOrder = () => {
    if (this.props.order?.payload?.total > 0.0) {
      if (
        window.confirm(
          `${translate('wantDiscardYourChanges')}`,
        )
      ) {
        this.cancelActiveOrder();
      }
    } else {
      this.cancelActiveOrder();
    }
  };

  actionSaveOrderError = () => {
    this.setState({ loading: false });
    if (window.confirm(`${translate('yourChangesWillBeLost')}`)) {
      this.cancelActiveOrder();
    } else {
      this.pushingScreen = false;
    }
  };

  cachingOrderAnwser = async codigo => {
    const { dateSelected: currentDate } =
      (await CacheOrderActivity.getOrderActivity()) || {};
    const taskCurrent = _.get(this.props, 'taskCurrent.current', {});

    const startDate = _.get(
      taskCurrent,
      'answer.start_date',
      new Date().toISOString(),
    );

    const dateSelected =
      _.get(this.props, 'client.current.dateSelected', currentDate) || '';

    const client = this.props.client.current;

    const { id: idTask = '0', id_processo: idProcess = '0' } = taskCurrent;
    const idContext = _.get(taskCurrent, 'event.id_contexto', '0');

    const userData = await new CacheAccount().getDataAccount() || '{}';
    const codmaq = userData?.user?.cod_maq || null;

    if (!codmaq) {
      createNotifyError(`${translate('cantSaveAnswer')}. ${translate('pleaseTryAgain')}`);
      return;
    }

    const baseAnswerPayload = {
      dataOpen: startDate,
      keyValueContext: client.pkey,
      idTask,
      idProcess,
      idContext,
      dateSelected,
      codmaq,
    };

    CacheOrderActivity.setPreOrderActivity(codigo, baseAnswerPayload);
  };

  actionSaveOrderNotError = codigo => {
    this.setState({ loading: false }, async () => {
      await this.eventSaveBudget(codigo);

      const { pkey } = (await CacheOrderActivity.getOrderActivity()) || {};
      if (
        this.props.makeOrderModule?.payload === MODULE_ACTIVITIES ||
        pkey === codigo
      ) {
        this.cachingOrderAnwser(codigo);
      }
      setTimeout(() => {
        this.props.dispatch(updateStatusOpenOrder(true));
        if (this.callbackSaveCartSucces) {
          this.callbackSaveCartSucces?.();
        } else {
          this.backToRoot();
        }
      }, 200);
    });
  };

  backToRoot() {
    this.handleBackScreen();
  }

  onChangeTableOrDeadline = resp => {
    const table = _.get(resp, 'tableChildSelect', {});
    const deadline = _.get(resp, 'prazChildSelect', {});

    const salesConditionsChild = { table, deadline };
    this.setState({ salesConditionsChild });

    this.clearAllProductGrouping();
  };

  onPressTableOrDeadline = () => {
    this.props.navigation.navigate('SalesConditionsChild', {
      product: {},
      onChangeTableOrDeadline: this.onChangeTableOrDeadline,
    });

    if (this.isVisibleOperationGroupActions) {
      this.dismissOperationGroupModal();
    }
  };

  toogleProductsGrouping = () => {
    const { activeProductsGrouping } = this.state;
    this.setState({
      activeProductsGrouping: !activeProductsGrouping,
    });
  };

  getActionTypeHandler() {
    return {
      operationGroupingDefault: this.onApplyOperationByGroup,
      removeSelectedItems: this.onApplyRemoveItems,
    };
  }

  handleOperationGroup = (actionType, props) => {
    const handler = this.getActionTypeHandler();
    handler[actionType](props);
  };

  toggleOperationGroupModal = props => {
    console.log('toggleOperationGroupModal', props);
    const { operation } = props;
    if (operation === 'deselectSelected') {
      this.handleProductGrouping?.('clearAll')
      return;
    }
    if (['deadline', 'table'].includes(operation)) {
      this.onPressTableOrDeadline();
    } else {
      this.props.navigation.navigate('ActionModal', {
        onApply: this.handleOperationGroup,
        props: props,
      });
    }
  };

  dismissOperationGroupModal = () => {
    // Navigation.dismissOverlay('OperationGroupActionsID');
    const { activeProductsGrouping } = this.state;
    if (activeProductsGrouping) {
      this.clearAllProductGrouping();
    } else {
      this.props.navigation.goBack();
    }
    this.isVisibleOperationGroupActions = false;
  };

  showOperationGroupActions = () => {
    if (!this.isVisibleOperationGroupActions) {
      this.isVisibleOperationGroupActions = true;
      if (!this.isVisibleOperationGroupActions) return;
      this.props.navigation.navigate('OperationGroupActions', {
        onPress: this.toggleOperationGroupModal,
      },
      {
        mode: 'modal',
      });
    }
  };

  appendProductGrouping = ({ product }) => {
    let { selectedProducts } = this.state;

    const prodIndex = selectedProducts.findIndex(
      ({ codigo }) => codigo === product.codigo,
    );

    selectedProducts =
      prodIndex !== -1
        ? selectedProducts.filter(p => p.codigo !== product.codigo)
        : [...selectedProducts, { ...product, selected: true }];

    this.setState({ selectedProducts }, () => {
      const { activeProductsGrouping } = this.state;

      if (selectedProducts.length && !this.isVisibleOperationGroupActions) {
        this.showOperationGroupActions();
      }

      if (!selectedProducts.length && activeProductsGrouping) {
        this.toogleProductsGrouping();
      }

      if (!selectedProducts.length && this.isVisibleOperationGroupActions) {
        this.dismissOperationGroupModal();
      }
    });
  };

  clearAllProductGrouping = () => {
    this.setState({ selectedProducts: [] }, () => {
      this.toogleProductsGrouping();
      if (this.isVisibleOperationGroupActions) {
        this.dismissOperationGroupModal();
      }
    });
  };

  getProductGroupingHandler = () => {
    return {
      append: this.appendProductGrouping,
      clearAll: this.clearAllProductGrouping,
      toggle: this.toogleProductsGrouping,
    };
  };

  handleProductGrouping = (operation, props = {}) => {
    const handler = this.getProductGroupingHandler();
    handler[operation](props);
  };

  onConfirmBackPress = () => this.popScreen();

  onPressOnConfirmBackPress = onPressHelper.debounce(this.onConfirmBackPress);

  onApplyRemoveItems = async () => {
    const { selectedProducts } = this.state;
    this.actualAction = 'remove_items';
    this.setState({ loading: true });
    this.props.dispatch(setCurrentProduct({}));
    this.props.dispatch(removeMultipleItems({ selectedProducts }));
  };

  onSearch = val => {
    const { productsAndCategories } = this.props;
    const isRootScreen =
      (productsAndCategories?.codGroupList || []).length === 0;
    if (!productsAndCategories.loading) {
      let productsFiltered = [];
      if (val) {
        const fields = getFieldsSearch(this.allOnlyProductsRef);
        productsFiltered = filterByTextFields(
          this.allOnlyProductsRef,
          fields,
          val,
        );
      } else if (isRootScreen && !this.showAllActive) {
        productsFiltered = this.productsHomeRef?.filter(
          item => item.tipo === 'produto',
        );
      } else {
        productsFiltered = this.allOnlyProductsRef;
      }

      this.setState({
        onlyProducts: productsFiltered,
        loadingCategoriesProducts: false,
      });
    }
  };

  onApplyOperationByGroup = async operations => {
    const { selectedProducts } = this.state;
    this.actualAction = 'group_operation';
    this.setState({ loading: true });
    this.props.dispatch(setCurrentProduct({}));
    this.props.dispatch(
      applyOperationByGroup({ operations, selectedProducts }),
    );
  };

  attachRef = ref => {
    this.salesConditionsRef.current = ref;
  };

  openConditionSalesSheet = () => {
    NavigationHelper.showOverlay(SCREEN_SALES_CONDITION.name, {
      cancelActiveOrder: this.cancelActiveOrder.bind(this),
      onClose: this.handleRegisterBackPress.bind(this),
      attachRef: this.attachRef,
    });
  };

  onPressShowAll = (type, callback = () => { }) => {
    let onlyProducts;
    let titleText = `${translate('cartScreen')}`;

    if (type) {
      onlyProducts = this[`${type}Ref`];
      titleText = `${translate(`${type}`)}`;
    } else {
      [, onlyProducts] = _.partition(
        this.props.productsAndCategories?.payload,
        item => item.tipo === 'categoria',
      );
    }

    this.showAllActive = true;
    this.onBackShowAll = callback;
    this.setState({ onlyProducts }, () => {
      this.props.navigation.setOptions({
        topBar: {
            title: {
                text: titleText,
            },
        },
    });
    });
  };

  goToShareOrder = async () => {
    const configs = _.get(this.props, 'configLib', {});
    const order = _.get(this.props.order, 'payload', {});
    const client = _.get(this.props.client, 'current', {});
    const activitiesComponentId = _.get(
      this.props.taskCurrent,
      'current.componentId',
      null,
    );

    const netInfo = await NetInfo.fetch();
    if (!netInfo.isConnected) {
      createNotifyError('Sem conexão com a internet');
      return;
    }

    /* NavigationHelper.push(
      this.props.componentId,
      SCREEN_SHARE_ORDER.name,
      'Configurações de envio',
      {
        configs,
        order,
        client,
        saveCartBudget: this.saveCartBudget,
        comesFromItinerary: this.comesFromItinerary(),
        activitiesComponentId,
      },
      {
        layout: {
          backgroundColor: '#fff',
        },
      },
    ); */
  };

  onPressFloatingAction = id => {
    switch (id) {
      case 'save_budget': {
        this.saveCartBudget();
        break;
      }
      case 'cancel_order': {
        this.onPressCancelOrder();
        break;
      }
      case 'condition_sales': {
        this.openConditionSalesSheet();
        break;
      }
      case 'share_order': {
        this.goToShareOrder();
        break;
      }
      default:
        break;
    }
  };

  setIconLeftHeader() {
    this.props.navigation.setOptions({
      headerLeft: () => (
        <TouchableOpacity onPress={() => {
          this.handleBackPress();
        }}
        >
          <Image
            source={Images.iconBack}
            style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
          />
        </TouchableOpacity>
      ),
    });
  }

  render() {
    const { order, config, configLib, currentProduct } = this.props;
    const {
      loading,
      loadingSalesHistory,
      loadingSaleSuggestionProducts,
      loadingFetchMore,
      actualValue,
      showModalSort,
      categoryStack,
      selectedProducts,
      showBackPressAlert,
      salesConditionsChild,
      scrollToCategory,
      actualCategory,
      goToTopScreen,
      suggestionProducts,
      historyProducts,
      isCombo,
      activeProductsGrouping,
    } = this.state;

    const urlStocker = _.get(config, 'payload.url_stocker', '');
    const tokenStocker = _.get(config, 'payload.token_stocker', '');
    const showProductAsHTML = _.get(
      configLib.payload,
      'listagem_campos_visibilidade.product.show_html',
      false,
    );

    const isRootScreen =
      (this.props.productsAndCategories?.codGroupList || []).length === 0;

    const enableOperationGroup = _.get(
      configLib,
      'payload.configuracoes.product_group.enable_select',
      false,
    );

    return (
      <Cart
        cart={order || {}}
        config={this.configCached}
        configLib={configLib.payload}
        loading={loading}
        loadingAllProducts={this.props.productsAndCategories?.loading}
        loadingCategoriesProducts={this.props.productsHome?.loading}
        loadingFetchMore={loadingFetchMore}
        actualSortValue={actualValue}
        showModalSort={showModalSort}
        initialNumToRender={PAGE_FACTOR}
        stocker={{
          url: urlStocker,
          token: tokenStocker,
        }}
        scrollToIndex={this.scrollToIndex}
        onPressObservations={this.onClickOnPressObservations}
        onPressConditionsSalesChild={this.onPressClickConditionsSalesChild}
        addItem={this.addItem}
        addHistoryProduct={this.onPressAddHistoryProduct}
        clickProductInHistoryProduct={this.onPressClickProductInHistoryProduct}
        addSuggestionProduct={this.onPressAddSuggestionProduct}
        clickProductInSuggestionProduct={
          this.onPressclickProductInSuggestionProduct
        }
        itemUpdate={currentProduct.payload}
        categoryClick={this.onPressCategoryClick}
        productClick={this.onPressProductCLick}
        data={this.state.onlyProducts.slice(0, this.productsPage)}
        fetchMoreProducts={type => this.fetchMoreProducts(type)}
        sort={this.sort}
        onClose={this.onCloseModal}
        onSelectProduct={this.onSelectProduct}
        onSelectProducts={this.onSelectProducts}
        selectedProducts={selectedProducts}
        showBackPressAlert={showBackPressAlert}
        onConfirmBackPress={this.onPressOnConfirmBackPress}
        onApplyRemoveItems={this.onApplyRemoveItems}
        onPressTableOrDeadline={this.onPressTableOrDeadline}
        salesConditionsChild={salesConditionsChild}
        scrollToCategory={scrollToCategory}
        actualCategory={actualCategory}
        goToTopScreen={goToTopScreen}
        categories={this.state.onlyCategories}
        showProductAsHTML={showProductAsHTML}
        showCarouselType={this.carouselType}
        // SaleSuggestionProducts
        visibleSaleSuggestionProducts={categoryStack.length === 0}
        loadingSaleSuggestionProducts={loadingSaleSuggestionProducts}
        suggestionProducts={suggestionProducts.slice(
          0,
          this.productsSuggestionPage,
        )}
        // SaleHistoryProducts
        visibleSaleHistorySuggetion={categoryStack.length === 0}
        loadingSalesHistory={loadingSalesHistory}
        historyProducts={historyProducts.slice(0, this.productsSaleHistoryPage)}
        isCombo={isCombo}
        onSearch={this.onSearch}
        recalculatePrice={this.recalculatePrice}
        onPressShowAll={this.onPressShowAll}
        isRootScreen={isRootScreen}
        onPressFloatingAction={this.onPressFloatingAction}
        componentIdCart={this.props.componentId}
        // OperationGroup
        enableOperationGroup={enableOperationGroup}
        onApplyOperationByGroup={this.onApplyOperationByGroup}
        handleProductGrouping={this.handleProductGrouping}
        activeProductsGrouping={activeProductsGrouping}
        showAllActive={this.showAllActive}
        flatListRef={this.flatListRef}
        navigation={this.props.navigation}
        toggleOperationGroupModal={this.toggleOperationGroupModal}
      />
    );
  }
}

CartContainer.propTypes = {
  componentId: PropTypes.string.isRequired,
  order: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  configLib: PropTypes.object.isRequired,
  productsAndCategories: PropTypes.object.isRequired,
  productsHome: PropTypes.object.isRequired,
  currentProduct: PropTypes.object.isRequired,
  taskCurrent: PropTypes.object.isRequired,
  promotions: PropTypes.object.isRequired,
  historyProducts: PropTypes.object.isRequired,
  suggestionProducts: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  makeOrderModule: PropTypes.object.isRequired,
  currentScreenVisible: PropTypes.object.isRequired,
  salesOpportunity: PropTypes.object.isRequired,
  productClick: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    productsAndCategories: state.productsFetch,
    productsHome: state.productsHomeFetch,
    currentProduct: state.productCurrent,
    taskCurrent: state.taskCurrent,
    promotions: state.promotionsFetch,
    historyProducts: state.saleHistoryProducts,
    suggestionProducts: state.saleSuggestionProducts,
    client: state.clientCurrent,
    config: state.configFetch,
    configLib: state.configLibFetch,
    makeOrderModule: state.makeOrderModule,
    currentScreenVisible: state.currentScreenVisible,
    salesOpportunity: state.salesOpportunityFetch,
  };
}

export default compose(
  actualCart(SCREEN_CART.name, mapStateToProps),
  setFilters(
    getProductFilters,
    'productsFilters',
    PRODUCT_FILTERS_SUCCESS,
    mapStateToProps,
  ),
)(CartContainer);
