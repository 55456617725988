import React, { Component } from 'react';
import { View, Text, StyleSheet, Platform } from 'react-native';
import * as config from '~/config/config';
import { fonts } from "../../../utils/Fonts";

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  sectionContainer: {
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: config.Colors.grayContainerDark,
    padding: 16,
  },
  sectionTitle: {
    marginBottom: 8,
    color: Platform.OS === 'ios' ? config.Colors.grayDark : config.Colors.primaryColor,
    fontSize: 14,
    fontFamily: fonts.QuicksandBold,
  },
});

class Section extends Component {
  render() {
    return (
      <View style={[styles.sectionContainer, this.props.style]}>
        {this.props.title && <Text style={styles.sectionTitle}>{this.props.title}</Text>}
        {this.props.children}
      </View>
    );
  }
}

Section.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.any,
};

export default Section;
