import { createAsyncThunk } from '@reduxjs/toolkit';
import { removeMultipleItems as removeMultipleItemsPromise } from './utilsLib';

export const REMOVE_MULTIPLE_ITEMS_FETCH = 'REMOVE_MULTIPLE_ITEMS_FETCH';

export const removeMultipleItems = createAsyncThunk(
  'ordersFetch/removeMultipleItems',
  async payload => {
    try {
      const response = await removeMultipleItemsPromise(payload);
      return JSON.parse(response);
    } catch (error) {
      console.log('error', error);
      return error;
    }
  },
);
