import AsyncStorage from '@react-native-async-storage/async-storage';

class SaleOpportunitySelectedCache {
  async getSaleOptnSelected() {
    const data = await AsyncStorage.getItem('@Session:CacheSaleOptnSelected');
    console.log('data', data);
    const saleOptnSelected = data ? JSON.parse(data) : false;
    console.log('saleOptnSelected', saleOptnSelected);
    return saleOptnSelected;
  }

  async setSaleOptnSelected(addressAlt) {
    await AsyncStorage.setItem(
      '@Session:CacheSaleOptnSelected',
      JSON.stringify(addressAlt),
    );
  }

  async removeSaleOptnSelected() {
    await AsyncStorage.removeItem('@Session:CacheSaleOptnSelected');
  }
}

export default new SaleOpportunitySelectedCache();
