import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import { color } from '~/common';
import Color from 'color';

import PropTypes from 'prop-types';

const ALPHA = 0.2;

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 6,
        paddingVertical: 2,
        borderRadius: 4,
        backgroundColor: Color(color.primaryColor).alpha(ALPHA),
        flexDirection: 'row',
        alignItems: 'center',
    },
    image: {
        tintColor: color.primaryColor,
        marginRight: 8,
        width: 12,
        height: 12,
    },
    text: {
        fontSize: 10,
        color: color.primaryColor,
    },
});

const StatusBadge = ({ baseColor, text, icon }) => {
    if (!text) return null;
    return (
        <View style={{ flexDirection: 'row' }}>
            <View style={[styles.container, { backgroundColor: Color(baseColor).alpha(ALPHA) }]}>
                <Image
                    style={[styles.image, { tintColor: baseColor }]}
                    source={icon}
                />
                <Text style={[styles.text, { color: baseColor }]}>{text.toUpperCase()}</Text>
            </View>
        </View>
    );
};

StatusBadge.propTypes = {
    baseColor: PropTypes.string,
    text: PropTypes.string.isRequired,
    icon: PropTypes.number.isRequired,
};

StatusBadge.defaultProps = {
    baseColor: color.primaryColor,
};

export default StatusBadge;
