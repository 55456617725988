import React from 'react';
import { View } from 'react-native';
import {
  Placeholder,
  PlaceholderMedia,
  PlaceholderLine,
  Fade,
} from 'rn-placeholder';
import colors from '../../../common/color';

const backgroundGray = {
  backgroundColor: colors.gray,
};

const ProductCategoriesStyle = {
  backgroundColor: colors.gray,
  height: 80,
  width: 80,
  borderRadius: 40,
  marginRight: 5,
};

const ProductVertical = {
  ...backgroundGray,
  borderRadius: 10,
  height: 236,
  width: 130,
  margin: 4,
  padding: 4,
};

const productIconInfo = {
  backgroundColor: colors.primaryColorTable,
  width: 14,
  height: 14,
  borderRadius: 12,
  alignSelf: 'flex-end',
};

const productImage = {
  marginVertical: 10,
  alignSelf: 'center',
  width: 80,
  height: 90,
  backgroundColor: colors.primaryColorTable,
};

const productName = {
  marginVertical: 5,
  backgroundColor: colors.primaryColorTable,
  width: '80%',
  height: 20,
  alignSelf: 'center',
};

const app = {
  ...backgroundGray,
  height: 160,
  paddingTop: 8,
  paddingHorizontal: 8,
  borderBottomColor: colors.primaryColorTable,
  borderBottomWidth: 2,
  flexDirection: 'row',
};

const imageProduct = {
  top: 20,
  width: 80,
  height: 90,
  backgroundColor: colors.primaryColorTable,
};

const divide = {
  marginLeft: 7,
  flex: 1,
};

const lineName = {
  backgroundColor: colors.primaryColorTable,
  marginLeft: 7,
  width: '90%',
  height: 50,
};

const linePrice = {
  backgroundColor: colors.primaryColorTable,
  marginLeft: 7,
  width: 80,
  height: 30,
};

const iconPoint = {
  backgroundColor: colors.primaryColorTable,
  width: 5,
  height: 20,
};

const badgeContainer = {
  top: 28,
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingHorizontal: 20,
};

const iconStatus = {
  backgroundColor: colors.primaryColorTable,
  width: 12,
  height: 12,
  borderRadius: 6,
};

const iconCheck = {
  width: 28,
  height: 28,
  borderRadius: 14,
  backgroundColor: colors.primaryColorTable,
};

const lineSubPrice = {
  alignSelf: 'center',
  backgroundColor: colors.primaryColorTable,
  marginLeft: 7,
  width: 80,
  height: 20,
};

const quantity = {
  alignSelf: 'center',
  width: 14,
  height: 14,
  borderRadius: 12,
  backgroundColor: colors.primaryColorTable,
  marginHorizontal: 10,
};

export function CategoriesPlaceholder() {
  return (
    <Placeholder Animation={Fade}>
      <View style={{ marginTop: 20, paddingLeft: 10, flexDirection: 'row' }}>
        <PlaceholderMedia style={ProductCategoriesStyle} />
        <PlaceholderMedia style={ProductCategoriesStyle} />
        <PlaceholderMedia style={ProductCategoriesStyle} />
        <PlaceholderMedia style={ProductCategoriesStyle} />
        <PlaceholderMedia style={ProductCategoriesStyle} />
        <PlaceholderMedia style={ProductCategoriesStyle} />
      </View>
    </Placeholder>
  );
}

export function CarrousselProdsPlaceholder() {
  return (
    <Placeholder Animation={Fade}>
      <View style={{ flexDirection: 'row', marginTop: 15 }}>
        <View style={ProductVertical}>
          <PlaceholderMedia style={productIconInfo} />
          <PlaceholderMedia style={productImage} />
          <PlaceholderLine style={productName} />
          <PlaceholderLine style={productName} />
          <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
            <PlaceholderMedia style={iconCheck} />
            <PlaceholderMedia style={quantity} />
            <PlaceholderMedia style={iconCheck} />
          </View>
        </View>

        <View style={ProductVertical}>
          <PlaceholderMedia style={productIconInfo} />
          <PlaceholderMedia style={productImage} />
          <PlaceholderLine style={productName} />
          <PlaceholderLine style={productName} />
          <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
            <PlaceholderMedia style={iconCheck} />
            <PlaceholderMedia style={quantity} />
            <PlaceholderMedia style={iconCheck} />
          </View>
        </View>

        <View style={ProductVertical}>
          <PlaceholderMedia style={productIconInfo} />
          <PlaceholderMedia style={productImage} />
          <PlaceholderLine style={productName} />
          <PlaceholderLine style={productName} />
          <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
            <PlaceholderMedia style={iconCheck} />
            <PlaceholderMedia style={quantity} />
            <PlaceholderMedia style={iconCheck} />
          </View>
        </View>

        <View style={ProductVertical}>
          <PlaceholderMedia style={productIconInfo} />
          <PlaceholderMedia style={productImage} />
          <PlaceholderLine style={productName} />
          <PlaceholderLine style={productName} />
          <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
            <PlaceholderMedia style={iconCheck} />
            <PlaceholderMedia style={quantity} />
            <PlaceholderMedia style={iconCheck} />
          </View>
        </View>

        <View style={ProductVertical}>
          <PlaceholderMedia style={productIconInfo} />
          <PlaceholderMedia style={productImage} />
          <PlaceholderLine style={productName} />
          <PlaceholderLine style={productName} />
          <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
            <PlaceholderMedia style={iconCheck} />
            <PlaceholderMedia style={quantity} />
            <PlaceholderMedia style={iconCheck} />
          </View>
        </View>

      </View>
    </Placeholder>
  );
}

export function OnlyProductsPlaceholder() {
  return (
    <Placeholder>
      <View style={{}}>
        <View style={app}>
          <View>
            <View style={imageProduct} />
            <View style={badgeContainer}>
              <View style={iconStatus} />
              <View style={iconStatus} />
            </View>
          </View>
          <View style={divide}>
            <PlaceholderLine style={lineName} />
            <View>
              <PlaceholderLine style={linePrice} />
            </View>
            <View style={{ flexDirection: 'row', paddingTop: 10 }}>
              <View style={iconCheck} />
              <View style={quantity} />
              <View style={iconCheck} />
              <PlaceholderLine style={lineSubPrice} />
            </View>
          </View>
          <View style={iconPoint} />
        </View>

        <View style={app}>
          <View>
            <View style={imageProduct} />
            <View style={badgeContainer}>
              <View style={iconStatus} />
              <View style={iconStatus} />
            </View>
          </View>
          <View style={divide}>
            <PlaceholderLine style={lineName} />
            <View>
              <PlaceholderLine style={linePrice} />
            </View>
            <View style={{ flexDirection: 'row', paddingTop: 10 }}>
              <View style={iconCheck} />
              <View style={quantity} />
              <View style={iconCheck} />
              <PlaceholderLine style={lineSubPrice} />
            </View>
          </View>
          <View style={iconPoint} />
        </View>
      </View>

      <View>
        <View style={app}>
          <View>
            <View style={imageProduct} />
            <View style={badgeContainer}>
              <View style={iconStatus} />
              <View style={iconStatus} />
            </View>
          </View>
          <View style={divide}>
            <PlaceholderLine style={lineName} />
            <View>
              <PlaceholderLine style={linePrice} />
            </View>
            <View style={{ flexDirection: 'row', paddingTop: 10 }}>
              <View style={iconCheck} />
              <View style={quantity} />
              <View style={iconCheck} />
              <PlaceholderLine style={lineSubPrice} />
            </View>
          </View>
          <View style={iconPoint} />
        </View>

        <View style={app}>
          <View>
            <View style={imageProduct} />
            <View style={badgeContainer}>
              <View style={iconStatus} />
              <View style={iconStatus} />
            </View>
          </View>
          <View style={divide}>
            <PlaceholderLine style={lineName} />
            <View>
              <PlaceholderLine style={linePrice} />
            </View>
            <View style={{ flexDirection: 'row', paddingTop: 10 }}>
              <View style={iconCheck} />
              <View style={quantity} />
              <View style={iconCheck} />
              <PlaceholderLine style={lineSubPrice} />
            </View>
          </View>
          <View style={iconPoint} />
        </View>
      </View>

      <View>
        <View style={app}>
          <View>
            <View style={imageProduct} />
            <View style={badgeContainer}>
              <View style={iconStatus} />
              <View style={iconStatus} />
            </View>
          </View>
          <View style={divide}>
            <PlaceholderLine style={lineName} />
            <View>
              <PlaceholderLine style={linePrice} />
            </View>
            <View style={{ flexDirection: 'row', paddingTop: 10 }}>
              <View style={iconCheck} />
              <View style={quantity} />
              <View style={iconCheck} />
              <PlaceholderLine style={lineSubPrice} />
            </View>
          </View>
          <View style={iconPoint} />
        </View>
      </View>
    </Placeholder>
  );
}


export default function AllProdsPlaceholder() {
  return (
    <Placeholder Animation={Fade}>
      <View style={{ marginTop: 15 }}>

        <View style={app}>
          <View>
            <PlaceholderMedia style={imageProduct} />
            <View style={badgeContainer}>
              <PlaceholderMedia style={iconStatus} />
              <PlaceholderMedia style={iconStatus} />
            </View>
          </View>
          <View style={divide}>
            <PlaceholderLine style={lineName} />
            <View>
              <PlaceholderLine style={linePrice} />
            </View>
            <View style={{ flexDirection: 'row', paddingTop: 10 }}>
              <PlaceholderMedia style={iconCheck} />
              <PlaceholderMedia style={quantity} />
              <PlaceholderMedia style={iconCheck} />
              <PlaceholderLine style={lineSubPrice} />
            </View>
          </View>
          <PlaceholderLine style={iconPoint} />
        </View>

        <View style={app}>
          <View>
            <PlaceholderMedia style={imageProduct} />
            <View style={badgeContainer}>
              <PlaceholderMedia style={iconStatus} />
              <PlaceholderMedia style={iconStatus} />
            </View>
          </View>
          <View style={divide}>
            <PlaceholderLine style={lineName} />
            <View>
              <PlaceholderLine style={linePrice} />
            </View>
            <View style={{ flexDirection: 'row', paddingTop: 10 }}>
              <PlaceholderMedia style={iconCheck} />
              <PlaceholderMedia style={quantity} />
              <PlaceholderMedia style={iconCheck} />
              <PlaceholderLine style={lineSubPrice} />
            </View>
          </View>
          <PlaceholderLine style={iconPoint} />
        </View>

        <View style={app}>
          <View>
            <PlaceholderMedia style={imageProduct} />
            <View style={badgeContainer}>
              <PlaceholderMedia style={iconStatus} />
              <PlaceholderMedia style={iconStatus} />
            </View>
          </View>
          <View style={divide}>
            <PlaceholderLine style={lineName} />
            <View>
              <PlaceholderLine style={linePrice} />
            </View>
            <View style={{ flexDirection: 'row', paddingTop: 10 }}>
              <PlaceholderMedia style={iconCheck} />
              <PlaceholderMedia style={quantity} />
              <PlaceholderMedia style={iconCheck} />
              <PlaceholderLine style={lineSubPrice} />
            </View>
          </View>
          <PlaceholderLine style={iconPoint} />
        </View>

      </View>
    </Placeholder>
  );
}
