import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text, View, StyleSheet } from 'react-native';
import Button from './Button';
import { color } from '~/common';
import { fonts } from '../utils/Fonts';

const styles = StyleSheet.create({
  emptyContainer: {
    marginVertical: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyImage: {
    tintColor: color.primaryColorGray,
    width: 80,
    height: 80,
    marginBottom: 20,
  },
  emptyText: {
    color: color.primaryColorGray,
    fontSize: 18,
    textAlign: 'center',
    marginHorizontal: 16,
    fontFamily: fonts.QuicksandBold,
    marginBottom: 15,
  },
  emptySubText: {
    color: color.grayDark,
    fontSize: 15,
    textAlign: 'center',
    marginHorizontal: 16,
    marginBottom: 15,
  },
});

function EmptyView({
  message, icon, actionTitle, action, actionColor, submessage,
}) {
  return (
    <View style={styles.emptyContainer}>
      <Image
        resizeMode="contain"
        style={styles.emptyImage}
        source={icon}
      />
      <Text style={styles.emptyText}>{message}</Text>
      {!!submessage &&
        <Text style={styles.emptySubText}>{submessage}</Text>
      }

      {actionTitle ?
        <Button
          title={actionTitle}
          onPress={() => { action(); }}
          color={actionColor || color.accentColor}
        />
        :
        null
      }
    </View>
  );
}

EmptyView.propTypes = {
  message: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  submessage: PropTypes.string,
  actionTitle: PropTypes.string,
  actionColor: PropTypes.string,
  action: PropTypes.func,
};

export default EmptyView;
