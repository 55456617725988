import { orderActions } from '~/screens/Filter/Filter';

export const findSortValuesByFieldAndOrder = (field, order, options) => {
  const option = options?.find(op => op.field === field);
  const orderAction = orderActions.find(oa => oa.value === order);

  if (option && order) {
    return {
      sortIndex: options.indexOf(option),
      sort: option,
      order: orderAction,
    };
  }
};
