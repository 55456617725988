import React, { useState } from 'react';

import SalesConditionCatalogSheet from './SalesConditionCatalogSheet';
import { useCallback } from 'react';
import { Navigation } from 'react-native-navigation';
import { useEffect } from 'react';
import { useRef } from 'react';

function SalesConditionCatalogSheetContainer({ route, navigation }) {
  const {
    componentId,
    onClose,
    initialState,
    handleChangeTable,
    handleChangeScale,
    onChangeParams,
  } = route.params;

  const [deadlines, setDeadlines] = useState(initialState?.deadlines);

  const [scales, setScales] = useState(initialState?.scales);

  const [selectedTable, setSelectedTable] = useState(
    initialState?.selectedTable,
  );
  const [selectedScale, setSelectedScale] = useState(
    initialState?.selectedScale,
  );

  const [selectedDeadline, setSelectedDeadline] = useState(
    initialState?.selectedDeadline,
  );

  const backHandler = useRef(null);

  const globalState = {
    selectedDeadline,
    selectedScale,
    selectedTable,
    scales,
    deadlines,
  };

  const onPressApply = () => {
    handleGeneralClose();
    console.log('globalState', globalState);
    onChangeParams(globalState);
  };

  const handleGeneralClose = useCallback(
    async () => {
      navigation.goBack();
      onClose?.();
    },
    [componentId, onClose],
  );
  const onPressClose = useCallback(
    () => {
      handleGeneralClose();
    },
    [handleGeneralClose],
  );

  const onChangeTable = async value => {
    const newState = await handleChangeTable(value, globalState);
    if (newState) {
      setDeadlines(newState.deadlines);
      setScales(newState.scales);
      setSelectedDeadline(newState.selectedDeadline);
      setSelectedScale(newState.selectedScale);
      setSelectedTable(value);
    }
  };
  const onChangeScale = async value => {
    const newState = await handleChangeScale(value, globalState);
    if (newState) {
      setDeadlines(newState.deadlines);
      setSelectedScale(value);
      setSelectedDeadline(newState.selectedDeadline);
    }
  };

  const onChangeDeadline = async value => {
    if (value === selectedDeadline) {
      return;
    }

    setSelectedDeadline(value);
  };

  useEffect(
    () => {
      const listener = {
        componentDidAppear: async () => {
        },
        componentDidDisappear: () => {
        },
      };

      return () => {
        // Make sure to unregister the listener during cleanup
      };
    },
    [componentId, handleGeneralClose, onPressClose],
  );

  return (
    <SalesConditionCatalogSheet
      onPressApply={onPressApply}
      onPressClose={onPressClose}
      tables={initialState?.tables}
      deadlines={deadlines}
      scales={scales}
      selectedDeadline={selectedDeadline}
      selectedScale={selectedScale}
      selectedTable={selectedTable}
      onChangeScale={onChangeScale}
      onChangeDeadline={onChangeDeadline}
      onChangeTable={onChangeTable}
    />
  );
}

export default SalesConditionCatalogSheetContainer;
