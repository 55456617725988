/* @flow */

import React from 'react';

import Grid from '~/scenes/Grid/Grid';
import GPSManager from '../../../controllers/GPS/GPSManager';
import GpsHelper from '../../../utils/GpsHelper';
import Gps from '../../../../components_base/src/components/Gps/Gps';
import _ from 'lodash';
import TextUtils from '../../../utils/TextUtils';
import Images from '../../../../assets/Images';
import ActivitiesModule from '../../../modules/ActivitiesModule';
import getQueryResult from '../../../services/resources/libFastSeller/genericQuery';
import executeActionOrOpenScreenMessage from '../../../utils/messages';
import onPressHelper from '~/utils/onPressHelper';
import { connect } from 'react-redux';
import Toast from 'react-native-root-toast';
import translate from '../../../locales';
import AddressAltCache from '~/cache/AddressAltCache';
import listGeneric from '~/services/resources/libFastSeller/listGeneric';
import { emp2long } from '../../../utils/FastSellerUtils';
import Action from '../handleAction';
import CacheDataController from '~/utils/CacheDataController';
import { Alert, BackHandler, Image, TouchableOpacity, View } from 'react-native';
import { Navigation } from 'react-native-navigation';
import NavigationHelper from '~/screens/NavigationHelper';
import { createNotifyError, createNotifyInfo } from '~/components/Web/ToastNotify';

const TAG = 'bin_schedule';

type Props = {
  item: {
    id: string,
    setup: string,
  },
  navigator: {
    pop: () => void,
    setButtons: () => void,
  },
  saveAnswerActivity: (
    payloadAnswer: Object<any>,
    successSaveAnswer: Function<any>,
    failureSaveAnswer: Function<any>,
  ) => void,
  dispatch: () => void,
  configLib: any,
  dateSelected: any,
};

class GridContainer extends React.Component<Props, void> {
  constructor(props) {
    super(props);

    this.state = {
      showSearch: false,
      searchText: '',
      items: [],
      message: '',
      dateOpen: '',
      extra_fields: [],
      savingAnswer: false,
      location: {
        accuracy: Infinity,
        altitude: null,
        heading: null,
        latitude: null,
        longitude: null,
        speed: 0.0,
        timestamp: 0,
      },
    };
    this.addressAltSelected = false;
    this.gpsManager = new GPSManager();
    this.pendingSave = false;
  }

  UNSAFE_componentWillMount() {
    const content = _.get(this.props.route.params.pushObject, 'item.answer.payload.content') || '';
    const startDate =
      _.get(this.props.route.params.pushObject, 'item.answer.start_date') || new Date().toISOString();
    const extra_fields = _.get(
        this.props.route.params.pushObject,
      'item.answer.payload.extra_fields' || '',
    );
    const setup = JSON.parse(_.get(this.props.route.params.pushObject, 'item.setup', '{}'));
    if (setup.lib_action) {
      Action.callLibMethod(
        () => this.loadItems(),
        () => {},
        setup.lib_action,
        'start_inventory',
      );
    } else {
      this.loadItems();
    }

    this.setState({
      message: content,
      dateOpen: startDate,
      extra_fields,
    });
  }

  componentDidAppear() {
    this.backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      this.validatePendingSave,
    );

    this.props.navigation.setOptions({
        headerLeft: () => (
            <TouchableOpacity onPress={this.validatePendingSave}>
                <Image
                    source={Images.iconBack}
                    style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
                />
            </TouchableOpacity>
        ),
        headerRight: () => (
            <View style={{ flexDirection: 'row' }}>
                <View>
                    <TouchableOpacity onPress={() => {
                        this.setState({ showSearch: !this.state.showSearch, searchText: '' });
                    }}>
                        <Image
                            source={Images.iconSearch}
                            style={{
                                tintColor: '#f0f0f0',
                                height: 20,
                                width: 20,
                                right: 20,
                            }}
                        />
                    </TouchableOpacity>
                </View>
            </View>
        ),
    });

  }

  async componentDidMount() {
    if (!_.isObject(this.addressAltSelected)) {
      const event = _.get(this.props, 'event', {});
      const { pkey: pkeyClient } = event;
      const addressAlt = await AddressAltCache.getAddressAlt(pkeyClient);
      this.addressAltSelected = addressAlt;
    }
  }

  getItems = async setup => {
    const { param_list_id: paramListId } = setup;
    const { source } = setup || {};
    const isSourceActive = !_.isEmpty(source);
    const pkeyClient = _.get(this.props, 'event.pkey');

    let items = [];
    if (paramListId) {
      items = await this.getItemsParamList(paramListId);
    } else if (setup.items) {
      items = setup.items;
    } else if (isSourceActive) {
      const { type, data, params = {} } = source || {};
      if (type === 'sql') {
        try {
          const result = await getQueryResult(data, JSON.stringify(params));
          items = JSON.parse(result);
        } catch (err) {
          const errors = JSON.parse(err);
          executeActionOrOpenScreenMessage(errors);
        }
      }
      if (type === 'self') {
        items = data;
      }
    }
    if (setup.type === 'grid_form') {
      const newItems = [];
      for (const item of items) {
        const { fields } = setup;
        let newFields = [...fields];
        const itemCache = await CacheDataController.getData(
          `@RESPONSE_ACTIVITES_${item.id}_${pkeyClient}`,
        );

        if (itemCache && itemCache.fields) {
          newFields = [...itemCache.fields];
        }
        newItems.push({
          ...item,
          fields: setup.type_edit_form === 'self' ? undefined : newFields || [],
        });
      }
      items = [...newItems];
    }

    return items.map(item => ({
      ...item,
      group: (setup.group || 0).toString(),
    }));
  };

  loadItems = async () => {
    const itemsAnswer = _.get(this.props.route.params.pushObject, 'item.answer.payload.items', []);
    const setup = JSON.parse(_.get(this.props.route.params.pushObject, 'item.setup', '{}'));
    let items = [];

    if (setup.type === 'grid_group') {
      const { groups } = setup;

      for (const group of groups) {
        const itemsGroup = await this.getItems(group);
        items = items.concat(itemsGroup);
      }
    } else {
      items = await this.getItems(setup);
    }

    if (itemsAnswer.length) {
      items = items.map(item => {
        const itemAnswer = _.find(
          itemsAnswer,
          i =>
            i.id.toString() === item.id.toString() &&
            i.group.toString() === item.group.toString(),
        );

        return {
          ...item,
          ...itemAnswer,
        };
      });
    }

    const newItems = itemsAnswer.filter(
      iAnswer => !items.some(item => item.id === iAnswer.id),
    );
    this.setState({ items: [...newItems, ...items] });
  };

  getItemsParamList = async (paramListId = ';', params = {}) => {
    const tamMemory = _.get(this.props.configLib, 'tam_memoria_paginacao');
    const pkeyClient = _.get(this.props.route.params.pushObject.event, 'pkey');
    const [id, groupId] = paramListId.split(';');
    const { data = [] } =
      (await listGeneric(
        emp2long(groupId),
        emp2long(id),
        {
          ...params,
          u_codigo_cliente: pkeyClient,
        },
        tamMemory,
      )) || {};
    return data.map(i => ({
      id: i.pkey,
      name: i.descricao || '',
      ean: i.ean || '',
    }));
  };

  componentDidDisappear() {
    this.backHandler?.remove();
  }

  navigationButtonPressed(event) {
    switch (event.buttonId) {
      case 'search':
        this.setState({ showSearch: !this.state.showSearch, searchText: '' });
        break;
      case 'backPress':
        this.validatePendingSave();
        break;
      default:
        break;
    }
  }

  onFinishEditingEvent = async item => {
    const itemsChanged = [...this.state.items];
    const objIndex = itemsChanged.findIndex(
      obj => obj.id.toString() === item.id.toString(),
    );
    const setup = JSON.parse(this.props.route.params.pushObject.item.setup || '{}');
    const pkeyClient = _.get(this.props.route.params.pushObject, 'event.pkey');

    itemsChanged[objIndex] = {
      ...itemsChanged[objIndex],
      ...item,
    };

    if (setup.lib_action === 'inventory') {
      const resp = await this.saveInventoryCollect(item);
      executeActionOrOpenScreenMessage(
        resp,
        () => {},
        () => {
          this.setState({ items: itemsChanged }, async () => {
            this.pendingSave = true;
            CacheDataController.saveData(
              `@RESPONSE_ACTIVITES_${item.id}_${pkeyClient}`,
              item,
            );
          });
        },
      );
    } else {
      this.setState({ items: itemsChanged }, async () => {
        this.pendingSave = true;
      });
    }
  };

  onConfirmDateEvent = (date, key) => {
    this.setState({ extra_fields: [{ key, value: date }] });
  };

  finishInventory = async () => {
    const params = {};
    const response = await ActivitiesModule.finishInventory(
      JSON.stringify(params),
    );
    return JSON.parse(response || '{}');
  };

  onDiscardChanges = async () => {
    const setup = JSON.parse(this.props.route.params.pushObject.item.setup || '{}');
    const pkeyClient = _.get(this.props.route.params.pushObject, 'event.pkey');

    this.state.items.forEach(item => {
      CacheDataController.removeItem(
        `@RESPONSE_ACTIVITES_${item.id}_${pkeyClient}`,
      );
    });

    if (setup.lib_action === 'inventory') {
      const params = {
        id: 0,
      };
      const resp = await ActivitiesModule.removeInventoryCollect(
        JSON.stringify(params),
      );
      executeActionOrOpenScreenMessage(JSON.parse(resp), () => {}, () => {});
    }

    this.props.navigation.pop();
  };

  validatePendingSave = () => {
    if (this.pendingSave) {
      Alert.alert(
        'Salvamento pendente!',
        'Se você sair perderá as suas alterações. \nDesejar salvar?',
        [
          {
            text: 'Sair',
            onPress: () => this.onDiscardChanges(),
          },
          {
            text: 'Salvar atividade',
            onPress: () => this.onSavePressed(),
          },
        ],
      );
    } else {
      this.props.navigator.pop();
    }
    return true;
  };

  saveInventoryCollect = async item => {
    const newProps = {};

    const currencySymbol = translate('currencySymbol');
    if (item.fields) {
      item.fields.forEach(({ value, map }) => {
        if (value) {
          newProps[map] =
            Number(
              value
                .replace(`${currencySymbol} `, '')
                .replace('.', '')
                .replace(',', '.'),
            ) || '';
        }
      });
    }
    const { fields: rFields, ...newItem } = item;

    const params = {
      item: {
        ...newItem,
        ...newProps,
      },
    };

    const resp = await ActivitiesModule.saveInventoryCollect(
      JSON.stringify(params),
    );
    return JSON.parse(resp || '{}');
  };

  onSavePressed = async source => {
    const { items, dateOpen, message, extra_fields, location } = this.state;
    const { item, dateSelected, event } = this.props.route.params.pushObject;

    const pkeyClient = _.get(this.props, 'event.pkey');
    const setup = JSON.parse(item.setup);
    const binSchedule = 'bin_schedule';

    const itemsAnswer = items.filter(iAnswer => {
      CacheDataController.removeItem(
        `@RESPONSE_ACTIVITES_${iAnswer.id}_${pkeyClient}`,
      );
      return iAnswer.fields
        ? iAnswer.fields.some(field => !!field.value)
        : !!iAnswer.value;
    });

    const errorMessage = GpsHelper.validateRulesGPSClient(
      setup,
      event,
      location,
    );
    if (!_.isEmpty(errorMessage)) {
      createNotifyError(errorMessage);
      this.setState({ loading: false });
      return;
    }

    if (itemsAnswer.length) {
      const answer = {
        dateSelected,
        dateOpen,
        latitude: location.latitude,
        longitude: location.longitude,
        payload: {
          items: itemsAnswer,
          content: message,
          location,
          extra_fields,
        },
        taskId: item.id,
      };
      if (setup.kind === binSchedule) {
        if (extra_fields && extra_fields[0].value) {
          this.saveAgendamentoBIN(answer.payload);
        } else {
          createNotifyInfo(`${translate('fillSchedulingDate')}`);
        }
      } else if (setup.lib_action === 'inventory') {
        let messages;
        if (source === 'extra_finish_button') {
          messages = await this.finishInventory(itemsAnswer);
        }
        executeActionOrOpenScreenMessage(
          messages,
          () => {},
          () => {
            this.props.route.params.pushObject.saveAnswerActivity(
              answer,
              this.successSaveAnswer,
              this.failureSaveAnswer,
            );
            this.setState({ savingAnswer: true });
            this.pendingSave = false;
          },
        );
        return;
      }
      this.props.route.params.pushObject.saveAnswerActivity(
        answer,
        this.successSaveAnswer,
        this.failureSaveAnswer,
      );
      this.setState({ savingAnswer: true });
      this.pendingSave = false;
    } else {
      createNotifyInfo(`${translate('putSomeDataToSave')}`);
    }
  };

  onChangeMessage = message => {
    this.setState({ message });
  };

  onChangeText = text => {
    clearTimeout(this);
    setTimeout(() => {
      this.setState({ searchText: TextUtils.slugfy(text) });
    }, 350);
  };

  getFilteredData() {
    const search = this.state.searchText.toLowerCase();

    let data = [];
    if (this.state.items && this.state.items.length > 0) {
      data = this.state.items.filter(item => {
        const values = Object.values(item) || [];
        const matched = values.filter(
          v => (v ? `${v}`.toLowerCase().includes(search) : false),
        );
        return matched.length > 0;
      });
    }
    return data;
  }

  successSaveAnswer = () => null;
  failureSaveAnswer = () => null;

  onClearFields = async item => {
    const itemsChanged = [...this.state.items];
    const objIndex = itemsChanged.findIndex(
      obj => obj.id.toString() === item.id.toString(),
    );
    const setup = JSON.parse(this.props.route.params.pushObject.item.setup || '{}');
    const pkeyClient = _.get(this.props.route.params.pushObject, 'event.pkey');

    itemsChanged[objIndex] = {
      ...itemsChanged[objIndex],
      ...item,
    };

    this.setState({ items: itemsChanged }, async () => {
      CacheDataController.removeItem(
        `@RESPONSE_ACTIVITES_${item.id}_${pkeyClient}`,
      );
      if (setup.lib_action === 'inventory') {
        const params = {
          id: item.id,
        };
        const resp = await ActivitiesModule.removeInventoryCollect(
          JSON.stringify(params),
        );
        executeActionOrOpenScreenMessage(JSON.parse(resp), () => {}, () => {});
      }
    });
  };

  openGridForm = async id => {
    const item = _.find(this.state.items, { id });
    const { name } = this.props.route.params.pushObject;
    const setup = JSON.parse(_.get(this.props.route.params.pushObject, 'item.setup', '{}'));
    const pkeyClient = _.get(this.props.route.params.pushObject, 'event.pkey');

    let { fields } = item;
    const itemCache = await CacheDataController.getData(
      `@RESPONSE_ACTIVITES_${id}_${pkeyClient}`,
    );

    if (itemCache && itemCache.fields) {
      ({ fields } = itemCache);
    }

    this.props.navigation.navigate('activities.GridForm', {
        item,
        fields: JSON.stringify(fields),
        onFinishEditingEvent: this.onFinishEditingEvent,
        onClearFields: this.onClearFields,
        setup,
    });
  };

  openGridFormOnPressHelper = onPressHelper.debounce(this.openGridForm);

  sumCollectWeight(item) {
    const itemFiltered = item.filter(item => item.value !== undefined);
    let sumCollect = 0;
    for (let i = 0; i < itemFiltered.length; i++) {
      sumCollect += parseFloat(itemFiltered[i].value);
    }
    return sumCollect;
  }

  saveAgendamentoBIN = payload => {
    const codCliente = this.props.route.params.pushObject.event.codigo;
    const { content, extra_fields, items } = payload;
    const collectWeight = this.sumCollectWeight(items);
    new Promise(async (resolve, reject) => {
      try {
        ActivitiesModule.saveBINScheduling(
          `${codCliente}`,
          extra_fields[0].value,
          collectWeight,
          content,
          resolve,
          reject,
        );
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  onLocationChange = location => {
    if (location) {
      this.setState({ location });
    }
  };

  onPressExtraFinishButton = async () => {
    this.onSavePressed('extra_finish_button');
  };

  newItemForm = form => {
    this.setState({ productNewForm: form });
  };

  onAddItem = () => {
    const { productNewForm, items } = this.state;
    const newItem = { ...productNewForm };
    const parsedSetup = JSON.parse(this.props.route.params.pushObject.item.setup);
    const newProps = {};
    Object.keys(items[0]).forEach(key => {
      newProps[key] = newItem[key];
    });
    const newItems = [
      {
        ...newProps,
        id: Date.now(),
        fields: parsedSetup.fields,
        group: 0,
        extra_item: true,
      },
      ...items,
    ];
    this.setState({ items: newItems });
  };

  onRemoveExtraItem = id => {
    const items = this.state.items.filter(item => item.id !== id);
    this.setState({ items });
  };

  renderGpsComponent() {
    const { location } = this.state;
    const { pkey: pkeyClient } = _.get(this.props.route.params.pushObject, 'event', {});
    const gpsPlaces = this.addressAltSelected || this.props.route.params.pushObject.event;

    return (
      <Gps
        places={[GpsHelper.parseGpsEvent(gpsPlaces)]}
        pkeyClient={pkeyClient}
        location={location}
        onLocationChange={this.onLocationChange}
        showPermitionLocation
        useViewExpandable
      />
    );
  }

  render() {
    const { message, showSearch, extra_fields, savingAnswer } = this.state;
    return (
      <Grid
        savingAnswer={savingAnswer}
        extra_fields={extra_fields}
        openGridForm={this.openGridFormOnPressHelper}
        onConfirmDateEvent={this.onConfirmDateEvent}
        setup={this.props.route.params.pushObject.item.setup}
        items={this.getFilteredData()}
        content={message}
        showSearch={showSearch}
        onChangeText={this.onChangeText}
        onFinishEditingEvent={this.onFinishEditingEvent}
        onSavePressed={this.onSavePressed}
        onChangeMessage={this.onChangeMessage}
        gpsComponent={this.renderGpsComponent()}
        newItemForm={this.newItemForm}
        onAddItem={this.onAddItem}
        onRemoveExtraItem={this.onRemoveExtraItem}
        onPressExtraFinishButton={this.onPressExtraFinishButton}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    configLib: state.configLibFetch.payload,
  };
}

export default connect(mapStateToProps)(GridContainer);