import axios from 'axios';

export const URL_BASE_EDI = 'https://server-edi-telegram.polibrasnet.com.br';

const loginEdi = async (empresa, user, password) => {
  const response = await axios.post(`${URL_BASE_EDI}/v1/api/auth/`, { company_id: empresa, user, password });
  return response.data.token;
};

export default loginEdi;
