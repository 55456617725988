import axios from 'axios';
import DeviceInfo from 'react-native-device-info';
import CacheAccount from '../Cache/CacheAccount';
import STAGING from '../../../services/resources/config';

const URL = `https://process-server${STAGING}.polisuite.com.br/v1/sync/user-devices/`;
const ERROR_MESSAGE = 'Erro ao registrar o dispositivo';

class LoginBase {
  constructor(username, password, dataAccount) {
    this.username = username;
    this.password = password;
    this.dataAccount = dataAccount;
    this.cacheAccount = new CacheAccount();

    this.config = {
      headers: {
        Authorization: `JWT ${dataAccount.token}`,
      },
    };
  }

  executeLogin() {
    return new Promise(async (resolve, reject) => {
      try {
        await this.registerDeviceUuid();
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  async registerDeviceUuid() {
    try {
      const data = await this.getDataDevice();
      const response = await axios.post(URL, data, this.config);
      this.cacheAccount.setUserDevice(response.data);
    } catch (e) {
      throw ERROR_MESSAGE;
    }
  }

  async getDataDevice() {
    const diskUtilizate =
      DeviceInfo.getTotalDiskCapacity() - DeviceInfo.getFreeDiskStorage();
    const platform = DeviceInfo.getSystemName();
    const provider = platform === 'Android' ? 'FCM' : 'APNS';

    const data = {
      device_id: DeviceInfo.getUniqueID(),
      device_info: {
        battery_level: await DeviceInfo.getBatteryLevel(),
        disk_utilization: diskUtilizate,
        so_version: DeviceInfo.getSystemVersion(),
      },
      platform,
      provider,
      provider_token: '11111111',
    };

    return data;
  }
}

export default LoginBase;
