import React from 'react';
import { View, Text, Image } from 'react-native';
import { RadioButton, RadioGroup } from '~/components/Web/RadioGroupData';
import { color } from '../../common';
import Masked from 'vanilla-masker';
import PropTypes from 'prop-types';
import Touchable from 'react-native-platform-touchable';
import Images from '../../../assets/Images';
import FloatingTitleTextInput from '../FloatingTitleTextInput';

const defaultOptions = [
  { label: 'Não usar', id: 1, name: 'n_usar' },
  { label: 'Substituir', id: 2, name: 'subst' },
  { label: 'Somar', id: 3, name: 'somar' },
];

function ActionItems({
  item,
  onChangeValue,
  value,
  selectedRadioOption,
  onChangeRadio,
  onPressTableOrDeadline,
  salesConditionsChild,
}) {
  const onChange = data => {
    switch (item.kind) {
      case 'money':
        {
          const newValue = Masked.toMoney(Masked.toNumber(data), {
            precision: 2,
            separator: ',',
            delimiter: '.',
          });
          onChangeValue(newValue);
        }
        break;
      default:
        onChangeValue(data);
        break;
    }

    return '';
  };

  const getField = type => {
    switch (type) {
      case 'combo':
        return (
          <Touchable
            onPress={onPressTableOrDeadline}
            >
            <View
              style={{
                marginTop: 4,
                padding: 3,
                backgroundColor: '#e7e7e7',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <Text>
                {salesConditionsChild[item.name]
                  ? salesConditionsChild[item.name].label
                  : 'Selecionar'}
              </Text>
              <Image
                style={{ width: 30, height: 30 }}
                source={Images.iconChevronRight}
              />
            </View>
          </Touchable>
        );
      case 'input':
        return (
          <FloatingTitleTextInput
            value={value}
            containerStyle={{ marginTop: 10 }}
            keyboardType="numeric"
            onChangeText={onChange}
            label={item.title}
            placeholder={item.kind === 'percentage' ? '%' : ''}
          />
        );
      default:
        return null;
    }
  };

  const options =
    item.name === 'table' || item.name === 'deadline'
      ? defaultOptions.slice(0, 2)
      : defaultOptions;

  return (
    <View>
      <Text style={{ fontWeight: '500', fontSize: 15 }}>{item.title}</Text>
      {getField(item.type)}
      <RadioGroup
        style={{ flexDirection: 'row' }}
        color={color.gray}
        activeColor={color.primaryColor}
        selectedIndex={selectedRadioOption ? selectedRadioOption.value : 0}
        onSelect={index => onChangeRadio(index, defaultOptions[index].name)}>
        {options.map(({ label, id }, index) => (
          <RadioButton value={`${index}`} key={`${id}`}>
            <Text>{label}</Text>
          </RadioButton>
        ))}
      </RadioGroup>
    </View>
  );
}

ActionItems.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  onChangeValue: PropTypes.func,
  value: PropTypes.string,
  onChangeRadio: PropTypes.func,
  selectedRadioOption: PropTypes.objectOf(PropTypes.any),
  onPressTableOrDeadline: PropTypes.func,
  salesConditionsChild: PropTypes.objectOf(PropTypes.any),
};

export default ActionItems;
