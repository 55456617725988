import axios from 'axios';
import CacheAccount from '~/controllers/Accounts/Cache/CacheAccount';
import { Platform } from 'react-native';
import CacheDataController from '~/utils/CacheDataController';

export const URL_BASE = 'https://anywhere-server.aws.polibr.com.br';
export const URL_SHARE = 'https://link.anywhere.polibr.com.br';
export const URL_SHORTER = 'https://anywhere-server.aws.polibr.com.br/link/';
const LOGIN_PATH = 'v1/api/auth/';

export async function loginAnywhere() {
  const headers = { 'Content-Type': 'application/json' };

  const url = `${URL_BASE}/${LOGIN_PATH}`;

  try {
    const cacheAccount = new CacheAccount();
    const dataAccount = await cacheAccount.getDataAccount();
    const deviceInfo = await CacheDataController.getData('@Session:deviceInfo');

    const uuid = deviceInfo?.uuid ?? '';

    const reqBody = {
      company_code: dataAccount?.user?.company?.company_code,
      username: dataAccount?.username,
      password: dataAccount?.password,
      platform_os: Platform.OS,
      session_uuid: uuid,
    };
    const response = await axios.post(url, reqBody, headers);
    return response.data;
  } catch (err) {
    throw err;
  }
}
