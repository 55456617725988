import React, { useEffect } from 'react';
import { Provider } from 'react-redux';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import AuthRoutes from './src/routes/auth.routes';
import MyTabs from './src/routes/bottomtabs.routes';

import store from './src/store/index';

import isSignedIn from './src/utils/AuthWeb';
import { NavigationContainer } from '@react-navigation/native';
import { navigationRef } from './src/routes/RootNavigation';
import Alert from '~/components/Web/Alert';

const App = () => {
  const [signedIn, setSignedIn] = React.useState(false);

  useEffect(() => {
    isSignedIn().then(async (res) => {
      if (res) {
        setSignedIn(true);
      }
    }).catch(err => {
      console.log(err);
    });
    
    // Intercepta o evento de "voltar" do navegador
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ''; // Isso ativa o alerta de confirmação padrão do navegador
    };

    // Adiciona o evento 'beforeunload' que intercepta o fechamento ou refresh da página
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Intercepta o "voltar" do navegador (eventos popstate)
    const handlePopState = (event) => {
      if (window.confirm('Você realmente deseja sair ou voltar?')) {
        // Permitir navegação de volta
        window.history.go(-1);
      } else {
        // Caso contrário, prevenir a navegação
        window.history.pushState(null, '', window.location.href);
      }
    };

    // Adiciona o evento popstate que é disparado ao clicar no botão "voltar" do navegador
    window.addEventListener('popstate', handlePopState);

    // Remove os eventos quando o componente for desmontado
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <Provider store={store}>
      <NavigationContainer ref={navigationRef}>
      {signedIn ? MyTabs() : AuthRoutes()}
      <Alert />
      <ToastContainer autoClose={2000} />
      </NavigationContainer>
    </Provider>
  );
};

export default App;
