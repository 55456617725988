import { StyleSheet } from 'react-native';
import * as config from '~/config/config';
import { fonts } from '~/utils/Fonts';

const styles = StyleSheet.create({
  modalOverlay: {
    backgroundColor: 'rgba(0,0,0, 0.5)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  modalContainer: {
    maxHeight: '90%',
    minHeight: 220,
    height: 'auto',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: 5,
    backgroundColor: 'white',
  },

  buttonsView: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  bottomButtons: {
    margin: 6,
    height: 50,
    width: 'auto',
  },
  subheader: {
    fontFamily: fonts.LatoBold,
    fontSize: 16,
    backgroundColor: '#fff',
    color: config.Colors.primaryColor,
  },
  sectionContainer: {
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderBottomColor: config.Colors.grayContainerDark,
  },
});

export default styles;
