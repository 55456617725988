import RNFetchBlob from 'react-native-blob-util';

import { getErrorByStatus } from '../../resources/SyncUtil';
import { CHECK_INTERNET_CONNECT } from '../../../constants/Sync';

const URL_BASE = 'http://syncon1.polibr.com.br:5000';
// const URL_BASE = 'http://10.0.0.105:5001';
const APPLICATION_ID = 'br.com.polibras.polistore';
export const ERROR_POLIEQUIPES_APK_INFO = 'ERROR_POLIQEUIPES_APK_INFO';
export const MESSAGE_ERROR_POLIEQUIPES_APK_INFO =
  'Não foi posível obter as informações da APK do PoliEquipes.';

export function infoAPK(user, company, urlBase) {
  const urlVmanager = urlBase || URL_BASE;
  const headers = { 'Content-Type': 'application/json' };
  const url = `${urlVmanager}/api/v1/employees/versions?employee_code=${company.toUpperCase()}&application_id=${APPLICATION_ID}&user=${user}`;
  return RNFetchBlob.config({ trustAllCerts: false })
    .fetch('GET', url, headers)
    .then(async response => {
      const { respInfo } = response;
      if (respInfo.status === 200) {
        const respJson = response.json();
        return respJson.data[0];
      }

      console.tron.log('infoAPK', 'Error', response);

      const error = { status: respInfo.status, message: response.data };
      const errorObject = await getErrorByStatus(
        error,
        ERROR_POLIEQUIPES_APK_INFO,
        MESSAGE_ERROR_POLIEQUIPES_APK_INFO,
      );
      throw errorObject;
    })
    .catch(async error => {
      console.tron.log('infoAPK', 'Error', error);

      const errorCatch = {
        status: CHECK_INTERNET_CONNECT,
        message: error.message,
      };
      const errorObject = await getErrorByStatus(
        errorCatch,
        ERROR_POLIEQUIPES_APK_INFO,
        MESSAGE_ERROR_POLIEQUIPES_APK_INFO,
      );
      throw errorObject;
    });
}
