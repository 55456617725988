import React from 'react';
import { Modal, StyleSheet, Text, View } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import { color } from '~/common';

type CustomAlertModalProps = {
  isVisible: boolean,
  disabledConfirm?: boolean,
  title: string,
  subTitle: string,
  description: string,
  children: any,
  onPressConfirm: () => {},
  confirmButtonText: string,
  onPressCancel: () => {},
  cancelButtonText: string,
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    top: 0,
    bottom: 0,
    position: 'absolute',
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  modalContent: {
    width: '90%',
    backgroundColor: '#fff',
    borderRadius: 8,
    padding: 16,
  },
  titleModalContent: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#000',
    marginBottom: 8,
    lineHeight: 24,
  },
  modalText: {
    fontSize: 16,
    color: '#000',
    marginBottom: 8,
    lineHeight: 24,
  },
  containerBtns: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 16,
  },
  containerBtnLeft: {
    paddingTop: 0,
    paddingRight: 8,
    paddingBottom: 1,
    paddingLeft: 8,
  },
  containerBtnRight: {
    paddingTop: 0,
    paddingRight: 8,
    paddingBottom: 1,
    paddingLeft: 8,
  },
  textBtns: {
    fontSize: 16,
    color: color.primaryColor,
  },
});

const CustomAlertModal = ({
  isVisible = false,
  title = '',
  subTitle = '',
  description = '',
  onPressConfirm = () => {},
  confirmButtonText = '',
  onPressCancel = () => {},
  cancelButtonText = '',
  disabledConfirm = false,
  children,
}: CustomAlertModalProps) => {
  return (
    <View>
      <Modal
        transparent
        style={{ margin: 0 }}
        animationType="slide"
        visible={isVisible}
        onRequestClose={() => {}}>
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text style={styles.titleModalContent}>{title}</Text>
            {subTitle ? (
              <View>
                <Text style={styles.modalText}>{subTitle}</Text>
              </View>
            ) : null}
            {description ? (
              <View>
                <Text style={styles.modalText}>{description}</Text>
                {children}
              </View>
            ) : null}
            <View style={styles.containerBtns}>
              <Touchable
                onPress={onPressCancel}
                >
                <View style={styles.containerBtnLeft}>
                  <Text style={styles.textBtns}>{cancelButtonText}</Text>
                </View>
              </Touchable>
              <Touchable
                disabled={disabledConfirm}
                onPress={onPressConfirm}
                style={{ marginLeft: 8, opacity: disabledConfirm ? 0.5 : 1 }}
                >
                <View style={styles.containerBtnRight}>
                  <Text style={styles.textBtns}>{confirmButtonText}</Text>
                </View>
              </Touchable>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

export default CustomAlertModal;
