import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import { color } from '~/common/index';
import Images from '@assets/Images';
import Touchable from 'react-native-platform-touchable';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
    outsideContainer: {
        marginBottom: 8,
        borderRadius: 24,
        marginHorizontal: 2,
        height: 28,
        minWidth: 28,
        overflow: 'hidden',
    },
    container: {
        borderRadius: 24,
        height: 28,
        minWidth: 28,
        justifyContent: 'center',
        borderColor: color.grayContainerDark,
        borderWidth: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    attribute: {
        fontSize: 13,
        marginRight: 24,
        marginLeft: 10,
        color: color.grayDark,
    },
    image: {
        width: 38,
        height: 38,
        borderColor: '#ddd',
        borderWidth: 0.5,
    },
    color: {
        width: 34,
        height: 34,
    },
    containerColor: {
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 24,
        height: 34,
        width: 34,
        overflow: 'hidden',
    },
    containerColor__withAttribute: {
        marginRight: 4,
        height: 30,
        width: 30,
        borderRadius: 15,
    },
    text: {
        fontSize: 16,
        marginHorizontal: 6,
        color: '#000',
    },
    badge: {
        backgroundColor: color.successColor,
        width: 12,
        height: 12,
        borderRadius: 7,
        position: 'absolute',
        right: 0,
        top: 0,
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconCheck: {
        width: 10,
        height: 10,
        tintColor: '#fff',
    },
});

const ImageOption = ({ value }) => (
    <Image
        source={{
            uri: value,
        }}
        style={styles.image}
    />
);

const ColorOption = ({ value, attribute }) => (
    <View
        style={[
            styles.containerColor,
            attribute ? styles.containerColor__withAttribute : {},
        ]}>
        <View style={[styles.color, { backgroundColor: value }]} />
    </View>
);

const TextOption = ({ value }) => (
    <Text style={styles.text}>{value}</Text>
);

function getAttributeValue(value, type, attribute) {
    switch (type) {
        case 'image':
            return <ImageOption value={value} />;
        case 'color':
            return <ColorOption value={value} attribute={attribute} />;
        case 'text':
        default:
            return <TextOption value={value} />;
    }
}

function getBodyOpttion(attribute, option, type) {
    return (
        <View style={styles.container}>
            {attribute ? <Text style={styles.attribute}>{attribute}</Text> : null}
            {getAttributeValue(option, type, attribute)}
        </View>
    );
}

const Option = ({
    attribute,
    option,
    type,
    onPress,
    selected,
    isPresent,
    clickButton,
}) => (
    <View>
        <View style={[styles.outsideContainer]}>
            {clickButton ? (
                <Touchable
                    foreground={Touchable.SelectableBackground()}
                    onPress={() => onPress(option)}>
                    {getBodyOpttion(attribute, option, type)}
                </Touchable>
            ) : (
                <View>{getBodyOpttion(attribute, option, type)}</View>
            )}
        </View>
        {selected ? (
            <View style={styles.badge}>
                <Image style={styles.iconCheck} source={Images.iconCheck} />
            </View>
        ) : null}
    </View>
);

Option.propTypes = {
    attribute: PropTypes.string,
    option: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onPress: PropTypes.func,
    selected: PropTypes.bool,
    isPresent: PropTypes.bool,
};

Option.defaultProps = {
    onPress: () => { },
};

export default Option;
