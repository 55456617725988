/* @flow */

import React, { useState, useEffect } from 'react';
import { Text, View, Image } from 'react-native';

import Touchable from 'react-native-platform-touchable';
import PropTypes from 'prop-types';

import translate from '~/locales';
import Images from '@assets/Images';
import styles from './styles';

const OPERATION_GROUP_HEADER_CONTENT = {
  selectProducts: 'selectProducts',
  countProducts: 'countProducts',
};

function CountProducts({ count = 0, onPress }) {
  const toggleVisibility = () =>
    onPress?.(OPERATION_GROUP_HEADER_CONTENT.selectProducts);
  return (
    <View style={[styles.countProductsContainer]}>
      <Touchable onPress={toggleVisibility}>
        <Image source={Images.iconClose} style={styles.iconClose} />
      </Touchable>
      <Text style={[styles.countProductsTextStyle, { marginHorizontal: 4 }]}>
        {count}
      </Text>
      <Text style={styles.countProductsTextStyle}>
        {translate('selecteds')}
      </Text>
    </View>
  );
}

CountProducts.propTypes = {
  count: PropTypes.number.isRequired,
  onPress: PropTypes.func.isRequired,
};

function SelectProducts({ onPress }) {
  const toggleVisibility = () =>
    onPress?.(OPERATION_GROUP_HEADER_CONTENT.countProducts);
  return (
    <View style={styles.container}>
      <Touchable onPress={toggleVisibility}>
        <Text style={styles.selectProductsTextStyle}>
          {translate('selectProducts')}
        </Text>
      </Touchable>
    </View>
  );
}

SelectProducts.propTypes = {
  onPress: PropTypes.func.isRequired,
};

export function Header({
  qtySelected,
  toggleProductsGrouping,
  toggleHeaderContent,
}) {
  const selectProductsHeader = 'selectProducts';

  const [headerContent, setHeaderContent] = useState(
    OPERATION_GROUP_HEADER_CONTENT.selectProducts,
  );

  const enableGroupingProducts = headerText => {
    toggleProductsGrouping({ activeGrouping: true });
    setHeaderContent(headerText);
  };

  const desableGroupingProducts = headerText => {
    toggleProductsGrouping({ activeGrouping: false });
    setHeaderContent(headerText);
  };

  useEffect(
    () => {
      const selectsProductsHeader = toggleHeaderContent
        ? OPERATION_GROUP_HEADER_CONTENT.selectProducts
        : OPERATION_GROUP_HEADER_CONTENT.countProducts;

      setHeaderContent(selectsProductsHeader);
    },
    [toggleHeaderContent],
  );

  return headerContent === selectProductsHeader ? (
    <SelectProducts onPress={enableGroupingProducts} />
  ) : (
    <CountProducts onPress={desableGroupingProducts} count={qtySelected} />
  );
}

Header.propTypes = {
  qtySelected: PropTypes.number.isRequired,
  toggleProductsGrouping: PropTypes.func.isRequired,
  toggleHeaderContent: PropTypes.bool,
};
