import React, { Component } from 'react';
import { View, TextInput } from 'react-native';
import Masked from 'vanilla-masker';
import { Button } from '../../components/index';
import Images from '@assets/Images';
import styles from './styles';
import _ from 'lodash';

import PropTypes from 'prop-types';

class Quantity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: this.validateQuantity(props.quantity),
    };

    this.addButton = _.debounce(props.onChangeQuantity, 1500);
  }

  componentDidUpdate({ quantity }) {
    (() => {
      if (quantity === this.props.quantity) {
        return;
      }
      if (this.state.quantity === this.props.quantity) {
        return;
      }
      this.setState({ quantity: this.props.quantity });
    })();
  }

  onChangeTextQuantity = quantity => {
    this.setState({ quantity });
  };

  onEndEditingQuantity = () => {
    const qty = this.validateQuantity(this.state.quantity);

    this.setState({ quantity: qty }, () => {
      this.addButton(qty);
    });
  };

  validateQuantity(quantity) {
    const { multiple, maxQuantity, minQuantity, ignoreStock } = this.props;

    let qty = quantity;
    if (quantity) {
      qty = Number.parseInt(Masked.toNumber(quantity));
    } else {
      qty = 0;
    }

    if (maxQuantity && qty > maxQuantity && !ignoreStock) {
      qty = maxQuantity;
    }

    if (qty < minQuantity) {
      qty = minQuantity;
      if (multiple > minQuantity) {
        qty = multiple;
      }
    }

    const qtyDiv = qty % multiple;
    if (qtyDiv !== 0) {
      qty = Math.trunc(qty / multiple) * multiple + multiple;
    }
    return qty;
  }

  addRemoveClick(qty) {
    const quantity = this.validateQuantity(this.state.quantity + qty);
    if (quantity >= 0) {
      const self = this;
      this.setState({ quantity }, () => {
        self.addButton(quantity);
      });
    }
  }

  render() {
    const {
      multiple,
      maxQuantity,
      minQuantity,
      style,
      inputStyle,
      updatingItem,
      ignoreStock,
    } = this.props;

    const maxQuantityValue = ignoreStock ? undefined : maxQuantity;
    const { quantity } = this.state;

    return (
      <View style={[styles.rootContainer, style]}>
        <Button
          iconLeft={Images.iconMinus}
          small
          disabled={updatingItem || quantity <= minQuantity}
          iconButton
          onPress={() => this.addRemoveClick(-multiple)}
        />
        <TextInput
          value={`${isNaN(this.state.quantity) ? 0 : this.state.quantity}`}
          editable={!updatingItem}
          style={[styles.input, inputStyle]}
          onChangeText={this.onChangeTextQuantity}
          onEndEditing={this.onEndEditingQuantity}
          underlineColorAndroid="transparent"
          keyboardType="numeric"
        />
        <Button
          iconLeft={Images.iconPlus}
          small
          disabled={
            updatingItem || (maxQuantityValue && quantity >= maxQuantityValue)
          }
          iconButton
          onPress={() => this.addRemoveClick(multiple)}
        />
      </View>
    );
  }
}

Quantity.propTypes = {
    quantity: PropTypes.number,
    maxQuantity: PropTypes.number,
    minQuantity: PropTypes.number,
    multiple: PropTypes.number,
    ignoreStock: PropTypes.bool,
    onChangeQuantity: PropTypes.func,
    updatingItem: PropTypes.bool,
    inputStyle: PropTypes.object,
    style: PropTypes.object,
};

Quantity.defaultProps = {
  onChangeQuantity: () => {},
  minQuantity: 0,
  multiple: 1,
  quantity: 0,
};

export default Quantity;
