import loginAnywhere from '../../services/anywhere/login';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { CALL_GENERIC_PROPERTY } from '../WrappedModule';

const TOKEN_AUTH_USER = '@TOKEN_AUTH_USER';

const AccountsApiModule = nameModule => {
  const methodByAlias = {
    obtainUserData: 'obterDadosUsuario',
    getValueAtivo: 'getValueAtivo',
    salvarInfoAtivo: 'salvarInfoAtivo',
  };
  let userData = {};

  const iniciarLib = async (company, login, uuid, resolve, reject) => {
    userData = {
      company,
      login,
      uuid: userData.uuid || uuid,
    };
    if (resolve !== undefined) {
      resolve(userData);
    }
    return userData;
  };

  const validadeLogin = async (company, login, password, resolve, reject) => {
    userData = {
      ...userData,
      company,
      login,
      password,
    };

    if (resolve !== undefined) {
      resolve(userData);
    }

    return userData;
  };

  const obtainParamsSyncon = async (
    company,
    login,
    password,
    resolve,
    reject,
  ) => {
    userData = {
      ...userData,
      company,
      login,
      password,
    };

    if (resolve !== undefined) {
      resolve(userData);
    }

    return userData;
  };

  const whenParamSignInSuccess = async (data, resolve, reject) => {
    userData = {
      ...userData,
      ...data,
      uuid: userData.uuid,
    };

    if (resolve !== undefined) {
      resolve(data);
    }
    return userData;
  };

  const callLoginAsyncTask = (params, resolve, reject) => {
    const { company, login, password } = userData;
    const { uuid } = userData;
    const username = login.split('@')[0];

    loginAnywhere(username, password, company, uuid)
      .then(response => {
        if (resolve !== undefined) {
          resolve(userData);
          if (response && response.data) {
            AsyncStorage.setItem(TOKEN_AUTH_USER, response.data.token).then(() => {
            });
          }
        }
      })
      .catch(error => {
        reject(error);
      });
    return userData;
  };

  const whenPerformSignInSuccess = async (data, resolve, reject) => {
    if (resolve !== undefined) {
      resolve(JSON.stringify(userData));
    }
    return JSON.stringify(userData);
  };

  const updateUUID = async (uuid, resolve, reject) => {
    userData = {
      ...userData,
      uuid,
    };
    if (resolve !== undefined) {
      resolve(JSON.stringify(userData));
    }

    return JSON.stringify(userData);
  };

  const logout = async () => {
    await AsyncStorage.clear();
  };

  const setCurrentModule = async (currentModule, resolve, reject) => {
    if (resolve !== undefined) {
      resolve(currentModule);
    }

    return currentModule;
  };

  const genericProperties = {};
  Object.keys(methodByAlias).forEach(
    k => (genericProperties[k] = CALL_GENERIC_PROPERTY),
  );

  return {
    getName: () => nameModule,
    methodByAlias,
    iniciarLib,
    validadeLogin,
    obtainParamsSyncon,
    whenParamSignInSuccess,
    callLoginAsyncTask,
    whenPerformSignInSuccess,
    updateUUID,
    logout,
    setCurrentModule,
    ...genericProperties,
  };
};

export default AccountsApiModule;
