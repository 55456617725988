import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  StyleSheet,
  Text,
  TextInput,
  ScrollView,
  View,
  Image,
  Share,
  Alert,
  Linking,
  TouchableOpacity,
} from 'react-native';
import { color } from '~/common';
import { Button, RadioGroupData } from '~/components';
import Icon from 'react-native-vector-icons/AntDesign';
import IconMaterial from 'react-native-vector-icons/MaterialCommunityIcons';
import Touchable from 'react-native-platform-touchable';
import moment from 'moment';
import { launchCamera, launchImageLibrary} from 'react-native-image-picker';
import Images from '@assets/Images';
import { DateTimePickerAndroid } from '@react-native-community/datetimepicker';
import saleConditions from '~/services/resources/libFastSeller/saleConditions';
import { parseFloatToCurrency } from '~/utils/Currency';
import _ from 'lodash';
import Snackbar from 'react-native-snackbar';
import OrdersModule from '~/modules/OrdersModule';
import NavigationHelper from '~/screens/NavigationHelper';
import Spinner from 'react-native-loading-spinner-overlay';
import {
  loginAnywhere,
  URL_SHARE,
  URL_SHORTER,
} from '~/services/resources/Anywhere';
import axios from 'axios';
import CacheDataController from '~/utils/CacheDataController';
import { connect } from 'react-redux';
import { currentScreenVisible } from '~/store/currentScreenVisible/slice';
import { SCREEN_SHARE_ORDER } from '~/screens/screens';
import { syncUpload } from '~/TaskSync';
import {
  SAVE_PHOTO_ANSWER_FETCH_SUCCESS,
  savePhotoAnswer,
} from '~/store/photoAnswerActivity/slice';
import CacheAccount from '~/controllers/Accounts/Cache/CacheAccount';
import { SYNC_FINALLY } from '~/constants/Sync';
import UploadAtividades from '~/services/resources/UploadAtividades';
import translate from '~/locales';
import CachePolistore from '~/controllers/Accounts/Cache/CachePolistore';
import { Navigation } from 'react-native-navigation';
import { useNetInfo } from '@react-native-community/netinfo';
import PropTypes from 'prop-types';
import { showAlert } from '~/components/Web/Alert';
import { createNotifyError } from '~/components/Web/ToastNotify';
import DateTime from '~/components/Form/Field/DateTime';
import AsyncStorage from '@react-native-async-storage/async-storage';

const styles = StyleSheet.create({
  title: {
    fontWeight: '700',
    color: 'black',
    fontSize: 18,
    marginBottom: 5,
  },
  container: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 16,
    height: '100%',
    width: '100%',
  },
  label: {
    fontSize: 12,
    fontWeight: '500',
  },
  value: {
    fontSize: 15,
    color: '#000',
  },
  labelValueSection: {
    marginBottom: 12,
  },
  radioSection: {
    width: 35,
  },
  containerInput: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderWidth: 0.9,
    borderColor: '#000',
    padding: 13,
    borderRadius: 4,
    marginTop: 4,
  },
  input: {
    flex: 1,
    backgroundColor: '#fff',
    color: '#000',
    fontSize: 15,
  },
  containerButton: {
    borderRadius: 4,
    backgroundColor: color.primaryColor,
  },
  button: {
    margin: 4,
    borderRadius: 0,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  containerInputs: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  textExpiration: { fontWeight: '500' },
  scrollView: { marginBottom: 30, width: '100%' },
  photoButton: {
    top: 32,
    backgroundColor: color.grayAlmostWhite,
    padding: 15,
    borderWidth: 1,
    borderColor: color.grayDark,
    borderStyle: 'dashed',
    borderRadius: 4,
    width: '100%',
  },
  buttonPhotoItem: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  textLabelPhoto: {
    marginLeft: 10,
    fontSize: 18,
    fontWeight: '700',
    color: '#000',
  },
  listPhotosContainer: {
    marginTop: 40,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  photoItem: {
    borderRadius: 4,
    height: 70,
    width: 80,
  },
  photoItemIcon: {
    bottom: 48,
    borderRadius: 4,
    left: 60,
    position: 'absolute',
  },
});

const ShareOrder = ({
  componentId,
  dispatch,
  currentScreen,
  syncFetch,
  photoAnswer,
  navigation,
  route,
}) => {
  const [config, setConfig] = useState({});
  const configs = route.params?.configs;
  const order = route.params?.order;
  const client = route.params?.client;
  const saveCartBudget = route.params?.saveCartBudget;
  const comesFromItinerary = route.params?.comesFromItinerary;
  const activitiesComponentId = route.params?.activitiesComponentId;
  const [loading, setLoading] = useState(true);
  const [selectedRadio, setSelectedRadio] = useState(1);
  const [photos, setPhotos] = useState([]);
  const [conditionSale, setConditionSale] = useState('');
  const [dateSelected, setDateSelected] = useState(
    moment().format('DD/MM/YYYY'),
  );
  const [timeSelected, setTimeSelected] = useState(
    moment()
      .endOf('day')
      .format('HH:mm'),
  );
  const netInfo = useNetInfo();
  const canSyncPhotos = useRef(false);
  const hadChange = useRef(false);

  const handleNavigationTopBar = () => {
    Navigation.mergeOptions(componentId, {
      topBar: {
        backButton: {
          id: 'backPress',
          showTitle: false,
          color: '#fff',
          popStackOnPress: false,
        },
      },
      hardwareBackButton: {
        dismissModalOnPress: false,
        popStackOnPress: false,
      },
    });
  };

  useEffect(
    () => {
      getConfig();
      if (
        canSyncPhotos.current &&
        photoAnswer.type === SAVE_PHOTO_ANSWER_FETCH_SUCCESS
      ) {
        syncPhotos();
      }
    },
    [photoAnswer.type],
  );

  const getConfig = async () => {
    const config = await AsyncStorage.getItem('@objPayload');
    const parseConfig = JSON.parse(config);
    setConfig(parseConfig);
  };

  const handleBackPress = () => {
    if (hadChange.current) {
      showAlert(translate('discardChanges'),
      `${translate('discardChangesInForm')}`,
      () => navigation.goBack());
    } else {
      navigation.goBack();
    }
  };

  useEffect(() => {
    setLeftButton();
    const { condvenda } = order;
    dispatch(
      currentScreenVisible({
        screen: componentId,
        screenName: SCREEN_SHARE_ORDER.name,
      }),
    );

    const listener = {
      componentDidAppear: () => {
        handleNavigationTopBar();
        saleConditions(client.pkey)
          .then(({ orgvenda, tipovenda, codprazo }) => {
            const orgV = orgvenda.find(ov => (ov.pkey = condvenda.orgvenda));
            const tipoV = tipovenda.find(tv => (tv.pkey = condvenda.tipovenda));
            const prazoV = codprazo.find(pv => (pv.pkey = condvenda.codprazo));

            setConditionSale(
              `${orgV.descricao} - ${tipoV.descricao} - ${prazoV.descricao}`,
            );
          })
          .finally(() => {
            setLoading(false);
          });
      },
      navigationButtonPressed,
    };
    const unsubscribe =  null;
    return () => {
      unsubscribe?.remove();
    };
  }, []);

  const handleBackScreen = () => {
    if (comesFromItinerary) {
      navigation.pop(2);
    } else {
      NavigationHelper.popToRoot(componentId);
      navigation.pop();
    }
  };

  const navigationButtonPressed = ({ buttonId }) => {
    const isBackPress = ['RNN.hardwareBackButton', 'backPress'].includes(
      buttonId,
    );
    if (isBackPress) {
      handleBackPress();
    }
  };

  const getImgSource = async response => {
    const convertToBlob = await fetch(response.assets[0].uri).then(response => response.blob());
    return {
      uri: convertToBlob,
      isStatic: true,
      fileName: response.assets[0].fileName || 'imagename.png',
      filePath: 'file://fakepath/imagename.png',
      type: convertToBlob.type,
    };
  };

  const onPressShowCamera = ({ actionType = '' }) => {
    const options = {
      mediaType: 'photo',
      includeBase64: false,
    };

    launchImageLibrary(options, async response => {
      if (response.didCancel) {
        console.tron.log('User cancelled image picker');
      } else if (response.error) {
        console.tron.log('Error take picture', response.error);
      } else if (response.customButton) {
        console.tron.log('User tapped custom button: ', response.customButton);
      } else {
        const source = await getImgSource(response);
        const newPhotos = [...photos];
        newPhotos.unshift(source);
        setPhotos(newPhotos);
        hadChange.current = true;
      }
    });
  };

  const onRemovePhoto = photo => {
    const newPhotos = [...photos].filter(
      ({ fileName }) => fileName !== photo.fileName,
    );
    setPhotos(newPhotos);
    hadChange.current = true;
  };

  const openTimePicker = mode => {
    DateTimePickerAndroid.open({
      mode,
      value:
        mode === 'date'
          ? moment(dateSelected, 'DD/MM/YYYY').toDate()
          : moment(timeSelected, 'HH:mm').toDate(),
      minimumDate: new Date(),
      is24Hour: true,
      onChange: (event, date) => {
        if (event.type !== 'dismissed') {
          const datePicked = moment(date).format('L');
          const hoursPicked = moment(date).format('HH:mm');

          const isSameDay = moment(
            mode === 'time' ? dateSelected : datePicked,
            'DD/MM/YYYY',
          ).isSame(moment(new Date()), 'day');

          if (isSameDay) {
            const isNowAfter = moment(new Date()).isSameOrAfter(
              moment(
                `${mode === 'time' ? dateSelected : datePicked} ${
                  mode === 'time' ? hoursPicked : timeSelected
                }`,
                'DD/MM/YYYY HH:mm',
              ).toDate(),
            );
            if (isNowAfter) {
              Snackbar.show({
                text: 'Data de expiração não pode ser menor do que agora.',
              });
              return;
            }
          }

          if (mode === 'time') {
            setTimeSelected(hoursPicked);
            hadChange.current = true;
          } else if (mode === 'date') {
            setDateSelected(datePicked);
            hadChange.current = true;
          }
        }
      },
    });
  };

  const alertEditing = () => {
    setSelectedRadio(0);
    hadChange.current = true;
    showAlert('Configuração indisponível',
    'O plano atual não permite configurar o pedido\nEntre em contato com o seu supervisor.',
    () => {
      setSelectedRadio(1);
      hadChange.current = true;
    });
  };

  const onChangeRadio = option => {
    if (option !== selectedRadio) {
      const shareOrderConfigs = _.get(
        configs,
        'payload.configuracoes.share_order',
        {},
      );
      if (option === 0) {
        if (shareOrderConfigs.enable_edition) {
          setSelectedRadio(option);
          hadChange.current = true;
        } else {
          alertEditing();
        }
      } else {
        setSelectedRadio(option);
        hadChange.current = true;
      }
    }
  };

  const getAnywhereToken = async () => {
    try {
      const resp = await loginAnywhere();
      return resp?.token;
    } catch (err) {
      console.tron.log('Error get anywhere token', err?.message);
      throw err;
    }
  };

  const getShortLink = async linkToShort => {
    try {
      const resp = await axios.post(URL_SHORTER, {
        url: linkToShort,
      });
      return resp.data?.link;
    } catch (err) {
      console.tron.log('Error get short link', err?.message);
      throw err;
    }
  };

  const saveObs = async () => {
    const urlStocker = _.get(config, 'payload.url_stocker', '');
    const tokenStocker = _.get(config, 'payload.token_stocker', '');
    const companyLogo = _.get(config, 'payload.logo', '');
    const companyName = _.get(config, 'payload.display_name', '');
    const bucketConfig = _.get(configs, 'payload.bucket_config.params', {});
    const userData = (await new CachePolistore().getParamsSignIn()) || '{}';
    const companyData = (await new CachePolistore().getLoginData()) || '{}';

    const { rca_codmaq: codmaq } = JSON.parse(userData);
    const { empresa: companyCode } = JSON.parse(companyData);
    const formattedPhotos = [...photos].map(photo => ({
      url: `${bucketConfig?.url}/${codmaq}/${
        photo?.fileName
      }?tenant=${companyCode?.toUpperCase()}`,
    }));

    const expirationDate = moment(
      `${dateSelected} ${timeSelected}`,
      'DD/MM/YYYY HH:mm',
    ).format('YYYY-MM-DD HH:mm:ss');

    return await OrdersModule.saveObservationLink(
      JSON.stringify({
        order: order.codigo,
        client: client.pkey,
        configuration: {
          editable: selectedRadio === 0,
          expires_in: expirationDate,
          photos: [...formattedPhotos],
        },
        urlStocker,
        tokenStocker,
        company_logo: companyLogo,
        company_name: companyName,
      }),
    );
  };

  const savePhotos = () => {
    const formPhotos = Object.values(photos).filter(v => Boolean(v && v.uri));
    if (formPhotos.length > 0) {
      canSyncPhotos.current = true;
      dispatch(
        savePhotoAnswer({
          payload: {
            photo: [...formPhotos],
          },
        }),
      );
    }
  };

  const canShare = () => {
    const onlyFromRouting = _.get(
      configs,
      'payload.configuracoes.share_order.only_from_routing',
      true,
    );

    return (onlyFromRouting && comesFromItinerary) || !onlyFromRouting;
  };

  const share = async shortLink => {
    Share.share({
      message: shortLink,
    })
      .then(async res => {
        await CacheDataController.saveData(`${order.codigo}_LINK`, shortLink);
      })
      .finally(() => {
        setTimeout(() => {
          handleBackScreen();
          setLoading(false);
        }, 200);
      });
  };

  const handleLink = async () => {
    setLoading(true);

    const shareOrderConfigs = _.get(
      configs,
      'payload.configuracoes.share_order',
      {},
    );

    const cacheLink = await CacheDataController.getData(`${order.codigo}_LINK`);

    const directShare = shareOrderConfigs.direct_share ?? {};

    let shortLink = cacheLink;
    if (!shortLink) {
      try {
        const token = await getAnywhereToken();
        const link = `${URL_SHARE}?order=${order.codigo}&token=${token}`;
        shortLink = await getShortLink(link);
      } catch (err) {
        setLoading(false);
        showAlert('Erro ao compartilhar pedido',
        'Seu pedido foi feito, porém não foi possível obter o link compartilhável.\n\nAcesse o pedido pela listagem geral de pedidos e tente gerar novamente',
        () => {},
        () => handleBackScreen());
        return;
      }
    }

    if (directShare?.link) {
      const clientPhone = client?.telefones?.find(
        ({ tipo }) => tipo === directShare.s_tipo,
      );
      const replacedLink = `${String(directShare.link || '').replace(
        '{s_tipo}',
        `${clientPhone?.numero}`,
      )}?text=${directShare.message}\n\n${shortLink}`;
      Linking.canOpenURL(replacedLink).then(async supported => {
        if (!supported) {
          await share(shortLink);
        } else {
          Linking.openURL(replacedLink);
        }
      });
    } else {
      await share(shortLink);
    }
  };

  const syncPhotos = async () => {
    const cacheAccount = new CacheAccount();
    const dataAccount = await cacheAccount.getDataAccount();
    const dataUserDevice = await cacheAccount.getUserDevice();
    const syncStatus = _.get(syncFetch, 'payload.statusSync');
    if (syncStatus === SYNC_FINALLY) {
      const uploadAtividades = new UploadAtividades(
        dataAccount,
        dataUserDevice,
        () => {
          Snackbar.show({
            text: `${translate('photosSuccessfullySynced')}`,
          });
        },
        () => {
          Snackbar.show({
            text: `${translate('errorSyncPhotos')}`,
          });
        },
      );
      uploadAtividades.upload();
    }
  };

  const onPressSend = async () => {
    if (!netInfo.isConnected) {
      showAlert('Sem conexão com a internet', 
      'Para compartilhar o pedido é necessário conexão com a internet.\n\nHabilite seus dados móveis ou wi-fi e tente novamente.',
      () => {});
      return;
    }

    if (canShare()) {
      setLoading(true);
      try {
        await saveObs();
        await saveCartBudget(
          () => {
            setLoading(false);
            handleLink();
            savePhotos();
            syncUpload();
          },
          () => {
            setLoading(false);
          },
        );
      } catch (err) {
        setLoading(false);
        console.log('ERR SHARE LINK =>', err?.message);
        createNotifyError('Erro ao compartilhar link');
      }
    } else {
      setLoading(false);
      createNotifyError('Compartilhamento permitido somente pelo roteiro!');
    }
  };

  const setLeftButton = () => {
    navigation.setOptions({
      headerLeft: () => (
        <TouchableOpacity onPress={() => {
          navigation.pop();
        }}
        >
          <Image
            source={Images.iconBack}
            style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
          />
        </TouchableOpacity>
      ),
    });
  };

  return (
    <View style={styles.container}>
      <Spinner
        visible={loading}
        overlayColor="rgba(0, 0, 0, 0.7)"
        textStyle={{ color: '#FFF' }}
      />
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={styles.scrollView}>
        <View>
          <Text style={styles.title}>Resumo do pedido</Text>
          <View style={styles.labelValueSection}>
            <Text style={styles.label}>Cliente</Text>
            <Text style={styles.value}>{client?.nome}</Text>
          </View>
          <View style={styles.labelValueSection}>
            <Text style={styles.label}>Valor total</Text>
            <Text style={styles.value}>
              {parseFloatToCurrency(order?.total, configs, {
                type: 'calculated',
                positiveOrNegative: true,
              })}
            </Text>
          </View>
          <View style={styles.labelValueSection}>
            <Text style={styles.label}>Forma de pagamento</Text>
            <Text style={styles.value}>{conditionSale}</Text>
          </View>
        </View>

        <View>
          <Text style={styles.title}>Configurações do pedido</Text>
          <View style={styles.labelValueSection}>
            <Text style={styles.label}>O cliente pode editar o pedido?</Text>
            <View style={styles.radioSection}>
              <RadioGroupData
                valueSelected={selectedRadio}
                itens={[{ label: 'Sim', value: 0 }, { label: 'Não', value: 1 }]}
                onSelectItem={onChangeRadio}
              />
            </View>
          </View>
        </View>

        <View style={{}}>
          <Text style={styles.textExpiration}>Expira em:</Text>
          <View style={styles.containerInputs}>
            <View style={{ flexGrow: 1, flexBasis: 0 }}>
              <Text style={{ fontSize: 12 }}>Data</Text>
              <DateTime
               editable={false}
               value={dateSelected}
               style={styles.input}
               onChangeValue={setDateSelected}
               />
            </View>
            <View style={{ width: 16 }} />
            <View style={{ flexGrow: 1, flexBasis: 0 }}>
              <Text style={{ fontSize: 12 }}>Hora</Text>
              <DateTime
               editable={false}
               value={timeSelected}
               style={styles.input}
               onChangeValue={setTimeSelected}
               />
            </View>
          </View>
        </View>

        <Touchable onPress={onPressShowCamera} style={styles.photoButton}>
          <View>
            <View style={styles.buttonPhotoItem}>
              <IconMaterial
                color={'#000'}
                name="camera-plus-outline"
                size={25}
              />
              <View style={{ flexDirection: 'column' }}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Text style={styles.textLabelPhoto}>Adicionar fotos</Text>
                  <Text style={{ fontSize: 12, marginLeft: 5 }}>
                    {'(Opcional)'}
                  </Text>
                </View>
                <Text style={{ marginLeft: 10 }}>
                  Toque para abrir a câmera
                </Text>
              </View>
            </View>
          </View>
        </Touchable>
        <View style={styles.listPhotosContainer}>
          {photos?.map(photo => (
            <View key={photo?.filename} style={{ padding: 5 }}>
              <Image style={styles.photoItem} source={photo} />
              <Icon
                style={styles.photoItemIcon}
                name="closesquare"
                size={26}
                color={'#000'}
                onPress={() => onRemovePhoto(photo)}
              />
            </View>
          ))}
          {photos?.length ? (
            <Text style={{ fontSize: 13 }}>
              * As fotos serão enviadas quando estiver conectado à uma rede
              WiFi.
            </Text>
          ) : null}
        </View>
      </ScrollView>
      <Button
        normalCase
        style={styles.containerButton}
        containerStyle={styles.button}
        title={'Enviar pedido'}
        onPress={onPressSend}
      />
    </View>
  );
};

ShareOrder.propTypes = {
  componentId: PropTypes.number,
  configs: PropTypes.objectOf(PropTypes.any),
  order: PropTypes.objectOf(PropTypes.any),
  client: PropTypes.objectOf(PropTypes.any),
  saveCartBudget: PropTypes.func,
  dispatch: PropTypes.func,
  currentScreen: PropTypes.any,
  config: PropTypes.objectOf(PropTypes.any),
  syncFetch: PropTypes.objectOf(PropTypes.any),
  photoAnswer: PropTypes.objectOf(PropTypes.any),
  comesFromItinerary: PropTypes.bool,
  activitiesComponentId: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    currentScreen: state.currentScreenVisible,
    config: state.configFetch,
    syncFetch: state.syncFetch,
    photoAnswer: state.savePhotoAnswer,
  };
}

export default connect(mapStateToProps)(ShareOrder);
