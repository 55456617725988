import OrdersModule from '~/modules/OrdersModule';
import ProductsModule from '~/modules/ProductsModule';

const getCart = payload => {
  const { codigo, pkeyClient, condvenda, orcamento } = payload;
  return new Promise((resolve, reject) => {
    OrdersModule.getCart(
      codigo,
      pkeyClient,
      JSON.stringify(condvenda),
      orcamento,
      resolve,
      reject,
    );
  });
};

const getCartCoringa = payload => {
  const { codigo, pkeyClient, condvenda, orcamento, pkPcad } = payload;
  return new Promise((resolve, reject) => {
    OrdersModule.getCartClientCoringa(
      codigo,
      pkeyClient || condvenda.codcliente,
      JSON.stringify(condvenda),
      orcamento,
      pkPcad,
      resolve,
      reject,
    );
  });
};

const cancelOrderActive = () =>
  new Promise((resolve, reject) => {
    OrdersModule.cancelActiveCart(resolve, reject);
  });

const getOrder = (pkeyOrder, isEditOrder = false) =>
  new Promise((resolve, reject) => {
    OrdersModule.getCartByPkey(pkeyOrder, isEditOrder, resolve, reject);
  });

const copyOrder = payload =>
  new Promise((resolve, reject) => {
    const { copyComplementary, order, keepCopyOrder = false } = payload;

    OrdersModule.copyOrder(
      order.numero_pedido,
      copyComplementary,
      keepCopyOrder,
      resolve,
      reject,
    );
  });

const saveCart = lastCartId => OrdersModule.saveCart(lastCartId);

const addItem = payload =>
  new Promise((resolve, reject) => {
    const { clientCode, itemKey, item, ignoreQtdSuggestion = false } = payload;

    const resolveInterceptor = data => {
      const parsedData = JSON.parse(data);
      if (parsedData.mensagens) {
        // Insert item interceptor
        parsedData.mensagens = parsedData.mensagens.map(message => ({
          ...message,
          params: {
            ...message.params,
            target: {
              product: item,
              quantity: item.quantidade,
            },
          },
        }));
      }
      resolve(JSON.stringify(parsedData));
    };

    OrdersModule.updateItem(
      clientCode,
      itemKey,
      JSON.stringify(item),
      ignoreQtdSuggestion,
      resolveInterceptor,
      reject,
    );
  });

const getLastCart = pkeyClient =>
  new Promise((resolve, reject) => {
    OrdersModule.getLastCart(pkeyClient, resolve, reject);
  });

const finishCart = ({ cartId }) =>
  new Promise((resolve, reject) => {
    OrdersModule.finishCart(cartId, resolve, reject);
  });

const updateShippingOrderAndCompany = (shipping, company = '{}') =>
  OrdersModule.updateShippingOrderAndCompany(shipping, company);

const applyOperationByGroup = payload =>
  new Promise((resolve, reject) => {
    const { operations, selectedProducts } = payload;
    OrdersModule.applyOperationByGroup(
      JSON.stringify(operations),
      JSON.stringify(selectedProducts),
      resolve,
      reject,
    );
  });

const removeMultipleItems = payload =>
  new Promise((resolve, reject) => {
    const { selectedProducts } = payload;
    OrdersModule.applyGroupExclusion(
      JSON.stringify(selectedProducts),
      resolve,
      reject,
    );
  });

const applyDiscountTalao = async ({ input, type }) => {
  const response = await OrdersModule.applyDiscountTalao(
    JSON.stringify(input),
    type,
  );
  return response;
};
const setScale = payload =>
  new Promise((resolve, reject) => {
    const { pkeyProduto, pkeyEscala, valor } = payload;
    ProductsModule.setScale(
      pkeyProduto.toString(),
      pkeyEscala.toString(),
      valor,
      true,
      resolve,
      reject,
    );
  });

const updateBonification = payload =>
  new Promise((resolve, reject) => {
    const { pkeyOrder, quant } = payload;
    OrdersModule.updateBonification(pkeyOrder, quant, resolve, reject);
  });

export {
  getCart,
  cancelOrderActive,
  getOrder,
  copyOrder,
  saveCart,
  addItem,
  getLastCart,
  finishCart,
  updateShippingOrderAndCompany,
  applyOperationByGroup,
  removeMultipleItems,
  applyDiscountTalao,
  setScale,
  updateBonification,
  getCartCoringa,
};
