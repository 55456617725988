import React, { Component } from 'react';

import _ from 'lodash';
import ComboDetail from './ComboDetail';
import translate from '../../locales';
import CombosModule from '~/modules/CombosModule';
import { updateItemFetch } from '~/store/ordersFetch/updateItemSlice';
import { updateCombo } from '~/store/ordersFetch/updateComboSlice';
import { connect } from 'react-redux';
import NavigationHelper from '~/screens/NavigationHelper';
import {
  SCREEN_OBSERVATIONS,
  SCREEN_PRODUCT_DETAIL,
  SCREEN_SALES_CONDITIONS_CHILD,
} from '~/screens/screens';
import { Alert, Image, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getSourceImageProduct } from '../../utils/ProductUtil';
import { getListWithPagination } from '~/utils/FastSellerUtils';
import Images from '../../../assets/Images';
import { filterByTextFields, getFieldsSearch } from '~/utils/sorterFilter';
import onPressHelper from '~/utils/onPressHelper';

import PropTypes from 'prop-types';
import { showAlert } from '~/components/Web/Alert';
import { createNotifyError, createNotifySuccess } from '~/components/Web/ToastNotify';

class ComboDetailContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: {},
      isCollapsedMessage: false,
      configCached: {},
      messages: [],
      combo: this.props.route?.params?.combo || {},
      applyingCombo: false,
    };

    this.productsCategoriesPage = 1;
  }

  componentDidMount() {
    this.getConfigCached();
    this.activeCombo();
    this.listCombDetail();
    this.props.navigation.setOptions({
        headerLeft: () => (
            <TouchableOpacity
                onPress={() => {
                    this.handleBackPress();
                }}>
                <Image
                    style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
                    source={Images.iconBack}
                />
            </TouchableOpacity>
        ),
    });
  }

  componentWillUnmount() {
    this.disableCombo();
  }

  handleBackPress() {
    const combo = this.state.combo;
    const itemsInCart = this.getItemsInCart();

    if (combo?.hasApplyFlow && !combo?.applied && itemsInCart.length) {
      showAlert(`${translate('atention')}`, translate('comboAlertExit'), this.removeItemsCart);
    } else {
        this.props.navigation.goBack();
    }
  }

  navigationButtonPressed = async event => {
    switch (event.buttonId) {
      case 'backPress':
        this.handleBackPress();
        break;

      default:
        break;
    }
  };

  activeCombo = async () => {
    const combo = this.state.combo;
    const id = combo?.id;

    await CombosModule.selectCombo(id, true);
  };

  disableCombo = async () => {
    const combo = this.state.combo;
    const id = combo?.id;
    await CombosModule.selectCombo(id, false);
  };

  removeItemsCart = async () => {
    const combo = this.state.combo;
    const id = combo?.id;

    this.setState(
      {
        applyingCombo: true,
      },
      () => {
        setTimeout(async () => {
          try {
            await CombosModule.removeCombo(id);
          } finally {
            this.setState({
              applyingCombo: false,
            });
            const itemsInCart = this.getItemsInCart();
            this.props.navigation.goBack();

            itemsInCart.forEach(item => {
              this.addItem(item, 0);
            });
          }
        }, 100);
      },
    );
  };

  onSearch = searchVal => {
    const combo = this.refCombs;
    const combGroups = combo?.groups || [];
    let newGroups = {};
    if (searchVal) {
      newGroups = [...combGroups]
        .map(group => ({
          ...group,
          products: filterByTextFields(
            group?.products,
            getFieldsSearch(group?.products),
            searchVal,
          ),
          isCollapsed: false,
        }))
        .filter(({ products = [] }) => products?.length);
    } else {
      newGroups = [...combGroups];
    }

    this.setState({ combo: { ...combo, groups: newGroups } });
  };

  onApplyCombo = () => {
    this.setState(
      {
        applyingCombo: true,
      },
      async () => {
        try {
          const { coddesconto } = this.state.combo;
          const items = this.getItemsInCart();
          if (!items.length) {
            createNotifyError('Adicione items para aplicar o combo.');
            return;
          }

          const cart = await CombosModule.applyCombo(coddesconto);
          createNotifySuccess('Combo aplicado com sucesso.');
          if (this.props.route.params.updateListCombos) {
            this.props.route.params.updateListCombos();
          }
          this.props.dispatch(updateCombo(cart));
          this.props.navigation.goBack();
        } catch (e) {
          const response = JSON.parse(e.message);

          const messages = _.get(response, 'mensagens', []).map(message => ({
            type: message.tipo,
            description: message.mensagem,
          }));

          if (response.mensagem) {
            messages.push({
              type: response.tipo,
              description: response.mensagem,
            });
          }

          this.setState({
            messages,
          });
        } finally {
          this.setState({
            applyingCombo: false,
          });
        }
      },
    );
  };

  getItemsInCart = () => {
    const { order } = this.props;
    const { combo } = this.state;
    const { payload } = order;

    let itemsInCart = [];
    combo?.groups?.map(group => {
      const { products } = group;
      const items = _.intersectionBy(payload.produtos, products, 'chave');
      itemsInCart = itemsInCart.concat(items);
    });

    return itemsInCart;
  };

  onRemoveCombo = async id => {
    this.setState(
      {
        applyingCombo: true,
      },
      () => {
        setTimeout(async () => {
          try {
            const cart = await CombosModule.removeCombo(id);

            Alert.alert(
              `${translate('comboRemoved')}`,
              `${translate('ComboSuccessfullyRemoved')}`,
              [
                {
                  text: 'OK',
                  onPress: async () => {
                    if (this.props.route.params.updateListCombos) {
                      this.props.route.params.updateListCombos();
                    }
                    this.props.dispatch(updateCombo(cart));
                    NavigationHelper.pop(this.props.componentId);
                  },
                },
              ],
              { cancelable: false },
            );
          } catch (e) {
            const response = JSON.parse(e.message);
            const messages = _.get(response, 'mensagens', []).map(message => ({
              type: message.tipo,
              description: message.mensagem,
            }));

            this.setState({
              messages,
            });
          } finally {
            this.setState({
              applyingCombo: false,
            });
          }
        }, 100);
      },
    );
  };

  onCollapsedMessage = () => {
    let { isCollapsedMessage } = this.state;
    isCollapsedMessage = !isCollapsedMessage;

    this.setState({
      isCollapsedMessage,
    });
  };

  onCollapsed = codGroup => {
    const newGroups = this.state?.combo?.groups || [];
    const index = newGroups.findIndex(gp => `${gp.id}` === `${codGroup}`);
    newGroups[index].isCollapsed = !newGroups[index].isCollapsed;

    this.setState(prevState => ({
      combo: { ...prevState.combo, groups: newGroups },
    }));
  };

  onPressObservations = product => {
    this.props.navigation.navigate(SCREEN_OBSERVATIONS.name, {
      product,
    });
  };

  onPressClickObservations = onPressHelper.debounce(this.onPressObservations);

  onPressConditionsSalesChild = product => {
    NavigationHelper.push(
      this.props.componentId,
      SCREEN_SALES_CONDITIONS_CHILD.name,
      SCREEN_SALES_CONDITIONS_CHILD.title,
      {
        product,
      },
    );
  };

  onPressClickConditionsSalesChild = onPressHelper.debounce(
    this.onPressConditionsSalesChild,
  );

  addItem = (item, quantity) => {
    const { order } = this.props;
    const { codcliente } = _.get(order, 'payload.condvenda', {});
    const itemCopy = Object.assign({}, item);

    const payloadAddItem = {
      clientCode: codcliente,
      itemKey: itemCopy.chave.toString(),
      item: {
        ...itemCopy,
        quantidade: quantity,
      },
    };
    this.props.dispatch(updateItemFetch(payloadAddItem));
  };

  fetchMoreProducts = () => {
    this.productsCategoriesPage += 1;
    this.setState(state => ({ loadingFetchMore: !state.loadingFetchMore }));
  };

  getDataInOrder(product) {
    const { chave } = product;
    const { payload } = this.props.order;
    const prod = _.find(payload.produtos, o => o.chave === chave);

    if (prod) {
      const { preco, quantidade } = prod;
      return { quantity: quantidade, price: preco };
    }

    return { quantity: 0, price: product.preco };
  }

  getDataProducts = products =>
    products.map(product => {
      const {
        chave,
        codigo,
        nome,
        menor_preco,
        estoque,
        descritivo,
        multiplo_venda,
        ean,
        tipo,
        html,
        unidade,
      } = product;

      const { configCached } = this.state;

      const { quantity, price } = this.getDataInOrder(product);
      const image = getSourceImageProduct(product, configCached);

      return {
        ...product,
        id: chave,
        codigo,
        name: nome,
        price,
        minPrice: menor_preco,
        stock: estoque,
        description: descritivo,
        quantity,
        multiple: multiplo_venda,
        ean,
        image,
        type: tipo,
        moreInfo: html,
        unity: unidade,
      };
    });

  getConfigCached = async () => {
    const value = await AsyncStorage.getItem('@objPayload');
    this.setState({ configCached: JSON.parse(value || '{}') });
  };

  async listCombDetail() {
    const combo = _.get(this.state, 'combo', {});
    const tamMemory = _.get(
      this.props.configLib,
      'payload.tam_memoria_paginacao',
    );

    await getListWithPagination(CombosModule.detailsCombPaginate, tamMemory, combo.id)
      .then(parsedData => {
        const productsCombo = _.filter(parsedData, {
          tipo: 'produto',
          coddesconto: combo.coddesconto,
        });
        const groupsCombo = _.groupBy(productsCombo, 'codgrupo');
        const groups = Object.keys(groupsCombo).map(groupId => {
          const group = groupsCombo[groupId][0];

          const products = groupsCombo[groupId].map(product => ({
            ...product,
            codproduto: product.codigo,
            chave:
              product.chave && product.chave.includes('|')
                ? product.chave.split('|')[1]
                : product.chave,
          }));

          return {
            id: groupId,
            title: group.descgrupo,
            products: this.getDataProducts(products),
            isCollapsed: true,
          };
        });
        this.refCombs = { ...combo, groups: [...groups] };
        this.setState({
          combo: { ...combo, groups: [...groups] },
        });
      })
      .finally(() => {
        this.setState({ applyingCombo: false });
      });
  }

  productClick = item => {
    this.props.navigation.navigate(SCREEN_PRODUCT_DETAIL.name, {
      addItem: this.addItem,
      product: item,
      pageDefault: 0,
    });
  };

  render() {
    const { configLib, order, currentProduct } = this.props;

    const {
      collapsed,
      isCollapsedMessage,
      applyingCombo,
      configCached,
      messages,
      combo,
    } = this.state;

    return (
      <ComboDetail
        cart={order || {}}
        messages={messages}
        combo={combo}
        collapsed={collapsed}
        isCollapsedMessage={isCollapsedMessage}
        onCollapsedMessage={this.onCollapsedMessage}
        onCollapsed={this.onCollapsed}
        onApplyCombo={this.onApplyCombo}
        applyingCombo={applyingCombo}
        onRemoveCombo={this.onRemoveCombo}
        addItem={this.addItem}
        itemUpdate={currentProduct.payload}
        actualSortValue="nome"
        fetchMoreProducts={this.fetchMoreProducts}
        config={configCached}
        configLib={configLib.payload}
        productClick={this.productClick}
        onSearch={this.onSearch}
        onPressObservations={this.onPressClickObservations}
        onPressConditionsSalesChild={this.onPressClickConditionsSalesChild}
      />
    );
  }
}

ComboDetailContainer.propTypes = {
  combo: PropTypes.objectOf(PropTypes.any),
  updateListCombos: PropTypes.func,
  componentId: PropTypes.string,
  order: PropTypes.objectOf(PropTypes.any),
  currentProduct: PropTypes.objectOf(PropTypes.any),
  configLib: PropTypes.objectOf(PropTypes.any),
  dispatch: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    order: state.orderFetch,
    currentProduct: state.productCurrent,
    config: state.configFetch,
    configLib: state.configLibFetch,
  };
}

export default connect(mapStateToProps)(ComboDetailContainer);
