/* @flow */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import uuidv4 from 'uuid/v4';
import CacheAccount from '~/controllers/Accounts/Cache/CacheAccount';
import AnswerController from '~/controllers/AnswerController';

export const SAVE_PHOTO_ANSWER_FETCH_REQUEST =
  'SAVE_PHOTO_ANSWER_FETCH_REQUEST';
export const SAVE_PHOTO_ANSWER_FETCH_SUCCESS =
  'SAVE_PHOTO_ANSWER_FETCH_SUCCESS';
export const SAVE_PHOTO_ANSWER_FETCH_FAILURE =
  'SAVE_PHOTO_ANSWER_FETCH_FAILURE';

const fakePromise = async data => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(data);
        }, 1000);
    });
};  

const getUserId = async () => {
  const account = await new CacheAccount().getDataAccount();
  return account?.user?.id;
};

const savePhotoAnswerFetch = async data => {
  const dateNow = new Date().toISOString();
  const userId = await getUserId();

  const answerObj = {
    deleted: false,
    created_at: dateNow,
    updated_at: dateNow,
    deleted_at: null,
    end_date: dateNow,
    extra_info: {},
    created_by: userId,
    start_date: data.dateOpen,
    origin_date: moment(data.dateSelected).unix(),
    payload: data.payload,
    user_task_list: data.userTaskList,
    task: data.taskId,
    sync_status: 'pending_to_sync',
  };
  const photoAnswersObj = [];
  data.payload.photo.forEach(photoAnswer => {
    const uuidAnswer = uuidv4();
    photoAnswersObj.push({
      ...answerObj,
      id: uuidAnswer,
      payload: photoAnswer?.uri,
    });
  });

  const resp = await fakePromise(photoAnswersObj);
  return resp;
};

export const savePhotoAnswer = createAsyncThunk(
  'savePhotoAnswer/fetch',
  async payload => {
    let data = '';
    try {
      data = await savePhotoAnswerFetch(payload);
    } catch (error) {
      console.tron.log(
        'PE_APP | file: slice.js:62 | SAVE_PHOTO_ANSWER_FETCH_FAILURE =>',
        error.message,
      );
      throw error;
    }
    return data;
  },
);

const initialState = {
  type: '',
  loading: false,
  erro: false,
  payload: [],
};

const savePhotoAnswerSlice = createSlice({
  name: 'savePhotoAnswer',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(savePhotoAnswer.pending, state => {
      state.type = SAVE_PHOTO_ANSWER_FETCH_REQUEST;
      state.loading = true;
    });
    builder.addCase(savePhotoAnswer.fulfilled, (state, action) => {
      state.type = SAVE_PHOTO_ANSWER_FETCH_SUCCESS;
      state.loading = false;
      state.erro = false;
      state.payload = action.payload;
    });
    builder.addCase(savePhotoAnswer.rejected, (state, action) => {
      state.type = SAVE_PHOTO_ANSWER_FETCH_FAILURE;
      state.loading = false;
      state.erro = true;
      state.payload = action.payload;
    });
  },
});

const { reducer } = savePhotoAnswerSlice;

export default reducer;
