import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
} from 'react-native';
import Touchable from 'react-native-platform-touchable';
import style from '~/common/style';
import Images from '@assets/Images';
import types from '../types';
import moment from 'moment';
import * as config from '../../../config/config';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  textSelected: {
    color: '#000',
    marginVertical: 8,
    marginLeft: 16,
    fontSize: 16,
  },
  textTitle: {
    color: '#979797',
    fontSize: 12,
    marginLeft: 16,
    marginTop: 6,
  },
  errorText: {
    color: 'red',
    fontSize: 12,
    marginLeft: 16,
    marginVertical: 6,
  },
  container: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: '#f5f5f5',
  },
  iconMenuDown: {
    width: 24,
    height: 24,
    margin: 8,
    tintColor: 'gray',
  },
});

class DateTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iosMode: 'datetime',
      showPickerIOS: false,
      iosDate: new Date(),
      androidDate: '',
      androidHour: '',
      error: '',
      displayModal: false,
      selectedDate: props.value || '',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value && nextProps.value !== '') {
      this.setState({ selectedDate: nextProps.value });
    }
  }

  onPress = () => {
    this.setState({ displayModal: true });
  };

  getIcon() {
    switch (this.props.type) {
      case types.DATE.name:
        return Images.iconCalendar;
      case types.DATE_HOUR.name:
        return Images.iconCalendarClock;
      case types.HOUR.name:
        return Images.iconClock;
      default:
        return Images.iconCalendarClock;
    }
  }


  check() {
    if (
      this.props.required &&
      (this.state.selectedDate === '' || this.state.selectedDate === undefined)
    ) {
      if (this.props.onBlur) {
        this.props.onBlur(true, this.props.field);
      }
      this.setState({ error: 'Esse campo é obrigatório' });
    } else {
      if (this.props.onBlur) {
        this.props.onBlur(false, this.props.field);
      }
      this.setState({ error: '' });
    }
  }

  setDateState = (selectedDate) => {
    const parseDate = moment(selectedDate).format('DD/MM/YYYY');
    const dateToState = selectedDate.split('/').reverse().join('-');
    const newState = {
      selectedDate: dateToState
    };

    if (this.props.required && (selectedDate === '' || selectedDate === undefined)) {
      if (this.props.onBlur) {
        this.props.onBlur(true, this.props.field);
      }
      newState['error'] = 'Esse campo é obrigatório';
    } else {
      if (this.props.onBlur) {
        this.props.onBlur(false, this.props.field);
      }
      newState['error'] = '';
    }

    this.setState(newState)
    this.props.onChangeValue(this.props.field, parseDate);
  }

  valueDisplay = (value) => {
    if (!value) {
      return '';
    }
    const date = value?.split('/').reverse().join('-');
    return date;
  }





  render() {
    const styleAllRadius = {
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      borderBottomLeftRadius: 5,
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderColor: '#ddd',
    };
    const active = {
      borderWidth: 2,
      borderColor: config.Colors.primaryColor,
    };
    const titleActive = {
      color: config.Colors.primaryColor,
    };

    const { outline, required, title } = this.props;
    const containerStyle = [styles.container, this.props.style];
    const textStyle = [styles.textTitle];
    if (outline) {
      containerStyle.push(styleAllRadius);
    }
    return (
      <View style={containerStyle}>
          <View>
            <Text style={textStyle}>{required ? `${title || ''} *` : title || ''}</Text>
            <View
              style={[
                style.spaceBetween,
              ]}
            >
              <input
              style={{
                backgroundColor: '#f9f9f9',
                color: '#333',
                fontSize: '16px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '10px',
                width: '100%',
              }}
                type="date"
                pattern="\d{2}/\d{2}/\d{4}"
                required={this.props.required}
                value={this.valueDisplay(this.state.selectedDate ? this.state.selectedDate : this.props.initialValue)}
                onChange={(e) => this.setDateState(e.target.value)}
                onBlur={() => this.check()}
              />
            </View>
            {this.state.error !== '' && (
              <View>
                <Text style={styles.errorText}>{this.state.error}</Text>
              </View>
            )}
          </View>
      </View>
    );
  }
}

DateTime.propTypes = {
  style: PropTypes.any,
  type: PropTypes.string,
  value: PropTypes.string,
  initialValue: PropTypes.string,
  title: PropTypes.string,
  outline: PropTypes.bool,
  onChangeValue: PropTypes.func,
  field: PropTypes.string,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  editable: PropTypes.bool,
};

export default DateTime;
