import ClientModule from '../../../modules/ClientsModule';

class RegisterAgainLib {
  sendDataForm(dataForm, clientKey, gpsConfig, gpsLocation) {
    const { requiredCollectGpsPoint = false } = gpsConfig || {};
    const { latitude = 0, longitude = 0 } = gpsLocation || {};
    const stringJsonDataForm = JSON.stringify(dataForm);

    if (requiredCollectGpsPoint && (!latitude && !longitude)) {
      const errMsg = [{ mensagem: 'Coleta de ponto obrigatória' }];
      throw JSON.stringify(errMsg);
    }

    const location = gpsLocation ? JSON.stringify(gpsLocation) : '';

    return new Promise((resolve, reject) => {
      ClientModule.getReRegisterClient(
        '',
        clientKey,
        stringJsonDataForm,
        location,
        resolve,
        reject,
      );
    });
  }

  saveEventOpenRegisterAgain(pkey, location) {
    return new Promise((resolve, reject) => {
      ClientModule.saveEventInRegisterAgain(pkey, location, resolve, reject);
    });
  }

  getParametersRegisterAgain(clientKey) {
    return new Promise((resolve, reject) => {
      ClientModule.parametersRegisterAgainClient(
        clientKey,
        resolve,
        reject,
      );
    });
  }
}

export default new RegisterAgainLib();
