/* @flow */

import React from 'react';
import Touchable from 'react-native-platform-touchable';
import { StyleSheet, Text, View } from 'react-native';
import { color } from '~/common';
import { fonts } from '../../utils/Fonts';
import translate from '../../locales';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    flex: 1,
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: color.grayContainer,
  },
  card: {
    backgroundColor: '#fff',
    padding: 12,
    paddingVertical: 16,
    minHeight: 20,
    flex: 1,
  },
  code: {
    color: '#929292',
    fontSize: 14,
    fontFamily: fonts.QuicksandBold,
  },
  name: {
    color: '#000',
    fontSize: 16,
    fontFamily: fonts.QuicksandBold,
  },
  label: {
    color: '#929292',
    fontSize: 13,
    fontFamily: fonts.Quicksand,
  },
  data: {
    color: '#929292',
    fontSize: 13,
    fontFamily: fonts.QuicksandBold,
    marginLeft: 2,
  },
});

function getStatus(cod, statusProspects) {
  return statusProspects.find(sp => sp.codstatus === cod);
}

const ProspectItem = ({
  item, index, onPressItem, statusProspects,
}) => {
  if (!item) return null;

  const statusProspectSelected = getStatus(item.situacao, statusProspects);

  return (
    <View>
      <Touchable
        style={styles.container}
        foreground={Touchable.SelectableBackground()}
        onPress={() => {
          onPressItem(item, index);
        }}
      >
        <View style={styles.card}>
          <View style={{ flex: 1, marginRight: 8 }}>
            {item.s_codprospeccao ? <Text style={styles.code}>{item.s_codprospeccao}</Text> : null}
            <Text style={styles.name}>{item.nome}</Text>
            <View style={{ flexDirection: 'row', flex: 1, flexWrap: 'wrap' }}>
              <Text style={styles.label}>{`${translate('reason')}:`}</Text>
              <Text style={styles.data}>{item.nome_fantasia}</Text>
            </View>
            {statusProspects && statusProspectSelected ? (
              <View style={{ flexDirection: 'row', flex: 1 }}>
                <Text style={styles.label}>{`${translate('status')}:`}</Text>
                <Text style={[styles.data, { color: statusProspectSelected.cor }]}>
                  {statusProspectSelected.descricao}
                </Text>
              </View>
            ) : null}
            <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
              <View
                style={{
                  flexDirection: 'row',
                  flex: 1,
                  width: 170,
                  marginRight: 10,
                  flexWrap: 'wrap',
                }}
              >
                <Text style={styles.label}>CNPJ/CPF:</Text>
                <Text style={styles.data}>{item.cnpj_cpf}</Text>
              </View>
            </View>
            <View style={{ flexDirection: 'row', flex: 1 }}>
              <Text style={styles.label}>{`${translate('contact')}`}</Text>
              <Text style={styles.data}>{item.nome_contato} </Text>
            </View>
          </View>
        </View>
      </Touchable>
    </View>
  );
};

ProspectItem.propTypes = {
    index: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
    statusProspects: PropTypes.array.isRequired,
    onPressItem: PropTypes.func.isRequired,
};

export default ProspectItem;
