import React,  {useMemo, useState } from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import { color } from '~/common';
import { fonts } from '~/utils/Fonts';
import Images from '../../../../assets/Images';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import atalhosConfig from '../../../../atalhos';

const app = {
    borderRadius: 10,
    width: 70,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 8,
    padding: 10,
};

const appImage = {
    width: 35,
    height: 35,
    resizeMode: 'contain',
};

const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        borderRadius: 100,
        overflow: 'visible',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        width: 84,
        height: 84,
        overflow: 'visible',
    },
    appDefault: {
        ...app,
        backgroundColor: '#fff',
    },
    appHighLight: {
        ...app,
        backgroundColor: color.primaryColorLight,
    },
    imageDefault: {
        ...appImage,
        tintColor: color.primaryColorLight,
    },
    imageHighLight: {
        ...appImage,
        tintColor: '#fff',
    },
    title: {
        fontSize: 12,
        textAlign: 'center',
        color: color.grayDark,
        fontFamily: fonts.QuicksandBold,
        fontWeight: 'bold',
        alignSelf: 'center',
        width: 80,
    },
    badge: {
        backgroundColor: 'red',
        borderRadius: 20,
        width: 18,
        height: 18,
        position: 'absolute',
        top: 4,
        right: 4,
        justifyContent: 'center',
        alignItems: 'center',
    },
    badgeText: {
        color: '#fff',
        fontSize: 12,
    },
    text: {
        fontSize: 18,
    },
    underline: {
        textDecorationLine: 'underline',
        fontWeight: 'bold',
    },
});

const useNormalizedDataShortcuts = (shortcuts, label) => {
    const labelRemovedAccents = label.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    const result = useMemo(() => {
        if (!shortcuts || !shortcuts[labelRemovedAccents]) return null;
        const res = getKey(shortcuts[labelRemovedAccents]?.keys);
        return res;
    }, [shortcuts, label]);

    return result;
};

const getKey = (keys) => {
    const parts = keys?.split('+');
    // console.log('PARTS', parts);
    if (parts?.length > 1) {
        return parts[1];
    }
    return null;
}

const UnderlineText = ({ text, targetLetter }) => {
    const index = text?.toLowerCase().indexOf(targetLetter?.toLowerCase()); // Busca case-insensitive

    if (index === -1) {
        // Se a letra não for encontrada, retorna o texto inteiro sem sublinhado.
        return <Text style={styles.title}>{text}</Text>;
    }

    // Divide o texto em três partes: antes, a letra sublinhada, e depois.
    const before = text.slice(0, index);
    const highlighted = text[index];
    const after = text.slice(index + 1);

    return (
        <Text style={styles.title}>
            {before}
            <Text style={styles.underline}>{highlighted}</Text>
            {after}
        </Text>
    );
};


const App = props => {
    const [wordEmphasis, setWordEmphasis] = useState(null);
    const [label,] = useState(props.props.item.label);
    const stateConfig = useSelector(state => state.configLibFetch?.payload?.configuracoes);
    const atalhos = stateConfig?.shortcuts?.screens['Home']?.atalhos || atalhosConfig.screens['Home']?.atalhos;
    // console.log('CONFIG SHORTCUTS atalhos:', atalhos);
    /* const navigation = useNavigation();
    const [showPopup, setShowPopup] = useState(false);

    const handleKeyDown = (event) => {
        if (event.altKey) {
            setShowPopup(prevShowPopup => !prevShowPopup); // Inverte o valor de showPopup
        }
    }; */

    /* useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        // Adiciona o listener para 'focus'
        const subscribeFocus = navigation.addListener('focus', () => {
          console.log('FOCUS');
          window.addEventListener('keydown', handleKeyDown);
        });
    
        // Adiciona o listener para 'blur'
        const subscribeBlur = navigation.addListener('blur', () => {
          console.log('BLUR');
          window.removeEventListener('keydown', handleKeyDown);
        });
    
        // Remove os listeners ao desmontar o componente
        return () => {
          subscribeFocus();
          subscribeBlur();
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, [navigation]); */

    // console.log('PROPS SHORTCUTS', atalhos);

    const shortcut = useNormalizedDataShortcuts(atalhos, props.props.item.label);

    return (
        <>
            {/* <View style={{
                position: 'absolute',
                top: 9,
                left: 0,
                right: 0,
                backgroundColor: 'transparent',
                alignItems: 'center',
            }}>
                <Popup visible={showPopup} message={shortcut?.keys} />
            </View> */}
            <View
                style={{
                    marginBottom: 20,
                    paddingTop: 50,
                    flex: 1,
                    alignItems: 'stretch',
                }}>
                <Touchable
                    // style={{ flex: 1 }}
                    onPress={() => {
                        props.onPress(
                            props.props.item.id,
                            props.props.item.title,
                            props.props.item.tabIndex,
                            props,
                        );
                    }}>
                    <View style={styles.mainContainer}>
                        <View style={styles.container}>
                            <View
                                style={[
                                    props.props.item.is_highlight
                                        ? styles.appHighLight
                                        : styles.appDefault,
                                ]}
                            >
                                <Image
                                    style={[
                                        props.props.item.is_highlight
                                            ? styles.imageHighLight
                                            : styles.imageDefault,
                                    ]}
                                    source={props.props.item.screen === 'expenses' ? Images.iconWallet : props.props.item.image}
                                />
                            </View>
                            {props.props.item.badgeText && props.props.item.badgeText !== '' && (
                                <View style={styles.badge}>
                                    <Text style={styles.badgeText}>{props.props.item.badgeText}</Text>
                                </View>
                            )}
                        </View>
                    </View>
                </Touchable>
                <UnderlineText text={label} targetLetter={shortcut} />
            </View>
        </>
    );
};

App.propTypes = {
    props: PropTypes.objectOf(PropTypes.any),
    id: PropTypes.string,
    tabIndex: PropTypes.number,
    title: PropTypes.string,
    label: PropTypes.string,
    badgeText: PropTypes.string,
    image: PropTypes.number,
    is_highlight: PropTypes.bool,
    onPress: PropTypes.func,
    shortcuts: PropTypes.array,
    showShortcuts: PropTypes.bool,
};

export default App;
