// @flow

import React from 'react';

import NumberValue from './NumberValue';

import PropTypes from 'prop-types';

const CalculatedValue = ({
  value = 0,
  style = {},
}) => (

  <NumberValue
    type="calculated"
    value={value}
    style={style}
  />

);

CalculatedValue.propTypes = {
    value: PropTypes.string.isRequired,
    style: PropTypes.object,
};

export default CalculatedValue;
