import { StyleSheet } from 'react-native';

import color from '../../../common/color';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  buttonMinus: {
    flex: 1,
    borderTopStartRadius: 18,
    borderBottomStartRadius: 18,
    backgroundColor: color.primaryColor,
    height: 36,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: color.primaryColor,
    borderWidth: 1,
  },
  buttonPlus: {
    flex: 1,
    borderTopEndRadius: 18,
    borderBottomEndRadius: 18,
    backgroundColor: '#fff',
    height: 36,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: color.primaryColor,
    borderWidth: 1,
  },
  iconButton: {
    width: 20,
    height: 20,
    tintColor: color.primaryColor,
  },
});

export default styles;
