/* @flow */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ServicesModule from '../../modules/ServicesModule';
import ConfigurationModule from '../../modules/ConfigurationModule';

import CacheDataController from '~/utils/CacheDataController';

export const CONFIG_LIB_REQUEST = 'CONFIG_LIB_REQUEST';
export const CONFIG_LIB_SUCCESS = 'CONFIG_LIB_SUCCESS';
export const CONFIG_LIB_FAILURE = 'CONFIG_LIB_FAILURE';

const CACHE_KEY = '@CONFIG_LIB';

let initialState = {
  type: '',
  loading: false,
  erro: false,
  payload: { tempo_intervalo_sincronismo: 300, ultima_sincronizacao: '' },
  modules: [],
};

const getConfig = () =>
  new Promise((resolve, reject) =>
    ConfigurationModule.getConfigurations(resolve, reject),
  );

const parseConfig = async config => {
  const listagem_campos_visibilidade_object = {};
  let { listagem_campos_visibilidade } = config;

  listagem_campos_visibilidade = listagem_campos_visibilidade
    ? JSON.parse(listagem_campos_visibilidade)
    : undefined;

  if (Array.isArray(listagem_campos_visibilidade)) {
    listagem_campos_visibilidade.forEach(list => {
      const {
        context,
        hidden_fields,
        show_fields,
        days_before,
        days_after,
      } = list;

      const showIsProcessDone = list && list.showIsProcessDone;

      if (context) {
        const hidden_fields_object = {};
        if (hidden_fields) {
          hidden_fields.forEach(field => (hidden_fields_object[field] = true));
        }

        const show_fields_object = {};
        if (show_fields) {
          show_fields.forEach(field => (show_fields_object[field] = true));
        }

        listagem_campos_visibilidade_object[context] = {
          ...list,
          hidden_fields: hidden_fields_object,
          show_fields: show_fields_object,
        };

        if (context === 'itinerary') {
          listagem_campos_visibilidade_object[context] = {
            ...listagem_campos_visibilidade_object[context],
            ...{ days_before, days_after, showIsProcessDone },
          };
        }
      }
    });

    listagem_campos_visibilidade = listagem_campos_visibilidade_object;
  }

  return { ...config, listagem_campos_visibilidade };
};

const restartServiceSync = async (configLibState, configLib) => {
  if (
    configLibState.tempo_intervalo_sincronismo !==
    configLib.tempo_intervalo_sincronismo
  ) {
    ServicesModule.stopSyncService();
    ServicesModule.startSyncService(configLib.tempo_intervalo_sincronismo);
  }

  return configLib;
};

export const configFetch = createAsyncThunk(
  'configLibFetch/configFetch',
  async (_, { getState }) => {
    const state = getState();
    let resp = {};
    try {
      const configLib = await getConfig();
      resp = await parseConfig(JSON.parse(configLib));
      CacheDataController.saveData(CACHE_KEY, resp);
      restartServiceSync(state.configLibFetch.payload, resp);
    } catch ({ message }) {
      console.tron.log(
        'PE_APP | file: slice.js:120 | configLibFetch err =>',
        message,
      );
    }
    return resp;
  },
);

export const configLibFetchSlice = createSlice({
  name: 'configLibFetch',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(configFetch.pending, (state, action) => {
      state.type = CONFIG_LIB_REQUEST;
      state.loading = true;
      state.erro = false;
    });
    builder.addCase(configFetch.fulfilled, (state, action) => {
      const { payload } = action;
      state.type = CONFIG_LIB_SUCCESS;
      state.loading = false;
      state.erro = false;
      state.payload = payload;
    });
    builder.addCase(configFetch.rejected, (state, action) => {
      state.type = CONFIG_LIB_FAILURE;
      state.loading = false;
      state.erro = true;
    });
  },
});

const { reducer } = configLibFetchSlice;

export default reducer;
