import React, { Component } from 'react';

import _ from 'lodash';
import { connect } from 'react-redux';

import {
  SALE_OPPORTUNITY_FAILURE,
  SALE_OPPORTUNITY_SUCCESS,
  listSalesOpportunity,
} from '../../store/salesOpportunityFetch/slice';

import SalesOpportunity from '../SalesOpportunity/SalesOpportunity';

import { SCREEN_DETAIL_OPPORTUNITY } from '../screens';
import NavigationHelper from '../NavigationHelper';
import onPressHelper from '~/utils/onPressHelper';

import PropTypes from 'prop-types';

class SalesOpportunityContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

    this.salesOpportunityList = [];
  }

  async UNSAFE_componentWillMount() {
    this.props.dispatch(listSalesOpportunity());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    (() => {
      if (
        nextProps.salesOpportunity.type === this.props.salesOpportunity.type
      ) {
        return;
      }
      if (nextProps.salesOpportunity.type === SALE_OPPORTUNITY_SUCCESS) {
        const { payload } = nextProps.salesOpportunity;
        if (_.isArray(payload)) {
          this.salesOpportunityList = _.map(payload, optn => {
            const jItens = _.get(optn, 'j_itens', '{}');
            const jItensParsed = JSON.parse(jItens);
            const arrProdutos = _.get(jItensParsed, 'produtos', []);

            return {
              ...optn,
              j_cliente: JSON.parse(optn.j_cliente),
              j_condvendas: JSON.parse(optn.j_condvendas),
              arr_produtos: arrProdutos,
            };
          });
        }
        this.setState({ loading: false });
      }
      if (nextProps.salesOpportunity.type !== SALE_OPPORTUNITY_FAILURE) {
        return;
      }
      this.setState({ loading: false });
    })();
  }

  onPress = saleOpportunityItem => {
    NavigationHelper.push(
      this.props.componentId,
      SCREEN_DETAIL_OPPORTUNITY.name,
      SCREEN_DETAIL_OPPORTUNITY.title,
      {
        saleOpportunityDetail: saleOpportunityItem,
        loading: true,
      },
    );
  };

  onPressSaleOpportunityItem = onPressHelper.debounce(this.onPress);

  render() {
    const { loading } = this.state;
    return (
      <SalesOpportunity
        data={this.salesOpportunityList}
        loading={loading}
        onPress={this.onPressSaleOpportunityItem}
      />
    );
  }
}

SalesOpportunityContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    configLib: PropTypes.object.isRequired,
    salesOpportunity: PropTypes.object.isRequired,
    navigator: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    salesOpportunity: state.salesOpportunityFetch,
    configLib: state.configLibFetch,
  };
}

export default connect(mapStateToProps)(SalesOpportunityContainer);
