/* @flow */

import React, { Component } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';

import Item from './Item';

class Table extends Component {
  static Item = Item;

  getItems() {
    const { values, noColor, style } = this.props;
    return values.map((value, index) => (value === null ? value : (
      <Item
        style={style}
        noColor={noColor}
        value={value}
        key={value.label}
        index={index}
        money={value.money}
        moneyPositiveOrNegative={value.moneyPositiveOrNegative}
      />
    )));
  }

  render() {
    return (
      <View>
        {this.getItems()}
      </View>
    );
  }
}

Table.propTypes = {
  values: PropTypes.array.isRequired,
  style: PropTypes.object,
  noColor: PropTypes.bool,
};

export default Table;
