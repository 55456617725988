/* @flow */

import React, { PureComponent } from 'react';
import { View, Text, StyleSheet, TextInput } from 'react-native';
import Slider from 'react-native-slider';
import Section from './Section';
import { color } from '~/common';
import Masked from 'vanilla-masker';
import types from '~/components/Form/types';
import currencyFormatter from 'currency-formatter';
import translate from '../../../locales';

type Props = {
  title: string,
  minValue: number,
  maxValue: number,
  value?: number,
  type: types.MONEY | types.NUMBER | types.INT,
  onChange(value: number): (value: number) => void,
};

const moneyFormatter = {
  precision: 2,
  separator: ',',
  delimiter: '.',
  unit: '',
};

const currencyFormatterConfig = { locale: 'pt-BR' };

const styles = StyleSheet.create({
  valueSlider: {
    fontSize: 12,
    color: '#000',
  },
  sliderContainer: {
    backgroundColor: '#ebebeb',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    marginTop: 10,
  },
});

class NumberFilter extends PureComponent<Props> {
  constructor(props) {
    super(props);

    const value = props.value || props.maxValue || props.minValue || 0;
    this.state = {
      value,
      value2: this.formatMoney(value),
    };
  }

  onTextChange = value => {
    const char = value.charAt(0);

    let valueFormart = Masked.toMoney(value, moneyFormatter);
    if (char === '-') {
      valueFormart = `${char}${valueFormart}`;
    }
    const valueFloat = currencyFormatter.unformat(
      valueFormart,
      currencyFormatterConfig,
    );

    if (
      valueFloat <= this.props.maxValue &&
      valueFloat >= this.props.minValue
    ) {
      if (this.props.onChange) {
        this.props.onChange(valueFloat);
      }

      this.setState({
        value: valueFloat,
        value2: this.formatMoney(valueFloat),
      });
    }
  };

  onSliderChange = value => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }

    this.setState({ value, value2: this.formatMoney(value) });
  };

  formatMoney(value) {
    return currencyFormatter.format(value.toFixed(2), currencyFormatterConfig);
  }

  render() {
    return (
      <Section title={this.props.title}>
        <Text style={[styles.valueSlider, { marginTop: 5 }]}>
          {`${translate('upUntil')}`} {this.formatMoney(this.state.value)}
        </Text>
        <View style={styles.sliderContainer}>
          <Slider
            style={{ marginTop: 18 }}
            maximumValue={this.props.maxValue}
            minimumValue={this.props.minValue}
            value={this.state.value}
            minimumTrackTintColor={color.primaryColor}
            thumbTintColor={color.primaryColor}
            onValueChange={this.onSliderChange}
          />
          <TextInput
            style={{ marginLeft: 15, marginBottom: 5 }}
            keyboardType="numeric"
            ref={ref => {
              this.input = ref;
            }}
            containerStyle={{ marginBottom: -8 }}
            value={this.state.value2}
            onChangeText={this.onTextChange}
          />
        </View>
      </Section>
    );
  }
}

export default NumberFilter;
