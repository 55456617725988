// ATENÇÃO: Esses métodos não devem estar implementados no react native. Fizemos
// isso temporariamente enquanto a atividade de configurações não é priorizada.

export function emp2long(value) {
  let ov = 0;
  if (value.length === 4) {
    for (let i = 0; i < value.length; ++i) {
      const x = value.charAt(i);
      ov <<= 8;
      ov |= x.charCodeAt(0);
    }
  }
  return ov;
}

export const getListWithPagination = async (libMethod, offset = 0, ...methodParams) => {
  return new Promise(async (resolve, reject) => {
    const pagination = { page: 0, offset, active: true };
    let data = '';

    try {
      while (pagination.active) {
        let resp;
        if (methodParams) {
          resp = await libMethod(...methodParams, JSON.stringify(pagination));
        } else {
          resp = await libMethod(JSON.stringify(pagination));
        }

        data = `${data}${resp}`;
        if (!(resp.length <= 0)) {
          pagination.active = false;
        }

        pagination.page += 1;
      }

      const finalData = typeof data === 'string' ? JSON.parse(data) : data;
      resolve(finalData);
    } catch (error) {
      reject(error.message);
    }
  });
};
