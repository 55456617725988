import axios from 'axios';
import CachePolistore from '~/controllers/Accounts/Cache/CachePolistore';
import translate from '~/locales';
import { parseStrToFloat } from '~/utils/Currency';

const getURLAndToken = async () => {
  let url = '';
  let token = '';

  const [paramSyncon, paramsSignIn, paramsLogin] = await Promise.all([
    JSON.parse((await new CachePolistore().getParamSyncon()) || '{}'),
    JSON.parse((await new CachePolistore().getParamsSignIn()) || '{}'),
    JSON.parse((await new CachePolistore().getLoginData()) || '{}'),
  ]);

  const hash = paramSyncon?.cnx?.hash ?? '';
  const codmaq = paramsSignIn?.rca_codmaq ?? '';
  const companyCode = paramsLogin?.empresa ?? '';

  url = paramSyncon?.cnx?.addr ?? '';
  token = `${codmaq}${companyCode}${hash}`;

  return {
    url,
    token,
  };
};

const get = async (endpoint, params) => {
  try {
    const { url, token } = await getURLAndToken();
    const { data } = await axios.get(
      `https://${url}/api/${endpoint}?${params || ''}&token=${token}`,
    );
    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export async function listExpenses(dateInterval = {}) {
  try {
    const { start, end } = dateInterval;
    let params = null;
    if (start && end) {
      params = `start_date=${start}&end_date=${end}`;
    }
    return await get('journal-travel/my-expenses', params);
  } catch (err) {
    throw new Error(err.message);
  }
}

export async function listExpensesTypes() {
  try {
    return await get('journal-travel/expense-types');
  } catch (err) {
    throw new Error(err.message);
  }
}

export async function createExpense(form) {
  try {
    const formData = new FormData();

    for (const key of Object.keys(form)) {
      if (key === 'photos') {
        formData.append('attachments[]', form[key][0].uri);
      } else {
        let val = form[key];
        if (`${val}`.includes(translate('currencySymbol'))) {
          val = parseStrToFloat(`${val}`.replace(translate('currencySymbol')));
        }
        formData.append(key, val);
      }
    }

    const { url, token } = await getURLAndToken();

    const resp = await axios.post(
      `https://${url}/api/journal-travel/expense?token=${token}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return resp.data;
  } catch (error) {
    console.log('error', error);
    throw new Error(
      error?.response?.data?.message || `${translate('errorOnCreateExpense')}`,
    );
  }
}
