import AsyncStorage from '@react-native-async-storage/async-storage';
import { getSourceImageProduct } from './ProductUtil';
import getImageProd from './GetAllImagesProds';

const getConfigCached = async () => {
  let configCached = null;
  const value = await AsyncStorage.getItem('@objPayload');
  if (value) {
    configCached = JSON.parse(value);

    return configCached;
  }
  return configCached;
};

const getImageProds = async prods => {
  const configCached = await getConfigCached();
  const prodsWithImg =
    prods && prods.length > 0
      ? prods.map(product => {
          const image = getImageProd(product, configCached);
          return { ...product, image };
        })
      : getImageProd(prods, configCached);
  return prodsWithImg;
};

export default getImageProds;
