import React, { Component } from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import Touchable from 'react-native-platform-touchable';

import Images from '@assets/Images';
import style from '../../common/style';
import { fonts } from '../../utils/Fonts';
import translate from '../../locales';
import { CalculatedValue } from '~/../components_base/src/components';
import { color } from '~/common';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  bottomActions: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: 'row',
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textQuantity: {
    backgroundColor: color.primaryColorDarkest,
    padding: 3,
    paddingHorizontal: 8,
    borderRadius: 3,
    overflow: 'hidden',
    color: '#fff',
    fontFamily: fonts.QuicksandBold,
  },
  textFinish: {
    fontFamily: fonts.QuicksandBold,
    color: '#fff',
  },
});

class CartBottomButton extends Component {
  onPressCart = () => this.props.onPressCart();

  render() {
    const { payload } = this.props.order;

    const { produtos, pronta_entrega } = payload;
    const quantidade = payload && produtos ? produtos.length : 0;
    const total = payload.total || 0;

    return (
      <View style={{ flex: 1, paddingBottom: 60 }}>
        <View
          style={[
            styles.bottomActions,
            { borderTopWidth: 0.9, borderTopColor: color.grayAlmostWhite },
          ]}>
          <View style={{ flex: 1 }}>
            <View
              style={[
                style.spaceBetween,
                {
                  marginLeft: 12,
                  marginRight: 12,
                },
                !pronta_entrega && { marginVertical: 12 },
              ]}>
              <View style={[style.bottomAction]}>
                <View>
                  <Text
                    style={{
                      color: color.grayDark,
                      fontSize: 31,
                      fontWeight: '700',
                    }}>
                    {quantidade}
                  </Text>
                </View>
                <View style={{ marginLeft: 10 }}>
                  <Text
                    style={{
                      color: color.grayDark,
                      fontSize: 13,
                      fontWeight: 'bold',
                    }}>
                    Itens
                  </Text>
                  <Text
                    style={{
                      color: color.grayDark,
                      fontSize: 13,
                      fontWeight: 'bold',
                    }}>
                    adicionados
                  </Text>
                </View>
              </View>

              {/* <View
                style={[
                  { alignItems: 'center' },
                  pronta_entrega && { paddingBottom: 12 },
                ]}>
                {pronta_entrega && (
                  <Image
                    style={{ tintColor: '#FFF' }}
                    source={Images.iconTruck}
                  />
                )}
                <Text style={styles.textFinish}>{`${translate(
                  'viewCart',
                )}`}</Text>
              </View> */}

              <Touchable
                onPress={this.onPressCart}
                style={{
                  backgroundColor: color.primaryColor,
                  width: '50%',
                  padding: 12,
                  borderRadius: 4,
                }}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Image
                    source={Images.iconCart}
                    style={style.iconBottomAction}
                  />
                  <Text
                    style={{
                      color: '#fff',
                      marginLeft: 10,
                      fontWeight: '600',
                      fontSize: 15,
                    }}>
                    {`${translate('total')}: `}
                    <CalculatedValue
                      value={total}
                      style={[style.textBottomAction]}
                    />
                  </Text>
                </View>
              </Touchable>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

CartBottomButton.propTypes = {
    onPressCart: PropTypes.func.isRequired,
    order: PropTypes.object.isRequired,
};

export default CartBottomButton;
