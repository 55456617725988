import translate from '../../../locales';

import Sync from '../../../services/resources/Sync';
import AccountModule from '../../../modules/AccountsModule';
import CachePolistore from '../Cache/CachePolistore';
import CacheDataController from '../../../utils/CacheDataController';
import Snackbar from 'react-native-snackbar';

class LoginPolistore {
  constructor(username, password, dataAccount) {
    this.username = username;
    this.password = password;
    this.dataAccount = dataAccount;
    this.cachePolistore = new CachePolistore();
  }

  executeLogin() {
    const company = this.dataAccount.user.company.company_code.toLowerCase();
    const { username } = this.dataAccount.user;
    Sync.setDownloadComplete('false');

    return new Promise(async (resolve, reject) => {
      const deviceInfo = await CacheDataController.getData(
        '@Session:deviceInfo',
      );
      const uuid = deviceInfo && deviceInfo.uuid ? deviceInfo.uuid : '';
      const check = await this.checkBase();
      if (!check) {
        await Sync.setLastSerial('0');
      }

      AccountModule.iniciarLib(company, username, uuid, resolve, reject);
    })
      .then(() => this.loginlib(company))
      .catch(message => Promise.reject(message));
  }

  loginlib(empresa) {
    const loginLocal = this.loginLocal(empresa);

    return new Promise((resolve, reject) => {
      loginLocal
        .then(() => {
          this.loginServer(empresa, resolve, reject);
        })
        .catch(error => {
          const dataError = JSON.parse(error);
          if (dataError[0].codigo === 'base_nao_corresponde') {
            reject(dataError[0].mensagem);
          } else {
            this.loginServer(empresa, resolve, reject);
          }
        });
    });
  }

  loginLocal(empresa) {
    const promise = new Promise((resolve, reject) => {
      AccountModule.validadeLogin(
        empresa,
        this.username,
        this.password,
        resolve,
        reject,
      );
      // AccountModule.logout(resolve, reject);
    });

    return promise;
  }

  loginServer(empresa, resolve, reject) {
    new Promise((resolveP, rejectP) => {
      AccountModule.obtainParamsSyncon(
        empresa,
        this.username,
        this.password,
        resolveP,
        rejectP,
      );
    })
      .then(data => {
        this.cachePolistore.setLoginData(empresa, this.username, this.password);
        this.paramSingInSucess(data, resolve, reject);
      })
      .catch(error => {
        reject(error);
      });
  }

  paramSingInSucess(data, resolve, reject) {
    new Promise((resolveP, rejectP) => {
      AccountModule.whenParamSignInSuccess(data, resolveP, rejectP);
    })
      .then(dataParamSiginIn => {
        this.cachePolistore.setParamSyncon(data);
        this.callLoginAsyncTask(dataParamSiginIn, resolve, reject);
      })
      .catch(error => {
        reject(error);
      });
  }

  callLoginAsyncTask(data, resolve, reject) {
    if (data === '') {
      Snackbar.show({
        text: `${translate('nullOrInvalidCode')}`,
      });
      return;
    }
    new Promise((resolveP, rejectP) => {
      AccountModule.callLoginAsyncTask(data, resolveP, rejectP);
    })
      .then(dataParamSiginIn => {
        this.performSingInSucess(dataParamSiginIn, resolve, reject);
      })
      .catch(error => {
        reject(error);
      });
  }

  performSingInSucess(data, resolve, reject) {
    new Promise((resolveP, rejectP) => {
      AccountModule.whenPerformSignInSuccess(data, resolveP, rejectP);
    })
      .then(response => {
        this.cachePolistore.setParamsSignIn(response);
        resolve();
      })
      .catch(error => {
        const dataError = JSON.parse(error);
        reject(dataError[0].mensagem);
      });
  }

  async checkBase() {
    return true;
  }
}

export default LoginPolistore;
