import React from 'react';
import { Image, StyleSheet } from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { getFocusedRouteNameFromRoute } from '@react-navigation/native';

import ClientsStack from './clients.routes';
import ProfileStack from './profile.routes';
import TodayStack from './today.routes';
import HomeStack from './home.routes';
import OrdersStack from './orders.routes';

import color from '../../components_base/src/values/color';
import { fonts } from '~/utils/Fonts';
import Images from '@assets/Images';

const styles = StyleSheet.create({
  title: {
    fontFamily: `${fonts.QuicksandBold}`,
    fontSize: 12,
    fontWeight: 'bold',
  },
});

const Tab = createBottomTabNavigator();

function MyTabs() {
  return (
    <Tab.Navigator
      name="MyTabs"
      screenOptions={{
        headerShown: false,
        tabBarLabelStyle: {
          paddingBottom: 3,
          marginBottom: 5,
        },
        tabBarIconStyle: {
          paddingBottom: 5,
          marginBottom: 5,
        },
      }}
      tabBarOptions={{
        activeTintColor: color.primaryColor,
        tabStyle: styles.title,
        keyboardHidesTabBar: true,
        labelPosition: 'below-icon',
        // labelStyle: styles.labelStyle,
        style: { flexDirection: 'row-reverse' },
      }}>
      <Tab.Screen
        name="HomeStack"
        component={HomeStack}
        options={({ route }) => ({
          title: 'Home',
          tabBarLabel: 'Início',
          tabBarIcon: ({ color, size }) => (
            <Image source={Images.iconStoreFill} style={{ width: 25, height: 25 }} tintColor={color}/>
          ),
          tabBarStyle: (route => {
            const routeName = getFocusedRouteNameFromRoute(route) ?? '';
            if (
              routeName === 'Cart' ||
              routeName === 'ClientDetail' ||
              routeName === 'ProductDetail' ||
              routeName === 'Combos' ||
              routeName === 'ComboDetail' ||
              routeName === 'ShareOrder' ||
              routeName === 'OrderObservations' ||
              routeName === 'Messages' ||
              routeName === 'ConditionsSalesChid' ||
              routeName === 'ProductObservation' ||
              routeName === 'ProductTabs' ||
              routeName === 'Resume' ||
              routeName === 'Observations' ||
              routeName === 'ActionModal' ||
              routeName === 'OperationGroupActions' ||
              routeName === 'Categories' ||
              routeName === 'Finish'
            ) {
              return { display: 'none' };
            }
            return;
          })(route),
        })}
      />
      <Tab.Screen
        name="ItinenaryStack"
        component={TodayStack}
        options={({ route }) => ({
          title: 'Itinenary',
          tabBarLabel: 'Roteiro',
          tabBarIcon: ({ color, size }) => (
            <Image source={Images.appCalendar} style={{ width: 25, height: 25 }} tintColor={color}/>
          ),
          tabBarStyle: (route => {
            const routeName = getFocusedRouteNameFromRoute(route) ?? '';
            if (
              routeName === 'Cart' ||
              routeName === 'ClientDetail' ||
              routeName === 'Activities' ||
              routeName === 'activities.Location' ||
              routeName === 'activities.Grid' ||
              routeName === 'activities.GridForm' ||
              routeName === 'activities.Choice' ||
              routeName === 'activities.Photo' ||
              routeName === 'activities.FreeText' ||
              routeName === 'activities.Stopwatch' ||
              routeName === 'activities.DynamicForm' ||
              routeName === 'ProductDetail' ||
              routeName === 'Combos' ||
              routeName === 'ComboDetail' ||
              routeName === 'ShareOrder' ||
              routeName === 'OrderObservations' ||
              routeName === 'Messages' ||
              routeName === 'ConditionsSalesChid' ||
              routeName === 'ProductObservation' ||
              routeName === 'ProductTabs' ||
              routeName === 'Resume' ||
              routeName === 'Observations' ||
              routeName === 'ActionModal' ||
              routeName === 'OperationGroupActions' ||
              routeName === 'Categories' ||
              routeName === 'Finish'
            ) {
              return { display: 'none' };
            }
            return;
          })(route),
        })}
      />
      <Tab.Screen
        name="ClientesStack"
        component={ClientsStack}
        options={({ route }) => ({
          title: 'Clientes',
          tabBarLabel: 'Clientes',
          tabBarIcon: ({ color, size }) => (
            <Image source={Images.iconCustomers} style={{ width: 25, height: 25 }} tintColor={color}/>
          ),
          tabBarStyle: (route => {
            const routeName = getFocusedRouteNameFromRoute(route) ?? '';
            if (
              routeName === 'Cart' ||
              routeName === 'ClientDetail' ||
              routeName === 'Activities' ||
              routeName === 'activities.Location' ||
              routeName === 'activities.Grid' ||
              routeName === 'activities.GridForm' ||
              routeName === 'activities.Choice' ||
              routeName === 'activities.Photo' ||
              routeName === 'activities.FreeText' ||
              routeName === 'activities.Stopwatch' ||
              routeName === 'activities.DynamicForm' ||
              routeName === 'RegisterAgain' ||
              routeName === 'ProductDetail' ||
              routeName === 'Combos' ||
              routeName === 'ComboDetail' ||
              routeName === 'ShareOrder' ||
              routeName === 'OrderObservations' ||
              routeName === 'Messages' ||
              routeName === 'ConditionsSalesChid' ||
              routeName === 'ProductObservation' ||
              routeName === 'ProductTabs' ||
              routeName === 'Resume' ||
              routeName === 'Observations' ||
              routeName === 'ActionModal' ||
              routeName === 'OperationGroupActions' ||
              routeName === 'Categories' ||
              routeName === 'Finish'
            ) {
              return { display: 'none' };
            }
            return;
          })(route),
        })}
      />
      <Tab.Screen
        name="OrdersStack"
        component={OrdersStack}
        options={({ route }) => ({
          title: 'Orders',
          tabBarLabel: 'Pedidos',
          tabBarIcon: ({ color, size }) => (
            <Image source={Images.iconOrder} style={{ width: 25, height: 25 }} tintColor={color}/>
          ),
          tabBarStyle: (route => {
            const routeName = getFocusedRouteNameFromRoute(route) ?? '';
            if (
              routeName === 'Cart' ||
              routeName === 'ClientDetail' ||
              routeName === 'ProductDetail' ||
              routeName === 'Combos' ||
              routeName === 'ComboDetail' ||
              routeName === 'ShareOrder' ||
              routeName === 'OrderObservations' ||
              routeName === 'Messages' ||
              routeName === 'ConditionsSalesChid' ||
              routeName === 'ProductObservation' ||
              routeName === 'ProductTabs' ||
              routeName === 'Resume' ||
              routeName === 'Observations' ||
              routeName === 'ActionModal' ||
              routeName === 'OperationGroupActions' ||
              routeName === 'Categories' ||
              routeName === 'Finish'
            ) {
              return { display: 'none' };
            }
            return;
          })(route),
        })}
      />
      <Tab.Screen
        name="ProfileStack"
        component={ProfileStack}
        options={{
          title: 'Profile',
          tabBarLabel: 'Perfil',
          tabBarIcon: ({ color, size }) => (
            <Image source={Images.iconAccount} style={{ width: 25, height: 25 }} tintColor={color}/>
          ),
        }}
      />
    </Tab.Navigator>
  );
}

export default MyTabs;
