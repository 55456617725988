import React from 'react';
import { Text, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { Popable } from 'react-native-popable';
import PropTypes from 'prop-types';

import translate from '~/locales';

import styles from './styles';

const CategoryItem = ({ item, index, onPressItem }) => {

    const checkTitle = (item, index) => {
        if (item?.title.length > 35) {
            return (
                <View style={styles.containerCardTitle}>
                    <Text style={styles.cardCatTitleMore} numberOfLines={2} onPress={() => onPressItem({ ...item, categoryIndex: index })}>
                        {item?.title.substring(0, 30) + '...'}
                    </Text>
                    <Popable
                        style={styles.popable}
                        content={
                            <Text style={styles.contentPopable}>{item?.title}</Text>
                        } position="top">
                        <View style={styles.btnMore}>
                            <Text style={styles.textBtnMore}>{translate('moreTitleCat')}</Text>
                        </View>
                    </Popable>
                </View>
            )
        }
        return (
            <Text style={styles.cardCatTitleNoMore} numberOfLines={2} onPress={() => onPressItem({ ...item, categoryIndex: index })}>
                {item?.title}
            </Text>
        )
    }

    return (
            <LinearGradient
                colors={['rgba(0,0,0,0.6)', 'rgba(0,0,0,0.1)']}
                style={styles.cardCatContainer}>
                {checkTitle(item, index)}
                {item?.title ?
                    (<View style={styles.containerSubTitle}>
                        {<Text style={styles.subTitle}>{item?.title.substring(0, 13)}</Text>}
                    </View>)
                    : null}
            </LinearGradient>
    );
}

CategoryItem.defaultProps = {
    item: {},
    index: 0,
    onPressItem: () => { },
};

CategoryItem.propTypes = {
    item: PropTypes.object,
    index: PropTypes.number,
    onPressItem: PropTypes.func,
};

export default CategoryItem;