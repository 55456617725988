import { StyleSheet } from 'react-native';
import * as config from '~/config/config';

const styles = StyleSheet.create({
  statusPosivite: {
    width: 10,
    height: 10,
    borderRadius: 10,
  },

  icon: {
    tintColor: config.Colors.grayRed,
    width: 14,
    height: 14,
    marginRight: 8,
  },

  mainView: { 
    flexDirection: 'row', 
    justifyContent: 'flex-start', 
    alignItems: 'center',
  },

  clientView: { 
    flexDirection: 'row', 
    justifyContent: 'flex-start', 
    alignItems: 'center',
    maxWidth: 320,
  },
});

export default styles;
