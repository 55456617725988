const HTML = `<!DOCTYPE HTML PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html xml:lang="en" xmlns="http://www.w3.org/1999/xhtml" lang="en">
<head>
<meta http-equiv="content-type" content="text/html; charset=UTF-8" />

<title>Relatório de despesas</title>
<style type="text/css">

@page {
	margin: 2cm;
}

body {
  font-family: arial;
}

#header {
  border-bottom: 1pt solid #000;
  padding-bottom: 8px;
  width: 100%;
}

#footer {
    position: fixed;
    bottom: -4px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
}

#footer p {
    margin: 0;
}

.logo_rel {
    width: 30%px;
    padding: 0 16px;
}

.logo_rel img {
    height: 64px;
}

.page-number:before {

    content: "Página " counter(page);
}


h1 {
    margin: 0;
}

.subtitle {
    font-size: 16px;
    font-weight: 500;
    color: #5a5a5a;
    padding: 0;
    margin-top: 8px;
}

.subtitle_value {
    color: #000000;
    padding: 0;
    margin: 0;
}

.content_body {
    margin-top: 32px;
}

.content_body table {
    width: 100%;
    border-collapse: collapse;
}

.content_body table td {
    padding: 12px 16px;
}

.content_body table thead td {
  outline: 2px solid;
  font-weight: 600;
  outline-offset: -2px;
}

.content_body table tbody td {
    border-top: solid 2px;
}

.content_body table tbody td p{
    margin: 0;
}

.type_desc {
    color: #5a5a5a;
}

.no_break_word {
    white-space: nowrap;
}


table tbody .body_group_content td {
    padding: 0;
}

.body_group_content table {
    width: 100%;
}

.body_group_content td table td {
    padding: 12px 16px;
}

.body_group_content td table td {
    border-bottom: none;
}

.no_border_bottom {
  border-top: none !important;
}

.body_group_content td table tr:not(:last-child) {
    border-bottom: rgb(173, 173, 173) 1px solid;
}

.no_break_word.text_align_top_table {
    vertical-align: top; 
    padding-top: 20px;
}

.total {
    text-align: right;
    font-weight: 600;
}

.total p{
    font-weight: 600;
    display: inline-block;
}

</style>
</head>
<body>
<div>
    <div id="header">
        <table>
            <tr>
                <td class="logo_rel">
                    <img src="https://polibrassoftware.com.br/wp-content/uploads/2022/04/polibras-icon-1.png" />
                </td>
                <td>
                    <div>
                        <h1>Resumo de lançamentos</h1>
                        <div class="subtitle">Emitido por: <span class="subtitle_value">{{ $name }}</span></div>

                        <div class="subtitle">Data e hora da emissão: <span class="subtitle_value"> {{ $month }}</span></div>
                    </div>
                </td>
            </tr>
        </table>
    </div>

    <div class="subtitle">Período do relatório: <span class="subtitle_value">{{ $interval }}</span></div>
</div>

<div class="content_body">
    <table>
        <thead>
            <tr>
                <td >Data</td>
                <td >Tipo</td>
                <td width="100%">Referência</td>
                <td >Valor</td>
            </tr>
        </thead>
        <tbody>
        <% _.forEach(expenses, function(ex) { 
          if (ex.date) {
            %><tr>
              <td class="no_break_word" ><%- ex.date %></td>
              <td class="no_break_word" ><%- ex.type %></td>
              <td>
                  <p class="type_resume"><%- ex.title %></p>
                  <p class="type_desc"><%- ex.description %></p>
              </td>
              <td class="no_break_word"><%- ex.value %></td>
            </tr><% 
          } else {
            %><tr>
                <td class="no_border_bottom" ><%- ex.date %></td>
                <td class="no_break_word" ><%- ex.type %></td>
                <td>
                    <p class="type_resume"><%- ex.title %></p>
                    <p class="type_desc"><%- ex.description %></p>
                </td>
                <td class="no_break_word"><%- ex.value %></td>
            </tr><% 
          }
        }); %>
        </tbody>  
    </table>

    <div class="total">
        <p>TOTAL:</p>
        <span>{{ $total }}</span>
    </div>

</div>

<div id="footer">
    <div >
        <p>{{ $company }}</p>
    </div>
</div>

</body>
</html>
`;

export default HTML;
