/* @flow */

import React, { Component } from 'react';
import {
  View,
  ScrollView,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';
import style from '~/common/style';
import Form from '../../components/Form/Form';
import Button from '../../components/Button';
import translate from '../../locales';
import types from '~/components/Form/types';
import PropTypes from 'prop-types';
import ModalOverlay from '~/components/Web/ModalOverLay/index.web';

const whoTypesInEndEditing = {
  CNPJ: types.CNPJ.name,
  CPF: types.CPF.name,
};
const styles = StyleSheet.create({
  positionAbsolute: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fff',
  },
});

class AddClient extends Component {
  onSaveButtonPress = () => {
    if (this.props.onSaveButtonPress) {
      this.props.onSaveButtonPress();
    }
  };

  render() {
    const {
      form,
      visible,
      formIsValid,
      getFormData,
      isFormValid,
      location,
      onLocationChange,
      gpsConfig,
      consultCNPJ,
      consultCNPJRequesting,
      initialValues,
    } = this.props;

    const {
      requiredCollectGpsPoint,
      showPermissionLocation,
      notifyOnSaving,
    } = gpsConfig;
    const titleSaveButton = visible
      ? `${translate('save')}`
      : `${translate('saving')}`;

    return (
      <View style={[style.container, { backgroundColor: 'white' }]}>
        <ModalOverlay visible={!visible} closeOnTouchOutside />
        <ActivityIndicator
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            zIndex: 9999,
          }}
          animating={!visible}
          size="large"
        />
        <ScrollView>
          <View style={{ marginBottom: 40 }}>
            {form && form.length > 0 ? (
              <Form
                style={{ backgroundColor: 'white' }}
                consultCNPJRequesting={consultCNPJRequesting}
                data={form}
                initialValues={initialValues}
                isFormValid={isFormValid}
                getFormData={getFormData}
                onEndEditing={consultCNPJ}
                whoTypesInEndEditing={whoTypesInEndEditing}
                validFields={this.props.validInpts}
              />
            ) : null}
          </View>
        </ScrollView>
        <View style={styles.positionAbsolute}>
          <Button
            containerStyle={{
              marginHorizontal: 16,
              marginTop: 5,
              marginBottom: 5,
            }}
            title={titleSaveButton}
            disabled={!visible || !formIsValid}
            onPress={this.onSaveButtonPress}
          />
        </View>
      </View>
    );
  }
}

AddClient.propTypes = {
  form: PropTypes.array,
  initialValues: PropTypes.object,
  visible: PropTypes.bool,
  formIsValid: PropTypes.bool,
  location: PropTypes.object,
  gpsConfig: PropTypes.object,
  onSaveButtonPress: PropTypes.func,
  getFormData: PropTypes.func,
  isFormValid: PropTypes.func,
  onLocationChange: PropTypes.func,
  consultCNPJ: PropTypes.func,
  validInpts: PropTypes.array,
};

export default AddClient;
