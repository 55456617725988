import moment from 'moment';

export const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
export const dayOfWeek = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom'];

export function formatDateHourByFormat(date, format) {
  return moment(date).format(format);
}

export function formatDateHourDefault(date) {
  return moment(date).format('DD/MM/YYYY HH:mm:ss');
}

export default class DateHelper {
  static getDayOfWeek(index) {
    return dayOfWeek[index];
  }

  static getMonth(index) {
    return months[index];
  }

  static getDaysTwoWeeks() {
    const days = [];
    const today = moment();
    let nextDay = today;

    nextDay = nextDay.add(-today.weekday(), 'd');
    for (let i = 1; i < 15; i = i + 1) {
      days.push({
        date: nextDay.toISOString(true),
        day: nextDay.date(),
        dayOfWeek: DateHelper.getDayOfWeek(nextDay.weekday()),
        month: DateHelper.getMonth(nextDay.month()),
      });
      nextDay = nextDay.add(1, 'd');
    }
    return {
      today:
        {
          position: moment().weekday(),
          date: moment().toISOString(true),
          day: moment().date(),
          dayOfWeek: DateHelper.getDayOfWeek(moment().weekday()),
          month: DateHelper.getMonth(moment().month()),
        },
      days,
    };
  }

}
