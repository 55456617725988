import { POLIEQUIPES, POLISTORE } from '../../controllers/Accounts/escopeApps';
import SyncSalesFactory from './SyncSales/SyncSalesFactory';
import CacheAccount from '../../controllers/Accounts/Cache/CacheAccount';

export default class SyncFactory {
  static async create() {
    const cacheAccount = new CacheAccount();
    const dataAccount = await cacheAccount.getDataAccount();
    const syncs = [];

    dataAccount.apps.forEach((escope) => {
      if (escope === POLIEQUIPES || escope === POLISTORE) {
        syncs.push(SyncSalesFactory.factory('syncV1'));
      }
    });

    return syncs;
  }
}
