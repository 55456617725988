
const EdiApiModule = (nameModule) => {
  const methodByAlias = {};
  const Edi = {
    path: null,
  };
  
  const getPathUrl = async (resolve, reject) => {
    if (resolve !== undefined) {
      resolve(Edi.path);
    }
    return resolve;
  };
  
  const setPathUrl = async () => {
    return Edi.path;
  };
  
  return {
    getName: () => nameModule,
    methodByAlias,
    getPathUrl,
    setPathUrl,
  };
};

export default EdiApiModule;
