import React from 'react';
import GPSManager from '../../../controllers/GPS/GPSManager';
import GpsHelper from '../../../utils/GpsHelper';
import Gps from '../../../../components_base/src/components/Gps/Gps';
import FreeText from './FreeText';
import _, { create } from 'lodash';
import AddressAltCache from '~/cache/AddressAltCache';
import NavigationHelper from '~/screens/NavigationHelper';
import { createNotifyInfo } from '~/components/Web/ToastNotify';

type Props = {
  item: {
    id: string,
    activityType: string,
    required: boolean,
  },
  navigator: {
    pop: () => void,
  },
  dateSelected: any,
  saveAnswerActivity: (
    payloadAnswer: Object<any>,
    successSaveAnswer: Function<any>,
    failureSaveAnswer: Function<any>,
  ) => void,
  event: any,
};

class FreeTextContainer extends React.Component<Props, void> {
  constructor(props) {
    super(props);

    this.state = {
      message: '',
      dateOpen: '',
      loading: false,
      location: {
        accuracy: Infinity,
        altitude: null,
        heading: null,
        latitude: null,
        longitude: null,
        speed: 0.0,
        timestamp: 0,
      },
    };
    this.addressAltSelected = false;
    this.gpsManager = new GPSManager();
  }

  async componentWillMount() {
    const content = _.get(this.props.route.params.pushObject, 'item.answer.payload.content') || '';
    const startDate =
      _.get(this.props.route.params.pushObject, 'item.answer.start_date') || new Date().toISOString();

    if (!_.isObject(this.addressAltSelected)) {
      const event = _.get(this.props, 'event', {});
      const { pkey: pkeyClient } = event;
      const addressAlt = await AddressAltCache.getAddressAlt(pkeyClient);
      this.addressAltSelected = addressAlt;
    }

    this.setState({
      message: content,
      dateOpen: startDate,
    });
}

  navigationButtonPressed(event) {
    switch (event.buttonId) {
      case 'backPress':
        NavigationHelper.pop(this.props.componentId);
        break;
      default:
        break;
    }
  }

  onChangeMessage = message => {
    this.setState({ message });
  };

  onSaveFreeText = async () => {
    this.setState({ loading: true });

    const { item, event } = this.props.route.params.pushObject;
    const setup = JSON.parse(item.setup);

    const message = GpsHelper.validateRulesGPSClient(
      setup,
      event,
      this.state.location,
    );
    if (!_.isEmpty(message)) {
      this.setState({ loading: false }, () => {
        createNotifyInfo(message);
      });
      return;
    }

    const payloadAnswer = {
      dateSelected: this.props.route.params.pushObject.dateSelected,
      dateOpen: this.state.dateOpen,
      latitude: this.state.location.latitude,
      longitude: this.state.location.longitude,
      payload: {
        content: this.state.message,
        location: this.state.location,
      },
      taskId: this.props.route.params.pushObject.item.id,
    };

    this.props.route.params.pushObject.saveAnswerActivity(
      payloadAnswer,
      this.successSaveAnswer,
      this.failureSaveAnswer,
    );
  };

  successSaveAnswer = () => this.setState({ loading: false });
  failureSaveAnswer = () => this.setState({ loading: false });

  onLocationChange = location => {
    if (location) {
      this.setState({ location });
    }
  };

  renderGpsComponent() {
    const { location } = this.state;
    const { pkey: pkeyClient } = _.get(this.props.route.params.pushObject, 'event', {});
    const gpsPlaces = this.addressAltSelected || this.props.route.params.pushObject.event;

    return (
      <Gps
        places={[GpsHelper.parseGpsEvent(gpsPlaces)]}
        pkeyClient={pkeyClient}
        location={location}
        onLocationChange={this.onLocationChange}
        showPermitionLocation
        useViewExpandable
      />
    );
  }

  render() {
    return (
      <FreeText
        item={this.props.item}
        message={this.state.message}
        onChangeMessage={this.onChangeMessage}
        onSaveFreeText={this.onSaveFreeText}
        loading={this.state.loading}
        gpsComponent={this.renderGpsComponent()}
      />
    );
  }
}

export default FreeTextContainer;