import { createAsyncThunk } from '@reduxjs/toolkit';
import { applyOperationByGroup as applyOperationByGroupPromise } from './utilsLib';

export const APPLY_OPERATION_BY_GROUP_FETCH = 'APPLY_OPERATION_BY_GROUP_FETCH';

export const applyOperationByGroup = createAsyncThunk(
  'ordersFetch/applyOperationByGroup',
  async payload => {
    try {
      const response = await applyOperationByGroupPromise(payload);
      return JSON.parse(response);
    } catch (error) {
      console.log('error', error);
      return error;
    }
  },
);
