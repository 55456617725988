import { StyleSheet } from 'react-native';
import { colors, sizes, defaultShadow } from '../../values/index';
import { fonts } from '../../../../src/utils/Fonts';
import * as config from '../../../../src/config/config';

const maxWidth = 150;
const maxHeight = 256;

const baseContainer = {
  borderRadius: 20,
  backgroundColor: '#FFF',
};

const moreInfoBase = {
  width: 28,
  height: 28,
  borderRadius: 14,
  backgroundColor: '#fff',
  alignItems: 'center',
  justifyContent: 'center',
};

const moreInfoImageBase = {
  width: 24,
  height: 24,
  borderRadius: 12,
  tintColor: config.Colors.primaryColor,
};

const styles = StyleSheet.create({
  shadowContainer__linear: {},
  shadowContainer__grid: {
    ...baseContainer,
    ...defaultShadow,
    maxWidth,
    maxHeight,
  },
  overflowContainer__grid: {
    ...baseContainer,
    overflow: 'hidden',
    maxWidth,
    maxHeight,
  },
  overflowContainer__linear: {
    ...baseContainer,
    borderRadius: 0,
    overflow: 'hidden',
  },
  container__grid: {
    ...baseContainer,
    maxWidth,
    maxHeight,
    alignItems: 'center',
    justifyContent: 'center',
  },
  container__linear: {
    ...baseContainer,
    marginRight: 4,
    borderRadius: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  image__linear: {
    height: 80,
    width: 80,
    resizeMode: 'contain',
  },
  image__grid: {
    height: 112,
    width: 90,
    // resizeMode: 'contain',
  },
  infoContainer__linear: {
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  infoContainer__grid: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  nameLikeContainer__linear: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
    justifyContent: 'space-between',
  },
  nameLikeContainer__grid: {
    marginBottom: 8,
  },
  name__linear: {
    color: colors.grayDarkest,
    fontSize: 16,
    marginRight: 6,
    flex: 1,
  },
  name__grid: {
    color: colors.grayDarkest,
    fontSize: 10,
    fontFamily: fonts.QuicksandBold,
    textAlign: 'center',
  },
  moreInfo__linear: {
    ...moreInfoBase,
  },
  moreInfo__grid: {
    ...moreInfoBase,
    marginHorizontal: 0,
  },
  moreInfoImage__linear: {
    ...moreInfoImageBase,
  },
  moreInfoImage__grid: {
    ...moreInfoImageBase,
  },
  moreInfoBadge__linear: {},
  moreInfoBadge__grid: {
    position: 'absolute',
    top: 4,
    right: 4,
  },
  priceContainer__linear: {
    alignItems: 'flex-start',
    marginBottom: 8,
  },
  priceContainer__grid: {
    alignItems: 'center',
    marginBottom: 8,
  },
  startingAt__linear: {
    fontSize: 12,
    color: colors.grayMid,
  },
  startingAt__grid: {
    fontSize: 11,
    color: colors.grayMid,
  },
  price: {
    fontSize: 18,
    color: colors.grayDark,
  },
  priceQuantityContainer__linear: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  priceQuantityContainer__grid: {
    alignItems: 'center',
  },
  productStock: {
    color: '#fff',
    fontSize: 12,
    marginRight: 2,
  },
  iconError: {
    tintColor: '#FFF',
    width: 16,
    height: 16,
  },
  badgeError: {
    borderColor: '#fff',
    width: 24,
    height: 24,
    borderWidth: 1,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#ff000f',
  },
  containerMessage: {
    paddingHorizontal: 10,
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.grayRedDark,
  },
  unavailableProduct: {
    justifyContent: 'center',
    flexDirection: 'row',
    marginVertical: 12,
    paddingVertical: 12,
  },
});

export default styles;
