import React from 'react';
import { View, Text } from 'react-native';

import {
  TYPE_MESSAGE_ERROR,
  TYPE_MESSAGE_QUESTION,
  TYPE_MESSAGE_INFO,
  TYPE_MESSAGE_WARNING,
} from '../../typeMessageConst';
import { color } from '../../../../common';
import styles from './styles';
import HTML from 'react-native-render-html';

import PropTypes from 'prop-types';

function MessageItem({ type, text, children }) {
  const getColorMessage = typeMessage => {
    switch (typeMessage) {
      case TYPE_MESSAGE_ERROR:
        return color.dangerColor;
      case TYPE_MESSAGE_WARNING:
        return color.yellowVibrant;
      case TYPE_MESSAGE_INFO:
        return color.primaryColorLight;
      case TYPE_MESSAGE_QUESTION:
        return color.accentColor;
      default:
        return '';
    }
  };

  const colorStrip = getColorMessage(type);
  const isHTML = /<\/?[a-zA-Z][^>]*>/.test(text);
  return (
    <View
      style={[
        styles.container,
        {
          borderColor: colorStrip,
        },
      ]}>
      <View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          {isHTML ? (
            <HTML source={{ html: text }} />
          ) : (
            <Text style={styles.text}>{text}</Text>
          )}
        </View>
      </View>
      {children}
    </View>
  );
}

MessageItem.propTypes = {
    type: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    children: PropTypes.node,
};

export default MessageItem;
