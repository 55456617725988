import React, { Component } from 'react';
import { ActivityIndicator, ScrollView, StyleSheet, View } from 'react-native';
import style from '../../../common/style';
import Form from '../../../components/Form';
import Button from '../../../components/Button';

import PropTypes from 'prop-types';
import ModalOverlay from '~/components/Web/ModalOverLay/index.web';

const styles = StyleSheet.create({
  positionAbsolute: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fff',
  },
});

class ReportForm extends Component {

  render() {
    const {
      form,
      getFormData,
      isFormValid,
      visibleButton,
      generateReport,
      initialValues,
      loading,
    } = this.props;

    return (
      <View style={[style.container, { backgroundColor: 'white' }]}>
        <ScrollView>
          <View>
            <ModalOverlay visible={loading} closeOnTouchOutside />
            <ActivityIndicator
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                zIndex: 9999,
              }}
              animating={loading}
              size="large" />
            {form && form.length > 0 ? (
              <Form
                isFormValid={isFormValid}
                data={form}
                initialValues={initialValues}
                getFormData={getFormData}
              />
            ) : null}
            <Button
              containerStyle={{
                marginHorizontal: 16,
                marginTop: 5,
                marginBottom: 5,
              }}
              title="Gerar Relatório"
              disabled={!visibleButton}
              onPress={generateReport}
            />
            <ActivityIndicator
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                zIndex: 9999,
              }}
              animating={this.props.loading}
              size="large"
            />
          </View>
        </ScrollView>
      </View>
    );
  }
}

ReportForm.propTypes = {
  visibleButton: PropTypes.bool,
  form: PropTypes.array,
  getFormData: PropTypes.func,
  isFormValid: PropTypes.func,
  generateReport: PropTypes.func,
  loading: PropTypes.bool,
};

export default ReportForm;
