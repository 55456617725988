import React, { useEffect } from 'react';
import { Image, Text, View } from 'react-native';
import { color } from '~/common';
import Button from '~/components/Button';
import NavigationHelper from '../NavigationHelper';
import Images from '../../../assets/Images';
import PropTypes from 'prop-types';
import translate from '~/locales';
import onPressHelper from '~/utils/onPressHelper';

function Success({ navigation, componentId, listScreenId }) {
  useEffect(() => {
    return () => {};
  }, []);

  const handleBack = onPressHelper.debounce(() => {
    navigation.pop(2);
  });

  const navigationButtonPressed = ({ buttonId }) => {
    switch (buttonId) {
      case 'backPress':
      case 'RNN.hardwareBackButton':
        handleBack();
        break;
      default:
        break;
    }
  };

  return (
    <View
      style={{
        padding: 16,
        justifyContent: 'space-between',
        height: '100%',
      }}>
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'space-between',
          top: 177,
        }}>
        <Image source={Images.success} style={{ width: 242, height: 216 }} />
        <Text
          style={{
            color: '#808080',
            width: 242,
            fontSize: 20,
            fontWeight: '700',
            textAlign: 'center',
          }}>
          {`${translate('successSaveExpense')}`}
        </Text>
      </View>
      <View
        style={{
          alignItems: 'stretch',
        }}>
        <Button
          style={{
            borderRadius: 4,
            backgroundColor: color.primaryColor,
          }}
          normalCase
          onPress={handleBack}
          containerStyle={{ borderRadius: 4 }}
          title={`${translate('backToExpenses')}`}
        />
      </View>
    </View>
  );
}

Success.propTypes = {
  componentId: PropTypes.number,
  listScreenId: PropTypes.number,
};

export default Success;
