import React from 'react';

const Checkbox = ({ label, checked, onChange }) => {
  const handleChange = (event) => {
    if (typeof onChange === 'function') {
      onChange({ checked: event.target.checked });
    }
  };

  return (
    <label>
      <input
        type="checkbox"
        checked={checked}
        onChange={handleChange}
      />
      {label && <span>{label}</span>}
    </label>
  );
};

export default Checkbox;