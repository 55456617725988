import Resource from '../Resource.js';

class NotificationResource extends Resource {

  registerFCM(data) {
    return this.post('v1/register-device/', data)
  }

  registerAPNS(data) {
    return this.post('api/notifications/apns_register/', data)
  }

}

export default new NotificationResource();
