import AsyncStorage from '@react-native-async-storage/async-storage';
import * as config from './config';

export default new class Auth {
  async getToken() {
    try {
      return await AsyncStorage.getItem(config.Constants.TOKEN_KEY);
    } catch (error) {
      return null;
    }
  }

  isLogged(callback) {
    this.getToken().then((token) => {
      callback(token !== null);
    });
  }

  async removeToken() {
    try {
      return await AsyncStorage.removeItem(config.Constants.TOKEN_KEY);
    } catch (err) {
      return null;
    }
  }

  async saveNotificationToken(token) {
    await AsyncStorage.setItem(config.Constants.NOTIFICATION_TOKEN_KEY, token);
  }

  async getNotificationToken(token) {
    try {
      return await AsyncStorage.getItem(config.Constants.NOTIFICATION_TOKEN_KEY);
    } catch (error) {
      return null;
    }
  }

  async saveToken(token) {
    await AsyncStorage.setItem(config.Constants.TOKEN_KEY, token);
  }
}();
