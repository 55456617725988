import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import OrdersModule from '~/modules/OrdersModule';

export const ORDER_OBSERVATIONS_REQUEST = 'ORDER_OBSERVATIONS_REQUEST';
export const ORDER_OBSERVATIONS_SUCCESS = 'ORDER_OBSERVATIONS_SUCCESS';
export const ORDER_OBSERVATIONS_FAILURE = 'ORDER_OBSERVATIONS_FAILURE';

const getOrderObs = payload =>
  new Promise((resolve, reject) => {
    const { codigo } = payload;
    OrdersModule.getOrderObservation(`${codigo}`, resolve, reject);
  });

const getOrderObservation = createAsyncThunk(
  'orderObservations/getOrderObservation',
  async payload => {
    const response = await getOrderObs(payload);
    const parseResponse = JSON.parse(response);
    return parseResponse;
  },
);

const initialState = {
  type: '',
  loading: false,
  erro: false,
  payload: [],
};

const orderObservationsSlice = createSlice({
  name: 'orderObservations',
  initialState,
  reducers: {},
  extraReducers: {
    [getOrderObservation.pending]: state => {
      state.loading = true;
      state.erro = false;
      state.type = ORDER_OBSERVATIONS_REQUEST;
    },
    [getOrderObservation.fulfilled]: (state, action) => {
      state.loading = false;
      state.erro = false;
      state.type = ORDER_OBSERVATIONS_SUCCESS;
      state.payload = action.payload;
    },
    [getOrderObservation.rejected]: (state, action) => {
      state.loading = false;
      state.erro = true;
      state.type = ORDER_OBSERVATIONS_FAILURE;
    },
  },
});

const { reducer } = orderObservationsSlice;

export default reducer;
