/* eslint-disable no-nested-ternary,no-shadow */
import React, { Component } from 'react';
import {
  View,
  Image,
  StyleSheet,
  Platform,
  ScrollView,
  UIManager,
  findNodeHandle,
  Text,
  Dimensions,
} from 'react-native';
import Images from '@assets/Images';
import { color, style } from '~/common/index';
import Section from '../components/Section';
import Scale from '../components/Scale/Scale';
import OrdersModule from '../../../modules/OrdersModule';
import find from 'lodash/find';
import get from 'lodash/get';
import has from 'lodash/has';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

import Spinner from 'react-native-loading-spinner-overlay';
import {
  ORDER_FETCH_REQUEST,
  ORDER_FETCH_SUCCESS,
} from '../../../store/ordersFetch/orderFetchSlice';
import { SET_SCALE_REQUEST } from '~/store/ordersFetch/updateScaleProductSlice';
import { UPDATE_ITEM_REQUEST } from '~/store/ordersFetch/updateItemSlice';
import ProductTopInfo from '../components/ProductTopInfo';
import ProductImage from '../../../../components_base/src/components/Image';
import {
  getSourceImageProduct,
  getMultipleSaleByRule,
} from '../../../utils/ProductUtil';
import { executeActionsMessage } from '../../../utils/messages';
import translate, { calendarLanguage as language } from '../../../locales';
import { Banners, Button } from '../../../components';
import PriceInput from '../components/PriceInput';
import { fonts } from '~/utils/Fonts';
import Touchable from 'react-native-platform-touchable';
import { convertToFloat } from '../components/utils';
import { getPrecisionFromType, parseStrToFloat } from '~/utils/Currency';
import onPressHelper from '~/utils/onPressHelper';
import AutoHeightWebView from 'react-native-autoheight-webview';

import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import getImageProd from '~/utils/GetAllImagesProds';
import getImageProds from '~/utils/GetImageProduct.web';
import Html from 'react-native-render-html';
import { adjustHtml } from '~/utils/GlobalUtils';

const discountValues = [`${translate('discount')}`, `${translate('increase')}`];

const styles = StyleSheet.create({
  sectionTitle: {
    marginBottom: 8,
    color: Platform.OS === 'ios' ? color.grayDark : color.primaryColor,
    fontSize: 14,
    fontFamily: fonts.QuicksandBold,
  },
  card: {
    overflow: 'hidden',
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: color.grayContainerDark,
  },
});

class ProductDetail extends Component {
  static defaultProps = {
    actions: ['X', 'Caixa', 'Kg'],
  };
  constructor(props) {
    super(props);

    const { product, cart } = this.props;

    const quantity = this.getProductQuantityInOrder(
      product?.chave,
      cart?.payload,
    );

    this.state = {
      product,
      price: 0,
      priceIni: 0,
      discountType: 0,
      valueDiscount: 0.0,
      valueDiscountIni: 0.0,
      quantity,
      loading: false,
      updatingItem: false,
      modalMoreInfosVisible: false,
      sourceProductImage: null,
      editing: false,
      editingSouce: '',
      toRecalculate: false,
    };
  }

  componentDidMount() {
    const { configLib } = this.props;
    const forceOpenMoreInfo = get(
      configLib,
      'configuracoes.forcar_minfo_detalhe',
      false,
    );
    this.setProduct(this.props, () => {});
    this.setState({
      modalMoreInfosVisible: forceOpenMoreInfo,
    });
    const sourceProductImage = getImageProds(
      this.props.product,
      this.props.config,
    );
    this.setState({ sourceProductImage });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    (() => {
      if (nextProps.cart.type === this.props.cart.type) {
        return;
      }
      if (this.props.cart.type !== ORDER_FETCH_REQUEST) {
        return;
      }
      if (nextProps.cart.type !== ORDER_FETCH_SUCCESS) {
        return;
      }
      this.setProduct(nextProps, () => {});
    })();

    (() => {
      if (nextProps.cart.type === this.props.cart.type) {
        return;
      }
      if (nextProps.cart.type === UPDATE_ITEM_REQUEST) {
        return;
      }
      if (this.props.cart.type !== UPDATE_ITEM_REQUEST) {
        return;
      }

      if (nextProps.cart.type === ORDER_FETCH_SUCCESS) {
        const { payload } = nextProps.cart;
        if (this.props.product.chave !== payload.ref) {
          return;
        }
        const messages = get(payload, 'mensagens') || [];

        executeActionsMessage(
          messages,
          () => this.actionUpdateItemError(nextProps.cart),
          () => this.actionUpdateItemNotError(nextProps),
        );
      }
    })();

    (() => {
      if (nextProps.cart.type === this.props.cart.type) {
        return;
      }
      if (nextProps.cart.type === SET_SCALE_REQUEST) {
        return;
      }
      if (this.props.cart.type === ORDER_FETCH_SUCCESS) {
        return;
      }
      const { payload } = nextProps.cart;
      const messages = get(payload, 'mensagens') || [];

      executeActionsMessage(
        messages,
        () => this.actionUpdateItemError(nextProps.cart),
        null,
      );
    })();

    (() => {
      if (
        nextProps.product?.chave !== this.state.product?.chave ||
        nextProps.product?.estoque !== this.state.product?.estoque ||
        nextProps.product?.preco !== this.state.product?.preco ||
        nextProps.product?.acrescimo !== this.state.product?.acrescimo ||
        nextProps.product?.desconto !== this.state.product?.desconto
      ) {
        this.setProduct(nextProps, () => {});
      }
    })();
  }

  componentDidUpdate(prevProps) {
    if (this.props.product?.preco !== prevProps.product?.preco) {
      const price = this.props.product.preco;
      this.setPrices(price, price);
      this.updatePrice(this.props.product, price, false);
    }
  }

  getValueInFloat = (price, type = '') => {
    const precision = getPrecisionFromType(type);

    const priceStr = parseStrToFloat(price, precision);
    return Number.parseFloat(priceStr);
  };

  onPressOption = (eventName, index) => {
    this.setState({ selectedMultiplier: this.props.actions[index] });
  };

  onRef = icon => {
    if (!this.state.icon) {
      this.setState({ icon });
    }
  };

  onGrantDiscountChange = grantDiscountActive => {
    this.setState({ grantDiscountActive });
  };

  onAuthorizedDiscountChange = authorizedDiscountActive => {
    this.setState({ authorizedDiscountActive });
  };

  onPressMultiplierType = () => {
    if (Platform.OS === 'ios') {
      this.picker.show();
    } else if (this.state.icon) {
      UIManager.showPopupMenu(
        findNodeHandle(this.state.icon),
        this.props.actions,
        this.onError,
        this.onPressOption,
      );
    }
  };

  getProductQuantityInOrder(chave, cart) {
    const products = cart?.produtos || [];
    const product = find(products, o => o.chave === chave);

    if (product) {
      this.productAdded = true;
      return product.quantidade;
    }

    this.productAdded = false;
    return 0;
  }

  setProduct(nextProps, callback) {
    let { product } = nextProps || {};
    product = Object.assign({}, product);

    const { cart } = nextProps;
    const products = cart?.payload?.produtos || [];
    const productFound = find(products, ['chave', product?.chave?.toString()]);
    let price = product.preco;

    if (productFound) {
      if (productFound.preco > 0) {
        const { type_magnitudes, estoque, desconto, acrescimo } = product;
        product = {
          ...product,
          ...productFound,
          type_magnitudes,
          estoque,
          desconto,
          acrescimo,
        };
      }
    } else if (product.from_recalculate) {
      price = this.state.price;
    } else {
      product.precototal = 0;
      product.quantidade = 0;
    }
    const fallbackProduct = nextProps.product;
    if (
      product.from_recalculate &&
      product.precototal !== fallbackProduct.precototal
    ) {
      product.precototal = fallbackProduct.precototal;
    }

    this.setDiscountIncrease(product);

    this.setState(
      {
        product,
        quantity: this.state.quantity,
        price,
        priceIni: price,
        toRecalculate: false,
        loading: false,
      },
      () => {
        callback();
      },
    );
  }

  setDiscountIncrease(product) {
    const { acrescimo, desconto } = product;
    const precision = getPrecisionFromType('calculated');

    const discount = () => {
      this.setState({
        valueDiscount: parseStrToFloat(desconto, precision),
        valueDiscountIni: parseStrToFloat(desconto, precision),
        discountType: 0,
        toRecalculate: desconto !== 0,
      });
    };

    const increase = () => {
      this.setState({
        valueDiscount: parseStrToFloat(acrescimo, precision),
        valueDiscountIni: parseStrToFloat(acrescimo, precision),
        discountType: 1,
        toRecalculate: acrescimo !== 0,
      });
    };
    if (product.acrescimo > 0) {
      increase();
    } else if (product.desconto > 0) {
      discount();
    } else if (this.state.discountType === 1) {
      increase();
    } else {
      discount();
    }
  }

  setQuantity = (qty, indexScaleSelected, callback = () => {}) => {
    const qtyFloat = convertToFloat(qty);
    const quantity = Number.isNaN(qtyFloat) ? 0 : qtyFloat;
    this.setState(
      {
        quantity,
        product: {
          ...this.state.product,
          precototal: quantity * this.state.price,
        },
      },
      callback,
    );
    this.props.hadChange();
    this.handlePropagateQuantity(qty);
  };

  setDiscounts = (discounts, recalculate = true) => {
    const precision = getPrecisionFromType('calculated');

    if (isObject(discounts)) {
      const disc = {};

      if (has(discounts, 'valueDiscount')) {
        const { valueDiscount } = discounts;
        disc.valueDiscount = parseStrToFloat(`${valueDiscount}`, precision);
      }

      if (has(discounts, 'valueDiscountIni')) {
        const { valueDiscountIni } = discounts;
        disc.valueDiscountIni = parseStrToFloat(
          `${valueDiscountIni}`,
          precision,
        );
      }

      if (has(discounts, 'discountType')) {
        const { discountType } = discounts;
        disc.discountType = discountType;
      }

      if (!isEmpty(disc)) {
        this.setState({ ...disc, toRecalculate: recalculate });
      }
    }
    this.props.hadChange();
  };

  setPrices = (price, priceIni) => {
    const priceWithPrecision = this.getValueInFloat(price, 'calculated');
    const priceIniWithPrecision = this.getValueInFloat(priceIni, 'calculated');

    this.setState({
      price: priceWithPrecision,
      priceIni: priceIniWithPrecision,
    });
  };

  actionUpdateItemError = order => {
    const qty = this.getProductQuantityInOrder(
      this.state.product.chave,
      order.payload,
    );

    const price = this.getValueInFloat(this.state.product.preco, 'calculated');
    this.props.updateProduct(this.state.product);
    this.setState({
      quantity: qty,
      price,
      priceIni: price,
      updatingItem: false,
      loading: false,
      editing: false,
    });
    this.setDiscountIncrease(this.state.product);
  };

  actionUpdateItemNotError = nextProps => {
    this.setState({ updatingItem: false, editing: false });
    this.setProduct(nextProps, () =>
      this.props.updateProduct(this.state.product),
    );
  };

  updatePrice = async (product, priceFloat, recalculate = true) => {
    const precision = getPrecisionFromType('calculated');

    let productActive = await OrdersModule.getItemActive(product.chave);

    productActive = JSON.parse(productActive);
    const { precobase: priceBaseProduct = 0 } = productActive || product;

    const discountOrIncrease =
      (priceFloat - priceBaseProduct) / (priceBaseProduct || 1);
    const percent = Number.parseFloat(discountOrIncrease * 100);
    const discountType = percent > 0 ? 1 : 0;

    const multiplyFactor = Math.pow(10, precision - 1);
    this.setDiscounts(
      {
        // eslint-disable-next-line prettier/prettier
        valueDiscount: Math.abs((percent * multiplyFactor) / multiplyFactor),
        discountType,
      },
      recalculate,
    );
  };

  desconto = async (product, value) => {
    const { editing, editingSouce } = this.state;
    let productActive = await OrdersModule.getItemActive(product.chave);

    productActive = JSON.parse(productActive);
    const { precobase: priceBaseProduct = 0 } = productActive || product;

    if (editing && editingSouce !== 'segment') {
      return;
    }

    const percentDiscount = 100 - this.getValueInFloat(value, 'calculated');

    const priceDecreased = this.getValueInFloat(
      priceBaseProduct * percentDiscount / 100,
      'calculated',
    );

    this.setState({ price: priceDecreased });
  };

  acrescimo = async (product, value) => {
    const { editing, editingSouce } = this.state;
    let productActive = await OrdersModule.getItemActive(product.chave);

    productActive = JSON.parse(productActive);
    const { precobase: priceBaseProduct = 0 } = productActive || product;

    if (editing && editingSouce !== 'segment') {
      return;
    }

    const percentDiscount = 100 + this.getValueInFloat(value, 'calculated');

    const priceIncreased = this.getValueInFloat(
      priceBaseProduct * percentDiscount / 100,
      'calculated',
    );

    this.setState({ price: priceIncreased });
  };

  propagateQuantity = qty => {
    const { product, price, valueDiscount, discountType } = this.state;
    this.props.addItem(
      {
        ...product,
        preco: price,
        desconto: discountType === 0 ? valueDiscount : product.desconto,
        acrescimo: discountType === 1 ? valueDiscount : product.acrescimo,
      },
      qty,
      true,
      false,
    );
  };

  addItemToCart = () => {
    const {
      product,
      price,
      valueDiscount,
      discountType,
      quantity,
    } = this.state;

    this.setState({ loading: true, toRecalculate: false }, () => {
      const editedProduct = {
        ...product,
        preco: price,
        desconto: discountType === 0 ? valueDiscount : product.desconto,
        acrescimo: discountType === 1 ? valueDiscount : product.acrescimo,
      };

      this.props.addItem(editedProduct, quantity);
    });
  };

  onPressAddItemToCart = onPressHelper.debounce(this.addItemToCart, 1500);

  handlePropagateQuantity = debounce(this.propagateQuantity, 1500);

  updateProductMagnitude = magnitudeSelect => {
    const selected = this.props.magnitudes.findIndex(
      i => i.value === magnitudeSelect,
    );
    if (magnitudeSelect !== this.props.magnitudeSelected.value) {
      // this.setState({ updatingItem: true });
      this.props.updateProductMagnitude(this.props.magnitudes[selected], false);
      this.props.hadChange();
    }
    this.props.hadChange();
  };

  imageCheck(product) {
    const { editable } = this.props;

    const productQuantity = product.quantidade;
    const imageCheck =
      ((product.estoque && product.estoque > 0) ||
        (productQuantity && product.estoque > 0)) &&
        editable ? (
        <View>
          <Image
            style={{ tintColor: '#fff', width: 20, height: 20 }}
            source={Images.iconCheck}
          />
        </View>
      ) : null;

    return imageCheck;
  }

  recalculatePrice = () => {
    const {
      price,
      product,
      discountType,
      valueDiscount,
      quantity,
    } = this.state;

    this.props.recalculatePrice(
      {
        discountType,
        price,
        valueDiscount,
        quantity,
      },
      product,
      () =>
        this.setState({
          toRecalculate: false,
          quantity,
        }),
    );
  };

  showMoreInfos = () => {
    this.setState({
      modalMoreInfosVisible: !this.state.modalMoreInfosVisible,
    });
  };

  render() {
    const {
      product,
      quantity,
      price,
      priceIni,
      valueDiscount,
      discountType,
      valueDiscountIni,
      loading,
      updatingItem,
      sourceProductImage,
      toRecalculate,
      indexScaleSelected,
    } = this.state;

    const {
      overallLoading,
      magnitudes,
      magnitudeSelected,
      ruleMagnitudes,
      onImageClick,
      cart,
      configLib,
      product: productProps,
      ignorarMultiploVenda,
      onMoreInfoStartLoad,
      onMoreInfoFinishLoad,
    } = this.props;
    const infos = productProps?.infos;

    const productImages = get(productProps, 'images', []);
    const hasMultiplesImages = productImages && productImages.length > 0;

    const ignora_estoque =
      get(cart, 'payload.configuracoes.ignora_estoque') || false;

    const volumeFactor = getMultipleSaleByRule(
      product,
      ignorarMultiploVenda,
      ruleMagnitudes,
      magnitudeSelected,
    );

    const disabledButton =
      toRecalculate ||
      (this.productAdded ? loading : quantity === 0 || loading) ||
      overallLoading;

    return (
      <IntlProvider locale={`${language}`}>
        <View style={style.container}>
          <Spinner
            visible={loading}
            overlayColor="rgba(0, 0, 0, 0.7)"
            textStyle={{ color: '#FFF' }}
          />
          <ScrollView
            contentContainerStyle={{ paddingBottom: 70 }}
            overScrollMode="never">
            <Section style={{ padding: 0 }}>
              <View>
                <View style={styles.card}>
                  {/* Container da imagem do produto - ocupando espaço próprio */}
                  <View style={{
                    width: '100%',
                    alignItems: 'center',
                    paddingVertical: 20,
                    backgroundColor: '#f9f9f9',
                    borderBottomWidth: 1,
                    borderBottomColor: color.grayContainerDark,
                  }}>
                    <ProductImage
                      source={sourceProductImage}
                      onImageClick={onImageClick}
                      isProductDetail={true}
                      style={{ width: 180, height: 180 }}
                    />
                  </View>

                  {/* Container das informações do produto - em área separada abaixo da imagem */}
                  <ProductTopInfo
                    product={{ ...product, preco: price }}
                    showInfos={infos && infos.general_infos_html}
                    configLib={configLib}
                    quantity={quantity}
                    setQuantity={this.setQuantity}
                    addItem={(_, qty) => this.setQuantity(qty)}
                    volumeFactor={volumeFactor}
                    magnitudes={magnitudes}
                    magnitudeSelect={magnitudeSelected}
                    updateProductMagnitude={this.updateProductMagnitude}
                    showModal={() =>
                      this.setState({ modalMoreInfosVisible: true })
                    }
                    updatingItem={updatingItem || overallLoading}
                    ignoreVolumeFactor={ignorarMultiploVenda}
                    ignoreStock={ignora_estoque}
                    setEditing={this.setEditing}
                    toRecalculate={toRecalculate}
                    recalculatePrice={this.recalculatePrice}
                  />
                </View>
              </View>
            </Section>
            <Section title={`${translate('changePrice')}`}>
              <PriceInput
                price={price}
                priceIni={priceIni}
                product={product}
                setPrices={this.setPrices}
                updatePrice={this.updatePrice}
                valueDiscount={valueDiscount}
                discountType={discountType}
                valueDiscountIni={valueDiscountIni}
                discountValues={discountValues}
                acrescimo={this.acrescimo}
                desconto={this.desconto}
                setDiscounts={this.setDiscounts}
                magnitudes={magnitudes}
                magnitudeSelect={magnitudeSelected}
                ruleMagnitudes={ruleMagnitudes}
                updateProductMagnitude={this.updateProductMagnitude}
                updatingItem={updatingItem}
                setEditing={false}
              />
            </Section>
            <Section title={`${translate('priceScale')}`}>
              <Scale
                product={product}
                updatingItem={updatingItem}
                callback={this.setQuantity}
                editing={this.state.editing}
                scaleFromProductDetailModal={indexScaleSelected}
                forceQuantity
              />
            </Section>
            <View>
              <Touchable
                onPress={this.showMoreInfos}>
                <Section>
                  <View>
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}>
                      <Text
                        numberOfLines={2}
                        style={{
                          marginBottom: 8,
                          color:
                            Platform.OS === 'ios'
                              ? color.grayDark
                              : color.primaryColor,
                          fontSize: 14,
                          fontFamily: fonts.QuicksandBold,
                        }}>
                        {`${translate('moreInfo')}`}
                      </Text>
                      <Image
                        style={{
                          width: 30,
                          height: 30,
                        }}
                        source={
                          this.state.modalMoreInfosVisible
                            ? Images.iconChevronUp
                            : Images.iconChevronDown
                        }
                      />
                    </View>
                    {this.state.modalMoreInfosVisible &&
                      infos &&
                      infos.general_infos_html ? (
                      <View
                        style={{
                          borderTopWidth: 0.3,
                          marginTop: 10,
                        }}>
                        <Html
                          onLoad={onMoreInfoFinishLoad}
                          onLoadStart={onMoreInfoStartLoad}
                          style={{
                            opacity: 0.99,
                            width: Dimensions.get('window').width - 32,
                            overflow: 'hidden',
                          }}
                          containerStyle={{ marginTop: 10 }}
                          source={{
                            html: adjustHtml(infos.general_infos_html),
                          }}
                          androidHardwareAccelerationDisabled
                        />
                      </View>
                    ) : null}
                  </View>
                </Section>
              </Touchable>
            </View>
            <Button
              big
              title={
                this.productAdded && quantity === 0
                  ? 'Remover produto'
                  : 'Incluir Produto'
              }
              normalCase
              disabled={disabledButton}
              color="#fff"
              containerStyle={{
                alignSelf: 'flex-end',
                marginRight: 15,
                borderRadius: 4,
                marginTop: 10,
              }}
              style={{
                backgroundColor: disabledButton
                  ? color.grayContainerDark
                  : color.primaryColor,
                borderRadius: 5,
              }}
              onPress={() => this.onPressAddItemToCart()}
            />
          </ScrollView>
        </View>
      </IntlProvider>
    );
  }
}

ProductDetail.propTypes = {
  actions: PropTypes.array,
  cart: PropTypes.object,
  product: PropTypes.object,
  ignorarMultiploVenda: PropTypes.bool,
  magnitudeSelected: PropTypes.object,
  magnitudes: PropTypes.array,
  ruleMagnitudes: PropTypes.array,
  config: PropTypes.object,
  configLib: PropTypes.object,
  addItem: PropTypes.func,
  acrescimo: PropTypes.func,
  desconto: PropTypes.func,
  updatePrice: PropTypes.func,
  updateProductMagnitude: PropTypes.func,
  updateProduct: PropTypes.func,
  onImageClick: PropTypes.func,
  recalculatePrice: PropTypes.func,
  onMoreInfoStartLoad: PropTypes.func,
  onMoreInfoFinishLoad: PropTypes.func,
  hadChange: PropTypes.func,
  mirrorProps: PropTypes.object,
  overallLoading: PropTypes.bool,
  navigation: PropTypes.object,
};

ProductDetail.defaultProps = {
  item: {
    quantity: 2,
    name: 'Nome',
  },
  actions: ['Unidade', 'Caixa', 'Kg'],
};

export default ProductDetail;
