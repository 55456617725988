import React, { Component } from 'react';
import { View, StyleSheet, Platform, Picker } from 'react-native';
import * as config from '../config/config';

import PropTypes from 'prop-types';
import { createNotifyError } from './Web/ToastNotify';

const styles = StyleSheet.create({
  textSelected: {
    color: '#000',
    marginVertical: 8,
    marginLeft: 16,
    fontSize: 16,
  },
  textTitle: {
    color: '#979797',
    fontSize: 12,
    marginLeft: 16,
    marginTop: 6,
  },
  container: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: '#f5f5f5',
  },
  iconMenuDown: {
    width: 24,
    height: 24,
    margin: 8,
    tintColor: 'gray',
  },
});

class SelectMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      icon: null,
      showPickerIOS: false,
      selected: props.value ? props.value : '-',
      showPickerWeb: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value && nextProps.value !== '') {
      this.setState({ selected: nextProps.value });
    }
  }

  onError() {
    createNotifyError('Popup Error');
  }

  onPressOption = (eventName, index) => {
    if (eventName !== 'itemSelected') return;
    this.setState({ selected: this.props.actions[index] });
    if (this.props.onPress) {
      this.props.onPress(eventName, index);
    }
    if (this.props.onChangeValue) {
      this.props.onChangeValue(this.props.actions[index]);
    }
  };

  onPress = () => {
    if (Platform.OS === 'ios') {
      this.setState({ showPickerIOS: !this.state.showPickerIOS });
    } else if (this.state.icon) {
      this.setState({ showPickerWeb: !this.state.showPickerWeb });
    }
  };

  onRef = icon => {
    if (!this.state.icon) {
      this.setState({ icon });
    }
  };

  onChange = (selected) => {
    this.setState({ selected }, () => this.props.onChangeValue(selected));
  };

  render() {
    const styleAllRadius = {
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      borderBottomLeftRadius: 5,
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderColor: '#ddd',
    };
    const active = {
      borderWidth: 2,
      borderColor: config.Colors.primaryColor,
    };
    const titleActive = {
      color: config.Colors.primaryColor,
    };
    const { showPickerIOS } = this.state;
    const {
      outline, actions, required, title, editable,
    } = this.props;
    const containerStyle = [styles.container, this.props.style];
    const textStyle = [styles.textTitle];
    if (this.props.outline) {
      containerStyle.push(styleAllRadius);
    }
    if (showPickerIOS) {
      containerStyle.push(active);
      textStyle.push(titleActive);
    }
    return (
      <View style={containerStyle}>
       <Picker style={styles.picker} selectedValue={this.state.selected} onValueChange={this.onChange}>
          <Picker.Item label={required ? `${title || ''} *` : title || ''} />
          {this.props.actions.map((item) => <Picker.Item key={item} label={item} value={item} />)}
        </Picker>
      </View>
    );
  }
}

SelectMenu.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onPress: PropTypes.func,
  onChangeValue: PropTypes.func,
  style: PropTypes.shape(),
  value: PropTypes.string,
  title: PropTypes.string,
  outline: PropTypes.bool,
  required: PropTypes.bool,
  editable: PropTypes.bool,
};

export default SelectMenu;
