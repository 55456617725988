import React from 'react';
import {
  View,
  StyleSheet,
  Platform,
  Text,
  ScrollView,
  Modal,
  PixelRatio,
  ActivityIndicator,
} from 'react-native';
import _ from 'lodash';
import { PagerTitleIndicator } from 'rn-viewpager';
import style, { width } from '~/common/style';
import * as config from '~/config/config';
import InfoContainer from './Info/Info.Container';
import TitlesContainer from '../Financing/Titles/Titles.Container';
import { fonts } from '../../utils/Fonts';
import CustomAlert from '../../components/CustomAlert';
import Gps from '../../../components_base/src/components/Gps/Gps';
import GpsHelper from '../../utils/GpsHelper';
import colors from '../../common/color';
import translate from '../../locales';
import Touchable from 'react-native-platform-touchable';
import AddressAltCache from '~/cache/AddressAltCache';
import BottomSheet from '~/components/BottomSheet';
import AddrAlternativeForm from '~/components/AddressAlternative/AddressAlternativeForm';
import { isShowMultiTabs } from '~/utils/GlobalUtils';
import MultiTabsView from '~/components/MultiTabsView/MultiTabsView';
import { TabView, TabBar, SceneMap } from 'react-native-tab-view';
import { Colors } from '../../config/config';
import PropTypes from 'prop-types';

import stylesOP from './styleOrderPending';
import { Component } from 'react';
import ModalOverlay from '~/components/Web/ModalOverLay/index.web';

const pixelRatioFontScale = PixelRatio.getFontScale();

const styles = StyleSheet.create({
  indicatorTextBaseStyle: {
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.5)',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: 'white',
    height: pixelRatioFontScale > 1.2 ? 270 : 220,
    width: '90%',
    borderRadius: 8,
    padding: 20,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#1A1A1A',
    fontFamily: fonts.Quicksand,
  },
  modalText: {
    fontSize: 16,
    color: '#1A1A1A',
    fontFamily: fonts.Quicksand,
    paddingTop: 10,
    paddingBottom: 5,
    fontWeight: '600',
  },
  containerBtns: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    top: 38,
    paddingBottom: 20,
    gap: 8,
  },
  containerBtnLeft: {
    paddingTop: 0,
    paddingRight: 8,
    paddingBottom: 1,
    paddingLeft: 8,
  },
  containerBtnRight: {
    paddingTop: 0,
    paddingRight: 8,
    paddingBottom: 1,
    paddingLeft: 8,
  },
});


class ClientDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 0,
      routes: [
        { key: 'first', title: `${translate('details')}` },
        { key: 'second', title: `${translate('titles')}` },
      ],
      modalVisible: false,
    };

    this.addressAltSelected = false;
    this.gpsPlaces = null;
    this.pkeyClient = null;
    this.showPermitionLocation = _.get(
      props.configLib,
      ['gps', 'required_in_demand'],
      'false',
    );
  }

  async componentDidMount() {
    const { pkey: pkeyClient } = _.get(this.props, 'item', {});
    this.pkeyClient = pkeyClient;
    this.gpsPlaces = this.getGpsPlaces();
    if (!_.isObject(this.addressAltSelected)) {
      const event = _.get(this.props, 'event', {});
      const { pkey: pkeyClient } = event;
      const addressAlt = await AddressAltCache.getAddressAlt(pkeyClient);
      this.addressAltSelected = addressAlt;
    }
  }

  _handleIndexChange = index => this.setState({ index });

  _renderTabBar = props => (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: Colors.stripGreen }}
      indicatorContainerStyle={{ backgroundColor: Colors.primaryColor }}
      inactiveColor={Colors.gray}
      activeColor="white"
    />
  );

  FirstRoute = () => {
    return (
      <View style={{ flex: 1, backgroundColor: '#fff' }}>
        <ScrollView>
          {this.showPermitionLocation === 'true' ? (
            <View />
          ) : null}
          <InfoContainer
            openMaps={this.props.openMaps}
            openPhone={this.props.openPhone}
            item={this.props.item}
          />
        </ScrollView>
      </View>
    );
  };

  SecondRoute = () => (
    <View style={{ flex: 1, backgroundColor: '#fff' }}>
      <TitlesContainer
        pkeyClient={this.props.item.pkey}
        navigator={this.props.navigator}
        routeByitinerary={this.props.routeByitinerary}
      />
    </View>
  );

  renderTitleIndicator() {
    const titles = [`${translate('details')}`, `${translate('titles')}`];
    const indicatorStyle = {
      paddingHorizontal: 16,
      backgroundColor: config.Colors.primaryColor,
    };
    if (titles.length <= 3) {
      indicatorStyle.width = width / titles.length;
    }
    return (
      <PagerTitleIndicator
        style={{ backgroundColor: config.Colors.primaryColor }}
        selectedBorderStyle={{ backgroundColor: config.Colors.accentColor }}
        itemStyle={indicatorStyle}
        selectedItemStyle={indicatorStyle}
        itemTextStyle={styles.indicatorTextBaseStyle}
        selectedItemTextStyle={[
          styles.indicatorTextBaseStyle,
          { color: 'white', fontFamily: fonts.QuicksandBold },
        ]}
        titles={titles}
      />
    );
  }

  renderAddressAlternative() {
    const { location, item: clientCurrent } = this.props;

    const { pkey: pkeyClient } = clientCurrent;

    return (
      <View
        style={{
          marginTop: 2,
          marginBottom: 2,
          height: 40,
          backgroundColor: '#FFFFFF',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Touchable
          onPress={() => this.modalRef.open()}>
          <View style={{ margin: 40, padding: 40 }}>
            <Text
              style={{
                color:
                  Platform.OS === 'ios' ? colors.grayDark : colors.primaryColor,
                fontFamily: fonts.QuicksandBold,
                alignSelf: 'center',
                fontSize: 14,
              }}>
              {translate('createAddress')}
            </Text>
          </View>
        </Touchable>
        <BottomSheet
          ref={ref => {
            this.modalRef = ref;
          }}
          height={650}
          onClose={this.onClose}>
          <AddrAlternativeForm
            pkeyClient={pkeyClient}
            location={location}
            onSaveReached={() => this.modalRef.close()}
          />
        </BottomSheet>
      </View>
    );
  }

  getGpsPlaces = () => {
    const gpsPlaces = this.addressAltSelected || this.props.item;
    return [GpsHelper.parseGpsEvent(gpsPlaces)];
  };

  customAlertView = () => (
    <CustomAlert showModal title={`${translate('dataUpdate')}`} noButtons>
      <Text style={[styles.orderClient, { marginTop: 16 }]}>
        {`${translate('weAreUpdatingYourData')}`}
      </Text>
      <Text>COMPONENTE WEB DE PROGRESSO AQUI</Text>
    </CustomAlert>
  );

  defaultView = () => {
    const { FirstRoute, SecondRoute } = this;
    const { item, loading, syncData, configLib, location = {} } = this.props;
    const { index, routes } = this.state;
    const { addressAlternative = false } = _.get(
      configLib,
      'configuracoes',
      {},
    );

    return (
      <View style={style.container}>
        <ModalOverlay visible={loading} closeOnTouchOutside />
        <ActivityIndicator
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            zIndex: 9999,
          }}
          animating={loading}
          size="large"
        />
        {syncData ? this.customAlertView() : null}
        <TabView
          renderTabBar={this._renderTabBar}
          navigationState={{ index, routes }}
          renderScene={SceneMap({
            first: this.FirstRoute,
            second: this.SecondRoute,
          })}
          onIndexChange={this._handleIndexChange}
        />
        {addressAlternative ? this.renderAddressAlternative() : null}
      </View>
    );
  };

  gpsView = () => {
    const { location, onLocationChangeFromMap } = this.props;
    const { pkey: pkeyClient } = _.get(this.props, 'item', {});
    const gpsPlaces = this.getGpsPlaces();

    return (
      <Gps
        places={gpsPlaces}
        pkeyClient={pkeyClient}
        location={location}
        errorGps=""
        onLocationChange={onLocationChangeFromMap}
        showPermitionLocation
        useViewExpandable
      />
    );
  };

  multiTabsView = () => {
    const { configLib, navigator, loading } = this.props;

    const pkClient = _.get(this.props, 'item.pkey', false);
    const params = pkClient
      ? {
          pk_cliente: pkClient,
        }
      : {};

    return (
      <>
        <ModalOverlay visible={loading} closeOnTouchOutside />
        <ActivityIndicator
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            zIndex: 9999,
          }}
          animating={loading}
          size="large"
        />
        <MultiTabsView
          configLib={configLib}
          groupId="CDET"
          params={params}
          navigator={navigator}
          loading={this.props.loading}
          renderHeaderComponent={() =>
            this.showPermitionLocation === 'true' ? <View /> : <View />
          }
        />
      </>
    );
  };

  cancelActiveOrder = () => {
    try {
      this.props.cancelActiveOrder();
      this.setState({ modalVisible: false }, () => {
        this.props.refModalOrderPendingClose();
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  restoreOrderPending = () => {
    try {
      this.props.restoreOrderPending();
      this.setState({ modalVisible: false }, () => {
        this.props.refModalOrderPendingClose();
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  saveCartBudget = () => {
    try {
      this.props.saveCartBudget();
      this.setState({ modalVisible: false }, () => {
        this.props.refModalOrderPendingClose();
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  displayModalDeleteOrder = () => {
    this.setState({ modalVisible: true });
  };

  closeModalDeleteOrder = () => {
    this.setState({ modalVisible: false });
  };

  modalOrderPending = () => {
    return (
      <BottomSheet
        ref={this.props.refModalOrderPending}
        customStyles={{
          container: {
            height: 300,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            padding: 5,
          },
        }}>
        <ScrollView>
          <View style={stylesOP.wrapper}>
            <Text style={stylesOP.title}>{translate('hasPendingOrder')}</Text>
            <View style={stylesOP.containerInfoClient}>
              <Text style={stylesOP.infoClientTitle}>
                {translate('client')}:{' '}
                <Text style={stylesOP.infoClientValue}>
                  {this.props.clientOrderPending?.nome}
                </Text>
              </Text>
            </View>
            <View style={stylesOP.containerInfoItems}>
              <Text style={stylesOP.infoItems}>
                {translate('total')}:{' '}
                <Text style={stylesOP.infoClientValue}>
                  R$ {this.props.totalOrder}
                </Text>
              </Text>
            </View>
            <View style={stylesOP.containerInfoOrder}>
              <Text>{translate('resolvingPendingOrder')}</Text>
            </View>
            <View style={stylesOP.rootBtns}>
              <View style={stylesOP.containerSaveOrder}>
                <View>
                  <Touchable
                    onPress={this.saveCartBudget}
                    >
                    <Text style={stylesOP.textBtns}>
                      {translate('saveOrderBudget')}
                    </Text>
                  </Touchable>
                </View>
              </View>
              <View style={stylesOP.conatinerRestoreOrder}>
                <View>
                  <Touchable
                    onPress={this.restoreOrderPending}
                    >
                    <Text style={stylesOP.textBtns}>
                      {translate('restoreOrder')}
                    </Text>
                  </Touchable>
                </View>
              </View>
              <View style={stylesOP.containerBtnDeleteOrder}>
                <View>
                  <Touchable
                    onPress={this.displayModalDeleteOrder}
                    >
                    <Text style={stylesOP.textBtns}>
                      {translate('deleteOrder')}
                    </Text>
                  </Touchable>
                </View>
              </View>
            </View>
            <View style={styles.container}>
              <Modal
                animationType="slide"
                transparent={true}
                visible={this.state.modalVisible}
                onRequestClose={this.closeModalDeleteOrder}>
                <View style={styles.modalContainer}>
                  <View style={styles.modalContent}>
                    <Text style={stylesOP.title}>
                      {translate('deleteOrder')}
                    </Text>
                    <View>
                      <Text style={styles.modalText}>
                        {translate('doYouWantDeleteOrder')}
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.modalText}>
                        {translate('onceConfirmAction')}
                      </Text>
                    </View>
                    <View style={styles.containerBtns}>
                      <Touchable
                        onPress={this.closeModalDeleteOrder}
                        >
                        <View style={styles.containerBtnLeft}>
                          <Text style={stylesOP.textBtns}>
                            {translate('decideLater')}
                          </Text>
                        </View>
                      </Touchable>
                      <Touchable
                        onPress={this.cancelActiveOrder}
                        style={{
                          marginLeft: 8,
                        }}
                       >
                        <View style={styles.containerBtnRight}>
                          <Text style={stylesOP.textBtns}>
                            {translate('yesDeletePendingOrder')}
                          </Text>
                        </View>
                      </Touchable>
                    </View>
                  </View>
                </View>
              </Modal>
            </View>
          </View>
        </ScrollView>
      </BottomSheet>
    );
  };

  render() {
    const { configLib, syncData, hasPendingOrder } = this.props;

    const showMultiTabs = isShowMultiTabs(
      configLib,
      'clients',
      'detalhe_cliente',
    );

    return (
      <View style={style.container}>
        {syncData ? this.customAlertView() : null}
        {!showMultiTabs ? this.defaultView() : this.multiTabsView()}
        {hasPendingOrder ? this.modalOrderPending() : null}
      </View>
    );
  }
}

ClientDetail.propTypes = {
    configLib: PropTypes.any,
    navigator: PropTypes.any,
    loading: PropTypes.bool,
    syncData: PropTypes.bool,
    item: PropTypes.any,
    location: PropTypes.any,
    routeByitinerary: PropTypes.bool,
    openMaps: PropTypes.func,
    openPhone: PropTypes.func,
    onLocationChangeFromMap: PropTypes.func,
    loadMultiTabs: PropTypes.func,
    multiTabs: PropTypes.array,
    multiTabsTitle: PropTypes.array,
    hasPendingOrder: PropTypes.bool,
    refModalOrderPending: PropTypes.any,
    refModalOrderPendingClose: PropTypes.any,
    totalOrder: PropTypes.number,
    cancelActiveOrder: PropTypes.func,
    restoreOrderPending: PropTypes.func,
    saveCartBudget: PropTypes.func,
    clientOrderPending: PropTypes.object,
};

export default ClientDetail;
