import React from 'react';
import { StyleSheet, View, TextInput, Text, Keyboard } from 'react-native';

export default class FloatingTitleTextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topPlaceholder: false,
      textString: '',
      error: this.props.error,
    };
    this.placeholder = this.props.placeholder ? this.props.placeholder : '';
    this.titleText = this.props.customTitle
      ? this.props.customTitle
      : this.placeholder;
  }

  renderPlaceHolderText = () => {
    if (
      this.props.error !== undefined &&
      this.props.error !== null &&
      this.props.error !== ''
    ) {
      return (
        <Text style={[styles.placeholder, this.props.titleStyle]}>
          {this.props.error}
        </Text>
      );
    }
  };

  onChangeText = value => {
    this.setState({ textString: value });
    if (this.props.onChangeText) {
      this.props.onChangeText(value);
    }
  };

  focus = () => {
    this.textInput.focus();
  };

  onFocus = event => {
    this.setState({ topPlaceholder: true });
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  };

  onBlur = () => {
    if (this.state.textString === '') {
      this.setState({ topPlaceholder: false });
    }
    if (this.props.onBlur) {
      this.props.onBlur(this.state.textString);
    }
  };

  onSubmitEditing = () => {
    if (this.props.onSubmitEditing) {
      this.props.onSubmitEditing();
    } else {
      Keyboard.dismiss();
    }
  };

  render() {
    return (
      <View style={this.props.style || this.props.containerStyle}>
        {this.renderPlaceHolderText()}
        {/* {this.props.value ? (
          <Text style={styles.labelInput}>
            {this.props.label}
          </Text>
        ) : null} */}
        <TextInput
          ref={ref => {
            this.textInput = ref;
          }}
          value={this.props.value ? this.props.value : this.state.textString}
          secureTextEntry={
            this.props.secureTextEntry ? this.props.secureTextEntry : false
          }
          onChangeText={value => {
            this.onChangeText(value);
          }}
          onFocus={event => {
            this.onFocus(event);
          }}
          multiline={this.props.multiline ? this.props.multiline : false}
          onBlur={() => {
            this.onBlur();
          }}
          placeholderTextColor={
            this.props.placeholderTextColor
              ? this.props.placeholderTextColor
              : '#B3B3B3'
          }
          placeholder={ this.props.label ? this.props.label : !this.state.topPlaceholder ? this.placeholder : ''}
          maxLength={this.props.maxLength ? this.props.maxLength : null}
          style={[
            styles.textInput,
            this.props.textInputStyle,
            this.props.error ? styles.error : null,
          ]}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  labelInput: {
    color: '#000',
    fontSize: 14,
    fontWeight: 'normal',
  },
  textInput: {
    height: 44,
    backgroundColor: '#EEEEEE',
    paddingLeft: 13,
    outlineColor: '#074885',
  },
  placeholder: {
    color: 'red',
    marginTop: 3,
    marginBottom: 5,
  },
  error: {
    borderColor: 'red',
    borderWidth: 1,
  },
});
