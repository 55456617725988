import React from 'react';

import Images from '../../../../assets/Images';
import ButtonIcon from '../ButtonIcon';
import styles from './styles';

import PropTypes from 'prop-types';

function ButtonIconInfo({ onPress }) {
  return (
    <ButtonIcon
      style={styles.buttonStyle}
      styleImage={styles.buttonImageStyle}
      onPress={onPress}
      source={Images.iconInfo}
    />
  );
}

ButtonIconInfo.propTypes = {
    onPress: PropTypes.func.isRequired,
};

export default ButtonIconInfo;
