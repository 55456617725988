/* @flow */

import React, { Component } from 'react';
import { Image, StyleSheet, Text, TextInput, View } from 'react-native';

import { CheckboxGroupData, RadioGroupData, Attachment } from '../components';
import Carousel from '../../components_base/src/components/Carousel';
import Touchable from 'react-native-platform-touchable';
import { color, style } from '~/common/index';
import Images from '@assets/Images';
import moment from 'moment';
import 'moment/locale/pt-br';
import DateTimePicker from 'react-native-modal-datetime-picker';
import Checkbox from '~/modules-wrapper/react-native-modest-checkbox';
import Masked from 'vanilla-masker';
import GridImage from '../../assets/img/activities/grid/';
import { getSourceImageProduct } from '../utils/ProductUtil';
import translate from '../locales';
import _ from 'lodash';
import CacheDataController from '~/utils/CacheDataController';

import PropTypes from 'prop-types';

const IMAGE_WIDTH = 80;

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderBottomColor: color.grayContainer,
    borderBottomWidth: 2,
  },
  containerPressed: {
    backgroundColor: '#dde1ed',
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderBottomColor: color.grayContainer,
    borderBottomWidth: 2,
  },
  image: {
    width: IMAGE_WIDTH,
    height: IMAGE_WIDTH,
    borderRadius: 10,
  },
  imageCameraOutsideContainer: {
    width: IMAGE_WIDTH,
    height: IMAGE_WIDTH,
    borderRadius: 10,
    overflow: 'hidden',
  },
  imageCameraContainer: {
    width: IMAGE_WIDTH,
    height: IMAGE_WIDTH,
    borderRadius: 10,
    backgroundColor: color.grayContainerDark,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageCamera: {
    width: 30,
    height: 30,
    tintColor: color.grayMid,
  },
  appIcon: {
    width: 20,
    height: 20,
    tintColor: color.grayMid,
  },
  name: {
    color: '#000',
    fontSize: 16,
  },
  ean: {
    color: color.grayMid,
    fontSize: 13,
    marginBottom: 6,
  },
  inputTitle: {
    fontSize: 13,
    color: color.primaryColorLight,
    marginRight: 10,
  },
  input: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: color.grayContainer,
    fontSize: 14,
    paddingVertical: 4,
    minHeight: 24,
    minWidth: 100,
    margin: 4,
    flex: 1,
    borderBottomWidth: 0.5,
    borderBottomColor: color.primaryColorLight,
  },
  ItemActionIcon: {
    width: 18,
    height: 18,
    tintColor: color.primaryColor,
    marginRight: 10,
  },
  ItemActionText: {
    color: color.primaryColor,
    fontSize: 14,
  },
  ItemActionContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
});

const moneyFormatter = {
  precision: 2,
  separator: ',',
  delimiter: '.',
  unit: translate('currencySymbol'),
};

moment.locale('pt-br');

const ItemAction = ({ text, icon, onPress }) => (
  <Touchable onPress={onPress}>
    <View style={[styles.ItemActionContainer]}>
      <Image style={styles.ItemActionIcon} source={icon} />
      <Text style={styles.ItemActionText}>{text}</Text>
    </View>
  </Touchable>
);

class GridItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDateTimePickerVisible: false,
      value: undefined,
      sourceImage: undefined,
      fields: props.setup.fields,
    };

    this.saveItemInput = _.debounce(this.onSaveItemGrid, 500);
  }
  componentDidMount() {
    this.loadImage(this.props);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.item.ean !== nextProps.item.ean) {
      this.loadImage(nextProps);
    }
  }

  loadImage(source) {
    const { item } = source;
    if (this.state.sourceImage) {
      return;
    }

    if (item.value) {
      this.setState({ value: item.value });
    }

    if (item.ean) {
      CacheDataController.getData('@objPayload', false).then(config => {
        const sourceImage = getSourceImageProduct(item, config);
        this.setState({ sourceImage });
      });
    } else if (item.image_source === 'url') {
      this.setState({ sourceImage: { uri: item.image_value } });
    } else if (item.image_source === 'bin') {
      this.setState({ sourceImage: GridImage[item.image_value] });
    }
  }

  getImage() {
    return (
      <View style={styles.imageCameraOutsideContainer}>
        <View style={this.state.sourceImage ? {} : styles.imageCameraContainer}>
          <Image
            style={this.state.sourceImage ? styles.image : styles.imageCamera}
            onError={() =>
              this.setState({
                sourceImage: Images.iconCamera,
                typeImage: 'icon',
              })
            }
            source={this.state.sourceImage || Images.iconCamera}
          />
        </View>
      </View>
    );
  }

  getName(setup) {
    const { type, name } = setup;
    if (type.localeCompare('price_survey') === 0) {
      return name || 'Preço (R$)';
    } else if (type.localeCompare('break') === 0) {
      return name || 'Ruptura';
    } else if (type.localeCompare('expiration') === 0) {
      return name || 'Vencimento';
    } else if (type.localeCompare('quantity') === 0) {
      return name || 'Quantidade';
    } else if (type.localeCompare('weight') === 0) {
      return name || 'Peso';
    }
    return '';
  }

  onFinishEditing(item, itemValue, onFinishEditingEvent, type) {
    let newValue = itemValue;
    if (type.localeCompare('quantity') === 0) {
      newValue = parseInt(itemValue, 10);
    }
    const itemWithValue = {
      ...item,
      value: newValue,
    };

    onFinishEditingEvent(itemWithValue);
  }

  hideDateTimePicker = () => this.setState({ isDateTimePickerVisible: false });

  handleDatePicked = (date, item, onFinishEditingEvent, type) => {
    const newValue = moment(date).format();

    this.onFinishEditing(item, newValue, onFinishEditingEvent, type);

    this.setState({ value: newValue });

    this.hideDateTimePicker();
  };

  onPressExpiration() {
    this.setState({ isDateTimePickerVisible: true });
  }

  onCheckBoxChange(value, item, onFinishEditingEvent, type) {
    this.setState({ value });

    this.onFinishEditing(item, value, onFinishEditingEvent, type);
  }

  isNumberType(type) {
    return (
      type.localeCompare('price_survey') === 0 ||
      type.localeCompare('quantity') === 0 ||
      type.localeCompare('weight') === 0
    );
  }

  getInput(item, setup, onFinishEditingEvent) {
    return (
      <View style={style.rowDirection}>
        {!setup.type.localeCompare('grid_form') === 0 && (
          <Text style={styles.inputTitle}>
            {this.getName(setup) || 'Tipo'}:
          </Text>
        )}
        {this.isNumberType(setup.type)
          ? this.getTextInput(item, onFinishEditingEvent, setup.type)
          : null}
        {setup.type.localeCompare('expiration') === 0 ? (
          <View style={styles.input}>
            <Image
              source={Images.iconCalendar}
              style={styles.appIcon}
              resizeMode="contain"
            />
            <Text
              style={styles.input}
              onPress={() => {
                this.onPressExpiration();
              }}>
              {' '}
              {this.getExpiration()}{' '}
            </Text>
            <DateTimePicker
              isVisible={this.state.isDateTimePickerVisible}
              minimumDate={new Date()}
              onConfirm={date => {
                this.handleDatePicked(
                  date,
                  item,
                  onFinishEditingEvent,
                  setup.type,
                );
              }}
              onCancel={this.hideDateTimePicker}
            />
          </View>
        ) : null}
        {setup.type.localeCompare('break') === 0 ? (
          <Checkbox
            label=""
            checked={this.state.value || false}
            onChange={cbox => {
              this.onCheckBoxChange(
                cbox.checked,
                item,
                onFinishEditingEvent,
                setup.type,
              );
            }}
          />
        ) : null}
        {setup.type.localeCompare('grid_form') === 0 && this.renderField()}
      </View>
    );
  }

  getTextInput(item, onFinishEditingEvent, type) {
    const value = this.state.value ? this.state.value.toString() : '';
    return (
      <TextInput
        style={styles.input}
        keyboardType="numeric"
        selectionColor={color.primaryColorLight}
        underlineColorAndroid={color.primaryColorLight}
        placeholderTextColor={color.primaryColorLight}
        selectTextOnFocus
        value={value}
        onChangeText={newValue => {
          if (type.localeCompare('price_survey') === 0) {
            const valueFormated = Masked.toMoney(newValue, moneyFormatter);
            this.setState({ value: valueFormated });
            this.onFinishEditing(
              item,
              valueFormated,
              onFinishEditingEvent,
              type,
            );
          } else if (
            type.localeCompare('quantity') === 0 ||
            type.localeCompare('weight') === 0
          ) {
            const regExp = /^\d*\.?\d*$/;
            const valueFormated = regExp.test(newValue)
              ? newValue
              : newValue.replace(/[^0-9|/.]/g, '');
            this.setState({
              value: valueFormated,
            });
            this.onFinishEditing(
              item,
              valueFormated,
              onFinishEditingEvent,
              type,
            );
          }
        }}
      />
    );
  }

  containsEan(setup) {
    return (
      setup.type.localeCompare('price_survey') === 0 ||
      setup.type.localeCompare('expiration') === 0 ||
      setup.type.localeCompare('break') === 0
    );
  }

  renderField() {
    const { setup, item, onFinishEditingEvent } = this.props;
    const { type, data, value } = item;

    switch (type) {
      case 'checkbox':
        return (
          <CheckboxGroupData
            valuesSelected={value || []}
            itens={item.data}
            onSelectItens={values =>
              this.onFinishEditing(
                item,
                values,
                onFinishEditingEvent,
                setup.type,
              )
            }
          />
        );
      case 'radio':
        return (
          <RadioGroupData
            valueSelected={value}
            itens={item.data}
            onSelectItem={value =>
              this.onFinishEditing(
                item,
                value,
                onFinishEditingEvent,
                setup.type,
              )
            }
          />
        );
      default:
        return null;
    }
  }

  getTextInputMultiple(index, type, kind) {
    const { fields } = this.state;
    const savedValue = this.props.item.fields[index].value || '';

    return (
      <TextInput
        style={styles.input}
        selectTextOnFocus
        value={`${savedValue}`}
        onChangeText={newValue => {
          let value = newValue;

          if (kind.localeCompare('price_survey') === 0) {
            value = Masked.toMoney(newValue, moneyFormatter);
          } else if (kind.localeCompare('weight') === 0) {
            const regExp = /^\d*\.?\d*$/;
            value = regExp.test(newValue)
              ? newValue
              : newValue.replace(/[^0-9|/.]/g, '');
          } else if (kind.localeCompare('quantity') === 0) {
            value = parseInt(newValue, 10);
          }

          fields[index].value = value;
          this.setState({ fields }, () => {
            this.saveItemInput();
          });
        }}
      />
    );
  }

  onSaveItemGrid = () => {
    const { fields } = this.state;
    this.props.onFinishEditingEvent({
      ...this.props.item,
      fields,
    });
  };

  getField = (field, index) => {
    const { type, kind } = field;

    switch (type) {
      case 'input':
        return this.getTextInputMultiple(index, type, kind);
      default:
        return null;
    }
  };

  renderMultiples() {
    const fields = _.get(this.props, 'item.fields', []) || [];

    return fields.map((field, index) => (
      <View style={style.rowDirection} key={index}>
        <Text style={styles.inputTitle}>{field.label}: </Text>
        {this.getField(field, index)}
      </View>
    ));
  }

  renderInput() {
    const { setup, item, onFinishEditingEvent, onRemoveExtraItem } = this.props;
    const { fields = [] } = item;
    const { type_edit_form, type } = setup;
    const hasAnswer = _.some(fields, i => !!i.value);
    const hasMultiple = fields && fields.length > 0 && fields.length <= 2;

    if (type === 'grid_form') {
      switch (type_edit_form) {
        case 'self':
          return hasMultiple
            ? this.renderMultiples()
            : this.getInput(item, setup, onFinishEditingEvent);
        default:
          return (
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'flex-start',
                display: 'flex',
              }}>
              <ItemAction
                onPress={() => {
                  this.props.openGridForm(item.id);
                }}
                text={
                  hasAnswer
                    ? `${translate('lookAnswer')}`
                    : `${translate('respond')}`
                }
                icon={hasAnswer ? Images.iconChoice : Images.iconEdit}
              />
              {!!(item.extra_item && !hasAnswer) && (
                <ItemAction
                  onPress={() => {
                    onRemoveExtraItem(item.id);
                  }}
                  text={`${translate('remove')}`}
                  icon={Images.iconDelete}
                />
              )}
            </View>
          );
      }
    } else {
      return this.getInput(item, setup, onFinishEditingEvent);
    }
  }

  renderItemAnexo = ({ item }) => <Attachment attach={item} />;

  render() {
    const { setup, item, onFinishEditingEvent } = this.props;

    return (
      <Touchable
        onPress={() => {
          if (setup.type.localeCompare('break') === 0) {
            this.onCheckBoxChange(
              !this.state.value,
              item,
              onFinishEditingEvent,
              setup.type,
            );
          }
        }}>
        <View
          style={
            setup.type.localeCompare('break') === 0 && this.state.value
              ? styles.containerPressed
              : styles.container
          }>
          <View style={style.rowDirection}>
            {!setup.hidden_image && (
              <View style={{ marginRight: 12 }}>{this.getImage()}</View>
            )}
            <View style={{ flex: 1 }}>
              <Text style={styles.name}>
                {item.name || `${translate('productName')}`}
              </Text>
              {item.ean ? <Text style={styles.ean}>{item.ean}</Text> : null}
              {this.renderInput()}
              {item.attachments &&
                item.attachments.length > 0 && (
                  <Carousel
                    data={item.attachments}
                    renderItem={this.renderItemAnexo}
                    ItemSeparatorComponent={() => <View style={{ width: 1 }} />}
                  />
                )}
            </View>
          </View>
        </View>
      </Touchable>
    );
  }

  getExpiration() {
    if (this.state.value) {
      return moment(this.state.value).format('L');
    }
    return '';
  }
}

GridItem.propTypes = {
  setup: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  onFinishEditingEvent: PropTypes.func.isRequired,
  openGridForm: PropTypes.func.isRequired,
};

GridItem.defaultProps = {
  item: {},
};

export default GridItem;
