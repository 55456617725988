/* @flow */

import React, { useRef, useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import Section from './Section';
import { color, style } from '~/common';
import moment from 'moment';
import translate from '~/locales';
import DateTime from '~/components/Form/Field/DateTime.web';

type Props = {
  title: string,
  type: 'date' | 'datetime' | 'time',
  startDate?: Date,
  endDate?: Date,
  startTitle: string,
  endTitle: string,
  onChangeStart(value: string): (value: string) => void,
  onChangeEnd(value: string): (value: string) => void,
  allowClear?: boolean,
};

const styles = StyleSheet.create({
  datePicker: { flex: 1, marginRight: 8 },
});

function DateRange(props: Props) {
  const startRef = useRef();
  const endRef = useRef();

  useEffect(
    () => {
      startRef.current = props.startDate;
      endRef.current = props.endDate;
    },
    [props.startDate, props.endDate],
  );

  const handleChangeStart = (field: string, value: any) => {
    startRef.current = value;
    props.onChangeStart?.(field, value);
  };
  const handleChangeEnd = (field: string, value: any) => {
    endRef.current = value;
    props.onChangeEnd?.(field, value);
  };

  return (
    <Section title={props.title}>
      <View style={[style.spaceBetween, { marginTop: 10 }]}>
        <View style={{ flex: 1 }}>
          <DateTime
            initialValue={props.startDate}
            value={props.startDate}
            style={styles.datePicker}
            type={props.type}
            title={props.startTitle}
            color={color.primaryColor}
            onChangeValue={handleChangeStart}
            allowClear={props.allowClear}
            clearOnError
            onValidate={(_, value) => {
              if (endRef.current !== null && value) {
                if (
                  moment(value, 'DD/MM/YYYY').isAfter(
                    moment(endRef.current, 'DD/MM/YYYY'),
                  )
                ) {
                  return translate('dateRangeBeforeError');
                }
              }
            }}
          />
        </View>
        <View style={{ flex: 1 }}>
          <DateTime
            initialValue={props.endDate}
            value={props.endDate}
            style={styles.datePicker}
            type={props.type}
            title={props.endTitle}
            color={color.primaryColor}
            onChangeValue={handleChangeEnd}
            allowClear={props.allowClear}
            clearOnError
            onValidate={(_, value) => {
              if (startRef.current !== null && value) {
                if (
                  moment(value, 'DD/MM/YYYY').isBefore(
                    moment(startRef.current, 'DD/MM/YYYY'),
                  )
                ) {
                  return translate('dateRangeAfterError');
                }
              }
            }}
          />
        </View>
      </View>
    </Section>
  );
}

export default DateRange;
