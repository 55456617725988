// @flow

import React from 'react';
import { View, Text } from 'react-native';
import PropTypes from 'prop-types';

import styles from './styles';

const Section = ({ title, children, style }) => (
  <View style={[styles.container, style]}>
    {
      !!title &&
      <Text style={styles.title}>{title}</Text>
    }
    {children}
  </View>
);

Section.propTypes = {
    title: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),
    style: PropTypes.object,
};

export default Section;
