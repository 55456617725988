import React, { Component } from 'react';
import { Agenda, LocaleConfig } from 'react-native-calendars';
import moment from 'moment';
import { FloatingAction } from 'react-native-floating-action';
import style from '../../common/style';
import {
    Image,
    StyleSheet,
    View,
    Text,
    ActivityIndicator,
} from 'react-native';
import ClientsListItem from '../../scenes/clients/Item/ClientsListItem';
import Images from '../../../assets/Images';
import EmptyView from '../../components/EmptyView';
import Search from '../../components/Search';
import translate, { calendarLanguage, calendarNames } from '../../locales';
import TodayModulesPlaceholder from './components/TodayModulesPlaceholder';
import { color } from '~/common';
import _ from 'lodash';
import * as config from '../../config/config';
import { fonts } from '~/utils/Fonts';
// import MapView, { Marker, PROVIDER_GOOGLE, Callout } from 'react-native-maps';
import MapViewDirections from 'react-native-maps-directions';
import { CustomAlert } from '~/components';

import PropTypes from 'prop-types';
import {GoogleMap, Marker} from "react-google-maps";
import MyMapComponent from "~/screens/Today/components/MapView";

const styles = StyleSheet.create({
    knob: {
        tintColor: config.Colors.grayContainerDark,
        width: 20,
        height: 30,
        resizeMode: 'contain',
    },
    renderTimeContainer: {
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
    },
    renderIcon: {
        width: 24,
        height: 24,
    },
    renderTime: {
        fontSize: 12,
        fontWeight: '600',
        marginLeft: 2,
    },
    renderInfo: {
        flex: 1,
        marginLeft: 10,
        marginTop: 6,
    },
    renderLabel: {
        fontSize: 10,
        fontWeight: '500',
        color: config.Colors.gray,
    },
    renderAlert: {
        fontSize: 14,
        flex: 1,
        fontWeight: '500',
        color: config.Colors.grayDark,
        paddingVertical: 10,
    },
});

class Today extends Component {
    constructor(props) {
        super(props);
        this.mapRef = React.createRef();

        LocaleConfig.locales[`${calendarLanguage}`] = calendarNames;
        LocaleConfig.defaultLocale = `${calendarLanguage}`;
    }
    registerLayoutWorkaround(ref) {
        const calendarInstance = ref;
        if (!ref) {
            return;
        }
        calendarInstance.onLayout = event => {
            calendarInstance.viewHeight = event.nativeEvent.layout.height;
            calendarInstance.viewWidth = event.nativeEvent.layout.width;
            calendarInstance.calendar?.current?.scrollToDay(
                calendarInstance.state.selectedDay,
                calendarInstance.calendarOffset(),
                false,
            );
            calendarInstance.forceUpdate();
        };
    }

    rowHasChanged = (r1, r2) => !_.isEqual(r1, r2);

    renderTime = (label, time, icon, color) => (
        <View style={styles.renderTimeContainer}>
            <Text style={{ alignSelf: 'stretch' }}>
                <Image source={icon} style={styles.renderIcon} />
            </Text>
            <View style={styles.renderInfo}>
                <Text
                    style={[
                        styles.renderTime,
                        { color: color || config.Colors.simpleDark },
                    ]}>
                    {time}
                </Text>
                <Text style={styles.renderLabel}>{label}</Text>
            </View>
        </View>
    );

    renderDay = (day, item) => null;

    renderMapView = () => {
        const { schedule, date } = this.props;
        const scheduleByDate = schedule[date] || [];
        const origin = scheduleByDate.length > 0 ? scheduleByDate[0] : {};
        const destination =
            scheduleByDate.length > 0
                ? scheduleByDate[scheduleByDate.length - 1]
                : {};
        return(
        <MyMapComponent
            ref={this.mapRef}
            isMarkerShown
            onPressItem={this.props.onPressItem}
            positions={scheduleByDate}
        />
        );
    };

    renderEmpty = () => {
        if (this.props.loading || this.props.failure) {
            return (
                <View
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        alignSelf: 'center',
                        top: '50%',
                    }}>
                    <ActivityIndicator size="large" color={color.primaryColor} />
                    {this.props.failure && (
                        <Text style={styles.renderAlert}>{translate('waitALittle')}</Text>
                    )}
                </View>
            );
        }

        return !this.props.isMapView ? (
            <EmptyView
                icon={Images.iconCustomerBig}
                message={`${translate('noCustomersThisDay')}`}
            />
        ) : (
            this.renderMapView()
        );
    };

    renderItem = (item, firstItemInDay) => (
        <ClientsListItem
            showIsProcessDone={this.props.showIsProcessDone}
            onPressItem={this.props.onPressItem}
            {...this.props}
            index={item.index}
            item={item}
            showHTML={this.props.showHTML}
        />
    );

    render() {
        const {
            date,
            schedule,
            showSearch,
            loading,
            onPressDate,
            onChangeText,
            itineraryConfig,
            onSelectOffRouteCustomer,
            canRequestServiceOffRoute,
            shouldNotify,
            updateChildData,
            searchText,
        } = this.props;
        let minDate;
        let maxDate;
        if (itineraryConfig) {
            const { days_before, days_after } = itineraryConfig || {};
            minDate = days_before
                ? moment()
                    .subtract(days_before, 'days')
                    .format('YYYY-MM-DD')
                : undefined;
            maxDate = days_after
                ? moment()
                    .add(days_after, 'days')
                    .format('YYYY-MM-DD')
                : undefined;
        }

        const actions = [
            {
                text: 'Alterar rota',
                icon: (
                    <Image
                        source={Images.iconGroup}
                        style={{
                            tintColor: '#FFF',
                            marginBottom: 10,
                        }}
                    />
                ),
                name: 'bt_new_task',
                position: 'center',
            },
        ];

        if (
            loading &&
            (!schedule || Object.values(schedule)?.every(item => item?.length === 0))
        ) {
            return <TodayModulesPlaceholder />;
        }

        return (
            <View style={style.container}>
                {!!showSearch && (
                    <Search
                        onChangeText={onChangeText}
                        inputProps={{
                            defaultValue: searchText,
                        }}
                    />
                )}
                <Agenda
                    ref={ref => {
                        this.registerLayoutWorkaround(ref);
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
                    items={this.props.isMapView ? [] : loading ? {} : schedule}
                    onDayPress={onPressDate}
                    selected={date}
                    renderItem={this.renderItem}
                    renderKnob={() => (
                        <Image style={styles.knob} source={Images.iconChevronDown} />
                    )}
                    showClosingKnob
                    renderDay={this.renderDay}
                    renderEmptyDate={this.renderEmpty}
                    renderEmptyData={this.renderEmpty}
                    rowHasChanged={this.rowHasChanged}
                    markedDates={{ '': { marked: false } }}
                    theme={{
                        selectedDayBackgroundColor: '#074885',
                        todayTextColor: '#074885',
                    }}
                />

                {!!canRequestServiceOffRoute && (
                    <FloatingAction
                        overrideWithAction
                        actions={actions}
                        onPressItem={onSelectOffRouteCustomer}
                        color="#074885"
                    />
                )}
                {shouldNotify ? (
                    <CustomAlert
                        showModal
                        title={`${translate('atention')}`}
                        message={`${translate('weAreUpdatingYourData')}`}
                        okButtonTitle="Ok"
                        okButtonPress={() => {
                            updateChildData({ loading: true });
                        }}
                    />
                ) : null}
            </View>
        );
    }
}

Today.propTypes = {
    date: PropTypes.any,
    schedule: PropTypes.any,
    showSearch: PropTypes.any,
    showIsProcessDone: PropTypes.bool,
    itineraryConfig: PropTypes.any,
    loading: PropTypes.bool,
    failure: PropTypes.bool,
    collapseAgenda: PropTypes.bool,
    isMapView: PropTypes.bool,
    onChangeText: PropTypes.func,
    onPressItem: PropTypes.func,
    onPressDate: PropTypes.func,
    onSelectOffRouteCustomer: PropTypes.func,
    canRequestServiceOffRoute: PropTypes.bool,
    showHTML: PropTypes.bool,
    shouldNotify: PropTypes.bool,
    updateChildData: PropTypes.func,
};

export default Today;