/* @flow */
import React, { Component } from 'react';
import _, { create } from 'lodash';
import moment from 'moment';
import Activities from './Activities';
import HandlerActivityValidate from './ActivityValidateHandler';
import HandlerCheck from './Check/CheckValidateHandler';
import HandlerStopWatch from './Stopwatch/StopwatchValidateHandler';
import HandlerLocation from './Location/LocationHandler';
import ActivityTypes from './ActivityTypes';
import GpsHelper from '../../utils/GpsHelper';
import { connect } from 'react-redux';
import { Alert, Image, View, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  ACTIVITIES_FETCH_SUCCESS,
  getListActivities,
} from '../../store/activitiesFetch/slice';
import onPressHelper from '~/utils/onPressHelper';
import { setPushingScreen } from '../../store/pushingScreen/slice';
import {
  clearCurrentTask,
  setCurrentTask,
} from '../../store/taskCurrent/slice';
import Toast from 'react-native-root-toast';
import {
  SCREEN_CLIENT_DETAIL,
  SCREEN_TRADE,
  SCREEN_ACTIVITIES,
} from '../screens';
import {
  MODULE_ACTIVITIES,
  setModuleCurrent,
} from '~/store/makeOrderModule/slice';
import { OPENING_VISIT } from '../../store/visit/action';
import { syncUpload } from '../../TaskSync';
import ClientModule from '../../modules/ClientsModule';
import ActivitiesCache from '../../utils/ActivitiesCache';
import GeneralModule from '~/modules/GeneralModule';
import { emp2long } from '~/utils/FastSellerUtils';
import ActivitiesModule from '~/modules/ActivitiesModule';
import translate from '../../locales';
import TextUtils from '../../utils/TextUtils';
import getQueryResult from '../../services/resources/libFastSeller/genericQuery';
import executeActionOrOpenScreenMessage from '../../utils/messages';
import RouteSolicitationsResource from '../../services/resources/RouteSolicitationsResource';
import CachePolistore from '../../controllers/Accounts/Cache/CachePolistore';
import NavigationHelper from '../NavigationHelper';
import {
  SAVE_ANSWER_FETCH_FAILURE,
  SAVE_ANSWER_FETCH_REQUEST,
  SAVE_ANSWER_FETCH_SUCCESS,
  saveAnswer,
} from '~/store/saveAnswer/slice';
import {
  setCurrentClient,
  CLIENT_CURRENT_BY_ACTIVITIES_FETCH_REQUEST,
} from '~/store/clientCurrent/slice';
import { listPhotos } from '~/store/photoAnswerActivity/listPhotosSlice';
import { getListNewActivities } from '~/store/newActivitiesFetch/slice';
import Images from '@assets/Images';

import PropTypes from 'prop-types';
import AccountsModule from "~/modules/AccountsModule";
import {createNotifyError, createNotifySuccess} from "~/components/Web/ToastNotify";
import isEmpty from "lodash/isEmpty";
import CacheAccount from '~/controllers/Accounts/Cache/CacheAccount';
import { showAlert } from '~/components/Web/Alert';

const heapIdPai = [];

class ActivitiesContainer extends Component {
  constructor(props) {
    super(props);

    this.task = {};
    this.successSaveAnswer = () => {};
    this.failureSaveAnswer = () => {};
    this.task = {};
    this.updateAnswer = false;
    this.payloadAnswer = {};
    this.routeByitinerary = true;
    this.isVisible = false;
    this.handleBackPress = this.handleBackPress.bind(this);

    this.state = {
      location: {
        accuracy: null,
        altitude: null,
        heading: null,
        latitude: null,
        longitude: null,
        speed: null,
        timestamp: null,
      },
      showSearch: false,
      searchText: '',
      visiblePermission: false,
      dynamicActivities: [],
    };

    this.props.navigation.addListener('focus', () => {
      this.isVisible = true;
      this.props.dispatch(clearCurrentTask());
      this.props.dispatch(getListNewActivities());
    });
  }

  componentWillMount() {
    heapIdPai.push(0);
  }

  componentDidMount() {
    if (this.props.route.params.event) {
      const idParent = heapIdPai.slice(-1)[0];
      this.loadActivities(idParent);
    }
    this.iconsHeader();
  }

  iconsHeader = () => {
    this.props.navigation.setOptions({
        headerLeft: () => (
            <TouchableOpacity onPress={this.handleBackPress}>
                <Image
                    source={Images.iconBack}
                    style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
                />
            </TouchableOpacity>
        ),
        headerRight: () => (
            <View style={{ flexDirection: 'row' }}>
                <View>
                    <TouchableOpacity onPress={() => {}}>
                        <Image
                            source={Images.iconSearch}
                            style={{
                                tintColor: '#f0f0f0',
                                height: 20,
                                width: 20,
                                right: 20,
                            }}
                        />
                    </TouchableOpacity>
                </View>
            </View>
        ),
    });
  }

  handleBackPress() {
      const heapUpdated = heapIdPai.pop();
      if (heapUpdated) {
        this.props.navigation.setOptions({
          title: this.props.event.nome,
        });
        this.loadActivities(heapIdPai.slice(-1)[0]);
      } else {
        this.props.navigation.pop();
      }
      this.props.dispatch(setModuleCurrent(''));
      return true;
  }

  componentWillUnmount() {
    this.isVisible = false;
    this.setState({ showSearch: false, searchText: '' });
  }
  componentWillMount() {
    heapIdPai.push(0);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    (async () => {
      if (nextProps.listActivities.type === this.props.listActivities.type) {
        return;
      }
      if (nextProps.listActivities.type !== ACTIVITIES_FETCH_SUCCESS) {
        return;
      }

      this.props.dispatch(listPhotos());
    })();

    (() => {
      if (nextProps.client.type === this.props.client.type) {
        return;
      }
      if (
        nextProps.client.type !== CLIENT_CURRENT_BY_ACTIVITIES_FETCH_REQUEST
      ) {
        return;
      }
      const isVisible = this.props.navigation.isFocused();
      if (isVisible) {
        this.props.dispatch(setModuleCurrent(MODULE_ACTIVITIES));

        const dataSelected = this.props.route?.params;
        const event = this.props.route?.params?.event;

        const dataLoadActivities = {
          ...dataSelected,
          event,
        }

        AsyncStorage.setItem('DATA_LOAD_ACTIVITIES', JSON.stringify(dataLoadActivities));

        this.updateAnswer = true;
        this.props.navigation.navigate(SCREEN_CLIENT_DETAIL.name, {
          clientCurrent: nextProps.client.current,
          task: this.task,
          routeByitinerary: this.routeByitinerary,
          originScreen: SCREEN_ACTIVITIES.name,
        });
      }
    })();

    (async () => {
      if (nextProps.saveAnswer.type === this.props.saveAnswer.type) {
        return;
      }
      if (this.props.saveAnswer.type !== SAVE_ANSWER_FETCH_REQUEST) {
        return;
      }
      if (nextProps.saveAnswer.type !== SAVE_ANSWER_FETCH_SUCCESS) {
        return;
      }
      if (!this.updateAnswer) {
        return;
      }
      this.updateAnswer = false;

      this.loadActivities(heapIdPai.slice(-1)[0], true);
      const { event } = nextProps;

      if (!this.isVisible) {
        this.props.navigation.pop();
      }

      createNotifySuccess(translate('changesSavedSuccesfully'));

      this.successSaveAnswer();

      try {
        await this.onActivityAnswered(event);
      } catch (error) {
        console.log(error.message);
      }
    })();

    (() => {
      if (nextProps.saveAnswer.type === this.props.saveAnswer.type) {
        return;
      }
      if (nextProps.saveAnswer.type !== SAVE_ANSWER_FETCH_FAILURE) {
        return;
      }

      createNotifyError(translate('cantSaveAnswer') + ': ' + translate('pleaseTryAgain'));

      this.failureSaveAnswer();
    })();

    //   // TODO: Esse trecho escuta o termino do evento de sincronização.
    //   // Por enquanto o código não espera que a sincronização termine. Qunado
    //   // isso ocorrer descomentar o trecho a baixo.
    //   // (() => {
    //   //   if (nextProps.syncFetch.type === this.props.syncFetch.type) return;
    //   //   if (!this.updateAnswer) return;
    //   //   if (nextProps.syncFetch.payload.statusSync === SYNC_FINALLY &&
    //   //     nextProps.syncFetch.payload.screen === SCREEN_ACTIVITIES.name) {
    //   //     this.setState({ syncData: false });
    //   //     this.props.dispatch(syncUpdateScreen(''));
    //   //     this.saveAnswerActivitySyncFinaly(this.payloadAnswer);
    //   //   }
    //   // })();
  }

  onChangeTextSearch = searchText => {
    clearTimeout(this);
    setTimeout(() => {
      this.setState({ searchText });
    }, 400);
  };

  navigationButtonPressed({ buttonId }) {
    if (buttonId === 'backPress') {
      this.handleBackPress();
      return;
    }
    if (buttonId === 'search') {
      this.handleSearch();
      return;
    }
  }

  async onActivityAnswered(event) {
    const setup = JSON.parse(this.task.setup);
    const action = _.get(setup, 'action');

    if (action === 'update_status_prospect') {
      const actionParams = _.get(setup, 'action_params');
      if (actionParams && actionParams.length > 0) {
        const status = actionParams.find(p => p.key === 'status').value;

        if (status) {
          await this.updateStatusProspectLib(event, status);
        }
      }
    }
    this.props.dispatch(clearCurrentTask());
    await syncUpload();
  }

  onPressActivity = async item => {
    const { listActivities, pushingScreen = {} } = this.props;
    const { event } = this.props.route?.params;
    if (pushingScreen.status) {
      return;
    }

    const handler = this.getChainActivityValidate(item.tipo);
    const resultHandler = await handler.handle(
        item,
        listActivities,
        'opening',
        event,
    );
    const {
      canKeepGoing,
      allowRequest,
      message,
      loading,
      canOpenTask,
      propsAlert,
    } = resultHandler;

    if (!canKeepGoing) {
      if (allowRequest) {
        this.task = item;
        this.setState({ showBottomAlert: true, alertMessage: message });
      } else {
        executeActionOrOpenScreenMessage([{ tipo: 'erro', mensagem: message }]);
      }
    }

    if (canOpenTask) {
      this.openTask(item);
    } else {
      if (propsAlert) {
        this.alert(propsAlert);
      }
      this.setState({ loading });
    }
  };

  onPressActivityHelper = onPressHelper.debounce(this.onPressActivity);

  handleCreateNewItem = async item => {
    const createNewItem = await this.createNewActivity(item);

    if (createNewItem) {
      const newItem = JSON.parse(createNewItem);
      this.loadActivities(heapIdPai.slice(-1)[0]);
      return newItem;
    }
  };

  handleChooseNewActivity = async item => {
    try {
      const canAnswer = this.canAnswer();

      if (!item) {
        throw new Error("Couldn't find activity");
      }
      if (!canAnswer) {
        alert(
          `${translate('dynamicActivity')}\n${translate('activityCannotCreateWithNoProcess')}`
      );
        
        return;
      }
      const newItem = await this.handleCreateNewItem(item);
      createNotifySuccess(translate('activityCreatedSuccessfully'));

      const { id, id_criador, id_pai, id_processo, nome, pkey } = newItem;

      this.openTask({
        ...item,
        id_pai,
        pkey,
        id,
        name: nome,
        id_criador,
        id_processo,
        save_dynamic: true,
      });
    } catch (err) {
      createNotifyError(translate('errorWhenCreateActivity') + ': ' + err);
    }
  };

  getChainActivityValidate = type => {
    const handlerGeneric = new HandlerActivityValidate();

    const ACTIVITY_TYPE = {
      check: HandlerCheck,
      stopWatch: HandlerStopWatch,
      location: HandlerLocation,
    };
    if (type && Object.keys(ACTIVITY_TYPE).includes(type)) {
      const handlerSpecific = new ACTIVITY_TYPE[type]();
      handlerGeneric.nested.push(handlerSpecific);
    }
    return handlerGeneric;
  };

  getTask(taskId) {
    const activities = _.get(this.props.listActivities, 'payload', []);
    const task = _.find(activities, { id: taskId });

    return task;
  }

  getTaskOptions() {
    const groupId = emp2long('ATIV');
    const id = emp2long('NOVA');

    const promise = new Promise((resolve, reject) =>
        GeneralModule.getCustomList(
            `${groupId}`,
            `${id}`,
            '{}',
            false,
            null,
            resolve,
            reject,
        ),
    );

    return promise;
  }

  getFilteredData() {
    const { searchText } = this.state;
    const activities = _.get(this.props.listActivities, 'payload', []);

    const search = TextUtils.slugfy(searchText);

    return activities.filter(cat => {
      const res =
          (cat.nome && TextUtils.slugfy(cat.nome).includes(search)) ||
          (cat.descricao && TextUtils.slugfy(cat.descricao).includes(search));

      return res;
    });
  }

  alert = propsAlert => {
    const { title, message, actionBtns } = propsAlert;

    const buttons = actionBtns
        ? _.map(actionBtns, btn => {
          const { text, onPressRef = false, args = [] } = btn;
          let button = {};
          if (!onPressRef) {
            button = {
              text,
              onPress: () => {},
            };
          }
          if (onPressRef) {
            button = {
              text,
              onPress: () => {
                if (args.length === 0) {
                  this[onPressRef]();
                }
                if (args.length !== 0) {
                  this[onPressRef](...args);
                }
              },
            };
          }
          return button;
        })
        : []; 
    showAlert(title, message, buttons[1].onPress);
  };

  handleSearch = () =>
      this.setState({
        showSearch: !this.state.showSearch,
        searchText: '',
      });

  updateStatusProspectLib(prospect, status) {
    return new Promise((resolve, reject) => {
      ClientModule.updateStatusProspect(
          prospect.pkey.toString(),
          status,
          resolve,
          reject,
      );
    });
  }

  async createNewActivity(item) {
    const idParent = heapIdPai.slice(-1)[0];
    const { event, dateSelected } = this.props.route?.params;
    const { activeProcessId: process_id } = event;
    const { nome, descricao, tipo, setup } = item;
    const userData = JSON.parse(
        (await new CachePolistore().getParamsSignIn()) || '{}',
    );
    const formattedSetup = JSON.parse(setup || '{}');

    const activity = {
      id_parent: idParent,
      id_process: process_id,
      name: nome,
      description: descricao,
      kind: tipo,
      id_creator: userData.rca_codmaq,
      date: moment(dateSelected).unix(),
      setup: {
        ...formattedSetup,
        is_dynamic: true,
      },
    };

    return new Promise((resolve, reject) =>
        ActivitiesModule.createNewItem(JSON.stringify(activity), resolve, reject),
    );
  }

  canOpenVisit = async ({ action = '' }) => {
    let name;
    let canOpenVisit = true;
    try {
      const data = await ActivitiesCache.getVisitStatus();
      const { client = '', status = '' } = data;
      ({ name } = client);
      if (status && status === OPENING_VISIT) {
        if (
            (action === 'checkin' || action === 'checkout') &&
            client.pkey !== this.props.event.pkey
        ) {
          canOpenVisit = false;
        }
      }
    } catch (err) {}
    return { canOpenVisit, name };
  };

  _fetchInfoTimer = async () => {
    try {
      const info_timer = await AsyncStorage.getItem('info_timer');
      return JSON.parse(info_timer) || {};
    } catch (err) {}
  };

  loadActivities = async (idParent, forceFetch = false) => {
    const { event, dateSelected } = this.props.route?.params;
    const loadDataActivities = await AsyncStorage.getItem('DATA_LOAD_ACTIVITIES');
    const parseDataActivities = JSON.parse(loadDataActivities || '{}');


    const pkey = event?.pkey || parseDataActivities?.event?.pkey;
    const activeProcessId = event?.activeProcessId || parseDataActivities?.event?.activeProcessId;
    const idContexto = event?.id_contexto || parseDataActivities?.event?.id_contexto;
    const valueDateSelect = dateSelected || parseDataActivities?.dateSelected;
    if (!(idContexto || pkey || valueDateSelect)) {
      alert(`${translate('unavailableActivities')}`);
    } else {
      const userData = await new CacheAccount().getDataAccount() || '{}';
      const codmaq = userData?.user?.cod_maq || null;
      const params = {
        id_parent: idParent,
        date: valueDateSelect,
        id_context: idContexto,
        id_process: activeProcessId,
        key_context: pkey,
        id_creator: codmaq,
        forceFetch,
      };

      this.props.dispatch(getListActivities(params));
    }
  };

  onNewTaskNameChanged = name => {
    clearTimeout(this);
    setTimeout(() => {
      this.setState({ newTaskOptionName: name });
    }, 400);
  };

  isAnswered(taskId) {
    const task = this.getTask(taskId);
    return task && task.answer;
  }

  hasLocation() {
    return (
        this.state.location.latitude !== null &&
        this.state.location.longitude !== null
    );
  }

  verifyTaskDependency = dependency => {
    let task;
    let canOpenTask = true;
    let message = '';
    if (_.isObject(dependency)) {
      const unansweredTasks = [];
      const unansweredTasksNames = [];
      for (const id of dependency.ids) {
        const fTask = this.getTask(id);
        const answered = this.isAnswered(id);
        if (dependency.type === 'OR') {
          canOpenTask = answered;
          if (canOpenTask) {
            break;
          } else if (fTask) {
            unansweredTasks.push(fTask);
            const name = fTask && fTask.name ? fTask.name : '';
            unansweredTasksNames.push(name);
          }
        } else if (dependency.type === 'AND') {
          if (!answered && fTask) {
            unansweredTasks.push(fTask);
            const name = fTask && fTask.name ? fTask.name : '';
            unansweredTasksNames.push(name);
          }
        }
      }
      const { length } = unansweredTasks;
      if (dependency.type === 'AND') {
        canOpenTask = length === 0;
        message = `${translate(
            'itsNecessaryToAnswer',
        )} "${unansweredTasksNames.join(`, ${translate('and')} `)}" ${translate(
            'before',
        )}`;
      } else if (dependency.type === 'OR') {
        message = `${translate(
            'itsNecessaryToAnswer',
        )} "${unansweredTasksNames.join(`, ${translate('or')} `)}" ${translate(
            'before',
        )}`;
      }
      if (!canOpenTask) {
        task = unansweredTasks[length - 1];
      }
    } else {
      task = this.getTask(dependency);
      canOpenTask = this.isAnswered(dependency);
      const name = task && task.name ? task.name : '';
      message = `${translate('itsNecessaryToAnswer')} "${name}" ${translate(
          'before',
      )}`;
    }
    return { task, canOpenTask, message };
  };

  openTask = async item => {
    const isSection = item.tipo.localeCompare('section') === 0;
    if (isSection) {
      heapIdPai.push(item.id);
      this.loadActivities(item.id);
    } else {
      this.isVisible = false;
      this.props.dispatch(setPushingScreen(true));
      this.showTaskScreen(item);
    }
  };

  showTaskScreen(item) {
    const push = ActivityTypes[item.kind_key];
    const setup = item.setup ? JSON.parse(item.setup) : {};
    const { event, dateSelected } = this.props.route?.params;
    this.props.dispatch(
        setCurrentTask({
          ...item,
          event,
          componentId: this.props.componentId,
        }),
    );

    if (item.kind_key.localeCompare('check') === 0) {
      const { action } = setup;

      if (action) {
        if (action.localeCompare('order') === 0 && event) {
          this.task = item;
          this.props.dispatch(
              setCurrentClient({
                ...event,
                dateSelected,
                fromActivities: true,
              }),
          );
        } else if (action.localeCompare('trade') === 0) {
          this.goToProcessTrade(event);
        }
      }
    } else {
      this.task = item;
      let pushObject = Object.assign({}, push);
      delete pushObject.icon;
      pushObject = {
        ...pushObject,
        title: item.name,
        passProps: {
          dateSelected,
          item,
          event,
          location: this.state.location,
          saveAnswerActivity: this.saveAnswerActivity,
        },
      };
      this.updateAnswer = true;
      this.props.navigation.navigate(pushObject.screen, {
        screenTitle: item.save_dynamic || item.is_dynamic ? `${item.name} (${translate('dynamic')})` : item.name,
        pushObject: pushObject.passProps,
      });
    }
  }

  goToProcessTrade(event) {
    if (event && event.task_list_trade) {
      const eventTrade = {
        ...event,
        source: SCREEN_TRADE.name,
        task_list: event.task_list_trade,
      };

      NavigationHelper.push(
          this.props.componentId,
          SCREEN_ACTIVITIES.name,
          'Trade',
          {
            event: eventTrade,
            parentScreen: SCREEN_ACTIVITIES.name,
          },
      );
    }
  }

  validateActivity = async (taskId, originRequest) => {
    const task = taskId ? this.getTask(taskId) : '{}';
    const setup = task && task.setup ? JSON.parse(task.setup) : {};
    const { type, source, allow_request: allowRequest, origin } =
    setup.validation || {};
    let canKeepGoing = true;
    let message = '';

    if (origin === originRequest) {
      if (type === 't_vars' && source) {
        const pkeyClient = _.get(this.props, 'event.pkey');
        const params = {
          pk_cliente: pkeyClient,
          task_id: taskId,
        };

        try {
          const data =
              (await getQueryResult(source, JSON.stringify(params))) || '[]';
          const dataParsed = JSON.parse(data);
          const validationResult = _.get(dataParsed, ['0'], {}) || '{}';
          const { result, message: resultMessage } = validationResult || {};
          canKeepGoing = result.localeCompare(0) === 0;
          message = resultMessage;
        } catch (err) {
          console.log('validateActivity err: ', err);
        }
      }
    }

    return {
      canKeepGoing,
      message,
      allowRequest,
    };
  };

  obtainUserDataLib = () =>
      new Promise((res, rej) => {
        AccountsModule.obtainUserData(res, rej);
      });

  saveAnswerActivity = async (
      payloadAnswer: Object<any>,
      successSaveAnswer: Function<any>,
      failureSaveAnswer: Function<any>,
  ) => {
    this.successSaveAnswer = successSaveAnswer;
    this.failureSaveAnswer = failureSaveAnswer;

    const event = this.props.addressAltSelected?.payload ?? this.props.route?.params?.event;
    const { taskId } = payloadAnswer || {};

    const userData = await new CacheAccount().getDataAccount() || '{}';
    const codmaq = userData?.user?.cod_maq || null;

    if (!codmaq) {
      failureSaveAnswer();
      return;
    }

    const { canKeepGoing, message, allowRequest } = await this.validateActivity(
        taskId,
        'saving',
    );
    if (canKeepGoing) {
      const location = _.get(payloadAnswer.payload, 'location', {});

      let onClientArea = false;
      if (
          location.latitude &&
          location.longitude &&
          location.accuracy &&
          event?.latitude &&
          event?.longitude &&
          event?.raio
      ) {
        onClientArea = GpsHelper.isPresentInArea(
            event,
            event?.raio,
            location,
            location.accuracy,
        );
      }

      const payload = {
        ...payloadAnswer,
        payload: {
          ...payloadAnswer.payload,
          on_client_area: onClientArea,
        },
        idProcess: this.task.id_processo,
        idTask: this.task.id,
        idContext: event?.id_contexto,
        keyValueContext: event?.pkey,
        codmaq,
        task: this.task,
      };
      this.props.dispatch(saveAnswer(payload));
    } else {
      failureSaveAnswer();

      if (allowRequest) {
        this.setState({ showBottomAlert: true, alertMessage: message });
      } else {
        executeActionOrOpenScreenMessage([{ tipo: 'erro', mensagem: message }]);
      }
    }
  };

  onHideModal = () => this.setState({ showBottomAlert: false });

  canAnswer() {
    const { listActivities } = this.props;
    const { payload = [] } = listActivities;
    return (
        payload.filter(activity => activity.blocked).length !== payload.length - 1
    );
  }
  handleSendRequest = async () => {
    const { task } = this;
    this.setState({ requesting: true });
    const pkeyClient = _.get(this.props, 'event.pkey');
    const taskSetup =
        task.setup && typeof task.setup === 'string'
            ? JSON.parse(task.setup)
            : task.setup;
    const requestType = taskSetup.request_type || '';

    RouteSolicitationsResource.sendSolicitation(pkeyClient, requestType, {
      task: task.id,
    })
        .then(() => {
          createNotifySuccess('Solicitação enviada com sucesso');
        })
        .catch(() => {
          createNotifyError('Não foi possível enviar a solicitação');
        })
        .finally(() => this.setState({ requesting: false }));
  };

  render() {
    const {
      showSearch,
      showBottomAlert,
      alertMessage,
      requesting,
    } = this.state;

    // TODO: avaliar a necessidade do parentScreen quando for realizar os ajuste
    // do trade para o novo modelo de atividades
    const { listActivities, newActivities } = this.props;

    return (
        <Activities
            loading={listActivities.loading}
            requesting={requesting}
            data={this.getFilteredData()}
            onPressActivity={this.onPressActivityHelper}
            onNewItemButtonPressed={this.onNewItemButtonPressed}
            onPressNewActivityItem={this.handleChooseNewActivity}
            onNewTaskNameChanged={this.onNewTaskNameChanged}
            showSearch={showSearch}
            onChangeTextSearch={this.onChangeTextSearch}
            onHideModal={this.onHideModal}
            showBottomAlert={showBottomAlert}
            alertMessage={alertMessage}
            handleSendRequest={this.handleSendRequest}
            newActivitiesData={newActivities}
            canAnswer={this.canAnswer()}
        />
    );
  }
}

ActivitiesContainer.propTypes = {
    componentId: PropTypes.string,
    route: PropTypes.object,
    navigation: PropTypes.object,
    listActivities: PropTypes.object,
    pushingScreen: PropTypes.object,
    dispatch: PropTypes.func,
    event: PropTypes.object,
    addressAltSelected: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    listActivities: state.activitiesFetch,
    newActivities: state.newActivitiesFetch.payload,
    configLib: state.configLibFetch,
    syncFetch: state.syncFetch,
    saveAnswer: state.saveAnswer,
    client: state.clientCurrent,
    permission: state.permissionsFetch,
  };
}

export default connect(mapStateToProps)(ActivitiesContainer);