import { createAsyncThunk } from '@reduxjs/toolkit';
import { cancelOrderActive } from './utilsLib';
import CacheOrderActivity from '../../utils/CacheOrderActivity';

export const CANCEL_CART_FETCH_REQUEST = 'CANCEL_CART_FETCH_REQUEST';
export const CANCEL_CART_FETCH_SUCCESS = 'CANCEL_CART_FETCH_SUCCESS';
export const CANCEL_CART_FETCH_FAILURE = 'CANCEL_CART_FETCH_FAILURE';

export const cancelCartActiveFetch = createAsyncThunk(
  'orderFetch/cancelCartActiveFetch',
  async () => {
    try {
      const data = await cancelOrderActive();
      const cart = JSON.parse(data);
      return cart;
    } catch (error) {
      console.log('error', error);
      throw new Error(error.message);
    } finally {
      await CacheOrderActivity.clearOrderActivity();
    }
  },
);
