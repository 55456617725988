import { StyleSheet } from 'react-native';
import { colors } from '../../../components_base/src/values';

const styles = StyleSheet.create({
  messageContainer: {
    flex: 1,
    padding: 25,
    marginTop: 15,
  },
  messageTitle: {
    fontSize: 22,
    fontWeight: 'bold',
    color: colors.primaryColor,
  },
  message: {
    fontSize: 17,
    lineHeight: 24,
    marginVertical: 20,
  },
  messageButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: 15,
  },
  messageButton: {
    paddingHorizontal: 20,
    paddingVertical: 8,
    marginLeft: 10,
  },
  messageButtonText: {
    color: colors.primaryColor,
    fontSize: 16,
    fontWeight: 'bold',
  },
  messageButtonRight: {
    backgroundColor: colors.primaryColor,
  },
  messageButtonTextRight: {
    color: '#fff',
  },
});

export default styles;
