/* @flow */

import React, { Component } from 'react';
import Images from '@assets/Images';
import { connect } from 'react-redux';
import _ from 'lodash';
import { SYNC_FINALLY } from '../../constants/Sync';
import NewClients from '~/scenes/NewClients/NewClients';
import { newClientsFetch } from '../../store/newClientsFetch/newClientsFetchSlice';
import CacheAccount from '~/controllers/Accounts/Cache/CacheAccount';
import UploadAtividades from '~/services/resources/UploadAtividades';
import {
  SCREEN_ADD_CLIENT,
  SCREEN_CART,
  SCREEN_COMPOUND_PRODUCT,
  SCREEN_HOME,
  SCREEN_NEW_CLIENTS,
  SCREEN_PRODUCT_DETAIL,
  SCREEN_RESUME_ORDER,
} from '../screens';
import TextUtils from '../../utils/TextUtils';
import translate from '../../locales';
import onPressHelper from '~/utils/onPressHelper';
import {
  ORDER_FETCH_REQUEST,
  ORDER_FETCH_SUCCESS,
  orderClient,
  updateOrderScreen,
  updateStatusOpenOrder,
} from '~/store/ordersFetch/orderFetchSlice';
import executeActionOrOpenScreenMessage from '~/utils/messages';
import { updateItemFetch } from '~/store/ordersFetch/updateItemSlice';
import { setPushingScreen } from '~/store/pushingScreen/slice';
import OrdersModule from '~/modules/OrdersModule';
import { setModuleCurrent } from '~/store/makeOrderModule/slice';
import { setCurrentClientNoThunk } from '~/store/clientCurrent/slice';
import listGeneric from '~/services/resources/libFastSeller/listGeneric';
import { emp2long } from '~/utils/FastSellerUtils';
import ClientsModule from '~/modules/ClientsModule';
import { setBackRootShowModal } from '~/store/backRootShowModal/slice';

import PropTypes from 'prop-types';
import { Image, TouchableOpacity } from 'react-native';
import { createNotifyError, createNotifyInfo, createNotifySuccess } from '~/components/Web/ToastNotify';

class NewClientsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSearch: false,
      searchText: '',
      allowOrder: false,
      loading: false,
    };
    this.mainRef = null;

    this.props.navigation.addListener('focus', () => {
      if (this.mainRef !== null) {
        const isByInductionMultiTabs = this.mainRef.multiTabsRef !== null;
        if (isByInductionMultiTabs) {
          this.mainRef.multiTabsRef.loadMultiTabs();
        } else {
          this.props.dispatch(newClientsFetch());
        }
      }
    });
  }

  componentDidMount() {
  ClientsModule.allowPcadOrder().then(resp => {
    const result = resp ? JSON.parse(resp) : null;
    const habilita_pedido = result ? result.habilita_pedido : false;
    this.setState({ allowOrder: habilita_pedido });
  });
    this.props.navigation.setOptions({
      headerLeft: () => (
        <TouchableOpacity onPress={() => {
          this.props.navigation.pop();
        }}
        >
          <Image
            source={Images.iconBack}
            style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
          />
        </TouchableOpacity>
      ),
      headerRight: () => (
        <TouchableOpacity onPress={() => {
          this.setState({ showSearch: !this.state.showSearch, searchText: '' });
        }}
        >
          <Image
            source={Images.iconSearch}
            style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 10 }}
          />
        </TouchableOpacity>
      ),
    });
  }

  navigationButtonPressed({ buttonId }) {
    if (buttonId === 'add') {
      this.onClickAdd();
    }
    if (buttonId === 'search') {
      this.setState({ showSearch: !this.state.showSearch, searchText: '' });
    }
  }

  UNSAFE_componentWillReceiveProps({ photoAnswer, order }) {
    (async () => {
      if (order.type === this.props.order.type) {
        return;
      }
      if (this.props.order.type !== ORDER_FETCH_REQUEST) {
        return;
      }
      const currentScreen = this.props.currentScreenVisible.screen;
      if (currentScreen !== this.props.componentId) {
        return;
      }
      if (order.type === ORDER_FETCH_SUCCESS) {
        const { payload } = order;
        const { condvenda, codigo } = payload;
        const messages = _.get(payload, 'mensagens') || [];
        this.setState({ loading: false }, () => {
          executeActionOrOpenScreenMessage(
            messages,
            () => this.actionOrderClientError(messages, codigo),
            () => this.actionOrderClientNotError(condvenda, codigo),
          );
        });
      }
    })();
  }

  actionOrderClientNotError = async (condvenda, codigo) => {
    const { configLib } = this.props;
    const showResumeFirst = _.get(
      configLib,
      'configuracoes.exibir_cvenda_abertura',
      false,
    );

    await this.saveEventOpenOrder(codigo);
    if (showResumeFirst) {
      this.showModalResume();
    }
    this.openCartScreen();
  };

  addItem = (item, quantity) => {
    const codcliente = _.get(this.props.order, 'payload.condvenda.codcliente');
    const itemCopy = { ...item };
    itemCopy.quantidade = quantity;
    const payloadAddItem = {
      clientCode: codcliente,
      itemKey: itemCopy.chave.toString(),
      item: itemCopy,
    };

    this.props.dispatch(updateItemFetch(payloadAddItem));
    this.props.dispatch(updateOrderScreen(SCREEN_HOME.name));
  };

  showModalResume() {
    this.props.navigation.navigate(SCREEN_RESUME_ORDER.name, {
      comesFromClientDetail: true,
      addItem: this.addItem,
      productClick: this.productClick,
    });
    this.props.dispatch(setBackRootShowModal(this.props.componentId));
  }

  saveEventOpenOrder(pkeyOrder) {
    const { location } = this.state;

    return new Promise((resolve, reject) =>
      OrdersModule.saveEventOpenOrder(
        pkeyOrder,
        JSON.stringify(location || {}),
        resolve,
        reject,
      ),
    );
  }

  productClick = item => {
    const pushingScreen = this.props.pushingScreen || {};
    if (pushingScreen.status) {
      return;
    }

    this.props.dispatch(setPushingScreen(true));

    const screen =
      item.tipo === 'produto'
        ? SCREEN_PRODUCT_DETAIL.name
        : SCREEN_COMPOUND_PRODUCT.name;
    this.props.navigation.navigate(screen, {
      addItem: this.addItem,
      product: item,
      screen,
      pageDefault: 1,
      cameByShowModal: true,
    });
  };

  openCartScreen() {
    const originScreen = this.props.originScreen || this.props.componentId;
    this.props.dispatch(setModuleCurrent(''));
    this.props.dispatch(updateStatusOpenOrder(false));
    this.props.dispatch(setCurrentClientNoThunk(this.pkeyClient));
    this.props.navigation.navigate(SCREEN_CART.name, {
      screen: SCREEN_NEW_CLIENTS.name,
      routeByitinerary: false,
      actualClient: this.pkeyClient,
      originScreen,
    });
    this.props.dispatch(setBackRootShowModal(this.props.componentId));
  }

  actionOrderClientError = async (messages, codigo) => {
    const messagesErrors = messages.filter(m => m.tipo === 'erro');
    const condSaleInvalid = messagesErrors.find(
      m => m.codigo === 'condicoes_venda_invalida',
    );
    if (condSaleInvalid && messagesErrors.length === 1) {
      try {
        this.showModalResume();
        await this.saveEventOpenOrder(codigo);
        this.openCartScreen();
      } catch (err) {}
    }
  };

  syncPhotos = async () => {
    const cacheAccount = new CacheAccount();
    const dataAccount = await cacheAccount.getDataAccount();
    const dataUserDevice = await cacheAccount.getUserDevice();
    const syncStatus = _.get(this.props.syncFetch, 'payload.statusSync');

    if (syncStatus === SYNC_FINALLY) {
      const uploadAtividades = new UploadAtividades(
        dataAccount,
        dataUserDevice,
        () => {
          createNotifySuccess(`${translate('photosSuccessfullySynced')}`);
          this.setState({ notSynchronizedPhotosSize: 0 });
        },
        () => {
          createNotifyError(`${translate('errorSyncPhotos')}`);
        },
      );
      uploadAtividades.upload();
    }
  };

  onHideModal = () => {
    if (this.failureSendRequest) {
      this.failureSendRequest();
    }
    this.setState({ modalVisible: false });
  };

  onClickAdd = () => {
    this.props.navigation.navigate(SCREEN_ADD_CLIENT.name);
  };

  onClickAddHelper = onPressHelper.debounce(this.onClickAdd);

  onChangeText = text => {
    clearTimeout(this);
    setTimeout(() => {
      this.setState({ searchText: TextUtils.slugfy(text) });
    }, 350);
  };

  searchIn(list, search) {
    let res = false;
    list.map(item => {
      res =
        res ||
        TextUtils.slugfy(item.valor)
          .toLowerCase()
          .trim()
          .includes(search);
      return item;
    });
    return res;
  }

  onSelectJoker = (_, idx) => {
    const { jokerClients } = this.state;
    this.setState({ loading: true }, () => {
      this.pkeyClient = jokerClients?.[idx];
      this.mainRef?.RbSheetRef?.close();
      const payload = {
        codigo: '0',
        pkeyClient: this.pkeyClient?.pkey || this.pkeyClient?.chave,
        condvenda: {},
        orcamento: false,
        pkPcad: this.pcadPkey,
      };
      this.props.dispatch(orderClient(payload));
    });
  };

  onLongPressItem = client => {
    if (this.state.allowOrder) {
      try {
        const tamMemory = _.get(this.props.configLib, 'tam_memoria_paginacao');
        this.pcadPkey = client.pkey || client.chave;

        this.setState({ loading: true }, async () => {
          const resp = await listGeneric(
            emp2long('CCOR'),
            emp2long('CCPR'),
            {},
            tamMemory,
          );

          this.setState(
            {
              jokerClients:
                resp?.data?.map(i => ({
                  ...i,
                  label: i.nome,
                  value: i.pkey || i.codigo || i.chave,
                })) ?? [],
            },
            () => {
              this.mainRef?.RbSheetRef?.open();
            },
          );
        });
      } catch (err) {
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  onPressItem = async item => {
    if (item.chave || item.pkey) {
      if (item.editavel === 'false') {
        createNotifyInfo('Não é possível editar esse pré-cadastro');
        return;
      }
      this.props.navigation.navigate(SCREEN_ADD_CLIENT.name, {
        item,
      });
    }
  };

  onPressItemHelper = onPressHelper.debounce(this.onPressItem);

  getFilteredData() {
    const { newClients } = this.props;
    const search = `${this.state.searchText || ''}`.toLowerCase().trim();
    return newClients?.payload?.filter(item => {
      const { pkey, t_data: tData, nome } = item;
      const res =
        (pkey &&
          pkey
            .toLowerCase()
            .trim()
            .includes(search)) ||
        (tData &&
          TextUtils.slugfy(tData)
            .toLowerCase()
            .trim()
            .includes(search)) ||
        (nome &&
          TextUtils.slugfy(nome)
            .toLowerCase()
            .trim()
            .includes(search));
      return res;
    });
  }

  render() {
    const { configLib, navigator, newClients } = this.props;

    return (
      <NewClients
        ref={ref => (this.mainRef = ref)}
        newClients
        multiTabsRef={this.multiTabsRef}
        showSearch={this.state.showSearch}
        loading={newClients?.loading || this.state.loading}
        onLongPressItem={this.onLongPressItem}
        onPressItem={this.onPressItemHelper}
        onPressAdd={this.onClickAddHelper}
        onChangeText={this.onChangeText}
        clients={this.getFilteredData()}
        jokerClients={this.state.jokerClients}
        onSelectJoker={this.onSelectJoker}
        // MultiTabs Props
        configLib={configLib}
        navigator={navigator}
      />
    );
  }
}

NewClientsContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    newClients: PropTypes.shape({
        payload: PropTypes.array,
        loading: PropTypes.bool,
        type: PropTypes.string,
    }).isRequired,
    configLib: PropTypes.object,
    order: PropTypes.shape({
        type: PropTypes.string,
        payload: PropTypes.object,
    }).isRequired,
    pushingScreen: PropTypes.object,
    componentId: PropTypes.number.isRequired,
    originScreen: PropTypes.number,
    photoAnswer: PropTypes.shape({
        type: PropTypes.string,
    }).isRequired,
    currentScreenVisible: PropTypes.shape({
        screen: PropTypes.string,
    }).isRequired,
};

function mapStateToProps(state) {
  return {
    newClients: state.newClientsFetch,
    configLib: state.configLibFetch?.payload,
    currentScreenVisible: state.currentScreenVisible,
    photoAnswer: state.savePhotoAnswer,
    syncFetch: state.syncFetch,
    order: state.orderFetch,
    client: state.clientCurrent,
  };
}

export default connect(mapStateToProps)(NewClientsContainer);
