import { StyleSheet } from 'react-native';
import { color } from '~/common';

const styles = StyleSheet.create({
  containerImage: { alignItems: 'center', width: 80 },
  containerPDF: { alignItems: 'center', width: 60 },
  iconContainerImage: { height: 60, width: 60 },
  iconContainerPDF: { height: 60, width: 60, tintColor: color.grayRed },
  text: { fontSize: 8 },
});

export default styles;
