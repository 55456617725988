import React, { Component } from 'react';
import Notifications from './Notifications';
import ClientsModule from '../../modules/ClientsModule';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NavigationHelper from '../NavigationHelper';
import { getNotifications } from '~/store/notifications/slice';

class NotificationsContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.dispatch(getNotifications());
    ClientsModule.markAsRead(null).then();
  }

  async onNavigatorEvent(event) {
    switch (event.id) {
      case 'backPress':
        this.handleBackPress();
        break;
      default:
        break;
    }
  }

  handleBackPress = () => {
    NavigationHelper.pop(this.props.componentId);
    return true;
  };

  validateNotification = notification => {
    if (Array.isArray(notification)) {
      if (notification.length > 0 && notification[0]?.tipo === 'erro') {
        return [];
      }
      return notification;
    }
    return [];
  }

  render() {
    const { notifications } = this.props;
    return (
      <Notifications
        loading={notifications.loading}
        items={this.validateNotification(notifications.payload)}
      />
    );
  }
}

NotificationsContainer.propTypes = {
  notifications: PropTypes.object,
  navigator: PropTypes.shape({
    pop: PropTypes.func,
    addOnNavigatorEvent: PropTypes.func,
  }),
};

function mapStateToProps(state) {
  return {
    notifications: state.notifications,
  };
}

export default connect(mapStateToProps)(NotificationsContainer);
