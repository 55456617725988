/* @flow */

import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { color } from '~/common';

import { connect } from 'react-redux';
import { getCurrencyFormatter, parseFloatToCurrency } from '~/utils/Currency';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
  info: {
    fontSize: 13,
    color: color.grayMid,
  },
  value: {
    fontSize: 16,
    color: '#000',
  },
});

const OrderInfo = ({ info, value, type = '' }) => {
  const moneyFormatter = getCurrencyFormatter(type, {
    noSymbolCurrency: true,
  });
  const parsedValue = parseFloatToCurrency(value, {
    type,
    moneyFormatter,
  });

  return (
    <View style={styles.container}>
      <Text style={styles.info}>{info}</Text>
      <Text style={styles.value}>{type ? parsedValue : value}</Text>
    </View>
  );
};

OrderInfo.propTypes = {
    info: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string,
};

export default connect()(OrderInfo);
