import React, { useState } from 'react';

export default function DateTimePicker({ isVisible, minimumDate, value, onConfirm, onCancel, index }) {
  const [selectedDate, setSelectedDate] = useState(value ? new Date(value).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]);

  const handleConfirm = (event) => {
    const date = event.target.value;
    setSelectedDate(date);
    onConfirm(new Date(date), index);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div>
      <input
        type="date"
        value={selectedDate}
        min={new Date(minimumDate).toISOString().split('T')[0]}
        onChange={handleConfirm}
      />
      <button onClick={onCancel}>Cancel</button>
    </div>
  );
}
