import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Modal, Image, ScrollView, Text, View } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import { color } from '~/common';
import PropTypes from 'prop-types';
import { RadioButton, RadioGroup } from '~/components/Web/RadioGroupData';
import Images from '../../../assets/Images';
import styles from './sorterStyles';
import translate from '~/locales';

type Props = {
  config?: any,
  onChangeOption?: (item: any) => void,
  orderComponentId?: number,
  filtersValues?: any,
};

export default function Sorter({
  orderComponentId,
  config,
  onChangeOption,
  filtersValues,
}: Props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeSort, setActiveSort] = useState({});

  const hasExternalFilter =
    filtersValues.activeSort !== undefined &&
    filtersValues.selectedOrder !== undefined;

  const selectedOption = useMemo(
    () => config?.findIndex(i => i.id === activeSort?.id),
    [activeSort, config],
  );

  const selectedIndexConsideringExternalFilter = useMemo(
    () => {
      if (hasExternalFilter) {
        const orderKey = filtersValues.selectedOrder.value;
        const sortKey = filtersValues.activeSort.field;

        return config?.findIndex(
          i => i.sort === orderKey && i.field === sortKey,
        );
      }
      return selectedOption;
    },
    [config, filtersValues, hasExternalFilter, selectedOption],
  );

  const sortLabel = useMemo(
    () => {
      if (selectedIndexConsideringExternalFilter !== -1) {
        return config[selectedIndexConsideringExternalFilter].label;
      }
      if (hasExternalFilter) {
        return `${filtersValues.activeSort.title} (${
          filtersValues.selectedOrder.name
        })`;
      }

      return activeSort.label;
    },
    [
      activeSort,
      config,
      filtersValues,
      hasExternalFilter,
      selectedIndexConsideringExternalFilter,
    ],
  );

  useEffect(() => {
    onChange(0);
  }, []);

  const onChange = idx => {
    setActiveSort(config?.[idx]);
    onChangeOption(config?.[idx]);
    setIsModalVisible(false);
  };

  return (
    <>
      {config?.length > 0 ? (
        <View style={styles.viewContainer}>
          <Text style={{ marginRight: 8 }}>{`${translate('orderedBy')}`}</Text>
          <Touchable
            onPress={() => {
              setIsModalVisible(true);
            }}
           >
            <View style={styles.buttonView}>
              <Text style={styles.buttonFilter}>{sortLabel}</Text>
              <Image
                source={Images.iconExpandArrow}
                tintColor={color.primary600}
              />
            </View>
          </Touchable>
        </View>
      ) : null}

      <Modal
        visible={isModalVisible}
        onBackButtonPress={() => {
          setIsModalVisible(false);
        }}
        onBackdropPress={() => {
          setIsModalVisible(false);
        }}
        animationIn="fadeIn"
        animationOut="fadeOut"
        transparent>
        <View style={styles.modal}>
          <Text style={styles.labelModal}>{`${translate('orderBy')}:`}</Text>
          <ScrollView style={styles.scrollView}>
            <RadioGroup
              selectedIndex={selectedIndexConsideringExternalFilter}
              onSelect={onChange}
              thickness={2}>
              {config?.map(item => (
                <RadioButton
                  style={styles.radioBtn}
                  key={item.id}
                  value={item.id}>
                  <View style={styles.optView}>
                    <Text style={styles.optLabel}>{item.label}</Text>
                    <Text style={styles.optDescription}>
                      {item.description}
                    </Text>
                  </View>
                </RadioButton>
              ))}
            </RadioGroup>
          </ScrollView>
        </View>
      </Modal>
    </>
  );
}

Sorter.defaultProps = {
  config: [],
  onChangeOption: () => {},
};

Sorter.propTypes = {
  config: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  onChangeOption: PropTypes.func,
};
