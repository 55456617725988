/* @flow */

import React from 'react';
import find from 'lodash/find';
import get from 'lodash/get';
import { Text, View, StyleSheet, Image } from 'react-native';
import { style, color } from '~/common';
import Quantity from '../components/Quantity';
import { MAGNITUDES_ONLY_PRODUCT } from '../../../constants/rulesProductMagnitudes';
import { fonts } from '../../../utils/Fonts';
import Images from '../../../../assets/Images';
import translate from '../../../locales';
import { Price, CalculatedValue } from '~/../components_base/src/components';
import { RadioGroupData } from '~/components';
import Touchable from 'react-native-platform-touchable';

import PropTypes from 'prop-types';


const styles = StyleSheet.create({
    dividerInfo: {
        width: 1,
        height: 30,
        backgroundColor: color.grayContainer,
    },
    productName: {
        fontSize: 16,
        marginBottom: 6,
        marginLeft: 10,
        marginRight: 5,
        fontFamily: fonts.QuicksandBold,
        color: color.grayDarkest,
        width: 310,
    },
    productMultiple: {
        fontSize: 14,
        marginLeft: 10,
        marginRight: 10,
        color: color.grayDark,
    },
    productSubTotal: {
        fontSize: 14,
        marginRight: 10,
        color: '#000',
    },
    subTotalContainer: {
        flex: 1,
        flexDirection: 'row',
        borderTopColor: color.grayContainerDark,
        borderTopWidth: 1,
        paddingVertical: 6,
        marginHorizontal: 12,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    subTotalTitle: {
        color: color.grayDark,
        fontFamily: fonts.QuicksandBold,
        fontSize: 14,
    },
    productPrice: {
        fontSize: 18,
        marginLeft: 10,
        marginRight: 10,
        marginTop: 10,
        color: '#000',
        fontWeight: '600',
    },
    estoqueContainer: {
        flex: 1,
        flexDirection: 'row',
        marginRight: 10,
    },
    iconError: {
        tintColor: '#FFF',
        width: 16,
        height: 16,
    },
    badgeError: {
        width: 24,
        height: 24,
        borderColor: '#fff',
        borderWidth: 1,
        borderRadius: 12,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#ff000f',
    },
});

function Magnitudes({ produto }) {
    if (produto.type_magnitudes === MAGNITUDES_ONLY_PRODUCT) {
        return produto.unidade_macro > 1 ? (
            <Text style={styles.productMultiple}>
                {produto.descricao_unidade_macro} {`${translate('contains')}`}{' '}
                {produto.unidade_macro}
            </Text>
        ) : null;
    }

    return produto.unidade > 1 ? (
        <Text style={styles.productMultiple}>
            {produto.descricao_unidade} {`${translate('contains')}`} {produto.unidade}
        </Text>
    ) : null;
}

const RenderEstoque = ({ product, configLib }) => {
    const hiddenFields =
        get(configLib, 'listagem_campos_visibilidade.product.hidden_fields') ||
        {};
    const showEstoque = !hiddenFields.estoque;

    const { estoque } = product;
    const styleColor = {};
    let image = null;

    if (showEstoque) {
        if (estoque === 0) {
            styleColor.color = '#ff000f';
            image = (
                <View style={styles.badgeError}>
                    <Image style={styles.iconError} source={Images.iconAlertError} />
                </View>
            );
        }
    }

    return showEstoque ? (
        <View style={styles.estoqueContainer}>
            <Text style={[styles.productMultiple, styleColor]}>
                {estoque} {`${translate('inStock')}`}
            </Text>
            {image}
        </View>
    ) : null;
};

export const TotalPrice = ({
    price,
    toRecalculate,
    precototal,
    stockExists,
    stock,
    recalculatePrice,
}) => {
    let view = null;
    if (price > 0) {
        if (toRecalculate) {
            view = (
                <Touchable onPress={recalculatePrice}>
                    <View>
                        <Text
                            style={{
                                fontSize: 17,
                                color: color.primaryColorLight,
                                borderColor: color.primaryColorLight,
                                borderWidth: 1,
                                borderRadius: 5,
                                paddingHorizontal: 8,
                                paddingVertical: 5,
                                fontWeight: '400',
                            }}>
                            Recalcular
                        </Text>
                    </View>
                </Touchable>
            );
        } else {
            view = (
                <Text style={styles.productSubTotal}>
                    <CalculatedValue
                        value={precototal || 0}
                        style={styles.productPrice}
                    />
                </Text>
            );
        }
    } else {
        view = (
            <Text style={styles.productPrice}>
                {stockExists && stock <= 0
                    ? `${translate('productNotAvailable')}`
                    : `${translate('priceNotAvailable')}`}
            </Text>
        );
    }

    return view;
};

const ProductTopInfo = (props) => {
    const {
        product,
        quantity,
        volumeFactor,
        setQuantity,
        addItem,
        magnitudes,
        magnitudeSelect,
        updateProductMagnitude,
        updatingItem,
        ignoreStock,
        ignoreVolumeFactor,
        configLib,
        setEditing,
        toRecalculate,
        recalculatePrice,
    } = props;
    const stockExists = product.stock !== undefined && product.stock !== null;

    let valueSelected = -1;

    if (magnitudeSelect?.value) {
        valueSelected = magnitudeSelect.value;
    } else if (magnitudes?.length) {
        const [magnitude] = magnitudes;
        valueSelected = magnitude.value;
    }

    const selectedMagnitude = find(
        magnitudes,
        mag => mag.value === magnitudeSelect.value,
    );

    return (
        <View style={{ flex: 1, marginTop: 8 }}>
            <Text style={[styles.productMultiple, { fontWeight: '300' }]}>
                Cód..:{product.codigo}
            </Text>
            <View style={{ flexDirection: 'row', width: '100%' }}>
                <Text style={styles.productName}>
                    {product.nome || product.descricao || ''}
                </Text>
            </View>
            <RenderEstoque product={product} configLib={configLib} />
            <View style={{ bottom: 35 }}>
                <Magnitudes produto={product} />
            </View>
            <View style={[style.spaceBetween, { marginBottom: 10, bottom: 20 }]}>
                <View style={{ flex: 1 }}>
                    <Text style={[styles.productMultiple, { fontSize: 16, flex: 1 }]}>
                        <Price value={product.precobase || 0} style={styles.productPrice} />
                    </Text>
                </View>
            </View>
            <View style={styles.subTotalContainer}>
                <View>
                    <View
                        style={[
                            style.spaceBetween,
                            {
                                marginBottom: 10,
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            },
                        ]}>
                        <Text>Inserir Por</Text>
                        <RadioGroupData
                            valueSelected={valueSelected}
                            itens={magnitudes}
                            onSelectItem={value => updateProductMagnitude(value)}
                        />
                    </View>
                    <View style={{ flexDirection: 'row', marginBottom: 5 }}>
                        <Text style={{ fontWeight: 'bold' }}>Quantidade</Text>
                        <Text style={{ marginLeft: 5 }}>
                            (múltiplo de {selectedMagnitude?.value || product.multiplo_venda})
                        </Text>
                    </View>
                    <View style={{ paddingBottom: 30}} >
                    {product.preco > 0 ? (
                        <Quantity
                            containerStyle={{ maxWidth: 120 }}
                            product={product}
                            quantity={quantity}
                            setQuantity={setQuantity}
                            addItem={addItem}
                            volumeFactor={volumeFactor}
                            updatingItem={updatingItem}
                            ignoreStock={ignoreStock}
                            ignoreVolumeFactor={ignoreVolumeFactor}
                            setEditing={setEditing}
                        />
                    ) : null}
                    </View>
                </View>
                <View>
                    <Text style={styles.subTotalTitle}>Sub total:</Text>
                    <TotalPrice
                        price={product.preco}
                        precototal={product.precototal}
                        stock={product.stock}
                        stockExists={stockExists}
                        toRecalculate={toRecalculate}
                        recalculatePrice={recalculatePrice}
                    />
                </View>
            </View>
        </View>
    );
};

ProductTopInfo.propTypes = {
    product: PropTypes.shape({
        stock: PropTypes.number,
        nome: PropTypes.string,
        descricao_unidade_macro: PropTypes.string,
        precobase: PropTypes.number,
        preco: PropTypes.number,
        precototal: PropTypes.number,
        unidade: PropTypes.number,
    }).isRequired,
    showInfos: PropTypes.bool.isRequired,
    quantity: PropTypes.number.isRequired,
    setQuantity: PropTypes.func.isRequired,
    addItem: PropTypes.func.isRequired,
    volumeFactor: PropTypes.number.isRequired,
    magnitudes: PropTypes.arrayOf(PropTypes.object).isRequired,
    magnitudeSelect: PropTypes.object.isRequired,
    updateProductMagnitude: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    setEditing: PropTypes.func.isRequired,
    updatingItem: PropTypes.bool.isRequired,
    ignoreStock: PropTypes.bool.isRequired,
    ignoreVolumeFactor: PropTypes.bool.isRequired,
    configLib: PropTypes.object.isRequired,
    toRecalculate: PropTypes.bool.isRequired,
    recalculatePrice: PropTypes.func.isRequired,
};

export default ProductTopInfo;
