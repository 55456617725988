import { StyleSheet } from 'react-native';
import { color } from '~/common';

const GENERIC_BUTTON = {
  padding: 8,
  marginRight: 12,
  paddingHorizontal: 12,
  borderWidth: 1,
  borderRadius: 4,
};

const styles = StyleSheet.create({
  containerInput: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderWidth: 0.9,
    borderColor: '#000',
    height: 36,
    borderRadius: 4,
    paddingHorizontal: 12,
  },
  input: {
    flex: 1,
    backgroundColor: '#fff',
    color: '#000',
    fontSize: 15,
  },
  currency: {
    fontWeight: '700',
    fontSize: 18,
    alignSelf: 'flex-end',
    marginBottom: 4,
    color: '#000',
  },
  money: {
    marginLeft: 2,
    fontWeight: '700',
    fontSize: 32,
    lineHeight: 40,
    color: '#000',
  },
  inactiveButton: {
    backgroundColor: '#E6E6E6',
    borderColor: '#BCBCBC',
    ...GENERIC_BUTTON,
  },
  activeButtonOut: {
    backgroundColor: '#FFE8E5',
    borderColor: '#FFA196',
    ...GENERIC_BUTTON,
  },
  activeButtonIn: {
    backgroundColor: '#D1E9FF',
    borderColor: '#76BDFE',
    ...GENERIC_BUTTON,
  },
  textButton: {
    color: '#000',
    fontWeight: '400',
  },
  buttonsView: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: 16,
  },
  valueType_in: {
    color: color.primaryColorLight,
    fontWeight: 'bold',
  },
  valueType_out: {
    color: color.redBadge,
    fontWeight: 'bold',
  },
  // ListTitles
  containerSearch: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: color.grayMid,
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 4,
    flexShrink: 1,
    width: '100%',
  },
  inputSearchTitles: {
    paddingTop: 10,
    paddingRight: 15,
    paddingBottom: 10,
    paddingLeft: 0,
    backgroundColor: '#fff',
    color: '#000',
    marginLeft: 5,
    fontSize: 15,
    padding: 5,
    width: '100%',
    flexShrink: 1,
  },
  containerRoot: {
    flex: 1,
    paddingHorizontal: 16,
    marginTop: 8,
    marginBottom: 8,
  },
  containerLoading: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    alignSelf: 'center',
    top: '50%',
  },
  containerList: {
    flex: 1, 
    marginTop: 8
  },

  // ItemTitle
  container: {
    backgroundColor: '#FFF',
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderBottomColor: color.grayContainer,
    borderBottomWidth: 4,
    bottom: 10,
  },
  clientName: {
    color: '#000',
    fontSize: 16,
  },
  clientCode: {
    color: '#929292',
    fontSize: 14,
  },
  installments: {
    color: '#000',
    fontSize: 12,
  },
  price: {
    color: '#000',
    fontSize: 18,
  },
  title: {
    color: color.grayMid,
    fontSize: 12,
  },
  titleBlack: {
    color: '#000',
    fontSize: 12,
  },
  date: {
    textAlign: 'right',
    fontSize: 12,
    color: color.grayMid,
  },
  strip: {
    width: 14,
    height: 24,
    position: 'absolute',
    top: 14,
    left: 14,
    bottom: 0,
    borderRadius: 10,
  },
  buttonStyle: {
    width: 28,
    height: 28,
    borderRadius: 14,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 0,
  },
  buttonImageStyle: {
    width: 24,
    height: 24,
    borderRadius: 12,
    tintColor: color.primaryColor,
  },
});

export default styles;
