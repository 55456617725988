const SyncApiModuleV1 = (nameModule) => {
  const methodByAlias = {};

  const getConnectionsParams = async (resolve, reject) => {
    const data = {
      url: '',
      password: '',
      uuid: '',
    };

    if (resolve !== undefined) {
      // fsLibJNI.getDataSyncControl();
      /*
      return getConnectionsParams sucess
      {
        "url":"https://dev1.polibr.com.br:443/sync_server/indexsync.php?j=ce113061@p111.polibr.com.br&vm=1.04.98_PE",
        "password":"teste12317cafccbf6c9997f5bb7df2b198811b6",
        "uuid":"dc5a7474fd6b1664"
      }
      */
      resolve(data);
    }
    return JSON.stringify(data);
  };

  const updateSerialOnLib = async (data, resolve, reject) => {
    // data= {"v":5,"last_update":3422346631}
    // fsLibJNI.whenGetDataSyncControlSuccess(data);

    /*
    return updateSerialOnLib erro
    [{
      "codigo":"USER_NOT_AUTHORIZED",
      "contexto":"void CFastSeller::whenGetDataSyncControlSuccess(QString, api::Messages*)",
      "mensagem":"Equipamento não é mais autorizado para conexão. O sistema será encerrado. Contate a empresa.",
      "params":{},
      "tipo":"erro"
    }]
    */

    if (resolve !== undefined) {
      resolve();
    }
    return resolve;
  };

  const getDataToUploadOnLib = async (resolve, reject) => {
    const data = {
      url: '',
      password: '',
      uuid: '',
      to: '',
      comp_msg: '',
      last_serial: '0',
      data_sliced: '0',
      package_length: '0',
      total: '0',
    };

    // fsLibJNI.getDataToUpload();
    /*
    return getDataToUploadOnLib sucess
    {
      "url":"https://dev1.polibr.com.br:443/sync_server/indexsync.php?j=ce113061@p111.polibr.com.br&vm=1.04.98_PE",
      "password":"teste12317cafccbf6c9997f5bb7df2b198811b6","uuid":"dc5a7474fd6b1664",
      "to":"poli@p111.polibr.com.br",
      "comp_msg":"",
      "last_serial":"0",
      "data_sliced":"0",
      "package_length":"0",
      "total":"0"
    }
    */

    if (resolve !== undefined) {
      resolve(data);
    }

    return JSON.stringify(data);
  };

  const getTablesToDownloadOnLib = async (lastSerial, resolve, reject) => {

    const data = {
      url: '',
      password: '',
      uuid: '',
      tables:
      [{
        tablename: '',
        created_at: ' ',
        updated_at: '',
      }],
      last_serial: '',
    };

    // lastSerial= 0
    // fsLibJNI.getTablesToDownload(lastSerial);

    /*
    return getTablesToDownloadOnLib sucess
    {
      "url":"https://dev1.polibr.com.br:443/sync_server/indexsync.php?j=ce113061@p111.polibr.com.br&vm=1.04.98_PE",
      "password":"teste12317cafccbf6c9997f5bb7df2b198811b6",
      "uuid":"dc5a7474fd6b1664",
      "tables":
      [{
        "tablename":"tp_sync_datalog",
        "created_at":"1593088292",
        "updated_at":"3422357043"
      }],
    "last_serial":"3422357043"
  }
    */

    if (resolve !== undefined) {
      resolve(data);
    }

    return JSON.stringify(data);
  };

  const processTablesOnLib = async (path, tableName, lastTable, recreateTriggers, resolve, reject) => {
    // path= /sdcard/libfastseller/dwdtemp/tp_sync_datalog_1654602391452
    // tableName = tp_sync_datalog
    // lastTable = true
    // recreateTriggers = true

    // fsLibJNI.processarDownload(path, tableName);

    if (resolve !== undefined) {
      resolve();
    }

    return resolve;
  };

  return {
    getName: () => nameModule,
    methodByAlias,
    getConnectionsParams,
    updateSerialOnLib,
    getDataToUploadOnLib,
    getTablesToDownloadOnLib,
    processTablesOnLib,
  };
};

export default SyncApiModuleV1;
