import React from 'react';
import { View, Image } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import PropTypes from 'prop-types';

import styles from './styles';

function ButtonIcon({ source, style, styleImage, onPress }) {
  return (
    <Touchable onPress={onPress}>
      <View style={[styles.buttonDefault, style]}>
        <Image style={[styles.iconDefault, styleImage]} source={source} />
      </View>
    </Touchable>
  );
}

ButtonIcon.propTypes = {
    source: PropTypes.any.isRequired,
    style: PropTypes.any,
    styleImage: PropTypes.any,
    onPress: PropTypes.func.isRequired,
};

export default ButtonIcon;
