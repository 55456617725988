/* @flow */

import React from 'react';
import {
  View,
  Image,
  Platform,
  StyleSheet,
  Text,
  ImageBackground,
} from 'react-native';
import Touchable from 'react-native-platform-touchable';
import Images from '@assets/Images';
import { fonts } from '~/utils/Fonts';
import { Size } from '~/common/measures';
import { color, style } from '~/common';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  bar: {
    backgroundColor: color.primaryColor,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
    height: Size.navBarHeight,
    maxHeight: Size.navBarHeight,
    paddingHorizontal: 16,
    overflow: 'hidden',
    paddingTop: Platform.OS === 'ios' ? 20 : 0,
  },
  signal: {
    borderRadius: 50,
    backgroundColor: color.googleRed,
    width: 11,
    height: 11,
  },
  companyLogo: {
    height: 42,
    width: 42,
    borderRadius: 10,
    marginTop: 10,
  },
  companyName: {
    color: '#fff',
    fontSize: 14,
    fontFamily: fonts.QuicksandBold,
  },
});

function HomeTopBar({
  companyLogo,
  companyName,
  availableUpdate: { hasAvailableUpdate },
  onPressUpdateVersion,
  notifications,
  onPressNotifications,
}) {
  return (
    <View style={styles.bar}>
      <View style={style.rowDirection}>
        {companyLogo ? (
          <Image
            resizeMode="contain"
            style={styles.companyLogo}
            source={{ uri: companyLogo }}
          />
        ) : (
          <Text style={styles.companyName}>{companyName}</Text>
        )}
      </View>
      <View
        style={{
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}>
        {hasAvailableUpdate && (
          <View>
            <Touchable onPress={onPressUpdateVersion}>
              <ImageBackground
                source={Images.iconDownloadVersion}
                style={{ width: 27, height: 27 }}
                imageStyle={{ tintColor: '#fff' }}>
                <Text style={styles.signal} />
              </ImageBackground>
            </Touchable>
          </View>
        )}
        <Touchable style={{ marginLeft: 10 }} onPress={onPressNotifications}>
          <ImageBackground
            source={Images.iconBell}
            style={{ width: 27, height: 27 }}
            imageStyle={{ tintColor: '#fff' }}>
            {notifications &&
              notifications.filter(({ read }) => !read).length > 0 && (
                <Text style={styles.signal} />
              )}
          </ImageBackground>
        </Touchable>
      </View>
    </View>
  );
}

HomeTopBar.propTypes = {
  onPressProfile: PropTypes.func.isRequired,
  onSyncApp: PropTypes.func.isRequired,
  onPressUpdateVersion: PropTypes.func.isRequired,
  companyLogo: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  availableUpdate: PropTypes.object.isRequired,
};

export default HomeTopBar;
