import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  StyleSheet,
  Image,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Text,
} from 'react-native';

import Images from '@assets/Images';
import onPressHelper from '~/utils/onPressHelper';

const ICON_SIZE = 24;

const styles = StyleSheet.create({
  dots: {
    width: ICON_SIZE,
    height: ICON_SIZE,
    tintColor: 'grey',
    alignSelf: 'flex-end',
  },
  actionContainer: {
    width: '100%',
    backgroundColor: '#FAFAFA',
    justifyContent: 'center',
    paddingHorizontal: 12,
  },
  actionText: {
    color: '#000',
    fontWeight: 'bold',
    paddingBottom: 8,

  },
});

export default class PopupMenu extends Component {
  static propTypes = {
    // array of strings, will be list items of Menu
    actions: PropTypes.arrayOf(PropTypes.string).isRequired,
    onPress: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      icon: null,
      open: false,
    };
  }

  onError() {
    console.tron.log('Popup Error');
  }

  onPressHandler = () => {
    this.setState({ open: !this.state.open });
  }

  onPress = onPressHelper.debounce(this.onPressHandler);

  onRef = icon => {
    if (!this.state.icon) {
      this.setState({ icon });
    }
  };

  render() {
    const { open } = this.state;
    const { actions } = this.props;
    return (
      <ScrollView>
        <TouchableOpacity
          onBlur={() => {
            setTimeout(() => {
              this.setState({ open: false });
            }, 200);
          }}
          onPress={this.onPress}
          activeOpacity={0.9}
        >
          <Image
            source={Images.iconDotsVertical}
            style={styles.dots}
            ref={this.onRef}
          />
        </TouchableOpacity>
        {open && actions.length > 0 ? (
          <FlatList
            contentContainerStyle={{}}
            key={item => String(item)}
            data={actions}
            renderItem={({ item, index }) => (
              <TouchableOpacity
                activeOpacity={0.9}
                style={styles.actionContainer}
                onPress={
                  () => {
                    this.props.onPress('itemSelected', index);
                    this.setState({ open: false });
                  }}
              >
                <Text style={styles.actionText}>{item}</Text>
              </TouchableOpacity>
            )}
          />
        ) : null}
      </ScrollView>
    );
  }
}
