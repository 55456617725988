import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  MAGNITUDES_MANY_PRODUCT,
  MAGNITUDES_ONLY_PRODUCT,
} from '~/constants/rulesProductMagnitudes';
import ProductsModule from '~/modules/ProductsModule';

export const PRODUCTS_HOME_FETCH_REQUEST = 'PRODUCTS_HOME_FETCH_REQUEST';
export const PRODUCTS_HOME_FETCH_SUCCESS = 'PRODUCTS_HOME_FETCH_SUCCESS';
export const PRODUCTS_HOME_FETCH_FAILURE = 'PRODUCTS_HOME_FETCH_FAILURE';

const initialState = {
  type: '',
  loading: false,
  erro: false,
  payload: [],
  codGroupList: [],
};

export const updateTypeMagnitudes = async (products, magnitudes) => {
  products.forEach(product => {
    const magnitudeFound = magnitudes.find(
      magnitude => magnitude.codproduto === product.codigo,
    );
    product.type_magnitudes = magnitudeFound
      ? MAGNITUDES_MANY_PRODUCT
      : MAGNITUDES_ONLY_PRODUCT;
  });

  return products;
};

export const getProductsHome = createAsyncThunk(
  'productsHomeFetch/getProductsHome',
  async (payload, { getState }) => {
    const { codcliente, nivel, codGroup, codOrder, condvenda } = payload;
    const state = getState();

    try {
      const resp = await ProductsModule.getProductsHome(
        codcliente,
        nivel,
        codGroup,
        codOrder,
        JSON.stringify(condvenda),
      );

      const prodWithMagnitudes = await updateTypeMagnitudes(
        JSON.parse(resp),
        state.productsMagnitudes.payload,
      );

      return {
        nivel: payload.nivel,
        codGroup: payload.codGroup,
        products: prodWithMagnitudes,
        sort: payload.sort,
      };
    } catch (error) {
      return error;
    }
  },
);

const productsHomeFetchSlice = createSlice({
  name: 'productsHomeFetch',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getProductsHome.pending, state => {
        state.loading = true;
        state.type = PRODUCTS_HOME_FETCH_REQUEST;
      })
      .addCase(getProductsHome.fulfilled, (state, action) => {
        const { payload } = action;

        let allProducts = state.payload;

        let cods = state.codGroupList;

        if (payload.nivel !== '0' && payload.codGroup !== '0') {
          cods.push(payload.codGroup);

          cods.reduce((arr, code) => {
            const productSelect = arr.find(o => o.codigo === code);
            if (!productSelect) {
              return [];
            }

            if (productSelect?.filhos && productSelect?.filhos?.length === 0) {
              productSelect.filhos = payload.products.filter(
                p => p.tipo === 'categoria',
              );
              const products = payload.products.filter(
                p => p.tipo !== 'categoria',
              );
              productSelect.filhos = productSelect.filhos.concat(products);
            }

            return productSelect.filhos;
          }, allProducts);
        } else {
          allProducts = payload.products.filter(p => p.tipo === 'categoria');
          const products = payload.products.filter(p => p.tipo !== 'categoria');
          allProducts = allProducts.concat(products);
          cods = [];
        }

        state.loading = false;
        state.payload = allProducts;
        state.codGroupList = cods;
        state.type = PRODUCTS_HOME_FETCH_SUCCESS;
      })
      .addCase(getProductsHome.rejected, state => {
        state.loading = false;
        state.erro = true;
        state.type = PRODUCTS_HOME_FETCH_FAILURE;
      });
  },
});

const { reducer } = productsHomeFetchSlice;

export default reducer;
