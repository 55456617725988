// @flow

import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import { color } from '~/common';
import Touchable from 'react-native-platform-touchable';
import { fonts } from "../../utils/Fonts";

import PropTypes from 'prop-types';

const BORDER_RADIUS = 10;

const styles = StyleSheet.create({
  rootContainer: {
    padding: 6,
  },
  container: {
    borderRadius: BORDER_RADIUS,
    padding: 8,
    flex: 1,
    minHeight: 38,
    flexDirection: 'row',
    alignItems: 'center',
  },
  container__normal: {
    backgroundColor: color.primaryColorLight,
  },
  container__danger: {
    backgroundColor: color.redBadge,
  },
  container__success: {
    backgroundColor: color.successColor,
  },
  container__warning: {
    backgroundColor: color.warningColor,
  },
  iconContainer: {
    position: 'absolute',
    left: 0,
    backgroundColor: '#FFF',
    width: 24,
    height: 24,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 8,
  },
  icon: {
    width: 16,
    height: 16,
  },
  icon__normal: {
    tintColor: color.primaryColorLight,
  },
  icon__danger: {
    tintColor: color.redBadge,
  },
  icon__success: {
    tintColor: color.successColor,
  },
  icon__warning: {
    tintColor: color.warningColor,
  },
  text: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: fonts.QuicksandBold,
    flex: 1,
    paddingLeft: 40,
    paddingRight: 40,
  },
});

const AlertBadge = ({
  icon, text, onPress, type,
}) => (
  <View style={styles.rootContainer}>
    <Touchable
      onPress={onPress}
    >
      <View style={[styles.container, styles[`container__${type}`]]}>
        <View style={styles.iconContainer}>
          <Image
            style={[styles.icon, styles[`icon__${type}`]]}
            source={icon}
          />
        </View>
        <Text style={styles.text}>
          {text}
        </Text>
      </View>
    </Touchable>
  </View>
);

AlertBadge.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onPress: PropTypes.func,
  type: PropTypes.oneOf(['normal', 'danger', 'success', 'warning']),
};

AlertBadge.defaultProps = {
  type: 'normal',
};

export default AlertBadge;
