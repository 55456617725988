import React, { useCallback, useState } from 'react';
import { Modal, View, Text, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import styles from './styles';

export let showAlert = () => null;

const Alert = () => {
    const [visible, setVisible] = useState(false);
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [onConfirm, setOnConfirm] = useState(null);
    const [onCancel, setOnCancel] = useState(null);

    showAlert = useCallback((title, subTitle, confirmCallback, cancelCallback) => {
        setTitle(title);
        setSubTitle(subTitle);
        setOnConfirm(() => confirmCallback || (() => { }));
        setOnCancel(()=> cancelCallback || (() => { }));
        setVisible(true);
    }, []);

    const hideAlert = () => {
        setVisible(false);
    };

    const handleConfirm = useCallback(() => {
        onConfirm();
        hideAlert();
    }, [onConfirm]);

    const handleCancel = useCallback(() => {
        onCancel();
        hideAlert();
    }, [onCancel]);

    return (
      <View>
        <Modal
            transparent={true}
            visible={visible}
            onRequestClose={hideAlert}
        >
            <View
                style={styles.root}
            >
                <View
                    style={styles.container}
                >
                    <View style={styles.titleContainer}>
                        <Text style={styles.title}>{title}</Text>
                    </View>
                    <View style={styles.subTitleContainer}>
                        <Text style={styles.subTitle}>{subTitle}</Text>
                    </View>
                    <View style={styles.buttonsContainer}>
                        <TouchableOpacity
                            style={styles.buttonCancel}
                            onPress={handleCancel}
                        >
                            <Text style={styles.textCancel}>Cancelar</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={handleConfirm} style={styles.buttonConfirm}>
                            <Text style={styles.textConfirm}>Confirmar</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </Modal>
        </View>
    );
};

export default Alert;
