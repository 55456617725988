/* eslint-disable no-shadow */
/* @flow */
import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import CartBottomButton from '../../components/Cart/CartBottomButton';
import { updateItemFetch } from '../../store/ordersFetch/updateItemSlice';
import {
    SCREEN_COMPOUND_PRODUCT,
    SCREEN_PRODUCT_DETAIL,
    SCREEN_RESUME_ORDER,
} from '../screens';

import onPressHelper from '~/utils/onPressHelper';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
    bottomActions: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        flexDirection: 'row',
        // backgroundColor: color.primaryColor,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
});

export default function actualCart(screen, propsMapper, enableOperationGroup, selectedProducts) {
    return WrappedComponent => {
        class ActiveCart extends Component {
            addItem = (item, quantity) => {
                const { codcliente } = this.props.order.payload.condvenda;
                const itemCopy = { ...item};
                itemCopy.quantidade = quantity;
                const payloadAddItem = {
                    clientCode: codcliente,
                    itemKey: itemCopy.chave.toString(),
                    item: itemCopy,
                };

                this.props.dispatch(updateItemFetch(payloadAddItem));
            };

            productClick = item => {
                const screen =
                    item.tipo === 'produto'
                        ? SCREEN_PRODUCT_DETAIL.name
                        : SCREEN_COMPOUND_PRODUCT.name;
                        
                this.props.navigation.navigate(screen, {
                    addItem: this.addItem,
                    product: item,
                    screen,
                    pageDefault: 1,
                    cameByShowModal: true,
                });
            };

            onPressCart = () => {
                if (this.props.navigation) {
                    this.props.navigation.navigate({
                      name: 'Resume',
                      params: {
                        addItem: this.addItem,
                        productClick: this.productClick,
                        byCart: true,
                      },
                    });
                  }
            };

            onClickOnPressCart = onPressHelper.debounce(this.onPressCart);

            render() {
                const { order, screen } = this.props;

                return (
                    <View
                        style={
                            screen === SCREEN_PRODUCT_DETAIL.name ||
                                screen === SCREEN_COMPOUND_PRODUCT.name
                                ? { flex: 1, paddingBottom: 0 }
                                : { flex: 1, paddingBottom: 60 }
                        }>
                        <WrappedComponent {...this.props} />
                        {!(
                            screen === SCREEN_PRODUCT_DETAIL.name ||
                            screen === SCREEN_COMPOUND_PRODUCT.name
                        ) ? (
                            <View style={styles.bottomActions}>
                                    {enableOperationGroup && selectedProducts.length > 0 ? (
                                        <View>
                                            <View
                                                style={{
                                                    paddingTop: 12,
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    left: 0,
                                                    right: 0,
                                                    backgroundColor: this.state.FABOpened ? null : '#fff',
                                                }}>
                                                <Actions onPress={this.props.toggleOperationGroupModal} />
                                            </View>
                                        </View>
                                    ) : <CartBottomButton
                                        default
                                        onPressCart={this.onClickOnPressCart}
                                        order={order}
                                    />}
                            </View>
                        ) : null}
                    </View>
                );
            }
        }

        ActiveCart.propTypes = {
            order: PropTypes.object.isRequired,
            navigator: PropTypes.object.isRequired,
            dispatch: PropTypes.func.isRequired,
            pushingScreen: PropTypes.func.isRequired,
            screen: PropTypes.string.isRequired,
        };

        function mapStateToProps(state, ownProps) {
            return {
                account: state.accountFetch,
                order: state.orderFetch,
                ...(propsMapper ? propsMapper(state, ownProps) : {}),
            };
        }

        return connect(mapStateToProps)(ActiveCart);
    };
}
