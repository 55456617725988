import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useMemo,
  } from 'react';
  import { Text, View, ScrollView, Image, TouchableOpacity } from 'react-native';
  import { TextField } from 'react-native-material-textfield';
  import Touchable from 'react-native-platform-touchable';
  import { color } from '~/common';
  import Button from '~/components/Button';
  import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
  import DateTime from '~/components/Form/Field/DateTime';
  import Field from '~/components/Form/Field/Field';
  import formStyles from './formStyles';
  import { launchCamera, launchImageLibrary} from 'react-native-image-picker';
  import _ from 'lodash';
  import moment from 'moment';
  import { createExpense, listExpensesTypes } from './api';
  import Spinner from 'react-native-loading-spinner-overlay';
  import PropTypes from 'prop-types';
  import translate from '~/locales';
  import Masked from 'vanilla-masker';
  import onPressHelper from '~/utils/onPressHelper';
  import {
    getCurrencyFormatter,
    getPrecisionFromType,
    parseStrToFloat,
  } from '~/utils/Currency';
import { createNotifyError } from '~/components/Web/ToastNotify';
import FloatingTitleTextInput from '~/components/FloatingTitleTextInput';
import Images from '@assets/Images';
import { showAlert } from '~/components/Web/Alert';
  
  function FieldForm({ ...props }) {
    return <TextField containerStyle={{ marginVertical: 6 }} {...props} />;
  }
  
  function Form({ navigation, componentId, listScreenId }) {
    const [showSubtractForm, setShowSubtractForm] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [loading, setLoading] = useState(false);
    const [expenseTypes, setExpenseTypes] = useState([]);
    const [listPhotos, setListPhotos] = useState([]);
    const formRef = useRef(formValues);
  
    const hasFormAnswer = () => {
      return (
        Object.values(formRef?.current || {}).filter(val => Boolean(val)).length >
        0
      );
    };
  
    const onChangeFormValue = _.debounce(({ field, value }) => {
      const newForm = { ...formValues, [field]: value };
      setFormValues(newForm);
      formRef.current = newForm;
    }, 500);
  
    useEffect(() => {  
      setLeftButton();  
      fetchExpenseTypes();
  
      onChangeFormValue({
        field: 'date',
        value: moment(new Date()).format('YYYY-MM-DD'),
      });
  
      return () => {};
    }, []);
  
    const navigationButtonPressed = ({ buttonId }) => {
      switch (buttonId) {
        case 'backPress':
        case 'RNN.hardwareBackButton':
          goBack();
          break;
        default:
          break;
      }
    };
  
    const goBack = () => {
      if (hasFormAnswer()) {
        showAlert(`${translate('cancelExpense')}`, `${translate('cancelExpenseMessage')}`, () => navigation.pop());
      } else {
        navigation.pop();
      }
    };
  
    const fetchExpenseTypes = async () => {
      try {
        const resp = await listExpensesTypes();
        if (resp?.length > 0) {
          const expensesMap = resp.map(({ id, name }) => ({
            label: name,
            value: id,
          }));
          setExpenseTypes(expensesMap);
        }
      } catch (e) {
        createNotifyError(`${translate('errorListExpenseTypes')}`);
      }
    };

    const blobToBase64 = blob => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    }

  
    const onPressCamera = ({ field, onChangeValue }) => {
      const options = {
        mediaType: 'photo',
        includeBase64: false,
      };
  
      const getImgSource = async response => {
        const convertToBlob = await fetch(response.assets[0].uri).then(response => response.blob());
        const base64 = await blobToBase64(convertToBlob);
        setListPhotos([...listPhotos, base64]);
        return {
          uri: convertToBlob,
          isStatic: true,
          fileName: response.assets[0].fileName || 'imagename.png',
          filePath: 'file://fakepath/imagename.png',
          type: convertToBlob.type,
        };
      };
  
      launchImageLibrary(options, async response => {
        if (response.didCancel) {
          console.log('User cancelled image picker');
        } else if (response.error) {
          console.log('Error get photo ', response.error);
        } else if (response.customButton) {
          console.log('User tapped custom button: ', response.customButton);
        } else {
          const source = await getImgSource(response);
          onChangeFormValue({
            field: 'photos',
            value: [...(formValues?.photos ?? []), source],
          });
        }
      });
    };
  
    const onRemovePhoto = photo => {
      const newPhotos = [...formValues.photos].filter(
        ({ fileName }) => fileName !== photo.fileName,
      );
      const listPhotosFiltered = listPhotos.filter(
        ({ fileName }) => fileName !== photo.fileName,
      );
      setListPhotos(listPhotosFiltered);
      onChangeFormValue({ field: 'photos', value: newPhotos });
    };
  
    const onSaveExpense = onPressHelper.debounce(async () => {
      try {
        setLoading(true);
        await createExpense(formValues);
        navigation.navigate('ExpenseSuccess', {
            listScreenId,
            navigation,
            });
      } catch ({ message }) {
        setTimeout(() => {
          createNotifyError(message);
        }, 100);
      } finally {
        setLoading(false);
      }
    });
  
    const onDeleteSubtract = () => {
      const formWithoutDebt = { ...formValues };
      delete formWithoutDebt.debt_amount;
      delete formWithoutDebt.debt_reason;
      setFormValues(formWithoutDebt);
      setShowSubtractForm(false);
    };
  
    const maskMoney = m => {
      const moneyFormatter = getCurrencyFormatter('price', {
        noSymbolCurrency: false,
      });
      return Masked.toMoney(Masked.toNumber(m), moneyFormatter);
    };
  
    const onChangeFieldMoney = (entry, field) => {
      onChangeFormValue({
        field,
        value: maskMoney(entry),
      });
    };
  
    const getMoneyValue = useCallback(
      () => {
        const precision = getPrecisionFromType('price');
        return maskMoney(parseStrToFloat(formValues.value || '0', precision));
      },
      [formValues.value],
    );
  
    const getMoneyDebt = useCallback(
      () => {
        const precision = getPrecisionFromType('price');
        return maskMoney(parseStrToFloat(formValues.debt_amount, precision));
      },
      [formValues.debt_amount],
    );
  
    const usePlaceHolderMoney = useMemo(() => maskMoney('0'), []);

    const setLeftButton = () => {
        navigation.setOptions({
          headerLeft: () => (
            <TouchableOpacity onPress={goBack}
            >
              <Image
                source={Images.iconBack}
                style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
              />
            </TouchableOpacity>
          ),
        });
      };
  
    return (
      <ScrollView style={formStyles.scrollView}>
        <FloatingTitleTextInput
          label={`${translate('place')}`}
          value={formValues.title}
          onChangeText={value => onChangeFormValue({ field: 'title', value })}
          style={formStyles.combo}
        />
        <DateTime
          type="DATE"
          value={moment(formValues.date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
          onChangeValue={(_, value) =>
            onChangeFormValue({
              field: 'date',
              value: moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            })
          }
          title={`${translate('fieldDate')}`}
          style={formStyles.dateTime}
        />
        <Field.Combo
          data_source={expenseTypes}
          style={formStyles.combo}
          title={`${translate('fieldType')}`}
          value={formValues.type}
          onChangeValue={(field, { value }) =>
            onChangeFormValue({ field: 'type', value })
          }
        />
  
        <FloatingTitleTextInput
          onChangeText={entry => onChangeFieldMoney(entry, 'value')}
          label={`${translate('fieldAmount')}`}
          placeholder={usePlaceHolderMoney}
          value={formValues.value ? getMoneyValue() : ''}
          keyboardType="decimal-pad"
          inputMode="decimal"
          style={formStyles.combo}
        />
  
        <Touchable
          style={formStyles.subtractButton}
          onPress={() => setShowSubtractForm(!showSubtractForm)}>
          <View style={formStyles.subtractButtonView}>
            <Image source={Images.iconMinus} tintColor={color.primaryColor} style={{ width: 24, height: 24 }} />
            <Text style={formStyles.subtractButtonText}>{`${translate(
              'subtractFromNote',
            )}`}</Text>
          </View>
        </Touchable>
  
        {showSubtractForm ? (
          <View style={formStyles.subtractContainer}>
            <View style={formStyles.subtractHeader}>
              <Text style={formStyles.subtractText}>
                {`${translate('subtractFromNoteValue')}`}
              </Text>
              <Image source={Images.iconTrash} tintColor={color.primaryColor} style={{ width: 24, height: 24 }} />
            </View>
            <FloatingTitleTextInput
              onChangeText={value => onChangeFieldMoney(value, 'debt_amount')}
              label={`${translate('subtractValue')}`}
              placeholder={usePlaceHolderMoney}
              value={formValues.debt_amount ? getMoneyDebt() : ''}
              keyboardType="decimal-pad"
              inputMode="decimal"
              style={formStyles.combo}
            />
            <FloatingTitleTextInput
              value={formValues.debt_reason}
              onChangeText={value =>
                onChangeFormValue({
                  field: 'debt_reason',
                  value,
                })
              }
              label={`${translate('fieldReason')}`}
              style={formStyles.combo}
            />
          </View>
        ) : null}
  
        <FloatingTitleTextInput
          value={formValues.observation}
          onChangeText={value =>
            onChangeFormValue({ field: 'observation', value })
          }
          label={`${translate('observations')}`}
          multiline
          style={formStyles.combo}
        />
  
        <View style={{ marginVertical: 12 }}>
          <Text>{`${translate('attachFile')}`}</Text>
          <Touchable style={formStyles.buttonCamera} onPress={onPressCamera}>
            <View style={formStyles.buttonCameraView}>
              <Image source={Images.iconCamera} tintColor={'#000'} style={{ width: 24, height: 24 }} />
              <Text style={formStyles.buttonCameraText}>{`${translate(
                'camera',
              )}`}</Text>
            </View>
          </Touchable>
          <View style={formStyles.listPhotosContainer}>
            {listPhotos?.map(photo => (
              <View key={photo?.filename} style={{ padding: 5 }}>
                <Image style={formStyles.photoItem} source={photo} />
                <Icon
                  style={formStyles.photoItemIcon}
                  name="close-box"
                  size={28}
                  color={'#000'}
                  onPress={() => onRemovePhoto(photo)}
                />
                <Touchable style={formStyles.photoItemCamera} onPress={() => onRemovePhoto(photo)}>
                <Image 
                source={Images.iconClose} 
                tintColor={'#000'} 
                style={{
                  ...formStyles.photoItemCamera,
                  width: 24,
                  height: 24,
                }} />
                </Touchable>
              </View>
            ))}
          </View>
        </View>
  
        <View style={formStyles.finishButtonsContainer}>
          <Button
            style={formStyles.buttonCancel}
            containerStyle={{ borderRadius: 4 }}
            color={color.primaryColor}
            title={`${translate('cancel')}`}
            onPress={goBack}
            outline
            normalCase
          />
          <Button
            style={formStyles.buttonConfirm}
            containerStyle={{ borderRadius: 4 }}
            title={`${translate('saveExpense')}`}
            onPress={() => onSaveExpense()}
            normalCase
          />
        </View>
  
        <Spinner
          visible={loading}
          overlayColor="rgba(0, 0, 0, 0.7)"
          textStyle={{ color: '#FFF' }}
        />
      </ScrollView>
    );
  }
  
  Form.propTypes = {
    componentId: PropTypes.string,
    listScreenId: PropTypes.string,
  };
  
  export default Form;
  