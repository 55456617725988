/**
 * @return {boolean}
 */
function CPFCheck(doc) {
  const cpf = doc
    .replace(/\./g, '')
    .replace(/-/g, '')
    .replace(/\D/g, '');
  const pattern = /^(\d{1})\1{10}$/;

  if (cpf.length !== 11) {
    return false;
  }
  if (pattern.test(cpf)) {
    return false;
  }
  let sum = 0;
  for (let i = 0; i < 9; i += 1) {
    sum += parseInt(cpf.charAt(i), 10) * (10 - i);
  }
  let mod = sum % 11;
  let digit = mod > 1 ? 11 - mod : 0;
  if (parseInt(cpf.charAt(9), 10) !== digit) {
    return false;
  }
  sum = 0;
  for (let i = 0; i < 10; i += 1) {
    sum += parseInt(cpf.charAt(i), 10) * (11 - i);
  }
  mod = sum % 11;
  digit = mod > 1 ? 11 - mod : 0;

  return parseInt(cpf.charAt(10), 10) === digit;
}

/**
 * @return {boolean}
 */
function CNPJCheck(entryCnpj) {
  const cnpj = entryCnpj.replace(/[^\d]+/g, '');
  if (cnpj === '') {
    return false;
  }

  return cnpj.length === 14;
}

/**
 * @return {boolean}
 */
function ZipCodeCheck(value) {
  return value.length === 9;
}

/**
 * @return {boolean}
 */
function PhoneCheck(value) {
  return value.length === 11 || value.length === 10;
}

/**
 * @return {boolean}
 */
function RegxCheck(value, regx) {
  const condition = new RegExp(regx);
  return condition.test(value);
}

/**
 * @return {string}
 */
function RegxMatch(value, regx) {
  if (regx) {
    const valueMatched = value.match(regx);
    if (valueMatched && valueMatched.length > 0) {
      return valueMatched[0];
    }
  }
  return value;
}

export default {
  CPFCheck,
  CNPJCheck,
  ZipCodeCheck,
  PhoneCheck,
  RegxCheck,
  RegxMatch,
};
