import { Platform } from 'react-native';
import Config from 'react-native-config';

export const PE_SOURCE_DATA = Config.PE_SOURCE_DATA || 'library';

export const SOURCE_DATA_TYPE_LIBRARY = 'library';
export const SOURCE_DATA_TYPE_API = 'api';

const SOURCE_DATA_ACTIVE =
  PE_SOURCE_DATA === SOURCE_DATA_TYPE_API
    ? SOURCE_DATA_TYPE_API
    : SOURCE_DATA_TYPE_LIBRARY;

export const getActiveSourceData = () => {
  if (
    SOURCE_DATA_ACTIVE === SOURCE_DATA_TYPE_LIBRARY &&
    Platform.OS === 'android'
  ) {
    return SOURCE_DATA_TYPE_LIBRARY;
  } else if (SOURCE_DATA_ACTIVE === SOURCE_DATA_TYPE_API) {
    return SOURCE_DATA_TYPE_API;
  } else {
    return SOURCE_DATA_TYPE_API;
  }
};

export const IS_API_SOURCE_DATA =
  getActiveSourceData() === SOURCE_DATA_TYPE_API;
export const IS_LIBRARY_SOURCE_DATA =
  getActiveSourceData() === SOURCE_DATA_TYPE_LIBRARY;

const AppConfig = {
  // TODO: remover containsPhoto após a nova estrutura de tasks
  containsPhoto: false,
};

export default AppConfig;
