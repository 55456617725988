import { Dimensions, StyleSheet } from 'react-native';

const { width, height } = Dimensions.get('window');

const size = 40;

const styles = StyleSheet.create({
  rootContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    minWidth: size,
    maxWidth: size,
    color: 'black',
    textAlign: 'center',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.7)',
  },
  htmlView: {
    flex: 1,
    width: width * 0.8, // 80% da largura da tela
    backgroundColor: '#fff',
    margin: width * 0.05, // 5% da largura da tela
    borderRadius: 10,
    overflow: 'hidden',
  },
  buttonClose: {
    position: 'absolute',
    top: height * 0.02, // 2% da altura da tela
    right: width * 0.02, // 2% da largura da tela
  },
});

export default styles;
