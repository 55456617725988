import React from 'react';
import { ScrollView, Text, View } from 'react-native';

import { Button } from '~/components';

import translate from '~/locales';

import SelectPicker from '~/components/SelectPicker';
import styles from './styles';

type Props = {
  onPressClose: Function,
  onPressApply: Function,
  tables: Array,
  scales: Array,
  deadlines: Array,
  selectedDeadline: Number,
  selectedScale: Number,
  selectedTable: Number,
  onChangeDeadline: Function,
  onChangeScale: Function,
  onChangeTable: Function,
};

function SalesConditionCatalogSheet(props: Props) {
  const {
    onPressClose,
    onPressApply,
    tables,
    scales,
    deadlines,
    selectedDeadline,
    selectedScale,
    selectedTable,
    onChangeDeadline,
    onChangeScale,
    onChangeTable,
  } = props;

  const defaultMapper = item => {
    return {
      label: item.descricao,
      value: Number(item.faixa ?? item.pkey),
    };
  };

  const selectedTableObject = tables
    ?.map(defaultMapper)
    .find(table => table.value === Number(selectedTable));

  const selectedScaleObject = scales
    ?.map(defaultMapper)
    .find(scale => scale.value === Number(selectedScale));

  const selectedDeadlineObject = deadlines
    ?.map(defaultMapper)
    .find(deadline => deadline.value === Number(selectedDeadline));

  return (
    <View style={styles.modalOverlay}>
      <View style={styles.modalContainer}>
        <ScrollView>
          <View style={[styles.sectionContainer, { paddingHorizontal: 0 }]}>
            <View>
              <Text style={[styles.subheader, { margin: 16 }]}>{`${translate(
                'salesCondition',
              )}`}</Text>
            </View>
            <View style={{ paddingHorizontal: 16, paddingBottom: 16 }}>
              <SelectPicker
                selected={selectedTableObject}
                style={{ margin: 0 }}
                title={`${translate('priceTable')}`}
                items={tables.map(defaultMapper)}
                onChangeValue={item => onChangeTable?.(item.value)}
              />
              <SelectPicker
                selected={selectedScaleObject}
                style={{ margin: 0, marginTop: 16 }}
                title={`${translate('scale')}`}
                items={scales.map(defaultMapper)}
                onChangeValue={item => onChangeScale?.(item.value)}
              />
              <SelectPicker
                selected={selectedDeadlineObject}
                style={{ margin: 0, marginTop: 16 }}
                title={`${translate('deadline')}`}
                items={deadlines.map(defaultMapper)}
                onChangeValue={item => onChangeDeadline?.(item.value)}
              />
            </View>
          </View>
        </ScrollView>

        <View style={styles.buttonsView}>
          <Button
            style={styles.bottomButtons}
            outline
            onPress={onPressClose}
            title={translate('cancel')}
          />
          <Button
            style={styles.bottomButtons}
            title={translate('apply')}
            onPress={onPressApply}
          />
        </View>
      </View>
    </View>
  );
}

export default SalesConditionCatalogSheet;
