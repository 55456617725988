import { createAsyncThunk } from '@reduxjs/toolkit';
import { finishCart as finishCartPromise } from './utilsLib';

export const FINISH_CART_FETCH_REQUEST = 'FINISH_CART_FETCH_REQUEST';
export const FINISH_CART_FETCH_SUCCESS = 'FINISH_CART_FETCH_SUCCESS';
export const FINISH_CART_FETCH_FAILURE = 'FINISH_CART_FETCH_FAILURE';

export const finishCart = createAsyncThunk(
  'orderFetch/finishCart',
  async payload => {
    try {
      const payloadCart = {
        cartId: payload,
      };
      const data = await finishCartPromise(payloadCart);
      const cart = JSON.parse(data);
      return cart;
    } catch (error) {
      console.log('error', error);
      throw new Error(error.message);
    }
  },
);
