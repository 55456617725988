import React from 'react';
import { View, FlatList } from 'react-native';
import {
    Placeholder,
    PlaceholderMedia,
    PlaceholderLine,
    Fade,
} from 'rn-placeholder';

import colors from '../../../common/color';

const backgroundGray = {
    backgroundColor: colors.gray,
};

const app = {
    ...backgroundGray,
    paddingHorizontal: 20,
    paddingTop: 15,
    paddingBottom: 10,
    borderBottomColor: colors.primaryColorTable,
    borderBottomWidth: 2,
    flexDirection: 'row',
    alignItems: 'center',
};

const badgeContainer = {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: colors.primaryColorTable,
};

const divide = {
    marginTop: 10,
    marginLeft: 7,
    flex: 1,
};

const lineName = {
    backgroundColor: colors.primaryColorTable,
    width: 80,
};

const lineOption = {
    backgroundColor: colors.primaryColorTable,
    width: 100,
};

const iconCheck = {
    width: 28,
    height: 28,
    borderRadius: 14,
    backgroundColor: colors.primaryColorTable,
};

export default function ActivityModulesPlaceholder() {
    return (
        <Placeholder
            Animation={Fade}
            style={backgroundGray}
        >
            <FlatList
                data={[1, 2, 3, 3, 4, 5, 6, 7, 8, 9, 10]}
                // keyExtractor={() => Math.random().toString()}
                renderItem={() => (
                    <View style={app}>
                        <PlaceholderMedia style={badgeContainer} />
                        <View style={divide}>
                            <PlaceholderLine style={lineName} />
                            <PlaceholderLine style={lineOption} />
                        </View>
                        <View>
                            <PlaceholderMedia style={iconCheck} />
                        </View>
                    </View>
                )}
            />
        </Placeholder>
    );
}
