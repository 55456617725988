import Auth from '~/config/Auth';
import React, { Component } from 'react';
import { Platform } from 'react-native';
import NotificationResource from '~/services/resources/notifications/NotificationResource';
import DeviceInfo from 'react-native-device-info';
import MainNav from '~/MainNav';
import * as config from '~/config/config';
import Toast from 'react-native-root-toast';
import CacheAccount from '~/controllers/Accounts/Cache/CacheAccount';
import InAppNotification from '~/screens/Notifications/InAppNotification';
import { TYPE_ROUTE_SOLICITATION_SYNC_DATA_DEVICE } from '~/screens/Notifications/typeRouteSolicitationConst';
import { tasksSync } from '~/TaskSync';
import { getVersionApp } from '~/utils/deviceAppInfo';
import PushNotification from '~/modules-wrapper/react-native-push-notification';
var notificationSound;

const SOLICITATION_APPROVED = 'solicitation_approved',
  SOLICITATION_REPROVED = 'solicitation_reproved';

class NotificationController extends Component {
  constructor() {
    super();
  }

  static getNotificationTextAndTitle(notification) {
    let data = notification.data || {};
    if (typeof data === 'string') {
      data = JSON.parse(data);
    }
    let type = data.type;
    let message = notification.message;
    let title = notification.title;

    switch (type) {
      case SOLICITATION_APPROVED:
        title = 'Solicitação aprovada';
        message = 'O cliente foi adicionado com sucesso na rota! Boas vendas!';
        break;
      case SOLICITATION_REPROVED:
        title = 'Solicitação recusada';
        message = 'O cliente não pode ser adicionado na rota.!';
        break;
      default:
        break;
    }

    return { title, message };
  }

  static showNotification(notification) {
    let self = this;
    let titleMessage = self.getNotificationTextAndTitle(notification);
    if (!notification.userInteraction) {
      if (notification.foreground) {
        if (titleMessage.title && titleMessage.message) {
          Toast.show(<InAppNotification {...titleMessage} />, {
            duration: Toast.durations.LONG,
            position: Toast.positions.TOP,
            shadow: false,
            animation: true,
            hideOnPress: true,
            delay: 0,
            backgroundColor: 'transparent',
          });
        }

        if (notificationSound) {
          notificationSound.play(success => {
            if (success) {
              console.tron.log('successfully finished playing');
            } else {
              console.tron.log('ERROR PLAYING SONG');
              if (Platform.OS == 'android') {
                notificationSound.reset();
              }
            }
          });
        }
      } else {
        PushNotification.localNotification({
          /* iOS and Android properties */
          ...titleMessage,
          smallIcon: 'notification_icon',
          color: config.Colors.primaryColor,
          data: notification.data,
        });
      }
    }
  }

  static processNotification(notification, isClick) {
    isClick = notification.userInteraction;
    if (isClick) {
      this.onClickNotification(notification);
    } else {
      this.showNotification(notification);
    }
  }

  static async registerOnServer(token) {
    const cache = new CacheAccount();
    const dataAccount = await cache.getDataAccount();
    const { user } = dataAccount;
    let deviceId = DeviceInfo.getUniqueID();

    const payload = {
      platform_key: '8ac127eb-6d1f-4d24-a4a8-539b086055d1',
      device_info: {
        device_name: DeviceInfo.getDeviceName(),
        api_level: DeviceInfo.getAPILevel(),
        battery_level: DeviceInfo.getBatteryLevel(),
        bundle_id: DeviceInfo.getBundleId(),
        model: DeviceInfo.getModel(),
        is_tablet: DeviceInfo.isTablet(),
        max_memory: DeviceInfo.getMaxMemory(),
        phone_number: DeviceInfo.getPhoneNumber(),
        version: DeviceInfo.getVersion(),
        version_application: getVersionApp(),
      },
      device_uuid: deviceId,
      device_token: token,
      company_code: user.company.company_code,
      person_kind: 'collaborator',
      person_foreign_code: user.username,
      person_name: user.first_name,
      person_identity_number: null,
      person_email: user.email,
      person_phone: null,
      person_city: null,
      person_state: null,
    };

    NotificationResource.registerFCM(payload)
      .then(response => {
        return response.json();
      })
      .then(() => {});
  }

  static onClickNotification() {
    let type = 1;

    switch (type) {
      case SOLICITATION_APPROVED:
        MainNav.startHome();
        break;
      case SOLICITATION_REPROVED:
        MainNav.startHome();
        break;
      default:
        MainNav.startHome();
        break;
    }
  }

  static configure() {
    let self = this;

    PushNotification.configure({
      onRegister: token => {
        Auth.saveNotificationToken(token.token);
        Auth.getToken().then(mainToken => {
          if (mainToken) {
            this.registerOnServer(token.token);
          }
        });
      },

      onNotification: notification => {
        const notificationData = notification.data || {};
        const data = notificationData.data;
        let type;
        if (typeof data === 'string') {
          const parsedData = JSON.parse(data);
          if (parsedData) {
            type = parsedData?.type;
          }
        }

        if (type === TYPE_ROUTE_SOLICITATION_SYNC_DATA_DEVICE) {
          tasksSync(true);
        } else {
          if (notification.foreground || notification.userInteraction) {
            this.processNotification(notification);
          }
          // required on iOS only (see fetchCompletionHandler docs: https://facebook.github.io/react-native/docs/pushnotificationios.html)
        }
      },

      // ANDROID ONLY: GCM Sender ID (optional - not required for local notifications, but is need to receive remote push notifications)
      senderID: config.Constants.SENDER_ID,

      // IOS ONLY (optional): default: all - Permissions to register.
      permissions: {
        alert: true,
        badge: true,
        sound: true,
      },

      // Should the initial notification be popped automatically
      // default: true
      popInitialNotification: false,

      requestPermissions: true,
    });
  }
}

export default NotificationController;
