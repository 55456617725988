// @flow

import React, { Component } from 'react';
import { View, Text } from 'react-native';
import Images from '@assets/Images';
import Touchable from 'react-native-platform-touchable';
import { Button, Price } from '../../components/index';
import { getComponent } from '../../../../src/components';
import Quantity from '../Quantity/index';
import ProductImage from '../Image';

import styles from './styles';
import ButtonIconInfo from '~/components/ButtonsIcon/ButtonIconInfo';
import MoreInfo from '../MoreInfo/MoreInfo';
import translate from '../../../../src/locales';
import { color } from '../../../../src/common';
import _ from 'lodash';

import PropTypes from 'prop-types';

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalMoreInfosVisible: false,
    };
  }

  renderUnavailableProduct = () => (
    <View style={styles.unavailableProduct}>
      <Text
        style={{
          fontSize: 11,
          fontWeight: '400',
          textAlign: 'center',
          color: color.redBadge,
        }}
        numberOfLines={2}>
        {`${translate('priceNotAvailable')}`}
      </Text>
    </View>
  );

  renderNameLike() {
    const { onPressLike, liked, product, type } = this.props;
    return (
      <View style={styles[`nameLikeContainer__${type}`]}>
        <Text numberOfLines={2} style={styles[`name__${type}`]}>
          {product.name}
        </Text>
        <Text style={[styles[`name__${type}`], { color: color.grayDark }]}>
          {product.codigo}
        </Text>
        {type === 'linear' ? (
          <Button
            flat
            iconButton
            iconLeft={liked ? Images.iconHeart : Images.iconHeartOutline}
            onPress={() => onPressLike(product)}
          />
        ) : null}
      </View>
    );
  }

  renderPrice() {
    const { product, type, configComponent } = this.props;

    if (_.isObject(configComponent) && configComponent.product_item_price) {
      return this.renderComponentPrice(configComponent.product_item_price);
    }

    return !(product.price === -1) ? (
      <View style={styles[`priceContainer__${type}`]}>
        <Price value={product.price} style={styles.price} />
        {/* <Text style={styles.price}>
          {Money(product.price)}
        </Text> */}
      </View>
    ) : (
      this.renderUnavailableProduct()
    );
  }

  renderComponentPrice(componentId) {
    const componentStyles = {
      container: {
        paddingBottom: 4,
        alignItems: 'center',
      },
      containerExpand: { marginLeft: 5 },
      iconExpand: {
        width: 10,
        height: 20,
        tintColor: '#fff',
        marginLeft: 5,
        textAlign: 'center',
      },
      containerPrice: { flex: 1, borderWidth: 0 },
      textPrice: {
        alignItems: 'center',
        fontSize: 16,
        marginLeft: 2,
        marginRight: 0,
      },
      iconMoney: {
        width: 15,
        height: 15,
        marginLeft: 5,
        tintColor: '#fff',
      },
    };
    const { product } = this.props;
    const productCopy = { ...product, chave: product.id };
    const props = { product: productCopy, componentStyles, isCarrosel: true };

    const ComponentItem = getComponent(componentId);

    return <ComponentItem {...props} />;
  }

  renderPriceQuantity() {
    const {
      type,
      product,
      onChangeQuantity,
      updatingItem,
      ignoreVolumeFactor,
      ignoreStock,
    } = this.props;

    const multiple = ignoreVolumeFactor ? product.unity : product.multiple;

    return (
      <View style={styles[`priceQuantityContainer__${type}`]}>
        <Quantity
          minQuantity={0}
          maxQuantity={product.stock}
          quantity={product.quantity}
          multiple={multiple}
          onChangeQuantity={onChangeQuantity}
          inputStyle={type === 'linear' ? { maxWidth: 50, minWidth: 50 } : {}}
          updatingItem={updatingItem}
          ignoreStock={ignoreStock}
        />
      </View>
    );
  }

  render() {
    const { type, onPress, product } = this.props;
    const { stock } = product;
    const stockExists = stock !== undefined && stock !== null && stock > 0;

    return (
      <View style={styles[`shadowContainer__${type}`]}>
        <View style={styles[`overflowContainer__${type}`]}>
          <Touchable
            onPress={() => onPress(product)}>
            <View style={styles[`container__${type}`]}>
              <ProductImage
                style={[
                  styles[`image__${type}`],
                  !stockExists ? { opacity: 0.5 } : {},
                ]}
                source={this.props.product.image}
              />
              {product.moreInfo ? (
                <View style={styles[`moreInfoBadge__${type}`]}>
                  <ButtonIconInfo
                    onPress={() => {
                      this.setState({ modalMoreInfosVisible: true });
                    }}
                  />
                </View>
              ) : null}
              <View style={styles[`infoContainer__${type}`]}>
                {this.renderNameLike()}
                {this.renderPrice()}
                {stockExists && !(product.price === -1)
                  ? this.renderPriceQuantity()
                  : null}
              </View>
            </View>
          </Touchable>
        </View>
        <MoreInfo
          modalMoreInfosVisible={this.state.modalMoreInfosVisible}
          htmlContent={product.moreInfo}
          onModalClose={() => {
            this.setState({ modalMoreInfosVisible: false });
          }}
        />
      </View>
    );
  }
}

Product.propTypes = {
    type: PropTypes.oneOf(['linear', 'grid']),
    product: PropTypes.object.isRequired,
    liked: PropTypes.bool,
    ignoreVolumeFactor: PropTypes.bool,
    ignoreStock: PropTypes.bool,
    onPress: PropTypes.func,
    onPressLike: PropTypes.func,
    onChangeQuantity: PropTypes.func.isRequired,
    updatingItem: PropTypes.bool,
    configComponent: PropTypes.object,
};

Product.defaultProps = {
  type: 'linear',
  onPress: () => { },
  onPressLike: () => { },
};

export default Product;
