/* @flow */

import React, { Component } from 'react';
import _ from 'lodash';
import { ActivityIndicator, Image, StyleSheet, Text, View } from 'react-native';
import style from '~/common/style';
import NewClientsListItem from './Item/NewClientsListItem';
import Touchable from 'react-native-platform-touchable';
import Images from '@assets/Images';
import * as config from '~/config/config';
import { Search, EmptyView } from '~/components';
import { FlashList } from '@shopify/flash-list';
import translate from '../../locales';
import { isShowMultiTabs } from '~/utils/GlobalUtils';
import MultiTabsView from '~/components/MultiTabsView/MultiTabsView';
import RawBottomSheet from '~/components/RawBottomSheet/RawBottomSheet';

import PropTypes from 'prop-types';
import ModalOverlay from '~/components/Web/ModalOverLay/index.web';

const styles = StyleSheet.create({
  addUserImage: {
    width: 20,
    height: 20,
    marginRight: 10,
    tintColor: config.Colors.primaryColor,
  },
  addUserContainer: {
    backgroundColor: config.Colors.primaryColor,
    borderBottomColor: '#ddd',
    paddingVertical: 12,
  },
  addUserButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    marginHorizontal: 40,
    paddingVertical: 6,
    paddingHorizontal: 16,
    backgroundColor: '#fff',
  },
  addUserText: {
    color: config.Colors.primaryColor,
    fontSize: 15,
  },
});

class NewClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMultiTabs: false,
    };

    this.multiTabsRef = null;
  }

  componentWillMount = () => {
    const { configLib } = this.props;
    const showMultiTabs = isShowMultiTabs(configLib, 'clients', 'precadastro');
    this.setState({ showMultiTabs });
  };

  onChangeText = text => {
    if (this.multiTabsRef) {
      this.multiTabsRef.onChangeText(text);
    } else {
      this.props.onChangeText(text);
    }
  };

  onChangeTextDebounce = _.debounce(this.onChangeText, 100);

  renderNewClientItem = ({ item }) => (
    <NewClientsListItem
      item={item}
      onLongPressItem={this.props.onLongPressItem}
      onPressItem={this.props.onPressItem}
    />
  );

  defaultView = () => {
    const { loading, clients } = this.props;
    const isVisible = loading ? true : clients.length <= 0;
    return (
      <View style={{ flex: 1 }}>
        <ModalOverlay visible={loading} closeOnTouchOutside />
        <ActivityIndicator
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            zIndex: 9999,
          }}
          animating={loading}
          size="large"
        />
        {clients && clients.length > 0 ? (
          <FlashList
            data={clients}
            keyExtractor={item => item?.chave?.toString()}
            estimatedItemSize={250}
            extraData={clients.length}
            renderItem={this.renderNewClientItem}
            ListFooterComponentStyle={{ marginBottom: 24 }}
          />
        ) : (
          <EmptyView
            icon={Images.iconCustomerBig}
            message={`${translate('noCustomers')}`}
          />
        )}
      </View>
    );
  };

  multiTabsView = () => {
    const {
      configLib,
      navigator,
      showSearch,
      onPressItem,
      onLongPressItem,
    } = this.props;
    return (
      <MultiTabsView
        ref={ref => {
          this.multiTabsRef = ref;
        }}
        configLib={configLib}
        groupId="CPRE"
        navigator={navigator}
        showSearch={showSearch}
        onPressItem={onPressItem}
        onLongPressItem={onLongPressItem}
      />
    );
  };

  render() {
    const {
      onPressAdd,
      showSearch,
      selectedJoker,
      jokerClients,
      onSelectJoker,
    } = this.props;

    const { showMultiTabs } = this.state;

    return (
      <View style={style.container}>
        {onPressAdd ? (
          <View style={styles.addUserContainer}>
            <Touchable
              onPress={() => {
                onPressAdd();
              }}>
              <View style={styles.addUserButton}>
                <Image
                  source={Images.iconAddUser}
                  style={styles.addUserImage}
                />
                <Text style={styles.addUserText}>{`${translate('add')}`}</Text>
              </View>
            </Touchable>
          </View>
        ) : null}
        {showSearch ? (
          <Search onChangeText={this.onChangeTextDebounce} />
        ) : null}
        {!showMultiTabs ? this.defaultView() : this.multiTabsView()}

        <RawBottomSheet
          setRef={ref => {
            this.RbSheetRef = ref;
          }}
          height={300}
          itens={_.map(jokerClients, 'label')}
          itemSelected={selectedJoker}
          onSelectOption={(selected, idx) => onSelectJoker(selected, idx)}
        />
      </View>
    );
  }
}

NewClients.propTypes = {
  clients: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  showSearch: PropTypes.bool.isRequired,
  configLib: PropTypes.object.isRequired,
  navigator: PropTypes.object.isRequired,
  jokerClients: PropTypes.array.isRequired,
  selectedJoker: PropTypes.object.isRequired,
  onSelectJoker: PropTypes.func.isRequired,
  onPressAdd: PropTypes.func.isRequired,
  onChangeText: PropTypes.func.isRequired,
  onPressItem: PropTypes.func.isRequired,
  onLongPressItem: PropTypes.func.isRequired,
};

NewClients.defaultProps = {
  showDates: true,
  onLongPressItem: () => {},
};

export default NewClients;
