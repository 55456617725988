import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import LoginContainer from '../screens/Login/Login.Container';
import { SCREEN_LOGIN } from '../screens/screens';
import MyTabs from './bottomtabs.routes';

const AuthStack = createStackNavigator();

const AuthRoutes = () => (
  <AuthStack.Navigator
    screenOptions={{
      headerShown: false,
      // animationEnabled: true,
      // animationTypeForReplace: 'pop',
    }}>
    <AuthStack.Screen name={SCREEN_LOGIN.name} component={LoginContainer} />
    <AuthStack.Screen name="MyTabs" component={MyTabs} />
  </AuthStack.Navigator>
);

export default AuthRoutes;
