/* @flow */

import React, { Component } from 'react';
import { FlatList, Image, StyleSheet, Text, View } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import Spinner from 'react-native-loading-spinner-overlay';

import style from '~/common/style';
import Images from '@assets/Images';
import * as config from '~/config/config';
import { Search } from '../../components';
import EmptyView from '../../components/EmptyView';
import ProspectItem from './ProspectItem';
import color from '../../common/color';
import translate from '../../locales';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  addUserImage: {
    width: 20,
    height: 20,
    marginRight: 10,
    tintColor: config.Colors.primaryColor,
  },
  addUserContainer: {
    backgroundColor: config.Colors.primaryColor,
    borderBottomColor: '#ddd',
    paddingVertical: 12,
  },
  addUserButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    marginHorizontal: 40,
    paddingVertical: 6,
    paddingHorizontal: 16,
    backgroundColor: '#fff',
  },
  addUserText: {
    color: config.Colors.primaryColor,
    fontSize: 15,
  },
  container: {
    flex: 1,
    backgroundColor: 'white',
    flexDirection: 'column',
    margin: 10,
    padding: 5,
  },
  statusContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 3,
  },
  titleContainer: {
    alignItems: 'center',
  },
  title: {
    color: color.primaryColor,
    fontSize: 14,
    fontWeight: '500',
  },
  statusName: {
    fontWeight: '400',
  },
});

class Prospects extends Component {
  renderProspecttem = ({ item, index }) => (
    <ProspectItem
      onPressItem={this.props.onPressItem}
      index={index}
      item={item}
      statusProspects={this.props.statusProspects}
    />
  );

  renderHeader() {
    const { prospects, statusProspects } = this.props;
    let renderHeader = null;

    if (statusProspects) {
      renderHeader = (
        <View style={styles.container}>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>{`${translate('resume')}`}</Text>
          </View>
          {prospects &&
            statusProspects.map(sp => {
              const size = prospects.filter(p => p.situacao === sp.codstatus).length;
              return (
                <View style={[styles.statusContainer]}>
                  <Text style={[styles.statusName, { color: sp.cor }]}>{sp.descricao}</Text>
                  <Text style={styles.statusValue}>{size}</Text>
                </View>
              );
            })}
        </View>
      );
    }

    return renderHeader;
  }

  render() {
    const {
      loading, onPressAdd, onChangeText, showSearch, prospects,
    } = this.props;

    const emptyMessage = `${translate('noneProspects')}`;

    return (
      <View style={style.container}>
        {onPressAdd && (
          <View style={styles.addUserContainer}>
            <Touchable
              onPress={() => {
                onPressAdd();
              }}
            >
              <View style={styles.addUserButton}>
                <Image source={Images.iconAddUser} style={styles.addUserImage} />
                <Text style={styles.addUserText}>{`${translate('add')}`}</Text>
              </View>
            </Touchable>
          </View>
        )}
        {showSearch ? <Search onChangeText={onChangeText} /> : null}
        <Spinner
          visible={loading}
          overlayColor="rgba(0, 0, 0, 0.7)"
          textStyle={{ color: '#FFF' }}
        />
        {prospects && prospects.length > 0 ? (
          <FlatList
            ListHeaderComponent={this.renderHeader()}
            data={prospects}
            removeClippedSubviews
            renderItem={this.renderProspecttem}
          />
        ) : (
          <EmptyView icon={Images.iconCustomerBig} message={emptyMessage} />
        )}
      </View>
    );
  }
}

Prospects.propTypes = {
  statusProspects: PropTypes.array,
  prospects: PropTypes.array,
  loading: PropTypes.bool,
  showSearch: PropTypes.bool,
  onPressAdd: PropTypes.func,
  onChangeText: PropTypes.func,
  onPressItem: PropTypes.func,
};

export default Prospects;
