import React from 'react';
import { Text, View } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import styles from './styles';
import translate from '~/locales';
import onPressHelper from '~/utils/onPressHelper';
import PropTypes from 'prop-types';

import * as RootNavigation from '../../../../src/routes/RootNavigation';

const FooterCategories = ({ categories, onPressItem, route, navigation }) => {
  const navigateToAllCategories = () => {
    RootNavigation.push('Categories', {
        categories,
        onPressItem: onPressItem,
    });
  };

  const pressNavigateToAllCategories = onPressHelper.debounce(
    navigateToAllCategories,
    1000,
  );

  return (
    <View style={styles.containerFooter}>
      <Touchable
        style={styles.btnContainer}
        onPress={pressNavigateToAllCategories}>
        <Text style={{
          fontSize: 14,
          fontWeight: 'bold',
        }}>{translate('See_All')}</Text>
      </Touchable>
    </View>
  );
};

FooterCategories.defaultProps = {
  componentIdCart: '',
  categories: [],
  onPressItem: () => {},
};

FooterCategories.propTypes = {
  componentIdCart: PropTypes.string,
  categories: PropTypes.array,
  onPressItem: PropTypes.func,
  navigation: PropTypes.func,
};

export default FooterCategories;
