import React, { Component } from 'react';
import { View, Text } from 'react-native';

import styles from './styles';
import ButtonIconInfo from '../../../../components/ButtonsIcon/ButtonIconInfo';
import ProductImage from '../../../../../components_base/src/components/Image';
import { adjustHtml, getSourceImageProduct } from '../../../../utils/ProductUtil';
import MoreInfo from '../../../../../components_base/src/components/MoreInfo/MoreInfo';
import translate from '../../../../locales';
import HTML from 'react-native-render-html';

import PropTypes from 'prop-types';

class ProductItem extends Component {
  state = {
    modalMoreInfosVisible: false,
    sourceProductImage: null,
  };

  componentDidMount() {
    if (!this.props.product.image) {
      const { product, config } = this.props;
      const sourceProductImage = getSourceImageProduct(product, config);
      this.setState({ sourceProductImage });
    }
  }

  /**
   * o Component HTML não é renderizado se o html vier com as tags de 'tabela'
  * */
  adjustHtml = (html) => adjustHtml(html);

  render() {
    const { product, showProductAsHTML } = this.props;
    const { sourceProductImage } = this.state;

    return (
      <View style={styles.container}>
        <View style={styles.cardImage}>
          <ProductImage
            styleError={{ position: 'absolute', left: '-44%' }}
            source={this.props.product?.image || sourceProductImage}
          />
          {product.cor && product.cor !== '0,0,0' && <View
            style={[
              styles.statusProduct,
              { alignSelf: 'center', backgroundColor: `rgb(${product.cor})` },
            ]}
          />}
        </View>
        <View style={{ marginTop: 10 }}>
          {showProductAsHTML ? (
            <View style={{ flexDirection: 'row', width: 220 }}>
              <HTML
                containerStyle={{ marginLeft: 10 }}
                source={{ html: this.adjustHtml(product.html) }}
              />
            </View>
          ) : (
            <View>
              <Text style={styles.cod}>
                {`${translate('code')}:`}
                <Text style={styles.cod_value}> {product.codproduto}</Text>
              </Text>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={{ width: 220 }}>
                  <Text style={styles.description}>{product.descricao || ''}</Text>
                  {product.estoque &&
                  <Text style={styles.cod}>
                    {`${translate('stock')}:`}
                    <Text style={styles.cod_value}> {product.estoque}</Text>
                  </Text>
              }
                </View>
                {product.html &&
                <View style={{ alignItems: 'flex-end' }}>
                  <ButtonIconInfo
                    onPress={() => { this.setState({ modalMoreInfosVisible: true }); }}
                  />
                </View>
            }
              </View>
            </View>
          )}
        </View>
        <MoreInfo
          modalMoreInfosVisible={this.state.modalMoreInfosVisible}
          htmlContent={product.html}
          onModalClose={() => { this.setState({ modalMoreInfosVisible: false }); }}
        />
      </View>
    );
  }
}

ProductItem.propTypes = {
    product: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    showProductAsHTML: PropTypes.bool.isRequired,
};

export default ProductItem;
