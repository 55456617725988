import React, { Component } from 'react';
import { View, Text, Alert, Image } from 'react-native';

import RNFS from 'react-native-fs';
import FileViewer from 'react-native-file-viewer';
import Touchable from 'react-native-platform-touchable';
import Spinner from 'react-native-loading-spinner-overlay';

import styles from './styles';
import Images from '@assets/Images';
import translate from '../../locales';

import PropTypes from 'prop-types';

class AttachmentPDF extends Component {
  state = { loading: false };

  openFile = async () => {
    const { name, value } = this.props.attach;

    const localFile = `${RNFS.DocumentDirectoryPath}/${name}.pdf`;
    const options = { fromUrl: value, toFile: localFile };

    try {
      this.setState({ loading: true });
      await RNFS.downloadFile(options).promise;
      FileViewer.open(localFile, { showOpenWithDialog: true, showAppsSuggestions: true });
    } catch (error) {
      Alert.alert(`${translate('noOpenFilePDF')}`);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading } = this.state;
    const { name } = this.props.attach;
    return (
      <Touchable
        onPress={this.openFile}
      >
        <View style={styles.containerPDF}>
          <Spinner
            visible={loading}
            overlayColor="rgba(0, 0, 0, 0.7)"
            textStyle={{ color: '#FFF' }}
          />
          <Image
            style={styles.iconContainerPDF}
            source={Images.iconPdf}
          />
          <Text style={styles.text}>{name}</Text>
        </View>
      </Touchable>
    );
  }
}

AttachmentPDF.propTypes = {
    attach: PropTypes.object.isRequired,
};

export default AttachmentPDF;
