import {
  CALL_GENERIC_PROPERTY,
  genericFuncWrapper,
} from '~/modules/WrappedModule';

const ClientsApiModule = nameModule => {
  const methodByAlias = {
    getListClients: 'listarClientes',
    getNewClients: 'listarNovosClientes',
    detailsClient: 'detalhesCliente',
    preRegisterCancelClient: 'cancelarPreCadastro',
    conditionSaleValid: 'condicoesVendaValida',
    parametersPreRegisterClient: 'parametrosPreCadastro',
    parametersRegisterAgainClient: 'parametrosReCadastro',
    getScheduleListing: 'listagemRoteiro',
    parametersFormProspect: 'parametrosFormularioProspect',
    listProspect: 'listarProspeccoes',
    saveProspect: 'salvarProspect',
    updateStatusProspect: 'alterarStatusProspect',
    getProcessList: 'obterListagemProcessos',
    getStatusAllProcesses: 'obterStatusProcessos',
    listNotifications: 'listarNotificacoes',
    listSolicitations: 'listarSolicitacoes',
    markAsRead: 'marcarComoLida',
    allowPcadOrder: 'habilitaPedidoPcad',
    getSaleConditionDefault: 'obterCondicaoVendaPadrao',
    getPreRegisterClient: 'obterPreCadastro',
    saveEventBeforeRegister: 'eventoSalvarPreCadastro',
    getReRegisterClient: 'obterReCadastro',
  };

  const genericProperties = {};
  Object.keys(methodByAlias).forEach(
    k => (genericProperties[k] = CALL_GENERIC_PROPERTY),
  );

  return {
    getName: () => nameModule,
    methodByAlias,
    ...genericProperties,
  };
};

export default ClientsApiModule;
