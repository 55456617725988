import * as config from "./config/config";
import {StyleSheet, Platform} from "react-native";
import { fonts } from "./utils/Fonts";

let badge = {
  width: 20,
  height: 20,
  backgroundColor: config.Colors.redBadge,
  borderRadius: 12,
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
}

const defaultStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: config.Colors.grayContainer
  },
  emptyContainer: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  emptyImage: {tintColor: config.Colors.grayDark, width: 80, height: 80, marginBottom: 20},
  emptyText: {
    color: config.Colors.grayDark,
    fontSize: 18,
    textAlign: 'center',
    marginHorizontal: 16,
    fontFamily: fonts.QuicksandBold,
    marginBottom: 15,
  },
  emptySubText: {
    color: config.Colors.grayDark,
    fontSize: 15,
    textAlign: 'center',
    marginHorizontal: 16,
    marginBottom: 15,
  },
  modal: {
    backgroundColor: '#fff',
    borderRadius: Platform.OS == 'ios' ? 10 : 2,
    elevation: 3
  },
  modalTitle: {
    fontSize: 18,
    color: Platform.OS == 'ios' ? '#000' : config.Colors.primaryColor,
    textAlign: Platform.OS == 'ios' ? 'center' : 'left',
    fontFamily: fonts.QuicksandBold,
  },
  modalMessage: {
    color: '#000',
    marginTop: Platform.OS == 'ios' ? 5 : 16,
    fontSize: Platform.OS == 'ios' ? 12 : 15,
    textAlign: 'center',
  },
  modalInput: {
    marginTop: 10,
    borderRadius: Platform.OS == 'ios' ? 5 : 0,
    borderWidth: Platform.OS == 'ios' ? 1 : 0,
    borderColor: Platform.OS == 'ios' ? '#bababa' : 'transparent',
    padding: 5,
  },
  leftRightItens: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: 6,
    shadowColor: '#000',
    shadowOffset: {width: 0.2, height: 0.6},
    shadowOpacity: 0.2,
    shadowRadius: 1,
    elevation: 1,
  },
  headerContainer: {
    backgroundColor: '#fff',
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  campaignHead: Platform.OS == 'ios' ?
    {
      fontSize: 16,
      color: '#000',
      margin: 16,
    }:
    {
      fontSize: 15,
      fontFamily: fonts.QuicksandBold,
      color: '#000',
      margin: 16,
    },
  subhead: Platform.OS == 'ios' ?
    {
      fontSize: 18,
      fontFamily: fonts.QuicksandBold,
    } :
    {
      color: config.Colors.primaryColor,
      fontFamily: fonts.QuicksandBold,
    },
  subheadMin: Platform.OS == 'ios' ?
    {
      fontSize: 16,
      fontFamily: fonts.QuicksandBold,
    } :
    {
      color: config.Colors.primaryColor,
      fontFamily: fonts.QuicksandBold,
    },
  productPrice: {
    fontSize: 20,
    color: '#000',
  },
  textListItens: {
    color: config.Colors.grayMid,
    fontSize: 16,
    margin: 16,
  },
  productMinPrice: {
    flex: 1,
    fontSize: 14,
    color: '#000',
  },
  badge: badge,
  badge24: {
    ...badge,
    width: 24,
    height: 24,
  },
  badge18: {
    ...badge,
    width: 18,
    height: 18,
  },
  badge16: {
    ...badge,
    width: 16,
    height: 16,
  },
  badgeText: {
    fontSize: 12,
    color: '#fff',
    backgroundColor: 'transparent',
  },
  touchableButtonAndroid: {
    borderRadius: 5,
    margin: 16,
    textAlign: 'center',
    flex: 1,
    alignItems: 'center',
    padding: 10,
    elevation: 3,
    backgroundColor: config.Colors.primaryColor
  },
  addRemoveContainer: {
    flex: 1,
    padding: Platform.OS === 'ios' ? 6 : 0,
    paddingRight: 6,
    paddingLeft: 6,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  addContainer: {
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 3
    },
    shadowRadius: 3,
    shadowOpacity: 0.7,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTopColor: config.Colors.grayAlmostWhite,
    borderTopWidth: 1,
  },
  addRemoveIcon: {
    width: 24,
    height: 24,
    margin: 3,
  },
  addRemoveButton: {
    height: 30,
    width: 30,
    borderRadius: 15,
    margin: 5,
    backgroundColor: config.Colors.primaryColor
  },
});

export default defaultStyles;
