import UserTaskListController from '../controllers/UserTaskListController';
import TasksRepository from '../database/repository/TasksRepository';
import AnswerController from '../controllers/AnswerController';

export async function processHasAnswer(item, dateSelected) {
  const userTaskListRepository = new UserTaskListController();
  const tasksRepository = new TasksRepository();
  const answerController = new AnswerController();

  const userTaskList = await userTaskListRepository.getByTaskListAndEventTask(
    item.task_list,
    item.pkey,
    item.source,
    dateSelected,
  );

  if (!userTaskList) {
    return false;
  }

  const tasks = await tasksRepository.getTasksByTaskList(item.task_list);

  for (const task of tasks) {
    const answer = await answerController.getByUserTaskListAndTask(
      userTaskList.id,
      task.id,
    );
    if (answer) {
      return true;
    }
  }

  return false;
}

export default async function isProcessDone(item, dateSelected) {
  const userTaskListRepository = new UserTaskListController();
  const tasksRepository = new TasksRepository();
  const answerController = new AnswerController();

  const userTaskList = await userTaskListRepository.getByTaskListAndEventTask(
    item.task_list,
    item.pkey,
    item.source,
    dateSelected,
  );

  if (!userTaskList) {
    return false;
  }

  let tasks = await tasksRepository.getTasksByTaskList(item.task_list);
  tasks = tasks?.filter(task => {
    const setup = JSON.parse(task.setup);
    return setup.required === true;
  });

  const answers = [];
  for (const task of tasks) {
    const answer = await answerController.getByUserTaskListAndTask(
      userTaskList.id,
      task.id,
    );
    if (answer) {
      answers.push(answer);
    }
  }

  return tasks.length === answers.length;
}
