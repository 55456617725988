import React, { Component } from 'react';
import { Alert, BackHandler, Image, TouchableOpacity } from 'react-native';

import _ from 'lodash';

import ConditionsSalesChild from './ConditionsSalesChild';

import { connect } from 'react-redux';

import GeneralModule from '../../modules/GeneralModule';
import OrdersModule from '../../modules/OrdersModule';
import { emp2long } from '../../utils/FastSellerUtils';
import Images from '@assets/Images';
import { cleanProductsPrices } from '../../store/productsCleanPrices/slice';

import {
  orderClient,
  ORDER_FETCH_REQUEST,
  ORDER_FETCH_SUCCESS,
} from '~/store/ordersFetch/orderFetchSlice';

import { setCurrentProduct } from '../../store/productCurrent/slice';

import PropTypes from 'prop-types';
import { createNotifyError } from '~/components/Web/ToastNotify';

const TAG = 'ConditionsSalesChidContainer';

class ConditionsSalesChidContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      productCurrent: props.route?.params?.product,
      tablesChild: [],
      tableChildSelect: { label: '', value: '' },
      prazsChild: [],
      prazChildSelect: { label: '', value: '' },
    };
  }

  componentDidMount() {
    this.setLeftButton();
    this.loadConditionsSalesChild();
  }

  componentDidUpdate({ order }) {
    (async () => {
      if (order.type === this.props.order.type) {
        return;
      }
      if (order.type !== ORDER_FETCH_REQUEST) {
        return;
      }
      if (this.props.order.type !== ORDER_FETCH_SUCCESS) {
        return;
      }

      this.setState({ loading: false });
    })();
  }

  handleBackPress = () => {
    this.props.navigation.goBack();
    return true;
  };

  async loadConditionsSalesChild() {
    try {
      let productActive = await OrdersModule.getItemActive(
        this.state.productCurrent.chave,
      );
      productActive = JSON.parse(productActive);

      const tablesChild = await this.loadTablesChild(productActive);
      const tabelaPrecoFilha = _.get(
        productActive,
        'condvenda.tabelapreco',
        '0',
      );
      let tableChildSelect = tablesChild.find(
        item => item.value === tabelaPrecoFilha,
      );
      if (!tableChildSelect) {
        tableChildSelect = { label: '', value: '' };
      }

      const prazsChild = await this.loadPrazChild(productActive);
      const prazoFilho = _.get(productActive, 'condvenda.prazo', '0');
      let prazChildSelect = prazsChild.find(item => item.value === prazoFilho);
      if (!prazChildSelect) {
        prazChildSelect = { label: '', value: '' };
      }

      this.setState({
        tablesChild,
        tableChildSelect,
        prazsChild,
        prazChildSelect,
      });

      if (this.props.onChangeTableOrDeadline) {
        this.props.onChangeTableOrDeadline({
          tableChildSelect,
          prazChildSelect,
        });
      }
    } catch (errors) {
      console.tron.log(TAG, 'loadConditionsSalesChild', errors);
      const jssonArray = JSON.parse(errors);
      createNotifyError(jssonArray[0].mensagem);
    }
  }

  async loadTablesChild(product) {
    const order = this.props.order.payload;
    const orgvenda = _.get(order, 'condvenda.orgvenda', '0');
    const codcliente = _.get(order, 'condvenda.codcliente', '0');
    const tabelapreco = _.get(order, 'condvenda.tabelapreco', '0');
    const tabelaPrecoFilha = _.get(product, 'condvenda.tabelapreco', '0');

    const groupId = emp2long('TBFL');
    const id = groupId;
    const params = {
      pk_cliente: codcliente,
      pk_org_venda: orgvenda,
      pk_tabela: tabelapreco,
      pk_tabela_filha: tabelaPrecoFilha,
    };

    try {
      const tablesChildPromise = new Promise((resolve, reject) =>
        GeneralModule.getCustomList(
          `${groupId}`,
          `${id}`,
          JSON.stringify(params),
          true,
          null,
          resolve,
          reject,
        ),
      );
      let tablesChild = await tablesChildPromise;
      tablesChild = JSON.parse(tablesChild).data;
      tablesChild = tablesChild.map(item => ({
        label: item.descricao,
        value: item.pkey,
      }));

      return tablesChild;
    } catch (errors) {
      console.log(TAG, 'loadPrazChild', errors);
      throw errors;
    }
  }

  async loadPrazChild(product) {
    const order = this.props.order.payload;
    const orgvenda = _.get(order, 'condvenda.orgvenda', '0');
    const tipovenda = _.get(order, 'condvenda.tipovenda', '0');
    const codmeio = _.get(order, 'condvenda.codmeio', '0');
    const codcliente = _.get(order, 'condvenda.codcliente', '0');
    const tabelaPrecoFilha = _.get(product, 'condvenda.tabelapreco', '0');
    const codprazo = _.get(order, 'condvenda.codprazo', '0');
    const prazoFilho = _.get(product, 'condvenda.prazo', '0');

    const groupId = emp2long('PRZF');
    const id = groupId;
    const params = {
      pk_cliente: codcliente,
      pk_org_venda: orgvenda,
      pk_tipo_venda: tipovenda,
      pk_meio_pag: codmeio,
      pk_tabela: tabelaPrecoFilha,
      pk_prazo: codprazo,
      pk_prazo_filho: prazoFilho,
    };

    try {
      const prazsChildPromise = new Promise((resolve, reject) =>
        GeneralModule.getCustomList(
          `${groupId}`,
          `${id}`,
          JSON.stringify(params),
          true,
          null,
          resolve,
          reject,
        ),
      );
      let prazsChild = await prazsChildPromise;
      prazsChild = JSON.parse(prazsChild).data;
      prazsChild = prazsChild.map(item => ({
        label: item.descricao,
        value: item.pkey,
      }));

      return prazsChild;
    } catch (errors) {
      console.log(TAG, 'loadPrazChild', errors);
      throw errors;
    }
  }

  handleChangeTableChild = tableChild => {
    const { onChangeTableOrDeadline } = this.props;
    if (tableChild.value === this.state.tableChildSelect.value) {
      return;
    }

    this.setState({ loading: true });
    const tablePrice = tableChild.value;
    const praz = this.state.prazChildSelect.value;

    if (onChangeTableOrDeadline) {
      onChangeTableOrDeadline({ tablePrice, praz });
      return;
    }

    this.changeConditionsSaleOrder(tablePrice, praz);
  };

  handleChangePrazChild = prazChild => {
    const { onChangeTableOrDeadline } = this.props;
    if (prazChild.value === this.state.prazChildSelect.value) {
      return;
    }

    this.setState({ loading: true });
    const tablePrice = this.state.tableChildSelect.value;
    const praz = prazChild.value;

    if (onChangeTableOrDeadline) {
      onChangeTableOrDeadline({ tablePrice, praz });
      return;
    }

    this.changeConditionsSaleOrder(tablePrice, praz);
  };

  changeConditionsSaleOrder = async (tabelapreco, prazo) => {
    const { dispatch, order, product } = this.props;
    const condSale = { tabelapreco, prazo };

    try {
      const productStrinfy = JSON.stringify(this.state.productCurrent);
      const consSaleStringfy = JSON.stringify(condSale);

      let productActive = await OrdersModule.updateConditionsSalesChild(
        productStrinfy,
        consSaleStringfy,
      );

      productActive = JSON.parse(productActive);
      await this.setState({
        productCurrent: {
          ...this.state.productCurrent,
          ...productActive,
        },
      });

      dispatch(setCurrentProduct(productActive));
      dispatch(cleanProductsPrices());
      await this.loadConditionsSalesChild();

      if (product.quantidade > 0) {
        const { codigo, condvenda, orcamento } = order.payload;
        const { codcliente } = condvenda;

        const payloadOrderClient = {
          codigo,
          pkeyClient: codcliente,
          condvenda,
          orcamento,
        };
        dispatch(orderClient(payloadOrderClient));
      } else {
        this.setState({ loading: false });
      }
    } catch (error) {
      const messages = JSON.parse(error.message);
      createNotifyError(messages[0]);
      this.setState({ loading: false });
    }
  };

  setLeftButton = () => {
    this.props.navigation.setOptions({
      headerLeft: () => (
        <TouchableOpacity onPress={() => {
          this.handleBackPress();
        }}
        >
          <Image
            source={Images.iconBack}
            style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
          />
        </TouchableOpacity>
      ),
    });
  };

  render() {
    const {
      loading,
      tablesChild,
      tableChildSelect,
      prazsChild,
      prazChildSelect,
    } = this.state;
    return (
      <ConditionsSalesChild
        loading={loading}
        tablesChild={tablesChild}
        tableChildSelect={tableChildSelect}
        prazsChild={prazsChild}
        prazChildSelect={prazChildSelect}
        onChangeTableChild={this.handleChangeTableChild}
        onChangePrazChild={this.handleChangePrazChild}
      />
    );
  }
}

ConditionsSalesChidContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    order: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    onChangeTableOrDeadline: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    order: state.orderFetch,
  };
}

export default connect(mapStateToProps)(ConditionsSalesChidContainer);
