import SyncFactory from './SyncFactory';
import SyncSalesFactory from './SyncSales/SyncSalesFactory';

const TAG = 'Sync';

export default class Sync {
  static async sync(progress) {
    try {
      const syncSales = SyncSalesFactory.factory('syncV1');
      await syncSales.execute(progress);
    } catch (error) {
      console.log(TAG, 'sync', 'Error', error);
      throw new Error(error);
    }

    return true;
  }

  static async uploadOnly(progress) {
    try {
      const syncSales = SyncSalesFactory.factory('syncV1');
      await syncSales.executeOnlyUpload(progress);
    } catch (error) {
      console.tron.log(TAG, 'sync', 'Error', error);
      throw error;
    }

    return true;
  }

  static async isInitialDownload() {
    const syncs = await SyncFactory.create();
    const promisses = [];

    syncs.forEach(sync => {
      promisses.push(sync.isInitialDownload());
    });

    return Promise.all(promisses).then(results => {
      const value = results.find(result => result === true);
      return !!value;
    });
  }

  static async getDownloadComplete() {
    const syncSales = SyncSalesFactory.factory('syncV1');
    return await syncSales.cache.getDownloadComplete();
  }

  static async setDownloadComplete(complete) {
    const syncSales = SyncSalesFactory.factory('syncV1');
    await syncSales.cache.setDownloadComplete(complete);
  }

  static getLastSerial() {
    const syncSales = SyncSalesFactory.factory('syncV1');
    return syncSales.cache.getLastSerial();
  }

  static async setLastSerial(value) {
    const syncSales = SyncSalesFactory.factory('syncV1');
    await syncSales.cache.setLastSerial(value);
  }
}
