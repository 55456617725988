import { StyleSheet } from 'react-native';
import color from '~/common/color';

export default StyleSheet.create({
  input: {
    backgroundColor: color.grayContainer,
    fontSize: 14,
    borderRadius: 20,
    minHeight: 28,
    flex: 1,
  },
  inputPrice: {
    borderRadius: 20,
    paddingVertical: 4,
    paddingLeft: 10,
    fontSize: 18,
    flex: 1,
  },
  containerQty: {
    flexDirection: 'row',
    borderRadius: 25,
    maxHeight: 32,
    paddingHorizontal: 2,
    paddingVertical: 2,
  },
  productMinPrice: {
    fontSize: 14,
    marginLeft: 10,
    marginRight: 10,
    color: '#000',
  },
  productPriceTable: {
    color: color.grayDark,
    marginLeft: 16,
  },
  productSubTotal: {
    fontSize: 14,
    marginLeft: 10,
    marginRight: 10,
    color: '#000',
  },
  productName: {
    fontSize: 16,
    marginBottom: 6,
    marginLeft: 10,
    marginRight: 10,
    color: color.grayMid,
  },
  productMultiple: {
    fontSize: 14,
    marginLeft: 10,
    marginRight: 10,
    color: '#000',
  },
  productPrice: {
    fontSize: 18,
    marginLeft: 10,
    marginRight: 10,
    color: '#000',
  },
  productBasePrice: {
    fontSize: 14,
    marginLeft: 10,
    marginRight: 10,
    color: '#000',
  },
});
