import React, { Component } from 'react';
import { connect } from 'react-redux';

import RawBottomSheet from '../RawBottomSheet';
import ClientItem from './ClientItem';
import PropTypes from 'prop-types';

class RawBottomSheetClients extends Component {
  state = {
    selectedClient: {},
  };

  componentDidMount() {
    const { selectedClient } = this.props;
    this.setState({ selectedClient });
  }

  onSelectOption = selectedClient => {
    this.setState({ selectedClient });
  };

  onPressConfirm = () => {
    const { onPressConfirm } = this.props;
    const { selectedClient } = this.state;

    if (onPressConfirm) {
      onPressConfirm(selectedClient);
    }
  };

  getValueFromKey = (key, infoAdicionais) => {
    let value;
    for (let i = 0; i < infoAdicionais.length; ++i) {
      if (infoAdicionais[i].chave === key) {
        value = infoAdicionais[i].valor;
        break;
      }
    }
    return value;
  };

  componentClientItem = item => {
    return (
      <ClientItem client={item} selectedClient={this.state.selectedClient} />
    );
  };

  render() {
    const { clients, setRef } = this.props;
    const { selectedClient } = this.state;

    return (
      <RawBottomSheet
        setRef={setRef}
        showSearch
        renderComponentItem={this.componentClientItem}
        height={450}
        itens={clients.payload}
        itemSelected={selectedClient}
        onSelectOption={this.onSelectOption}
        onPressConfirm={this.onPressConfirm}
        titleStyle={{ fontSize: 15, marginBottom: 7, width: '70%' }}
        showConfirmButton
      />
    );
  }
}

RawBottomSheetClients.propTypes = {
    clients: PropTypes.object,
    selectedClient: PropTypes.object,
    setRef: PropTypes.func,
    onPressConfirm: PropTypes.func,
    };

function mapStateToProps(state) {
  return {
    clients: state.clientsFetch,
  };
}

export default connect(mapStateToProps)(RawBottomSheetClients);
