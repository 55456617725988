export const ERROR_GERAL = 'ERROR_GERAL';
export const USER_NOT_AUTHORIZED = 'USER_NOT_AUTHORIZED';
export const USER_MESSAGE_USER_NOT = 'Equipamento não é mais autorizado para conexão. O sistema será encerrado. Contacte a empresa.';
export const ERROR_PROCESS_DATA_SALES = 'ERROR_PROCESS_DATA_SALES';
export const MESSASE_ERROR_PROCESS_DATA_SALES = 'Conclua suas alterações para sincronizar.';
export const ERROR_UPLOAD_GET_SERIAL = 'ERROR_UPLOAD__SERIAL';
export const MESSAGE_ERROR_UPLOAD_GET_SERIAL = 'Erro ao enviar suas informações! Não foi possível recuperar seu último serial.';
export const ERROR_UPLOAD_TO_SERVER = 'ERROR_UPLOAD__SERIAL';
export const MESSAGE_ERROR_UPLOAD_TO_SERVER = 'Erro ao enviar suas informações! Servidor não recebeu suas informações.';
export const ERROR_DOWNLOAD_TO_SERVER = 'ERROR_DOWNLOAD_TO_SERVER';
export const MESSAGE_ERROR_DOWNLOAD_TO_SERVER = 'Erro ao receber suas informações!';
export const ERROR_PROCESS_TABLES = 'ERROR_PROCESS_TABLES';
export const MESSAGE_ERROR_PROCESS_TABLES = 'Erro no processamento de tabelas';
export const ERROR_NETWORK_CONNECT = 'ERROR_NETWORK_CONNECT';
export const MESSAGE_ERROR_NETWORK_CONNECT = 'Por favor verifique sua conexão de internet e tente novamente.';
export const CHECK_INTERNET_CONNECT = 'CHECK_INTERNET_CONNECT';
export const USER_PASSWD_EXPIRED = 'data_sync_control_error_5';

export const SYNC_STARTED = 'SYNC_STARTED';
export const SYNC_DOWNLOAD_SALES = 'SYNC_DOWNLOAD_SALES';
export const SYNC_PROCESS_SALES = 'SYNC_PROCESS_SALES';
export const SYNC_UPLOAD_SALES = 'SYNC_UPLOAD_SALES';
export const SYNC_DOWNLOAD_ACTIVITIES = 'SYNC_DOWNLOAD_ACTIVITIES';
export const SYNC_PROCESS_ACTIVITIES = 'SYNC_PROCESS_ACTIVITIES';
export const SYNC_UPLOAD_ACTIVITIES = 'SYNC_UPLOAD_ACTIVITIES';
export const SYNC_FINALLY = 'SYNC_FINALLY';

export const GENERATE_DATA_STARTED = 'GENERATE_DATA_START';
export const GENERATE_DATA_FINALLY = 'GENERATE_DATA_FINALLY';

export const LAST_UPLOAD_TIME = 'LAST_UPLOAD_TIME';
