import { StyleSheet } from 'react-native';
import { color } from '~/common';

const stylesCarouselPhoto = StyleSheet.create({
  itemSeparatorComponent: {
    width: 5,
  },
  listHeaderComponent: {
    flexDirection: 'row',
  },
  optionContainer: {
    height: 100,
    width: 80,
  },
  containerFlatList: { padding: 10 },
});

const stylesItemPhoto = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  image: {
    borderRadius: 10,
    height: 100,
    width: 80,
  },
  imageIcon: {
    margin: 5,
    width: 20,
    height: 20,
    tintColor: color.grayRed,
  },
});

export { stylesCarouselPhoto, stylesItemPhoto };
