import { Platform } from 'react-native';

const primaryColor = '#074885';

export const Colors = {
  primaryColor,
  primaryColorLight: '#0a7bd6',
  primaryColorLightest: '#b1ddf6',
  tabBarBackgroundColor: '#eeeeee',
  primaryColorDarkest: '#04294b',
  primaryColorDark: '#053867',
  accentColor: '#1CBC26',
  grayRed: '#ae2029',
  grayRedDark: '#8d343a',
  grayTextInput: '#7d7d7d',
  grayContainer: Platform.OS === 'ios' ? '#EFEFF4' : '#F5F5F9',
  grayContainerDark: '#dcdcdc',
  grayDarkest: '#414141',
  grayDark: '#7a7a7a',
  grayMid: '#7d7d7d',
  gray: '#c7c7c7',
  grayAlmostWhite: '#e7e7e7',
  navBarButtonColor: '#fff',
  tabBarSelectedTextColor: primaryColor,
  separatorList: '#d8e1e1',
  blueBadge: '#00a4ff',
  blueIOS: '#157efb',
  redBadge: '#df0800',
  yellowBadge: '#df9900',
  warningColor: '#FF7E00',
  facebookBlue: '#4267b2',
  googleRed: '#db4437',
  graySearchContainer: '#dcdce1',
  stripGreen: '#7dc736',
  stripOrange: '#c76e00',
  infoAlert: '#FF7E00',
  stripGreenDark: '#5f9f2d',
  stripBlue: '#3ba1c1',
  navBarSubtitleColor: '#fff',
  textEmpty: '#8cc5da',
  defaultBackgroundColor: '#f3f3f3',
  simpleDark: '#000',
  borderGray: '#DADCE0',
  simpleTextGray: '#4F4F4F',
  grayLight: '#515151',
  strongRed: '#FF3D31',
};

export const Size = {
  navBarHeight: Platform.OS === 'ios' ? 64 : 56,
  navBarIconSize: Platform.OS === 'ios' ? 20 : 24,
  navBarIconMargin: Platform.OS === 'ios' ? 10 : 8,
  navBarActionSize: Platform.OS === 'ios' ? 40 : 40,
  navBarActionMargin: Platform.OS === 'ios' ? 0 : 8,
};

export const Constants = {
  TOKEN_KEY: 'auth_token',
  TIME_VERSION_CHECKED: 'time_version_checked',
  NOTIFICATION_TOKEN_KEY: 'notification_token',
  CLIENT_ID: 'c4f85113029a4433b3d04b6a1f1c7d2f',
  CLIENT_SECRET: '2449be3b2523469abfab61cefd4deae2',
  GRANT_TYPE: 'password',
  RESPONSE_TYPE_TOKEN: 'token',
  PAYMENT_TYPES: {
    CREDIT_CARD: 'credit_card',
  },
  SENDER_ID: '10997492075',
};

export const ITINERARY_SITUATION = {
  LATE: {
    color: Colors.strongRed,
    label: 'Atrasado',
    timeline: false,
  },
  OUT_OF_ROUTE: {
    color: Colors.grayLight,
    label: 'Fora de rota',
    timeline: true,
  },
  // ON_TIME: {
  //   color: '#27AE60',
  //   label: 'Realizado como planejado',
  //   timeline: true,
  // },
  // ON_ORDER_LATE: {
  //   color: '#E2CD0E',
  //   label: 'Realizado como planejado, mas atrasado',
  //   timeline: true,
  // },
  // ADVANCED: {
  //   color: '#0066FF',
  //   label: 'Adiantado',
  //   timeline: true,
  // },
  // SKIPPED: {
  //   color: '#DC8400',
  //   label: 'Realizado fora de ordem',
  //   timeline: true,
  // },
  // PLANNED: {
  //   color: '#0098DA',
  //   label: 'Planejado',
  //   timeline: false,
  // },
};
