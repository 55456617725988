import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import translate from '~/locales';
import styles from './styles';
import { useState } from 'react';
import { useEffect } from 'react';

const NoUpToDateAlert = ({ visible }) => {
  const [toggle, setToggle] = useState(visible);

  useEffect(
    () => {
      setToggle(visible);
    },
    [visible],
  );

  const onClose = () => {
    setToggle(false);
  };

  return toggle ? (
    <View style={styles.container}>
      <View style={styles.itens}>
        <Text style={styles.title}>{translate('titleNoUpToDate')}</Text>
        <Text style={styles.description}>
          {translate('descriptionNoUpToDate')}
        </Text>
        <TouchableOpacity style={styles.button} onPress={onClose}>
          <Text style={styles.btnText}>{translate('ok')}</Text>
        </TouchableOpacity>
      </View>
    </View>
  ) : null;
};

export default NoUpToDateAlert;
