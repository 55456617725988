import { StyleSheet } from 'react-native';
import colors from './values/color';
import { fonts } from "../../src/utils/Fonts";

const appStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.grayContainer,
  },
  price: {
    fontSize: 20,
    color: colors.grayDark,
  },
  productName: {
    color: colors.grayDarkest,
    fontSize: 16,
    marginRight: 6,
    flex: 1,
  },
  centerView: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  spaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  spaceAround: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  rowDirection: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default appStyles;
