/* @flow */

import React, { Component } from 'react';

import Finish from './Finish';
import { connect } from 'react-redux';

import { updateStatusOpenOrder } from '../../store/ordersFetch/orderFetchSlice';
import { syncUpload } from '../../TaskSync';
import { Navigation } from 'react-native-navigation';

import PropTypes from 'prop-types';

class FinishContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    syncUpload();
  }

  backToRoot() {
    Navigation.dismissAllModals({
      animationType: 'none',
    });
    Navigation.popToRoot(this.props.componentId, {
      animated: true,
      animationType: 'fade',
    });
  }

  onPressHome = async () => {
    this.backToRoot();
    this.props.dispatch(updateStatusOpenOrder(true));
  };

  navigationButtonPressed(event) {
    switch (event.buttonId) {
      case 'backPress':
        this.onPressHome();
        break;

      default:
        break;
    }
  }

  render() {
    return <Finish onPressHome={this.onPressHome} cart={this.props.order} />;
  }
}

FinishContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    navigator: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,
    makeOrderModule: PropTypes.object.isRequired,
    navigatorScreen: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    makeOrderModule: state.makeOrderModule,
  };
}

export default connect(mapStateToProps)(FinishContainer);
