import React from 'react';
import _ from 'lodash';
import { Alert, Image, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import DynamicForm from './DynamicForm';
import RegisterAgainLib from '../../../services/resources/libFastSeller/RegisterAgainLib';
import {
  GENERATE_DATA_FINALLY,
  GENERATE_DATA_STARTED,
  SYNC_PROCESS_SALES,
} from '../../../constants/Sync';
import { syncUpdateData } from '../../../store/syncFetch/slice';
import { existsAnsweredField, formatQSQLDataSource } from '../../../utils/Form'; //
import translate from '../../../locales';
import getGpsConfigs from '../../../utils/ConfigHelper';
import types from '../../../../src/components/Form/types';
import { Navigation } from 'react-native-navigation';
import NavigationHelper from '~/screens/NavigationHelper';
import Snackbar from 'react-native-snackbar';
import { handlePermissions } from '~/store/permission/slice';
import Images from '@assets/Images';
import { createNotifyError } from '~/components/Web/ToastNotify';
import { showAlert } from '~/components/Web/Alert';

type Props = {
  event: any,
  item: any,
  syncFetch: any,
  dateSelected: any,
  saveAnswerActivity: (
    payloadAnswer: Object<any>,
    successSaveAnswer: Function<any>,
    failureSaveAnswer: Function<any>,
  ) => void,
  dispatch: any,
};

const REGISTER_AGAIN = 'register_again';

class DynamicFormContainer extends React.Component<void, Props, void> {
  constructor(props) {
    super(props);

    this.state = {
      form: [],
      dataForm: {},
      dateOpen: '',
      visibleButton: false,
      loading: false,
      location: {},
      gpsConfig: {},
    };
  }

  componentWillMount() {
    this.sendOpenEvent = true;
    this.handleLocationGPS();

    let setupFields = _.get(this.props.route.params.pushObject, 'item.answer.payload.fields') || false;
    const startDate =
      _.get(this.props.route.params.pushObject, 'item.answer.start_date') || new Date().toISOString();

    if (!setupFields) {
      const setup = JSON.parse(this.props.route.params.pushObject.item.setup);
      const { type } = setup;

      if (type && type === REGISTER_AGAIN) {
        RegisterAgainLib.getParametersRegisterAgain(this.props.route.params.pushObject.event.pkey)
          .then(dataStr => {
            const data = JSON.parse(dataStr);
            const dataFormStr = _.get(data, 'form_data', '[]');
            setupFields = JSON.parse(dataFormStr);
            this.setState({ form: setupFields, dateOpen: startDate });
          })
          .catch(erro => {
            const dataErro = JSON.parse(erro || '[]');
            Snackbar.show({ text: `${dataErro[0]?.mensagem}` });
          });
      } else {
        setupFields = setup.fields;
        this.setState({ form: setupFields, dateOpen: startDate });
      }
    } else {
      const dataForm = {};

      const newSetupFields = [...setupFields].map(field => {
        const { value, ...rest } = field;
        dataForm[field.field] = value;

        return rest;
      });

      this.setState({
        form: newSetupFields,
        dateOpen: startDate,
        dataForm,
      });
    }
  }

  UNSAFE_componentWillReceiveProps({ syncFetch }) {
    (() => {
      if (syncFetch.type === this.props.syncFetch.type) {
        return;
      }
      if (
        syncFetch.payload.statusSync !== SYNC_PROCESS_SALES &&
        syncFetch.payload.screen === 'activities.DynamicForm'
      ) {
        this.sendRegisterAgain();
      }
    })();
  }

  componentDidDisappear() {
    this.sendOpenEvent = false;
  }

  componentWillUnmount() {
    this.sendOpenEvent = false;
    }

  navigationButtonPressed(event) {
    switch (event.buttonId) {
      case 'backPress' || 'RNN.hardwareBackButton':
        this.handleBackPress();
        break;
      default:
        break;
    }
  }

  iconLeft = () => {
    this.props.navigation.setOptions({
        headerLeft: () => (
          <TouchableOpacity onPress={() => {
            this.handleBackPress();
          }}
          >
            <Image
              source={Images.iconBack}
              style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
            />
          </TouchableOpacity>
        ),
      });
};

  onLocationChange = location => {
    if (location && (location.latitude || location.latitude)) {
      this.setState({ location }, () => {
        if (this.sendOpenEvent) {
          RegisterAgainLib.saveEventOpenRegisterAgain(
            this.props.route.params.pushObject.event.pkey,
            JSON.stringify(this.state.location),
          ).then(() => {
            this.sendOpenEvent = false;
          });
        }
      });
    } else if (!this.state.gpsConfig.requiredCollectGpsPoint) {
      if (this.sendOpenEvent) {
        RegisterAgainLib.saveEventOpenRegisterAgain(
            this.props.route.params.pushObject.event.pkey,
          JSON.stringify(this.state.location),
        ).then(() => {
          this.sendOpenEvent = false;
        });
      }
    }
  };

  getFormData = dataForm => {
    const { form } = this.state;
    const formWithValue = form.map(field => {
      if (field.type === types.RESULT.name) {
        const value = this.applyOperation(field.rule, dataForm);
        /* eslint-disable no-param-reassign */
        dataForm[field.field] = value;
        return {
          ...field,
          value,
        };
      }
      return { ...field };
    });
    this.setState({ dataForm, form: formWithValue });
  };

  applyOperation = (rule = {}, dataForm = {}) => {
    let result = 0;
    const { operation = '', fields = [] } = rule;
    const expression = operation.toString().toUpperCase();
    switch (expression) {
      case 'SUM':
      case 'MINUS':
      case 'MULTI':
        result = this.sumMinusOrMulti(fields, expression, dataForm);
        break;
      case 'DIVIS':
      case 'PERC':
        result = this.division(rule, dataForm);
        break;
      default:
    }

    if (operation === 'PERC') {
      return result === 0
        ? '0'
        : parseFloat(result * 100)
            .toFixed(2)
            .toString()
            .concat(' %');
    }

    return result === 0
      ? '0'
      : parseFloat(result)
          .toFixed(2)
          .toString();
  };

  division = (rule, dataForm) => {
    const { dividend: fields1, divisor: fields2 } = rule;
    const dividend = this.sumMinusOrMulti(fields1, 'SUM', dataForm);
    const divisor = this.sumMinusOrMulti(fields2, 'SUM', dataForm);

    if (divisor === 0) {
      return 0;
    }
    return parseFloat(dividend / divisor).toFixed(2);
  };

  sumMinusOrMulti = (fields, op, dataForm) =>
    fields.reduce((previousValue, currentValue) => {
      const currValue = parseFloat(dataForm[currentValue]);
      if (Number.isNaN(currValue)) {
        return previousValue;
      }
      let returnedValue = 0;
      switch (op) {
        case 'SUM':
          returnedValue = currValue + previousValue;
          break;
        case 'MINUS':
          returnedValue = currValue - previousValue;
          break;
        case 'MULTI':
          if (previousValue === 0) {
            return currValue;
          }
          returnedValue = currValue * previousValue;
          break;
        default:
      }
      return returnedValue;
    }, 0);

  acceptYesBackPress = () => {
    this.props.navigation.pop();
    return false;
  };  

  handleBackPress = () => {
    if (existsAnsweredField(this.state.dataForm)) {
      showAlert(translate('discardChanges'), `${translate('discardChangesInForm')}`, this.acceptYesBackPress);
      return true;
    }
    this.props.navigation.pop();
    return false;
  };

  isFormValid = valid => {
    this.setState({ visibleButton: valid });
  };

  sendForm = () => {
    this.setState({ loading: true });
    const setup = JSON.parse(this.props.item.setup);
    const { type } = setup;

    if (type && type === REGISTER_AGAIN) {
      const { syncFetch } = this.props;
      const { statusSync } = syncFetch.payload;

      if (statusSync !== SYNC_PROCESS_SALES) {
        this.sendRegisterAgain();
      }
    } else {
      this.sendAnswer();
    }
  };

  handleLocationGPS = () => {
    const { dispatch, permission } = this.props;
    const { gpsConfig } = this.state;
    const permissions = _.get(permission, 'payload.response', {});

    const getGpsConfig = getGpsConfigs(
      'required_in_registering_again',
      'notify_in_registering_again',
    );

    this.setState({ gpsConfig: getGpsConfig }, () => {
      if (permissions.location !== 'authorized') {
        const {
          showPermissionLocation,
          requiredCollectGpsPoint,
          notifyOnSaving,
        } = gpsConfig;

        if (
          showPermissionLocation ||
          requiredCollectGpsPoint ||
          notifyOnSaving
        ) {
          dispatch(handlePermissions(['location']));
        }
      }
    });
  };

  async sendRegisterAgain() {
    const { dataForm, location, gpsConfig } = this.state;

    try {
      await RegisterAgainLib.sendDataForm(
        Object.assign({}, dataForm),
        this.props.route.params.pushObject.event.pkey,
        gpsConfig,
        location,
      );
      this.sendAnswer();
    } catch (error) {
      const dataErro = JSON.parse(error);

      this.setState({ loading: false }, () => {
        createNotifyError(`${dataErro?.[0]?.mensagem}`);
      });
    }
  }

  sendAnswer() {
    const { dataForm, form, location } = this.state;
    const formWithValue = form.map(item => ({
      ...item,
      value: dataForm[item.field],
    }));
    const payloadAnswer = {
      dateSelected: this.props.route.params.pushObject.dateSelected,
      dateOpen: this.state.dateOpen,
      latitude: null,
      longitude: null,
      payload: { fields: formWithValue, location },
      taskId: this.props.route.params.pushObject.item.id,
    };

    this.props.route.params.pushObject.saveAnswerActivity(
      payloadAnswer,
      this.successSaveAnswer,
      this.failureSaveAnswer,
    );
  }

  formatQSQLDataSource = dataSource => {
    const formattedDataSource = formatQSQLDataSource(dataSource);
    return formattedDataSource;
  };

  successSaveAnswer = () => this.setState({ loading: false });
  failureSaveAnswer = () => this.setState({ loading: false });

  render() {
    const { form, visibleButton, loading, gpsConfig, location } = this.state;

    return (
      <DynamicForm
        isFormValid={this.isFormValid}
        getFormData={this.getFormData}
        sendForm={this.sendForm}
        form={form}
        initialValues={this.state.dataForm}
        visibleButton={visibleButton}
        loading={loading}
        formatQSQLDataSource={this.formatQSQLDataSource}
        gpsConfig={gpsConfig}
        location={location}
        onLocationChange={this.onLocationChange}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    syncFetch: state.syncFetch,
  };
}

export default connect(mapStateToProps)(DynamicFormContainer);
