/* @flow */

import React, { Component } from 'react';
import { Form } from '~/components';
import { style } from '~/common';
import { ScrollView, View } from 'react-native';

import PropTypes from 'prop-types';

class Observations extends Component {
  constructor(props) {
    super(props);
  }

  isFormValid = valid => {
    this.props.isFormValid(valid);
  };

  render() {
    const { form, getFormData } = this.props;
    return (
      <View style={[style.container, { backgroundColor: 'white' }]}>
        <ScrollView>
          <View>
            {form && form.length > 0 ? (
              <Form
                isFormValid={this.isFormValid}
                data={form}
                getFormData={getFormData}
              />
            ) : null}
          </View>
        </ScrollView>
      </View>
    );
  }
}

Observations.propTypes = {
  isFormValid: PropTypes.func.isRequired,
  getFormData: PropTypes.func.isRequired,
  form: PropTypes.array.isRequired,
};

export default Observations;
