import React, { useEffect, useCallback, useState } from 'react';
import { FlatList, View } from 'react-native';
import CategoryItem from '../../../components_base/src/components/Categories/Category';
import NavigationHelper from '../NavigationHelper';
import SearchBar from '~/components/SearchBar';
import translate from '~/locales';
import EmptyView from '~/components/EmptyView';
import CategoryImages from '@assets/CategoryImages';
import Snackbar from 'react-native-snackbar';
import { CategoriesPlaceholder } from './CategoriesPlaceholder';

import PropTypes from 'prop-types';

import styles from './styles';
import { createNotifyError } from '~/components/Web/ToastNotify';

const Categories = ({ navigation, route }) => {
    const categories = route.params.categories;
    const onPressItem = route.params.onPressItem;
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState(categories);

    const backAction = () => {
        navigation.pop();
        return true;
    };


    const controlLoading = () => {
        if (!categories || categories.length === 0) {
            setLoading(false);
        }
        if (categories && categories.length > 0) {
            setLoading(false);
        }
    }

    useEffect(() => {
        controlLoading();
    }, []);

    const handleSearch = useCallback((text) => {
        if (!categories) {
            createNotifyError(translate('errFilterCategories'));
            return;
        }

        const formattedQuery = text.toLowerCase();
        const filteredData = categories.filter((item) => contains(item, formattedQuery));
        setFilteredData(filteredData);
        setSearchText(text);
    }, [categories]);

    const contains = ({ nome }, query) => nome?.toLowerCase().includes(query);

    const handlePressItem = (item, index) => {
        try {
            onPressItem(item, index);
            navigation.pop();
        } catch (error) {
            createNotifyError(translate('errOpenCategory'));
        }
    }

    const renderItem = ({ item, index }) => (
        <View style={styles.column}>
            <View style={
                {
                    flex: 1,
                    alignItems: index % 2 === 0 ? 'flex-start' : 'flex-end',
                    padding: 10,
                }
            }>
                <CategoryItem item={item} index={index} onPressItem={handlePressItem} />
            </View>
        </View>
    );

    return (
        <View style={{
            flex: 1
        }}>
            <View>
                <SearchBar placeholder={`${translate('searchCategories')}`} onSearch={handleSearch} showFilterBtn={false} />
            </View>
            {loading ?
                <CategoriesPlaceholder /> :
                <FlatList
                    data={filteredData}
                    renderItem={renderItem}
                    keyExtractor={({ codigo }) => codigo}
                    numColumns={2}
                    // estimatedItemSize={160}
                    ListEmptyComponent={
                        <EmptyView
                            icon={CategoryImages.iconCategory}
                            message={translate('noCategories')}
                        />
                    }
                />
            }
        </View>
    );
}

Categories.propTypes = {
    componentId: PropTypes.string.isRequired,
    categories: PropTypes.array.isRequired,
    onPressItem: PropTypes.func.isRequired,
    navigation: PropTypes.func,
};

export default Categories;