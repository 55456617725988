import React from 'react';
import { Section } from '../../components';
import { View, Text, Image, ScrollView } from 'react-native';
import Images from '../../../assets/Images';
import Accordion from '../../components/Web/Accordion/Accordion';
import translate from '../../locales';
import styles from './styles';

import PropTypes from 'prop-types';

const renderHeader = (item, i, isActive) => (
  <Section style={styles.orderComboItems}>
    <Text
      numberOfLines={2}
      style={styles.orderComboTitle}
    >{item.codigo} - {item.descricao}
    </Text>
    <Image
      style={styles.orderComboImage}
      source={isActive ? Images.iconChevronUp : Images.iconChevronDown}
    />
  </Section>
);

const renderContent = (section) => (
  <View style={styles.orderComboContent}>
    <Text>{section.codigo}</Text>
    <Text style={styles.orderComboDescription}>{section.descricao}</Text>
    <Text>{section.estoque} {`${translate('inStockLCase')}`}</Text>
  </View>
);

const OrderCombo = ({
  sections,
  activeSections,
  updateSections,
}) => (
  <ScrollView>
    <Accordion
      sections={sections}
      activeSections={activeSections}
      renderHeader={renderHeader}
      renderContent={renderContent}
      onChange={updateSections}
    />
  </ScrollView>
);

OrderCombo.propTypes = {
    sections: PropTypes.array.isRequired,
    activeSections: PropTypes.array.isRequired,
    updateSections: PropTypes.func.isRequired,
};

export default OrderCombo;
