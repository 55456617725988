import { Alert } from 'react-native';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import store from '~/store';
import translate from '~/locales';
import GpsHelper from '~/utils/GpsHelper';

function getConfig(conf) {
  const configLib = store.getState().configLibFetch;
  return get(configLib, `payload.${conf}`, {});
}

function greaterThan(val1, val2) {
  return val1 > val2;
}

function validate(
  { value1, value2, comparativeMethod },
  callBackIsNotValid = () => {},
) {
  const isValid = comparativeMethod(value1, value2);
  if (!isValid) {
    callBackIsNotValid();
  }
}

const COMPARATIVE = {
  gt: greaterThan,
};

class HandlerSaveAnswerLocation {
  constructor() {
    this.nested = [];
    this.continueSaving = true;
  }

  enableTracing() {
    const confGps = getConfig('gps');
    const enableTracking = confGps?.enable_tracing;

    // enable_tracing é uma string 'true' ou 'false'
    return enableTracking ? enableTracking === 'true' : false;
  }

  maximumPrecisionValidate() {
    const {
      location: { accuracy: myAccuracy },
    } = this.props;
    const confGps = getConfig('gps');
    const maximumAccuracy = confGps?.maximum_accuracy ?? false;

    if (maximumAccuracy) {
      const propsValidate = {
        value1: maximumAccuracy,
        value2: myAccuracy,
        comparativeMethod: COMPARATIVE.gt,
      };

      validate(propsValidate, () => {
        throw new Error(`${translate('maximumPrecisionReached')}`);
      });
    }
  }

  rulesGPSClientValidate() {
    const { item, clientLocation, location } = this.props;
    const setup = JSON.parse(item.setup || '{}');

    const message = GpsHelper.validateRulesGPSClient(
      setup,
      clientLocation,
      location,
    );

    if (!isEmpty(message)) {
      throw new Error(message);
    }
  }

  locationValidate() {
    const { location } = this.props;

    if (!(location.latitude && location.longitude)) {
      throw new Error(`${translate('errorPointRequiredGPS')}`);
    }
  }

  handle(props) {
    this.props = props;
    try {
      this.maximumPrecisionValidate();
      this.rulesGPSClientValidate();
      this.locationValidate();
      return true;
    } catch ({ message }) {
      alert(`${translate('cantSaveAnswer')} ${message}`);
      return false;
    }
  }
}

export default new HandlerSaveAnswerLocation();
