import Repository from './Repository';

export default class PhotoAnswersRepository extends Repository {
  constructor() {
    super();
    this.tableName = 'photo_answers';
  }

  async getPhotoToSync() {
    return new Promise((resolve, reject) => {
      const result = [];
      this.db.transaction(tx => {
        const select = `SELECT ${this.getDefaultProjection(
          this.tableName,
          false,
        )} FROM ${
          this.tableName
        } WHERE sync_status IN ('pending_to_sync', 'error');`;

        tx.executeSql(
          select,
          [],
          (tx, results) => {
            const len = results.rows.length;

            for (let i = 0; i < len; i += 1) {
              result.push(this.getValuesFromDatabase(results.rows.item(i)));
            }

            resolve(result);
          },
          error => {
            reject(error);
          },
        );
      });
    });
  }

  async getPhotosSynchronized() {
    return new Promise((resolve, reject) => {
      const result = [];
      this.db.transaction(tx => {
        const select = `SELECT ${this.getDefaultProjection(
          this.tableName,
          false,
        )} FROM ${this.tableName} WHERE sync_status IN ('accepted');`;

        tx.executeSql(
          select,
          [],
          (tx, results) => {
            const len = results.rows.length;

            for (let i = 0; i < len; i += 1) {
              result.push(this.getValuesFromDatabase(results.rows.item(i)));
            }

            resolve(result);
          },
          error => {
            reject(error);
          },
        );
      });
    });
  }

  setPhotoSynchronizedSuccess(id) {
    return new Promise((resolve, reject) => {
      this.db.transaction(tx => {
        const select = `UPDATE ${
          this.tableName
        } SET sync_status = 'accepted', sync_error = NULL WHERE id = '${id}';`;
        tx.executeSql(
          select,
          [],
          () => {
            resolve();
          },
          error => {
            reject(error);
          },
        );
      });
    });
  }

  setPhotoStatusSync(id, status, errorMsg = 'NULL') {
    return new Promise((resolve, reject) => {
      this.db.transaction(tx => {
        const select = `UPDATE ${
          this.tableName
        } SET sync_status = '${status}', sync_error = '${errorMsg}' WHERE id = '${id}';`;
        tx.executeSql(
          select,
          [],
          () => {
            resolve();
          },
          error => {
            reject(error);
          },
        );
      });
    });
  }

  insertPhotos(photos) {
    return new Promise((resolve, reject) => {
      if (photos.length > 0) {
        let i = 0;
        photos.forEach(photoAnswer => {
          const columns = this.getDefaultProjection(this.tableName, false);

          const bind = [];
          const columnsInsert = [];
          const params = [];

          const columnsSplit = columns.split(',');
          for (const key in columnsSplit) {
            const column = columnsSplit[key];
            if (
              photoAnswer[column] !== null &&
              photoAnswer[column] !== undefined
            ) {
              columnsInsert.push(column);
              bind.push('?');
              params.push(this.getValueToInsert(photoAnswer[column]));
            }
          }

          this.db.transaction(tx => {
            const sql = `INSERT OR REPLACE INTO ${
              this.tableName
            } (${columnsInsert}) VALUES (${bind});`;

            tx.executeSql(
              sql,
              params,
              () => {
                i += 1;
                console.tron.log('LFS insert then', this.tableName);
                if (photos.length === i) {
                  resolve(photoAnswer);
                }
              },
              error => {
                console.tron.log('LFS insert error');
                reject(error);
              },
            );
          });
        });
      } else {
        // TODO: Remover esse código após corrigir o fluxo de salvamento de foto. Hoje está chamando photoAnswerActivity sempre.
        resolve();
      }
    });
  }

  successDb() {
    console.log('PhotoAnswersRepository successDb');
  }

  errorDb(err) {
    console.log('PhotoAnswersRepository errorDb', err);
  }
}
