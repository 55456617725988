import React, { Component } from 'react';
import { ActivityIndicator, View } from 'react-native';

import { PagerTitleIndicator } from 'rn-viewpager';

import EmptyView from '../EmptyView';
import Images from '../../../assets/Images';
import translate from '../../../src/locales';
import { fonts } from '../../utils/Fonts';

import styles from './styles';
import Html from 'react-native-render-html';
import { adjustHtml } from '~/utils/GlobalUtils';
import _ from 'lodash';
import { emp2long, getListWithPagination } from '~/utils/FastSellerUtils';
import GeneralModule from '../../modules/GeneralModule';
import { style } from '~/common';
import Touchable from 'react-native-platform-touchable';
import { TabBar, TabView } from 'react-native-tab-view';
import { Colors } from '~/config/config';
import { FlashList } from '@shopify/flash-list';

import PropTypes from 'prop-types';
import ModalOverlay from '../Web/ModalOverLay/index.web';
import { createNotifyError } from '../Web/ToastNotify';

class MultiTabsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            multiTabs: [],
            multiTabsTitle: [],
            multiTabsFiltered: [],
            actualIndex: 0,
            loading: true,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        (() => {
            if (
                this.state.multiTabsFiltered.length !==
                prevState.multiTabsFiltered.length &&
                this.state.loading !== false
            ) {
                this.setState({ loading: false });
            }
        })();
    }

    componentWillUnmount() {
        this.setState({
            multiTabs: [],
            multiTabsTitle: [],
            multiTabsFiltered: {},
        });
    }

    componentDidMount() {
        this.loadMultiTabs();
    }

    async navigationButtonPressed(event) {
        switch (event.buttonId) {
            case 'search':
                this.setState({ loading: true });
                this.onChangeText();
                break;
            default:
                break;
        }
    }

    onChangeText = (text = '') => {
        const textForSearch = text.toLowerCase().trim();
        const multiTabsFiltered = this.state.multiTabs.reduce((acc, tab, index) => {
            const dataFiltered = tab.data.filter(info => {
                const { html = '' } = info;
                return new RegExp(textForSearch, 'gi').test(html);
            });

            if (dataFiltered.length) {
                acc[index] = { ...tab, data: dataFiltered };
            }

            return acc;
        }, {});
        const multiTabsTitle = Object.values(multiTabsFiltered).map(
            tab => tab.title,
        );
        this.setState({ multiTabsFiltered, multiTabsTitle });
    };

    loadMultiTabs = async () => {
        const {
            params: parameters = '{}',
            groupId: group,
            id: propsId,
        } = this.props;

        const tamMemory = _.get(this.props.configLib, 'tam_memoria_paginacao');

        const groupId = emp2long(group);
        const id = propsId || null;
        const params = parameters !== '{}' ? parameters : {};

        try {
            const multiTabs = await getListWithPagination(
                GeneralModule.getCustomListTabs,
                tamMemory,
                `${groupId}`,
                id,
                JSON.stringify(params),
                true,
                null,
            );
            if (multiTabs) {
                this.loadMultiTabsParams(multiTabs);
                this.setState({ loading: false });
            }
        } catch (errors) {
            const messages = JSON.parse(errors);
            const alertMsg = _.get(messages[0], 'mensagem');
            const alertCod = _.get(messages[0], 'codigo');
            if (alertMsg && alertCod !== 'list_data_less') {
                createNotifyError(alertMsg);
            }
        }
    };

    loadMultiTabsParams = multiTabs => {
        const multiTabsTitle = [];

        const multTabs = multiTabs.map((tab, id) => {
            const { title } = tab;
            multiTabsTitle.push(title);

            return { key: id, id, ...tab };
        });

        this.setState({
            multiTabsTitle,
            multiTabs: multTabs,
            multiTabsFiltered: multTabs,
        });
    };

    renderTitleIndicator = () => {
        const { multiTabsTitle } = this.state;

        return (
            <PagerTitleIndicator
                style={styles.PagerTitleIndicator}
                selectedBorderStyle={styles.selectedBorderStyle}
                itemStyle={styles.indicatorStyle}
                selectedItemStyle={styles.indicatorStyle}
                itemTextStyle={styles.indicatorTextBaseStyle}
                selectedItemTextStyle={[
                    styles.indicatorTextBaseStyle,
                    { color: 'white', fontFamily: fonts.QuicksandBold, fontWeight: 'bold' },
                ]}
                titles={multiTabsTitle}
                trackScroll
            />
        );
    };

    keyExtractor = (item, index) => `${item.index}-${index}`;

    renderMultiTab = ({ route }) => {
        const { data = [], id } = route;

        return (
            <View key={id} style={[styles.container]}>
                {data.length ? (
                    <FlashList
                        contentContainerStyle={styles.content}
                        data={data}
                        keyExtractor={this.keyExtractor}
                        removeClippedSubviews={false}
                        refreshing={false}
                        renderItem={this.renderItem}
                        estimatedItemSize={390}
                        ListHeaderComponent={
                            id === 0 ? (
                                <View>
                                    {this.props.renderHeaderComponent?.()}
                                </View>
                            ) : null
                        }
                    />
                ) : (
                    <EmptyView
                        icon={Images.iconBulletedList}
                        message={`${translate('noInformation')}`}
                    />
                )}
            </View>
        );
    };

    renderItem = ({ item: tabItem }) =>
        tabItem.html ? (
            <View
                style={{
                    backgroundColor: '#FFF',
                }}>
                <Touchable
                    style={style.card}
                    onLongPress={() => {
                        if (this.props.onLongPressItem) {
                            this.props.onLongPressItem(tabItem);
                        }
                    }}
                    onPress={() => {
                        if (this.props.onPressItem) {
                            this.props.onPressItem(tabItem);
                        }
                    }}>
                    <View>
                        <Html source={{ html: adjustHtml(tabItem.html) }} />
                    </View>
                </Touchable>
            </View>
        ) : (
            <EmptyView
                icon={Images.iconBulletedList}
                message={`${translate('noHTML')}`}
            />
        );

    handleIndexChange = actualIndex => this.setState({ actualIndex });

    _renderTabBar = props => (
        <TabBar
            {...props}
            indicatorStyle={{ backgroundColor: Colors.stripGreen }}
            indicatorContainerStyle={{ backgroundColor: Colors.primaryColor }}
            inactiveColor={Colors.gray}
            activeColor="white"
        />
    );

    renderTabView() {
        const { actualIndex, multiTabs, multiTabsFiltered } = this.state;
        const RenderMultiTab = this.renderMultiTab;

        const newMultiTabs = multiTabs.map((tab, index) => {
            return {
                ...tab,
                data: multiTabsFiltered[index]?.data || [],
            };
        });

        return (
            <TabView
                renderTabBar={this._renderTabBar}
                navigationState={{ index: actualIndex, routes: newMultiTabs }}
                onIndexChange={this.handleIndexChange}
                renderScene={({ route, jumpTo }) => {
                    switch (route.key) {
                        case actualIndex:
                            return <RenderMultiTab jumpTo={jumpTo} route={route} />;
                    }
                }}
            />
        );
    }

    render() {
        const { multiTabsFiltered: multiTabs = [] } = this.state;
        return Object.keys(multiTabs).length ? (
            this.renderTabView()
        ) : (
            <View>
                <ModalOverlay visible={this.state.loading} closeOnTouchOutside />
                <ActivityIndicator
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        zIndex: 9999,
                    }}
                    animating={this.state.loading}
                    size="large"
                />
                <EmptyView
                    icon={Images.iconBulletedList}
                    message={`${translate('noTabs')}`}
                />
            </View>
        );
    }
}

MultiTabsView.propTypes = {
    configLib: PropTypes.object,
    id: PropTypes.string,
    groupId: PropTypes.string,
    params: PropTypes.object,
    navigator: PropTypes.object,
    onPressItem: PropTypes.func,
    renderHeaderComponent: PropTypes.func,
    onLongPressItem: PropTypes.func,
    showSearch: PropTypes.bool,
};

export default MultiTabsView;
