/* 
@flow
*/

import React, { Component } from 'react';
import Login from './Login';

import { connect } from 'react-redux';

import Account from '~/controllers/Accounts/Account';
import CacheAccount from '../../controllers/Accounts/Cache/CacheAccount';
import AccountModule from '../../modules/AccountsModule';
import translate from '../../locales';
import CacheDataController from '../../utils/CacheDataController';

import { createNotifyError } from '~/components/Web/ToastNotify';

import PropTypes from 'prop-types';
import generateUUID from '~/utils/RandomUUID';

import taskSync, { tasksSync } from '../../TaskSync';

class LoginContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      disabled: false,
    };

    this.cacheAccount = new CacheAccount();
  }

  /* async componentDidAppear() {
    const isLogout = await CacheDataController.getData('@LOGOUT_REF');
    const url = await Linking.getInitialURL();
    if (url) {
      if (!isLogout) {
        this.loginByPoliInstaller(url);
      } else {
        CacheDataController.removeItem('@LOGOUT_REF');
      }
    }
  } */

  onPressLogin = () => {
    this.setState({ showModal: true });
  };

  onPressAuthenticate = async () => {};

  onUUIDChange = async ({ username, password, paramsUuid }) => {
    this.setState({ disabled: true });
    if (!username || !password) {
      createNotifyError(`${translate('usernameAndPasswordMustNotBeEmpty')}`);
      this.setState({ disabled: false });
      return;
    }
    const user = username.trim().toLowerCase();
    const uuid = generateUUID();

    const usernameSplit = user.split('@');
    if (usernameSplit[0]) {
      const usernameSplitPoint = usernameSplit[0].replace(/[^\w. ]/g, '');
      if (usernameSplitPoint !== usernameSplit[0]) {
        createNotifyError(`${translate('invalidUsername')}`);
        this.setState({ disabled: false });
        return;
      }
    }
    if (!usernameSplit[1]) {
      createNotifyError(`${translate('nullOrInvalidCode')}`);
      this.setState({ disabled: false });
      return;
    }
    if (usernameSplit[1].length !== 4) {
      createNotifyError(`${translate('codeMustHaveOnlyFourDigits')}`);
      this.setState({ disabled: false });
      return;
    }
    if (password) {
      const passwordFormated = password.replace(/([à-ü]|[À-Ü])/g, '');
      if (password !== passwordFormated) {
        createNotifyError(`${translate('invalidCredentials')}`);
        this.setState({ disabled: false });
        return;
      }
    }
    const usernameComplete = `${usernameSplit[0]}@${
      usernameSplit[1]
    }.polibr.com.br`;

    if (uuid) {
      await this.updateUUID(uuid);
    }
    const account = new Account(this.onSucess, this.onError);
    await account.login(usernameComplete, password);
    await CacheDataController.saveData('@Session:deviceInfo', { uuid });
  };

  onEggDropTables = async path => {
    createNotifyError(`${translate('baseDeleted')}`);
  };

  onDismissModal = () => {
    this.setState({ showModal: false });
  };

  onSucess = async () => {
    this.setState({ disabled: false });
    tasksSync(true);
    this.props.navigation.navigate('MyTabs');
    taskSync().then(async () => {
      const dataAccount = await this.cacheAccount.getDataAccount();
      const { token } = dataAccount;
      if (!token) {
        const { login, password } = dataAccount;
        const account = new Account(null, null);

        const data = await account.getDataProcess(login, password);
        await cacheAccount.setDataAccount(data);
      }
    });
  };

  onError = text => {
    createNotifyError(text);
    this.setState({ disabled: false });
  };

  showAlert = () => {};

  updateUUID(uuid) {
    return new Promise((resolve, reject) => {
      AccountModule.updateUUID(uuid, resolve, reject);
    });
  }

  handleOpenURL = event => {
    console.tron.log('LFS Link Login Container', event.url);
  };

  async loginByPoliInstaller(url) {}

  render() {
    return (
      <Login
        disabled={this.state.disabled}
        showModal={this.state.showModal}
        onDismissModal={this.onDismissModal}
        onPressLogin={this.onPressLogin}
        onPressAuthenticate={this.onPressAuthenticate}
        onUUIDChange={this.onUUIDChange}
        onEggDropTables={this.onEggDropTables}
      />
    );
  }
}

LoginContainer.propTypes = {
  syncFetch: PropTypes.shape({
      payload: PropTypes.shape({
      statusSync: PropTypes.string,
      }),
  }),
  navigator: PropTypes.shape({
      push: PropTypes.func,
  }),
  navigation: PropTypes.shape({
      navigate: PropTypes.func,
  }),
  login: PropTypes.shape({
      loading: PropTypes.bool,
      payload: PropTypes.shape({}),
      type: PropTypes.string,
  }),
};

const mapStateToProps = state => ({
  syncFetch: state.syncFetch,
});

export default connect(mapStateToProps)(LoginContainer);