import React, { Component } from 'react';
import {
    View,
    Text,
    StyleSheet,
    Image,
    Platform,
} from 'react-native';
import { DateTimePickerAndroid } from '@react-native-community/datetimepicker';
import Touchable from 'react-native-platform-touchable';
import Images from '@assets/Images';
import types from './types';
import moment from 'moment';
import { color as defaultColors, style } from '~/common';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
    textSelected: {
        color: '#000',
        marginVertical: 8,
        marginLeft: 16,
        fontSize: 16,
    },
    textTitle: {
        color: '#979797',
        fontSize: 12,
        marginLeft: 16,
        marginTop: 6,
    },
    errorText: {
        color: 'red',
        fontSize: 12,
        marginLeft: 16,
        marginVertical: 6,
    },
    container: {
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        backgroundColor: '#f5f5f5',
    },
    iconMenuDown: {
        width: 24,
        height: 24,
        margin: 8,
        tintColor: 'gray',
    },
});

const months = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
];

class DatePicker extends Component{
    constructor(props) {
        super(props);
        this.state = {
            iosMode: 'datetime',
            showPickerIOS: false,
            iosDate: props.initialValue || new Date(),
            androidDate: props.initialValue || new Date(),
            androidHour: '',
            error: '',
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value && nextProps.value !== '') {
            this.setState({ selectedDate: nextProps.value });
        }
    }

    onPress = () => {
        switch (this.props.type) {
            case types.DATE.name:
                this.showDatePicker(false);
                break;
            case types.DATE_HOUR.name:
                this.showDatePicker(true);
                break;
            case types.HOUR.name:
                this.showHourPicker();
                break;
            default:
                this.showDatePicker(true);
                break;
        }
    };

    getIcon() {
        switch (this.props.type) {
            case types.DATE.name:
                return Images.iconCalendar;
            case types.DATE_HOUR.name:
                return Images.iconCalendarClock;
            case types.HOUR.name:
                return Images.iconClock;
            default:
                return Images.iconCalendarClock;
        }
    }

    setDateIOS = newDate => {
        let selectedDate = '';
        switch (this.props.type) {
            case types.DATE.name:
                selectedDate = moment(newDate).format('DD/MM/YYYY');
                break;
            case types.DATE_HOUR.name:
                selectedDate = moment(newDate).format('DD/MM/YYYY HH:mm');
                break;
            case types.HOUR.name:
                selectedDate = moment(newDate).format('HH:mm');
                break;
            default:
                selectedDate = moment(newDate).format('DD/MM/YYYY HH:mm');
                break;
        }
        this.setState({ iosDate: newDate, selectedDate }, () => {
            this.props.onChangeValue(
                this.props.field,
                this.state.selectedDate,
                'typed',
            );
        });
    };

    async showTimePickerAndroid() {}

    async showDatePickerAndroid(withHour) {
        try {
            DateTimePickerAndroid.open({
                mode: 'date',
                value: moment().toDate(),
                onChange: (event, date) => {
                    if (event.type !== 'dismissed') {
                        const newDate = moment(date).format('DD/MM/YYYY');
                        this.setState(
                            {
                                androidDate: `${newDate}`,
                                selectedDate: this.state.androidHour
                                    ? `${newDate} ${this.state.androidHour}`
                                    : newDate,
                            },
                            () => {
                                this.props.onChangeValue(
                                    this.props.field,
                                    this.state.selectedDate,
                                );
                            },
                        );
                        if (withHour) {
                            this.showTimePickerAndroid();
                        } else {
                            this.check();
                        }
                    } else {
                        this.check();
                    }
                },
            });
        } catch ({ code, message }) {
            console.warn('Cannot open date picker', message);
        }
    }

    showDatePickerIOS(mode) {
        this.setState({ iosMode: mode, showPickerIOS: true });
    }

    check() {
        if (
            this.props.required &&
            (this.state.selectedDate === '' || this.state.selectedDate === undefined)
        ) {
            if (this.props.onBlur) {
                this.props.onBlur(true, this.props.field);
            }
            this.setState({ error: 'Esse campo é obrigatório' });
        } else {
            if (this.props.onBlur) {
                this.props.onBlur(false, this.props.field);
            }
            this.setState({ error: '' });
        }
    }

    showDatePicker(withHour) {
        if (Platform.OS === 'ios') {
            if (this.state.showPickerIOS) {
                this.setState({ showPickerIOS: false });
                this.check();
            } else {
                this.showDatePickerIOS(withHour ? 'datetime' : 'date');
            }
        } else {
            this.showDatePickerAndroid(withHour);
        }
    }

    showHourPicker() {
        if (Platform.OS === 'ios') {
            if (this.state.showPickerIOS) {
                this.setState({ showPickerIOS: false });
                this.check();
            } else {
                this.showDatePickerIOS('time');
            }
        } else {
            this.showTimePickerAndroid();
        }
    }

    render() {
        const styleAllRadius = {
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            borderBottomLeftRadius: 5,
            backgroundColor: 'transparent',
            borderWidth: 1,
            borderColor: '#ddd',
        };
        const active = {
            borderWidth: 2,
            borderColor: defaultColors.primaryColor,
        };
        const titleActive = {
            color: defaultColors.primaryColor,
        };
        const { showPickerIOS, iosMode } = this.state;
        const {
            outline,
            items,
            required,
            title,
            containerStyle,
            color,
            titleColor,
        } = this.props;
        const containerStyles = [styles.container, this.props.style];
        const textStyle = [styles.textTitle];
        if (outline) {
            containerStyles.push(styleAllRadius);
        }
        if (showPickerIOS) {
            containerStyles.push(active);
            textStyle.push(titleActive);
        }

        containerStyles.push(containerStyle);

        return (
            <View style={containerStyles}>
                <Touchable
                    onPress={this.onPress}>
                    <View>
                        <Text style={[textStyle, titleColor ? { color: titleColor } : {}]}>
                            {required ? `${title || ''} *` : title || ''}
                        </Text>
                        <View
                            style={[
                                style.spaceBetween,
                                showPickerIOS
                                    ? { borderBottomWidth: 1, borderBottomColor: '#dedede' }
                                    : {},
                            ]}>
                            <Text
                                ref={this.onRef}
                                style={[styles.textSelected, color ? { color } : {}]}>
                                {this.state.selectedDate}
                            </Text>
                            <Image source={this.getIcon()} style={styles.iconMenuDown} />
                        </View>
                        {(!outline || this.state.error !== '') && (
                            <View style={{ flex: 1, height: 1, backgroundColor: '#ddd' }} />
                        )}
                        {this.state.error !== '' && (
                            <View>
                                <Text style={styles.errorText}>{this.state.error}</Text>
                            </View>
                        )}
                    </View>
                </Touchable>
            </View>
        );
    }
}

DatePicker.propTypes = {
    field: PropTypes.string,
    initialValue: PropTypes.any,
    onChangeValue: PropTypes.func,
    onBlur: PropTypes.func,
    required: PropTypes.bool,
    title: PropTypes.string,
    outline: PropTypes.bool,
    type: PropTypes.string,
    style: PropTypes.any,
    containerStyle: PropTypes.any,
    color: PropTypes.string,
    titleColor: PropTypes.string,
};

export default DatePicker;
