import { NativeModules } from 'react-native';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const PROSPECTS_FETCH_REQUEST = 'PROSPECTS_FETCH_REQUEST';
export const PROSPECTS_FETCH_SUCCESS = 'PROSPECTS_FETCH_SUCCESS';
export const PROSPECTS_FETCH_FAILURE = 'PROSPECTS_FETCH_FAILURE';

const initialState = {
  type: '',
  loading: false,
  erro: false,
  payload: [],
};
const getListProspects = async () => {
  return await NativeModules.ClientsModule.listProspect();
};

export const prospectsFetch = createAsyncThunk(
  'prospects/prospectsFetch',
  async () => {
    try {
      const prospects = await getListProspects();
      let parsedProspects = JSON.parse(prospects);
      parsedProspects =
        parsedProspects.length > 0
          ? parsedProspects.map(pro => ({ ...pro, source: 'prospect' }))
          : [];
      return parsedProspects || [];
    } catch (error) {
      console.log(error);
    }
  },
);

const prospects = createSlice({
  name: 'prospects',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(prospectsFetch.pending, state => {
      state.type = PROSPECTS_FETCH_REQUEST;
      state.loading = true;
    });
    builder.addCase(prospectsFetch.fulfilled, (state, action) => {
      state.type = PROSPECTS_FETCH_SUCCESS;
      state.loading = false;
      state.erro = false;
      state.payload = action.payload;
    });
    builder.addCase(prospectsFetch.rejected, (state, action) => {
      state.type = PROSPECTS_FETCH_FAILURE;
      state.loading = false;
      state.erro = true;
      state.payload = action.payload;
    });
  },
});

const { reducer } = prospects;
export default reducer;
