/* @flow */

import React from 'react';
import {
  View,
  Text,
  Platform,
  ProgressViewIOS,
  ActivityIndicator,
  ScrollView,
  StyleSheet,
} from 'react-native';
import { FlashList } from '@shopify/flash-list';
import OrderListItem from './components/OrderListItem';
import { color, style } from '~/common';
import CustomAlert from '../../components/CustomAlert';
import EmptyView from '../../components/EmptyView';
import Images from '@assets/Images';
import translate from '../../locales';
import _ from 'lodash';
import OrderModulesPlaceholder from './components/OrderModulesPlaceholder';
import SearchBar from '~/components/SearchBar';
import Resume from '~/components/FilterGroup/Resume';
import Sorter from '~/components/FilterGroup/Sorter';

import AlertOrders from '~/components/Web/AlertOrders';
import PropTypes from 'prop-types';
import ModalOverlay from '~/components/Web/ModalOverLay/index.web';

const styles = StyleSheet.create({
  emptyContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    alignSelf: 'center',
    top: '50%',
  },
});
function Orders({
  orders,
  loading,
  failure,
  syncData,
  configExportReport,
  searchText,
  location,
  gpsConfigs,
  onChangeTextSearch,
  onPress,
  onCopyPress,
  onDeletePress,
  onEditPress,
  onSharePress,
  onLocationChangeFromMap,
  showOrderHeaderAsHTML,
  onSortChange,
  filters,
  onListFiltered,
  ordersRef,
  setFiltersValues,
  filtersValues,
  orderComponentId,
  navigation
}: Props) {
  const keyExtractor = (item, index) => `${item.numero_pedido}-${index}`;

  const renderItem = ({ item }: React.ReactPropTypes.object) => (
    <OrderListItem
      onPress={onPress}
      onCopyPress={onCopyPress}
      onDeletePress={onDeletePress}
      onEditPress={onEditPress}
      onSharePress={onSharePress}
      order={item}
      configExportReport={configExportReport}
      showOrderHeaderAsHTML={showOrderHeaderAsHTML}
      navigation={navigation}
    />
  );

  const debouncedOnChangeTextSearch = _.debounce(onChangeTextSearch, 500);

  const renderEmpty = () => {
    if (loading || failure) {
      return (
        <View style={styles.emptyContainer}>
          <ActivityIndicator size="large" color={color.primaryColor} />
          {failure && (
            <Text style={style.renderAlert}>{translate('waitALittle')}</Text>
          )}
        </View>
      );
    }
    return (
      <EmptyView
        icon={Images.iconBoxEmpty}
        message={`${translate('no_orders_found')}`}
      />
    );
  };

  if (loading && orders.length === 0) {
    return <OrderModulesPlaceholder />;
  }

  return (
    <View style={style.container}>
      <AlertOrders />
      <ModalOverlay visible={loading} closeOnTouchOutside />
      <ActivityIndicator 
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          zIndex: 9999,
        }}
        animating={loading}
        size="large"
      />
      {syncData && (
        <CustomAlert showModal title={`${translate('dataUpdate')}`} noButtons>
          <Text style={{ marginTop: 16 }}>
            {`${translate('weAreUpdatingYourData')}`}
          </Text>
        </CustomAlert>
      )}
      <View style={{ height: '100%', flex: 1 }}>
        <View>
          <SearchBar
            filters={filters?.fields}
            placeholder={`${translate('searchForOrders')}`}
            onSearch={debouncedOnChangeTextSearch}
            orders={ordersRef}
            onListFiltered={onListFiltered}
            showFilterBtn={filters?.fields?.length > 0}
            setFiltersValues={setFiltersValues}
            filtersValues={filtersValues}
            initialValue={searchText}
          />
        </View>
        {orders && orders.length > 0 ? (
          <FlashList
            refreshing={loading}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
            data={orders}
            estimatedItemSize={180}
            ListFooterComponentStyle={{
              paddingBottom: 15,
            }}
            ListHeaderComponent={
              <>
                <Resume
                  config={filters?.resume}
                  context="orders"
                  list={orders}
                />
                {filters?.sortFields?.length > 0 && (
                  <Sorter
                    orderComponentId={orderComponentId}
                    config={filters?.sortFields}
                    onChangeOption={onSortChange}
                    filtersValues={filtersValues}
                  />
                )}
              </>
            }
          />
        ) : (
          renderEmpty()
        )}
      </View>
    </View>
  );
}

Orders.propTypes = {
  orders: PropTypes.array,
  loading: PropTypes.bool,
  failure: PropTypes.bool,
  syncData: PropTypes.bool,
  configExportReport: PropTypes.object,
  showSearch: PropTypes.bool,
  showOrderHeaderAsHTML: PropTypes.bool,
  location: PropTypes.object,
  gpsConfigs: PropTypes.object,
  onChangeTextSearch: PropTypes.func,
  onPress: PropTypes.func,
  onCopyPress: PropTypes.func,
  onDeletePress: PropTypes.func,
  onEditPress: PropTypes.func,
  onSharePress: PropTypes.func,
  onLocationChangeFromMap: PropTypes.func,
};

export default Orders;
