import { StyleSheet } from 'react-native';
import * as config from '~/config/config';

const styles = StyleSheet.create({
  buttonDefault: {
    marginHorizontal: 10,
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: config.Colors.primaryColor,
  },
  iconDefault: {
    width: 20,
    height: 20,
    margin: 2,
    tintColor: '#fff',
  },
});

export default styles;
