import { StyleSheet, Dimensions, Platform } from 'react-native';
import { defaultShadow } from '~/common';

const IS_IOS = Platform.OS === 'ios';
const { width: viewportWidth, height: viewportHeight } = Dimensions.get('window');

function wp(percentage) {
  const value = (percentage * viewportWidth) / 100;
  return Math.round(value);
}

const slideWidth = wp(75);
const itemHorizontalMargin = wp(2);

export const sliderWidth = viewportWidth;
export const itemWidth = slideWidth + itemHorizontalMargin * 2;

const entryBorderRadius = 8;

const defaultContainer = {
  height: 150,
  marginBottom: IS_IOS ? 0 : -1, // Prevent a random Android rendering issue
  borderRadius: entryBorderRadius,
};


export default StyleSheet.create({
  slideInnerContainer: {
    width: itemWidth,
    paddingHorizontal: itemHorizontalMargin,
  },
  shadowContainer: {
    ...defaultContainer,
    marginBottom: 10,
    marginHorizontal: 5,
    marginTop: 5,
  },
  overflowContainer: {
    ...defaultContainer,
    overflow: 'hidden',
  },
  imageContainer: {
    ...defaultContainer,
  },
  imageContainerEven: {
    backgroundColor: '#1a1917',
    borderRadius: entryBorderRadius,
  },
  image: {
    ...StyleSheet.absoluteFillObject,
    resizeMode: 'cover',
    borderRadius: IS_IOS ? entryBorderRadius : 0,
    borderTopLeftRadius: entryBorderRadius,
    borderTopRightRadius: entryBorderRadius,
  },
  // image's border radius is buggy on iOS; let's hack it!
  radiusMask: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: entryBorderRadius,
    backgroundColor: 'white',
  },
});
