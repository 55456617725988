import React, { Component } from 'react';
import { View, Text, StyleSheet, Image, Platform, Picker, Alert } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import style from '~/common/style';
import Images from '@assets/Images';
import * as config from '../config/config';
import RawBottomSheet from './RawBottomSheet/RawBottomSheet';
import _ from 'lodash';

import PropTypes from 'prop-types';
import { createNotifyError } from './Web/ToastNotify';

const styles = StyleSheet.create({
  textSelected: {
    color: '#000',
    marginVertical: 8,
    marginLeft: 16,
    fontSize: 16,
  },
  textTitle: {
    color: '#979797',
    fontSize: 12,
    marginLeft: 16,
    marginTop: 6,
  },
  container: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: '#f5f5f5',
  },
  iconMenuDown: {
    width: 24,
    height: 24,
    margin: 8,
    tintColor: 'gray',
  },
});

class SelectMenu extends Component {
  constructor(props) {
    super(props);
    const { actions, required } = props || [];
    this.state = {
      icon: null,
      showPickerIOS: false,
      selectedObj: required ? actions.length > 0 && actions[0] : undefined,
      selected: required ? actions.length > 0 && actions[0] && actions[0].label : '',
      options: null,
    };
  }

  componentDidMount() {
    if (this.props.value) {
      this.props.onPress('itemSelected', this.props.actions.indexOf(this.props.value));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value && nextProps.value !== '') {
      if (nextProps.required) {
        const selectedObj = nextProps.actions.filter(o => o.value === nextProps.value)[0];
        this.setState({ selectedObj });
      }
      this.setState({ selected: nextProps.value });
    }
  }

  onError() {
    createNotifyError('Erro! Popup Error');
  }

  onPressOption = (item, index) => {
    this.RbSheetRef.close();
    this.setState({
      selected: this.props.actions[index].label || this.props.actions[index],
    });
    if (this.props.onPress) {
      this.props.onPress('itemSelected', index);
    }
  };

  onPress = () => {
    if (Platform.OS === 'ios') {
      this.setState({ showPickerIOS: !this.state.showPickerIOS });
    } else {
      this.RbSheetRef.open();
    }
  };

  onRef = icon => {
    if (!this.state.icon) {
      this.setState({ icon });
    }
  };

  onChange = (selected) => {
    this.setState({ selected }, () => this.props.onChangeValue(selected.value));
  };

  render() {
    const styleAllRadius = {
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      borderBottomLeftRadius: 5,
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderColor: '#ddd',
    };
    const active = {
      borderWidth: 2,
      borderColor: config.Colors.primaryColor,
    };
    const titleActive = {
      color: config.Colors.primaryColor,
    };
    const { showPickerIOS } = this.state;
    const {
      outline, actions, required, title, titulo, editable = true,
    } = this.props;
    const containerStyle = [styles.container, this.props.style];
    const textStyle = [styles.textTitle];
    if (this.props.outline) {
      containerStyle.push(styleAllRadius);
    }
    if (showPickerIOS) {
      containerStyle.push(active);
      textStyle.push(titleActive);
    }
    return (
      <View style={containerStyle}>
        <Touchable
          onPress={this.onPress}
          disabled={!editable}
        >
          <View>
            <Text style={textStyle}>{required ? `${title || ''} *` : title || ''}</Text>
            <View style={[style.spaceBetween, showPickerIOS ? { borderBottomWidth: 1, borderBottomColor: '#dedede' } : {}]}>
              <Text ref={this.onRef} style={styles.textSelected}>{this.state.selected}</Text>
              <Image
                source={Images.iconMenuDown}
                style={styles.iconMenuDown}
              />
            </View>
            {!outline && <View style={{ flex: 1, height: 1, backgroundColor: '#ddd' }} />}
            { showPickerIOS && <View style={{ flex: 1, height: 1, backgroundColor: '#ddd' }} />}
            { showPickerIOS &&
            <Picker selectedValue={this.state.selected} onValueChange={this.onChange}>
              {actions.map((item) => <Picker.Item label={item} value={item} />)}
            </Picker>
            }
          </View>
        </Touchable>
        <RawBottomSheet
          setRef={ref => { this.RbSheetRef = ref; }}
          showSearch
          height={400}
          itens={actions}
          itemSelected={this.state.selected}
          onSelectOption={this.onPressOption}
          titleStyle={{ fontSize: 15, marginBottom: 7, width: '70%' }}
        />
      </View>
    );
  }
}

SelectMenu.propTypes = {
  actions: PropTypes.array,
  onPress: PropTypes.func,
  onChangeValue: PropTypes.func,
  style: PropTypes.object,
  value: PropTypes.string,
  title: PropTypes.string,
  outline: PropTypes.bool,
  required: PropTypes.bool,
  editable: PropTypes.bool,
};

export default SelectMenu;

