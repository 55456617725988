import { StyleSheet } from 'react-native';
import { color } from '~/common';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    flex: 1,
    top: 0,
    bottom: 0,
    position: 'absolute',
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    width: 680,
    transform: [{ translateX: 940 }],
  },
  modalContent: {
    width: '100%',
    // paddingTop: 20,
    paddingHorizontal: 24,
    paddingBottom: 16,
    borderRadius: 8,
    backgroundColor: color.neutral10,
  },
  title: {
    color: color.neutral900,
    fontSize: 18,
    fontWeight: '700',
    lineHeight: 24,
  },
  separator: {
    alignSelf: 'stretch',
    borderBottomWidth: 1,
    borderBottomColor: '#E6E6E6',
    marginVertical: 16,
  },
  inputContainer: {
    paddingTop: 8,
    paddingBottom: 16,
  },
  textInput: {
    paddingVertical: 10,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: color.neutral900,
    paddingRight: 10,
    paddingLeft: 18,
  },
  radioGroupContainer: {
    marginBottom: 12,
  },
  radioGroup: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  radioButton: {
    paddingHorizontal: 8,
    alignItems: 'center',
  },
  radioBtnText: {
    color: color.neutral900,
    fontSize: 16,
    fontWeight: '400',
    height: 24,
  },
  description: {
    color: color.neutral600,
    fontSize: 12,
    fontWeight: '500',
    lineHeight: 16,
  },
  btnActionsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  btnActionsContent: {
    padding: 6,
  },
  btnCancel: {
    marginRight: 4,
  },
  btnApply: {
    marginLeft: 4,
  },
  btnActionsText: {
    fontSize: 18,
    fontWeight: 'normal',
    lineHeight: 24,
    color: color.primary600,
  },
  textFieldInputContainerStyle: {
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderRadius: 4,
    borderBottomWidth: 1,
    borderBottomColor: color.neutral900,
    backgroundColor: color.whiteColor,
    borderWidth: 1,
  },
  textFieldFocusedInputContainerStyle: {
    borderWidth: 2,
    borderColor: color.primary600,
    borderBottomWidth: 2,
    borderBottomColor: color.primary600,
  },
  textFieldError: {
    borderWidth: 2,
    borderColor: color.feedbackNegative,
    borderBottomWidth: 2,
    borderBottomColor: color.feedbackNegative,
  },
  affixTextStyle: {
    marginLeft: 10,
    color: color.neutral700,
    paddingRight: 0,
  },
  textFieldStyle: {
    marginHorizontal: 0,
    paddingLeft: 0,
  },
});

export default styles;
