import { createAsyncThunk } from '@reduxjs/toolkit';
import { setScale } from './utilsLib';
import { hasProductDataChanged } from '../../utils/ProductUtil';
import { setCurrentProduct } from '../productCurrent/slice';
export const SET_SCALE_REQUEST = 'SET_SCALE_REQUEST';

export const updateScaleProduct = createAsyncThunk(
  'ordersFetch/updateScaleProduct',
  async (payload, { getState, dispatch }) => {
    try {
      const currentProduct = getState().productCurrent.payload;

      const data = await setScale(payload);
      const parseData = JSON.parse(data);

      const order = parseData;
      const { produtos } = order;
      const { item } = payload;

      let product = produtos.find(
        prod => prod.chave === payload.pkeyProduto.toString(),
      );
      let hasProductChanged = false;
      if (!product) {
        hasProductChanged = hasProductDataChanged(currentProduct, item);
        product = item;
      } else {
        hasProductChanged = hasProductDataChanged(item, product);
      }
      if (hasProductChanged) {
        dispatch(setCurrentProduct({ ...item, ...product }));
      }
      return order;
    } catch (error) {
      console.log('error', error);
      return error;
    }
  },
);
