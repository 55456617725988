/* eslint-disable no-param-reassign,no-shadow */
/* @flow */

import React from 'react';
import { ScrollView, StyleSheet, Text, View, Image } from 'react-native';
import { color, style } from '~/common';
import Touchable from 'react-native-platform-touchable';
import Images from '@assets/Images';
import { RadioButton, RadioGroup } from 'react-native-flexi-radio-button';
import types from '~/components/Form/types';
import { Button } from '~/components';
import Number from './components/Number';
import DateRange from './components/DateRange';
import Selector from './components/Selector';

import filter from './components/filters';
import sort from '../../utils/sorterFilter';
import { fonts } from '../../utils/Fonts';
import CheckList from './components/CheckList/CheckList';

import _ from 'lodash';
import translate from '../../locales';
import { Navigation } from 'react-native-navigation';

export const orderActions = [
  { name: 'Crescente', value: 'asc' },
  { name: 'Decrescente', value: 'desc' },
];

export type Attribute = {
  field: string,
  title: string,
  type: string,
  order: number,
  to_sort: boolean,
  to_filter: boolean,
  value?: any,
};

type Props = {
  list: Array<any>,
  navigator: any,
  onListFiltered?: (list: Array<any>) => void,
  saveValues?: (values: {}) => void,
  filtersValues?: {},
};

const styles = StyleSheet.create({
  sectionContainer: {
    padding: 16,
    marginBottom: 16,
  },
  titleSection: {
    color: '#000',
    fontFamily: fonts.QuicksandBold,
    fontSize: 16,
  },
  sortOption: {
    color: color.grayMid,
  },
  radioButton: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: color.grayContainerDark,
  },
  containerIconSort: {
    padding: 8,
  },
  iconSort: {
    height: 28,
    width: 28,
    tintColor: color.primaryTextColor,
  },
});

class Filter extends React.Component<Props, void> {
  UNSAFE_componentWillMount() {
    const { props } = this;
    const { config } = props;
    const sortOptions: Array<Attribute> = [];
    const filterOptions: Array<Attribute> = [];
    const numbersNames = [];
    const listsValuesNames = [];
    config.forEach((attribute: Attribute) => {
      if (attribute.to_sort) {
        sortOptions.push(attribute);
      }
      if (attribute.to_filter && attribute.type !== types.TEXT.name) {
        filterOptions.push(attribute);
      }
      if (
        attribute.type === types.INT.name ||
        attribute.type === types.MONEY.name ||
        attribute.type === types.NUMBER.name
      ) {
        numbersNames.push(attribute.field);
      }

      if (
        attribute.type === types.LIST.name ||
        attribute.type === types.COMBO.name ||
        attribute.type === types.CHECKLIST.name
      ) {
        listsValuesNames.push(attribute);
      }
    });

    const values = [];

    if (props && props.list && props.list.length > 0) {
      numbersNames.forEach(attName => {
        const minValue = _.minBy(
          props.list.map(item => ({
            ...item,
            [attName]: parseFloat(
              item[attName].replace('.', '').replace(',', '.'),
            ),
          })),
          attName,
        );
        const maxValue = _.maxBy(
          props.list.map(item => ({
            ...item,
            [attName]: parseFloat(
              item[attName].replace('.', '').replace(',', '.'),
            ),
          })),
          attName,
        );

        values[attName] = { min: minValue[attName], max: maxValue[attName] };
      });
    }

    const listValues = listsValuesNames.reduce((curr, next) => {
      curr[`${next.field}_values`] = next.values;
      curr[next.field] = next.values.map(value => value.name);
      return curr;
    }, {});

    const { filtersValues = {} } = this.props;

    this.state = {
      selectedOrder: props.selectedOrder || orderActions[0],
      sortOptions,
      filterOptions,
      ...values,
      ...listValues,
      activeSortIndex: 0,
      activeSort: props.activeSort || sortOptions[0],
      ...filtersValues,
    };
    this.styleNavigator(Navigation);
  }

  onSliderChange = (value, field) => {
    this.setState({ [`value_${field}`]: value });
  };

  onDateChange = (value: Date, field: string, kind: 'end' | 'start') => {
    this.setState({ [`${kind}_${field}`]: value });
  };

  onChangeSelection = (value: string, field: string) => {
    const selectedValue = this.state[`${field}_values`].find(
      o => o.name === value,
    );
    this.setState({
      [`value_${field}`]: selectedValue.value,
      [`select_value_${field}`]: selectedValue,
    });
  };

  onChangeCheckList = (value: string, field: string) => {
    const selectValues = this.state[`select_value_${field}`]
      ? [...this.state[`select_value_${field}`]]
      : [];
    const selectedValue = this.state[`${field}_values`].find(
      o => o.name === value,
    );

    const index = selectValues.map(v => v.value).indexOf(selectedValue.value);
    if (index === -1) {
      selectValues.push(selectedValue);
    } else {
      selectValues.splice(index, 1);
    }

    this.setState({
      [`value_${field}`]: selectValues.map(v => v.value),
      [`select_value_${field}`]: selectValues,
    });
  };

  setKey = ({ order, field }) => `${order}-${field}`;

  getFilterOption(option: Attribute) {
    switch (option.type) {
      case types.MONEY.name:
      case types.NUMBER.name:
      case types.INT.name:
        return (
          <Number
            key={() => this.setKey(option)}
            title={option.title}
            minValue={this.state[option.field].min}
            value={this.state[`value_${option.field}`]}
            maxValue={this.state[option.field].max}
            type={option.type}
            onChange={value => this.onSliderChange(value, option.field)}
          />
        );
      case types.DATE_HOUR.name:
      case types.DATE.name:
      case types.HOUR.name:
        return (
          <DateRange
            key={() => this.setKey(option)}
            title={option.title}
            startTitle={translate('dateFrom')}
            endTitle={translate('dateTo')}
            startDate={this.state[`start_${option.field}`]}
            endDate={this.state[`end_${option.field}`]}
            type={option.type}
            onChangeStart={(field, value) =>
              this.onDateChange(value, option.field, 'start')
            }
            onChangeEnd={(field, value) =>
              this.onDateChange(value, option.field, 'end')
            }
          />
        );
      case types.COMBO.name:
      case types.LIST.name:
        const selectValue = this.state[`select_value_${option.field}`] || {
          name: '',
        };
        return (
          <Selector
            key={() => this.setKey(option)}
            title={option.title}
            label={option.title}
            value={selectValue.name}
            type={option.type}
            actions={this.state[option.field]}
            onChange={value => this.onChangeSelection(value, option.field)}
          />
        );
      case types.CHECKLIST.name:
        const selectValueCheck =
          this.state[`select_value_${option.field}`] || [];

        return (
          <CheckList
            key={() => this.setKey(option)}
            title={option.title}
            value={selectValueCheck}
            data={this.state[option.field]}
            onChange={value => this.onChangeCheckList(value, option.field)}
          />
        );
      default:
        return null;
    }
  }

  onChangeOrder = () => {
    const selectedOrder =
      this.state.selectedOrder.value === 'asc'
        ? orderActions[1]
        : orderActions[0];
    this.setState({ selectedOrder });
  };

  getNonEmptyFilters = (fieldsAndValues, next) => {
    let value;
    let selectValue;
    if (next.type === types.DATE.name) {
      const value1 = this.state[`start_${next.field}`];
      const value2 = this.state[`end_${next.field}`];
      if (value1) {
        fieldsAndValues.values[`start_${next.field}`] = value1;
      }
      if (value2) {
        fieldsAndValues.values[`end_${next.field}`] = value2;
      }
      if (value1 || value2) {
        value = [];
        value.push(value1, value2);
      }
    } else {
      selectValue = this.state[`select_value_${next.field}`];
      value = this.state[`value_${next.field}`];
    }
    if (selectValue) {
      fieldsAndValues.values[`select_value_${next.field}`] = selectValue;
    }
    if (value) {
      fieldsAndValues.values[`value_${next.field}`] = value;
      const attribute = {
        ...next,
        value,
      };
      fieldsAndValues.fields.push(attribute);
    }
    return fieldsAndValues;
  };

  styleNavigator(navigator) {
    navigator
      ? Navigation.mergeOptions(this.props.componentId, {
          topBar: {
            title: {
              text: 'Filtrar',
              color: 'white',
            },
            background: {
              color: color.primaryColor,
            },
            backButton: {
              color: color.primaryColor,
            },
          },
        })
      : null;
  }

  selectSort = index => {
    const { sortOptions } = this.state;
    this.setState({ activeSort: sortOptions[index], activeSortIndex: index });
  };

  apply = () => {
    const { filterOptions, activeSort, selectedOrder } = this.state;
    const { fields: config, values } = filterOptions.reduce(
      this.getNonEmptyFilters,
      { fields: [], values: {} },
    );

    const filtered = filter(this.props.list, config);
    const sortAndFiltered = sort(filtered, activeSort, selectedOrder.value);
    if (this.props.onListFiltered) {
      this.props.onListFiltered(sortAndFiltered);
    }
    if (this.props.saveValues) {
      values.activeSort = this.state.activeSort;
      values.activeSortIndex = this.state.activeSortIndex;
      values.selectedOrder = this.state.selectedOrder;
      this.props.saveValues(values);
    }
    Navigation.dismissModal(this.props.componentId);
  };

  render() {
    const {
      sortOptions,
      activeSortIndex,
      filterOptions,
      selectedOrder,
    } = this.state;

    return (
      <View style={style.containerWhite}>
        <ScrollView>
          <View style={styles.sectionContainer}>
            <View style={style.spaceBetween}>
              <Text style={styles.titleSection}>{`${translate(
                'orderBy',
              )}`}</Text>
              <Touchable onPress={this.onChangeOrder}>
                <View style={styles.containerIconSort}>
                  <Image
                    style={styles.iconSort}
                    source={
                      selectedOrder.value === 'asc'
                        ? Images.iconAscending
                        : Images.iconDescending
                    }
                  />
                </View>
              </Touchable>
            </View>
            <RadioGroup
              style={{ marginTop: 20 }}
              color={color.gray}
              selectedIndex={activeSortIndex}
              activeColor={color.primaryColor}
              onSelect={this.selectSort}>
              {sortOptions.sort((a, b) => a.order < b.order).map(option => (
                <RadioButton
                  key={() => this.setKey(option)}
                  style={styles.radioButton}
                  value={option.name}>
                  <Text style={styles.sortOption}>{option.title}</Text>
                </RadioButton>
              ))}
            </RadioGroup>
          </View>
          {filterOptions.map((option: Attribute) =>
            this.getFilterOption(option),
          )}
        </ScrollView>
        <Button
          containerStyle={{ marginHorizontal: 16, marginVertical: 12 }}
          title="Aplicar"
          onPress={this.apply}
        />
      </View>
    );
  }
}

export default Filter;
