/* @flow */

import React from 'react';
import { View, Image, Text, Platform, StyleSheet } from 'react-native';

import Touchable from 'react-native-platform-touchable';
import PropTypes from 'prop-types';

import { color } from '~/common';
import Images from '@assets/Images';
import { fonts } from '../../utils/Fonts';

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 8,
    paddingVertical: 16,
    gap: 16,
    flexDirection: 'row',
  },
  userInfo: {
    paddingVertical: 12,
    flexDirection: 'column',
    flex: 1,
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  userName: {
    fontSize: 16,
    fontWeight: '700',
    paddingBottom: 8,
    color: color.neutral900,
  },
  userLoginCompanyContainer: {
    flexDirection: 'row',
  },
  userLoginCompany: {
    fontSize: 12,
    fontWeight: '500',
    color: color.neutral900,
  },
  separator: {
    height: 1,
    backgroundColor: '#E6E6E6',
    marginLeft: 20,
  },
  image: {
    height: Platform.OS === 'ios' ? 60 : 70,
    width: Platform.OS === 'ios' ? 60 : 70,
    borderRadius: Platform.OS === 'ios' ? 30 : 35,
    marginHorizontal: 16,
  },
  name: {
    color: Platform.OS === 'ios' ? 'black' : 'white',
    fontFamily: fonts.QuicksandMedium,
    fontSize: 18,
    marginRight: 16,
  },
  subinfo: {
    fontSize: 12,
    color: Platform.OS === 'ios' ? color.grayDark : 'white',
    fontFamily: fonts.Quicksand,
  },
  imageCameraContainer: {
    height: Platform.OS === 'ios' ? 60 : 70,
    width: Platform.OS === 'ios' ? 60 : 70,
    borderRadius: Platform.OS === 'ios' ? 30 : 35,
    marginHorizontal: 16,
    backgroundColor: color.grayContainerDark,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageCamera: {
    height: Platform.OS === 'ios' ? 40 : 50,
    width: Platform.OS === 'ios' ? 40 : 50,
    tintColor: color.grayMid,
  },
});

function ImageInfo({ image, onSelectImage }) {
  return (
    <Touchable
      onPress={onSelectImage}>
      {image ? (
        <Image style={styles.image} source={image} />
      ) : (
        <View style={styles.imageCameraContainer}>
          <Image style={styles.imageCamera} source={Images.iconCamera} />
        </View>
      )}
    </Touchable>
  );
}

ImageInfo.propTypes = {
  image: PropTypes.any,
  onSelectImage: PropTypes.func.isRequired,
};

function UserInfoDetail({
  userInfo: { userName, userLogin, userCompany },
  handleEasterEgg,
}) {
  return (
    <View>
      <View>
        <Text style={styles.userName}>{userName?.value ?? ''}</Text>
      </View>
        <View style={styles.userLoginCompanyContainer}>
          <Text style={styles.userLoginCompany}>
            {userLogin?.label ?? ''}:{' '}
          </Text>
          <Text style={styles.userLoginCompany}>{userLogin?.value ?? ''}</Text>
        </View>
        <View style={styles.userLoginCompanyContainer}>
          <Text style={styles.userLoginCompany}>
            {userCompany?.label ?? ''}:{' '}
          </Text>
          <Text style={styles.userLoginCompany}>
            {userCompany?.value ?? ''}
          </Text>
        </View>
    </View>
  );
}

UserInfoDetail.propTypes = {
  userInfo: PropTypes.shape({
    userName: PropTypes.string,
    userLogin: PropTypes.string,
    userCompany: PropTypes.string,
  }),
  handleEasterEgg: PropTypes.func.isRequired,
};

function TopInfo({ image, onSelectImage, userInfo, handleEasterEgg }) {
  return (
    <View>
      <View style={styles.container}>
        <ImageInfo image={image} onSelectImage={onSelectImage} />
        <UserInfoDetail userInfo={userInfo} handleEasterEgg={handleEasterEgg} />
      </View>
      <View style={styles.separator} />
    </View>
  );
}

TopInfo.propTypes = {
  image: PropTypes.any.isRequired,
  onSelectImage: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired,
  handleEasterEgg: PropTypes.func.isRequired,
};

export default TopInfo;
