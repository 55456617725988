export function convertToFloat(value) {
  const replace = `${value}`.replace(',', '.');
  const valueFloat = parseFloat(replace);
  return valueFloat;
}

export function convertMonyToFloat(value) {
  const replace = `${value}`.replace('.', '');
  return convertToFloat(replace);
}
