/* @flow */

import React, { Component } from 'react';
import {
  View,
  Text,
  Image,
  StyleSheet,
  TextInput,
  Alert,
  ScrollView,
} from 'react-native';
import NavigationHelper from '~/screens/NavigationHelper';
import Images from '@assets/Images';
import DateTimePicker from '../components/DateTimePicker';
import DateTime from '~/components/Form/Field/DateTime';;
import { color, style } from '~/common';
import moment from 'moment';
import Checkbox from '~/modules-wrapper/react-native-modest-checkbox';
import Masked from 'vanilla-masker';
import * as config from '~/config/config';
import { Button, SubHeader } from '~/components';
import translate from '../../../locales';
import { Navigation } from 'react-native-navigation';
import { showAlert } from '~/components/Web/Alert';

const moneyFormatter = {
  precision: 2,
  separator: ',',
  delimiter: '.',
  unit: translate('currencySymbol'),
};

type Props = {
  setup: Object,
  fields: String,
  item: Object,
  navigator: Object,
  canClear: Boolean,
  onBlur: Function,
  onFinishEditingEvent: Function,
  onClearFields: Function,
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderBottomColor: color.grayContainer,
    borderBottomWidth: 2,
  },
  name: {
    color: '#000',
    fontSize: 16,
    marginRight: 10,
  },
  input: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: color.grayContainer,
    fontSize: 14,
    borderRadius: 15,
    paddingVertical: 4,
    minHeight: 24,
    minWidth: 100,
    margin: 4,
    flex: 1,
  },
  inputTitle: {
    fontSize: 13,
    color: color.primaryColorLight,
    marginRight: 10,
  },
  positionAbsolute: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fff',
  },
});

class GridFormContainer extends Component<Props> {
  constructor(props) {
    super(props);

    this.state = {
      isDateTimePickerVisible: false,
      fields: JSON.parse(this.props.route.params.fields),
      isFormValid: false,
    };
    this.index = null;
    this.setup = props.route.params.setup || {};
  }

  componentWillMount() {
    const isFormValid = this.isFormValid();
    this.setState({ isFormValid });
  }

  componentWillAppear() {
    const isFormValid = this.isFormValid();
    this.setState({ isFormValid });
  }

  navigationButtonPressed(event) {
    if (event.buttonId === 'search') {
      return;
    }
  }

  isFormValid() {
    const { fields = [] } = this.state;
    let count = 0;
    fields.forEach(f => {
      if (f.required && !f.value) {
        count += 1;
      }
    });
    return count > 0;
  }

  getTextInput(index, type, kind = '') {
    const { fields = [] } = this.state;
    fields[index].value = fields[index].value
      ? fields[index].value.toString()
      : '';

    return (
      <TextInput
        style={styles.input}
        keyboardType="numeric"
        selectTextOnFocus
        value={fields[index].value}
        onBlur={() => {
          const isFormValid = this.isFormValid();
          this.setState({ isFormValid });
        }}
        onChangeText={newValue => {
          let value = newValue;

          if (kind.localeCompare('price_survey') === 0) {
            value = Masked.toMoney(newValue, moneyFormatter);
          } else if (kind.localeCompare('quantity') === 0) {
            value = newValue.replace(/[^0-9]/g, '');
          } else if (kind.localeCompare('weight') === 0) {
            const regExp = /^\d*\.?\d*$/;
            value = regExp.test(newValue)
              ? newValue
              : newValue.replace(/[^0-9|/.]/g, '');
          } else if (kind.localeCompare('quantity') === 0) {
            value = parseInt(newValue, 10);
          }

          fields[index].value = value;
          const isFormValid = this.isFormValid();

          this.setState({ fields, isFormValid });
        }}
      />
    );
  }

  onPressExpiration = index => {
    this.index = index;
    this.setState({ isDateTimePickerVisible: true });
  };

  hideDateTimePicker = () => {
    this.setState({ isDateTimePickerVisible: false }, () => {
      this.index = null;
    });
  };

  dateTimePickerOnConfirm = (date, index) => {
    const { fields } = this.state;
    const newValue = moment(date).format();
    fields[index].value = newValue;
    this.setState({ fields, isDateTimePickerVisible: false }, () => {
      this.index = null;
    });
  };

  getField = (field, index) => {
    const { type, value, kind } = field;
    const { fields = [], isDateTimePickerVisible } = this.state;
    switch (type) {
      case 'input':
        return this.getTextInput(index, type, kind);
      case 'date': {
        return (
          <View style={styles.input}>
            <Image
              source={Images.iconCalendar}
              style={styles.appIcon}
              resizeMode="contain"
            />
            <Text
              style={styles.input}
              onPress={() => this.onPressExpiration(index)}>
              {value ? moment(value).format('L') : ''}
            </Text>
            <DateTimePicker
              isVisible={isDateTimePickerVisible && this.index === index}
              minimumDate={new Date()}
              date={value ? new Date(value) : new Date()}
              onConfirm={date => this.dateTimePickerOnConfirm(date, index)}
              onCancel={this.hideDateTimePicker}
            />
          </View>
        );
      }
      case 'checkbox':
        return (
          <Checkbox
            isChecked={value || false}
            onClick={() => {
              fields[index].value = !value;
              this.setState({ fields });
            }}
          />
        );
      default:
        return null;
    }
  };

  onSaveItemGrid = () => {
    const { fields = [] } = this.state;

    this.props.onFinishEditingEvent({
      ...this.props.item,
      fields,
    });
    NavigationHelper.pop(this.props.componentId);
  };

  confirmClearForm = () => {
    const { fields = [] } = this.state;
    const clearedFields = fields.map(f => ({ ...f, value: '' }));
    if (this.props.onClearFields) {
        this.props.onClearFields({
          ...this.props.item,
          fields: clearedFields,
        });
      }
      this.props.navigation.goBack();
    }

  onClearForm = () => {
    showAlert(translate('clearFields'), translate('wantToClearFields'), this.confirmClearForm);
  };

  render() {
    const { item } = this.props.route.params;
    const { fields = [], isFormValid } = this.state;
    const { can_clear_fields: canClearFields = true } = this.setup;
    return (
      <View style={style.container}>
        <View style={styles.container}>
          <View style={style.rowDirection}>
            <View>
              <Text style={styles.name}>
                {item.name || `${translate('productName')}`}
              </Text>
            </View>
          </View>

          <View
            style={{
              borderBottomWidth: 1,
              borderBottomColor: config.Colors.grayContainerDark,
              paddingBottom: 10,
            }}
          />

          <View
            style={{
              marginLeft: -20,
              paddingBottom: 10,
            }}>
            <SubHeader>Preencha as Informações:</SubHeader>
          </View>
        </View>

        <ScrollView style={{ marginBottom: 90 }}>
          <View
            style={{
              backgroundColor: '#fff',
              padding: 16,
            }}>
            {fields.map((field, index) => (
              <View style={style.rowDirection} key={index}>
                <Text style={styles.inputTitle}>{field.label}: </Text>
                {this.getField(field, index)}
              </View>
            ))}
          </View>
        </ScrollView>

        <View style={styles.positionAbsolute}>
          <Button
            containerStyle={{
              marginHorizontal: 16,
              marginTop: 5,
              marginBottom: 5,
            }}
            title={`${translate('save')}`}
            onPress={this.onSaveItemGrid}
            disabled={isFormValid}
          />
          {!!canClearFields && (
            <Button
              containerStyle={{
                marginHorizontal: 16,
                marginTop: 5,
                marginBottom: 5,
              }}
              textStyle={{ color: 'red' }}
              title={`${translate('clear')}`}
              onPress={this.onClearForm}
              flat
              disabled={isFormValid}
            />
          )}
        </View>
      </View>
    );
  }
}

export default GridFormContainer;
