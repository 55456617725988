import React, { Component } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import NavigationHelper from '../screens/NavigationHelper';

import Images from '../../assets/Images';
import color from '../common/color';
import { SCREEN_FILTER } from '../screens/screens';
import SelectPicker from './SelectPicker';
import sort from '../utils/sorterFilter';
import AlertBadge from '~/components/AlertBadge';
import translate from '../locales';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  containerFilter: {
    backgroundColor: '#f3f3f3',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
  },
  imageFilter: {
    width: 24,
    height: 24,
    marginRight: 10,
    tintColor: color.grayDark,
    marginTop: 10,
  },
  iconSort: {
    height: 24,
    width: 24,
    marginLeft: 5,
    marginTop: 5,
    tintColor: color.grayDark,
  },
});

class Filter extends Component {
  state = {
    listFilter: [],
    filtersValues: {},
    sortOptions: [],
    sortSelected: {},
    sortType: 'asc',
  };

  componentDidMount() {
    this.setState({ listFilter: this.props.list });
  }

  componentDidUpdate({ filters }) {
    (() => {
      if (filters.length === this.props.filters.length) return;
      let sortOptions = [];
      this.props.filters.forEach(option => {
        if (option.to_sort) {
          sortOptions.push(option);
        }
      });

      sortOptions = sortOptions.map(option => ({ ...option, label: option.title, value: option.field }));
      this.setState(
        { sortOptions, sortSelected: sortOptions[0] },
        () => this.changeSort(sortOptions[0]),
      );
    })();
  }

  onListFiltered = (list) => {
    this.setState({ listFilter: list });
    this.props.onListFiltered(list);
  };

  saveValues = (values) => {
    const { activeSortIndex, selectedOrder } = values;
    const sortSelected = this.state.sortOptions[activeSortIndex];

    this.setState({ filtersValues: values, sortSelected, sortType: selectedOrder.value });
  };

  showFilter = () => {
    const { list, filters } = this.props;

    NavigationHelper.showModal(
      SCREEN_FILTER.name,
      SCREEN_FILTER.title,
      {
        list,
        config: filters,
        onListFiltered: this.onListFiltered,
        saveValues: this.saveValues,
        filtersValues: this.state.filtersValues,
      },
    );
  };

  changeSort = (sortSelected) => {
    const {
      listFilter, filtersValues, sortOptions, sortType,
    } = this.state;
    const activeSortIndex = sortOptions.map(s => s.value).indexOf(sortSelected.value);
    const listSort = sort(listFilter, sortSelected, sortType);

    this.setState({ sortSelected, filtersValues: { ...filtersValues, activeSortIndex } });
    this.props.onListFiltered(listSort);
  };

  sortAscDesc = () => {
    const {
      sortType, sortSelected, listFilter, filtersValues,
    } = this.state;
    let type = '';
    let selectedOrder = {};

    if (sortType === 'asc') {
      type = 'desc';
      selectedOrder = { name: `${translate('descending')}`, value: 'desc' };
    } else {
      type = 'asc';
      selectedOrder = { name: `${translate('ascending')}`, value: 'asc' };
    }

    const listSort = sort(listFilter, sortSelected, type);

    this.setState({ sortType: type, filtersValues: { ...filtersValues, selectedOrder } });
    this.props.onListFiltered(listSort);
  };

  render() {
    const { sortSelected, sortOptions, sortType } = this.state;

    return this.props.filters && this.props.filters.length > 0 ? (
      <View style={styles.containerFilter}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Touchable onPress={this.sortAscDesc}>
            <Image
              style={styles.iconSort}
              source={sortType === 'asc' ? Images.iconAscending : Images.iconDescending}
            />
          </Touchable>
          <SelectPicker
            height={280}
            containerStyle={{ borderWidth: 0 }}
            selected={sortSelected}
            items={sortOptions}
            onChangeValue={this.changeSort}
            outline
          />
        </View>
        <Touchable onPress={this.showFilter}>
          <Image source={Images.iconFilter} style={styles.imageFilter} />
        </Touchable>
      </View>
    ) : <AlertBadge icon={Images.iconAlert} text={`${translate('youDontHaveFilters')}`} type="warning" />;
  }
}

Filter.propTypes = {
  list: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  onListFiltered: PropTypes.func.isRequired,
};

export default Filter;
