import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import ProfileContainer from '../screens/Profile/Profile.Container';
import { Colors } from '../config/config';
import { fonts } from '../utils/Fonts';

const Stack = createStackNavigator();

function ProfileStack() {
  return (
    <Stack.Navigator
      screenOptions={{
        title: 'Perfil',
        headerStyle: {
          backgroundColor: Colors.primaryColor,
          textAlign: 'center',
        },
        headerTitleAlign: 'left',
        headerTintColor: '#fff',
        headerTitleStyle: {
          fontWeight: 'bold',
          fontFamily: `${fonts.QuicksandBold}`,
        },
        /* headerRight: () => (
          <View style={{ flexDirection: 'row' }}>
            <View>
              <TouchableOpacity onPress={() => { }}>
                <Image
                  source={Images.iconSync}
                  style={{
                            tintColor: '#f0f0f0', height: 20, width: 20, right: 20,
        }}
                />
              </TouchableOpacity>
            </View>
            <View>
              <TouchableOpacity onPress={() => { }}>
                <Text style={{ color: '#f0f0f0', marginRight: 10 }}>SAIR</Text>
              </TouchableOpacity>
            </View>
          </View>
            ), */
      }}>
      <Stack.Screen
        name="Profile"
        options={{ title: 'Perfil' }}
        component={ProfileContainer}
      />
    </Stack.Navigator>
  );
}

export default ProfileStack;
