import { createSlice } from '@reduxjs/toolkit';

export const PRODUCT_CURRENT = 'PRODUCT_CURRENT';

const initialState = {
  type: '',
  payload: {},
};

const productCurrentSlice = createSlice({
  name: 'productCurrent',
  initialState,
  reducers: {
    setCurrentProduct: (state, action) => {
      state.type = PRODUCT_CURRENT;
      state.payload = action.payload;
    },
  },
});

export const { setCurrentProduct } = productCurrentSlice.actions;

const { reducer } = productCurrentSlice;

export default reducer;
