import React from 'react';
import { Text } from 'react-native';
import Typography from 'politokens/js/Fonts'
import Color from 'politokens/js/Light'

const baseTypographTitle = {
  fontFamily: Typography.textFamilyTitle,
  fontWeight: Typography.textWeightBold,
}

const baseTypographContent = {
  color: Color.colorTextInverseTitle,
  fontFamily: Typography.textFamilyContent,
  fontWeight: Typography.textWeightSemiBold,
}

const typographyFonts = {
  titleHeaderH6: {
    ...baseTypographTitle,
    fontSize: Typography.textTitleH6,
  },
  titleHeaderH5: {
    ...baseTypographTitle,
    fontSize: Typography.textTitleH5,

  },
  titleHeaderH4: {
    ...baseTypographTitle,
    fontSize: Typography.textTitleH4,

  },
  titleHeaderH3: {
    ...baseTypographTitle,
    fontSize: Typography.textTitleH3,

  },
  titleHeaderH2: {
    ...baseTypographTitle,
    fontSize: Typography.textTitleH2,

  },
  titleHeaderH1: {
    ...baseTypographTitle,
    fontSize: Typography.textTitleH1,

  },
  contentNormal: {
    ...baseTypographContent,
    fontWeight: Typography.textWeightNormal,
    fontSize: Typography.textSizeNormal,
  },
  contentSm: {
    ...baseTypographContent,
    fontWeight: Typography.textWeightSemiBold,
    fontSize: Typography.textSizeSm,
  },
  contentXs: {
    ...baseTypographContent,
    fontWeight: Typography.textWeightSemiBold,
    fontSize: Typography.textSizeXs,
  },
}

const colorTheme = { 
  darkLetter: {
    color: Color.colorTextTitle,
  },
  lightLetter: {
    color: Color.colorTextInverseTitle,
  },
  paragraph: {
    color: Color.colorTextParagraph,
  }
}

export const CustomText = (props) => {
  
  const typography = typographyFonts[props.type] || typographyFonts.contentNormal
  const theme = colorTheme[props.colorTheme] || colorTheme.lightLetter

  return (
    <Text style={[typography, theme]}>{props.title}</Text>
  );
};