/* @flow */

import React, { Component } from 'react';
import { FlashList } from '@shopify/flash-list';
import {
  StyleSheet,
  View,
  Text,
  Image,
  ScrollView,
  KeyboardAvoidingView,
} from 'react-native';
import Images from '@assets/Images';
import { Button, Search, SubHeader, EmptyView, Form } from '~/components';
import { TextField } from 'react-native-material-textfield';
import DateTimePicker from 'react-native-modal-datetime-picker';
import GridItem from '../../components/GridItem';
import moment from 'moment';
import { color, style } from '~/common/index';
import _ from 'lodash';
import translate from '../../locales';
import { FloatingAction } from 'react-native-floating-action';
import RBSheet from '../../components/BottomSheet/index';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  positionAbsolute: {
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fff',
  },
  input: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: color.grayContainer,
    fontSize: 14,
    borderRadius: 15,
    padding: 2,
    minHeight: 20,
    minWidth: 100,
    margin: 4,
  },
  appIcon: {
    width: 20,
    height: 20,
    tintColor: color.grayMid,
  },
});

class Grid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDateTimePickerVisible: false,
      fields: JSON.parse(this.props.setup).extra_fields,
      isFormValid: false,
    };
    this.dateFormatted = '';
  }
  getHeader(group) {
    if (group.title) {
      return <SubHeader>{group.title}</SubHeader>;
    }

    return null;
  }

  getFooter() {
    return (
      <View style={{ height: 135 }}>
        <TextField
          outline
          containerStyle={{ margin: 16 }}
          value={this.props.content}
          onChangeText={this.props.onChangeMessage}
          label={`${translate('message')}`}
          multiline
        />
      </View>
    );
  }

  renderItemGrid = ({ item }) => {
    const groupId = item.group.toString();
    const items = this.props.items.filter(i => i.group.toString() === groupId);

    return (
      <FlashList
        style={{ backgroundColor: '#FFF' }}
        data={items}
        keyExtractor={item => (item.id.toString() + groupId).toString()}
        ListHeaderComponent={this.getHeader(item)}
        renderItem={this.renderItem}
        estimatedItemSize={120}
      />
    );
  };
  onPressExpiration = () => {
    this.setState({ isDateTimePickerVisible: true });
  };

  showSchedulingDate = (extra_fields, value, label) => {
    if (!extra_fields) {
      return value ? moment(value).format('L') : label;
    }
    return moment.unix(extra_fields[0].value).format('DD-MM-YYYY');
  };

  renderItem = ({ item }) => {
    const setup = JSON.parse(this.props.setup);
    let params = setup;
    if (setup.type === 'grid_group') {
      const { groups } = setup;
      params = _.find(
        groups,
        g => g.group.toString() === item.group.toString(),
      );
    }

    return (
      <GridItem
        openGridForm={this.props.openGridForm}
        setup={params}
        item={item}
        onFinishEditingEvent={this.props.onFinishEditingEvent}
        onRemoveExtraItem={this.props.onRemoveExtraItem}
      />
    );
  };

  hideDateTimePicker = () => {
    this.setState({ isDateTimePickerVisible: false });
  };

  dateTimePickerOnPress = index => {
    const { extra_fields } = this.props;
    const { fields } = this.state;
    this.onPressExpiration();
    if (fields[index].value === undefined) {
      const selectedDate = _.get(extra_fields, '0.value', '');
      const date = selectedDate
        ? new Date(moment.unix(selectedDate))
        : new Date();
      const newValue = moment(date).format();
      fields[index].value = newValue;
      this.setState({ fields });
    }
  };

  dateTimePickerOnConfirm = (date, index, key) => {
    const { fields } = this.state;
    const newValue = moment(date).format();
    fields[index].value = newValue;
    this.setState({ fields, isDateTimePickerVisible: false }, () => {
      this.dateFormatted = `${moment(newValue).unix()}`;
      this.props.onConfirmDateEvent(this.dateFormatted, key);
    });
  };

  dateTimePickerCancel = index => {
    const { fields } = this.state;
    this.hideDateTimePicker();
    if (!this.dateFormatted) {
      fields[index].value = undefined;
      this.setState({ fields });
    }
  };

  getField = (field, index) => {
    const { type, label, value, key } = field;
    const { extra_fields } = this.props;
    const { isDateTimePickerVisible } = this.state;
    switch (type) {
      case 'date':
        return (
          <View style={styles.input}>
            <Image
              source={Images.iconCalendar}
              style={styles.appIcon}
              resizeMode="contain"
            />
            <Text
              style={styles.input}
              onPress={() => this.dateTimePickerOnPress(index)}>
              {this.showSchedulingDate(extra_fields, value, label)}
            </Text>
            <DateTimePicker
              isVisible={isDateTimePickerVisible}
              minimumDate={new Date()}
              date={value ? new Date(value) : new Date()}
              onConfirm={date => this.dateTimePickerOnConfirm(date, index, key)}
              onCancel={() => this.dateTimePickerCancel(index)}
            />
          </View>
        );
      default:
        return null;
    }
  };

  render() {
    const { items, savingAnswer, onPressExtraFinishButton } = this.props;
    const setup = JSON.parse(this.props.setup);
    const groups = setup.groups || [{ group: '0' }];
    const { fields } = this.state;
    const newItemFields = _.get(setup, 'new_item.fields', []);
    const newItemTitle = _.get(setup, 'new_item.title', 'Adicionar Item');
    const extraFinishButton = _.get(setup, 'extra_finish_button', {});

    return items && items.length > 0 ? (
      <View style={style.container}>
        <ScrollView
          keyboardShouldPersistTaps="always"
          style={{ backgroundColor: '#FFF' }}>
          <KeyboardAvoidingView behavior="height" keyboardVerticalOffset={10}>
            <View style={{ flex: 1 }}>{this.props.gpsComponent || null}</View>
            <View style={{ backgroundColor: '#FFF' }}>
              {this.props.showSearch ? (
                <Search onChangeText={this.props.onChangeText} />
              ) : null}
              {fields
                ? fields.map((field, index) => this.getField(field, index))
                : null}
              <FlashList
                data={groups}
                keyExtractor={item => item.group.toString()}
                renderItem={this.renderItemGrid}
                extraData={this.props}
                ListFooterComponent={this.getFooter()}
                estimatedItemSize={5000}
              />
            </View>
          </KeyboardAvoidingView>
        </ScrollView>
        <View style={styles.positionAbsolute}>
          <Button
            containerStyle={{
              marginHorizontal: 16,
              marginTop: 5,
              marginBottom: 5,
            }}
            title={
              savingAnswer ? `${translate('saving')}` : `${translate('save')}`
            }
            disabled={savingAnswer}
            onPress={this.props.onSavePressed}
          />
          {!!extraFinishButton.show && (
            <Button
              containerStyle={{
                marginHorizontal: 16,
                marginTop: 5,
                marginBottom: 5,
              }}
              title={extraFinishButton.title}
              disabled={savingAnswer}
              onPress={() => onPressExtraFinishButton()}
            />
          )}
        </View>
        <RBSheet
          ref={ref => {
            this.formRef = ref;
          }}
          height={300}>
          <ScrollView>
            {newItemFields && newItemFields.length > 0 ? (
              <Form
                data={newItemFields}
                isFormValid={isFormValid => {
                  if (isFormValid !== this.state.isFormValid) {
                    this.setState({ isFormValid });
                  }
                }}
                getFormData={form => {
                  this.props.newItemForm(form);
                }}
              />
            ) : null}
          </ScrollView>
          <Button
            disabled={!this.state.isFormValid}
            containerStyle={{
              marginHorizontal: 16,
              marginTop: 5,
              marginBottom: 5,
            }}
            title={
              savingAnswer ? `${translate('saving')}` : `${translate('save')}`
            }
            onPress={() => {
              if (this.state.isFormValid) {
                this.props.onAddItem();
                this.formRef.close();
              }
            }}
          />
        </RBSheet>
        {Boolean(newItemFields && newItemFields.length > 0) && (
          <View
            style={{
              width: 100,
              height: 100,
              position: 'absolute',
              bottom: 70,
              right: 0,
            }}>
            <FloatingAction
              overrideWithAction
              actions={[
                {
                  text: newItemTitle,
                  icon: (
                    <Image
                      source={Images.iconPlus}
                      style={{
                        tintColor: '#FFF',
                        width: 40,
                        height: 40,
                      }}
                    />
                  ),
                  name: 'bt_new_task',
                  position: 'center',
                },
              ]}
              color="#074885"
              onPressItem={() => this.formRef.open()}
            />
          </View>
        )}
      </View>
    ) : (
      <EmptyView
        icon={Images.iconBulletedList}
        message={`${translate('noItems')}`}
      />
    );
  }
}

Grid.propTypes = {
  setup: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  content: PropTypes.string.isRequired,
  showSearch: PropTypes.bool.isRequired,
  extra_fields: PropTypes.array.isRequired,
  onChangeText: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onFinishEditingEvent: PropTypes.func.isRequired,
  onChangeMessage: PropTypes.func.isRequired,
  onConfirmDateEvent: PropTypes.func.isRequired,
  onPressExtraFinishButton: PropTypes.func.isRequired,
  onRemoveExtraItem: PropTypes.func.isRequired,
  openGridForm: PropTypes.func.isRequired,
  newItemForm: PropTypes.func.isRequired,
  onAddItem: PropTypes.func.isRequired,
  savingAnswer: PropTypes.bool.isRequired,
  gpsComponent: PropTypes.any,
};

export default Grid;
