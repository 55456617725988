import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ProductsModule from '~/modules/ProductsModule';

export const SCALE_FETCH_REQUEST = 'SCALE_FETCH_REQUEST';
export const SCALE_FETCH_SUCCESS = 'SCALE_FETCH_SUCCESS';
export const SCALE_FETCH_FAILURE = 'SCALE_FETCH_FAILURE';

const getScale = payload =>
  new Promise((resolve, reject) => {
    ProductsModule.getScale(payload, resolve, reject);
  });

export const scale = createAsyncThunk('scale/fetch', async payload => {
  try {
    const response = await getScale(payload);
    return JSON.parse(response);
  } catch (error) {
    console.log('error', error);
    return error;
  }
});

const initialState = {
  type: '',
  loading: false,
  erro: false,
  payload: {},
};

const scaleSlice = createSlice({
  name: 'scale',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(scale.pending, state => {
        state.loading = true;
        state.type = SCALE_FETCH_REQUEST;
      })
      .addCase(scale.fulfilled, (state, action) => {
        state.loading = false;
        state.payload = action.payload;
        state.type = SCALE_FETCH_SUCCESS;
      })
      .addCase(scale.rejected, state => {
        state.loading = false;
        state.erro = true;
        state.type = SCALE_FETCH_FAILURE;
      });
  },
});

const { reducer } = scaleSlice;

export default reducer;
