import React from 'react';
import MultiTabsView from '~/components/MultiTabsView/MultiTabsView';

type Props = {
  pk_produto: Array,
  navigator: any,
  configLib: Object,
};

class CatalogProdDetail extends React.Component<Props, void> {
  multiTabsView = () => {
    const { configLib, navigator } = this.props;

    const params = {
      pk_produto: this.props.pk_produto,
    };
    return (
      <MultiTabsView
        configLib={configLib}
        groupId="PDET"
        params={params}
        navigator={navigator}
        loading={false}
        renderHeaderComponent={() => {}}
      />
    );
  };

  render() {
    return <>{this.multiTabsView()}</>;
  }
}

export default CatalogProdDetail;
