/* @flow */

import _ from 'lodash';
import Masked from 'vanilla-masker';
import { store } from '../store/index';
import translate from '~/locales';

const DEFAULT_CURRENCY_FORMATTED = {
  unit: translate('currencySymbol'),
  separator: translate('separatorCurrency'),
  delimiter: translate('delimiterCurrency'),
  precision: 2,
};

const DECIMAL_PRECISION_TYPE = {
  price: 'preco',
  calculated: 'calculo',
  discount: 'desconto',
  quantity: 'quantidade',
  exhibition: 'exibicao',
};

const getDecimalPrecisionConfig = configLib =>
  _.get(configLib, 'payload.casas_decimais', {});

const getDecimalPrecision = (type = 'exhibition') => {
  const configLib = store.getState().configLibFetch;
  const { precision: defaultPrecision } = DEFAULT_CURRENCY_FORMATTED;
  const decimalPrecisionConfig = getDecimalPrecisionConfig(configLib);

  const decimalPrecision = _.get(
    decimalPrecisionConfig,
    DECIMAL_PRECISION_TYPE[type],
    defaultPrecision,
  );

  return parseInt(decimalPrecision, 10);
};

const getPrecisionFromType = (type = 'exhibition') => {
  const moneyFormatter = getCurrencyFormatter(type, {
    noSymbolCurrency: true,
  });
  const { precision } = moneyFormatter;
  return precision;
};

const getCurrencyFormatter = (
  decimalPrecisionType = 'exhibition',
  options = {},
) => {
  const { noSymbolCurrency = false } = options;
  const precision = getDecimalPrecision(decimalPrecisionType);
  const unit = noSymbolCurrency ? '' : DEFAULT_CURRENCY_FORMATTED.unit;

  return {
    ...DEFAULT_CURRENCY_FORMATTED,
    precision,
    unit,
  };
};

function parseStrToFloat(value, decimalPrecision) {
  const num = `${value}`;
  let dotPos = num.indexOf('.');
  let commaPos = num.indexOf(',');
  let sep = false;

  if (dotPos < 0) {
    dotPos = 0;
  }
  if (commaPos < 0) {
    commaPos = 0;
  }

  if (dotPos > commaPos && dotPos) {
    sep = dotPos;
  } else if (commaPos > dotPos && commaPos) {
    sep = commaPos;
  }

  const precision =
    decimalPrecision >= 0
      ? decimalPrecision
      : DEFAULT_CURRENCY_FORMATTED.precision;

  if (sep === false) {
    const valueParsedFloat = parseFloat(num.replace(/[^\d]/g, ''));
    return valueParsedFloat.toFixed(precision);
  }

  const valueParsedFloat = parseFloat(
    `${num.substr(0, sep).replace(/[^\d]/g, '')}.${num
      .substr(sep + 1, num.length)
      .replace(/[^0-9]/, '')}`,
  );
  return valueParsedFloat.toFixed(precision);
}

function parseFloatToCurrency(value, options = {}) {
  const {
    type = '',
    positiveOrNegative = false,
    moneyFormatter = false,
  } = options;

  const currencyFormatter = moneyFormatter || getCurrencyFormatter(type);
  const { precision } = currencyFormatter;
  const valueParsedFloat = parseStrToFloat(value, precision);

  if (positiveOrNegative) {
    const valueFormatted = valueParsedFloat >= 0.0 ? '' : '-';
    return valueFormatted + Masked.toMoney(valueParsedFloat, currencyFormatter);
  }

  return Masked.toMoney(valueParsedFloat, currencyFormatter);
}

export {
  getDecimalPrecision,
  getCurrencyFormatter,
  parseFloatToCurrency,
  parseStrToFloat,
  getDecimalPrecisionConfig,
  getPrecisionFromType,
};
