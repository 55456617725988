import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, Platform } from 'react-native';
import { color } from '~/common';
import { fonts } from "../../../utils/Fonts";

const styles = StyleSheet.create({
  section: {
    flex: 1,
    backgroundColor: Platform.OS === 'ios' ? color.grayContainer : '#fff',
    color: Platform.OS === 'ios' ? color.sectionIOS : color.accentColor,
    paddingHorizontal: 16,
    paddingTop: Platform.OS === 'ios' ? 10 : 16,
    paddingBottom: Platform.OS === 'ios' ? 10 : 24,
    fontFamily: fonts.QuicksandBold,
    fontSize: 14,
  },
});

function Section({ children }) {
  return (
    <Text
      numberOfLines={1}
      ellipsize="tail"
      style={[
        styles.section,
        (children === undefined || children === '') &&
        Platform.OS === 'android' ?
          { backgroundColor: 'transparent' }
          :
          {},
      ]}
    >
      {children}
    </Text>
  );
}

Section.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node]),
};

export default Section;
