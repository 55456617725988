class Resource {
  constructor() {
    this.baseUrl = 'http://api-polimessage-production.us-east-1.elasticbeanstalk.com/api';
  }

  async getDefaultOptions(token) {
    // let token = await Auth.getToken();

    return {
      headers: {
        global: false,
      },
    };
  }

  getURL(path) {
    console.tron.log('BASE URL ENV', global.environment, this.baseUrl);

    const url = this.baseUrl;
    const completeUrl = `${url}/${path}`;
    return completeUrl;
  }

  async fetch(method, path, data, token) {
    const defaultOptions = await this.getDefaultOptions(token);
    const objRequest = {
      ...defaultOptions,
      method,
      headers: {
        ...defaultOptions.headers,
        'Content-Type': 'application/json',
      },
    };

    if (method !== 'GET' && method !== 'HEAD') {
      objRequest.body = data;
    }

    return fetch(this.getURL(path), objRequest);
  }

  get(path, data) {
    data = JSON.stringify(data || {});
    return this.fetch('GET', path, data);
  }

  patch(path, data) {
    data = JSON.stringify(data);
    return this.fetch('PATCH', path, data);
  }

  postForm(path, data) {
    const formData = new FormData();

    for (const k in data) {
      formData.append(k, data[k]);
    }

    const objRequest = {
      method: 'POST',
      body: formData,
      headers: {
      },
    };
    return fetch(this.getURL(path), objRequest);
  }

  registerDevice(path, data, token) {
    data = JSON.stringify(data);

    const objRequest = {
      method: 'POST',
      body: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${token}`,
      },
    };

    return fetch(this.getURL(path), objRequest);
  }

  loginPost(path, data) {
    data = JSON.stringify(data);

    const objRequest = {
      method: 'POST',
      body: data,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return fetch(this.getURL(path), objRequest);
  }

  post(path, data) {
    data = JSON.stringify(data);
    return this.fetch('POST', path, data);
  }

  upload(path, data) {
    return this.fetch({
      url: this.getURL(path),
      method: 'POST',
      cache: false,
      dataType: 'json',
      processData: false,
      contentType: false,

      data,
    });
  }

  put(path, data) {
    data = JSON.stringify(data);

    return this.fetch('PUT', path, data);
  }

  delete(path, data) {
    data = JSON.stringify(data);

    return this.fetch('DELETE', path, data);
  }
}

export default Resource;
