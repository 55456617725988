import get from 'lodash/get';
import find from 'lodash/find';

export const adjustHtml = html =>
  html
    ? html
        .replace(/<(table|tbody|tr|td)[^>]*>/g, '<div>')
        .replace(/<(TABLE|TBODY|TR|TD)[^>]*>/g, '<div>')
        .replace(/<\/(table|tbody|tr|td)[^>]*>/g, '</div>')
        .replace(/<\/(TABLE|TBODY|TR|TD)[^>]*>/g, '</div>')
    : '';

export const listFieldsVisibility = (configLib, context = '') => {
  if (configLib) {
    const rawValue = get(
      configLib,
      `listagem_campos_visibilidade.${context}`,
      {},
    );

    // Remove null values in rawValue
    Object.keys(rawValue).forEach(key => {
      const value = rawValue[key];
      if (value === null) {
        delete rawValue[key];
      }
    });

    return rawValue;
  }
  return {};
};

export const isShowHTML = (configLib, context = '') => {
  const ctxVisibility = listFieldsVisibility(configLib, context);
  const { show_html: showHTML = false } = ctxVisibility;

  return showHTML;
};

export const isShowMultiTabs = (configLib, context = '', displayName) => {
  const ctxVisibility = listFieldsVisibility(configLib, context);
  const { show_multi_tabs: showMultiTabs = false } = ctxVisibility;
  if (!showMultiTabs) {
    return false;
  }

  return !!find(showMultiTabs, tab => tab === displayName);
};
