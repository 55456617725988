import React from 'react';
import { View, Text } from 'react-native';
import Checkbox from 'react-native-check-box';

import styles from './styles';
import { color } from '../../common';
import PropTypes from 'prop-types';

function RadioGroupData({ valuesSelected, itens, onSelectItens }) {
  const onChangeValues = (value) => {
    const values = [...valuesSelected];
    const indexFound = values.indexOf(value);
    if (indexFound === -1) {
      values.push(value);
    } else {
      values.splice(indexFound, 1);
    }

    onSelectItens(values);
  };

  return (
    <View style={[itens.length <= 3 && styles.container, { padding: 2 }]}>
      {itens.map(item => (
        <View style={styles.groupItemCheckBox} key={item.value}>
          <Checkbox
            isChecked={valuesSelected.includes(item.value)}
            onClick={() => onChangeValues(item.value)}
            checkBoxColor={color.blueIOS}
          />
          <Text>{item.label}</Text>
        </View>
        ))
        }
    </View>
  );
}

RadioGroupData.propTypes = {
    valuesSelected: PropTypes.any,
    itens: PropTypes.array,
    onSelectItens: PropTypes.func,
};

export default RadioGroupData;
