// import AlertBuyingFor from './AlertBuyingFor';
// import Banners from './Banners';
// import Box from './Box';
import Button from './Button';
import Carousel from './Carousel';
import Categories from './Categories';
// import Category from './Category';
// import Input from './Input';
import Product from './Product';
// import ProductBonus from './ProductBonus';
import Quantity from './Quantity';
// import SearchBar from './SearchBar';
import Section from './Section';
// import SimpleInfo from './SimpleInfo';

import Price, { CalculatedValue } from './NumberValue';

export {
  // AlertBuyingFor,
  // Banners,
  Button,
  // Box,
  Carousel,
  Categories,
  // Category,
  // Input,
  Product,
  // ProductBonus,
  Quantity,
  // SearchBar,
  Section,
  // SimpleInfo,
  Price,
  CalculatedValue,
};
