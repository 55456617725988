import React from 'react';
import {
  View,
  ScrollView,
} from 'react-native';
import Form from '~/components/Form/Form';
import Button from '../../components/Button';
import styles from './styles';
import translate from '../../locales';
import ModalOverlay from '~/components/Web/ModalOverLay/index.web';

type Props = {
  form: [],
  visible: boolean,
  formIsValid: boolean,
  gpsConfig: Object,
  location: Object,
  onLocationChange: () => void,
  formatQSQLDataSource: () => Array,
  onSaveButtonPress: () => void,
  getFormData: () => void,
  isFormValid: () => void,
};

function RegisterAgain({
  form,
  visible,
  formIsValid,
  getFormData,
  isFormValid,
  onSaveButtonPress,
  formatQSQLDataSource,
  gpsConfig,
  location,
  onLocationChange,
}: Props) {
  const titleSaveButton = visible
    ? `${translate('save')}`
    : `${translate('saving')}`;
  const {
    requiredCollectGpsPoint,
    showPermissionLocation,
    notifyOnSaving,
  } = gpsConfig;

  return (
    <View style={styles.container}>
      <ModalOverlay visible={!visible} closeOnTouchOutside />
      <ScrollView>
        {form && form.length > 0 ? (
          <View style={{ marginBottom: 40 }}>
            <Form
              style={{ backgroundColor: 'white' }}
              data={form}
              isFormValid={isFormValid}
              getFormData={getFormData}
              formatQSQLDataSource={formatQSQLDataSource}
            />
          </View>
        ) : null}
      </ScrollView>
      <View style={styles.positionAbsolute}>
        <Button
          containerStyle={styles.buttonContainer}
          title={titleSaveButton}
          disabled={!visible || !formIsValid}
          onPress={onSaveButtonPress}
        />
      </View>
    </View>
  );
}

export default RegisterAgain;