import React, { Component } from 'react';
import { Navigation } from 'react-native-navigation';
import Snackbar from 'react-native-snackbar';
import translate from '~/locales';
import ProductsModule from '~/modules/ProductsModule';
import getQueryResult from '~/services/resources/libFastSeller/genericQuery';
import CatalogProdDetail from './CatalogProdDetail';
import NavigationHelper from '~/screens/NavigationHelper';
import { SCREEN_SALES_CONDITION_CATALOG } from '~/screens/screens';
import { createNotifyError } from '~/components/Web/ToastNotify';
import PropTypes from 'prop-types';

class CatalogProdDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tables: [],
      scales: [],
      deadlines: [],
      selectedTable: null,
      selectedScale: null,
      selectedDeadline: null,
      selectedOrgsale: null,
    };
    this.fabRef = null;
  }

  onChangeParams = (params) => {
    this.setState({ ...params });
    this.onClose();
  };

  onClose = () => {
    this.fabRef?.animateButton();
  };

  handleChangeTable = async (pkey, state) => {
    const { selectedTable, selectedScale, selectedDeadline } = state;
    if (pkey === selectedTable) {
      return null; // Retorna null se não houver mudanças
    }

    let scales = await getQueryResult(
      'FTS3;PROD;BESC',
      JSON.stringify({
        tabelapreco: pkey,
      }),
      true,
    );
    scales = JSON.parse(scales);

    let newScale = selectedScale;
    if (!scales.find((scale) => scale.faixa === newScale)) {
      newScale = scales[0]?.faixa;
    }

    let deadlines = await getQueryResult(
      'FTS3;PROD;BPRZ',
      JSON.stringify({
        faixa: newScale,
        tabelapreco: pkey,
      }),
      true,
    );
    deadlines = JSON.parse(deadlines);

    let newDeadline = selectedDeadline;
    if (!deadlines.find((scale) => scale.pkey === selectedDeadline)) {
      newDeadline = deadlines[0]?.pkey;
    }

    return {
      scales,
      deadlines,
      selectedScale: newScale,
      selectedTable: pkey,
      selectedDeadline: newDeadline,
    };
  };

  handleChangeScale = async (faixa, state) => {
    const { selectedTable, selectedScale, selectedDeadline } = state;
    if (faixa === selectedScale) {
      return null; // Retorna null se não houver mudanças
    }

    let deadlines = await getQueryResult(
      'FTS3;PROD;BPRZ',
      JSON.stringify({
        faixa: Number(faixa),
        tabelapreco: Number(selectedTable),
      }),
      true,
    );
    deadlines = JSON.parse(deadlines);

    let newDeadline = selectedDeadline;
    if (!deadlines.find((scale) => scale.pkey === selectedDeadline)) {
      newDeadline = deadlines[0]?.pkey;
    }

    return {
      deadlines,
      selectedDeadline: newDeadline,
    };
  };

  loadConditions = async () => {
    const { pk_produto } = this.props.route.params;
    try {
      const response = await ProductsModule?.setActiveProduct(pk_produto);
      const data = JSON.parse(response);
      const orgvenda = data?.orgvenda;

      let tables = await getQueryResult('FTS3;PROD;TABL', JSON.stringify({}), false);
      tables = await JSON.parse(tables);

      const firstTable = tables[0];

      let scales = await getQueryResult(
        'FTS3;PROD;BESC',
        JSON.stringify({
          tabelapreco: Number(firstTable.pkey),
        }),
        true,
      );
      scales = await JSON.parse(scales);

      const firstScale = scales[0];

      let deadlines = await getQueryResult(
        'FTS3;PROD;BPRZ',
        JSON.stringify({
          faixa: Number(firstScale.faixa),
          tabelapreco: Number(firstTable.pkey),
        }),
        true,
      );
      deadlines = await JSON.parse(deadlines);

      const firstDeadline = deadlines[0];

      return {
        selectedDeadline: firstDeadline.pkey,
        selectedScale: firstScale.faixa,
        selectedTable: firstTable.pkey,
        selectedOrgsale: orgvenda,
        tables,
        deadlines,
        scales,
      };
    } catch (e) {
      console.log(e);
      createNotifyError(translate('errorWhenSetProductActive'));
    }
  };

  componentDidMount() {
    this.loadConditions().then((params) => {
      // Verifica se o estado realmente mudou antes de atualizar
      if (JSON.stringify(this.state) !== JSON.stringify(params)) {
        this.setState({
          ...params,
        });
      }
    });
  }

  openConditionSalesSheet = () => {
    this.props.navigation.navigate('SalesConditionCatalogSheet', {
      initialState: this.state,
      handleChangeScale: this.handleChangeScale,
      handleChangeTable: this.handleChangeTable,
      onChangeParams: this.onChangeParams,
      onClose: this.onClose,
    });
  };

  render() {
    const { configLib, pk_produto } = this.props.route?.params;
    const { selectedDeadline, selectedOrgsale, selectedScale, selectedTable } = this.state;

    return (
      <CatalogProdDetail
        configLib={configLib}
        navigation={this.props.navigation}
        pk_produto={pk_produto}
        selectedDeadline={selectedDeadline}
        selectedOrgsale={selectedOrgsale}
        selectedScale={selectedScale}
        selectedTable={selectedTable}
        onPressChangeCondition={this.openConditionSalesSheet}
        fabRef={(ref) => (this.fabRef = ref)}
      />
    );
  }
}

CatalogProdDetailContainer.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
};

export default CatalogProdDetailContainer;