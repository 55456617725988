/* @flow */

import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import { color } from '~/common/index';
import Images from '@assets/Images';
import { fonts } from '../../../../utils/Fonts';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
    outsideContainer: {
        overflow: 'hidden',
        flex: 1,
        borderRadius: 15,
        padding: 3,
    },
    container: {
        borderRadius: 15,
        minHeight: 24,
        flexDirection: 'row',
        backgroundColor: color.grayContainer,
        paddingVertical: 4,
        paddingHorizontal: 8,
    },
    container_selected: {
        backgroundColor: color.greenLightest,
    },
    scale: {
        fontFamily: fonts.QuicksandBold,
        fontSize: 14,
        color: color.grayMid,
        marginRight: 10,
        flex: 1,
    },
    scale_selected: {
        color: color.successColor,
    },
    iconContainer: {
        backgroundColor: color.blueBadge,
        width: 18,
        height: 18,
        borderRadius: 7,
    },
    iconContainer_selected: {
        backgroundColor: 'transparent',
        marginRight: 6,
    },
    icon: {
        width: 18,
        height: 18,
        tintColor: color.successColor,
    },
});

function ScaleOption({
    scale, index, selected, onSelect,
}) {
    const isSelected = selected === index;

    return (
        <View style={styles.outsideContainer}>
            <Touchable
                foreground={Touchable.SelectableBackground()}
                onPress={onSelect}
            >
                <View style={[
                    styles.container,
                    isSelected ? styles.container_selected : {},
                ]}
                >
                    <Text style={[
                        styles.scale,
                        isSelected ? styles.scale_selected : {},
                    ]}
                    >
                        {scale.value ? (`A partir de ${scale.value}`) : 'Escala padrão'}
                    </Text>
                    {isSelected ?
                        <View style={[
                            styles.iconContainer,
                            isSelected ? styles.iconContainer_selected : {},
                        ]}
                        >
                            {isSelected ?
                                <Image
                                    style={[styles.icon]}
                                    source={Images.iconCheck}
                                />
                                :
                                null
                            }
                        </View>
                        :
                        null
                    }
                </View>
            </Touchable>
        </View>
    );
}

ScaleOption.propTypes = {
    scale: PropTypes.shape({
        value: PropTypes.number,
    }).isRequired,
    index: PropTypes.number.isRequired,
    selected: PropTypes.number.isRequired,
    onSelect: PropTypes.func.isRequired,
};

ScaleOption.defaultProps = {
    scale: 'A partir de 10 unidades o preço é R$ 10,00',
};

export default ScaleOption;
