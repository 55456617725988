import { StyleSheet, Dimensions } from 'react-native';

import { color } from '~/common';
import { fonts } from '../../utils/Fonts';

const styles = StyleSheet.create({
  wrapper: {
    padding: 10,
    paddingBottom: 0,
  },
  modalOverlay: {
    backgroundColor: 'rgba(0,0,0, 0.5)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  modalContainer: {
    maxHeight: '80%',
    minHeight: 220,
    height: 'auto',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: 5,
    backgroundColor: 'white',
  },

  buttonsView: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingHorizontal: 6,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 20,
    color: color.neutral900,
    lineHeight: 28,
  },
  messages: {
    padding: 10,
  },
  bottomButtons: {
    marginLeft: 6,
    minHeight: 50,
    elevation: 0,
    borderRadius: 10,
    textTransform: 'uppercase',
    maxWidth: Dimensions.get('window').width * 0.7,
  },

  cardContainer: {
    backgroundColor: color.primaryColor,
  },
  cardContainerContent: {
    marginLeft: 16,
    marginRight: 16,
    marginTop: 5,
    marginBottom: 16,
  },
  oops: {
    color: '#fff',
    fontSize: 14,
    fontFamily: fonts.QuicksandBold,
  },
  alertMessage: {
    color: '#fff',
    fontSize: 24,
    fontFamily: fonts.Quicksand,
  },
  exit: {
    marginTop: 10,
    width: 20,
    height: 20,
    tintColor: color.navBarSubtitleColor,
  },
  header: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'space-between',
    marginBottom: 30,
  },
  actionButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

export default styles;
