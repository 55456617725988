import RNExitApp from 'react-native-exit-app';

import AccountModule from '../../../modules/AccountsModule';
import Sync from '../../../services/resources/Sync';
import CacheDataController from '../../../utils/CacheDataController';

class ValidadeLoginPolistore {
  constructor(cachePolistore) {
    this.cachePolistore = cachePolistore;
  }

  validadeLogin() {
    return new Promise(async (resolve, reject) => {
      const checkBase = await this.checkBase();
      if (!checkBase) {
        Sync.setDownloadComplete('false');
        reject();
      } else {
        const dataLoginPromise = this.cachePolistore.getLoginData();
        const dataSynconPromise = this.cachePolistore.getParamSyncon();
        const dataSignInPromise = this.cachePolistore.getParamsSignIn();
        const deviceInfo = await CacheDataController.getData(
          '@Session:deviceInfo',
        );
        const uuid = deviceInfo && deviceInfo.uuid ? deviceInfo.uuid : '';

        Promise.all([dataLoginPromise, dataSynconPromise, dataSignInPromise])
          .then(async values => {
            const dataLogin = values[0];
            let dataSyncon = values[1];
            const dataSignIn = values[2];

            dataSyncon = {};

            try {
              await this.iniciarLib(dataLogin, uuid);
              if (dataLogin && dataSyncon && dataSignIn) {
                Promise.all([
                  this.setDataLogin(dataLogin),
                  this.setDataSyncon(dataSyncon),
                  this.setDataSignIn(dataSignIn),
                ])
                  .then(() => {
                    resolve();
                  })
                  .catch(() => {
                    Sync.setDownloadComplete('false');
                    reject();
                  });
              } else {
                Sync.setDownloadComplete('false');
                reject();
              }
            } catch (err) {
              RNExitApp.exitApp();
            }
          })
          .catch(() => {
            reject();
          });
      }
    });
  }

  async checkBase() {
    return true;
  }

  iniciarLib(data, uuid) {
    return new Promise((resolve, reject) => {
      const d = JSON.parse(data);
      const { empresa, loginUsuario } = d;
      const [username] = loginUsuario.split('@');

      AccountModule.iniciarLib(empresa, username, uuid, resolve, reject);
    });
  }

  setDataLogin(data) {
    return new Promise((resolve, reject) => {
      const d = JSON.parse(data);
      AccountModule.validadeLogin(
        d.empresa,
        d.loginUsuario,
        d.senha,
        resolve,
        reject,
      );
    });
  }

  setDataSyncon(data) {
    return new Promise((resolve, reject) => {
      AccountModule.whenParamSignInSuccess(data, resolve, reject);
    });
  }

  setDataSignIn(data) {
    return new Promise((resolve, reject) => {
      AccountModule.whenPerformSignInSuccess(data, resolve, reject);
    });
  }
}

export default ValidadeLoginPolistore;
