import { createAsyncThunk } from '@reduxjs/toolkit';
import { copyOrder, getCart, getCartCoringa } from './utilsLib';

export const ORDERS_COPY_FETCH_REQUEST = 'ORDERS_COPY_FETCH_REQUEST';
export const ORDERS_COPY_FETCH_SUCCESS = 'ORDERS_COPY_FETCH_SUCCESS';
export const ORDERS_COPY_FETCH_FAILURE = 'ORDERS_COPY_FETCH_FAILURE';

export const copyOrderFetch = createAsyncThunk(
  'orderFetch/copyOrderFetch',
  async payload => {
    try {
      const data = await copyOrder(payload);
      const orderCopy = JSON.parse(data);
      let val = null;

      if (orderCopy.pkPcad > 0) {
        val = await getCartCoringa(orderCopy);
      } else {
        val = await getCart(orderCopy);
      }

      const valObj = JSON.parse(val);
      const payloadCopyObj = orderCopy;

      const { mensagens } = payloadCopyObj;
      const order = { ...valObj, mensagens };

      return order;
    } catch (error) {
      throw new Error(error.message);
    }
  },
);
