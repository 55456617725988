import {CALL_GENERIC_PROPERTY, genericFuncWrapper} from "~/modules/WrappedModule";

const FinancingApiModule = (nameModule) => {
  const methodByAlias = {
    listTitles: 'listarTitulos',
    getFilterTitles: 'getFiltroFinanceiros',
  };

  const listTitlesParams = (params) => {
    return genericFuncWrapper('obterListagem')('1414550089', '1414549586', params, true, null);
  };

  const genericProperties = {};
  Object.keys(methodByAlias).forEach((k) => genericProperties[k] = CALL_GENERIC_PROPERTY);

  return {
    getName: () => nameModule,
    listTitlesParams,
    methodByAlias,
    ...genericProperties
  };
};

export default FinancingApiModule;