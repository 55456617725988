import ClientModule from '../../../modules/ClientsModule';
import axios from 'axios';

class PrRegisterAgainLib {
  sendDataForm(pkey, stringJsonDataForm) {
    return new Promise((resolve, reject) => {
      ClientModule.getPreRegisterClient(
        pkey,
        stringJsonDataForm,
        resolve,
        reject,
      );
    });
  }

  saveEvent(pkey, gpsLocation) {
    return new Promise((res, rej) => {
      ClientModule.saveEventBeforeRegister(pkey, gpsLocation, res, rej);
    });
  }

  getParametersPreRegister(pkeyCliente = '0') {
    return new Promise((resolve, reject) => {
      ClientModule.parametersPreRegisterClient(pkeyCliente, resolve, reject);
    });
  }

  consultCNPJ({ url, token, cnpj }) {
    const fullUrl = url.replace(':cnpj', cnpj);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get(fullUrl, config)
        .then(({ data }) => {
          if (data.status !== 'ERROR') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  fillFormField(field, fieldsResp, fieldsConfig) {
    const newField = { ...field };
    Object.entries(fieldsConfig).forEach(({ 0: reqT, 1: confT }) => {
      const value = fieldsResp[reqT];
      if (field.field === confT) {
        newField.value = value;
      }
    });
    return newField;
  }
}

export default new PrRegisterAgainLib();
