/* @flow */

export const MAKE_ORDER_MODULE = 'MAKE_ORDER_MODULE';

export const MODULE_SALE = 'MODULE_SALE';
export const MODULE_ACTIVITIES = 'MODULE_ACTIVITIES';

export function setModuleCurrent(module) {
  return dispatch => {
    dispatch({
      type: MAKE_ORDER_MODULE,
      payload: module,
    });
  };
}
