import AsyncStorage from '@react-native-async-storage/async-storage';
import CacheAccount from '~/controllers/Accounts/Cache/CacheAccount';

class CredentialsCache {
  async addAccountCredentials(credential) {
    const login = credential.login;
    if (!login) {
      return;
    }

    const accounts = await this.getAccountCredentials();
    const logins = accounts.map(account => account.login);

    if (!logins.includes(login)) {
      accounts.push(credential);
      await AsyncStorage.setItem('@Accounts', JSON.stringify(accounts));
    }
  }

  async removeAccountCredential(company, username) {
    let accounts = await this.getAccountCredentials();
    accounts = accounts.filter(
      account =>
        account.login !==
        `${username?.toLowerCase()}@${company?.toLowerCase()}.polibr.com.br`,
    );
    await AsyncStorage.setItem('@Accounts', JSON.stringify(accounts));
  }

  async getAccountCredentials() {
    const data = await AsyncStorage.getItem('@Accounts');
    return data ? JSON.parse(data) : [];
  }

  async getAvailableAccounts() {
    const accounts = await this.getAccountCredentials();
    const currentAccount = await new CacheAccount().getDataAccount();

    return accounts
      .map(account => ({
        credentials: {
          password: account.password,
          login: account.login,
        },
        company: account?.user?.company,
        username: account.username,
        name: (account.user?.first_name || account.username)?.toUpperCase(),
        selected: currentAccount?.login === account.login,
      }))
      .sort((a, b) => b.selected - a.selected);
  }
}

export default new CredentialsCache();
