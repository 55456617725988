import { StyleSheet } from 'react-native';

export const colors = {
  black: '#1a1917',
  gray: '#888888',
  background1: '#B721FF',
  background2: '#21D4FD',
};

export default StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: colors.black,
  },
  scrollview: {
    flex: 1,
  },
  slider: {
    overflow: 'visible',
  },
  sliderContentContainer: {
  },
  paginationContainer: {
    paddingVertical: 8,
  },
  dotContainerStyle: {
    marginHorizontal: 2,
  },
  paginationDot: {
    width: 15,
    height: 4,
    borderRadius: 4,
  },
});
