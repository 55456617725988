import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import GeneralModules from '../../../src/modules/GeneralModule';

export const SALE_OPPORTUNITY_REQUEST = 'SALE_OPPORTUNITY_REQUEST';
export const SALE_OPPORTUNITY_SUCCESS = 'SALE_OPPORTUNITY_SUCCESS';
export const SALE_OPPORTUNITY_FAILURE = 'SALE_OPPORTUNITY_FAILURE';
export const SALE_OPPORTUNITY_SELECTED = 'SALE_OPPORTUNITY_SELECTED';

const initialState = {
  type: '',
  loading: false,
  erro: false,
  payload: [],
  selected: false,
};

export const listSalesOpportunity = createAsyncThunk(
  'salesOpportunityFetch/listSalesOpportunity',
  async (_, { getState }) => {
    try {
      const { configLibFetch } = getState();
      const { tam_memoria_paginacao: offset } = configLibFetch.payload;

      const response = await new Promise(async (resolve, reject) => {
        const paginationProps = { page: 0, offset, active: true };

        let active = true;
        let data = '';
        try {
          while (active) {
            const pagination = JSON.stringify(paginationProps);
            const resp = await GeneralModules.listSalesOpportunity(pagination);
            data = `${data}${resp}`;

            if (resp.length > 0) {
              paginationProps.page += 1;
            } else {
              active = false;
            }
          }

          resolve(data);
        } catch (error) {
          reject(error.message);
        }
      });

      const salesOpportunity =
        response.length === 0 ? [] : JSON.parse(response);

      return salesOpportunity;
    } catch (error) {
      return JSON.parse(error);
    }
  },
);

const salesOpportunityFetch = createSlice({
  name: 'salesOpportunityFetch',
  initialState,
  reducers: {
    setSaleOpportunity: (state, action) => {
      state.type = SALE_OPPORTUNITY_SELECTED;
      state.selected = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(listSalesOpportunity.pending, state => {
      state.type = SALE_OPPORTUNITY_REQUEST;
      state.loading = true;
      state.erro = false;
    });
    builder.addCase(listSalesOpportunity.fulfilled, (state, action) => {
      state.type = SALE_OPPORTUNITY_SUCCESS;
      state.loading = false;
      state.erro = false;
      state.payload = action.payload;
    });
    builder.addCase(listSalesOpportunity.rejected, (state, action) => {
      state.type = SALE_OPPORTUNITY_FAILURE;
      state.loading = false;
      state.erro = true;
      state.payload = action.payload;
    });
  },
});

const { actions, reducer } = salesOpportunityFetch;

export const { setSaleOpportunity } = actions;
export default reducer;
