import React from 'react';
import { Colors } from '../config/config';
import { fonts } from '~/utils/Fonts';
import { createStackNavigator } from '@react-navigation/stack';

import ClientsContainer from '../screens/Clients/Clients.Container';
import ClientDetailContainer from '../screens/ClientDetail/ClientDetail.Container';
import CartContainer from '../screens/Cart/Cart.Container';
import ResumeContainer from '../screens/Resume/Resume.Container';
import MessagesContainer from '../screens/Messages/Messages.Container';
import FinishContainer from '../screens/Finish/Finish.Container';
import ProductDetailContainer from '../screens/ProductScreens/ProductDetail/ProductDetail.Container';
import ConditionsSalesChildContainer from '../screens/ConditionsSalesChild/ConditionsSalesChildContainer';
import ProductTabsContainer from '../screens/ProductScreens/ProductTabs/ProductTabsContainer';
import ObservationsContainer from '../screens/Resume/Observations/Observations.Container';
import OrderObservations from '../screens/Resume/Observations/Observations.Container';
import ProductObservationsContainer from '~/screens/Observations/Observations.Container';
import OrderDetailContainer from '../screens/OrderDetail/OrderDetail.Container';
import NewClientsContainer from '../screens/NewClients/NewClients.Container';
import AddClientContainer from '../screens/AddClient/AddClient.Container';
import CombosContainer from '../screens/Combos/Combos.Container';
import ComboDetailContainer from '~/screens/ComboDetail/ComboDetail.Container';
import ShareOrder from '~/screens/Cart/components/ShareOrder.web';
import Categories from '~/screens/Categories/Categories';
import { ActionModal } from '~/components/OperationGroup/ActionModal/OperationGroupActionModal';
import OperationGroupActions from '~/components/OperationGroup/Actions/OperationGroupActions';
import RegisterAgainContainer from '~/screens/RegisterAgain/RegisterAgain.Container';
import WebviewContainer from '../screens/Webview/Webview.Container.web';
import ActivitiesContainer from '~/screens/Activities/Activities.Container';
import ActivitieLocationContainer from "~/screens/Activities/Location/Location.Container";
import ActivitiesGrid from "~/screens/Activities/Grid/Grid.Container";
import GridForm from "~/screens/Activities/Grid/GridForm.Container";
import ActivitiesChoice from "~/screens/Activities/Choice/Choice.Container";
import ActivitiesPhoto from "~/screens/Activities/Photo/Photo.Container";
import ActivitiesFreeText from "~/screens/Activities/FreeText/FreeText.Container";
import ActivitiesStopWatch from "~/screens/Activities/Stopwatch/Stopwatch.Container";
import ActivitiesDynamicForm from "~/screens/Activities/DynamicForm/DynamicForm.Container";

const Stack = createStackNavigator();

function ClientsStack() {
  return (
    <Stack.Navigator
      screenOptions={{
        title: 'Clientes',
        headerStyle: {
          backgroundColor: Colors.primaryColor,
          textAlign: 'center',
        },
        headerTitleAlign: 'left',
        headerTintColor: '#fff',
        headerTitleStyle: {
          fontFamily: `${fonts.QuicksandBold}`,
          fontWeight: 'bold',
        },
      }}>
      <Stack.Screen
        name="Clients"
        options={{ title: 'Clientes' }}
        component={ClientsContainer}
      />
      <Stack.Screen
        name="ClientDetail"
        options={{ title: 'Detalhes do Cliente' }}
        component={ClientDetailContainer}
      />
      <Stack.Screen
        name="Cart"
        options={{ title: 'Produtos' }}
        component={CartContainer}
      />
      <Stack.Screen
        name="Resume"
        options={{ title: 'Resumo do pedido' }}
        component={ResumeContainer}
      />
      <Stack.Screen
        name="Messages"
        options={{ title: 'Mensagens' }}
        component={MessagesContainer}
      />
      <Stack.Screen
        name="Finish"
        options={{ title: 'Pedido concluído' }}
        component={FinishContainer}
      />
      <Stack.Screen
        name="ProductDetail"
        options={{ title: 'Product Detail' }}
        component={ProductDetailContainer}
      />
      <Stack.Screen
        name="ConditionsSalesChild"
        options={{ title: 'Condições de Venda' }}
        component={ConditionsSalesChildContainer}
      />
      <Stack.Screen
        name="ProductTabs"
        options={{ title: 'Mais Produtos', headerTitleAlign: 'left' }}
        component={ProductTabsContainer}
      />
      <Stack.Screen
        name="Observations"
        options={{ title: 'Observações do Pedido' }}
        component={ObservationsContainer}
      />
      <Stack.Screen
        name="OrderDetail"
        options={{ title: 'Detalhes do Pedido' }}
        component={OrderDetailContainer}
      />
      <Stack.Screen
        name="NewClients"
        options={{ title: 'Novos Clientes' }}
        component={NewClientsContainer}
      />
      <Stack.Screen
        name="AddClient"
        options={{ title: 'Adicionar Cliente' }}
        component={AddClientContainer}
      />
      <Stack.Screen
        name="Combos"
        options={{ title: 'Combos' }}
        component={CombosContainer}
      />
      <Stack.Screen
        name="ComboDetail"
        options={{ title: 'Detalhes do Combo' }}
        component={ComboDetailContainer}
      />
      <Stack.Screen
        name="OrderObservations"
        options={{ title: 'Observações do Pedido' }}
        component={OrderObservations}
      />
       <Stack.Screen
        name="ProductObservation"
        options={{ title: 'Observações do produto' }}
        component={ProductObservationsContainer}
      />
      <Stack.Screen
        name="ShareOrder"
        options={{ title: 'Compartilhar Pedido' }}
        component={ShareOrder}
      />
       <Stack.Screen
        name='ActionModal'
        options={{ title: 'Operação' }}
        component={ActionModal}
      />
      <Stack.Screen
        name="OperationGroupActions"
        options={{ title: 'Operações' }}
        component={OperationGroupActions}
      />
      <Stack.Screen
        name="Categories"
        options={{ title: 'Categorias' }}
        component={Categories}
      />
      <Stack.Screen
        name="RegisterAgain"
        options={{ title: 'Registrar Novamente' }}
        component={RegisterAgainContainer}
      />
      <Stack.Screen
        name="webview"
        options={{ title: 'Webview' }}
        component={WebviewContainer}
      />
      <Stack.Screen
        name="Activities"
        options={{ title: 'Atividades' }}
        component={ActivitiesContainer}
      />
      <Stack.Screen
            name="activities.Location"
            options={{ title: 'Localização' }}
            component={ActivitieLocationContainer}
        />
        <Stack.Screen
            name="activities.Grid"
            options={{ title: 'Lista Genérica' }}
            component={ActivitiesGrid}
        />
        <Stack.Screen
            name="activities.GridForm"
            options={{ title: 'Formulário Genérico' }}
            component={GridForm}
        />
        <Stack.Screen
            name="activities.Choice"
            options={{ title: 'Escolha' }}
            component={ActivitiesChoice}
        />
        <Stack.Screen
            name="activities.Photo"
            options={{ title: 'Foto' }}
            component={ActivitiesPhoto}
        />
        <Stack.Screen
            name="activities.FreeText"
            options={{ title: 'Texto Livre' }}
            component={ActivitiesFreeText}
        />
        <Stack.Screen
            name="activities.Stopwatch"
            options={{ title: 'Cronômetro' }}
            component={ActivitiesStopWatch}
        />
        <Stack.Screen
            name="activities.DynamicForm"
            options={{ title: 'Formulário Dinâmico' }}
            component={ActivitiesDynamicForm}
        />
    </Stack.Navigator>
  );
}

export default ClientsStack;
