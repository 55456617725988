/* @flow */

import React, { Component } from 'react';
import { FlashList } from '@shopify/flash-list';
import OrderItem from './OrderItem';

import PropTypes from 'prop-types';

class Items extends Component {
  renderItem = ({ item }) => (
    <OrderItem item={item} config={this.props.config} />
  );

  render() {
    const data = this.props.items;
    /**
     * valores aceitos pela propriedade data: ReadonlyArray<ItemT> | null | undefined.
     * Por estar recebendo uma Promisse, apenas era renderizado o initialNumToRender (default=10).
     * */
    return (
      <FlashList
        renderItem={this.renderItem}
        keyExtractor={item => item.pkey}
        data={Array.isArray(data) ? data : null}
        estimatedItemSize={80}
      />
    );
  }
}

Items.propTypes = {
  items: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired,
};

export default Items;
