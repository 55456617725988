import { Alert, NativeModules, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ProxyPolyfillBuilder from 'proxy-polyfill/src/proxy';
import {
  getActiveSourceData,
  SOURCE_DATA_TYPE_API,
  SOURCE_DATA_TYPE_LIBRARY,
} from '~/AppConfig';
import { TOKEN_AUTH_USER } from '~/services/anywhere/constants';
// import ActivitiesApiModule from "~/modules/api/ActivitiesApiModule";
import AccountsApiModule from '~/modules/api/AccountsApiModule';
import SyncApiModule from '~/modules/api/SyncApiModule';
import ConfigurationApiModule from '~/modules/api/ConfigurationApiModule';
import SyncApiModuleV1 from '~/modules/api/SyncApiModuleV1';
import EdiApiModule from '~/modules/api/EdiApiModule';
import ServicesApiModule from '~/modules/api/ServicesApiModule';
import ClientsApiModule from '~/modules/api/ClientsApiModule';
import FinancingApiModule from '~/modules/api/FinancingApiModule';
import GeneralApiModule from '~/modules/api/GeneralApiModule';
import JustifyApiModule from '~/modules/api/JustifyApiModule';
import ReportsApiModule from '~/modules/api/ReportsApiModule';
import OrdersApiModule from '~/modules/api/OrdersApiModule';
import CombosApiModule from '~/modules/api/CombosApiModule';

import { callMethodApi } from '~/services/anywhere/callmethod';
import exitApp from '../utils/ForceExitApp';

export const CALL_GENERIC_PROPERTY = 'CALL_GENERIC';

const BaseApiModule = nameModule => {
  const methodByAlias = {
    // ActivitiesModule
    saveAnswer: 'salvarResposta',
    saveBINScheduling: 'salvarAgendamentoBIN',
    createNewItem: 'criarAtividade',
    listActivities: 'listarAtividades',
    flightPlanAlerts: 'alertasPlanoDeVoo',
    listAddressAlternative: 'listarEnderecosAlternativos',

    // CombosModule
    listCombos: 'listarCombos',
    selectCombo: 'selecionarCombo',
    applyCombo: 'aplicarCombo',
    removeCombo: 'removerCombo',

    // EnvironmentalModule
    listBin: 'listarAmbiental',

    // IncentiveModule
    applyIncentive: 'aplicarIncentivo',
    listIncentives: 'listarIncentivos',

    // ItineraryModule
    verificaRestricoesAbrirVisita: 'verificaRestricoesAbrirVisitaDataAgenda',

    // NotificationsModule
    listNotifications: 'listarNotificacoes',
    markAsRead: 'marcarComoLida',

    // ProductsModule
    getListProductsByGroup: 'listarGruposProdutos',
    getProduto: 'getProduto',
    getListProducts: 'listarTodosProdutos',
    getProductObservations: 'obterObservacoesProduto',
    setProductObservation: 'salvarObservacaoProduto',
    getProductAttributes: 'obterCombinacoesAtributosValores',
    getScale: 'obterEscalaPrecoProduto',
    setScale: 'salvarEscalaPrecoProduto',
    getProductsMagnitude: 'produtoGrandezas',
    getFilterProducts: 'getFiltroProdutos',
    listarTodasBonificacoes: 'listarTodasBonificacoes',
    getCombos: 'obterCombos',
    getInfosProduct: 'obterInfosProduto',
    getProductsHome: 'listarProdutosHome',

    // VisitsModule
    opening: 'eventoAbrirVisita',
    closing: 'eventoFecharVisita',

    // ClientsModule - InFile
    // FinancingModule - InFile
    // GeneralModule - InFile
    // JustifyModule - InFile
    // OrderModule - InFile
    // ReportsModule - InFile
  };

  const genericProperties = {};
  Object.keys(methodByAlias).forEach(
    k => (genericProperties[k] = CALL_GENERIC_PROPERTY),
  );

  return {
    getName: () => nameModule,
    methodByAlias,
    listNotifications: () => {},
    ...genericProperties,
  };
};

const ProviderByModuleName = {
  AccountsModule: AccountsApiModule,
  ConfigurationModule: ConfigurationApiModule,
  SyncModule: SyncApiModule,
  SyncModuleV1: SyncApiModuleV1,
  EdiModule: EdiApiModule,
  ServicesModule: ServicesApiModule,
  ClientsModule: ClientsApiModule,
  FinancingModule: FinancingApiModule,
  GeneralModule: GeneralApiModule,
  JustifyModule: JustifyApiModule,
  OrdersModule: OrdersApiModule,
  ReportsModule: ReportsApiModule,
  CombosModule: CombosApiModule,
};

const paramsActiveCart = {
  method_name: 'fslib_pedido_carrinho_ativo',
  params_list: [],
};

const BasePreRunMap = {};

const BasePostRunMap = {};

const fakeSleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

const logOutWeb = async () => {
  try {
    await AsyncStorage.clear();
    window.location.reload(false);
  } catch (error) {
    alert('Erro ao Sair da Aplicação...');
  }
};

const baseGenericFuncWrapper = (target, name) => {
  return async (...args) => {
    const finalMethodName =
      'methodByAlias' in target ? target.methodByAlias[name] || name : name;
    const preRunPayload = BasePreRunMap[name] || [];
    const postRunPayload = BasePostRunMap[name] || [];
    const payload = [
      ...preRunPayload,
      {
        method_name: finalMethodName,
        params_list: args,
      },
      ...postRunPayload,
    ];
    const resultPosition = preRunPayload.length;
    const authToken = await AsyncStorage.getItem(TOKEN_AUTH_USER);
    let resolve = (...rArgs) => {};
    let reject = (...rArgs) => {};
    if (args.length >= 2) {
      let candidateResolve = args[args.length - 2];
      let candidateReject = args[args.length - 1];

      if (
        typeof candidateResolve === 'function' &&
        typeof candidateReject === 'function'
      ) {
        resolve = candidateResolve;
        reject = candidateReject;
      }
    }
    try {
      const result = await callMethodApi(payload, authToken);
      const response = result.data[resultPosition].response;
      if (
        response.includes('query_invalid_answer') ||
        response.includes('save_answer_db')
      ) {
        reject(response);
      } else {
        resolve(response);
        return response;
      }
    } catch (err) {
      if (err.response) {
        const rejectResponse = [
          {
            contexto: 'anywhere_api',
            mensagem: `Falha ao chamar a api para. ${finalMethodName}`,
            content: err.response,
            codigo: `api_error_${err.response.status}`,
            tipo: 'error',
          },
        ];
        reject(JSON.stringify(rejectResponse));
        if (err.response.status === 403 || err.response.status === 401) {
          if (Platform.OS !== 'web') {
            throw new Error('Usuário não autorizado') &&
              Alert.alert(
                'Ops!',
                `${err.response.data.msg}` || 'Usuário não autorizado',
                [{ text: 'OK', onPress: exitApp }],
                { cancelable: false },
              );
          } else {
            if (confirm('Usuário não autorizado. Deseja sair do app?')) {
              logOutWeb();
            } else {
              logOutWeb();
            }
          }
        } else if (err.response.status === 428) {
          throw new Error(`${name} - ${err.response.data.message}`);
        } else {
          throw new Error(`${err.response}`);
        }
      } else {
        const rejectResponse = [
          {
            contexto: 'anywhere_api',
            mensagem: `Falha ao chamar a api para. ${finalMethodName}`,
            content: JSON.stringify(err),
            codigo: 'api_error_exception',
            tipo: 'error',
          },
        ];
        reject(JSON.stringify(rejectResponse));
      }
    }
  };
};

export const genericFuncWrapper = name => baseGenericFuncWrapper({}, name);

const createLibraryModuleFromName = moduleName => NativeModules[moduleName];

const createApiModuleFromName = moduleName => {
  const genericHandler = {
    get: function(target, name) {
      return target[name] !== CALL_GENERIC_PROPERTY
        ? target[name]
        : baseGenericFuncWrapper(target, name);
    },
  };

  const proxyPolyfill = ProxyPolyfillBuilder();
  const module = ProviderByModuleName[moduleName] || BaseApiModule;
  return new proxyPolyfill(module(moduleName), genericHandler);
};

export const createFromModuleName = moduleName => {
  const activeSourceData = getActiveSourceData();
  if (activeSourceData === SOURCE_DATA_TYPE_LIBRARY) {
    return createLibraryModuleFromName(moduleName);
  } else if (activeSourceData === SOURCE_DATA_TYPE_API) {
    return createApiModuleFromName(moduleName);
  } else {
    throw new Error('Check the "SOURCE_DATA_ACTIVE" value in AppConfig.js');
  }
};
