/* @flow */

import React, { Component } from 'react';
import { Modal, View, Text, StyleSheet, ScrollView } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import TopInfo from '~/components/Profile/TopInfo';

import { ContactUs } from '~/components';
import color from '../../common/color';
import { SEND_PHOTO_ONLY_WIFI } from '../../constants/Photo';
import { colors } from '../../../components_base/src/values';
import translate from '../../locales';
import UpdateDatas from '~/components/Profile/UpdateDatas';
import QuickActions from '~/components/Profile/QuickActions';
import Constants from '~/components/Profile/Constants';
import ProgressBarModal from '~/components/Profile/ProgressBarModal';
import Images from '@assets/Images';
import LoopText from '~/components/LoopText';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  buttonHideProgress: {
    fontWeight: '500',
    fontSize: 14,
    color: colors.primaryColorLight,
  },
  versionContainer: {
    marginVertical: 16,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  versionLabel: {
    fontSize: 10,
    fontWeight: 'bold',
    lineHeight: 16,
    letterSpacing: 0.2,
  },
  versionValue: {
    fontSize: 10,
    fontWeight: 'bold',
    lineHeight: 16,
    letterSpacing: 0.2,
  },
  modalStyle: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  modalContainer: {
    backgroundColor: 'white',
    paddingTop: 24,
    paddingVertical: 15,
    justifyContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    borderColor: 'rgba(0, 0, 0, 0.1)',
  },
  modalContent: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalTitle: {
    color: color.neutral900,
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 24,
    padding: 8,
  },
  modalSubTitle: {
    color: color.neutral600,
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 16,
  },
  LoopTextContainer: {
    paddingTop: 24,
    paddingVertical: 36,
    gap: 48,
  },
  circularProgressContainer: {
    marginHorizontal: 43,
    marginVertical: 71,
  },
  circularProgressContentContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  circularProgressContentText: {
    fontSize: 32,
    fontWeight: 'bold',
    lineHeight: 40,
    color: color.neutral900,
  },
  circularProgressContentPerc: {
    fontWeight: 'bold',
    lineHeight: 24,
    color: color.neutral900,
    fontSize: 18,
    paddingBottom: 4,
  },
});

const LOOP_TEXT_ARRAY = [
  'searchingYourInfos',
  'loadingTables',
  'organizingYourProducts',
  'knowingYourClients',
  'mappingYourRoutes',
];

function CircularProgressText({ completeSyncText, percentText }) {
  const circularText =
    completeSyncText || Math.round(percentText < 98 ? percentText : 98);
  return (
    <View style={styles.circularProgressContentContainer}>
      <Text style={styles.circularProgressContentText}>{circularText}</Text>
      <Text style={styles.circularProgressContentPerc}>{'%'}</Text>
    </View>
  );
}

CircularProgressText.propTypes = {
  completeSyncText: PropTypes.number.isRequired,
  percentText: PropTypes.number.isRequired,
};

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cbValue: true,
    };
    this.popupRef = React.createRef();
  }

  async UNSAFE_componentWillMount() {
    const cbValue = JSON.parse(
      await AsyncStorage.getItem(SEND_PHOTO_ONLY_WIFI),
    );
    if (cbValue !== null) {
      this.setState({ cbValue });
    }
  }

  changeCbValue() {
    this.setState({ cbValue: !this.state.cbValue }, async () => {
      await AsyncStorage.setItem(
        SEND_PHOTO_ONLY_WIFI,
        JSON.stringify(this.state.cbValue),
      );
    });
  }

  showContactUs = () => {
    this.popupRef.show();
  };

  onPressClose = () => {
    this.props.onPressClose();
  };

  getActions = () => {
    const { contactUs, showUploadPhotoCheckBox } = this.props;
    return [
      {
        id: 1,
        title: `${translate('sendPhotoOnlyWifi')}`,
        description: `${translate('sendPhotoOnlyWifiDescription')}`,
        onPress: this.changeCbValue.bind(this),
        show: showUploadPhotoCheckBox,
        buttonType: Constants.BUTTONTYPE.toggle,
        value: this.state.cbValue,
      },
      {
        id: 2,
        title: `${translate('contactUs')}`,
        description: `${translate('contactUsDescription')}`,
        onPress: this.showContactUs.bind(this),
        show: !!contactUs.length,
        buttonType: Constants.BUTTONTYPE.default,
      },
      {
        id: 4,
        title: `${translate('logOut')}`,
        description: `${translate('loginRequired')}`,
        onPress: this.onPressClose.bind(this),
        show: true,
        buttonType: Constants.BUTTONTYPE.default,
        icon: Images.outlineLogout,
      },
    ];
  };

  onAnimationComplete = () => {
    this.props.finishForceSync();
  };

  fillingText = fill => {
    const { completeSyncText } = this.props;
    return (
      <CircularProgressText
        completeSyncText={completeSyncText}
        percentText={fill}
      />
    );
  };

  render() {
    const {
      visibleInfo,
      source,
      onSelectImage,
      handleEasterEgg,
      onHideProgress,
      contactUs,
      downloadBase,
      showProgress,
      progress,
      textProgress,
      indeterminate,
      forceSync,
      completeSyncText,
    } = this.props;
    let { data } = this.props;

    if (!visibleInfo) {
      data = [];
    }

    const [
      userName = {},
      userLogin = {},
      userCompany = {},
      lastUpdate = {},
      lastSend = {},
      version = {},
    ] = data;

    const userInfo = { userName, userLogin, userCompany };
    const dataInfo = { lastUpdate, lastSend };
    const actions = this.getActions();

    return (
      <>
      <ScrollView style={{ backgroundColor: color.whiteColor }}>
        <TopInfo
          userInfo={userInfo}
          subinfo={`${translate('salesman')}`}
          image={source}
          handleEasterEgg={handleEasterEgg}
          onSelectImage={onSelectImage}
        />
        {((showProgress && !downloadBase) || !data.length) && (
          <View style={{ backgroundColor: '#fff' }}>
            <ProgressBarModal
              progress={progress}
              onHideProgress={onHideProgress}
              text={textProgress}
              indeterminate={indeterminate}
              showHideButton={false}
            />
          </View>
        )}
        <UpdateDatas dataInfo={dataInfo} />
        <QuickActions actions={actions} />
        <ContactUs
          contactUs={contactUs}
          ref={target => {
            this.popupRef = target;
          }}
          onTouchOutside={() => this.popupRef.close()}
          title={`${translate('contactsAndSocialNetworks')}`}
        />
        <View style={styles.versionContainer}>
          <Text style={styles.versionLabel}>{version.label}: </Text>
          <Text style={styles.versionValue}>{version.value}</Text>
        </View>
      </ScrollView>
      {forceSync ? (
          <Modal isVisible={true} style={styles.modalStyle}>
            <View style={styles.modalContainer}>
              <View style={{ paddingBottom: 8 }}>
                <View style={styles.modalContent}>
                  <Text style={styles.modalTitle}>{translate('syncInfo')}</Text>
                  <Text style={styles.modalSubTitle}>
                    {translate('pleaseWait')}
                  </Text>
                  <Text style={styles.modalSubTitle}>
                    {translate('wePreparingYou')}
                  </Text>
                  <View style={[styles.circularProgressContainer]}>
                    <AnimatedCircularProgress
                      size={186}
                      width={12}
                      fill={completeSyncText || 98}
                      backgroundColor="#E6E6E6"
                      tintColor="#0586FF"
                      tintColorSecondary="#05FF1E"
                      lineCap="round"
                      fillLineCap="round"
                      padding={10}
                      duration={5000}
                      rotation={360}
                      onAnimationComplete={this.onAnimationComplete}>
                      {this.fillingText}
                    </AnimatedCircularProgress>
                  </View>
                  <View style={styles.LoopTextContainer}>
                    <LoopText
                      textArray={LOOP_TEXT_ARRAY.map(txt => translate(txt))}
                    />
                  </View>
                </View>
              </View>
            </View>
          </Modal>
        ) : null}
      </>
    );
  }
}

Profile.propTypes = {
  source: PropTypes.any,
  data: PropTypes.array,
  visibleInfo: PropTypes.bool,
  showUploadPhotoCheckBox: PropTypes.bool,
  onPressClose: PropTypes.func,
  onSelectImage: PropTypes.func,
  handleEasterEgg: PropTypes.func,
  contactUs: PropTypes.array,
  downloadBase: PropTypes.bool,
  showProgress: PropTypes.bool,
  forceSync: PropTypes.bool,
  finishForceSync: PropTypes.func,
  completeSyncText: PropTypes.number,
  onHideProgress: PropTypes.func,
  progress: PropTypes.any,
  textProgress: PropTypes.any,
  indeterminate: PropTypes.any,
};

export default Profile;
