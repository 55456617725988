import LoginBase from './LoginBase';
import LoginPolistore from './LoginPolistore';
import { POLIEQUIPES, POLISTORE } from '../escopeApps';

class LoginFactory {
  static createLoginFactory(username, password, dataAccount) {
    // const logins = Array(new LoginBase(username, password, dataAccount));
    const logins = [];

    dataAccount.apps.forEach((escope) => {
      if (escope === POLIEQUIPES || escope === POLISTORE) {
        logins.push(new LoginPolistore(username, password, dataAccount));
      }
    });

    return logins;
  }
}

export default LoginFactory;
