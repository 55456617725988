import { StyleSheet } from 'react-native';
import { color } from '~/common';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'absolute',
    left: '50%',
    bottom: 0,
    right: 0,
    marginRight: 8,
    padding: 4,
    marginBottom: 4,
  },
  itens: {
    paddingHorizontal: 4,
    borderRadius: 4,
    alignSelf: 'center',
    backgroundColor: color.neutral10,
    elevation: 2,
  },
  title: {
    color: color.neutral900,
    fontSize: 12,
    fontWeight: '700',
    lineHeight: 16,
    marginBottom: 4,
  },
  description: {
    color: color.neutral600,
    fontSize: 12,
    fontWeight: '500',
    lineHeight: 16,
    marginBottom: 12,
  },
  button: {
    paddingHorizontal: 4,
    paddingBottom: 1,
    height: 24,
  },
  btnText: {
    color: color.primary600,
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 24,
  },
});

export default styles;
