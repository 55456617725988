import React from 'react';
import {
  TextInput,
  StyleSheet,
  View,
  TouchableOpacity,
  Keyboard,
} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { Colors } from '~/config/config';
import translate from '~/locales';

const styles = StyleSheet.create({
  container: {
    padding: 5,
    backgroundColor: Colors.primaryColor,
    paddingHorizontal: 12,
  },
  searchBar: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderColor: '#FFF',
    borderStyle: 'solid',
    borderRadius: 4,
  },
  searchBarInput: {
    flex: 1,
    // fontWeight: 'normal',
    color: '#212121',
    backgroundColor: 'transparent',
    paddingLeft: 10,
    fontSize: 16,
  },
});

export default class SearchBar extends React.Component {
  static defaultProps = {
    onChangeText: () => {},
    onEndEditing: () => {},
    onSubmitEditing: () => {},
    inputStyle: {},
    iconCloseName: 'md-close',
    iconSearchName: 'ios-search-sharp',
    iconBackName: 'md-arrow-back',
    placeholder: `${translate('search')}`,
    returnKeyType: 'search',
    placeholderColor: '#bdbdbd',
    iconColor: '#737373',
    textStyle: {},
    alwaysShowBackButton: false,
    height: 40,
  };

  constructor(props) {
    super(props);
    this.state = {
      isOnFocus: false,
      wait: true,
    };
    this._onFocus = this._onFocus.bind(this);
    this._onBlur = this._onBlur.bind(this);
    this._onClose = this._onClose.bind(this);
  }

  _onClose() {
    this._textInput.setNativeProps({ text: '' });
    this.props.onChangeText('');
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  _onFocus() {
    this.setState({ isOnFocus: true });
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }

  _onBlur() {
    this.setState({ isOnFocus: false });
    if (this.props.onBlur) {
      this.props.onBlur();
    }
    this._dismissKeyboard();
  }

  _dismissKeyboard() {
    Keyboard.dismiss();
  }

  _backPressed() {
    Keyboard.dismiss();
    if (this.props.onBackPress) {
      this.props.onBackPress();
    }
  }

  render() {
    const {
      height,
      autoCorrect,
      returnKeyType,
      onChangeText,
      placeholder,
      inputStyle,
      iconColor,
      iconCloseComponent,
      iconSearchComponent,
      iconBackComponent,
      iconBackName,
      iconSearchName,
      iconCloseName,
      placeholderColor,
      textStyle,
    } = this.props;

    return (
      <View
        onStartShouldSetResponder={this._dismissKeyboard}
        style={styles.container}>
        <View
          style={[
            styles.searchBar,
            {
              height: height,
              paddingLeft: 10,
            },
            inputStyle,
          ]}>
          {this.state.isOnFocus || this.props.alwaysShowBackButton ? (
            <TouchableOpacity onPress={this._backPressed.bind(this)}>
              {iconBackComponent ? (
                iconBackComponent
              ) : (
                <Icon name={iconBackName} size={20} color={iconColor} />
              )}
            </TouchableOpacity>
          ) : iconSearchComponent ? (
            iconSearchComponent
          ) : (
            <Icon name={iconSearchName} size={20} color={iconColor} />
          )}
          <TextInput
            autoCorrect={autoCorrect === true}
            ref={c => (this._textInput = c)}
            returnKeyType={returnKeyType}
            onFocus={this._onFocus}
            onBlur={this._onBlur}
            onChangeText={onChangeText}
            onEndEditing={this.props.onEndEditing}
            onSubmitEditing={this.props.onSubmitEditing}
            placeholder={placeholder}
            placeholderTextColor={placeholderColor}
            underlineColorAndroid="transparent"
            style={[styles.searchBarInput, textStyle]}
            {...this.props.inputProps}
          />
          {this.state.isOnFocus ? (
            <TouchableOpacity onPress={this._onClose}>
              {iconCloseComponent ? (
                iconCloseComponent
              ) : (
                <Icon
                  style={{ paddingRight: 10 }}
                  name={iconCloseName}
                  size={20}
                  color={iconColor}
                />
              )}
            </TouchableOpacity>
          ) : null}
        </View>
      </View>
    );
  }
}
