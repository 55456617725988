import {
  CALL_GENERIC_PROPERTY,
  genericFuncWrapper,
} from '~/modules/WrappedModule';

const GeneralApiModule = nameModule => {
  const methodByAlias = {
    getCustomList: 'obterListagem',
    getCustomListCustomized: 'obterListagemCustomizada',
    getFilters: 'obterFiltros',
    getSql: 'getSQL',
    // listSalesOpportunity: 'listarOportunidadeVendas',
    getCustomListTabs: 'obterListagemAba',
  };

  /* const getCustomListTabs = (groupId, id, params, custom, pagination) => {
    return genericFuncWrapper('obterListagemAba')(
      groupId,
      id,
      params,
      custom,
      '1',
      pagination,
    );
  }; */

  const genericProperties = {};
  Object.keys(methodByAlias).forEach(
    k => (genericProperties[k] = CALL_GENERIC_PROPERTY),
  );

  return {
    getName: () => nameModule,
    // getCustomListTabs,
    methodByAlias,
    ...genericProperties,
  };
};

export default GeneralApiModule;