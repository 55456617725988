export default class GretsOnlyProduct {
  list(product) {
    const {
      descricao_unidade,
      descricao_unidade_macro,
      multiplo_venda,
    } = product;

    const unidade_macro = product?.unidade_macro;

    const magnitudes = [];
    const magnitudeSelect = { label: descricao_unidade, value: multiplo_venda };
    magnitudes.push(magnitudeSelect);

    if (
      descricao_unidade !== descricao_unidade_macro &&
      multiplo_venda !== unidade_macro
    ) {
      magnitudes.push({ label: descricao_unidade_macro, value: unidade_macro });
    }

    return [magnitudes, magnitudeSelect];
  }

  updateMagnitude(product, productsTreeParam, cods, magnitudeSelect) {
    const { unidade_macro, multiplo_venda } = product;

    const multiSale =
      magnitudeSelect.value === unidade_macro ? unidade_macro : multiplo_venda;

    const quantidade = this._updateQuantity(product.quantidade, multiSale);
    const productCopy = Object.assign({}, product);
    productCopy.quantidade = quantidade;

    return productCopy;
  }

  getMultipleSale(product, magnitudeSelect) {
    if (magnitudeSelect.value === product?.unidade_macro) {
      return product.unidade_macro;
    }

    return product?.multiplo_venda;
  }

  _updateQuantity(quantity, multiSale) {
    let qty = 0;
    const qtyDiv = quantity % multiSale;
    if (qtyDiv !== 0) {
      qty = Math.trunc(quantity / multiSale) * multiSale + multiSale;
    } else {
      qty = quantity;
    }

    return qty;
  }
}
