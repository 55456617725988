import { Navigation } from 'react-native-navigation';
import { standardTabs } from './screens/Home/components/HomeJSONUtils';
import { SCREEN_LOGIN } from '~/screens/screens';

class MainNav {
  static singleApp(screenName) {
    Navigation.setRoot({
      root: {
        stack: {
          children: [
            {
              component: {
                name: screenName,
                options: {
                  topBar: {
                    title: {
                      text: 'PoliEquipes',
                    },
                  },
                },
              },
            },
          ],
        },
      },
    });
  }
  static startLogin() {
    this.singleApp(SCREEN_LOGIN.name);
  }

  static startHome() {
    const bottomTabs = standardTabs.filter(tab => tab.required);
    this.startHomeByTabs(bottomTabs);
  }
  static setTabs(tabs, modules) {
    let bottomTabs = [];
    const noTabs = !tabs || tabs.length === 0 || !tabs[0];
    if (noTabs && !modules) {
      bottomTabs = standardTabs.filter(tab => tab.required);
    } else if (modules && noTabs) {
      let basicTabsOnModules = [];
      if (typeof modules[0].id === 'undefined') {
        basicTabsOnModules = modules
          .filter(
            app => app === 'itinerary' || app === 'clients' || app === 'orders',
          )
          .map(bTab =>
            standardTabs.find(
              sTab => sTab.stack.id === bTab || sTab.title === bTab,
            ),
          );
        bottomTabs = [standardTabs[0], ...basicTabsOnModules, standardTabs[4]];
      } else {
        basicTabsOnModules = modules
          .filter(
            app =>
              app.id === 'itinerary' ||
              app.id === 'clients' ||
              app.id === 'orders',
          )
          .map(bTab =>
            standardTabs.find(
              sTab => sTab.stack.id === bTab.id || sTab.title === bTab.id,
            ),
          );

        bottomTabs = [standardTabs[0], ...basicTabsOnModules, standardTabs[4]];
      }
    } else if (!noTabs) {
      bottomTabs = tabs;
    }

    bottomTabs.forEach(tab => {
      if (modules !== undefined) {
        const configTab = modules.find(
          app => app.id === tab.stack.id || app.id === tab.title,
        );

        if (configTab !== undefined) {
          const { options } = tab.stack.children[0].component;
          options.bottomTab.text = configTab.label;
          options.topBar.title.text = configTab.title;
        }
      }
    });
    return bottomTabs;
  }

  static startHomeByTabs(bottomTabs, modules) {
    const tabs = this.setTabs(bottomTabs, modules);

    Navigation.setRoot({
      root: {
        bottomTabs: {
          children: tabs,
        },
      },
    });
  }
}

export default MainNav;
