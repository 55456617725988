/* @flow */

import _ from 'lodash';
import translate from '../../locales';
import executeActionOrOpenScreenMessage from '../../utils/messages';
import { SCREEN_ACTIVITIES, SCREEN_CLIENT_PROCESS } from '../screens';
import ItineraryModule from '../../modules/ItineraryModule';
import TextUtils from '../../utils/TextUtils';
import isProcessDone, { processHasAnswer } from '../../utils/activities';
import ActivityCache from '../../cache/ActivityCache';
import ClientsModule from '~/modules/ClientsModule';
import moment from 'moment';
import { setCurrentClient } from '~/store/clientCurrent/slice';
import { fetchItinerary } from '~/store/itineraryFetch/slice';
import { store } from '~/store/index';
import AccountsModule from '~/modules/AccountsModule';

export default class ActivitiesScreen {
  constructor(pushScreen, dispatch, dateSelected, event, allEvents, context) {
    this.pushScreen = pushScreen;
    this.dispatch = dispatch;
    this.dateSelected = store.getState().itineraryFetch.selectedDate;
    this.event = event;
    this.allEvents = store.getState().itineraryFetch.payload;
    this.context = context;
    this.clientBlocked = {};
  }

  pushNextScreen = ({ screen, title, props }) => {
    this.pushScreen(screen, title, {
      ...props,
    });
  };

  getClient = pkey =>
    new Promise((resolve, reject) => {
      ClientsModule.detailsClient(pkey, resolve, reject);
    });

  getProcessBlock = async () => {
    let process = {};
    try {
      let cached = await ActivityCache.getTaskListProcessBlock();
      if (!cached) {
        const fromDb = await AccountsModule?.getValueAtivo(
          'INFO',
          'processo_ativo',
        );
        cached = JSON.parse(fromDb);
      }
      process = typeof cached === 'string' ? JSON.parse(cached) : cached;
    } catch (err) {
      process = {};
      console.log('ERR GET PROCESS BLOCK', err.message);
    }

    return process;
  };

  isSameClient = async event => {
    const activityProcess = (await this.getProcessBlock()) || {};
    const processBlockClient = _.get(activityProcess, 'client', '');

    this.clientBlocketDate = activityProcess.itineraryDate;
    return {
      isSameClient: processBlockClient.pkey === event.pkey,
      clientBlocked: processBlockClient,
      itineraryDate: activityProcess.itineraryDate,
      clientsAreBlocked: activityProcess.clientsBlocked,
    };
  };

  verifyRestrictionsOpenVisit = async (event, processId, date) => {
    let messages = await ItineraryModule?.verificaRestricoesAbrirVisita(
      event.pkey,
      processId,
      this.context === 'itinerary',
      JSON.stringify({
        t_data_agenda: `${moment(date).unix()}`,
      }),
    );
    messages = JSON.parse(messages || '[]');

    const {
      isSameClient,
      clientBlocked,
      itineraryDate,
      clientsAreBlocked,
    } = await this.isSameClient(event);

    if (clientsAreBlocked && itineraryDate) {
      const today = moment().format('YYYY-MM-DD');
      if (itineraryDate === today) {
        if (itineraryDate !== date) {
          messages.push({
            codigo: 'pending_checkout',
            contexto: 'itinerary',
            tipo: 'erro',
            mensagem: 'Processo aberto em outro dia do roteiro',
            params: {},
          });
        }
      }
    }

    this.clientBlocked = clientBlocked;
    // Atenção: Esta é uma solução paliativa para este caso.
    // TODO: Alterar a consulta do TVAR "ABRI" "VIST" para que ela retorne também o código do erro.
    const isSequenceRequiredError = _.find(messages, ({ mensagem }) => {
      const message = _.deburr(TextUtils.slugfy(mensagem));
      const error =
        message.includes('venda') &&
        message.includes('fora') &&
        message.includes('sequencia') &&
        message.includes('rota');
      return error;
    });

    if (messages.length > 0) {
      if (
        (!!isSequenceRequiredError && isSameClient) ||
        (this.context === 'customers' && isSameClient)
      ) {
        return [];
      }

      // const isDone = await isProcessDone(event, this.dateSelected);
      // const hasAnswer = await processHasAnswer(event, this.dateSelected);

      return messages;
    }

    return [];
  };

  getPendingCheckInClient = async restrictions => {
    const processBlock =
      _.find(restrictions, i => i.codigo === 'pending_checkout') || {};
    let clientBlocked = null;
    let pkeyCurrentClient = null;
    const params = _.get(processBlock, 'params', {});

    if (params) {
      const currentClientParams = _.get(params, 'current_client', '')?.split(
        ':',
      );
      pkeyCurrentClient = currentClientParams?.[0] || '';

      this.clientBlocketDate = currentClientParams?.[1];
      if (this.clientBlocketDate) {
        this.allEvents = await fetchItinerary(
          this.clientBlocketDate,
          store.getState(),
        );
      }

      clientBlocked =
        _.find(this.allEvents, e => e.pkey === pkeyCurrentClient) || '';

      const clientByCache = this.clientBlocked || {};

      clientBlocked = !clientBlocked
        ? clientByCache
        : { ...clientBlocked, ...clientByCache };
    }

    return clientBlocked;
  };

  handleOneProcessScreen = async (client, processId, date) => {
    const restrictions =
      (await this.verifyRestrictionsOpenVisit(client, processId, date)) || [];

    const clientBlocked = await this.getPendingCheckInClient(restrictions);
    const hasClientBlocked = clientBlocked && clientBlocked.pkey;
    const isSameClient = await this.isSameClient(client);
    const sameClient = client?.pkey === clientBlocked?.pkey;
    if (restrictions.length > 0) {
      executeActionOrOpenScreenMessage(
        restrictions,
        null,
        null,
        hasClientBlocked
          ? () => {
              this.handleNextScreen(
                clientBlocked,
                this.clientBlocketDate || date,
              );
            }
          : null,
        hasClientBlocked ? `${translate('goToClient')}` : null,
      );
    } else {
      this.dispatch(
        setCurrentClient({
          ...client,
          dateSelected: date,
        }),
      );
      this.pushNextScreen({
        screen: SCREEN_ACTIVITIES,
        title: client.nome,
        props: {
          event: {
            ...client,
            activeProcessId: processId,
          },
          dateSelected: date,
        },
        overrideBackPress: true,
      });
    }
  };

  handleNextScreen = (event, date) => {
    const process = _.get(event, 'process', []);
    const processId = _.get(event, 'id_processo', '');
    const hasMultipleProcess = process && process.length > 1;
    if (hasMultipleProcess) {
      this.dispatch(
        setCurrentClient({
          ...event,
          dateSelected: date,
        }),
      );

      this.pushNextScreen({
        screen: SCREEN_CLIENT_PROCESS,
        title: event.nome,
        overrideBackPress: true,
        props: {
          client: event,
          dateSelected: date,
          handleScreen: this.handleOneProcessScreen,
        },
      });
    } else {
      let activeProcessId;
      if (process && process.length > 0) {
        activeProcessId = process[0].id;
      } else if (processId) {
        activeProcessId = processId;
      }
      this.handleOneProcessScreen(event, activeProcessId, date);
    }
  };
}
