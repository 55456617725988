import React from 'react';
import { View } from 'react-native';

import ItemPhoto from './ItemPhoto';
import Carousel from '../../../../../components_base/src/components/Carousel';
import Option from '../../components/Option';

import { stylesCarouselPhoto } from './styles';
import translate from '../../../../locales';

type Props = {
  photos: Array,
  source: Object,
  useCache: boolean,
  onPressShowCamera: () => void,
  onRemovePhoto: () => void,
};

function CarouselPhoto({
  photos,
  source,
  onPressShowCamera,
  onRemovePhoto,
  useCache,
}: Props) {
  const itemSeparatorComponent = () => (
    <View style={stylesCarouselPhoto.itemSeparatorComponent} />
  );

  const listHeaderComponent = (
    <View style={stylesCarouselPhoto.listHeaderComponent}>
      <Option
        title={`${translate('takePicture')}`}
        source={source}
        onPress={onPressShowCamera}
        containerStyle={stylesCarouselPhoto.optionContainer}
      />
      <View style={stylesCarouselPhoto.itemSeparatorComponent} />
    </View>
  );

  const renderItem = ({ item, index }) => {
    if (item.deleted_at) {
      return null;
    }

    return (
      <ItemPhoto
        photo={item}
        useCache={useCache}
        index={index}
        onRemovePhoto={onRemovePhoto}
      />
    );
  };

  return (
    <Carousel
      data={photos}
      ListHeaderComponent={listHeaderComponent}
      ItemSeparatorComponent={itemSeparatorComponent}
      containerStyle={stylesCarouselPhoto.containerFlatList}
      showsHorizontalScrollIndicator={false}
      renderItem={renderItem}
    />
  );
}

export default CarouselPhoto;
