/* @flow */

import React, { Component } from 'react';
import Observations from './Observations';
import {
  productObservations,
  PRODUCT_OBSERVATIONS_SUCCESS,
} from '~/store/productObservations/slice';
import {
  SET_PRODUCT_OBSERVATIONS_SUCCESS,
  setProductObservations,
} from '~/store/setProductObservations/slice';
import { connect } from 'react-redux';
import translate from '../../locales';
import { Image, TouchableOpacity } from 'react-native';
import Images from '@assets/Images';

import PropTypes from 'prop-types';
import { createNotifyError, createNotifySuccess } from '~/components/Web/ToastNotify';
import { showAlert } from '~/components/Web/Alert';

class ObservationsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldValueValid: false,
    };
  }

  UNSAFE_componentWillMount() {
    const { chave } = this.props?.route?.params.product;
    this.props.dispatch(productObservations({ codigo: chave }));
  }

  componentDidMount() {
    this.setLeftButton();
    this.extractObservations(this.props.observations.payload);
  }

  componentDidUpdate(prevProps) {
    (() => {
      if (prevProps.setObservations.type === this.props.setObservations.type) {
        return;
      }
      if (
        this.props.setObservations.type !== SET_PRODUCT_OBSERVATIONS_SUCCESS
      ) {
        return;
      }
      createNotifySuccess(`${translate('observationAdded')}`);
      this.props.navigation.goBack();
    })();
    (() => {
      if (prevProps.observations.type === this.props.observations.type) {
        return;
      }
      if (this.props.observations.type !== PRODUCT_OBSERVATIONS_SUCCESS) {
        return;
      }
      const data = this.props.observations.payload;
      if (
        Array.isArray(data) &&
        (data.length === 0 || (data.length && data[0].codigo === 'sem_dados'))
      ) {
        createNotifyError(`${translate('thereAreNotObservationsToThisProduct')}`);
        setTimeout(() => {
          this.props.navigation.pop(1);
        }, 500);
      }
    })();
  }

  setSaveButton(valid) {
    const rightButtons = [];
    if (valid) {
      this.props.navigation.setOptions({
        headerRight: () => (
          <TouchableOpacity
            onPress={() => {
              this.saveObservation();
            }}>
            <Image
              source={Images.iconSave}
              style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 10 }}
            />
          </TouchableOpacity>
        ),
      });
    }
  }

  navigationButtonPressed({ buttonId }) {
    if (buttonId === 'save') {
      this.saveObservation();
    }
    if (buttonId === 'backPress') {
      this.handleBackPress();
    }
  }

  extractObservations = observations => {
    const hasValueObs = observations.some(obs => obs.value);
    const newObj = {};
    if (hasValueObs) {
      observations.forEach(objeto => {
        const { field, value } = objeto;
        newObj[field.toString()] = value;
      });
    }
    return newObj;
  };

  compareTwoObjects = (firstObject, secondObject) => {
    const firstObjKeys = Object.keys(firstObject);
    const secondObjKeys = Object.keys(secondObject);
    if (firstObjKeys.length !== secondObjKeys.length) {
      return false;
    }
    for (const chave of firstObjKeys) {
      if (firstObject[chave] !== secondObject[chave]) {
        return false;
      }
    }
    return true;
  };

  getFormData = formData => {
    for (const chave in formData) {
      if (formData[chave] === '') {
        this.setState({ formData: null });
      } else {
        this.setState({ formData });
      }
    }
  };

  handleBackPress = () => {
    const { observations } = this.props;
    const hasValueObs = observations.payload.some(obs => obs.value);
    const initialValueForm = this.extractObservations(
      this.props.observations.payload,
    );
    const areEqual = this.compareTwoObjects(
      this.state.formData ? this.state.formData : {},
      initialValueForm,
    );

    if (!this.state.formData && hasValueObs && !areEqual) {
      this.props.navigation.goBack();
      return true;
    }
    if (this.state.formData && areEqual) {
      this.props.navigation.goBack();
      return true;
    }
    if (!this.state.formData && !hasValueObs) {
      this.props.navigation.goBack();
      return true;
    }
    if (this.state.formData && !areEqual) {
      showAlert(`${translate('discardChanges')}`, `${translate('discardChangesInForm')}`, () => {
        this.props.navigation.goBack();
      });
    }
  };

  isFormValid = valid => {
    const initialValueForm = this.extractObservations(
      this.props.observations.payload,
    );

    const areEqual = this.compareTwoObjects(
      this.state.formData ? this.state.formData : {},
      initialValueForm,
    );

    if (!valid) {
      this.setSaveButton(false);
      this.setState({ oldValueValid: valid });
      return;
    }

    if (this.state.formData && areEqual) {
      this.setSaveButton(false);
      this.setState({ oldValueValid: false });
      return;
    }

    if (this.state.formData && !areEqual) {
      if (valid && this.state.oldValueValid) {
        return;
      }
      this.setSaveButton(true);
      this.setState({ oldValueValid: true });
    }
  };

  saveObservation() {
    const { formData } = this.state;
    const { chave } = this.props.route?.params?.product;
    const data = this.props.observations.payload;

    const observations = [];

    if (formData) {
      Object.keys(formData).forEach(key => {
        observations.push({
          codigo: chave,
          observation: formData[key]?.value
            ? formData[key]?.value
            : formData[key],
          field: key,
        });
      });
      this.props.dispatch(setProductObservations(observations));
    } else {
      showAlert(`${translate('noChanges')}`, `${translate('noChangesInForm')}`, () => {});
    }
  }

  setLeftButton = () => {
    this.props.navigation.setOptions({
      headerLeft: () => (
        <TouchableOpacity onPress={() => {
          this.handleBackPress();
        }}
        >
          <Image
            source={Images.iconBack}
            style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
          />
        </TouchableOpacity>
      ),
    });
  };

  render() {
    return this.props.observations.payload &&
      this.props.observations.payload.length > 0 ? (
      <Observations
        form={this.props.observations.payload}
        isFormValid={this.isFormValid}
        getFormData={this.getFormData}
      />
    ) : null;
  }
}

ObservationsContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    observations: PropTypes.object.isRequired,
    setObservations: PropTypes.object.isRequired,
    componentId: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    observations: state.productObservations,
    setObservations: state.setProductObservations,
  };
}

export default connect(mapStateToProps)(ObservationsContainer);
