import _ from 'lodash';
import types from '../components/Form/types';
import moment from 'moment';
import TextUtils from './TextUtils';

export const transformNumber = value =>
  Number(value.replace(/\./gm, '').replace(',', '.'));

export function format(type, value) {
  if (type === types.MONEY.name && typeof value === 'string') {
    return transformNumber(value);
  }
  if (type === types.DATE.name && typeof value === 'string') {
    return new Date(value);
  }
  return value;
}

function getFormated(field, obj) {
  switch (field.type) {
    case types.DATE.name:
      return moment(obj[field.field], 'DD/MM/YYYY').unix();
    case types.TEXT.name:
      return _.deburr(obj[field.field]);
    case types.MONEY.name:
      return format(field.type, obj[field.field]) === -1
        ? 1
        : format(field.type, obj[field.field]);
    default:
      return obj[field.field];
  }
}

const normalize = field => item => getFormated(field, item);

function normalizeProducts(item) {
  return item.tipo === 'categoria' ? -1 : 0;
}

const sort = (list, field, order = 'asc') => _.orderBy(list, [normalizeProducts, normalize(field)], ['asc', order]);
export default sort;

export const filterByTextFields = (list, fields, text) =>
  list.filter(obj =>
    fields.some(field => {
      const fieldText = TextUtils.slugfy(_.deburr(obj[field]));
      const textCheck = TextUtils.slugfy(_.deburr(text));
      return fieldText.includes(textCheck);
    }),
  );

export const getFieldsSearch = list => list?.length;
