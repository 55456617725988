import { StyleSheet } from 'react-native';
import { color, size } from '~/common';

const styles = StyleSheet.create({
  separator: {
    height: 1,
    marginHorizontal: size.horizontalSpacing,
    backgroundColor: color.grayContainerDark,
  },
  containerComboItem: {
    backgroundColor: 'white',
  },
  title: {
    marginBottom: 5,
    fontSize: 16,
    fontWeight: 'bold',
    color: '#1A1A1A'
  },
  code: {
    color: color.grayMid,
    marginBottom: 5,
    fontSize: 16,
    fontWeight: '500',
  },
  stock: {
    fontSize: 16,
  },
  period: {
    color: color.grayDarkest,
    fontSize: 14,
  },
  icon: {
    width: 30,
    height: 30,
    tintColor: color.grayDark,
  },
  label: {
    marginLeft: 5,
    color: color.grayDarkest,
    fontSize: 14,
    fontWeight: '500',
  },
  value: {
    fontSize: 14,
    fontWeight: '400',
  },
  combo_applied: {
    tintColor: color.successColor,
  },
  description: {
    marginBottom: 10,
    fontSize: 14,
    fontWeight: '100',
  },
  descriptionBold: {
    color: color.black,
    marginBottom: 10,
    fontSize: 14,
    fontWeight: '600',
  },
  indicatorStyle: {
    paddingHorizontal: 16,
    backgroundColor: color.primaryColor,
  },
  PagerTitleIndicator: {
    backgroundColor: color.primaryColor,
  },
  selectedBorderStyle: {
    backgroundColor: color.accentColor,
  },
  indicatorTextBaseStyle: {
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.5)',
  },
  orderComboItems: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  orderComboTitle: {
    color: color.grayDarkest,
    fontSize: 12,
  },
  orderComboImage: {
    width: 20,
    height: 20,
  },
  orderComboContent: {
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 15,
  },
  orderComboDescription: {
    marginTop: 10,
    marginBottom: 10,
    fontWeight: '500',
  },
});

export default styles;
