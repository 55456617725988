import GretsManyProduct from './GretsManyProduct';
import GretsOnlyProduct from './GretsOnlyProduct';
import { MAGNITUDES_MANY_PRODUCT } from '../../../constants/rulesProductMagnitudes';

export default function getRuleMagnitudes(magnitudes, product) {
  if (product.type_magnitudes === MAGNITUDES_MANY_PRODUCT) {
    return new GretsManyProduct(magnitudes, product);
  }

  return new GretsOnlyProduct(product);
}
