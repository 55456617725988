import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Permission from 'react-native-permissions';

export const PERMISSION_REQUEST = 'PERMISSION_REQUEST';
export const PERMISSION_SUCCESS = 'PERMISSION_SUCCESS';
export const PERMISSION_FAILURE = 'PERMISSION_FAILURE';
export const PERMISSION_CLEAR = 'PERMISSION_CLEAR';

const checkPermission = async ({ permissions }) => {
  if (Array.isArray(permissions)) {
    const response = await Permission.checkMultiple(permissions);
    return { permissions, response };
  }
  const response = await Permission.check(permissions);
  return { permissions, response };
};

const askForPermission = async ({ permissions, response }) => {
  if (Array.isArray(permissions)) {
    const requestPermissions = [];
    permissions.forEach(permission => {
      if (response[permission] !== 'authorized') {
        requestPermissions.push(Permission.request(permission));
      }
    });

    const result = Promise.all(requestPermissions);

    if (Array.isArray(result)) {
      return result.map(resp => ({
        permissions,
        response,
      }));
    }
    return { permissions, response };
  }

  //VALIDAR/RESOLVER ESSE FLUXO MAIS TARDE
  if (response !== Permission.RESULTS.GRANTED) {
    const res = await Permission.request(permissions);
    return res;
  }
  return { permissions, response };
};

export const handlePermissions = createAsyncThunk(
  'permissionsFetch/handlePermissions',
  async (payload, { getState }) => {
    try {
      const data = await checkPermission({ permissions: payload });
      const resp = await askForPermission(data);

      return resp;
    } catch (err) {
      return {};
    }
  },
);

const initialState = {
  type: '',
  loading: false,
  erro: false,
  payload: {},
};

const permissionsFetch = createSlice({
  name: 'permissionsFetch',
  initialState,
  reducers: {
    permissionClear: (state, action) => {
      state.type = PERMISSION_CLEAR;
      state.payload = {};
    },
  },
  extraReducers: builder => {
    builder.addCase(handlePermissions.pending, state => {
      state.type = PERMISSION_REQUEST;
      state.loading = true;
    });
    builder.addCase(handlePermissions.fulfilled, (state, action) => {
      state.type = PERMISSION_SUCCESS;
      state.loading = false;
      state.erro = false;
      state.payload = { ...state.payload, ...action.payload };
    });
    builder.addCase(handlePermissions.rejected, (state, action) => {
      state.type = PERMISSION_FAILURE;
      state.loading = false;
      state.erro = true;
      state.payload = action.payload;
    });
  },
});

const { reducer, actions } = permissionsFetch;

export const { permissionClear } = actions;

export default reducer;
