import _ from 'lodash';
import store from '../store/index';

export default function getGpsConfigs(keyRequired, keyNotify) {
  const { configLibFetch } = store.getState();
  const libConfigGPS = _.get(configLibFetch, 'payload.gps', {});

  const getConfig = (config, defaultValue) =>
    _.get(libConfigGPS, config, defaultValue);
  const parseConfig = config =>
    typeof config === 'string' ? JSON.parse(config) : config;

  const requiredGPSPermissionLib = getConfig('required_out_demand', 'false');
  const requiredCollectGpsPointLib = getConfig(keyRequired, 'false');
  const notifyOnSavingLib = getConfig(keyNotify, 'false');

  const showPermissionLocation = parseConfig(requiredGPSPermissionLib);
  const requiredCollectGpsPoint = parseConfig(requiredCollectGpsPointLib);
  const notifyOnSaving = parseConfig(notifyOnSavingLib);

  return {
    showPermissionLocation,
    requiredCollectGpsPoint,
    notifyOnSaving,
  };
}
