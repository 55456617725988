import moment from 'moment';
import ClientsModule from '~/modules/ClientsModule';
import ActivitiesModule from '~/modules/ActivitiesModule';
import NotificationController from '~/controllers/notifications/NotificationController';
import translate from '~/locales';
import {createNotifyError} from "~/components/Web/ToastNotify";

const createNotification = async notification => {
  await ClientsModule.createNotification(JSON.stringify(notification));
};

const verifyAndSendAlerts = async params => {
  try {
    const rawData = await ActivitiesModule.flightPlanAlerts(
      JSON.stringify(params),
    );
    const alerts = JSON.parse(rawData);

    alerts?.forEach(alert => {
      const { stealth = false, key, priority = 1, msg } = alert;
      if (!stealth) {
        NotificationController.showNotification({
          title: translate('flightPlan'),
          message: msg,
          foreground: true,
        });
      }
      createNotification({
        type: alert.stealth ? 'STEALTH' : 'INTERNAL',
        title: translate('flightPlan'),
        description: alert.msg,
        payload: JSON.stringify({
          alert: {
            priority,
            key,
            params,
          },
        }),
      });
    });
  } catch (e) {
    createNotifyError(translate('flightPlanVerifyError'));
    console.log('flightPlanTrigger.web line 45. error: ', e);
  }
};

export const trigAction = (type, action) => {
  const trigTypes = {
    atividade: async () => {},
  }; 

  /* if (type in trigTypes) {
    trigTypes[type]();
  } */
};
