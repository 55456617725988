/* @flow */

import React, { Component } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import Items from './Items/Items';
import Info from './Info/Info';
import { width } from '~/components/measures';
import translate from '../../locales';
import MultiTabsView from '~/components/MultiTabsView/MultiTabsView';
import _ from 'lodash';
import { isShowMultiTabs } from '~/utils/GlobalUtils';
import { SceneMap, TabBar, TabView } from 'react-native-tab-view';
import { Colors } from '~/config/config';
import { color } from '~/common';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  loadingIndicator: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    alignSelf: 'center',
    top: '50%',
  },
});

class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMultiTabs: false,
      activeTab: 0,
      tabs: [
        { key: 'first', title: `${translate('details')}` },
        { key: 'second', title: `${translate('items')}` },
      ],
    };
  }

  async componentDidMount() {
    const { configLib } = this.props;
    const showMultiTabs = isShowMultiTabs(
      configLib,
      'orders',
      'detalhe_pedido',
    );
    this.setState({ showMultiTabs });
  }

  infoRoute = () => {
    const { order, info, showOrderDetailHeaderAsHTML } = this.props;

    return (
      <Info
        order={order}
        info={info}
        showOrderDetailHeaderAsHTML={showOrderDetailHeaderAsHTML}
      />
    );
  };

  itemsRoute = () => {
    const { items, config } = this.props;

    return <Items items={items} config={config} />;
  };

  _renderTabBar = props => (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: Colors.stripGreen }}
      indicatorContainerStyle={{ backgroundColor: Colors.primaryColor }}
      inactiveColor={Colors.gray}
      activeColor="white"
    />
  );

  defaultView = () => {
    const { info } = this.props;
    return (
      <TabView
        renderTabBar={this._renderTabBar}
        navigationState={{
          index: this.state.activeTab,
          routes: this.state.tabs,
          info,
        }}
        onIndexChange={tab => this.setState({ activeTab: tab })}
        renderScene={SceneMap({
          first: this.infoRoute,
          second: this.itemsRoute,
        })}
        initialLayout={{ width }}
      />
    );
  };

  multiTabsView = () => {
    const { configLib, navigator } = this.props;

    const pkOrder = _.get(this.props, 'order.numero_pedido', false);
    const params = pkOrder
      ? {
          pk_pedido: pkOrder,
        }
      : {};

    return (
      <MultiTabsView
        ref={ref => {
          this.multiTabsRef = ref;
        }}
        configLib={configLib}
        params={params}
        groupId="PGDT"
        navigator={navigator}
      />
    );
  };

  render() {
    const { loading } = this.props;
    const { showMultiTabs } = this.state;

    if (loading) {
      return (
        <View style={styles.loadingIndicator}>
          <ActivityIndicator size="large" color={color.primaryColor} />
        </View>
      );
    }

    return !showMultiTabs ? this.defaultView() : this.multiTabsView();
  }
}

OrderDetail.propTypes = {
  order: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired,
  showOrderDetailHeaderAsHTML: PropTypes.bool.isRequired,
  configLib: PropTypes.object.isRequired,
  navigator: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default OrderDetail;
