import axios from 'axios';

import { BASE_ANYWHERE_URL_PROD } from '../constants';

export const callMethodApi = (payload, token) => {
  return axios.post(`${BASE_ANYWHERE_URL_PROD}/v1/api/call-method/`, payload, {
    headers: { Authorization: `FSJWT ${token}` },
  });
};

export const callGenerateReportApi = (payload, token) => {
  return axios.post(
    `${BASE_ANYWHERE_URL_PROD}/v1/api/generate-report/`,
    payload,
    { headers: { Authorization: `FSJWT ${token}` } },
  );
};

export default callMethodApi;
