/* @flow */

import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { color } from '~/common';
import { fonts } from "../../../utils/Fonts";

type Props = {
  children: any,
  title: string,
}

const styles = StyleSheet.create({
  sectionContainer: {
    padding: 16,
    marginBottom: 16,
  },
  titleSection: {
    color: '#000',
    fontFamily: fonts.QuicksandBold,
    fontSize: 16,
  },
});

const Section = (props: Props) => (
  <View style={styles.sectionContainer}>
    <Text style={styles.titleSection}>{props.title}</Text>
    { props.children }
  </View>
);

export default Section;
