/* @flow */

import React from 'react';
import { ScrollView, View, StyleSheet, Text } from 'react-native';
import { style, color } from '~/common';
import Images from '@assets/Images';
import { Button, FAB } from '../../../components';
import { fonts } from '../../../utils/Fonts';
import translate from '../../../locales';
import Gps from '../../../../components_base/src/components/Gps/Gps';
import _ from 'lodash';
import GpsHelper from '~/utils/GpsHelper';
import TextInputScanner from '../components/TextInputScanner';

type Props = {
  onFinish: () => {},
  onChangeMessage: () => {},
  message: string,
  time: string,
  isRunning: boolean,
  isToFinish: boolean,
  isFinished: boolean,
  retry: boolean,
  start(): () => void,
  pause(): () => void,
  setup: Object,
  onLocationChange: () => void,
};

const buttonColor = '#134fdd';

const styles = StyleSheet.create({
  content: {
    flex: 1,

    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  textContainer: {},
  textStopwatch: {
    alignSelf: 'center',
    color: buttonColor,
    fontSize: 40,
    fontFamily: fonts.QuicksandLight,
  },
  buttonsContainer: {
    alignItems: 'center',
    alignSelf: 'center',
    marginTop: 20,
  },
});

class Stopwatch extends React.Component<Props, void> {
  constructor(props: Props) {
    super(props);
  }

  onPressPlay = () => {
    const { isRunning, isToFinish } = this.props;

    if (!(isRunning || isToFinish)) {
      this.props.start();
    } else if (isRunning) {
      this.props.pause();
    }
  };

  getPrimaryIcon() {
    if (this.props.isRunning) {
      return Images.iconStop;
    } else if (this.props.isToFinish) {
      return Images.iconStop;
    }
    return Images.iconPlay;
  }

  getIconColor() {
    if (this.props.isRunning) {
      return color.grayMid;
    } else if (this.props.isToFinish) {
      return color.grayMid;
    }
    return '#FFF';
  }

  getPrimaryColor() {
    if (this.props.isRunning) {
      return color.redBadge;
    } else if (this.props.isToFinish) {
      return color.grayContainerDark;
    }
    return buttonColor;
  }

  render() {
    const { pkey: pkeyClient } = _.get(this.props, 'event', {});

    return (
      <View style={style.whiteContainer}>
        <View style={styles.content}>
          <ScrollView>
            <View style={{ flex: 1 }}>
              <Gps
                places={[GpsHelper.parseGpsEvent(this.props.gpsPlaces || {})]}
                pkeyClient={pkeyClient}
                location={this.props.location}
                onLocationChange={this.props.onLocationChange}
                showPermitionLocation
                useViewExpandable
              />
            </View>
          </ScrollView>
          <View style={styles.textContainer}>
            <View style={{}}>
              <Text
                style={[
                  styles.textStopwatch,
                  this.props.isFinished && this.props.isToFinish
                    ? { color: color.successColor }
                    : {},
                ]}>
                {this.props.time}
              </Text>
              {!this.props.isFinished || this.props.retry ? (
                <View style={styles.buttonsContainer}>
                  <FAB
                    disabled={false}
                    style={{ marginBottom: 20 }}
                    fabStyle={{
                      backgroundColor: this.getPrimaryColor(),
                    }}
                    icon={this.getPrimaryIcon()}
                    iconColor={this.getIconColor()}
                    onPress={this.onPressPlay}
                  />
                </View>
              ) : null}
            </View>
            <TextInputScanner
              value={this.props.message}
              onChangeText={this.props.onChangeMessage}
              config={this.props.setup?.input_config}
            />
            <Button
              disabled={this.props.isToFinish}
              onPress={() => this.props.onFinish()}
              title={
                this.props.isToFinish
                  ? `${translate('saving')}`
                  : `${translate('save')}`
              }
              containerStyle={{ marginHorizontal: 16, marginBottom: 16 }}
            />
          </View>
        </View>
      </View>
    );
  }
}

export default Stopwatch;
