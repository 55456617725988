import React from 'react';
import { View, Text, Image } from 'react-native';
import Touchable from 'react-native-platform-touchable';

import styles from './styles';
import BannerImage from '~/components/Banners/components/BannerImage';
import Images from '@assets/Images';
import { Section } from '~/components';
import appStyles from '../../../components_base/src/appStyles';
import { IntlProvider, FormattedNumber } from 'react-intl';
import { calendarLanguage as language } from '~/locales';
import translate from '../../locales';

import PropsTypes from 'prop-types';

const ComboItem = ({
  combo,
  onPressCombo,
  comboType,
}) => {
  const {
    codigo,
    estoque,
    descricao,
    periodo,
    applied,
    hasApplyFlow,
    url_imagem: imageUrl,
    // title,
    saldo: amount,
    metodologia: method,
  } = combo;

  const image = imageUrl ? { uri: imageUrl } : Images.iconNoImageBig;
  // const hasTitle = !!title;
  const hasDescription = !!descricao;
  const hasPeriod = !!periodo;
  const isApplied = !!applied;
  const hasStock = !!estoque;
  const hasCode = !!codigo;
  const hasAmount = !!amount;
  const hasMethod = !!method;

  return (
    <IntlProvider locale={`${language}`}>
      <Touchable
        onPress={() => onPressCombo(combo)}
      >
        <View style={styles.containerComboItem}>
          {
            comboType === 'combos' && (
            <BannerImage
              image={image}
            />
            )
          }
          <Section>
            {/* {hasTitle && (<Text style={styles.title}>{title}</Text>)} */}
            {comboType === 'combos_pedido' && hasCode && (<Text style={styles.code}>{codigo}</Text>)}
            {hasDescription && (
            <Text
              numberOfLines={3}
              style={comboType === 'combos_pedido' ? styles.descriptionBold : styles.title}
            >
              {descricao}
            </Text>
            )}
            {hasMethod
              ? (
                <View style={{ bottom: 5 }}>
                  <Text style={styles.value}>{method}</Text>
                </View>
              )
              : null
            }
            {comboType === 'combos_pedido' && hasStock && (<Text style={styles.stock}>{estoque} {`${translate('inStockLCase')}`}</Text>)}
            {hasPeriod && (
            <View style={appStyles.rowDirection}>
              <Image source={Images.iconCalendar} style={styles.icon} />
              <Text style={styles.label}>{translate('period')}: </Text>
              <Text style={styles.value}>{periodo}</Text>
            </View>
            )}
            {
              comboType === 'combos' &&
              (
                <View style={appStyles.spaceBetween}>
                  {hasAmount
                    ? (
                      <View style={appStyles.rowDirection}>
                        <Image source={Images.iconCoinBig} style={styles.icon} />
                        <Text style={styles.label}>{translate('sharedBalance')}: </Text>
                        <FormattedNumber
                          formatStyle="currency"
                          currency="BRL"
                          value={combo.amount || 0}
                          style={styles.value}
                        />
                      </View>
                    )
                    : null
                  }
                  <View>
                    {!!(hasApplyFlow && isApplied) && (
                      <Image
                        source={Images.iconCheckCircle}
                        style={styles.combo_applied}
                      />
                    )}
                  </View>
                </View>
              )
            }
          </Section>
        </View>
      </Touchable>
    </IntlProvider>
  );
};

ComboItem.propTypes = {
    combo: PropsTypes.object.isRequired,
    comboType: PropsTypes.string.isRequired,
    onPressCombo: PropsTypes.func.isRequired,
};

export default ComboItem;
