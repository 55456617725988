import { createSlice } from '@reduxjs/toolkit';

export const PUSHING_SCREEN = 'PUSHING_SCREEN';

const initialState = {
  type: '',
  status: '',
};

const Types = {
  PUSHING_SCREEN,
};

const pushingScreen = createSlice({
  name: 'pushingScreen',
  initialState,
  reducers: {
    setPushingScreen: (state, action) => {
      if (!Object.values(Types).includes(action.type)) {
        return state;
      }
      state.type = PUSHING_SCREEN;
      state.status = action.payload;
    },
  },
});

const { actions, reducer } = pushingScreen;

export const { setPushingScreen } = actions;

export default reducer;
