class CategoryDetailParser {
    static categoriesfromJsonLib(categoriesLib) {
      const categories = [];
      for (let i = 0; i < categoriesLib.length; ++i) {
        categories.push({
          ...categoriesLib[i],
          title: categoriesLib[i].nome || categoriesLib[i].descricao,
          icon: categoriesLib[i].icon,
        });
      }
      return categories;
    }
  }
  
  export default CategoryDetailParser;
  