/* @flow */

import React, { Component } from 'react';
import {View, Image, ActivityIndicator} from 'react-native';
import { FlashList } from '@shopify/flash-list';

import ActivityListItem, { getIcon } from './components/ActivityListItem';
import Spinner from 'react-native-loading-spinner-overlay';
import EmptyView from '../../components/EmptyView';
import Images from '@assets/Images';
import { FloatingAction } from 'react-native-floating-action';
import Icon from 'react-native-vector-icons/AntDesign';
import { style, color } from '../../common';
import translate from '../../locales';
import { Search } from '../../components';
import BottomAlert from '../../components/BottomAlert';
import ActivityModulesPlaceholder from './components/ActivityModulesPlaceholder';
import CustomAlertModal from '~/components/CustomAlertModal';
import { TextField } from 'react-native-material-textfield';

import PropTypes from 'prop-types';
import ModalOverlay from "~/components/Web/ModalOverLay";

class Activities extends Component {
  constructor(props) {
    super(props);
    this.currentNewActivity = null;
    this.state = {
      floatOpened: false,
      newActivityModal: false,
      name: undefined,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.showBottomAlert === this.props.showBottomAlert) {
      return;
    }
    if (nextProps.showBottomAlert) {
      this.bottomAlert.open();
    }
  }

  onPressItem = id => {
    const { newActivitiesData = [] } = this.props;

    const newActivity = newActivitiesData.find(x => x.pkey === id);
    this.currentNewActivity = newActivity;
    this.setState({
      name: newActivity.name,
      floatOpened: false,
      newActivityModal: true,
    });
  };

  onChangeActivityName = value => {
    if (this.currentNewActivity) {
      this.currentNewActivity = {
        ...this.currentNewActivity,
        nome: value,
      };
      this.setState({
        name: value,
      });
    }
  };

  onCloseNewActivityModal = () => {
    this.setState({
      newActivityModal: false,
    });
    this.currentNewActivity = null;
  };

  onPressConfirmActivityModal = () => {
    if (this.currentNewActivity) {
      this.props.onPressNewActivityItem?.(this.currentNewActivity);
    }
    this.onCloseNewActivityModal();
  };

  keyExtractor = item => item.id;

  renderItem = ({ item }) => {
    const { answer, isSectionAnswered } = item;

    return (
        <ActivityListItem
            item={item}
            required={item.required}
            finished={!!answer || isSectionAnswered}
            onPress={this.props.onPressActivity}
        />
    );
  };

  render() {
    const {
      loading,
      data,
      showSearch,
      onChangeTextSearch,
      onHideModal,
      alertMessage,
      handleSendRequest,
      newActivitiesData = [],
      canAnswer,
    } = this.props;

    if (loading && data.length === 0) {
      return <ActivityModulesPlaceholder />;
    }

    const newActivitiesOptions = newActivitiesData.map(activity => ({
      name: activity.pkey,
      text: activity.nome,
      color: color.greenLightest,
      icon: (
          <Image
              style={{
                tintColor: color.grayDarkest,
              }}
              source={getIcon(activity.tipo)}
          />
      ),
    }));

    return (
        <View style={style.container}>
          <ModalOverlay visible={loading} closeOnTouchOutside />
          <ActivityIndicator
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                zIndex: 9999,
              }}
              animating={loading}
              size="large"
          />
          {showSearch && <Search onChangeText={onChangeTextSearch} />}

          {data && data.length > 0 ? (
              <FlashList
                  data={data}
                  keyExtractor={this.keyExtractor}
                  renderItem={this.renderItem}
                  estimatedItemSize={200}
              />
          ) : (
              <EmptyView
                  icon={Images.iconBulletedList}
                  message={`${translate('unavailableActivities')} :(`}
              />
          )}
          {newActivitiesOptions?.length > 0 && canAnswer ? (
              <FloatingAction
                  visible
                  showBackground
                  floatingIcon={
                    <Icon
                        size={25}
                        name={this.state.floatOpened ? 'close' : 'plus'}
                    />
                  }
                  actions={newActivitiesOptions}
                  onPressItem={this.onPressItem}
                  color={color.stripGreen}
                  overlayColor="rgba(0, 0, 0, 0.7)"
                  actionsPaddingTopBottom={0}
                  distanceToEdge={{ horizontal: 16, vertical: 32 }}
                  onClose={() => {
                    this.setState({ floatOpened: false });
                  }}
                  onOpen={() => {
                    this.setState({ floatOpened: true });
                  }}
              />
          ) : null}

          <View>
            <BottomAlert
                setRef={ref => {
                  this.bottomAlert = ref;
                }}
                title={`${translate('atention')}`}
                description={`${alertMessage}. \nDeseja enviar uma solicitação?`}
                mainButtonTitle={`${translate('send')}`}
                secondaryButtonTitle={`${translate('cancel')}`}
                hasSecondaryButton
                onPressSecondaryButton={() => this.bottomAlert.close()}
                onPressMainButton={() => {
                  handleSendRequest();
                  this.bottomAlert.close();
                }}
                onClose={onHideModal}
            />
          </View>
          <CustomAlertModal
              title={translate('createActivityTitle')}
              description={translate('createActivityDescription')}
              cancelButtonText={translate('no')}
              confirmButtonText={translate('createActivity')}
              isVisible={this.state.newActivityModal}
              onPressConfirm={this.onPressConfirmActivityModal}
              onPressCancel={this.onCloseNewActivityModal}
              disabledConfirm={!this.state.name}>
            <TextField
                value={this.currentNewActivity?.nome}
                onChangeText={this.onChangeActivityName}
                containerStyle={{ marginVertical: 6 }}
                label={`${translate('activityName')}`}
            />
          </CustomAlertModal>
        </View>
    );
  }
}

Activities.propTypes = {
  onPressActivity: PropTypes.func.isRequired,
  onChangeChoice: PropTypes.func.isRequired,
  onNewItemButtonPressed: PropTypes.func.isRequired,
  onModalCancelPressed: PropTypes.func.isRequired,
  onCreateNewItem: PropTypes.func.isRequired,
  onNewTaskNameChanged: PropTypes.func.isRequired,
  onChangeTextSearch: PropTypes.func.isRequired,
  onHideModal: PropTypes.func.isRequired,
  handleSendRequest: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  newTasksOptions: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  itemSelected: PropTypes.object.isRequired,
  modalNewTaskVisible: PropTypes.bool.isRequired,
  showSearch: PropTypes.bool.isRequired,
  showBottomAlert: PropTypes.bool.isRequired,
  alertMessage: PropTypes.string.isRequired,
  requesting: PropTypes.bool.isRequired,
};

export default Activities;