import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ProductsModule from '~/modules/ProductsModule';

export const PRODUCT_ATTRIBUTES_REQUEST = 'PRODUCT_ATTRIBUTES_REQUEST';
export const PRODUCT_ATTRIBUTES_SUCCESS = 'PRODUCT_ATTRIBUTES_SUCCESS';
export const PRODUCT_ATTRIBUTES_FAILURE = 'PRODUCT_ATTRIBUTES_FAILURE';

const getProducts = payload =>
  new Promise((resolve, reject) => {
    const { codigo, condVenda } = payload;
    ProductsModule.getProductAttributes(
      `${codigo}`,
      JSON.stringify(condVenda),
      resolve,
      reject,
    );
  });

export const productAttributes = createAsyncThunk(
  'productAttributes/fetch',
  async payload => {
    try {
      const filters = await getProducts(payload);
      return filters;
    } catch (error) {
      throw error;
    }
  },
);
const initialState = {
  type: '',
  loading: false,
  erro: false,
  payload: {
    atributos_valores: [],
    combinacoes: [],
  },
};
const productAttributesSlice = createSlice({
  name: 'productAttributes',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(productAttributes.pending, state => {
        state.type = PRODUCT_ATTRIBUTES_REQUEST;
        state.loading = true;
      })
      .addCase(productAttributes.fulfilled, (state, action) => {
        const payload = JSON.parse(action.payload);
        state.type = PRODUCT_ATTRIBUTES_SUCCESS;
        state.loading = false;
        state.payload = payload;
      })
      .addCase(productAttributes.rejected, state => {
        state.type = PRODUCT_ATTRIBUTES_FAILURE;
        state.loading = false;
        state.erro = true;
      });
  },
});
const { reducer } = productAttributesSlice;
export default reducer;
