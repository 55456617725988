export default {
  TEXT: { name: 'STRG' },
  NUMBER: { name: 'NUMERO' },
  INT: { name: 'INTE' },
  CNPJ: { name: 'CNPJ' },
  CPF: { name: 'CPF' },
  ZIP_CODE: { name: 'CEP' },
  MONEY: { name: 'DINHEIRO' },
  PERCENTAGE: { name: 'PERCENTUAL' },
  PHONE: { name: 'TELEFONE' },
  PHONE_EMP: { name: 'TELE' },
  DATE: { name: 'DATE' },
  HOUR: { name: 'HORARIO' },
  DATE_HOUR: { name: 'DATA_HORARIO' },
  LIST: { name: 'LIST' },
  SQL: { name: 'QSQL' },
  COMBO: { name: 'COMB' },
  CHECKLIST: { name: 'CHECKLIST' },
  RESULT: { name: 'RESULTADO' },
  PHOTO: { name: 'IMAG' },
};
