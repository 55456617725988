import AsyncStorage from "@react-native-async-storage/async-storage";
import atalhosConfig from "../../atalhos";

// Variáveis e estados globais
const pressedKeys = new Set(); // Para armazenar as teclas pressionadas
let keyPressTimeout = null;
let currentScreen = null;
let atalhos = null;
let actionsMap = {};

// Define a tela atual e configura os atalhos
export const fnCurrentScreen = async (screen) => {
  currentScreen = screen;
  const configCache = await AsyncStorage.getItem('@CONFIG_LIB-1') || '{}';
  const config = JSON.parse(configCache);
  const configShortcuts = config?.configuracoes?.shortcuts || atalhosConfig;
  atalhos = configShortcuts.screens[currentScreen]?.atalhos || {};
};

// Define o mapeamento de ações
export const setActionsMap = (map) => {
  actionsMap = map;
};

checkPressedIsOnlyNumber = () => {
  const keys = Array.from(pressedKeys); // Converte o Set ou iterável em um array

  // Verifica se todas as teclas pressionadas são numéricas
  return keys.length > 0 && keys.every((key) => !isNaN(key));
}

const getCombinedNumber = (pressedKeys) => {
  const keys = Array.from(pressedKeys); // Converte o Set ou iterável em um array

  // Filtra apenas os valores numéricos
  const numericKeys = keys.filter((key) => !isNaN(key));

  // Se todos os valores são numéricos, combina-os em um único número
  if (numericKeys.length > 0 && numericKeys.every((key) => !isNaN(key))) {
    return parseInt(numericKeys.join(""), 10); // Combina e converte para número
  }

  // Caso contrário, retorna null ou algum valor indicando inválido
  return null;
};

// Função principal para gerenciar o pressionamento de teclas
export const handleKeyDown = (event) => {
  pressedKeys.add(event.key);

  const isNumber = checkPressedIsOnlyNumber();
  if (isNumber && currentScreen === 'Clients') {
    setTimeout(() => {
      const indexClient = getCombinedNumber(pressedKeys);
      const normalizedIndex = indexClient - 1;
      actionsMap['getClient'](normalizedIndex);
      pressedKeys.clear();
    }, 1000);
    return;
  }
  
  // Executa a ação correspondente, se o atalho for válido
  if (atalhos && typeof atalhos === 'object') {
    if (processShortcuts(event)) {
      event.preventDefault(); // Previne o comportamento padrão se o atalho foi reconhecido
    }
  }

  // Reinicia o temporizador de captura de teclas
  resetKeyPressTimeout();
};

// Processa os atalhos e executa a ação correspondente
const processShortcuts = (event) => {
  for (const [action, { keys }] of Object.entries(atalhos)) {
    if (checkKeyCombination(keys)) {
      executeAction(action); // Executa a ação associada
      return true; // Retorna true se o atalho foi encontrado
    }
  }
  return false; // Retorna false se nenhum atalho foi encontrado
};

// Verifica se a combinação de teclas é válida
const checkKeyCombination = (combination) => {
  const keys = combination.split('+').map(key => key.trim());
  return keys.every(key => pressedKeys.has(key));
};

// Executa a função correspondente à ação
const executeAction = (action) => {
  const actionFunction = actionsMap[action];
  if (actionFunction) {
    actionFunction();
  } else {
    console.warn(`Ação '${action}' não encontrada`);
  }
};

// Reinicia o temporizador para limpar as teclas pressionadas
const resetKeyPressTimeout = () => {
  if (keyPressTimeout) {
    clearTimeout(keyPressTimeout);
  }
  keyPressTimeout = setTimeout(() => pressedKeys.clear(), 300);
};
