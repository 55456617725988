import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { Image } from 'react-native';
import Images from '@assets/Images';
import PropTypes from 'prop-types';

import styles from './styles';

class ClientItem extends Component {
    render() {
        const { client, selectedClient, renderStatus, renderColorsStatus } = this.props;
        const { cor1, cor2, cor3 } = client;
        const imgSrc = Images.iconLock;
        return (
            <View style={ styles.mainView }>
                {(client.status_pedido && client.status_pedido !== 'LIBERADO') ? 
                        <Image source={imgSrc} style={[styles.icon,]}/> : null}
                <View style={{ alignItems: 'center' }}>
                    {client ?
                        <View style={{ flexDirection: 'row' }}>
                            {cor1 ? (
                                <View style={[styles.statusPosivite, { backgroundColor: cor1.toLowerCase() }]} />
                            ) : (
                                    <View style={styles.statusPosivite} />
                                )}
                            {cor2 ? (
                                <View style={[styles.statusPosivite, { backgroundColor: cor2.toLowerCase() }]} />
                                ) : (
                                    <View style={styles.statusPosivite} />
                            )}
                            {cor3 ? (
                                <View style={[styles.statusPosivite, { backgroundColor: cor3.toLowerCase() }]} />
                                ) : (
                                    <View style={styles.statusPosivite} />
                            )}
                        </View> : null 
                    }
                </View>
                <View style={ styles.clientView }>
                    <Text style={[{ fontSize: 16 },
                        client.codigo === selectedClient?.codigo ? { fontWeight: 'bold' } : {}]}
                        >{`${client.codigo}`} - {`${client.nome}`}
                    </Text>
                </View>
            </View>
        );
    }
};

ClientItem.propTypes = {
    client: PropTypes.object.isRequired,
    selectedClient: PropTypes.object.isRequired,
    renderStatus: PropTypes.func.isRequired,
    renderColorsStatus: PropTypes.func.isRequired
};

export default ClientItem;
