import colors from './color';
import sizes from './sizes';

const defaultShadow = {
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 3,
  },
  shadowOpacity: 0.27,
  shadowRadius: 4.65,
  elevation: 6,
  margin: 8,
};

export {
  colors,
  sizes,
  defaultShadow,
};
