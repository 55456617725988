
const ServicesApiModule = (nameModule) => {
  const methodByAlias = {};

  const isServiceRunning = (serviceClass) => {
    return true;
  };

  const startSyncService = (intervalTimeSync) => {
  };

  const stopSyncService = () => {
  };

  const syncServiceRunnig = async () => {
    // eslint-disable-next-line no-new
    return true;
  };

  const syncUpdateProgresService = (textProgress, valueProgress) => {
  };

  const syncUpdateStatusService = (status) => {
    return status;
  };
    
  return {
    getName: () => nameModule,
    methodByAlias,
    isServiceRunning,
    startSyncService,
    stopSyncService,
    syncServiceRunnig,
    syncUpdateProgresService,
    syncUpdateStatusService,
  };
};
  
export default ServicesApiModule;
  