import React, { Component } from 'react';
import { Alert, BackHandler, Image, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Navigation } from 'react-native-navigation';
import NavigationHelper from '~/screens/NavigationHelper';
import ProductTabs from './ProductTabs';

import { updateItemFetch } from '../../../store/ordersFetch/updateItemSlice';
import {
  SCREEN_OBSERVATIONS,
  SCREEN_SALES_CONDITIONS_CHILD,
  SCREEN_PRODUCT_DETAIL,
  SCREEN_COMPOUND_PRODUCT,
} from '../../screens';
import GeneralModule from '../../../modules/GeneralModule';
import {
  emp2long,
  getListWithPagination,
} from '../../../utils/FastSellerUtils';
import Images from '@assets/Images';
import {
  filterByTextFields,
  getFieldsSearch,
} from '../../../utils/sorterFilter';

import PropTypes from 'prop-types';
import { createNotifyInfo } from '~/components/Web/ToastNotify';

class ProductTabsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      tabs: [],
      productsTabs: [],
      configCached: {},
      showSearch: false,
    };
  }

  componentDidMount() {
    this.loadProductsTab();
    this.props.navigation.setOptions({
      headerLeft: () => (
        <TouchableOpacity onPress={() => {
          this.props.navigation.pop();
        }}
        >
          <Image
            source={Images.iconBack}
            style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
          />
        </TouchableOpacity>
      ),
      headerRight: () => (
        <TouchableOpacity onPress={() => {
          this.setState({ showSearch: !this.state.showSearch, searchText: '' });
        }}
        >
          <Image
            source={Images.iconSearch}
            style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 10 }}
          />
        </TouchableOpacity>
      ),
    });
    this.getConfigCached();
  }
  componentDidDisappear() {
    this.setState({ tabs: [] });
  }

  componentDidAppear() {
    this.pushingScreen = false;
    this.loadProductsTab();
  }

  navigationButtonPressed = event => {
    switch (event.buttonId) {
      case 'search':
        this.setState({ showSearch: !this.state.showSearch });
        break;
      default:
        break;
    }
  };

  handleBackPress = () => {
    NavigationHelper.pop(this.props.componentId);
    return true;
  };

  onChangeText = searchText => {
    const productsTabs = this.productsTabsRef.map(productTab => {
      let { data } = productTab;

      const fields = getFieldsSearch(data);
      data = filterByTextFields(data, fields, searchText);

      return {
        ...productTab,
        data,
      };
    });

    this.setState({ productsTabs });
  };

  onPressObservations = product => {
    this.props.navigation.navigate(SCREEN_OBSERVATIONS.name, {
      product,
    });
  };

  onPressConditionsSalesChild = product => {
    this.props.navigation.navigate(SCREEN_SALES_CONDITIONS_CHILD.name, {
      product,
    });
  };

  onProductClick = item => {
    if (this.pushingScreen) {
      return;
    }
    this.pushingScreen = true;

    const screen =
      item.tipo === 'produto'
        ? SCREEN_PRODUCT_DETAIL.name
        : SCREEN_COMPOUND_PRODUCT.name;

    this.props.navigation.navigate(screen, {
      addItem: this.addItem,
      product: item,
      pageDefault: 0,
    });
  };

  getConfigCached = async () => {
    const value = await AsyncStorage.getItem('@objPayload');
    if (value) {
      this.setState({ configCached: JSON.parse(value) });
    }
  };

  pushingScreen = false;
  productsTabsRef = [];

  addItem = async (item, quantity) => {
    const { order, currentProduct } = this.props;
    const { codcliente } = order.payload.condvenda;
    let itemCopy = Object.assign({}, item);
    itemCopy.quantidade = quantity;

    if (currentProduct.payload && currentProduct.payload.chave === item.chave) {
      const {
        preco,
        precobase,
        desconto,
        acrescimo,
        estoque,
      } = currentProduct.payload;
      itemCopy = {
        ...itemCopy,
        preco,
        precobase,
        desconto,
        acrescimo,
        estoque,
      };
      const payloadAddItem = {
        clientCode: codcliente,
        itemKey: itemCopy.chave.toString(),
        item: itemCopy,
      };
      this.props.dispatch(updateItemFetch(payloadAddItem));
    } else {
      try {
        const payloadAddItem = {
          clientCode: codcliente,
          itemKey: itemCopy.chave.toString(),
          item: itemCopy,
        };
        this.props.dispatch(updateItemFetch(payloadAddItem));
      } catch (error) {
        const messages = JSON.parse(error.message);
        console.tron.log('ProductTabsContainer addItem error', messages);
      }
    }
  };

  async loadProductsTab() {
    this.setState({ loading: true });
    const groupId = emp2long('PDIT');
    const params = {};
    const tamMemory = _.get(this.props.configLib, 'tam_memoria_paginacao');

    try {
      const productsTabs = await getListWithPagination(
        GeneralModule.getCustomListTabs,
        tamMemory,
        `${groupId}`,
        null,
        JSON.stringify(params),
        true,
        '1',
      );

      this.parseProductsTab(productsTabs);
    } catch (errors) {
      this.setState({ loading: false });
      const messages = JSON.parse(errors);
      const alertMsg = _.get(messages[0], 'mensagem');
      const alertCod = _.get(messages[0], 'codigo');
      if (alertMsg && alertCod !== 'list_data_less') {
        createNotifyInfo(alertMsg);
      }
    }
  }

  parseProductsTab(productsTabsData) {
    const tabs = [];
    const productsTabs = productsTabsData.map((productTab, id) => {
      const { title } = productTab;
      let { data } = productTab;

      tabs.push(title);
      data = this.parseProduct(data);

      return {
        ...productTab,
        id,
        data,
      };
    });

    this.productsTabsRef = productsTabs;
    this.setState({ tabs, productsTabs, loading: false });
  }

  parseProduct(data) {
    const prods = {};
    this.props.products.payload.forEach(item => {
      if (item.tipo === 'produto') {
        prods[item.chave] = item;
      }
    });

    return data.map((item, index) => {
      const { pkey, html } = item;
      const [type, key] = pkey.split('|');

      // Retornar o produto
      if (type && type === 'P' && key) {
        const product = prods[key];
        if (product) {
          return {
            ...product,
            index,
            html,
          };
        }
      }

      // retorna outros itens que não são produtos como campanha, combo, opcao e etc
      return {
        ...item,
        index,
      };
    });
  }

  render() {
    const {
      productsTabs,
      tabs,
      configCached,
      showSearch,
      loading,
    } = this.state;
    const { configLib, order, currentProduct } = this.props;

    const showProductAsHTML = _.get(
      configLib,
      'listagem_campos_visibilidade.product.show_html',
      false,
    );

    return (
      <ProductTabs
        loading={loading}
        tabs={tabs}
        productsTabs={productsTabs}
        config={configCached}
        configLib={configLib}
        cart={order || {}}
        showSearch={showSearch}
        itemUpdate={currentProduct.payload}
        onPressObservations={this.onPressObservations}
        onPressConditionsSalesChild={this.onPressConditionsSalesChild}
        addItem={this.addItem}
        onProductClick={this.onProductClick}
        onChangeTextSearch={this.onChangeText}
        showProductAsHTML={showProductAsHTML}
      />
    );
  }
}

ProductTabsContainer.propTypes = {
    configLib: PropTypes.object.isRequired,
    products: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,
    currentProduct: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    configLib: state.configLibFetch.payload,
    order: state.orderFetch,
    currentProduct: state.productCurrent,
    products: state.productsFetch,
  };
}

export default connect(mapStateToProps)(ProductTabsContainer);
