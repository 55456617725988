import _ from 'lodash';

import { Alert } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Snackbar from 'react-native-snackbar';

import getQueryResult from '../../services/resources/libFastSeller/genericQuery';
import translate from '../../locales';
import {createNotifyError, createNotifyInfo} from '~/components/Web/ToastNotify';

class HandlerActivityValidate {
  constructor() {
    this.nested = [];
    /* eslint-disable no-underscore-dangle */
    this._openTask = { canOpenTask: true };
    this.keepGoing = { canKeepGoing: true, message: '' };
    this.propsAlert = false;
  }

  get openTask() {
    return { ...this._openTask };
  }

  set openTask(isCanOpenTask) {
    this._openTask = { canOpenTask: isCanOpenTask };
  }

  getTaskById = (taskId, listActivities) => {
    const activities = _.get(listActivities, 'payload', []);
    return _.find(activities, { id: taskId }) || false;
  };

  getTaskAndSetup(item, listActivities) {
    const { id: taskId } = item;
    const activities = _.get(listActivities, 'payload', []);
    const task = taskId ? _.find(activities, { id: taskId }) : {};
    let setup;
    let message = '';
    try {
      setup = task && task.setup ? JSON.parse(task.setup) : {};
    } catch (error) {
      console.warn('getTaskAndSetup error:', error);
      setup = {};
      message = 'UnableToOpenActivity';
    }
    return { task, setup, message };
  }

  infoTimer = async () => {
    try {
      const infoTimer = await AsyncStorage.getItem('info_timer');
      return JSON.parse(infoTimer) || {};
    } catch (err) {
      /* eslint-disable no-console */
      console.log('infoTimer error:', err);
    }
    return null;
  };

  isBlocked(blocked, message = 'releaseActivity') {
    if (blocked) {
      createNotifyError(`${translate(message)}`);
      this.openTask = false;
    }
  }

  isSequence(sequence) {
    // TODO: Tratar itens para serem respondidos na sequêcia
    if (sequence !== 1) {
      createNotifyInfo(`${translate('activitiesSequenceRequired')}`);
      this.openTask = false;
    }
  }

  async isCanKeepGoing(validation, taskId, originRequest, event) {
    const { type, source, allow_request: allowRequest, origin } = validation;

    if (origin === originRequest) {
      if (type === 't_vars' && source) {
        const pkeyClient = event && event.pkey;
        const params = {
          pk_cliente: pkeyClient,
          task_id: taskId,
        };

        try {
          const data =
            (await getQueryResult(source, JSON.stringify(params))) || '[]';
          const dataParsed = JSON.parse(data);
          const validationResult = _.get(dataParsed, ['0'], {});
          const { result = '', message: resultMessage } = validationResult;
          const canKeepGoing = result.localeCompare('0') === 0;
          const message = resultMessage;
          this.keepGoing = { canKeepGoing, message, allowRequest };
          this.openTask = canKeepGoing || false;
        } catch (err) {
          createNotifyError(
            'Erro ao validar abertura de atividade. Validação obrigatória. Contate o suporte.',
          );
        }
      }
    }
  }

  async isToAccessOtherActivity(item) {
    const { id, name, isRunning, setup } = await this.infoTimer();

    const { blocking } = setup || {};

    const activityInProgress = ![
      !!isRunning,
      !!blocking,
      !!(item.id !== id),
    ].includes(false);
    if (activityInProgress) {
      alert(
        `${translate('atention')}, ${name}, ${translate(
          'errorActivityInProgress',
        )}`,
      );
      this.openTask = false;
    }
  }

  checkAnsweredTaskId = (taskDependency, listActivities) => {
    const task = this.getTaskById(taskDependency, listActivities);
    if (!task) {
      this.propsAlert = {
        title: `${translate('atention')}`,
        message: `${translate('cantFindTaskDependency')} ${translate(
          'contactSupport',
        )}.`,
      };
      this.openTask = false;
    } else if (task && !task.answer) {
      this.propsAlert = {
        title: `${translate('atention')}`,
        message: `${translate('itsNecessaryToAnswer')} ${task.name} ${translate(
          'before',
        )}`,
        actionBtns: [
          {
            text: `${translate('cancel')}`,
          },
          {
            text: `${translate('openActivity')}`,
            onPressRef: 'showTaskScreen',
            args: [task],
          },
        ],
      };
      this.openTask = false;
    }
  };

  checkAnsweredTasks = (taskDependency, listActivities) => {
    const { ids } = taskDependency;

    const taskId =
      _.find(
        ids,
        tskId => !_.isObject(this.getTaskById(tskId, listActivities)),
      ) || true;

    if (typeof taskId === 'number') {
      this.propsAlert = {
        title: `${translate('atention')}`,
        message: `${translate('cantFindTaskDependency')} ${translate(
          'contactSupport',
        )}.`,
      };
      this.openTask = false;
      return;
    }

    const { type } = taskDependency;
    const tasks = _.map(ids, idTask =>
      this.getTaskById(idTask, listActivities),
    );

    if (type === 'OR') {
      const anyTaskAnswered = _.some(tasks, task => Boolean(task.answer));
      if (!anyTaskAnswered) {
        const tasksName = _.map(tasks, task => task.name);
        this.propsAlert = {
          title: `${translate('atention')}`,
          message: `${translate('itsNecessaryToAnswer')} "${tasksName.join(
            `, ${translate('or')} `,
          )}" ${translate('before')}`,
          actionBtns: [
            {
              text: `${translate('cancel')}`,
            },
            {
              text: `${translate('openActivity')}`,
              onPressRef: 'showTaskScreen',
              args: [_.first(tasks)],
            },
          ],
        };
        this.openTask = false;
      }
    } else if (type === 'AND') {
      const tasksNotAnswered = _.filter(tasks, task => !task.answer);
      if (tasksNotAnswered.length) {
        const tasksName = _.map(tasksNotAnswered, task => task.name);
        this.propsAlert = {
          title: `${translate('atention')}`,
          message: `${translate('itsNecessaryToAnswer')} "${tasksName.join(
            `, ${translate('and')} `,
          )}" ${translate('before')}`,
          actionBtns: [
            {
              text: `${translate('cancel')}`,
            },
            {
              text: `${translate('openActivity')}`,
              onPressRef: 'showTaskScreen',
              args: [_.first(tasksNotAnswered)],
            },
          ],
        };
        this.openTask = false;
      }
    }
  };

  isTaskAlreadyAnswered = (item, canEdit) => {
    if (!canEdit) {
      const answered = _.get(item, 'answer', false);
      if (answered) {
        createNotifyInfo(`${translate('taskAlreadyAnswered')}`);
        this.openTask = false;
      }
    }
  };

  async handle(item, listActivities, originRequest, event) {
    const { blocked, sequence, id: taskId } = item;
    const { setup, message } = this.getTaskAndSetup(item, listActivities);

    const {
      validation = {},
      sequence_required: sequenceRequired = false,
      task_dependency: taskDependency,
      can_edit: canEdit = true,
    } = setup;

    await this.isCanKeepGoing(validation, taskId, originRequest, event);
    this.isBlocked(blocked, message || 'releaseActivity');
    if (blocked) {
      return {
        loading: false,
        ...this.keepGoing,
        ...this.openTask,
        propsAlert: this.propsAlert,
      };
    }
    if (sequenceRequired) {
      this.isSequence(sequence);
    }
    await this.isToAccessOtherActivity(item);
    if (taskDependency) {
      const verifyTaskDependency = _.isObject(taskDependency)
        ? this.checkAnsweredTasks
        : this.checkAnsweredTaskId;

      verifyTaskDependency(taskDependency, listActivities);
    }

    this.isTaskAlreadyAnswered(item, canEdit);

    const { canOpenTask } = this.openTask;
    if (canOpenTask) {
      await this.next(item, listActivities, originRequest, event);
    }

    return {
      loading: false,
      ...this.keepGoing,
      ...this.openTask,
      propsAlert: this.propsAlert,
    };
  }

  async next(item, listActivities, originRequest, event) {
    if (this.nested.length) {
      let isCanOpenTask = null;

      for (const handler of this.nested) {
        /* eslint-disable no-await-in-loop */
        const canOpenTask = await handler.handle(
          item,
          listActivities,
          originRequest,
          event,
        );
        isCanOpenTask = canOpenTask;
      }

      this.openTask = isCanOpenTask;
    }
  }
}

export default HandlerActivityValidate;
