import React, { Component } from 'react';
import { Image, TouchableOpacity } from 'react-native';
import _ from 'lodash';
import { connect } from 'react-redux';

import Catalog from './Catolog';

import Images from '@assets/Images';
import TextUtils from '../../utils/TextUtils';
import listCatolog from '../../services/resources/libFastSeller/catalog';
import NavigationHelper from '../NavigationHelper';
import getImageProds from '~/utils/GetImageProduct';
import { isShowMultiTabs } from '~/utils/GlobalUtils';

import PropTypes from 'prop-types';
import { createNotifyError } from '~/components/Web/ToastNotify';

class CatalogContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      catalog: [],
      showSearch: false,
      searchText: '',
      showMultiTabs: false,
    };
    this.showProductAsHTML = false;
  }

  // Evitar que seja exibido na tela a alteracao do 'padrao' para o template
  UNSAFE_componentWillMount() {
    this.showProductAsHTML = this.isShowProductAsHTML();
    const showMultiTabs = isShowMultiTabs(
      this.props.configLib,
      'catalog',
      'detalhes_produto',
    );

    this.setState({ showMultiTabs });
  }

  async componentDidMount() {
    this.setLeftButtons();
    this.setRightButtons();
    try {
      const tamMemory = _.get(this.props.configLib, 'tam_memoria_paginacao');
      let catalog = await listCatolog(tamMemory);
      catalog = JSON.parse(catalog).data;
      const prodsWithImage = await getImageProds(catalog);
      catalog = prodsWithImage;
      this.setState({ catalog });
    } catch (errors) {
      if (typeof errors === 'object') {
        return;
      }
      const jssonArray = JSON.parse(errors);
      createNotifyError(jssonArray[0].mensagem);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.configLib) {
      this.showProductAsHTML = this.isShowProductAsHTML();
    }
  }

  navigationButtonPressed(event) {
    switch (event.buttonId) {
      case 'search':
        this.setState({ showSearch: !this.state.showSearch, searchText: '' });
        break;
      default:
        break;
    }
  }

  getFilteredData() {
    const { catalog, searchText } = this.state;
    const search = TextUtils.slugfy(searchText);

    return catalog.filter(cat => {
      const res =
        (cat.codproduto && TextUtils.slugfy(cat.codproduto).includes(search)) ||
        (cat.descricao && TextUtils.slugfy(cat.descricao).includes(search));

      return res;
    });
  }

  isShowProductAsHTML = () =>
    this.props.configLib
      ? _.get(
          this.props.configLib,
          'listagem_campos_visibilidade.catalog.show_html',
          false,
        )
      : false;

  handleBackPress = () => {
    NavigationHelper.pop(this.props.componentId);
    return true;
  };

  handleChangeText = text => {
    clearTimeout(this);
    setTimeout(() => {
      this.setState({ searchText: text });
    }, 400);
  };

  setLeftButtons() {
    this.props?.navigation?.setOptions({
        headerLeft: () => (
            <TouchableOpacity onPress={() => {
              this.props.navigation.pop();
            }}
            >
              <Image
                source={Images.iconBack}
                style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
              />
            </TouchableOpacity>
          ),
    });
  }

  setRightButtons = () => {
    this.props?.navigation?.setOptions({
        headerRight: () => (
            <TouchableOpacity onPress={() => {
              this.setState({ showSearch: !this.state.showSearch, searchText: '' });
            }}
            >
              <Image
                source={Images.iconSearch}
                style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 10 }}
              />
            </TouchableOpacity>
          ),
    });
  }

  render() {
    const { showSearch, showMultiTabs } = this.state;
    const { config } = this.props;
    const { payload = {} } = config;
    const {
      url_stocker: urlStocker = '',
      token_stocker: tokenStocker = '',
    } = payload;

    return (
      <Catalog
        catalog={this.getFilteredData()}
        config={{ url_stocker: urlStocker, token_stocker: tokenStocker }}
        showSearch={showSearch}
        onChangeText={this.handleChangeText}
        showProductAsHTML={this.showProductAsHTML}
        showMultiTabs={showMultiTabs}
        navigator={this.props.navigator}
        configLib={this.props.configLib}
        componentId={this.props.componentId}
        navigation={this.props.navigation}
      />
    );
  }
}

CatalogContainer.propTypes = {
    navigator: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    configLib: PropTypes.object.isRequired,
    componentId: PropTypes.string.isRequired,
    navigation: PropTypes.object
};

function mapStateToProps(state) {
  return {
    config: state.configFetch,
    configLib: state.configLibFetch.payload,
  };
}

export default connect(mapStateToProps)(CatalogContainer);
