import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import STAGING from '../../../config/config';
import CacheAccount from "~/controllers/Accounts/Cache/CacheAccount";
import { createNotifyError } from '~/components/Web/ToastNotify';
import translate from '~/locales';
const URL_UPLOAD_SYNC = `https://process-server${STAGING}.polisuite.com.br/v1/sync/user/`;
const URL_UPLOAD_PHOTOS_SYNC = `https://process-server${STAGING}.polisuite.com.br/v1/forms/upload/`;
const ERROR_RESERVE_IDS = 'Erro na reserva de ids';
const ERROR_SYN_UPLOAD =
    'Falha ao enviar dados. Verifique sua conexão com a internet e sincronize os dados novamente.';

const canSyncPhotos = true;

const syncUploadPhotos = async (data) => {
    const dataBucket = await AsyncStorage.getItem('@CONFIG_LIB-1');
    let bucketConfig = dataBucket ? JSON.parse(dataBucket) : null;

    if (!bucketConfig) {
        createNotifyError(ERROR_SYN_UPLOAD);
        return;
    }

    const userData = await new CacheAccount().getDataAccount() || null;
    const codmaq = userData?.user?.cod_maq || null;
    let headers = {};

    if (bucketConfig) {
        headers = {
            Authorization: `JWT ${userData.token}`,
            credential_access_key: bucketConfig?.bucket_config?.params.credential_access_key,
            credential_secret_key: bucketConfig?.bucket_config?.params.credential_secret_key,
        };
    }

    const config = {
        headers: {
            ...headers,
            'Content-Type': 'multipart/form-data',
        },
    };

    const url = bucketConfig
        ? `${bucketConfig.bucket_config.params.url}/${codmaq}`
        : URL_UPLOAD_PHOTOS_SYNC;  

    const errors = [];

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `JWT ${userData.token}`);
    myHeaders.append("credential_access_key", bucketConfig?.bucket_config?.params.credential_access_key);
    myHeaders.append("credential_secret_key", bucketConfig?.bucket_config?.params.credential_secret_key);
    

    if (data?.payload?.photo.length > 0) {
        await Promise.all(
            data?.payload?.photo.map(async photoToSync => {

            const formdata = new FormData();

            formdata.append("file", photoToSync.uri, photoToSync.fileName);
            formdata.append("name", photoToSync.fileName);
            formdata.append("type", photoToSync.type);
            formdata.append("uri", photoToSync.filePath);

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow"
            };

            fetch(url, requestOptions)
            .then((response) => response.text())
            .then((result) => console.log('RESULT SEND PHOTO', result))
            .catch((error) => console.error('ERROR SEND PHOTO', error));
            }),
          );
    }

    if (errors.length > 0) {
        createNotifyError(translate('notCompleteSyncPhotos'));
        return;
      }
}

export default syncUploadPhotos;