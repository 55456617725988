/* @flow */

import React, { Component } from 'react';
import style from '~/common/style';
import Images from '@assets/Images';
import Touchable from 'react-native-platform-touchable';
import { Dimensions, Image, Linking, StyleSheet, Text, View } from 'react-native';
import * as config from '../../../config/config';
import { color } from '../../../common';
import IconText from '../../../../src/components/IconText';
import { fonts } from '../../../utils/Fonts';
import openMaps from '../../../common/openMaps';
import MoreInfo from '../../../../components_base/src/components/MoreInfo/MoreInfo';
import PopupMenu from '../../../components/PopupMenu';
import translate from '../../../locales';
import {
  getColorMessageRouteSolicitation,
  getLabelRouteSolicitation,
} from '../../../screens/Notifications/typeRouteSolicitationConst';
import _ from 'lodash';
import CacheDataController from '../../../utils/CacheDataController';
import HTML from 'react-native-render-html';
import { adjustHtml } from '~/utils/GlobalUtils';

import PropTypes from 'prop-types';
import { createNotifyError } from '~/components/Web/ToastNotify';

const badgeContainer = {
  width: 22,
  height: 22,
  borderRadius: 12,
  justifyContent: 'center',
  position: 'absolute',
  bottom: 0,
  right: 5,
  alignItems: 'center',
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    flex: 1,
    flexDirection: 'row',
    borderBottomWidth: 1,

    borderBottomColor: color.grayContainer,
  },
  popupMenu: {
    position: 'absolute',
    right: -10,
    top: -12,
    bottom: 0,
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingVertical: 12,
    paddingHorizontal: 6,
  },
  card: {
    backgroundColor: '#fff',
    padding: 12,
    paddingVertical: 16,
    minHeight: 20,
    flex: 1,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#DADCE0',
    marginTop: 8,
    marginHorizontal: 10,
  },
  sequenceContainer: {
    borderBottomWidth: 4,
    borderBottomColor: '#D9D9D9',
    width: 25,
    marginLeft: 10,
    alignItems: 'center',
  },
  sequenceText: {
    color: color.grayMid,
    fontSize: 16,
    fontFamily: fonts.QuicksandBold,
  },
  sequenceTextSmall: {
    fontSize: 12,
    fontFamily: fonts.QuicksandBold,
    color: color.grayMid,
  },
  code: {
    color: '#4F4F4F',
    fontSize: 14,
    fontFamily: fonts.QuicksandBold,
  },
  name: {
    color: '#4F4F4F',
    fontSize: 16,
    marginRight: 15,
    fontFamily: fonts.QuicksandBold,
  },
  statusPosivite: {
    width: 30,
    height: 10,
    marginRight: 10,
    borderRadius: 10,
  },
  iconBadgeCheck: {
    width: 18,
    height: 18,
    tintColor: '#fff',
  },
  greenBadgeContainer: {
    ...badgeContainer,
    backgroundColor: color.stripGreenDark,
  },
  grayBadgeContainer: {
    ...badgeContainer,
    backgroundColor: color.grayContainerDark,
  },
  cardDesc: {
    width: '100%',
    maxWidth: 400,
  }
});

const LIBERADO = 'LIBERADO';

const MORE_INFOS = `${translate('moreInfo')}`;
const OPEN_ROUTE = `${translate('goTo')}`;
const OPEN_REGISTER_AGAIN = `${translate('registerAgain')}`;
const CHANGE_CUSTOMER_LOCATION = `${translate('changeCustomerLocation')}`;

const screenWidth = Dimensions.get('window').width;

class ClientsListItem extends Component {
  constructor(props) {
    super(props);

    this.showClientAsHTML = false;

    this.state = {
      actions: [MORE_INFOS, OPEN_ROUTE],
      modalMoreInfosVisible: false,
    };
  }

  UNSAFE_componentWillMount() {
    const showHTML = _.get(this.props, 'showHTML', false);
    this.showClientAsHTML = showHTML || this.isShowClientAsHTML();
  }

  async componentDidMount() {
    const { configLib, solicitations, item } = this.props;
    const { pkey } = item;
    if (configLib) {
      const actions = [...this.state.actions];
      const showRegisterAgain = _.get(
        configLib,
        'listagem_campos_visibilidade.clients.show_fields.register_again',
        false,
      );

      const showUpdateClientLocation = _.get(
        configLib,
        'listagem_campos_visibilidade.clients.show_fields.change_customer_location',
        false,
      );

      if (showRegisterAgain) {
        actions.push(OPEN_REGISTER_AGAIN);
      }
      if (showUpdateClientLocation) {
        actions.push(CHANGE_CUSTOMER_LOCATION);
      }

      this.setState({ actions });
    }

    const solicitationClientCache = await CacheDataController.getData(pkey);
    if (solicitationClientCache) {
      this.setState({ solicitationClient: solicitationClientCache });
    } else {
      const solicitationClientDB = _.find(
        solicitations,
        ({ client_code: clientPkey }) => clientPkey === item.pkey,
      );
      if (solicitationClientDB) {
        this.setState({ solicitationClient: solicitationClientDB });
      }
    }
  }

  /**
   * Razão para o isShowClientAsHTML ser chamado no willMount e no ReceiveProps:
   * - Realizando o teste de atualizar na servidor o show_html, percebi que sem
   * a chamada no ReceiveProps era necessário fechar a aplicação para o resultado
   * da alteração fazer efeito
   * - Quanto adicionei no ReceiveProps essa chamada o comportamento foi o esperado,
   * ou seja, atualizar sem precisar fechar a aplicação.
   *  */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.configLib) {
      const showHTML = _.get(nextProps, 'showHTML', false);
      this.showClientAsHTML = showHTML || this.isShowClientAsHTML();
    }
  }

  onOpenMaps = () => {
    const { item } = this.props;

    if (item?.latitude && item?.longitude) {
      Linking.openURL(`https://www.google.com/maps/search/?api=1&query=${item.latitude},${item.longitude}`);
      return false;
    } else {
      createNotifyError(`${translate('noCustomerLocation')}`);
    }
  };

  onMoreInfosPress() {
    this.setState({ modalMoreInfosVisible: true });
  }

  onPressPopUp = (eventName, index) => {
    if (eventName !== 'itemSelected') {
      return;
    }
    if (this.state.actions[index] === MORE_INFOS) {
      this.onMoreInfosPress();
    } else if (this.state.actions[index] === OPEN_ROUTE) {
      this.onOpenMaps();
    } else if (this.state.actions[index] === OPEN_REGISTER_AGAIN) {
      this.onOpenRegisterAgain();
    } else if (this.state.actions[index] === CHANGE_CUSTOMER_LOCATION) {
      this.props.onOpenChangeCustomerLocation(this.props.item);
    }
  };

  onOpenRegisterAgain() {
    const { item, onOpenRegisterAgain } = this.props;
    onOpenRegisterAgain(item);
  }

  getValueFromKey(key, infoAdicionais) {
    let value;
    for (let i = 0; i < infoAdicionais.length; ++i) {
      if (infoAdicionais[i].chave === key) {
        value = infoAdicionais[i].valor;
        break;
      }
    }
    return value;
  }

  isShowClientAsHTML = () =>
    this.props.configLib
      ? _.get(
          this.props.configLib,
          'listagem_campos_visibilidade.clients.show_html',
          false,
        )
      : false;

  /**
   * o Component HTML não é renderizado se o html vier com as tags de 'tabela'
   * */
  adjustHtml = html => adjustHtml(html);

  renderExtraInfo = () => {
    const { solicitationClient } = this.state;
    if (solicitationClient) {
      const labelColor = getColorMessageRouteSolicitation(
        solicitationClient.status,
      );
      const label = getLabelRouteSolicitation(solicitationClient.status);
      const extraInfoStyle = { tintColor: labelColor };
      return (
        <IconText
          imageSrc={Images.iconInfo}
          imageStyle={{ ...extraInfoStyle }}
          text={label}
          textStyle={{
            ...extraInfoStyle,
            fontSize: 12,
            fontFamily: fonts.QuicksandMedium,
          }}
        />
      );
    }
    return null;
  };

  renderColorsStatus(item) {
    let render = null;

    if (item) {
      const { cor1, cor2, cor3 } = item;
      render = (
        <View style={{ flexDirection: 'row', marginTop: 10 }}>
          {cor1 ? (
            <View
              style={[
                styles.statusPosivite,
                { backgroundColor: cor1.toLowerCase() },
              ]}
            />
          ) : // <View style={styles.statusPosivite} />
          null}
          {cor2 ? (
            <View
              style={[
                styles.statusPosivite,
                { backgroundColor: cor2.toLowerCase() },
              ]}
            />
          ) : // <View style={styles.statusPosivite} />
          null}
          {cor3 ? (
            <View
              style={[
                styles.statusPosivite,
                { backgroundColor: cor3.toLowerCase() },
              ]}
            />
          ) : // <View style={styles.statusPosivite} />
          null}
        </View>
      );
    }

    return render;
  }

  renderTime = (label, time, icon, markerColor) => (
    <View style={{ flexDirection: 'column' }}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Image
          source={icon}
          style={{
            tintColor: markerColor || config.Colors.simpleDark,
            width: 22,
            height: 22,
          }}
        />
        <Text
          style={{
            fontSize: 12,
            fontWeight: '600',
            color: markerColor || config.Colors.simpleDark,
            marginLeft: 2,
          }}>
          {time}
        </Text>
      </View>
      <Text
        style={{ fontSize: 10, fontWeight: '500', color: config.Colors.gray }}>
        {label}
      </Text>
    </View>
  );

  renderStatus = item => {
    let statusPedidoRender = null;
    if (item.status_pedido && item.status_pedido !== LIBERADO) {
      const imgSrc = Images.iconLock;
      const showReason =
        this.getValueFromKey('mostra_motivo', item.info_adicionais) === '1';
      let iconText = `${translate('clientBlocked')}`;
      if (showReason) {
        iconText = this.getValueFromKey('descricao', item.info_adicionais);
      }
      statusPedidoRender = (
        <IconText
          imageSrc={imgSrc}
          imageStyle={{ tintColor: config.Colors.grayRed, marginTop: 5 }}
          text={iconText}
          textStyle={{
            color: config.Colors.grayRed,
            fontFamily: fonts.QuicksandMedium,
            fontSize: 12,
            marginTop: 5,
          }}
        />
      );
    }
    return statusPedidoRender;
  };

  renderClientDescr(item) {
    return this.showClientAsHTML ? (
      <View style={styles.cardDesc}>
        <View>
          {this.props.renderOffRoute ? this.props.renderOffRoute(item) : null}
        </View>
        <View style={{ flexDirection: 'row' }}>
          <HTML
            contentWidth={screenWidth}
            containerStyle={{ width: '100%' }}
            source={{ html: this.adjustHtml(item.html) }}
          />
        </View>
      </View>
    ) : (
      <View>
        <View style={{ flexDirection: 'row' }}>
          {item.codigo ? <Text style={styles.code}>{item.codigo}</Text> : null}
          {this.props.renderOffRoute ? this.props.renderOffRoute(item) : null}
        </View>
        <Text style={styles.name}>
          {item.nome || item.name || item.display_name}
        </Text>
        <IconText
          // imageSrc={Images.iconStore}
          text={item.fantasia || item.trading_name}
          textStyle={{ fontFamily: fonts.QuicksandMedium }}
        />
      </View>
    );
  };
  
  render() {
    const { item, index, showIsProcessDone } = this.props;

    const sequence = item.sequence || index + 1;

    const hasMoreInfo =
      item && ((item.latitude && item.longitude) || item.html);
    const isProcessDone = item && !!item.isProcessesDone;

    return (
      <View>
        <View style={styles.container}>
          <View style={styles.container}>
            <Touchable
              style={styles.card}
              onPress={() => this.props.onPressItem(item)}>
              <View style={style.spaceBetween}>
                <View style={{ width: 50, marginRight: 5 }}>
                  <View style={[styles.sequenceContainer]}>
                    <Text
                      style={[
                        _.size(`${sequence}`) > 2
                          ? styles.sequenceTextSmall
                          : styles.sequenceText,
                      ]}>
                      {sequence}
                    </Text>
                  </View>
                  {/* {this.renderColorsStatus(item)} */}
                </View>
                <View
                  style={{ flex: 1, marginRight: 5, paddingHorizontal: 15 }}>
                  {this.renderClientDescr(item)}
                  {this.renderStatus(item)}
                  {this.props.renderExtraInfo
                    ? this.props.renderExtraInfo(item)
                    : this.renderExtraInfo()}
                  {this.renderColorsStatus(item)}
                  <View
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      justifyContent: 'space-around',
                    }}>
                    {item.attendanceTime
                      ? this.renderTime(
                          `${translate('expectedTime')}`,
                          `${item.attendanceTime}hrs`,
                          Images.iconCalendarClock,
                          item.markerColor,
                        )
                      : null}
                    {item.visitTime
                      ? this.renderTime(
                          `${translate('visitTime')}`,
                          `${item.visitTime}min`,
                          Images.iconClock,
                        )
                      : null}
                    {item.displacementTime
                      ? this.renderTime(
                          `${translate('displacementTime')}`,
                          `${item.displacementTime}min`,
                          Images.iconTruck,
                        )
                      : null}
                  </View>
                </View>
                {!!showIsProcessDone && (
                  <View
                    style={
                      isProcessDone
                        ? styles.greenBadgeContainer
                        : styles.grayBadgeContainer
                    }>
                    <Image
                      source={Images.iconCheck}
                      style={styles.iconBadgeCheck}
                    />
                  </View>
                )}
                {!!hasMoreInfo && (
                  <View style={styles.popupMenu}>
                    <PopupMenu
                      actions={this.state.actions}
                      onPress={this.onPressPopUp}
                    />
                    <View style={{ flex: 1 }} />
                  </View>
                )}
              </View>
            </Touchable>
          </View>
          <MoreInfo
            modalMoreInfosVisible={this.state.modalMoreInfosVisible}
            htmlContent={item.html}
            onModalClose={() => {
              this.setState({ modalMoreInfosVisible: false });
            }}
          />
        </View>
      </View>
    );
  }
}

ClientsListItem.propTypes = {
  configLib: PropTypes.object,
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  showIsProcessDone: PropTypes.bool,
  onPressItem: PropTypes.func.isRequired,
  onOpenRegisterAgain: PropTypes.func,
  onOpenChangeCustomerLocation: PropTypes.func,
  solicitations: PropTypes.array,
  renderOffRoute: PropTypes.func,
  renderExtraInfo: PropTypes.func,
};

export default ClientsListItem;
