import moment from 'moment';
import React, { useMemo, useCallback } from 'react';
import { FlatList, StyleSheet, Text, View } from 'react-native';
import { Money, color } from '~/common';
import { EmptyView } from '~/components';
import Touchable from 'react-native-platform-touchable';
import _ from 'lodash';
import Images from '../../../../assets/Images';
import PropTypes from 'prop-types';
import translate from '~/locales';

const styles = StyleSheet.create({
  valueType_in: {
    color: color.primaryColorLight,
    fontWeight: 'bold',
  },
  valueType_out: {
    color: color.redBadge,
    fontWeight: 'bold',
  },
});

function TimeLine({ data, onPressExpense }) {
  const dataToRender = useMemo(
    () =>
      _.groupBy(_.orderBy(data, ['date'], ['desc']), item =>
        moment(item.date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      ),
    [data],
  );

  const onPressItemExpense = useCallback(
    item => {
      onPressExpense(item);
    },
    [onPressExpense],
  );

  const ItemSeparatorComponent = useCallback(() => {
    return (
      <View
        style={{
          height: 1,
          backgroundColor: '#CCCCCC',
        }}
      />
    );
  }, []);

  const ListEmptyComponent = useCallback(() => {
    return (
      <EmptyView
        icon={Images.iconBoxEmpty}
        message={`${translate('emptyExpenses')}`}
      />
    );
  }, []);

  const renderItem = ({ item }) => {
    return (
      <FlatList
        style={{ width: '100%' }}
        data={dataToRender[item]}
        ListHeaderComponent={getHeader(item)}
        renderItem={renderItemDate}
        ItemSeparatorComponent={ItemSeparatorComponent}
      />
    );
  };

  const renderItemDate = ({ item }) => {
    return (
      <Touchable
        onPress={() => onPressItemExpense(item)}>
        <View
          style={{
            backgroundColor: '#fff',
            padding: 10,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <View
            style={{
              flexDirection: 'column',
              fontWeight: 'bold',
              marginLeft: 5,
            }}>
            <Text style={{ color: '#000' }}>{item?.title}</Text>
            <Text style={{ fontSize: 12 }}>{item?.description}</Text>
          </View>
          <Text style={styles[`valueType_${item?.type}`]}>
            {Money(item?.value)}
          </Text>
        </View>
      </Touchable>
    );
  };

  const getHeader = date => {
    const today = moment().format('DD/MM/YYYY');
    return (
      <View
        style={{
          backgroundColor: color.grayContainerDark,
          paddingHorizontal: 16,
          paddingVertical: 4,
        }}>
        <Text
          style={{
            fontWeight: 'bold',
            fontSize: 14,
            color: '#000',
          }}>
          {today === moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY')
            ? `${translate('today')}`
            : moment(date, 'DD/MM/YYYY').format('LL')}
        </Text>
      </View>
    );
  };

  return (
    <FlatList
      style={{ width: '100%', marginLeft: 0 }}
      data={Object.keys(dataToRender)}
      renderItem={renderItem}
      removeClippedSubviews
      ListEmptyComponent={ListEmptyComponent}
    />
  );
}

TimeLine.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  onPressExpense: PropTypes.func,
};

TimeLine.defaultProps = {
  data: [],
  onPressExpense: () => {},
};

export default TimeLine;
