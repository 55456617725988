import Images from '@assets/Images';

const types = {
  check: {
    type: 'check',
    typeName: 'Check',
    icon: Images.iconCheck,
    screen: 'activities.Check',
    overrideBackPress: true,
  },
  text: {
    type: 'text',
    typeName: 'Texto Livre',
    icon: Images.iconPencil,
    screen: 'activities.FreeText',
    overrideBackPress: true,
  },
  location: {
    type: 'location',
    typeName: 'Localização',
    icon: Images.iconMapMarker,
    screen: 'activities.Location',
    overrideBackPress: true,
  },
  grid: {
    type: 'grid',
    typeName: 'Lista Genérica',
    icon: Images.iconBulletedList,
    screen: 'activities.Grid',
    overrideBackPress: true,
  },
  quiz: {
    type: 'quiz',
    typeName: 'Quiz',
    icon: Images.iconQuestions,
    screen: 'activities.Quiz',
    overrideBackPress: true,
  },
  qrcode: {
    type: 'qrcode',
    typeName: 'Ler QRCode',
    icon: Images.iconQRCode,
    screen: 'activities.QRCode',
  },
  photo: {
    type: 'photo',
    typeName: 'Foto',
    icon: Images.iconCamera,
    screen: 'activities.Photo',
    overrideBackPress: true,
  },
  achievement: {
    type: 'achievement',
    typeName: 'Conquista',
    icon: Images.iconTrophy,
    screen: 'activities.Photo',
    overrideBackPress: true,
  },
  stopWatch: {
    type: 'stopWatch',
    typeName: 'Temporizador',
    icon: Images.iconStopwatch,
    screen: 'activities.Stopwatch',
    overrideBackPress: true,
  },
  dynamic_form: {
    type: 'dynamic_form',
    typeName: 'Formulário Dinâmico',
    icon: Images.iconEditProperty,
    screen: 'activities.DynamicForm',
    overrideBackPress: true,
  },
  choice: {
    type: 'choice',
    typeName: 'Escolha',
    icon: Images.iconChoice,
    screen: 'activities.Choice',
    overrideBackPress: true,
  },
  section: {
    type: 'section',
    typeName: 'Seção',
    icon: Images.iconFolder,
  },
};

export default types;
