export default {
  horizontalSpacing: 16,
  textVerySmall: 11,
  textSmall: 13,
  textNormal: 15,
  textLittleBig: 16,
  textBig: 20,
  textVeryBig: 30,
  boxBorderRadius: 12,
  verticalSpaceSeparatorSmall: 6,
  verticalSpaceSeparatorNormal: 10,
  verticalSpaceSeparatorLarge: 16,
  verticalSpaceSeparatorXL: 24,
  horizontalSpaceSmall: 8,
  horizontalSpace: 16,
  horizontalSpaceLarge: 24,
  horizontalSpaceXL: 32,
};
