/* @flow */

import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

import PropTypes from 'prop-types';

import { color } from '~/common';
import translate from '~/locales';

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 24,
    paddingVertical: 16,
    gap: 16,
  },
  title: {
    fontSize: 18,
    fontWeight: '700',
    color: color.black,
  },
  lastUpdateSendContainer: {
    justifyContent: 'space-between',
    marginTop: 16,
    marginBottom: 16,
  },
  lastUpdateSendLabel: {
    fontSize: 10,
    fontWeight: 'bold',
    color: color.neutral600,
  },
  lastUpdateSendValue: {
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 24,
    color: color.neutral900,
  },
  button: {
    borderWidth: 1,
    borderColor: '#B21500',
    borderRadius: 5,
  },
  updateFailure: {
    paddingRight: 16,
    paddingLeft: 8,
    paddingVertical: 8,
    color: '#B21500',
    fontWeight: '400',
    fontSize: 16,
  },
  imageImage: {
    color: '#B21500',
    tintColor: '#B21500',
  },
  imageContainer: {
    marginHorizontal: 2,
    marginTop: 2,
    marginBottom: 4,
  },
  updateFailureImageAndText: {
    alignContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
  },
  updateFailureContainer: {
    flexDirection: 'row',
    paddingHorizontal: 8,
    paddingVertical: 12,
    justifyContent: 'space-between',
    backgroundColor: '#FFE8E5',
    borderRadius: 4,
  },
});

function LastUpdateSend({ dataInfo: { lastUpdate, lastSend } }) {
  return (
    <View style={styles.lastUpdateSendContainer}>
      <View style={{ marginBottom: 12 }}>
        <Text style={styles.lastUpdateSendLabel}>
          {lastUpdate?.label ?? ''}
        </Text>
        <Text style={styles.lastUpdateSendValue}>
          {lastUpdate?.value ?? ''}
        </Text>
      </View>
      <View style={{ marginTop: 12 }}>
        <Text style={styles.lastUpdateSendLabel}>{lastSend?.label ?? ''}</Text>
        <Text style={styles.lastUpdateSendValue}>{lastSend?.value ?? ''}</Text>
      </View>
    </View>
  );
}

LastUpdateSend.propTypes = {
  dataInfo: PropTypes.shape({
    lastUpdate: PropTypes.object,
    lastSend: PropTypes.object,
  }),
};

function UpdateDatas({ dataInfo }) {
  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.title}>{`${translate('updateDatas')}`}</Text>
      </View>
      <LastUpdateSend dataInfo={dataInfo} />
    </View>
  );
}

UpdateDatas.propTypes = {
  dataInfo: PropTypes.object,
};

export default UpdateDatas;
