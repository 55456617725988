import Images from '@assets/Images';

export default function setModuleIcon(image) {
  let icon = Images.appDefault;

  switch (image) {
    case 'appCalendar':
      icon = Images.appCalendar;
      break;
    case 'appClients':
      icon = Images.appClients;
      break;
    case 'appOrders':
      icon = Images.appOrders;
      break;
    case 'appReports':
      icon = Images.appReports;
      break;
    case 'appTitles':
      icon = Images.appFinancing;
      break;
    case 'appTrade':
      icon = Images.appTrade;
      break;
    case 'appPrint':
      icon = Images.appPrint;
      break;
    case 'appProspect':
      icon = Images.appProspect;
      break;
    case 'appGoals':
      icon = Images.appGoals;
      break;
    case 'appManagement':
      icon = Images.appManagement;
      break;
    case 'appCatalog':
      icon = Images.iconProduct;
      break;
    case 'appEnviromental':
      icon = Images.iconRecycling;
      break;
    case 'appGamification':
      icon = Images.appGamification;
      break;
    default:
      break;
  }

  return icon;
}
