import AsyncStorage from '@react-native-async-storage/async-storage';

class CacheImage {
  static async getImageProfile() {
    const data = await AsyncStorage.getItem('@Session:imageProfile');
    return JSON.parse(data);
  }

  static async setImageProfile(data) {
    await AsyncStorage.setItem('@Session:imageProfile', JSON.stringify(data));
  }
}

export default CacheImage;
