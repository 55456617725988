import _ from 'lodash';
import moment from 'moment';

export function existsAnsweredField(form) {
  let exists = false;
  for (const field in form) {
    if (
      form[field] &&
      form[field] !== '' &&
      form[field] !== '-' &&
      form[field] !== '--'
    ) {
      exists = true;
      break;
    }
  }

  return exists;
}

export function relateFields(form) {
  let obj;
  const child = _.find(form, ['setup.parent_relationship', 'should_update']);
  if (child) {
    const { data_source, position, setup } = child;
    const parent = _.find(form, ['position', setup.parent_position]);
    if (parent) {
      obj = {
        child: { position, data_source },
        parent: { position: parent.position, data_source: parent.data_source },
      };
      return obj;
    }
  }
}

export function formatQSQLDataSource(dataSource) {
  let formattedDataSource = dataSource;

  if (!_.isEmpty(dataSource) && !dataSource[0].label) {
    formattedDataSource = dataSource.split(',').map(data => {
      const obj = { label: data, value: data };
      return obj;
    });
  }
  return formattedDataSource;
}

export function formatDateFields(fieldsRules, value, params) {
  const { type = '' } = fieldsRules
    ? fieldsRules.find(({ field }) => field === 'date')
    : {};
  let paramsArray;
  let maxDate;
  let minDate;
  if (Array.isArray(params)) {
    const { length } = params;
    maxDate = params[length - 1] ? params[length - 1] : undefined;
    minDate = params[length - 2] ? params[length - 2] : undefined;
  } else if (typeof params === 'string' && params !== '0') {
    paramsArray = params.split('|');
    const { length } = paramsArray;
    maxDate = paramsArray[length - 1];
    minDate = paramsArray[length - 2];
  }
  let date = new Date();
  if (value) {
    if (value.includes('/')) {
      date = moment(value, 'DD-MM-YYYY').toDate();
    } else {
      date = moment.unix(value).toDate();
    }
  }
  if (type === 'sum') {
    maxDate = maxDate
      ? moment(new Date())
          .add(maxDate, 's')
          .toDate()
      : undefined;
    minDate = minDate
      ? moment(new Date())
          .subtract(minDate, 's')
          .toDate()
      : undefined;
  } else {
    if (value && !value.includes('/') && value > 0) {
      date = new Date(value * 1000);
    }
    if (params !== '') {
      const { length } = paramsArray;
      if (paramsArray && paramsArray.length > 0) {
        if (paramsArray[length - 2]) {
          minDate = moment(minDate * 1000).toDate();
          if (moment().isBefore(minDate)) {
            date = minDate;
          }
        }
        if (paramsArray[length - 1]) {
          maxDate = moment(maxDate * 1000).toDate();
        }
      }
    }
  }

  if (maxDate && minDate) {
    if (maxDate < minDate) {
      const aux = maxDate;
      maxDate = minDate;
      minDate = aux;
    }
  }
  return { date, maxDate, minDate };
}
