import _ from 'lodash';
import CacheAccount from '../controllers/Accounts/Cache/CacheAccount';
import STAGING from './resources/config';

const URL_BASE = `https://process-server${STAGING}.polisuite.com.br/v1`;

const getConfig = async () => {
  const cacheAccount = new CacheAccount();
  const dataAccount = await cacheAccount.getDataAccount();
  const token = _.get(dataAccount, 'token', '');

  return {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`,
    },
  };
};

const getCompanyCode = async () => {
  const cacheAccount = new CacheAccount();
  const dataAccount = await cacheAccount.getDataAccount();
  const company = _.get(dataAccount, 'user.company.company_code').toLowerCase() || '';

  return company;
};


export {
  getConfig,
  getCompanyCode,
  URL_BASE,
};
