/**
 * Codigo de mensagem do retorno da LIBFS
 */
const FAMILY_SCALE_VALIDATION = 'validacao_escala_familia';
const QUANTITY_SUGGESTION = 'sugestao_quantidade';
const EXISTS_INCENTIVE = 'existe_incentivos';
const INVALID_SALES_CONDITION = 'condicoes_venda_invalida';

export {
  FAMILY_SCALE_VALIDATION,
  QUANTITY_SUGGESTION,
  EXISTS_INCENTIVE,
  INVALID_SALES_CONDITION,
};
