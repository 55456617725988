import React, { Component } from 'react';
import { Dimensions, View } from 'react-native';
import { RNCamera } from 'react-native-camera';
import translate from '~/locales';
import Images from '../../../assets/Images';
import PropTypes from 'prop-types';
import { Button } from '../../../components_base/src/components';
import Snackbar from 'react-native-snackbar';
import onPressHelper from '~/utils/onPressHelper';

import styles from './styles';

class BarCodeCamera extends Component {
  constructor(props) {
    super(props);

    this.state = {
      flash: false,
      camera: {
        type: RNCamera.Constants.Type.back,
        flashMode: RNCamera.Constants.FlashMode.auto,
      },
    };
  }

  handleBarCodeRead = onPressHelper.debounce(this.onBarCodeRead, 200);

  onBarCodeRead(scanResult) {
    const { onDetect, onClose } = this.props;
    onClose?.();
    onDetect?.(scanResult.data);
    if (scanResult.data != null) {
      onDetect?.(scanResult.data);
      Snackbar.show({
        text: translate('barCodeFound'),
      });
    } else {
      Snackbar.show({
        text: translate('noBarCodeFound'),
      });
    }
  }

  toggleFlash() {
    this.setState({
      flash: !this.state.flash,
    });
  }

  render() {
    const { onClose } = this.props;

    const { flash } = this.state;

    const { height, width } = Dimensions.get('window');
    const maskRowHeight = Math.round((height - 300) / 20);
    const maskColWidth = (width - 300) / 2;

    return (
      <View style={styles.container}>
        <RNCamera
          flashMode={flash ? 'torch' : 'off'}
          defaultTouchToFocus
          mirrorImage={false}
          onBarCodeRead={this.handleBarCodeRead.bind(this)}
          permissionDialogTitle={translate('messageAccesCameraTitle')}
          permissionDialogMessage={translate('messageAccesCamera')}
          style={styles.preview}
          type={this.state.camera.type}
        />
        <View style={styles.maskOutter}>
          <View
            style={[{ flex: maskRowHeight }, styles.maskRow, styles.maskFrame]}
          />
          <View style={[{ flex: 12 }, styles.maskCenter]}>
            <View style={[{ width: maskColWidth }, styles.maskFrame]} />
            <View
              style={[
                styles.maskInner,
                {
                  width: width * 0.9,
                },
              ]}
            />
            <View style={[{ width: maskColWidth }, styles.maskFrame]} />
          </View>
          <View
            style={[{ flex: maskRowHeight }, styles.maskRow, styles.maskFrame]}
          />
        </View>
        <View style={[styles.overlay, styles.bottomOverlay]}>
          <Button
            titleColor="white"
            iconLeft={flash ? Images.zap : Images.zapOff}
            iconButton
            flat
            onPress={this.toggleFlash.bind(this)}
          />
          <Button
            style={styles.bottomButtons}
            title={translate('Close')}
            containerStyle={{
              borderRadius: 0,
            }}
            onPress={onClose}
          />
        </View>
      </View>
    );
  }
}

BarCodeCamera.propTypes = {
  onClose: PropTypes.func,
  onDetect: PropTypes.func,
};

BarCodeCamera.defaultProps = {
  onClose: () => {},
  onDetect: () => {},
};

export default BarCodeCamera;
