import { StyleSheet } from 'react-native';
import * as config from '../../../config/config';

const styles = StyleSheet.create({
  buttonStyle: {
    width: 28,
    height: 28,
    borderRadius: 14,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 0,
  },
  buttonImageStyle: {
    width: 24,
    height: 24,
    borderRadius: 12,
    tintColor: config.Colors.primaryColor,
  },
});

export default styles;
