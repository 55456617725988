import translate from '../locales';

export const SCREEN_ACTIVITIES = {
  name: 'Activities',
  title: `${translate('activitiesScreen')}`,
};
export const SCREEN_CLIENTS = {
  name: 'clients',
  title: `${translate('clientsScreen')}`,
};
export const SCREEN_OFF_ROUTE_CUSTOMERS = {
  name: 'OffRouteClients',
  title: `${translate('offRouteCustomersScreen')}`,
};
export const SCREEN_TRADE = {
  name: 'trade',
  title: `${translate('tradeScreen')}`,
};
export const SCREEN_ADD_CLIENT = {
  name: 'AddClient',
  title: `${translate('addClientScreen')}`,
};
export const SCREEN_ADD_PROSPECT = {
  name: 'AddProspect',
  title: `${translate('addProspectScreen')}`,
};
export const SCREEN_NEW_CLIENTS = {
  name: 'NewClients',
  title: `${translate('newClientsScreen')}`,
};
export const SCREEN_PROSPECTS = {
  name: 'prospects',
  title: `${translate('prospectsScreen')}`,
};
export const SCREEN_ENVIROMENTAL = {
  name: 'environmental',
  title: `${translate('enviromentScreen')}`,
};
export const SCREEN_CATALOG_PRODUCTS = {
  name: 'catalog_products',
  title: `${translate('catalogScreen')}`,
};
export const SCREEN_OBSERVATIONS = {
  name: 'Observations',
  title: `${translate('productObservationsScreen')}`,
};
export const SCREEN_SALES_CONDITIONS_CHILD = {
  name: 'SalesConditionsChild',
  title: `${translate('conditionsSalesChild')}`,
};
export const SCREEN_ORDER_OBSERVATIONS = {
  name: 'OrderObservations',
  title: `${translate('orderObservationsScreen')}`,
};
export const SCREEN_PRODUCT_DETAIL = {
  name: 'ProductDetail',
  title: `${translate('productDetailScreen')}`,
};
export const SCREEN_PRODUCT_FULL_IMAGE = {
  name: 'ProductFullImage',
  title: `${translate('imageScreen')}`,
};
export const SCREEN_CART = {
  name: 'Cart',
  title: `${translate('cartDetailScreen')}`,
};
export const SCREEN_ORDERS = {
  name: 'orders',
  title: `${translate('orderScreen')}`,
};
export const SCREEN_ORDER_DETAIL = {
  name: 'OrderDetail',
  title: `${translate('orderDetailsScreen')}`,
};
export const SCREEN_RESUME_ORDER = {
  name: 'Resume',
  title: `${translate('orderResumeScreen')}`,
};
export const SCREEN_FINISH_ORDER = {
  name: 'Finish',
  title: `${translate('finishScreen')}`,
};
export const SCREEN_TITLES = {
  name: 'titles',
  title: `${translate('titlesScreen')}`,
};
export const SCREEN_CLIENT_DETAIL = {
  name: 'ClientDetail',
  title: `${translate('clientDetailsScreen')}`,
};
export const SCREEN_ITINERARY = {
  name: 'itinerary',
  title: `${translate('itineraryScreen')}`,
};
export const SCREEN_LOGIN = {
  name: 'Login',
  title: `${translate('Iniciar sesión')}`,
};
export const SCREEN_NOTIFICATIONS = {
  name: 'Notifications',
  title: `${translate('Notifications')}`,
};
export const SCREEN_SOLICITATIONS = {
  name: 'Solicitations',
  title: `${translate('Solicitations')}`,
};

export const SCREEN_HOME = {
  name: 'Home',
  title: `${translate('homeScreen')}`,
};
export const SCREEN_BONIFICATIONS = {
  name: 'Bonifications',
  title: `${translate('bonificationsScreen')}`,
};
export const SCREEN_PROFILE = {
  name: 'Profile',
  title: `${translate('profileScreen')}`,
};
export const SCREEN_LIGTH_BOX = {
  name: 'LigDehtBox',
  title: `${translate('lightBoxScreen')}`,
};
export const SCREEN_COMPOUND_PRODUCT = {
  name: 'CompoundProducts',
  title: `${translate('ad')}`,
};
export const SCREEN_PROMOTIONS = {
  name: 'Promotions',
  title: `${translate('compoundProductScreen')}`,
};
export const SCREEN_PROMOTION_DETAIL = {
  name: 'PromotionDetails',
  title: `${translate('promotionDetailsScreen')}`,
};
export const SCREEN_REPORT_FORM = {
  name: 'ReportForm',
  title: `${translate('generationFormScreen')}`,
};
export const SCREEN_REPORTS = {
  name: 'reports',
  title: `${translate('reportsScreen')}`,
};
export const SCREEN_COMBOS = {
  name: 'Combos',
  title: `${translate('combosScreen')}`,
};
export const SCREEN_COMBO_DETAIL = {
  name: 'ComboDetail',
  title: `${translate('comboDetailScreen')}`,
};
export const SCREEN_WEBVIEW = { name: 'webview' };
export const SCREEN_FILTER = {
  name: 'Filter',
  title: `${translate('filtersScreen')}`,
};
export const SCREEN_UPDATE_VERSION_APP = {
  name: 'UpdateVersionApp',
  title: `${translate('updateVersionScreen')}`,
};
export const SCREEN_MESSAGES = {
  name: 'Messages',
  title: `${translate('messagesScreen')}`,
};
export const SCREEN_REWARDS = {
  name: 'Rewards',
  title: `${translate('rewardsScreen')}`,
};
export const SCREEN_REGISTER_AGAIN = {
  name: 'RegisterAgain',
  title: `${translate('registerAgain')}`,
};
export const SCREEN_PRINT = {
  name: 'print',
  title: `${translate('printScreen')}`,
};
export const SCREEN_WEBVIEW_GAMIFICATION = {
  title: 'Gameficação',
  name: `${translate('gamificationScreen')}`,
};
export const SCREEN_WEBVIEW_MANAGEMENT = {
  title: 'Gestão',
  name: `${translate('managementScreen')}`,
};
export const SCREEN_WEBVIEW_GOALS = {
  title: 'Metas',
  name: `${translate('goals')}`,
};
export const SCREEN_PRODUCT_TABS = {
  title: `${translate('moreProductsScreen')}`,
  name: 'ProductTabs',
}; // Requer tradução
export const SCREEN_INCENTIVE = { title: 'Incentivos', name: 'Incentivos' };
export const SCREEN_CLIENT_PROCESS = {
  title: `${translate('screenClientProcess')}`,
  name: 'ClientProcess',
};
export const SCREEN_PRICE_TERM_CONDITIONS = {
  title: `${translate('screenPriceTermConditions')}`,
  name: 'PriceTermConditions',
};
export const SCREEN_SALES_OPPORTUNITY = {
  title: `${translate('SalesOpportunity')}`,
  name: 'SalesOpportunity',
};
export const SCREEN_DETAIL_OPPORTUNITY = {
  title: `${translate('DetailOpportunity')}`,
  name: 'DetailOpportunity',
};

export const SCREEN_PRODUCTS_LISTING = {
  title: `${translate('productsListing')}`,
  name: 'productsListing',
};

export const SCREEN_EXPENSES = {
  title: `${translate('expenses')}`,
  name: 'expenses',
};

export const SCREEN_EXPENSES_DETAIL = {
  title: `${translate('expenseDetail')}`,
  name: 'expenseDetail',
};

export const SCREEN_EXPENSES_FORM = {
  title: `${translate('expensesForm')}`,
  name: 'expensesForm',
};

export const SCREEN_EXPENSES_SUCCESS = {
  title: `${translate('expenseSuccess')}`,
  name: 'expenseSuccess',
};

export const SCREEN_EXPENSES_RESUME = {
  title: `${translate('expensesResume')}`,
  name: 'expensesResume',
};

export const SCREEN_SHARE_ORDER = {
  title: `${translate('shareOrderScreen')}`,
  name: 'shareOrderScreen',
};

export const SCREEN_CATALOG_MULTI_TAB = {
  title: `${translate('catalogProdDetail')}`,
  name: 'CatalogProdDetail',
};

export const SCREEN_PRODUCT_CODE_SCANNER = {
  title: `${translate('shareOrderScreen')}`,
  name: 'ProductCodeScanner',
};
export const SCREEN_CATEGORIES = {
  title: `${translate('categoriesScreen')}`,
  name: 'Categories',
};
export const SCREEN_LIST_TITLES = {
  title: `${translate('listTitlesScreen')}`,
  name: 'ListTitles',
};
export const SCREEN_WEBVIEW_DETAIL = {
  title: `${translate('details')}`,
  name: 'WebViewDetail',
};
