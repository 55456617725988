/* @flow */

import React, { Component } from 'react';
import _ from 'lodash';
import { Alert, BackHandler } from 'react-native';
import Images from '@assets/Images';
import { connect } from 'react-redux';
import RouteSolicitationsResource, {
  KIND_ADD_CUSTOMER_ROUTE,
} from '../../services/resources/RouteSolicitationsResource';

import Clients from '../../../src/scenes/clients/Clients';
import { SCREEN_NEW_CLIENTS } from '../screens';
import TextUtils from '../../utils/TextUtils';
import translate from '../../locales';
import { tasksSync } from '../../../src/TaskSync';
import CacheDataController from '../../utils/CacheDataController';
import { TYPE_ROUTE_SOLICITATION_RUNNING } from '../../screens/Notifications/typeRouteSolicitationConst';
import getQueryResult from '../../services/resources/libFastSeller/genericQuery';
import { getListClients } from '~/store/clientsFetch/slice';
import Snackbar from 'react-native-snackbar';
import NavigationHelper from '../NavigationHelper';
import { Navigation } from 'react-native-navigation';

import PropTypes from 'prop-types';

class OffRouteCustomerContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSearch: false,
      searchText: '',
      processing: false,
    };

    this.pushingScreen = false;
  }

  componentDidMount() {
    if (!this.props.clients?.payload?.length) {
      this.props.dispatch(getListClients());
    }
  }

  componentDidAppear() {
    this.backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      this.handleBackPress.bind(this),
    );
    NavigationHelper.setRightButtons(this.props.componentId, [
      {
        icon: Images.iconSearch,
        title: `${translate('search')}`,
        id: 'search',
      },
    ]);
  }

  componentDidDisappear() {
    this.backHandler?.remove();
  }

  navigationButtonPressed(event) {
    switch (event.buttonId) {
      case 'search':
        this.setState({ showSearch: !this.state.showSearch, searchText: '' });
        break;
      default:
        break;
    }
  }

  onConfirmSendSolicitation = async item => {
    try {
      const { pkey } = item;
      this.setState({
        processing: true,
      });
      await this.saveSoliciationInCache(pkey);
      await RouteSolicitationsResource.sendSolicitation(
        pkey,
        KIND_ADD_CUSTOMER_ROUTE,
      );

      CacheDataController.removeItem(pkey);
      setTimeout(() => {
        Snackbar.show({
          text: `${translate('successSendRequest')}`,
          duration: Snackbar.LENGTH_SHORT,
          action: {
            text: 'Sair',
            onPress: () => {
              Snackbar.dismiss();
              this.handleBackPress();
            },
          },
        });
      }, 500);
      tasksSync(true);
    } catch (e) {
      Snackbar.show({
        text: 'Atenção! Não foi possível enviar a solicitação!',
      });
      console.tron.log(
        'PE_APP | file: OffRouteCustomer.Container.js:126 | err =>',
        e,
      );
    } finally {
      this.setState(
        {
          processing: false,
        },
        () => {},
      );
    }
  };

  onPressItem = async item => {
    const { pkey } = item;
    const { canRequest, message } = await this.canSendRequest(pkey);

    if (canRequest) {
      const solicitationCache = await CacheDataController.getData(pkey);
      if (
        !(
          solicitationCache &&
          solicitationCache.status === TYPE_ROUTE_SOLICITATION_RUNNING
        )
      ) {
        Alert.alert(
          `${translate('offRouteService')}`,
          `${item.nome} ${translate('offRouteCustomerService')}`,
          [
            {
              text: 'Cancelar',
              onPress: () => {},
            },
            {
              text: `${translate('yesRequest')}`,
              onPress: this.onConfirmSendSolicitation.bind(this, item),
            },
          ],
        );
      } else {
        Snackbar.show({
          text:
            'Solicitação já em andamento! Aguarde a resposta do supervisor.',
        });
      }
    } else {
      const toastMessage =
        message || 'Não é possível enviar uma solicitação para este cliente.';
      Snackbar.show({ text: `${toastMessage}` });
    }
  };

  onClickAdd = () => {
    if (this.pushingScreen) {
      return;
    }
    this.pushingScreen = true;
    NavigationHelper.push(
      this.props.componentId,
      SCREEN_NEW_CLIENTS.name,
      SCREEN_NEW_CLIENTS.title,
      null,
      {
        overrideBackPress: true,
      },
    );
  };

  onChangeText = text => {
    clearTimeout(this);
    setTimeout(() => {
      this.setState({ searchText: text });
    }, 400);
  };

  getFilteredData() {
    const { searchText } = this.state;
    const sClients = _.get(this.props, 'clients.payload', []);
    const customersInRoute = _.get(this.props, 'customersInRoute', []);
    const allClients = sClients.filter(
      customer =>
        !customersInRoute.filter(
          customerRoute => customerRoute.pkey === customer.pkey,
        ).length,
    );
    const search = TextUtils.slugfy(searchText);

    return allClients.filter(client => {
      const res =
        (client.codigo && TextUtils.slugfy(client.codigo).includes(search)) ||
        (client.nome && TextUtils.slugfy(client.nome).includes(search)) ||
        (client.fantasia && TextUtils.slugfy(client.fantasia).includes(search));
      return res || this.searchIn(client.info_adicionais, search);
    });
  }

  handleBackPress = () => {
    NavigationHelper.pop(this.props.componentId);
    return true;
  };

  async canSendRequest(pkeyClient) {
    let canRequest = true;
    let message = '';
    const params = {
      pk_cliente: pkeyClient,
      kind: KIND_ADD_CUSTOMER_ROUTE,
    };

    try {
      const data =
        (await getQueryResult('VLIT;SEND;SLCT', JSON.stringify(params))) ||
        '[]';
      const dataParsed = JSON.parse(data);
      const validationResult = _.get(dataParsed, ['0'], {}) || {};
      const { result, message: resultMessage } = validationResult || {};
      canRequest = result.localeCompare(0) === 0;
      message = resultMessage;
    } catch (err) {
      console.log('ERR VALIDATE SEND REQUEST', err);
    }

    return {
      canRequest,
      message,
    };
  }

  searchIn(list, search) {
    let res = false;
    if (list) {
      list.forEach(item => {
        if (TextUtils.slugfy(item.valor).includes(search)) {
          res = true;
        }
      });
    }
    return res;
  }

  saveSoliciationInCache = async pkey => {
    await CacheDataController.saveData(pkey, {
      clientPkey: pkey,
      status: TYPE_ROUTE_SOLICITATION_RUNNING,
    });
  };

  render() {
    return (
      <Clients
        showDates={false}
        showSearch={this.state.showSearch}
        showButtonNewRegister={false}
        loading={this.props.clients.loading || this.state.processing}
        onPressItem={this.onPressItem}
        onPressAdd={this.onClickAdd}
        onChangeText={this.onChangeText}
        clients={this.getFilteredData()}
        solicitations={this.props.solicitations}
      />
    );
  }
}

OffRouteCustomerContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    clients: PropTypes.object.isRequired,
    solicitations: PropTypes.array.isRequired,
};

function mapStateToProps({ clientsFetch }) {
  return {
    clients: clientsFetch,
  };
}

export default connect(mapStateToProps)(OffRouteCustomerContainer);
