import React, { useState } from 'react';
import {
    Image,
    Platform,
    StyleSheet,
    Text,
    View,
    ImageBackground,
} from 'react-native';
import Images from '@assets/Images';
import Touchable from 'react-native-platform-touchable';
import { color } from '~/common';
import FloatingTitleTextInput from '~/components/FloatingTitleTextInput';
import { fonts } from '../../utils/Fonts';
import translate from '../../locales';
import { getVersionApp } from '~/utils/deviceAppInfo';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
    Logo__Container: {
        position: 'absolute',
        alignSelf: 'center',
        top: 150,
    },
    logoPoli: {
        width: 100,
        height: 100,
        marginBottom: 20,
    },
    loginButton: {
        backgroundColor: color.accentColor,
        borderRadius: 8,
        paddingVertical: 8,
        paddingHorizontal: 16,
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 150,
        height: 40,
    },
    loginButtonDisabled: {
        backgroundColor: color.grayDark,
        borderRadius: 8,
        paddingVertical: 8,
        paddingHorizontal: 16,
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 150,
        height: 40,
    },
    loginButtonText: {
        color: '#fff',
        fontSize: 16,
        fontFamily: fonts.QuicksandBold,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    version: {
        color: '#fff',
        textAlign: 'center',
        marginVertical: 3,
        fontSize: 18,
        fontWeight: 'bold',
    },
    versionContainer: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: Platform.OS === 'ios' ? 10 : 0,
        justifyContent: 'center',
    },
});

const Login = ({
    onPressAuthenticate,
    onUUIDChange,
    onEggDropTables,
    disabled,
    loggingIn,
}) => {
    const [secureTextEntry, setSecureTextEntry] = useState(true);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [uuid, setUuid] = useState('');
    const versionApp = getVersionApp();

    const renderAccessory = (passwd) => (
        <Touchable style={styles.iconTextField} onPress={() => setSecureTextEntry(!secureTextEntry)}>
            <Image source={passwd} style={styles.iconTextField} />
        </Touchable>
    );

    return (
        <ImageBackground
            source={Images.loginBackground}
            style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
            }}
        >
            <View style={styles.Logo__Container}>
                <Image source={Images.logoPoliEquipes} style={styles.logoPoli} resizeMode="contain" />
            </View>
            <View
                style={{
                    width: '75%',
                }}
            >
                <View>
                    <View
                        style={{
                            marginBottom: 60,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    />

                    <View style={{ margin: 10 }}>
                        <FloatingTitleTextInput
                            placeholder="Login"
                            value={username}
                            tintColor={color.primaryColor}
                            onChangeText={(text) => setUsername(text)}
                        />
                    </View>

                    <View style={{ margin: 10 }}>
                        <FloatingTitleTextInput
                            label={`${translate('password')}`}
                            placeholder={`${translate('password')}`}
                            secureTextEntry={secureTextEntry}
                            value={password || ''}
                            tintColor={color.primaryColor}
                            onChangeText={(passwordResp) => setPassword(passwordResp)}
                            renderAccessory={() => renderAccessory(Images.iconEye)}
                        />
                    </View>
                    {!loggingIn ? (
                        <Touchable
                            onPress={() => {
                                onUUIDChange({ username, password, paramsUuid: uuid });
                            }}
                            style={{ marginTop: 10 }}
                        >
                            <View
                                style={[
                                    disabled ? styles.loginButtonDisabled : styles.loginButton,
                                    { flexDirection: 'row' },
                                ]}
                            >
                                <Text style={styles.loginButtonText}>
                                    {disabled ? `${translate('authenticating')}` : 'LOGIN'}
                                </Text>
                            </View>
                        </Touchable>
                    ) : (
                        <View style={[styles.loginButtonDisabled, { flexDirection: 'row' }]}>
                            <Text style={styles.loginButtonText}>{`${translate('authenticating')}`}</Text>
                            <ActivityIndicator style={{ marginLeft: 3 }} size="small" color="#fff" />
                        </View>
                    )}
                </View>
            </View>
            <View style={[styles.versionContainer, styles.rowDirection]}>
                <Text style={styles.version}> v{versionApp}</Text>
            </View>
        </ImageBackground>
    );
};

Login.propTypes = {
    onPressAuthenticate: PropTypes.func.isRequired,
    onUUIDChange: PropTypes.func.isRequired,
    onEggDropTables: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

Login.defaultProps = {
    disabled: false,
};

export default Login;