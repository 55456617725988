import React, { Component } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import color from '../../common/color';
import { fonts } from '../../utils/Fonts';
import Touchable from 'react-native-platform-touchable';
import Images from '../../../assets/Images';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    paddingHorizontal: 16,
    paddingVertical: 12,
    paddingBottom: 0,
    borderBottomColor: color.grayContainer,
    borderBottomWidth: 4,
  },
  descricao: {
    color: '#000',
    fontSize: 18,
    fontFamily: fonts.QuicksandBold,
  },
  actions: {
    borderTopColor: color.grayContainerDark,
    borderTopWidth: 1,
    marginTop: 15,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  orderActionContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    paddingHorizontal: 20,
  },
  orderActionIcon: {
    width: 18,
    height: 18,
    tintColor: color.primaryColor,
    marginRight: 10,
  },
  orderActionText: {
    color: color.primaryColor,
    fontSize: 14,
  },
});

const ReportAction = ({ text, icon, output, report, onPress }) => {
  return (
    <Touchable onPress={() => onPress(report, output)}>
      <View style={styles.orderActionContainer}>
        <Image
          style={styles.orderActionIcon}
          source={icon}
        />
        <Text style={styles.orderActionText}>
          {text}
        </Text>
      </View>
    </Touchable>
  );
};

class ReportItem extends Component {

  render() {
    const { report, onGenereateReport } = this.props;
    const { descricao } = report;

    return (
      <View style={styles.container}>
        <Text style={styles.descricao}>{descricao}</Text>
        <View style={styles.actions} >
          <ReportAction
            text="HTML"
            icon={Images.iconHtml}
            output="html"
            report={report}
            onPress={onGenereateReport}
          />
          <ReportAction
            text="PDF"
            icon={Images.iconPdf}
            output="pdf"
            report={report}
            onPress={onGenereateReport}
          />
        </View>
      </View>
    );
  }
}

ReportItem.propTypes = {
  report: PropTypes.object.isRequired,
  onGenereateReport: PropTypes.func.isRequired,
};

export default ReportItem;
