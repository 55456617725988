/* @flow */

import React from 'react';
import { View, Text, Image } from 'react-native';

import { style } from '~/common';
import SelectPicker from '../../../components/SelectPicker';
import DateTimePicker from 'react-native-modal-datetime-picker';
import Images from '@assets/Images';
import moment from 'moment';
import Touchable from 'react-native-platform-touchable';
import translate from '../../../locales';

type Props = {
  itemSelected: any,
  data: any,
  onChangeChoice: (itemSelected: any) => any,
  onConfirmDateSelected: () => {},
  dateTime: boolean,
  extraComponents: Array<string>,
  inputConfig: Object,
};
class Choice extends React.Component<Props, void> {
  constructor(props) {
    super(props);
    this.state = {
      isDateTimePickerVisible: false,
    };
  }

  onPress = () => this.setState({ isDateTimePickerVisible: true });

  onConfirmDate = date => {
    const newValue = moment(date).format();
    this.hideDateTimePicker();
    const dateFormatted = `${moment(newValue).unix()}`;
    this.props.onConfirmDateSelected(dateFormatted);
  };

  setBeforeAndAfterDays = (type, field) => {
    let dateFormated;

    if (!field.setup.days_after && !field.setup.days_before) {
      return dateFormated;
    } else if (type === 'before' && field.setup.days_before) {
      dateFormated = new Date(
        moment().subtract(field.setup.days_before, 'days'),
      );
    } else if (type === 'after' && field.setup.days_after) {
      dateFormated = new Date(moment().add(field.setup.days_after, 'days'));
    }
    return dateFormated;
  };

  getFields = field => {
    const humanDate = this.props.dateTime
      ? moment.unix(this.props.dateTime).format('L')
      : null;
    switch (field.id) {
      case 'date':
        return (
          <View>
            <Touchable
              foreground={Touchable.SelectableBackground()}
              onPress={this.onPress}>
              <View style={style.dateTimePicker}>
                <Image
                  source={Images.iconCalendar}
                  style={style.appIcon}
                  resizeMode="contain"
                />
                <Text style={{ marginLeft: 14, fontSize: 14 }}>
                  {humanDate || `${translate('selectADate')}`}
                </Text>
              </View>
            </Touchable>
            <DateTimePicker
              isVisible={this.state.isDateTimePickerVisible}
              date={new Date()}
              minimumDate={this.setBeforeAndAfterDays('before', field)}
              maximumDate={this.setBeforeAndAfterDays('after', field)}
              onConfirm={date => this.onConfirmDate(date)}
              onCancel={this.hideDateTimePicker}
            />
          </View>
        );
      default:
        return null;
    }
  };

  hideDateTimePicker = () => this.setState({ isDateTimePickerVisible: false });

  render() {
    const { itemSelected, data, onChangeChoice, extraComponents } = this.props;

    return (
      <View style={style.whiteContainer}>
        <View style={{ padding: 10 }}>
          <SelectPicker
            height={280}
            selected={itemSelected}
            items={data}
            onChangeValue={onChangeChoice}
          />
          {extraComponents
            ? extraComponents.map(field => this.getFields(field))
            : null}
        </View>
      </View>
    );
  }
}

export default Choice;