import React, { Component } from 'react';
import {
  ActivityIndicator,
  Dimensions,
  Image,
  ImageBackground,
  Modal,
  Platform,
  StyleSheet,
  View,
} from 'react-native';

import Touchable from 'react-native-platform-touchable';
import Images from '../../../../assets/Images';
import colors from '~/common/color';
import ImageZoom from 'react-native-image-pan-zoom';
import Button from '../Button';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  imageItem: {
    height: 100,
    width: 100,
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
});

class ImageComponent extends Component {
  constructor(props) {
    super(props);
    const { source } = this.props;

    this.state = {
      source,
      errorImage: false,
      hideProgress: false,
      modalExpandImageVisible: false,
    };
  }

  componentDidMount() {
    if (this.props.source && this.props.source.then) {
      this.props.source
      .then((result) => {
        this.setState({ source: result ? result[0] : null, hideProgress: true });
      })
      .catch((error) => {
        this.setState({ errorImage: true, hideProgress: true });
      });
    } else {
      // this.setImage(null);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.source !== nextProps.source && nextProps.source?.then) {
      nextProps.source
      .then((result) => {
        this.setState({ source: result ? result[0] : null, hideProgress: true });
      })
      .catch((error) => {
        this.setState({ errorImage: true, hideProgress: true });
      });
    }
  }

  onImageClick = () => {
    if (this.props.onImageClick) {
      this.props.onImageClick();
    }
    if (!this.props.notExpand) {
      this.setState({ modalExpandImageVisible: true });
    }
  };

  onLoad = () => {
    this.setState({ hideProgress: true, errorImage: false });
  };

  onError = () => {
    this.setState({ hideProgress: true, errorImage: true });
  };

  setImage(source) {
    this.setState({
      source: {
        ...source,
        // uri: utlImage,
      },
      // hideProgress,
      errorImage: false,
    });
  }

  imageModalAndroid = () => {
    const { errorImage } = this.state;
    const { source } = this.props;

    if (errorImage) {
      return (
        <Image
          resizeMode={this.props.resizeMode || 'contain'}
          style={styles.imageItem}
          source={source ? { ...source } : Images.iconProductDefault}
        />
      );
    }

    return (
      <Image
        onError={this.onError}
        onLoad={this.onLoad}
        resizeMode={this.props.resizeMode || 'contain'}
        style={styles.imageItem}
        source={source || Images.iconProductDefault}
      />
    );
  };

  renderImage = () => {
    const { errorImage, hideProgress, source } = this.state;
    const { isProductDetail } = this.props;
    const isInvalidSource = !source || Object.keys(source).length === 0;
    const styleImage = !isProductDetail ? {
      height: 100,
      width: 100,
    } : {
      height: 200,
      width: 200,
    };
    if ((errorImage && hideProgress) || isInvalidSource) {
      return (
        <Image
          tabIndex={-1}
          source={Images.iconProductDefault}
          style={this.props.bottomBar ? this.props.style : styleImage}
          resizeMode={'contain'}
          tintColor={colors.grayDark}
        />
      );
    }

    return (
      <Image
        tabIndex={-1}
        resizeMode={'contain'}
        style={this.props.bottomBar ? this.props.style : styleImage}
        source={source || Images.iconProductDefault}
      />
    );
  };

  render() {
    const { errorImage, hideProgress, source } = this.state;
    let { modalExpandImageVisible } = this.state;
    const styleImage = this.props.style || styles.imageItem;

    const loadImage =
      !errorImage && !hideProgress ? (
        <View style={styles.loading}>
          <ActivityIndicator animating={hideProgress} />
        </View>
      ) : null;

    modalExpandImageVisible = modalExpandImageVisible && !!source;

    return (
      <View style={this.props.isProductDetail ? { 
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
       } : {}}>
        <Touchable
          tabIndex={-1}
          disabled={!source || this.state.errorImage}
          style={[styleImage]}
          onPress={this.onImageClick}>
          {this.renderImage()}
        </Touchable>
        {loadImage}

        <Modal
          animationType="slide"
          transparent
          visible={modalExpandImageVisible}>
          <View
            style={styles.modal}>
            <Image
                  tabIndex={-1}
                  onError={this.onError}
                  resizeMode={this.props.resizeMode || 'contain'}
                  style={{ width: '50%', height: '70%', resizeMode: 'contain' }}
                  source={source || Images.iconProductDefault}
                />
            <Touchable onPress={() => {
                this.setState({ modalExpandImageVisible: false });
              }} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
              <Image tintColor={'#FFFF'} source={Images.iconClose} style={{ position: 'absolute', bottom: 20, right: 20, width: 30, height: 30 }} />
              </Touchable>
          </View>
        </Modal>
      </View>
    );
  }
}

ImageComponent.propTypes = {
    source: PropTypes.any.isRequired,
    style: PropTypes.any,
    styleError: PropTypes.any,
    useCache: PropTypes.bool,
    resizeMode: PropTypes.string,
    onImageClick: PropTypes.func,
    notExpand: PropTypes.bool,
    isProductDetail: PropTypes.bool,
    bottomBar: PropTypes.bool,
};

ImageComponent.defaultProps = {
  useCache: true,
  notExpand: false,
  isProductDetail: false,
  bottomBar: false,

};

export default ImageComponent;