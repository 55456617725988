import _ from 'lodash';
import FileViewer from 'react-native-file-viewer';
import { Platform } from 'react-native';
import ReportsModule from '../modules/ReportsModule';
import CacheAccount from '../controllers/Accounts/Cache/CacheAccount';

const REPORTS_NOT_INSTALLED_ERROR = 'REPORTS_NOT_INSTALLED_ERROR';
const REPORTS_GENERATE_ERROR_MESSAGE = 'Ocorreu algum erro na geração do relatório.';
const REPORTS_NOT_INSTALLED_ERROR_MESSAGE = 'Módulo de Geração de relatórios não instalado.';

function openReport(respReport, callback) {
  let success = false;
  let savePath = false;
  let data = respReport;
  if (Platform.OS === 'web') {
    data = respReport.data;
    if (respReport.status === 200 && respReport.data.exit_code === 0) {
      if (callback) {
        success = true;
        callback(respReport);
      }
    }
  } else {
    savePath = data.savePath;
    success = data.success;
    FileViewer.open(savePath, {
      showOpenWithDialog: true,
      showAppsSuggestions: true,
    });
  }
  if (!success) {
    const finalMessage = data.message || REPORTS_GENERATE_ERROR_MESSAGE;
    throw [{ mensagem: finalMessage, tipo: 'erro' }];
  }
}

export default async function exportReport(pkey, params, output, callback) {
  const cacheAccount = new CacheAccount();
  const dataAccount = await cacheAccount.getDataAccount();
  const company =
    _.get(dataAccount, 'user.company.company_code').toLowerCase() || '';
  const user = _.get(dataAccount, 'user.username') || '';

  const pathDB = `n:/db_ps_${company}_${user}/`;
  const pathReport = `${pathDB}rela`;

  try {
    const respReportString = await ReportsModule.generateReport(
      pkey,
      params,
      output,
      pathDB,
      pathReport,
    );
    const respReport = JSON.parse(respReportString);
    openReport(respReport, callback);
  } catch (e) {
    if (e.code === REPORTS_NOT_INSTALLED_ERROR) {
      const error = [{ mensagem: REPORTS_NOT_INSTALLED_ERROR_MESSAGE, tipo: 'erro' }];
      throw error;
    }

    throw e;
  }
}
