import React, { memo } from 'react';
import { Image, Modal, StyleSheet, View } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import Images from '../../../../assets/Images';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
    modal: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        backgroundColor: 'rgba(0,0,0,0.5)'
    },
    modalImage: { width: '50%', height: '70%', resizeMode: 'contain' },
    modalCloseButton: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    },
    modalCloseIcon: {
        position: 'absolute',
        bottom: 20,
        right: 20,
        width: 30,
        height: 30
    },
});

const ExpandImageModal = memo(({ source, onClose, resizeMode = 'contain', onError }) => {
    return (
        <Modal animationType="slide" transparent visible={true}>
            <View style={styles.modal}>
                <Image
                    tabIndex={-1}
                    onError={onError}
                    resizeMode={resizeMode}
                    style={styles.modalImage}
                    source={source}
                />
                <Touchable onPress={onClose} style={styles.modalCloseButton}>
                    <Image
                        tintColor="#FFFF"
                        source={Images.iconClose}
                        style={styles.modalCloseIcon}
                    />
                </Touchable>
            </View>
        </Modal>
    );
});

export default ExpandImageModal;

ExpandImageModal.propTypes = {
    source: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    resizeMode: PropTypes.string,
};