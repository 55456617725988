import { CALL_GENERIC_PROPERTY } from '~/modules/WrappedModule';

const CombosApiModule = nameModule => {
  const methodByAlias = {
    detailsCombPaginate: 'detalhesComboPaginado',
    selectCombo: 'selecionarCombo',
    detailsComb: 'detalhesCombo',
    listCombos: 'listarCombos',
    listCombosWithPagination: 'listarCombosComPaginacao',
    applyCombo: 'aplicarCombo',
    removeCombo: 'removerCombo',
  };

  const genericProperties = {};
  Object.keys(methodByAlias).forEach(
    k => (genericProperties[k] = CALL_GENERIC_PROPERTY),
  );

  return {
    getName: () => nameModule,
    methodByAlias,
    ...genericProperties,
  };
};

export default CombosApiModule;
