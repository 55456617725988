import SyncApiV1 from '~/services/anywhere/sync/SyncApiV1';

const TAG = 'SyncSalesFactory';
class SyncSalesFactory {
  static factory(config) {
    let sync = null;

    sync = new SyncApiV1();

    return sync;
  }
}

export default SyncSalesFactory;
