import { StyleSheet } from 'react-native';
import color from '~/common/color';
import { fonts } from '../../../src/utils/Fonts';

export default StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    paddingHorizontal: 16,
    paddingVertical: 12,
    paddingBottom: 0,
    borderBottomColor: color.grayContainer,
    borderBottomWidth: 4,
  },
  number: {
    color: color.grayMid,
    fontSize: 14,
    marginRight: 6,
    fontFamily: fonts.QuicksandBold,
  },
  clientName: {
    color: '#000',
    fontSize: 16,
    fontFamily: fonts.QuicksandBold,
    paddingRight: 10,
  },
  cnpjCpf: {
    color: '#000',
    fontSize: 18,
  },
  title: {
    color: color.grayMid,
    fontSize: 12,
    marginBottom: 4,
  },
  titleBlack: {
    color: 'rgb(0,0,0)',
    fontSize: 12,
  },
  date: {
    textAlign: 'right',
    fontSize: 12,
    fontFamily: fonts.QuicksandItalic,
    color: color.grayMid,
    marginBottom: 4,
  },
});
