import React from 'react';
import { View, Text } from 'react-native';

import MessageItem from '../MessageItem';
import { TYPE_MESSAGE_QUESTION } from '../../typeMessageConst';
import styles from './styles';
import Touchable from 'react-native-platform-touchable';
import translate from '../../../../locales/index';

import PropTypes from 'prop-types';

function MessageItemQuestion({ text, onPressConfirm, onPressCancel }) {
  return (
    <MessageItem type={TYPE_MESSAGE_QUESTION} text={text}>
      <View style={styles.groupButtons}>
        <View style={styles.buttons}>
          <Touchable
            onPress={onPressCancel}
            >
            <Text style={styles.button}>{`${translate('no')}`}</Text>
          </Touchable>
          <Touchable
            style={styles.yesButton}
            onPress={onPressConfirm}>
            <Text style={styles.button}>{`${translate('yes')}`}</Text>
          </Touchable>
        </View>
      </View>
    </MessageItem>
  );
}

MessageItemQuestion.propTypes = {
    text: PropTypes.string.isRequired,
    onPressConfirm: PropTypes.func.isRequired,
    onPressCancel: PropTypes.func.isRequired,
};

export default MessageItemQuestion;
