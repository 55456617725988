import { StyleSheet } from 'react-native';
import { color } from '~/common';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: color.grayContainer,
  },
  indicatorStyle: {
    paddingHorizontal: 16,
    backgroundColor: color.primaryColor,
  },
  PagerTitleIndicator: {
    backgroundColor: color.primaryColor,
  },
  selectedBorderStyle: {
    backgroundColor: color.accentColor,
  },
  indicatorTextBaseStyle: {
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.5)',
  },
});

export default styles;
