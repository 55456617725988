/* @flow */

import React, { useState } from 'react';
import { Text, View, Image, TouchableWithoutFeedback } from 'react-native';

import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import constants from '../constants';
import translate from '~/locales';
import Images from '@assets/Images';
import styles from './styles';

const sortById = (a, b) => a.id - b.id;

function Separator() {
  return <View style={styles.separator} />;
}

function ActionItem({
  actionProps,
  onPress,
  styleContainer,
  styleIcon,
  styleText,
}) {
  const { operation, icon } = actionProps;

  const onPressActionItem = () => onPress(actionProps);

  return (
    <TouchableWithoutFeedback onPress={onPressActionItem}>
      <View style={[styleContainer || styles.actionItem]}>
        <Image source={icon} style={[styleIcon || styles.actionIcon]} tintColor={'#000'} />
        <Text style={[styleText || styles.actionText]}>
          {translate(operation)}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
}

ActionItem.propTypes = {
  actionProps: PropTypes.shape({
    operation: PropTypes.string.isRequired,
    icon: PropTypes.any.isRequired,
  }),
  onPress: PropTypes.func.isRequired,
  styleContainer: PropTypes.object,
  styleIcon: PropTypes.object,
  styleText: PropTypes.object,
};

function getActions(configLib) {
  const mainActions = [];
  const otherActions = [];

  const optionsVisibility = _.get(
    configLib,
    'configuracoes.product_group.options',
    false,
  );

  const actions = (optionsVisibility
    ? constants.ACTIONS.filter(
        opts =>
          opts.alwaysVisible ||
          optionsVisibility.includes(opts.title.toLowerCase()),
      )
    : constants.ACTIONS
  ).sort(sortById);

  for (const action of actions) {
    action.id <= 3 ? mainActions.push(action) : otherActions.push(action);
  }

  return {
    mainActions,
    otherActions,
  };
}

function Actions({ configLib,  route, navigation, onPress }) {
  const [expanded, setExpanded] = useState(false);
  const localOnpress = onPress || route.params.onPress;

  const sellMore = () => setExpanded(!expanded);

  const { mainActions, otherActions } = getActions(configLib);

  return (
    <View style={styles.container}>
      <View style={styles.actionsContainer}>
        <View style={expanded ? styles.mainActionsExpanded : styles.mainActions}>
          {mainActions.map(act => {
            return (
              <ActionItem
                key={act.id}
                actionProps={{ ...act }}
                onPress={localOnpress}
              />
            );
          })}
          <ActionItem
            actionProps={{
              operation: 'sellMore',
              icon: Images.iconDotsVertical,
            }}
            onPress={sellMore}
            style={styles.sellMore}
          />
        </View>
        {expanded ? (
          <View>
            <Separator />
            <View>
              {otherActions.map(act => {
                return (
                  <ActionItem
                    key={act.id}
                    actionProps={{ ...act }}
                    onPress={localOnpress}
                    styleContainer={styles.actionItemExpanded}
                    styleIcon={styles.actionIcon}
                    styleText={styles.actionTextExpanded}
                  />
                );
              })}
            </View>
          </View>
        ) : null}
      </View>
    </View>
  );
}

Actions.propTypes = {
  configLib: PropTypes.object.isRequired,
  onPress: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    configLib: state.configLibFetch.payload,
  };
}
export default connect(mapStateToProps)(Actions);
