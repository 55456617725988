/* @flow */

import React, { Component } from 'react';
import { Image, Share, TouchableOpacity } from 'react-native';
import Orders from './Orders';
import { connect } from 'react-redux';
import get from 'lodash/get';
import some from 'lodash/some';
import { CONFIG_LIB_SUCCESS } from '~/store/configLibFetch/slice';
import { SCREEN_CART, SCREEN_ORDER_DETAIL, SCREEN_ORDERS } from '../screens';
import {
  ORDERS_FETCH_FAILURE,
  ORDERS_FETCH_SUCCESS,
  getListOrders,
} from '~/store/orders/slice';
import {
  orderDeleteFetch,
  ORDERS_DELETE_FETCH_FAILURE,
  ORDERS_DELETE_FETCH_SUCCESS,
} from '~/store/orders/orderDeleteSlice';

import {
  updateStatusOpenOrder,
  GET_CART_FETCH_REQUEST,
  ORDER_FETCH_SUCCESS,
} from '~/store/ordersFetch/orderFetchSlice';
import {
  setCurrentClient,
  setCurrentClientNoThunk,
  CLIENT_CURRENT_FETCH_FAILURE,
  CLIENT_CURRENT_FETCH_SUCCESS,
  CLIENT_CURRENT_FETCH_REQUEST,
} from '~/store/clientCurrent/slice';
import { setPushingScreen } from '~/store/pushingScreen/slice';
import { SYNC_PROCESS_SALES } from '../../constants/Sync';

import {
  MODULE_SALE,
  setModuleCurrent,
} from '../../store/makeOrderModule/action';
import executeActionOrOpenScreenMessage from '../../utils/messages';
import OrdersModule from '../../modules/OrdersModule';
import AccountsModule from '../../modules/AccountsModule';
import translate from '../../locales';
import Images from '../../../assets/Images';
import verifyOrderPending from '../../utils/orderUtils';
import {
  filterByTextFields,
  getFieldsSearch,
} from '../Filter/components/filters';
import GpsHelper from '../../utils/GpsHelper';
import getQueryResult from '../../services/resources/libFastSeller/genericQuery';
import {
  getListClients,
  CLIENTS_FETCH_SUCCESS,
} from '../../store/clientsFetch/slice';
import { syncUpdateScreen } from '../../store/syncFetch/slice';
import {
  copyOrderFetch,
  ORDERS_COPY_FETCH_REQUEST,
} from '~/store/ordersFetch/orderCopySlice';
import {
  EDIT_CART_FETCH_REQUEST,
  EDIT_CART_FETCH_SUCCESS,
  editOrderFetch,
} from '~/store/ordersFetch/orderEditSlice';
import { Colors } from '~/config/config';
import onPressHelper from '~/utils/onPressHelper';

import { currentScreenVisible as setCurrentScreenVisible } from '~/store/currentScreenVisible/slice';
import CacheDataController from '~/utils/CacheDataController';
import { emp2long } from '~/utils/FastSellerUtils';
import listGeneric from '~/services/resources/libFastSeller/listGeneric';
import { setBackRootShowModal } from '~/store/backRootShowModal/slice';
import sort from '~/utils/sorterFilter';
import GeneralModule from '../../modules/GeneralModule';
import { findSortValuesByFieldAndOrder } from '~/components/FilterGroup/helpers';

import PropTypes from 'prop-types';
import { createNotifyError, createNotifyInfo } from '~/components/Web/ToastNotify';
import { showAlert } from '~/components/Web/Alert';
import { showAlertOrders } from '~/components/Web/AlertOrders';

const COPY = 'COPY';
const EDIT = 'EDIT';
const DELETE = 'DELETE';

class OrdersContainer extends Component {
  constructor(props) {
    super(props);

    this.order = null;
    this.pkeyOrder = '';
    this.action = '';
    this.ordersRef = [];
    this.listClients = [];
    this.locationRef = {};

    this.state = {
      filteredOrders: [],
      showSearch: false,
      orderCopied: {},
      keepCopyOrder: false,
      copyNormalOrComplementary: false,
      questionAnswered: false,
      searchText: '',
      filters: {},
      filtersValues: {},
    };
    this.props.navigation.setOptions({
      headerLeft: () => (null),
      headerRight: () => (
        <TouchableOpacity onPress={() => {
          this.setState({ showSearch: !this.state.showSearch });
        }}
        >
          <Image
            source={Images.iconSearch}
            style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 10 }}
          />
        </TouchableOpacity>
      ),
    });
    this.props.navigation.addListener('focus', () => {
      this.fetchFilters();
      this.setFiltersValues({});
      this.fetchOrders();

      this.props.dispatch(
        setCurrentScreenVisible({
          screen: this.props.componentId,
          screenName: SCREEN_ORDERS.name,
        }),
      );
    });
  }

  fetchOrders() {
    const paramsBindQuery = {
      chaveCliente: '0',
      statudPedido: '-1',
      statusFiltro: '-1',
    };
    this.props.dispatch(getListOrders(paramsBindQuery));
    if (this.listClients.length === 0) {
      this.props.dispatch(getListClients());
    }
  }

  UNSAFE_componentWillReceiveProps({
    orders,
    order,
    clients,
    client,
    syncFetch,
    currentScreenVisible,
    configLib,
  }) {
    (() => {
      if (configLib.type === this.props.configLib.type) {
        return;
      }
      if (configLib.type !== CONFIG_LIB_SUCCESS) {
        return;
      }
      this.fetchOrders();
    })();
    (() => {
      const { type } = orders;

      if (type === this.props.orders.type) {
        return;
      }
      if (
        type === ORDERS_FETCH_SUCCESS ||
        type === ORDERS_DELETE_FETCH_SUCCESS
      ) {
        this.updateOrders(orders.payload);
      } else if (
        type === ORDERS_FETCH_FAILURE ||
        type === ORDERS_DELETE_FETCH_FAILURE
      ) {
        this.props.dispatch(setPushingScreen(false));
        const errorMsgs = orders.errors || [];
        const alertMsg = get(errorMsgs[errorMsgs.length - 1], 'mensagem');
        const alertCod = get(errorMsgs[errorMsgs.length - 1], 'codigo');
        if (alertMsg && alertCod !== 'list_data_less') {
          createNotifyError(alertMsg);
        }
      }
    })();

    (() => {
      const { type } = order;
      if (type === this.props.order.type) {
        return;
      }
      if (type === EDIT_CART_FETCH_REQUEST) {
        return;
      }

      if (
        this.props.order.type === EDIT_CART_FETCH_REQUEST ||
        this.props.order.type === GET_CART_FETCH_REQUEST
      ) {
        if (type === EDIT_CART_FETCH_SUCCESS /*ORDER_FETCH_SUCCESS*/) {
          const isVisible = this.props.navigation.isFocused();

          if (!isVisible) {
            return;
          }
          const { payload } = order;
          const messages = get(payload, 'mensagens', []);

          executeActionOrOpenScreenMessage(
            messages,
            () => this.actionEditOrderError(messages, payload),
            () => this.actionEditOrderNotError(payload, 'edit'),
          );
        }
      }
    })();

    (() => {
      const { type } = order;
      if (type === this.props.order.type) {
        return;
      }
      if (type === ORDERS_COPY_FETCH_REQUEST) {
        return;
      }
      if (
        this.props.order.type === ORDERS_COPY_FETCH_REQUEST ||
        this.props.order.type === GET_CART_FETCH_REQUEST
      ) {
        if (type === ORDER_FETCH_SUCCESS) {
          const isVisible =
            currentScreenVisible.screen === this.props.componentId;

          if (!isVisible) {
            return;
          }
          const { payload } = order;
          const messages = get(payload, 'mensagens') || [];
          const copyOrderContext = some(messages, [
            'codigo',
            'produto_nao_existe_mais',
          ]);

          // This code mask "Pedido não pode ser copiado" message, this is specific to this scenario"

          const filteredMessages = copyOrderContext
            ? messages.map(message => {
                if (message.codigo === 'copiar_carrinho') {
                  return {
                    ...message,
                    mensagem: translate('someProductsWillNotBeAdded'),
                  };
                }
                return message;
              })
            : messages;

          executeActionOrOpenScreenMessage(
            filteredMessages,
            () => this.actionCopyOrderError(),
            () => this.actionEditOrderNotError(order.payload, 'copy'),
            copyOrderContext ? () => this.actionButtonKeepCopyOrder() : false,
            copyOrderContext ? translate('removeProductsAndKeepCopyOrder') : '',
          );
        }
      }
    })();
    (async () => {
      const { type } = client;

      const isVisible = this.props.navigation.isFocused();

      if (type === this.props.client.type) {
        return;
      }
      if (this.props.client.type !== CLIENT_CURRENT_FETCH_REQUEST) {
        return;
      }

      if (type === CLIENT_CURRENT_FETCH_SUCCESS) {
        if (isVisible) {
          this.props.navigation.navigate(SCREEN_CART.name, {
            screen: SCREEN_ORDERS.name,
          });
          this.props.dispatch(updateStatusOpenOrder(false));
          this.props.dispatch(setModuleCurrent(MODULE_SALE));
          this.props.dispatch(setCurrentClientNoThunk(client.current));
          this.props.dispatch(setBackRootShowModal(this.props.componentId));
        }
        this.action = '';
      } else if (type === CLIENT_CURRENT_FETCH_FAILURE) {
        if (isVisible) {
          const errorMsgs = orders.errors.mensagens || [];
          const alertMsg =
            get(errorMsgs[errorMsgs.length - 1], 'mensagem') ||
            `${translate('errorCopyOrder')}`;
            createNotifyError(alertMsg);
        }
        this.props.dispatch(setPushingScreen(false));
      }
    })();

    (() => {
      if (syncFetch.type === this.props.syncFetch.type) {
        return;
      }
      if (
        syncFetch.payload.statusSync !== SYNC_PROCESS_SALES &&
        syncFetch.payload.screen === SCREEN_ORDERS.name
      ) {
        this.props.dispatch(syncUpdateScreen(''));

        if (this.action === COPY) {
          this.executeCopyOrder(this.order);
        } else if (this.action === EDIT) {
          this.props.dispatch(editOrderFetch(this.pkeyOrder));
        } else if (this.action === DELETE) {
          this.props.dispatch(orderDeleteFetch(this.pkeyOrder));
        }
      }
    })();

    (() => {
      const { type } = clients;
      if (type === this.props.clients.type) {
        return;
      }
      if (type !== CLIENTS_FETCH_SUCCESS) {
        return;
      }
      if (this.listClients.length !== 0) {
        return;
      }
      this.listClients = clients.payload;
    })();
  }

  navigationButtonPressed(event) {
    switch (event.buttonId) {
      case 'search':
        this.setState(
          { showSearch: !this.state.showSearch, searchText: '' },
          () => {
            if (!this.state.showSearch) {
              this.updateOrders(this.props.orders.payload || []);
            }
          },
        );
        break;
      case 'bottomTabSelected':
        this.setCurrentModule(SCREEN_ORDERS.name.toUpperCase());
        break;
      default:
        break;
    }
  }

  onChangeTextSearch = (searchText = '') => {
    const fields = getFieldsSearch(this.ordersRef);
    const ordersSearch = filterByTextFields(this.ordersRef, fields, searchText);

    this.setState({ filteredOrders: ordersSearch, searchText });
  };

  setCurrentModule(currentModule) {
    return new Promise((resolve, reject) => {
      AccountsModule.setCurrentModule(currentModule, resolve, reject);
    });
  }

  onSortChange = option => {
    if (option) {
      const sorted = sort(this.state.filteredOrders, option, option.sort);

      const sortFields =
        this.state.filters?.fields
          ?.sort((a, b) => a.order < b.order)
          ?.filter(i => i.to_sort) || [];
      const inferredValues = findSortValuesByFieldAndOrder(
        option.field,
        option.sort,
        sortFields,
      );

      let filtersValueCopy = { ...this.state.filtersValues };
      if (inferredValues) {
        filtersValueCopy.activeSort = inferredValues.sort;
        filtersValueCopy.selectedOrder = inferredValues.order;
        filtersValueCopy.activeSortIndex = inferredValues.sortIndex;
      }
      this.setState({
        filteredOrders: sorted,
        filtersValues: filtersValueCopy,
      });
    }
  };

  onPressItem = async order => {
    if (this.props.pushingScreen.status) {
      return;
    }
    this.props.dispatch(setPushingScreen(true));

    try {
      const data = await OrdersModule.getObservationLink(order.numero_pedido);
      console.log('LFSX 🚀 | file: Orders.Container.js:401 | data =>', data);
    } catch (err) {
      console.log('LFSX 🚀 | file: Orders.Container.js:402 | err =>', err);
    }

    const showOrderDetailHeaderAsHTML = get(
      this.props.configLib.payload,
      'listagem_campos_visibilidade.order_detail.show_html',
      false,
    );

    const orderNumber =
      order.codigo_interno !== '0' ? order.codigo_interno : order.numero_pedido;
      this.props.navigation.navigate(SCREEN_ORDER_DETAIL.name, {
        orderParams: order,
        showOrderDetailHeaderAsHTML,
      });
    };

  onPressItemHelper = onPressHelper.debounce(this.onPressItem);

  onCopyPress = async order => {
    const { hasPendingOrder, message } = await verifyOrderPending();

    if (!hasPendingOrder) {
      this.setState({ orderCopied: order || {} });
      this.action = COPY;

      const { syncFetch } = this.props;
      const { statusSync } = syncFetch.payload;
      if (statusSync !== SYNC_PROCESS_SALES) {
        if (this.props.pushingScreen.status) {
          return;
        }
        this.props.dispatch(setPushingScreen(true));
        this.executeCopyOrder(order);
      } else {
        this.order = order;
        this.props.dispatch(syncUpdateScreen(SCREEN_ORDERS.name));
      }
    } else {
      createNotifyInfo(message);
    }
  };

  onPressCopyHelper = onPressHelper.debounce(this.onCopyPress);

  onDeletePress = deleteOrderSelect => {
    showAlert(`${translate('deleteCart')}`, `${translate('wantDeleteThisCart')}\n\n ${translate(
      'afterDeleteCanNotGoback',
    )}`, () => this.deleteOrder(deleteOrderSelect));

  };

  onPressDeleteHelper = onPressHelper.debounce(this.onDeletePress);

  onEditPress = async order => {
    const { hasPendingOrder, message } = await verifyOrderPending();

    if (!hasPendingOrder) {
      const { syncFetch } = this.props;
      const { statusSync } = syncFetch.payload;

      const pkeyOrder = order.numero_pedido || order.codigo;
      if (statusSync !== SYNC_PROCESS_SALES) {
        if (this.props.pushingScreen.status) {
          return;
        }
        this.props.dispatch(setPushingScreen(true));
        this.props.dispatch(editOrderFetch(pkeyOrder));
      } else {
        this.action = EDIT;
        this.pkeyOrder = pkeyOrder;
        this.props.dispatch(syncUpdateScreen(SCREEN_ORDERS.name));
      }
    } else {
      createNotifyError(message);
    }
  };

  onPressEditHelper = onPressHelper.debounce(this.onEditPress);

  getClientByPkey = async (pkey, isJoker = false) => {
    let clie = null;
    if (isJoker) {
      try {
        const tamMemory = get(
          this.props.configLib,
          'payload.tam_memoria_paginacao',
        );

        const resp = await listGeneric(
          emp2long('CCOR'),
          emp2long('CCPR'),
          {},
          tamMemory,
        );
        clie = resp?.data?.find(cli => cli.pkey === pkey);
      } catch (err) {
        console.log('ERROR GET CLIENTES_CORINGA =>', err.message);
      }
    } else {
      clie = this.listClients.find(cli => cli.pkey === pkey);
    }
    return clie;
  };

  deleteOrder(deleteOrderSelect) {
    const { syncFetch } = this.props;
    const { statusSync } = syncFetch.payload;
    if (statusSync !== SYNC_PROCESS_SALES) {
      this.props.dispatch(orderDeleteFetch(deleteOrderSelect));
    } else {
      this.action = DELETE;
      this.pkeyOrder = deleteOrderSelect;
      this.props.dispatch(syncUpdateScreen(SCREEN_ORDERS.name));
    }
    CacheDataController.removeItem(`${deleteOrderSelect.numero_pedido}_LINK`);
  }

  updateOrders(orders, calback = () => {}) {
    this.setState({ filteredOrders: orders }, calback);
    this.ordersRef = orders;
    if (this.props.setList) {
      this.props.setList(orders);
    }
    if (this.state.searchText) {
      this.onChangeTextSearch(this.state.searchText);
    }
  }

  onLocationChangeFromMap = location => {
    if (location) {
      this.locationRef = location; // Using ref to prevent rerenders
    }
  };

  async getOrderData(pkey) {
    const result =
      (await getQueryResult(
        'DADOS;FTSL;PEDC',
        JSON.stringify({ 1: pkey }),
        false,
      )) || '[]';
    const parsedResult = JSON.parse(result);
    return parsedResult;
  }

  async validateGPSPoints(order, type) {
    const location = this.locationRef;
    const pkeyOrder = order.numero_pedido || order.codigo;

    const extraOrderData = await this.getOrderData(pkeyOrder);
    const orderInfo = extraOrderData[0] || {};

    const {
      requiredOnEdit,
      verifyAreaOnEdit,
      requiredOnCopy,
      verifyAreaOnCopy,
      verifyAreaOnShare,
      requiredOnShare,
    } = this.getGPSConfigs();

    const clientFind = await this.getClientByPkey(
      orderInfo.codcliente,
      order.cliente_coringa,
    );

    const args = {
      required: '',
      verify: '',
      client: clientFind,
      location,
    };

    if (type === 'copy') {
      args.required = requiredOnCopy;
      args.verify = verifyAreaOnCopy;
    } else if (type === 'edit') {
      args.required = requiredOnEdit;
      args.verify = verifyAreaOnEdit;
    } else if (type === 'share') {
      args.required = requiredOnShare;
      args.verify = verifyAreaOnShare;
    }

    const { msgs } = GpsHelper.validateRulesByConfigs(args);

    return new Promise((resolve, reject) => {
      if (msgs.length) {
        createNotifyError(msgs.join('. '));
        reject(new Error('GPS validation error'));
      } else {
        resolve();
      }
    });
  }

  async saveEventOrder(pkeyOrder, type) {
    const location = this.locationRef;

    return new Promise((resolve, reject) => {
      if (type === 'edit') {
        OrdersModule.saveEventEditOrder(
          pkeyOrder,
          JSON.stringify(location),
          resolve,
          reject,
        );
      } else if (type === 'copy') {
        OrdersModule.saveEventOpenOrder(
          pkeyOrder,
          JSON.stringify(location),
          resolve,
          reject,
        );
      }
    });
  }

  async executeCopyOrder(order) {
    const { numero_pedido: numeroPedido } = order;
    const {
      keepCopyOrder = false,
      copyNormalOrComplementary,
      questionAnswered,
    } = this.state;
    try {
      let typeCopyOrder = await OrdersModule.copyTypeOrder(numeroPedido);
      typeCopyOrder = JSON.parse(typeCopyOrder);

      const { type, copy_complement: copyComplement } = typeCopyOrder;
      const copyComplementary =
        type !== 'question_user' ? !!copyComplement : copyNormalOrComplementary;

      if (type === 'question_user' && !questionAnswered) {
        showAlertOrders(`${translate('chooseCopyType')}`, 
        `${translate('questionCopyComplementary')}`, 
        `${translate('complementary')}`,
        `${translate('normal')}`,
        () => {
          this.setState({
            copyNormalOrComplementary: false,
          });
          const payload = {
            copyComplementary: false,
            order,
            keepCopyOrder,
          };
          this.props.dispatch(copyOrderFetch(payload));
        }
        , () => {
          this.setState({
            copyNormalOrComplementary: true,
          });
          const payload = {
            copyComplementary: true,
            order,
            keepCopyOrder,
          };
          this.props.dispatch(copyOrderFetch(payload));
        }
        );
      } else {
        const payload = {
          copyComplementary,
          order,
          keepCopyOrder,
        };
        this.props.dispatch(copyOrderFetch(payload));
        this.setState({ questionAnswered: false });
      }
    } catch (errors) {
      const jssonArray = JSON.parse(errors.message);
      createNotifyError(jssonArray[0].mensagem);
    }
  }

  actionCopyOrderError = () => {
    this.props.dispatch(setPushingScreen(false));
    this.props.dispatch(
      setCurrentScreenVisible({
        screen: this.props.componentId,
        screenName: SCREEN_ORDERS.name,
      }),
    );

    this.action = '';
    new Promise((res, rej) => {
      OrdersModule.cancelActiveCart(res, rej);
    }).then(() => {});
  };

  actionEditOrderError = async (messages, order) => {
    const messagesErrors = messages.filter(m => m.tipo === 'erro');
    const condSaleInvalid = messagesErrors.find(
      m => m.codigo === 'condicoes_venda_invalida',
    );

    if (condSaleInvalid && messagesErrors.length === 1) {
      const { codcliente } = order.condvenda;
      const clientFind = await this.getClientByPkey(
        codcliente,
        order.cliente_coringa,
      );
      this.props.dispatch(setCurrentClient(clientFind));
    } else {
      this.props.dispatch(setPushingScreen(false));
    }
  };

  cancelCart = async () =>
    new Promise((res, rej) => OrdersModule.cancelActiveCart(res, rej));

  actionButtonKeepCopyOrder = async () => {
    this.setState(
      {
        keepCopyOrder: true,
        questionAnswered: true,
      },
      async () => {
        const { orderCopied } = this.state;
        try {
          await this.cancelCart();
          await this.onCopyPress(orderCopied);
        } catch (err) {
        } finally {
          this.setState({ keepCopyOrder: false });
        }
      },
    );
  };

  actionEditOrderNotError = async (order, type) => {
    const { codcliente } = order.condvenda;
    const pkeyOrder = order.numero_pedido || order.codigo;
    this.saveEventOrder(pkeyOrder, type);
    const clientFind = await this.getClientByPkey(
      codcliente,
      order.cliente_coringa,
    );
    this.props.dispatch(
      setCurrentScreenVisible({
        screen: this.props.componentId,
        screenName: SCREEN_ORDERS.name,
      }),
    );
    this.props.dispatch(setCurrentClient(clientFind));

    this.action = '';
  };

  onSharePress = async order => {
    await this.validateGPSPoints(order, 'share');

    const shareLink = await CacheDataController.getData(
      `${order.numero_pedido}_LINK`,
    );

    if (!shareLink) {
      createNotifyInfo('Link de compartilhamento não disponível');
      return;
    }

    await Share.share({
      message: shareLink,
    });
  };

  getGPSConfigs() {
    const {
      configLib: { payload },
    } = this.props;

    const isTrueSet = str => str === 'true';
    const requiredOnEdit = isTrueSet(
      get(payload, 'gps.required_edit_demand', 'false'),
    );
    const verifyAreaOnEdit = isTrueSet(
      get(payload, 'gps.verify_area_client_edit_demand', 'false'),
    );

    const requiredOnCopy = isTrueSet(
      get(payload, 'gps.required_copying_demand', 'false'),
    );
    const verifyAreaOnCopy = isTrueSet(
      get(payload, 'gps.verify_area_client_copying_demand', 'false'),
    );

    const requiredOnShare = isTrueSet(
      get(payload, 'gps.required_sharing_demand', 'false'),
    );
    const verifyAreaOnShare = isTrueSet(
      get(payload, 'gps.verify_area_client_sharing_demand', 'false'),
    );

    const showLocationComponent =
      requiredOnEdit || requiredOnCopy || requiredOnShare;

    return {
      showLocationComponent,
      requiredOnEdit,
      verifyAreaOnEdit,
      verifyAreaOnCopy,
      requiredOnCopy,
      verifyAreaOnShare,
      requiredOnShare,
    };
  }

  fetchFilters = () =>
    new Promise((resolve, reject) => {
      GeneralModule.getFilters(
        `${emp2long('FILT')}`,
        `${emp2long('PEDG')}`,
        '',
        '',
        resolve,
        reject,
      );
    }).then(resp => {
      const data = JSON.parse(resp || '{}')?.data;
      const filters = data?.find(fi => fi.context === 'orders');
      this.setState({ filters });
    });

  onListFiltered = list => {
    this.setState({ filteredOrders: list });
  };

  setFiltersValues = values => {
    this.setState({ filtersValues: values });
  };

  render() {
    const {
      filteredOrders,
      showSearch,
      filters,
      filtersValues,
      searchText,
    } = this.state;

    const location = this.locationRef;

    const configExportReport = get(
      this.props.configLib,
      'payload.configuracoes.report.export_order',
    );
    const showOrderHeaderAsHTML = get(
      this.props.configLib.payload,
      'listagem_campos_visibilidade.orders.show_html',
      false,
    );

    const { showLocationComponent } = this.getGPSConfigs();

    const loadingGeral = this.props.order.generalLoading;

    const syncData =
      this.props.syncFetch.payload.statusSync !== SYNC_PROCESS_SALES &&
      this.props.syncFetch.payload.screen === SCREEN_ORDERS.name;

    return (
      <Orders
        orderComponentId={this.props.componentId}
        onPress={this.onPressItemHelper}
        onCopyPress={this.onPressCopyHelper}
        onDeletePress={this.onPressDeleteHelper}
        onEditPress={this.onPressEditHelper}
        onSharePress={this.onSharePress}
        orders={filteredOrders}
        loading={loadingGeral}
        failure={this.props.orders.erro}
        syncData={syncData}
        configExportReport={configExportReport}
        showSearch={showSearch}
        onChangeTextSearch={this.onChangeTextSearch}
        showOrderHeaderAsHTML={showOrderHeaderAsHTML}
        onLocationChangeFromMap={this.onLocationChangeFromMap}
        location={location}
        searchText={searchText}
        gpsConfigs={{
          showLocationComponent,
        }}
        onSortChange={this.onSortChange}
        filters={filters}
        onListFiltered={this.onListFiltered}
        ordersRef={this.ordersRef}
        setFiltersValues={this.setFiltersValues}
        filtersValues={filtersValues}
        navigation={this.props.navigation}
      />
    );
  }
}

OrdersContainer.propTypes = {
  orders: PropTypes.object,
  order: PropTypes.object,
  client: PropTypes.object,
  clients: PropTypes.object,
  dispatch: PropTypes.func,
  pushingScreen: PropTypes.object,
  setList: PropTypes.func,
  syncFetch: PropTypes.object,
  configLib: PropTypes.object,
  componentId: PropTypes.string,
  currentScreenVisible: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    orders: state.orders,
    clients: state.clientsFetch,
    order: state.orderFetch,
    client: state.clientCurrent,
    pushingScreen: state.pushingScreen,
    syncFetch: state.syncFetch,
    configLib: state.configLibFetch,
    currentScreenVisible: state.currentScreenVisible,
    permission: state.permissionsFetch,
  };
}

export default connect(mapStateToProps)(OrdersContainer);
