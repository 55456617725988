import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App.web';

const env = process.env.NODE_ENV;

if (env === 'production') {
  if (typeof console._commandLineAPI !== 'undefined') {
    console.API = console._commandLineAPI;
  } else if (typeof console._inspectorCommandLineAPI !== 'undefined') {
    console.API = console._inspectorCommandLineAPI;
  } else if (typeof console.clear !== 'undefined') {
    console.API = console;
  }
  console.API.clear();

  const noOp = function() {};
  window.console = {
    log: noOp,
    info: noOp,
    warn: noOp,
    debug: noOp,
    error: noOp,
    exception: noOp,
    table: noOp,
    trace: noOp,
    dir: noOp,
    dirxml: noOp,
    group: noOp,
    groupCollapsed: noOp,
    groupEnd: noOp,
    time: noOp,
    timeEnd: noOp,
    clear: noOp,
    count: noOp,
    assert: noOp,
    markTimeline: noOp,
    profile: noOp,
    profileEnd: noOp,
    timeline: noOp,
    timelineEnd: noOp,
    timeStamp: noOp,
    context: noOp,
  };
}

ReactDOM.createRoot(document.getElementById('app')).render(<App />);
