import _ from 'lodash';

import translate from '../../../locales';
import HandlerActivityValidate from '../ActivityValidateHandler';
import { createNotifyInfo } from '~/components/Web/ToastNotify';

class HandlerLocation extends HandlerActivityValidate {
  isAllActivitiesRequiredDone = async (item, listActivities) => {
    const { payload: activities } = await listActivities;
    const requiredNotDone = _.filter(
      activities,
      act => ![item.id !== act.id, !act.answer, act.required].includes(false),
    );

    if (requiredNotDone.length) {
      createNotifyInfo(translate('activityRequiredNotDone'));
      this.openTask = false;
    }
  };

  async handle(item, listActivities, originRequest, event) {
    const setup = JSON.parse(item.setup || '{}');
    const { action = '' } = setup;
    if (action === 'checkout') {
      await this.isAllActivitiesRequiredDone(item, listActivities);
    }

    const { canOpenTask } = this.openTask;
    if (canOpenTask) {
      await this.next(item, listActivities, originRequest, event);
    }
    return canOpenTask;
  }
}

export default HandlerLocation;
