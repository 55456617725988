import { Alert } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import Toast from 'react-native-root-toast';

import HandlerActivityValidate from '../ActivityValidateHandler';
import translate from '../../../locales';
import Snackbar from 'react-native-snackbar';

class HandlerStopWatch extends HandlerActivityValidate {
  infoTimer = async () => {
    try {
      const infoTimer = await AsyncStorage.getItem('info_timer');
      return JSON.parse(infoTimer) || {};
    } catch (err) {
      /* eslint no-console: */
      console.tron.log('_fetchInfoTimer', err);
    }
    return null;
  };

  async isToTimerRegistered(item) {
    const { id } = await this.infoTimer();
    const { id: taskId, answer } = item;
    const { retry } = JSON.parse(item.setup || '{}');

    const timerRegistered = ![
      !id || taskId === id,
      !!answer,
      retry === false,
    ].includes(false);
    if (timerRegistered) {
      Snackbar.show({ text: `${translate('timerAlreadyRegistered')}` });
      this.openTask = false;
    }
  }

  async handle(item, listActivities, originRequest, event) {
    await this.isToTimerRegistered(item);

    const { canOpenTask } = this.openTask;
    if (canOpenTask) {
      await this.next(item, listActivities, originRequest, event);
    }
    return canOpenTask;
  }
}

export default HandlerStopWatch;
