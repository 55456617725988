/* @flow */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Info from './Info';
import { SCREEN_ADD_CLIENT } from '../../screens';
import NavigationHelper from '~/screens/NavigationHelper';

import PropTypes from 'prop-types';

class InfoContainer extends Component {
  constructor(props) {
    super(props);
  }

  onClickAdd = () => {
    NavigationHelper.push(
      this.props.componentId,
      SCREEN_ADD_CLIENT.name,
      SCREEN_ADD_CLIENT.title,
    );
  };

  render() {
    return (
      <Info
        openMaps={this.props.openMaps}
        openPhone={this.props.openPhone}
        item={this.props.item}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    account: [],
  };
}

InfoContainer.propTypes = {
  item: PropTypes.object,
  openMaps: PropTypes.func,
  openPhone: PropTypes.func,
};

export default connect(mapStateToProps)(InfoContainer);
