import React, { Component } from 'react';
import { Image, TouchableOpacity } from 'react-native';
import _ from 'lodash';
import { connect } from 'react-redux';

import Catalog from './Catolog';

import Images from '@assets/Images';
import listCatolog from '../../services/resources/libFastSeller/catalog';
import NavigationHelper from '../NavigationHelper';
import getImageProds from '~/utils/GetImageProduct';
import { isShowMultiTabs } from '~/utils/GlobalUtils';
import {
  filterByTextFields,
  getFieldsSearch,
} from '../Filter/components/filters';

import PropTypes from 'prop-types';

class CatalogContainer extends Component{
  constructor(props) {
    super(props);
    this.state = {
      filteredCatalog: [],
      showSearch: false,
      searchText: '',
      showMultiTabs: false,
    };
    this.catalogRef = [];
    this.showProductAsHTML = false;
  }

  // Evitar que seja exibido na tela a alteracao do 'padrao' para o template
  UNSAFE_componentWillMount() {
    this.showProductAsHTML = this.isShowProductAsHTML();
    const showMultiTabs = isShowMultiTabs(
      this.props.configLib,
      'catalog',
      'detalhes_produto',
    );

    this.setState({ showMultiTabs });
  }

  loadCatalog = async () => {
    try {
      const tamMemory = _.get(this.props.configLib, 'tam_memoria_paginacao');
      const listCatalog = await listCatolog(tamMemory);
      const jsonCatalog = JSON.parse(listCatalog).data;
      const prodsWithImage = await getImageProds(jsonCatalog);
      this.setState({ filteredCatalog: prodsWithImage });
      this.catalogRef = prodsWithImage;
    } catch (errors) {
      this.setState({ filteredCatalog: [] });
      this.catalogRef = [];
      if (typeof errors === 'object') {
        return;
      }
      const jsonArray = JSON.parse(errors);
      console.log('ERROR_LOAD_CATALOG:', jsonArray);
    }
  };

  async componentDidMount() {
    this.setLeftButtons();
    this.setRightButtons();
    this.loadCatalog();
  }

  onChangeTextSearch = searchText => {
    const fields = getFieldsSearch(this.catalogRef);
    const ordersSearch = filterByTextFields(
      this.catalogRef,
      fields,
      searchText,
    );

    this.setState({ filteredCatalog: ordersSearch, searchText });
  };

  debouncedOnChangeTextSearch = _.debounce(this.onChangeTextSearch, 500);

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.configLib) {
      this.showProductAsHTML = this.isShowProductAsHTML();
    }
  }

  navigationButtonPressed({ buttonId }) {
    if (buttonId === 'search') {
      this.setState(
        {
          showSearch: !this.state.showSearch,
          searchText: '',
        },
        () => {
          if (!this.state.showSearch) {
            this.setState({ filteredCatalog: this.catalogRef });
          }
        },
      );
      return;
    }
  }

  isShowProductAsHTML = () =>
    this.props.configLib
      ? _.get(
          this.props.configLib,
          'listagem_campos_visibilidade.catalog.show_html',
          false,
        )
      : false;

  handleBackPress = () => {
    NavigationHelper.pop(this.props.componentId);
    return true;
  };

  handleChangeText = text => {
    clearTimeout(this);
    setTimeout(() => {
      this.setState({ searchText: text });
    }, 400);
  };

  setLeftButtons() {
    this.props?.navigation?.setOptions({
        headerLeft: () => (
            <TouchableOpacity onPress={() => {
              this.props.navigation.pop();
            }}
            >
              <Image
                source={Images.iconBack}
                style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
              />
            </TouchableOpacity>
          ),
    });
  }

  setRightButtons = () => {
    this.props?.navigation?.setOptions({
      headerRight: () => (
        <TouchableOpacity onPress={() => {
          this.setState({ showSearch: !this.state.showSearch, searchText: '' });
        }}
        >
          <Image
            source={Images.iconSearch}
            style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 10 }}
          />
        </TouchableOpacity>
      ),
    });
  };

  render() {
    const {
      showSearch,
      showMultiTabs,
      searchText,
      filteredCatalog,
    } = this.state;
    const { config } = this.props;
    const { payload = {} } = config;
    const {
      url_stocker: urlStocker = '',
      token_stocker: tokenStocker = '',
    } = payload;

    return (
      <Catalog
        catalog={filteredCatalog}
        config={{ url_stocker: urlStocker, token_stocker: tokenStocker }}
        showSearch={showSearch}
        onChangeText={this.debouncedOnChangeTextSearch}
        showProductAsHTML={this.showProductAsHTML}
        showMultiTabs={showMultiTabs}
        navigator={this.props.navigator}
        configLib={this.props.configLib}
        componentId={this.props.componentId}
        searchText={searchText}
        navigation={this.props.navigation}
      />
    );
  }
}

CatalogContainer.propTypes = {
  navigator: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  configLib: PropTypes.object.isRequired,
  componentId: PropTypes.string.isRequired,
  navigation: PropTypes.object
};

function mapStateToProps(state) {
  return {
    config: state.configFetch,
    configLib: state.configLibFetch.payload,
  };
}

export default connect(mapStateToProps)(CatalogContainer);
