import React from 'react';
import { View, Image, StyleSheet } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import { color, style } from '~/common/index';
import Images from '@assets/Images';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
    outsideContainer: {
        borderRadius: 10,
        overflow: 'hidden',
    },
    removeButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 1,
    },
    container: {
        borderRadius: 10,
        backgroundColor: color.grayContainer,
        height: 100,
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        color: color.primaryColor,
        fontSize: 16,
    },
    icon: {
        alignSelf: 'center',
        height: 150,
        width: 150,
    },
    iconRemove: {
        margin: 5,
        width: 25,
        height: 25,
        tintColor: color.grayRed,
    },
    iconDefault: {
        alignSelf: 'center',
        tintColor: color.grayMid,
        height: 50,
        width: 50,
    },
});

const Option = ({
    onPress,
    title,
    icon,
    onRemove,
    containerStyle,
    source,
    editable = true,
}) => (
    <View style={styles.outsideContainer}>
        <Touchable
            onPress={onPress}
            disabled={!editable}>
            <View style={[styles.container, containerStyle]}>
                {source !== Images.iconCamera && (
                    <View style={styles.removeButton}>
                        <Touchable onPress={onRemove}>
                            <Image
                                source={Images.iconDelete}
                                style={styles.iconRemove}
                                resizeMode="stretch"
                            />
                        </Touchable>
                    </View>
                )}
                <View style={style.rowDirection}>
                    <Image
                        style={
                            source === Images.iconCamera ? styles.iconDefault : styles.icon
                        }
                        source={source}
                        resizeMode="stretch"
                    />
                </View>
            </View>
        </Touchable>
    </View>
);


Option.propTypes = {
    onPress: PropTypes.func,
    onRemove: PropTypes.func,
    title: PropTypes.string,
    icon: PropTypes.number,
    containerStyle: PropTypes.any,
    source: PropTypes.any,
    editable: PropTypes.bool,
};

export default Option;
