import React, { useMemo } from 'react';
import RawBottomSheet from '../RawBottomSheet/RawBottomSheet';
import PropTypes from 'prop-types';
import ProductItem from './ProductItem';
import CryptoJS from 'react-native-crypto-js';

function ProductListGroup({
  setRef,
  items,
  onPressConfirm,
  onPressRemove,
  onPressRemoveAll,
  configImageSouce,
}) {
  const mappedItems = useMemo(
    () =>
      items.reduce((acc, item) => {
        const name = item?.nome;
        const hashedName = CryptoJS.MD5(name).toString();
        acc[hashedName] = item;
        return acc;
      }, {}),
    [items],
  );

  const searchByName = name => {
    const hashedName = CryptoJS.MD5(name).toString();
    return hashedName in mappedItems ? mappedItems[hashedName] : undefined;
  };

  const renderItem = item => {
    const searchedItem = searchByName(item);
    if (!searchedItem) {
      return null;
    }

    return (
      <ProductItem
        onRemove={onPressRemove}
        product={searchedItem}
        configImageSouce={configImageSouce}
      />
    );
  };

  return (
    <RawBottomSheet
      height={450}
      setRef={ref => setRef(ref)}
      itens={items.map(pd => pd.nome)}
      showSearch
      showConfirmButton={items && items.length > 0}
      onPressConfirm={onPressConfirm}
      onPressRemoveAll={onPressRemoveAll}
      renderComponentItem={(item, index) => renderItem(item)}
    />
  );
}

ProductListGroup.propTypes = {
  setRef: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.object),
  onPressConfirm: PropTypes.func,
  onPressRemove: PropTypes.func,
  configImageSouce: PropTypes.objectOf(PropTypes.any),
  onPressRemoveAll: PropTypes.func,
};

export default ProductListGroup;
