import React, { Component } from 'react';
import { Image, ScrollView, Text, View } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import Images from '~/../assets/Images';
import translate from '~/locales';
import BottomSheet from '../BottomSheet';
import RawBottomSheet from '../RawBottomSheet/RawBottomSheet';
import AddrAlternativeForm from './AddressAlternativeForm';
import { getAddressAlternative } from '~/store/addressAlternativeFetch/slice';
import styles from './styles';

import PropTypes from 'prop-types';

class AddressAlternative extends Component {
  getAddressComplete({
    logradouro: addressName = '',
    numero: addressNumber = '',
    bairro: district = '',
    cidade: city = '',
    uf: stateCode = '',
  }) {
    return [
      `${addressName}`,
      `${addressNumber}`,
      `${district}`,
      `${city}/${stateCode}`,
    ]
      .filter(vl => !!vl)
      .join(', ');
  }

  onChangeAddressAlt(selected) {
    const { onChangeAddressAlt } = this.props;
    if (onChangeAddressAlt) {
      onChangeAddressAlt(selected);
    }
    this.RbSheetRef.close();
  }

  onSaveReachedAddrAlternative() {
    const { pkeyClient = false } = this.props;
    if (pkeyClient) {
      this.props.dispatch(getAddressAlternative({ pkeyClient }));
    }
    this.modalRef.close();
  }

  renderAddrAlternativeForm() {
    const { pkeyClient, location } = this.props;

    return (
      <ScrollView style={{ flex: 1 }}>
        <BottomSheet
          ref={ref => {
            this.modalRef = ref;
          }}
          height={700}
          onClose={this.onClose}>
          <AddrAlternativeForm
            pkeyClient={pkeyClient}
            location={location}
            onSaveReached={() => this.onSaveReachedAddrAlternative()}
          />
        </BottomSheet>
      </ScrollView>
    );
  }

  onPressCreateAddressAlt() {
    this.RbSheetRef.close();
    if (this.modalRef) {
      this.modalRef.open();
    }
  }

  renderItem(address) {
    const { descricao: title = '' } = address;
    const addrComplete = this.getAddressComplete(address);
    return (
      <View style={{ marginHorizontal: 2 }}>
        <Text style={styles.title}>{title}</Text>
        <View style={styles.addrComplete}>
          <Text style={{ fontSize: 16, marginHorizontal: 2 }}>
            {addrComplete}
          </Text>
        </View>
      </View>
    );
  }

  render() {
    const { listAddressAlt, address } = this.props;

    return (
      <View style={styles.container}>
        <Text style={styles.header}>{translate('addrCheckin')}</Text>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}>
          <View>
            <Image source={Images.iconMapMarker} style={styles.img} />
          </View>
          {this.renderItem(address)}
        </View>
        <Touchable
          onPress={() => this.RbSheetRef.open()}>
          <Text style={styles.btn}>{translate('addrChange')}</Text>
        </Touchable>
        <RawBottomSheet
          setRef={ref => {
            this.RbSheetRef = ref;
          }}
          height={450}
          showSearch
          itens={listAddressAlt}
          itemSelected={address}
          renderComponentItem={addr => this.renderItem(addr)}
          onSelectOption={selected => this.onChangeAddressAlt(selected)}
          showConfirmButton
          btnTitle={translate('addrCreate')}
          onPressConfirm={() => this.onPressCreateAddressAlt()}
        />
        {this.renderAddrAlternativeForm()}
      </View>
    );
  }
}

AddressAlternative.propTypes = {
    address: PropTypes.object.isRequired,
    listAddressAlt: PropTypes.array.isRequired,
    onChangeAddressAlt: PropTypes.func.isRequired,
    pkeyClient: PropTypes.any.isRequired,
    location: PropTypes.any.isRequired,
};

export default AddressAlternative;
