/* @flow */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import ConfigRepository from '../../database/repository/ConfigRepository';

export const CONFIG_REQUEST = 'CONFIG_REQUEST';
export const CONFIG_SUCCESS = 'CONFIG_SUCCESS';
export const CONFIG_FAILURE = 'CONFIG_FAILURE';

export const getConfig = createAsyncThunk('configFetch/getConfig', async () => {
  let resp = {};
  try {
    resp = await new ConfigRepository().get();
  } catch (err) {
    console.tron.log(
      'PE_APP | file: slice.js:12 | CONFIG_FETCH_FAILURE =>',
      err,
    );
  }
  return resp;
});

const initialState = {
  type: '',
  loading: false,
  erro: false,
  payload: {},
};

export const configFetchSlice = createSlice({
  name: 'configFetch',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getConfig.pending, (state, action) => {
        state.type = CONFIG_REQUEST;
        state.loading = true;
        state.erro = false;
      })
      .addCase(getConfig.fulfilled, (state, action) => {
        const { payload } = action;
        state.type = CONFIG_SUCCESS;
        state.loading = false;
        state.erro = false;
        state.payload = payload;
      })
      .addCase(getConfig.rejected, (state, action) => {
        const { payload } = action;
        state.type = CONFIG_FAILURE;
        state.loading = false;
        state.erro = true;
        state.payload = payload;
      });
  },
});

const { actions, reducer } = configFetchSlice;

export default reducer;
