import NetInfo from '@react-native-community/netinfo';

import {
  CHECK_INTERNET_CONNECT,
  ERROR_NETWORK_CONNECT,
  USER_NOT_AUTHORIZED,
} from '../../constants/Sync';
import translate from '../../locales';
import DeviceInfo from 'react-native-device-info';
import CacheDataController from '../../utils/CacheDataController';

export async function getErrorByStatus(error, codeContext, messageContext) {
  let code = codeContext;
  let message = messageContext;

  if (error.status === CHECK_INTERNET_CONNECT) {
    const connectionInfo = await NetInfo.fetch();

    if (connectionInfo.type === 'none' || connectionInfo.type === 'unknown') {
      code = ERROR_NETWORK_CONNECT;
      message = `${translate('errorInternetConnection')}`;
    }
  } else if (error.status === 401) {
    code = USER_NOT_AUTHORIZED;
    message = `${translate('errorDeviceNotAuthorized')}`;
  }

  return { code, message };
}

export function getErrorByLib(error) {
  const code = error.status;
  let { message } = error;

  if (code === USER_NOT_AUTHORIZED) {
    message = `${translate('errorDeviceNotAuthorized')}`;
  }

  return { code, message };
}

async function getDeviceInfo() {
  const deviceInfoCache = await CacheDataController.getData(
    '@Session:deviceInfo',
  );
  const uuid =
    deviceInfoCache && deviceInfoCache.uuid ? deviceInfoCache.uuid : '';
  // TODO: Enviar infos de armazenamento no DVSZ
  // const diskTotalCapacityBytes = await DeviceInfo.getTotalDiskCapacity();
  // const diskUtilizationBytes = diskTotalCapacityBytes - DeviceInfo.getFreeDiskStorage();
  // const diskUtilizationGB = diskUtilizationBytes / (1024 * 1024 * 1024);
  // const diskTotalCapacityGB = diskTotalCapacityBytes / (1024 * 1024 * 1024);
  const batteryLevel = 0;

  const capabilities = {
    BATT: `${parseFloat(batteryLevel).toFixed(2) * 100}%`,
    OSVR: '1.0.0',
    PLAT: 'web',
    MDEL: 'web',
    VERS: '1.0.0_ANYWHERE',
    PRDC: 'web',
    MFCT: 'web',
    IMEI: '',
    IMSI: '',
    VARS: '1',
    UUID: uuid,
    NWIP: '',
    NWIF: {
      mac: '',
    },
    DVSZ: {
      internal: {
        used: '',
        total: '',
      },
    },
  };

  return capabilities;
}

export async function sendCapabilities() {
  return true;
}
