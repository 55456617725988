/* @flow */

import React from 'react';
import { BackHandler, Alert, TouchableOpacity, Image } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Stopwatch from './Stopwatch';
import GPSManager from '../../../controllers/GPS/GPSManager';
import GpsHelper from '../../../utils/GpsHelper';
import Gps from '../../../../components_base/src/components/Gps/Gps';

import _, { create } from 'lodash';
import stopwatch from './api/stopwatch';
import translate from '../../../locales';
import AddressAltCache from '~/cache/AddressAltCache';
import { createNotifyError, createNotifyInfo } from '~/components/Web/ToastNotify';
import { showAlert } from '~/components/Web/Alert';
import Images from '@assets/Images';

type Props = {
  item: {
    kind_key: string,
    required: boolean,
    name: string,
    company: string,
    id: string,
    setup: {
      blocking: boolean,
      retry: boolean,
    },
  },
  dateSelected: any,
  saveAnswerActivity: (
    payloadAnswer: Object<any>,
    successSaveAnswer: Function<any>,
    failureSaveAnswer: Function<any>,
  ) => void,
  navigator: {
    pop: () => void,
  },
};

class StopwatchContainer extends React.Component<Props, void> {
  constructor(props) {
    super(props);
    this.stopwatch = stopwatch(this.updateTime);
    this.state = {
      time: 0,
      timeString: '00:00:00',
      message: '',
      dateOpen: '',
      isToFinish: false,
      isRunning: false,
      location: {
        accuracy: Infinity,
        altitude: null,
        heading: null,
        latitude: null,
        longitude: null,
        speed: 0.0,
        timestamp: 0,
      },
    };
    this.addressAltSelected = false;
    this.gpsManager = new GPSManager();
  }

  async UNSAFE_componentWillMount() {
    const infoTimer =
      JSON.parse(await AsyncStorage.getItem('info_timer')) || {};
    const { isRunning, isToFinish, time } = infoTimer;
    let timer;
    if (!isRunning) {
      const retry = _.get(this.props.route.params.pushObject, 'item.setup.retry') || true;
      const hasAnswer = _.get(this.props.route.params.pushObject, 'item.answer.payload.time');
      if (!retry || !hasAnswer) {
        timer = infoTimer;
      }
      this.setup(timer);
    }
    this.setup({ isRunning, isToFinish, time });
  }

  async componentDidMount() {
    this.iconLeft();
    if (!_.isObject(this.addressAltSelected)) {
      const event = _.get(this.props.route.params.pushObject, 'event', {});
      const { pkey: pkeyClient } = event;
      const addressAlt = await AddressAltCache.getAddressAlt(pkeyClient);
      this.addressAltSelected = addressAlt;
    }
  }

    acceptNoBackPress = async () => {
        await AsyncStorage.removeItem('info_timer');
        this.props.navigation.pop();
        return false;
    };

    iconLeft = () => {
        this.props.navigation.setOptions({
            headerLeft: () => (
              <TouchableOpacity onPress={() => {
                this.handleBackPress();
              }}
              >
                <Image
                  source={Images.iconBack}
                  style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
                />
              </TouchableOpacity>
            ),
          });
    };

  // eslint-disable-next-line react/sort-comp
  handleBackPress = async () => {
    const { isRunning, time, isToFinish } = this.state;
    const payload = JSON.parse(await AsyncStorage.getItem('info_timer'));

    if (payload && isRunning === false && time > 0 && isToFinish === false) {
      showAlert(translate('stopwatchPaused'), `${translate('saveStopwatchRunning')}\n\n${translate('wantSaveTime')}`, this.onFinish, this.acceptNoBackPress);
      return true;
    }
    this.props.navigation.pop();
    return false;
  };
  navigationButtonPressed(event) {
    switch (event.buttonId) {
      case 'backPress':
        this.handleBackPress();
        break;
      case 'willAppear':
        this.backHandler = BackHandler.addEventListener(
          'hardwareBackPress',
          this.handleBackPress,
        );
        break;
      case 'willDisappear':
        this.backHandler?.remove();
        break;
      default:
        break;
    }
  }

  onChangeMessage = message => {
    this.setState({ message });
  };

  onFinish = () => {
    if (this.state.time === 0) {
      createNotifyError(`${translate('errorSaveStopwatch')}`);
    } else {
      this.stopwatch.stop();
      AsyncStorage.removeItem('info_timer');

      const answer = {
        dateSelected: this.props.route.params.pushObject.dateSelected,
        dateOpen: this.state.dateOpen,
        latitude: this.state.location.latitude,
        longitude: this.state.location.longitude,
        payload: {
          time: this.state.time,
          content: this.state.message,
          location: this.state.location,
        },
        taskId: this.props.route.params.pushObject.item.id,
      };

      const { item, event } = this.props.route.params.pushObject;
      const setup = JSON.parse(item.setup);

      const message = GpsHelper.validateRulesGPSClient(
        setup,
        event,
        this.state.location,
      );
      if (!_.isEmpty(message)) {
        createNotifyInfo(message);
        return;
      }

      this.props.route.params.pushObject.saveAnswerActivity(
        answer,
        this.successSaveAnswer,
        this.failureSaveAnswer,
      );

      this.setState({
        isToFinish: true,
        isRunning: false,
        time: 0,
        timeString: '00:00:00',
      });
    }
  };

  setup(timer) {
    const timerPayload = timer;
    const isRunning = timerPayload ? timerPayload.isRunning : false;
    const isToFinish = timerPayload ? timerPayload.isToFinish : false;

    const startedTime = timerPayload ? timerPayload.time : 0;
    const paused = isRunning === false && isToFinish === false;

    let diffTime = 0;
    if (startedTime && !paused) {
      diffTime = new Date().getTime() - startedTime;
    } else {
      diffTime = startedTime;
    }
    if (diffTime) {
      this.stopwatch.setTime(diffTime);
    }

    const savedTime = _.get(this.props.route.params.pushObject, 'item.answer.payload.time');
    const time = savedTime || diffTime || this.state.time;
    const content = _.get(this.props.route.params.pushObject, 'item.answer.payload.content') || '';
    const startDate =
      _.get(this.props.route.params.pushObject, 'item.answer.start_date') || new Date().toISOString();

    if (isRunning || (diffTime && (!savedTime && savedTime !== undefined))) {
      this.stopwatch.start();
    }

    this.setState(
      {
        time,
        startedTime,
        isFinished: savedTime,
        timeString: time ? stopwatch().formatTime(time) : '00:00:00',
        message: content,
        isRunning,
        isToFinish,
        dateOpen: startDate,
      },
      () => {
        this.saveTimerInfo();
      },
    );
  }

  setTime = (timeString, time) => {
    this.setState({ time, timeString });
  };

  successSaveAnswer = () => null;
  failureSaveAnswer = () => null;

  saveTimerInfo(time) {
    const { isRunning, isToFinish, startedTime } = this.state;
    const { id, name } = this.props.route.params.pushObject.item;
    const setup = JSON.parse(this.props.route.params.pushObject.item.setup);
    const timer = {
      id,
      name,
      setup,
      isRunning,
      isToFinish,
      ...(() => (time || startedTime ? { time: time || startedTime } : {}))(),
    };
    AsyncStorage.setItem('info_timer', JSON.stringify(timer));
  }

  start = () => {
    this.stopwatch.setTime(0);
    this.stopwatch.start();
    this.setState({ isRunning: true }, () => {
      this.saveTimerInfo(new Date().getTime());
    });
  };
  stop = () => {
    this.setState({ isToFinish: true, isRunning: false }, () => {
      this.saveTimerInfo();
    });
    this.stopwatch.stop();
  };
  pause = () => {
    this.setState({ isToFinish: false, isRunning: false }, () => {
      this.saveTimerInfo(this.stopwatch.getTime());
    });
    this.stopwatch.stop();
  };

  updateTime = (time, timeMilis) => {
    this.setTime(time, timeMilis);
  };

  error = message => {
    createNotifyError(message);
  };

  onLocationChange = location => {
    if (location) {
      this.setState({ location });
    }
  };

  render() {
    const retry = _.get(this.props.route.params.pushObject, 'item.setup.retry') || true;
    return (
      <Stopwatch
        time={this.state.timeString}
        stop={this.stop}
        pause={this.pause}
        start={this.start}
        retry={retry}
        isRunning={this.state.isRunning}
        isToFinish={this.state.isToFinish}
        isFinished={this.state.isFinished}
        setTime={this.setTime}
        onFinish={this.onFinish}
        onChangeMessage={this.onChangeMessage}
        message={this.state.message}
        gpsPlaces={this.addressAltSelected || this.props.event}
        event={this.props.event}
        location={this.state.location}
        setup={JSON.parse(this.props.item?.setup || '{}')}
        onLocationChange={this.onLocationChange}
      />
    );
  }
}

export default StopwatchContainer;
