import AsyncStorage from '@react-native-async-storage/async-storage';
import * as schema from './Schema';

const DB_VERSION_KEY = 'db_version';

class SqliteHelper {
  constructor() {
    this.db = null;
    this.dbName = schema.databaseName;
    this.dbVersion = schema.databaseVersion;
    this.tables = schema.Tables;
    this.success = null;
    this.error = null;
    this.open(this.success, this.error)
      .then(r => r)
      .catch(e => e);
  }

  async open(success, error) {
    this.success = success;
    this.error = error;

    try {
      const dbOpenRequest = window?.indexedDB.open(this.dbName, this.dbVersion);

      dbOpenRequest.onupgradeneeded = event => {
        const db = dbOpenRequest.result;

        // Adiciona tabelas conforme o esquema
        for (const tableName in this.tables) {
          const table = this.tables[tableName];
          if (!db.objectStoreNames.contains(tableName)) {
            const store = db.createObjectStore(tableName, {
              keyPath: table.primary_key ? 'id' : undefined,
              autoIncrement: table.primary_key ? true : false,
            });
            for (const columnName in table) {
              const column = table[columnName];
              if (column.primary_key) {
                store.createIndex(columnName, columnName, {
                  unique: column.primary_key,
                });
              }
            }
          }
        }
      };

      dbOpenRequest.onsuccess = event => {
        this.db = event.target.result;

        // Executa o schema migration se necessário
        this.createTablesFromSchema()
          .then(() => {
            if (this.success) {
              this.success();
            }
          })
          .catch(err => {
            if (this.error) {
              this.error(err);
            }
          });
      };

      dbOpenRequest.onerror = event => {
        if (this.error) {
          this.error(event.target.error);
        }
      };
    } catch (err) {
      if (this.error) {
        this.error(err);
      }
    }
  }

  async createTablesFromSchema() {
    const dbVersion = await AsyncStorage.getItem(DB_VERSION_KEY);
    if (dbVersion === null || dbVersion !== `${this.dbVersion}`) {
      await this.schemaMigration();
      await AsyncStorage.setItem(DB_VERSION_KEY, `${this.dbVersion}`);
    }
  }

  async schemaMigration() {
    // IndexedDB não tem um mecanismo de migração direto, então isso é apenas um exemplo.
    // Se você precisar atualizar esquemas, você pode usar o evento onupgradeneeded do open().
    // Aqui você pode fazer verificações e adições baseadas na versão.
  }

  async getExistingColumns(tableName) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction(tableName, 'readonly');
      const store = transaction.objectStore(tableName);
      const request = store.get(1); // Usando um item de exemplo para obter o esquema

      request.onsuccess = () => {
        const columns = [];
        const indexes = store.indexNames;
        for (let i = 0; i < indexes.length; i++) {
          columns.push(indexes[i]);
        }
        resolve(columns);
      };

      request.onerror = () => {
        reject(request.error);
      };
    });
  }

  async alterTable(table, tableName, existingColumns) {
    // IndexedDB não suporta alterações de schema diretamente, então você deve usar uma abordagem alternativa.
    // Por exemplo, você pode criar uma nova tabela, copiar dados e renomear tabelas.

    // Exemplo de código para ilustrar:
    const newTableName = `${tableName}_new`;
    const transaction = this.db.transaction([tableName], 'readwrite');
    const oldStore = transaction.objectStore(tableName);
    const newStore = this.db.createObjectStore(newTableName, {
      keyPath: 'id',
      autoIncrement: true,
    });

    for (const key in table) {
      const column = table[key];
      if (!existingColumns.includes(key)) {
        newStore.createIndex(key, key, { unique: column.primary_key });
      }
    }

    transaction.oncomplete = async () => {
      await this.copyData(tableName, newTableName);
      this.db.deleteObjectStore(tableName);
      this.db.createObjectStore(tableName, {
        keyPath: 'id',
        autoIncrement: true,
      });
      await this.copyData(newTableName, tableName);
      this.db.deleteObjectStore(newTableName);
    };

    transaction.onerror = event => {
      console.error('Transaction failed:', event.target.error);
    };
  }

  async copyData(sourceTable, targetTable) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction(
        [sourceTable, targetTable],
        'readwrite',
      );
      const sourceStore = transaction.objectStore(sourceTable);
      const targetStore = transaction.objectStore(targetTable);

      sourceStore.openCursor().onsuccess = event => {
        const cursor = event.target.result;
        if (cursor) {
          targetStore.put(cursor.value);
          cursor.continue();
        } else {
          resolve();
        }
      };

      transaction.onerror = event => {
        reject(event.target.error);
      };
    });
  }

  async dropTables(success) {
    for (const name in this.tables) {
      const transaction = this.db.transaction(name, 'readwrite');
      transaction.objectStore(name).clear();
      transaction.oncomplete = () => {
        if (success) {
          success();
        }
      };
      transaction.onerror = event => {
        console.error('Failed to clear table:', event.target.error);
      };
    }
  }

  async dropDatabase() {
    return new Promise((resolve, reject) => {
      const request = window?.indexedDB.deleteDatabase(this.dbName);
      request.onsuccess = () => {
        resolve();
      };
      request.onerror = () => {
        reject(request.error);
      };
    });
  }
}

export default new SqliteHelper();
