import { StyleSheet } from 'react-native';

import { color } from '~/common';
import { fonts } from '../../../../utils/Fonts';

const styles = StyleSheet.create({
  line: {
    borderTopColor: color.grayContainerDark,
    borderTopWidth: 1,
    marginTop: 5,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  groupButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: 120,
  },
  yesButton: {
    borderWidth: 1,
    borderRadius: 5,
    paddingVertical: 5,
    paddingHorizontal: 8,
    alignItems: 'center',
    borderColor: color.primary600,
  },
  button: {
    fontSize: 16,
    lineHeight: 18,
    color: color.primary600,
    fontFamily: fonts.Quicksand,
  },
});

export default styles;
