import React from 'react';
import { View, Text } from 'react-native';

import ImageAnexo from '../../../components_base/src/components/Image';
import styles from './styles';

import PropTypes from 'prop-types';

function AttachmentImage({ attach }) {
  const { value, name } = attach;

  return (
    <View style={styles.containerImage}>
      <ImageAnexo
        style={styles.iconContainerImage}
        source={{ uri: value }}
      />
      <Text style={styles.text}>{name}</Text>
    </View>
  );
}

AttachmentImage.propTypes = {
    attach: PropTypes.object.isRequired,
};

export default AttachmentImage;
