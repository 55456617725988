import React, { Component } from 'react';
import { color } from '../../common';
import { View } from 'react-native';
import { IndicatorViewPager, PagerDotIndicator } from 'rn-viewpager';
import Images from '../../../assets/Images';
import BannerImage from './components/BannerImage';

import PropTypes from 'prop-types';

class Banners extends Component {
  onPressItem(item) {
    const { onPressItem } = this.props;
    if (onPressItem) {
      this.props.onPressItem(item);
    }
  }
  renderIndicator() {
    return (
      <PagerDotIndicator
        style={{ top: 160 }}
        selectedDotStyle={{ backgroundColor: color.primaryColor }}
        pageCount={this.props.images.length}
      />
    );
  }

  render() {
    const { images } = this.props;
    return (
      <IndicatorViewPager
        indicator={this.renderIndicator()}
        style={{ height: 150 }}>
        {images.map((item, index) => {
          const img = item.image || item.uri;
          return (
            <View key={`${index}`}>
              <BannerImage
                resizeMode="contain"
                image={img ? { uri: img } : Images.iconNoImageBig}
                index={index}
                onPressItem={() => this.onPressItem(item)}
                pressable
              />
            </View>
          );
        })}
      </IndicatorViewPager>
    );
  }
}

Banners.propTypes = {
  images: PropTypes.array,
  onPressItem: PropTypes.func,
};

Banners.defaultProps = {
  images: []
};

export default Banners;
