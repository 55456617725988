import axios from 'axios';
import { Platform } from 'react-native';
import { BASE_ANYWHERE_URL_PROD } from '../constants';

const loginAnywhere = async (username, password, company, uuid) => {
  axios.defaults.timeout = 300000;
  const dataAccount = {
    company_code: company,
    username,
    password,
    // session_uuid: '123456',
    platform_os: Platform.OS,
    session_uuid: uuid,
  };
  return axios.post(`${BASE_ANYWHERE_URL_PROD}/v1/api/auth/`, dataAccount);
};

export default loginAnywhere;
