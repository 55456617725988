import { Platform, NativeModules } from 'react-native';
import I18n from 'i18n-js';
import es from './es-ES';
import pt from './pt-BR';
import en from './en-US';
import { esES, ptBR, enUS } from './calendarNames';

const normalizeTranslate = {
  en: 'en_US',
  en_US: 'en_US',
  pt_BR: 'pt_BR',
  pt_US: 'pt_BR',
  es: 'es_ES',
  es_ES: 'es_ES',
};

const normalizeCalendarTranslate = {
  en: {
    calendarLanguage: 'en',
    calendarNames: enUS,
  },
  en_US: {
    calendarLanguage: 'en-us',
    calendarNames: enUS,
  },
  pt_BR: {
    calendarLanguage: 'pt-br',
    calendarNames: ptBR,
  },
  es: {
    calendarLanguage: 'es',
    calendarNames: esES,
  },
  es_ES: {
    calendarLanguage: 'es-es',
    calendarNames: esES,
  },
};
const getLanguageByDevice = () => {
  let language;
  if (Platform.OS === 'ios') {
    language = NativeModules.SettingsManager.settings.AppleLocale;
  } else if (Platform.OS === 'web') {
    language = 'pt_BR';
  } else {
    language = NativeModules.I18nManager.localeIdentifier;
  }
  return language;
};

const language = getLanguageByDevice();
const prefixLanguage = typeof language === 'string' && language.split('_')[0];
const translateNormalize = normalizeTranslate[language] || normalizeTranslate[prefixLanguage] || 'en_US';

const calendar =
  normalizeCalendarTranslate[translateNormalize] || normalizeCalendarTranslate[prefixLanguage];

const calendarLanguage =
  calendar && calendar.calendarLanguage ? calendar.calendarLanguage : 'en-us';

const calendarNames = calendar && calendar.calendarNames ? calendar.calendarNames : enUS;

I18n.translations = {
  en,
  en_US: en,
  pt_BR: pt,
  es,
  es_ES: es,
};

const setLanguageToI18n = () => {
  const locale = translateNormalize;
  Object.assign(I18n, { locale, fallbacks: true });
};

setLanguageToI18n();
const translate = key => I18n.t(key);
export { calendarLanguage, calendarNames };
export default translate;
