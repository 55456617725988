import AsyncStorage from '@react-native-async-storage/async-storage';

class CacheTabs {
  static async getTabs() {
    const data = await AsyncStorage.getItem('@bottomTabs');
    return JSON.parse(data);
  }
  static async setTabs(tabs) {
    if (tabs) {
      await AsyncStorage.setItem('@bottomTabs', JSON.stringify(tabs));
    }
  }
}

export default CacheTabs;
