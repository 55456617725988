import { createNotifyError } from '~/components/Web/ToastNotify';
import Repository from './Repository';

export default class TasksRepository extends Repository {
  constructor() {
    super('task_lists');
    this.tableName = 'task_lists';
  }

  async deleteAll() {
    try {
      const transaction = this.db.transaction(this.tableName, 'readwrite');
      const store = transaction.objectStore(this.tableName);
      await new Promise((resolve, reject) => {
        const request = store.clear();
        request.onsuccess = () => resolve();
        request.onerror = () => reject(request.error);
      });
    } catch (error) {
      this.errorDb(error);
    }
  }

  async delete(task) {
    try {
      const transaction = this.db.transaction(this.tableName, 'readwrite');
      const store = transaction.objectStore(this.tableName);
      await new Promise((resolve, reject) => {
        const request = store.delete(task.id);
        request.onsuccess = () => resolve();
        request.onerror = () => reject(request.error);
      });
    } catch (error) {
      this.errorDb(error);
    }
  }

  async get() {
    try {
      const transaction = this.db.transaction(this.tableName, 'readonly');
      const store = transaction.objectStore(this.tableName);
      const data = await new Promise((resolve, reject) => {
        const request = store.openCursor();
        request.onsuccess = () => {
          const cursor = request.result;
          if (cursor) {
            resolve(cursor.value);
          } else {
            resolve(null);
          }
        };
        request.onerror = () => {
          reject(request.error);
        };
      });
      return data;
    } catch (error) {
      this.errorDb(error);
    }
  }

  async getTasksByTaskList(taskListId) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction(this.tableName, 'readonly');
      const store = transaction.objectStore(this.tableName);
      const request = store.openCursor();
      const results = [];
  
      request.onsuccess = function() {
        let cursor = request.result;
        if (cursor) {
          let key = cursor.key; 
          let value = cursor.value;
          results.push({ key, value });
          cursor.continue();
        } else {
          resolve(results.length > 0 ? results : null);
        }
      };
  
      request.onerror = () => {
        reject(request.error);
      };
    });
  }

  successDb() {
    console.log('Database operation successful');
  }

  errorDb(err) {
    console.log('Database error:', err);
  }
}