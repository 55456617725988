/* @flow */

import React, { Component } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import ButtonIconInfo from '../../../components/ButtonsIcon/ButtonIconInfo';
import MoreInfo from '../../../../components_base/src/components/MoreInfo/MoreInfo';
import { color } from '~/common';
import { fonts } from '../../../utils/Fonts';
import translate from '../../../locales';
import Touchable from 'react-native-platform-touchable';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  card: {
    backgroundColor: '#fff',
    padding: 12,
    paddingVertical: 16,
    minHeight: 20,
    flex: 1,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: '#DADCE0',
    marginTop: 8,
    marginHorizontal: 10,
  },
  code: {
    color: '#929292',
    fontSize: 14,
    fontFamily: fonts.QuicksandBold,
  },
  name: {
    color: '#000',
    fontSize: 16,
    fontFamily: fonts.QuicksandBold,
  },
  date: {
    textAlign: 'left',
    fontSize: 12,
    fontFamily: fonts.QuicksandItalic,
    color: color.grayMid,
  },
});

class PreCadItem extends Component {
  state = {
    modalMoreInfosVisible: false,
  };

  render() {
    const { item, onPressItem, onLongPressItem } = this.props;

    return (
      <View>
        <Touchable
          onPress={() => onPressItem(item)}
          onLongPress={() => onLongPressItem(item)}>
          <View style={styles.card}>
            <View style={{ flex: 1, marginRight: 8 }}>
              {item.chave ? (
                <Text style={styles.code}>{item.chave}</Text>
              ) : null}
              <Text style={styles.name}>
                {item.nome || item.name || item.display_name}
              </Text>
              <Text style={styles.date}>
                {`${translate('registeredAt')}`}{' '}
                {item.t_data && item.t_data.replace('-', '/').replace('-', '/')}
              </Text>
            </View>
            {!!item.html && (
              <View
                style={{ justifyContent: 'center', alignItems: 'flex-end' }}>
                <ButtonIconInfo
                  onPress={() => {
                    this.setState({ modalMoreInfosVisible: true });
                  }}
                />
              </View>
            )}
            <MoreInfo
              modalMoreInfosVisible={this.state.modalMoreInfosVisible}
              htmlContent={item.html}
              onModalClose={() => {
                this.setState({ modalMoreInfosVisible: false });
              }}
            />
          </View>
        </Touchable>
      </View>
    );
  }
}

PreCadItem.propTypes = {
  onPressItem: PropTypes.func.isRequired,
  onLongPressItem: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};

export default PreCadItem;
