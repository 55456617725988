import AsyncStorage from '@react-native-async-storage/async-storage';

class ApkCache {
  async getData() {
    const data = await AsyncStorage.getItem('@SessionDataAPK');
    return JSON.parse(data);
  }

  async setData(data) {
    if (data) {
      const dataString = JSON.stringify(data);
      await AsyncStorage.setItem('@SessionDataAPK', dataString);
    }
  }

  async getDataLastService() {
    const data = await AsyncStorage.getItem('@SessionDataAPKLastService');
    return JSON.parse(data);
  }

  async setDataLastService(data) {
    if (data) {
      const dataString = JSON.stringify(data);
      await AsyncStorage.setItem('@SessionDataAPKLastService', dataString);
    }
  }
}

export default new ApkCache();
