import React, { Component } from 'react';
import { View, Text } from 'react-native';
import Touchable from 'react-native-platform-touchable';

import styles from './styles';

import style from '../../../src/common/style';
import translate from '../../locales';

import PropTypes from 'prop-types';

class SaleOpportunityItem extends Component {
  onPress = item => this.props.onPress(item);

  render() {
    const { saleOpportunityItem: item } = this.props;

    const { j_cliente: client, t_criada_em: createdAt } = item;

    return (
      <Touchable
        foreground={Touchable.SelectableBackground()}
        onPress={() => this.onPress(item)}>
        <View style={styles.container}>
          <View>
            <View style={style.rowDirection}>
              {/* <Text style={styles.number}>
                {item.s_external_identifier}
              </Text> */}
            </View>
            <Text style={styles.clientName}>{client.s_nome}</Text>
            <Text style={styles.cnpjCpf}>{client.s_cnpjcpf}</Text>
            <Text style={styles.title}>
              {`${translate('situation')}: `}:
              <Text style={[styles.titleBlack, { color: `rgb(${'0,0,0'})` }]}>
                {item.s_status}
              </Text>
            </Text>
            <Text style={styles.date}>
              {`${translate('saleOpportunityStartedAt')} `}
              {createdAt.replace('-', '/').replace('-', '/')}
            </Text>
          </View>
        </View>
      </Touchable>
    );
  }
}

SaleOpportunityItem.propTypes = {
    saleOpportunityItem: PropTypes.object.isRequired,
    onPress: PropTypes.func.isRequired,
};

export default SaleOpportunityItem;
