import React, { useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { View, Text, Image } from "react-native";
import { compose, withProps } from "recompose";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from "react-google-maps";
import Images from '../../../../assets/Images';
import * as config from '../../../config/config';
import { fonts } from '~/utils/Fonts';
import translate from '../../../locales';

const mapOptions = {
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    zoomControl: true,
    scaleControl: true,
}

const MyMapComponent = compose(
    withProps((props) => ({
        googleMapURL:
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyDRvXD60ZyXFCdcXee6NmDSCRRYmoiVcKU&v=3.exp&libraries=geometry,drawing,places,marker",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `${props?.height}px` || '100%' }} />,
        mapElement: <div style={{ height: `100%` }} />,
    })),
    withScriptjs,
    withGoogleMap
)(
    forwardRef((props, ref) => {
        const mapRef = useRef(null);

        useImperativeHandle(ref, () => ({
            fitBounds: () => {
                if (mapRef.current && props.positions?.length > 0) {
                    const bounds = new window.google.maps.LatLngBounds();
                    props.positions.forEach((place) => {
                        bounds.extend({ lat: place?.latitude, lng: place?.longitude });
                    });
                    mapRef?.current?.fitBounds(bounds);
                }
            },
        }));

        useEffect(() => {
            if (mapRef.current && props.positions?.length > 0) {
                const bounds = new window.google.maps.LatLngBounds();
                props.positions.forEach((place) => {
                    const latitude = parseFloat(place?.latitude);
                    const longitude = parseFloat(place?.longitude);
                    if (!isNaN(latitude) && !isNaN(longitude)) {
                        bounds.extend({ lat: latitude, lng: longitude });
                    } else {
                        console.warn('Invalid latitude or longitude for place:', place);
                    }
                });
                mapRef.current.fitBounds(bounds);
            }
        }, [props.positions]);

        return (
            <GoogleMap
                ref={(map) => {
                    if (map) {
                        mapRef.current = map;
                    }
                }}
                options={mapOptions}
                defaultZoom={props?.zoom || 17}
            >
                {props.positions?.length > 0
                    ? props.positions.map((place, index) => (
                        <Marker
                            key={`${place?.pkey}-${index}`}
                            position={{ lat: place?.latitude, lng: place?.longitude }}
                            onClick={props?.onPressItem ? () => props.onPressItem(place) : null}
                            icon={place?.icon ? place.icon : null}
                        >
                            <View style={{ flexDirection: 'row' }}>
                                <Image
                                    source={Images.iconStore}
                                    style={{
                                        tintColor: place?.markerColor,
                                        width: 20,
                                        height: 20,
                                    }}
                                />
                                <Text
                                    style={{
                                        backgroundColor: place?.markerColor,
                                        color: config.Colors.navBarButtonColor,
                                        borderRadius: 50,
                                        fontSize: 12,
                                        padding: 7,
                                    }}
                                >
                                    {index + 1}
                                </Text>
                            </View>

                            <View
                                style={{
                                    flexDirection: 'column',
                                    borderBottomWidth: 1,
                                    borderBottomColor: config.Colors.grayContainer,
                                    minWidth: 300,
                                }}
                            >
                                <View
                                    style={{
                                        backgroundColor: config.Colors.navBarButtonColor,
                                        padding: 12,
                                        paddingVertical: 16,
                                        minHeight: 20,
                                        flex: 1,
                                        borderRadius: 12,
                                        borderWidth: 1,
                                        borderColor: config.Colors.borderGray,
                                        marginTop: 8,
                                        marginHorizontal: 10,
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: config.Colors.simpleTextGray,
                                            fontSize: 12,
                                            fontFamily: fonts.QuicksandBold,
                                        }}
                                    >
                                        {place?.cnpj_cpf} | {place?.codigo}
                                    </Text>
                                    <Text
                                        style={{
                                            color: config.Colors.simpleTextGray,
                                            fontSize: 12,
                                            fontFamily: fonts.QuicksandBold,
                                        }}
                                    >
                                        {place?.nome}
                                    </Text>
                                    {place?.fora_de_rota ? (
                                        <View style={{ alignItems: 'flex-start' }}>
                                            <Text
                                                style={{
                                                    fontSize: 10,
                                                    color: config.Colors.navBarButtonColor,
                                                    fontFamily: fonts.QuicksandBold,
                                                    borderRadius: 5,
                                                    backgroundColor: config.Colors.primaryColor,
                                                    paddingHorizontal: 9,
                                                    paddingVertical: 3,
                                                }}
                                            >
                                                {translate('ClientOffRoute').toUpperCase()}
                                            </Text>
                                        </View>
                                    ) : null}
                                    <View style={{ width: '100%', marginTop: 8 }}>
                                        {place?.attendanceTime
                                            ? renderTime(
                                                `${translate('expectedTime')}`,
                                                `${place?.attendanceTime}hrs`,
                                                Images.iconCalendarClock,
                                                place?.markerColor
                                            )
                                            : null}
                                        {place?.visitTime
                                            ? renderTime(
                                                `${translate('visitTime')}`,
                                                `${place?.visitTime}min`,
                                                Images.iconClock
                                            )
                                            : null}
                                        {place?.displacementTime
                                            ? renderTime(
                                                `${translate('displacementTime')}`,
                                                `${place?.displacementTime}min`,
                                                Images.iconTruck
                                            )
                                            : null}
                                    </View>
                                </View>
                            </View>
                        </Marker>
                    ))
                    : null}
            </GoogleMap>
        );
    })
);

const renderTime = (label, time, icon, color) => (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Image
            source={icon}
            style={{ tintColor: color, width: 20, height: 20, marginRight: 5 }}
        />
        <Text style={{ fontSize: 12, color: config.Colors.simpleTextGray }}>
            {label}: {time}
        </Text>
    </View>
);

export default MyMapComponent;