export default function(c) {
    const api = {};
    const duration = 1000;
    let callback = c;
    let time = 0;
    let clocktimer;
    let h;
    let m;
    let s;
  
    function pad(num, size) {
      const aux = `0000${String(num)}`;
      return aux.substr(aux.length - size);
    }
  
    function formatTime(t) {
      const timeToFormat = t || (time += duration);
      h = Math.floor(timeToFormat / (60 * 60 * 1000));
      m = Math.floor((timeToFormat / (60 * 1000)) % 60);
      s = Math.floor((timeToFormat / 1000) % 60);
      return `${pad(h, 2)}:${pad(m, 2)}:${pad(s, 2)}`;
    }
  
    function update() {
      try {
        callback(formatTime(), time);
      } catch (e) {
        console.error('ERR TIMER', e);
      }
    }
  
    api.updateCallback = function(callbackUpdate) {
      callback = callbackUpdate;
    };
  
    api.setTime = function(timeUpdated) {
      time = timeUpdated;
    };
  
    api.start = function() {
      clocktimer = setInterval(update, duration);
    };
  
    api.stop = function() {
      clearInterval(clocktimer);
    };
  
    api.getTime = function() {
      return time;
    };
  
    api.formatTime = formatTime;
  
    return api;
  }