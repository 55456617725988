import BaseController from './BaseController';
import ConfigRepository from '../database/repository/ConfigRepository';

export default class ConfigController extends BaseController {
  constructor() {
    super();
    this.repository = new ConfigRepository();
  }

  upsert(data) {
    return new Promise((resolve, reject) => {
      this.repository.get()
        .then((config) => {
          if (!config) {
            data.created_at = new Date().toISOString();
            data.updated_at =  data.created_at;
            data.deleted = false;
            data.sync_status = 'accepted';

            this.repository.insert(data)
              .then(() => {
                resolve();
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            data.updated_at = new Date().toISOString();
            data.created_at = config.created_at;
            data.deleted = config.deleted;
            data.sync_status = config.sync_status;

            this.update(data, config.id)
              .then(() => {
                console.log('UPSERT success');
                resolve();
              })
              .catch((error) => {
                console.log('UPSERT error 1', error);
                reject(error);
              });
          }
        })
        .catch((error) => {
          console.log('UPSERT error 2', error);
          reject(error);
        });
    });
  }
}