import { Platform, StyleSheet } from 'react-native';
import DeviceInfo from 'react-native-device-info';
import colors from '~/common/color';

const isTablet = Platform.OS === 'web' ? false : DeviceInfo.isTablet();

const styles = StyleSheet.create({
    // Categories
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    column: {
        flex: isTablet ? 1 : 0.5,
    },
    rootSearchContainer: {
        width: 360,
        height: 68,
        paddingTop: 16,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
    },
    searchContainer: {
        height: 40,
        marginBottom: 20,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 10,
        paddingRight: 10,
    },
    searchInput: {
        width: 330,
        height: 45,
        borderRadius: 4,
        borderColor: 'gray',
        borderWidth: 1,
    },
    // CategoriesPlaceholder
    rootConatiner: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    containerProductCategories: {
        width: '50%', 
        padding: 5, 
        left: 10
    },
    productCategoriesStyle: {
        backgroundColor: colors.gray,
        height: 80,
        width: 150,
        borderRadius: 6,
        marginRight: 5,
    },
});

export default styles;