/* @flow */

import React, { Component } from 'react';
import _, { split } from 'lodash';
import { Platform } from 'react-native';
import Masked from 'vanilla-masker';
import FloatingTitleTextInput from '~/components/FloatingTitleTextInput';
import * as config from '../../../config/config';
import types from '../types';

import List from './List';
import Combo from './Combo';
import validation from './validation';
import translate from '../../../locales';

import PropTypes from 'prop-types';

function unmaskMoney(entry) {
    return Number(
        entry
            .replace(`${translate('currencySymbol')} `, '')
            .replace('.', '')
            .replace(',', '.'),
    );
}

class Field extends Component {
    state = {
        value: '',
    };

    static List = List;
    static Combo = Combo;

    UNSAFE_componentWillMount() {
        const value = this.props.initialValue || this.props.value;
        if (value) {
            this.setState({ value: this.unformat(value) });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value === this.props.value) {
            return;
        }
        if (nextProps.source === 'typed') {
            return;
        }

        const formattedValue = this.unformat(nextProps.value);
        const formattedStateValue = this.unformat(this.state.value);

        if (formattedValue !== formattedStateValue) {
            this.setState({ value: formattedValue }, () => {
                this.onBlur();
            });
        }
    }

    componentDidMount() {
        if (this.props.onRef !== null) {
            this.props.onRef(this);
        }
    }

    onBlur = () => {
        const error = this.isValid();
        this.setState({ error }, () => {
            if (this.props.onBlur) {
                this.props.onBlur(error !== '', this.props.field);
            }
        });
    };

    onChangeValueDebounce = _.debounce(this.props.onChangeValue, 500);

    handleNotifyChange = (field, value) => {
        this.onChangeValueDebounce(field, value, 'typed');
    };

    onChange = (field, value) => {
        const unformattedValue = this.unformat(
            validation.RegxMatch(value, this.props.regx),
        );
        this.setState({ value: unformattedValue }, () => {
            this.handleNotifyChange(field, unformattedValue);
        });
    };

    onSubmitEditing = () => {
        if (this.props.onSubmitEditing !== null) {
            this.props.onSubmitEditing();
        }
    };

    onEndEditing = () => {
        const { field } = this.props;
        const { value } = this.state;
        if (this.props.onEndEditing !== null) {
            this.props.onEndEditing({
                field,
                value: this.unformat(validation.RegxMatch(value, this.props.regx)),
            });
        }
    };

    getTextFieldType() {
        const { type } = this.props;
        switch (type) {
            case types.CNPJ.name:
            case types.CPF.name:
            case types.ZIP_CODE.name:
            case types.MONEY.name:
            case types.PERCENTAGE.name:
            case types.NUMBER.name:
            case types.INT.name:
                return 'numeric';
            case types.PHONE.name:
            case types.PHONE_EMP.name:
                return 'phone-pad';
            default:
                return 'default';
        }
    }

    focus = () => {
        this.textField.focus();
    };

    isValid() {
        const { value } = this.state;
        const { type, required, regx } = this.props;
        if (required && value.length === 0) {
            return `${translate('thisFieldIsRequired')}`;
        } else if (value.length > 0) {
            switch (type) {
                case types.CPF.name:
                case types.CNPJ.name:
                    if (value.length <= 11) {
                        if (!validation.CPFCheck(value)) {
                            return `${translate('identityNumber')} ${translate(
                                'typedIsNotValid',
                            )}`;
                        }
                        return '';
                    }
                    if (!validation.CNPJCheck(value)) {
                        return `${translate('cnpj')} ${translate('typedIsNotValid')}`;
                    }
                    return '';
                case types.ZIP_CODE.name:
                    if (!validation.ZipCodeCheck(value)) {
                        return `${translate('ZipCode')} ${translate('typedIsNotValid')}`;
                    }
                    return '';
                case types.PHONE.name:
                case types.PHONE_EMP.name:
                    if (!validation.PhoneCheck(value)) {
                        return `${translate('phone')} ${translate('typedIsNotValid')}`;
                    }
                    return '';
                default:
                    if (!validation.RegxCheck(value, regx)) {
                        return `${translate('fieldIsNotValid')}`;
                    }
                    return '';
            }
        }
        return '';
    }

    unformat(value) {
        switch (this.props.type) {
            case types.MONEY.name:
                return unmaskMoney(value);
            case types.CNPJ.name:
            case types.CPF.name:
                return String(value).replace(/\D/g, '');
            case types.PHONE.name:
            case types.PHONE_EMP.name:
                const splitted = String(value)?.split('/');
                if (splitted.length > 1) {
                    return splitted[0].trim().replace(/\D/g, '');
                }
                return String(value).replace(/\D/g, '');
            default:
                if (this.props.regx) {
                    return String(value).replace(this.props.regx, '');
                }
                return value;
        }
    }

    format(value) {
        const moneyFormatter = {
            precision: 2,
            separator: ',',
            delimiter: '.',
            unit: translate('currencySymbol'),
        };
        switch (this.props.type) {
            case types.MONEY.name:
                return Masked.toMoney(Masked.toNumber(value), moneyFormatter);
            case types.NUMBER.name:
                return Masked.toNumber(value);
            case types.PHONE.name:
            case types.PHONE_EMP.name:
                return Masked.toPattern(
                    value,
                    value.length <= 10 ? '(99) 9999-9999' : '(99) 99999-9999',
                );
            case types.ZIP_CODE.name:
                return Masked.toPattern(value, '99999-999');
            case types.PERCENTAGE.name:
                return value;
            case types.CNPJ.name:
            case types.CPF.name:
                if (value.length <= 11) {
                    return Masked.toPattern(value, '999.999.999-99');
                }
                return Masked.toPattern(value, '99.999.999/9999-99');
            default:
                return value;
        }
    }

    render() {
        const { title, maxLength, field, required, editable } = this.props;

        return (
            <FloatingTitleTextInput
                ref={input => {
                    this.textField = input;
                }}
                required={required}
                onSubmitEditing={this.onSubmitEditing}
                returnKeyType="next"
                blurOnSubmit={false}
                containerStyle={{ marginTop: 16 }}
                label={required ? `${title} *` : title}
                error={this.state.error}
                keyboardType={this.getTextFieldType()}
                maxLength={maxLength || 200}
                outline={Platform.OS === 'ios'}
                value={this.format(this.state.value)}
                tintColor={config.Colors.primaryColor}
                onBlur={() => this.onBlur(field)}
                onChangeText={value => this.onChange(field, value)}
                editable={editable}
                onEndEditing={this.onEndEditing}
            />
        );
    }
}

Field.propTypes = {
    title: PropTypes.string,
    maxLength: PropTypes.number,
    field: PropTypes.string,
    type: PropTypes.string,
    onChangeValue: PropTypes.func,
    onBlur: PropTypes.func,
    onSubmitEditing: PropTypes.func,
    onRef: PropTypes.func,
    onEndEditing: PropTypes.func,
    value: PropTypes.string,
    required: PropTypes.bool,
    regx: PropTypes.string,
    editable: PropTypes.bool,
};

Field.defaultProps = {
    format: '',
    onChangeValue: () => { },
    onSuccess: () => { },
    value: '',
    regx: '',
};

export default Field;
