/* @flow */

import React, { Component } from 'react';
import { Image, TouchableOpacity } from 'react-native';
import OrderDetail from './OrderDetail';
import { connect } from 'react-redux';
import { getListOrder } from '~/store/order/slice';

import PropTypes from 'prop-types';
import Images from '@assets/Images';

class OrderDetailContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.navigation.setOptions({
      title: this.props.route.params.orderParams.numero_pedido,
      headerLeft: () => (
        <TouchableOpacity
          onPress={() => {
            this.props.navigation.pop();
          }}
          style={{ paddingHorizontal: 16 }}
        >
          <Image
            source={Images.iconBack}
            style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
          />
        </TouchableOpacity>
      ),
    });


    const pkPedido = this.props.route.params.orderParams.numero_pedido;
    this.props.dispatch(getListOrder({ pkPedido }));
  }

  render() {
    const {
      order,
      config,
      configLib,
      navigator,
    } = this.props;

    const { orderParams, showOrderDetailHeaderAsHTML } = this.props.route.params;

    const { payload, loading } = order;
    const { items, info } = payload;

    return (
      <OrderDetail
        loading={loading}
        order={orderParams}
        items={items}
        info={info}
        config={config.payload}
        showOrderDetailHeaderAsHTML={showOrderDetailHeaderAsHTML}
        // MultiTabs Props
        configLib={configLib}
        navigator={navigator}
      />
    );
  }
}

OrderDetailContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    orderParams: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,
    navigator: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    configLib: PropTypes.object.isRequired,
    showOrderDetailHeaderAsHTML: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    order: state.order,
    config: state.configFetch,
    configLib: state.configLibFetch.payload,
  };
}

export default connect(mapStateToProps)(OrderDetailContainer);
