import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ProductsModule from '~/modules/ProductsModule';

export const PRODUCT_FILTERS_REQUEST = 'PRODUCT_FILTERS_REQUEST';
export const PRODUCT_FILTERS_SUCCESS = 'PRODUCT_FILTERS_SUCCESS';
export const PRODUCT_FILTERS_FAILURE = 'PRODUCT_FILTERS_FAILURE';

const getProductsFilter = () =>
  new Promise((resolve, reject) => {
    ProductsModule.getFilterProducts(resolve, reject);
  });

export const getProductFilters = createAsyncThunk(
  'productsFilters/getProductFilters',
  async () => {
    try {
      const filters = await getProductsFilter();
      return filters;
    } catch (error) {
      throw error;
    }
  },
);
const initialState = {
  type: '',
  loading: false,
  erro: false,
  payload: [],
};
const productsFiltersSlice = createSlice({
  name: 'productsFilters',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getProductFilters.pending, state => {
        state.type = PRODUCT_FILTERS_REQUEST;
        state.loading = true;
      })
      .addCase(getProductFilters.fulfilled, (state, action) => {
        const payload = JSON.parse(action.payload);
        state.type = PRODUCT_FILTERS_SUCCESS;
        state.loading = false;
        state.payload = payload;
      })
      .addCase(getProductFilters.rejected, state => {
        state.type = PRODUCT_FILTERS_FAILURE;
        state.loading = false;
        state.erro = true;
      });
  },
});
const { reducer } = productsFiltersSlice;
export default reducer;
