import React from 'react';
import _ from 'lodash';
import { FlatList, Text, View } from 'react-native';
import style from '~/common/style';
import Spinner from 'react-native-loading-spinner-overlay';
import EmptyView from '../../components/EmptyView';
import Images from '@assets/Images';
import PropTypes from 'prop-types';
import moment from 'moment';

function Notifications({ items, loading }) {
  const dates = _.groupBy(_.orderBy(items, ['date'], ['desc']), item => {
    return moment.unix(item.date).format('YYYY-MM-DD');
  });

  const getHeader = date => {
    const today = moment().format('YYYY-MM-DD');
    return (
      <Text
        style={{
          fontWeight: 'bold',
          marginTop: 5,
          marginBottom: 5,
          fontSize: 16,
          marginLeft: 5,
        }}>
        {today === moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD')
          ? 'Hoje'
          : moment(date, 'YYYY-MM-DD').fromNow()}
      </Text>
    );
  };

  const renderItemDate = ({ item: date, index }) => {
    const notifications = dates[date];

    const itemSeparatorComponent = () => (
      <View style={{ height: 4, backgroundColor: '#e7e7e7' }} />
    );

    return (
      <FlatList
        data={notifications}
        removeClippedSubviews
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        ListHeaderComponent={getHeader(date)}
        ItemSeparatorComponent={itemSeparatorComponent}
      />
    );
  };

  const renderItem = ({ item }) => (
    <View
      style={{
        padding: 2,
        backgroundColor: '#fff',
      }}>
      <View style={{ padding: 4 }}>
        <Text>{item.title}</Text>
      </View>
      <View style={{ padding: 10, flexDirection: 'row' }}>
        <Text style={{ marginLeft: 5 }}>{item.description}</Text>
      </View>
    </View>
  );

  const keyExtractor = (item, index) => `${item.id}-${index}`;

  const itemSeparatorComponent = () => (
    <View style={{ height: 0.1, backgroundColor: '#e7e7e7' }} />
  );

  const listEmptyComponent = () => (
    <EmptyView
      icon={Images.iconBellFill}
      message="Não há notificações para exibir!"
    />
  );

  return (
    <View style={style.container}>
      <Spinner
        visible={loading}
        overlayColor="rgba(0, 0, 0, 0.7)"
        textStyle={{ color: '#FFF' }}
      />

      <FlatList
        data={Object.keys(dates)}
        removeClippedSubviews
        keyExtractor={keyExtractor}
        renderItem={renderItemDate}
        ItemSeparatorComponent={itemSeparatorComponent}
        ListEmptyComponent={listEmptyComponent}
      />
    </View>
  );
}

Notifications.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool,
};

export default Notifications;
