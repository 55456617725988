import translate from '~/locales';
import Images from '@assets/Images';

const ACTION_TYPE = {
  alert: 'alert',
  inputValue: 'inputValue',
  inputPercent: 'inputPercent',
  inputMonetary: 'inputMonetary',
  select: 'select',
};

const DESCRIPTION_OPTIONS = {
  noOperations: 'noOperations',
  operations: 'operations',
};

const constants = {
  ACTIONS: [
    {
      id: 1,
      operation: 'multiple',
      title: translate('multiple2'),
      icon: Images.iconPlus,
      alwaysVisible: false,
      descriptionOptions: {
        type: DESCRIPTION_OPTIONS.operations,
        textReplace: 'replaceMultipleDescription',
        textPlus: 'addMultipleDescription',
      },
      type: ACTION_TYPE.inputValue,
    },
    {
      id: 2,
      operation: 'discount',
      title: translate('discount'),
      icon: Images.iconPlusMinus,
      alwaysVisible: false,
      descriptionOptions: {
        type: DESCRIPTION_OPTIONS.operations,
        textReplace: 'replaceDiscountDescription',
        textPlus: 'addDiscountDescription',
      },
      type: ACTION_TYPE.inputPercent,
    },
    {
      id: 3,
      operation: 'price',
      title: translate('price2'),
      icon: Images.iconCoinSymbol,
      alwaysVisible: false,
      descriptionOptions: {
        type: DESCRIPTION_OPTIONS.operations,
        textReplace: 'replacePriceDescription',
        textPlus: 'addPriceDescription',
      },
      type: ACTION_TYPE.inputMonetary,
    },
    {
      id: 4,
      operation: 'deadline',
      title: translate('deadline'),
      icon: Images.iconCalendar,
      alwaysVisible: false,
      description: '',
      type: ACTION_TYPE.select,
    },
    {
      id: 5,
      operation: 'table',
      title: translate('table'),
      icon: Images.iconPriceTable,
      alwaysVisible: false,
      description: '',
      type: ACTION_TYPE.select,
    },
    {
      id: 6,
      operation: 'removeSelectedItems',
      title: '',
      icon: Images.iconDelete,
      alwaysVisible: true,
      type: 'alert',
      descriptionOptions: {
        type: DESCRIPTION_OPTIONS.noOperations,
        textDescription: 'wantRemoveSelectedItems',
      },
    },
  ],
  OPERATIONS: [
    {
      operation: 'multiple',
      response: {
        action: 'n_usar',
        value: '1',
      },
    },
    {
      operation: 'price',
      response: {
        action: 'n_usar',
        value: '1',
      },
    },
    {
      operation: 'discount',
      response: {
        action: 'n_usar',
        value: '1',
      },
    },
    {
      operation: 'table',
      response: {
        action: 'n_usar',
        value: '1',
      },
    },
    {
      operation: 'deadline',
      response: {
        action: 'n_usar',
        value: '1',
      },
    },
  ],
  OPERATION_TYPE: {
    noUse: 'n_usar',
    plus: 'somar',
    replace: 'subst',
  },
};

constants.ACTION_TYPE = ACTION_TYPE;
constants.DESCRIPTION_OPTIONS = DESCRIPTION_OPTIONS;

export default constants;
