import { StyleSheet } from 'react-native';

import color from '../../../common/color';

const styles = StyleSheet.create({
  container: { flexDirection: 'row' },
  containerExpand: {
    marginLeft: 10,
  },
  containerImages: {
    flexDirection: 'row',
    alignItems: 'center',
    borderTopStartRadius: 5,
    borderBottomStartRadius: 5,
    borderColor: color.primaryColorLight,
    backgroundColor: color.primaryColorLight,
    borderWidth: 1,
  },
  iconMoney: {
    width: 25,
    height: 25,
    tintColor: '#fff',
    marginLeft: 5,

  },
  iconExpand: {
    width: 20,
    height: 30,
    tintColor: '#fff',

  },
  containerPrice: {
    borderColor: color.primaryColorLight,
    borderWidth: 1,
    borderTopEndRadius: 5,
    borderBottomEndRadius: 5,
  },
  textPrice: {
    fontSize: 18,
    color: color.primaryColorLight,
    marginLeft: 40,
    marginRight: 20,
  },
});

export default styles;
