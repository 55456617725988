import AsyncStorage from '@react-native-async-storage/async-storage';

import axios from 'axios';

import CacheAccount from '../../controllers/Accounts/Cache/CacheAccount';
import ConfigController from '../../controllers/ConfigController';
import STAGING from './config';
import { ERROR_GERAL } from '../../constants/Sync';

const URL = `https://process-server${STAGING}.polisuite.com.br/v1/core/settings/`;

export default class SyncConfig {
  constructor() {
    this.cacheAccount = new CacheAccount();
  }

  async sync() {
    const dataAccount = await this.cacheAccount.getDataAccount();
    const config = {
      headers: {
        Authorization: `JWT ${dataAccount.token}`,
      },
    };

    let response = {};
    try {
      response = await axios.get(URL, config);
    } catch (error) {
      throw new Error({
        code: ERROR_GERAL,
        message: 'Erro ao obter as informações de configurações da empresa',
      });
    }

    try {
      const configController = new ConfigController();
      await configController.upsert(response.data);
      await AsyncStorage.setItem('@objPayload', JSON.stringify(response.data));
    } catch (error) {
      throw new Error({
        code: ERROR_GERAL,
        message:
          'Error ao processar as informações de configurações da empresa',
      });
    }

    return true;
  }
}
