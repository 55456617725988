/* @flow */

import React, { Component } from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import color from '../values/color';
import { fonts } from '../../../src/utils/Fonts';
import translate from '~/locales';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  outsideContainer: {
    borderRadius: 50,
    overflow: 'hidden',
  },
  outsideContainer_flat: {
    borderRadius: 0,
  },
  container: {
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: color.primaryColor,
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 50,
    elevation: 2,
  },
  container_big: {
    backgroundColor: color.primaryColor,
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  container_small: {
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  container_flat: {
    backgroundColor: 'transparent',
    elevation: 0,
  },
  container_outline: {
    backgroundColor: 'transparent',
    borderColor: color.primaryColor,
    borderWidth: 1,
    elevation: 0,
  },
  disabled: {
    backgroundColor: color.grayContainerDark,
  },
  title_disabled: {
    color: color.grayMid,
    fontFamily: fonts.QuicksandBold,
  },
  title_flat: {
    color: color.primaryColor,
    fontFamily: fonts.QuicksandBold,
  },
  title_big: {
    fontSize: 16,
  },
  title_small: {
    fontSize: 13,
  },
  title: {
    fontFamily: fonts.QuicksandBold,
    color: '#FFF',
    fontWeight: 'bold',
  },
  icon: {
    tintColor: '#fff',
    width: 20,
    height: 20,
    marginHorizontal: 8,
  },
});

function getIconPadding(big, small) {
  if (big) {
    return 16;
  }
  if (small) {
    return 4;
  }
  return 8;
}

class Button extends Component {
  static defaultProps = {
    title: 'Continuar',
  };
  state = {};

  onPress = () => {
    if (this.props.onPress) {
      this.props.onPress();
    }
  };
  render() {
    const {
      title,
      big,
      titleColor,
      noUpperCase,
      iconButton,
      disabled,
      small,
      flat,
      style,
      outline,
      transparent,
      containerStyle,
      iconLeft,
      iconRight,
    } = this.props;
    return (
      <View
        style={[
          styles.outsideContainer,
          flat ? styles.outsideContainer_flat : {},
          containerStyle,
        ]}>
        <Touchable
          disabled={disabled}
          onPress={this.onPress}>
          <View
            style={[
              styles.container,
              big ? styles.container_big : {},
              small ? styles.container_small : {},
              flat ? styles.container_flat : {},
              disabled ? styles.disabled : {},
              flat && disabled ? { backgroundColor: 'transparent' } : {},
              transparent ? { backgroundColor: 'transparent' } : {},
              outline ? styles.container_outline : {},
              outline && big ? { borderWidth: 2 } : {},
              iconButton
                ? { paddingHorizontal: getIconPadding(big, small) }
                : {},
              style,
            ]}>
            {iconLeft ? (
              <Image
                style={[
                  styles.icon,
                  flat ? { tintColor: color.primaryColor } : {},
                  titleColor ? { tintColor: titleColor } : {},
                  iconButton ? { marginHorizontal: 0 } : {},
                ]}
                source={iconLeft}
              />
            ) : null}
            {title && !iconButton ? (
              <Text
                ellipsize="tail"
                numberOfLines={1}
                style={[
                  styles.title,
                  flat || outline ? styles.title_flat : {},
                  big ? styles.title_big : {},
                  small ? styles.title_small : {},
                  disabled ? styles.title_disabled : {},
                  titleColor ? { color: titleColor } : {},
                ]}>
                {noUpperCase
                  ? title || translate('Enter')
                  : title.toUpperCase() || translate('Enter')?.toUpperCase()}
              </Text>
            ) : null}
            {iconRight !== undefined ? (
              <Image
                style={[
                  styles.icon,
                  flat ? { tintColor: color.primaryColor } : {},
                  titleColor ? { tintColor: titleColor } : {},
                  iconButton ? { margin: 0 } : {},
                ]}
                source={iconRight}
              />
            ) : null}
          </View>
        </Touchable>
      </View>
    );
  }
}

Button.propTypes = {
    onPress: PropTypes.func.isRequired,
    title: PropTypes.string,
    iconButton: PropTypes.bool,
    style: PropTypes.object,
    containerStyle: PropTypes.object,
    big: PropTypes.bool,
    disabled: PropTypes.bool,
    small: PropTypes.bool,
    flat: PropTypes.bool,
    outline: PropTypes.bool,
    iconLeft: PropTypes.any,
    iconRight: PropTypes.any,
    transparent: PropTypes.bool,
    noUpperCase: PropTypes.bool,
    titleColor: PropTypes.string,
};

export default Button;
