import React from 'react';
import { View, Image, Text } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import Images from '@assets/Images';
import translate from '~/locales';

import styles from './styles';

const OrderPending = ({
  onPressEditPendingOrder,
  onPressCancelPendingOrder,
  totalOrderPending,
  clientOrderPending,
}) => {
  return (
    <View>
      <View style={styles.appHighLight}>
        <View style={styles.containerImg}>
          <Image style={styles.imageHighLight} source={Images.iconInfo} />
        </View>
        <View style={styles.containerTitle}>
          <Text style={styles.title}>{`${translate('orderPending')}`}</Text>
        </View>
      </View>
      <View style={styles.containerSubtitle}>
        <Text style={styles.subtitle}>
          {`${translate('wantContinueEditingOrder')}`}
        </Text>
        <Text style={styles.textClient}>{translate('client')}: <Text style={styles.textClientValue}>{clientOrderPending}</Text></Text>
        <Text style={styles.textTotal}>{translate('total')}: <Text style={styles.textClientValue}>{translate('currencySymbol')} {totalOrderPending}</Text></Text>
      </View>
      <View style={styles.containerBtns}>
        <Touchable
          onPress={onPressCancelPendingOrder}
          >
          <View style={styles.containerBtnLeft}>
            <Text style={styles.textButton}>{translate('deleteOrder')}</Text>
        </View>
        </Touchable>
        <Touchable
          onPress={onPressEditPendingOrder}
          style={{
            marginRight: 18,
          }}
          >
          <View style={styles.containerBtnRight}>
            <Text style={styles.textButton}>{translate('restoreOrder')}</Text>
        </View>
        </Touchable>
      </View>
    </View>
  );
};

export default OrderPending;
