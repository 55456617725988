/* @flow */

import React, { Component } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Touchable from 'react-native-platform-touchable';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 16,
        paddingVertical: 5,
        width: 180,
    },
    totalizerName: {
        fontSize: 14,
        color: '#929292',
        fontWeight: '600',
    },
    totalizerValue: {
        fontSize: 12,
        color: '#000',
    },
});

class TotalizerListItem extends Component {
    onPress = () => {

    };

    render() {
        const { totalizer } = this.props;
        return (
            <Touchable
                onPress={this.onPress}
            >
                <View style={styles.container}>
                    <Text style={styles.totalizerName}>{totalizer.totalizador}</Text>
                    {totalizer && totalizer.quantidade !== '' &&
                        <Text style={styles.totalizerValue}>
                            {totalizer.quantidade} {totalizer.dado_qua}
                        </Text>
                    }
                    {totalizer && totalizer.valor !== '' &&
                        <Text style={styles.totalizerValue}>
                            {totalizer.valor} {totalizer.dado_valor}
                        </Text>
                    }
                </View>
            </Touchable>
        );
    }
}

TotalizerListItem.propTypes = {
    totalizer: PropTypes.object.isRequired,
};

export default TotalizerListItem;
