/* @flow */
/* global Number */
import Masked from 'vanilla-masker';
import translate from '~/locales';
import { getPrecisionFromType } from '~/utils/Currency';

export default function Money(
  value = '',
  config = {
    noCurrency: false,
    hasPrefix: true,
    precisionType: 'exhibition',
    formatToNumber: false,
  },
) {
  const precision = getPrecisionFromType(config.precisionType) || 2;
  const moneyFormatter = {
    precision,
    separator: ',',
    delimiter: '.',
    unit: translate('currencySymbol'),
  };
  const positiveOrNegative = value >= 0.0 ? '' : '-';
  if (config.noCurrency === true) {
    moneyFormatter.unit = '';
  }
  if (value) {
    const newValue = config.formatToNumber ? Masked.toNumber(value) : value;
    const number =
      typeof newValue === 'string'
        ? parseFloat(newValue).toFixed(precision)
        : newValue.toFixed(precision);
    return config.hasPrefix
      ? positiveOrNegative + Masked.toMoney(number, moneyFormatter)
      : Masked.toMoney(number, moneyFormatter);
  }
  return config.hasPrefix
    ? positiveOrNegative + Masked.toMoney('0.00', moneyFormatter)
    : Masked.toMoney('0.00', moneyFormatter);
}
