import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { connect } from 'react-redux';
import { TextField } from 'react-native-material-textfield';
import Masked from 'vanilla-masker';
import { ORDER_FETCH_SUCCESS } from '../../../store/ordersFetch/orderFetchSlice';
import { getPrecisionFromType } from '~/utils/Currency';

import PropTypes from 'prop-types';
import FloatingTitleTextInput from '~/components/FloatingTitleTextInput';

class DiscountViewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.cartValue
        ? `${parseFloat(props.cartValue).toFixed(2)}`
        : '0.00',
      percent: props.cartPercent
        ? `${parseFloat(props.cartPercent).toFixed(2)}`
        : '0.00',
    };
  }

  onChange = (value, kind) => {
    const newValue = this.format(value);
    this.setState({ [kind]: newValue });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    (() => {
      if (nextProps.order.type !== ORDER_FETCH_SUCCESS) {
        return;
      }
      let { percent = 0.0, value = 0.0 } =
        nextProps.order.payload[this.props.ord] || {};
      const sValue = parseFloat(this.state.value);
      const sPercent = parseFloat(this.state.percent);
      if (percent !== sPercent || value !== sValue) {
        percent = `${parseFloat(percent).toFixed(2)}`;
        value = `${parseFloat(value).toFixed(2)}`;
        this.setState({ value, percent });
      }
    })();
  }

  format = value => {
    const precision = getPrecisionFromType('discount');
    const moneyFormatter = {
      precision,
      separator: '.',
    };

    return Masked.toMoney(value, moneyFormatter);
  };

  onBlur = kind => {
    const { setDiscountTalao, ord } = this.props;
    const { [kind]: inputV } = this.state;
    const input = {
      kind: kind === 'value' ? 1 : 2,
      value: inputV,
    };

    if (kind === 'value') {
      this.setState({ percent: this.format('0.00') }, () => {
        setDiscountTalao(input, ord);
      });
    } else if (kind === 'percent') {
      this.setState({ value: this.format('0.00') }, () => {
        setDiscountTalao(input, ord);
      });
    }
  };

  render() {
    const { sufixo, prefixo, titulo, descricao1, descricao2 } = this.props;
    return (
      <View style={{ padding: 15 }}>
        <Text
          style={{
            fontSize: 12,
            fontWeight: 'bold',
          }}>
          {titulo}
        </Text>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginTop: 20,
          }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Text
              style={{
                fontWeight: 'bold',
                fontSize: 15,
                marginRight: 1,
              }}>
              {prefixo}
            </Text>
            <FloatingTitleTextInput
              containerStyle={{ width: '60%', marginLeft: 5 }}
              value={`${this.state.value}`}
              keyboardType="numeric"
              ref={ref => {
                this.valueRef = ref;
              }}
              onChangeText={v => this.onChange(v, 'value')}
              onBlur={() => this.onBlur('value')}
              label={descricao2}
            />
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Text
              style={{
                fontWeight: 'bold',
                fontSize: 15,
              }}>
              {sufixo}
            </Text>
            <FloatingTitleTextInput
              containerStyle={{ width: '60%', marginLeft: 5 }}
              value={`${this.state.percent}`}
              keyboardType="numeric"
              ref={ref => {
                this.percentRef = ref;
              }}
              onChangeText={v => this.onChange(v, 'percent')}
              onBlur={() => this.onBlur('percent')}
              label={descricao1}
            />
          </View>
        </View>
      </View>
    );
  }
}

DiscountViewForm.propTypes = {
    setDiscountTalao: PropTypes.func.isRequired,
    ord: PropTypes.string.isRequired,
    value1: PropTypes.string.isRequired,
    sufixo: PropTypes.string.isRequired,
    prefixo: PropTypes.string.isRequired,
    titulo: PropTypes.string.isRequired,
    cartValue: PropTypes.string.isRequired,
    cartPercent: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    order: state.orderFetch,
  };
}

export default connect(mapStateToProps)(DiscountViewForm);
