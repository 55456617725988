import React, { Component } from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';

export default function activeCart(WrappedComponent, propsMapper) {
  class ActiveCart extends Component {
    render() {
      return (
        <View style={{ flex: 1, paddingBottom: 0 }}>
          <WrappedComponent {...this.props} />
        </View>
      );
    }
  }

  function mapStateToProps(state) {
    return {
      ...propsMapper(state),
    };
  }

  return connect(mapStateToProps)(ActiveCart);
}
