import { StyleSheet } from 'react-native';
import { color } from '~/common';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  selectProductsTextStyle: {
    color: color.primary600,
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 16,
    borderBottomColor: color.primary600,
    borderBottomWidth: 1,
    borderStyle: 'dashed',
  },
  countProductsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconClose: {
    tintColor: color.neutral600,
    width: 24,
    height: 24,
    alignSelf: 'center',
  },
  countProductsTextStyle: {
    color: color.neutral600,
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 16,
    marginTop: 4,
  },
});

export default styles;
