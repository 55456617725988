import React from 'react';
import { Image, TouchableOpacity, Text, View } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import TodayContainer from '../screens/Today/Today.Container';
import OffRouteCustomerContainer from '../screens/OffRouteCustomer/OffRouteCustomer.Container';
import ClientProcessContainer from '../screens/ClientProcess/ClientProcess.Container';
import ClientDetailContainer from '../screens/ClientDetail/ClientDetail.Container';
import NewClientsContainer from '../screens/NewClients/NewClients.Container';
import AddClientContainer from '../screens/AddClient/AddClient.Container';
import { Colors } from '../config/config';
import Images from '@assets/Images';
import { fonts } from '../utils/Fonts';
import MessagesContainer from '../screens/Messages/Messages.Container';
import ProductDetailContainer from '~/screens/ProductScreens/ProductDetail/ProductDetail.Container';
import ResumeContainer from '../screens/Resume/Resume.Container';
import ObservationsContainer from '~/screens/Observations/Observations.Container';
import ConditionsSalesChidContainer from '~/screens/ConditionsSalesChild/ConditionsSalesChildContainer';
import ShareOrder from '~/screens/Cart/components/ShareOrder.web';
import OrderObservations from '~/screens/Resume/Observations/Observations.Container';
import CartContainer from '../screens/Cart/Cart.Container';
import ProductTabsContainer from '../screens/ProductScreens/ProductTabs/ProductTabsContainer';
import CombosContainer from '../screens/Combos/Combos.Container';
import ComboDetailContainer from '~/screens/ComboDetail/ComboDetail.Container';
import ActionModal from '~/components/OperationGroup/ActionModal';
import Categories from '~/screens/Categories/Categories';
import OperationGroupActions from '~/components/OperationGroup/Actions/OperationGroupActions';
import ActivitiesContainer from "~/screens/Activities/Activities.Container";
import ActivitieLocationContainer from "~/screens/Activities/Location/Location.Container";
import ActivitiesGrid from "~/screens/Activities/Grid/Grid.Container";
import GridForm from "~/screens/Activities/Grid/GridForm.Container";
import ActivitiesChoice from "~/screens/Activities/Choice/Choice.Container";
import ActivitiesPhoto from "~/screens/Activities/Photo/Photo.Container";
import ActivitiesFreeText from "~/screens/Activities/FreeText/FreeText.Container";
import ActivitiesStopWatch from "~/screens/Activities/Stopwatch/Stopwatch.Container";
import ActivitiesDynamicForm from "~/screens/Activities/DynamicForm/DynamicForm.Container";

const Stack = createStackNavigator();

const HeaderRight = () => (
  <View style={{ flexDirection: 'row' }}>
    <View>
      <TouchableOpacity onPress={() => {}}>
        <Image
          source={Images.iconSearch}
          style={{
            tintColor: '#f0f0f0',
            height: 20,
            width: 20,
            right: 20,
          }}
        />
      </TouchableOpacity>
    </View>
    <View>
      <TouchableOpacity onPress={() => {}}>
        <Text style={{ color: '#f0f0f0', marginRight: 10 }}>SAIR</Text>
      </TouchableOpacity>
    </View>
  </View>
);

function TodayStack() {
  return (
    <Stack.Navigator
      screenOptions={{
        title: 'Roteiro',
        headerStyle: {
          backgroundColor: Colors.primaryColor,
          textAlign: 'center',
        },
        headerTitleAlign: 'left',
        headerTintColor: '#fff',
        headerTitleStyle: {
          fontWeight: 'bold',
          fontFamily: `${fonts.QuicksandBold}`,
        },
        // headerRight: () => HeaderRight(),
      }}>
      <Stack.Screen
        name="Itinenary"
        options={{ title: 'Roteiro' }}
        component={TodayContainer}
      />
      <Stack.Screen
        name="ClientProcess"
        component={ClientProcessContainer}
        options={({ route }) => ({ title: route.params.client.nome })}
      />
      <Stack.Screen
        name="OffRouteCustomer"
        options={{ title: 'Clientes fora de rota' }}
        component={OffRouteCustomerContainer}
      />
      <Stack.Screen
        name="ClientDetail"
        options={{ title: 'Detalhes do Cliente' }}
        component={ClientDetailContainer}
      />
      <Stack.Screen
        name="NewClients"
        options={{ title: 'Novos Clientes' }}
        component={NewClientsContainer}
      />
      <Stack.Screen
        name="AddClient"
        options={{ title: 'Adicionar Cliente' }}
        component={AddClientContainer}
      />
      <Stack.Screen
        name="Messages"
        options={{ title: 'Mensagens' }}
        component={MessagesContainer}
      />
      <Stack.Screen
        name="ProductDetail"
        options={{ title: 'Detalhes do Produto' }}
        component={ProductDetailContainer}
      />
      <Stack.Screen
        name="ProductObservation"
        options={{ title: 'Observações do produto' }}
        component={ObservationsContainer}
      />
      <Stack.Screen
        name="ConditionsSalesChid"
        options={{ title: 'Condições de Venda' }}
        component={ConditionsSalesChidContainer}
      />
      <Stack.Screen
        name="ShareOrder"
        options={{ title: 'Compartilhar Pedido' }}
        component={ShareOrder}
      />
      <Stack.Screen
        name="OrderObservations"
        options={{ title: 'Observações do Pedido' }}
        component={OrderObservations}
      />
      <Stack.Screen
        name="Resume"
        options={{ title: 'Resumo do pedido' }}
        component={ResumeContainer}
      />
      <Stack.Screen
        name="Cart"
        options={{ title: 'Produtos' }}
        component={CartContainer}
      />
      <Stack.Screen
        name="ProductTabs"
        options={{ title: 'Mais Produtos', headerTitleAlign: 'left' }}
        component={ProductTabsContainer}
      />
      <Stack.Screen
        name="Combos"
        options={{ title: 'Combos' }}
        component={CombosContainer}
      />
      <Stack.Screen
        name="ComboDetail"
        options={{ title: 'Detalhes do Combo' }}
        component={ComboDetailContainer}
      />
      <Stack.Screen
        name='ActionModal'
        options={{ title: 'Operação' }}
        component={ActionModal}
      />
      <Stack.Screen
        name="OperationGroupActions"
        options={{ title: 'Operações' }}
        component={OperationGroupActions}
      />
      <Stack.Screen
        name="Categories"
        options={{ title: 'Categorias' }}
        component={Categories}
      />
        <Stack.Screen
            name="Activities"
            options={{ title: 'Atividades' }}
            component={ActivitiesContainer}
        />
        <Stack.Screen
            name="activities.Location"
            options={{ title: 'Localização' }}
            component={ActivitieLocationContainer}
        />
        <Stack.Screen
            name="activities.Grid"
            options={{ title: 'Lista Genérica' }}
            component={ActivitiesGrid}
        />
        <Stack.Screen
            name="activities.GridForm"
            options={{ title: 'Formulário Genérico' }}
            component={GridForm}
        />
        <Stack.Screen
            name="activities.Choice"
            options={{ title: 'Escolha' }}
            component={ActivitiesChoice}
        />
        <Stack.Screen
            name="activities.Photo"
            options={{ title: 'Foto' }}
            component={ActivitiesPhoto}
        />
        <Stack.Screen
            name="activities.FreeText"
            options={{ title: 'Texto Livre' }}
            component={ActivitiesFreeText}
        />
        <Stack.Screen
            name="activities.Stopwatch"
            options={{ title: 'Cronômetro' }}
            component={ActivitiesStopWatch}
        />
        <Stack.Screen
            name="activities.DynamicForm"
            options={{ title: 'Formulário Dinâmico' }}
            component={ActivitiesDynamicForm}
        />
    </Stack.Navigator>
  );
}

export default TodayStack;
