/* @flow */

import React, { useEffect, useState } from 'react';
import { Modal, Text, View } from 'react-native';

import Masked from 'vanilla-masker';
import FloatingTitleTextInput from '~/components/FloatingTitleTextInput';
import PropTypes from 'prop-types';
import { RadioButton, RadioGroup } from '~/components/Web/RadioGroupData';
import Touchable from 'react-native-platform-touchable';
import { TextField } from 'react-native-material-textfield';

import { color } from '~/common';
import translate from '~/locales';
import { getDecimalPrecision } from '~/utils/Currency';
import styles from './styles';
import constants from '../constants';

function Separator() {
  return <View style={styles.separator} />;
}

function getPrefix(type) {
  const prefix = {
    inputPercent: '%',
    inputMonetary: translate('currencySymbol'),
  };

  return prefix[type];
}

function getLabel(type, title) {
  const {
    ACTION_TYPE: { inputValue },
  } = constants;

  const prefixLabel = type === inputValue ? translate('new') : false;

  return prefixLabel ? `${prefixLabel} ${title}` : title;
}

function getPrecisionType(actionType) {
  const precisionType = {
    multiple: 'quantity',
    discount: 'discount',
    price: 'price',
  };
  return precisionType[actionType];
}

function getPlaceHolder(props) {
  const { operation: actionType } = props;

  const precisionType = getPrecisionType(actionType);
  if (precisionType) {
    const precision = getDecimalPrecision(precisionType);
    return Masked.toMoney('0', { precision });
  }
  return '';
}

export function ActionModal({ componentId, route, navigation }) {
  const props = route.params.props;
  const onApply = route.params.onApply;
  const { operation: actionType, type, descriptionOptions } = props;
  const {
    OPERATION_TYPE: { replace, plus },
    DESCRIPTION_OPTIONS: { noOperations, operations: PlusOrReplaceOperation },
  } = constants;

  const title = translate(actionType);
  const prefix = getPrefix(type);
  const label = getLabel(type, title);
  const placeHolder = getPlaceHolder(props);

  const [toggleInputFocus, setToggleInputFocus] = useState(false);
  const [descrAddOrSubst, setDescrAddOrSubst] = useState('');
  const [valueOperation, setValueOperation] = useState(false);
  const [operationType, setOperationType] = useState(replace);
  const [error, setError] = useState({});

  useEffect(() => {
    if (descriptionOptions?.type === PlusOrReplaceOperation) {
      setDescrAddOrSubst(descriptionOptions?.textReplace);
    }

    if (descriptionOptions?.type === noOperations) {
      setDescrAddOrSubst(descriptionOptions?.textDescription);
    }
  }, []);

  const onchangeOperationType = typeSelected => {
    typeSelected === plus
      ? setDescrAddOrSubst(descriptionOptions?.textPlus)
      : setDescrAddOrSubst(descriptionOptions?.textReplace);

    setOperationType(typeSelected);

    if (toggleInputFocus) {
      setToggleInputFocus(!toggleInputFocus);
    }
  };

  const onFocus = () => {
    setError({});
    setToggleInputFocus(!toggleInputFocus);
  };
  const onEndEditing = () => {
    setToggleInputFocus(false);
    setError({});
  };
  const onChangeText = text => setValueOperation(text);

  const onCloseActionModal = () => navigation.goBack();

  const updateOperations = () => {
    return constants.OPERATIONS.map(optItem => {
      if (optItem.operation === actionType) {
        return {
          ...optItem,
          response: {
            action: operationType,
            value: valueOperation,
          },
        };
      }
      return { ...optItem };
    });
  };

  const validateFields = () => {
    if (['alert'].includes(type)) {
      return true;
    }

    if ([false, ''].includes(valueOperation)) {
      setError({ valueOperation: translate('thisFieldIsRequired') });
      return false;
    }

    return true;
  };

  const isOperationGroupingDefault = () => {
    return ['multiple', 'price', 'discount'].includes(actionType);
  };

  const onPressApply = () => {
    const isFieldsValid = validateFields();
    if (!isFieldsValid) {
      return;
    }

    let actionProps = {};
    let actType = actionType;
    if (isOperationGroupingDefault()) {
      actionProps = updateOperations();
      actType = 'operationGroupingDefault';
    }

    actType = ['deadline', 'table'].includes(actionType)
      ? 'deadlineTable'
      : actType;
    onApply(actType, actionProps);
    onCloseActionModal();
  };

  return (
    <View>
      <View style={styles.container}>
        <Modal isVisible={true} animationType="slide" transparent>
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
              <Text style={styles.title}>{title}</Text>
              <Separator />
              {type !== 'alert' ? (
                <>
                  <View style={styles.inputContainer}>
                    <FloatingTitleTextInput
                      useNativeDriver={false}
                      affixTextStyle={styles.affixTextStyle}
                      style={[
                        styles.textFieldStyle,
                        !prefix ? { marginHorizontal: 18 } : {},
                      ]}
                      inputContainerStyle={[
                        styles.textFieldInputContainerStyle,
                        toggleInputFocus
                          ? styles.textFieldFocusedInputContainerStyle
                          : {},
                        error.valueOperation ? styles.textFieldError : {},
                      ]}
                      tintColor={color.primary600}
                      prefix={prefix}
                      label={label}
                      keyboardType={'decimal-pad'}
                      onChangeText={onChangeText}
                      onFocus={onFocus}
                      onEndEditing={onEndEditing}
                      error={error.valueOperation}
                      placeholder={placeHolder}
                    />
                  </View>
                  <View style={styles.radioGroupContainer}>
                    <RadioGroup
                      selectedIndex={0}
                      style={styles.radioGroup}
                      thickness={2}
                      size={18}
                      color={color.neutral900}
                      onSelect={(_, val) => {
                        onchangeOperationType(val);
                      }}>
                      <RadioButton
                        isSelected
                        color={color.primary600}
                        activeColor={color.primary600}
                        style={styles.radioButton}
                        value={replace}>
                        <Text style={styles.radioBtnText}>
                          {translate('replace')}
                        </Text>
                      </RadioButton>
                      <RadioButton
                        color={color.primary600}
                        activeColor={color.primary600}
                        style={styles.radioButton}
                        value={plus}>
                        <Text style={styles.radioBtnText}>
                          {`${translate('add')}`}
                        </Text>
                      </RadioButton>
                    </RadioGroup>
                  </View>
                </>
              ) : null}
              <View>
                <Text style={styles.description}>
                  {translate(descrAddOrSubst)}
                </Text>
              </View>
              <Separator />
              <View style={styles.btnActionsContainer}>
                <Touchable
                  onPress={onCloseActionModal}
                  >
                  <View style={[styles.btnActionsContent, styles.btnCancel]}>
                    <Text style={styles.btnActionsText}>
                      {translate('cancel')}
                    </Text>
                  </View>
                </Touchable>
                <Touchable
                  onPress={onPressApply}
                  >
                  <View style={[styles.btnActionsContent, styles.btnApply]}>
                    <Text style={styles.btnActionsText}>
                      {translate('apply')}
                    </Text>
                  </View>
                </Touchable>
              </View>
            </View>
          </View>
        </Modal>
      </View>
    </View>
  );
}

ActionModal.propTypes = {
  componentId: PropTypes.string.isRequired,
  onApply: PropTypes.func.isRequired,
  props: PropTypes.shape({
    operation: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    descriptionOptions: PropTypes.shape({
      type: PropTypes.string,
      textReplace: PropTypes.string,
      textPlus: PropTypes.string,
      textDescription: PropTypes.string,
    }),
  }),
};

ActionModal.options = {
  layout: {
    componentBackgroundColor: 'transparent',
  },
  overlay: {
    interceptTouchOutside: true,
  },
};