/* @flow */
import Attachment from './Attachment';
import Banners from './Banners';
import Button from './Button';
import CheckboxGroupData from './CheckboxGroupData';
import Form from './Form';
import { DecrementIncrementIcon, PriceItemActive } from './Product';
import RadioGroupData from './RadioGroupData';
import Table from './Table';
import Search from './Search';
import TopInfo from './Profile/TopInfo';
import EmptyView from './EmptyView';
import List from './Profile/List';
import FAB from './FAB';
import StatusBadge from './StatusBadge';
import DatePicker from './DatePicker/DatePicker';
import CustomAlert from './CustomAlert';
import ProgressBar from './ProgressBar';
import SubHeader from './SubHeader';
import AlertBadge from './AlertBadge';
import Section from './Section';
import ContactUs from './ContactUs';
import BarCodeCamera from './BarCodeCamera';
import { TitleListItem, TotalizerListItem, Totalizers } from './Titles';
import OperationGroup from './OperationGroup';
import CustomAlertModal from './CustomAlertModal';
import NoUpToDateAlert from './NoUpToDateAlert';

export function getComponent(codeComponent) {
  switch (codeComponent) {
    case 'component_product_detailt_discount_increment_icon':
      return DecrementIncrementIcon;
    case 'component_product_item_show_price':
      return PriceItemActive;
    default:
      return null;
  }
}

export {
  AlertBadge,
  Attachment,
  Banners,
  Button,
  CheckboxGroupData,
  CustomAlert,
  DatePicker,
  EmptyView,
  Form,
  RadioGroupData,
  Table,
  TopInfo,
  List,
  Search,
  StatusBadge,
  ProgressBar,
  SubHeader,
  FAB,
  Section,
  ContactUs,
  TitleListItem,
  TotalizerListItem,
  Totalizers,
  BarCodeCamera,
  OperationGroup,
  CustomAlertModal,
  NoUpToDateAlert,
};
