import React, { Component } from 'react';
import _ from 'lodash';

import ProductDetail from './ProductDetail';
import {
  ORDER_FETCH_REQUEST,
  ORDER_FETCH_SUCCESS,
} from '../../../store/ordersFetch/orderFetchSlice';
import {
  updateItemFetch,
  UPDATE_ITEM_REQUEST,
} from '~/store/ordersFetch/updateItemSlice';
import Toast from 'react-native-root-toast';
import getRuleMagnitudes from '../Business/rules';
import { MAGNITUDES_ONLY_PRODUCT } from '../../../constants/rulesProductMagnitudes';

import ProductsModule from '../../../modules/ProductsModule';
import OrdersModule from '../../../modules/OrdersModule';
import executeActionOrOpenScreenMessage from '../../../utils/messages';
import translate from '../../../../src/locales';
import { setCurrentProduct } from '../../../store/productCurrent/slice';
import Images from '../../../../assets/Images';
import { connect } from 'react-redux';
import { Alert, TouchableOpacity, Image } from 'react-native';
import CacheDataController from '~/utils/CacheDataController';
import { SET_SCALE_REQUEST } from '~/store/ordersFetch/updateScaleProductSlice';

import PropTypes from 'prop-types';

import getImageProds from '~/utils/GetImageProduct';

import { handleKeyDown, setActionsMap } from '~/utils/KeyBindingsUtils';

class ProductDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.ruleMagnitudes = {};
    this.state = {
      magnitudes: [],
      magnitudeSelect: {},
      product: this.props.product || props?.route?.params?.product,
      updateItem: [],
      fallbackQuantity: 0,
    };
    this.canBack = !props.hasChange;

    const forceOpenMoreInfo = _.get(
      props.configLibFetch,
      'configuracoes.forcar_minfo_detalhe',
      false,
    );

    this.lockedBack = forceOpenMoreInfo;
    this.popScreen = _.debounce(this.onBackPress, 400);

    this.props.navigation.addListener('focus', () => {
      window.addEventListener('keydown', handleKeyDown);
    });

    this.props.navigation.addListener('blur', () => {
      window.removeEventListener('keydown', handleKeyDown);
    });

  }

  componentDidMount() {
    setActionsMap({
      Voltar: () => {
        this.props.navigation.pop();
      },
    });

    this.props.navigation.setOptions({
      title: this.props.route.params.product.nome,
      headerLeft: () => (
        <TouchableOpacity onPress={() => {
          this.props.navigation.pop();
        }}
        >
          <Image
            source={Images.iconBack}
            style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
          />
        </TouchableOpacity>
      ),
    });
  }

  UNSAFE_componentWillMount() {
    const { productsMagnitudes } = this.props;
    const { product } = this.props.route.params;
    const { chave } = product;
    const listMagnitudes = productsMagnitudes && productsMagnitudes.payload;

    const ruleMagnitudes = getRuleMagnitudes(listMagnitudes, product);
    const [magnitudes, magnitudeSelect] = ruleMagnitudes.list(product);
    const magnitudesWithLabels = _.filter(magnitudes, mag => mag.label !== '');
    this.setState({ magnitudes: magnitudesWithLabels, magnitudeSelect });
    this.ruleMagnitudes = ruleMagnitudes;

    const promises = [];
    promises.push(
      ProductsModule.getInfosProduct(product.chave),
      OrdersModule.getItemActive(chave),
    );

    Promise.all(promises)
      .then(data => {
        const productState = { ...this.state.product };
        const infos = data[0];
        productState.infos = JSON.parse(infos);

        let productActive = data[1];
        productActive = JSON.parse(productActive);
        productState.estoque = productActive.estoque;
        productState.preco = productActive.preco;
        productState.precobase = productActive.precobase;
        productState.desconto = productActive.desconto;
        productState.acrescimo = productActive.acrescimo;

        const newProduct = {
          ...productState,
          preco: this.props.mirrorProps
            ? this.props.mirrorProps.price
            : productActive.preco,
          desconto:
            this.props.mirrorProps && this.props.mirrorProps.discountType === 0
              ? this.props.mirrorProps.valueDiscount
              : productActive.desconto,
          acrescimo:
            this.props.mirrorProps && this.props.mirrorProps.discountType === 1
              ? this.props.mirrorProps.valueDiscount
              : productActive.acrescimo,
        };
        const fallbackQuantity = this.props.mirrorProps
          ? this.props.mirrorProps.fallbackQuantity
          : product?.quantidade;

        this.setState({
          product: newProduct,
          fallbackQuantity,
        });
        this.props.dispatch(setCurrentProduct(productState));
      })
      .catch(error => {
        const messages = JSON.parse(error.message);
        executeActionOrOpenScreenMessage(messages);
      });
  }

  componentDidUpdate(prevProps) {
    (async () => {
      if (this.props.order.type === prevProps.order.type) {
        return;
      }
      if (
        prevProps.order.type !== ORDER_FETCH_REQUEST &&
        prevProps.order.type !== SET_SCALE_REQUEST
      ) {
        return;
      }
      if (this.props.order.type !== ORDER_FETCH_SUCCESS) {
        return;
      }
      const product = this.props.product || this.props.route.params.product;

      let infos = await ProductsModule.getInfosProduct(product.chave);
      infos = JSON.parse(infos);
      let productActive = await OrdersModule.getItemActive(product.chave);
      productActive = JSON.parse(productActive);

      this.setState({
        product: { ...productActive, infos },
      });
    })();

    (async () => {
      if (this.props.order.type === prevProps.order.type) {
        return;
      }
      if (prevProps.order.type !== UPDATE_ITEM_REQUEST) {
        return;
      }
      if (this.props.order.type !== ORDER_FETCH_SUCCESS) {
        return;
      }
      const product = this.props?.product || this.props?.route?.params?.product;

      let infos = await ProductsModule.getInfosProduct(product.chave);
      infos = JSON.parse(infos);
      this.setState({
        product: { ...this.state.product, infos },
      });
    })();
  }

  componentDidDisappear() {
    this.BackHandler?.remove();
    this.canBack = true;
  }

  persistProductChanges(close = false) {
    const { updateItem } = this.state;
    const [isUpdateItem, payloadAddItem] = updateItem;

    if (isUpdateItem) {
      this.props.dispatch(updateItemFetch(payloadAddItem));
    }
    if (close) {
      this.closeScreen();
    }
  }

  navigationButtonPressed(event) {
    switch (event.buttonId) {
      case 'backPress':
        this.popScreen();
        break;
      case 'RNN.hardwareBackButton':
        this.popScreen();
        break;
      default:
        break;
    }
  }

  closeScreen() {
    this.canBack = true;

    if (this.props.cameByShowModal) {
      this.props.navigation.pop();
    } else {
      this.props.navigation.pop();
    }
  }

  onBackPress() {
    if (this.lockedBack) {
      return;
    }
    if (!this.canBack) {
      Alert.alert(
        `${translate('discardChanges')}`,
        `${translate('lostChangesOnExit')}`,
        [
          {
            text: `${translate('no')}`,
            style: 'cancel',
          },
          {
            text: `${translate('yesRemove')}`,
            onPress: this.discardChanges,
          },
        ],
        { cancelable: false },
      );
    } else {
      this.closeScreen();
    }
  }

  discardChanges = async () => {
    const fallbackQuantity = this.state.fallbackQuantity;
    const { updateItem } = this.state;
    const [isUpdateItem, payloadAddItem] = updateItem;
    if (isUpdateItem) {
      this.setState(
        {
          updateItem: [
            isUpdateItem,
            {
              ...payloadAddItem,
              item: {
                ...payloadAddItem?.item,
                quantidade: fallbackQuantity,
              },
              ignoreQtdSuggestion: true,
            },
          ],
        },
        () => {
          this.persistProductChanges(true);
        },
      );
    } else if (!isUpdateItem) {
      const { codcliente } = this.props.order.payload.condvenda;
      const product = this.props.product;
      let productActive = await OrdersModule.getItemActive(product.chave);

      productActive = JSON.parse(productActive);
      const ownPayload = {
        clientCode: codcliente,
        itemKey: productActive.chave.toString(),
        item: {
          ...productActive,
          quantidade: fallbackQuantity,
        },
        ignoreQtdSuggestion: true,
      };
      this.setState(
        {
          updateItem: [true, ownPayload],
        },
        () => {
          this.persistProductChanges(true);
        },
      );
    } else {
      this.closeScreen();
    }
  };

  setProductImages = async product => {
    const productImages = await getImageProds(product);
    if (productImages && productImages.length > 0) {
      const productWithImages = {
        ...this.state.product,
        images: productImages,
      };
      this.setState({ product: productWithImages });
    }
  };

  desconto = async (item, value) => {
    const { order } = this.props;
    const { codcliente } = order.payload.condvenda;

    if (item.quantidade === 0) {
      try {
        let data = await OrdersModule.calcDiscountIncrement(
          item.chave.toString(),
          value.toString(),
          0,
        );
        data = JSON.parse(data);

        const { preco, acrescimo, desconto } = data;
        const product = {
          ...item,
          preco,
          acrescimo,
          desconto,
          quantidade: item.multiplo_venda,
        };

        this.setState({ product }, () => {
          const itemCopy = {
            ...product,
            desconto: value,
            force_discount_increase: true,
          };
          const payloadAddItem = {
            clientCode: codcliente,
            itemKey: itemCopy.chave.toString(),
            item: itemCopy,
            ignoreQtdSuggestion: true,
          };
          this.props.dispatch(updateItemFetch(payloadAddItem));
        });
      } catch (error) {
        const messages = JSON.parse(error.message);
        executeActionOrOpenScreenMessage(messages);
      }
    } else {
      const itemCopy = {
        ...item,
        desconto: value,
        force_discount_increase: true,
      };
      const payloadAddItem = {
        clientCode: codcliente,
        itemKey: itemCopy.chave.toString(),
        item: itemCopy,
        ignoreQtdSuggestion: true,
      };
      this.props.dispatch(updateItemFetch(payloadAddItem));
    }
  };

  acrescimo = async (item, value) => {
    const { order } = this.props;
    const { codcliente } = order.payload.condvenda;

    if (item.quantidade === 0) {
      try {
        let data = await OrdersModule.calcDiscountIncrement(
          item.chave.toString(),
          value.toString(),
          1,
        );
        data = JSON.parse(data);

        const { preco, acrescimo, desconto } = data;
        const product = {
          ...item,
          preco,
          acrescimo,
          desconto,
          quantidade: item.multiplo_venda,
        };

        this.setState({ product }, () => {
          const itemCopy = {
            ...product,
            acrescimo: value,
            force_discount_increase: true,
          };
          const payloadAddItem = {
            clientCode: codcliente,
            itemKey: itemCopy.chave.toString(),
            item: itemCopy,
            ignoreQtdSuggestion: true,
          };
          this.props.dispatch(updateItemFetch(payloadAddItem));
        });
      } catch (error) {
        const messages = JSON.parse(error.message);
        executeActionOrOpenScreenMessage(messages);
      }
    } else {
      const itemCopy = {
        ...item,
        acrescimo: value,
        force_discount_increase: true,
      };
      const payloadAddItem = {
        clientCode: codcliente,
        itemKey: itemCopy.chave.toString(),
        item: itemCopy,
        ignoreQtdSuggestion: true,
      };

      this.props.dispatch(updateItemFetch(payloadAddItem));
    }
  };

  recalculatePrice = async (values, item, callback = () => {}) => {
    const { price, discountType, valueDiscount, quantity } = values;
    try {
      let data;

      if (valueDiscount !== 0) {
        data = await OrdersModule.calcDiscountIncrement(
          item.chave.toString(),
          `${valueDiscount}`.toString(),
          discountType,
        );
      } else {
        data = await OrdersModule.calcPrice(
          item.chave.toString(),
          `${price}`.toString(),
        );
      }
      data = JSON.parse(data);

      const { acrescimo, desconto } = data;

      let infos = '';
      const respInfo = await ProductsModule.getInfosProduct(item.chave);
      infos = JSON.parse(respInfo);

      const product = {
        ...item,
        acrescimo,
        desconto,
        quantidade: quantity,
        preco: price,
        precototal: quantity * price,
        from_recalculate: true,
        infos,
      };

      this.setState({ product }, () => callback());
    } catch (error) {
      const messages = JSON.parse(error.message);
      executeActionOrOpenScreenMessage(messages);
    }
  };

  updatePrice = async (item, value) => {
    const { order } = this.props;
    const { codcliente } = order.payload.condvenda;

    if (item.quantidade === 0) {
      try {
        let data = await OrdersModule.calcPrice(
          item.chave.toString(),
          value.toString(),
        );
        data = JSON.parse(data);

        const { preco, acrescimo, desconto } = data;
        const product = {
          ...item,
          preco,
          acrescimo,
          desconto,
          quantidade: item.multiplo_venda,
        };

        this.setState({ product }, () => {
          const itemCopy = {
            ...product,
            preco: value,
            force_discount_increase: true,
          };
          const payloadAddItem = {
            clientCode: codcliente,
            itemKey: itemCopy.chave.toString(),
            item: itemCopy,
            ignoreQtdSuggestion: true,
          };
          this.props.dispatch(updateItemFetch(payloadAddItem));
        });
      } catch (error) {
        const messages = JSON.parse(error.message);
        executeActionOrOpenScreenMessage(messages);
      }
    } else {
      const itemCopy = { ...item, preco: value, force_discount_increase: true };

      const payloadAddItem = {
        clientCode: codcliente,
        itemKey: itemCopy.chave.toString(),
        item: itemCopy,
        ignoreQtdSuggestion: true,
      };
      this.props.dispatch(updateItemFetch(payloadAddItem));
    }
  };

  saveInCache = async () => {
    const { product = {} } = this.state;
    const { quantidade: quantity, type = '' } = product;

    await CacheDataController.saveData('@ADD_ITEM_REF', {
      product,
      quantity,
      type,
    });
  };

  addItem = (product, quantity, ignoreQtdSuggestion = false, close = true) => {
    const { order } = this.props;
    const { codcliente } = order.payload.condvenda;
    const productCopy = {
      ...product,
      force_discount_increase: true,
      quantidade: quantity,
    };
    this.setState({ product: productCopy }, () => {
      this.saveInCache();
      const payloadAddItem = {
        clientCode: codcliente,
        itemKey: productCopy?.chave?.toString(),
        item: productCopy,
        ignoreQtdSuggestion,
      };
      this.setState(
        {
          updateItem: [true, payloadAddItem],
        },
        () => {
          this.persistProductChanges(close);
        },
      );
    });
  };

  updateProductMagnitude = (magnitudeSelect, isUpdateItem = true) => {
    if (magnitudeSelect.value !== this.state.magnitudeSelect.value) {
      const { productsFetch } = this.props;
      const cods = productsFetch.codGroupList;
      const productsTree = productsFetch.payload;

      const product = this.ruleMagnitudes.updateMagnitude(
        this.state.product,
        productsTree,
        cods,
        magnitudeSelect,
      );

      if (product) {
        this.setState({ product, magnitudeSelect });

        if (
          product.type_magnitudes === MAGNITUDES_ONLY_PRODUCT &&
          isUpdateItem
        ) {
          const { codcliente } = this.props.order.payload.condvenda;
          const payloadAddItem = {
            clientCode: codcliente,
            itemKey: product.chave.toString(),
            item: product,
            ignoreQtdSuggestion: true,
          };
          this.props.dispatch(updateItemFetch(payloadAddItem));
        }
      } else {
        this.setState(state => ({ magnitudeSelect: state.magnitudeSelect }));
        Toast.show(
          'Seus produtos não estão na mesma hieraquia. Entre em contato com o suporte.',
          {
            duration: Toast.durations.LONG,
            position: Toast.positions.CENTER,
          },
        );
      }
    }
  };

  updateProduct = product => {
    const newProduct = {
      ...product,
      precototal: product.preco * product.quantidade,
    };
    this.setState({
      product: { ...newProduct },
    });
  };

  hadChange = () => {
    this.canBack = false;
  };
  onMoreInfoFinishLoad = () => {
    this.lockedBack = false;
  };

  onMoreInfoStartLoad = () => {
    this.lockedBack = true;
  };

  render() {
    const { order, config, mirrorProps, configLibFetch } = this.props;
    const { magnitudes, magnitudeSelect, product } = this.state;
    const ignorarMultiploVenda = _.get(
      order,
      'payload.configuracoes.ignora_multiplo_de_venda',
    );

    return (
      <ProductDetail
        product={product}
        cart={order || {}}
        config={config.payload}
        configLib={configLibFetch}
        ignorarMultiploVenda={ignorarMultiploVenda}
        magnitudeSelected={magnitudeSelect}
        magnitudes={magnitudes}
        ruleMagnitudes={this.ruleMagnitudes}
        addItem={this.addItem}
        desconto={this.desconto}
        acrescimo={this.acrescimo}
        updatePrice={this.updatePrice}
        updateProductMagnitude={this.updateProductMagnitude}
        updateProduct={this.updateProduct}
        recalculatePrice={this.recalculatePrice}
        hadChange={this.hadChange}
        mirrorProps={mirrorProps}
        overallLoading={this.props.order.loading}
        onMoreInfoStartLoad={this.onMoreInfoStartLoad}
        onMoreInfoFinishLoad={this.onMoreInfoFinishLoad}
        navigation={this.props.navigation}
      />
    );
  }
}

ProductDetailContainer.propTypes = {
    order: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    configLibFetch: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    productsMagnitudes: PropTypes.object.isRequired,
    productsFetch: PropTypes.object.isRequired,
    currentProduct: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    sScale: PropTypes.object.isRequired,
    componentId: PropTypes.string.isRequired,
    cameByShowModal: PropTypes.bool,
    mirrorProps: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    order: state.orderFetch,
    config: state.configFetch,
    configLibFetch: state.configLibFetch.payload,
    productsMagnitudes: state.productsMagnitudes,
    sScale: state.scale,
    productsFetch: state.productsFetch,
    currentProduct: state.productCurrent,
  };
}

export default connect(mapStateToProps)(ProductDetailContainer);
