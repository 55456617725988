/* @flow */

import React, { Component } from 'react';
import { Platform } from 'react-native';
import ComboSelectMenu from '../../ComboSelectMenu';
import _ from 'lodash';

import PropTypes from 'prop-types';

class List extends Component {
    state = {};
    UNSAFE_componentWillMount() {
        const dataSource = this.props.data_source;

        const { value, type } = this.props;
        const newValue = value && value.label ? value.value : value;
        const selectedValue =
            type !== 'QSQL' && value
                ? dataSource.find(o => o.value === newValue) || dataSource[0]
                : newValue || '';
        this.setState({ selectedValue }, () =>
            this.props.onChangeValue(
                this.props.field,
                this.state.selectedValue,
                'typed',
            ),
        );
    }

    onPressOption = (eventName, index) => {
        if (eventName !== 'itemSelected') {
            return;
        }
        let dataSource = this.props.data_source;
        if (!Array.isArray(this.props.data_source)) {
            dataSource = this.props.data_source.split(',');
        }
        this.setState({ selectedValue: dataSource[index] }, () => {
            this.props.onChangeValue(
                this.props.field,
                this.state.selectedValue,
                'typed',
            );
        });
    };

    onChangeValue = value => {
        this.setState({ selectedValue: value }, () => {
            this.props.onChangeValue(
                this.props.field,
                this.state.selectedValue,
                'typed',
            );
        });
    };

    renderEntry(values, field) {
        const { selectedValue } = this.state;
        let dataSource = this.props.data_source;
        if (!Array.isArray(this.props.data_source)) {
            dataSource = this.props.data_source.split(',');
        }
        let valueSelected = selectedValue?.label || selectedValue;
        if (!valueSelected) {
            valueSelected = '';
        }

        return (
            <ComboSelectMenu
                field={field}
                required={this.props.required}
                outline={Platform.OS === 'ios'}
                style={this.props.style}
                title={this.props.title || this.props.titulo || this.props.description}
                value={valueSelected}
                actions={_.map(dataSource, 'label')}
                onChangeValue={this.onChangeValue}
                onPress={this.onPressOption}
                editable={this.props.editable}
            />
        );
    }

    render() {
        let dataSource = this.props.data_source;
        if (!Array.isArray(this.props.data_source)) {
            dataSource = this.props.data_source.split(',');
        }
        return this.renderEntry(
            dataSource,
            this.props.field || this.props.description,
        );
    }
}

List.propTypes = {
    field: PropTypes.string,
    data_source: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    title: PropTypes.string,
    titulo: PropTypes.string,
    description: PropTypes.string,
    style: PropTypes.any,
    value: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.string,
    onChangeValue: PropTypes.func,
    editable: PropTypes.bool,
};

List.defaultProps = {
    onChangeValue: () => { },
    editable: true,
};

export default List;
