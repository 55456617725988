import { Platform, StyleSheet } from 'react-native';
import { color, size } from '~/common';
import { colors } from '../../../components_base/src/values';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  positionAbsolute: {
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    position: 'absolute',
  },
  description: {
    marginBottom: 10,
    fontSize: 16,
    fontWeight: 'bold',
    color: '#1A1A1A',
  },
  title: {
    marginBottom: 5,
    fontSize: 12,
  },
  message: {
    color: '#929292',
    fontSize: 14,
    marginLeft: 20,
    marginTop: 5,
  },
  period: {
    color: color.grayDarkest,
    fontSize: 14,
  },
  icon: {
    width: 30,
    height: 30,
    tintColor: color.grayDark,
  },
  label: {
    marginLeft: 5,
    color: color.grayDarkest,
    fontSize: 14,
    fontWeight: '500',
  },
  value: {
    fontSize: 14,
    fontWeight: '400',
  },
  strip: {
    width: 12,
    height: 30,
    position: 'absolute',
    left: 8,
    top: 10,
    bottom: 0,
    borderRadius: 10,
  },
  title_group: {
    fontSize: 15,
    marginRight: 10,
    fontWeight: '500',
  },
  separator: {
    height: 1,
    backgroundColor: colors.grayContainer,
  },
  chevron: {
    tintColor: color.grayMid,
    width: 35,
    height: 35,
    resizeMode: 'contain',
  },
  alert_combo_applied: {
    height: '6%',
    borderColor: '#000',
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: color.successColor,
    borderRadius: 8,
    padding: 20,
  },
  alert_combo_applied_text: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
  },
  alert_combo_applied_image: {
    marginLeft: 10,
    tintColor: '#FFF',
  },
});

export default styles;
