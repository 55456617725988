import StockerProductResource from '../services/resources/StockerProductResource';
import { getPrecisionFromType, parseStrToFloat } from './Currency';

export function getSourceImageProduct(product, configStocker) {
  let sourceImage = null;
  const { url_stocker: urlStocker, token_stocker: tokenStocker } =
    configStocker || {};

  if (urlStocker && tokenStocker && product.ean && product.ean !== '0') {
    sourceImage =
      StockerProductResource.getThumbImage(
        urlStocker,
        tokenStocker,
        product.barcode || product.ean,
        false,
      ) || null;
  } else if (product.url_imagem && product.url_imagem !== '0') {
    sourceImage = { uri: product.url_imagem };
  }

  return sourceImage;
}

export function getMultipleSaleByRule(
  product,
  ignoreMultipleSales,
  ruleMagnitudes,
  magnitudeSelect,
) {
  return ignoreMultipleSales
    ? product?.unidade
    : ruleMagnitudes?.getMultipleSale(product, magnitudeSelect) || 1;
}

export function getQtyFromStockAndSalesMultiple(quantity, stock, ignoreStock) {
  const precision = getPrecisionFromType('quantity');
  const formattedQuantity = parseStrToFloat(quantity, precision);
  let qty = quantity;
  qty = formattedQuantity;
  if (qty < 0) {
    qty = 0;
  }
  if (qty > stock && !ignoreStock) {
    qty = stock;
  }
  return qty;
}

export function hasProductDataChanged(productA = {}, productB = {}) {
  const { preco, precobase, desconto, acrescimo, quantidade } = productA;

  const {
    preco: precoB,
    precobase: precobaseB,
    desconto: descontoB,
    acrescimo: acrescimoB,
    quantidade: quantidadeB,
  } = productB;

  const hasChanged =
    preco !== precoB ||
    precobase !== precobaseB ||
    desconto !== descontoB ||
    acrescimo !== acrescimoB ||
    quantidade !== quantidadeB;

  return hasChanged;
}

export const adjustHtml = html =>
  html
    ? html
        .replace(/<(table|tbody|tr|td)[^>]*>/g, '<div>')
        .replace(/<(TABLE|TBODY|TR|TD)[^>]*>/g, '<div>')
        .replace(/<\/(table|tbody|tr|td)[^>]*>/g, '</div>')
        .replace(/<\/(TABLE|TBODY|TR|TD)[^>]*>/g, '</div>')
    : '';
