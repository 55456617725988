import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import { color } from '~/common/index';
import Images from '@assets/Images';
import Touchable from 'react-native-platform-touchable';

type Props = {
  value: string,
  onPress: () => void,
  selected: boolean,
};

const styles = StyleSheet.create({
  outsideContainer: {
    marginBottom: 8,
    borderRadius: 24,
    marginHorizontal: 2,
    height: 28,
    minWidth: 28,
    overflow: 'hidden',
  },
  container: {
    borderRadius: 24,
    height: 28,
    minWidth: 28,
    justifyContent: 'center',
    borderColor: color.grayContainerDark,
    borderWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  attribute: {
    fontSize: 13,
    marginRight: 10,
    marginLeft: 10,
    color: color.grayDark,
  },
  badge: {
    backgroundColor: color.successColor,
    width: 12,
    height: 12,
    borderRadius: 7,
    position: 'absolute',
    right: 0,
    top: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconCheck: {
    width: 10,
    height: 10,
    tintColor: '#fff',
  },
});

const ItemCheck = ({ value, onPress, selected }: Props) => (
  <View>
    <View style={[styles.outsideContainer]}>
      <Touchable
        foreground={Touchable.SelectableBackground()}
        onPress={() => onPress(value)}>
        <View style={styles.container}>
          <Text style={styles.attribute}>{value}</Text>
        </View>
      </Touchable>
    </View>
    {selected && (
      <View style={styles.badge}>
        <Image style={styles.iconCheck} source={Images.iconCheck} />
      </View>
    )}
  </View>
);

export default ItemCheck;
