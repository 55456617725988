/* @flow */

import React, { Component } from 'react';
import {
  FlatList,
  Image,
  Platform,
  StyleSheet,
  Text,
  TextInput,
  View,
  ActivityIndicator,
} from 'react-native';
import { isChildWindow } from '~/services/anywhere/constants';
import RawBottomSheetClients from '../../components/RawBottomSheet/RawBottomSheetClients';
import Modal from 'react-native-modal';
import { RadioButton, RadioGroup } from 'react-native-flexi-radio-button';
import defaultStyles from '../../styles';
import ProductList from '../Product/ProductList';
import TextUtils from '~/utils/TextUtils';
import { color } from '~/common';
import Categories from '../../../components_base/src/components/Categories';
import CategoryDetailParser from './utils/CategoryDetailParser';
import ProductsCarousel from './components/ProductsCarousel';
import Images from '../../../assets/Images';
import EmptyView from '../../components/EmptyView';
import translate, { calendarLanguage as language } from '../../locales';
import { getSourceImageProduct } from '../../utils/ProductUtil';
import BottomSheet from '../../components/BottomSheet/index';
import ProductListGroup from '../../components/OperationByGroup/ProductListGroup';
import OperationByGroup from '../../components/OperationByGroup/OperationByGroup';
import _ from 'lodash';
import {
  CarrousselProdsPlaceholder,
  CategoriesPlaceholder,
  OnlyProductsPlaceholder,
} from './components/CategoryModulesPlaceholder';
import Touchable from 'react-native-platform-touchable';
import { fonts } from '~/utils/Fonts';
import onPressHelper from '~/utils/onPressHelper';
import { FloatingAction } from 'react-native-floating-action';
import { OperationGroup } from '~/components';

import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import ModalOverlay from '~/components/Web/ModalOverLay/index.web';
import { createNotifyInfo } from '~/components/Web/ToastNotify';

import Actions from '~/components/OperationGroup/Actions';

const BUDGET_FAB_OPTION = {
  name: 'save_budget',
  text: `${translate('saveBudget')}`,
  icon: <Image source={Images.iconSave} tintColor={'#FFF'} style={{ width: 18, height: 18 }} />,
};

const SHARING_OPTION = {
  name: 'share_order',
  text: 'Compartilhar com cliente',
  icon: <Image source={Images.iconShare} tintColor={'#FFF'} style={{ width: 18, height: 18 }} />,
};

const DEFAULT_FLOATING_OPTIONS = [
  {
    name: 'cancel_order',
    text: 'Cancelar carrinho',
    icon: <Image source={Images.iconTrash} tintColor={'#FFF'} style={{ width: 18, height: 18 }} />,
  },
];

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: color.grayContainer,
  },
  detailsComponent: {
    marginTop: 15,
  },
  containerSearch: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: color.grayMid,
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 4,
  },
  iconLeft: {
    padding: 10,
    tintColor: color.grayMid,
    width: 10,
    height: 10,
  },
  input: {
    flex: 1,
    paddingTop: 10,
    paddingRight: 15,
    paddingBottom: 10,
    paddingLeft: 0,
    backgroundColor: '#fff',
    color: '#000',
    marginLeft: 5,
    fontSize: 15,
    padding: 5,
  },
  iconRigthPressable: {
    padding: 10,
  },
  iconRigth: {
    tintColor: color.grayMid,
    width: 20,
    height: 20,
  },
});

const NAME = `${translate('name')} (A-Z)`;
const PRICE = `${translate('minPrice')}`;
const SORT_OPTIONS = [NAME, PRICE];

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      isSearchActive: false,
      FABOpened: false,
    };

    this.onEndEditingSearch = _.debounce(val => {
      this.props.onSearch(val);
    }, 300);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.goToTopScreen) {
      this.flatListRef.scrollToOffset({ offset: 0, animated: true });
      this.setState({ searchValue: '', isSearchActive: false }, () => {
        this.onEndEditingSearch('');
      });
    }
    if (nextProps.showBackPressAlert === this.props.showBackPressAlert) {
      return;
    }
  }

  onClose = () => this.props.onClose();

  getModalSortAndroid() {
    return (
      <Modal
        isVisible={this.props.showModalSort}
        onBackButtonPress={this.onClose}>
        <View>
          <View style={defaultStyles.modal}>
            <View>
              <View style={{ padding: 16 }}>
                <Text style={defaultStyles.modalTitle}>{`${translate(
                  'orderBy',
                )}`}</Text>
                <View>
                  <RadioGroup
                    style={{ marginTop: 20 }}
                    color={color.gray}
                    selectedIndex={SORT_OPTIONS.indexOf(
                      this.props.actualSortValue,
                    )}
                    activeColor={color.primaryColor}
                    onSelect={(index, value) => this.props.sort(value)}>
                    {SORT_OPTIONS.map((option, index) => (
                      <RadioButton value={`${index}`}>
                        <Text>{option}</Text>
                      </RadioButton>
                    ))}
                  </RadioGroup>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Modal>
    );
  }

  categoryClick = section => {
    this.props.categoryClick(section);
  };

  onPressCategoryClick = onPressHelper.debounce(this.categoryClick);

  productClick = item => {
    this.props.productClick(item);
  };

  onPressProductClick = onPressHelper.debounce(this.productClick);

  refFlatList = ref => {
    this.flatListRef = ref;
  };

  keyExtractor = item => `${item.chave}`;

  handleLoadMore = type => {
    if (this.props.fetchMoreProducts) {
      this.props.fetchMoreProducts(type);
    }
  };

  renderCategories = (categories, categoryClick) => {
    const categoriesToShowInCartScreen = categories.slice(0, 10);
    const { loadingCategoriesProducts, navigation } = this.props;
    let component = null;

    const showCategories = Boolean(categories && categories.length > 0);

    if (loadingCategoriesProducts) {
      component = <CategoriesPlaceholder />;
    } else if (showCategories) {
      component = (
        <Categories
          categories={categoriesToShowInCartScreen}
          onPressItem={categoryClick}
          scrollToCategory={this.props.scrollToCategory}
          actualCategory={this.props.actualCategory}
          onEndReachedThreshold={0.1}
          onEndReached={() => {
            this.handleLoadMore({ type: 'categories' });
          }}
          componentIdCart={this.props.componentIdCart}
          allCategories={categories}
          navigattion={navigation}
        />
      );
    }

    return component;
  };

  // TODO: Trazer as listagens de produtos do carrosel de forma dinâmica e parametrizavel.
  renderSalesHistoryCarousel = () => {
    const {
      itemUpdate,
      initialNumToRender,
      addHistoryProduct,
      clickProductInHistoryProduct,
      historyProducts = [],
      showCarouselType = {},
      loadingSalesHistory,
    } = this.props;

    if (loadingSalesHistory) {
      return <CarrousselProdsPlaceholder />;
    }

    if (showCarouselType.historico_venda && historyProducts.length) {
      return (
        <View style={[styles.detailsComponent]}>
          <ProductsCarousel
            title={`${translate('saleHistory')}`}
            itemUpdate={itemUpdate}
            products={historyProducts}
            addQuantity={addHistoryProduct}
            productClick={clickProductInHistoryProduct}
            showUnavailableProducts
            productType="history"
            onEndReachedThreshold={0.1}
            onEndReached={() => this.handleLoadMore({ type: 'saleHistory' })}
            initialNumToRender={initialNumToRender}
            ListFooterComponent={this.renderListFooter(
              'See_All',
              'saleHistory',
            )}
            ListFooterComponentStyle={{ alignSelf: 'center' }}
          />
        </View>
      );
    }
  };

  renderSaleSuggestionCarousel = () => {
    const {
      itemUpdate,
      initialNumToRender,
      addSuggestionProduct,
      clickProductInSuggestionProduct,
      suggestionProducts = [],
      showCarouselType = {},
      loadingSaleSuggestionProducts,
    } = this.props;

    if (showCarouselType.sugestao_venda && suggestionProducts.length) {
      return (
        <View style={[styles.detailsComponent]}>
          <ProductsCarousel
            title={`${translate('productsSuggestion')}`}
            itemUpdate={itemUpdate}
            products={suggestionProducts}
            addQuantity={addSuggestionProduct}
            productClick={clickProductInSuggestionProduct}
            showUnavailableProducts={false}
            productType="suggestion"
            onEndReachedThreshold={0.1}
            onEndReached={() =>
              this.handleLoadMore({ type: 'productsSuggestion' })
            }
            initialNumToRender={initialNumToRender}
            ListFooterComponent={this.renderListFooter(
              'See_All',
              'productsSuggestion',
            )}
            ListFooterComponentStyle={{ alignSelf: 'center' }}
          />
        </View>
      );
    }
    return loadingSaleSuggestionProducts ? (
      <CarrousselProdsPlaceholder />
    ) : null;
  };

  toggleProductsGrouping = ({ activeGrouping }) => {
    if (activeGrouping) {
      this.props.handleProductGrouping?.('toggle');
    } else {
      this.props.handleProductGrouping?.('clearAll');
    }
  };

  renderListHeader = () => {
    const {
      categories = [],
      loadingCategoriesProducts,
      visibleSaleHistorySuggetion,
      visibleSaleSuggestionProducts,
      enableOperationGroup,
      selectedProducts,
    } = this.props;

    const categoriesDetail = CategoryDetailParser.categoriesfromJsonLib(
      categories,
    );

    return (
      <View
        style={[
          styles.detailsComponent,
          {
            marginRight: 6,
            marginLeft: 8,
            marginBottom: 14,
          },
        ]}>
        {!this.state.isSearchActive
          ? this.renderCategories(categoriesDetail, this.onPressCategoryClick)
          : null}
        {!this.state.isSearchActive && visibleSaleSuggestionProducts ? (
          <View style={[styles.detailsComponent]}>
            {this.renderSaleSuggestionCarousel()}
          </View>
        ) : null}
        {!this.state.isSearchActive && visibleSaleHistorySuggetion ? (
          <View style={styles.detailsComponent}>
            {this.renderSalesHistoryCarousel()}
          </View>
        ) : null}
        {enableOperationGroup ? (
          <View>
            <OperationGroup.Header
              qtySelected={selectedProducts.length}
              toggleProductsGrouping={this.toggleProductsGrouping}
              toggleHeaderContent={this.props.activeProductsGrouping === false}
            />
          </View>
        ) : null}
      </View>
    );
  };

  seeAllProducts = () => {
    this.setState({ isSearchActive: true, searchValue: '' }, () => {
      this.flatListRef.scrollToOffset({ offset: 0, animated: true });
      this.props.onPressShowAll(null, () => {
        this.onEndEditingSearch(this.state.searchValue);
        this.setState({ isSearchActive: false });
      });
    });
  }

  renderListFooter = (actionTitle, type = null) => {
    return (
      <View style={{ alignSelf: 'center', marginVertical: 12 }}>
        <Touchable
          style={{ paddingHorizontal: 24 }}
          onPress={() => {
            this.setState({ isSearchActive: true, searchValue: '' }, () => {
              this.flatListRef.scrollToOffset({ offset: 0, animated: true });
              this.props.onPressShowAll(type, () => {
                this.onEndEditingSearch(this.state.searchValue);
                this.setState({ isSearchActive: false });
              });
            });
          }}>
          <Text
            style={{
              fontSize: 16,
              fontFamily: fonts.QuicksandBold,
              color: color.primaryColor,
              fontWeight: 'bold',
            }}>
            {translate(actionTitle)}
          </Text>
        </Touchable>
      </View>
    );
  };

  renderSearch = () => (
    <View style={{ marginTop: 10, padding: 10 }}>
      <View style={styles.containerSearch}>
        {this.props.loadingAllProducts ? (
          <ActivityIndicator
            style={{ width: 10, height: 10, padding: 10 }}
            animating={this.props.loadingAllProducts}
            color={color.grayMid}
          />
        ) : (
          <Image source={Images.iconSearch} style={styles.iconLeft} />
        )}

        <TextInput
          editable={!this.props.loadingAllProducts}
          selectTextOnFocus
          placeholder={
            this.props.loadingAllProducts
              ? `${translate('loadingAllProducts')}`
              : `${translate('searchForProducts')}`
          }
          value={this.state.searchValue}
          onEndEditing={() => {
            if (this.inputSearchRef) {
              this.inputSearchRef.blur();
            }
          }}
          returnKeyType="search"
          defaultValue=""
          onChangeText={val => {
            this.setState({ searchValue: val }, () => {
              this.onEndEditingSearch(val);
            });
          }}
          ref={ref => {
            this.inputSearchRef = ref;
          }}
          blurOnSubmit
          style={styles.input}
          placeholderTextColor={color.grayMid}
          onFocus={() => this.setState({ isSearchActive: true })}
          onBlur={() => {
            if (!this.state.searchValue) {
              this.setState({ isSearchActive: false });
            }
          }}
        />

        {this.state.searchValue?.length > 0 ? (
          <Touchable
            tabIndex={-1}
            onPress={this.forceInsertSearchValue}
            style={styles.iconRigthPressable}
            disabled={this.props.loadingAllProducts}>
            <Image source={Images.iconClose} style={styles.iconRigth} />
          </Touchable>
        ) : null}
        <Touchable
          tabIndex={-1}
          disabled={this.props.loadingAllProducts}
          onPress={this.handleClickOpenBarCodeScanner}
          style={styles.iconRigthPressable}>
          <Image source={Images.barCode} style={styles.iconRigth} />
        </Touchable>
      </View>
      {this.props.enableOperationGroup && this.props.selectedProducts.length > 0 ? (
        <View>
          <View
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 20, // Adicione uma margem superior
              marginBottom: 20, // Adicione uma margem inferior
              height: this.state.FABOpened ? 100 : 50,
              backgroundColor: this.state.FABOpened ? null : '#fff',
            }}>
            <Actions onPress={this.props.toggleOperationGroupModal} />
          </View>
        </View>
      ) : null}
    </View>
  );

  forceInsertSearchValue = (value = '', clear = true) => {
    if (typeof value !== 'string') {
      return this.resetSearch();
    }

    this.setState({ searchValue: value, isSearchActive: false }, () => {
      this.onEndEditingSearch(value);
      if (this.inputSearchRef && clear) {
        this.inputSearchRef.blur();
        this.inputSearchRef.clear();
      }
    });
  };

  resetSearch = () => {
    this.setState({ searchValue: '', isSearchActive: false }, () => {
      this.onEndEditingSearch('');
    });
  };

  openBarCodeScanner = async () => {
    createNotifyInfo('Não há suporte para câmera no momento...');
    /* const allowed = await this.checkPermissionCamera();
    if (allowed) {
      NavigationHelper.showOverlay(SCREEN_PRODUCT_CODE_SCANNER.name, {
        onDetect: value => this.forceInsertSearchValue(value, false),
      });
    } else {
      Alert.alert(
        `${translate('atention')}`,
        `${translate('messageAccesCameraLocationAndFiles')}`,
        [
          {
            text: `${translate('openSettings')}`,
            style: 'default',
            onPress: () => {
              return AndroidOpenSettings.appDetailsSettings();
            },
          },
        ],
      );
    } */
  };

  handleClickOpenBarCodeScanner = onPressHelper.debounce(
    this.openBarCodeScanner.bind(this),
  );

  appendProductGrouping = product => {
    this.props.handleProductGrouping?.('append', { product });
  };

  onLongPress = product => {
    const { enableOperationGroup } = this.props;

    if (enableOperationGroup) {
      this.appendProductGrouping(product);
      this.props.handleProductGrouping?.('toggle');
    }
  };

  renderItem = ({ item }) => {
    const {
      selectedProducts,
      showProductAsHTML,
      activeProductsGrouping,
    } = this.props;

    const productSelected = selectedProducts.find(
      ({ codigo }) => codigo === item.codigo,
    );
    return (
      <ProductList
        ref={ref => {
          this[TextUtils.slugfy(item.nome)] = ref;
        }}
        editable
        onPressObservations={this.props.onPressObservations}
        onPressConditionsSalesChild={this.props.onPressConditionsSalesChild}
        addItem={this.props.addItem}
        itemUpdate={this.props.itemUpdate}
        item={item}
        cart={this.props.cart}
        config={this.props.config}
        configLib={this.props.configLib}
        itemClick={this.onPressProductClick}
        appendProductGrouping={this.appendProductGrouping}
        isSelectionMode={activeProductsGrouping}
        isSelected={!!productSelected}
        showProductAsHTML={showProductAsHTML}
        isCombo={this.props.isCombo}
        forceProductRefetch
        onLongPress={this.onLongPress}
        loadingAllProducts={this.props.loadingAllProducts}
      />
    );
  };

  getImageBasedOnConfigIcon = (options) => {
    const newOptions = [];
    options.map((option) => {
      if (option.name === 'save_budget') {
        newOptions.push({
          ...option,
          icon: <Image source={Images.iconSave} tintColor={'#FFF'} style={{ width: 18, height: 18 }} />,
        })
      }
      if (option.name === 'share_order') {
        newOptions.push({
          ...option,
          icon: <Image source={Images.iconShare} tintColor={'#FFF'} style={{ width: 18, height: 18 }} />
        })
      }
      if (option.name === 'cancel_order') {
        newOptions.push({
          ...option,
          icon: <Image source={Images.iconTrash} tintColor={'#FFF'} style={{ width: 18, height: 18 }} />
        })
      }
    })
    return newOptions;
  }

  getFabOptions = () => {
    const cartFabOptions = _.get(
      this.props.configLib,
      'configuracoes.fab_options.cart',
      [],
    );

    const allowSharing = _.get(
      this.props.configLib,
      'configuracoes.allow_sharing_order',
      true,
    );

    const getImageIcon = this.getImageBasedOnConfigIcon(cartFabOptions);

    const options = [...getImageIcon].map(op => ({
      ...op,
      icon: op.icon,
    }));


    const hasBudgetOption = options.find(op => op.name === 'save_budget');
    if (!hasBudgetOption) {
      const hasBudgetConfig = _.get(
        this.props.configLib,
        'configuracoes.has_type_orcamento',
      );
      if (hasBudgetConfig) {
        options.push(BUDGET_FAB_OPTION);
      }
    }

    if (allowSharing) {
      options.push(SHARING_OPTION);
    }



    return options;
  };

  render() {
    const {
      data,
      actualSortValue,
      loading,
      configLib,
      salesConditionsChild,
      selectedProducts,
      onClose,
      onConfirmBackPress,
      onSelectProduct,
      onApplyOperationByGroup,
      onApplyRemoveItems,
      onPressTableOrDeadline,
      onSelectProducts,
      loadingCategoriesProducts,
      isRootScreen,
      onPressFloatingAction,
      categories,
    } = this.props;

    const lastAddedProduct = selectedProducts[selectedProducts.length - 1];

    const operationsConfig = _.get(
      configLib,
      'configuracoes.product_group',
      {},
    );

    const isOperationGroupActive = selectedProducts.length > 0;

    return (
      <IntlProvider locale={`${language}`}>
        <View style={styles.container}>
          <ModalOverlay visible={loading} closeOnTouchOutside />
          <ActivityIndicator style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            zIndex: 9999,
          }}
            animating={loading}
            size="large" />
          {Platform.OS === 'android' && this.props.showModalSort
            ? this.getModalSortAndroid()
            : null}
          {!loadingCategoriesProducts ? this.renderSearch() : null}
          <FlatList
            ref={this.refFlatList}
            keyExtractor={this.keyExtractor}
            removeClippedSubviews={false}
            refreshing={false}
            data={data}
            extraData={[actualSortValue, selectedProducts]}
            renderItem={this.renderItem}
            ListHeaderComponent={this.renderListHeader}
            ListFooterComponent={
              isRootScreen ? this.renderListFooter('See_All') : null
            }
            onEndReachedThreshold={0.1}
            onEndReached={() => {
              this.handleLoadMore({ type: 'products' });
            }}
            // estimatedItemSize={250}
            ListEmptyComponent={
              loadingCategoriesProducts ? (
                <OnlyProductsPlaceholder />
              ) : (
                <EmptyView
                  icon={Images.iconBulletedList}
                  message={`${translate('noProductsAndCategories')}`}
                />
              )
            }
            contentContainerStyle={{
              padding: 10,
            }}
          />

          <BottomSheet
            ref={ref => {
              this.OperationByGroupRef = ref;
            }}
            height={450}
            onClose={this.onClose}>
            <OperationByGroup
              salesConditionsChild={salesConditionsChild}
              operationsConfig={
                operationsConfig ? operationsConfig.options : []
              }
              onPressTableOrDeadline={() => {
                this.OperationByGroupRef.close();
                this.ProductListRef.close();
                onPressTableOrDeadline();
              }}
              onConfirm={resp => {
                this.OperationByGroupRef.close();
                this.ProductListRef.close();
                onApplyOperationByGroup(resp);
              }}
              onRemoveItems={() => {
                this.OperationByGroupRef.close();
                onApplyRemoveItems();
              }}
              onCancel={() => {
                this.OperationByGroupRef.close();
              }}
            />
          </BottomSheet>
          <ProductListGroup
            items={selectedProducts}
            setRef={ref => {
              this.ProductListRef = ref;
            }}
            configImageSouce={this.props.config}
            onPressRemove={product => onSelectProduct(product)}
            onPressRemoveAll={() => {
              if (onSelectProducts) {
                onSelectProducts();
              }
              this.ProductListRef.close();
            }}
            onPressConfirm={() => {
              this.ProductListRef.close();
              this.OperationByGroupRef.open();
            }}
          />

          {!isOperationGroupActive ? (
            <FloatingAction
              visible
              showBackground
              floatingIcon={
                <Image source={this.state.FABOpened ? Images.iconClose : Images.iconBulletedList}
                  style={{ width: 24, height: 24, tintColor: '#fff' }} />
              }
              actions={[...this.getFabOptions(), ...DEFAULT_FLOATING_OPTIONS]}
              onPressItem={onPressFloatingAction}
              color={color.primaryColor}
              overlayColor="rgba(0, 0, 0, 0.7)"
              actionsPaddingTopBottom={0}
              onClose={() => {
                this.setState({ FABOpened: false });
              }}
              onOpen={() => {
                this.setState({ FABOpened: true });
              }}
              ref={ref => {
                this.FABRef = ref;
              }}
              distanceToEdge={{ horizontal: 16, vertical: 32 }}
            />
          ) : null}
        </View>
      </IntlProvider>
    );
  }
}

Cart.propTypes = {
  loading: PropTypes.bool,
  isRootScreen: PropTypes.bool,
  loadingCategoriesProducts: PropTypes.bool,
  data: PropTypes.array,
  categoryClick: PropTypes.func,
  productClick: PropTypes.func,
  addItem: PropTypes.func,
  loadingSalesHistory: PropTypes.bool,
  addHistoryProduct: PropTypes.func,
  clickProductInHistoryProduct: PropTypes.func,
  loadingSaleSuggestionProducts: PropTypes.bool,
  addSuggestionProduct: PropTypes.func,
  clickProductInSuggestionProduct: PropTypes.func,
  onClose: PropTypes.func,
  onPressObservations: PropTypes.func,
  onPressConditionsSalesChild: PropTypes.func,
  sort: PropTypes.func,
  cart: PropTypes.any,
  visibleSaleHistorySuggetion: PropTypes.bool,
  visibleSaleSuggestionProducts: PropTypes.bool,
  historyProducts: PropTypes.any,
  suggestionProducts: PropTypes.any,
  initialNumToRender: PropTypes.number,
  config: PropTypes.any,
  configLib: PropTypes.any,
  itemUpdate: PropTypes.any,
  showModalSort: PropTypes.bool,
  actualSortValue: PropTypes.string,
  selectedProducts: PropTypes.array,
  showBackPressAlert: PropTypes.bool,
  salesConditionsChild: PropTypes.object,
  scrollToCategory: PropTypes.bool,
  actualCategory: PropTypes.number,
  fetchMoreProducts: PropTypes.func,
  onSelectProduct: PropTypes.func,
  onSelectProducts: PropTypes.func,
  onConfirmBackPress: PropTypes.func,
  onApplyRemoveItems: PropTypes.func,
  onApplyOperationByGroup: PropTypes.func,
  onPressTableOrDeadline: PropTypes.func,
  goToTopScreen: PropTypes.bool,
  categories: PropTypes.array,
  showProductAsHTML: PropTypes.bool,
  showCarouselType: PropTypes.object,
  isCombo: PropTypes.bool,
  onSearch: PropTypes.func,
  navigation: PropTypes.func,
  execCommand: PropTypes.func,
};

Cart.defaultProps = {
  loading: true,
  loadingCategoriesProducts: true,
  loadingSalesHistory: true,
  loadingSaleSuggestionProducts: true,
};

export default Cart;
