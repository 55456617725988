
const SyncApiModule = (nameModule) => {
  const methodByAlias = {};

  const downloadTables = async () => {
  };

  const checkIntegrityDB = async (resolve, reject) => {
  };

  const isFinishDownloadTables = async (datalogLastSerial, tables, tablesWithError, resolve) => {
  };

  const containsSyncTabelas = async (tables) => {
  };

  const containsSyncDatalog = async (tables) => {
  };

  const processTables = async (tables, resolve, reject) => {
  };

  const whenGetDataSyncControlSuccess = async (resolve, reject) => {
  };

  const finalizeUpdatesData = async (lastSerial, resolve, reject) => {
  };

  return {
    getName: () => nameModule,
    methodByAlias,
    downloadTables,
    checkIntegrityDB,
    isFinishDownloadTables,
    containsSyncTabelas,
    containsSyncDatalog,
    processTables,
    whenGetDataSyncControlSuccess,
    finalizeUpdatesData,
  };
};

export default SyncApiModule;
