import React from 'react';
import { View } from 'react-native';
import Spinner from 'react-native-loading-spinner-overlay';

import SelectPicker from '~/components/SelectPicker';

import translate from '../../locales';
import styles from './styles';

import PropTypes from 'prop-types';

function ConditionsSalesChild({
  loading,
  tablesChild,
  tableChildSelect,
  prazsChild,
  prazChildSelect,
  onChangeTableChild,
  onChangePrazChild,
}) {
  return (
    <View style={styles.container}>
      <Spinner
        visible={loading}
        overlayColor="rgba(0, 0, 0, 0.7)"
        textStyle={{ color: '#FFF' }}
      />
      <SelectPicker
        selected={tableChildSelect}
        style={styles.selectPicker}
        title={`${translate('priceTable')}`}
        items={tablesChild}
        onChangeValue={onChangeTableChild}
      />
      <SelectPicker
        selected={prazChildSelect}
        style={styles.selectPicker}
        title={`${translate('deadline')}`}
        items={prazsChild}
        onChangeValue={onChangePrazChild}
      />
    </View>
  );
}

ConditionsSalesChild.propTypes = {
    loading: PropTypes.bool.isRequired,
    tablesChild: PropTypes.array.isRequired,
    tableChildSelect: PropTypes.number.isRequired,
    prazsChild: PropTypes.array.isRequired,
    prazChildSelect: PropTypes.number.isRequired,
    onChangeTableChild: PropTypes.func.isRequired,
    onChangePrazChild: PropTypes.func.isRequired,
};

export default ConditionsSalesChild;
