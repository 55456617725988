import Repository from './Repository';

export default class UserTaskListRepository extends Repository {
  constructor() {
    super();
    this.tableName = 'user_task_lists';
  }

  deleteAll(callback) {
    this.db.transaction(tx => {
      const sql = `DELETE FROM ${this.tableName};`;
      tx.executeSql(sql, [], callback);
    });
  }

  delete(userTaskList, callback) {
    this.db.transaction(tx => {
      const sql = `DELETE FROM ${this.tableName} WHERE id = ${
        userTaskList.id
      };`;
      tx.executeSql(sql, [], callback);
    });
  }

  get(callback) {
    this.db.transaction(tx => {
      const select = `SELECT ${this.getDefaultProjection(
        this.tableName,
        false,
      )} FROM ${this.tableName} LIMIT 1;`;
      tx.executeSql(
        select,
        [],
        (tx, results) => {
          const userTaskList = results.rows.item(0);
          callback(userTaskList);
        },
        this.errorDb,
      );
    });
  }

  async getByTaskListAndEventTask(taskListId, pkey, source, date) {
    return {};
  }

  updateUserTaskListStatusTempIdToReservedId(tempId, reservedId) {
    return new Promise((resolve, reject) => {
      this.db.transaction(tx => {
        const select = `UPDATE ${
          this.tableName
        } SET id = '${reservedId}', sync_status = 'pending_to_sync' WHERE id = '${tempId}';`;
        tx.executeSql(
          select,
          [],
          () => {
            resolve();
          },
          error => {
            reject(error);
          },
        );
      });
    });
  }

  successDb() {}

  errorDb(err) {
    console.tron.log('err db', err);
  }
}
