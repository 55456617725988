import get from 'lodash/get';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Snackbar from 'react-native-snackbar';
import UploadAtividades from '~/services/resources/UploadAtividades';
import CacheAccount from '~/controllers/Accounts/Cache/CacheAccount';
import translate from '~/locales';
import { SYNC_FINALLY } from '~/constants/Sync';
import { createNotifyError, createNotifySuccess } from '~/components/Web/ToastNotify';

export const PHOTOS_SYNC_PENDING = 'PHOTOS_SYNC_PENDING';
export const PHOTOS_SYNC_IDLE = 'PHOTOS_SYNC_IDLE';

const sync = async ({ getState, dispatch }) => {
  const state = getState();
  const cacheAccount = new CacheAccount();
  const dataAccount = await cacheAccount.getDataAccount();
  const dataUserDevice = await cacheAccount.getUserDevice();
  const syncStatus = get(state.syncFetch, 'payload.statusSync');
  if (syncStatus === SYNC_FINALLY) {
    await new UploadAtividades(
      dataAccount,
      dataUserDevice,
      () => {
        setTimeout(() => {
          createNotifySuccess(translate('photosSuccessfullySynced'));
        }, 500);
        dispatch(setPhotosToSync([]));
      },
      message => {
        setTimeout(() => {
          createNotifyError(translate('errorSyncPhotos'));
        }, 500);
        dispatch(requestSync());
      },
    ).upload();
  }
};

export const syncPhotos = createAsyncThunk(
  'photosSync/syncPhotos',
  async (_, { getState, dispatch }) => {
    return await sync({
      getState: getState,
      dispatch,
    });
  },
);

const initialState = {
  type: '',
  loading: true,
  erro: false,
  payload: {
    photosToSync: [],
  },
};

const photosSync = createSlice({
  name: 'photosSync',
  initialState,
  reducers: {
    setPhotosToSync: (state, action) => {
      state.payload.photosToSync = action.payload;
      state.type = PHOTOS_SYNC_IDLE;
    },
    requestSync: state => {
      state.type = PHOTOS_SYNC_PENDING;
    },
  },
  extraReducers: builder => {
    builder.addCase(syncPhotos.pending, state => {
      state.loading = true;
    });
    builder.addCase(syncPhotos.fulfilled, state => {
      state.loading = false;
      state.erro = false;
    });
    builder.addCase(syncPhotos.rejected, state => {
      state.loading = false;
      state.erro = true;
    });
  },
});

const { reducer } = photosSync;
export const { setPhotosToSync, requestSync } = photosSync.actions;

export default reducer;