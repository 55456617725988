import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  screen: '',
  screenName: '',
};

const currentScreenVisibleSlice = createSlice({
  name: 'currentScreenVisible',
  initialState,
  reducers: {
    currentScreenVisible: (state, action) => {
      if (action.payload.screen) {
        state.screen = action.payload.screen;
        state.screenName = action.payload.screenName;
      } else {
        state.screen = action.payload;
      }
    },
  },
});

export const { currentScreenVisible } = currentScreenVisibleSlice.actions;

const { reducer } = currentScreenVisibleSlice;

export default reducer;
