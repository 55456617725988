import React, { useState, useEffect } from 'react';
import { StyleSheet, Text } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import PropTypes from 'prop-types';

const GENERIC_BUTTON = {
  padding: 8,
  marginRight: 12,
  paddingHorizontal: 12,
  borderWidth: 1,
  borderRadius: 4,
};

const styles = StyleSheet.create({
  textButton: {
    color: '#000',
    fontWeight: '400',
  },
  inactiveButton: {
    backgroundColor: '#E6E6E6',
    borderColor: '#BCBCBC',
    ...GENERIC_BUTTON,
  },
  activeButton: {
    backgroundColor: '#D1E9FF',
    borderColor: '#76BDFE',
    ...GENERIC_BUTTON,
  },
});

function ToggleButton({ onPress, activeStyle, text, active, forceInactivate }) {
  const [isActive, setIsActive] = useState(active);

  const onPressIn = () => {
    setIsActive(!isActive);
    if (onPress) {
      onPress(!isActive);
    }
  };

  useEffect(
    () => {
      if (forceInactivate) {
        setIsActive(false);
      }
    },
    [forceInactivate],
  );

  return (
    <Touchable
      onPress={onPressIn}
      style={
        isActive ? activeStyle || styles.activeButton : styles.inactiveButton
      }>
      <Text style={styles.textButton}>{text}</Text>
    </Touchable>
  );
}

ToggleButton.propTypes = {
  onPress: PropTypes.func,
  activeStyle: PropTypes.objectOf(PropTypes.any),
  text: PropTypes.string,
  active: PropTypes.bool,
  forceInactivate: PropTypes.bool,
};

export default ToggleButton;
