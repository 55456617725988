import React, { useEffect } from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import ImageComponent from '../../../components_base/src/components/Image/ImageComponent';
import moment from 'moment';
import { Money } from '~/common';
import PropTypes from 'prop-types';
import translate from '~/locales';
import Images from '@assets/Images';

function LabelValue({ label, value }) {
  return (
    <View style={{ marginBottom: 20 }}>
      <Text style={{ fontSize: 12 }}>{label}</Text>
      <Text style={{ fontSize: 16, color: '#000' }}>{value}</Text>
    </View>
  );
}

LabelValue.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

function ExpensesDetail({ navigation, route }) {
    const expense = route?.params?.expense;
    useEffect(() => {
        navigation.setOptions({
            headerLeft: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image source={Images.iconBack} style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }} />
                </TouchableOpacity>
            ),
        });
    }, []);
  return (
    <View
      style={{
        paddingHorizontal: 16,
        paddingVertical: 12,
      }}>
      <LabelValue label={`${translate('place')}`} value={expense?.title} />

      <LabelValue
        label={`${translate('paymentDate')}`}
        value={moment(
          expense?.type === 'in' ? expense?.date : expense?.invoice_date,
        ).format('DD/MM/YYYY')}
      />

      <LabelValue
        label={`${translate('fieldType')}`}
        value={expense?.description}
      />

      <LabelValue
        label={`${translate('fieldAmount')}`}
        value={Money(expense?.value)}
      />

      {expense?.debt_amount ? (
        <LabelValue
          label={`${translate('adjustValField')}`}
          value={Money(expense?.debt_amount)}
        />
      ) : null}

      {expense?.debt_reason ? (
        <LabelValue
          label={`${translate('reasonField')}`}
          value={expense?.debt_reason}
        />
      ) : null}

      {expense?.observation ? (
        <LabelValue
          label={`${translate('observations')}`}
          value={expense?.observation}
        />
      ) : null}

      <View>
        <Text style={{ fontSize: 12, marginBottom: 4 }}>{`${translate(
          'attachments',
        )}`}</Text>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
          }}>
          {expense?.attachments?.map((att, key) => (
            <ImageComponent
              key={att?.url}
              style={{
                width: 94,
                height: 94,
                borderRadius: 4,
                marginRight: 8,
              }}
              source={{ uri: att }}
              useCache={false}
              resizeMode="stretch"
            />
          ))}
        </View>
      </View>
    </View>
  );
}

ExpensesDetail.propTypes = {
  expense: PropTypes.objectOf(PropTypes.any),
};

export default ExpensesDetail;
