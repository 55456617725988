import React from 'react';
import { View, FlatList } from 'react-native';
import { Placeholder, PlaceholderLine, Fade } from 'rn-placeholder';

import colors from '../../../common/color';

const backgroundGray = {
  backgroundColor: colors.gray,
};

const app = {
  ...backgroundGray,
  paddingHorizontal: 12,
  paddingVertical: 12,
  borderBottomColor: colors.primaryColorTable,
  borderBottomWidth: 2,
  flexDirection: 'row',
  alignItems: 'center',
};

const divide = {
  marginLeft: 7,
  flex: 1,
};

const lineId = {
  backgroundColor: colors.primaryColorTable,
  width: 40,
};

const lineName = {
  backgroundColor: colors.primaryColorTable,
  width: '70%',
  height: 30,
};

const lineSubName = {
  backgroundColor: colors.primaryColorTable,
  width: '20%',
  height: 30,
};

const lineSituation = {
  backgroundColor: colors.primaryColorTable,
  width: '30%',
  height: 10,
};

const iconPoint = {
  backgroundColor: colors.primaryColorTable,
  width: 5,
  height: 20,
  right: 3,
  marginBottom: 60,
};

export default function OrderModulesPlaceholder() {
  return (
    <Placeholder Animation={Fade}>
      <FlatList
        data={[1, 2, 3, 3, 4, 5, 6, 7, 8, 9, 10]}
        keyExtractor={() => Math.random()}
        renderItem={() => (
          <View style={app}>
            <View style={divide}>
              <PlaceholderLine style={lineId} />
              <PlaceholderLine style={lineName} />
              <PlaceholderLine style={lineSubName} />
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <PlaceholderLine style={lineSituation} />
                <PlaceholderLine style={[lineSituation, { top: 10 }]} />
              </View>
            </View>
            <PlaceholderLine style={iconPoint} />
          </View>
        )}
      />
    </Placeholder>
  );
}
