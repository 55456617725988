import GeneralModule from '../../../modules/GeneralModule';
import { emp2long } from '../../../utils/FastSellerUtils';

export default async function listCombos(offset) {
  const groupId = emp2long('PPRI');
  const id = emp2long('PPRO');
  const params = { pk_orgvenda: 0 };
  const pagination = { page: 0, offset, active: true };

  let active = true;
  let data = '';
  try {
    while (active) {
      const productsCatalogPromise = new Promise((resolve, reject) =>
        GeneralModule.getCustomList(
          `${groupId}`,
          `${id}`,
          JSON.stringify(params),
          true,
          JSON.stringify(pagination),
          resolve,
          reject,
        ));
      const resp = await productsCatalogPromise;
      data = `${data}${resp}`;

      if (!(resp.length > 0)) {
        active = false;
      }

      pagination.page += 1;
    }

    return data;
  } catch (errors) {
    throw errors;
  }
}
