/* @flow */

import { Navigation } from 'react-native-navigation';
import { SCREEN_HOME } from './screens';
import _ from 'lodash';

export default class NavigationHelper {
  static push(
    fromId,
    toId,
    title = '',
    props = null,
    options = {},
    fromSideMenu = false,
  ) {
    Navigation.push(fromId, {
      component: {
        name: toId,
        passProps: props,
        options: {
          bottomTabs: {
            visible: false,
            drawBehind: true,
          },
          topBar: {
            ...(title
              ? {
                  title: {
                    text: title,
                  },
                  subtitle: {
                    text: _.get(options, 'subtitle', ''),
                  },
                }
              : {}),
            backButton: {
              title: '',
              showTitle: false,
            },
            elevation: 0,
          },
          ...options,
        },
      },
    });

    if (fromSideMenu) {
      this.hideSideMenu();
    }
  }
  static showOverlay(name, props = {}) {
    Navigation.showModal({
      component: {
        name,
        passProps: props,

        options: {
          modalPresentationStyle: 'overCurrentContext',
          layout: {
            backgroundColor: 'transparent',
            componentBackgroundColor: 'transparent',
          },
          overlay: {
            interceptTouchOutside: false,
          },
        },
      },
    });
  }

  static showModal(name, title, props, hasTopBar = true) {
    Navigation.showModal({
      stack: {
        children: [
          {
            component: {
              name,
              passProps: {
                ...props,
              },
              options: {
                topBar: {
                  visible: hasTopBar,
                  title: {
                    text: title,
                  },
                },
              },
            },
          },
        ],
      },
    });
  }

  static setMergeOptions(componentId, options = {}) {
    Navigation.mergeOptions(componentId, {
      topBar: {
        ...options,
      },
    });
  }

  static setRightButtons(componentId, buttons = [], options = {}) {
    Navigation.mergeOptions(componentId, {
      topBar: {
        rightButtons: [...buttons],
        ...options,
      },
    });
  }

  static pop(componentId) {
    return Navigation.pop(componentId);
  }

  static popTo(componentIdTarget) {
    return Navigation.popTo(componentIdTarget);
  }

  static popToRoot(componentIdTarget) {
    return Navigation.popToRoot(componentIdTarget);
  }

  static hideSideMenu() {
    Navigation.mergeOptions(SCREEN_HOME.name, {
      sideMenu: {
        left: {
          visible: false,
        },
      },
    });
  }

  static popRootTab(indexTab, screenTab) {
    return Navigation.events().registerBottomTabSelectedListener(
      ({ selectedTabIndex }) => {
        if (selectedTabIndex === indexTab) {
          Navigation.popToRoot(screenTab);
        }
      },
    );
  }

  static switchTab(componentId, currentTabIndex) {
    Navigation.mergeOptions(componentId, {
      bottomTabs: { currentTabIndex },
    });
  }
}
