import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ProductsModule from '~/modules/ProductsModule';

import {
  MAGNITUDES_MANY_PRODUCT,
  MAGNITUDES_ONLY_PRODUCT,
} from '../../constants/rulesProductMagnitudes';

import getImageProds from '../../utils/GetImageProduct';

export const PRODUCT_FETCH_REQUEST = 'PRODUCT_FETCH_REQUEST';
export const PRODUCT_FETCH_SUCCESS = 'PRODUCT_FETCH_SUCCESS';
export const PRODUCT_FETCH_FAILURE = 'PRODUCT_FETCH_FAILURE';
export const PRODUCT_PUSH_CODS = 'PRODUCT_PUSH_CODS';
export const PRODUCT_POP_CODS = 'PRODUCT_POP_CODS';

export const updateTypeMagnitudes = async (products, magnitudes) => {
  products.forEach(product => {
    const magnitudeFound = magnitudes.find(
        magnitude => magnitude.codproduto === product.codigo,
    );
    product.type_magnitudes = magnitudeFound
        ? MAGNITUDES_MANY_PRODUCT
        : MAGNITUDES_ONLY_PRODUCT;
  });

  return products;
};

const getListProductsByGroup = (payload, offset) =>
    new Promise(async (resolve, reject) => {
      const { codcliente, nivel, codGroup, codOrder, condvenda } = payload;

      const pagination = { page: 0, offset, active: true };

    let active = true;
    let data = '';
    try {
      while (active) {
        const resp = await ProductsModule?.getListProductsByGroup(
          codcliente,
          nivel,
          codGroup,
          codOrder,
          JSON.stringify(condvenda),
          JSON.stringify(pagination),
        );
        data = `${data}${resp}`;

          if (!(resp.length > 0)) {
            active = false;
          }

          pagination.page += 1;
        }

        resolve(data);
      } catch (error) {
        reject(error.message);
      }
    });

export const getProducts = createAsyncThunk(
    'productsFetch/getProducts',
    async (payload, { getState }) => {
      const state = getState();
      const offset = state.configLibFetch.payload.tam_memoria_paginacao;
      const data = await getListProductsByGroup(payload, offset);
      const parseData = JSON.parse(data);
      const prodWithMagnitudes = await updateTypeMagnitudes(
          parseData,
          state.productsMagnitudes.payload,
      );

      const prodsWithImage = await getImageProds(prodWithMagnitudes);

      return {
        nivel: payload.nivel,
        codGroup: payload.codGroup,
        products: prodsWithImage,
        sort: payload.sort,
      };
    },
);

const initialState = {
  type: '',
  loading: false,
  erro: false,
  payload: [],
  codGroupList: [],
  currentPromise: null,
  preLoading: false,
};

const productsFetchSlice = createSlice({
  name: 'productsFetch',
  initialState,
  reducers: {
    pushCod: (state, action) => {
      state.type = PRODUCT_PUSH_CODS;
      state.codGroupList.push(action.payload);
    },
    popCod: state => {
      state.type = PRODUCT_POP_CODS;
      state.codGroupList.pop();
    },
    abort: state => {
      state.currentPromise?.abort();
      state.currentPromise = null;
      state.preLoading = false;
    },
    preLoading: state => {
      state.preLoading = true;
    },
    wrapPromise: (state, action) => {
      state.currentPromise = action.payload;
      state.preLoading = false;
    },
  },
  extraReducers: builder => {
    builder
        .addCase(getProducts.pending, state => {
          state.loading = true;
          state.type = PRODUCT_FETCH_REQUEST;
        })
        .addCase(getProducts.fulfilled, (state, action) => {
          const { payload } = action;
          let allProducts = state.payload;

          let cods = state.codGroupList;

          if (payload.nivel !== '0' && payload.codGroup !== '0') {
            cods.push(payload.codGroup);

            cods.reduce((arr, code) => {
              const productSelect = arr.find(o => o.codigo === code);
              if (!productSelect) {
                return [];
              }

              if (productSelect?.filhos && productSelect?.filhos?.length === 0) {
                productSelect.filhos = payload.products.filter(
                    p => p.tipo === 'categoria',
                );
                const products = payload.products.filter(
                    p => p.tipo !== 'categoria',
                );
                productSelect.filhos = productSelect.filhos.concat(products);
              }

            return productSelect.filhos;
          }, allProducts);
        } else {
          allProducts = payload.products.filter(p => p.tipo === 'categoria');
          const products = payload.products.filter(p => p.tipo !== 'categoria');
          allProducts = allProducts.concat(products);
          cods = [];
        }
        state.loading = false;
        state.payload = allProducts;
        state.codGroupList = cods;
        state.type = PRODUCT_FETCH_SUCCESS;
        state.preLoading = false;
      })
      .addCase(getProducts.rejected, state => {
        state.loading = false;
        state.erro = true;
        state.preLoading = false;
        state.type = PRODUCT_FETCH_FAILURE;
      });
  },
});

const { reducer } = productsFetchSlice;

export const {
  pushCod,
  popCod,
  abort,
  preLoading,
  wrapPromise,
} = productsFetchSlice.actions;
export default reducer;