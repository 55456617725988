/* @flow */

import React, { Component } from 'react';
import _, { create } from 'lodash';
import { Image, TouchableOpacity, View } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import Images from '@assets/Images';
import { connect } from 'react-redux';

import Resume from './Resume';

import { updateShipping } from '~/store/ordersFetch/updateShippingSlice';
import { applyDiscountTalao } from '~/store/ordersFetch/applyDiscountTalaoSlice';
import {
  UPDATE_ITEM_REQUEST,
  updateItemFetch,
} from '~/store/ordersFetch/updateItemSlice';
import { UPDATE_SHIPPING_ORDER_REQUEST } from '~/store/ordersFetch/updateShippingSlice';
import { CANCEL_CART_FETCH_REQUEST } from '~/store/ordersFetch/orderCancelSlice';
import {
  REMOVE_MULTIPLE_ITEMS_FETCH,
  removeMultipleItems,
} from '~/store/ordersFetch/removeMultipleItemsSlice';
import {
  APPLY_OPERATION_BY_GROUP_FETCH,
  applyOperationByGroup,
} from '~/store/ordersFetch/applyOperationByGroupSlice';
import {
  getCartFetch,
  ORDER_FETCH_REQUEST,
  ORDER_FETCH_SUCCESS,
  orderClient,
  forceUpdatelistComb,
  updateOrderScreen,
} from '../../store/ordersFetch/orderFetchSlice';

import {
  finishCart,
  FINISH_CART_FETCH_REQUEST,
} from '../../store/ordersFetch/finishCartSlice';
import TextUtils from '../../utils/TextUtils';
import IncentiveModule from '../../modules/IncentiveModule';
import OrdersModule from '../../modules/OrdersModule';
import { abort } from '../../store/productsFetch/slice';
import {
  SCREEN_OBSERVATIONS,
  SCREEN_ORDER_OBSERVATIONS,
  SCREEN_INCENTIVE,
  SCREEN_RESUME_ORDER,
  SCREEN_SALES_CONDITIONS_CHILD,
  SCREEN_PRICE_TERM_CONDITIONS,
  SCREEN_SHARE_ORDER,
} from '../screens';
import { MODULE_ACTIVITIES } from '../../store/makeOrderModule/action';
import GpsHelper from '../../utils/GpsHelper';
import executeActionOrOpenScreenMessage, {
  executeActionsMessage,
} from '../../utils/messages';
import translate from '../../locales';
import {
  CLIENT_CURRENT_FETCH_REQUEST,
  CLIENT_CURRENT_FETCH_SUCCESS,
  setCurrentClient,
} from '../../store/clientCurrent/slice';
import { setCurrentProduct } from '../../store/productCurrent/slice';
import { emp2long } from '../../utils/FastSellerUtils';
import CacheDataController from '../../utils/CacheDataController';
import CachePolistore from '../../controllers/Accounts/Cache/CachePolistore';
import { clearCurrentTask } from '~/store/taskCurrent/slice';
import getQueryResult from '~/services/resources/libFastSeller/genericQuery';
import { getSourceImageProduct } from '~/utils/ProductUtil';
import {
  MAGNITUDES_MANY_PRODUCT,
  MAGNITUDES_ONLY_PRODUCT,
} from '~/constants/rulesProductMagnitudes';
import { getDecimalPrecisionConfig } from '~/utils/Currency';
import { Navigation } from 'react-native-navigation';
import { cancelCartActiveFetch } from '~/store/ordersFetch/orderCancelSlice';
import {
  updateStatusOpenOrder,
  cleanFallbackPayload,
} from '../../store/ordersFetch/orderFetchSlice';
import {
  EXISTS_INCENTIVE,
  FAMILY_SCALE_VALIDATION,
  INVALID_SALES_CONDITION,
  PRODUCTS_VERIFICATION
} from '~/utils/MessageCode';
import { setSaleOpportunity } from '../../store/salesOpportunityFetch/slice';
import SaleOpportunityCache from '~/cache/SaleOpportunityCache';
import NavigationHelper from '../NavigationHelper';
import { handlePermissions } from '~/store/permission/slice';
import { saveAnswer } from '~/store/saveAnswer/slice';
import onPressHelper from '~/utils/onPressHelper';
import {
  SAVE_CART_FETCH_REQUEST,
  saveCart,
} from '~/store/ordersFetch/saveCartSlice';
import CacheOrderActivity from '~/utils/CacheOrderActivity';
import NetInfo from '@react-native-community/netinfo';
import { SHIPPING_TYPES } from '~/components/SalesCondition';

import PropTypes from 'prop-types';
import { showAlert } from '~/components/Web/Alert';
import {createNotifyError, createNotifyInfo, createNotifySuccess} from '~/components/Web/ToastNotify';
import getImageProds from '~/utils/GetImageProduct';
import {tasksSync} from "~/TaskSync.web";
import GeneralModule from '~/modules/GeneralModule';
import CacheAccount from '~/controllers/Accounts/Cache/CacheAccount';

import { handleKeyDown, setActionsMap } from '~/utils/KeyBindingsUtils';

class ResumeContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orgSaleSelected: null,
      isModalVisible: false,
      showSearch: false,
      showPrazoByTablePrice: false,
      searchText: '',
      editionMode: false,
      discountsView: [],
      condSalesCurrent: null,

      location: {
        accuracy: Infinity,
        altitude: null,
        heading: null,
        latitude: 0,
        longitude: 0,
        speed: 0.0,
        timestamp: 0,
      },
      selectedProducts: [],
      salesConditionsChild: { table: null, deadline: null },
      productsByList: [],
      pressedBackInEditionMode: false,
      activeProductsGrouping: false,
      actualCart: null,
      actualOrder: null,
    };
    this.props.navigation.addListener('focus', () => {
      setActionsMap({
        Pesquisar: () => {
          this.setState({ showSearch: !this.state.showSearch, searchText: '' });
        },
        Produtos: () => {
          this.props.navigation.replace('Cart', {
            byResume: true,
          });
        },
        Finalizar: () => this.onPressFinishOrder(),
        Obs: () => this.onPressObservations(),
      });

      window.addEventListener('keydown', handleKeyDown);
      const { activeProductsGrouping, selectedProducts } = this.state;
      if (
        activeProductsGrouping &&
        selectedProducts.length &&
        !this.isVisibleOperationGroupActions
      ) {
        // this.showOperationGroupActions();
      }
      if (this.salesConditionsRef.current !== null) {
        this.salesConditionsRef.current.listConditionsSales(
          this.props.client.current,
        );
      }
      this.isVisible = true;
      this.handlePrice(this.props.order.payload.total);
      this.verifyObservations();
      this.setButtons();
    });

    this.props.navigation.addListener('blur', () => {
      window.removeEventListener('keydown', handleKeyDown);
      this.isVisible = false;
    });

    this.clientChange = {};
    this.canListConditionSales = false;
    this.addigItem = false;
    this.salesOpportunitySelected = props?.salesOpportunity?.selected;
    this.changeConditionSales = false;
    this.isVisible = false;
    this.isVisibleOperationGroupActions = false;

    this.salesConditionsRef = React.createRef();
    this.screenMounted = false;
    this.watchId = null;
  }

  UNSAFE_componentWillMount() {
    this.screenMounted = true;
    const { permission, dispatch } = this.props;
    const permissions = _.get(permission, 'payload.response', {});
    const { activeProductsGrouping, selectedProducts } = this.state;

    if (
      activeProductsGrouping &&
      selectedProducts.length &&
      !this.isVisibleOperationGroupActions
    ) {
      this.showOperationGroupActions();
    }
    if (permissions.location !== 'authorized') {
      const { requiredDemand } = this.getGPSConfigs();
      if (requiredDemand) {
        dispatch(handlePermissions(['location']));
      }
    }
    Promise.all([this.getDiscountTalao(), this.getListByContext()]).catch(
      err => {
        console.log('ERROR Promise all Resume:', err);
      },
    );
  }

  componentDidMount() {
    window.addEventListener('keydown', handleKeyDown);
  }

  getGpsLocation = () => {
    this.watchId = null;
  };  
  

  stopGpsLocation = () => {
    if (this.watchId !== null) {
      this.watchId = null;
    }
  };

  closeModal = () => {
    this.setState({ isModalVisible: false });
  };

  verifyPriceChange(newPrice, lastPrice) {
    if (this.salesConditionsRef.current === null) {
      return;
    }
    const lastSelectedShipping = _.get(
      this.salesConditionsRef.current,
      'state.selectedShipping',
    );

    const shipping = _.get(this.props.order.payload, 'frete.tipo', '0');
    const actualShippingType = _.get(lastSelectedShipping, 'type', '');

    if (shipping !== '0' && actualShippingType === SHIPPING_TYPES.API) {
      if (newPrice !== lastPrice) {
        if(confirm(translate('priceOrderChangeMSG'))){
          this.salesConditionsRef.current.listShippingCompaniesByAPI(
            lastSelectedShipping,
          );
        }
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    (() => {
      if (nextProps.order.type === this.props.order.type) {
        return;
      }
      if (this.props.order.type !== UPDATE_SHIPPING_ORDER_REQUEST) {
        return;
      }
      if (nextProps.order.type !== ORDER_FETCH_SUCCESS) {
        return;
      }
      this.getListByContext();
      this.setState({ actualCart: nextProps.order });
    })();

    (() => {
      if (nextProps.order.type === this.props.order.type) {
        return;
      }
      if (nextProps.order.type !== ORDER_FETCH_SUCCESS) {
        return;
      }
      if (this.props.order.type === CANCEL_CART_FETCH_REQUEST) {
        return;
      }
      if (nextProps.order.type === CANCEL_CART_FETCH_REQUEST) {
        return;
      }
      if (this.props.order.type === FINISH_CART_FETCH_REQUEST) {
        return;
      }
      if (nextProps.order.type === FINISH_CART_FETCH_REQUEST) {
        return;
      }
      this.verifyPriceChange(
        nextProps.order.payload.total,
        this.props.order.payload.total,
      );
    })();
    (() => {
      if (nextProps.order.type === this.props.order.type) {
        return;
      }
      if (
        this.props.order.type !== APPLY_OPERATION_BY_GROUP_FETCH &&
        this.props.order.type !== REMOVE_MULTIPLE_ITEMS_FETCH
      ) {
        return;
      }
      if (nextProps.order.type === ORDER_FETCH_SUCCESS) {
        this.setState({
          selectedProducts: [],
          editionMode: false,
        });
        this.onCloseModal();
        if (this.props.order.type === REMOVE_MULTIPLE_ITEMS_FETCH) {
          this.getListByContext();
        }
      }
    })();

    (() => {
      if (nextProps.order.type === this.props.order.type) {
        return;
      }
      if (nextProps.order.type === FINISH_CART_FETCH_REQUEST) {
        return;
      }
      if (this.props.order.type !== FINISH_CART_FETCH_REQUEST) {
        return;
      }
      if (nextProps.order.type === ORDER_FETCH_SUCCESS) {
        const messages = _.get(nextProps.order, 'payload.mensagens', []);

        const codigo = _.get(nextProps.order, 'payload.codigo');
        const task = _.get(this.props, 'taskCurrent.current');

        if (nextProps.order.payload.produtos.length > 0) {
          this.setState({ actualCart: nextProps.order }, () => {
            this.updateCartData(nextProps.order);
          });
        }

        const firstValidMessage = messages.find(msg =>
          [FAMILY_SCALE_VALIDATION, EXISTS_INCENTIVE, PRODUCTS_VERIFICATION].includes(msg.codigo),
        )?.codigo;

        const actionMessageNotErrorMapper = {
          [FAMILY_SCALE_VALIDATION]: this.updateCartAfterMessagesScreen,
          [PRODUCTS_VERIFICATION]: async () => {
            try {
              const messages = nextProps?.order;
              const responseMessages = messages?.payload?.mensagens ?? [];
              const responseMessage = responseMessages.some(msg =>
                msg.tipo === 'erro',
              )
              if (responseMessage) {
                await this.updateCartData()
              } else {
                const task = this.props.taskCurrent?.current || {};
                const taskNotEmpty = Object.keys(task).length > 0;
                if (taskNotEmpty) {
                  this.actionFinishOrderNotError(codigo);
                } else {
                  tasksSync();

                  this.handleBackScreen();
  
                  setTimeout(() => {
                    // Timeout for work after the all screens are closed
                    createNotifySuccess(`${translate('order_successful')}`);
                  }, 200);
                }
              }
            } catch (err) {
              // ignore
            }
          }
        };

        const actionMessageErrorMapper = {
          [EXISTS_INCENTIVE]: this.openIncentivesWithAutoFinish.bind(this),
          [PRODUCTS_VERIFICATION]: async (option) => {
            if (option === 'no') await actionMessageNotErrorMapper[PRODUCTS_VERIFICATION]()
          },
        };

        const actionMessagesNotError =
          firstValidMessage in actionMessageNotErrorMapper
            ? actionMessageNotErrorMapper[firstValidMessage]
            : this.actionFinishOrderNotError;   

        const actionMessagesError =
          firstValidMessage in actionMessageErrorMapper
            ? actionMessageErrorMapper[firstValidMessage]
            : () => { };   

        executeActionOrOpenScreenMessage(
          messages,
          actionMessagesError,
          () => {
            actionMessagesNotError(codigo, task)
          },
        );
      }
    })();

    (async () => {
      if (nextProps.order.type === this.props.order.type) {
        return;
      }
      if (this.props.order.type !== SAVE_CART_FETCH_REQUEST) {
        return;
      }
      if (nextProps.order.type === ORDER_FETCH_SUCCESS) {
        const messages = _.get(nextProps.order, 'payload.mensagens', []);
        executeActionOrOpenScreenMessage(
          messages,
          () => {
            if (this.callbackSaveCartError) {
              this.callbackSaveCartError?.();
            }
          },
          () => {
            if(this.callbackSaveCartSuccess) {
              this.callbackSaveCartSuccess?.();
            } else {
              createNotifySuccess('Carrinho Salvo com Sucesso!');
              const task = this.props.taskCurrent?.current || {};
              const taskNotEmpty = Object.keys(task).length > 0;
              if (taskNotEmpty) {
                this.props.navigation.replace('Activities', {
                  event: {},
                });
              } else {
                this.props.navigation.popToTop();
              }
            }
          }
        );
      }
    })();

    (() => {
      if (nextProps.order.type === this.props.order.type) {
        return;
      }
      if (this.props.order.type !== UPDATE_ITEM_REQUEST) {
        return;
      }
      if (nextProps.order.type !== ORDER_FETCH_SUCCESS) {
        return;
      }

      const currentScreen = nextProps.currentScreenVisible.screen;
      const nokProductClicked = !this.productClicked;
      const isVisible = this.props.navigation.isFocused();
      /* if (currentScreen !== this.props.componentId && nokProductClicked) {
        return;
      } */

      const { payload } = nextProps.order;
      const messages = _.get(payload, 'mensagens', []);
      if (messages.length) {
        if (nextProps.order.screen !== SCREEN_RESUME_ORDER.name) {
          return;
        }
        const filteredMsgs = messages.some(
          m => m.codigo === 'sugestao_quantidade',
        )
          ? messages.map(m => ({
            ...m,
            onPressConfirm: this.applySuggestionQuantity,
            onPressCancel: this.applySuggestionQuantity,
          }))
          : messages;
        executeActionOrOpenScreenMessage(filteredMsgs, null, () => {
          if (messages.some(m => m.codigo === 'sugestao_quantidade')) {
            this.applySuggestionQuantity();
          }
        });
      } else {
        setTimeout(() => this.getListByContext(), 100);
      }
    })();

    (async () => {
      if (nextProps.order.type === this.props.order.type) {
        return;
      }
      if (nextProps.order.type === ORDER_FETCH_REQUEST) {
        return;
      }
      if (nextProps.order.type === CANCEL_CART_FETCH_REQUEST) {
        return;
      }
      if(nextProps.order.type === ORDER_FETCH_SUCCESS && this.props.order.type === CANCEL_CART_FETCH_REQUEST){
        if (nextProps.order.payload.produtos === undefined) {
          const task = this.props.taskCurrent?.current || {};
          const taskNotEmpty = Object.keys(task).length > 0;
          if (taskNotEmpty) {
            this.props.navigation.replace('Activities', {
              event: {},
            });
          } else {
            this.props.navigation.popToTop();
          }
          return;
        }
      }
      if (this.props.order.type === UPDATE_ITEM_REQUEST) {
        return;
      }
      if (
        this.props.order.type === APPLY_OPERATION_BY_GROUP_FETCH ||
        nextProps.order.type === APPLY_OPERATION_BY_GROUP_FETCH
      ) {
        return;
      }
      if (
        this.props.order.type === REMOVE_MULTIPLE_ITEMS_FETCH ||
        nextProps.order.type === REMOVE_MULTIPLE_ITEMS_FETCH
      ) {
        return;
      }
      if (nextProps.order.type !== UPDATE_SHIPPING_ORDER_REQUEST) { // PROBLEMA AO SALVAR ORCAMENTO
        return; // PROBLEMA AO SALVAR ORCAMENTO
      } // PROBLEMA AO SALVAR ORCAMENTO
      if (
        this.props.order.type === FINISH_CART_FETCH_REQUEST ||
        nextProps.order.type === FINISH_CART_FETCH_REQUEST
      ) {
        return;
      }
      if (
        this.props.order.type === SAVE_CART_FETCH_REQUEST ||
        nextProps.order.type === SAVE_CART_FETCH_REQUEST
      ) {
        return;
      }
      if (nextProps.order.type === ORDER_FETCH_SUCCESS) {
        this.setState({ editionMode: false });
        const { order: orderProps } = nextProps;
        const { payload: cart = {} } = orderProps;

        if (this.salesConditionsRef?.current !== null) {
          this.salesConditionsRef?.current?.setSaleCondition(cart.condvenda);
        }
        const messages = _.get(cart, 'mensagens', []);
        if (messages.length || this.changeConditionSales) {
          if (!messages.some(m => m.codigo === 'sugestao_quantidade')) {
            executeActionOrOpenScreenMessage(
              messages,
              () => {
                const saleConditionInvalid = this.salesConditionsRef.current
                  ?.state?.invalidConditions;

                this.setState({
                  editionMode: saleConditionInvalid,
                });
              },
              () => this.actionCancelOrderNotError(orderProps),
            );
          }
        }
      }
    })();
    (async () => {
      if (nextProps.order.type === this.props.order.type) {
        return;
      }

      if (nextProps.client.type === CLIENT_CURRENT_FETCH_REQUEST) {
        this.canListConditionSales = true;
      }

      if (nextProps.order.type !== ORDER_FETCH_SUCCESS) {
        return;
      }

      if (this.props.client.type !== CLIENT_CURRENT_FETCH_SUCCESS) {
        return;
      }

      if (this.addigItem) {
        return;
      }

      if (this.canListConditionSales) {
        const { current: client } = this.props.client;
        if (this.salesConditionsRef.current !== null) {
          this.salesConditionsRef.current.listConditionsSales(client);
        }

        this.canListConditionSales = false;
      }
    })();
  }

  UNSAFE_componentWillUnmount() {
    this.screenMounted = false;
    window.removeEventListener('keydown', handleKeyDown);
    this.stopGpsLocation();
  }

  updateCartData = async (data = []) => {
    // const cart = data.length ? _.get(data, 'order.payload', '') : _.get(this.props, 'order.payload', '');
    const cart = data.length ? data.order?.payload : this.props.order?.payload;
    if (!cart) {
      return;
    }
    const cartId = cart.codigo.toString();
    await this.props.dispatch(getCartFetch(cartId));
  }

  updateCartAfterMessagesScreen = () => {
    this.updateCartData();
    this.props.dispatch(setCurrentProduct({}));
  };

  componentWillUnmount() {
    const { activeProductsGrouping } = this.state;
    if (activeProductsGrouping) {
      this.clearAllProductGrouping();
    }
    this.isVisible = false;
    this.stopGpsLocation();
  }

  onPressObservations = product => {
    if (this.state.editionMode) {
      createNotifyInfo(`${translate('observations')}.${translate('cannotOpenObservation')}`);
      return;
    }

    if (product) {
      this.props.navigation.navigate('OrderObservations', {
        product,
      });
    } else {
      this.props.navigation.navigate('OrderObservations', {
        codigo: this.props.order.payload.codigo,
      });
    }
  };

  onClickOnPressObservations = onPressHelper.debounce(this.onPressObservations);

  removeSelectedProducts = () => {
    const { selectedProducts } = this.state;

    if (selectedProducts.length) {
      this.clearAllProductGrouping();
    }
  };

  navigationButtonPressed({ buttonId }) {
    if (buttonId === 'RNN.back') {
      this.onPressHandleBackPress();
      this.removeSelectedProducts();
    } else if (buttonId === 'RNN.hardwareBackButton') {
      // DEACTIVATE HARDWARE BACK BUTTON
    } else if (buttonId === 'incentive') {
      this.onPressOpenIncentives();
    } else if (buttonId === 'search') {
      this.setState({ showSearch: !this.state.showSearch, searchText: '' });
    }
  }

  cancelActiveOrder = async () => {
    const promiseCancelOrder = new Promise((resolve, reject) =>
      OrdersModule?.cancelActiveCart(resolve, reject),
    );

    try {
      await promiseCancelOrder;
      this.props.dispatch(updateStatusOpenOrder(true));
    } catch (error) {
      createNotifyError(`${translate('atention')}.${translate('errorOnCancelOrder')}`);
    }
  };

  checkBeforeBackPress = () => {
    if (this.salesConditionsRef.current === null) {
      return;
    }
    const currentScreen = this.props.currentScreenVisible.screen;
    const isEditionMode = this.state.editionMode;

    const saleConditionState = _.get(
      this.salesConditionsRef.current,
      'state',
      {},
    );
    const isVisible = this.props.navigation.isFocused();
    if (!isVisible) {
      return false;
    }
    const valueCombinations = _.get(
      saleConditionState,
      'conditions.combinacoes',
      [],
    );
    const operationGroupContext = [
      'OperationGroupActionModalID',
      'OperationGroupActionsID',
    ].includes(this.props.currentScreenVisible.screen);

    if (!isVisible && !operationGroupContext) {
      return false;
    }
    const { selectedShipping = {}, shippings = [] } = saleConditionState;

    if (selectedShipping.type === SHIPPING_TYPES.API) {
      const userConfirmed = window.confirm(
        `${translate('lostShippingMessage')}\n\n${translate('you_really_want_leave')}`
      );
      
      if (userConfirmed) {
        if (shippings[0]) {
          this.props.dispatch(
            updateShipping({
              shipping: JSON.stringify(shippings[0]),
            }),
          );
          setTimeout(() => {
            this.props.navigation.popToTop();
          }, 800);
        }
      } else {
        // Ação para quando o usuário escolhe "stay"
      }
      return false;
    }

    const messages = _.get(this.props, 'order.payload.mensagens', []);
    const condSaleInvalid = messages.find(
      msg => msg.codigo === INVALID_SALES_CONDITION,
    );

    if (isEditionMode && valueCombinations.length > 0 && !condSaleInvalid) {
      this.setState({ pressedBackInEditionMode: true });
      return false;
    }

    if (condSaleInvalid || valueCombinations.length === 0) {
      this.cancelActiveOrder();
      this.props.navigation.popToTop();
    }

    return true;
  };

  handleBackPress = () => {
    const confirmBackPress = this.checkBeforeBackPress();
    const byCart = this.props.route.params?.byCart || false;
    const task = this.props.taskCurrent?.current || {};
    const taskNotEmpty = Object.keys(task).length > 0;
    const isEditionMode = this.state.editionMode;
    if (!byCart && confirmBackPress && !taskNotEmpty) {
      this.props.navigation.popToTop();
    } else if (byCart && confirmBackPress && !taskNotEmpty) {
      this.props.navigation.replace('Cart', {
        byResume: true,
      });
    } else if(!byCart && !confirmBackPress && !taskNotEmpty && isEditionMode) {
      console.log('IF 3');
    } else {
      this.props.navigation.replace('Activities', {
        event: {},
      });
    }
  };

  onPressHandleBackPress = onPressHelper.debounce(this.handleBackPress);

  confirmBackInEditionMode = () => {
    this.setState({ pressedBackInEditionMode: false });
    const byCart = this.props.route.params?.byCart || false;
    if (byCart) {
      this.props.navigation.replace('Cart', {
        byResume: true,
      });
    } else {
      this.props.navigation.pop();
    }
  };

  cancelBackInEditionMode = () => {
    this.setState({ pressedBackInEditionMode: false });
  };

  getListByContext() {
    const {
      configLib: { payload },
    } = this.props;
    const cartId = _.get(this.props.order, 'payload.codigo');

    const getCustomList = _.get(
      payload,
      'listagem_campos_visibilidade.order_resume.get_custom_list',
      false,
    );

    if (getCustomList) {
      const params = {
        pk_pedido: cartId,
      };

      new Promise((resolve, reject) => {
        GeneralModule?.getCustomList(
          `${emp2long('PDIT')}`,
          `${emp2long('ITEM')}`,
          JSON.stringify(params),
          true,
          null,
          resolve,
          reject,
        );
      }).then(resp => {
        const { data = [] } = JSON.parse(resp || '{}') || {};
        const productsByList = this.getDataProducts(
          data.filter(i => i.tipo === 'produto') || [],
        );
        this.setState({ productsByList });
      });
    }
  }

  getConfigCached = async () => {
    const value = await AsyncStorage.getItem('@objPayload');
    if (value) {
      this.setState({ configCached: JSON.parse(value) });
    }
  };

  getDataInOrder(product) {
    const { chave } = product;
    const { payload } = this.props.order;
    const prod = _.find(payload.produtos, o => o.chave === chave);

    if (prod) {
      const { preco, quantidade } = prod;
      return { ...prod, quantity: quantidade, price: preco };
    }

    return { ...prod, quantity: 0, price: product.preco };
  }

  getDataProducts = productsMap =>
    productsMap.map(product => {
      const {
        nome,
        html,
        acrescimo,
        desconto,
        estoque,
        menor_preco: minPrice,
        multiplo_venda: salesMultiple,
        preco = '',
        precobase,
        precototal,
        quantidade,
        unidade,
        unidade_macro: macroUnity,
        chave,
        codigo,
      } = product;

      const { configCached } = this.state;

      const prodByCart = this.getDataInOrder(product);
      const image = getImageProds(product, configCached);
      const contaisMagnitude = this.props.productsMagnitudes.payload.find(
        magnitude => magnitude.codproduto === product.codigo,
      );

      return {
        ...prodByCart,
        ...product,
        chave,
        codigo,
        acrescimo: Number(acrescimo ? acrescimo.replace(',', '.') : '0'),
        desconto: Number(desconto ? desconto.replace(',', '.') : '0'),
        estoque: Number(estoque),
        menor_preco: Number(minPrice ? minPrice.replace(',', '.') : '0'),
        multiplo_venda: Number(salesMultiple),
        preco: Number(preco ? preco.replace(',', '.') : '0'),
        precobase: Number(precobase ? precobase.replace(',', '.') : '0'),
        precototal: Number(precototal ? precototal.replace(',', '.') : '0'),
        quantidade: Number(quantidade),
        unidade: Number(unidade),
        unidade_macro: Number(macroUnity),
        id: prodByCart.chave,
        name: nome,
        nome,
        minPrice: prodByCart.menor_preco,
        stock: prodByCart.estoque,
        description: prodByCart.descricao,
        multiple: prodByCart.multiplo_venda,
        ean: prodByCart.ean,
        image,
        type: prodByCart.tipo,
        moreInfo: html,
        unity: prodByCart.unidade,
        type_magnitudes: contaisMagnitude
          ? MAGNITUDES_MANY_PRODUCT
          : MAGNITUDES_ONLY_PRODUCT,
        html,
      };
    });

  onChangeText = text => {
    clearTimeout(this);
    setTimeout(() => {
      this.setState({ searchText: text });
    }, 400);
  };

  async handlePrice(price) {
    const { price: lastPrice } =
      (await CacheDataController.getData('@order_price')) || {};

    if (String(price) !== String(lastPrice)) {
      this.verifyPriceChange(price, lastPrice);
      await CacheDataController.saveData('@order_price', { price });
    }
  }

  getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              timestamp: position.timestamp,
            });
          },
          (error) => {
            reject(error);
          },
          { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
        );
      } else {
        reject(new Error('Geolocation is not supported by this browser.'));
      }
    });
  };

  saveCartBudget = async (callbackSuccess, callbackError) => {
    const task = this.props.taskCurrent?.current || this.props.route.params?.taskCurrent?.current?.item;
    this.callbackSaveCartSuccess = callbackSuccess;
    this.callbackSaveCartError = callbackError;

    if (task && this.props.makeOrderModule.payload === MODULE_ACTIVITIES) {
      const client = this.props.client.current;
      const setup = task?.setup ? JSON.parse(task?.setup) : {};
      try {
        const location = await this.getCurrentLocation();
        const adaptativeLocation = {
          coords: {
            latitude: location.latitude,
            longitude: location.longitude,
            accuracy: location?.accuracy || 0,
          },
        };
        this.location = { ...adaptativeLocation.coords, timestamp: location.timestamp };
        const message = GpsHelper.validateRulesGPSClient(
          setup,
          client,
          this.location,
        );
        if (!_.isEmpty(message)) {
          createNotifyInfo(`${translate('atention')}`, message);
          return;
        }
      } catch (err) {
        createNotifyError(`${translate('atention')}`, err);
        return;
      }
    }
    if (this.props?.order?.payload?.total === 0) {
      createNotifyInfo('Não foi possível salvar o carrinho. Carrinho vazio.');
      return;
    }
    this.props.dispatch(saveCart(this.props.order.payload.codigo));
  };

  onCloseModal = () => {
    this.setState({ showModalSort: false, showBackPressAlert: false });
  };

  onSelectProduct = product => {
    let { selectedProducts } = this.state;

    const prodIndex = selectedProducts.findIndex(
      ({ codigo }) => codigo === product.codigo,
    );

    selectedProducts =
      prodIndex !== -1
        ? selectedProducts.filter(p => p.codigo !== product.codigo)
        : [...selectedProducts, { ...product, selected: true }];

    this.setState({ selectedProducts });
  };

  clearAllProductGrouping = () => {
    this.setState({ selectedProducts: [] }, () => {
      this.toogleProductsGrouping();
      if (this.isVisibleOperationGroupActions) {
        this.dismissOperationGroupActions();
      }
    });
  };

  getActionTypeHandler() {
    return {
      operationGroupingDefault: this.onApplyOperationByGroup,
      removeSelectedItems: this.onApplyRemoveItems,
    };
  }

  handleOperationGroup = (actionType, props) => {
    const handler = this.getActionTypeHandler();
    handler[actionType](props);
  };

  toggleOperationGroupModal = props => {
    const { operation } = props;
    if (operation === 'deselectSelected') {
      this.handleProductGrouping?.('clearAll')
      return;
    }
    if (['deadline', 'table'].includes(operation)) {
      this.onPressTableOrDeadline();
    } else {
      this.props.navigation.navigate('ActionModal', {
        onApply: this.handleOperationGroup,
        props: props,
      });
    }
  };

  dismissOperationGroupActions = () => {
    Navigation.dismissOverlay('OperationGroupActionsID');
    this.isVisibleOperationGroupActions = false;
  };

  appendProductGrouping = ({ product }) => {
    let { selectedProducts } = this.state;

    const prodIndex = selectedProducts.findIndex(
      ({ codigo }) => codigo === product.codigo,
    );

    selectedProducts =
      prodIndex !== -1
        ? selectedProducts.filter(p => p.codigo !== product.codigo)
        : [...selectedProducts, { ...product, selected: true }];

    this.setState({ selectedProducts }, () => {
      const { activeProductsGrouping } = this.state;

      if (!selectedProducts.length && activeProductsGrouping) {
        this.toogleProductsGrouping();
      }
    });
  };

  onSelectProducts = () => {
    this.setState({ selectedProducts: [] });
  };

  onChangeTableOrDeadline = resp => {
    const table = _.get(resp, 'tableChildSelect', {});
    const deadline = _.get(resp, 'prazChildSelect', {});

    const salesConditionsChild = { table, deadline };
    this.setState({ salesConditionsChild });
  };

  onConfirmBackPress = () => {
    this.props.navigation.push('Cart', {
      comesFromClientDetail: this.props.route.params?.comesFromClientDetail,
    });
  };

  onPressOnConfirmBackPress = onPressHelper.debounce(this.onConfirmBackPress);

  onApplyRemoveItems = async () => {
    const { selectedProducts } = this.state;

    this.props.dispatch(setCurrentProduct({}));
    this.props.dispatch(removeMultipleItems({ selectedProducts }));
  };

  onApplyOperationByGroup = async operations => {
    const { selectedProducts } = this.state;
    this.props.dispatch(setCurrentProduct({}));

    this.props.dispatch(
      applyOperationByGroup({ operations, selectedProducts }),
    );
  };

  onPressConditionsSalesChild = (product, context = 'default') => {
    NavigationHelper.push(
      this.props.componentId,
      SCREEN_SALES_CONDITIONS_CHILD.name,
      SCREEN_SALES_CONDITIONS_CHILD.title,
      {
        product,
        onChangeTableOrDeadline:
          context !== 'default' ? this.onChangeTableOrDeadline : null,
      },
    );
  };

  onPressConditionsPriceTerm = (params = {}) => {
    const { product } = params;
    const { order: activeOrder } = this.props;
    const produtos = _.get(activeOrder, 'payload.produtos', []);
    const listProducts = product ? [product] : produtos;

    NavigationHelper.push(
      this.props.componentId,
      SCREEN_PRICE_TERM_CONDITIONS.name,
      SCREEN_PRICE_TERM_CONDITIONS.title,
      {
        products: listProducts,
      },
    );
  };

  isExpiredOfflinePeriod = async () => {
    try {
      if (!this.props.configLib?.payload?.tempo_offline) {
        throw new Error('Invalid tempo_offline field');
      }
      const result =
        (await getQueryResult(
          'FTS3;TIME;OFLN',
          JSON.stringify({ 1: this.props.configLib?.payload?.tempo_offline }),
          false,
        )) || '[]';
      const response = JSON.parse(result)?.[0];
      const values = Object.values(response);

      return values?.[0] === '0';
    } catch (err) {
      return this.props.configLib?.payload?.tempo_offline;
    }
  };

  onPressFinishOrder = async () => {
    Promise.all([
      this.isContextSaleOpportunity(),
      // this.validateGPSPoints(),
      // this.isExpiredOfflinePeriod(),
    ]).then(result => {
      const isExpired = result[2] ?? false;

      if (isExpired) {
        this.setState({ isModalVisible: true });
        return;
      }
      showAlert(`${translate('finishOrder')}`, `${translate('clickConfirmToSendYourOrder')}`, () => {
        this.onAcceptFinishOrder();
      });
    });
  };

  onClickOnPressFinishOrder = onPressHelper.debounce(this.onPressFinishOrder);

  onPressCancelOrder = () => {
    if (
      this.props.order &&
      this.props.order.payload &&
      this.props.order.payload.total > 0.0
    ) {
      if (
        window.confirm(
            `${translate('wantDiscardYourChanges')}`,
        )
    ) {
      this.onAcceptCancelOrder();
    }
    } else {
      this.onAcceptCancelOrder();
    }
  };

  onClickOnPressCancelOrder = onPressHelper.debounce(this.onPressCancelOrder);

  onAcceptCancelOrder = async () => {
    this.cancelCart = true;
    this.props.dispatch(abort());
    this.props.dispatch(updateStatusOpenOrder(true));
    await this.props.dispatch(cancelCartActiveFetch());

    this.props.dispatch(clearCurrentTask());
    this.props.dispatch(setSaleOpportunity(false));
    SaleOpportunityCache.removeSaleOptnSelected();
  };

  onAcceptFinishOrder = () => {
    if (this.state.isModalVisible) {
      this.closeModal();
    }
    const { payload } = this.props.order;
    const { codigo } = payload;
    this.props.dispatch(finishCart(codigo));
  };

  onListProduct = () => {
    this.props.navigation.pop();
  };

  onConfirmChangeClient = client => {
    const clientCurrent = this.props.client.current;
    if (clientCurrent.pkey !== client.pkey) {
      this.clientChange = client;

      const cart = this.props.order.payload;
      const cartId = cart.codigo;
      const { orcamento, condvenda } = cart;

      const condvendaCopy = { ...condvenda };
      condvendaCopy.codcliente = client.pkey;
      condvendaCopy.codmeio = true;
      condvendaCopy.codprazo = true;
      condvendaCopy.orgvenda = true;
      condvendaCopy.tabelapreco = true;
      condvendaCopy.tipovenda = true;
      const payloadOrderClient = {
        codigo: cartId,
        pkeyClient: client.pkey,
        condvenda: condvendaCopy,
        orcamento,
        screen: SCREEN_RESUME_ORDER,
      };
      this.props.dispatch(orderClient(payloadOrderClient));
      this.handleClientChange();
    }
  };

  onLocationChange = location => {
    if (location) {
      this.setState({ location });
    }
  };

  setButtons = () => {
    const checkIncentive = _.get(
      this.props.order,
      'payload.configuracoes.incentivo_habilitado',
      false,
    );

    let rightButtons = [{ id: 'search', icon: Images.iconSearch }];
    if (checkIncentive) {
      rightButtons = [
        ...rightButtons,
        { id: 'incentive', icon: Images.iconGift },
      ];
    }

    this.props.navigation.setOptions({
      headerRight: () => (
        <View style={{ flexDirection: 'row' }}>
          <View>
            <TouchableOpacity onPress={() => {
              this.setState({ showSearch: !this.state.showSearch, searchText: '' });
        }}
            >
              <Image
                source={Images.iconSearch}
                style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 20 }}
              />
            </TouchableOpacity>
          </View>
          <View>
            {/* */}
          </View>
        </View>
      ),
      headerLeft: () => (
        <TouchableOpacity onPress={this.handleBackPress}
        >
          <Image
            source={Images.iconBack}
            style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
          />
        </TouchableOpacity>
      ),
    });
  };

  getGPSConfigs() {
    const {
      configLib: { payload },
    } = this.props;
    const task = _.get(this.props, 'taskCurrent.current', {});
    const setup = task?.setup ? JSON.parse(task?.setup) : {};
    const configs = GpsHelper.getGpsConfigsByLibAndActivity(
      payload.gps,
      setup,
      'out',
    );
    return configs;
  }

  finishSaleOpportunity = selected =>
    new Promise((resolve, reject) => {
      if (selected) {
        GeneralModule?.finishSaleOpportunity(selected.u_pkey)
          .then(() => {
            this.props.dispatch(setSaleOpportunity(false));
            SaleOpportunityCache.removeSaleOptnSelected();
            resolve(true);
          })
          .catch(() => {
            createNotifyError(`${translate('SalesOpportunity')}. ${translate('paramsError')}`);
            reject();
          });
      } else {
        resolve(true); // chama o saveAnswer. Acho que deveria ser reject/resolve false
      }
    });

  isContextSaleOpportunity = async () => {
    const selected =
      this.salesOpportunitySelected ||
      (await SaleOpportunityCache.getSaleOptnSelected());

    return this.finishSaleOpportunity(selected);
  };

  handleBackScreen = () => {
    const activitiesComponentId = _.get(
        this.props.taskCurrent,
        'current.componentId',
        null,
    );
    const task = this.props.taskCurrent?.current || {};
    const taskNotEmpty = Object.keys(task).length > 0;


    if (taskNotEmpty) {
      console.log('IF 1');
      /* this.props.navigation.replace('Activities', {
        event: task?.event || {},
        orderSuccess: true,
      }); */
      this.props.navigation.pop();
      setTimeout(() => {
        this.props.navigation.navigate('Activities');
      }, 1000);
    } else {
      console.log('ELSE 1');
      this.props.navigation.popToTop();
    }
  };

  saveOrderAnwser = async codigo => {
    const { dateSelected: currentDate } =
      (await CacheOrderActivity.getOrderActivity()) || {};
    const taskCurrent = _.get(this.props, 'taskCurrent.current', {});
    const { mensagens, ...orderPayload } = _.get(
      this.props,
      'order.fallback_payload',
      {},
    );

    const produtos = orderPayload?.produtos?.length > 0 ? orderPayload.produtos : this.state.actualCart?.payload?.produtos;

    const orderProducts = _.map(produtos, prod => {
      const { html, ...rest } = prod;

      // Check if xilincado is a json string
      if (rest.xilincado && TextUtils.isJSONString(rest.xilincado)) {
        rest.xilincado = JSON.parse(rest.xilincado);
      }

      return rest;
    });

    const newPayload = {
      ...orderPayload,
      produtos: orderProducts,
      location: this.state.location,
    };

    if(orderProducts.length === 0) {
      createNotifyError(`${translate('cantSaveAnswer')}. ${translate('pleaseTryAgain')}`);
      return;
    }

    const startDate = _.get(
      taskCurrent,
      'answer.start_date',
      new Date().toISOString(),
    );

    const dateSelected =
      _.get(this.props, 'client.current.dateSelected', currentDate) || '';

    const client = this.props.client.current;


    const { id: idTask = '0', id_processo: idProcess = '0' } = taskCurrent;
    const idContext = _.get(taskCurrent, 'event.id_contexto', '0');

    const userData = await new CacheAccount().getDataAccount() || '{}';
    const codmaq = userData?.user?.cod_maq || null;

    if (!codmaq) {
      createNotifyError(`${translate('cantSaveAnswer')}. ${translate(
        'pleaseTryAgain',
      )}`);
      return;
    }
    const { location } = newPayload;
    const { current } = this.props.client;

    let onClientArea = false;
    if (
      location.latitude &&
      location.longitude &&
      location.accuracy &&
      current.latitude &&
      current.longitude &&
      current.raio
    ) {
      onClientArea = GpsHelper.isPresentInArea(
        current,
        current.raio,
        location,
        location.accuracy,
      );
    }

    let baseAnswerPayload = await CacheOrderActivity.getPreOrderActivity(
      codigo,
    );

    if (!baseAnswerPayload) {
      baseAnswerPayload = {
        dataOpen: startDate,
        keyValueContext: client.pkey,
        idTask,
        idProcess,
        idContext,
        dateSelected,
        codmaq,
      };
    } else {
      CacheOrderActivity.clearPreOrderActivity(codigo);
    }

    await this.props.dispatch(
      saveAnswer({
        ...baseAnswerPayload,
        latitude: this.state.location.latitude,
        longitude: this.state.location.longitude,
        payload: {
          ...newPayload,
          on_client_area: onClientArea,
        },
      }),
    );
  };

  actionFinishOrderNotError = async codigo => {
    const contextSaleOpportunity = await this.isContextSaleOpportunity();
    if (contextSaleOpportunity) {
      await this.saveEventCloseOrder(codigo);
      const { pkey } = (await CacheOrderActivity.getOrderActivity()) || {};
      const existsPreOrder = await CacheOrderActivity.existsPreOrderActivity(
        codigo,
      );
      if (
        this.props.makeOrderModule?.payload === MODULE_ACTIVITIES ||
        pkey === codigo ||
        existsPreOrder
      ) {
        this.saveOrderAnwser(codigo);
      }
    }
    this.props.dispatch(cleanFallbackPayload());

    await CacheOrderActivity.clearOrderActivity();

    this.props.navigation.replace('Activities', {
      event: this.props.taskCurrent.current?.event || {},
    });

  };

  saveEventCloseOrder(pkeyOrder) {
    const { location } = this.state;

    return new Promise((resolve, reject) =>
      OrdersModule?.saveEventCloseOrder(
        pkeyOrder,
        JSON.stringify(location || {}),
        resolve,
        reject,
      ),
    );
  }
  actionCancelOrderNotError = cart => {
    if (this.salesConditionsRef.current !== null) {
      this.salesConditionsRef?.current?.updateConditionSale(cart);
    }
    this.setState({ editionMode: false }, () => {
      this.getListByContext();
      this.getDiscountTalao();
    });
  };

  applySuggestionQuantity = async (option, params = {}) => {
    const { quantidade_sugerida: suggestion, target: targetProduct } = params;
    const productRefCache = await CacheDataController.getData('@ADD_ITEM_REF');

    let toApplyProduct = productRefCache;

    if (
      targetProduct &&
      productRefCache.product.codigo !== targetProduct?.codigo
    ) {
      toApplyProduct = targetProduct;
    }

    const { product: productRef, quantity: quantityRef } = toApplyProduct || {};
    this.addigItem = true;

    if (option === 'yes') {
      this.addItem(productRef, suggestion || quantityRef);
    } else {
      this.addItem(productRef, quantityRef, true);
    }

    await CacheDataController.removeItem('@ADD_ITEM_REF');
  };
  async openIncentives() {
    try {
      let incentive = await IncentiveModule?.listIncentives();
      incentive = JSON.parse(incentive);
      NavigationHelper.showModal(
        SCREEN_INCENTIVE.name,
        SCREEN_INCENTIVE.title,
        {
          incentive,
        },
      );
    } catch (error) {
      const errors = JSON.parse(error.message);
      executeActionOrOpenScreenMessage(errors);
    }
  }

  async openIncentivesWithAutoFinish() {
    try {
      let incentive = await IncentiveModule?.listIncentives();
      incentive = JSON.parse(incentive);
      NavigationHelper.showModal(
        SCREEN_INCENTIVE.name,
        SCREEN_INCENTIVE.title,
        {
          incentive,
          onClose: this.onAcceptFinishOrder,
        },
      );
    } catch (error) {
      const errors = JSON.parse(error.message);
      executeActionOrOpenScreenMessage(errors);
    }
  }

  onPressOpenIncentives = onPressHelper.debounce(this.openIncentives);

  validateGPSPoints() {
    const { location } = this.state;
    const client = this.props.client.current;
    const {
      requiredDemand: required,
      verifyAreaDemand: verify,
    } = this.getGPSConfigs();

    const { msgs } = GpsHelper.validateRulesByConfigs({
      required,
      verify,
      client,
      location,
    });

    return new Promise((resolve, reject) => {
      if (msgs.length) {
        createNotifyError(msgs.join('. '));
        reject();
      } else {
        resolve();
      }
    });
  }

  filterProducts() {
    const { order, configLib } = this.props;
    const getCustomList = _.get(
      configLib.payload,
      'listagem_campos_visibilidade.order_resume.get_custom_list',
      false,
    );

    const produtos = _.get(order, 'payload.produtos') || [];
    const { productsByList } = this.state;
    const search = TextUtils.slugfy(this.state.searchText);
    const productsToRender =
      productsByList.length > 0 || getCustomList ? productsByList : produtos;

    return productsToRender.filter(produto => {
      const res =
        (produto.codigo && TextUtils.slugfy(produto.codigo).includes(search)) ||
        TextUtils.slugfy(produto.nome).includes(search) ||
        TextUtils.slugfy(produto.descritivo).includes(search);
      return res;
    });
  }

  getDiscountTalao = async () => {
    const cartId = _.get(this.props.order, 'payload.codigo');
    try {
      const params = {
        pk_pedido: cartId,
      };
      const data =
        (await getQueryResult('CART;VIEW;DPTL', JSON.stringify(params))) ||
        '[]';
      const dataParsed = JSON.parse(data);

      this.setState({ discountsView: dataParsed });
    } catch (err) { }
  };

  addItem = async (item, quantity, ignoreQtdSuggestion = false) => {
    const { order, currentProduct } = this.props;
    const { codcliente } = order.payload.condvenda;
    let itemCopy = Object.assign({}, item);
    itemCopy.quantidade = quantity;

    if (currentProduct.payload && currentProduct.payload.chave === item.chave) {
      const {
        preco,
        precobase,
        desconto,
        acrescimo,
        estoque,
      } = currentProduct.payload;
      itemCopy = {
        ...itemCopy,
        preco,
        precobase,
        desconto,
        acrescimo,
        estoque,
      };
      const payloadAddItem = {
        clientCode: codcliente,
        itemKey: itemCopy.chave.toString(),
        item: itemCopy,
        ignoreQtdSuggestion: ignoreQtdSuggestion,
      };
      this.props.dispatch(updateItemFetch(payloadAddItem));
    } else {
      try {
        const payloadAddItem = {
          clientCode: codcliente,
          itemKey: itemCopy.chave.toString(),
          item: itemCopy,
          ignoreQtdSuggestion: ignoreQtdSuggestion,
        };
        this.props.dispatch(updateItemFetch(payloadAddItem));
      } catch (error) {
        const messages = JSON.parse(error.message);
      }
    }
    this.props.dispatch(updateOrderScreen(SCREEN_RESUME_ORDER.name));
  };

  setDiscountTalao = (input, type) => {
    if (!Number.isNaN(parseFloat(input.value)) && this.isVisible) {
      this.props.dispatch(applyDiscountTalao({ input, type }));
    }
  };

  productClick = product => {
    this.productClicked = true;
    this.props?.productClick ? this.props.productClick(product) : this.props.route.params?.productClick(product);
  };

  onPressProductClick = onPressHelper.debounce(this.productClick);

  comesFromItinerary = () => {
    const activitiesComponentId = _.get(
      this.props.taskCurrent,
      'current.componentId',
      null,
    );
    const makeOrderModule = _.get(this.props.makeOrderModule, 'payload');

    return activitiesComponentId && makeOrderModule === MODULE_ACTIVITIES;
  };

  goToShareOrder = async () => {
    const configs = _.get(this.props, 'configLib', {});
    const order = _.get(this.props.order, 'payload', {});
    const client = _.get(this.props.client, 'current', {});
    const activitiesComponentId = _.get(
      this.props.taskCurrent,
      'current.componentId',
      null,
    );

    const netInfo = await NetInfo.fetch();
    if (!netInfo.isConnected) {
      createNotifyError('Sem conexão com a internet');
      return;
    }

    /* NavigationHelper.push(
      this.props.componentId,
      SCREEN_SHARE_ORDER.name,
      'Configurações de envio',
      {
        configs,
        order,
        client,
        saveCartBudget: this.saveCartBudget,
        comesFromItinerary: this.comesFromItinerary(),
        activitiesComponentId,
      },
      {
        layout: {
          backgroundColor: '#fff',
        },
      },
    ); */
  };

  onPressFloatingAction = id => {
    switch (id) {
      case 'save_budget': {
        this.saveCartBudget();
        break;
      }
      case 'cancel_order': {
        this.onClickOnPressCancelOrder();
        break;
      }
      case 'share_order': {
        this.goToShareOrder();
        break;
      }
      default:
        break;
    }
  };

  getObservations = () =>
    new Promise((resolve, reject) => {
      OrdersModule?.getOrderObservation(
        this.props.order.payload.codigo,
        resolve,
        reject,
      );
    });

  verifyObservations = async () => {
    const { configLib } = this.props;
    const requiredObservations = _.get(
      configLib.payload,
      'configuracoes.observacoes_obrigatorias',
      false,
    );
    let hasAnswer = false;

    if (requiredObservations) {
      const resp = (await this.getObservations()) ?? '[]';
      const observations = JSON.parse(resp);
      const answered = observations?.filter(fi => fi.value);
      hasAnswer = answered.length > 0;
    }

    this.setState({ observationsAnswerd: hasAnswer });
  };

  toogleProductsGrouping = () => {
    const { activeProductsGrouping } = this.state;
    this.setState({
      activeProductsGrouping: !activeProductsGrouping,
    });
  };

  getProductGroupingHandler = () => {
    return {
      append: this.appendProductGrouping,
      clearAll: this.clearAllProductGrouping,
      toggle: this.toogleProductsGrouping,
    };
  };

  handleProductGrouping = (operation, props = {}) => {
    const handler = this.getProductGroupingHandler();
    handler[operation](props);
  };

  handleClientChange = () => {
    this.props.dispatch(setCurrentClient(this.clientChange));
  };

  handleChangeConditions = changed => {
    this.changeConditionSales = changed;
    this.props.dispatch(forceUpdatelistComb(changed));
  };

  handleChangeState = state => {
    this.setState({
      orgSaleSelected: state.selectedSaleOrganization,
    });
  };

  handleToggleEdition = editing => {
    this.setState({
      editionMode: editing,
    });
  };

  render() {
    const {
      order,
      config,
      configLib,
      currentProduct,
      componentId,
    } = this.props;

    const {
      showSearch,
      location,
      selectedProducts,
      showBackPressAlert,
      salesConditionsChild,
      discountsView,
      observationsAnswerd,
      pressedBackInEditionMode,
      isModalVisible,
      activeProductsGrouping,
    } = this.state;

    const enableOperationGroup = _.get(
      configLib,
      'payload.configuracoes.product_group.enable_select',
      false,
    );

    const hiddenFields =
      _.get(
        configLib.payload,
        'listagem_campos_visibilidade.order.hidden_fields',
      ) || {};
    const decimalPrecisionConfig = getDecimalPrecisionConfig(configLib);

    const allowChangeClient = _.get(
      order,
      'payload.configuracoes.permite_alterar_cliente',
      false,
    );
    const produtos = this.filterProducts();

    const productsQuantity = order?.payload?.produtos
      ? order?.payload?.produtos?.length
      : 0;
    const genericCartInfos = _.get(order, 'payload.genericinfos', []);

    const configResume = {
      paramsStoker: config.payload,
      verba_hablitado: !hiddenFields.verba,
      configLib,
      lib: configLib.payload,
      decimalPrecisionConfig,
    };

    return (
      <Resume
        {...this.props}
        showSearch={showSearch}
        onChangeText={this.onChangeText}
        cart={order}
        config={configResume}
        itemUpdate={currentProduct.payload}
        location={location}
        onPressFinishOrder={this.onClickOnPressFinishOrder}
        onPressCancelOrder={this.onClickOnPressCancelOrder}
        addItem={this.addItem}
        productClick={this.onPressProductClick}
        onPressObservations={this.onClickOnPressObservations}
        onPressConditionsSalesChild={this.onPressConditionsSalesChild}
        onListProduct={this.onListProduct}
        onLocationChange={this.onLocationChange}
        editionMode={this.state.editionMode}
        produtos={produtos}
        productsQuantity={productsQuantity}
        productsMagnitudes={this.props.productsMagnitudes.payload}
        total={this.props.order.payload ? this.props.order.payload.total : 0}
        client={this.props.client.current}
        loading={this.props.order.generalLoading}
        genericViewObj={genericCartInfos}
        allowChangeClient={allowChangeClient}
        onConfirmChangeClient={this.onConfirmChangeClient}
        onClose={this.onCloseModal}
        onSelectProduct={this.onSelectProduct}
        onSelectProducts={this.onSelectProducts}
        selectedProducts={selectedProducts}
        showBackPressAlert={showBackPressAlert}
        onConfirmBackPress={this.onPressOnConfirmBackPress}
        onApplyOperationByGroup={this.onApplyOperationByGroup}
        onApplyRemoveItems={this.onApplyRemoveItems}
        salesConditionsChild={salesConditionsChild}
        onPressConditionsPriceTerm={this.onPressConditionsPriceTerm}
        discountsView={discountsView}
        setDiscountTalao={this.setDiscountTalao}
        onPressFloatingAction={this.onPressFloatingAction}
        observationsAnswerd={observationsAnswerd}
        pressedBackInEditionMode={pressedBackInEditionMode}
        confirmBackInEditionMode={this.confirmBackInEditionMode}
        cancelBackInEditionMode={this.cancelBackInEditionMode}
        // OperationGroup
        enableOperationGroup={enableOperationGroup}
        appendProductGrouping={this.appendProductGrouping}
        isModalVisible={isModalVisible}
        onAcceptFinishOrder={this.onAcceptFinishOrder}
        closeModal={this.closeModal}
        handleProductGrouping={this.handleProductGrouping}
        activeProductsGrouping={activeProductsGrouping}
        toggleOperationGroupModal={this.toggleOperationGroupModal}
        onChangeConditions={this.handleChangeConditions}
        onClientChange={this.handleClientChange}
        salesConditionsRef={this.salesConditionsRef}
        onToggleEdition={this.handleToggleEdition}
        onChangeState={this.handleChangeState}
        parentComponentId={componentId}
        orgSaleSelected={this.state.orgSaleSelected}
        screenMounted={this.screenMounted}
      />
    );
  }
}

ResumeContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  currentScreenVisible: PropTypes.bool.isRequired,
  productsMagnitudes: PropTypes.object.isRequired,
  configLib: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  products: PropTypes.object.isRequired,
  currentProduct: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  makeOrderModule: PropTypes.object.isRequired,
  taskCurrent: PropTypes.object.isRequired,
  saveAnswerFetch: PropTypes.object.isRequired,
  permission: PropTypes.object.isRequired,
  idRoot: PropTypes.string.isRequired,
  productClick: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    currentScreenVisible: state.currentScreenVisible,
    productsMagnitudes: state.productsMagnitudes,
    configLib: state.configLibFetch,
    client: state.clientCurrent,
    products: state.productsFetch,
    currentProduct: state.productCurrent,
    order: state.orderFetch,
    config: state.configFetch,
    makeOrderModule: state.makeOrderModule,
    taskCurrent: state.taskCurrent,
    saveAnswerFetch: state.saveAnswer,
    permission: state.permissionsFetch,
    idRoot: state.backRootShowModal.idRoot,
  };
}

export default connect(mapStateToProps)(ResumeContainer);