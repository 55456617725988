import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateShippingOrderAndCompany } from './utilsLib';

export const UPDATE_SHIPPING_ORDER_REQUEST = 'UPDATE_SHIPPING_ORDER_REQUEST';

export const updateShipping = createAsyncThunk(
  'ordersFetch/updateShipping',
  async payload => {
    try {
      const response = await updateShippingOrderAndCompany(
        payload.shipping,
        payload.company,
      );

      return JSON.parse(response);
    } catch (error) {
      console.log('error', error);
      return error;
    }
  },
);
