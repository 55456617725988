import {genericFuncWrapper} from "~/modules/WrappedModule";

const JustifyApiModule = (nameModule) => {
  const methodByAlias = {
  };

  const saveJustify = async (pkeyClient, type, value, text, location, resolve, reject) => {
    try {
      const salvarJustificativa = await genericFuncWrapper('salvarJustificativa');
      const response = await salvarJustificativa(pkeyClient, type.toUpperCase(), value, text);
      const parsedResponse = JSON.parse(response);
      if ('chave' in parsedResponse) {
        const postExecuteMethodName = type.toUpperCase() === 'JUSTIFICATION_NOT_SALE' ?
          'eventoJustificativaNaoVenda' : 'eventoJustificativaNaoVisita'
        let postExecuteMethod = await genericFuncWrapper(postExecuteMethodName);
        await postExecuteMethod(parsedResponse['chave'], location);
      }
      if (resolve) {
        resolve(response)
      }
      return response;
    } catch (e) {
      reject(e)
    }
  };

  return {
    getName: () => nameModule,
    saveJustify,
    methodByAlias
  };
};

export default JustifyApiModule;