import GPSDevice from './GPSDevice';

export default class GPSManager {
  gpsDevice = new GPSDevice();

  getCurrentPosition() {
    return this.gpsDevice.getCurrentPosition();
  }

  watchPosition(onLocationChanged, onLocationError) {
    this.gpsDevice.watchPosition(onLocationChanged, onLocationError);
  }

  clearWatchers() {
    this.gpsDevice.clearWatchers();
  }

  stopObserving() {
    this.gpsDevice.stopObserving();
  }
}
