/* eslint-disable no-nested-ternary */
/* @flow */

import React, { Component } from 'react';
import { View, StyleSheet, Text, Image, Platform } from 'react-native';
import { color, style } from '~/common';
import Images from '@assets/Images';
import FastImage from 'react-native-fast-image';
import OrderInfo from '../Info/OrderInfo';
import Touchable from 'react-native-platform-touchable';
import { fonts } from '../../../utils/Fonts';
import { getSourceImageProduct } from '../../../utils/ProductUtil';
import translate from '../../../locales';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  imageItem: {
    height: 100,
    width: 100,
  },
  container: {
    backgroundColor: '#fff',
    borderBottomColor: color.grayContainerDark,
    borderBottomWidth: 4,
  },
  chevron: {
    tintColor: color.grayContainerDark,
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  productName: {
    fontSize: 16,
    marginBottom: 6,
    marginLeft: 10,
    marginRight: 10,
    fontFamily: fonts.QuicksandMedium,
    color: '#000',
  },
  productCode: {
    fontSize: 14,
    marginLeft: 10,
    marginRight: 10,
    fontFamily: fonts.QuicksandBold,
    color: color.grayMid,
  },
});

class OrderItem extends Component {
  state = {
    errorImage: false,
    showInfo: false,
  };

  onPress = () => {
    this.setState({ showInfo: !this.state.showInfo });
  };

  render() {
    const { item, config } = this.props;
    const sourceProductImage = getSourceImageProduct(item, config);

    return (
      <View style={styles.container}>
        <Touchable onPress={this.onPress}>
          <View
            style={[
              style.spaceBetween,
              { paddingHorizontal: 8, paddingVertical: 8 },
            ]}>
            {this.state.errorImage ? (
              <Image source={Images.iconNoImage} style={styles.imageItem} />
            ) : Platform.OS === 'ios' ? (
              <Image
                onError={() => {
                  this.setState({ errorImage: true });
                }}
                onLoad={() => {
                  this.setState({ errorImage: false });
                }}
                defaultSource={Images.iconNoImage}
                fallbackSource={Images.iconNoImage}
                resizeMethod="contain"
                resizeMode="contain"
                style={styles.imageItem}
                source={sourceProductImage}
              />
            ) : sourceProductImage ? (
              <FastImage
                onError={() => {
                  this.setState({ errorImage: true });
                }}
                onLoad={() => {
                  this.setState({ errorImage: false });
                }}
                resizeMode={FastImage.resizeMode.contain}
                style={styles.imageItem}
                source={sourceProductImage}
              />
            ) : null}
            <View>
              <Text style={styles.productCode}>{item.codigo}</Text>
              <Text style={styles.productName}>{item.descricao}</Text>
            </View>
            <Image
              style={styles.chevron}
              source={
                this.state.showInfo
                  ? Images.iconChevronUp
                  : Images.iconChevronDown
              }
            />
          </View>
        </Touchable>
        {this.state.showInfo ? (
          <View>
            <View style={style.divider} />
            <View style={style.spaceBetween}>
              <OrderInfo
                info={`${translate('requestedQuantity')}`}
                value={item.quantidade}
              />
              <OrderInfo
                info={`${translate('billedQuantity')}`}
                value={item.quantidade_faturada}
                type="calculated"
              />
            </View>
            <View style={style.spaceBetween}>
              <OrderInfo
                info={`${translate('requestedPrice')}`}
                value={item.preco}
                type="price"
              />
              <OrderInfo
                info={`${translate('billedPrice')}`}
                value={item.preco_faturado}
                type="price"
              />
            </View>
            <View style={style.spaceBetween}>
              <OrderInfo
                info={`${translate('requestedTotal')}`}
                value={item.total}
                type="calculated"
              />
              <OrderInfo
                info={`${translate('billedTotal')}`}
                value={item.total_faturado}
                type="calculated"
              />
            </View>
            <View style={style.spaceBetween}>
              <OrderInfo
                info={`${translate('discountRequested')}`}
                value={item.desconto}
              />
              <OrderInfo
                info={`${translate('billedDiscount')}`}
                value={item.desconto_faturado}
              />
            </View>
          </View>
        ) : null}
      </View>
    );
  }
}

OrderItem.propTypes = {
  item: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
};

export default OrderItem;
