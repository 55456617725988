import {
    FlatList,
    Image,
    KeyboardAvoidingView,
    Text,
    View,
    Platform,
    Animated,
    Easing,
} from 'react-native';
import React, { Component } from 'react';
import Images from '@assets/Images';
import styles from './styles';
import IconText from '../../../../src/components/IconText';
import moment from 'moment';
import AlertBadge from '~/components/AlertBadge';
import GpsHelper from '../../../../src/utils/GpsHelper';
import Touchable from 'react-native-platform-touchable';
import color from '../../values/color';
import translate from '../../../../src/locales';
import AddressAltCache from '~/cache/AddressAltCache';
import _ from 'lodash';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import MyMapComponent from "~/screens/Today/components/MapView";

class Gps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            followLocation: true,
            showContainerMap: false,
            isConnected: true,
            crossHairValue: new Animated.Value(0),
            webLocation: {
                latitude: 0.0,
                longitude: 0.0,
                timestamp: 0.0,
                accuracy: 0.0,
                altitude: 0.0,
                speed: 0.0,
                title: '',
            }
        };
        this.mapRef = React.createRef();
        this.placeCurrent = props.places;
        this.geoOptions = {
            enableHighAccuracy: true,
        }
    }

    async componentDidMount() {
        if (this.props.showPermitionLocation) {
            if(navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.geoSuccess, this.geoError, this.geoOptions);
            } else {
                alert('Geolocation is not supported by this browser.');
            }
        }
        this.animateCrossHair();

        const { pkeyClient = false, places = [] } = this.props;
        if (pkeyClient) {
            const placeCached = await AddressAltCache.getAddressAlt(pkeyClient);
            this.placesCurrent = placeCached ? [placeCached] : places;
        } else {
            this.placesCurrent = places;
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.places, this.props.places)) {
            this.placesCurrent = this.props.places;
        }
    }

    geoSuccess = (position) => {
        this.setState({
            webLocation: {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                timestamp: position.timestamp,
                accuracy: position.coords.accuracy,
                altitude: position.coords.altitude,
                speed: position.coords.speed,
                title: 'Local Atual',
            }
        }, () => {
            this.props.onLocationChange(this.state.webLocation);
        });
    }

    geoError = (error) => {
        alert(`ERROR(${error.code}): ${error.message}`);
    }

    onItemPlacePress = place => {
        if (!place.latitude && !place.longitude && !place.title) {
            return;
        }
        alert(`${place.title ? place.title : 'Seu Local Atual'}`);
    };

    renderItemPlaces = ({ item }) => (
        <Touchable
            onPress={() => this.onItemPlacePress(item)}>
            <View style={styles.cardItemContainer}>
                <View style={styles.image}>
                    <Image
                        style={{ tintColor: '#FFFFFF', width: 25, height: 25 }}
                        source={Images.iconStore}
                    />
                </View>
                <View style={{ paddingRight: 40 }}>
                    <Text
                        numberOfLines={2}
                        ellipsizeMode="middle"
                        style={styles.cardItemTitle}>
                        {item.title || `(${translate('indeterminatedPlace')})`}
                    </Text>
                    <Text style={styles.cardItemDescription}>
                        {this.distanceToPlace((this.props.location || this.state.webLocation), item)}
                    </Text>
                </View>
            </View>
        </Touchable>
    );

    getAlertBadge(placesIsValid, myLocationIsValid, inThePlace) {
        let icon;
        let text;
        let type;

        const { gpsError } = this.props;

        if (!this.state.isConnected && myLocationIsValid) {
            icon = Images.iconAlert;
            text = `${translate('errorConnectionGPS')}`;
            type = 'danger';
        } else if (gpsError) {
            icon = Images.iconAlert;
            text = `${gpsError}`;
            type = 'danger';
        } else if (placesIsValid && myLocationIsValid) {
            if (inThePlace) {
                icon = Images.iconCheck;
                text = `${translate('inPlace')}`;
                type = 'success';
            } else {
                icon = Images.iconAlert;
                if (this.placesCurrent[0].latitude && this.placesCurrent[0].longitude) {
                    text = `${translate('outOfPlace')}`;
                    type = 'warning';
                } else {
                    text = `${translate('noCustomerLocation')}`;
                    type = 'danger';
                }
            }
        }

        return icon ? (
            <AlertBadge
                onPress={this.onPressEnableGPS}
                icon={icon}
                text={text}
                type={type}
            />
        ) : null;
    }

    inThePlace(location, place) {
        let inThePlace = false;

        if (
            location.latitude &&
            location.longitude &&
            place.latitude &&
            place.longitude
        ) {
            inThePlace = GpsHelper.isPresentInArea(
                location,
                location.accuracy,
                place,
                place.radius,
            );
        }
        return inThePlace;
    }

    distanceToPlace(location, place) {
        if (location && location.latitude && location.longitude && place) {
            if (this.inThePlace(location, place)) {
                return `${translate('inThisPlace')}`;
            }
            let distance = GpsHelper.haversineKm(location, place);
            let unity = '';
            if (distance < 1) {
                distance = parseInt(distance * 1000, 10);
                unity =
                    distance > 1 ? `${translate('meters')}` : `${translate('meter')}`;
            } else {
                distance = parseInt(distance, 10);
                unity =
                    distance > 1
                        ? `${translate('kilometers')}`
                        : `${translate('kilometer')}`;
            }
            return `${translate('locationDistance')} ${distance} ${unity}`;
        }
        return null;
    }

    keyExtractor = (item, index) => `${item.id}-${index}`;

    expandableMyLocation = myLocationIsValid => {
        if (!myLocationIsValid) {
            return this.animateCrossHairImage();
        }

        return (
            <IconText
                containerStyle={{ flex: 1, marginBottom: 4 }}
                imageSrc={
                    myLocationIsValid ? Images.iconCrosshairGps : Images.iconCrosshair
                }
                imageStyle={styles.cardIcon}
                text={`${translate('location')}`}
                textStyle={{ fontSize: 12 }}
            />
        );
    };

    datetimeView = datetime => (
        <IconText
            imageSrc={datetime ? Images.iconEvent : Images.iconCalendar}
            imageStyle={styles.cardIcon}
            text={datetime ? `${translate('updatedAt')} ${datetime}` : '-'}
            textStyle={{ fontSize: 12 }}
        />
    );

    animateCrossHairImage = () => (
        <View
            style={{
                flexDirection: 'row',
                flex: 1,
                alignItems: 'center',
                marginBottom: 4,
            }}>
            <Animated.Image
                style={[
                    styles.cardIcon,
                    { opacity: this.state.crossHairValue, marginRight: 8 },
                ]}
                source={Images.iconCrosshairGps}
            />
            <Text style={{ flex: 1, fontSize: 12 }} numberOfLines={2}>
                {`${translate('CollectingPoints')}`}
            </Text>
        </View>
    );

    accuracyView = (myLocationIsValid, accuracy) => {
        if (!accuracy) {
            return this.animateCrossHairImage();
        }

        return (
            <IconText
                containerStyle={{ marginBottom: 4 }}
                imageSrc={
                    myLocationIsValid ? Images.iconCrosshairGps : Images.iconCrosshair
                }
                imageStyle={styles.cardIcon}
                text={`${translate('precisionOf')} ${accuracy} ${accuracy > 1 ? `${translate('meters')}` : `${translate('meter')}`
                    }`}
                textStyle={{ fontSize: 12 }}
            />
        );
    };

    formatDateTime = location => {
        const datetime =
            location && location.timestamp
                ? moment(location.timestamp).format('DD/MM HH:mm:ss')
                : null;
        return datetime;
    };

    setThumbTintColor = () => {
        let thumbTintColor;
        if (Platform.OS === 'ios') {
            thumbTintColor = '#FFFFFF';
        } else {
            thumbTintColor = this.state.followLocation;
        }
        if (this.state.followLocation) {
            thumbTintColor = color.primaryColor;
        } else {
            thumbTintColor = color.grayAlmostWhite;
        }
        return thumbTintColor;
    };

    animateCrossHair() {
        this.state.crossHairValue.setValue(0),
            Animated.timing(this.state.crossHairValue, {
                toValue: 1,
                duration: 1000,
                easing: Easing.linear,
                useNativeDriver: true,
            }).start(() => this.animateCrossHair());
    }

    render() {
        const { useViewExpandable } = this.props;
        const { webLocation: location } = this.state;

        const accuracy =
            location && location.accuracy ? location.accuracy.toFixed() : null;
        const datetime = this.formatDateTime(location);

        const placesIsValid = this.placesCurrent && this.placesCurrent.length > 0;

        const inThePlace = placesIsValid
            ? this.inThePlace(location, this.placesCurrent[0])
            : false;

        const myLocationIsValid =
            location && location.latitude && location.longitude;

        this.setThumbTintColor();

        const alertBadgeInfos = this.getAlertBadge(
            placesIsValid,
            myLocationIsValid,
            inThePlace,
        );

        let initialLatitude = location.latitude;
        initialLatitude =
            !initialLatitude && placesIsValid && this.placesCurrent[0].latitude
                ? this.placesCurrent[0].latitude
                : 0.0;
        let initialLongitude = location.longitude;
        initialLongitude =
            !initialLongitude && placesIsValid && this.placesCurrent[0].longitude
                ? this.placesCurrent[0].longitude
                : 0.0;
        const initialRegionMap = {
            latitude: !initialLatitude ? 0.0 : initialLatitude,
            longitude: !initialLongitude ? 0.0 : initialLongitude,
            longitudeDelta: 0.01,
            latitudeDelta: 0.01,
        };

        const showContentMap =
            (useViewExpandable && this.state.showContainerMap) || !useViewExpandable;

        return (
            <View style={styles.container}>
                <KeyboardAvoidingView behavior="position" keyboardVerticalOffset={10}>
                    {useViewExpandable && (
                        <Touchable
                            onPress={this.onPress}>
                            <View style={[styles.cardContainer, { flexDirection: 'row' }]}>
                                {this.expandableMyLocation(myLocationIsValid)}
                                <Image
                                    style={{
                                        alignItems: 'flex-end',
                                        width: 20,
                                        height: 20,
                                        margin: 2,
                                        tintColor: color.grayDark,
                                    }}
                                    source={
                                        this.state.showContainerMap
                                            ? Images.iconCollapseArrow
                                            : Images.iconExpandArrow
                                    }
                                />
                            </View>
                        </Touchable>
                    )}
                    <View
                        style={[
                            { flex: 1 },
                            showContentMap ? null : styles.contentMapInvisible,
                        ]}>
                        <View>
                            {alertBadgeInfos}
                            <MyMapComponent
                                ref={this.mapRef}
                                height={220}
                                zoom={17}
                                positions={[
                                    {
                                        latitude: location.latitude,
                                        longitude: location.longitude,
                                        title: location.title,
                                    },
                                    this.placesCurrent
                                        ? {
                                            latitude: this.placesCurrent[0].latitude,
                                            longitude: this.placesCurrent[0].longitude,
                                            icon: Images.iconStore
                                        }
                                        : null,
                                ]}
                            />
                        </View>

                        <View style={[styles.cardContainer, { flexDirection: 'row' }]}>
                            <View style={{ flex: 1 }}>
                                {this.accuracyView(myLocationIsValid, accuracy)}
                                {this.datetimeView(datetime)}
                            </View>
                            <View
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                }}>
                            </View>
                        </View>
                        {placesIsValid ? (
                            <FlatList
                                data={this.placesCurrent}
                                keyExtractor={this.keyExtractor}
                                renderItem={this.renderItemPlaces}
                            />
                        ) : null}
                    </View>
                </KeyboardAvoidingView>
            </View>
        );
    }
}
function mapStateToProps(state) {
    return {
        permission: state.permissionsFetch,
    };
}

Gps.propTypes = {
    places: PropTypes.array,
    location: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
        timestamp: PropTypes.number,
        accuracy: PropTypes.number,
    }),
    pkeyClient: PropTypes.string,
    gpsError: PropTypes.string,
    onLocationChange: PropTypes.func,
    showPermitionLocation: PropTypes.bool,
    useViewExpandable: PropTypes.bool,
    permission: PropTypes.object,
    dispatch: PropTypes.func,
};

export default connect(mapStateToProps)(Gps);
