import React, { Component } from 'react';
import { View, Alert, FlatList, Text, StyleSheet, Image as ImageIcon } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import ActionItems from './ActionItems';
import { color } from '~/common';
import Images from '../../../assets/Images';
import IconText from '../IconText';
import translate from '~/locales';
import PropTypes from 'prop-types';

import { styleDeleteItems } from './styles';
import { showAlert } from '../Web/Alert';


const styles = StyleSheet.create({
    footerButtonsContent: {
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 20,
    },
    leftFooterButton: {
        paddingHorizontal: 50,
        paddingVertical: 10,
        alignItems: 'center',
    },
    rightFooterButton: {
        paddingHorizontal: 50,
        borderRadius: 2,
        paddingVertical: 10,
        backgroundColor: color.primaryColor,
    },
});

const defaultOperations = [
    {
        title: 'Tabela',
        name: 'table',
        type: 'combo',
        kind: 'select',
        id: 4,
    },
    {
        title: 'Prazo',
        name: 'deadline',
        type: 'combo',
        kind: 'select',
        id: 5,
    },
    {
        title: 'Multiplo',
        name: 'multiple',
        type: 'input',
        kind: 'money',
        id: 1,
    },
    {
        title: 'Preco',
        name: 'price',
        type: 'input',
        kind: 'money',
        id: 2,
    },
    {
        title: 'Desconto',
        name: 'discount',
        type: 'input',
        kind: 'percentage',
        id: 3,
    },
];

class OperationByGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            multiple: null,
            price: null,
            discount: null,
            table: this.props.salesConditionsChild.table,
            deadline: this.props.salesConditionsChild.deadline,
        };
    }

    onPressConfirm = () => {
        this.props.onConfirm({ ...this.state });
    }

    onPressRemoveItems = () => {
        this.props.onRemoveItems();
    }

    onChangeRadio = (
        value,
        option,
        item,
    ) => {
        this.setState({ [item.name]: { ...this.state[item.name], action: { value, option } } });
    }

    keyExtractor = (item, index) => `${item.id}-${index}`;

    renderFooter = () => {
        const renderButtons = Object.values(this.state).filter(Boolean).length > 0;
        return (
            <View>
                <View style={styleDeleteItems.container}>
                    <Touchable onPress={() => {
                        showAlert(translate('removeSelectedItems'), translate('wantRemoveSelectedItems'), this.onPressRemoveItems);
                    }}
                    >
                        <IconText
                            text={translate('removeSelectedItems')}
                            imageSrc={Images.iconDelete}
                            imageStyle={styleDeleteItems.text}
                            textStyle={styleDeleteItems.text}
                        />
                    </Touchable>
                </View>
                {
                    renderButtons && (
                        <View style={styles.footerButtonsContent}>
                            <Touchable onPress={this.props.onCancel} style={styles.leftFooterButton}>
                                <Text>
                                    Cancelar
                                </Text>
                            </Touchable>
                            <Touchable onPress={this.onPressConfirm} style={styles.rightFooterButton}>
                                <Text style={{ color: '#FFF' }}>
                                    Aplicar
                                </Text>
                            </Touchable>
                        </View>
                    )
                }
            </View >
        );
    }


    render() {
        const { operationsConfig } = this.props;
        const operations = defaultOperations
            .filter(defOp => operationsConfig
                .includes(defOp.title.toLowerCase()));

        return (
            <View>
                <FlatList
                    data={operations}
                    keyExtractor={this.keyExtractor}
                    contentContainerStyle={{ padding: 20 }}
                    renderItem={({ item }) => (
                        <ActionItems
                            value={this.state[item.name] ? this.state[item.name].value : ''}
                            item={item}
                            onChangeValue={(value) => {
                                this.setState({ [item.name]: { ...this.state[item.name], value } });
                            }}
                            onChangeRadio={(value, option) => this.onChangeRadio(value, option, item)}
                            selectedRadioOption={this.state[item.name] ? this.state[item.name].action : null}
                            onPressTableOrDeadline={this.props.onPressTableOrDeadline}
                            salesConditionsChild={this.props.salesConditionsChild}
                        />
                    )}
                    ListFooterComponent={this.renderFooter}
                />
            </View>
        );
    }
}

OperationByGroup.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onRemoveItems: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onPressTableOrDeadline: PropTypes.func.isRequired,
    salesConditionsChild: PropTypes.object.isRequired,
    operationsConfig: PropTypes.array.isRequired,
};

export default OperationByGroup;
