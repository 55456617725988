/* @flow */

import React, { Component } from 'react';
import { Image, TouchableOpacity } from 'react-native';
import Observations from './Observations';
import { connect } from 'react-redux';
import Types from '~/components/Form/types';
import translate from '../../../locales';
import moment from 'moment';
import OrdersModule from '~/modules/OrdersModule';
import Images from '../../../../assets/Images';

import PropTypes from 'prop-types';
import { createNotifyError, createNotifySuccess } from '~/components/Web/ToastNotify';
import { showAlert } from '~/components/Web/Alert';

const fieldsRules = [
  {
    field: 'date',
    type: 'sum',
  },
];

class ObservationsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataForm: [],
      initialValues: {},
      loading: true,
    };
  }

  componentDidMount() {
    this.setLeftButton();
    const { codigo } = this.props.route.params;
    new Promise((resolve, reject) => {
      OrdersModule.getOrderObservation(codigo, resolve, reject);
    })
      .then(data => {
        const newData = JSON.parse(data);

        if (
          Array.isArray(newData) &&
          (newData.length === 0 ||
            (newData.length && newData[0].codigo === 'sem_dados'))
        ) {
          this.setState({ loading: false }, () => {
            createNotifyError(`${translate('thereAreNotObservationsToThisProduct')}`);
            setTimeout(() => {
              this.props.navigation.goBack();
            }, 1000);
          });
          return;
        }
        const initialValues = {};
        const newDataForm = [...newData].map(field => {
          const { value, ...rest } = field;
          initialValues[field.field] = value;

          return rest;
        });
        this.setState({ dataForm: newDataForm, initialValues });
      })
      .catch(() => {
        this.setState({ loading: false }, () => {
          createNotifyError(`${translate('errorOpeningOrderNote')}`);
          setTimeout(() => {
            this.props.navigation.goBack();
          }, 1000);
        });
      })
      .finally(() => {
        if (this.state.loading) {
          this.setState({ loading: false });
        }
      });
  }

  validateForm = () => {
    const formData = [];
    this.state.dataForm.forEach(field => {
      let value = '';
      if (field.type === 'COMB') {
        value =
          field.data_source.find(item => item.value === field.value) || '';
      } else {
        value = field.value || '';
      }
      formData[field.field] = value;
    });
    this.setState({ formData, oldFormData: formData });
  };

  navigationButtonPressed({ buttonId }) {
    if (buttonId === 'save') {
      this.saveObservation();
    } else if (buttonId === 'backPress') {
      this.checkChangeBeforeBackPress();
    }
  }

  setSaveButton(valid) {
    const rightButtons = [];
    if (valid) {
      this.props.navigation.setOptions({
        headerRight: () => (
          <TouchableOpacity
            onPress={() => {
              this.saveObservation();
            }}>
            <Image
              source={Images.iconSave}
              style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 10 }}
            />
          </TouchableOpacity>
        ),
      });
    }
  }

  getFormData = formData => {
    this.setState({ formData });
  };

  isFormValid = valid => {
    if (valid !== this.isValid) {
      this.isValid = valid;
      this.setSaveButton(valid);
    }
  };

  getAttributeFromField(field, attribute = 'type') {
    let ret = '';
    const obs = this.state.dataForm;
    if (obs && obs.length > 0) {
      for (let i = 0; i < obs.length; ++i) {
        if (obs[i].field === parseInt(field)) {
          if (attribute === 'type') {
            ret = obs[i].type;
            break;
          } else if (attribute === 'order') {
            ret = obs[i].order;
            break;
          }
        }
      }
    }
    return ret;
  }

  async saveObservation() {
    const { formData: initialFormData, initialValues } = this.state;

    let formData = initialFormData;
    if (Array.isArray(formData) && formData.every(item => !item)) {
      formData = initialValues;
    }

    const { codigo } = this.props.route.params;
    this.setState({ loading: true });

    const form = Object.entries(formData).map(([key, fieldData]) => {
      if (fieldData === undefined) {
        return;
      }

      let { value } = fieldData;

      if (value === undefined) {
        value = fieldData;
      }

      const fieldType = this.getAttributeFromField(key);
      if ([Types.DATE.name, Types.DATE_HOUR.name, Types.HOUR.name].includes(fieldType)) {
        if (typeof fieldData === 'string' && fieldData.trim().includes('/')) {
          const [day, month, year] = fieldData.trim().split('/');
          value = `${moment(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`).unix()}`;
        }
      }

      return {
        useCOBS: true,
        obsType: this.getAttributeFromField(key, 'order'),
        value,
      };
    }).filter(Boolean);

    try {
      await OrdersModule.saveOrderObservations(codigo, JSON.stringify(form));
      setTimeout(() => {
        createNotifySuccess(`${translate('observationAdded')}`);
      }, 100);
      this.props.navigation.goBack();
    } catch (err) {
      createNotifyError(`${translate('ErrorSavingOrderNote')}`);
    } finally {
      this.setState({ loading: false });
    }
  }


  /* async saveObservation() {
    let { formData, initialValues } = this.state;
    console.log('saveObservation formData', formData);

    // Check if formData is a array or object

    if (formData?.length !== undefined && formData.every(i => !i)) {
      formData = initialValues;
    }

    const { codigo } = this.props.route.params;
    console.log('saveObservation codigo', codigo);
    this.setState({ loading: true });

    const form = Object.keys(formData).map(key => {
      if (formData[key] === undefined) {
        return;
      }
      let { value } = formData[key];
      console.log('saveObservation value', value);
      console.log('saveObservation formData[key]', formData[key]);

      if (value === undefined) {
        value = formData[key];
      }

      if (
        this.getAttributeFromField(key).localeCompare(Types.DATE.name) === 0 ||
        this.getAttributeFromField(key).localeCompare(Types.DATE_HOUR.name) ===
          0 ||
        this.getAttributeFromField(key).localeCompare(Types.HOUR.name) === 0
      ) {
        if (formData[key].trim().includes('/')) {
          const dateFields = formData[key].trim().split('/');
          const day =
            (parseInt(dateFields[0]) < 10 ? '0' : '') + parseInt(dateFields[0]);
          const month =
            (parseInt(dateFields[1]) < 10 ? '0' : '') + parseInt(dateFields[1]);

          value = `${moment(`${dateFields[2]}-${month}-${day}`).unix()}`;
        }
      }

      const obj = {
        useCOBS: true,
        obsType: this.getAttributeFromField(key, 'order'),
        value,
      }

      console.log('obj', obj);

      return obj;
    });

    console.log('saveObservation form', form);

    try {
      await OrdersModule.saveOrderObservations(codigo, JSON.stringify(form));
      setTimeout(() => {
        createNotifySuccess(`${translate('observationAdded')}`);
      }, 100);
      this.props.navigation.goBack();
    } catch (err) {
      createNotifyError(`${translate('ErrorSavingOrderNote')}`);
    } finally {
      this.setState({ loading: false });
    }
  } */

  checkChangeBeforeBackPress = () => {
    const { oldFormData, formData = [] } = this.state;
    if (
      (oldFormData &&
        JSON.stringify(oldFormData) !== JSON.stringify(formData)) ||
      (!oldFormData && Array.isArray(formData) && formData.some(elemt => elemt))
    ) {
      showAlert(`${translate('discardChanges')}`, `${translate('discardChangesInForm')}`, () => {
        this.props.navigation.goBack();
      });
    } else {
      this.props.navigation.goBack();
    }
  };

  setLeftButton = () => {
    this.props.navigation.setOptions({
      headerLeft: () => (
        <TouchableOpacity onPress={() => {
          this.checkChangeBeforeBackPress();
        }}
        >
          <Image
            source={Images.iconBack}
            style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
          />
        </TouchableOpacity>
      ),
    });
  };

  render() {
    const { dataForm, loading, initialValues } = this.state;

    return (
      <Observations
        fieldsRules={fieldsRules}
        form={dataForm}
        initialValues={initialValues}
        isFormValid={this.isFormValid}
        getFormData={this.getFormData}
        loading={loading}
      />
    );
  }
}

ObservationsContainer.propTypes = {
    componentId: PropTypes.string.isRequired,
    codigo: PropTypes.string.isRequired,
    observations: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    observations: state.orderObservations,
  };
}

export default connect(mapStateToProps)(ObservationsContainer);
