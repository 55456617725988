import React from 'react';
import { View, Image as ImageIcon } from 'react-native';
import Touchable from 'react-native-platform-touchable';

import Image from '../../../../../components_base/src/components/Image';

import { stylesItemPhoto } from './styles';
import Images from '@assets/Images';

type Props = {
  photo: Object,
  index: number,
  useCache: boolean,
  onRemovePhoto: () => void,
};

function ItemPhoto({ photo, index, onRemovePhoto, useCache }: Props) {
  return (
    <View style={stylesItemPhoto.container}>
      <Image
        useCache={useCache}
        style={stylesItemPhoto.image}
        source={photo.base64}
        resizeMode="stretch"
        notExpand
      />
      <Touchable
        onPress={() => onRemovePhoto(index)}>
        <ImageIcon
          style={stylesItemPhoto.imageIcon}
          source={Images.iconDelete}
        />
      </Touchable>
    </View>
  );
}

export default ItemPhoto;
