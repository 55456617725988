import AsyncStorage from '@react-native-async-storage/async-storage';

export const isSignedIn = () =>
  new Promise((resolve, reject) => {
    AsyncStorage.getItem('@TOKEN_AUTH_USER')
      .then(res => {
        if (res !== null && res !== undefined && res !== '') {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch(err => reject(err));
  });

export default isSignedIn;
