import { createAsyncThunk } from '@reduxjs/toolkit';
import { applyDiscountTalao as applyDiscountTalaoPromise } from './utilsLib';

export const APPLY_DISCOUNT_TALAO_FETCH = 'APPLY_DISCOUNT_TALAO_FETCH';

export const applyDiscountTalao = createAsyncThunk(
  'ordersFetch/applyDiscountTalao',
  async payload => {
    try {
      const response = await applyDiscountTalaoPromise(payload);
      return JSON.parse(response);
    } catch (error) {
      console.log('error', error);
      return error;
    }
  },
);
