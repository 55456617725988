import React, { Component } from 'react';
import { View, ScrollView } from 'react-native';

import Item from './Item';
import Section from './Section';
import PropTypes from 'prop-types';

class List extends Component {
  static Item = Item;
  static Section = Section;
  render() {
    const { style } = this.props;
    return (
      <View>
        <ScrollView>
          <View>
            {this.props.children}
          </View>
        </ScrollView>
      </View>
    );
  }
}

List.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

export default List;
