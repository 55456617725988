/* @flow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import ClientsModule from '~/modules/ClientsModule';

export const CLIENT_CURRENT_FETCH_REQUEST = 'CLIENT_CURRENT_FETCH_REQUEST';
export const CLIENT_CURRENT_BY_ACTIVITIES_FETCH_REQUEST =
  'CLIENT_CURRENT_BY_ACTIVITIES_FETCH_REQUEST';
export const CLIENT_CURRENT_SET_CLIENT = 'CLIENT_CURRENT_SET_CLIENT';

export const CLIENT_CURRENT_FETCH_SUCCESS = 'CLIENT_CURRENT_FETCH_SUCCESS';
export const CLIENT_CURRENT_FETCH_FAILURE = 'CLIENT_CURRENT_FETCH_FAILURE';

const initialState = {
  type: '',
  loading: false,
  erro: false,
  current: {},
};

const getClient = payload =>
  new Promise((resolve, reject) => {
    ClientsModule.detailsClient(payload.pkey, resolve, reject);
  });

export const setCurrentClient = createAsyncThunk(
  'clientCurrent/setCurrentClient',
  async payload => {
    try {
      const resultGetClient = await getClient(payload);
      const clientDetail = JSON.parse(resultGetClient);
      const {
        descricao: motivo,
        dateSelected,
        processo,
        id_contexto,
      } = payload;

      let { latitude, longitude, raio } = payload;

      if (latitude && longitude && raio) {
        latitude = parseFloat(latitude);
        longitude = parseFloat(longitude);
      }

      const process =
        processo && typeof processo === 'string'
          ? JSON.parse(processo || '[]')
          : processo || [];

      return {
        ...clientDetail,
        latitude,
        longitude,
        raio,
        motivo,
        dateSelected,
        process,
        id_contexto,
      };
    } catch (error) {
      return error;
    }
  },
);

const clientCurrentSlice = createSlice({
  name: 'clientCurrent',
  initialState,
  reducers: {
    setClientCurrentByActivities: (state, action) => {
      state.type = CLIENT_CURRENT_BY_ACTIVITIES_FETCH_REQUEST;
      state.loading = true;
      state.erro = false;
    },
    setCurrentClientNoThunk: (state, action) => {
      state.type = CLIENT_CURRENT_SET_CLIENT;
      state.current = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(setCurrentClient.pending, (state, { meta }) => {
        state.type = meta.arg.fromActivities
          ? CLIENT_CURRENT_BY_ACTIVITIES_FETCH_REQUEST
          : CLIENT_CURRENT_FETCH_REQUEST;
        state.loading = true;
        state.erro = false;
      })
      .addCase(setCurrentClient.fulfilled, (state, action) => {
        state.type = CLIENT_CURRENT_FETCH_SUCCESS;
        state.loading = false;
        state.erro = false;
        state.current = action.payload;
      })
      .addCase(setCurrentClient.rejected, (state, action) => {
        state.type = CLIENT_CURRENT_FETCH_FAILURE;
        state.loading = false;
        state.erro = true;
        state.current = action.payload;
      });
  },
});

const { actions, reducer } = clientCurrentSlice;

export const {
  setClientCurrentByActivities,
  setCurrentClientNoThunk,
} = actions;

export default reducer;
