import { Alert } from 'react-native';

import HandlerActivityValidate from '../ActivityValidateHandler';
import ActivitiesCache from '../../../utils/ActivitiesCache';
import translate from '../../../locales';
import { OPENING_VISIT } from '../../../store/visit/action';

class HandlerCheck extends HandlerActivityValidate {
  canOpenVisit = async (item, listActivities, event) => {
    const { setup } = this.getTaskAndSetup(item, listActivities);
    const { action = '' } = setup;

    let name;
    let isCanOpenVisit = true;

    try {
      const data = await ActivitiesCache.getVisitStatus();
      const {
        client = '',
        status = '',
      } = data;
      ({ name } = client);

      if (status && status === OPENING_VISIT) {
        if (
          (action === 'checkin' || action === 'checkout') &&
          client.pkey !== event.pkey
        ) {
          isCanOpenVisit = false;
        }
      }
    } catch (err) {
      /* eslint-disable no-console */
      console.tron.log(err);
    }

    if (!isCanOpenVisit) {
      Alert.alert(
        `${translate('atention')}`,
        `Check-out pendente no cliente ${name}. Responda para continuar.`,
      );
      this.openTask = false;
    }
  }

  async handle(item, listActivities, originRequest, event) {
    this.canOpenVisit(item, listActivities, event);
    const { canOpenTask } = this.openTask;
    if (canOpenTask) this.next(item, listActivities, originRequest, event);
    return this.openTask;
  }
}

export default HandlerCheck;
