/* @flow */
import { combineReducers } from '@reduxjs/toolkit';
import syncFetch from './syncFetch/slice';
import configFetch from './configFetch/slice';
import configLibFetch from './configLibFetch/slice';
import clientsFetch from './clientsFetch/slice';
import makeOrderModule from './makeOrderModule/slice';
import clientCurrent from './clientCurrent/slice';
import itineraryFetch from './itineraryFetch/slice';
import orderFetch from './ordersFetch/orderFetchSlice';
import productsFetch from './productsFetch/slice';
import productsMagnitudes from './productsMagnitudes/slice';
import productsFilters from './productCurrent/slice';
import saleSuggestionProducts from './saleSuggestionProducts/slice';
import saleHistoryProducts from './saleHistoryProducts/slice';
import orderObservations from './orderObservations/slice';
import activitiesFetch from './activitiesFetch/slice';
import newClientsFetch from './newClientsFetch/newClientsFetchSlice';
import prospects from './prospectsFetch/slice';
import saveAnswer from './saveAnswer/slice';
import saveJustify from './justification/slice';
import savePhotoAnswer from './photoAnswerActivity/slice';
import addressAlternativeFetch from './addressAlternativeFetch/slice';
import notifications from './notifications/slice';
import versionUpdate from './versionUpdate/slice';
import pushingScreen from './pushingScreen/slice';
import salesOpportunityFetch from './salesOpportunityFetch/slice';
import orders from './orders/slice';
import order from './order/slice';
import scale from './scale/slice';
import productCurrent from './productCurrent/slice';
import promotionsFetch from './promotionsFetch/slice';
import currentScreenVisible from './currentScreenVisible/slice';
import listPhotos from './photoAnswerActivity/listPhotosSlice';
import productAttributes from './productAttributes/slice';
import productObservations from './productObservations/slice';
import productsCleanPrices from './productsCleanPrices/slice';
import setProductObservations from './setProductObservations/slice';
import taskCurrent from './taskCurrent/slice';
import permissionsFetch from './permission/slice';
import productsHomeFetch from './productsHome/slice';
import backRootShowModal from './backRootShowModal/slice';
import selectedTitles from './wallet/selectedTitles/slice';
import newActivitiesFetch from './newActivitiesFetch/slice';
import photosSync from './photosSync/slice';

const reducers = combineReducers({
  syncFetch,
  configFetch,
  configLibFetch,
  clientsFetch,
  makeOrderModule,
  clientCurrent,
  itineraryFetch,
  orderFetch,
  productsFetch,
  saleSuggestionProducts,
  saleHistoryProducts,
  orderObservations,
  activitiesFetch,
  newClientsFetch,
  prospects,
  saveAnswer,
  saveJustify,
  savePhotoAnswer,
  addressAlternativeFetch,
  notifications,
  versionUpdate,
  pushingScreen,
  salesOpportunityFetch,
  orders,
  order,
  productsMagnitudes,
  productsFilters,
  scale,
  promotionsFetch,
  productCurrent,
  currentScreenVisible,
  listPhotos,
  productAttributes,
  productObservations,
  productsCleanPrices,
  setProductObservations,
  taskCurrent,
  permissionsFetch,
  productsHomeFetch,
  backRootShowModal,
  selectedTitles,
  newActivitiesFetch,
  photosSync,
});

export default reducers;
