import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ProductsModule from '~/modules/ProductsModule';

export const PROMOTION_FETCH_REQUEST = 'PROMOTION_FETCH_REQUEST';
export const PROMOTION_FETCH_SUCCESS = 'PROMOTION_FETCH_SUCCESS';
export const PROMOTION_FETCH_FAILURE = 'PROMOTION_FETCH_FAILURE';

const getPromotions = () =>
  new Promise((resolve, reject) => {
    return ProductsModule.listarTodasBonificacoes(resolve, reject);
  });

export const fetchPromotions = createAsyncThunk(
  'promotionsFetch/fetch',
  async payload => {
    try {
      const promotions = await getPromotions(payload);
      return JSON.parse(promotions);
    } catch (error) {
      console.log('error', error);
      return error;
    }
  },
);

const initialState = {
  type: '',
  loading: false,
  erro: false,
  payload: [],
};

const promotionsFetchSlice = createSlice({
  name: 'promotionsFetch',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchPromotions.pending, state => {
        state.loading = true;
        state.type = PROMOTION_FETCH_REQUEST;
      })
      .addCase(fetchPromotions.fulfilled, (state, action) => {
        state.loading = false;
        state.payload = action.payload;
        state.type = PROMOTION_FETCH_SUCCESS;
      })
      .addCase(fetchPromotions.rejected, state => {
        state.loading = false;
        state.erro = true;
        state.type = PROMOTION_FETCH_FAILURE;
      });
  },
});

const { reducer } = promotionsFetchSlice;

export default reducer;
