// @flow

import React from 'react';

import NumberValue from './NumberValue';

import PropTypes from 'prop-types';

const Price = ({ value = 0, style = {} }) => (
  <NumberValue type="price" value={value} style={style} />
);

Price.propTypes = {
    value: PropTypes.string.isRequired,
    style: PropTypes.object,
};

export default Price;
