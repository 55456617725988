/* @flow */

import React, { Component } from 'react';
import { View, Text, StyleSheet, Share } from 'react-native';
import { color } from '~/common';
import Touchable from 'react-native-platform-touchable';
import MoreInfo from '../../../components_base/src/components/MoreInfo/MoreInfo';
import ButtonIconInfo from '../../components/ButtonsIcon/ButtonIconInfo';
import * as config from '../../config/config';
import _ from 'lodash';
import translate from '../../locales';
import { adjustHtml } from '~/utils/GlobalUtils';
import Html from 'react-native-render-html';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#FFF',
        paddingHorizontal: 16,
        paddingVertical: 12,
        borderBottomColor: color.grayContainer,
        borderBottomWidth: 4,
    },
    clientName: {
        color: '#000',
        fontSize: 16,
        fontWeight: 'bold',
    },
    clientCode: {
        color: '#929292',
        fontSize: 14,
    },
    installments: {
        color: '#000',
        fontSize: 12,
    },
    price: {
        color: '#000',
        fontSize: 18,
    },
    title: {
        color: color.grayMid,
        fontSize: 12,
    },
    titleBlack: {
        color: '#000',
        fontSize: 12,
    },
    date: {
        textAlign: 'right',
        fontSize: 12,
        color: color.grayMid,
    },
    strip: {
        width: 14,
        height: 24,
        position: 'absolute',
        top: 14,
        left: 14,
        bottom: 0,
        borderRadius: 10,
    },
    buttonStyle: {
        width: 28,
        height: 28,
        borderRadius: 14,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
        marginHorizontal: 0,
    },
    buttonImageStyle: {
        width: 24,
        height: 24,
        borderRadius: 12,
        tintColor: config.Colors.primaryColor,
    },
});

class TitleListItem extends Component {
    state = {
        modalMoreInfosVisible: false,
    };

    onPressShare = async () => {
        const { title } = this.props;
        const { linha_digitavel: digitableLine = '' } = JSON.parse(
            title.boleto || '{}',
        );
        const formatted = digitableLine
            .split('.')
            .join('')
            .replace(/\s/g, '');
        await Share.share({
            message: formatted,
            title: 'Compartilhar linha digitável',
        });
    };

    defaultView = () => {
        const { title, type } = this.props;
        const { linha_digitavel: digitableLine } = JSON.parse(title.data || '{}');

        return (
            <View style={styles.container}>
                <View style={{ marginLeft: 22 }}>
                    <Text style={styles.clientCode}>
                        {title.codcliente && !_.isEmpty(title.codcliente)
                            ? title.codcliente
                            : 'Sem código'}
                    </Text>
                    <View
                        style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ width: 270 }}>
                            {!!title.nome && (
                                <Text style={styles.clientName}>{title.nome}</Text>
                            )}
                            {title.numero !== '' && (
                                <Text style={styles.title}>
                                    {type === 'financing' ? 'Título' : 'Ambiental'}:
                                    <Text style={styles.titleBlack}> {title.numero}</Text>
                                </Text>
                            )}
                        </View>
                        {!!title.html && (
                            <View
                                style={{ justifyContent: 'center', alignItems: 'flex-end' }}>
                                <ButtonIconInfo
                                    onPress={() => {
                                        this.setState({ modalMoreInfosVisible: true });
                                    }}
                                />
                            </View>
                        )}
                    </View>
                    <View
                        style={{
                            marginTop: 12,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}>
                        <View>
                            {title.parcela !== '' && (
                                <Text style={styles.installments}>
                                    {`${translate('portion')}`} {title.parcela}
                                </Text>
                            )}
                            {!!title.valor && (
                                <Text style={styles.price}>
                                    {type === 'financing' && 'R$ '}
                                    {title.valor}
                                    {type === 'environmental' && ' Kg'}
                                </Text>
                            )}
                        </View>
                        <View>
                            {title.emissao !== '' && (
                                <Text style={styles.date}>
                                    {`${translate('utterance')}`} {title.emissao}
                                </Text>
                            )}
                            {title.vencimento !== '' && (
                                <Text style={styles.date}>
                                    {`${translate('dueDate')}`} {title.vencimento}
                                </Text>
                            )}
                        </View>
                    </View>
                </View>
                {!!digitableLine && (
                    <Touchable
                        onPress={_.debounce(this.onPressShare, 500)}
                        >
                        <View
                            style={{
                                width: '80%',
                                alignSelf: 'center',
                                padding: 10,
                                marginTop: 10,
                            }}>
                            <Text
                                style={{
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                }}>
                                {digitableLine}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 12,
                                    textAlign: 'center',
                                }}>
                                Pressione para compartilhar.
                            </Text>
                        </View>
                    </Touchable>
                )}
                <View style={[styles.strip, { backgroundColor: title.cor }]} />
                <MoreInfo
                    modalMoreInfosVisible={this.state.modalMoreInfosVisible}
                    htmlContent={title.html}
                    onModalClose={() => {
                        this.setState({ modalMoreInfosVisible: false });
                    }}
                />
            </View>
        );
    };

    render() {
        const { title, onCLickTitle, showItemAsHTML } = this.props;

        return (
            <Touchable
                onPress={onCLickTitle}>
                {showItemAsHTML ? (
                    <View style={[{ flexDirection: 'row' }, styles.container]}>
                        <Html
                            containerStyle={{ marginLeft: 10 }}
                            source={{ html: adjustHtml(title.html) }}
                        />
                    </View>
                ) : (
                    this.defaultView()
                )}
            </Touchable>
        );
    }
}

TitleListItem.propTypes = {
    title: PropTypes.object.isRequired,
    onCLickTitle: PropTypes.func.isRequired,
    showItemAsHTML: PropTypes.bool,
};

export default TitleListItem;
