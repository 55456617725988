// @flow

import React from 'react';
import { View, Text } from 'react-native';
import Touchable from 'react-native-platform-touchable';

import styles from './styles';
import ProductImage from '../../../../components_base/src/components/Image';
import { getSourceImageProduct } from '../../../../src/utils/ProductUtil';

import PropTypes from 'prop-types';

const ProductBonus = () => {
  const { onPress, product, paramsStoker } = props;
  const sourceProductImage = getSourceImageProduct(product, paramsStoker);

  return (
    <Touchable
      onPress={() => onPress(product)}
    >
      <View style={styles.container}>
        <View style={{ width: 100, height: 120, justifyContent: 'center' }}>
          <ProductImage source={sourceProductImage} />
        </View>
        <View style={styles.infoContainer}>
          <Text style={styles.name}>
            {product.name}
          </Text>
          <Text style={styles.startingAt}>{product.quantity} unidades</Text>
        </View>
      </View>
    </Touchable>
  );
};

ProductBonus.propTypes = {
    product: PropTypes.object.isRequired,
    paramsStoker: PropTypes.object.isRequired,
    onPress: PropTypes.func,
};

ProductBonus.defaultProps = {
  onPress: () => {},
};

export default ProductBonus;
