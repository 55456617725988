/* @flow */

import React, { Component } from 'react';
import Images from '@assets/Images';
import { connect } from 'react-redux';
import _ from 'lodash';

import Clients from '~/scenes/clients/Clients';
import {
    CLIENT_CURRENT_FETCH_REQUEST,
    CLIENT_CURRENT_FETCH_SUCCESS,
    setCurrentClient,
} from '../../store/clientCurrent/slice';
import {
    SCREEN_TRADE,
    SCREEN_ACTIVITIES,
    SCREEN_REGISTER_AGAIN,
    SCREEN_CLIENTS,
} from '../screens';
import TextUtils from '../../utils/TextUtils';
import {
    MODULE_SALE,
    setModuleCurrent,
} from '../../store/makeOrderModule/action';
import { ORDER_FETCH_SUCCESS } from '~/store/ordersFetch/orderFetchSlice';
import { SAVE_CART_FETCH_REQUEST } from '../../store/ordersFetch/saveCartSlice';
import { executeActionsMessage } from '../../utils/messages';
import translate from '../../locales';
import onPressHelper from '~/utils/onPressHelper';
import AccountsModule from '../../modules/AccountsModule';
import Toast from 'react-native-root-toast';
import RouteSolicitationsResource, {
    KIND_REQUEST_CHANGE_CUSTOMER_LOCATION,
} from '../../services/resources/RouteSolicitationsResource';

import {
    getListClients,
    CLIENTS_FETCH_SUCCESS,
} from '../../store/clientsFetch/slice';

import NavigationHelper from '../NavigationHelper';
import { CONFIG_LIB_SUCCESS } from '~/store/configLibFetch/slice';
import { currentScreenVisible } from '~/store/currentScreenVisible/slice';

import PropTypes from 'prop-types';
import { Image, TouchableOpacity } from 'react-native';

class ClientsContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showSearch: false,
            searchText: '',
        };

        this.pushingScreen = false;
        this.clients = [];
        this.clientsFiltered = [];

        this.onPressItemPressed = false;
    }

    componentDidMount() {
        this.props.navigation.setOptions({
            headerLeft: () => (null),
            headerRight: () => (
                <TouchableOpacity onPress={() => {
                    this.setState({ showSearch: !this.state.showSearch, searchText: '' });
                }}
                >
                    <Image
                        source={Images.iconSearch}
                        style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 10 }}
                    />
                </TouchableOpacity>
            ),
        });
        this.props.dispatch(getListClients());
        this.props.dispatch(
            currentScreenVisible({
                screen: this.props.componentId,
                screenName: SCREEN_CLIENTS.name,
            }),
        );
        this.renderButtonNewClients();
    }

    componentWillUnmount() {
        this.setState({ showSearch: false, searchText: '' });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        (() => {
            if (nextProps.configLib.type === this.props.configLib.type) {
                return;
            }
            if (nextProps.configLib.type !== CONFIG_LIB_SUCCESS) {
                return;
            }
            if (this.props.clients?.payload?.length === 0) {
                this.props.dispatch(getListClients());
            }

            this.renderButtonNewClients();
        })();
        (() => {
            if (nextProps.clients.type === this.props.clients.type) {
                return;
            }
            if (nextProps.clients.type !== CLIENTS_FETCH_SUCCESS) {
                return;
            }
            if (this.clients.length !== 0) {
                return;
            }
            this.clients = nextProps.clients.payload;
            this.clientsFiltered = nextProps.clients.payload;
        })();
        (() => {
            if (nextProps.client.type === this.props.client.type) {
                return;
            }
            if (this.props.client.type !== CLIENT_CURRENT_FETCH_REQUEST) {
                return;
            }
            if (nextProps.client.type !== CLIENT_CURRENT_FETCH_SUCCESS) {
                return;
            }
            const visible = this.props.navigation.isFocused();
            if (!visible) return;

            if (!this.onPressItemPressed) {
                return;
            }

            this.props.dispatch(setModuleCurrent(MODULE_SALE));

            const clientsModule = _.get(
                nextProps.configLib.payload,
                'configuracoes.modules',
                [],
            ).find(app => app.id === 'clients');
            const canDoOrder = _.get(clientsModule, 'props.can_do_order', true);
            const client = nextProps.client.current;
            this.onPressItemPressed = false;

            this.props.navigation.navigate('ClientDetail', {
                canDoOrder: canDoOrder,
                clientCurrent: client,
            });
        })();

        (() => {
            if (nextProps.order.type === this.props.order.type) {
                return;
            }
            if (this.props.order.type !== SAVE_CART_FETCH_REQUEST) {
                return;
            }
            if (nextProps.order.type === ORDER_FETCH_SUCCESS) {
                const currentScreen = this.props.currentScreenVisible.screen;
                if (currentScreen !== this.props.componentId) {
                    return;
                }
                const { payload } = nextProps.order;
                const messages = _.get(payload, 'mensagens', []);

                if (messages.length !== 0) {
                    const actionMessagesNotError =
                        this.clients.length === 0
                            ? () => this.props.dispatch(getListClients())
                            : false;

                    executeActionsMessage(messages, undefined, actionMessagesNotError);
                }
            }
        })();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.searchText !== prevState.searchText) {
            this.clientsFiltered = this.getFilteredData();
        }
    }

    navigationButtonPressed({ buttonId }) {
        if (buttonId === 'add') {
            this.onClickAdd();
        } else if (buttonId === 'search') {
            this.setState(
                { showSearch: !this.state.showSearch, searchText: '' },
                () => {
                    this.onChangeText();
                },
            );
        } else if (buttonId === 'bottomTabSelected') {
            this.setCurrentModule(SCREEN_CLIENTS.name.toUpperCase());
        }
    }

    setCurrentModule(currentModule) {
        return new Promise((resolve, reject) => {
            AccountsModule.setCurrentModule(currentModule, resolve, reject);
        });
    }

    onPressItem = item => {
        this.onPressItemPressed = true;
        const { module } = this.props;
        if (module && module === SCREEN_TRADE.name) {
            this.openProcessTrade(item);
        } else {
            this.props.dispatch(setCurrentClient(item));
        }
    };

    onPressItemHelper = onPressHelper.debounce(this.onPressItem);

    renderButtonNewClients = () => {
        const modules = _.get(
            this.props.configLib?.payload,
            'configuracoes.modules',
            [],
        );
        const clientsModule = modules.find(app => app.id === 'clients');
        this.showButtonNewRegister = _.get(
            clientsModule,
            'props.pre_register',
            true,
        );
    }

    openProcessTrade = client => {
        if (client && client.task_list_trade) {
            const event = {
                ...client,
                source: SCREEN_TRADE.name,
                task_list: client.task_list_trade,
            };
            NavigationHelper.push(
                this.props.componentId,
                SCREEN_ACTIVITIES.name,
                event.nome || 'Processo Trade',
                {
                    event,
                },
            );
        }
    };

    onClickAdd = () => {
        this.props.navigation.navigate('NewClients');
    };

    onClickAddHelper = onPressHelper.debounce(this.onClickAdd);

    handleSendRequest = () => {
        this.setState({ loading: true });
        RouteSolicitationsResource.sendSolicitation(
            this.actualClient.pkey,
            KIND_REQUEST_CHANGE_CUSTOMER_LOCATION,
            this.requestPayload,
        )
            .then(() => {
                this.successSendRequest();
                Toast.show(`${translate('successSendRequest')}`, {
                    duration: Toast.durations.LONG,
                    position: Toast.positions.BOTTOM,
                });
            })
            .catch(() => {
                this.failureSendRequest();
                Toast.show(`${translate('errorSendRequest')}`, {
                    duration: Toast.durations.LONG,
                    position: Toast.positions.BOTTOM,
                });
            })
            .finally(() => {
                this.setState({ loading: false });
                NavigationHelper.pop(this.props.componentId);
            });
    };

    onChangeCustomerLocation = (
        { payload: { location, content } },
        success,
        failure,
    ) => {
        success();
        this.successSendRequest = success;
        this.failureSendRequest = failure;
        this.requestPayload = {
            ...location,
            content,
        };
        if (!this.requestApproved) {
            this.setState({ modalVisible: true });
        }
    };

    onOpenChangeCustomerLocation = item => {
        this.actualClient = item;
        NavigationHelper.push(
            this.props.componentId,
            'activities.Location',
            `${item.nome}`,
            {
                source: 'clients',
                item: {},
                saveAnswerActivity: this.onChangeCustomerLocation,
            },
        );
    };

    onOpenRegisterAgain = client => {
        this.props.navigation.navigate(SCREEN_REGISTER_AGAIN.name, {
            client,
        });
    };

    onChangeText = text => {
        clearTimeout(this);
        setTimeout(() => {
            this.setState({ searchText: text });
        }, 400);
    };

    searchIn(list, search) {
        let res = false;
        if (list) {
            list.forEach(item => {
                if (TextUtils.slugfy(item.valor).includes(search)) {
                    res = true;
                }
            });
        }
        return res;
    }

    openProcessTrade = client => {
        if (client && client.task_list_trade) {
            const event = {
                ...client,
                source: SCREEN_TRADE.name,
                task_list: client.task_list_trade,
            };
            NavigationHelper.push(
                SCREEN_ACTIVITIES.name,
                event.nome || 'Processo Trade',
                {
                    event,
                },
            );
        }
    };

    onHideModal = () => {
        if (this.failureSendRequest) {
            this.failureSendRequest();
        }
        this.setState({ modalVisible: false });
    };

    getFilteredData() {
        const { searchText } = this.state;

        const search = TextUtils.slugfy(searchText);

        return this.clients.filter(client => {
            const res =
                (client.codigo && TextUtils.slugfy(client.codigo).includes(search)) ||
                (client.nome && TextUtils.slugfy(client.nome).includes(search)) ||
                (client.fantasia && TextUtils.slugfy(client.fantasia).includes(search));
            return res || this.searchIn(client.info_adicionais, search);
        });
    }

    render() {
        const { configLib, clients } = this.props;
        const { showSearch, modalVisible } = this.state;

        return (
            <Clients
                configLib={configLib?.payload ?? {}}
                showDates={false}
                showSearch={showSearch}
                showButtonNewRegister={this.showButtonNewRegister}
                loading={clients.loading}
                failure={clients.erro}
                onPressItem={this.onPressItemHelper}
                onPressAdd={this.onClickAddHelper}
                onChangeText={this.onChangeText}
                onOpenRegisterAgain={this.onOpenRegisterAgain}
                clients={this.clientsFiltered}
                onOpenChangeCustomerLocation={this.onOpenChangeCustomerLocation}
                modalVisible={modalVisible}
                onHideModal={this.onHideModal}
                handleSendRequest={this.handleSendRequest}
            />
        );
    }
}

ClientsContainer.propTypes = {
    configLib: PropTypes.object,
    dispatch: PropTypes.func,
    clients: PropTypes.object,
    client: PropTypes.object,
    navigator: PropTypes.object,
    module: PropTypes.string,
    order: PropTypes.object,
    componentId: PropTypes.string,
    currentScreenVisible: PropTypes.object,
};

function mapStateToProps({
                             orderFetch,
                             configLibFetch,
                             clientsFetch,
                             clientCurrent,
                             currentScreenVisible,
                         }) {
    return {
        order: orderFetch,
        configLib: configLibFetch,
        clients: clientsFetch,
        client: clientCurrent,
        currentScreenVisible,
    };
}

export default connect(mapStateToProps)(ClientsContainer);