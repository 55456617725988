import React, { Component } from 'react';
import { ActivityIndicator, View } from 'react-native';
import { FlashList } from '@shopify/flash-list';
import EmptyView from '../../components/EmptyView';
import Images from '../../../assets/Images';
import style from '../../common/style';
import ReportItem from './ReportItem';
import translate from '../../locales';

import PropTypes from 'prop-types';
import ModalOverlay from '~/components/Web/ModalOverLay/index.web';

class Reports extends Component {
  keyExtractor = item => item.pkey;

  renderItem = ({ item }) => (
    <ReportItem
      report={item}
      onGenereateReport={this.props.onGenereateReport}
    />
  );

  renderListEmptyComponent = () => (
    <EmptyView
      icon={Images.appReports}
      message={`${translate('noneReports')}`}
    />
  );
  render() {
    const { reports } = this.props;

    return (
      <View style={style.container}>
        <ModalOverlay visible={this.props.loading} closeOnTouchOutside />
        <ActivityIndicator
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          zIndex: 9999,
        }}
        animating={this.props.loading} 
        size="large" />
        <FlashList
          data={reports}
          keyExtractor={this.keyExtractor}
          renderItem={this.renderItem}
          estimatedItemSize={365}
          ListEmptyComponent={this.renderListEmptyComponent}
        />
      </View>
    );
  }
}

Reports.propTypes = {
  reports: PropTypes.array.isRequired,
  onGenereateReport: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default Reports;
