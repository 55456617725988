/* @flow */

import React, { Component } from 'react';
import {
  KeyboardAvoidingView,
  View,
  Text,
  ActivityIndicator,
  Platform,
  StyleSheet,
} from 'react-native';
import Modal from 'react-native-modal';
import { color } from '~/common';

import { width } from './measures';

import AndroidButton from './Button';
import { fonts } from '../utils/Fonts';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  modal: {
    borderRadius: Platform.OS === 'ios' ? 10 : 2,
    backgroundColor: Platform.OS === 'ios' ? 'transparent' : '#fff',
    elevation: 3,
    width: width - 48,
  },
  modalContainer: {
    backgroundColor: Platform.OS === 'ios' ? 'rgba(255,255,255,0.8)' : 'transparent',
    width: width - 48,
    marginHorizontal: 6,
    borderRadius: Platform.OS === 'ios' ? 10 : 2,
  },
  modalTitle: {
    fontSize: 18,
    color: Platform.OS === 'ios' ? '#000' : color.primaryColorLight,
    textAlign: Platform.OS === 'ios' ? 'center' : 'left',
    fontFamily: fonts.QuicksandBold,
  },
  modalTitle_danger: {
    color: color.redBadge,
    fontFamily: fonts.QuicksandBold,
  },
  modalMessage: {
    color: '#000',
    marginTop: Platform.OS === 'ios' ? 5 : 16,
    fontSize: Platform.OS === 'ios' ? 14 : 15,
    textAlign: 'center',
    marginBottom: 20,
  },
  containerButtonsAndroid: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 6,
  },
});

class CustomAlert extends Component {
  static defaultProps = {
    showModal: false,
    cancelButtonTitle: '',
    message: '',
  };

  getModalContent() {
    return (
      this.props.modalLoading ?
        <ActivityIndicator
          colors={[color.primaryColor, color.accentColor]}
          style={{ width: 80, height: 80 }}
        />
        :
        <View>
          <View style={!this.props.allContent && { padding: 16 }}>
            { this.props.title &&
            <Text style={[
              styles.modalTitle,
              this.props.danger ? styles.modalTitle_danger : {},
            ]}
            >
              {this.props.title}
            </Text>
            }
            { this.props.message !== '' && <Text style={styles.modalMessage}>{this.props.message}</Text> }
            <View>
              {this.props.children}
            </View>
          </View>
          <View style={!this.props.allContent && styles.containerButtonsAndroid}>
            {this.props.cancelButtonTitle !== ''
                &&
                <AndroidButton
                  disabled={this.props.disableButtons}
                  transparent={Platform.OS === 'ios'}
                  flat
                  title={this.props.cancelButtonTitle || 'Cancelar'}
                  onPress={() => {
                    if (this.props.cancelButtonPress) {
                      this.props.cancelButtonPress();
                    }
                  }}
                />
              }
            {
              this.props.noButtons ?
              null
              :
              <AndroidButton
                disabled={this.props.disableButtons}
                flat
                transparent={Platform.OS === 'ios'}
                title={this.props.okButtonTitle || 'OK'}
                onPress={() => {
                    if (this.props.okButtonPress) {
                      this.props.okButtonPress();
                    }
                  }}
              />
            }
          </View>
        </View>
    );
  }

  render() {
    return (
      <Modal isVisible={this.props.showModal}>
        <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : null}>
          <View style={[styles.modalContainer]}>
            <View style={styles.modal}>{this.getModalContent()}</View>
          </View>
        </KeyboardAvoidingView>
      </Modal>
    );
  }
}

CustomAlert.propTypes = {
  showModal: PropTypes.bool.isRequired,
  disableButtons: PropTypes.bool,
  cancelButtonTitle: PropTypes.string,
  okButtonTitle: PropTypes.string,
  cancelButtonPress: PropTypes.func,
  okButtonPress: PropTypes.func,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  children: PropTypes.any,
  danger: PropTypes.bool,
  noButtons: PropTypes.bool,
  allContent: PropTypes.bool,
};

export default CustomAlert;