import api from './StockerApiRequest';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';

moment.locale('pt-BR');

const getExpireDate = (expireInMinutes = 5760) => {
  // 5760 minutes = 4 days
  const now = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
  let expireTime = moment(now)
    .add(expireInMinutes, 'minutes')
    .format('YYYY-MM-DD HH:mm:ss');
  return expireTime;
};

const validateCache = async item => {
  const now = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
  const expireTime = moment(new Date(item.expires)).format(
    'YYYY-MM-DD HH:mm:ss',
  );
  if (now > expireTime) {
    await AsyncStorage.removeItem(item.key);
    return false;
  }
  return true;
};

const setImageProdInCache = async (product, urlImageProd) => {
  const { ean } = product;
  const key = `imageProd_${ean}`;
  const value = urlImageProd;
  const expires = getExpireDate();
  const urls = [];
  
  if (value && value.length > 0) {
    value.map(item => {
      if (item && item.files && item.files.length > 0) {
        urls.push(item.files[0].original_url);
      }
    });
  }
  
  const item = {
    uri: urls,
    expires: expires,
  };

  try {
    await AsyncStorage.setItem(key, JSON.stringify(item));
  } catch (error) {
    console.log('ERROR SET IMAGE IN CACHE: ', error);
  }
};

const getRemoteImage = async (product, config) => {
  api.defaults.headers.common.Authorization = `Bearer ${config.token_stocker}`;
  api.defaults.headers.post['Content-Type'] = 'application/json';
  let res = null;
  try {
    res = await api.get(`https://stocker.isilist.com.br/api/v2/product_info/`, {
      params: {
        eans: product.ean,
        include_company_groups: 1,
        include_variants: 0,
      },
    });
  } catch (error) {
    // console.log('ERROR GET IMAGE: ', error);
  }
  
  // Salva no cache independentemente do resultado (sucesso ou falha)
  await setImageProdInCache(product, res && res.data.length > 0 ? res.data : null);
  
  return res && res.data.length > 0 ? res.data[0].files : null;
};

const getImageProd = async (product, config) => {
  if (
    config &&
    config.url_stocker &&
    config.token_stocker &&
    product.ean &&
    product.ean !== '0'
  ) {
    const getUrlInCache = await AsyncStorage.getItem(
      `imageProd_${product.ean}`,
    );
    if (getUrlInCache !== null) {
      const item = JSON.parse(getUrlInCache);
      const isValid = await validateCache(item);
      if (isValid) {
        return item.uri;
      } else {
        return getRemoteImage(product, config);
      }
    } else {
      return getRemoteImage(product, config);
    }
  }
};

export default getImageProd;
