import React, { Component } from 'react';
import { Image, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import _ from 'lodash';

import { style } from '~/common';
import NavigationHelper from '~/screens/NavigationHelper';
import Search from '../../components/Search';
import { filterByTextFields } from '../Filter/components/filters';
import {
    SCREEN_COMBOS,
    SCREEN_PROMOTIONS,
    SCREEN_REWARDS,
    SCREEN_PRODUCT_TABS,
} from '~/screens/screens';

import PropTypes from 'prop-types';

import Images from '@assets/Images';

const setFilters = (
    fetchFilters,
    fetchReducer,
    successType,
    propsMapper,
) => WrappedComponent => {

    const rightButtons = [];

    class HighOrderFilter extends Component {
        constructor(props) {
            super(props);
            this.state = {
                showSearch: false,
                search: '',
                searchFields: [],
                filteredList: [],
            };

            this.onChangeText = _.debounce(this.textChange, 500);
            this.open = false;
        }

        componentDidMount() {
            this.props.dispatch(fetchFilters());
        }

        UNSAFE_componentWillReceiveProps({ filters }) {
            (() => {
                if (filters.type === this.props.filters.type) {
                    return;
                }
                if (filters.type !== successType) {
                    return;
                }
                this.setFilterButton();
            })();
        }

        componentWillUnmount() {
            if (this.navigatorEventReturn) {
                this.navigatorEventReturn();
            }
        }

        onListFiltered = list => {
            this.setState({ filteredList: list });
            this.setFilteredListToWrapped(list);
        };

        setNewButtons = button => {
            this.setFilterButton(button);
        };

        setList = (list, showProductAsHTML, comboSearchFields) => {
            this.setSearchFields(list, showProductAsHTML, comboSearchFields);
            this.setState({ filteredList: list });
        };

        setFilterButton(newButton) {
            if (newButton && !this.buttonAlreadyExists(newButton.id)) {
                rightButtons.unshift(newButton);
            }

            this.setButtons();
        }

        setSearchFields(list, showProductAsHTML, comboSearchFields) {
            let searchFields = '';
            if (showProductAsHTML) {
                searchFields = ['html'];
            } else {
                searchFields = list && list.length ? Object.keys(list[0]) : [];
                if (_.isArray(comboSearchFields) && comboSearchFields.length) {
                    searchFields = _.concat(searchFields, comboSearchFields);
                }
            }

            this.setState({ searchFields });
        }

        setButtons() {
            /* this.props.navigation.setOptions({
                headerRight: () => (
                  <View style={{ flexDirection: 'row' }}>
                    <View>
                      <TouchableOpacity onPress={() => {
                      this.setState({ showSearch: !this.state.showSearch, search: '' }, () => this.filterBySearchFields());
                    }}
                      >
                        <Image
                          source={Images.iconSearch}
                          style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 20 }}
                        />
                      </TouchableOpacity>
                    </View>
                    <View>
                      <TouchableOpacity onPress={() => {
                    this.props.navigation.navigate('ProductTabs');
                  }}
                      >
                        <Image
                          source={Images.iconBulletedList}
                          style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 10 }}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                ),
              }); */
        }

        setFilteredListToWrapped(list, search) {
            if (this.wrapped && this.wrapped.onListFiltered) {
                this.wrapped.onListFiltered(list, search);
            }
        }

        textChange = search => {
            this.setState({ search }, () => this.filterBySearchFields());
        };

        buttonAlreadyExists(id) {
            let exists = false;
            rightButtons.forEach(button => {
                if (id === button.id) {
                    exists = true;
                }
            });
            return exists;
        }

        saveValues = () => {
            this.open = false;
        };

        filterBySearchFields() {
            const { search } = this.state;
            const { filteredList } = this.state;
            const { searchFields } = this.state;
            let filtered = filteredList;
            if (searchFields && searchFields.length) {
                filtered = filterByTextFields(filteredList, searchFields, search);
            }
            this.setFilteredListToWrapped(filtered, search);
        }

        // showFilter() {
        //   if (!this.state.list && !this.state.list.length) return;
        //   this.navigator.showModal({
        //     screen: 'Filter',
        //     title: 'Filtros',
        //     passProps: {
        //       list: this.state.list,
        //       config: this.props.filters.payload,
        //       onListFiltered: this.onListFiltered,
        //       saveValues: this.saveValues,
        //       filtersValues: this.state.filtersValues,
        //     },
        //   });
        // }

        tryOpen(id) {
            if (!this.open) {
                switch (id) {
                    case 'showPromotions':
                        NavigationHelper.push(
                            this.props.componentId,
                            SCREEN_PROMOTIONS.name,
                            SCREEN_PROMOTIONS.title,
                            null,
                            {
                                overrideBackPress: true,
                            },
                        );

                        break;

                    case 'showCombos':
                        NavigationHelper.push(
                            this.props.componentId,
                            SCREEN_COMBOS.name,
                            SCREEN_COMBOS.title,
                            {
                                comboType: 'combos',
                            },
                        );
                        break;

                    case 'productTabs':
                        NavigationHelper.push(
                            this.props.componentId,
                            SCREEN_PRODUCT_TABS.name,
                            SCREEN_PRODUCT_TABS.title,
                            null,
                            {
                                overrideBackPress: true,
                            },
                        );

                        break;

                    case 'showCombosPedido':
                        NavigationHelper.push(
                            this.props.componentId,
                            SCREEN_COMBOS.name,
                            SCREEN_COMBOS.title,
                            {
                                comboType: 'combos_pedido',
                            },
                        );
                        break;

                    case 'showRewards':
                        NavigationHelper.push(
                            this.props.componentId,
                            SCREEN_REWARDS.name,
                            SCREEN_REWARDS.title,
                        );
                        break;

                    // case 'filter':
                    //   this.showFilter();
                    //   break;
                    default:
                        break;
                }
                this.open = true;
            }
        }
        componentDidAppear() {
            this.open = false;
        }

        navigationButtonPressed = event => {
            switch (event.buttonId) {
                case 'showPromotions':
                case 'showCombos':
                case 'showCombosPedido':
                case 'productTabs':
                    this.tryOpen(event.buttonId);
                    break;
                // case 'filter':
                //   this.tryOpen(event.id);
                //   break;
                case 'showRewards':
                    this.tryOpen(event.buttonId);
                    break;
                case 'search':
                    this.setState(
                        { showSearch: !this.state.showSearch, search: '' },
                        () => this.filterBySearchFields(),
                    );
                    break;

                case 'bottomTabSelected':
                    if (event.selectedTabIndex === 0) {
                        NavigationHelper.popToRoot(this.props.componentId);
                    }
                    break;
                default:
                    break;
            }
        };

        render() {
            const { showSearch } = this.state;

            return (
                <View style={style.container}>
                    {showSearch ? <Search onChangeText={this.onChangeText} /> : null}
                    <WrappedComponent
                        ref={ref => {
                            this.wrapped = ref;
                        }}
                        setList={this.setList}
                        setNewButtons={this.setNewButtons}
                        {...this.props}
                    />
                </View>
            );
        }
    }

    HighOrderFilter.propTypes = {
        componentId: PropTypes.string,
        filters: PropTypes.object,
        dispatch: PropTypes.func,
    };

    function mapStateToProps(state) {
        return {
            filters: state[fetchReducer],
        };
    }

    return connect(mapStateToProps)(HighOrderFilter);
};

export default setFilters;
