import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ClientsModule from '../../modules/ClientsModule';
import moment from 'moment';
import { getListWithPagination } from '~/utils/FastSellerUtils';
import getMarkerColor from '~/screens/Today/components/utils/getMarkerColor';
import CacheDataController from '~/utils/CacheDataController';

export const ITINERARY_REQUEST = 'ITINERARY_REQUEST';
export const ITINERARY_SUCCESS = 'ITINERARY_SUCCESS';
export const ITINERARY_FAILURE = 'ITINERARY_FAILURE';
export const CACHE_KEY = '@LIST_ITINERARY';

export const fetchItinerary = async (date, state) => {
  const dateUnix = date ? `${moment(date).unix()}` : '';
  const { tam_memoria_paginacao = 0 } = state.configLibFetch?.payload;

  if (!state.configLibFetch?.payload?.tam_memoria_paginacao) {
    throw new Error('tam_memoria_paginacao must be specified');
  }
  let finalData = await CacheDataController.getDataWithExpiration(
    `${CACHE_KEY}_${dateUnix}`,
  );

  if (!finalData) {
    const scheduling = await getListWithPagination(
      ClientsModule.getScheduleListing,
      tam_memoria_paginacao,
      dateUnix,
    );
    const extraData = await setProcessDone({
      scheduling,
      date: date,
    });

    finalData = formatEvents(extraData);
    if (finalData && finalData.length > 0) {
      const timestamp = 7200; // 2 hours
      CacheDataController.saveDataWithExpiration(
        `${CACHE_KEY}_${dateUnix}`,
        finalData,
        timestamp,
      );
    }
  }

  return finalData;
};

export const getListItinerary = createAsyncThunk(
  'itineraryFetch/getListItinerary',
  async (date, { getState }) => {
    return await fetchItinerary(date, getState());
  },
);
function formatEvents(scheduling) {
  try {
    const data = scheduling.map((cli, index) => {
      const {
        raio,
        horario_atendimento: attendanceTime,
        tempo_visitacao: visitTime,
      } = cli;

      let { latitude, longitude } = cli;
      const processResp = cli.processo || '[]';

      const process =
        processResp && typeof processResp === 'string'
          ? JSON.parse(processResp)
          : [];
      const nextEvent = scheduling[index + 1];
      const nextAttendanceTime =
        nextEvent && nextEvent.horario_atendimento
          ? moment(nextEvent.horario_atendimento, 'HH:mm')
          : null;
      const currentClientTime =
        attendanceTime && visitTime
          ? moment(attendanceTime, 'HH:mm').add({ m: visitTime })
          : null;
      const diffTime = nextAttendanceTime
        ? nextAttendanceTime.diff(currentClientTime, 'minutes')
        : null;

      latitude = parseFloat(latitude);
      longitude = parseFloat(longitude);

      const markerColor = getMarkerColor(cli);

      return {
        ...cli,
        index,
        raio,
        latitude,
        longitude,
        source: 'client',
        process,
        displacementTime: diffTime > 0 ? diffTime : null,
        attendanceTime: !cli.fora_de_rota && attendanceTime,
        visitTime,
        markerColor,
      };
    });

    return data;
  } catch (e) {
    return [];
  }
}

const setProcessDone = ({ scheduling, date: dateSelected }) =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await Promise.all(
        scheduling.map(async (cli, index) => {
          const {
            processo = '[]',
            id_processo: processId,
            pkey,
            id_contexto: contextId,
          } = cli;

          const processoParsed = JSON.parse(processo);
          const processes =
            processoParsed.length > 0 ? processoParsed : [{ id: processId }];

          const params = processes.map(({ id }) => ({
            id_parent: 0,
            date: dateSelected,
            id_context: contextId,
            id_process: id,
            key_context: pkey,
          }));
          const jsonToParse = await ClientsModule.getStatusAllProcesses(
            JSON.stringify(params),
          );
          const statusProcesses = JSON.parse(jsonToParse);
          const { all_processes_done: isProcessesDone } = statusProcesses;

          return {
            ...cli,
            isProcessesDone,
          };
        }),
      );

      const finalData = formatEvents(data);
      resolve(finalData);
    } catch (err) {
      reject([]);
    }
  });

const initialState = {
  type: '',
  loading: true,
  erro: false,
  payload: [],
  selectedDate: '',
};

const itineraryFetch = createSlice({
  name: 'itineraryFetch',
  initialState,
  reducers: {
    resetItineraryFetch: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(getListItinerary.pending, (state, { meta }) => {
      const date = meta.arg;
      state.type = ITINERARY_REQUEST;
      state.loading = true;
      state.selectedDate = date;
    });
    builder.addCase(getListItinerary.fulfilled, (state, action) => {
      state.type = ITINERARY_SUCCESS;
      state.loading = false;
      state.erro = false;
      state.payload = action.payload;
    });
    builder.addCase(getListItinerary.rejected, (state, action) => {
      state.type = ITINERARY_FAILURE;
      state.loading = false;
      state.erro = true;
      state.payload = action.payload;
    });
  },
});

const { reducer } = itineraryFetch;
export const { resetItineraryFetch } = itineraryFetch.actions;

export default reducer;
