import React, { Component } from 'react';
import {
  Animated,
  Image,
  Modal,
  StyleSheet,
  Text,
  View,
  Dimensions,
  ScrollView,
  ImageBackground,
  PixelRatio,
  ActivityIndicator,
} from 'react-native';
import Touchable from 'react-native-platform-touchable';

import Images from '@assets/Images';
import { color, style } from '~/common';
import HomeTopBar from './components/HomeTopBar';
import App from './components/App';
import { CustomAlert } from '~/components';
import { getVersionApp } from '../../utils/deviceAppInfo';
import { fonts } from '../../utils/Fonts';
import HomeModulesPlaceholder from './components/HomeModulesPlaceholder';
import EmptyView from '../../components/EmptyView';
import Spinner from 'react-native-loading-spinner-overlay';
import translate from '../../locales';
import BannerImage from '~/components/Banners/components/BannerImage';
import _ from 'lodash';
import { FloatingAction } from 'react-native-floating-action';
import BottomAlert from '../../components/BottomAlert';
import { CustomText } from '~/components/Typography';
import { FlashList } from '@shopify/flash-list';

const { width: viewportWidth, height: viewportHeight } = Dimensions.get(
  'window',
);

import OrderPending from '~/components/OrderPending/OrderPending';
import PhotoPending from '~/components/PhotoSync/PhotoPending';

import PropTypes from 'prop-types';
import ModalOverlay from '~/components/Web/ModalOverLay/index.web';
import CircularProgressBar from '~/components/Web/CircularProgressBar';
import Tip from './components/Tip.web';

const pixelRatioFontScale = PixelRatio.getFontScale();

const styles = StyleSheet.create({
  Logo__Container: {
    backgroundColor: color.primaryColor,
  },
  greetingContainer: {
    marginLeft: 16,
    marginRight: 16,
    marginTop: 16,
    marginBottom: 16,
  },
  greetingHello: {
    color: '#fff',
    fontSize: 20,
  },
  greetingName: {
    color: '#fff',
    fontSize: 20,
    fontFamily: fonts.QuicksandBold,
  },
  buttonHideProgress: {
    fontWeight: '500',
    fontSize: 14,
    color: '#20B2AA',
  },
  appHighLight: {
    flexDirection: 'row',
    alignItems: 'center',
    width: viewportWidth - 10,
    borderRadius: 10,
    padding: 10,
    backgroundColor: color.primaryColorLight,
  },
  imageHighLight: {
    width: 25,
    height: 25,
    resizeMode: 'contain',
    tintColor: '#fff',
  },
  title: {
    fontSize: 12,
    color: '#fff',
    paddingLeft: 5,
    paddingRight: 5,
  },
  signal: {
    borderRadius: 50,
    backgroundColor: color.accentColor,
    width: 11,
    height: 11,
  },
  midContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalContainerCancelOrder: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    flex: 1,
    top: 0,
    bottom: 0,
    position: 'absolute',
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: 'white',
    height: pixelRatioFontScale > 1.2 ? 300 : 220,
    width: '90%',
    borderRadius: 8,
    padding: 20,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#1A1A1A',
    fontFamily: fonts.Quicksand,
  },
  modalText: {
    fontSize: 16,
    color: '#1A1A1A',
    fontFamily: fonts.Quicksand,
    paddingTop: 10,
    paddingBottom: 5,
    fontWeight: '600',
  },
  titleModalContent: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#1A1A1A',
    fontFamily: fonts.Quicksand,
  },
  containerBtns: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    top: 38,
    paddingBottom: 20,
    gap: 8,
  },
  textBtns: {
    fontSize: pixelRatioFontScale > 1.2 ? 16 : 18,
    color: color.primaryColor,
  },
  containerBtnLeft: {
    paddingTop: 0,
    paddingRight: 8,
    paddingBottom: 1,
    paddingLeft: 8,
  },
  containerBtnRight: {
    paddingTop: 0,
    paddingRight: 8,
    paddingBottom: 1,
    paddingLeft: 8,
  },
});

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearch: new Animated.Value(0),
      modalCancelOrder: false,
      alertSync: false,
    };
    this.buttonSyncPress = _.debounce(this.props.onPressSyncPhotos, 1000);
    this.buttonClearPhotosPress = _.debounce(
      this.props.onPressClearPhotos,
      1000,
    );
  }

  onPressProfile = () => {
    this.props.onPressProfile();
  };

  onPressSearch = () => {
    this.showSearch(true);
  };

  getVersion() {
    return getVersionApp();
  }

  getProgress() {
    const { progress } = this.props;
    const text = this.props.textProgress;

    return (
      <View>
        <ModalOverlay visible={this.props.downloadBase} closeOnTouchOutside>
        <CircularProgressBar progress={progress} color={color.primaryColor} />
        </ModalOverlay>
      </View>
    );
  }

  keyExtractor = (item, index) => `${index}`;

  showSearch(show) {
    if (show) {
      this.setState({ show }, () => {
        Animated.timing(this.state.showSearch, {
          toValue: show ? 1 : 0,
          duration: 200,
          useNativeDriver: true,
        }).start();
      });
    } else {
      Animated.timing(this.state.showSearch, {
        toValue: show ? 1 : 0,
        duration: 200,
        useNativeDriver: true,
      }).start(() => {
        this.setState({ show });
      });
    }
  }

  showAccounts = () => {
    this.props.pressShowAccounts();
  };

  cancelChooseAccount = () => {
    this.props.cancelChooseAccounts();
  };

  chooseAccount = account => {
    this.props.chooseAccount(account);
  };

  renderApp = (item, index) => (
    <App props={item} onPress={this.props.onPressApp} showShortcuts={this.props.showShortcuts}/>
  );

  renderMainContent = () => {
    const { syncFinally, appModules } = this.props;
    if (syncFinally && appModules?.length === 0) {
      return (
        <EmptyView
          icon={Images.iconBoxEmpty}
          message={`${translate('youDontHaveModules')}`}
        />
      );
    }
    return <HomeModulesPlaceholder />;
  };

  renderTopBarByBanner = () => {
    const {
      availableUpdate: { hasAvailableUpdate },
      firstName = '',
      onPressUpdateVersion,
      notifications,
      onPressNotifications,
    } = this.props;
    return (
      <View style={{ position: 'relative' }}>
        <BannerImage
          image={{ uri: this.props.bannerUrl }}
          fullWidth
          resizeMode="stretch"
        />

        <Touchable
          style={{
            marginLeft: 10,
            position: 'absolute',
            right: 10,
            top: 10,
          }}
          onPress={onPressNotifications}>
          <ImageBackground
            source={Images.iconBell}
            style={{ width: 27, height: 27 }}
            imageStyle={{ tintColor: '#fff' }}>
            {notifications &&
              notifications.filter(({ read }) => !read).length > 0 && (
                <Text style={styles.signal} />
              )}
          </ImageBackground>
        </Touchable>
        {firstName !== '' && (
          <View style={styles.midContent}>
            <View style={[styles.greetingContainer, { flexDirection: 'row' }]}>
              <Text
                style={[
                  styles.greetingHello,
                  { color: color.primaryColorLight },
                ]}>
                {`${translate('hello')}`},{' '}
                <Text
                  style={[
                    styles.greetingName,
                    { color: color.primaryColorLight },
                  ]}>
                  {firstName}.
                </Text>
              </Text>
            </View>
            {hasAvailableUpdate && (
              <View style={{ marginRight: 15 }}>
                <Touchable onPress={onPressUpdateVersion}>
                  <ImageBackground
                    source={Images.iconDownloadVersion}
                    style={{ width: 27, height: 27 }}
                    imageStyle={{ tintColor: color.primaryColorLight }}>
                    <Text style={styles.signal} />
                  </ImageBackground>
                </Touchable>
              </View>
            )}
          </View>
        )}
      </View>
    );
  };

  renderTopBar = () => {
    const {
      config: { logo, display_name: displayName },
      availableUpdate,
      firstName,
      onPressUpdateVersion,
      notifications,
      onPressNotifications,
    } = this.props;

    return (
      <View>
        <HomeTopBar
          companyLogo={logo}
          companyName={displayName}
          availableUpdate={availableUpdate}
          onPressUpdateVersion={onPressUpdateVersion}
          notifications={notifications}
          onPressNotifications={onPressNotifications}
        />
        <View style={styles.Logo__Container}>
          <View style={[styles.greetingContainer]}>
            <Text style={styles.greetingHello}>{`${translate('hello')}`}</Text>
            <CustomText
              type="titleHeaderH4"
              colorTheme="lightLetter"
              title={firstName}
            />
          </View>
        </View>
      </View>
    );
  };

  cancelOrderPending = () => {
    this.props.onPressCancelPendingOrder;
    this.setState({ modalCancelOrder: true });
  };

  cancelActiveOrder = () => {
    try {
      this.props.onPressCancelPendingOrder();
      this.setState({ modalCancelOrder: false });
    } catch (error) {
      console.log('error', error);
    }
  };

  onPressEditPendingOrder = () => {
    if (this.props.visibleAlertSync) {
      this.setState({ alertSync: true });
    } else {
      this.props.onPressEditPendingOrder();
    }
  };

  closeModalCancelOrder = () => {
    this.setState({ modalCancelOrder: false });
  };

  dismissModalSync = () => {
    this.setState({ alertSync: false });
  };

  render() {
    const { alertSync } = this.state;
    const {
      downloadBase,
      title,
      appModules,
      sizeAvailable,
      showModalEdi,
      onChangeShowModalEdi,
      filename,
      onSendFileEdi,
      showFechamento,
      showBanner,
      bannerUrl,
      notSynchronizedPhotosSize,
      notSynchronizedPhotosList,
      hasOrderPending,
      modalVisible,
      homeActions,
      onPressFloatingAction,
      handleSendRequest,
      onHideModal,
      requesting,
      showShortcuts,
    } = this.props;

    const handleShowBanner = () => {
      if (showBanner && bannerUrl !== '') {
        return this.renderTopBarByBanner()
      }
      return this.renderTopBar()
    }
    const photoPendingHandler = () => {
      if (notSynchronizedPhotosSize > 0 ) {
        return (
          <PhotoPending
            onPressSendNow={this.buttonSyncPress}
            pendingPhotosList={notSynchronizedPhotosList}
          />
        )
      }
    }
    const showFechamentoHandler = () => {
      if (showFechamento) {
        return (
          <Touchable
            onPress={() => {
              this.props.onPressFechamento();
            }}>
            <View>
              <View>
                <View style={styles.appHighLight}>
                  <Image
                    style={styles.imageHighLight}
                    source={Images.iconInfo}
                  />
                  <Text style={styles.title}>
                    {`${translate('doDayClosure')}`}
                  </Text>
                </View>
              </View>
            </View>
          </Touchable>
        )
      }
    }
    const appModulesHandler = () => {
      if (Array.isArray(appModules) && appModules.length === 0) {
        return this.renderMainContent()
      }
      return (
        <View
          style={{
            width: Dimensions.get('screen').width,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: 16,
            paddingRight: 8,
            bottom: hasOrderPending ? 60 : 0,
          }}>
          <FlashList
            extraData={showShortcuts}
            keyExtractor={this.keyExtractor}
            renderItem={this.renderApp}
            data={appModules}
            numColumns={4}
            estimatedItemSize={101}
          />
        </View>
      )
    }
    const orderPendingHandler = () => {
      if (hasOrderPending) {
        return (
          <OrderPending
          onPressEditPendingOrder={this.onPressEditPendingOrder}
          onPressCancelPendingOrder={this.cancelOrderPending}
          totalOrderPending={this.props.totalOrderPending}
          clientOrderPending={this.props.clientOrderPending}
        />
        )
      }
    }
    const sizeAvailableHandler = () => {
      if (sizeAvailable && sizeAvailable > 0) {
        return (
          <Touchable
            onPress={this.buttonClearPhotosPress}
            style={{ marginTop: 5 }}>
            <View style={styles.appHighLight}>
              <Image
                style={styles.imageHighLight}
                source={Images.iconInfo}
              />
              <Text style={styles.title}>
                {`${translate(
                  'deleteSyncronizedPhotoToFreeSpace',
                )} ${sizeAvailable}MB.`}
              </Text>
            </View>
          </Touchable>
        )
      }
    }
    const downloadBaseHandler = () => {
      if (downloadBase) {
        return (
          <View>
            {this.getProgress()}
          </View>
        )
      }
    }
    const alertSyncHandler = () => {
      if (!downloadBase && alertSync) {
        return (
          <CustomAlert
            showModal
            title={`${translate('dataUpdate')}`}
            okButtonPress={this.dismissModalSync}>
            <Text style={[styles.orderClient, { marginTop: 16 }]}>
              {`${translate('weAreUpdatingYourData')}`}
            </Text>
            <Text>ProgressBar WEB AQUI</Text>
          </CustomAlert>
        )
      }
    }
    const homeActionsHandler = () => {
      if (homeActions && homeActions.filter(({ visible }) => Boolean(visible)).length > 0 ) {
        return (
          <FloatingAction
            visible={appModules && appModules.length > 0}
            showBackground={false}
            floatingIcon={
              <Image
                source={Images.iconEditProperty}
                style={{ tintColor: '#fff' }}
              />
            }
            actions={homeActions
              .filter(({ visible }) => Boolean(visible))
              .map(action => ({
                ...action,
                icon: (
                  <Image
                    source={Images[action.icon]}
                    style={{ tintColor: '#fff', width: 22, height: 22 }}
                  />
                ),
              }))}
            onPressItem={onPressFloatingAction}
          />
        )
      }
    }

    return (
      <View style={{ flex: 1 }}>
        <View style={styles.modalContainerCancelOrder}>
          <Modal
            style={{ margin: 0 }}
            animationType="slide"
            visible={this.state.modalCancelOrder}
            onRequestClose={this.closeModalCancelOrder}>
            <View style={styles.modalContainer}>
              <View style={styles.modalContent}>
                <Text style={styles.titleModalContent}>{translate('deleteOrder')}</Text>
                <View>
                  <Text style={styles.modalText}>{translate('doYouWantDeleteOrder')}</Text>
                  </View>
                <View>
                  <Text style={styles.modalText}>{translate('onceConfirmAction')}</Text>
                </View>
                  <View style={styles.containerBtns}>
                  <Touchable
                    onPress={this.closeModalCancelOrder}
                    >
                    <View style={styles.containerBtnLeft}>
                      <Text style={styles.textBtns}>
                        {translate('decideLater')}
                      </Text>
                    </View>
                  </Touchable>
                  <Touchable
                    onPress={this.cancelActiveOrder}
                    style={{ marginLeft: 8 }}
                    >
                    <View style={styles.containerBtnRight}>
                      <Text style={styles.textBtns}>
                        {translate('yesDeletePendingOrder')}
                      </Text>
                    </View>
                  </Touchable>
                </View>
              </View>
            </View>
          </Modal>
          </View>
        <ScrollView style={style.container}>
          <Spinner
            visible={requesting}
            overlayColor="rgba(0, 0, 0, 0.7)"
            textStyle={{ color: '#FFF' }}
          />
          {handleShowBanner()}
          <View style={{ alignItems: 'center', margin: 10 }}>
            {showFechamentoHandler()}
            {sizeAvailableHandler()}
            {orderPendingHandler()}
            {photoPendingHandler()}
          </View>
          {
            !Array.isArray(appModules) || (appModules && appModules.length === 0) ? this.renderMainContent() : (
              <FlashList
              keyExtractor={this.keyExtractor}
              renderItem={this.renderApp}
              data={appModules}
              numColumns={4}
              estimatedItemSize={101}
            />
            )
          }
          {downloadBaseHandler()}
          {alertSyncHandler()}
          <BottomAlert
            setRef={ref => {
              this.bottomAlert = ref;
            }}
            title={`${translate('atention')}`}
            description={`${translate('creatingStartingPoint')}.\n${translate(
              'wantToSubmit',
            )}`}
            mainButtonTitle={`${translate('send')}`}
            secondaryButtonTitle={`${translate('cancel')}`}
            hasSecondaryButton
            onPressSecondaryButton={() => this.bottomAlert.close()}
            onPressMainButton={() => {
              this.bottomAlert.close();
              handleSendRequest();
            }}
            visible={modalVisible}
            onClose={onHideModal}
          />
        </ScrollView>
        {homeActionsHandler()}
        <View style={{ bottom: 10 }}>
          <Tip/>
        </View>
      </View>
    );
  }
}

Home.propTypes = {
  onPressApp: PropTypes.func.isRequired,
  onPressProfile: PropTypes.func,
  onPressFechamento: PropTypes.func.isRequired,
  onPressFloatingAction: PropTypes.func.isRequired,
  onPressSyncPhotos: PropTypes.func.isRequired,
  onPressClearPhotos: PropTypes.func.isRequired,
  onPressNotifications: PropTypes.func.isRequired,
  onPressCancelPendingOrder: PropTypes.func.isRequired,
  onPressEditPendingOrder: PropTypes.func.isRequired,
  onHideModal: PropTypes.func.isRequired,
  handleSendRequest: PropTypes.func.isRequired,
  showModalEdi: PropTypes.bool.isRequired,
  onChangeShowModalEdi: PropTypes.func.isRequired,
  onSendFileEdi: PropTypes.func.isRequired,
  showFechamento: PropTypes.bool.isRequired,
  requesting: PropTypes.bool.isRequired,
  hasOrderPending: PropTypes.bool.isRequired,
  visibleAlertSync: PropTypes.bool.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  syncFinally: PropTypes.bool.isRequired,
  downloadBase: PropTypes.bool.isRequired,
  showBanner: PropTypes.bool.isRequired,
  notSynchronizedPhotosSize: PropTypes.number.isRequired,
  notSynchronizedPhotosList: PropTypes.array.isRequired,
  homeActions: PropTypes.array.isRequired,
  availableUpdate: PropTypes.object.isRequired,
  firstName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  bannerUrl: PropTypes.string.isRequired,
  sizeAvailable: PropTypes.number.isRequired,
  textProgress: PropTypes.string.isRequired,
  appModules: PropTypes.array.isRequired,
  notifications: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired,
  progress: PropTypes.number.isRequired,
  progressDownload: PropTypes.number.isRequired,
  totalOrderPending: PropTypes.object.isRequired,
  clientOrderPending: PropTypes.object.isRequired,
  showShortcuts: PropTypes.bool,
  shortCuts: PropTypes.array,
};

export default Home;
