import UserTaskListRepository from '../database/repository/UserTaskListRepository';
import BaseController from './BaseController';

export default class UserTaskListController extends BaseController {
  constructor() {
    super();
    this.repository = new UserTaskListRepository();
  }

  async getByTaskListAndEventTask(taskListId, pkey, source, date) {
    return this.repository.getByTaskListAndEventTask(taskListId, pkey, source, date);
  }

  updateUserTaskListStatusTempIdToReservedId(tempId, reservedId) {
    return this.repository.updateUserTaskListStatusTempIdToReservedId(tempId, reservedId);
  }
}
