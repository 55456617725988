import React from 'react';
import { Text } from 'react-native';
import { RadioGroup, RadioButton } from '../../components/Web/RadioGroupData';
import PropTypes from 'prop-types';

import styles from './styles';

function RadioGroupData({
    valueSelected,
    itens,
    onSelectItem,
    disabled,
}) {
    const onSelectItemRadio = (index, value) => {
        onSelectItem(value);
    };

    const selectedIndex = itens && itens.findIndex(item => item.value === valueSelected);

    return (
        <RadioGroup
            style={itens?.length <= 3 && styles.container}
            selectedIndex={selectedIndex}
            onSelect={onSelectItemRadio}
            thickness={2}>
            {itens?.map(item => (
                <RadioButton key={item.value} value={item.value} disabled={disabled}>
                    <Text>{item.label}</Text>
                </RadioButton>
            ))}
        </RadioGroup>
    );
}

RadioGroupData.propTypes = {
    valueSelected: PropTypes.any,
    itens: PropTypes.array,
    onSelectItem: PropTypes.func,
    disabled: PropTypes.bool,
};

export default RadioGroupData;
