/* @flow */

import React from 'react';
import find from 'lodash/find';
import get from 'lodash/get';
import { Text, View, StyleSheet, Image } from 'react-native';
import { style, color } from '~/common';
import Quantity from '../components/Quantity';
import { MAGNITUDES_ONLY_PRODUCT } from '../../../constants/rulesProductMagnitudes';
import { fonts } from '../../../utils/Fonts';
import Images from '../../../../assets/Images';
import translate from '../../../locales';
import { Price, CalculatedValue } from '~/../components_base/src/components';
import { RadioGroupData } from '~/components';
import Touchable from 'react-native-platform-touchable';

import PropTypes from 'prop-types';


const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 15,
    },
    headerInfo: {
        marginBottom: 15,
    },
    productCode: {
        fontSize: 14,
        marginBottom: 5,
        fontFamily: 'Quicksand-Regular',
        color: color.neutral700,
        fontWeight: '300',
    },
    productName: {
        fontSize: 16,
        marginBottom: 10,
        fontFamily: 'Quicksand-Regular',
        color: color.neutral700,
        fontWeight: 'bold',
    },
    stockContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
    },
    stockText: {
        fontSize: 14,
        fontFamily: 'Quicksand-Regular',
        color: color.neutral700,
        fontWeight: 'bold',
    },
    productMultiple: {
        fontSize: 14,
        fontFamily: 'Quicksand-Regular',
        color: color.neutral700,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    priceContainer: {
        marginBottom: 15,
    },
    productPrice: {
        fontSize: 18,
        fontFamily: 'Quicksand-Regular',
        color: color.neutral700,
        fontWeight: 'bold',
    },
    divider: {
        height: 1,
        backgroundColor: color.grayContainerDark,
        marginVertical: 15,
    },
    subTotalContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        paddingVertical: 10,
    },
    magnitudeContainer: {
        marginBottom: 15,
    },
    magnitudeLabel: {
        marginBottom: 5,
    },
    quantityContainer: {
        marginBottom: 15,
    },
    quantityLabel: {
        flexDirection: 'row',
        marginBottom: 10,
    },
    quantityLabelText: {
        fontWeight: 'bold',
    },
    quantityFactorText: {
        marginLeft: 5,
    },
    subTotalTitle: {
        color: color.grayDark,
        fontFamily: fonts.QuicksandBold,
        fontSize: 14,
        marginBottom: 5,
    },
    subTotalRight: {
        alignItems: 'flex-end',
    },
    iconError: {
        tintColor: '#FFF',
        width: 16,
        height: 16,
    },
    badgeError: {
        width: 24,
        height: 24,
        borderColor: '#fff',
        borderWidth: 1,
        borderRadius: 12,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#ff000f',
        marginLeft: 5,
    },
    recalculateButton: {
        fontSize: 17,
        color: color.primaryColorLight,
        borderColor: color.primaryColorLight,
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 8,
        paddingVertical: 5,
        fontWeight: '400',
    }
});

function Magnitudes({ produto }) {
    if (produto.type_magnitudes === MAGNITUDES_ONLY_PRODUCT) {
        return produto.unidade_macro > 1 ? (
            <Text style={styles.productMultiple}>
                {produto.descricao_unidade_macro} {`${translate('contains')}`}{' '}
                {produto.unidade_macro}
            </Text>
        ) : null;
    }

    return produto.unidade > 1 ? (
        <Text style={styles.productMultiple}>
            {produto.descricao_unidade} {`${translate('contains')}`} {produto.unidade}
        </Text>
    ) : null;
}

const RenderEstoque = ({ product, configLib }) => {
    const hiddenFields =
        get(configLib, 'listagem_campos_visibilidade.product.hidden_fields') ||
        {};
    const showEstoque = !hiddenFields.estoque;

    const { estoque } = product;
    const styleColor = {};
    let image = null;

    if (showEstoque) {
        if (estoque === 0) {
            styleColor.color = '#ff000f';
            image = (
                <View style={styles.badgeError}>
                    <Image style={styles.iconError} source={Images.iconAlertError} />
                </View>
            );
        }
    }

    return showEstoque ? (
        <View style={styles.stockContainer}>
            <Text style={[styles.stockText, styleColor]}>
                {estoque} {`${translate('inStock')}`}
            </Text>
            {image}
        </View>
    ) : null;
};

export const TotalPrice = ({
    price,
    toRecalculate,
    precototal,
    stockExists,
    stock,
    recalculatePrice,
}) => {
    if (price > 0) {
        if (toRecalculate) {
            return (
                <Touchable onPress={recalculatePrice}>
                    <Text style={styles.recalculateButton}>
                        Recalcular
                    </Text>
                </Touchable>
            );
        } 
        return (
            <Text>
                <CalculatedValue
                    value={precototal || 0}
                    style={styles.productPrice}
                />
            </Text>
        );
    } 
    
    return (
        <Text style={styles.productPrice}>
            {stockExists && stock <= 0
                ? `${translate('productNotAvailable')}`
                : `${translate('priceNotAvailable')}`}
        </Text>
    );
};

const ProductTopInfo = (props) => {
    const {
        product,
        quantity,
        volumeFactor,
        setQuantity,
        addItem,
        magnitudes,
        magnitudeSelect,
        updateProductMagnitude,
        updatingItem,
        ignoreStock,
        ignoreVolumeFactor,
        configLib,
        setEditing,
        toRecalculate,
        recalculatePrice,
    } = props;
    const stockExists = product.stock !== undefined && product.stock !== null;

    let valueSelected = -1;

    if (magnitudeSelect?.value) {
        valueSelected = magnitudeSelect.value;
    } else if (magnitudes?.length) {
        const [magnitude] = magnitudes;
        valueSelected = magnitude.value;
    }

    const selectedMagnitude = find(
        magnitudes,
        mag => mag.value === magnitudeSelect.value,
    );

    return (
        <View style={styles.container}>
            {/* Informações do Produto */}
            <View style={styles.headerInfo}>
                <Text style={styles.productCode}>
                    Cód.: {product.codigo}
                </Text>

                <Text style={styles.productName}>
                    {product.nome || product.descricao || ''}
                </Text>

                <RenderEstoque product={product} configLib={configLib} />

                <Magnitudes produto={product} />

                <View style={styles.priceContainer}>
                    <Text>
                        <Price value={product.precobase || 0} style={styles.productPrice} />
                    </Text>
                </View>
            </View>

            <View style={styles.divider} />

            {/* Controles de Quantidade e Subtotal */}
            <View>
                {/* Seleção de Magnitude */}
                <View style={styles.magnitudeContainer}>
                    <Text style={styles.magnitudeLabel}>Inserir Por</Text>
                    <RadioGroupData
                        valueSelected={valueSelected}
                        itens={magnitudes}
                        onSelectItem={value => updateProductMagnitude(value)}
                    />
                </View>

                <View style={styles.subTotalContainer}>
                    {/* Controle de Quantidade */}
                    <View style={styles.quantityContainer}>
                        <View style={styles.quantityLabel}>
                            <Text style={styles.quantityLabelText}>Quantidade</Text>
                            <Text style={styles.quantityFactorText}>
                                (múltiplo de {selectedMagnitude?.value || product.multiplo_venda})
                            </Text>
                        </View>

                        {product.preco > 0 ? (
                            <Quantity
                                containerStyle={{ maxWidth: 120 }}
                                product={product}
                                quantity={quantity}
                                setQuantity={setQuantity}
                                addItem={addItem}
                                volumeFactor={volumeFactor}
                                updatingItem={updatingItem}
                                ignoreStock={ignoreStock}
                                ignoreVolumeFactor={ignoreVolumeFactor}
                                setEditing={setEditing}
                            />
                        ) : null}
                    </View>

                    {/* Subtotal */}
                    <View style={styles.subTotalRight}>
                        <Text style={styles.subTotalTitle}>Sub total:</Text>
                        <TotalPrice
                            price={product.preco}
                            precototal={product.precototal}
                            stock={product.stock}
                            stockExists={stockExists}
                            toRecalculate={toRecalculate}
                            recalculatePrice={recalculatePrice}
                        />
                    </View>
                </View>
            </View>
        </View>
    );
};

ProductTopInfo.propTypes = {
    product: PropTypes.shape({
        stock: PropTypes.number,
        nome: PropTypes.string,
        descricao_unidade_macro: PropTypes.string,
        precobase: PropTypes.number,
        preco: PropTypes.number,
        precototal: PropTypes.number,
        unidade: PropTypes.number,
    }).isRequired,
    showInfos: PropTypes.bool.isRequired,
    quantity: PropTypes.number.isRequired,
    setQuantity: PropTypes.func.isRequired,
    addItem: PropTypes.func.isRequired,
    volumeFactor: PropTypes.number.isRequired,
    magnitudes: PropTypes.arrayOf(PropTypes.object).isRequired,
    magnitudeSelect: PropTypes.object.isRequired,
    updateProductMagnitude: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    setEditing: PropTypes.func.isRequired,
    updatingItem: PropTypes.bool.isRequired,
    ignoreStock: PropTypes.bool.isRequired,
    ignoreVolumeFactor: PropTypes.bool.isRequired,
    configLib: PropTypes.object.isRequired,
    toRecalculate: PropTypes.bool.isRequired,
    recalculatePrice: PropTypes.func.isRequired,
};

export default ProductTopInfo;
