/* @flow */
import React from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';

import { color } from '~/common';

const styles = StyleSheet.create({
  defaultStyle: {
    height: 5,
    borderRadius: 2,
  },
  container: {
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  current: {
    backgroundColor: color.primaryTextColor,
  },
  total: {
    flexDirection: 'row',
    overflow: 'hidden',
    backgroundColor: color.gray,
    height: 5,
  },
});

function ProgressBar(props) {
  const { current, total, containerStyle } = props;

  const currentStyle = [styles.current, { flex: current }, styles.defaultStyle];
  const totalStyle = [styles.total, { flex: total }, styles.defaultStyle, props.style];
  const remainingStyle = [styles.defaultStyle, { flex: total - current }];

  return (
    <View style={[styles.container, containerStyle]}>
      <View style={{ height: 5 }}>
        <View style={totalStyle}>
          <View style={currentStyle} />
          <View style={remainingStyle} />
        </View>
      </View>
    </View>
  );
}

ProgressBar.propTypes = {
  total: PropTypes.number,
  current: PropTypes.number,
  style: PropTypes.any,
  containerStyle: PropTypes.any,
};

export default ProgressBar;
