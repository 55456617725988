/* @flow */
import { configureStore } from '@reduxjs/toolkit';
import reducers from './reducers';
import { trigAction } from '~/utils/flightPlanTrigger';
import translate from '~/locales';
import { createNotifyError } from '~/components/Web/ToastNotify';

const actionTriggerMiddleware = () => next => action => {
  const actionMapper = {
    saveAnswer: 'atividade',
  };
  try {
    const type = action.type;
    const splittedType = type.split('/');

    const status = splittedType[splittedType.length - 1];
    const thunk = splittedType[splittedType.length - 2];

    if (status === 'fulfilled') {
      trigAction(actionMapper[thunk], action);
    }
  } catch (e) {
    createNotifyError(translate('cannotTrigFlightPlanAlerts'));
  }
  const result = next(action);
  return result;
};

export const store = configureStore({
  reducer: reducers,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(actionTriggerMiddleware),
});

export default store;
