const linksBase = {
  storeBase: {
    ios: 'itms-apps://itunes.apple.com/us/app/id',
    android: 'market://details?id=',
  },
  whatsapp: {
    supported: 'https://wa.me/',
    store: {
      ios: '310633997',
      android: 'com.whatsapp',
    },
  },
  facebook: {
    supported: 'https://www.facebook.com/',
    store: {
      ios: '284882215',
      android: 'com.facebook.katana',
    },
  },
  instagram: {
    supported: 'instagram://user?username=',
    store: {
      ios: '389801252',
      android: 'com.instagram.android',
    },
  },
  email: {
    supported: 'mailto:',
  },
  phone: {
    supported: 'tel:',
  },
};
export default linksBase;
