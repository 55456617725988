import Repository from './Repository';

export default class ConfigRepository extends Repository {
  constructor() {
    super();
    this.tableName = 'config';
  }

  successDb() {
    console.log('success db');
  }

  errorDb(err) {
    console.tron.log('err db', err);
  }
}
