import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  idRoot: null,
};

const backRootShowModalSlice = createSlice({
  name: 'backRootShowModal',
  initialState: initialState,
  reducers: {
    setBackRootShowModal: (state, action) => {
      state.idRoot = action.payload;
    },
  },
});

const { actions, reducer } = backRootShowModalSlice;

export const { setBackRootShowModal } = actions;

export default reducer;
