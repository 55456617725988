
import { store } from '~/store';
import CacheApiV1 from './CacheApiV1';
import api from '~/services/anywhere/apiBaseAnywhere';
import { GENERATE_DATA_STARTED, LAST_UPLOAD_TIME } from '~/constants/Sync';
import _ from 'lodash';
import CacheDataController from '~/utils/CacheDataController';
import moment from 'moment';
import { getAuthToken } from '~/services/anywhere/constants';

class SyncApiV1 {
  constructor() {
    this.cache = CacheApiV1;
    this.download = null;
    this.upload = null;
    this.process = null;
  }

  static async startSync() {
    const authToken = await getAuthToken();
    const statusData = _.get(
      store.getState().syncFetch,
      'payload.statusData',
      GENERATE_DATA_STARTED,
    );
    if (statusData !== GENERATE_DATA_STARTED) {
      const axiosInstance = await api();
      const response = await axiosInstance.get('/v1/api/update-database/',{ headers: { Authorization: `FSJWT ${authToken}` } },
      );
      const results = response.data.result;
      if (results) {
        const lastUploadCache = await CacheDataController.getData(
          LAST_UPLOAD_TIME,
        );
        const { last_update: serviceLastUpload } = results;
        const cacheData = {
          lastUpload: serviceLastUpload,
          dateTime: moment().format('lll'),
        };

        if (lastUploadCache) {
          const { lastUpload } = lastUploadCache;

          if (lastUpload < serviceLastUpload) {
            await CacheDataController.saveData(LAST_UPLOAD_TIME, cacheData);
          }
        } else {
          await CacheDataController.saveData(LAST_UPLOAD_TIME, cacheData);
        }
      }
    }
  }

  static async removeDatabase() {
    const authToken = await getAuthToken();
    const axiosInstance = await api();
    const removeResponse = await axiosInstance.delete('/v1/api/update-database/', { headers: { Authorization: `FSJWT ${authToken}` } });
    return removeResponse;
  }

  execute(progress) {
    return new Promise(async (resolve, reject) => {
      try {
        await SyncApiV1.startSync();

        progress({ text: 'Verificando dados...', progress: 1 });
        const authToken = await getAuthToken();
        const axiosInstance = await api();

        const verifyDatabaseStatus = async () => {
          const response = await axiosInstance.get('/v1/api/status-sync/',
            { headers: { Authorization: `FSJWT ${authToken}` } },
          );
          if (response.status === 200) {
            const data = response.data;
            if (data.ready) {
              await this.cache.setDownloadComplete('true');
              resolve();
              return true;
            }
            const progressData = data.progress_data;
            if (progressData) {
              progress({
                text: progressData.text,
                progress: progressData.value,
              });
            }
            setTimeout(verifyDatabaseStatus, 2200);
          }
          return false;
        };
        // setTimeout(verifyDatabaseStatus, 5000);
        await verifyDatabaseStatus();
      } catch (error) {
        reject(error);
      }
    });
  }

  async isInitialDownload() {
    const downloadComplete = await this.cache.getDownloadComplete();
    return !downloadComplete;
  }
}

export default SyncApiV1;
