/* @flow */

import React, { Component } from 'react';
import { ScrollView, View } from 'react-native';
import { Form } from '~/components/index';
import { style } from '~/common/index';
import Spinner from 'react-native-loading-spinner-overlay';

import PropTypes from 'prop-types';

class Observations extends Component {
  constructor(props) {
    super(props);
  }

  isFormValid = valid => {
    this.props.isFormValid(valid);
  };

  render() {
    const {
      form,
      getFormData,
      fieldsRules,
      loading,
      initialValues,
    } = this.props;
    return (
      <View style={[style.container, { backgroundColor: 'white' }]}>
        <Spinner
          visible={loading}
          overlayColor="rgba(0, 0, 0, 0.7)"
          textStyle={{ color: '#FFF' }}
        />
        <ScrollView>
          <View>
            {form && form.length > 0 ? (
              <Form
                fieldsRules={fieldsRules}
                isFormValid={this.isFormValid}
                data={form}
                initialValues={initialValues}
                getFormData={getFormData}
              />
            ) : null}
          </View>
        </ScrollView>
      </View>
    );
  }
}

Observations.propTypes = {
  isFormValid: PropTypes.func.isRequired,
  getFormData: PropTypes.func.isRequired,
  form: PropTypes.array.isRequired,
  fieldsRules: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default Observations;
