/* @flow */
import React, { Component } from 'react';
import { Image, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import NavigationHelper from '~/screens/NavigationHelper';
import Titles from './Titles';

import Images from '../../../../assets/Images';
import FinancingModule from '~/modules/FinancingModule';
import {
    filterByTextFields,
    getFieldsSearch,
} from '../../Filter/components/filters';

import {
    getListClients,
    CLIENTS_FETCH_REQUEST,
    CLIENTS_FETCH_SUCCESS,
} from '../../../store/clientsFetch/slice';
import { SCREEN_TITLES } from '../../screens';
import { getListWithPagination } from '../../../utils/FastSellerUtils';
import _ from 'lodash';
import onPressHelper from '~/utils/onPressHelper';

import PropTypes from 'prop-types';

const CLIENT_DEFAULT = '0';
const FILTER_STATUS_DEFAULT = '0';

class TitlesContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: 'Títulos',
            titles: [],
            sortedTitles: [],
            totalizers: null,
            showSearch: false,
            searchText: '',
            searchFields: [],
        };

        this.clients = [];
    }

    componentDidMount() {
        this.setLeftButtons();
        this.setRightButtons();
        const pkClient = this.props.pkeyClient
            ? this.props.pkeyClient
            : CLIENT_DEFAULT;
        const tamMemory = _.get(this.props.configLib, 'tam_memoria_paginacao');
        if (this.clients.length === 0) {
            this.clients = this.props.clients.payload;
        }

        getListWithPagination(
            FinancingModule.listTitles,
            tamMemory,
            pkClient,
            FILTER_STATUS_DEFAULT,
        )
            .then(financier => {
                const titlesParcel = financier.titles.data;
                const { totalizers } = financier;
                const searchFields = getFieldsSearch(titlesParcel);

                this.setState({
                    title: financier.titles.title,
                    titles: titlesParcel,
                    sortedTitles: titlesParcel,
                    totalizers,
                    searchFields,
                });
            })
            .catch(data => {
                console.tron.log('resp query list error', data);
            });
    }

    UNSAFE_componentWillReceiveProps({ clients }) {
        (async () => {
          if (clients.type === this.props.clients.type) return;
          if (this.props.clients.type !== CLIENTS_FETCH_REQUEST) return;
          if (clients.type !== CLIENTS_FETCH_SUCCESS) return;
          const isVisibleScreen = await this.props.navigation.isFocused();
          if (isVisibleScreen) {
            const client = clients.payload.find(cli => cli.codigo === this.title.codcliente);
            this.props.navigation.navigate('Titles', { pkeyClient: client.pkey });
            this.props.navigation.setOptions({ title: client.nome || SCREEN_TITLES.title });
            this.setState({ actualClient: client.pkey });
            this.renderDetailTitle(client.pkey);
          }
        })();
      }

    navigationButtonPressed(event) {
        switch (event.buttonId) {
            case 'search':
                this.setState({ showSearch: !this.state.showSearch, searchText: '' });
                break;
            case 'backPress':
                this.handleBackPress();
                break;
            default:
                break;
        }
    }

    onListFiltered = list => this.setState({ sortedTitles: list });

    onChangeText = searchText => {
        clearTimeout(this);
        setTimeout(() => {
            this.setState({ searchText });
        }, 400);
    };

    onPressTitle = title => {
        if (this.props.pkeyClient) {
            return;
        }
        this.title = title;

        if (this.clients.length === 0) {
            this.props.dispatch(getListClients());
        } else {
            this.pushTitleScreen();
        }
    };

    onPressTitleHelper = onPressHelper.debounce(this.onPressTitle);

    pushTitleScreen = async () => {
        const visible = this.props.navigation.isFocused();
        if (visible) {
            const client = this.clients.find(
                cli => cli.codigo === this.title.codcliente,
            );
            this.props.navigation.navigate('Titles', {
                pkeyClient: client.pkey,
            });
        }
    };

    onClickTitle = (title) => {
        if (this.props.pkeyClient) return;
    
        this.props.dispatch(getListClients());
        this.title = title;
      };

    renderDetailTitle = (pkclient) => {
        const pkClient = pkclient || CLIENT_DEFAULT;
        const tamMemory = _.get(this.props.configLib, 'tam_memoria_paginacao');
    
        getListWithPagination(FinancingModule.listTitles, tamMemory, pkClient, FILTER_STATUS_DEFAULT)
          .then(financier => {
            const titlesParcel = financier.titles.data;
            const { totalizers } = financier;
            const searchFields = getFieldsSearch(titlesParcel);
    
            this.setState({
              title: financier.titles.title,
              titles: titlesParcel,
              sortedTitles: titlesParcel,
              totalizers,
              searchFields,
            });
          }).catch(data => {
            console.log('resp query list error', data);
          });
      }
    

    handleBackPress = () => {
        if (!this.props.routeByitinerary) {
            this.props.navigation.goBack();
        }
    };

    setRightButtons = () => {
        this.props?.navigation?.setOptions({
            headerRight: () => (
                <TouchableOpacity onPress={() => {
                  this.setState({ showSearch: !this.state.showSearch, searchText: '' });
                }}
                >
                  <Image
                    source={Images.iconSearch}
                    style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 10 }}
                  />
                </TouchableOpacity>
              ),
          });
    };

    setLeftButtons() {
        this.props?.navigation?.setOptions({
            headerLeft: () => (
                <TouchableOpacity onPress={() => {
                  if (this.state.actualClient !== CLIENT_DEFAULT) {
                    this.renderDetailTitle(CLIENT_DEFAULT);
                    this.setState({ actualClient: CLIENT_DEFAULT });
                    this.props.navigation.setOptions({ title: SCREEN_TITLES.title });
                  } else {
                    this.props.navigation.pop();
                  }
                }}
                >
                  <Image
                    source={Images.iconBack}
                    style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
                  />
                </TouchableOpacity>
              ),
        });
      }

    render() {
        const {
            title,
            titles,
            sortedTitles,
            totalizers,
            showSearch,
            searchText,
            searchFields,
        } = this.state;

        const showItemAsHTML = _.get(
            this.props.configLib,
            'listagem_campos_visibilidade.titles.show_html',
            false,
        );

        return (
            <Titles
                title={title}
                titles={titles}
                sortedTitles={filterByTextFields(
                    sortedTitles,
                    searchFields,
                    searchText,
                )}
                totalizers={totalizers}
                showSearch={showSearch}
                onChangeText={this.onChangeText}
                onListFiltered={this.onListFiltered}
                onCLickTitle={this.onClickTitle}
                showItemAsHTML={showItemAsHTML}
            />
        );
    }
}

TitlesContainer.propTypes = {
    clients: PropTypes.object,
    configLib: PropTypes.object,
    currentScreenVisible: PropTypes.object,
    componentId: PropTypes.string,
    pkeyClient: PropTypes.string,
    routeByitinerary: PropTypes.bool,
    dispatch: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        clients: state.clientsFetch,
        configLib: state.configLibFetch.payload,
        currentScreenVisible: state.currentScreenVisible,
    };
}

export default connect(mapStateToProps)(TitlesContainer);
