import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ClientsModule from '~/modules/ClientsModule';

export const NEW_CLIENTS_FETCH_REQUEST = 'NEW_CLIENTS_FETCH_REQUEST';
export const NEW_CLIENTS_FETCH_SUCCESS = 'NEW_CLIENTS_FETCH_SUCCESS';
export const NEW_CLIENTS_FETCH_FAILURE = 'NEW_CLIENTS_FETCH_FAILURE';

const getListClientsOnLib = () =>
  new Promise((resolve, reject) => {
    ClientsModule.getNewClients(resolve, reject);
  });

export const newClientsFetch = createAsyncThunk(
  'newClientsFetch/newClientsFetch',
  async () => {
    const resultGetListNewClients = await getListClientsOnLib();
    const listNewClients = JSON.parse(resultGetListNewClients);
    return listNewClients;
  },
);

const initialState = {
  type: '',
  loading: false,
  erro: false,
  payload: [],
};

const newClientsFetchSlice = createSlice({
  name: 'newClientsFetch',
  initialState,
  reducers: {},
  extraReducers: {
    [newClientsFetch.pending]: (state, action) => {
      state.type = NEW_CLIENTS_FETCH_REQUEST;
      state.loading = true;
      state.erro = false;
    },
    [newClientsFetch.fulfilled]: (state, action) => {
      state.type = NEW_CLIENTS_FETCH_SUCCESS;
      state.loading = false;
      state.erro = false;
      state.payload = action.payload;
    },
    [newClientsFetch.rejected]: (state, action) => {
      state.type = NEW_CLIENTS_FETCH_FAILURE;
      state.loading = false;
      state.erro = true;
    },
  },
});

export const { reducer } = newClientsFetchSlice;

export default reducer;
