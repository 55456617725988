/* @flow */
import moment from 'moment';
import { GENERATE_DATA_FINALLY, SYNC_FINALLY } from '../../constants/Sync';
import { createSlice } from '@reduxjs/toolkit';

export const SYNC_UPDATE_STATUS_TIME = 'SYNC_UPDATE_STATUS_TIME';
export const SYNC_UPDATE_STATUS = 'SYNC_UPDATE_STATUS';
export const SYNC_UPDATE_SCREEN = 'SYNC_UPDATE_SCREEN';
export const SYNC_GENERATE_DATA = 'SYNC_GENERATE_DATA';
export const SYNC_SHOW_PROGRESS = 'SYNC_SHOW_PROGRESS';
export const SYNC_UPDATE_PROGRESS = 'SYNC_UPDATE_PROGRESS';
export const SYNC_UPDATE_CODE_ERROR = 'SYNC_UPDATE_CODE_ERROR';

const initialState = {
  type: '',
  payload: {
    timeLastSync: 0,
    statusSync: SYNC_FINALLY,
    screen: '',
    statusData: GENERATE_DATA_FINALLY,
    codeError: '',
    showProgress: false,
    progressData: { text: '', progress: 0 },
  },
};

const syncFetchSlice = createSlice({
  name: 'syncFetch',
  initialState,
  reducers: {
    syncUpdateTimeAndStatus: (state, action) => {
      const momentFormat = moment().format();
      const { payload } = action;

      state.type = SYNC_UPDATE_STATUS_TIME;
      state.payload.timeLastSync = momentFormat;
      state.payload.statusSync = payload;
    },
    syncUpdateStatus: (state, action) => {
      state.type = SYNC_UPDATE_STATUS;
      state.payload.statusSync = action.payload;
    },
    syncUpdateData: (state, action) => {
      state.type = SYNC_GENERATE_DATA;
      state.payload.statusData = action.payload;
    },
    syncUpdateScreen: (state, action) => {
      state.type = SYNC_UPDATE_SCREEN;
      state.payload.screen = action.payload;
    },
    showProgressSync: (state, action) => {
      state.type = SYNC_SHOW_PROGRESS;
      state.payload.showProgress = action.payload;
    },
    updateProgressSync: (state, action) => {
      state.type = SYNC_UPDATE_PROGRESS;
      state.payload.progressData.text = action.payload.text;
      state.payload.progressData.progress = action.payload.progress;
    },
    synUpdateCodeError: (state, action) => {
      state.type = SYNC_UPDATE_CODE_ERROR;
      state.payload.codeError = action.payload;
    },
  },
  extraReducers: builder => { },
});

const { actions, reducer } = syncFetchSlice;

export const {
  syncUpdateTimeAndStatus,
  syncUpdateStatus,
  syncUpdateData,
  syncUpdateScreen,
  showProgressSync,
  updateProgressSync,
  synUpdateCodeError,
} = actions;

export default reducer;
