import appDefault from './img/view_grid.png';
import appClients from './img/cliente.png';
import appToday from './img/rotas.png';
import appCalendar from './img/agenda.png';
import appGamification from './img/gamification.png';
import appLearning from './img/e_learning.png';
import appFinancing from './img/financeiro.png';
import appGoals from './img/goals.png';
import appManagement from './img/management.png';
import appOrders from './img/pedidos.png';
import appPrint from './img/iconPrint.png';
import appProspect from './img/prospect.png';
import appReports from './img/file.png';
import appSales from './img/promoção.png';
import appTrade from './img/iconTrade.png';
import cblAlimentos from './img/cbl_alimentos.png';
import iconAddPause from './img/addPause.png';
import iconChangeUser from './img/change_user.png';
import iconCoinBig from './img/coin_big.png';
import iconCollapseBack from './img/collapseBack.png';
import iconCustomerBig from './img/customer_big.png';
import iconEditProperty from './img/editProperty.png';
import iconAccount from './img/account.png';
import iconAscending from './img/ascendinSorting.png';
import iconAddUser from './img/add_user.png';
import iconAlert from './img/alert.png';
import iconAlertError from './img/alert_error.png';
import iconArchive from './img/archive.png';
import iconArrowRight from './img/arrowRight.png';
import iconBack from './img/arrowBack.png';
import iconBell from './img/bell.png';
import iconBellFill from './img/bell_fill.png';
import iconBoxEmpty from './img/boxEmpty.png';
import iconBroom from './img/broom.png';
import iconBulletedList from './img/bulleted_list.png';
import iconCalendarDelete from './img/calendarDelete.png';
import iconCalendar from './img/calendar.png';
import iconCalendarClock from './img/calendarClock.png';
import iconClock from './img/clock.png';
import iconCamera from './img/camera.png';
import iconCart from './img/cart.png';
import iconCartPlus from './img/cart_plus.png';
import iconCartMoney from './img/cart_money.png';
import iconCheck from './img/check.png';
import iconCheckCircle from './img/check_circle.png';
import iconCheckIn from './img/checkIn.png';
import iconCheckOut from './img/checkOut.png';
import iconChevronDown from './img/chevron_down.png';
import iconChevronUp from './img/chevron_up.png';
import iconChevronRight from './img/chevron_right.png';
import iconClose from './img/close.png';
import iconCollapseArrow from './img/collapseArrow.png';
import iconCopy from './img/copy.png';
import iconCompare from './img/compare.png';
import iconCustomers from './img/customers.png';
import iconCreditCard from './img/credit_card.png';
import iconCrosshair from './img/crosshairs.png';
import iconCrosshairGps from './img/crosshairs-gps.png';
import iconDelete from './img/delete.png';
import iconDescending from './img/descendingSorting.png';
import iconDotsHorizontal from './img/dots_horizontal.png';
import iconDotsVertical from './img/dots_vertical.png';
import iconDuplicate from './img/duplicate.png';
import iconEdit from './img/edit.png';
import iconEmailFill from './img/email_fill.png';
import iconEvent from './img/event.png';
import iconExpandArrow from './img/expandArrow.png';
import iconFilter from './img/filter.png';
import iconFindUserMale from './img/find_user_male.png';
import iconFolder from './img/folder.png';
import iconGift from './img/card_giftcard.png';
import iconGroup from './img/group.png';
import iconHomeSelected from './img/home_selected.png';
import iconHtml from './img/html.png';
import iconInfo from './img/info.png';
import iconLock from './img/lock.png';
import iconMapMarker from './img/map_marker.png';
import iconDownloadVersion from './img/download_version.png';
import iconMinus from './img/minus.png';
import iconMinusWhite from './img/minus_white.png';
import iconMoreThan from './img/more_than.png';
import iconMenuDown from './img/menu_down.png';
import iconNoProductImage from './img/icon_no_product_image.png';
import iconNoUsers from './img/account.png';
import iconNoImageBig from './img/no_image_big.png';
import iconOrder from './img/order.png';
import iconOrderFill from './img/order_fill.png';
import iconPause from './img/pause.png';
import iconProduct from './img/product.png';
import iconProductDefault from './img/product_default.png';
import iconPdf from './img/pdf.png';
import iconPencil from './img/edit.png';
import iconPhoneFill from './img/phone_fill.png';
import iconPlay from './img/play.png';
import iconPlaceMarker from './img/placeMarker.png';
import iconPlus from './img/plus.png';
import iconPlusWhite from './img/plus_white.png';
import iconPriceSearch from './img/priceSearch.png';
import iconQuestions from './img/questions.png';
import iconQRCode from './img/qrcode.png';
import iconRoute from './img/route.png';
import iconSettings from './img/settings.png';
import iconStar from './img/star.png';
import iconStore from './img/store.png';
import iconTitles from './img/titles.png';
import iconStoreFill from './img/store_fill.png';
import iconStopwatch from './img/stopwatch.png';
import iconSearch from './img/search.png';
import iconSort from './img/sorting.png';
import iconTrophy from './img/trophy.png';
import iconTune from './img/tune.png';
import iconSync from './img/sync.png';
import iconPackageMobile from './img/packageMobile.png';
import iconChoice from './img/choice.png';
import iconEye from './img/eye.png';
import iconEyeHidden from './img/eye_hidden.png';
import iconDatabaseRemove from './img/database_remove.png';
import iconRecycling from './img/recycling.png';
import iconStop from './img/stop.png';
import iconRewards from './img/rewards.png';
import iconTruck from './img/truck.png';
import iconFacebook from './img/facebook.png';
import iconInstagram from './img/instagram.png';
import iconWhatsapp from './img/whatsapp.png';
import iconExternalLink from './img/external.png';
import iconCoinSymbol from './img/coin_symbol.png';
import iconPlusMinus from './img/plus_minus.png';
import uploadCloud from './img/upload_cloud.png';
import logoPoli from './img/logo_poli.png';
import logoPoliSquare from './img/logo_poli_square.png';
import logoPoliSquareWhite from './img/logo_poli_square_white.png';
import logoPoliHorizontal from './img/logo_poli_horizontal.png';
import loginBackground from './img/background_login.png';
import logoPoliEquipes from './img/poliEquipes_logo.png';
import lottieCheck from './img/Check.json';
import barCode from './img/bar_code.png';
import zapOff from './img/zapOff.png';
import zap from './img/zap.png';
import success from './img/success.png';
import iconWallet from './img/wallet.png';
import iconSave from './img/save.png';
import iconShare from './img/share.png';
import iconTrash from './img/trash.png';
import iconChart from './img/chart.png';
import iconRightUp from './img/right-up.png';
import iconLeftDown from './img/left-down.png';
import iconDownload from './img/download.png';
import iconUSD from './img/usd.png';

const Images = {
  appDefault,
  appClients,
  appToday,
  appCalendar,
  appGamification,
  appLearning,
  appFinancing,
  appGoals,
  appManagement,
  appOrders,
  appPrint,
  appProspect,
  appReports,
  appSales,
  appTrade,
  cblAlimentos,
  iconAddPause,
  iconChangeUser,
  iconCoinBig,
  iconCollapseBack,
  iconCustomerBig,
  iconEditProperty,
  iconAccount,
  iconAscending,
  iconAddUser,
  iconAlert,
  iconAlertError,
  iconArchive,
  iconArrowRight,
  iconBack,
  iconBell,
  iconBellFill,
  iconBoxEmpty,
  iconBroom,
  iconBulletedList,
  iconCalendarDelete,
  iconCalendar,
  iconCalendarClock,
  iconClock,
  iconCamera,
  iconCart,
  iconCartPlus,
  iconCartMoney,
  iconCheck,
  iconCheckCircle,
  iconCheckIn,
  iconCheckOut,
  iconChevronDown,
  iconChevronUp,
  iconChevronRight,
  iconClose,
  iconCollapseArrow,
  iconCopy,
  iconCompare,
  iconCustomers,
  iconCreditCard,
  iconCrosshair,
  iconCrosshairGps,
  iconDelete,
  iconDescending,
  iconDotsHorizontal,
  iconDotsVertical,
  iconDuplicate,
  iconEdit,
  iconEmailFill,
  iconEvent,
  iconExpandArrow,
  iconFilter,
  iconFindUserMale,
  iconFolder,
  iconGift,
  iconGroup,
  iconHomeSelected,
  iconHtml,
  iconInfo,
  iconLock,
  iconMapMarker,
  iconDownloadVersion,
  iconMinus,
  iconMinusWhite,
  iconMoreThan,
  iconMenuDown,
  iconNoProductImage,
  iconNoUsers,
  iconNoImageBig,
  iconOrder,
  iconOrderFill,
  iconPause,
  iconProduct,
  iconProductDefault,
  iconPdf,
  iconPencil,
  iconPhoneFill,
  iconPlay,
  iconPlaceMarker,
  iconPlus,
  iconPlusWhite,
  iconPriceSearch,
  iconQuestions,
  iconQRCode,
  iconRoute,
  iconSettings,
  iconStar,
  iconStore,
  iconTitles,
  iconStoreFill,
  iconStopwatch,
  iconSearch,
  iconSort,
  iconTrophy,
  iconTune,
  iconSync,
  iconPackageMobile,
  iconChoice,
  iconEye,
  iconEyeHidden,
  iconDatabaseRemove,
  iconRecycling,
  iconStop,
  iconRewards,
  iconTruck,
  iconFacebook,
  iconInstagram,
  iconWhatsapp,
  iconExternalLink,
  uploadCloud,

  logoPoli,
  logoPoliSquare,
  logoPoliSquareWhite,
  logoPoliHorizontal,
  loginBackground,
  logoPoliEquipes,

  lottieCheck,
  barCode,
  zapOff,
  zap,
  success,

  iconWallet,
  iconSave,
  iconShare,
  iconTrash,
  iconChart,
  iconRightUp,
  iconLeftDown,
  iconDownload,
  iconCoinSymbol,
  iconPlusMinus,
  iconUSD,
};

export default Images;
