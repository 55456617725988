import React, { Component } from 'react';
import { style, color, Money } from '../../common';
import { View, ScrollView, StyleSheet, Text, Image, ActivityIndicator } from 'react-native';
import Table from '../../components/Table';
import Button from '~/components/Button';
import Search from '../../components/Search';
import RawBottomSheetClients from '../../components/RawBottomSheet/RawBottomSheetClients';
import SalesCondition from '~/components/SalesCondition';
import * as config from '~/config/config';
import ProductList from '../Product/ProductList';
import Touchable from 'react-native-platform-touchable';
import Images from '@assets/Images';
import {
  MAGNITUDES_MANY_PRODUCT,
  MAGNITUDES_ONLY_PRODUCT,
} from '../../constants/rulesProductMagnitudes';
import ProductBonus from '../../../components_base/src/components/ProductBonus/ProductBonus';
import { fonts } from '../../utils/Fonts';
import _ from 'lodash';
import GpsHelper from '../../utils/GpsHelper';
import translate, { calendarLanguage as intlLanguage } from '../../locales';
import ProductListGroup from '../../components/OperationByGroup/ProductListGroup';
import BottomSheet from '../../components/BottomSheet';
import OperationByGroup from '../../components/OperationByGroup/OperationByGroup';
import DiscountsViewItem from './components/DiscountFormItem';
import { parseFloatToCurrency } from '~/utils/Currency';
import { FlashList } from '@shopify/flash-list';
import { FloatingAction } from 'react-native-floating-action';
import { CustomAlertModal, OperationGroup } from '~/components';
import Actions from '~/components/OperationGroup/Actions';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import ModalOverlay from '~/components/Web/ModalOverLay/index.web';

const BUDGET_FAB_OPTION = {
  name: 'save_budget',
  text: `${translate('saveBudget')}`,
  icon: <Image source={Images.iconSave} tintColor={'#FFF'} style={{ width: 18, height: 18 }} />,
};

const SHARING_OPTION = {
  name: 'share_order',
  text: 'Compartilhar com cliente',
  icon: <Image source={Images.iconShare} tintColor={'#FFF'} style={{ width: 18, height: 18 }} />,
};

const DEFAULT_FLOATING_OPTIONS = [
  {
    name: 'cancel_order',
    text: 'Cancelar carrinho',
    icon: <Image source={Images.iconTrash} tintColor={'#FFF'} style={{ width: 18, height: 18 }} />,
  },
];

const styles = StyleSheet.create({
  itemsProducts: {
    backgroundColor: '#FFFFFF',
    padding: 16,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  subheader: {
    fontFamily: fonts.QuicksandBold,
    fontSize: 16,
    backgroundColor: '#fff',
    color: config.Colors.primaryColor,
    fontWeight: 'bold',
  },
  sectionContainer: {
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderBottomColor: config.Colors.grayContainerDark,
  },
  topContainer: {
    backgroundColor: '#fff',
  },
  name: {
    color: '#000',
    fontFamily: fonts.QuicksandBold,
    fontSize: 16,
    fontWeight: 'bold',
  },
  iconCartAction: {
    width: 24,
    height: 24,
    margin: 4,
    tintColor: color.primaryColor,
  },
  iconchangeClient: {
    width: 30,
    height: 30,
    padding: 12,
    marginRight: 10,
    tintColor: color.primaryColor,
  },
  promoTopContainer: {
    backgroundColor: '#FFFFFF',
    borderBottomWidth: 1,
    borderBottomColor: color.grayContainerDark,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 16,
  },
  iconPromoContainer: {
    borderRadius: 2,
    backgroundColor: color.successColor,
    paddingVertical: 3,
    paddingHorizontal: 1,
    marginRight: 16,
  },
  iconPromo: {
    tintColor: '#FFF',
    width: 15,
    height: 15,
  },
  textPromo: {
    color: color.successColor,
  },
});

class Resume extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FABOpened: false,
      modalLostChanges: this.props.pressedBackInEditionMode,
    };
  }
  RBSheetGenre = null;

  getGiftView = (cart, paramsStoker) => {
    const { bonificacoes } = cart.payload;
    const gifts = [];
    if (bonificacoes && bonificacoes.length > 0) {
      bonificacoes.forEach(boni => {
        if (boni.brindes && boni.brindes.length > 0) {
          boni.brindes.forEach(brinde => {
            gifts.push({
              ean: brinde.ean,
              name: brinde.descricao,
              quantity: brinde.quantidade,
            });
          });
        }
      });
    }
    let giftsView = null;
    if (gifts && gifts.length > 0) {
      giftsView = (
        <View>
          <View style={styles.promoTopContainer}>
            <View style={styles.iconPromoContainer}>
              <Image style={styles.iconPromo} source={Images.iconStar} />
            </View>
            <Text style={styles.textPromo}>{`${translate('gifts')}`}</Text>
          </View>
          <View>
            {gifts.map(gift => (
              <ProductBonus product={gift} paramsStoker={paramsStoker} />
            ))}
          </View>
        </View>
      );
    }

    return giftsView;
  };

  getFundsView = (cart, { verba_hablitado }) => {
    const { verba } = cart.payload;

    let fundsView = null;
    if (verba && verba_hablitado) {
      fundsView = (
        <View
          style={[
            styles.sectionContainer,
            { paddingHorizontal: 0, paddingVertical: 16 },
          ]}>
          <View>
            <Text
              style={[
                styles.subheader,
                { marginHorizontal: 16, marginBottom: 8 },
              ]}>
              {`${translate('funds')}`}
            </Text>
          </View>
          <Table
            values={[
              {
                label: `${translate('initial')}`,
                value: Money(verba.saldo),
                money: true,
                moneyPositiveOrNegative: new Number(verba.saldo) >= 0.0,
              },
              {
                label:
                  new Number(verba.vl_pedido) >= 0.0
                    ? `${translate('addition')}`
                    : `${translate('reduction')}`,
                value: Money(verba.vl_pedido),
                money: true,
                moneyPositiveOrNegative: new Number(verba.vl_pedido) >= 0.0,
              },
              {
                label: `${translate('total')}`,
                value: parseFloatToCurrency(verba.total, {
                  type: 'calculated',
                  positiveOrNegative: true,
                }),
                main: true,
                money: true,
                moneyPositiveOrNegative: new Number(verba.total) >= 0.0,
              },
            ]}
          />
        </View>
      );
    }

    return fundsView;
  };

  renderGenericInfos = ({ item: genericViewObj }) => {
    const types = {
      money: {
        res: info => Money(info?.value, info),
      },
      text: {
        res: info =>
          info?.precisionType ? Money(info?.value, info) : info.value,
      },
      number: {
        res: info =>
          info?.precisionType ? Money(info?.value, info) : info.value,
      },
    };

    let genericView = null;
    if (genericViewObj?.infos?.length > 0) {
      const infos = genericViewObj.infos.map(info => {
        const value = types[info.type]
          ? types[info.type].res(info)
          : info.value;
        return {
          ...info,
          value: value || '',
          money: info.type === 'money',
        };
      });

      genericView = (
        <View
          style={[
            styles.sectionContainer,
            { paddingHorizontal: 0, paddingVertical: 16 },
          ]}>
          <View>
            <Text
              style={[
                styles.subheader,
                { marginHorizontal: 16, marginBottom: 8 },
              ]}>
              {genericViewObj.title}
            </Text>
          </View>
          <Table values={infos} />
        </View>
      );
    }
    return genericView;
  };

  onConfirmChangeClient = client => {
    const { onConfirmChangeClient } = this.props;
    const { RBSheetGenre } = this;

    if (onConfirmChangeClient) {
      onConfirmChangeClient(client);
    }

    if (RBSheetGenre) {
      RBSheetGenre.close();
    }
  };

  renderDiscountsItem = ({ item }) => {
    const {
      setDiscountTalao,
      cart: { payload: order },
    } = this.props;
    const { [item.ord]: ordersValues } = order || {};
    const { value, percent } = ordersValues || {};

    return (
      <DiscountsViewItem
        onActiveSection={active => {
          this.setState({ discountsViewFocused: active });
        }}
        key={() => Date.now()}
        {...item}
        setDiscountTalao={setDiscountTalao}
        cartValue={value}
        cartPercent={percent}
      />
    );
  };

  valuesResume = () => {
    const { productsQuantity, total, cart } = this.props;

    const valueShipping = _.get(cart.payload, 'frete.valor', 0);

    const values = [
      {
        label: `${translate('items')}`,
        value: productsQuantity,
      },
    ];

    if (valueShipping > 0) {
      values.push({
        label: `${translate('shipping')}`,
        value: Money(valueShipping),
      });
    }

    values.push({
      label: `${translate('total')}`,
      value: parseFloatToCurrency(total, { type: 'calculated' }),
      main: true,
    });

    return values;
  };

  getGpsPlaces = () => {
    const gpsPlaces = this.addressAltSelected || this.props.client;
    return [GpsHelper.parseGpsEvent(gpsPlaces)];
  };

  getMainButtonTitleAndAction = () => {
    const {
      editionMode,
      onPressFinishOrder,
      onConfirmBackPress,
      onPressObservations,
      observationsAnswerd,
    } = this.props;
    const comesFromClientDetail = this.props.route?.params?.comesFromClientDetail;
    let title = `${translate('finishOrder')}`;
    let action = onPressFinishOrder;
    const requiredObservations = _.get(
      this.props.config.lib,
      'configuracoes.observacoes_obrigatorias',
      false,
    );

    if (editionMode) {
      title = `${translate('applySalesConditions')}`;
      action = this.onApplyConditionSale.bind(this);
    } else if (comesFromClientDetail) {
      title = `${translate('goToProducts')}`;
      action = onConfirmBackPress;
    } else if (requiredObservations && !observationsAnswerd) {
      title = `${translate('answerObservations')}`;
      action = onPressObservations;
    }

    return {
      title,
      action,
    };
  };

  getImageBasedOnConfigIcon = (options) => {
    const newOptions = [];
    options.map((option) => {
      if (option.name === 'save_budget') {
        newOptions.push( {
          ...option,
          icon: <Image source={Images.iconSave} tintColor={'#FFF'} style={{ width: 18, height: 18 }} />,
        })
      }
      if (option.name === 'share_order') {
        newOptions.push( {
          ...option,
          icon: <Image source={Images.iconShare} tintColor={'#FFF'} style={{ width: 18, height: 18 }} />
        })
      }
      if (option.name === 'cancel_order') {
        newOptions.push({
          ...option,
          icon: <Image source={Images.iconTrash} tintColor={'#FFF'} style={{ width: 18, height: 18 }} />
      })
    }
  })
    return newOptions;
  }

  getFabOptions = () => {
    const cartFabOptions = _.get(
      this.props.config.lib,
      'configuracoes.fab_options.cart',
      [],
    );
    const allowSharing = _.get(
      this.props.config.lib,
      'configuracoes.allow_sharing_order',
      true,
    );

    const getImageIcon = this.getImageBasedOnConfigIcon(cartFabOptions);

    const options = [...getImageIcon].map(op => ({
      ...op,
      icon: op.icon,
    }));
    const hasBudgetOption = options.find(op => op.name === 'save_budget');
    if (!hasBudgetOption) {
      const hasBudgetConfig = _.get(
        this.props.config.lib,
        'configuracoes.has_type_orcamento',
      );
      if (hasBudgetConfig) {
        options.push(BUDGET_FAB_OPTION);
      }
    }

    if (allowSharing) {
      options.push(SHARING_OPTION);
    }

    return options;
  };

  onLongPress = product => {
    const { enableOperationGroup = false } = this.props;

    if (enableOperationGroup) {
      this.appendProductGrouping(product);
      this.props.handleProductGrouping?.('toggle');
    }
  };

  appendProductGrouping = product => {
    this.props.handleProductGrouping?.('append', { product });
  };

  toggleProductsGrouping = ({ activeGrouping }) => {
    if (activeGrouping) {
      this.props.handleProductGrouping?.('toggle');
    } else {
      this.props.handleProductGrouping?.('clearAll');
    }
  };

  onApplyConditionSalePrevious() {
    if (this.props.salesConditionsRef.current !== null) {
      this.props.salesConditionsRef.current.applyPreviousConditions();
    }
  }
  onApplyConditionSale() {
    if (this.props.salesConditionsRef.current !== null) {
      this.props.salesConditionsRef.current.applyConditionSale();
    }
  }
  keyExtractor = item => item.title;

  render() {
    const { onConfirmChangeClient } = this;
    const {
      showSearch,
      onChangeText,
      addItem,
      onPressObservations,
      onPressConditionsSalesChild,
      cart,
      produtos,
      client,
      loading,
      onListProduct,
      productClick,
      config,
      productsMagnitudes,
      itemUpdate,
      location,
      onLocationChange,
      genericViewObj,
      allowChangeClient,
      selectedProducts,
      onSelectProducts,
      onSelectProduct,
      onApplyOperationByGroup,
      onApplyRemoveItems,
      salesConditionsChild,
      editionMode,
      onPressConditionsPriceTerm,
      discountsView,
      comesFromClientDetail,
      onPressFloatingAction,
      pressedBackInEditionMode,
      onAcceptFinishOrder,
      closeModal,
      isModalVisible,
      enableOperationGroup,
      activeProductsGrouping,
      toggleOperationGroupModal,
      parentComponentId,
      onChangeConditions,
      onToggleEdition,
      onClientChange,
      onChangeState,
      orgSaleSelected,
    } = this.props;

    const giftsView = this.getGiftView(cart, config.paramsStoker);
    const fundsView = this.getFundsView(cart, config);
    const showPermitionLocation = _.get(
      config.lib,
      ['gps', 'required_out_demand'],
      'true',
    );
    const operationsConfig = _.get(
      config.lib,
      'configuracoes.product_group',
      {},
    );

    const { pkey: pkeyClient } = _.get(this.props, 'client', {});
    const gpsPlaces = this.getGpsPlaces();

    const showProductAsHTML = _.get(
      config.lib,
      'listagem_campos_visibilidade.order_resume.show_html',
      false,
    );

    const { title, action } = this.getMainButtonTitleAndAction();
    const hideSummary = _.get(config.lib, 'configuracoes.hideSummary', false);

    const isOperationGroupActive =
      activeProductsGrouping && selectedProducts.length > 0;

    const hideMainButtons =
      isOperationGroupActive || this.state.discountsViewFocused;

    const requiredObservations = _.get(
      this.props.config.lib,
      'configuracoes.observacoes_obrigatorias',
      false,
    );

    return (
      <View style={style.container}>
        <CustomAlertModal
          title={`${translate('lostChangeSalesCondition')}`}
          subTitle={`${translate('lostChangeSalesConditionMessage')}`}
          description={`${translate('confirmLostChangeSalesCondition')}`}
          cancelButtonText={`${translate('cancel')}`}
          confirmButtonText={`${translate('yesBack')}`}
          isVisible={pressedBackInEditionMode}
          onPressConfirm={this.props.confirmBackInEditionMode}
          onPressCancel={this.props.cancelBackInEditionMode}
        />
        <CustomAlertModal
          title={`${translate('outdatedData')}`}
          subTitle={`${translate('outdatedDataMessage')}`}
          description={`${translate('outdatedDataDescription')}`}
          cancelButtonText={`${translate('back')}`}
          confirmButtonText={`${translate('saveBudget')}`}
          isVisible={isModalVisible && !loading}
          onPressConfirm={onAcceptFinishOrder}
          onPressCancel={closeModal}
        />
        <RawBottomSheetClients
          selectedClient={client}
          onPressConfirm={onConfirmChangeClient}
          setRef={ref => {
            this.RBSheetGenre = ref;
          }}
        />

        {showSearch ? (
          <Search
            title={`${translate('search')}`}
            onChangeText={onChangeText}
          />
        ) : null}
        <ModalOverlay
          visible={loading}
          closeOnTouchOutside
        />
        <ActivityIndicator style={{ position: 'absolute', top: '50%', left: '50%', zIndex: 9999 }} animating={loading} size="large" />

        <ScrollView>
          <View>
            <View style={styles.topContainer}>
              <View style={[styles.sectionContainer, { padding: 16 }]}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  <Text style={styles.subheader}>{`${translate(
                    'customer',
                  )}`}</Text>
                  {allowChangeClient && (
                    <Touchable
                      onPress={() => this.RBSheetGenre.open()}>
                      <Image
                        source={Images.iconChangeUser}
                        style={styles.iconchangeClient}
                      />
                    </Touchable>
                  )}
                </View>
                <Text style={[styles.name, { marginTop: 8 }]}>
                  {client?.nome}
                </Text>
              </View>
              <SalesCondition
                ref={this.props.salesConditionsRef}
                {...{
                  parentComponentId,
                  onChangeConditions,
                  onToggleEdition,
                  onClientChange,
                  onChangeState,
                }}
              />
              {genericViewObj.length > 0 && (
                <FlashList
                  data={genericViewObj}
                  keyExtractor={this.keyExtractor}
                  renderItem={this.renderGenericInfos}
                  estimatedItemSize={120}
                />
              )}
              {discountsView.length > 0 && (
                <View
                  style={[
                    styles.sectionContainer,
                    { paddingHorizontal: 0, paddingVertical: 16 },
                  ]}>
                  <Text
                    style={[
                      styles.subheader,
                      { marginHorizontal: 16, marginBottom: 8 },
                    ]}>
                    {`${translate('discount')}s`}
                  </Text>
                  <FlashList
                    estimatedItemSize={240}
                    keyExtractor={discount => `${discount.ordem}`}
                    data={discountsView}
                    renderItem={this.renderDiscountsItem}
                  />
                </View>
              )}

              <View style={{ margin: 8 }}>
                <Touchable
                  onPress={() => onPressObservations()}
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 4,
                    padding: 12,
                    borderWidth: 1,
                    borderColor: color.primaryColorLight,
                  }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      alignSelf: 'center',
                    }}>
                    <Image source={Images.iconEdit} style={{ width: 20, height: 20, tintColor: color.primaryColorLight }} />
                    <Text
                      style={{
                        color: color.primaryColorLight,
                        fontSize: 16,
                        marginLeft: 8,
                      }}>
                      {translate('answerObservations')}
                    </Text>
                  </View>
                </Touchable>
                {requiredObservations ? (
                  <View style={{ marginTop: 6 }}>
                    <Text style={{ color: color.dangerColor }}>
                      {translate('requiredObservations')}
                    </Text>
                  </View>
                ) : null}
              </View>

              {fundsView}
              {!hideSummary ? (
                <View
                  style={[
                    styles.sectionContainer,
                    { paddingHorizontal: 0, paddingVertical: 16 },
                  ]}>
                  <View>
                    <Text
                      style={[
                        styles.subheader,
                        { marginHorizontal: 16, marginBottom: 8 },
                      ]}>
                      {`${translate('resume')}`}
                    </Text>
                  </View>
                  <Table values={this.valuesResume()} />
                </View>
              ) : null}
            </View>
            <View style={styles.itemsProducts}>
              <Text style={[styles.subheader]}>{`${translate('items')}`}</Text>
              <Touchable
                onPress={onListProduct}>
                <Image source={Images.iconCart} style={styles.iconCartAction} />
              </Touchable>
            </View>
            {enableOperationGroup && produtos.length ? (
              <View
                style={{
                  backgroundColor: '#fff',
                }}>
                <View
                  style={{
                    marginHorizontal: 16,
                    paddingVertical: 16,
                  }}>
                  <OperationGroup.Header
                    qtySelected={selectedProducts.length}
                    toggleProductsGrouping={this.toggleProductsGrouping}
                    toggleHeaderContent={activeProductsGrouping === false}
                  />
                </View>
              </View>
            ) : null}
            <IntlProvider locale={`${intlLanguage}`}>
              <View
                style={{
                  marginBottom:
                    activeProductsGrouping && selectedProducts.length ? 60 : 44,
                }}>
                {produtos &&
                  produtos.map(product => {
                    if (!`${product.codigo}`.includes('#')) {
                      const contaisMagnitude = productsMagnitudes.find(
                        magnitude => magnitude.codproduto === product.codigo,
                      );

                      const productSelected = this.props.selectedProducts.find(
                        ({ codigo }) => codigo === product.codigo,
                      );

                      const item = {
                        ...product,
                        type_magnitudes: contaisMagnitude
                          ? MAGNITUDES_MANY_PRODUCT
                          : MAGNITUDES_ONLY_PRODUCT,
                      };
                      return (
                        <ProductList
                          editable
                          key={item.codigo}
                          item={item}
                          cart={cart}
                          config={config.paramsStoker}
                          configLib={config.lib}
                          itemUpdate={itemUpdate}
                          onPressObservations={onPressObservations}
                          onPressConditionsSalesChild={
                            onPressConditionsSalesChild
                          }
                          onPressConditionsPriceTerm={
                            onPressConditionsPriceTerm
                          }
                          addItem={addItem}
                          itemClick={productClick}
                          isSelected={!!productSelected}
                          isSelectionMode={activeProductsGrouping}
                          onSelectProduct={onSelectProduct}
                          showProductAsHTML={showProductAsHTML}
                          onLongPress={this.onLongPress}
                          appendProductGrouping={this.appendProductGrouping}
                        />
                      );
                    }

                    return null;
                  })}
              </View>
            </IntlProvider>
            {giftsView}
          </View>
        </ScrollView>

        {activeProductsGrouping && selectedProducts.length ? (
          <View
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: this.state.FABOpened ? null : '#fff',
            }}>
            <Actions onPress={toggleOperationGroupModal} />
          </View>
        ) : null}

        {!hideMainButtons ? (
          <FloatingAction
            visible={!editionMode}
            showBackground
            floatingIcon={
              <Image source={this.state.FABOpened ? Images.iconClose : Images.iconBulletedList}
                style={{ width: 24, height: 24, tintColor: '#fff' }} />
            }
            actions={[...this.getFabOptions(), ...DEFAULT_FLOATING_OPTIONS]}
            onPressItem={onPressFloatingAction}
            color={color.primaryColor}
            overlayColor="rgba(0, 0, 0, 0.7)"
            actionsPaddingTopBottom={0}
            onClose={() => {
              this.setState({ FABOpened: false });
            }}
            onOpen={() => {
              this.setState({ FABOpened: true });
            }}
            distanceToEdge={{ horizontal: 16, vertical: 52 }}
          />
        ) : null}

        {!hideMainButtons ? (
          <View
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: this.state.FABOpened ? null : '#fff',
            }}>
            <Button
              style={{
                margin: 6,
                backgroundColor: this.state.FABOpened
                  ? color.sectionIOS
                  : color.primaryColor,
                borderRadius: 4,
              }}
              title={title}
              onPress={action}
              disabled={this.state.FABOpened}
            />
            {editionMode ? (
              <Button
                style={{ margin: 6 }}
                flat
                disabled={!orgSaleSelected.value}
                color={
                  !orgSaleSelected.value
                    ? color.grayAlmostWhite
                    : color.dangerColor
                }
                title={`${translate('discardSaleCondition')}`}
                onPress={this.onApplyConditionSalePrevious.bind(this)}
              />
            ) : null}
          </View>
        ) : null}

        <BottomSheet
          ref={ref => {
            this.OperationByGroupRef = ref;
          }}
          height={450}
          onClose={this.props.onClose}>
          <OperationByGroup
            salesConditionsChild={salesConditionsChild}
            operationsConfig={operationsConfig ? operationsConfig.options : []}
            onPressTableOrDeadline={() => {
              this.OperationByGroupRef.close();
              this.ProductListRef.close();
              onPressConditionsSalesChild({}, 'operation_by_group');
            }}
            onConfirm={resp => {
              this.OperationByGroupRef.close();
              this.ProductListRef.close();
              onApplyOperationByGroup(resp);
            }}
            onRemoveItems={() => {
              this.OperationByGroupRef.close();
              onApplyRemoveItems();
            }}
            onCancel={() => {
              this.OperationByGroupRef.close();
            }}
          />
        </BottomSheet>
        <ProductListGroup
          items={selectedProducts}
          setRef={ref => {
            this.ProductListRef = ref;
          }}
          configImageSouce={this.props.config.paramsStoker}
          onPressRemove={product => onSelectProduct(product)}
          onPressRemoveAll={() => {
            if (onSelectProducts) {
              onSelectProducts();
            }
            this.ProductListRef.close();
          }}
          onPressConfirm={() => {
            this.ProductListRef.close();
            this.OperationByGroupRef.open();
          }}
        />
      </View>
    );
  }
}

Resume.propTypes = {
  comesFromClientDetail: PropTypes.bool,
  cart: PropTypes.any,
  genericViewObj: PropTypes.any,
  listOrgSale: PropTypes.any,
  orgSaleSelected: PropTypes.any,
  listTypeSale: PropTypes.any,
  typeSaleSelected: PropTypes.any,
  listMeios: PropTypes.any,
  meioSaleSelected: PropTypes.any,
  listPrazos: PropTypes.any,
  prazoSaleSelected: PropTypes.any,
  listTablePrices: PropTypes.any,
  tablePriceSaleSelected: PropTypes.any,
  listCustomersInvoice: PropTypes.any,
  customersInvoiceSelected: PropTypes.any,
  listShippings: PropTypes.any,
  shippingSelected: PropTypes.any,
  produtos: PropTypes.any,
  productsQuantity: PropTypes.any,
  productsMagnitudes: PropTypes.any,
  editionMode: PropTypes.bool,
  config: {
    paramsStoker: {
      url_stocker: PropTypes.string,
      token_stocker: PropTypes.string,
    },
    verba_hablitado: PropTypes.bool,
    configLib: PropTypes.any,
    lib: PropTypes.any,
    decimalPrecisionConfig: PropTypes.any,
  },
  total: PropTypes.any,
  client: PropTypes.any,
  itemUpdate: PropTypes.any,
  loading: PropTypes.bool,
  showSearch: PropTypes.any,  
  location: PropTypes.any,
  allowChangeClient: PropTypes.bool,
  showPrazoByTablePrice: PropTypes.bool,
  listPrazosByTablePrice: PropTypes.array,
  selectedProducts: PropTypes.array,
  showBackPressAlert: PropTypes.bool,
  salesConditionsChild: PropTypes.object,
  discountsView: PropTypes.object,
  shippingCompanies: PropTypes.array,
  shippingCompanySelected: PropTypes.object,
  onChangeShippingCompany: PropTypes.func,
  onApplyRemoveItems: PropTypes.func,
  onClose: PropTypes.func,
  onConfirmBackPress: PropTypes.func,
  onApplyOperationByGroup: PropTypes.func,
  onSelectProduct: PropTypes.func,
  onSelectProducts: PropTypes.func,
  onChangePrazoByTablePrice: PropTypes.func,
  onPressFinishOrder: PropTypes.func,
  onPressCancelOrder: PropTypes.func,
  onChangeOrgSale: PropTypes.func,
  onChangeTypeSale: PropTypes.func,
  onChangeMeioSale: PropTypes.func,
  onChangePrazoSale: PropTypes.func,
  onChangeTablePriceSale: PropTypes.func,
  onChangeCustomerInvoice: PropTypes.func,
  onChangeShipping: PropTypes.func,
  addItem: PropTypes.func,
  productClick: PropTypes.func,
  onPressObservations: PropTypes.func,
  onPressConditionsSalesChild: PropTypes.func,
  onListProduct: PropTypes.func,
  onChangeText: PropTypes.func,
  onLocationChange: PropTypes.func,
  onConfirmChangeClient: PropTypes.func,
  showPrazosSale: PropTypes.func,
  onApplyConditionSale: PropTypes.func,
  onApplyConditionSalePrevious: PropTypes.func,
  onPressConditionsPriceTerm: PropTypes.func,
  setDiscountTalao: PropTypes.func,
  showPriceTermConditionsBtn: PropTypes.bool,
  showPriceConditions: PropTypes.bool,
  pressedBackInEditionMode: PropTypes.bool,
  confirmBackInEditionMode: PropTypes.func,
  cancelBackInEditionMode: PropTypes.func,
  observationsAnswerd: PropTypes.bool,
};

export default Resume;