import React, { useState, useEffect, useRef, useCallback } from 'react';
import {Image, TouchableOpacity, View} from 'react-native';
import onPressHelper from '~/utils/onPressHelper';
import _ from 'lodash';
import ClientsModule from '~/modules/ClientsModule';
import { FlashList } from '@shopify/flash-list';
import ProcessListItem from './ClientProcessItem/ClientProcessItem';
import CachePolistore from '~/controllers/Accounts/Cache/CachePolistore';

import PropTypes from 'prop-types';
import AddressAltCache from "~/cache/AddressAltCache";
import {setAddressSelected as setAddressAlternative} from "~/store/addressAlternativeFetch/slice";
import Images from "@assets/Images";
import CacheAccount from '~/controllers/Accounts/Cache/CacheAccount';

function ClientProcessContainer({
                                    componentId,
                                    navigation,
                                    route,
                                }) {
    const [processes, setProcesses] = useState([]);

    useEffect(() => {
        loadProcesses().then();
        navigation.setOptions({
            title: _.get(route.params.client, 'nome', ''),
            headerLeft: () => (
                <TouchableOpacity onPress={async () => {
                    navigation.goBack();
                }}
                >
                    <Image
                        source={Images.iconBack}
                        style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
                    />
                </TouchableOpacity>
            ),
        });
    }, []);

    const loadProcesses = useCallback(
        async () => {
            const cProcesses = _.get(route?.params?.client, 'process', []);
            const contextId = _.get(route?.params?.client, 'id_contexto', '');
            const pkey = _.get(route?.params?.client, 'pkey');

            const userData = await new CacheAccount().getDataAccount() || '{}';
            const codMaq = userData?.user?.cod_maq || null;

            if (!codMaq) {
                console.log('codMaq not found');
                return;
            }

            const params = cProcesses.map(({ id }) => ({
                id_creator: codMaq,
                id_parent: 0,
                date: route?.params?.dateSelected,
                id_context: contextId,
                id_process: id,
                key_context: pkey,
            }));

            try {
                const response = await ClientsModule.getProcessList(
                    JSON.stringify(params),
                );
                const parsedResponse = JSON.parse(response);
                const mergedProcess = cProcesses.map(process => {
                    const processInfo =
                        _.find(parsedResponse, { process_id: process.id }) || {};

                    const {
                        total_activities: activitiesLength,
                        total_activities_answered: activitiesAnswered,
                    } = processInfo;

                    const progress =
                        Number(activitiesAnswered) / Number(activitiesLength);

                    return {
                        ...process,
                        progress,
                        activitiesLength,
                        activitiesAnswered,
                    };
                });
                setProcesses(mergedProcess);
            } catch (err) {
                setProcesses(processes);
                console.log('loadProcesses err', err);
            }
        },
        [route?.params?.client, route?.params?.dateSelected, processes],
    );

    const onPressProcess = async ({ id }) => {
        route?.params?.handleScreen(route?.params?.client, id, route?.params?.dateSelected);
    };

    const onPressProcessHelper = onPressHelper.debounce(onPressProcess);

    const renderItem = ({ item }) => {
        return (
            <ProcessListItem
                progress={item.progress || 0}
                activitiesLength={item.activitiesLength || 0}
                activitiesAnswered={item.activitiesAnswered || 0}
                process={item}
                onPress={onPressProcessHelper}
            />
        );
    };

    const itemSeparatorComponent = () => (
        <View
            style={{
                height: 10,
                backgroundColor: '#F0F0F0',
            }}
        />
    );

    return (
        <View
            style={{
                flex: 1,
                height: '100%',
                justifyContent: 'center',
                padding: 15,
            }}>
            <FlashList
                contentContainerStyle={{ backgroundColor: '#F0F0F0' }}
                data={processes}
                keyExtractor={(item, index) => `${item.id}${index}`}
                renderItem={renderItem}
                ItemSeparatorComponent={itemSeparatorComponent}
                estimatedListSize={processes?.length}
                estimatedItemSize={390}
            />
        </View>
    );
}

export default ClientProcessContainer;