import { StyleSheet } from 'react-native';
import { color } from '~/common';


const styleDeleteItems = StyleSheet.create({
  container: {
    display: 'flex',
    marginTop: 10,
    marginLeft: 10,
  },
  text: {
    fontSize: 16,
    color: color.grayRed,
    tintColor: color.grayRed,
  },

});

export { styleDeleteItems };
