/* @flow */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ClientsModule from '../../modules/ClientsModule';

export const CLIENTS_FETCH_REQUEST = 'CLIENTS_FETCH_REQUEST';
export const CLIENTS_FETCH_SUCCESS = 'CLIENTS_FETCH_SUCCESS';
export const CLIENTS_FETCH_FAILURE = 'CLIENTS_FETCH_FAILURE';

export const getListClients = createAsyncThunk(
  'clients/getListClients',
  async (_, { getState }) => {
    let active = true;
    let data = '';

    const state = getState();
    const offset = state.configLibFetch.payload.tam_memoria_paginacao;
    if (!offset) {
      throw new Error('Invalid offset');
    }
    const pagination = { page: 0, offset, active: true };
    while (active) {
      const resp = await ClientsModule.getListClients(
        JSON.stringify(pagination),
      );
      data = `${data}${resp}`;
      if (!(resp.length > 0)) {
        active = false;
      }

      pagination.page += 1;
    }
    return JSON.parse(data || '[]');
  },
);

const initialState = {
  type: '',
  loading: false,
  erro: false,
  payload: [],
};

const clientsfetchslice = createSlice({
  name: 'clientsFetch',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getListClients.pending, state => {
      state.type = CLIENTS_FETCH_REQUEST;
      state.loading = true;
    });
    builder.addCase(getListClients.fulfilled, (state, action) => {
      state.type = CLIENTS_FETCH_SUCCESS;
      state.loading = false;
      state.erro = false;
      state.payload = action.payload;
    });
    builder.addCase(getListClients.rejected, (state, action) => {
      state.type = CLIENTS_FETCH_FAILURE;
      state.loading = false;
      state.erro = true;
      state.payload = [];
    });
  },
});

const { reducer } = clientsfetchslice;

export default reducer;
