/* @flow */

import React, { Component } from 'react';
import {
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import _ from 'lodash';
import Field from './Field/Field';
import ZipCode from './Field/ZipCode';
import types from './types';
import DateTime from './Field/DateTime';
import { fonts } from '../../utils/Fonts';
import { colors } from '../../../components_base/src/components/Banners/styles';
import { formatQSQLDataSource, formatDateFields } from '../../utils/Form';
import {launchCamera, launchImageLibrary} from 'react-native-image-picker';
import Option from '~/screens/Activities/components/Option';
import translate from '~/locales';
import Images from '~/../assets/Images';
import moment from 'moment';
import { FlashList } from '@shopify/flash-list';
import EmptyView from '../EmptyView';
import getQueryResult from '~/services/resources/libFastSeller/genericQuery';
import executeActionOrOpenScreenMessage from '../../utils/messages';

import PropTypes from 'prop-types';

const styles = {
  ...StyleSheet.create({
    Form: {
      margin: 16,
      minHeight: 20,
    },
    Text__Required: {
      color: colors.grayDark,
      fontFamily: fonts.QuicksandBoldItalic,
    },
  }),
};

function orderData(a, b) {
  const posA = parseInt(a.position);
  const posB = parseInt(b.position);
  if (posA < posB) {
    return -1;
  }
  if (posA > posB) {
    return 1;
  }
  return 0;
}

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      getData: false,
      fields: [],
    };
    this.focusNextField = this.focusNextField.bind(this);
    this.inputs = {};
    this.sources = {};
    this.listPhotos = [];
  }

  componentDidMount() {
    const { data, initialValues = {} } = this.props;

    if (data && data.length > 0) {
      const fields = [...data]
        .sort(orderData)
        .map(field => this.getTypedField(field));
      this.setState({ fields, ...initialValues });
    }
  }

  listEmptyComponent = () => (
    <EmptyView
      icon={Images.iconBulletedList}
      message={`${translate('noFields')}`}
    />
  );

  UNSAFE_componentWillReceiveProps(nexProps) {
    if (
      nexProps.data &&
      nexProps.data.length > 0 &&
      this.state.getData === false
    ) {
      const max = nexProps.data.reduce(
        (prev, current) => (prev.y > current.y ? prev : current),
      );
      nexProps.data.forEach(f => {
        const prevValue = this.state[f.field];
        this.setState({ [f.field]: f.value || prevValue || '' });
      });

      this.setState({ max, getData: true }, async () => {
        const isValid = await this.isFormValid();
        nexProps.isFormValid(isValid);
      });
    }
    if (
      nexProps.consultCNPJRequesting === false &&
      this.props.consultCNPJRequesting === true
    ) {
      const max = nexProps.data.reduce(
        (prev, current) => (prev.y > current.y ? prev : current),
      );
      nexProps.data.forEach(f => {
        const prevValue = this.state[f.field];
        this.setState({ [f.field]: f.value || prevValue || '' });
      });

      this.setState({ max, getData: true }, async () => {
        const isValid = await this.isFormValid();
        nexProps.isFormValid(isValid);
      });
    }
  }

  onChangeValue = (field, value, source = 'auto') => {
    if (this.props.filterFields) {
      this.props.filterFields(field, value);
    }
    const newValue = value && value.label ? value.value : value;
    this.sources[field] = source;
    this.setState(
      {
        [field]: newValue,
      },
      () => {
        const formData = {};
        const { fields } = this.state;
        fields.forEach(f => {
          const { key } = f;
          formData[key] =
            this.state[key] === '-' || this.state[key] === '--'
              ? ''
              : this.state[key];
        });

        if (this.props.isFormValid) {
          this.isFormValid().then(isValid => {
            this.props.isFormValid(isValid);
          });
        }

        this.props.getFormData(formData);
      },
    );
  };

  validateInputResult = async (result = {}) => {
    const parseMsg = JSON.parse(result.resultado);
    const msg = [
      {
        mensagem: parseMsg[0].message,
      },
    ];
    executeActionOrOpenScreenMessage(msg, () => {}, () => {});
  };

  onBlur = (hasError, field, value) => {
    const errors = {
      ...this.state.errors,
      [field]: hasError,
    };
    this.setState({ errors }, async () => {
      if (this.props.isFormValid) {
        const isValid = await this.isFormValid();
        this.props.isFormValid(isValid);
      }
      if (this.props.validFields && this.props.validFields.length > 0) {
        if (this.props.validFields.includes(field)) {
          const result = await getQueryResult(
            'SALV;PCAD;VALI',
            JSON.stringify({
              s_nome: value,
            }),
          );
          let t = JSON.parse(result);
          this.validateInputResult(t[0]);
        }
      }
    });
  };

  onPopulate = values => {
    Object.entries(values).forEach(([key, value]) => {
      this.onChangeValue(key, value);
    });
  };

  convertBlobToBase64 = (blob, field) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        this.listPhotos.push({photo: reader.result, field});
      };
      reader.readAsDataURL(blob);
    });
  };

  getImgSource = async (response, field) => {
    const convertToBlob = await fetch(response.assets[0].uri).then(response => response.blob());
    this.convertBlobToBase64(convertToBlob, field);
        return {
          uri: convertToBlob,
          isStatic: true,
          fileName: response.assets[0].fileName || 'imagename.png',
          filePath: 'file://fakepath/imagename.png',
          type: convertToBlob.type,
        };
  };

  getTypedField(field) {
    const {
      max_length: maxLength,
      limit_max: limitMax,
      status_v1: statusV1,
    } = field;
    let editable =
      statusV1 !== undefined && statusV1 !== null && statusV1 !== -1
        ? statusV1 === 1 || Boolean(statusV1)
        : true;

    editable = field.type === types.RESULT.name ? false : editable;

    const value = this.state[field.field]
      ? this.state[field.field]
      : field.value;

    const props = {
      position: field.position,
      field: field.field,
      onChangeValue: this.onChangeValue,
      onBlur: e => this.onBlur(e, field.field, value),
      maxLength: parseInt(maxLength || limitMax || 200),
      title: field.title || field.titulo || field.description,
      value: this.state[field.field] ? this.state[field.field] : field.value,
      source: this.sources[field.field] || 'auto',
      type: field.type,
      required: field.required ? field.required : false,
      params: field.s_param || '',
      editable,
      onEndEditing:
        this.props.whoTypesInEndEditing &&
        this.props.whoTypesInEndEditing[field.type]
          ? this.props.onEndEditing
          : null,
    };

    const consultCNPJRequesting = _.get(
      this.props,
      'consultCNPJRequesting',
      false,
    );
    switch (field.type) {
      case types.COMBO.name:
        return (
          <Field.Combo
            onRef={ref => {
              this.inputs[field.position] = ref;
            }}
            onSubmitEditing={() => {
              this.focusNextField(field.position);
            }}
            max={this.state.max}
            key={field.field}
            value={field.value}
            data_source={field.data_source}
            {...props}
            style={{ marginTop: 16 }}
          />
        );
      case types.SQL.name:
        return (
          <Field.Combo
            onRef={ref => {
              this.inputs[field.position] = ref;
            }}
            onSubmitEditing={() => {
              this.focusNextField(field.position);
            }}
            max={this.state.max}
            key={field.field}
            value={field.value}
            data_source={this.formatDataSource(field.data_source)}
            {...props}
            style={{ marginTop: 16 }}
          />
        );
      case types.LIST.name:
        return (
          <Field.List
            onRef={ref => {
              this.inputs[field.position] = ref;
            }}
            onSubmitEditing={() => {
              this.focusNextField(field.position);
            }}
            forcePropsValue
            max={this.state.max}
            key={field.field}
            value={field.value}
            data_source={`-,${field.data_source}`}
            {...props}
            style={{ marginTop: 16 }}
          />
        );
      case types.DATE.name:
      case types.DATE_HOUR.name:
      case types.HOUR.name: {
        let { date, minDate, maxDate } = formatDateFields(
          this.props.fieldsRules,
          props.value,
          props.params,
        );

        let value = props.value;
        if (props.value && !props.value.includes('/') && props.value.length > 7) {
          value = moment.unix(props.value, 'x').format('D/M/YYYY');
        }

        return (
          <DateTime
            onRef={ref => {
              this.inputs[field.position] = ref;
            }}
            onSubmitEditing={() => {
              this.focusNextField(field.position);
            }}
            max={this.state.max}
            minDate={minDate}
            initialValue={value}
            maxDate={maxDate}
            key={field.field}
            outline={Platform.OS === 'ios'}
            {...props}
            value={value}
            style={{ marginTop: 16, fontFamily: fonts.Quicksand }}
          />
        );
      }
      case types.PHOTO.name: {
        const photoField = this.listPhotos.find(
          photo => photo.field === props.field,
        );
        return (
          <View style={{ paddingHorizontal: 30 }} key={field.field}>
            <Text style={{ textAlign: 'center', marginTop: 20 }}>
              {props.title}
            </Text>
            <Option
              title={`${translate('takePicture')}`}
              source={photoField?.photo || Images.iconCamera}
              onPress={() => this.onPressCamera(props)}
              onRemove={() => this.onRemovePhoto(props)}
              containerStyle={{ margin: 20, marginBottom: 0 }}
              editable={props.editable}
            />
          </View>
        );
      }

      case types.ZIP_CODE.name: {
        return (
          <ZipCode
            onRef={ref => {
              this.inputs[field.position] = ref;
            }}
            onSubmitEditing={() => {
              this.focusNextField(field.position);
            }}
            onPopulate={this.onPopulate}
            key={field.field}
            {...props}
            value={field.value}
            fieldsMapper={field.data_source}
            style={{ marginBottom: 20, fontFamily: fonts.Quicksand }}
          />
        );
      }

      default: {
        const initialValue = this.props.initialValues?.[field.field];
        return (
          <Field
            onRef={ref => {
              this.inputs[field.position] = ref;
            }}
            onSubmitEditing={() => {
              this.focusNextField(field.position);
            }}
            max={this.state.max}
            key={field.field}
            regx={field.regx}
            {...props}
            value={field.value}
            initialValue={initialValue}
            style={{ marginBottom: 20, fontFamily: fonts.Quicksand }}
            requestCNPJ={consultCNPJRequesting}
          />
        );
      }
    }
  }

  onRemovePhoto = ({ field, onChangeValue }) => {
    this.listPhotos = this.listPhotos.filter(photo => photo.field !== field);
    onChangeValue(field, null);
  };

  onPressCamera = ({ field, onChangeValue }) => {
    const options = {
      mediaType: 'photo',
      includeBase64: false,
    };

    launchImageLibrary(options, async response => {
      if (response.didCancel) {
        console.tron.log('User cancelled image picker');
      } else if (response.error) {
        this.setState({ showModal: true });
      } else if (response.customButton) {
        console.tron.log('User tapped custom button: ', response.customButton);
      } else {
        const source = await this.getImgSource(response, field);
        onChangeValue(field, source);
      }
    });
  };

  getFormData() {
    const data = {};
    const { fields } = this.state;
    fields.forEach(field => {
      const { props, key } = field;
      const { position } = props;
      const indice = (position < 10 ? '0' : '') + position;
      const value = this.state[key] ? this.state[key] : '';
      data[`campo${indice}`] = value;
    });
    return data;
  }

  formatDataSource = dataSource => {
    if (this.props.formatQSQLDataSource) {
      return this.props.formatQSQLDataSource(dataSource);
    }
    return formatQSQLDataSource(dataSource);
  };

  focusNextField(position) {
    try {
      const positionToNumber = parseInt(position) + 1;
      const positionToString = `${positionToNumber}`;
      this.inputs[positionToString].focus();
    } catch (error) {
      error;
    }
  }

  async isFormValid() {
    // eslint-disable-next-line consistent-this
    const componentInstance = this;
    return await new Promise(function(resolve) {
      setTimeout(() => {
        const { errors } = componentInstance.state;
        let countError = 0;
        Object.keys(errors).forEach(key => {
          if (errors[key] === true) {
            countError += 1;
          }
        });

        let countNotExists = 0;

        const values = { ...componentInstance.state };

        componentInstance.props.data.forEach(field => {
          if (
            ['', '-', '--', undefined].includes(values[field.field]) &&
            field.type !== types.RESULT.name
          ) {
            countNotExists += 1;
            if (field.required) {
              countError += 1;
            }
          }
        });
        resolve(
          countNotExists < componentInstance.props.data.length &&
            countError === 0,
        );
      }, 100);
    });
  }

  render() {
    const { data } = this.props;

    const dataCopy = [...data];

    return (
      <KeyboardAvoidingView style={this.props.style}>
        <View style={[styles.Form, this.props.style]}>
          {!this.props.hideRequiredLabelInfo && (
            <Text style={styles.Text__Required}>
              {translate('fieldsWithAreMandatory')}
            </Text>
          )}
          <FlashList
            estimatedItemSize={200}
            data={dataCopy.sort(orderData)}
            keyExtractor={item =>
              item.position ? item.position.toString() : item.field.toString()
            }
            renderItem={({ item: field }) => this.getTypedField(field)}
            ListEmptyComponent={this.listEmptyComponent}
            extraData={this.props}
          />
        </View>
      </KeyboardAvoidingView>
    );
  }
}

Form.propTypes = {
    data: PropTypes.array,
    initialValues: PropTypes.object,
    hideRequiredLabelInfo: PropTypes.bool,
    isFormValid: PropTypes.func,
    getFormData: PropTypes.func,
    filterFields: PropTypes.func,
    formatQSQLDataSource: PropTypes.func,
    onEndEditing: PropTypes.func,
    fieldsRules: PropTypes.array,
    style: PropTypes.object,
    consultCNPJRequesting: PropTypes.bool,
    whoTypesInEndEditing: PropTypes.object,
    validFields: PropTypes.array,
    };

Form.defaultProps = {
  data: [],
};

export default Form;
