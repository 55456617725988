import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCart, getOrder, getCartCoringa } from './utilsLib';
import _ from 'lodash';
import { ORDERS_COPY_FETCH_REQUEST, copyOrderFetch } from './orderCopySlice';
import {
  EDIT_CART_FETCH_FAILURE,
  EDIT_CART_FETCH_REQUEST,
  EDIT_CART_FETCH_SUCCESS,
  editOrderFetch,
} from './orderEditSlice';

import { updateItemFetch, UPDATE_ITEM_REQUEST } from './updateItemSlice';
import { saveCart, SAVE_CART_FETCH_REQUEST } from './saveCartSlice';
import { lastCart, LAST_CART_REQUEST } from './lastCartSlice';
import {
  finishCart,
  FINISH_CART_FETCH_FAILURE,
  FINISH_CART_FETCH_REQUEST,
} from './finishCartSlice';

import {
  cancelCartActiveFetch,
  CANCEL_CART_FETCH_REQUEST,
} from './orderCancelSlice';

import { updateCombo, UPDATE_COMBO_ORDER_REQUEST } from './updateComboSlice';
import {
  updateShipping,
  UPDATE_SHIPPING_ORDER_REQUEST,
} from './updateShippingSlice';
import {
  applyOperationByGroup,
  APPLY_OPERATION_BY_GROUP_FETCH,
} from './applyOperationByGroupSlice';

import {
  removeMultipleItems,
  REMOVE_MULTIPLE_ITEMS_FETCH,
} from './removeMultipleItemsSlice';

import {
  APPLY_DISCOUNT_TALAO_FETCH,
  applyDiscountTalao,
} from './applyDiscountTalaoSlice';
import {
  updateScaleProduct,
  SET_SCALE_REQUEST,
} from './updateScaleProductSlice';

import {
  updateBonificationCart,
  ORDER_UPDATE_BONIFICATION_CART,
} from './updateBonificationCartSlice';
export const ORDER_FETCH_REQUEST = 'ORDER_FETCH_REQUEST';
export const GET_CART_FETCH_REQUEST = 'GET_CART_FETCH_REQUEST';

export const ORDER_FETCH_SUCCESS = 'ORDER_FETCH_SUCCESS';
export const ORDER_FETCH_FAILURE = 'ORDER_FETCH_FAILURE';

export const orderClient = createAsyncThunk(
  'orderFetch/orderClient',
  async payload => {
    try {
      let data = [];
      if (payload.pkPcad) {
        data = await getCartCoringa(payload);
      } else {
        data = await getCart(payload);
      }
      const cart = JSON.parse(data);
      return cart;
    } catch (error) {
      console.log('error', error);
      throw new Error(error.message);
    }
  },
);

export const getCartFetch = createAsyncThunk(
  'orderFetch/getCartFetch',
  async payload => {
    try {
      const order = await getOrder(payload);
      return JSON.parse(order);
    } catch (error) {
      console.log('error', error);
      return error;
    }
  },
);

const initialState = {
  type: '',
  loading: false,
  generalLoading: false,
  erro: false,
  screen: '',
  ref: '',
  payload: {
    codigo: 0,
    condvenda: {
      codcliente: '0',
      codmeio: '0',
      codprazo: '0',
      orgvenda: '1',
      tabelapreco: '0',
      tipovenda: '0',
    },
    total: 0,
    produtos: [],
  },
  fallback_payload: undefined,
  canOpenOrder: true,
  forceUpdatelistComb: false
};

const prepareOrderPayload = (state, action, considerQuestions = false) => {
  let { payload } = action;
  const messages = _.get(payload, 'mensagens', []);
  const messagesErrors = messages.filter(
    m =>
      m.tipo === 'erro' ||
      (m.tipo === 'question' &&
        m.codigo === 'salv_pedc_tvar' &&
        considerQuestions),
  );

  if (messagesErrors && messagesErrors.length > 0) {
    const condSaleInvalid = messagesErrors.find(
      m => m.codigo === 'condicoes_venda_invalida',
    );
    const outOfStockByOperationGroup = messagesErrors.find(
      m =>
        m.codigo === 'out_of_stock' &&
        m.contexto.includes('fslib_core_pedido_aplicar_operacao_por_grupo'),
    );
    if (condSaleInvalid) {
      payload.condvenda = {
        codcliente: '0',
        codmeio: '0',
        codprazo: '0',
        orgvenda: '0',
        tabelapreco: '0',
        tipovenda: '0',
      };
    } else if (!outOfStockByOperationGroup) {
      payload = state.payload;
      payload.mensagens = messages;
    }
  }
  if (state.payload.produtos) {
    const productsHtml = {};
    state.payload.produtos?.forEach(produto => {
      productsHtml[produto.chave] = produto.html;
    });

    const newProductsWithHtml = payload.produtos?.map(produto => {
      const html = productsHtml[produto.chave] || produto.html;
      return {
        ...produto,
        html,
      };
    });
    payload.produtos = newProductsWithHtml;
  }
  return payload;
};

export const orderFetchSlice = createSlice({
  name: 'orderFetch',
  initialState,
  reducers: {
    updateStatusOpenOrder: (state, action) => {
      state.canOpenOrder = action.payload;
    },
    updateOrderScreen: (state, action) => {
      state.screen = action.payload;
    },
    cleanFallbackPayload: state => {
      delete state.fallback_payload;
    },
    updateProductHTML: (state, action) => {
      const { chave, html } = action.payload;
      if (state.payload.produtos) {
        const newProdutos = state.payload.produtos.map(produto => {
          if (produto.chave === chave) {
            return {
              ...produto,
              html,
            };
          }
          return produto;
        });
        state.payload.produtos = newProdutos;
      }
    },
    forceUpdatelistComb: (state, action) => {
      state.forceUpdatelistComb = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(orderClient.pending, state => {
      state.type = ORDER_FETCH_REQUEST;
      state.loading = true;
      state.generalLoading = true;
    });
    builder.addCase(orderClient.fulfilled, (state, action) => {
      state.type = ORDER_FETCH_SUCCESS;
      state.loading = false;
      state.generalLoading = false;
      state.erro = false;
      state.payload = prepareOrderPayload(state, action);
    });
    builder.addCase(orderClient.rejected, (state, action) => {
      state.type = ORDER_FETCH_FAILURE;
      state.loading = false;
      state.generalLoading = false;
      state.erro = true;
      state.payload = action.payload;
    });
    builder.addCase(copyOrderFetch.pending, state => {
      state.type = ORDERS_COPY_FETCH_REQUEST;
      state.loading = true;
      state.generalLoading = true;
    });
    builder.addCase(copyOrderFetch.fulfilled, (state, action) => {
      const { mensagens } = action.payload;
      const order = { ...action.payload, mensagens };
      state.type = ORDER_FETCH_SUCCESS;
      state.loading = false;
      state.generalLoading = false;
      state.erro = false;
      state.payload = order;
    });
    builder.addCase(copyOrderFetch.rejected, (state, action) => {
      state.type = ORDER_FETCH_FAILURE;
      state.loading = false;
      state.generalLoading = false;
      state.erro = true;
      state.payload = action.payload;
    });
    builder.addCase(editOrderFetch.pending, state => {
      state.type = EDIT_CART_FETCH_REQUEST;
      state.loading = true;
      state.generalLoading = true;
    });
    builder.addCase(editOrderFetch.fulfilled, (state, action) => {
      state.type = EDIT_CART_FETCH_SUCCESS;
      state.loading = false;
      state.generalLoading = false;
      state.erro = false;
      state.payload = action.payload;
    });
    builder.addCase(editOrderFetch.rejected, (state, action) => {
      state.type = EDIT_CART_FETCH_FAILURE;
      state.loading = false;
      state.generalLoading = false;
      state.erro = true;
      state.payload = action.payload;
    });
    // updateItemFetch
    builder.addCase(updateItemFetch.pending, (state, action) => {
      state.type = UPDATE_ITEM_REQUEST;
      state.loading = true;
      state.generalLoading = false;
    });
    builder.addCase(updateItemFetch.fulfilled, (state, action) => {
      state.type = ORDER_FETCH_SUCCESS;
      state.loading = false;
      state.generalLoading = false;
      state.erro = false;
      state.payload = prepareOrderPayload(state, action);
    });
    builder.addCase(updateItemFetch.rejected, (state, action) => {
      state.type = ORDER_FETCH_FAILURE;
      state.loading = false;
      state.generalLoading = false;
      state.erro = true;
      state.payload = action.payload;
    });

    builder.addCase(saveCart.pending, state => {
      state.type = SAVE_CART_FETCH_REQUEST;
      state.loading = true;
      state.generalLoading = true;
    });
    builder.addCase(saveCart.fulfilled, (state, action) => {
      state.type = ORDER_FETCH_SUCCESS;
      state.loading = false;
      state.generalLoading = false;
      state.erro = false;
      state.payload = prepareOrderPayload(state, action, true);
    });
    builder.addCase(saveCart.rejected, (state, action) => {
      state.type = ORDER_FETCH_FAILURE;
      state.loading = false;
      state.generalLoading = false;
      state.erro = true;
      state.payload = action.payload;
    });
    // lastCart
    builder.addCase(lastCart.pending, state => {
      state.type = LAST_CART_REQUEST;
      state.generalLoading = true;
      state.loading = true;
    });
    builder.addCase(lastCart.fulfilled, (state, action) => {
      state.type = ORDER_FETCH_SUCCESS;
      state.loading = false;
      state.generalLoading = false;
      state.erro = false;
      state.payload = prepareOrderPayload(state, action);
    });
    builder.addCase(lastCart.rejected, (state, action) => {
      state.type = ORDER_FETCH_FAILURE;
      state.loading = false;
      state.generalLoading = false;
      state.erro = true;
      state.payload = action.payload;
    });
    // finishCart
    builder.addCase(finishCart.pending, state => {
      state.type = FINISH_CART_FETCH_REQUEST;
      state.loading = true;
      state.generalLoading = true;
    });
    builder.addCase(finishCart.fulfilled, (state, action) => {
      state.type = ORDER_FETCH_SUCCESS;
      state.loading = false;
      state.generalLoading = false;
      state.erro = false;
      state.fallback_payload = state.payload;
      state.payload = prepareOrderPayload(state, action, true);
    });
    builder.addCase(finishCart.rejected, (state, action) => {
      state.type = FINISH_CART_FETCH_FAILURE;
      state.loading = false;
      state.generalLoading = false;
      state.erro = true;
      state.payload = action.payload;
    });
    // updateCombo
    builder.addCase(updateCombo.pending, state => {
      state.type = UPDATE_COMBO_ORDER_REQUEST;
      state.loading = true;
      state.generalLoading = true;
    });
    builder.addCase(updateCombo.fulfilled, (state, action) => {
      state.type = ORDER_FETCH_SUCCESS;
      state.loading = false;
      state.generalLoading = false;
      state.erro = false;
      state.payload = prepareOrderPayload(state, action);
    });
    builder.addCase(updateCombo.rejected, (state, action) => {
      state.type = ORDER_FETCH_FAILURE;
      state.loading = false;
      state.generalLoading = false;
      state.erro = true;
      state.payload = action.payload;
    });
    // updateShipping
    builder.addCase(updateShipping.pending, state => {
      state.type = UPDATE_SHIPPING_ORDER_REQUEST;
      state.loading = true;
      state.generalLoading = true;
    });
    builder.addCase(updateShipping.fulfilled, (state, action) => {
      state.type = ORDER_FETCH_SUCCESS;
      state.loading = false;
      state.generalLoading = false;
      state.erro = false;
      state.payload = prepareOrderPayload(state, action);
    });
    builder.addCase(updateShipping.rejected, (state, action) => {
      state.type = ORDER_FETCH_FAILURE;
      state.loading = false;
      state.generalLoading = false;
      state.erro = true;
      state.payload = action.payload;
    });
    // applyOperationByGroup
    builder.addCase(applyOperationByGroup.pending, state => {
      state.type = APPLY_OPERATION_BY_GROUP_FETCH;
      state.loading = true;
      state.generalLoading = true;
    });
    builder.addCase(applyOperationByGroup.fulfilled, (state, action) => {
      state.type = ORDER_FETCH_SUCCESS;
      state.loading = false;
      state.generalLoading = false;
      state.erro = false;
      state.payload = prepareOrderPayload(state, action);
    });
    builder.addCase(applyOperationByGroup.rejected, (state, action) => {
      state.type = ORDER_FETCH_FAILURE;
      state.loading = false;
      state.generalLoading = false;
      state.erro = true;
      state.payload = action.payload;
    });
    // removeMultipleItems
    builder.addCase(removeMultipleItems.pending, state => {
      state.type = REMOVE_MULTIPLE_ITEMS_FETCH;
      state.loading = true;
      state.generalLoading = true;
    });
    builder.addCase(removeMultipleItems.fulfilled, (state, action) => {
      state.type = ORDER_FETCH_SUCCESS;
      state.loading = false;
      state.generalLoading = false;
      state.erro = false;
      state.payload = prepareOrderPayload(state, action);
    });
    builder.addCase(removeMultipleItems.rejected, (state, action) => {
      state.type = ORDER_FETCH_FAILURE;
      state.loading = false;
      state.generalLoading = false;
      state.erro = true;
      state.payload = action.payload;
    });
    // removeMultipleItems
    builder.addCase(applyDiscountTalao.pending, state => {
      state.type = APPLY_DISCOUNT_TALAO_FETCH;
      state.loading = true;
    });
    builder.addCase(applyDiscountTalao.fulfilled, (state, action) => {
      state.type = ORDER_FETCH_SUCCESS;
      state.loading = false;
      state.generalLoading = false;
      state.erro = false;
      state.payload = prepareOrderPayload(state, action);
    });
    builder.addCase(applyDiscountTalao.rejected, (state, action) => {
      state.type = ORDER_FETCH_FAILURE;
      state.loading = false;
      state.generalLoading = false;
      state.erro = true;
      state.payload = action.payload;
    });
    // updateScaleProduct
    builder.addCase(updateScaleProduct.pending, state => {
      state.type = SET_SCALE_REQUEST;
      state.loading = true;
      state.generalLoading = false;
    });
    builder.addCase(updateScaleProduct.fulfilled, (state, action) => {
      state.type = ORDER_FETCH_SUCCESS;
      state.loading = false;
      state.generalLoading = false;
      state.erro = false;
      state.payload = prepareOrderPayload(state, action);
    });
    builder.addCase(updateScaleProduct.rejected, (state, action) => {
      state.type = ORDER_FETCH_FAILURE;
      state.loading = false;
      state.generalLoading = false;
      state.erro = true;
      state.payload = action.payload;
    });
    // updateBonificationCart
    builder.addCase(updateBonificationCart.pending, state => {
      state.type = ORDER_UPDATE_BONIFICATION_CART;
      state.loading = true;
      state.generalLoading = true;
    });
    builder.addCase(updateBonificationCart.fulfilled, (state, action) => {
      state.type = ORDER_FETCH_SUCCESS;
      state.loading = false;
      state.generalLoading = false;
      state.erro = false;
      state.payload = prepareOrderPayload(state, action);
    });
    builder.addCase(updateBonificationCart.rejected, (state, action) => {
      state.type = ORDER_FETCH_FAILURE;
      state.loading = false;
      state.generalLoading = false;
      state.erro = true;
      state.payload = action.payload;
    });
    // getOrderFetch
    builder.addCase(getCartFetch.pending, state => {
      state.type = GET_CART_FETCH_REQUEST;
      state.loading = true;
      state.generalLoading = true;
    });
    builder.addCase(getCartFetch.fulfilled, (state, action) => {
      state.type = ORDER_FETCH_SUCCESS;
      state.loading = false;
      state.generalLoading = false;
      state.erro = false;
      state.payload = prepareOrderPayload(state, action);
    });
    builder.addCase(getCartFetch.rejected, (state, action) => {
      state.type = ORDER_FETCH_FAILURE;
      state.loading = false;
      state.generalLoading = false;
      state.erro = true;
      state.payload = action.payload;
    });

    // cancelCartActiveFetch
    builder.addCase(cancelCartActiveFetch.pending, state => {
      state.type = CANCEL_CART_FETCH_REQUEST;
      state.loading = true;
      state.generalLoading = true;
    });
    builder.addCase(cancelCartActiveFetch.fulfilled, (state, action) => {
      state.type = ORDER_FETCH_SUCCESS;
      state.loading = false;
      state.generalLoading = false;
      state.erro = false;
      state.payload = prepareOrderPayload(state, action);
    });
    builder.addCase(cancelCartActiveFetch.rejected, (state, action) => {
      state.type = ORDER_FETCH_FAILURE;
      state.loading = false;
      state.generalLoading = false;
      state.erro = true;
      state.payload = action.payload;
    });
  },
});

export const {
  updateStatusOpenOrder,
  updateOrderScreen,
  cleanFallbackPayload,
  updateProductHTML,
  forceUpdatelistComb
} = orderFetchSlice.actions;

const { reducer } = orderFetchSlice;

export default reducer;