import { StyleSheet } from 'react-native';

import { color } from '../../common';

const styles = StyleSheet.create({
  listContainer: {
    flex: 1,
    padding: 14,
  },
  listTitle: {
    fontSize: 16,
    marginBottom: 20,
    color: '#666',
    fontWeight: '600',
  },
  listButton: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
  },
  listLabel: {
    fontSize: 16,
  },
  separator: {
    height: 0.6,
    backgroundColor: color.grayContainerDark,
  },
});

export default styles;
