import React, { Component } from 'react';
import { Modal, View } from 'react-native';
import Button from '../Button';
import Images from '../../../../assets/Images';
import WebView from 'react-native-webview';
import styles from './styles';

import PropTypes from 'prop-types';

class MoreInfo extends Component {
  render() {
    const { modalMoreInfosVisible, htmlContent, onModalClose } = this.props;

    return (
      <View>
        <Modal
          animationType="slide"
          transparent
          visible={modalMoreInfosVisible}
          onRequestClose={onModalClose}>
          <View style={styles.container}>
            <View style={styles.htmlView}>
              <WebView
                source={{ html: `${htmlContent}` }}
                scalesPageToFit={false}
                scrollEnabled
              />
            </View>
          </View>

          <Button
            containerStyle={styles.buttonClose}
            iconLeft={Images.iconClose}
            iconButton
            onPress={onModalClose}
          />
        </Modal>
      </View>
    );
  }
}

MoreInfo.propTypes = {
    modalMoreInfosVisible: PropTypes.bool.isRequired,
    htmlContent: PropTypes.string.isRequired,
    onModalClose: PropTypes.func.isRequired,
};

export default MoreInfo;
