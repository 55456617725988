import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  positionAbsolute: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fff',
  },
  buttonContainer: {
    marginHorizontal: 16,
    marginTop: 5,
    marginBottom: 5,
  },
});

export default styles;
