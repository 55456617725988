import React, { useEffect, useState, useRef } from 'react';
import { Image, StyleSheet, TouchableOpacity } from 'react-native';
import { Provider } from 'react-redux';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import AuthRoutes from './src/routes/auth.routes';
import MyTabs from './src/routes/bottomtabs.routes';

import store from './src/store/index';

import isSignedIn from './src/utils/AuthWeb';
import { NavigationContainer, useNavigationContainerRef} from '@react-navigation/native';
import { navigationRef } from './src/routes/RootNavigation';
import Alert from '~/components/Web/Alert';
import KeyBindingsModal from '~/components/ModalKeyBindingsMap/KeyBindingsModal';
import Images from '@assets/Images';
import { color } from '~/common';

import { fnCurrentScreen } from '~/utils/KeyBindingsUtils';

const App = () => {
  const [signedIn, setSignedIn] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [actualScreen, setActualScreen] = useState('Login');

  const navigationContainerRef = useNavigationContainerRef();
  const routeNameRef = useRef();

  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);

  useEffect(() => {
    isSignedIn()
      .then((res) => {
        if (res) setSignedIn(true);
      })
      .catch(console.error);

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };


    const handlePopState = (event) => {
      if (!window.confirm('Você realmente deseja sair ou voltar?')) {
        window.history.pushState(null, '', window.location.href);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    fnCurrentScreen(actualScreen);
  }, [actualScreen]);

  return (
    <Provider store={store}>
      <NavigationContainer
        ref={(ref) => {
          navigationRef.current = ref;
          navigationContainerRef.current = ref;
        }}
        onReady={() => {
          routeNameRef.current = navigationContainerRef.getCurrentRoute().name;
        }}
        onStateChange={() => {
          const currentRouteName = navigationContainerRef.getCurrentRoute().name;
          if (currentRouteName !== actualScreen) {
            setActualScreen(currentRouteName);
            fnCurrentScreen(currentRouteName);
          }
        }}
      >
        {signedIn ? MyTabs() : AuthRoutes()}
        <Alert />
        <ToastContainer autoClose={2000} />
        <KeyBindingsModal visible={modalVisible} onClose={closeModal} screen={actualScreen} />
        <TouchableOpacity style={styles.floatingButton} onPress={openModal}>
          <Image source={Images.webkeyboard} style={styles.icon} />
        </TouchableOpacity>
      </NavigationContainer>
    </Provider>
  );
};

const styles = StyleSheet.create({
  floatingButton: {
    position: 'absolute',
    right: 30,
    alignSelf: 'center',
    bottom: 30,
    backgroundColor: color.primaryColor,
    borderRadius: 30,
    paddingVertical: 10,
    paddingHorizontal: 15,
    elevation: 5,
  },
  icon: {
    width: 30,
    height: 30,
    tintColor: 'white',
  },
});

export default App;
