// @flow

import React, { Component } from 'react';
import { View, TouchableHighlight, Modal } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import { ParallaxImage } from 'react-native-snap-carousel';

import styles from './styles';
import stylesFullWidth from './stylesFullWidth';

import Images from '@assets/Images';

import Image from 'react-native-android-image-polyfill';
import color from '~/common/color';
import { Button } from '../../../../components_base/src/components';

import PropTypes from 'prop-types';

class BannerImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalExpandImageVisible: false,
    };
  }

  onPressItem = () => {
    if (this.props.onPressItem) {
      this.props.onPressItem(this.props.image, this.props.index);
    }
    this.setState({ modalExpandImageVisible: true });
  };

  getImage() {
    const {
      even,
      parallax,
      index,
      image,
      onPressItem,
      fullWidth,
      ...rest
    } = this.props;

    const imageStyles = fullWidth ? stylesFullWidth : styles;

    if (parallax) {
      return (
        <ParallaxImage
          source={image || Images.iconNoImageBig}
          containerStyle={[
            imageStyles.imageContainer,
            even ? imageStyles.imageContainerEven : {},
          ]}
          style={imageStyles.image}
          parallaxFactor={0.35}
          showSpinner
          spinnerColor={
            even ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.25)'
          }
          onError={this.loadOnError}
          {...rest}
        />
      );
    } else if (image === Images.iconNoImageBig) {
      return <Image source={image} tintColor={color.grayDark} {...rest} />;
    } else {
      return (
        <Image
          source={image || Images.iconNoImageBig}
          style={imageStyles.image}
          onError={this.loadOnError}
          {...rest}
        />
      );
    }
  }

  loadOnError = () => {
    this.setState({ image: Images.iconNoImageBig });
  };

  render() {
    const { image } = this.state;
    const { even, fullWidth, showFullImage, pressable } = this.props;
    const imageStyles = fullWidth ? stylesFullWidth : styles;

    const { modalExpandImageVisible } = this.state;

    const modalIsVisible =
      modalExpandImageVisible &&
      showFullImage &&
      image !== Images.iconNoImageBig;

    return (
      <View
        style={
          image === Images.iconNoImageBig
            ? imageStyles.overflowNoImageContainer
            : imageStyles.overflowContainer
        }>
        <Touchable
          disabled={!pressable}
          onPress={this.onPressItem}>
          <View
            style={[
              imageStyles.imageContainer,
              even ? imageStyles.imageContainerEven : { alignItems: 'center' },
            ]}>
            {this.getImage()}
          </View>
        </Touchable>

        <Modal
          animationType="slide"
          transparent
          visible={modalIsVisible}
          onRequestClose={() =>
            this.setState({ modalExpandImageVisible: false })
          }>
          <TouchableHighlight
            onPress={() => {
              this.setState({ modalExpandImageVisible: false });
            }}>
            <View
              style={{
                height: '100%',
                backgroundColor: 'rgba(0,0,0,0.8)',
              }}>
              {this.getImage()}
              <Button
                containerStyle={{
                  position: 'absolute',
                  right: 0,
                  margin: 16,
                  width: 36,
                  height: 36,
                }}
                iconLeft={Images.iconClose}
                iconButton
                onPress={() => {
                  this.setState({ modalExpandImageVisible: false });
                }}
              />
            </View>
          </TouchableHighlight>
        </Modal>
      </View>
    );
  }
}

BannerImage.propTypes = {
  image: PropTypes.any,
  index: PropTypes.number,
  fullWidth: PropTypes.bool,
  onPressItem: PropTypes.func,
  onImageClick: PropTypes.func,
  even: PropTypes.bool,
  parallax: PropTypes.bool,
  showFullImage: PropTypes.bool,
  pressable: PropTypes.bool,
};

BannerImage.defaultProps = {
  showFullImage: true,
  pressable: false,
};

export default BannerImage;
