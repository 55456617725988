import AsyncStorage from '@react-native-async-storage/async-storage';

class ActivitiesCache {
  async setVisitStatus(data) {
    await AsyncStorage.setItem('@VisitStatus:key', JSON.stringify(data));
  }
  async getVisitStatus() {
    const data = await AsyncStorage.getItem('@VisitStatus:key');
    return data ? JSON.parse(data) : {};
  }
  async removeVisitStatusKey() {
    await AsyncStorage.removeItem('@VisitStatus:key');
  }
}

export default new ActivitiesCache();
