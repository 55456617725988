import api from '../apiBaseAnywhere';

console.log('api', api);

export const callMethodApi = async (payload, token) => {
  const axiosInstance = await api();
  return axiosInstance.post('/v1/api/call-method/', payload, {
    headers: { Authorization: `FSJWT ${token}` },
  });
};

export const callGenerateReportApi = async (payload, token) => {
  const axiosInstance = await api();
  return axiosInstance.post('/v1/api/generate-report/', payload, { headers: { Authorization: `FSJWT ${token}` } },
  );
};

export default callMethodApi;
