export default class GretsManyProduct {
  constructor(magnitudes) {
    this.magnitudes = magnitudes;
  }

  list(product) {
    let magnitudeSelect = {};

    magnitudeSelect = this.magnitudes.find(magnitude => magnitude.codproduto === product.codigo);
    const magnitudes =
      this.magnitudes.filter(magnitude => magnitude.agrupamento === magnitudeSelect.agrupamento)
        .map(magnitude => ({ label: magnitude.descricao, value: magnitude.codproduto }));

    magnitudeSelect = { label: magnitudeSelect.descricao, value: magnitudeSelect.codproduto };

    return [magnitudes, magnitudeSelect];
  }

  updateMagnitude(product, productsTreeParam, cods, magnitudeSelect) {
    let productsTree = productsTreeParam;

    productsTree = cods.reduce((arr, code) =>
      arr.filter(o => o.codigo === code)[0].filhos, productsTree);

    return productsTree.find(prod => prod.codigo === magnitudeSelect.value);
  }

  getMultipleSale(product) {
    return product.multiplo_venda;
  }
}
