import React, { memo } from 'react';
import { Image } from 'react-native';
import { FloatingAction } from 'react-native-floating-action';
import { color } from '~/common';
import MultiTabsView from '~/components/MultiTabsView/MultiTabsView';
import Images from '@assets/Images';
import PropTypes from 'prop-types';

const CatalogProdDetail = memo(({
  configLib,
  navigation,
  pk_produto,
  selectedDeadline,
  selectedOrgsale,
  selectedScale,
  selectedTable,
  onPressChangeCondition,
  fabRef,
}) => {

  const params = {
    pk_produto: Number(pk_produto),
    pk_prazo: Number(selectedDeadline),
    pk_tabela: Number(selectedTable),
    pk_orgvenda: Number(selectedOrgsale),
    pk_escala: Number(selectedScale),
  };

  return (
    <>
      <MultiTabsView
        configLib={configLib}
        groupId="PDET"
        params={params}
        navigator={navigation}
        loading={false}
      />
      <FloatingAction
        overlayColor="transparent"
        floatingIcon={
          <Image source={Images.iconPlusMinus} style={{ width: 24, height: 24, tintColor: '#FFFFFF' }} />
        }
        ref={fabRef}
        onOpen={onPressChangeCondition}
        color={color.neutral700}
        actionsPaddingTopBottom={0}
        distanceToEdge={{ horizontal: 16, vertical: 32 }}
      />
    </>
  );
});

CatalogProdDetail.propTypes = {
  pk_produto: PropTypes.number,
  navigation: PropTypes.object,
  configLib: PropTypes.object,
  selectedDeadline: PropTypes.number,
  selectedOrgsale: PropTypes.number,
  selectedScale: PropTypes.number,
  selectedTable: PropTypes.number,
  onPressChangeCondition: PropTypes.func,
  fabRef: PropTypes.func,
};

export default CatalogProdDetail;