import React from 'react';
import { View, StyleSheet } from 'react-native';
import * as Progress from 'react-native-progress';
import Svg, { Circle, Text as SvgText } from 'react-native-svg';

const CircularProgressBar = ({ progress, color }) => {
  const radius = 45;
  const circumference = 2 * Math.PI * radius;
  const progressStrokeDashoffset = circumference - (circumference * progress) / 100;

  return (
    <View style={styles.container}>
      <Svg height="100" width="100">
        <Circle cx="50" cy="50" r={radius} stroke={color} strokeWidth="2" fill="transparent" />
        <Progress.Circle
          style={styles.progressCircle}
          progress={progress / 100}
          thickness={4}
          color={color}
          unfilledColor="rgba(0, 0, 0, 0.2)"
          strokeDashoffset={progressStrokeDashoffset}
          strokeDasharray={`${circumference} ${circumference}`}
        />
        <SvgText
          x="50%"
          y="50%"
          dy="4"
          fontSize="16"
          fontWeight="bold"
          textAnchor="middle"
          fill="#333"
        >
          {`${progress}%`}
        </SvgText>
      </Svg>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  progressCircle: {
    position: 'absolute',
    top: 2,
    left: 2,
  },
});

export default CircularProgressBar;