import { StyleSheet } from 'react-native';
import colors from '../../values/color';
import { fonts } from '../../../../src/utils/Fonts';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.grayContainer,
  },
  cardContainer: {
    marginTop: 4,
    marginBottom: 4,
    elevation: 1,
    padding: 12,
    backgroundColor: '#FFFFFF',
  },
  cardIcon: {
    width: 18,
    height: 18,
    tintColor: 'gray',
  },
  cardItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 1,
    backgroundColor: '#FFFFFF',
  },
  cardItemTitle: {
    fontFamily: fonts.QuicksandBold,
    fontSize: 14,
    paddingRight: 8,
  },
  cardItemDescription: {
    fontSize: 12,
  },
  image: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#CFD8DD',
    height: 40,
    width: 40,
    borderRadius: 20,
    margin: 10,
  },
  iconMapMarker: {
    width: 40,
    height: 40,
    tintColor: colors.primaryColor,
  },

  contentMapInvisible: {
    height: 0,
  },
});

export default styles;
