/* @flow */

import React, { Component } from 'react';
import { Platform } from 'react-native';
import axios from 'axios';
import Masked from 'vanilla-masker';
import { TextField } from 'react-native-material-textfield';
import * as config from '../../../config/config';

import List from './List';
import Combo from './Combo';
import validation from './validation';
import translate from '../../../locales';

import PropTypes from 'prop-types';

class ZipCode extends Component {
    state = {
        value: '',
    };
    static List = List;
    static Combo = Combo;

    UNSAFE_componentWillMount() {
        this.setState({ value: this.format(this.props.value) });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value === this.props.value) {
            return;
        }
        if (nextProps.value !== this.state.value) {
            this.setState({ value: nextProps.value }, () => {
                this.onBlur();
            });
        }
    }

    componentDidMount() {
        if (this.props.onRef !== null) {
            this.props.onRef(this);
        }
    }

    onBlur = () => {
        const error = this.isValid();
        this.setState({ error }, () => {
            if (this.props.onBlur) {
                this.props.onBlur(error !== '', this.props.field);
            }
        });
    };

    onChange = (field, value) => {
        this.setState({ value: this.format(value) }, () => {
            this.props.onChangeValue(field, this.state.value, 'typed');
            this.onBlur();
            this.afterChange(value);
        });
    };

    onSubmitEditing = () => {
        if (this.props.onSubmitEditing !== null) {
            this.props.onSubmitEditing();
        }
    };

    afterChange = async value => {
        const onlyNumbers = value.replace(/\D/g, '');
        if (onlyNumbers.length === 8) {
            const { data: addr } = await axios.get(
                `https://viacep.com.br/ws/${onlyNumbers}/json/`,
            );
            if (addr.cep) {
                this.handlePopulatedValues({
                    uf: addr.uf,
                    localidade: addr.localidade,
                    bairro: addr.bairro,
                    logradouro: addr.logradouro,
                    complemento: addr.complemento,
                });
            }
        }
    };
    handlePopulatedValues = (values) => {
        const { fieldsMapper } = this.props;
        const unpackedFields = this.unpackZipCodeFields(fieldsMapper);
        const populatedValues = Object.keys(unpackedFields).reduce((acc, key) => {
            acc[key] = values[unpackedFields[key]];
            return acc;
        }, {});

        this.props.onPopulate?.(populatedValues);
    };

    unpackZipCodeFields = (mapper) => {
        // First of all get mapper and split by;
        const fields = (mapper?.trim() || '').split('|');

        // Then, for each field, get the value from state and return an object with the field name and value
        const unpackedFields = fields?.reduce((acc, field) => {
            const target = field.split('+');
            if (target.length === 2) {
                const fieldName = target[0];
                const fieldAlias = target[1];
                acc[fieldAlias] = fieldName;
            }

            return acc;
        }, {});

        return unpackedFields || {};
    };

    onEndEditing = () => {
        const { field } = this.props;
        const { value } = this.state;
        if (this.props.onEndEditing !== null) {
            if (this.props.value !== this.state.value) {
                this.props.onEndEditing({
                    field,
                    value: this.unformat(validation.RegxMatch(value, this.props.regx)),
                });
            }
        }
    };

    focus = () => {
        this.textField.focus();
    };

    isValid() {
        const { value } = this.state;
        const { required } = this.props;
        if (required && value.length === 0) {
            return `${translate('thisFieldIsRequired')}`;
        } else if (value.length > 0) {
            if (!validation.ZipCodeCheck(value)) {
                return `${translate('ZipCode')} ${translate('typedIsNotValid')}`;
            }
        }
        return '';
    }

    format(value) {
        return Masked.toPattern(value, '99999-999');
    }

    render() {
        const { title, field, required, editable } = this.props;

        return (
            <TextField
                ref={input => {
                    this.textField = input;
                }}
                required={required}
                onSubmitEditing={this.onSubmitEditing}
                returnKeyType="next"
                blurOnSubmit={false}
                containerStyle={{ marginTop: 16 }}
                label={required ? `${title} *` : title}
                error={this.state.error}
                keyboardTyp="numeric"
                maxLength={9}
                outline={Platform.OS === 'ios'}
                value={this.state.value}
                tintColor={config.Colors.primaryColor}
                onBlur={() => this.onBlur(field)}
                onChangeText={value => this.onChange(field, value)}
                editable={editable}
                onEndEditing={this.onEndEditing}
            />
        );
    }
}

ZipCode.propTypes = {
    title: PropTypes.string,
    field: PropTypes.string,
    onChangeValue: PropTypes.func,
    onBlur: PropTypes.func,
    onSubmitEditing: PropTypes.func,
    onRef: PropTypes.func,
    onEndEditing: PropTypes.func,
    value: PropTypes.string,
    required: PropTypes.bool,
    regx: PropTypes.string,
    editable: PropTypes.bool,
};

ZipCode.defaultProps = {
    format: '',
    onChangeValue: () => { },
    onSuccess: () => { },
    value: '',
};

export default ZipCode;
