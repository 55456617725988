import React, { useState, useEffect, useCallback, memo, useMemo, useRef } from 'react';
import { ActivityIndicator, Image, StyleSheet, View } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import Images from '../../../../assets/Images';
import colors from '~/common/color';
import PropTypes from 'prop-types';
import ExpandImageModal from './ExpandImageModal';
import { isEmpty } from 'lodash';

const styles = StyleSheet.create({
  imageItem: { height: 100, width: 100 },
  loading: { flex: 1, justifyContent: 'center', alignItems: 'center' },
  loadingOverlay: { 
    position: 'absolute', 
    top: 0, 
    left: 0, 
    right: 0, 
    bottom: 0, 
    justifyContent: 'center', 
    alignItems: 'center' 
  },
  productDetailContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  defaultContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  }
});

const ImageComponent = memo((props) => {
  const { 
    source: originalSource, 
    style, 
    resizeMode = 'contain', 
    onImageClick, 
    notExpand = false,
    isProductDetail = false,
    bottomBar = false
  } = props;

  const isMounted = useRef(true);
  
  const [imageState, setImageState] = useState({
    source: null,
    errorImage: false,
    hideProgress: false,
    modalExpandImageVisible: false,
    isLoading: true,
    showPlaceholder: true
  });

  const isPromise = useMemo(() => originalSource && typeof originalSource.then === 'function', [originalSource]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!isPromise) {
      setImageState(prev => ({
        ...prev,
        source: originalSource,
        isLoading: false,
        hideProgress: true,
        errorImage: false,
        showPlaceholder: !originalSource
      }));
      return;
    }
    
    const loadSource = async () => {
      try {
        const result = await originalSource;
        
        if (!isMounted.current) return;
        
        const imageUrl = result?.[0]?.original_url || 
                        (Array.isArray(result) && result[0]) || 
                        result;
        
        if (imageUrl) {
          setImageState(prev => ({
            ...prev,
            source: imageUrl,
            isLoading: false,
            hideProgress: true,
            errorImage: false,
            showPlaceholder: false
          }));
        } else {
          setImageState(prev => ({
            ...prev,
            source: null,
            isLoading: false,
            hideProgress: true,
            errorImage: true,
            showPlaceholder: true
          }));
        }
      } catch (error) {
        console.error('Erro ao carregar imagem:', error);
        if (isMounted.current) {
          setImageState(prev => ({ 
            ...prev, 
            source: null,
            errorImage: true, 
            isLoading: false,
            hideProgress: true,
            showPlaceholder: true
          }));
        }
      }
    };

    loadSource();
  }, [originalSource, isPromise]);

  const handleImageClick = useCallback(() => {
    onImageClick?.();
    if (!notExpand) setImageState(prev => ({ ...prev, modalExpandImageVisible: true }));
  }, [onImageClick, notExpand]);

  const handleLoad = useCallback(() => {
    setImageState(prev => ({ 
      ...prev, 
      hideProgress: true, 
      errorImage: false,
      showPlaceholder: false
    }));
  }, []);

  const handleError = useCallback(() => {
    setImageState(prev => ({ 
      ...prev, 
      hideProgress: true, 
      errorImage: true,
      showPlaceholder: true
    }));
  }, []);

  const closeModal = useCallback(() => {
    setImageState(prev => ({ ...prev, modalExpandImageVisible: false }));
  }, []);

  const styleImage = useMemo(() => isProductDetail
    ? { height: 200, width: 200 }
    : { height: 100, width: 100 }, [isProductDetail]);

  const finalStyle = useMemo(() => bottomBar ? style : styleImage, [bottomBar, style, styleImage]);

  const { source, errorImage, hideProgress, modalExpandImageVisible, isLoading, showPlaceholder } = imageState;
  
  const imageSource = useMemo(() => {
    return (errorImage || showPlaceholder || !source || isEmpty(source)) ?
      Images.iconProductDefault : 
      source;
  }, [errorImage, source, showPlaceholder]);


  const loadingIndicator = useMemo(() => (
    !errorImage && isLoading ? (
      <View style={[styles.loadingOverlay]}>
        <ActivityIndicator size="small" color={colors.primaryColor} />
      </View>
    ) : null
  ), [errorImage, isLoading]);


  return (
    <View style={isProductDetail ? styles.productDetailContainer : styles.defaultContainer}>
      <Touchable
        tabIndex={-1}
        disabled={errorImage || isLoading}
        style={[finalStyle]}
        onPress={handleImageClick}
      >
        <Image
          tabIndex={-1}
          onError={handleError}
          onLoad={handleLoad}
          resizeMode={resizeMode}
          style={[
            finalStyle,
            errorImage && { tintColor: colors.grayDark }
          ]}
          source={imageSource}
          fadeDuration={0}
        />
      </Touchable>
      {loadingIndicator}
      {modalExpandImageVisible && source && !errorImage && (
        <ExpandImageModal
          source={source}
          onClose={closeModal}
          resizeMode={resizeMode}
        />
      )}
    </View>
  );
});

ImageComponent.propTypes = {
  source: PropTypes.any.isRequired,
  style: PropTypes.any,
  resizeMode: PropTypes.string,
  onImageClick: PropTypes.func,
  notExpand: PropTypes.bool,
  isProductDetail: PropTypes.bool,
  bottomBar: PropTypes.bool,
};

ImageComponent.defaultProps = {
  notExpand: false,
  isProductDetail: false,
  bottomBar: false,
};

export default ImageComponent;