import axios from 'axios';
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../apiBaseAnywhere';

const COMMON_ANYWHERE_URL = 'https://anywhere-server.aws.polibr.com.br';
const CSUL_ANYWHERE_URL = 'https://csul.anywhere.polibr.com.br';

const loginAnywhere = async (username, password, company, uuid) => {
  axios.defaults.timeout = 300000;
  const dataAccount = {
    company_code: company,
    username,
    password,
    // session_uuid: '123456',
    platform_os: Platform.OS,
    session_uuid: uuid,
  };
  // save company code in local storage
  await AsyncStorage.setItem('company_code', company);
  const axiosInstance = await api();

  if (company === 'csul') {
    return axiosInstance.post(`${CSUL_ANYWHERE_URL}/v1/api/auth/`, dataAccount);
  }

  return axiosInstance.post(`${COMMON_ANYWHERE_URL}/v1/api/auth/`, dataAccount);
};

export default loginAnywhere;
