// @flow

import React from 'react';
import { View, Text } from 'react-native';
import { Button } from '../../components/index';

import commonStyles from '../../../src/appStyles';
import styles from './styles';
import { FlashList } from '@shopify/flash-list';
import PropTypes from 'prop-types';

const keyExtractor = (item, index) => `${item.id}-${index}`;

const Carousel = ({
  data,
  renderItem,
  title,
  action,
  moreInfo,
  titleStyle,
  containerStyle,
  listStyle,
  ...rest
}) => (
  <View style={containerStyle}>
    <View style={[commonStyles.spaceBetween, styles.titleContainer]}>
      {!!title && <Text style={[styles.title, titleStyle]}>{title}</Text>}
      {!!action && <Button title={action.text} onPress={action.onPress} flat />}
    </View>
    <FlashList
      estimatedItemSize={240}
      style={listStyle}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      data={data}
      horizontal
      {...rest}
    />
  </View>
);

Carousel.propTypes = {
    data: PropTypes.array.isRequired,
    renderItem: PropTypes.func.isRequired,
    title: PropTypes.string,
    action: PropTypes.shape({
        text: PropTypes.string.isRequired,
        onPress: PropTypes.func.isRequired,
    }),
    containerStyle: PropTypes.object,
    listStyle: PropTypes.object,
    titleStyle: PropTypes.object,
    initialNumToRender: PropTypes.number,
    moreInfo: PropTypes.shape({
        text: PropTypes.string.isRequired,
        onPress: PropTypes.func.isRequired,
    }),
};

Carousel.defaultProps = {
    initialNumToRender: 10,
};

export default Carousel;
