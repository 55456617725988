import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ProductsModule from '~/modules/ProductsModule';

export const SET_PRODUCT_OBSERVATIONS_REQUEST =
  'SET_PRODUCT_OBSERVATIONS_REQUEST';
export const SET_PRODUCT_OBSERVATIONS_SUCCESS =
  'SET_PRODUCT_OBSERVATIONS_SUCCESS';
export const SET_PRODUCT_OBSERVATIONS_FAILURE =
  'SET_PRODUCT_OBSERVATIONS_FAILURE';

const getProductObservation = payload =>
  new Promise((resolve, reject) => {
    const { codigo, observation, field } = payload;
    ProductsModule.setProductObservation(
      `${codigo}`,
      field,
      observation,
      resolve,
      reject,
    );
  });

const getProductObservations = observations => {
  return Promise.all(
    observations.map(observation => getProductObservation(observation)),
  );
};

export const setProductObservations = createAsyncThunk(
  'setProductObservations/fetch',
  async payload => {
    try {
      await getProductObservations(payload);
      return {};
    } catch (error) {
      console.log('error', error);
      return error;
    }
  },
);

const initialState = {
  type: '',
  loading: false,
  erro: false,
  payload: [],
};

const setProductObservationsSlice = createSlice({
  name: 'setProductObservations',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(setProductObservations.pending, state => {
        state.loading = true;
        state.type = SET_PRODUCT_OBSERVATIONS_REQUEST;
      })
      .addCase(setProductObservations.fulfilled, (state, action) => {
        state.loading = false;
        state.payload = action.payload;
        state.type = SET_PRODUCT_OBSERVATIONS_SUCCESS;
      })
      .addCase(setProductObservations.rejected, state => {
        state.loading = false;
        state.erro = true;
        state.type = SET_PRODUCT_OBSERVATIONS_FAILURE;
      });
  },
});

const { reducer } = setProductObservationsSlice;

export default reducer;
