import { createAsyncThunk } from '@reduxjs/toolkit';
import { getLastCart } from './utilsLib';
export const LAST_CART_REQUEST = 'LAST_CART_REQUEST';

export const lastCart = createAsyncThunk(
  'ordersFetch/lastCart',
  async payload => {
    try {
      const cart = await getLastCart(payload);
      return JSON.parse(cart);
    } catch (error) {
      console.log('error', error);
      return error;
    }
  },
);
