import React, { Component } from 'react';

import GeneralModule from '../../../modules/GeneralModule';
import FilterBase from '../../../components/Filter';
import { emp2long } from '../../../utils/FastSellerUtils';

import PropTypes from 'prop-types';

class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: { fields: [] },
    };
  }

  componentDidMount() {
    // TODO: Colocar essas informações num endpoint.
    const groupId = emp2long('TITU');
    const id = emp2long('LIST');
    new Promise((resolve, reject) => {
      GeneralModule.getFilters(`${groupId}`, `${id}`, '', '', resolve, reject);
    }).then(jsonData => {
      let filter = JSON.parse(jsonData).data;
      filter = filter && filter.find(fi => fi.context === 'titles');
      let valuesCheck = [];

      if (!filter) {
        return;
      }

      filter.fields = filter?.fields?.map(f => {
        const values = f.field.split('.');
        const field = values[values.length - 1];

        if (f.type === 'CHECKLIST') {
          valuesCheck = this.props.titles
            .filter(
              (value, index, self) =>
                self.map(s => s.tipo).indexOf(value.tipo) === index,
            )
            .map(value => ({ value: value.tipo, name: value.tipo_label }));

          f.values = valuesCheck;
        }

        return { ...f, field };
      });

      this.setState({ filter });
    });
  }

  onListFiltered = list => this.props.onListFiltered(list);

  render() {
    const { filter } = this.state;
    const { titles } = this.props;

    return (
      <FilterBase
        filters={filter.fields}
        list={titles}
        onListFiltered={this.onListFiltered}
      />
    );
  }
}

Filter.propTypes = {
  titles: PropTypes.array,
  onListFiltered: PropTypes.func,
};

export default Filter;
