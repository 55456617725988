import ActivitiesModule from '~/modules/ActivitiesModule';
import _ from 'lodash';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import translate from '~/locales';
import ActivityCache from '../../cache/ActivityCache';
import { createNotifyInfo } from '~/components/Web/ToastNotify';

export const ACTIVITIES_FETCH_REQUEST = 'ACTIVITIES_FETCH_REQUEST';
export const ACTIVITIES_FETCH_SUCCESS = 'ACTIVITIES_FETCH_SUCCESS';
export const ACTIVITIES_FETCH_FAILURE = 'ACTIVITIES_FETCH_FAILURE';
export const CACHE_KEY = '@LIST_ACTIVITIES';

const initialState = {
  type: '',
  loading: true,
  erro: false,
  payload: [],
};

const getActivities = payload => {
  const promise = new Promise(async (resolve, reject) => {
    try {
      const data = await ActivitiesModule.listActivities(
        JSON.stringify(payload),
      );
      const activityProcess =
        (await ActivityCache.getTaskListProcessBlock()) || null;
      const items = JSON.parse(data);

      const { atividades } = items;

      const activitiesLocation = _.filter(
        atividades,
        act => act.tipo === 'location',
      );

      const checkInActivity = _.find(activitiesLocation, act => {
        let setup;
        try {
          setup = JSON.parse(act.setup);
        } catch (error) {
          console.warn(
            "CheckInActivity Error: parsing JSON in 'setup':",
            error,
          );
          setup = {};
        }

        return setup.action === 'checkin';
      });

      const checkOutActivity = _.find(activitiesLocation, act => {
        let setup;
        try {
          setup = JSON.parse(act.setup);
        } catch (error) {
          console.warn('CheckOutActivity Error: parsing JSON:', error);
          setup = {};
        }

        return setup.action === 'checkout';
      });

      const activities = _.get(items, 'atividades', []).map(activity => {
        let answer = activity.resposta;
        if (answer) {
          answer = {
            ...answer,
            payload: JSON.parse(answer.payload),
            readableDate: `${moment
              .unix(answer.data_inicio)
              .fromNow()}, às ${moment
              .unix(answer.data_inicio)
              .format('hh:mm')}`,
          };
        }
        if (!activity.setup || typeof activity.setup !== 'string') {
          activity.setup = '{}';
        }
        let setup;
        try {
          setup = JSON.parse(activity.setup);
        } catch (error) {
          console.warn("Activities Error parsing JSON in 'setup':", error);
          setup = {};
        }
        const isCheckIn =
          activity.tipo === 'location' && setup.action === 'checkin';
        const required = activityProcess?.isNewCheckIn
          ? isCheckIn ||
            (!!checkInActivity.resposta &&
              Boolean(Number(activity.obrigatoria)))
          : activity.obrigatoria;

        let isToBocked = false;
        if (checkInActivity !== undefined && checkOutActivity !== undefined) {
          const { resposta: respostaIn } = checkInActivity;
          const { resposta: respostaOut } = checkOutActivity;

          const checkInAnswer = respostaIn
            ? !!Object.keys(respostaIn).length
            : false;
          const checkOutAnswer = respostaOut
            ? !!Object.keys(respostaOut).length
            : false;

          if (!isCheckIn) {
            if (
              !checkInAnswer ||
              (checkInAnswer && checkOutAnswer && !activityProcess)
            ) {
              isToBocked = true;
            } else if (
              checkOutAnswer &&
              activityProcess?.activitiesBlocked !== undefined
            ) {
              isToBocked = activityProcess?.activitiesBlocked;
            }
          }
        } else {
          isToBocked = true;
        }

        let isSectionAnswered = false;
        if (activity.tipo === 'section') {
          const childrenActivities = _.get(activity, 'atividades_filhas', []);
          const answeredChildren =
            childrenActivities.filter(({ resposta }) => !!resposta) || [];
          const requiredChildren =
            childrenActivities.filter(
              ({ obrigatoria }) => obrigatoria === '1',
            ) || [];
          const requiredChildrenAnswered =
            requiredChildren.filter(({ resposta }) => !!resposta) || [];

          isSectionAnswered =
            requiredChildren.length > 0
              ? requiredChildren.length === requiredChildrenAnswered.length
              : answeredChildren.length === childrenActivities.length;
        }

        return {
          ...activity,
          id: parseInt(activity.id, 10),
          name: activity.nome,
          required: Boolean(Number(required)),
          kind: activity.tipo,
          kind_key: activity.tipo,
          answer: answer || null,
          is_dynamic: setup?.is_dynamic || false,
          blocked: isToBocked,
          isSectionAnswered,
        };
      });

      resolve(JSON.stringify(activities));
    } catch (error) {
      console.warn('getActivities error:', error);
      createNotifyInfo(`${translate('UnableToLoadingListOfActivities')}`);
      reject(JSON.stringify([]));
    }
  });

  return promise;
};

export const getListActivities = createAsyncThunk(
  'activitiesFetch/getListActivities',
  async payload => {
    try {
      const finalData = await getActivities(payload);
      return JSON.parse(finalData || '[]');
    } catch (err) {
      return [];
    }
  },
);

const activitiesFetch = createSlice({
  name: 'activitiesFetch',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getListActivities.pending, state => {
      state.type = ACTIVITIES_FETCH_REQUEST;
      state.loading = true;
    });
    builder.addCase(getListActivities.fulfilled, (state, action) => {
      state.type = ACTIVITIES_FETCH_SUCCESS;
      state.loading = false;
      state.erro = false;
      state.payload = action.payload;
    });
    builder.addCase(getListActivities.rejected, (state, action) => {
      state.type = ACTIVITIES_FETCH_FAILURE;
      state.loading = false;
      state.erro = true;
      state.payload = action.payload;
    });
  },
});

const { reducer } = activitiesFetch;

export default reducer;
