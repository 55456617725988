import React, { useCallback, useState } from 'react';
import { FlatList, View, Text, Image, Button } from 'react-native';
import { FlashList } from '@shopify/flash-list';
import Images from '../../../assets/Images';
import styles from './styles';
import BannerImage from '~/components/Banners/components/BannerImage';
import appStyles from '../../../components_base/src/appStyles';
import { size, color } from '~/common';
import Touchable from 'react-native-platform-touchable';
import Spinner from 'react-native-loading-spinner-overlay';
import ProductList from '~/screens/Product/ProductList';
import { IntlProvider } from 'react-intl';
import translate, { calendarLanguage as language } from '../../locales';
import SearchBar from '~/components/SearchBar';
import PropTypes from 'prop-types';

const PAGE_FACTOR = 10;
const PAGE_INITIAL = 1 * PAGE_FACTOR;

const ComboDetail = props => {
  const {
    combo,
    onApplyCombo,
    onRemoveCombo,
    applyingCombo,
    messages,
    onCollapsedMessage,
    isCollapsedMessage,
    collapsed,
    onCollapsed,
    productClick,
    configLib,
    config,
    addItem,
    itemUpdate,
    cart,
    onPressObservations,
    onPressConditionsSalesChild,
    onSearch,
  } = props;

  const descricao = combo?.descricao;
  const periodo = combo?.periodo;
  const applied = combo?.applied;
  const hasApplyFlow = combo?.hasApplyFlow;
  const title = combo?.title;
  const method = combo?.metodologia;

  const image = combo?.image ? { uri: combo.image } : Images.iconNoImageBig;

  const [activeListPage, setActiveListPage] = useState(PAGE_INITIAL);

  const keyExtractor = item => item.id;

  const itemSeparatorComponent = () => <View style={styles.separator} />;

  const renderItemMessage = ({ item, index }) => (
    <View
      style={[
        appStyles.rowDirection,
        {
          backgroundColor: '#fff',
          borderRadius: 10,
          padding: 10,
          marginBottom: 10,
          marginLeft: 10,
          marginRight: 10,
        },
      ]}>
      <View
        style={[
          styles.strip,
          {
            backgroundColor: item.type === 'erro' ? '#ec0909' : '#2D9CDB',
          },
        ]}
      />
      <Text style={styles.message}>{item.description}</Text>
    </View>
  );

  const renderProduct = useCallback(
    ({ item }) => {
      return (
        <ProductList
          editable
          onPressObservations={onPressObservations}
          addItem={addItem}
          itemUpdate={itemUpdate}
          item={item}
          cart={cart}
          config={config}
          configLib={configLib}
          itemClick={productClick}
          isCombo
          onPressConditionsSalesChild={onPressConditionsSalesChild}
        />
      );
    },
    [
      addItem,
      cart,
      config,
      configLib,
      itemUpdate,
      onPressObservations,
      productClick,
      onPressConditionsSalesChild,
    ],
  );

  const renderItem = useCallback(
    ({ item, index }) => {
      const { products } = item;

      const isCollapsed = item.isCollapsed;

      return (
        <View
          style={{
            flex: 1,
            maxHeight: isCollapsed ? 50 : undefined,
          }}
          key={index}>
          <Touchable
            onPress={() => {
              onCollapsed(item.id);
            }}>
            <View
              style={[
                appStyles.rowDirection,
                {
                  marginBottom: 10,
                  marginLeft: 5,
                  marginTop: 5,
                },
              ]}>
              <Image
                style={styles.chevron}
                source={
                  isCollapsed ? Images.iconChevronRight : Images.iconChevronDown
                }
              />
              <Text style={styles.title_group}>{item.title}</Text>
            </View>
          </Touchable>
          {!isCollapsed ? (
            <FlashList
              renderItem={renderProduct}
              keyExtractor={keyExtractor}
              data={products.slice(0, activeListPage)}
              estimatedItemSize={390}
              onEndReached={() => {
                setActiveListPage(activeListPage + PAGE_INITIAL);
              }}
              onEndReachedThreshold={0.1}
            />
          ) : null}
        </View>
      );
    },
    [activeListPage, collapsed, onCollapsed, renderProduct],
  );
  return (
    <IntlProvider locale={`${language}`}>
      <View style={styles.container}>
        <Spinner
          visible={applyingCombo}
          overlayColor="rgba(0, 0, 0, 0.7)"
          textStyle={{ color: '#FFF' }}
        />
        {hasApplyFlow && applied ? (
          <View style={styles.alert_combo_applied}>
            <Image
              style={styles.alert_combo_applied_image}
              source={Images.iconCheckCircle}
            />
            <Text style={styles.alert_combo_applied_text}>COMBO APLICADO</Text>
            <View />
          </View>
        ) : null}
        <BannerImage image={image} fullWidth resizeMode="contain" pressable />

        <View
          style={{
            padding: size.horizontalSpacing,
            paddingBottom: 10,
          }}>
          {descricao ? (
            <Text style={styles.description}>{descricao}</Text>
          ) : null}
          {title ? <Text style={styles.title}>{title}</Text> : null}
          {method ? (
            <View style={[appStyles.rowDirection, { bottom: 5 }]}>
              <Text style={styles.value}>{method}</Text>
            </View>
          ) : null}
          {periodo ? (
            <View style={appStyles.rowDirection}>
              <Image source={Images.iconCalendar} style={styles.icon} />
              <Text style={styles.label}>{translate('period')}: </Text>
              <Text style={styles.value}>{periodo}</Text>
            </View>
          ) : null}
        </View>

        <SearchBar placeholder={`${translate('search')}`} onSearch={onSearch} />

        <View style={styles.separator} />
        <View
          style={{
            flex: 1,
          }}>
          {messages.length ? (
            <View>
              <Touchable
                onPress={() => {
                  onCollapsedMessage();
                }}>
                <View
                  style={[
                    appStyles.rowDirection,
                    {
                      marginBottom: 0,
                      marginLeft: 5,
                      marginTop: 5,
                    },
                  ]}>
                  <Image
                    style={styles.chevron}
                    source={
                      isCollapsedMessage
                        ? Images.iconChevronRight
                        : Images.iconChevronDown
                    }
                  />
                  <Text style={styles.title_group}>Mensagens</Text>
                </View>
              </Touchable>
              {!isCollapsedMessage ? (
                <FlatList
                  data={messages}
                  renderItem={renderItemMessage}
                  ItemSeparatorComponent={itemSeparatorComponent}
                />
              ) : null}
            </View>
          ) : null}
          <View
            style={[
              styles.container,
              {
                marginBottom: 30,
              },
            ]}>
            {combo?.groups?.map((group, index) => {
              return renderItem({
                item: group,
                index,
              });
            })}
          </View>
        </View>
        {hasApplyFlow ? (
          <View style={styles.positionAbsolute}>
            {!applied ? (
              <Button
                style={{
                  marginHorizontal: 16,
                  marginTop: 5,
                  marginBottom: 10,
                  backgroundColor: color.successColor,
                }}
                title={`${translate('apply')}`}
                onPress={onApplyCombo}
              />
            ) : (
              <Button
                style={{
                  marginHorizontal: 16,
                  marginTop: 5,
                  marginBottom: 5,
                  backgroundColor: color.primaryColor,
                }}
                title={`${translate('remove')}`}
                onPress={() => {
                  onRemoveCombo(combo.id);
                }}
              />
            )}
          </View>
        ) : null}
      </View>
    </IntlProvider>
  );
};

ComboDetail.propTypes = {
  combo: PropTypes.objectOf(PropTypes.any),
  onApplyCombo: PropTypes.func,
  onRemoveCombo: PropTypes.func,
  applyingCombo: PropTypes.bool,
  messages: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  onCollapsedMessage: PropTypes.func,
  isCollapsedMessage: PropTypes.bool,
  collapsed: PropTypes.bool,
  onCollapsed: PropTypes.func,
  productClick: PropTypes.func,
  configLib: PropTypes.objectOf(PropTypes.any),
  config: PropTypes.objectOf(PropTypes.any),
  addItem: PropTypes.func,
  itemUpdate: PropTypes.objectOf(PropTypes.any),
  cart: PropTypes.objectOf(PropTypes.any),
  onPressObservations: PropTypes.func,
  onSearch: PropTypes.func,
  onPressConditionsSalesChild: PropTypes.func,
};

export default ComboDetail;
