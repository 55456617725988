class Product {
  constructor(product) {
    this.id = '';
    this.codigo = '';
    this.image = '';
    this.name = '';
    this.price = 0;
    this.minPrice = 0;
    this.stock = 0;
    this.description = '';
    this.taxes = '';
    this.ean = '';
    this.code = '';
    this.quantity = 0;
    this.multiple = 1;
    this.type = 'product';
    this.moreInfo = '';
    this.unity = 0;
    this.unidade_macro = 0;

    if (product) {
      this.id = product.id;
      this.codigo = product.codigo;
      this.image = product.image;
      this.name = product.name;
      this.price = product.price;
      this.minPrice = product.minPrice;
      this.stock = product.stock;
      this.description = product.description;
      this.taxes = product.taxes;
      this.ean = product.ean;
      this.quantity = product.quantity;
      this.multiple = product.multiple;
      this.type = product.type;
      this.moreInfo = product.moreInfo;
      this.unity = product.unity;
      this.unidade_macro = product.unidade_macro;
    }
  }
  toString() {
    return `Product: ${this.name}`;
  }
}

export default Product;
