import { createAsyncThunk } from '@reduxjs/toolkit';
import { addItem } from './utilsLib';
import { hasProductDataChanged } from '../../utils/ProductUtil';
import { setCurrentProduct } from '../productCurrent/slice';

export const UPDATE_ITEM_REQUEST = 'UPDATE_ITEM_REQUEST';

export const updateItemFetch = createAsyncThunk(
  'ordersFetch/updateItemFetch',
  async (payload, { getState, dispatch }) => {
    try {
      const currentProduct = getState().productCurrent.payload;
      const multiplo_venda = currentProduct?.multiplo_venda;

      const data = await addItem(payload);
      const parseData = JSON.parse(data);

      let order = parseData;
      // const order = parseData;
      const { produtos } = order;
      const { item } = payload;
      let product = produtos.find(prod => prod.chave === payload.item.chave);
      let hasProductChanged = false;

      if (!product) {
        hasProductChanged = hasProductDataChanged(currentProduct, item);
        product = item;
      } else {
        hasProductChanged = hasProductDataChanged(item, product);
      }
      if (hasProductChanged) {
        product = {
          ...product,
          multiplo_venda,
        };
        dispatch(setCurrentProduct({ ...item, ...product }));
      }

      const copyOrder = { ...order };
      const productIndex = copyOrder.produtos.findIndex(prod => prod.chave === product.chave);
      copyOrder.produtos[productIndex] = product;
      order = copyOrder;

      return {
        ...order,
        ref: product?.chave,
      };
    } catch (error) {
      console.log('error', error);
      return error;
    }
  },
);
