import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { updateTypeMagnitudes } from '~/store/productsFetch/slice';
import OrdersModule from '~/modules/OrdersModule';

export const SALE_HISTORY_PRODUCTS_REQUEST = 'SALE_HISTORY_PRODUCTS_REQUEST';
export const SALE_HISTORY_PRODUCTS_SUCCESS = 'SALE_HISTORY_PRODUCTS_SUCCESS';
export const SALE_HISTORY_PRODUCTS_FAILURE = 'SALE_HISTORY_PRODUCTS_FAILURE';

const getSaleHistoryProducts = payload =>
  new Promise((resolve, reject) =>
    OrdersModule.saleHistoryDonizete(payload, resolve, reject),
  );

export const saleHistoryProducts = createAsyncThunk(
  'salesHistoryProducts/fetch',
  async (payload, { getState }) => {
    const products = await getSaleHistoryProducts(payload);
    const parsedProducts = JSON.parse(products);
    const updatedProducts = updateTypeMagnitudes(
      parsedProducts,
      getState().productsMagnitudes.payload,
    );
    return updatedProducts;
  },
);

const initialState = {
  type: '',
  loading: false,
  erro: false,
  payload: [],
};

const salesHistoryProductsSlice = createSlice({
  name: 'saleHistoryProducts',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(saleHistoryProducts.pending, state => {
        state.type = SALE_HISTORY_PRODUCTS_REQUEST;
        state.loading = true;
      })
      .addCase(saleHistoryProducts.fulfilled, (state, action) => {
        state.type = SALE_HISTORY_PRODUCTS_SUCCESS;
        state.loading = false;
        state.payload = action.payload;
      })
      .addCase(saleHistoryProducts.rejected, state => {
        state.type = SALE_HISTORY_PRODUCTS_FAILURE;
        state.loading = false;
        state.erro = true;
      });
  },
});

const { reducer } = salesHistoryProductsSlice;

export default reducer;
