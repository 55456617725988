import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import OrdersContainer from '../screens/Orders/Orders.Container';
import { Colors } from '../config/config';

import ClientDetailContainer from '../screens/ClientDetail/ClientDetail.Container';
import CartContainer from '../screens/Cart/Cart.Container';
import ResumeContainer from '../screens/Resume/Resume.Container';
import MessagesContainer from '../screens/Messages/Messages.Container';
import FinishContainer from '../screens/Finish/Finish.Container';
import ProductDetailContainer from '../screens/ProductScreens/ProductDetail/ProductDetail.Container';
import ConditionsSalesChildContainer from '../screens/ConditionsSalesChild/ConditionsSalesChildContainer';
import ProductTabsContainer from '../screens/ProductScreens/ProductTabs/ProductTabsContainer';
import ObservationsContainer from '../screens/Resume/Observations/Observations.Container';
import OrderObservations from '~/screens/Resume/Observations/Observations.Container';
import OrderDetailContainer from '../screens/OrderDetail/OrderDetail.Container';
import CombosContainer from '../screens/Combos/Combos.Container';
import ComboDetailContainer from '~/screens/ComboDetail/ComboDetail.Container';
import ProductObservationsContainer from '~/screens/Observations/Observations.Container';
import ShareOrder from '~/screens/Cart/components/ShareOrder.web';
import ConditionsSalesChidContainer from '~/screens/ConditionsSalesChild/ConditionsSalesChildContainer';
import { fonts } from '../utils/Fonts';
import { ActionModal } from '~/components/OperationGroup/ActionModal/OperationGroupActionModal';
import OperationGroupActions from '~/components/OperationGroup/Actions/OperationGroupActions';
import Categories from '~/screens/Categories/Categories';
import Filter from '~/screens/Filter/Filter';

const Stack = createStackNavigator();

function OrdersStack() {
  return (
    <Stack.Navigator
      screenOptions={{
        title: 'Pedidos',
        headerStyle: {
          backgroundColor: Colors.primaryColor,
          textAlign: 'center',
        },
        headerTitleAlign: 'left',
        headerTintColor: '#fff',
        headerTitleStyle: {
          fontWeight: 'bold',
          fontFamily: `${fonts.QuicksandBold}`,
        },
      }}>
      <Stack.Screen
        name="Orders"
        options={{ title: 'Pedidos' }}
        component={OrdersContainer}
      />
      <Stack.Screen
        name="ClientDetail"
        options={{ title: 'Detalhes do Cliente' }}
        component={ClientDetailContainer}
      />
      <Stack.Screen
        name="Cart"
        options={{ title: 'Carrinho' }}
        component={CartContainer}
      />
      <Stack.Screen
        name="Resume"
        options={{ title: 'Resume Cart' }}
        component={ResumeContainer}
      />
      <Stack.Screen
        name="Messages"
        options={{ title: 'Mensagens' }}
        component={MessagesContainer}
      />
      <Stack.Screen
        name="Finish"
        options={{ title: 'Pedido concluído' }}
        component={FinishContainer}
      />
      <Stack.Screen
        name="ProductDetail"
        options={{ title: 'Product Detail' }}
        component={ProductDetailContainer}
      />
      <Stack.Screen
        name="ConditionsSalesChild"
        options={{ title: 'Condições de Venda' }}
        component={ConditionsSalesChildContainer}
      />
      <Stack.Screen
        name="ProductTabs"
        options={{ title: 'Mais Produtos' }}
        component={ProductTabsContainer}
      />
      <Stack.Screen
        name="Observations"
        options={{ title: 'Observações' }}
        component={ObservationsContainer}
      />
      <Stack.Screen
        name="OrderDetail"
        options={{ title: 'Detalhes do Pedido' }}
        component={OrderDetailContainer}
      />
      <Stack.Screen
        name="Combos"
        options={{ title: 'Combos' }}
        component={CombosContainer}
      />
      <Stack.Screen
        name="ComboDetail"
        options={{ title: 'Detalhes do Combo' }}
        component={ComboDetailContainer}
      />
      <Stack.Screen
        name="OrderObservations"
        options={{ title: 'Observações do Pedido' }}
        component={OrderObservations}
      />
       <Stack.Screen
        name="ProductObservation"
        options={{ title: 'Observações do produto' }}
        component={ProductObservationsContainer}
      />
      <Stack.Screen
        name="ShareOrder"
        options={{ title: 'Compartilhar Pedido' }}
        component={ShareOrder}
      />
      <Stack.Screen
        name="ConditionsSalesChid"
        options={{ title: 'Condições de Venda' }}
        component={ConditionsSalesChidContainer}
      />
      <Stack.Screen
        name='ActionModal'
        options={{ title: 'Operação' }}
        component={ActionModal}
      />
      <Stack.Screen
        name="OperationGroupActions"
        options={{ title: 'Operações' }}
        component={OperationGroupActions}
      />
      <Stack.Screen
        name="Categories"
        options={{ title: 'Categorias' }}
        component={Categories}
      />
      <Stack.Screen
        name="OrdersFilters"
        options={{ title: 'Filtros' }}
        component={Filter}
      />
    </Stack.Navigator>
  );
}

export default OrdersStack;
