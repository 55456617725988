import { toast } from 'react-toastify';

export const createNotifySuccess = message => {
  toast.success(`${message}`, {
    position: toast.POSITION.TOP_CENTER,
  });
};

export const createNotifyInfo = message => {
  toast.info(`${message}`, {
    position: toast.POSITION.TOP_CENTER,
  });
};

export const createNotifyWarning = message => {
  toast.warn(`${message}`, {
    position: toast.POSITION.TOP_CENTER,
  });
};

export const createNotifyError = message => {
  toast.error(`${message}`, {
    position: toast.POSITION.TOP_CENTER,
  });
};
