import { StyleSheet } from 'react-native';
import { color } from '~/common';
import { width } from '../measures';

const styles = StyleSheet.create({
  viewContainer: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    marginTop: 8,
    marginBottom: 8,
    alignSelf: 'flex-end',
    alignItems: 'center',
  },
  buttonFilter: {
    color: color.primary600,
    borderBottomWidth: 1,
    borderStyle: 'dotted',
    borderBottomColor: color.primary600,
  },
  buttonView: { flexDirection: 'row', alignItems: 'center' },
  modal: {
    backgroundColor: 'white',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: 300,
    maxHeight: '60%',
    borderRadius: 8,
    paddingVertical: 24,
    padding: 24,
  },
  titleModal: { fontWeight: '700', fontSize: 18, lineHeight: 24 },
  scrollView: {
    width: '100%',
  },
  radioBtn: {
    alignItems: 'center',
  },
  optLabel: {
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 24,
    color: '#1A1A1A',
  },
  optDescription: {
    fontWeight: '500',
    fontSize: 12,
    maxWidth: 150,
    lineHeight: 16,
    color: '#808080',
  },
  optView: {
    marginLeft: 6,
  },
});

export default styles;
