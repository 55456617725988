import React, { Component } from 'react';
import { View, FlatList, StyleSheet, ActivityIndicator } from 'react-native';
import { FlashList } from '@shopify/flash-list';
import { IndicatorViewPager, PagerTitleIndicator } from 'rn-viewpager';
import styles from './styles';
import EmptyView from '../../components/EmptyView';
import Images from '../../../assets/Images';
import ComboItem from './ComboItem';
import Search from '../../components/Search';
import { color, style } from '../../common';
import { fonts } from '../../utils/Fonts';
import translate from '../../locales';
import OrderCombo from './OrderCombo';
import onPressHelper from '~/utils/onPressHelper';
import { TabBar, TabView } from 'react-native-tab-view';
import { Colors } from '~/config/config';
import PropTypes from 'prop-types';
import ModalOverlay from '~/components/Web/ModalOverLay/index.web';

const Styles = StyleSheet.create({
  flashlist: {
    backgroundColor: '#fff',
    padding: 0,
    minHeight: 20,
    flex: 1,
    marginTop: 4,
  },
  view: {
    flex: 1,
    backgroundColor: color.grayContainer,
  },
});

class Combos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSections: [],
      actualIndex: 0,
      combos: this.props.combos,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.combos !== this.props.combos) {
        this.setState({ combos: nextProps.combos });
    }
}

  keyExtractor = (item, index) => `${item.id}-${index}`;

  updateSections = activeSections => {
    this.setState({ activeSections });
  };

  onPressComboDebounce = onPressHelper.debounce(this.props.onPressCombo, 100);

  renderItem = ({ item }) => (
    <ComboItem
      combo={item}
      onPressCombo={this.onPressComboDebounce}
      comboType={this.props.comboType}
    />
  );

  renderTitleIndicator = () => {
    const { combos } = this.state;

    if (combos.length > 1) {
      const titles = combos.map(combo => combo.campaign);
      return (
        <PagerTitleIndicator
          style={styles.PagerTitleIndicator}
          selectedBorderStyle={styles.selectedBorderStyle}
          itemStyle={styles.indicatorStyle}
          selectedItemStyle={styles.indicatorStyle}
          itemTextStyle={styles.indicatorTextBaseStyle}
          selectedItemTextStyle={[
            styles.indicatorTextBaseStyle,
            { color: 'white', fontFamily: fonts.QuicksandBold },
          ]}
          titles={titles}
        />
      );
    }

    return null;
  };

  renderCombosCampaign = ({ route }) => {
    const { id, combos } = route;

    return (
      <View key={`${id}`} style={Styles.view}>
        {combos && combos.length > 0 ? (
          <FlashList
            style={Styles.flashlist}
            data={combos}
            extraData={this.state}
            keyExtractor={this.keyExtractor}
            renderItem={this.renderItem}
            removeClippedSubviews={false}
            estimatedItemSize={260}
          />
        ) : (
          <EmptyView
            icon={Images.appOrders}
            message={`${translate('unavailableCombos')}`}
          />
        )}
      </View>
    );
  };

  _renderTabBar = props => (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: Colors.stripGreen }}
      indicatorContainerStyle={{ backgroundColor: Colors.primaryColor }}
      inactiveColor={Colors.gray}
      activeColor="white"
    />
  );

  render() {
    const { loading, showSearch, onChangeText } = this.props;
    const { combos } = this.state;
    const RenderCombTabs = this.renderCombosCampaign;

    return (
      <View style={style.container}>
        {showSearch && <Search onChangeText={onChangeText} />}
        <ModalOverlay visible={loading} closeOnTouchOutside />
        <ActivityIndicator 
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            zIndex: 9999,
          }}
          animating={loading}
          size="large"
        />
        {this.props.comboType === 'combos' && combos?.length === 0 && !loading && (
          <EmptyView
          icon={Images.appOrders}
          message={`${translate('unavailableCombos')}`}
        />
        )}
        {this.props.comboType === 'combos' && (combos && combos.length > 0) ? (
          <TabView
            renderTabBar={combos?.length > 1 ? this._renderTabBar : () => {}}
            navigationState={{
              index: this.state.actualIndex,
              routes: combos.map((tab, index) => {
                return {
                  ...tab,
                  key: index,
                };
              }),
            }}
            onIndexChange={idx => this.setState({ actualIndex: idx })}
            renderScene={({ route, jumpTo }) => {
              switch (route.key) {
                case this.state.actualIndex:
                  return <RenderCombTabs route={route} jumpTo={jumpTo} />;
              }
            }}
          />
        ) : (
          <OrderCombo
            sections={combos}
            activeSections={this.state.activeSections}
            updateSections={this.updateSections}
          />
        )}
      </View>
    );
  }
}

Combos.propTypes = {
  combos: PropTypes.array,
  loading: PropTypes.bool,
  showSearch: PropTypes.bool,
  comboType: PropTypes.string,
  onChangeText: PropTypes.func,
  onPressCombo: PropTypes.func,
};

export default Combos;
