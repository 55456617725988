import { createSlice } from '@reduxjs/toolkit';

export const TASK_CURRENT_FETCH_REQUEST = 'TASK_CURRENT_FETCH_REQUEST';
export const TASK_CURRENT_FETCH_SUCCESS = 'TASK_CURRENT_FETCH_SUCCESS';
export const TASK_CURRENT_FETCH_FAILURE = 'TASK_CURRENT_FETCH_FAILURE';

export const TASK_CURRENT_CLEAR_FETCH_SUCCESS =
  'TASK_CURRENT_CLEAR_FETCH_SUCCESS';

const initialState = {
  type: '',
  loading: false,
  erro: false,
  current: {},
};

const taskCurrentSlice = createSlice({
  name: 'taskCurrent',
  initialState,
  reducers: {
    setCurrentTask: (state, action) => {
      state.type = TASK_CURRENT_FETCH_SUCCESS;
      state.current = action.payload;
    },
    clearCurrentTask: (state, action) => {
      state.type = TASK_CURRENT_CLEAR_FETCH_SUCCESS;
      state.current = {};
    },
  },
});

const { reducer, actions } = taskCurrentSlice;

export const { setCurrentTask, clearCurrentTask } = actions;

export default reducer;
