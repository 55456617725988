import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import { color } from '~/common';
import { fonts } from '~/utils/Fonts';
import Images from '../../../../assets/Images';
import PropTypes from 'prop-types';

const app = {
    borderRadius: 10,
    width: 70,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 8,
    padding: 10,
};

const appImage = {
    width: 35,
    height: 35,
    resizeMode: 'contain',
};

const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        borderRadius: 100,
        overflow: 'visible',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        width: 84,
        height: 84,
        overflow: 'visible',
    },
    appDefault: {
        ...app,
        backgroundColor: '#fff',
    },
    appHighLight: {
        ...app,
        backgroundColor: color.primaryColorLight,
    },
    imageDefault: {
        ...appImage,
        tintColor: color.primaryColorLight,
    },
    imageHighLight: {
        ...appImage,
        tintColor: '#fff',
    },
    title: {
        fontSize: 12,
        textAlign: 'center',
        color: color.grayDark,
        fontFamily: fonts.QuicksandBold,
        fontWeight: 'bold',
        alignSelf: 'center',
        width: 80,
    },
    badge: {
        backgroundColor: 'red',
        borderRadius: 20,
        width: 18,
        height: 18,
        position: 'absolute',
        top: 4,
        right: 4,
        justifyContent: 'center',
        alignItems: 'center',
    },
    badgeText: {
        color: '#fff',
        fontSize: 12,
    },
});

const App = props => (
    <View
        style={{
            marginBottom: 20,
            flex: 1,
            alignItems: 'stretch',
        }}>
        <Touchable
            // style={{ flex: 1 }}
            onPress={() => {
                props.onPress(
                    props.props.item.id,
                    props.props.item.title,
                    props.props.item.tabIndex,
                    props,
                );
            }}>
            <View style={styles.mainContainer}>
                <View style={styles.container}>
                    <View
                        style={[
                            props.props.item.is_highlight
                                ? styles.appHighLight
                                : styles.appDefault,
                        ]}
                    >
                        <Image
                            style={[
                                props.props.item.is_highlight
                                    ? styles.imageHighLight
                                    : styles.imageDefault,
                            ]}
                            source={props.props.item.screen === 'expenses' ? Images.iconWallet : props.props.item.image}
                        />
                    </View>
                    {props.props.item.badgeText && props.props.item.badgeText !== '' && (
                        <View style={styles.badge}>
                            <Text style={styles.badgeText}>{props.props.item.badgeText}</Text>
                        </View>
                    )}
                </View>
            </View>
        </Touchable>
        <Text style={styles.title}>{props.props.item.label}</Text>
    </View>
);

App.propTypes = {
    props: PropTypes.objectOf(PropTypes.any),
    id: PropTypes.string,
    tabIndex: PropTypes.number,
    title: PropTypes.string,
    label: PropTypes.string,
    badgeText: PropTypes.string,
    image: PropTypes.number,
    is_highlight: PropTypes.bool,
    onPress: PropTypes.func,
};

export default App;
