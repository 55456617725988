import AsyncStorage from '@react-native-async-storage/async-storage';

class CacheModules {
  static async getModules() {
    const data = await AsyncStorage.getItem('@modules');
    return JSON.parse(data);
  }
  static async setModules(modules) {
    if (modules) {
      await AsyncStorage.setItem('@modules', JSON.stringify(modules));
    }
  }
}

export default CacheModules;
