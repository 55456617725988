import React, { Component } from 'react';

import ReportForm from './ReportForm';
import Images from '@assets/Images';

import PropTypes from 'prop-types';
import { Image, TouchableOpacity } from 'react-native';

class ReportFormContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleButton: false,
      dataForm: [],
      formData: [],
      initialValues: {},
      loading: false,
    };
  }

  componentWillMount() {
    this.setLeftButton();
    const initialValues = {};

    const dataForm = this.props?.route?.params.report.params.map(prop => {
      const { value, ...rest } = prop;
      initialValues[prop.field] = value;

      return rest;
    });

    const visibleButton = !Object.values(initialValues).some(
      data => data === undefined || data === null || data === '',
    );

    this.setState({
      dataForm,
      formData: initialValues,
      visibleButton,
      initialValues,
    });
  }

  componentWillUnmount() {
    this.setState({ loading: false });
  }

  getFormData = formData => {
    this.setState({ formData });
  };

  isFormValid = valid => {
    this.setState({ visibleButton: valid });
  };

  generateReport = async () => {
    this.setState({ loading: true });
    const { report, output, exportReport, navigator } = this.props?.route?.params;
    const { componentId } = this.props;
    const { params } = report;

    const values = {};

    Object.values(this.state.formData).forEach((value, index) => {
      let valueParam = value;
      const param = params[index];

      if (param.type === 'DATE' && valueParam.includes('/')) {
        const [day, month, year] = valueParam.trim().split('/');
        valueParam = new Date(year, month - 1, day).getTime() / 1000;
        valueParam = valueParam.toString();
      } else if (param.type === 'COMB' && typeof valueParam === 'object') {
        valueParam = valueParam.value;
      }
      values[param.name_bind] = valueParam;
    });
    const paramsStringfy = JSON.stringify(values);
    await exportReport(report.pkey, paramsStringfy, output, report);
    this.setState({ loading: false });
    navigator && navigator.pop();
  };

  setLeftButton = () => {
    this.props.navigation.setOptions({
      title: this.props.route.params.title,
      headerLeft: () => (
        <TouchableOpacity onPress={() => {
          this.props.navigation.pop();
        }}
        >
          <Image
            source={Images.iconBack}
            style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
          />
        </TouchableOpacity>
      ),
    });
  };

  render() {
    return (
      <ReportForm
        form={this.props.route.params.report.params}
        initialValues={this.state.initialValues}
        visibleButton={this.state.visibleButton}
        isFormValid={this.isFormValid}
        getFormData={this.getFormData}
        generateReport={this.generateReport}
        loading={this.state.loading}
      />
    );
  }
}

ReportFormContainer.propTypes = {
    report: PropTypes.object.isRequired,
    output: PropTypes.string.isRequired,
    exportReport: PropTypes.func.isRequired,
    componentId: PropTypes.string.isRequired,
};

export default ReportFormContainer;
