import React, { Component } from 'react';
import Images from '@assets/Images';
import _ from 'lodash';
import Combos from './Combos';
import CombosModule from '../../modules/CombosModule';
import TextUtils from '../../utils/TextUtils';
import ProductsModule from '../../modules/ProductsModule';
import { SCREEN_COMBO_DETAIL } from '~/screens/screens';
import { connect } from 'react-redux';
import { Image, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import NavigationHelper from '../NavigationHelper';
import { getListWithPagination } from '~/utils/FastSellerUtils';

import PropTypes from 'prop-types';

class CombosContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      combos: [],
      showSearch: false,
      searchText: '',
      configCached: {},
    };
  }

  componentDidMount() {
    if (this.props.route?.params?.comboType === 'combos') {
      this.getListCombos();
    } else if (this.props.route?.params?.comboType === 'combos_pedido') {
      this.getCombosFromOrderTab();
    }
    this.getConfigCached();
    this.props.navigation.setOptions({
        headerLeft: () => (
            <TouchableOpacity onPress={() => {
              this.handleBackPress();
            }}
            >
              <Image
                source={Images.iconBack}
                style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
              />
            </TouchableOpacity>
          ),
        headerRight: () => (
            <TouchableOpacity onPress={() => {
              this.setState({ showSearch: !this.state.showSearch, searchText: '' });
            }}
            >
              <Image
                source={Images.iconSearch}
                style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 10 }}
              />
            </TouchableOpacity>
          )
    });
  }

  componentWillUnmount() {
    this.setState({ combos: [] });
}

  handleBackPress = () => {
    this.props.navigation.pop();
  };

  getCombosFromOrderTab() {
    new Promise((resolve, reject) => {
      this.setState({ loading: true });
      ProductsModule.getCombos(resolve, reject);
    })
      .then(data => {
        const combos = JSON.parse(data);
        this.setState({ combos, loading: false });
      })
      .catch(() => this.setState({ loading: false }));
  }

  navigationButtonPressed = event => {
    switch (event.buttonId) {
      case 'backPress':
        this.handleBackPress();
        break;
      case 'search':
        this.setState({ showSearch: !this.state.showSearch, searchText: '' });
        break;
      default:
        break;
    }
  };

  onChangeText = searchText => this.setState({ searchText });

  getFilteredData() {
    const { searchText, combos } = this.state;
    const search = TextUtils.slugfy(searchText);

    if (this.props.route?.params?.comboType === 'combos') {
      return combos.map(combo => {
        let { combos: combosCampaign } = combo;

        combosCampaign = combosCampaign.filter(comboSearch => {
          const res =
            (comboSearch.codigo &&
              TextUtils.slugfy(comboSearch.codigo).includes(search)) ||
            TextUtils.slugfy(comboSearch.descricao).includes(search) ||
            TextUtils.slugfy(comboSearch.estoque).includes(search);
          return res;
        });

        return {
          id: combo.id,
          campaign: combo.campaign,
          combos: combosCampaign,
        };
      });
    }

    return combos.filter(comboSearch => {
      const res =
        (comboSearch.codigo &&
          TextUtils.slugfy(comboSearch.codigo).includes(search)) ||
        TextUtils.slugfy(comboSearch.descricao).includes(search) ||
        TextUtils.slugfy(comboSearch.estoque).includes(search);
      return res;
    });
  }

  onPress = item => {
    const order = _.get(this.props.order, 'payload', {});
    const salesConditions = JSON.stringify(order.condvenda);
    const orderId = order.codigo;
    const clientId = _.get(this.props.client, 'current.pkey', null);
    if (this.props.route?.params?.comboType === 'combos') {
      this.props.navigation.navigate(SCREEN_COMBO_DETAIL.name, {
        salesConditions,
        orderId,
        clientId,
        combo: item,
        updateListCombos: this.getListCombos,
      });
    }
  };

  getConfigCached = async () => {
    const value = await AsyncStorage.getItem('@objPayload');
    this.setState({ configCached: JSON.parse(value || '{}') });
  };

  getListCombos = async () => {
    this.setState({ loading: true });
    const tamMemory = _.get(
      this.props.configLib,
      'payload.tam_memoria_paginacao',
    );

    try {
      const order = _.get(this.props.order, 'payload', {});
      const salesConditions = JSON.stringify(order.condvenda);
      const orderId = order.codigo;
      const clientId = _.get(this.props.client, 'current.pkey', null);
      const items = await getListWithPagination(
        CombosModule.listCombosWithPagination,
        tamMemory,
        clientId,
        orderId,
        salesConditions,
        false,
      );
      // const data = JSON.parse(items);
      const combosGroup = _.groupBy(items, 'titulo');
      const combos = Object.keys(combosGroup).map(campaign => {
        const combosCampaign = _.filter(combosGroup[campaign], {
          tipo: 'combo',
        }).map(combo => ({
          ...combo,
          applied: combo.aplicado,
          hasApplyFlow: combo.fluxo_aplicar,
          id: combo.codigo,
          title: combo.titulo,
          image: combo.url_imagem,
          amount: combo.saldo,
        }));

        return {
          id: Date.now(),
          campaign,
          combos: combosCampaign,
        };
      });
      this.setState({ combos });
    } catch (e) {
      console.log('LISTAGEM  ERROR combos', e);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading, showSearch } = this.state;

    return (
      <Combos
        combos={this.getFilteredData()}
        loading={loading}
        showSearch={showSearch}
        onChangeText={this.onChangeText}
        onPressCombo={this.onPress}
        comboType={this.props.route?.params?.comboType}
      />
    );
  }
}

CombosContainer.propTypes = {
    navigator: PropTypes.object,
    configLib: PropTypes.object,
    comboType: PropTypes.string,
    order: PropTypes.object,
    client: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    order: state.orderFetch,
    client: state.clientCurrent,
    configLib: state.configLibFetch,
  };
}

export default connect(mapStateToProps)(CombosContainer);
