import React from 'react';
import { Text, View } from 'react-native';
import RBSheet from '../BottomSheet/index';
import Touchable from 'react-native-platform-touchable';
import styles from './styles';

import PropTypes from 'prop-types';

function BottomAlert({
  title,
  description,
  containerStyle,
  mainButtonStyle,
  mainButtonTitle,
  hasSecondaryButton,
  secondaryButtonStyle,
  secondaryButtonTitle,
  visible,
  onPressSecondaryButton,
  onPressMainButton,
  onClose,
  setRef,
}) {
  return (
    <RBSheet
      ref={ref => {
        setRef(ref);
      }}
      styles={containerStyle || {}}
      onClose={onClose}
      visible={visible}>
      <View style={styles.messageContainer}>
        <Text style={styles.messageTitle}>{title}</Text>
        <Text style={styles.message}>{description || ''}</Text>
        <View style={styles.messageButtonContainer}>
          {hasSecondaryButton && (
            <Touchable
              style={secondaryButtonStyle || styles.messageButton}
              onPress={onPressSecondaryButton}>
              <Text style={styles.messageButtonText}>
                {secondaryButtonTitle}
              </Text>
            </Touchable>
          )}
          <Touchable
            style={
              mainButtonStyle || [
                styles.messageButton,
                styles.messageButtonRight,
              ]
            }
            onPress={onPressMainButton}>
            <Text
              style={[styles.messageButtonText, styles.messageButtonTextRight]}>
              {mainButtonTitle}
            </Text>
          </Touchable>
        </View>
      </View>
    </RBSheet>
  );
}

BottomAlert.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  containerStyle: PropTypes.object,
  mainButtonTitle: PropTypes.string.isRequired,
  mainButtonStyle: PropTypes.object,
  secondaryButtonTitle: PropTypes.string,
  secondaryButtonStyle: PropTypes.object,
  hasSecondaryButton: PropTypes.bool,
  onPressMainButton: PropTypes.func.isRequired,
  onPressSecondaryButton: PropTypes.func,
  setRef: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BottomAlert;
