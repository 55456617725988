import AsyncStorage from '@react-native-async-storage/async-storage';

export const UPDATE_VERSION_AVAILABLE = '@UpdateVersionAvailableKey';

const VERSION = 1;

class CacheDataController {
  static clear = (callback, error) => {
    try {
      AsyncStorage.clear(callback);
    } catch (err) {
      error(err);
    }
  };

  static removeWithCriteria = (criteria, callback = () => {}) => {
    AsyncStorage.getAllKeys((err, keys) => {
      if (err) {
        console.tron.log('error getAllKeys cacheController');
      } else {
        const filteredKeys = keys.filter(key => key.includes(criteria));
        AsyncStorage.multiRemove(filteredKeys, callback);
      }
    });
  };

  static removeItem = (key, callback = () => {}) => {
    AsyncStorage.removeItem(`${key}-${VERSION}`, callback);
  };

  static saveData = async (key, data) => {
    try {
      await AsyncStorage.setItem(`${key}-${VERSION}`, JSON.stringify(data));
    } catch (error) {
      console.tron.log('error getData cacheController');
    }
  };

  static getData = async (key, hasVersion = true) => {
    let data = null;
    const finalKey = hasVersion ? `${key}-${VERSION}` : `${key}`;
    try {
      const value = await AsyncStorage.getItem(finalKey);
      if (value !== null) {
        data = JSON.parse(value);
      }
    } catch (error) {
      console.tron.log('error getData cacheController');
    }
    return data;
  };

  static saveDataWithExpiration = async (key, data, timestamp = 60) => {
    const expiration = Date.now() + 1000 * timestamp;
    await this.saveData(key, {
      expiration,
      data,
    });
  };
  static getDataWithExpiration = async (key, hasVersion = true) => {
    const content = await this.getData(key, hasVersion);
    if (content) {
      const expiration = content?.expiration || 0;
      if (Date.now() > expiration) {
        await this.removeItem(key);
        return null;
      }
      return content?.data;
    }
    return content;
  };
}

export default CacheDataController;
