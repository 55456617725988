import GeneralModule from '../../../modules/GeneralModule';

export default async function listGeneric(groupId, id, params, offset) {
  const pagination = { page: 0, offset, active: true };

  let active = true;
  let data = '';
  try {
    while (active) {
      const listPromise = new Promise((resolve, reject) =>
        GeneralModule.getCustomList(
          `${groupId}`,
          `${id}`,
          JSON.stringify(params),
          true,
          JSON.stringify(pagination),
          resolve,
          reject,
        ));
      const resp = await listPromise;
      data = `${data}${resp}`;

      if (!(resp.length > 0)) {
        active = false;
      }

      pagination.page += 1;
    }

    return JSON.parse(data);
  } catch (errors) {
    throw errors;
  }
}
