import CachePolistore from '../Cache/CachePolistore';
import ValidadeLoginPolistore from './ValidadeLoginPolistore';
import { POLIEQUIPES, POLISTORE } from '../escopeApps';

class ValidadeLoginFactory {
  static createValidadeLogin(dataAccount) {
    const validade = [];
    dataAccount.apps.forEach((escope) => {
      if (escope === POLIEQUIPES || escope === POLISTORE) {
        const cache = new CachePolistore();
        validade.push(new ValidadeLoginPolistore(cache));
      }
    });

    return validade;
  }
}

export default ValidadeLoginFactory;
