import AsyncStorage from '@react-native-async-storage/async-storage';

class AddressAltCache {
  async getAddressAlt(pkeyClient) {
    const data = await AsyncStorage.getItem('@Session:CacheAddressAlt');
    const addressAlt = data ? JSON.parse(data) : false;
    return addressAlt && addressAlt.pkeyClient === pkeyClient
      ? addressAlt
      : false;
  }

  async setAddressAlt(addressAlt) {
    await AsyncStorage.setItem(
      '@Session:CacheAddressAlt',
      JSON.stringify(addressAlt),
    );
  }

  async removeAddressAlt() {
    await AsyncStorage.removeItem('@Session:CacheAddressAlt');
  }
}

export default new AddressAltCache();
