import { StyleSheet } from 'react-native';
import { color } from '~/common';

const styles = StyleSheet.create({
  container: {
    marginTop: 4,
    marginBottom: 4,
    padding: 12,
    backgroundColor: '#FFFFFF',
  },
  header: {
    fontSize: 16,
    fontWeight: 'normal',
    lineHeight: 24,
    color: '#1A1A1A',
  },
  img: {
    marginTop: 20,
    marginBottom: 0,
    marginRight: 4,
    width: 20,
    height: 20,
    tintColor: color.primaryColor,
  },
  title: {
    flex: 1,
    fontWeight: 'bold',
    fontSize: 16,
    color: color.grayDark,
    marginHorizontal: 2,
    paddingRight: 12,
  },
  btn: {
    fontSize: 16,
    fontWeight: 'bold',
    alignSelf: 'flex-end',
    lineHeight: 24,
    color: '#38A0FF',

  },
  addrComplete: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginHorizontal: 2,
    paddingRight: 12,
  },
});

export default styles;
