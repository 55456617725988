/* @flow */

import React from 'react';
import Section from './Section';
import SelectMenu from '../../../components/SelectMenu';

type Props = {
  title: string,
  actions: Array<string>,
  onSelect(): () => void,
  onChange(): () => void,
  label: string,
  value?: string,
}

function Selector(props: Props) {
  return (
    <Section title={props.title}>
      <SelectMenu
        style={{ marginTop: 10 }}
        value={props.value || ''}
        actions={props.actions}
        onPress={props.onSelect}
        onChangeValue={props.onChange}
        title={props.label}
      />
    </Section>
  );
}

export default Selector;
