/* @flow */

import React from 'react';
import { View, Image, Text, StyleSheet, Switch } from 'react-native';

import Touchable from 'react-native-platform-touchable';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { color } from '~/common';
import Images from '@assets/Images';
import translate from '~/locales';
import Constants from './Constants';

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 24,
    paddingVertical: 16,
    gap: 16,
  },
  title: {
    fontSize: 18,
    fontWeight: '700',
    color: color.black,
    marginBottom: 16,
  },
  actions: {
    marginVertical: 14,
  },
  actionTitle: {
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 24,
    color: color.black,
    marginBottom: 8,
  },
  actionDescription: {
    fontSize: 10,
    fontWeight: 'bold',
    lineHeight: 12,
    letterSpacing: 0.2,
    color: color.neutral500,
  },
  separator: {
    height: 1,
    backgroundColor: color.grayContainer,
  },
  actionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 14,
  },
});

function Action({ item }) {
  return (
    <View>
      <Touchable
        onPress={item?.onPress}>
        <View style={styles.actionContainer}>
          <View style={{ flexBasis: '80%' }}>
            <Text style={styles.actionTitle}>{item?.title || ''}</Text>
            <Text style={styles.actionDescription}>
              {item?.description || ''}
            </Text>
          </View>
          <View>
            {item?.buttonType === Constants.BUTTONTYPE.toggle ? (
              <Switch
                value={item?.value ?? false}
                thumbColor="#00529E"
                onValueChange={item?.onPress}
              />
            ) : null}
            {item?.buttonType === Constants.BUTTONTYPE.default ? (
              <View style={{ marginRight: 8 }}>
                <Image source={item?.icon || Images.iconExternalLink} />
              </View>
            ) : null}
          </View>
        </View>
      </Touchable>
      <View style={styles.separator} />
    </View>
  );
}

Action.propTypes = {
  item: PropTypes.shape({
    onPress: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string,
    buttonType: PropTypes.string,
    value: PropTypes.string,
    icon: PropTypes.any,
  }),
};

function QuickActions({ actions = [] }) {
  const actionsToShow = _.filter(actions, { show: true });

  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.title}>{`${translate('quickActions')}`}</Text>
        <View style={styles.separator} />
      </View>
      <View>
        {actionsToShow.map(act => <Action key={act.id} item={act} />)}
      </View>
    </View>
  );
}

QuickActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
};

export default QuickActions;
