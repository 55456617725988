/* @flow */

import React from 'react';
import {
    Alert,
    Image,
    KeyboardAvoidingView,
    ScrollView,
    StyleSheet,
    TouchableOpacity,
    View,
} from 'react-native';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import style from '~/common/style';
import Gps from '../../../../components_base/src/components/Gps/Gps';
import GPSManager from '../../../controllers/GPS/GPSManager';
import Button from '../../../components/Button';
import GpsHelper from '../../../utils/GpsHelper';
import ActivitiesCache from '../../../utils/ActivitiesCache';
import {
    OPENING_VISIT_SUCCESS,
    OPENING_VISIT_FAILURE,
    opening as openingVisit,
    CLOSING_VISIT_SUCCESS,
    CLOSING_VISIT_FAILURE,
    closing as closingVisit,
    CLOSING_VISIT,
    OPENING_VISIT,
} from '../../../store/visit/action';
import { SYNC_PROCESS_SALES } from '../../../constants/Sync';
import { syncUpdateScreen } from '../../../store/syncFetch/slice';
import translate from '../../../locales';
import ActivityCache from '../../../cache/ActivityCache';
import getQueryResult from '../../../services/resources/libFastSeller/genericQuery';

import {
    getAddressAlternative,
    setAddressSelected as setAddressAlternative,
    ADDRESS_ALTERNATIVE_FAILURE,
    ADDRESS_ALTERNATIVE_SUCCESS,
    ADDRESS_ALTERNATIVE_SELECTED,
} from '~/store/addressAlternativeFetch/slice';
import ClientsModule from '../../../modules/ClientsModule';
import AddressAltCache from '../../../cache/AddressAltCache';
import AddressAlternative from '~/components/AddressAlternative/AddressAlternative';
import VisitsModule from '~/modules/VisitsModule';
import AccountsModule from '~/modules/AccountsModule';
import HandlerSaveAnswerLocation from './HandlerSaveAnswerLocation';
import TextInputScanner from '../components/TextInputScanner';
import Images from "@assets/Images";
import {createNotifyError} from "~/components/Web/ToastNotify";
import { tasksSync } from '~/TaskSync.web';

type Props = {
    item: {
        kind_key: string,
        required: boolean,
        name: string,
        company: string,
        id: string,
    },
    syncFetch: any,
    event: any,
    dispatch: any,
    permission: any,
    sVisit: any,
    dateSelected: any,
    listAddressAlternative: Array,
    saveAnswerActivity: (
        payloadAnswer: Object<any>,
        successSaveAnswer: Function<any>,
        failureSaveAnswer: Function<any>,
    ) => void,
};

const OPEN_VISIT_ACTION = 'OPEN_VISIT_ACTION';
const CLOSE_VISIT_ACTION = 'CLOSE_VISIT_ACTION';

class LocationContainer extends React.Component<Props, void> {
    constructor(props) {
        super(props);

        this.location = '';
        this.action = '';
        this.addressAltConfig = false;
        this.addressAltSelected = false;
        this.state = {
            loading: false,
            dateOpen: '',
            message: '',
            permission: {
                response: '',
            },
            location: {
                latitude: null,
                longitude: null,
                timestamp: 0,
                accuracy: 0,
                altitude: 0,
                speed: 0,
                heading: 0,
            },
            gpsError: '',
            listAddressAlt: [],

            addrPkey: '',
            addrTitle: '',
            addrCEP: '',
            addrUF: '',
            addrCity: '',
            addrDistrict: '',
            addrName: '',
            addrComplement: '',
        };
        this.gpsManager = new GPSManager();
        this.activitySetup = JSON.parse(this.props?.item?.setup || '{}');
    }

    UNSAFE_componentWillReceiveProps({
                                         permission,
                                         sVisit,
                                         syncFetch,
                                         listAddressAlternative,
                                     }) {

        (() => {
            if (sVisit.type === this.props.sVisit.type) {
                return;
            }
            if (sVisit.type !== OPENING_VISIT_SUCCESS) {
                return;
            }

            this.saveAnswer();
        })();

        (() => {
            if (sVisit.type === this.props.sVisit.type) {
                return;
            }
            if (sVisit.type !== OPENING_VISIT_FAILURE) {
                return;
            }
            alert(sVisit.payload[0].mensagem);
            this.setState({ loading: false });
        })();

        (() => {
            if (sVisit.type === this.props.sVisit.type) {
                return;
            }
            if (sVisit.type !== CLOSING_VISIT_SUCCESS) {
                return;
            }

            this.saveAnswer();
        })();

        (() => {
            if (sVisit.type === this.props.sVisit.type) {
                return;
            }
            if (sVisit.type !== CLOSING_VISIT_FAILURE) {
                return;
            }
            alert(sVisit.payload[0].mensagem);
            this.setState({ loading: false });
        })();

        (() => {
            if (syncFetch.type === this.props.syncFetch.type) {
                return;
            }
            if (
                syncFetch.payload.statusSync !== SYNC_PROCESS_SALES &&
                syncFetch.payload.screen === 'activities.Location'
            ) {
                this.props.dispatch(syncUpdateScreen(''));
                const { event } = this.props;

                if (this.action === OPEN_VISIT_ACTION) {
                    this.props.dispatch(openingVisit(event.pkey, this.location));
                } else if (this.action === CLOSE_VISIT_ACTION) {
                    this.props.dispatch(closingVisit(event.pkey, this.location));
                }
            }
        })();

        (() => {
            if (
                listAddressAlternative.type === this.props.listAddressAlternative.type
            ) {
                return;
            }
            if (listAddressAlternative.type === ADDRESS_ALTERNATIVE_SUCCESS) {
                this.setState({
                    listAddressAlt: listAddressAlternative.payload,
                });
            }
        })();
        (() => {
            if (
                listAddressAlternative.type === this.props.listAddressAlternative.type
            ) {
                return;
            }
            if (listAddressAlternative.type === ADDRESS_ALTERNATIVE_FAILURE) {
                const error = _.get(listAddressAlternative, 'payload[0].mensagem', '');
                if (error) {
                    alert(error);
                }
            }
        })();
        (() => {
            if (listAddressAlternative.type === ADDRESS_ALTERNATIVE_SELECTED) {
                const { selected } = listAddressAlternative;
                if (selected) {
                    this.addressAltSelected = selected;
                }
            }
        })();
    }

    async componentDidMount() {
        if (!_.isObject(this.addressAltSelected)) {
            const event = _.get(this.props.route.params.pushObject, 'event', {});
            const { pkey: pkeyClient } = event;
            const addressAlt = await AddressAltCache.getAddressAlt(pkeyClient);
            this.addressAltSelected = addressAlt;
        }
        const content = _.get(this.props.route.params.pushObject, 'item.answer.payload.content', '');

        const startDate = _.get(
            this.props.route.params.pushObject,
            'item.answer.start_date',
            new Date().toISOString(),
        );

        await this.configAddressAlternative();

        this.setState({
            message: content,
            dateOpen: startDate,
        });

        this.props.navigation.setOptions({
            headerLeft: () => (
                <TouchableOpacity onPress={async () => {
                    const { answer = false } = _.get(this.props.route.params.pushObject, 'item', {});
                    if (!answer) {
                        await AddressAltCache.removeAddressAlt();
                        this.props.dispatch(setAddressAlternative(null));
                    }
                    this.props.navigation.goBack();
                }}
                >
                    <Image
                        source={Images.iconBack}
                        style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
                    />
                </TouchableOpacity>
            ),
        });
    }

    async configAddressAlternative() {
        const { pkey: pkeyClient } = _.get(this.props.route.params.pushObject, 'event', {});
        const { address_alt: addressAlt, action } = JSON.parse(
            _.get(this.props.route.params.pushObject, 'item.setup', '{}'),
        );
        if (addressAlt && action === 'checkin') {
            const clientJson = JSON.parse(await this.getClient(pkeyClient));

            this.mainAddressClient = _.get(clientJson, 'enderecos', []);

            this.addressAltConfig = true;
            this.props.dispatch(getAddressAlternative({ pkeyClient }));
        }
    }

    getClient = pkey =>
        new Promise((resolve, reject) => {
            ClientsModule.detailsClient(pkey, resolve, reject);
        });

    onChangeMessage = message => {
        this.setState({ message });
    };

    // eslint-disable-next-line react/sort-comp
    async saveAnswer() {
        const { dateOpen, location, message } = this.state;
        const { dateSelected, item } = this.props.route.params.pushObject;
        const setup = JSON.parse(item.setup || '{}') || {};

        const dateNow = moment().startOf('day');
        const dateRoute = moment(dateSelected).startOf('day');
        const onSchedule = dateNow.isSame(dateRoute);
        const isCheckIn = setup.action === 'checkin';
        const isCheckOut = setup.action === 'checkout';
        let checkInId = 0;

        if (isCheckIn) {
            try {
                const result = (await getQueryResult('ATIV;LAST;CKID')) || '[]';
                const parsedResult = JSON.parse(result);
                const data = _.get(parsedResult, ['0', 'checkin_id'], '0');
                checkInId = parseInt(data, 10);
            } catch (err) {
                console.log('SAVE ANSWER ERROR: ', err)
            }
        } else if (isCheckOut) {
            await AddressAltCache.removeAddressAlt();
            this.props.dispatch(setAddressAlternative(null));
        }

        const payloadAnswer = {
            dateSelected,
            dateOpen,
            latitude: location.latitude,
            longitude: location.longitude,
            payload: {
                latitude: location.latitude,
                longitude: location.longitude,
                altitude: location.altitude,
                speed: location.speed,
                heading: location.heading,
                accuracy: location.accuracy,
                content: message,
                location,
                on_schedule: onSchedule,
                checkin_id: isCheckIn ? (checkInId += 1) : null,
            },
            taskId: item.id,
        };

        try {
            this.props.route.params.pushObject.saveAnswerActivity(
                payloadAnswer,
                this.successSaveAnswer,
                this.failureSaveAnswer,
            );
            if (isCheckOut) {
                await AddressAltCache.removeAddressAlt();
                this.props.dispatch(setAddressAlternative(null));
            }
        } catch (err) {
            alert(`${translate('cantSaveAnswer')} ${translate('pleaseTryAgain')}`);
        } finally {
            this.setState({ loading: false });
        }
    }

    successSaveAnswer = () => {};
    failureSaveAnswer = () => {};

    async openingVisit(location) {
        const { event, item } = this.props.route.params.pushObject;
        this.setVisitStatus(OPENING_VISIT);

        try {
            const data = JSON.stringify({
                client: event,
                date: moment().format('YYYY-MM-DD'),
                activitiesBlocked: false,
                clientsBlocked: true,
                isNewCheckIn: !!item.answer,
                itineraryDate: this.props.route.params.pushObject.dateSelected,
            });

            await AccountsModule.salvarInfoAtivo('processo_ativo', data);
        
            try {
                await ActivityCache.setTaskListProcessBlock(data);
            } catch (err) {
                console.log('ERROR SET ActivityCache.setTaskListProcessBlock', err.message);
            }
        } catch (err) {
            console.log('ERROR SET PROCESS', err.message);
        }

        new Promise((res, rej) => {
            VisitsModule.opening(
                event.pkey,
                JSON.stringify(location || {}),
                res,
                rej,
            );
        })
            .then(() => {
                this.saveAnswer();
            })
            .catch(err => {
                createNotifyError('Erro ao abrir visita');
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    async closingVisit(location) {
        const { event, item } = this.props.route.params.pushObject;
        this.setVisitStatus(CLOSING_VISIT);

        ActivityCache.setTaskListProcessBlock({
            client: event.pkey || '',
            date: moment().format('YYYY-MM-DD'),
            activitiesBlocked: true,
            clientsBlocked: false,
            isNewCheckIn: !!item.answer,
            itineraryDate: this.props.route.params.pushObject.dateSelected,
        });

        new Promise((res, rej) => {
            VisitsModule.closing(
                event.pkey,
                JSON.stringify(location || {}),
                res,
                rej,
            );
        })
            .then(() => {
                this.saveAnswer();
                tasksSync();
            })
            .catch(err => {
                console.log(err.message);
                createNotifyError('Erro ao fechar visita');
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    onSaveChoice = () => {
        const { location } = this.state;

        const { item, event } = this.props.route.params.pushObject;
        const setup = JSON.parse(item.setup || '{}');

        const clientLocation = this.addressAltSelected || event;

        const continueSaving = HandlerSaveAnswerLocation.handle({
            ...this.state,
            ...this.props.route.params.pushObject,
            location,
            clientLocation,
        });
        if (!continueSaving) {
            return;
        }
        this.setState({ loading: true });

        if (setup.action) {
            if (setup.action.localeCompare('checkin') === 0) {
                this.openingVisit(location);
            } else if (setup.action.localeCompare('checkout') === 0) {
                this.closingVisit(location);
            }
        } else {
            this.saveAnswer();
        }
    };

    setVisitStatus = async status => {
        const { nome: name = '', pkey = '' } = this.props.route.params.pushObject.event;
        const data = {
            status,
            client: {
                name,
                pkey,
            },
        };
        try {
            if (status === OPENING_VISIT) {
                await ActivitiesCache.setVisitStatus(data);
            } else if (status === CLOSING_VISIT) {
                await ActivitiesCache.removeVisitStatusKey();
            }
        } catch (err) {}
    };

    gpsListener = () => {
        /* GPSState.addListener(status => {
            switch (status) {
                case GPSState.RESTRICTED:
                    this.setState({ gpsError: `${translate('unabledLocation')}` }, () => {
                        GPSState.requestAuthorization(GPSState.AUTHORIZED_ALWAYS);
                    });
                    break;
                case GPSState.AUTHORIZED_ALWAYS:
                case GPSState.AUTHORIZED_WHENINUSE:
                    this.setState({ gpsError: '' });
                    break;
                default:
                    break;
            }
        }); */
    };

    error = message => {
        createNotifyError(message);
    };

    onLocationChangeFromMap = location => {
        if (!_.isEqual(location, this.state.location)) {
            this.setState({ location });
        }
    };

    onChangeAddressAlt = async addressAlt => {
        const { pkey: pkeyClient } = _.get(this.props.route.params.pushObject, 'event', {});
        const { pkey: addrPkey } = addressAlt;
        const { codigo, nome, fantasia } = _.get(this.props.route.params.pushObject, 'event', {});

        const address = {
            ...this.props.route.params.pushObject.event,
            ...addressAlt,
            pkeyClient,
            pkey: pkeyClient,
        };
        const parsedGps = GpsHelper.parseGpsEvent({
            codigo,
            nome,
            fantasia,
            ...address,
        });

        const addrAlt = { ...address, ...parsedGps };
        await AddressAltCache.setAddressAlt(addrAlt);
        this.props.dispatch(setAddressAlternative(addrAlt));

        this.setState({ addrPkey });
    };

    getGpsPlaces = () => {
        const event = _.get(this.props.route.params.pushObject, 'event', {});
        const { codigo, nome, fantasia } = event;
        const places = this.addressAltSelected || event;
        return [
            GpsHelper.parseGpsEvent({
                codigo,
                nome,
                fantasia,
                ...places,
            }),
        ];
    };

    getAddressAlt() {
        const [addr = false] = this.addressAltSelected
            ? [this.addressAltSelected]
            : this.mainAddressClient || [];

        return addr;
    }

    isShowAddrAlternative() {
        const addr = this.getAddressAlt();
        return [this.addressAltConfig, addr].every(elemt => elemt);
    }

    render() {
        const { message, loading, location, gpsError, listAddressAlt } = this.state;

        const { pkey: pkeyClient = false } = _.get(this.props.route.params.pushObject, 'event', {});
        const gpsPlaces = this.getGpsPlaces();
        const addrAlt = this.getAddressAlt();

        return (
            <View style={style.container}>
                <ScrollView
                    keyboardShouldPersistTaps="always"
                    style={{ marginBottom: 70 }}>
                    <KeyboardAvoidingView behavior="position" keyboardVerticalOffset={10}>
                        <View style={{ flex: 1 }}>
                            <Gps
                                places={this.props.route.params.pushObject.event ? gpsPlaces : null}
                                pkeyClient={pkeyClient}
                                location={location}
                                onLocationChange={this.onLocationChangeFromMap}
                                gpsError={gpsError}
                                showPermitionLocation
                            />
                            {this.isShowAddrAlternative() ? (
                                <AddressAlternative
                                    address={addrAlt}
                                    listAddressAlt={listAddressAlt}
                                    onChangeAddressAlt={selected =>
                                        this.onChangeAddressAlt(selected)
                                    }
                                    pkeyClient={pkeyClient}
                                    location={location}
                                />
                            ) : null}
                            <TextInputScanner
                                value={message}
                                onChangeText={this.onChangeMessage}
                                config={this.activitySetup?.input_config}
                            />
                        </View>
                    </KeyboardAvoidingView>
                </ScrollView>
                <View
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: '#fff',
                    }}>
                    <Button
                        style={{ margin: 16 }}
                        onPress={this.onSaveChoice}
                        title={loading ? `${translate('saving')}` : `${translate('save')}`}
                        disabled={loading}
                    />
                </View>
            </View>
        );
    }
}

function mapStateToProps({ syncFetch, addressAlternativeFetch }) {
    return {
        permission: {},
        sVisit: {},
        syncFetch: syncFetch,
        listAddressAlternative: addressAlternativeFetch,
    };
}

export default connect(mapStateToProps)(LocationContainer);