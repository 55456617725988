import React from 'react';
import { View } from 'react-native';

import ItemCheck from './ItemCheck';
import Section from '../Section';

type Props = {
  title: string,
  value: Object,
  data: Array<Object>,
  onChange: Function,
};

const CheckList = ({ title, value, data, onChange }: Props) => (
  <Section title={title}>
    <View style={{ padding: 10, flexDirection: 'row' }}>
      {data.map(item => (
        <ItemCheck
          key={item}
          value={item}
          onPress={onChange}
          selected={value.map(v => v.name).includes(item)}
        />
      ))}
    </View>
  </Section>
);

export default CheckList;
