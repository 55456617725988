import React from 'react';
import { View } from 'react-native';
import { Placeholder, Fade, PlaceholderMedia } from 'rn-placeholder';
import styles from './styles';

export function CategoriesPlaceholder() {
    const placeholders = Array.from({ length: 20 }, (_, i) => (
        <View key={i} style={styles.containerProductCategories}>
            <PlaceholderMedia hasRadius style={styles.productCategoriesStyle} />
        </View>
    ));

    return (
        <Placeholder Animation={Fade}>
            <View style={styles.rootConatiner}>
                {placeholders}
            </View>
        </Placeholder>
    );
}