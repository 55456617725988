/* @flow */

import React, { Component } from 'react';
import { Alert, BackHandler } from 'react-native';
import { connect } from 'react-redux';
import { SCREEN_ACTIVITIES, SCREEN_ADD_PROSPECT } from '../screens';
import TextUtils from '../../utils/TextUtils';
import Prospects from './Prospects';
import GeneralModule from '../../modules/GeneralModule';
import { emp2long } from '../../utils/FastSellerUtils';
import { Navigation } from 'react-native-navigation';
import NavigationHelper from '../NavigationHelper';

import PropTypes from 'prop-types';

class ProspectsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statusProspects: [],
      showSearch: false,
      searchText: '',
      data: [],
    };

    this.pushingScreen = false;

    this.backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      this.handleBackPress,
    );
  }

  async componentDidMount() {
    const groupId = emp2long('POLI');
    const id = emp2long('PRST');
    const params = {};

    const statusProspectsPromise = new Promise((resolve, reject) =>
      GeneralModule.getCustomList(
        `${groupId}`,
        `${id}`,
        JSON.stringify(params),
        true,
        null,
        resolve,
        reject,
      ),
    );

    try {
      let statusProspects = await statusProspectsPromise;
      statusProspects = JSON.parse(statusProspects).data;
      this.setState({ statusProspects });
    } catch (errors) {
      const jssonArray = JSON.parse(errors);
      Alert.alert('', jssonArray[0].mensagem);
    }
  }

  componentWillUnmount() {
    this.backHandler?.remove();
  }
  componentDidAppear() {
    this.listProspects();
  }

  componentDidDisappear() {
    this.pushingScreen = false;
  }

  navigationButtonPressed(event) {
    switch (event.buttonId) {
      case 'add':
        this.onClickAdd();
        break;
      case 'search':
        this.setState({ showSearch: !this.state.showSearch, searchText: '' });
        break;
      case 'filter':
        break;
      case 'bottomTabSelected':
        if (event.selectedTabIndex === 0) {
          NavigationHelper.popToRoot(this.props.componentId);
        }
        break;
      case 'backPress':
        this.handleBackPress();
        break;
      default:
        break;
    }
  }

  onPressItem = item => {
    NavigationHelper.push(
      this.props.componentId,
      SCREEN_ACTIVITIES.name,
      SCREEN_ACTIVITIES.title,
      { event: item },
    );
  };

  onClickAdd = () => {
    this.pushingScreen = true;
    NavigationHelper.push(
      this.props.componentId,
      SCREEN_ADD_PROSPECT.name,
      SCREEN_ADD_PROSPECT.title,
      { overrideBackPress: true },
    );
  };

  onChangeText = text => {
    clearTimeout(this);
    setTimeout(() => {
      this.setState({ searchText: TextUtils.slugfy(text) });
    }, 350);
  };

  getFilteredData() {
    const search =
      this.state.searchText && this.state.searchText.toLowerCase().trim();
    return this.state.data.filter(item => {
      const { pkey, t_data, nome } = item;
      const res =
        (pkey &&
          pkey
            .toLowerCase()
            .trim()
            .includes(search)) ||
        (t_data &&
          TextUtils.slugfy(t_data)
            .toLowerCase()
            .trim()
            .includes(search)) ||
        (nome &&
          TextUtils.slugfy(nome)
            .toLowerCase()
            .trim()
            .includes(search));
      return res;
    });
  }

  searchIn(list, search) {
    let res = false;
    list.map(item => {
      res =
        res ||
        TextUtils.slugfy(item.valor)
          .toLowerCase()
          .trim()
          .includes(search);
      return item;
    });
    return res;
  }

  async listProspects() {
    const groupId = emp2long('POLI');
    const id = emp2long('PRCT');
    const params = {};

    const getProspects = new Promise((resolve, reject) =>
      GeneralModule.getCustomList(
        `${groupId}`,
        `${id}`,
        JSON.stringify(params),
        true,
        null,
        resolve,
        reject,
      ),
    );
    try {
      const dataProspects = await getProspects;
      const parsedDataProspects = JSON.parse(dataProspects)?.data || [];
      this.setState({ data: parsedDataProspects });
    } catch (errors) {
      const parseErrorMsg = JSON.parse(errors);
      Alert.alert('', parseErrorMsg[0].mensagem);
    }
  }

  handleBackPress = () => {
    NavigationHelper.pop(this.props.componentId);
    return true;
  };

  render() {
    return (
      <Prospects
        statusProspects={this.state.statusProspects}
        showSearch={this.state.showSearch}
        loading={this.props.prospects.loading}
        onPressItem={this.onPressItem}
        onPressAdd={this.onClickAdd}
        onChangeText={this.onChangeText}
        prospects={this.getFilteredData()}
      />
    );
  }
}

ProspectsContainer.propTypes = {
    prospects: PropTypes.shape({
        payload: PropTypes.object,
        loading: PropTypes.bool,
        type: PropTypes.string,
    }),
    navigator: PropTypes.shape({
        addnavigationButtonPressed: PropTypes.func,
        push: PropTypes.func,
        popToRoot: PropTypes.func,
        pop: PropTypes.func,
        setStyle: PropTypes.func,
        setButtons: PropTypes.func,
    }),
};

function mapStateToProps(state) {
  return {
    prospects: state.prospects,
  };
}

export default connect(mapStateToProps)(ProspectsContainer);
