import Decimal from 'decimal.js';
import _ from 'lodash';
import requiredPointGPS from '../constants/GPS';
import translate from '../locales';

const EARTH_RADIUS = 6371.0;

export default class GpsHelper {
  static haversineKm(pointA, pointB) {
    const radians = Math.PI / 180.0;
    const latA = pointA.latitude * radians;
    const lngA = pointA.longitude * radians;
    const latB = pointB.latitude * radians;
    const lngB = pointB.longitude * radians;

    const result = Math.acos(
      Math.sin(latA) * Math.sin(latB) +
        Math.cos(latA) * Math.cos(latB) * Math.cos(lngB - lngA),
    );

    return isNaN(result) ? 0 : result * EARTH_RADIUS;
  }
  static isPresentInArea(pointA, radiusA, pointB, radiusB) {
    const distance = GpsHelper.haversineKm(pointA, pointB) * 1000.0;
    const newRadiusA = radiusA < 1 ? radiusA * 1000 : radiusA;
    const newRadiusB = radiusB < 1 ? radiusB * 1000 : radiusB;
    const radius = new Decimal(newRadiusA).plus(newRadiusB);
    return radius.minus(new Decimal(distance)) >= 0.0;
  }

  static parseGpsEvent(event) {
    const { codigo, nome, fantasia, raio, latitude, longitude } = event || {};

    const title = nome || fantasia;
    const radius = raio || 100.0;
    return {
      latitude,
      longitude,
      radius,
      title: `${codigo} - ${title}`,
    };
  }

  static validateRulesGPSClient(setup, client, location) {
    if (
      setup.required_collect_gps_point === requiredPointGPS.REQUIRED ||
      setup.required_collect_gps_point === requiredPointGPS.REQUIRED_IN_AREA
    ) {
      if (!(location.latitude && location.longitude)) {
        return `${translate('errorPointRequiredGPS')}`;
      }

      if (
        setup.required_collect_gps_point === requiredPointGPS.REQUIRED_IN_AREA
      ) {
        if (!client.raio) {
          return `${translate('errorRadiusClientRequiredGPS')}`;
        }

        const isPresent = GpsHelper.isPresentInArea(
          client,
          client.raio,
          location,
          location.accuracy,
        );

        if (!isPresent) {
          return `${translate('errorAreaClientGPS')}`;
        }
      }
    }

    return '';
  }

  static validateRulesByConfigs = ({ required, verify, client, location }) => {
    const hasLocation = location.latitude && location.longitude;
    const msgs = [];

    if (required && !hasLocation) {
      msgs.push(`${translate('errorPointRequiredGPS')}`);
    }

    if (required && verify && !msgs.length) {
      if (!client.raio) {
        msgs.push(`${translate('errorRadiusClientRequiredGPS')}`);
      } else {
        const isPresent = GpsHelper.isPresentInArea(
          client,
          client.raio,
          location,
          location.accuracy,
        );
        if (!isPresent) {
          msgs.push(`${translate('errorAreaClientGPS')}`);
        }
      }
    }

    return {
      msgs,
    };
  };

  static getGpsConfigsByLibAndActivity(payloadLib, payloadActivity, type) {
    const isTrueSet = str => str === 'true';
    const requiredDemand = isTrueSet(
      _.get(payloadLib, `required_${type}_demand`, 'false'),
    );
    const verifyAreaDemand = isTrueSet(
      _.get(payloadLib, `verify_area_client_${type}_demand`, 'false'),
    );

    const requiredByActivity =
      payloadActivity.required_collect_gps_point === requiredPointGPS.REQUIRED;
    const verifyAreaByActivity =
      payloadActivity.required_collect_gps_point ===
      requiredPointGPS.REQUIRED_IN_AREA;

    return {
      requiredDemand: requiredDemand || requiredByActivity,
      verifyAreaDemand: verifyAreaDemand || verifyAreaByActivity,
    };
  }
}
