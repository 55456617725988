/* @flow */

import React from 'react';
import { ScrollView, View } from 'react-native';

import CarouselPhoto from './CarouselPhoto/CarouselPhoto';

import { style } from '~/common';
import { Button } from '~/components';
import Option from '../components/Option';
import SelectPicker from '../../../components/SelectPicker';
import translate from '../../../locales';
import BottomAlert from '../../../components/BottomAlert';
import Images from '../../../../assets/Images';
import BeforeAndAfter from './BeforeAndAfter/BeforeAndAfter';
import TextInputScanner from '../components/TextInputScanner';

type Props = {
  onPressShowCamera: () => {},
  onFinish: () => {},
  onChangeMessage: () => {},
  onRemovePhoto: () => void,
  selectValue: () => {},
  onPressExit: () => {},
  hideModal: () => {},
  photos: [],
  message: string,
  extra_comp: any,
  extra_info: any,
  loading: boolean,
  showBackPressAlert: boolean,
  photoAction: String,
  beforeSrc: Object,
  afterSrc: Object,
  gpsComponent: any,
  setup: Object,
};

class Photo extends React.Component<Props, void> {
  constructor(props) {
    super(props);

    this.state = {
      mycomp: '',
      valueSelected: '',
    };
  }

  componentDidMount() {
    if (typeof this.props.extra_comp !== 'undefined') {
      this.setState({ mycomp: this.props.extra_comp });
    }
    if (typeof this.props.extra_info !== 'undefined') {
      this.setState({ valueSelected: this.props.extra_info });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.showBackPressAlert === this.props.showBackPressAlert) {
      return;
    }
    if (nextProps.showBackPressAlert) {
      this.bottomAlert.open();
    }
  }

  onConfirmBackPress = () => {
    this.bottomAlert.close();
    this.props.onPressExit();
  };

  onConfirmSave = () => {
    this.bottomAlert.close();
    this.props.onFinish();
  };

  execFunc = itemValue => {
    this.props.selectValue(itemValue);
    this.setState({ valueSelected: itemValue });
  };

  renderExtra = () => (
    <SelectPicker
      selected={this.state.valueSelected || ''}
      style={{ margin: 0, marginTop: 16 }}
      title={this.state.mycomp[0].content.title || ''}
      items={this.state.mycomp[0].content.options || {}}
      onChangeValue={itemValue => {
        this.execFunc(itemValue);
      }}
    />
  );

  render() {
    const {
      photos,
      hideModal,
      photoAction,
      onPressShowCamera,
      onRemovePhoto,
      loading,
      onFinish,
      onChangeMessage,
      message,
      afterSrc,
      beforeSrc,
    } = this.props;
    const photosVisible = photos.filter(
      photo => photo.deleted_at === undefined
    );

    return (
      <View style={{ flex: 1 }}>
        <ScrollView style={{ flexGrow: 1 }}>
          <View style={{ flex: 1 }}>{this.props.gpsComponent}</View>
          <View style={style.whiteContainer}>
            {photoAction === 'before_and_after' ? (
              <BeforeAndAfter
                afterSrc={afterSrc || Images.iconCamera}
                beforeSrc={beforeSrc || Images.iconCamera}
                onPressShowCamera={onPressShowCamera}
                onRemovePhoto={onRemovePhoto}
              />
            ) : (
              <View style={{ flex: 1 }}>
                {photosVisible.length === 0 && photos.length === 0 ? (
                  <View style={{ paddingHorizontal: 30 }}>
                    <Option
                      title={`${translate('takePicture')}`}
                      source={Images.iconCamera}
                      onPress={onPressShowCamera}
                      containerStyle={{ margin: 20, marginBottom: 0 }}
                    />
                  </View>
                ) : (
                  <CarouselPhoto
                    useCache={false}
                    photos={photos}
                    source={Images.iconCamera}
                    onPressShowCamera={onPressShowCamera}
                    onRemovePhoto={onRemovePhoto}
                  />
                )}
              </View>
            )}

            {this.state.mycomp !== '' && this.state.mycomp[0].type === 'picker'
              ? this.renderExtra()
              : null}

            <TextInputScanner
              value={message}
              onChangeText={onChangeMessage}
              config={this.props.setup?.input_config}
            />

            <Button
              disabled={loading}
              onPress={onFinish}
              title={
                loading ? `${translate('saving')}` : `${translate('save')}`
              }
              containerStyle={{ marginHorizontal: 16, marginBottom: 16 }}
            />
            <BottomAlert
              setRef={ref => {
                this.bottomAlert = ref;
              }}
              title={`${translate('atention')}`}
              description={`${translate('alertPhotoActivity')}`}
              mainButtonTitle={`${translate('save')}`}
              secondaryButtonTitle={`${translate('getOut')}`}
              hasSecondaryButton
              onPressSecondaryButton={this.onConfirmBackPress}
              onPressMainButton={this.onConfirmSave}
              onClose={hideModal}
            />
          </View>
        </ScrollView>
      </View>
    );
  }
}

export default Photo;
