import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getListWithPagination } from '~/utils/FastSellerUtils';
import ClientsModule from '../../modules/ClientsModule';

export const NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_REQUEST';
export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS';
export const NOTIFICATIONS_FAILURE = 'NOTIFICATIONS_FAILURE';

const initialState = {
  type: '',
  loading: false,
  error: false,
  payload: [],
};

export const getNotifications = createAsyncThunk(
  'notifications/getNotifications',
  async (args, { getState }) => {
    const state = getState();
    const offset = state.configLibFetch.payload.tam_memoria_paginacao;
    try {
      const response = await getListWithPagination(
        ClientsModule.listNotifications,
        offset,
      );
      return response;
    } catch (err) {
      throw err;
    }
  },
);

const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getNotifications.pending, state => {
      state.type = NOTIFICATIONS_REQUEST;
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.type = NOTIFICATIONS_SUCCESS;
      state.loading = false;
      state.error = false;
      state.payload = action.payload;
    });
    builder.addCase(getNotifications.rejected, (state, action) => {
      state.type = NOTIFICATIONS_FAILURE;
      state.loading = false;
      state.error = true;
      state.payload = action.payload;
    });
  },
});

const { reducer } = notifications;

export default reducer;
