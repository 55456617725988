import React, { Component } from 'react';
import { View, Text, StyleSheet, Image, Platform, Alert } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import style from '~/common/style';
import Images from '@assets/Images';
import * as config from '../config/config';
import color from '../common/color';
import RawBottomSheet from './RawBottomSheet/RawBottomSheet';
import _ from 'lodash';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  textSelected: {
    color: '#000',
    marginVertical: 8,
    marginLeft: 16,
    fontSize: 16,
  },
  textTitle: {
    color: '#979797',
    fontSize: 12,
    marginLeft: 16,
    marginTop: 0,
  },
  container: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: '#f5f5f5',
  },
  iconMenuDown: {
    width: 24,
    height: 24,
    margin: 8,
    tintColor: 'gray',
  },
  innerContainer: {
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  optionContainer: {
    padding: 15,
    borderBottomColor: color.grayContainerDark,
    borderBottomWidth: 1,
  },
});


class SelectPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      icon: null,
      showPickerIOS: false,
      selected: props.selected || {},
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selected && nextProps.selected !== '') {
      this.setState({ selected: nextProps.selected });
    }

    if (nextProps.showOptions) this.onPress();
  }

  onError() {
    Alert.alert('Erro', 'Popup Error');
  }

  onPressOption = (entry) => {
    const selected = this.props.items.find(i => i.label === entry);
    if (selected) {
      this.setState({ selected }, () => {
        this.onChange(selected);
      });

      if (this.props.onPress) {
        this.props.onPress(entry);
      }
    }
    this.RbSheetRef.close();
  };

  onPress = () => {
    this.RbSheetRef.open();
    if (Platform.OS === 'ios') {
      this.setState({ showPickerIOS: !this.state.showPickerIOS });
    }
  };

  onRef = icon => {
    if (!this.state.icon) {
      this.setState({ icon });
    }
  };

  onChange = (selected) => {
    this.setState({ selected }, () => this.props.onChangeValue(selected));
  };

  onPressConditionsPriceTerm = () => {
    const { onPressConditionsPriceTerm } = this.props;
    if (onPressConditionsPriceTerm) {
      this.RbSheetRef.close();
      onPressConditionsPriceTerm();
    }
  }

  renderOption(settings) {
    const { item, getLabel } = settings;
    return (
      <View style={styles.optionContainer}>
        <View style={styles.innerContainer}>
          <Text style={{
            fontWeight: 'normal', alignSelf: 'flex-start', fontSize: 16, color: '#000',
          }}
          >{getLabel(item)}
          </Text>
        </View>
      </View>
    );
  }

  renderComponentItem = (item) => (
    <Text style={this.state.selected.label === item ?
      { fontSize: 16, fontWeight: 'bold' } :
      { fontSize: 16 }}
    >
      {item}
    </Text>
  );

  render() {
    const styleAllRadius = {
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      borderBottomLeftRadius: 5,
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderColor: '#ddd',
    };

    const active = {
      borderWidth: 2,
      borderColor: config.Colors.primaryColor,
    };

    const titleActive = {
      color: config.Colors.primaryColor,
    };

    const { showPickerIOS } = this.state;
    const {
      outline,
      items,
      required,
      title,
      containerStyle,
      color,
      titleColor,
      height,
      onClose,
      showPriceTermConditionsBtn,
      showPriceConditions,
    } = this.props;
    const containerStyles = [styles.container, this.props.style];
    const textStyle = [styles.textTitle];
    const selected = this.state.selected;

    if (this.props.outline) {
      containerStyles.push(styleAllRadius);
    }

    if (showPickerIOS) {
      containerStyles.push(active);
      textStyle.push(titleActive);
    }

    containerStyles.push(containerStyle);

    return (
      <View style={containerStyles}>
        <Touchable
          onPress={this.onPress}
        >
          <View>
            <Text style={[textStyle, titleColor ? { color: titleColor } : {}]}>
              {required ? `${title || ''} *` : title || ''}
            </Text>
            <View style={[style.spaceBetween, showPickerIOS ? { borderBottomWidth: 1, borderBottomColor: '#dedede' } : {}]}>
              <Text
                ref={this.onRef}
                style={[styles.textSelected, color ? { color } : {}]}
              >
                {selected.label}
              </Text>
              <Image
                source={Images.iconMenuDown}
                style={styles.iconMenuDown}
              />
            </View>
            {!outline && <View style={{ flex: 1, height: 1, backgroundColor: '#ddd' }} />}
            { showPickerIOS && <View style={{ flex: 1, height: 1, backgroundColor: '#ddd' }} />}
            <RawBottomSheet
              height={height}
              setRef={ref => { this.RbSheetRef = ref; }}
              itens={_.map(items, 'label')}
              showSearch
              onSelectOption={this.onPressOption}
              renderComponentItem={this.renderComponentItem}
              onClose={onClose}
              showPriceTermConditionsBtn={showPriceTermConditionsBtn}
              onPressConditionsPriceTerm={this.onPressConditionsPriceTerm}
              showPriceConditions={showPriceConditions}
            />
          </View>
        </Touchable>
      </View>
    );
  }
}

SelectPicker.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  onPress: PropTypes.func.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  title: PropTypes.string,
  outline: PropTypes.bool,
  required: PropTypes.bool,
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  color: PropTypes.string,
  titleColor: PropTypes.string,
  showOptions: PropTypes.bool,
  onClose: PropTypes.func,
  showPriceTermConditionsBtn: PropTypes.bool,
  onPressConditionsPriceTerm: PropTypes.func,
  showPriceConditions: PropTypes.bool,
  selected: PropTypes.object,
};


export default SelectPicker;