import AsyncStorage from '@react-native-async-storage/async-storage';

class ConditionSaleCache {
  async set(conditionSaleItemSelected) {
    const conditionSaleCache = await this.get();
    const initialConditionSale = {
      ...conditionSaleCache,
      ...conditionSaleItemSelected,
    };
    await AsyncStorage.setItem(
      '@Session:ConditionSaleCache',
      JSON.stringify(initialConditionSale),
    );
  }

  async get() {
    const data = await AsyncStorage.getItem('@Session:ConditionSaleCache');
    return JSON.parse(data);
  }

  async reset(conditionSaleItensSelected) {
    await AsyncStorage.removeItem('@Session:ConditionSaleCache');
    await this.set(conditionSaleItensSelected);
  }
}

export default new ConditionSaleCache();
