/* @flow */

import React, { Component } from 'react';
import { Text, TextInput, View } from 'react-native';
import Masked from 'vanilla-masker';

import { connect } from 'react-redux';

import { style } from '~/common';
import styles from './styles';
import Discount from './Discount';
import { fonts } from '../../../utils/Fonts';
import translate from '../../../locales';
import {
    getCurrencyFormatter,
    getPrecisionFromType,
    parseStrToFloat,
} from '~/utils/Currency';

import PropTypes from 'prop-types';

class PriceInput extends Component {
    componentWillMount() {
        this.value = this.getPriceFormatted();
        this.valueDiscount = this.getDiscountFormatted();
    }

    UNSAFE_componentWillReceiveProps({ price, valueDiscount }) {
        if (this.props.price !== price) {
            this.value = this.getPriceFormatted(price);
        }
        if (this.props.valueDiscount !== valueDiscount) {
            this.valueDiscount = this.getDiscountFormatted(valueDiscount);
        }
    }

    getDiscountFormatted(newDiscount) {
        const precision = getPrecisionFromType('discount');
        const { valueDiscount } = this.props;
        let priceStr = 0;
        if (newDiscount === 0) {
            priceStr = newDiscount;
        } else {
            priceStr = newDiscount || valueDiscount;
        }
        return parseStrToFloat(priceStr, precision);
    }

    getPriceFormatted(newPrice) {
        const precision = getPrecisionFromType('price');
        const { price } = this.props;
        let priceStr = 0;
        if (newPrice === 0) {
            priceStr = newPrice;
        } else {
            priceStr = newPrice || price;
        }
        return parseStrToFloat(priceStr, precision);
    }

    onChangeText = entry => {
        const moneyFormatter = getCurrencyFormatter('price', {
            noSymbolCurrency: true,
        });
        let value = entry;
        value = Masked.toMoney(Masked.toNumber(value), moneyFormatter);
        this.props.setPrices(value, this.props.priceIni);
    };

    onEndEditing = () => {
        const { price, priceIni, product } = this.props;
        const precision = getPrecisionFromType('price');

        const floatPrice = parseFloat(parseStrToFloat(price, precision));

        if (floatPrice !== priceIni && floatPrice > 0) {
            this.value = price;
            this.props.updatePrice(product, floatPrice);
            this.props.setPrices(price, price);
        }
    };

    render() {
        const {
            product,
            discountValues,
            discountType,
            valueDiscountIni,
            acrescimo,
            desconto,
            setDiscounts,
            updatingItem,
            configLib,
            setEditing,
        } = this.props;

        return (
            <View>
                <View style={style.spaceBetween}>
                    <View style={[style.rowDirection, { flex: 1 }]}>
                        <Text style={{ marginRight: 10, color: '#000' }}>{`${translate(
                            'currencySymbol',
                        )}`}</Text>
                        <TextInput
                            keyboardType="numeric"
                            editable={!updatingItem}
                            style={[
                                styles.input,
                                styles.inputPrice,
                                {
                                    fontFamily: fonts.Quicksand,
                                    backgroundColor: '#fff',
                                    padding: 15,
                                    fontSize: 17,
                                    borderWidth: 1,
                                    borderRadius: 5,
                                },
                            ]}
                            value={`${this.value}`}
                            onChangeText={val => this.onChangeText(val)}
                            onEndEditing={this.onEndEditing}
                            onFocus={() => {
                                if (setEditing) {
                                    setEditing(true, 'price');
                                }
                            }}
                            onBlur={() => this.onEndEditing}
                            onKeyPress={e => {
                              if (e.key === 'Enter') {
                                this.onEndEditing();
                              }
                            }}
                        />
                    </View>
                </View>
                <Discount
                    configLib={configLib}
                    discountValues={discountValues}
                    discountType={discountType}
                    valueDiscount={this.valueDiscount}
                    valueDiscountIni={valueDiscountIni}
                    product={product}
                    desconto={desconto}
                    acrescimo={acrescimo}
                    setDiscounts={setDiscounts}
                    updatingItem={updatingItem}
                    setEditing={setEditing}
                />
            </View>
        );
    }
}

PriceInput.propTypes = {
    price: PropTypes.number,
    priceIni: PropTypes.number,
    product: PropTypes.object,
    valueDiscount: PropTypes.number,
    discountType: PropTypes.string,
    valueDiscountIni: PropTypes.number,
    discountValues: PropTypes.array,
    acrescimo: PropTypes.func,
    desconto: PropTypes.func,
    setDiscounts: PropTypes.func,
    updatePrice: PropTypes.func,
    setPrices: PropTypes.func,
    setEditing: PropTypes.func,
    updatingItem: PropTypes.bool,
    configLib: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        configLib: state.configLibFetch,
    };
}

export default connect(mapStateToProps)(PriceInput);
