import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Função para obter a URL base com base no código da empresa
export const getBaseUrl = async () => {
  console.log('getBaseUrl');
  const companyCode = await AsyncStorage.getItem('company_code');
  console.log('companyCode', companyCode);

  // Retornar a URL base com base no código da empresa
  if (companyCode === 'csul') {
    return 'https://csul.anywhere.polibr.com.br';
  }
  return 'https://anywhere-server.aws.polibr.com.br';
};

// Função para criar a instância do axios com a baseURL resolvida
const createApiInstance = async () => {
  const baseURL = await getBaseUrl(); // Resolve a Promise para obter a URL
  console.log('Resolved baseURL:', baseURL);

  // Criar e retornar a instância do axios
  return axios.create({
    baseURL,
  });
};

export const api = async () => {
  const instance = await createApiInstance(); // Criar a instância do axios
  console.log('instance:', instance);

  // Retornar a instância do axios
  return instance;
}

// Exportar a função para uso em outros arquivos
export default api;