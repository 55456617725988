const atalhosConfig = 
{
  "screens": {
    "Home": {
      "atalhos": {
        "Hoje": { "keys": "Shift+H", "label": "Hoje" },
        "Clientes": { "keys": "Shift+C", "label": "Clientes" },
        // "Catalogo": { "keys": "Shift+A", "label": "Catálogo" },
        // "Despesas": { "keys": "Shift+D", "label": "Despesas" },
        "Pedidos": { "keys": "Shift+P", "label": "Pedidos" },
        // "Relatorios": { "keys": "Shift+R", "label": "Relatorios" },
        "Restaurar": { "keys": "Shift+I", "label": "Restaurar Pedido" },
        // "Titulos": { "keys": "Shift+T", "label": "Títulos" },
        // "Oportunidade de Venda": { "keys": "Shift+O", "label": "Oportunidade de Venda" },
        // "Gestao": { "keys": "Shift+G", "label": "Gestão" },
        // "Despesas": { "keys": "Shift+D", "label": "Despesas" },
        "Excluir": { "keys": "Shift+E", "label": "Excluir Pedido" },
      }
    },
    "Clients": {
      "atalhos": {
        "Pesquisar": { "keys": "Shift+F", "label": "Pesquisar" },
        "Selecione": { "keys": "Número Teclado", "label": "Selecionar um cliente. Digite o número que representa a posição do cliente na lista." },
      }
    },
    "ClientDetail": {
      "atalhos": {
        "Carrinho": { "keys": "Shift+C", "label": "Abrir Carrinho" },
        "Voltar": { "keys": "Shift+V", "label": "Voltar" },
      }
    },
    "Resume": {
      "atalhos": {
        "Pesquisar": { "keys": "Shift+L", "label": "Pesquisar" },
        "Produtos": { "keys": "Shift+P", "label": "Ir para Produtos" },
        "Finalizar": { "keys": "Shift+F", "label": "Finalizar Carrinho" },
        "Obs": { "keys": "Shift+O", "label": "Tela de Observações" },
      }
    },
    "Cart": {
      "atalhos": {
        "Resumo": { "keys": "Shift+R", "label": "Resumo" },
        "Voltar": { "keys": "Shift+V", "label": "Voltar" },
        "Produtos": { "keys": "Shift+A", "label": "Produtos" },
      }
    },
    "ProductDetail": {
      "atalhos": {
        "Voltar": { "keys": "Shift+V", "label": "Voltar" },
      }
    },
  }
}

export default atalhosConfig;

  