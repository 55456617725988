import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Reports from './Reports';
import ReportsModule from '../../modules/ReportsModule';
import PrintModule from '../../modules/PrintModule';
import executeActionOrOpenScreenMessage from '../../utils/messages';
import exportReport from './../../utils/report';
import translate from '../../locales';
import { getListWithPagination } from '../../utils/FastSellerUtils';
import onPressHelper from '~/utils/onPressHelper';

import PropTypes from 'prop-types';
import { Image, TouchableOpacity } from 'react-native';
import Images from '@assets/Images';

import { SCREEN_REPORT_FORM, SCREEN_WEBVIEW } from '../screens';

class ReportsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = { reports: [], loading: false };
  }

  componentDidMount() {
    this.setLeftButton();
    const { configLib } = this.props;
    const memorySize = _.get(configLib, 'payload.tam_memoria_paginacao');

    getListWithPagination(ReportsModule.getListReports, memorySize)
      .then(reports => this.setState({ reports }))
      .catch(err => console.tron.log('ERR GET REPORTS', err));
  }

  handleBackPress = () => {
   this.props.navigation.goBack();
  };

  openReport(report_url, report) {
    this.props.navigation.navigate({
      name: SCREEN_WEBVIEW.name,
      params: {
        name: report.descricao || SCREEN_WEBVIEW.title,
        key: 'report',
        final_url: true,
        url: report_url
      },
    });
  }

  exportReport = async (pkey, params, output, report) => {
    try {
      const configProntaEntrega = _.get(
        this.props.configLib.payload,
        'configuracoes.fast_delivery',
      );
      if (configProntaEntrega) {
        await PrintModule.verifyPrintManagerPackageInstalled();
      }

      await exportReport(pkey, params, output, (responseReport) => {
        if (responseReport.status === 200) {
          this.openReport(responseReport.data.report_url, report)
        }
      });
    } catch (err) {
      let error = err;
      if (error.code === 'PRINT_MANAGER_NOT_INSTALLED_ERROR') {
        error = [
          {
            mensagem: `${translate('printManagerNotInstaller')}`,
            tipo: 'erro',
          },
        ];
      }

      executeActionOrOpenScreenMessage(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  openFormReport(report, output) {
    this.props.navigation.navigate({
      name: SCREEN_REPORT_FORM.name,
      params: {
        report,
        output,
        exportReport: this.exportReport,
        title: report.descricao || SCREEN_REPORT_FORM.title,
        name: report.descricao || SCREEN_REPORT_FORM.title,
      },
    });
    this.setState({ loading: false });
  }

  generateReport = (report, output) => {
    this.setState({ loading: true });
    if (report.params && report.params.length > 0) {
      this.openFormReport(report, output);
    } else {
      this.exportReport(report.pkey, '', output, report);
    }
  };

  onPressGenerateReportHelper = onPressHelper.debounce(this.generateReport);

  setLeftButton = () => {
    this.props.navigation.setOptions({
      headerLeft: () => (
        <TouchableOpacity onPress={() => {
          this.handleBackPress();
        }}
        >
          <Image
            source={Images.iconBack}
            style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
          />
        </TouchableOpacity>
      ),
    });
  };

  render() {
    return (
      <Reports
        reports={this.state.reports}
        onGenereateReport={this.onPressGenerateReportHelper}
        loading={this.state.loading}
      />
    );
  }
}

ReportsContainer.propTypes = {
    configLib: PropTypes.object,
    componentId: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    configLib: state.configLibFetch,
  };
}

export default connect(mapStateToProps)(ReportsContainer);
