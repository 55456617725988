import { Platform } from 'react-native';
import chroma from 'chroma-js';

const PRIMARY_COLOR = '#074885';
const ACCENT_COLOR = '#1CBC26';
const SUCCESS_COLOR = '#89D500';
const WARNING_COLOR = '#fd8a00';
const DANGER_COLOR = '#DA0B17';

export default {
  primaryColor: PRIMARY_COLOR,
  primaryColorLight: chroma(PRIMARY_COLOR).brighten(2).hex(),
  primaryColorLightest: chroma(PRIMARY_COLOR).brighten(2.6).hex(),
  primaryColorDark: chroma(PRIMARY_COLOR).darken(2).hex(),
  primaryColorDarkest: chroma(PRIMARY_COLOR).darken(2.6).hex(),
  accentColor: ACCENT_COLOR,
  accentColorLight: chroma(ACCENT_COLOR).brighten(2).hex(),
  accentColorLightest: chroma(ACCENT_COLOR).brighten(2.6).hex(),
  accentColorDark: chroma(ACCENT_COLOR).darken(2).hex(),
  accentColorDarkest: chroma(ACCENT_COLOR).darken(2.6).hex(),
  successColor: SUCCESS_COLOR,
  successColorLight: chroma(SUCCESS_COLOR).brighten(2).hex(),
  successColorLightest: chroma(SUCCESS_COLOR).brighten(2.6).hex(),
  successColorDark: chroma(SUCCESS_COLOR).darken(2).hex(),
  successColorDarkest: chroma(SUCCESS_COLOR).darken(2.6).hex(),
  warningColor: WARNING_COLOR,
  warningColorLight: chroma(WARNING_COLOR).brighten(2).hex(),
  warningColorLightest: chroma(WARNING_COLOR).brighten(2.6).hex(),
  warningColorDark: chroma(WARNING_COLOR).darken(2).hex(),
  warningColorDarkest: chroma(WARNING_COLOR).darken(2.6).hex(),
  dangerColor: DANGER_COLOR,
  dangerColorLight: chroma(DANGER_COLOR).brighten(2).hex(),
  dangerColorLightest: chroma(DANGER_COLOR).brighten(2.6).hex(),
  dangerColorDark: chroma(DANGER_COLOR).darken(2).hex(),
  dangerColorDarkest: chroma(DANGER_COLOR).darken(2.6).hex(),
  black: '#000',
  grayRed: '#ae2029',
  grayRedDark: '#8d343a',
  grayTextInput: '#7d7d7d',
  grayContainer: Platform.OS === 'ios' ? '#EFEFF4' : '#F0F0F0',
  grayContainerDark: '#dcdcdc',
  grayDark: '#7a7a7a',
  grayDarkest: '#414141',
  grayMid: '#969696',
  gray: '#D8D8D8',
  grayAlmostWhite: '#f8f8f8',
  navBarButtonColor: '#fff',
  navBarSubtitleColor: '#fff',
  tabBarBackgroundColor: '#fff',
  tabBarSelectedTextColor: PRIMARY_COLOR,
  separatorList: '#d8e1e1',
  blueBadge: '#00a4ff',
  blueIOS: '#157efb',
  redBadge: '#df0800',
  redLight: '#FF6D6D',
  redLightest: '#ffacb1',
  yellowVibrant: '#ffb318',
  yellowBadge: '#df9900',
  facebookBlue: '#4267b2',
  googleRed: '#db4437',
  graySearchContainer: '#dcdce1',
  stripGreen: '#7dc736',
  stripGreenLightest: '#e7ffd0',
  whatsappGreen: '#075E54',
  whatsappGreenLight: '#25D366',
  stripOrange: '#c76e00',
  infoAlert: '#FF7E00',
  stripGreenDark: '#5f9f2d',
  stripBlue: '#3ba1c1',
  textEmpty: '#8cc5da',
  sectionIOS: '#6D6D72',
};
