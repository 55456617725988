import React, { Component } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  icon: {
    width: 14,
    height: 14,
    marginRight: 8,
  },
  text: {
    flex: 1,
  },
});

class IconText extends Component {
  render() {
    const { containerStyle, imageSrc, imageStyle, text, textStyle } = this.props;

    return (
      <View style={[{ flexDirection: 'row', flex: 1, alignItems: 'center' }, containerStyle]}>
        <Image resizeMode="contain" source={imageSrc} style={[styles.icon, imageStyle]} />
        <Text style={[styles.text, textStyle]} numberOfLines={2}>{text} </Text>
      </View>);
  }
}

IconText.propTypes = {
  imageSrc: PropTypes.any,
  imageStyle: PropTypes.any,
  containerStyle: PropTypes.any,
  text: PropTypes.string,
  textStyle: PropTypes.any,
};

export default IconText;
