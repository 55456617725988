import React, { useState } from 'react';
import { Image, Modal, View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import Images from '@assets/Images';
import { memo } from 'react';
import { color } from '~/common';
import { useSelector } from 'react-redux';

import atalhosConfig from '../../../atalhos';

export let homeShortcutss = null;

const KeyBindingsMOdal = memo(({ visible, onClose, screen, keyBindingsRef }) => {
  const stateConfig = useSelector(state => state.configLibFetch?.payload?.configuracoes);
  const atalhos = stateConfig?.shortcuts?.screens[screen]?.atalhos || atalhosConfig?.screens[screen]?.atalhos || {};
  const homeShortcuts = stateConfig?.shortcuts?.screens['Home']?.atalhos || atalhosConfig.screens['Home']?.atalhos;
  homeShortcutss = homeShortcuts;
  
  return (
    <Modal
      ref={keyBindingsRef}
      visible={visible}
      transparent={true}
      animationType="fade"
      onRequestClose={onClose}
    >
      <View style={styles.overlay}>
        <View style={styles.modalContent}>
          <TouchableOpacity style={styles.closeButton} onPress={onClose}>
            <Image source={Images.iconClose} style={{ width: 20, height: 20 }} />
          </TouchableOpacity>
          <Text style={styles.title}>Lista de Atalhos</Text>
          <View style={styles.shortcutList}>
          {Object.entries(atalhos).length > 0 ? (
              Object.entries(atalhos).map(([key, { keys, label }]) => (
                <View key={key} style={styles.shortcutItem}>
                  <Text style={styles.action}>
                    {label.charAt(0).toUpperCase() + label.slice(1)}
                  </Text>
                 <View style={styles.containerKeys}>
                    <Text style={styles.keys}>{keys}</Text>
                  </View>
                </View>
              ))
            ) : (
              <View style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 20,
              }}>
                <Image source={Images.emptyVector} style={{ width: 300, height: 300 }} />
              </View>
            )}
          </View>
        </View>
      </View>
    </Modal>
  );
});

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    width: '25%',
    // height: '50%',
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  title: {
    fontFamily: 'Quicksand-Regular',
    fontSize: 24,
    fontWeight: color.neutral900,
    marginBottom: 10,
  },
  shortcutList: {
    width: '100%',
    paddingVertical: 10,
  },
  shortcutItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: 30,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15, // torna o botão redondo
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // cor de fundo opcional
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  action: {
    fontFamily: 'Quicksand-Regular',
    fontSize: 16,
    fontWeight: 'bold',
    fontWeight: color.neutral900,
    flex: 1,
  },
  containerKeys: {
    flexDirection: 'row',
    backgroundColor: 'transparent', // Fundo transparente para aplicar o gradiente
    padding: 5,
    borderRadius: 5,
    marginLeft: 10,
    // Efeito de metalizado usando gradiente de fundo e sombra
    backgroundImage: 'linear-gradient(135deg, #074885, #4a8cba, #6ba2d9)', // Gradiente com a cor especificada
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', // Sombra para efeito de elevação
  },
  keys: {
    fontFamily: 'Quicksand-Regular',
    fontSize: 16,
    color: '#FFF',
    fontWeight: color.neutral700,
  },
});

export default KeyBindingsMOdal;
