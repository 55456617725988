import { fonts } from '~/utils/Fonts';
import * as config from '~/config/config';

const { StyleSheet } = require('react-native');
const { color } = require('~/common');

const styles = StyleSheet.create({
    itemsProducts: {
        backgroundColor: '#FFFFFF',
        padding: 16,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    subheader: {
        fontFamily: fonts.QuicksandBold,
        fontSize: 16,
        backgroundColor: '#fff',
        color: config.Colors.primaryColor,
    },
    sectionContainer: {
        paddingHorizontal: 16,
        borderBottomWidth: 1,
        borderBottomColor: config.Colors.grayContainerDark,
    },
    topContainer: {
        backgroundColor: '#fff',
    },
    name: {
        color: '#000',
        fontFamily: fonts.QuicksandBold,
        fontSize: 16,
    },
    iconCartAction: {
        width: 24,
        height: 24,
        margin: 4,
        tintColor: color.primaryColor,
    },
    iconchangeClient: {
        width: 30,
        height: 30,
        padding: 12,
        marginRight: 10,
        tintColor: color.primaryColor,
    },
    promoTopContainer: {
        backgroundColor: '#FFFFFF',
        borderBottomWidth: 1,
        borderBottomColor: color.grayContainerDark,
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 16,
    },
    iconPromoContainer: {
        borderRadius: 2,
        backgroundColor: color.successColor,
        paddingVertical: 3,
        paddingHorizontal: 1,
        marginRight: 16,
    },
    iconPromo: {
        tintColor: '#FFF',
        width: 15,
        height: 15,
    },
    textPromo: {
        color: color.successColor,
    },
});

const stylesLabelProntaEntrega = StyleSheet.create({
    container: {
        top: 8,
        backgroundColor: color.accentColor,
        borderRadius: 10,
        height: 30,
        flexDirection: 'row',
        alignItems: 'center',
    },
    iconTruck: {
        left: 15,
        tintColor: '#FFF',
    },
    text: {
        height: 17,
        left: 30,
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 14,
        lineHeight: 16,
        color: '#FFFFFF',
    },
});

export { styles, stylesLabelProntaEntrega };