/* @flow */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import JustifyModule from '~/modules/JustifyModule';

export const JUSTIFICATION_SAVE_REQUEST = 'JUSTIFICATION_SAVE_REQUEST';
export const JUSTIFICATION_SAVE_SUCCESS = 'JUSTIFICATION_SAVE_SUCCESS';
export const JUSTIFICATION_SAVE_FAILURE = 'JUSTIFICATION_SAVE_FAILURE';

const saveJustifyFetch = payload =>
  new Promise((resolve, reject) => {
    const { clientKey, type, value, text, location } = payload;

    JustifyModule.saveJustify(
      clientKey,
      type,
      value,
      text,
      JSON.stringify(location || {}),
      resolve,
      reject,
    );
  });

export const saveJustify = createAsyncThunk(
  'justify/saveJustify',
  async (args, { getState }) => {
    let data = '';
    try {
      data = await saveJustifyFetch(args);
    } catch (error) {
      console.tron.log(
        'PE_APP | file: slice.js:32 | JUSTIFICATION_SAVE_FAILURE =>',
        error.message,
      );
      throw error;
    }
    return data;
  },
);

const initialState = {
  type: '',
  loading: false,
  erro: false,
  payload: [],
};

const saveJustifySlice = createSlice({
  name: 'saveJustifySlice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(saveJustify.pending, state => {
      state.type = JUSTIFICATION_SAVE_REQUEST;
      state.loading = true;
    });
    builder.addCase(saveJustify.fulfilled, (state, action) => {
      state.type = JUSTIFICATION_SAVE_SUCCESS;
      state.loading = false;
      state.erro = false;
      state.payload = action.payload;
    });
    builder.addCase(saveJustify.rejected, (state, action) => {
      state.type = JUSTIFICATION_SAVE_FAILURE;
      state.loading = false;
      state.erro = true;
      state.payload = action?.payload || [];
    });
  },
});

const { reducer } = saveJustifySlice;

export default reducer;
