import OrdersModule from '../modules/OrdersModule';
import translate from '../locales';

const verifyOrderPending = async () => {
  let hasPendingOrder = false;
  let clientPkey = 0;
  const message = `${translate('alreadyExistsOpenedCart')}`;
  let orderPending = {};

  try {
    const orderPedingStringfy = await OrdersModule.hasPendingOrder();
    orderPending = JSON.parse(orderPedingStringfy);
    clientPkey = orderPending.condvenda.codcliente;
    hasPendingOrder =
      orderPending.codigo !== '0' && orderPending.condvenda?.codcliente !== '0';
  } catch (error) {
    console.log('Erro ao listar pedido pendente');
  }

  return {
    hasPendingOrder,
    clientPkey,
    message,
    orderPending,
  };
};

export default verifyOrderPending;
