import AnswersRepository from '../database/repository/AnswersRepository';
import BaseController from './BaseController';
import PhotoAnswersRepository from '../database/repository/PhotoAnswersRepository';

export default class AnswerController extends BaseController {
  constructor() {
    super();
    this.repository = new AnswersRepository();
    this.photoRepository = new PhotoAnswersRepository();
  }

  updateUserTaskTempIdToReservedId(tempId, reservedId) {
    return this.repository.updateUserTaskTempIdToReservedId(tempId, reservedId);
  }

  getLastSequenceAnswered(userTaskList) {
    return this.repository.getLastSequenceAnswered(userTaskList);
  }

  async getByUserTaskListAndTask(userTaskListId, taskId) {
    return this.repository.getByUserTaskListAndTask(userTaskListId, taskId);
  }

  // TODO: utilizar ao salvar resposta
  upsert(data) {
    return new Promise((resolve, reject) => {
      const userTaskListId = data.user_task_list;
      const taskId = data.task;

      this.getByUserTaskListAndTask(userTaskListId, taskId)
        .then(answer => {
          if (answer === null) {
            this.insert(data)
              .then(() => {
                resolve();
              })
              .catch(error => {
                console.log(
                  'ERROR getByUserTaskListAndTask insert',
                  error,
                );
                reject(error);
              });
          } else {
            const newStatus =
              answer.sync_status === 'pending_to_reserve'
                ? answer.sync_status
                : 'pending_to_sync';
            const updatedFields = {
              updated_at: new Date().toISOString(),
              payload: data.payload,
              extra_info: data.extra_info,
              status: data.status,
              latitude: data.latitude,
              longitude: data.longitude,
              deleted: data.deleted,
              deleted_at: data.deleted_at,
              sync_status: newStatus,
            };
            this.update(Object.assign(answer, updatedFields))
              .then(() => {
                resolve();
              })
              .catch(error => {
                console.log(
                  'ERROR getByUserTaskListAndTask insert',
                  error,
                );
                reject(error);
              });
          }
        })
        .catch(error => {
          console.log('ERROR getByUserTaskListAndTask', error);
          reject(error);
        });
    });
  }

  insertPhoto(photoAnswer) {
    return this.photoRepository.insertPhotos(photoAnswer);
  }

  getPhotoToSync() {
    return this.photoRepository.getPhotoToSync();
  }

  setPhotoSynchronizedSuccess(id) {
    return this.photoRepository.setPhotoSynchronizedSuccess(id);
  }

  setPhotoStatusSync(id, status, errorMsg) {
    return this.photoRepository.setPhotoStatusSync(id, status, errorMsg);
  }

  existsByTaskAndUserTaskList(taskId) {
    return this.repository.existsByTaskAndUserTaskList(taskId);
  }

  listPhotos() {
    return this.photoRepository.getAll();
  }

  getPhotosSynchronized() {
    return this.photoRepository.getPhotosSynchronized();
  }

  getByUserTaskList(userTaskListId) {
    return this.repository.getByUserTaskList(userTaskListId);
  }
}
