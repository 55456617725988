import { createSlice } from '@reduxjs/toolkit';

export const PRODUCTS_CLEAN_PRICES = 'PRODUCTS_CLEAN_PRICES';

const initialState = {
  type: '',
  payload: false,
};

const productsCleanPricesSlice = createSlice({
  name: 'productsCleanPrices',
  initialState,
  reducers: {
    cleanProductsPrices: (state) => {
      state.type = PRODUCTS_CLEAN_PRICES;
      state.payload = !state.payload
    },
  },
});

export const { cleanProductsPrices } = productsCleanPricesSlice.actions;

const { reducer } = productsCleanPricesSlice;

export default reducer;
