/* @flow */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import uuidv4 from 'uuid/v4';
import ActivitiesModule from '~/modules/ActivitiesModule';
import getQueryResult from '../../services/resources/libFastSeller/genericQuery';
import _ from 'lodash';

export const SAVE_ANSWER_FETCH_REQUEST = 'SAVE_ANSWER_FETCH_REQUEST';
export const SAVE_ANSWER_FETCH_SUCCESS = 'SAVE_ANSWER_FETCH_SUCCESS';
export const SAVE_ANSWER_FETCH_FAILURE = 'SAVE_ANSWER_FETCH_FAILURE';

const saveAnswerActivityLib = answer =>
  new Promise(async (resolve, reject) => {
    const uuidAnswer = uuidv4();
    const {
      dateOpen,
      end_date,
      payload,
      idContext,
      idProcess,
      idTask,
      keyValueContext,
      dateSelected,
      codmaq,
    } = answer;
    let newPayload = payload;
    let checkInId;

    if (!payload.checkin_id) {
      const params = {
        key_context: keyValueContext,
        id_process: idProcess,
      };

      try {
        const result = await getQueryResult(
          'ATIV;CURR;CKID',
          JSON.stringify(params),
        );
        const parsedResult = JSON.parse(result);
        const data = _.get(parsedResult, ['0', 'checkin_id']);
        checkInId = parseInt(data, 10);
      } catch (err) {
        checkInId = 0;
      }
      newPayload = { ...payload, checkin_id: checkInId };
    }

    const answerLib = {
      id: uuidAnswer,
      id_contexto: idContext,
      id_processo: idProcess,
      id_atividade: idTask,
      chave_contexto: keyValueContext,
      start_date: moment(dateOpen).unix(),
      origin_date: moment(dateSelected).unix(),
      end_date: moment(end_date).unix(),
      payload: newPayload,
      created_by: codmaq,
    };

    const answerLibStringfy = JSON.stringify(answerLib);
    ActivitiesModule.saveAnswer(answerLibStringfy, resolve, reject);
  });

export const saveAnswer = createAsyncThunk(
  'activities/saveAnswer',
  async (args, { getState }) => {
    let data = '';
    try {
      data = await saveAnswerActivityLib(args);
    } catch (error) {
      throw error;
    }
    return data;
  },
);

const initialState = {
  type: '',
  loading: false,
  erro: false,
  payload: [],
};

const saveAnswerSlice = createSlice({
  name: 'saveAnswer',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(saveAnswer.pending, state => {
      state.type = SAVE_ANSWER_FETCH_REQUEST;
      state.loading = true;
    });
    builder.addCase(saveAnswer.fulfilled, (state, action) => {
      const data = JSON.parse(action.payload);
      state.type = SAVE_ANSWER_FETCH_SUCCESS;
      state.loading = false;
      state.erro = false;
      state.payload = data;
    });
    builder.addCase(saveAnswer.rejected, (state, action) => {
      state.type = SAVE_ANSWER_FETCH_FAILURE;
      state.loading = false;
      state.erro = true;
      state.payload = action.payload;
    });
  },
});

const { reducer } = saveAnswerSlice;

export default reducer;
