import React from 'react';

import AttachmentImage from './AttachmentImage';
import AttachmentPDF from './AttachmentPDF';

import PropTypes from 'prop-types';

function Attachment({ attach }) {
  const { type } = attach;

  switch (type) {
    case 'image':
      return <AttachmentImage attach={attach} />;
    case 'pdf':
      return <AttachmentPDF attach={attach} />;
    default:
      return null;
  }
}

Attachment.propTypes = {
  attach: PropTypes.object.isRequired,
};

export default Attachment;
