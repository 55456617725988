/* eslint-disable no-mixed-operators,no-nested-ternary */
import React, { Component } from 'react';
import { Image, Platform, StyleSheet, Text, View } from 'react-native';
import SwipeOut from 'react-native-swipeout';
import Touchable from 'react-native-platform-touchable';
import Images from '@assets/Images';
import PopupMenu from '../../components/PopupMenu';
import { style, color } from '~/common';
import {
  ORDER_FETCH_REQUEST,
  ORDER_FETCH_FAILURE,
  ORDER_FETCH_SUCCESS,
  updateProductHTML,
} from '~/store/ordersFetch/orderFetchSlice';
import store from '~/store';

import { UPDATE_ITEM_REQUEST } from '~/store/ordersFetch/updateItemSlice';
import { APPLY_OPERATION_BY_GROUP_FETCH } from '~/store/ordersFetch/applyOperationByGroupSlice';
import { REMOVE_MULTIPLE_ITEMS_FETCH } from '~/store/ordersFetch/removeMultipleItemsSlice';
import _ from 'lodash';
import * as config from '../../config/config';
import Option from '../ProductScreens/MatrixProduct/components/Option';
import { MAGNITUDES_ONLY_PRODUCT } from '../../constants/rulesProductMagnitudes';
import ProductImage from '../../../components_base/src/components/Image';
import { fonts } from '../../utils/Fonts';

import { getSourceImageProduct } from '../../utils/ProductUtil';
import { adjustHtml } from '../../utils/GlobalUtils';
import { executeActionsMessage } from '../../utils/messages';
import translate from '../../locales';
import { getComponent } from '../../components';
import Quantity from '../ProductScreens/components/Quantity';
import IconText from '../../components/IconText';
import HTML from 'react-native-render-html';
import ProductsModule from '~/modules/ProductsModule';
import MoreInfo from '~/../components_base/src/components/MoreInfo';
import { Price, CalculatedValue } from '~/../components_base/src/components';
import { getProductFetch } from '~/store/productsFetch/utilsLib';

import PropTypes from 'prop-types';
import getImageProd from '~/utils/GetAllImagesProds';

const MORE_INFOS = `${translate('moreInfo')}`;
const OBSERVATIONS = `${translate('observations')}`;
const CONDITIONS_SALES_CHILD = `${translate('conditionsSalesChild')}`;
const GENERAL_INFO = `${translate('generalInfo')}`;
const REMOVE_ITEM = `${translate('removeItem')}`;
const CONDITIONS_PRICE_TERM = `${translate('conditionsPriceTerm')}`;

const DEFAULT_ACTIONS = [
  { name: 'mais_info', title: MORE_INFOS },
  { name: 'observacoes', title: OBSERVATIONS },
  { name: 'cond_venda_filha', title: CONDITIONS_SALES_CHILD },
  { name: 'info_geral', title: GENERAL_INFO },
];

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    minHeight: 20,
    flex: 1,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#DADCE0',
  },
  badgeAdded: {
    position: 'absolute',
    zIndex: 9,
    bottom: 6,
    right: 0,
    width: 26,
    height: 26,
    backgroundColor: config.Colors.stripGreen,
    borderRadius: 15,
    elevation: 2,
    borderWidth: 2,
    borderColor: '#fff',
    justifyContent: 'center',
  },
  containerMessage: {
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    fontSize: 14,
    borderRadius: 16,
    paddingVertical: 4,
    minHeight: 28,
    minWidth: 60,
  },
  productName: {
    fontSize: 14,
    marginLeft: 10,
    marginRight: 16,
    fontFamily: fonts.QuicksandBold,
    color: color.grayDarkest,
    fontWeight: 'bold',
  },
  productPrice: {
    fontSize: 18,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
    color: '#000',
  },
  totalPrice: {
    fontSize: 16,
    fontFamily: fonts.QuicksandBold,
    color: color.primaryColor,
    marginLeft: 8,
  },
  productMinPrice: {
    fontSize: 14,
    marginLeft: 10,
    marginRight: 10,
    color: '#000',
  },
  productMultiple: {
    fontSize: 16,
    marginLeft: 10,
    marginRight: 10,
    marginVertical: 8,
    color: '#000',
    fontFamily: fonts.QuicksandItalic,
  },
  productMagnitude: {
    fontSize: 12,
    marginLeft: 10,
    marginRight: 10,
    color: '#000',
  },
  productStock: {
    fontSize: 14,
    marginLeft: 10,
    marginRight: 5,
    color: '#000',
  },
  card: {
    overflow: 'hidden',
    flex: 1,
  },
  optionsContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: 10,
    paddingHorizontal: 16,
  },
  statusPosivite: {
    width: 10,
    height: 10,
    marginLeft: 10,
    borderRadius: 10,
  },
  iconError: {
    tintColor: '#FFF',
    width: 16,
    height: 16,
  },
  badgeError: {
    width: 24,
    height: 24,
    borderColor: '#fff',
    borderWidth: 1,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ff000f',
  },
});

function Unidades({ produto }) {
  if (produto.type_magnitudes === MAGNITUDES_ONLY_PRODUCT) {
    return produto.unidade_macro > 1 ? (
      <Text style={styles.productMagnitude}>
        {produto.descricao_unidade_macro} {`${translate('contains')}`}{' '}
        {produto.unidade_macro}
      </Text>
    ) : null;
  }

  return produto.unidade > 1 ? (
    <Text style={styles.productMagnitude}>
      {`${translate('contains')}`} {produto.unidade}
    </Text>
  ) : null;
}

class ProductList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      item: {},
      quantity: 0,
      lastQuantity: '0',
      actions: [],
      modalMoreInfosVisible: false,
      updatingItem: false,
      generalInfos: [],
      sourceProductImage: null,
      clickable: true,
    };

    this.refPriceItemActive = React.createRef(); // Inicializa a ref
  }
  UNSAFE_componentWillMount() {
    if (this.props.item && !this.props.item.image) {
      const sourceProductImage = getImageProd(
        this.props.item,
        this.props.config,
      );
      this.setState({ sourceProductImage });
    }
  }

  async componentDidMount() {
    this.updateProduct(this.props.item, undefined, true);
    if (this.props.forceProductRefetch) {
      const pkey = this.props.item?.chave;
      const condvenda = this.props.cart.payload.condvenda;
      const productInOrder = this.props.cart.payload.produtos.find(
        produto => produto.chave === pkey,
      );
      if (productInOrder) {
        getProductFetch({ pkey, condvenda }).then(response => {
          const newProduct = JSON.parse(response);
          const toUpdateProps = {
            html: newProduct.html,
          };
          store.dispatch(
            updateProductHTML({
              chave: pkey,
              html: newProduct.html,
            }),
          );
          this.updateProduct({
            ...this.props.item,
            ...toUpdateProps,
          });
        });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    (() => {
      if (nextProps.cart.type === this.props.cart.type) {
        return;
      }
      if (
        this.props.cart.type === UPDATE_ITEM_REQUEST &&
        this.props.cart.type === APPLY_OPERATION_BY_GROUP_FETCH &&
        this.props.cart.type === REMOVE_MULTIPLE_ITEMS_FETCH
      ) {
        return;
      }
      if (
        nextProps.cart.type !== UPDATE_ITEM_REQUEST &&
        nextProps.cart.type !== APPLY_OPERATION_BY_GROUP_FETCH &&
        nextProps.cart.type !== REMOVE_MULTIPLE_ITEMS_FETCH &&
        nextProps.cart.type !== ORDER_FETCH_FAILURE
      ) {
        return;
      }
      if (nextProps.cart.type === ORDER_FETCH_FAILURE) {
        this.setState({ clickable: true });
      }
    })();

    (async () => {
      if (nextProps.cart.type === this.props.cart.type) {
        return;
      }
      if (
        this.props.cart.type === UPDATE_ITEM_REQUEST &&
        this.props.cart.type === APPLY_OPERATION_BY_GROUP_FETCH &&
        this.props.cart.type === REMOVE_MULTIPLE_ITEMS_FETCH
      ) {
        return;
      }
      if (
        nextProps.cart.type !== UPDATE_ITEM_REQUEST &&
        nextProps.cart.type !== APPLY_OPERATION_BY_GROUP_FETCH &&
        nextProps.cart.type !== REMOVE_MULTIPLE_ITEMS_FETCH &&
        nextProps.cart.type !== ORDER_FETCH_SUCCESS
      ) {
        return;
      }
      if (nextProps.cart.type === ORDER_FETCH_SUCCESS) {
        const prevType = this.props.cart.type;
        const ref = nextProps.cart.payload.ref;
        const condvenda = nextProps.cart.payload.condvenda;
        const pkey = this.props.item?.chave;

        const needsUpdateHTMLTypes = [
          UPDATE_ITEM_REQUEST,
          APPLY_OPERATION_BY_GROUP_FETCH,
          REMOVE_MULTIPLE_ITEMS_FETCH,
        ];
        if (
          this.props.item?.chave === ref ||
          prevType !== UPDATE_ITEM_REQUEST
        ) {
          let toUpdateProps = {};
          if (
            this.props.forceProductRefetch &&
            needsUpdateHTMLTypes.includes(prevType)
          ) {
            try {
              const actualHtml = this.props.item.html;
              const response = await getProductFetch({ pkey, condvenda });
              const newProduct = JSON.parse(response);

              toUpdateProps = {
                html: newProduct.html !== "" ? newProduct.html : actualHtml,
              };

              store.dispatch(
                updateProductHTML({
                  chave: pkey,
                  html: newProduct.html !== "" ? newProduct.html : actualHtml,
                }),
              );
            } catch (err) {
              console.error('getProductsFetch', err);
            }
          }
          this.updateProduct(
            {
              ...this.props.item,
              ...toUpdateProps,
            },
            nextProps.cart.payload.produtos,
          );
        } else if (prevType === UPDATE_ITEM_REQUEST) {
          this.updateOnlyProductPrice(
            {
              ...this.props.item,
            },
            nextProps.cart.payload.produtos,
          );
        } else {
          this.setState({
            updatingItem: false,
          });
        }
      }
    })();

    (() => {
      if (this.props.item.html === nextProps.item.html) {
        return;
      }
      if (nextProps.item.html === this.state.item.html) {
        return;
      }
      this.updateProduct(nextProps.item, undefined, true);
    })();

    (() => {
      if (
        this.props.cart?.configuracoes?.ignora_estoque ===
        nextProps.cart.configuracoes?.ignora_estoque
      ) {
        return;
      }
    })();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.item.quantidade !== this.state.quantity) {
      return true;
    }
    if (
      nextProps.itemUpdate &&
      nextProps.itemUpdate.ean === nextProps.item.ean
    ) {
      return true;
    }
    if (
      nextProps.itemUpdate &&
      nextProps.itemUpdate.preco === nextProps.item.preco
    ) {
      return true;
    }
    if (nextProps.item.estoque !== this.props.item.estoque) {
      return true;
    }
    if (nextProps.item.preco !== this.props.item.preco) {
      return true;
    }
    if (nextProps.item.quantidade !== this.props.item.quantidade) {
      return true;
    }
    if (nextProps.isSelectionMode !== this.props.isSelectionMode) {
      return true;
    }
    if (nextProps.isSelected !== this.props.isSelected) {
      return true;
    }

    if (nextState.quantity !== this.state.quantity) {
      return true;
    }
    if (nextState.lastQuantity !== this.state.lastQuantity) {
      return true;
    }
    if (nextState.item.estoque !== this.state.item.estoque) {
      return true;
    }
    if (nextState.item.preco !== this.state.item.preco) {
      return true;
    }
    if (nextState.item.quantidade !== this.state.item.quantidade) {
      return true;
    }
    if (nextState.modalMoreInfosVisible !== this.state.modalMoreInfosVisible) {
      return true;
    }
    if (nextState.updatingItem !== this.state.updatingItem) {
      return true;
    }
    if (nextState.actions !== this.state.actions) {
      return true;
    }
    if (nextState.sourceProductImage !== this.state.sourceProductImage) {
      return true;
    }

    if (nextProps.loadingAllProducts !== this.props.loadingAllProducts) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    (() => {
      if (prevProps.isCombo !== this.props.isCombo) {
        this.updateProduct(this.props.item, undefined, true);
      }
    })();

    (() => {
      if (this.props.item.html === prevProps.item.html) {
        return;
      }
      if (this.props.item.html === this.state.item.html) {
        return;
      }
      this.updateProduct(this.props.item, undefined, true);
    })();
    (() => {
      if (this.props.item.preco === prevProps.item.preco) {
        return;
      }
      if (this.props.item.preco === this.state.item.preco) {
        return;
      }
      this.updateProduct(this.props.item, undefined, true);
    })();

    (() => {
      if (this.props.item.estoque === prevProps.item.estoque) {
        return;
      }
      if (this.props.item.estoque === this.state.item.estoque) {
        return;
      }
      this.updateProduct(this.props.item, undefined, true);
    })();

    (() => {
      if (this.props.item.quantidade === prevProps.item.quantidade) {
        return;
      }
      if (this.props.item.quantidade === this.state.item.quantidade) {
        return;
      }
      this.updateProduct(this.props.item, undefined, true);
    })();

    (() => {
      if (prevProps.cart.type === this.props.cart.type) {
        return;
      }
      if (this.props.cart.type === ORDER_FETCH_REQUEST) {
        return;
      }
      if (prevProps.cart.type !== ORDER_FETCH_REQUEST) {
        return;
      }
      if (this.props.cart.type !== ORDER_FETCH_SUCCESS) {
        return;
      }
      const { payload } = this.props.cart;
      const messages = _.get(payload, 'mensagens') || [];

      executeActionsMessage(messages, undefined, () =>
        this.updateProduct(this.props.item, undefined, true),
      );
    })();
  }

  handlePress = () => {
    if (this.refPriceItemActive?.handleOnpress) {
        this.refPriceItemActive.handleOnpress();
    } else {
      console.log('Referência ao componente filho não está disponível.');
    }
  };

  onMoreInfosPress(isGeneralInfo) {
    this.setState({ modalMoreInfosVisible: true, isGeneralInfo });
  }

  onPressObservations() {
    this.props.onPressObservations(this.state.item);
  }

  onPressConditionsSalesChild() {
    const { onPressConditionsSalesChild } = this.props;

    if (onPressConditionsSalesChild) {
      onPressConditionsSalesChild(this.state.item);
    }
  }

  onPressConditionsPriceTerm() {
    const { onPressConditionsPriceTerm } = this.props;

    if (onPressConditionsPriceTerm) {
      onPressConditionsPriceTerm({ product: this.state.item });
    }
  }

  onRemove() {
    this.updateItem(this.state.item, 0);
  }

  onPressPopUp = async (eventName, index) => {
    if (eventName !== 'itemSelected') {
      return;
    }
    if (this.state.actions[index] === MORE_INFOS) {
      this.onMoreInfosPress(false);
    } else if (this.state.actions[index] === OBSERVATIONS) {
      this.onPressObservations();
    } else if (this.state.actions[index] === CONDITIONS_SALES_CHILD) {
      this.onPressConditionsSalesChild();
    } else if (this.state.actions[index] === REMOVE_ITEM) {
      this.onRemove();
    } else if (this.state.actions[index] === GENERAL_INFO) {
      await this.loadGeneralInfo();
      this.onMoreInfosPress(true);
    } else if (this.state.actions[index] === CONDITIONS_PRICE_TERM) {
      this.onPressConditionsPriceTerm();
    }
  };

  async loadGeneralInfo() {
    const { item } = this.props;
    let infos = await ProductsModule.getInfosProduct(item.chave);
    infos = JSON.parse(infos);
    const html = _.get(infos, 'general_infos_html', '');
    this.setState({ generalInfos: html });
  }

  getActions(item) {
    const { html } = item;
    const quantidade = item.quantidade || this.state.quantity;
    const {
      configLib,
      onPressConditionsPriceTerm,
      showProductAsHTML = false,
    } = this.props;
    const hiddenFieldsConf = _.get(
      configLib,
      'listagem_campos_visibilidade.product.hidden_fields',
      {},
    );
    const showPriceConditions = _.get(
      configLib,
      'listagem_campos_visibilidade.product.show_price_conditions',
      false,
    );
    const hiddenFields = Object.keys(hiddenFieldsConf);
    const actions = DEFAULT_ACTIONS.filter(
      i => !hiddenFields.includes(i.name),
    ).map(({ title }) => title);

    if (html && !showProductAsHTML && !actions.includes(MORE_INFOS)) {
      actions.push(MORE_INFOS);
    }

    if (quantidade > 0 && !actions.includes(REMOVE_ITEM)) {
      actions.push(REMOVE_ITEM);
    }

    // * Para que seja apenas incluso na Tela de Resumo do carrinho
    if (onPressConditionsPriceTerm && showPriceConditions) {
      actions.push(CONDITIONS_PRICE_TERM);
    }

    return actions;
  }

  renderProductInfo = product => {
    const { showProductAsHTML = false } = this.props;

    const html = adjustHtml(product.html);

    if (showProductAsHTML && html) {
      return (
        <View style={{ marginRight: 5 }}>
          <HTML containerStyle={{ marginLeft: 10 }} source={{ html }} />
        </View>
      );
    }

    return (
      <View style={{ marginRight: 5 }}>
        <Text style={styles.productName}>{product.nome || ''}</Text>
        <Text style={{ marginLeft: 10 }}>{product.codigo}</Text>
        {product.tipo !== 'matriz' ? <Unidades produto={product} /> : null}
        {product.tipo !== 'matriz'
          ? this.renderEstoque(product, this.props.configLib)
          : null}
      </View>
    );
  };

  appendProductGrouping() {
    console.log('appendProductGrouping')
    const { item } = this.state;
    this.props.appendProductGrouping?.(item);
  }

  onPress = () => {
    if (this.props.isSelectionMode) {
      this.appendProductGrouping();
    } else {
      this.itemClick();
    }
  };

  onLongPress = () => {
    const { item } = this.state;
    this.props.onLongPress?.(item);
  };

  getDefaultView() {
    const { itemUpdate, configLib, cart } = this.props;
    const {
      item: product = {},
      quantity,
      updatingItem,
      clickable,
    } = this.state;
    const ignoreSalesMultiple = _.get(
      cart,
      'payload.configuracoes.ignora_multiplo_de_venda',
      false,
    );
    const hiddenFields = _.get(
      configLib,
      'listagem_campos_visibilidade.product.hidden_fields',
      {},
    );
    const configComponent = _.get(
      configLib,
      'configuracoes.components_screen',
      {},
    );
    const highlightDiffPrice = _.get(
      configLib,
      'listagem_campos_visibilidade.product.highlight_diff_price',
      false,
    );

    const ignoreStock = _.get(
      cart,
      'payload.configuracoes.ignora_estoque',
      false,
    );
    const volumeFactor = ignoreSalesMultiple
      ? product.unidade
      : product.multiplo_venda;
    const stockExists =
      product.estoque !== undefined &&
      product.estoque !== null &&
      product.estoque > 0;
    const showStock = !hiddenFields.estoque;
    const showIncrementDecrement = !showStock || stockExists || ignoreStock;
    const showBasedOnStock =
      product.estoque === undefined ||
      product.estoque === null ||
      product.estoque > 0;
    const hasAttribute = false;
    const showDiffPrice =
      highlightDiffPrice && (product.desconto || product.acrescimo);

    return (
      <Touchable
        // tabIndex={-1}
        style={{
          borderRadius: 4,
          marginBottom: 8,
        }}
        disabled={!clickable}
        underlayColor="#ddd"
        onPress={this.onPress}
        // onLongPress={this.onLongPress}
        >
        <View
          style={[
            styles.container,
            this.props.isSelected
              ? { backgroundColor: color.grayContainerDark }
              : { backgroundColor: '#fff' },
          ]}>
          <View style={[styles.card]}>
            <View style={style.spaceBetween}>
              <View
                style={{
                  justifyContent: 'center',
                  textAlign: 'center',
                  alignItems: 'center',
                }}>
                <View style={{ marginBottom: 8, width: 100, height: 100 }}>
                  <ProductImage
                    styleError={{ position: 'absolute', left: '-44%' }}
                    style={
                      !stockExists
                        ? { height: '100%', opacity: 0.5 }
                        : { height: '100%' }
                    }
                    source={
                      this.props.item?.image || this.state.sourceProductImage
                    }
                  />
                  {this.imageCheck()}
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    bottom: 4,
                  }}>
                  {product.cor1 ? (
                    <View
                      style={[
                        styles.statusPosivite,
                        { backgroundColor: product.cor1.toLowerCase() },
                      ]}
                    />
                  ) : (
                    <View style={[styles.statusPosivite]} />
                  )}
                  {product.cor2 ? (
                    <View
                      style={[
                        styles.statusPosivite,
                        { backgroundColor: product.cor2.toLowerCase() },
                      ]}
                    />
                  ) : (
                    <View style={[styles.statusPosivite]} />
                  )}
                  {product.cor3 && product.cor3 !== '0,0,0' ? (
                    <View
                      style={[
                        styles.statusPosivite,
                        { backgroundColor: `rgb(${product.cor3})` },
                      ]}
                    />
                  ) : (
                    <View style={[styles.statusPosivite]} />
                  )}
                </View>
              </View>
              <View
                style={{
                  flex: 1,
                  marginTop: 8,
                  paddingRight: 20,
                  marginRight: 10,
                }}>
                <Touchable
                  tabIndex={-1}
                  style={{ flex: 1 }}
                  // onPress={this.onPress}
                  onLongPress={this.onLongPress}
                  >
                  {this.renderProductInfo(product)}   
                </Touchable>

                {product.tipo === 'matriz' ? (
                  product.preco > 0 ? (
                    <View>
                      <Text style={[styles.productMinPrice]}>{`${translate(
                        'from',
                      )}`}</Text>
                      <Price
                        value={product.preco || product.precobase || 0}
                        style={styles.productPrice}
                      />
                    </View>
                  ) : (
                    <View
                      style={[
                        style.rowDirection,
                        style.flexWrap,
                        { paddingBottom: 10 },
                      ]}>
                      <CalculatedValue
                        value={product.precototal}
                        style={styles.totalPrice}
                      />
                    </View>
                  )
                ) : configComponent && configComponent.product_item_price ? (
                  <View style={{ marginTop: 5 }}
                  tabIndex={-1}
                  >
                    {this.renderComponentPrice(
                      configComponent.product_item_price,
                    )}
                  </View>
                ) : (product.preco > 0 && showBasedOnStock) ||
                (product.precobase && product.precobase > 0) ||
                ignoreStock ? (
                  <Price
                    value={
                      itemUpdate.chave === product.chave
                        ? itemUpdate.preco || 0
                        : product.preco || product.precobase || 0
                    }
                    style={[styles.productPrice]}
                  />
                ) : null}
                {!stockExists ? (
                  <IconText
                    text={`${translate('productNotAvailable')}`}
                    containerStyle={styles.containerMessage}
                    textStyle={{
                      color: color.redBadge,
                      fontWeight: '400',
                    }}
                  />
                ) : !configComponent.product_item_price &&
                !(product.preco >= 0) ? (
                  <IconText
                    text={`${translate('priceNotAvailable')}`}
                    containerStyle={styles.containerMessage}
                    textStyle={{
                      color: '#000',
                      fontWeight: '400',
                    }}
                  />
                ) : null}
                {product.tipo === 'matriz' ? (
                  product.preco > 0 ? (
                    <View style={[style.rowDirection, style.flexWrap]}>
                      <CalculatedValue
                        value={product.precototal || 0}
                        style={styles.totalPrice}
                      />
                    </View>
                  ) : null
                ) : showIncrementDecrement ? (
                  <View style={[{ paddingTop: 8, paddingBottom: 4 }]} onFocus={() => {
                    this.handlePress();
                  }}>
                    <View style={[{ flexDirection: 'row' }]}>
                      {!this.props.isSelectionMode && !this.props?.loadingAllProducts && (
                        <Quantity
                          product={product}
                          quantity={quantity}
                          setQuantity={this.setQuantity}
                          addItem={this.addItem}
                          volumeFactor={volumeFactor}
                          updatingItem={updatingItem}
                          ignoreStock={ignoreStock}
                        />
                      )}
                      <CalculatedValue
                        value={product.precototal || 0}
                        style={[
                          styles.totalPrice,
                          {
                            color: showDiffPrice
                              ? '#ff000f'
                              : color.primaryColor,
                          },
                        ]}
                      />
                    </View>
                    {product.tipo !== 'matriz' && product.multiplo_venda > 1 ? (
                      <Text style={styles.productMultiple}>
                        * {`${translate('change')}`} de {product.multiplo_venda}{' '}
                        em {product.multiplo_venda}
                      </Text>
                    ) : null}
                  </View>
                ) : null}
              </View>
              {Platform.OS === 'android' && (
                <View
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    paddingVertical: 12,
                    paddingHorizontal: 6,
                  }}>
                  {product.tipo !== 'matriz' &&
                  this.state.actions.length > 0 ? (
                    <PopupMenu
                      actions={this.state.actions}
                      onPress={this.onPressPopUp}
                    />
                  ) : null}
                  <View style={{ flex: 1 }} />
                </View>
              )}
            </View>
            {hasAttribute ? (
              <View>
                <View style={styles.optionsContainer}>
                  <Option attribute="Cor" option="#ddd" type="color" />
                </View>
              </View>
            ) : null}
            {!_.isEmpty(product.opcoes) ? (
              <View>
                <View style={styles.optionsContainer}>
                  {product.opcoes.atributos_valores.map(option => (
                    <Option
                      attribute={option.atributo}
                      selected={false}
                      clickButton={false}
                      option={option.valores[0]}
                      type={option.tipo}
                    />
                  ))}
                </View>
              </View>
            ) : null}
          </View>
          {this.renderMoreInfoModal()}
        </View>
      </Touchable>
    );
  }

  updateOnlyProductPrice(item, cartProducts) {
    const { config } = this.props;
    const produto = { ...item };

    const items = cartProducts || this.props.cart.payload.produtos;
    const cartProduct = _.find(items, ['chave', `${item.chave}`]);
    const { quantity } = this.state;

    const newerQuantity = cartProduct?.quantidade ?? quantity;

    if (cartProduct) {
      produto.preco = cartProduct.preco;
      produto.precototal = produto.preco * newerQuantity;
      produto.desconto = cartProduct.desconto;
      produto.acrescimo = cartProduct.acrescimo;
      if (this.props.forceProductRefetch) {
        produto.html = cartProduct.html;
      }

      this.setState({
        updatingItem: false,
        clickable: true,
        item: produto,
        quantity: newerQuantity,
        actions: this.getActions(produto),
        sourceProductImage: getSourceImageProduct(item, config),
      });
    } else {
      produto.precototal = produto.precobase * newerQuantity;

      this.setState({
        updatingItem: false,
        clickable: true,
        item: produto,
        quantity: newerQuantity,
        actions: this.getActions(produto),
        sourceProductImage: getSourceImageProduct(item, config),
      });
    }
  }

  updateProduct(item, cartProducts, html_from_cart = false) {
    const { config } = this.props;
    const produto = Object.assign({}, item);
    const items = cartProducts || this.props.cart.payload.produtos;
    const cartProduct = _.find(items, ['chave', `${item.chave}`]);

    if (cartProduct) {
      produto.quantidade = cartProduct.quantidade;
      produto.preco = cartProduct.preco;
      produto.precototal = cartProduct.precototal;
      produto.desconto = cartProduct.desconto;
      produto.acrescimo = cartProduct.acrescimo;
      if (html_from_cart && this.props.forceProductRefetch) {
        produto.html = cartProduct.html;
      }

      const { quantidade } = produto;

      this.setState({
        updatingItem: false,
        clickable: true,
        item: produto,
        quantity: quantidade,
        lastQuantity: `${quantidade}`,
        actions: this.getActions(produto),
        sourceProductImage: getSourceImageProduct(item, config),
      });
    } else {
      produto.quantidade = 0;
      produto.precototal = quantidade * produto.preco;

      const { quantidade } = produto;
      this.setState({
        updatingItem: false,
        clickable: true,
        item: produto,
        quantity: quantidade,
        lastQuantity: `${quantidade}`,
        actions: this.getActions(produto),
        sourceProductImage: getSourceImageProduct(item, config),
      });
    }
  }

  imageCheck() {
    const imageCheck =
      this.state.quantity > 0 || this.props.isSelected ? (
        <View style={styles.badgeAdded}>
          <Image
            style={{ tintColor: '#fff', width: 20, height: 20 }}
            source={Images.iconCheck}
          />
        </View>
      ) : null;

    return imageCheck;
  }

  updateItem(item, quantity, lastQuantity) {
    this.setState({ updatingItem: true }, () => {
      if (this.props.addItem) {
        this.props.addItem(item, quantity, lastQuantity);
      }
    });
  }

  setQuantity = (quantity, callback = () => {}) => {
    this.setState({ quantity, clickable: false }, callback);
  };

  addItem = (product, quantity) => {
    this.setState({ updatingItem: true, clickable: false });
    this.props.addItem(product, quantity);
  };

  addRemove(item, quantity) {
    this.updateItem(this.state.item, quantity);
  }

  itemClick = () => {
    if (this.props.editable) {
      this.props.itemClick(this.state.item);
    }
  };

  renderComponentPrice(componentId) {
    const { item } = this.state;
    const props = { product: item, isCombo: this.props.isCombo };
    const ComponentItem = getComponent(componentId);

    return <ComponentItem key={item.chave} {...props}  ref={(ref) => {
      this.refPriceItemActive = ref; // Armazena a referência na instância da classe pai
    }} />;
  }

  renderEstoque(product, configLib) {
    const hiddenFields =
      _.get(configLib, 'listagem_campos_visibilidade.product.hidden_fields') ||
      {};
    let showEstoque = !hiddenFields.estoque;

    const { estoque } = product;
    showEstoque = estoque > -1 ? showEstoque : false;

    const styleColor = {};
    let image = null;

    if (showEstoque && estoque && estoque === 0) {
      styleColor.color = '#ff000f';
      image = (
        <View style={styles.badgeError}>
          <Image style={styles.iconError} source={Images.iconAlertError} />
        </View>
      );
    }

    return showEstoque && estoque ? (
      <View style={{ flex: 1, flexDirection: 'row' }}>
        <Text style={[styles.productStock, styleColor]}>
          {estoque} {`${translate('inStock')}`}
        </Text>
        {image}
      </View>
    ) : null;
  }

  renderMoreInfoModal() {
    const { generalInfos, isGeneralInfo, item } = this.state;
    const htmlContent = isGeneralInfo ? generalInfos : item.html;
    return (
      <MoreInfo
        modalMoreInfosVisible={this.state.modalMoreInfosVisible}
        htmlContent={htmlContent}
        onModalClose={() => {
          this.setState({ modalMoreInfosVisible: false });
        }}
      />
    );
  }

  render() {
    const { quantity } = this.state;
    if (Platform.OS === 'ios' && quantity > 0 && this.props.editable) {
      return (
        <SwipeOut
          right={[
            {
              text: `${translate('remove')}`,
              onPress: () => {
                this.onRemove();
              },
              backgroundColor: color.redBadge,
            },
          ]}>
          {this.getDefaultView()}
        </SwipeOut>
      );
    }
    return this.getDefaultView();
  }
}

ProductList.propTypes = {
  item: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  configLib: PropTypes.object.isRequired,
  itemUpdate: PropTypes.object.isRequired,
  onPressObservations: PropTypes.func,
  onPressConditionsSalesChild: PropTypes.func.isRequired,
  onPressConditionsPriceTerm: PropTypes.func.isRequired,
  addItem: PropTypes.func.isRequired,
  itemClick: PropTypes.func.isRequired,
  onSelectProduct: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  isSelectionMode: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  showPriceConditions: PropTypes.bool.isRequired,
  showProductAsHTML: PropTypes.bool.isRequired,
  isCombo: PropTypes.bool.isRequired,
  clickable: PropTypes.bool.isRequired,
  forceProductRefetch: PropTypes.bool.isRequired,
  loadingAllProducts: PropTypes.bool,
};

ProductList.defaultProps = {
  onPressObservations: () => {},
  updateItem: {
    loading: false,
    itemKey: 0,
  },
};

export default ProductList;
