import React, { useState, useCallback, useEffect } from 'react';
import {
  FlatList,
  Image,
  SafeAreaView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import ToggleButton from './components/ToggleButton';
import RNMonthPicker from 'react-native-month-year-picker';
import Touchable from 'react-native-platform-touchable';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import translate, { calendarNames } from '~/locales';
import moment from 'moment';
import { Button } from '../../../components_base/src/components';
import { Money, color } from '~/common';
import TimeLine from './components/Timeline';
import { listExpenses } from './api';
import Snackbar from 'react-native-snackbar';
import Spinner from 'react-native-loading-spinner-overlay';
import FileViewer from 'react-native-file-viewer';
import { Navigation } from 'react-native-navigation';
import RNHTMLtoPDF from 'react-native-html-to-pdf';
import _ from 'lodash';
import HTMLTemplate from './pdfTemplate';
import AccountsModule from '~/modules/AccountsModule';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FloatingAction } from 'react-native-floating-action';
import Images from '@assets/Images';
import { createNotifyError, createNotifyInfo } from '~/components/Web/ToastNotify';
import AsyncStorage from '@react-native-async-storage/async-storage';

const dateFilters = [
  {
    label: `5 ${translate('days')}`,
    value: 5,
    active: false,
    id: 0,
  },
  {
    label: `10 ${translate('days')}`,
    value: 10,
    active: false,
    id: 1,
  },
  {
    label: `15 ${translate('days')}`,
    value: 15,
    active: false,
    id: 2,
  },
  {
    label: `30 ${translate('days')}`,
    value: 30,
    active: false,
    id: 3,
  },
];

const expenseTypes = {
  out: `${translate('in')}`,
  in: `${translate('out')}`,
};

const styles = StyleSheet.create({
  containerInput: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderWidth: 0.9,
    borderColor: '#000',
    height: 36,
    borderRadius: 4,
    paddingHorizontal: 12,
  },
  input: {
    backgroundColor: '#fff',
    color: '#000',
    fontSize: 15,
  },
});

function Resume({ navigation, componentId, onPressExpense, config }) {
  const [activeFilter, setActiveFilter] = useState(null);
  const [visiblePickerStart, setVisiblePickerStart] = useState(false);
  const [visiblePickerEnd, setVisiblePickerEnd] = useState(false);
  const [dataExpenses, setDataExpenses] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [localConfig, setLocalConfig] = useState(config);

  const getDates = date => {
    return {
      start: moment(date)
        .startOf('month')
        .format('YYYY-MM-DD'),
      end: moment(date)
        .endOf('month')
        .format('YYYY-MM-DD'),
    };
  };

  useEffect(() => {
    setLeftButton();
    getConfig();
    const dates = getDates(new Date());
    getExpenses(dates);
    setActiveFilter(null);
  }, []);

    const getConfig = async () => {
        try {
            const config = await AsyncStorage.getItem('@objPayload');
            setLocalConfig(JSON.parse(config));
        } catch (err) {
            console.log('ERROR getConfig: ', err);
        }
    };

  const updateStartDate = date => setStartDate(date);

  const updateEndDate = date => setEndDate(date);

  const updateVisiblePickerStart = visible => setVisiblePickerStart(visible);

  const updateVisiblePickerEnd = visible => setVisiblePickerEnd(visible);

  const getExpenses = async date => {
    try {
      const expensesList = (await listExpenses(date)) || {};
      setDataExpenses(expensesList?.movements);
      updateStartDate(date?.start);
      updateEndDate(date?.end);
    } catch (err) {
      createNotifyError(`${translate('errorListExpenses')}`);
    } finally {
      setLoading(false);
    }
  };

  const obtainUserDataLib = () =>
    new Promise((res, rej) => {
      AccountsModule.obtainUserData(res, rej);
    });

  const getUserName = async () => {
    const userParamsData = await obtainUserDataLib();
    const userParamsDataParse = JSON.parse(userParamsData);
    const firstName = _.get(userParamsDataParse, 'rca_nomeusuario', '');
    return firstName;
  };

  const getFile = async () => {
    _.templateSettings.interpolate = /{{([\s\S]+?)}}/g;
    const username = await getUserName();
    const template = _.template(HTMLTemplate);
    const companyName = _.get(localConfig, 'display_name', '');
    const final = template({
      expenses: [...dataExpenses].map((ex, idx) => {
        let date = moment(ex.date).format('L');
        if (idx > 0) {
          let previousDate = moment(dataExpenses[idx - 1]?.date).format('L');
          if (previousDate === date) {
            date = null;
          }
        }
        return {
          date,
          value: Money(ex.value),
          type: expenseTypes[ex.type],
          title: ex.title,
          description: ex.description,
          htmlClass: date ? ' no_border_bottom' : '',
        };
      }),
      $name: username,
      $month: moment().format('LLL'),
      $company: `${companyName}`,
      $total: Money(_.sumBy(dataExpenses, ex => ex.value)),
      $interval: `${moment(startDate).format('L')} - ${moment(endDate).format(
        'L',
      )}`,
    });
    let options = {
      html: final,
      fileName: 'relatorio_despesas',
      directory: 'Documents',
    };
    return final;
    // return await RNHTMLtoPDF.convert(options);
  };

  const onGeneratePdf = async () => {
    setLoading(true);
    try {
      if (!dataExpenses?.length) {
        createNotifyInfo(`${translate('emptyListToGeneratePdf')}`);
      } else {
        const file = await getFile();
        generatePDF(file);
        //await FileViewer.open(file.filePath);
      }
    } catch (err) {
      createNotifyError(`${translate('errorGenerateReport')}`);
    } finally {
      setLoading(false);
    }
  };

  const onFilterByDays = (index, isActive, item) => {
    setLoading(true);
    setActiveFilter(item.value);
    const end = moment(new Date()).format('YYYY-MM-DD');
    if (isActive) {
      const start = moment(new Date())
        .subtract(item.value, 'days')
        .format('YYYY-MM-DD');
      getExpenses({ start, end });
    } else {
      getExpenses(getDates(new Date()));
    }
  };

  const onFilterByRange = () => {
    setLoading(true);
    const start = moment(startDate)
      .startOf('month')
      .format('YYYY-MM-DD');
    const end = moment(endDate)
      .endOf('month')
      .format('YYYY-MM-DD');
    getExpenses({ start, end });
  };

  const renderItem = ({ item, index }) => {
    return (
      <ToggleButton
        forceInactivate={activeFilter !== item.value}
        onPress={isActive => onFilterByDays(index, isActive, item)}
        text={item.label}
      />
    );
  };

  const onValueChange = useCallback(
    (event, newDate, type) => {
      if (event !== 'dismissedAction') {
        if (type === 'start') {
          const selectedDate =
            moment(newDate).format('YYYY-MM-DD') || startDate;

          updateVisiblePickerStart(false);
          updateStartDate(selectedDate);
        } else if (type === 'end') {
          const selectedDate = moment(newDate).format('YYYY-MM-DD') || endDate;

          updateVisiblePickerEnd(false);
          updateEndDate(selectedDate);
        }
      } else {
        if (type === 'start') {
          updateVisiblePickerStart(false);
        } else if (type === 'end') {
          updateVisiblePickerEnd(false);
        }
      }
    },
    [endDate, startDate],
  );

  const setLeftButton = () => {
    navigation.setOptions({
      headerLeft: () => (
        <TouchableOpacity onPress={() => {
          navigation.goBack();
        }}
        >
          <Image
            source={Images.iconBack}
            style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
          />
        </TouchableOpacity>
      ),
    });
  };

    const generatePDF = (html) => {
        const newWindow = window.open();
        newWindow.document.write(html);
        newWindow.document.close();
        newWindow.print();
    };



  return (
    <SafeAreaView style={{ height: '100%' }}>
      <View style={{ paddingLeft: 16, paddingVertical: 16 }}>
        <Text
          style={{
            marginBottom: 12,
            fontWeight: '400',
            fontSize: 16,
            color: '#000',
          }}>
          {`${translate('reportsOfLast')}`}
        </Text>
        <FlatList
          data={dateFilters}
          estimatedItemSize={190}
          renderItem={renderItem}
          horizontal
          showsHorizontalScrollIndicator={false}
          ListFooterComponent={
            <View>
              <ToggleButton
                forceInactivate={activeFilter !== -1}
                onPress={isActive => {
                  setActiveFilter(isActive ? -1 : null);
                }}
                text={`${translate('other')}`}
              />
            </View>
          }
        />
      </View>

      {visiblePickerStart && (
        <RNMonthPicker
          onChange={(e, date) => onValueChange(e, date, 'start')}
          value={moment(startDate).toDate()}
          mode="short"
        />
      )}
      {visiblePickerEnd && (
        <RNMonthPicker
          onChange={(e, date) => onValueChange(e, date, 'end')}
          value={moment(endDate).toDate()}
          mode="short"
        />
      )}

      {activeFilter === -1 ? (
        <>
          <View
            style={{
              flexDirection: 'row',
              paddingHorizontal: 16,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Touchable
              style={{
                width: 185,
              }}
              onPress={() => updateVisiblePickerStart(!visiblePickerStart)}>
              <View style={[styles.containerInput, {}]}>
                <TextInput
                  editable={false}
                  value={`${
                    calendarNames?.monthNames?.[moment(startDate).get('month')]
                  } - ${moment(startDate).get('year')}`}
                  style={styles.input}
                />
                <Icon name="calendar" size={20} color={'#000'} />
              </View>
            </Touchable>
            <Touchable
              style={{
                width: 185,
              }}
              onPress={() => updateVisiblePickerEnd(!visiblePickerEnd)}>
              <View style={[styles.containerInput]}>
                <TextInput
                  editable={false}
                  value={`${
                    calendarNames?.monthNames?.[moment(endDate).get('month')]
                  } - ${moment(endDate).get('year')}`}
                  style={styles.input}
                />
                <Icon name="calendar" size={20} color={'#000'} />
              </View>
            </Touchable>
          </View>
          <Button
            style={{
              borderRadius: 4,
              borderWidth: 1,
            }}
            containerStyle={{
              borderRadius: 4,
              paddingHorizontal: 16,
              marginTop: 16,
              alignSelf: 'flex-end',
              marginBottom: 16,
            }}
            color={color.primaryColor}
            title={`${translate('generateResume')}`}
            onPress={onFilterByRange}
            outline
            normalCase
          />
        </>
      ) : null}
      <Spinner
        visible={loading}
        overlayColor="rgba(0, 0, 0, 0.7)"
        textStyle={{ color: '#FFF' }}
      />

      <TimeLine data={dataExpenses} onPressExpense={onPressExpense} />

      <FloatingAction
        onPressMain={onGeneratePdf}
        visible
        showBackground={false}
        floatingIcon={<Image source={Images.iconDownload} tintColor={'#FFF'} style={{ width: 18, height: 18 }} />}
      />
    </SafeAreaView>
  );
}

Resume.propTypes = {
  componentId: PropTypes.number,
  onPressExpense: PropTypes.func,
  config: PropTypes.objectOf(PropTypes.any),
};

function mapStateToProps(state) {
  return {
    config: state.configFetch,
  };
}

export default connect(mapStateToProps)(Resume);
