import AsyncStorage from '@react-native-async-storage/async-storage';

class CacheAtividades {
  async getData() {
    const data = await AsyncStorage.getItem('@Session:dataAtividade');
    return JSON.parse(data);
  }

  async setData(data) {
    await AsyncStorage.setItem('@Session:dataAtividade', JSON.stringify(data));
  }
}

export default CacheAtividades;
