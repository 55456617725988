import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View, Text, Platform, Image } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import Images from '@assets/Images';

const styles = StyleSheet.create({
  row: {
    height: 48,
    backgroundColor: '#fff',
    justifyContent: 'space-between',
  },
  rowContent: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 47,
    paddingHorizontal: 16,
    justifyContent: 'space-between',
  },
  text: {
    fontSize: 17,
    color: '#000',
  },
  iosIcon: {
    width: 24,
    height: 24,
    tintColor: 'rgba(0,0,0,0.25)',
  },
  bottomLine: {
    backgroundColor: 'rgba(0, 0, 0, 0.054)',
    height: 1,
    marginLeft: Platform.OS === 'ios' ? 16 : 0,
  },
});

function Item({
  title, onPress, children, style, textStyle, index,
}) {
  return (
    <Touchable
      onPress={() => { onPress(index); }}
    >
      <View style={styles.row}>
        <View style={[styles.rowContent, style]}>
          {children && typeof children !== 'string' ?
            children
            :
            <Text style={[styles.text, textStyle]}>{title || children}</Text>
          }
          {Platform.OS === 'ios' &&
          <Image
            source={Images.iconChevronRight}
            style={styles.iosIcon}
          />
          }
        </View>
        <View style={styles.bottomLine} />
      </View>
    </Touchable>
  );
}

Item.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string,
  // eslint-disable-next-line react/no-typos
  style: PropTypes.style,
  // eslint-disable-next-line react/no-typos
  textStyle: PropTypes.style,
  onPress: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node]),
};

Item.defaultProps = {
  title: '',
  style: {},
  textStyle: {},
  onPress: () => {},
  children: undefined,
};

export default Item;
