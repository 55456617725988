import { createAsyncThunk } from '@reduxjs/toolkit';

export const UPDATE_COMBO_ORDER_REQUEST = 'UPDATE_COMBO_ORDER';

const updateComboPromise = payload =>
  new Promise(resolve => {
    resolve(payload);
  });

export const updateCombo = createAsyncThunk(
  'ordersFetch/updateCombo',
  async payload => {
    try {
      const response = await updateComboPromise(payload);

      return JSON.parse(response);
    } catch (error) {
      return JSON.parse(error);
    }
  },
);
