import axios from 'axios';
import { getAuthToken } from '~/services/anywhere/constants';
import api from '../apiBaseAnywhere';

export const handleDestroySession = async (save = false) => {
  await destroySession(save);
  alert('Pedido finalizado! Fechando aba...');
  window.close();
};

export const destroySession = async (save = false) => {
  const token = await getAuthToken();
  const axiosInstance = await api();
  return axiosInstance.delete('/v1/api/session/', {
    headers: { Authorization: `FSJWT ${token}` },
    data: {
      save,
    },
  });
};

export const createSession = async customerPkey => {
  const token = await getAuthToken();
  const axiosInstance = await api();
  return axiosInstance.post('/v1/api/session/',
    {
      cliente_pkey: customerPkey,
    },
    {
      headers: { Authorization: `FSJWT ${token}` },
    },
  );
};
