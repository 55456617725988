import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  card: {
    borderRadius: 4,
    padding: 12,
    borderWidth: 1,
    borderColor: '#E6E6E6',
    flexDirection: 'row',
    backgroundColor: 'white',
    alignItems: 'center',
    flexShrink: 1,
    margin: 8,
    width: '100%',
    overflow: 'hidden',
  },
  title: {
    color: '#666666',
    fontSize: 12,
    fontWeight: '600',
    flexShrink: 1,
    maxWidth: '90%',
    lineHeight: 16,
  },
  result: {
    fontSize: 14,
    fontWeight: '500',
    color: '#1A1A1A',
    lineHeight: 16,
  },
});

export default function CardResume({ title, icon, result }) {
  return (
    <View style={styles.card}>
      <Image source={icon} style={{ width: 24, height: 24 }} tintColor={'#1A1A1A'} />
      <View style={{ marginLeft: 8, width: '100%' }}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.result}>{result}</Text>
      </View>
    </View>
  );
}

CardResume.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  result: PropTypes.string,
};
