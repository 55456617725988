import React from 'react';
import PropTypes from 'prop-types';
import { View, Image, Text } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import Images from '@assets/Images';
import translate from '~/locales';

import styles from '../OrderPending/styles';

const PhotoPending = ({ onPressSendNow, pendingPhotosList }) => {
  const cad = pendingPhotosList.filter(photo => photo.task === null);
  const task = pendingPhotosList.filter(photo => photo.task);

  function setString() {
    if (cad.length > 0 && task.length > 0) {
      return (
        <Text style={styles.textClientValue}>{`${translate(
          'register',
        )} e ${translate('activities')}`}</Text>
      );
    } else if (cad.length > 0) {
      return (
        <Text style={styles.textClientValue}>{translate('register')}</Text>
      );
    } else {
      return (
        <Text style={styles.textClientValue}>{translate('activities')}</Text>
      );
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.appHighLight}>
        <View style={styles.containerImg}>
          <Image style={styles.imageHighLight} source={Images.uploadCloud} />
        </View>
        <View style={styles.containerTitle}>
          <Text style={styles.title}>{`${translate(
            'syncPendingImages',
          )}`}</Text>
        </View>
      </View>
      <View style={styles.containerSubtitle}>
        <Text style={styles.subtitle}>
          {`${translate('thereArePhotos')} `}
          {setString()}
          {` ${translate('photosWasntSent')} `}
        </Text>
        <Text>
          <Text style={styles.textTotal}>{`${pendingPhotosList.length} ${
            pendingPhotosList.length > 1
              ? translate('photos')
              : translate('photo')
          }`}</Text>
          <Text> {translate('pendingSend')}</Text>
        </Text>
      </View>
      <View style={styles.containerBtns}>
        <Touchable
          onPress={onPressSendNow}
          style={{
            marginRight: 18,
          }}
          >
          <View style={styles.containerBtnRight}>
            <Text style={styles.textButton}>{translate('sendNow')}</Text>
          </View>
        </Touchable>
      </View>
    </View>
  );
};

PhotoPending.propTypes = {
  onPressSendNow: PropTypes.func,
  pendingPhotosList: PropTypes.Array,
};
export default PhotoPending;
