import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateBonification } from './utilsLib';

export const ORDER_UPDATE_BONIFICATION_CART = 'ORDER_UPDATE_BONIFICATION_CART';

export const updateBonificationCart = createAsyncThunk(
  'ordersFetch/updateBonificationCart',
  async payload => {
    try {
      const response = await updateBonification(payload);

      return JSON.parse(response);
    } catch (error) {
      console.log('error', error);
      return error;
    }
  },
);
