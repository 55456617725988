import { StyleSheet } from 'react-native';
import { colors, sizes } from '../../values';
import { fonts } from "../../../../src/utils/Fonts";


const styles = StyleSheet.create({
  container: {
    padding: sizes.horizontalSpacing,
    borderBottomColor: colors.grayContainerDark,
    borderBottomWidth: 3,
    backgroundColor: '#FFF',
  },
  title: {
    color: colors.primaryColor,
    fontSize: sizes.textLittleBig,
    fontFamily: fonts.QuicksandBold,
    marginBottom: sizes.horizontalSpacing,
  },
});

export default styles;
