import React from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';

import TotalizerListItem from './TotalizerListItem';
import color from '../../common/color';
import * as config from '../../config/config';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  totalizerContent: {
    paddingLeft: 16,
    paddingTop: 16,
  },
  title: {
    color: Platform.OS === 'ios' ? color.grayDark : color.primaryColor,
    fontSize: 16,
    fontWeight: 'bold',
  },
  containerTotalizers: {
    flexWrap: 'wrap',
    borderBottomColor: config.Colors.grayContainer,
    borderBottomWidth: 4,
  },
});

function Totalizers({ totalizers }) {
  const { title } = totalizers;
  const totalizersColumn1 = [];
  const totalizersColumn2 = [];

  totalizers.data.forEach((t, index) => {
    if (index % 2 === 0) {
      totalizersColumn1.push(t);
    } else {
      totalizersColumn2.push(t);
    }
  });

  return (
    <View>
      {totalizers &&
      <View>
        <View style={styles.totalizerContent}>
          <Text style={styles.title}>{title}</Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View style={[{ flex: 1 }, styles.containerTotalizers]}>
            {totalizersColumn1.map((totalizer) => (
              <TotalizerListItem key={totalizer.ord} totalizer={totalizer} />))
            }
          </View>
          <View style={[{ flex: 1 }, styles.containerTotalizers]}>
            {totalizersColumn2.map((totalizer) =>
              <TotalizerListItem key={totalizer.ord} totalizer={totalizer} />)
            }
          </View>
        </View>
      </View>
      }
    </View>
  );
}

Totalizers.propTypes = {
  totalizers: PropTypes.shape({
    title: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
};

export default Totalizers;
