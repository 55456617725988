/* @flow */

import React from 'react';
import { ScrollView, View } from 'react-native';
import { style } from '~/common';
import Option from '../../components/Option';
import PropTypes from 'prop-types';

function BeforeAndAfter({
  onPressShowCamera,
  onRemovePhoto,
  afterSrc,
  beforeSrc,
}) {
  const onPressShowCameraBeforeSrc = () => {
    onPressShowCamera({ actionType: 'beforeSrc' });
  };

  const onRemovePhotoBeforeSrc = () => {
    onRemovePhoto('beforeSrc');
  };
  const onPressShowCameraAfterSrc = () => {
    onPressShowCamera({ actionType: 'afterSrc' });
  };
  const onRemovePhotoAfterSrc = () => {
    onRemovePhoto('afterSrc');
  };



  return (
    <View>
      <ScrollView>
        <View style={style.whiteContainer}>
          <View style={{ paddingHorizontal: 30 }}>
            <Option
              source={beforeSrc?.base64 ? { uri: beforeSrc.base64 } : beforeSrc}
              onPress={onPressShowCameraBeforeSrc}
              containerStyle={{ margin: 20, marginBottom: 0 }}
              onRemove={onRemovePhotoBeforeSrc}
            />
            <Option
              source={afterSrc?.base64 ? { uri: afterSrc.base64 } : afterSrc}
              onPress={onPressShowCameraAfterSrc}
              containerStyle={{ margin: 20 }}
              onRemove={onRemovePhotoAfterSrc}
            />
          </View>
        </View>
      </ScrollView>
    </View>
  );
}

BeforeAndAfter.propTypes = {
  onPressShowCamera: PropTypes.func,
  beforeSrc: PropTypes.any,
  afterSrc: PropTypes.any,
};

export default BeforeAndAfter;
