import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ProductsModule from '~/modules/ProductsModule';

export const PRODUCT_OBSERVATIONS_REQUEST = 'PRODUCT_OBSERVATIONS_REQUEST';
export const PRODUCT_OBSERVATIONS_SUCCESS = 'PRODUCT_OBSERVATIONS_SUCCESS';
export const PRODUCT_OBSERVATIONS_FAILURE = 'PRODUCT_OBSERVATIONS_FAILURE';

const getProducts = payload =>
  new Promise((resolve, reject) => {
    const { codigo } = payload;
    ProductsModule.getProductObservations(`${codigo}`, resolve, reject);
  });

export const productObservations = createAsyncThunk(
  'productObservations/fetch',
  async payload => {
    try {
      const filters = await getProducts(payload);
      return filters;
    } catch (error) {
      throw error;
    }
  },
);
const initialState = {
  type: '',
  loading: false,
  erro: false,
  payload: [],
};
const productObservationsSlice = createSlice({
  name: 'productObservations',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(productObservations.pending, state => {
        state.type = PRODUCT_OBSERVATIONS_REQUEST;
        state.loading = true;
      })
      .addCase(productObservations.fulfilled, (state, action) => {
        const payload = JSON.parse(action.payload);
        state.type = PRODUCT_OBSERVATIONS_SUCCESS;
        state.loading = false;
        state.payload = payload;
      })
      .addCase(productObservations.rejected, state => {
        state.type = PRODUCT_OBSERVATIONS_FAILURE;
        state.loading = false;
        state.erro = true;
      });
  },
});
const { reducer } = productObservationsSlice;
export default reducer;
