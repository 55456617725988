import React, { Component } from 'react';
import { View, Image, Text } from 'react-native';
import { FormattedNumber, IntlProvider } from 'react-intl';
import Touchable from 'react-native-platform-touchable';
import { connect } from 'react-redux';

import OrdersModule from '../../../modules/OrdersModule';
import { setCurrentProduct } from '../../../store/productCurrent/slice';
import styles from './styles';
import Images from '@assets/Images';
import translate from '../../../locales';
import {
    ORDER_FETCH_REQUEST,
    ORDER_FETCH_SUCCESS,
} from '../../../../src/store/ordersFetch/orderFetchSlice';
import onPressHelper from '~/utils/onPressHelper';
import { calendarLanguage as language } from '~/locales';

import PropTypes from 'prop-types';

class PriceItemActive extends Component {
    state = {
        showPrice: false,
        loadPrice: false,
        showIconMoney: true,
        price: 0.0,
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        (() => {
            if (this.props.product.preco === nextProps.product.preco) {
                return;
            }
            this.setState({ price: nextProps.product.preco });
        })();
    }

    componentDidUpdate(prevProps) {
        (() => {
            if (this.props.currentProduct.preco === prevProps.currentProduct.preco) {
                return;
            }
            if (this.props.currentProduct.preco === this.state.price) {
                return;
            }
            if (this.props.currentProduct.chave === this.props.product.chave) {
                this.setState({ price: this.props.currentProduct.preco });
            }
        })();

        (async () => {
            if (this.props.sOrder.type === prevProps.sOrder.type) {
                return;
            }
            if (prevProps.sOrder.type !== ORDER_FETCH_REQUEST) {
                return;
            }
            if (this.props.sOrder.type !== ORDER_FETCH_SUCCESS) {
                return;
            }
            this.setState({ showPrice: false, loadPrice: false, price: 0.0 });
        })();

        (async () => {
            if (
                this.props.productsCleanPrices.payload ===
                prevProps.productsCleanPrices.payload
            ) {
                return;
            }
            this.setState({ showPrice: false, loadPrice: false });
        })();

        (async () => {
            if (this.props.isCombo !== prevProps.isCombo) {
                await this.loadItemActivie();
            }
        })();
    }

    getFinalPrice = () => {
        const { price } = this.state;
        const { product } = this.props;
        const { preco, precobase } = product;

        let finalPrice = -1;
        if (price && price > 0) {
            finalPrice = price;
        } else if (preco && preco > 0) {
            finalPrice = product.preco;
        } else if (precobase && precobase > 0) {
            finalPrice = product.precobase;
        }
        return finalPrice;
    };

    handleOnpress = async () => {
        const { loadPrice, showPrice, showIconMoney } = this.state;
        const { isCarrosel = false } = this.props;

        if (loadPrice) {
            this.setState({
                showPrice: !showPrice,
                loadPrice: false,
                showIconMoney: isCarrosel ? !showIconMoney : showIconMoney,
            });
        } else {
            await this.loadItemActivie();
            this.setState({
                showPrice: true,
                loadPrice: true,
                showIconMoney: isCarrosel ? !showIconMoney : showIconMoney,
            });
        }
    };

    handleOnPress = onPressHelper.debounce(this.handleOnpress, 200);

    loadItemActivie = async (updateCurrentProduct = true) => {
        const { product } = this.props;

        try {
            const productId = product.chave ? product.chave : product.id;
            let produtActive = await OrdersModule.getAndUpdateItemActive(
                productId,
                true,
            );
            produtActive = JSON.parse(produtActive);
            const { preco, precobase, desconto, acrescimo, estoque } = produtActive;
            const productCopy = {
                ...product,
                preco,
                precobase,
                desconto,
                acrescimo,
                estoque,
            };

            this.setState({ price: preco });
            if (updateCurrentProduct) {
                this.props.dispatch(setCurrentProduct(productCopy));
            }
        } catch (error) {
            const messages = JSON.parse(error.message);
            console.tron.log('PriceItemActive loadItemActivie', messages);
        }
    };

    render() {
        const { showPrice, showIconMoney, price } = this.state;
        const { product, componentStyles = {} } = this.props;
        const priceExists =
            (price && price > 0) ||
            (product.preco && product.preco > 0) ||
            (product.precobase && product.precobase > 0);
        const finalPrice = this.getFinalPrice();

        return (
            <View style={[styles.container, { ...componentStyles.container }]}>
                <Touchable
                    tabIndex={-1}
                    style={[styles.containerExpand, componentStyles.containerExpand]}
                    onPress={this.handleOnPress}>
                    <View
                        style={[
                            styles.containerImages,
                            showPrice ? {} : { borderRadius: 5 },
                        ]}>
                        {showIconMoney ? (
                            <Image
                                style={[styles.iconMoney, componentStyles.iconMoney]}
                                source={Images.iconCoinBig}
                            />
                        ) : null}
                        {showPrice ? (
                            <Image
                                style={[styles.iconExpand, componentStyles.iconExpand]}
                                source={Images.iconCollapseBack}
                            />
                        ) : (
                            <Image
                                style={[styles.iconExpand, componentStyles.iconExpand]}
                                source={Images.iconMoreThan}
                            />
                        )}
                    </View>
                </Touchable>
                {showPrice && (
                    <View style={[styles.containerPrice, componentStyles.containerPrice]}>
                        {priceExists ? (
                            <IntlProvider locale={`${language}`}>
                                <FormattedNumber
                                    style="currency"
                                    currency="BRL"
                                    value={finalPrice}
                                // style={[styles.textPrice, componentStyles.textPrice]}
                                />
                            </IntlProvider>
                        ) : (
                            <Text style={[styles.textPrice, componentStyles.textPrice]}>
                                {translate('priceNotAvailable')}
                            </Text>
                        )}
                    </View>
                )}
            </View>
        );
    }
}

PriceItemActive.propTypes = {
    product: PropTypes.object.isRequired,
    currentProduct: PropTypes.object.isRequired,
    productsCleanPrices: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    sOrder: PropTypes.object.isRequired,
    componentStyles: PropTypes.object,
    isCarrosel: PropTypes.bool,
    isCombo: PropTypes.bool,
};

function mapStateToProps(state) {
    return {
        currentProduct: state.productCurrent.payload,
        productsCleanPrices: state.productsCleanPrices,
        sOrder: state.orderFetch,
    };
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(
    PriceItemActive
  );
