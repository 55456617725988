import React from 'react';
import { Text, View, ScrollView } from 'react-native';

import MessageItem from './components/MessageItem';
import MessageItemQuestion from './components/MessageItemQuestion';
import styles from './styles';
import { TYPE_MESSAGE_QUESTION } from './typeMessageConst';
import translate from '../../locales';
import { Button } from '../../../components_base/src/components';
import Action from '../Activities/handleAction';
import executeActionOrOpenScreenMessage from '~/utils/messages';

import PropTypes from 'prop-types';

function Messages({
  messages,
  onPressExit,
  hasActionButton,
  onPressActionButton,
  actionButtonTitle,
  actionMessagesNotError,
}) {
  const onConfirm = ({ params: { confirm_button: confirmButton } }) => {
    if (confirmButton) {
      const {
        module,
        method,
        method_params: methodParams,
        type = 'lib',
      } = confirmButton;
      const callFunction =
        type === 'lib' ? Action.callLibMethod : Action.callInternalMethod;

      callFunction(
        (data = []) => {
          executeActionOrOpenScreenMessage(data, null, actionMessagesNotError);
        },
        err => {
          executeActionOrOpenScreenMessage(err);
        },
        module,
        method,
        ...Object.values(methodParams),
      );
    }
    onPressExit('yes');
  };

  const onRefuse = ({ params: { refuse_button: refuseButton } }) => {
    if (refuseButton) {
      const {
        module,
        method,
        method_params: methodParams,
        type = 'lib',
      } = refuseButton;
      const callFunction =
        type === 'lib' ? Action.callLibMethod : Action.callInternalMethod;

      callFunction(
        (data = []) => {
          executeActionOrOpenScreenMessage(data);
        },
        err => {
          executeActionOrOpenScreenMessage(err);
        },
        module,
        method,
        ...Object.values(methodParams),
      );
    }
    onPressExit();
  };

  const renderItem = ({ item }) => {
    const { tipo, mensagem, onPressCancel, onPressConfirm, params } = item;

    if (tipo === TYPE_MESSAGE_QUESTION) {
      return (
        <MessageItemQuestion
          text={mensagem}
          onPressCancel={() => {
            if (onPressCancel) {
              onPressCancel('no', params);
            }
            onRefuse(item);
          }}
          onPressConfirm={() => {
            if (onPressConfirm) {
              onPressConfirm('yes', params);
            }
            onConfirm(item);
          }}
        />
      );
    }

    return <MessageItem type={tipo} text={mensagem ? mensagem : item} />;
  };

  const handleExit = () => {
    onPressExit();
  };

  return (
    <View style={styles.modalOverlay}>
    <View style={styles.modalContainer}>
      <ScrollView>
        <View style={styles.wrapper}>
          <Text style={styles.title}>
            {translate('somePointsNeedsYourAtention')}
          </Text>
        </View>
        <View style={styles.messages}>
          {messages
            ? messages.map(message => renderItem({ item: message }))
            : null}
        </View>
      </ScrollView>

      <View style={styles.buttonsView}>
        {hasActionButton ? (
          <Button
            style={styles.bottomButtons}
            outline
            flat
            onPress={onPressActionButton}
            title={actionButtonTitle}
          />
        ) : null}
        <Button
          style={styles.bottomButtons}
          title={translate('Close')}
          containerStyle={{
            borderRadius: 0,
          }}
          onPress={handleExit}
        />
      </View>
    </View>
  </View>
  );
}

Messages.propTypes = {
  messages: PropTypes.array.isRequired,
  onPressExit: PropTypes.func.isRequired,
  hasActionButton: PropTypes.bool.isRequired,
  onPressActionButton: PropTypes.func,
  actionButtonTitle: PropTypes.string,
  actionMessagesNotError: PropTypes.func,
};

export default Messages;
