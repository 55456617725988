import React from 'react';
import _ from 'lodash';
import Toast from 'react-native-root-toast';

import { connect } from 'react-redux';

import Choice from './Choice';
import GPSManager from '../../../controllers/GPS/GPSManager';
import GpsHelper from '../../../utils/GpsHelper';
import { KeyboardAvoidingView, ScrollView, View } from 'react-native';
import Gps from '../../../../components_base/src/components/Gps/Gps';
import Button from '../../../components/Button';
import style from '../../../common/style';
import {
  GENERATE_DATA_FINALLY,
  GENERATE_DATA_STARTED,
  SYNC_FINALLY,
  SYNC_PROCESS_SALES,
  SYNC_UPDATE_STATUS,
} from '../../../constants/Sync';
import {
  syncUpdateData,
  syncUpdateScreen,
} from '../../../store/syncFetch/slice';
import ClientModule from './../../../modules/ClientsModule';
import translate from '../../../locales';
import AddressAltCache from '../../../cache/AddressAltCache';
import { Navigation } from 'react-native-navigation';
import {
  saveJustify,
  JUSTIFICATION_SAVE_FAILURE,
  JUSTIFICATION_SAVE_SUCCESS,
} from '~/store/justification/slice';
import NavigationHelper from '~/screens/NavigationHelper';
import Snackbar from 'react-native-snackbar';
import TextInputScanner from '../components/TextInputScanner';

type Props = {
  item: any,
  event: any,
  justification: any,
  syncFetch: any,
  dateSelected: any,
  navigator: {
    pop: () => void,
  },
  dispatch: any,
  saveAnswerActivity: (
    payloadAnswer: Object<any>,
    successSaveAnswer: Function<any>,
    failureSaveAnswer: Function<any>,
  ) => void,
};

const TYPE_CHOICE = {
  JUSTIFICATION_NOT_SALE: 'JUSTIFICATION_NOT_SALE',
  JUSTIFICATION_NOT_VISIT: 'JUSTIFICATION_NOT_VISIT',
  UPDATE_STATUS_PROSPECT: 'UPDATE_STATUS_PROSPECT',
};

class ChoiceContainer extends React.Component<Props, void> {
  constructor(props) {
    super(props);

    this.location = '';
    this.action = '';
    this.addressAltSelected = false;
    this.state = {
      itemSelected: {},
      itens: [],
      message: '',
      dateOpen: '',
      loading: false,
      location: {
        accuracy: Infinity,
        altitude: null,
        heading: null,
        latitude: null,
        longitude: null,
        speed: 0.0,
        timestamp: 0,
      },
      dateTime: null,
    };

    this.gpsManager = new GPSManager();
  }

  UNSAFE_componentWillMount() {
    const itens = this.getData();

    let itemSelected = { label: '', value: '' };
    if (itens && itens.length > 0) {
      [itemSelected] = [itens[0]];
    }

    const answer = _.get(this.props.route.params.pushObject, 'item.answer');
    const dateOpen = _.get(answer, 'start_date') || new Date().toISOString();
    const dateTime = _.get(answer, 'payload.dateTime', null);
    const message = _.get(answer, 'payload.content') || '';
    itemSelected = _.get(answer, 'payload.selected') || itemSelected;

    this.setState({
      dateTime,
      itens,
      itemSelected,
      dateOpen,
      message,
    });
  }

  UNSAFE_componentWillReceiveProps({ justification, syncFetch }) {
    (() => {
      if (justification.type === this.props.justification.type) {
        return;
      }
      if (justification.type !== JUSTIFICATION_SAVE_SUCCESS) {
        return;
      }
      this.saveAnswer();
    })();

    (() => {
      if (justification.type === this.props.justification.type) {
        return;
      }
      if (justification.type !== JUSTIFICATION_SAVE_FAILURE) {
        return;
      }

      Snackbar.show({
        text: `${justification?.payload[0]?.mensagem ??
          'Erro ao salvar justificativa'}`,
      });
      this.setState({ loading: false });
    })();

    (() => {
      if (syncFetch.type === this.props.syncFetch.type) {
        return;
      }
      if (
        syncFetch.payload.statusSync !== SYNC_PROCESS_SALES &&
        syncFetch.payload.screen === 'activities.Choice'
      ) {
        this.props.dispatch(syncUpdateScreen(''));
        const { item, event } = this.props.route.params.pushObject;
        const { itemSelected } = this.state;
        const setupJson = JSON.parse(item.setup);

        if (this.action === 'JUSTIFICATION') {
          this.props.dispatch(
            saveJustify({
              clientKey: event.pkey,
              type: setupJson.type,
              value: `${itemSelected.value}`,
              text: itemSelected.label,
              location: this.location,
            }),
          );
        } else if (this.action === 'UPDATE_STATUS_PROSPECT') {
          this.updateStatusProspectLib();
        }
      }
    })();
  }

  async componentDidMount() {
    if (!_.isObject(this.addressAltSelected)) {
      const event = _.get(this.props.route.params.pushObject.pushObject, 'event', {});
      const { pkey: pkeyClient } = event;
      const addressAlt = await AddressAltCache.getAddressAlt(pkeyClient);
      this.addressAltSelected = addressAlt;
    }
  }

  navigationButtonPressed(event) {
    switch (event.buttonId) {
      case 'backPress':
        NavigationHelper.pop(this.props.componentId);
        break;
      default:
        break;
    }
  }

  onChangeChoice = itemSelected => this.setState({ itemSelected });
  onchangeMessage = message => this.setState({ message });

  onSaveChoice = () => {
    this.setState({ loading: true });
    const { item, event } = this.props.route.params.pushObject;
    const setup = JSON.parse(item.setup);
    const { location } = this.state;

    const message = GpsHelper.validateRulesGPSClient(setup, event, location);
    if (!_.isEmpty(message)) {
      Snackbar.show({ text: `${message}` });
      this.setState({ loading: false });
      return;
    }

    const { type } = setup;
    if (type) {
      if (
        type.toLowerCase() ===
          TYPE_CHOICE.JUSTIFICATION_NOT_SALE.toLowerCase() ||
        type.toLowerCase() === TYPE_CHOICE.JUSTIFICATION_NOT_VISIT.toLowerCase()
      ) {
        this.saveJustify(location);
      } else if (
        type.toLowerCase() === TYPE_CHOICE.UPDATE_STATUS_PROSPECT.toLowerCase()
      ) {
        this.updateStatusProspect();
      } else {
        this.saveAnswer();
      }
    } else {
      this.saveAnswer();
    }
  };

  getData() {
    const { item } = this.props.route.params.pushObject;
    const dataJson = JSON.parse(item.setup);
    return dataJson.items;
  }

  onLocationChangeFromMap = location => {
    if (location) {
      this.setState({ location });
    }
  };

  saveJustify(location) {
    const { syncFetch } = this.props;
    const { item, event } = this.props.route.params.pushObject;

    const { statusSync } = syncFetch?.payload;

    if (statusSync !== SYNC_PROCESS_SALES) {
      const { itemSelected } = this.state;
      const setupJson = JSON.parse(item.setup);

      this.props.dispatch(
        saveJustify({
          clientKey: event?.pkey,
          type: setupJson.type,
          value: `${itemSelected.value}`,
          text: itemSelected.label,
          location,
        }),
      );
    } else {
      this.location = location;
      this.action = 'JUSTIFICATION';

      this.props.dispatch(syncUpdateScreen('activities.Choice'));
    }
  }

  updateStatusProspect() {
    const { syncFetch } = this.props.route.params.pushObject;
    const { statusSync } = syncFetch.payload;

    if (statusSync !== SYNC_PROCESS_SALES) {
      this.updateStatusProspectLib();
    } else {
      this.action = 'UPDATE_STATUS_PROSPECT';
      this.props.dispatch(syncUpdateScreen('activities.Choice'));
    }
  }

  updateStatusProspectLib() {
    new Promise((resolve, reject) => {
      ClientModule.updateStatusProspect(
        this.props.event.pkey.toString(),
        this.state.itemSelected.value.toString(),
        resolve,
        reject,
      );
    })
      .then(() => {
        this.saveAnswer();
      })
      .catch(error => {
        const jsonEror = JSON.parse(error);
        Snackbar.show({ text: `${jsonEror[0]?.mensagem}` });
        this.setState({ loading: false });
      });
  }

  saveAnswer() {
    const { message, itemSelected, dateOpen } = this.state;

    const { item, dateSelected } = this.props.route.params.pushObject;
    const { location } = this.state;
    const { latitude, longitude } = location;
    const setup = JSON.parse(item.setup || '{}');

    const payloadAnswer = {
      dateSelected,
      dateOpen,
      latitude,
      longitude,
      payload: {
        content: message,
        selected: itemSelected,
        location,
      },
      taskId: item.id,
    };
    if (
      setup.extra_components &&
      setup.extra_components.some(({ id }) => id === 'date')
    ) {
      payloadAnswer.payload = {
        ...payloadAnswer.payload,
        date_time: this.state.dateTime,
      };
    }
    this.props.route.params.pushObject.saveAnswerActivity(
      payloadAnswer,
      this.successSaveAnswer,
      this.failureSaveAnswer,
    );
  }

  showLocation() {
    const { item } = this.props.route.params.pushObject;
    const dataJson = JSON.parse(item.setup);
    return !dataJson.disabled_location;
  }

  onConfirmDateSelected = dateTime => {
    this.setState({ dateTime });
  };

  successSaveAnswer = () => this.setState({ loading: false });
  failureSaveAnswer = () => this.setState({ loading: false });

  getGpsPlaces = () => {
    const gpsPlaces = this.addressAltSelected || this.props.route.params.pushObject.event;
    return [GpsHelper.parseGpsEvent(gpsPlaces)];
  };

  render() {
    const { itemSelected, itens, message, loading, location } = this.state;
    const gpsPlaces = this.getGpsPlaces();
    const { pkey: pkeyClient } = _.get(this.props.route.params.pushObject, 'event', {});
    const setup = JSON.parse(this.props.route.params.pushObject.setup || '{}');

    return (
      <View style={style.container}>
        <ScrollView
          keyboardShouldPersistTaps="always"
          style={{ marginBottom: 70 }}>
          <KeyboardAvoidingView behavior="position" keyboardVerticalOffset={10}>
            <View style={{ flex: 1 }}>
              {this.showLocation() && (
                <Gps
                  places={gpsPlaces}
                  pkeyClient={pkeyClient}
                  location={location}
                  onLocationChange={this.onLocationChangeFromMap}
                  showPermitionLocation
                  useViewExpandable
                />
              )}
              <Choice
                data={itens}
                itemSelected={itemSelected}
                loading={loading}
                onChangeChoice={this.onChangeChoice}
                extraComponents={setup.extra_components}
                dateTime={this.state.dateTime}
                onConfirmDateSelected={this.onConfirmDateSelected}
                inputConfig={setup.input_config}
              />
            </View>
            <TextInputScanner
              value={message}
              onChangeText={this.onchangeMessage}
              config={setup.input_config}
            />
          </KeyboardAvoidingView>
        </ScrollView>
        <View
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#fff',
          }}>
          <Button
            style={{ margin: 16 }}
            onPress={this.onSaveChoice}
            title={loading ? `${translate('saving')}` : `${translate('save')}`}
            disabled={loading}
          />
        </View>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return {
    justification: state.saveJustify,
    syncFetch: state.syncFetch,
  };
}

export default connect(mapStateToProps)(ChoiceContainer);