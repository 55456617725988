import { StyleSheet } from 'react-native';
import { color } from '~/common';

const styles = StyleSheet.create({
  subtractContainer: {
    borderWidth: 0.4,
    padding: 16,
    marginVertical: 12,
    borderRadius: 4,
  },
  subtractHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subtractText: { fontSize: 16, color: '#000' },
  scrollView: { padding: 16 },
  dateTime: {
    backgroundColor: color.grayAlmostWhite,
    borderBottomWidth: 0.1,
    marginVertical: 12,
  },
  combo: {
    backgroundColor: color.grayAlmostWhite,
    marginVertical: 12,
    borderBottomWidth: 0.1,
  },
  subtractButton: {
    alignSelf: 'flex-end',
    marginVertical: 12,
    borderColor: color.primaryColor,
    borderWidth: 1,
    paddingVertical: 6,
    borderRadius: 4,
    width: 185,
  },
  subtractButtonView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  subtractButtonText: {
    color: color.primaryColor,
    fontSize: 16,
    fontWeight: '400',
  },
  buttonCamera: { alignSelf: 'flex-start', marginVertical: 6 },
  buttonCameraView: {
    flexDirection: 'row',
    backgroundColor: color.grayAlmostWhite,
    alignItems: 'center',
    justifyContent: 'space-around',
    borderWidth: 1,
    borderStyle: 'dashed',
    padding: 14,
    width: 156,
  },
  buttonCameraText: { fontSize: 20, color: '#000', fontWeight: 'bold' },
  finishButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginVertical: 12,
    marginBottom: 30,
  },
  buttonCancel: {
    borderRadius: 4,
    borderWidth: 0,
  },
  buttonConfirm: {
    backgroundColor: color.primaryColor,
    borderRadius: 4,
  },
  listPhotosContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  photoItem: {
    borderRadius: 4,
    height: 64,
    width: 64,
  },
  photoItemIcon: {
    bottom: 45,
    borderRadius: 4,
    left: 45,
    position: 'absolute',
  },
});

export default styles;
