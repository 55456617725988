import Geolocation from 'react-native-geolocation-service';
import OpenAppSettingsHelper from '../../../src/utils/OpenAppSettingsHelper';

export default class GPSDevice {
  constructor() {
    this.bestLastLocation = { timestamp: 0, accuracy: Infinity };
    this.gpsWatchId = 0;
    this.wifiWatchId = 0;
  }

  locationChanged(position, source) {
    if (position.timestamp > this.bestLastLocation.timestamp) {
      this.bestLastLocation = position;
      if (this.onLocationChanged) {
        this.onLocationChanged(position);
      }
    }
  }

  locationError(error, source) {
    let message = ''; // Verifique se você está com internet ou com o GPS ligado.
    switch (error.code) {
      case 1:
        message = 'Por favor, ative a permissão de localização.';
        break;
      case 5:
        message = 'GPS desativado. Por favor ative-o.';
        break;
      default:
        break;
    }
    if (this.onLocationError) {
      this.onLocationError(error.code, message);
    }
  }

  getCurrentPosition() {
    const configGps = {
      enableHighAccuracy: true,
      maximumAge: 0,
      timeout: 0,
      distanceFilter: 0,
      useSignificantChanges: false,
    };
    return new Promise((resolve, reject) =>
      Geolocation.getCurrentPosition(
        position => resolve(position),
        error => {
          let message = ''; // Verifique se você está com internet ou com o GPS ligado.
          switch (error.code) {
            case 1:
              message = 'Por favor, ative a permissão de localização.';
              OpenAppSettingsHelper('location');
              break;
            case 4:
              message = 'Verifique a versão do seu Google Suite Apps.';
              break;
            case 5:
              message = 'GPS desativado. Por favor ative-o.';
              break;
            default:
              break;
          }

          reject(message);
        },
        configGps,
      ),
    );
  }

  watchPosition(onLocationChanged, onLocationError) {
    this.onLocationChanged = onLocationChanged;
    this.onLocationError = onLocationError;

    const configGps = {
      enableHighAccuracy: true,
      maximumAge: 0,
      timeout: 0,
      distanceFilter: 0,
      useSignificantChanges: false,
    };
    this.gpsWatchId = Geolocation.watchPosition(
      position => this.locationChanged(position, 'gps'),
      error => this.locationError(error, 'gps'),
      configGps,
    );

    const configWifi = {
      enableHighAccuracy: false,
      maximumAge: 0,
      timeout: 0,
      distanceFilter: 0,
      useSignificantChanges: false,
    };
    this.wifiWatchId = Geolocation.watchPosition(
      position => this.locationChanged(position, 'wifi'),
      error => this.locationError(error, 'wifi'),
      configWifi,
    );
  }

  clearWatchers() {
    Geolocation.clearWatch(this.gpsWatchId);
    Geolocation.clearWatch(this.wifiWatchId);
  }

  stopObserving() {
    Geolocation.stopObserving();
  }
}
