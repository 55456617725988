import React, { useEffect, useState, useCallback } from 'react';
import Expenses from './Expenses';
import {
  SCREEN_EXPENSES_DETAIL,
  SCREEN_EXPENSES_RESUME,
} from '../screens';
import { listExpenses } from './api';
import moment from 'moment';
import PropTypes from 'prop-types';
import translate from '~/locales';
import onPressHelper from '~/utils/onPressHelper';
import { Image, TouchableOpacity } from 'react-native';
import Images from '@assets/Images';
import { createNotifyError } from '~/components/Web/ToastNotify';

const ExpensesContainer = ({ navigation, componentId }) => {
  const [loading, setLoading] = useState(true);
  const [expenses, setExpenses] = useState([]);
  const [dateSelected, setDateSelected] = useState(new Date());

  useEffect(() => {
    setButtons();
    onChangeDate(new Date());
  }, []);

  const setButtons = () => {
    navigation.setOptions({
      headerLeft: () => (
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <Image source={Images.iconBack} style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }} />
        </TouchableOpacity>
      ),
      headerRight: () => (
        <TouchableOpacity onPress={() => {
          goToResume();
        }}
        >
            <Image
                source={Images.iconChart}
                style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 10 }}
            />
        </TouchableOpacity>
    )
    });
  };

  const getDates = date => {
    return {
      start: moment(date)
        .startOf('month')
        .format('YYYY-MM-DD'),
      end: moment(date)
        .endOf('month')
        .format('YYYY-MM-DD'),
    };
  };

  const onChangeDate = date => {
    setLoading(true);
    setDateSelected(date);
    getExpenses(getDates(date));
  };

  const getExpenses = useCallback(async date => {
    try {
      const expensesList = (await listExpenses(date)) || {};
      setExpenses(expensesList);
    } catch (err) {
      createNotifyError(`${translate('errorListExpenses')}`);
    } finally {
      setLoading(false);
    }
  }, []);

  const componentDidAppear = () => {
    onChangeDate(new Date());
  };

  const goToResume = onPressHelper.debounce(() => {
    navigation.navigate('ExpenseResume', {
      expenses: expenses?.movements || [],
      onPressExpense,
      navigation: navigation,
    });
  });

  const navigationButtonPressed = ({ buttonId }) => {
    if (buttonId === 'resume') {
      goToResume();
    }
  };

  const onPressExpense = onPressHelper.debounce(expense => {
    navigation.navigate('ExpenseDetail', {
      navigation: navigation,
      expense,
    });
  });

  const goToForm = onPressHelper.debounce(() => {
    navigation.navigate('ExpensesForm', {
      listScreenId: componentId,
      navigation: navigation,
    });
  });

  return (
    <Expenses
      onPressExpense={onPressExpense}
      goToForm={goToForm}
      onChangeDate={onChangeDate}
      expenses={expenses}
      loading={loading}
      dateSelected={dateSelected}
    />
  );
};

ExpensesContainer.propTypes = {
  componentId: PropTypes.string,
};

export default ExpensesContainer;
