import React from "react";
import { View, Text, StyleSheet } from "react-native";

import { color } from "~/common";

const Tip = () => {
    return (
        <View style={styles.container}>
            <Text style={styles.text}>
                Clique no <Text style={styles.textEmphasis}>Botão Azul</Text> ao lado para ver os atalhos disponíveis
            </Text>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        fontFamily: 'Quicksand-Regular',
        fontSize: 20,
        color: '#959595',
    },
    textEmphasis: {
        fontFamily: 'Quicksand-Regular',
        color: color.neutral700, 
        fontWeight: 'bold',
    },
});

export default Tip;
