import iconCategory from './img/category/categoryFilled.png';
import iconDrinks from './img/category/barFilled.png';
import iconPet from './img/category/petCommands.png';
import iconFood from './img/category/food.png';
import iconHousekeeping from './img/category/housekeeping.png';
import iconMaintenance from './img/category/maintenance.png';

const CategoryImages = {
  iconCategory: iconCategory,
  iconDrinks: iconDrinks,
  iconPet: iconPet,
  iconFood: iconFood,
  iconHousekeeping: iconHousekeeping,
  iconMaintenance: iconMaintenance,
};

export default CategoryImages;
