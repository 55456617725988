/* @flow */

import React, { Component } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { launchCamera, launchImageLibrary} from 'react-native-image-picker';
import _ from 'lodash';
import RNFS from 'react-native-fs';

import Profile from './Profile';
import { SCREEN_PROFILE } from '../screens';
import CacheAccount from '../../controllers/Accounts/Cache/CacheAccount';
import ServicesModule from '../../modules/ServicesModule';
import CacheImage from '../../utils/CachePhoto';
import { formatDateHourDefault } from '../../utils/DateHelper';
import { getVersionApp } from '../../utils/deviceAppInfo';
import {
  PHOTO_HEIGTH,
  PHOTO_QUALITY,
  PHOTO_WIDTH,
} from '../../constants/Photo';
import { connect } from 'react-redux';
import { tasksSync } from '../../TaskSync';
import translate from '../../locales';
import CacheDataController from '../../utils/CacheDataController';
import { LAST_UPLOAD_TIME, SYNC_FINALLY } from '../../constants/Sync';
import moment from 'moment';
import AccountsModule from '~/modules/AccountsModule';

import { Navigation } from 'react-native-navigation';

import AnswerController from '~/controllers/AnswerController';
import onPressHelper from '~/utils/onPressHelper';
import { currentScreenVisible } from '~/store/currentScreenVisible/slice';

import PropTypes from 'prop-types';
import { createNotifyInfo } from '~/components/Web/ToastNotify';
import { Image, TouchableOpacity, View } from 'react-native';
import Images from '@assets/Images';
import { showAlert } from '~/components/Web/Alert';

const TITLE_ALERT = `${translate('exitPoliEquipes')}`;
const MESSAGE_EXIT = `${translate('lostChangesOnExit')}. ${translate(
  'you_really_want_leave',
)}`;

const MESSAGE_ERROR_EXIT = `${translate('errorExitPoliEquipes')}`;

class ProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      source: null,
      visibleInfo: true,
      downloadBase: false,
      progress: 0,
      text: '',
      title: `${translate('syncInfo')}`,
      indeterminate: false,
      showUploadPhotoCheckBox: false,
      forceSync: false,
      completeSyncText: 0,
    };
    this.finishForceSyncId;
  }

  componentDidMount() {
    this.setIconsButton();
    this.startProfile();
    this.props.dispatch(
      currentScreenVisible({
        screen: this.props.componentId,
        screenName: SCREEN_PROFILE.name,
      }),
    );
  }

   onSyncApp = async () => {
    this.setState({ forceSync: true, completeSyncText: 0 }, async () => {
      if (this.props.syncFetch.payload.statusSync === SYNC_FINALLY) {
        this.setState({ downloadBase: true });
        await tasksSync(false);
        const data = await this.getData();
        this.setState({ downloadBase: false, data });
      } else {
        createNotifyInfo(`${translate('syncInBackground')}`);
      }
    });
  }

  toggleForceSync = () => {
    const { showProgress } = this.props.syncFetch.payload;
    const { downloadBase, completeSyncText } = this.state;

    const syncComplete = !showProgress && !downloadBase;

    if (syncComplete && completeSyncText !== 100) {
      this.setState({ completeSyncText: 100 });
      return;
    }

    if (syncComplete && completeSyncText === 100) {
      this.setState({ forceSync: false, completeSyncText: 0 }, () => {
        clearInterval(this.finishForceSyncId);
      });
    }
  };

  finishForceSync = () => {
    this.finishForceSyncId = setInterval(this.toggleForceSync, 2000);
  };

  onPressOnSyncApp = onPressHelper.debounce(this.onSyncApp);

  onClose = async option => {
    showAlert(TITLE_ALERT, MESSAGE_EXIT, this.confirmeClose);
  };

  onPressClose = onPressHelper.debounce(() => this.onClose('close'));

  onVisibleInfo = () => {
    this.setState({ visibleInfo: !this.state.visibleInfo });
  };

  onSelectImage = () => {
    const options = {
      title: `${translate('image')}`,
      cancelButtonTitle: `${translate('cancel')}`,
      takePhotoButtonTitle: `${translate('takePicture')}`,
      chooseFromLibraryButtonTitle: `${translate('selectFromGallery')}`,
      mediaType: 'photo',
      quality: PHOTO_QUALITY,
      maxWidth: PHOTO_WIDTH,
      maxHeight: PHOTO_HEIGTH,
      noData: true,
      storageOptions: {
        skipBackup: true,
        path: 'images',
      },
    };

    showImagePicker(options, response => {
      if (response.didCancel) {
        console.tron.log('User cancelled image picker');
      } else if (response.error) {
        console.tron.log('ImagePicker Error: ', response.error);
      } else if (response.customButton) {
        console.tron.log('User tapped custom button: ', response.customButton);
      } else {
        const source = {
          uri: response.uri,
          isStatic: true,
          fileName: response.fileName,
          filePath: response.path,
        };

        CacheImage.setImageProfile(source);
        this.setState({ source });
      }
    });
  };

  navigationButtonPressed({ buttonId }) {
    if (buttonId === 'leave') {
      this.onClose('close');
    }
    if (buttonId === 'sync') {
      this.onPressOnSyncApp();
    }
    if (buttonId === 'bottomTabSelected') {
      this.setCurrentModule(SCREEN_PROFILE.name.toUpperCase());
    }
  }

  setCurrentModule(currentModule) {
    return new Promise((resolve, reject) => {
      AccountsModule.setCurrentModule(currentModule, resolve, reject);
    });
  }

  obtainUserDataLib = () =>
    new Promise((res, rej) => {
      AccountsModule.obtainUserData(res, rej);
    });

  handleBackPress = () => {
    Navigation.mergeOptions(this.props.componentId, {
      bottomTabs: {
        currentTabIndex: 0,
      },
    });
    return true;
  };

  async startProfile() {
    const showUploadPhotoCheckBox = await this.showUploadPhotoCheckBox();
    let source = await CacheImage.getImageProfile();

    if (source) {
      source = (await RNFS.exists(source.filePath)) ? source : null;
    }

    const data = await this.getData();
    this.setState({ source, data, showUploadPhotoCheckBox });
  }

  onHideProgress = () => {
    this.setState({ downloadBase: false });
  };

  async getData() {
    const userParamsData = await this.obtainUserDataLib();
    const userParamsDataParse = JSON.parse(userParamsData);
    const firstName = _.get(userParamsDataParse, 'rca_nomeusuario', '');
    const { configLib } = this.props;
    const cache = new CacheAccount();
    const dataAccount = await cache.getDataAccount();
    const newDataAccount = {
      ...dataAccount,
      user: {
        ...dataAccount.user,
        first_name: firstName,
      },
    };

    const lastUploadCache =
      (await CacheDataController.getData(LAST_UPLOAD_TIME)) || {};
    const lastUpdateDate = moment(
      configLib.ultima_sincronizacao,
      'DD/MM/YYYY HH:mm:ss',
    ).format('lll');
    const data = [];

    if (!_.isEmpty(newDataAccount)) {
      const { user } = newDataAccount;
      const { username } = user || newDataAccount;
      const first_name = _.get(user, 'first_name', '');
      const { name } = user.company;
      const version = getVersionApp();

      data.push(
        { label: `${translate('name')}`, value: first_name },
        { label: `${translate('login')}`, value: username },
        { label: `${translate('companyName')}`, value: name },
        { label: `${translate('lastDataUpdate')}`, value: lastUpdateDate },
        {
          label: `${translate('lastDataUpload')}`,
          value: lastUploadCache.dateTime,
        },
        { label: `${translate('version')}`, value: version },
      );
    }

    return data;
  }

  showUploadPhotoCheckBox = async () => {
    let showUploadPhotoCheckBox;
    try {
      const answerController = new AnswerController();
      const photosToSync = await answerController.getPhotoToSync();
      if (photosToSync === undefined || photosToSync?.length === undefined) {
        throw new Error('Error to get photos to sync');
      }
      showUploadPhotoCheckBox = photosToSync.length > 0;
    } catch (e) {
      showUploadPhotoCheckBox = false;
    }
    return showUploadPhotoCheckBox;
  };

  async confirmeClose(egg = false) {
    try {
      await AsyncStorage.clear();
      await ServicesModule.stopSyncService();
      window.location.reload(false);
    } catch (error) {
      alert(MESSAGE_ERROR_EXIT);
    }
  }

  logoutLib = () =>
    new Promise((res, rej) => {
      AccountsModule.logout(res, rej);
    });

  handleEasterEgg = source => {
    switch (source) {
      case 'logout':
        this.confirmeClose(true);
        break;
      default:
        break;
    }
  };

  async confirmeRemoveBaseAndClose() {
    await this.confirmeClose();
  }

  formartLastUpdateApps(lastUpdate) {
    const dateHour = [];

    lastUpdate.forEach(last => {
      if (last) {
        dateHour.push(formatDateHourDefault(last));
      }
    });

    return dateHour.join(', ');
  }

  setIconsButton() {
    this.props.navigation.setOptions({
      headerRight: () => (
        <View style={{ flexDirection: 'row' }}>
          <View>
            <TouchableOpacity onPress={this.onPressOnSyncApp}>
              <Image
                source={Images.iconSync}
                style={{
                  tintColor: '#f0f0f0', height: 20, width: 20, right: 20,
                }}
              />
            </TouchableOpacity>
          </View>
        </View>
      ),
    });
  }

  render() {
    const {
      visibleInfo,
      source,
      data,
      downloadBase,
      title,
      indeterminate,
      showAlertPhoto,
      showUploadPhotoCheckBox,
      forceSync,
      completeSyncText,
    } = this.state;
    const { showProgress, progressData } = this.props.syncFetch.payload;
    const { configLib } = this.props;
    const contacts = _.get(configLib, 'configuracoes.contacts', []);
    return (
      <Profile
        showUploadPhotoCheckBox={showUploadPhotoCheckBox}
        data={data}
        source={source}
        visibleInfo={visibleInfo}
        onPressClose={this.onPressClose}
        onVisibleInfo={this.onVisibleInfo}
        onSelectImage={this.onSelectImage}
        onHideProgress={this.onHideProgress}
        downloadBase={downloadBase}
        showProgress={showProgress}
        progress={progressData.progress}
        textProgress={progressData.text}
        title={title}
        indeterminate={indeterminate}
        showAlertPhoto={showAlertPhoto}
        handleEasterEgg={this.handleEasterEgg}
        contactUs={contacts}
        //Force Sync Context
        forceSync={forceSync}
        finishForceSync={this.finishForceSync}
        completeSyncText={completeSyncText}
      />
    );
  }
}

ProfileContainer.propTypes = {
  syncFetch: PropTypes.object,
  configLib: PropTypes.object,
  photosAnswers: PropTypes.array,
  dispatch: PropTypes.func,
  navigator: PropTypes.object,
  navigation: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    configLib: state.configLibFetch.payload,
    syncFetch: state.syncFetch,
  };
}

export default connect(mapStateToProps)(ProfileContainer);
