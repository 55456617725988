import { createSlice } from '@reduxjs/toolkit';

export const SET_AVAILABLE_UPDATE = 'SET_AVAILABLE_UPDATE';

const initialState = {
  type: '',
  hasAvailableUpdate: false,
  payload: {},
};

const versionUpdate = createSlice({
  name: 'versionUpdate',
  initialState,
  reducers: {
    setAvailableUpdate: (state, action) => {
      state.type = SET_AVAILABLE_UPDATE;
      state.payload = action.payload;
      state.hasAvailableUpdate = true;
    },
  },
  extraReducers: builder => {},
});

const { reducer, actions } = versionUpdate;

export const { setAvailableUpdate } = actions;

export default reducer;
