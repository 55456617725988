import * as Schema from '../Schema.native';
import SQLiteHelper from './../SQLiteHelper';

export default class Repository {
  constructor(tableName) {
    this.tableName = tableName;
    this.db = SQLiteHelper.db;
    if (!this.db) {
      throw new Error('Database not initialized');
    }
  }

  async get() {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction(this.tableName, 'readonly');
      const store = transaction.objectStore(this.tableName);
      const request = store.openCursor();
      const results = [];
  
      request.onsuccess = function() {
        let cursor = request.result;
        if (cursor) {
          let key = cursor.key; 
          let value = cursor.value;
          results.push({ key, value });
          cursor.continue();
        } else {
          resolve(results.length > 0 ? results : null);
        }
      };
  
      request.onerror = () => {
        reject(request.error);
      };
    });
  }

  async getById(taskId) {
    try {
      const transaction = this.db.transaction(this.tableName, 'readonly');
      const store = transaction.objectStore(this.tableName);
      const task = await new Promise((resolve, reject) => {
        const request = store.get(taskId);
        request.onsuccess = () => {
          resolve(request.result);
        };
        request.onerror = () => {
          reject(request.error);
        };
      });
      return task;
    } catch (error) {
      throw error;
    }
  }

  getDefaultProjection(tableName, withAlias) {
    return this.getProjectionArray(tableName, withAlias).join(',');
  }

  getProjectionArray(tableName, withAlias) {
    const table = Schema.Tables[tableName];
    const projection = [];
    for (const key in table) {
      projection.push(withAlias ? `${key} as ${tableName}_${key}` : key);
    }
    return projection;
  }

  getStringValuesToInsert(obj, tableName, withAlias) {
    const projectionArray = this.getProjectionArray(tableName, withAlias);
    const values = [];
    projectionArray.forEach(field => {
      switch (Schema.Tables[tableName][field].type) {
        case Schema.SupportedTypes.TEXT:
          values.push(`"${obj[field]}"`);
          break;
        case Schema.SupportedTypes.BOOLEAN:
          values.push(obj[field] === true ? 1 : 0);
          break;
        case Schema.SupportedTypes.DATETIME:
          values.push(`"${obj[field]}"`);
          break;
        default:
          values.push(obj[field]);
          break;
      }
    });
    return values.join(', ');
  }

  getValuesFromDatabase(obj) {
    const finalObj = {};
    Object.keys(obj).forEach(field => {
      switch (Schema.Tables[this.tableName][field].type) {
        case Schema.SupportedTypes.JSON:
          finalObj[field] = JSON.parse(obj[field]);
          break;
        case Schema.SupportedTypes.BOOLEAN:
          finalObj[field] = obj[field] === 1;
          break;
        default:
          finalObj[field] = obj[field];
          break;
      }
    });
    return finalObj;
  }

  async getIdsToReserve() {
    try {
      const transaction = this.db.transaction(this.tableName, 'readonly');
      const store = transaction.objectStore(this.tableName);
      const ids = await new Promise((resolve, reject) => {
        const request = store.index('sync_status').getAllKeys('pending_to_reserve');
        request.onsuccess = () => {
          resolve(request.result);
        };
        request.onerror = () => {
          reject(request.error);
        };
      });
      return ids;
    } catch (error) {
      throw error;
    }
  }

  async getAllToSync() {
    try {
      const transaction = this.db.transaction(this.tableName, 'readonly');
      const store = transaction.objectStore(this.tableName);
      const result = await new Promise((resolve, reject) => {
        const request = store.index('sync_status').getAll(['pending_to_sync', 'error']);
        request.onsuccess = () => {
          resolve(request.result.map(item => this.getValuesFromDatabase(item)));
        };
        request.onerror = () => {
          reject(request.error);
        };
      });
      return result;
    } catch (error) {
      throw error;
    }
  }

  async getAll() {
    try {
      const transaction = this.db.transaction(this.tableName, 'readonly');
      const store = transaction.objectStore(this.tableName);
      const objects = await new Promise((resolve, reject) => {
        const request = store.getAll();
        request.onsuccess = () => {
          resolve(request.result);
        };
        request.onerror = () => {
          reject(request.error);
        };
      });
      return objects;
    } catch (error) {
      throw error;
    }
  }

  async updateTempIdToReservedId(tempId, reservedId) {
    try {
      const transaction = this.db.transaction(this.tableName, 'readwrite');
      const store = transaction.objectStore(this.tableName);
      await new Promise((resolve, reject) => {
        const request = store.get(tempId);
        request.onsuccess = () => {
          const item = request.result;
          if (item) {
            item.id = reservedId;
            store.put(item).onsuccess = () => resolve();
            store.put(item).onerror = () => reject(request.error);
          } else {
            reject(new Error('Item not found'));
          }
        };
        request.onerror = () => {
          reject(request.error);
        };
      });
    } catch (error) {
      throw error;
    }
  }

  async updateSyncStatus(id, status) {
    try {
      const transaction = this.db.transaction(this.tableName, 'readwrite');
      const store = transaction.objectStore(this.tableName);
      await new Promise((resolve, reject) => {
        const request = store.get(id);
        request.onsuccess = () => {
          const item = request.result;
          if (item) {
            item.sync_status = status;
            store.put(item).onsuccess = () => resolve();
            store.put(item).onerror = () => reject(request.error);
          } else {
            reject(new Error('Item not found'));
          }
        };
        request.onerror = () => {
          reject(request.error);
        };
      });
    } catch (error) {
      throw error;
    }
  }

  async insert(object) {
    try {
      const transaction = this.db.transaction(this.tableName, 'readwrite');
      const store = transaction.objectStore(this.tableName);
  
      await new Promise((resolve, reject) => {
        const request = store.put(object, object.id);
        request.onsuccess = () => resolve(object);
        request.onerror = () => reject(request.error);
      });
  
      return object;
    } catch (error) {
      console.error('Error inserting object:', error);
      throw error;
    }
  }

  async insertFromServer(dataArray, key) {
    try {
      const objects = dataArray[key];
      for (const item of objects) {
        const columns = this.getDefaultProjection(this.tableName, false);
        const object = {};

        const columnsSplit = columns.split(',');
        for (const columnKey in columnsSplit) {
          const column = columnsSplit[columnKey];
          if (column === 'sync_status') {
            object[column] = 'accepted';
          } else if (item[column] != null && item[column] !== undefined) {
            object[column] = this.getValueToInsert(item[column]);
          }
        }

        await this.insert(object);
      }
    } catch (error) {
      console.error('Error inserting object:', error);
      throw error;
    }
  }

  async update(object, id) {
    try {
      await this.updateSyncStatus(id, object.sync_status);
      return object;
    } catch (error) {
      throw error;
    }
  }

  async setSynchronizedSuccess(id) {
    try {
      await this.update(id, {
        sync_status: 'accepted',
        sync_error: null,
      });
    } catch (error) {
      throw error;
    }
  }

  async setSynchronizedError(id, errorMsg) {
    try {
      await this.update(id, {
        sync_status: 'error',
        sync_error: errorMsg,
      });
    } catch (error) {
      throw error;
    }
  }

  getValueToInsert(value) {
    if (typeof value === 'object') {
      return JSON.stringify(value);
    } else if (typeof value === 'boolean') {
      return value ? 1 : 0;
    }
    return value;
  }

  async exists(id) {
    try {
      const transaction = this.db.transaction(this.tableName, 'readonly');
      const store = transaction.objectStore(this.tableName);
      const exists = await new Promise((resolve, reject) => {
        const request = store.get(id);
        request.onsuccess = () => {
          resolve(!!request.result);
        };
        request.onerror = () => {
          reject(request.error);
        };
      });
      return exists;
    } catch (error) {
      throw error;
    }
  }

  successDb() {
    throw new Error('You have to implement the method successDb');
  }

  errorDb(err) {
    throw new Error('You have to implement the method errorDb');
  }
}