import React, { Component } from 'react';
import { Text, StyleSheet, Platform } from 'react-native';
import { color } from '~/common';
import { fonts } from "../utils/Fonts";

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  subheader: {
    color: Platform.OS === 'ios' ? color.grayDark : color.primaryColor,
    marginHorizontal: 16,
    marginTop: 10,
    fontFamily: fonts.QuicksandBold,
    fontSize: 14,
    fontWeight: 'bold',
  },
});

class SubHeader extends Component {
  static defaultProps = {
    children: '',
    style: {},
  };

  render() {
    const { children } = this.props;
    return (
      <Text style={[styles.subheader, this.props.style]}>
        {typeof children === 'string' ? children.toUpperCase() : children }
      </Text>
    );
  }
}

SubHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
};

export default SubHeader;
