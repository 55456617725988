/* @flow */

import React from 'react';
import {
  View,
  ScrollView,
  StyleSheet,
  Text,
  Platform,
  ProgressViewIOS,
} from 'react-native';
import style from '~/common/style';
import Form from '~/components/Form/Form';
import Spinner from 'react-native-loading-spinner-overlay';
import Button from '../../../components/Button';
import CustomAlert from '../../../components/CustomAlert';
import colors from '../../../common/color';
import translate from '../../../locales';
import Gps from '../../../../components_base/src/components/Gps';

type Props = {
  isFormValid: () => void,
  form: [],
  initialValues: Object,
  getFormData: () => void,
  sendForm: () => void,
  visibleButton: boolean,
  loading: boolean,
  formatQSQLDataSource: () => Array,
  gpsConfig: Object,
  location: Object,
  onLocationChange: () => void,
};

const styles = StyleSheet.create({
  positionAbsolute: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fff',
  },
});

class DynamicForm extends React.Component<void, Props, void> {
  state = {};

  isFormValid = valid => {
    this.props.isFormValid(valid);
  };

  render() {
    const {
      form,
      getFormData,
      visibleButton,
      sendForm,
      loading,
      formatQSQLDataSource,
      gpsConfig,
      location,
      onLocationChange,
      initialValues,
    } = this.props;

    const {
      requiredCollectGpsPoint,
      showPermissionLocation,
      notifyOnSaving,
    } = gpsConfig;

    return (
      <View style={[style.container, { backgroundColor: 'white' }]}>
        <Spinner
          visible={loading}
          overlayColor="rgba(0, 0, 0, 0.7)"
          textStyle={{ color: '#FFF' }}
        />
        <ScrollView>
          <View>
            {(requiredCollectGpsPoint || notifyOnSaving) && (
              <Gps
                location={location}
                errorGps=""
                onLocationChange={onLocationChange}
                showPermitionLocation={showPermissionLocation}
                useViewExpandable
              />
            )}
          </View>
          <View style={{ marginBottom: 40, backgroundColor: '#FFF' }}>
            {form &&
              form.length > 0 && (
                <Form
                  isFormValid={this.isFormValid}
                  data={form}
                  initialValues={initialValues}
                  getFormData={getFormData}
                  formatQSQLDataSource={formatQSQLDataSource}
                />
              )}
          </View>
        </ScrollView>
        <View style={styles.positionAbsolute}>
          <Button
            containerStyle={{
              marginHorizontal: 16,
              marginTop: 5,
              marginBottom: 5,
            }}
            title={loading ? `${translate('saving')}` : `${translate('save')}`}
            disabled={!visibleButton || loading}
            onPress={sendForm}
          />
        </View>
      </View>
    );
  }
}
export default DynamicForm;
