import { createAsyncThunk } from '@reduxjs/toolkit';
import { saveCart as saveCartPromise } from './utilsLib';
import CacheOrderActivity from '../../utils/CacheOrderActivity';

export const SAVE_CART_FETCH_REQUEST = 'SAVE_CART_FETCH_REQUEST';
export const SAVE_CART_FETCH_SUCCESS = 'SAVE_CART_FETCH_SUCCESS';
export const SAVE_CART_FETCH_FAILURE = 'SAVE_CART_FETCH_FAILURE';

export const saveCart = createAsyncThunk(
  'ordersFetch/saveCart',
  async payload => {
    try {
      const cart = await saveCartPromise(payload);
      return JSON.parse(cart);
    } catch (error) {
      console.log('error', error);
      return error;
    } finally {
      await CacheOrderActivity.clearOrderActivity();
    }
  },
);
