/* @flow */

export const OPENING_VISIT = 'OPENING_VISIT';
export const OPENING_VISIT_SUCCESS = 'OPENING_VISIT_SUCCESS';
export const OPENING_VISIT_FAILURE = 'OPENING_VISIT_FAILURE';

export const CLOSING_VISIT = 'CLOSING_VISIT';
export const CLOSING_VISIT_SUCCESS = 'CLOSING_VISIT_SUCCESS';
export const CLOSING_VISIT_FAILURE = 'CLOSING_VISIT_FAILURE';

export function openingSuccess() {
  return dispatch => {
    dispatch({
      type: OPENING_VISIT_SUCCESS,
    });
  };
}


export function openingFailure(payload) {
  return dispatch => {
    dispatch({
      type: OPENING_VISIT_FAILURE,
      payload,
    });
  };
}

export function opening(clientKey, location) {
  return dispatch => {
    dispatch({
      type: OPENING_VISIT,
      payload: {
        clientKey, location,
      },
    });
  };
}

export function closingSuccess() {
  return dispatch => {
    dispatch({
      type: CLOSING_VISIT_SUCCESS,
    });
  };
}


export function closingFailure(payload) {
  return dispatch => {
    dispatch({
      type: CLOSING_VISIT_FAILURE,
      payload,
    });
  };
}

export function closing(clientKey, location) {
  return dispatch => {
    dispatch({
      type: CLOSING_VISIT,
      payload: {
        clientKey, location,
      },
    });
  };
}
