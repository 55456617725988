/* @flow */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import uuidv4 from 'uuid/v4';
import ActivitiesModule from '~/modules/ActivitiesModule';
import getQueryResult from '../../services/resources/libFastSeller/genericQuery';
import _ from 'lodash';
import CacheAccount from '~/controllers/Accounts/Cache/CacheAccount';
import AnswerController from '~/controllers/AnswerController';

export const LIST_PHOTO_FETCH_REQUEST = 'LIST_PHOTO_FETCH_REQUEST';
export const LIST_PHOTO_FETCH_SUCCESS = 'LIST_PHOTO_FETCH_SUCCESS';
export const LIST_PHOTO_FETCH_FAILURE = 'LIST_PHOTO_FETCH_FAILURE';

const listPhotoAnswerActivity = async () =>
  await new AnswerController().listPhotos();

export const listPhotos = createAsyncThunk(
  'photoAnswer/listPhotos',
  async (args, { getState }) => {
    let data = '';
    try {
      data = await listPhotoAnswerActivity();
    } catch (error) {
      throw error;
    }
    return data;
  },
);

const initialState = {
  type: '',
  loading: false,
  erro: false,
  payload: [],
};

const listPhotosSlice = createSlice({
  name: 'listPhotosSlice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(listPhotos.pending, state => {
      state.type = LIST_PHOTO_FETCH_REQUEST;
      state.loading = true;
    });
    builder.addCase(listPhotos.fulfilled, (state, action) => {
      state.type = LIST_PHOTO_FETCH_SUCCESS;
      state.loading = false;
      state.erro = false;
      state.payload = action.payload;
    });
    builder.addCase(listPhotos.rejected, (state, action) => {
      state.type = LIST_PHOTO_FETCH_FAILURE;
      state.loading = false;
      state.erro = true;
      state.payload = action.payload;
    });
  },
});

const { reducer } = listPhotosSlice;

export default reducer;
