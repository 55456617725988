/* @flow */

import React, { Component } from 'react';
import { View, StyleSheet, Image, Text } from 'react-native';
import Images from '@assets/Images';
import Touchable from 'react-native-platform-touchable';
import style from '~/common/style';
import * as config from '~/config/config';
import { SubHeader } from '~/components';
import IconText from '~/components/IconText';
import { fonts } from '../../../utils/Fonts';
import TextUtils from '../../../utils/TextUtils';
import Money from '../../../common/Money';
import CustomItemInfo from '../../../components/CustomItemInfo';
import translate from '../../../locales';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  infoContainer: {
    padding: 16,
    paddingVertical: 16,
    backgroundColor: '#fff',
    borderBottomColor: config.Colors.grayContainerDark,
    borderBottomWidth: 1,
  },
  infoIcon: {
    width: 15,
    height: 15,
    tintColor: config.Colors.grayContainerDark,
  },
  infoText: {
    marginLeft: 16,
    flex: 1,
    color: config.Colors.grayDark,
  },
  code: {
    color: '#4f4f4f',
    fontFamily: fonts.QuicksandBold,
    fontSize: 14,
    fontWeight: 'bold',
  },
  name: {
    color: '#4F4F4F',
    fontFamily: fonts.QuicksandBold,
    fontSize: 16,
    fontWeight: 'bold',
  },
  normalText: {
    fontSize: 14,
    fontFamily: fonts.QuicksandBold,
  },
  phoneAction: {
    tintColor: config.Colors.blueBadge,
    width: 24,
    height: 24,
  },
  informationText: {
    color: '#000',
    fontSize: 12,
    fontFamily: fonts.QuicksandBold,
    marginTop: 8,
  },
});

const LIBERADO = 'LIBERADO';

class Info extends Component {
  getValueFromKey(key, infoAdicionais) {
    let value;
    for (let i = 0; i < infoAdicionais.length; ++i) {
      if (infoAdicionais[i].chave === key) {
        value = infoAdicionais[i].valor;
        break;
      }
    }
    return value;
  }

  renderTelefones = ({ item }, key) => (
    <Touchable
      key={key}
      onPress={() => this.props.openPhone(item.numero)}>
      <View
        style={[
          style.spaceBetween,
          style.rowDirection,
          { padding: 4, flex: 1 },
        ]}>
        <Image source={Images.iconPhoneFill} style={styles.infoIcon} />
        <Text style={[styles.infoText, { flex: 2 }]}>{item.numero}</Text>
        <Text style={[styles.infoText, { flex: 1 }]}>{item.tipo}</Text>
        <Image style={styles.phoneAction} source={Images.iconPhoneFill} />
      </View>
    </Touchable>
  );

  renderEnderecos = ({ item }, key) => {
    const { logradouro, numero, cidade, uf, descricao, bairro, cep } = item;

    return (
      <Touchable
        key={key}
        onPress={() => {
          this.props.openMaps();
        }}>
        <View
          style={[
            style.spaceBetween,
            style.rowDirection,
            { padding: 4, flex: 1 },
          ]}>
          <Image source={Images.iconHomeSelected} style={styles.infoIcon} />
          <Text
            style={[
              styles.infoText,
              { flex: 2 },
            ]}>{`${logradouro}, ${numero}. ${bairro} - ${cidade}/${uf}. ${cep}`}</Text>
          <Text style={[styles.infoText, { flex: 1 }]}>{descricao}</Text>
          <Image style={styles.phoneAction} source={Images.iconRoute} />
        </View>
      </Touchable>
    );
  };

  renderInfoAdicionais = ({ item }) => {
    const { chave, valor } = item;

    if (valor) {
      return (
        <View key={`${chave}-${valor}`}>
          <Text style={styles.informationText}>{chave}</Text>
          <Text>{valor}</Text>
        </View>
      );
    }
    return null;
  };

  renderStatus = item => {
    let statusPedidoRender = null;
    if (item && item.status_pedido && item.status_pedido !== LIBERADO) {
      const iconText = item.motivo || 'Cliente bloqueado';
      statusPedidoRender = (
        <IconText
          imageSrc={Images.iconLock}
          imageStyle={{ tintColor: config.Colors.grayRed }}
          text={iconText}
          textStyle={{
            color: config.Colors.grayRed,
            fontFamily: fonts.QuicksandMedium,
          }}
        />
      );
    }
    return statusPedidoRender;
  };

  renderCpfCnpj = value => {
    let cpfCnpj = null;
    if (value) {
      const valueWithoutFormat = value.replace(/(\.|\/|\-)/g, '');
      if (valueWithoutFormat.length <= 11) {
        cpfCnpj = `${TextUtils.cpfMask(valueWithoutFormat)}`;
      } else {
        cpfCnpj = `${TextUtils.cnpjMask(valueWithoutFormat)}`;
      }

      cpfCnpj = cpfCnpj ? (
        <Text style={styles.normalText}>{cpfCnpj}</Text>
      ) : null;
    }

    return cpfCnpj;
  };

  renderFinancySection = item => {
    const financeiro = item?.financeiro;

    if (financeiro) {
      const {
        lim_credito,
        lim_credito_em_aberto,
        saldo_lim_credito,
        outras_info_financeiro: otherInfoFinancial,
      } = financeiro;

      if (lim_credito || lim_credito_em_aberto || saldo_lim_credito) {
        return (
          <View style={{ backgroundColor: '#fff' }}>
            <View>
              <SubHeader>{`${translate('financing')}`}</SubHeader>
            </View>
            <View style={[styles.infoContainer]}>
              {lim_credito ? (
                <View>
                  <Text style={styles.informationText}>{`${translate(
                    'totalLimitCredit',
                  )}`}</Text>
                  <Text>{Money(lim_credito)}</Text>
                </View>
              ) : null}
              {lim_credito_em_aberto ? (
                <View>
                  <Text style={styles.informationText}>{`${translate(
                    'openCreditLimit',
                  )}`}</Text>
                  <Text>{Money(lim_credito_em_aberto)}</Text>
                </View>
              ) : null}
              {saldo_lim_credito ? (
                <View>
                  <Text style={styles.informationText}>{`${translate(
                    'creditLimitBalance',
                  )}`}</Text>
                  <Text>{Money(saldo_lim_credito)}</Text>
                </View>
              ) : null}
              {otherInfoFinancial
                ? otherInfoFinancial
                    .filter(otherInfo => otherInfo.valor && otherInfo.chave)
                    .map(otherInfo => (
                      <View key={`${otherInfo.chave}-${otherInfo.valor}`}>
                        <Text style={styles.informationText}>
                          {otherInfo.chave}
                        </Text>
                        <Text>{otherInfo.valor.toString().toUpperCase()}</Text>
                      </View>
                    ))
                : null}
            </View>
          </View>
        );
      }
    }
    return null;
  };

  renderCustomSection = item => {
    if (item && item.data && item.data.length) {
      const { title, data } = item;
      if (data) {
        return (
          <View style={{ backgroundColor: '#fff' }}>
            <View>
              <SubHeader>{title}</SubHeader>
            </View>
            <View style={[styles.infoContainer]}>
              <CustomItemInfo data={data} />
            </View>
          </View>
        );
      }
    }
    return null;
  };

  renderAdditionalInfosSection = infoAdicionais => {
    if (infoAdicionais && infoAdicionais.length > 0) {
      return (
        <View style={{ backgroundColor: '#fff' }}>
          <View>
            <SubHeader>{`${translate('aditionalInformations')}`}</SubHeader>
          </View>
          <View style={[styles.infoContainer]}>
            {infoAdicionais.map(info =>
              this.renderInfoAdicionais({ item: info }),
            )}
          </View>
        </View>
      );
    }
    return null;
  };

  render() {
    const { item } = this.props;
    // const { telefones, enderecos } = item;
    const telefones = item?.telefones;
    const enderecos = item?.enderecos;
    const clienteFazParte = item?.cliente_faz_parte;
    const infoAdicionais = item?.info_adicionais;

    return (
      <View style={style.container}>
        {item?.codigo ? (
          <View style={styles.infoContainer}>
            <View style={style.spaceBetween}>
              <View style={{ flex: 1 }}>
                {item?.codigo ? (
                  <Text style={styles.code}>{item.codigo}</Text>
                ) : null}
                <Text style={styles.name}>{item.nome}</Text>
                <IconText
                  imageSrc={Images.iconStore}
                  text={item.fantasia || item.trading_name}
                  textStyle={{ fontFamily: fonts.QuicksandMedium }}
                />
                {this.renderCpfCnpj(item.cnpj_cpf)}
                {this.renderStatus(this.props.item)}
              </View>
            </View>
          </View>
        ) : null}
        {telefones ? (
          <View>
            <View style={{ backgroundColor: '#fff' }}>
              <SubHeader>{`${translate('phones')}`}</SubHeader>
            </View>
            <View style={styles.infoContainer}>
              {telefones.map((tel, key) =>
                this.renderTelefones({ item: tel }, key),
              )}
            </View>
          </View>
        ) : null}
        {enderecos ? (
          <View>
            <View style={{ backgroundColor: '#fff' }}>
              <SubHeader>{`${translate('pluralAddress')}`}</SubHeader>
            </View>
            <View style={[styles.infoContainer, { flex: 1 }]}>
              {enderecos.map((end, key) =>
                this.renderEnderecos({ item: end }, key),
              )}
            </View>
          </View>
        ) : null}
        {this.renderFinancySection(item)}
        {this.renderCustomSection(clienteFazParte)}
        {this.renderAdditionalInfosSection(infoAdicionais)}
      </View>
    );
  }
}

Info.propTypes = {
  item: PropTypes.object,
  openMaps: PropTypes.func,
  openPhone: PropTypes.func,
};

export default Info;
