import React, { useState, useCallback, useMemo } from 'react';
import { Text, TextInput, View, SafeAreaView, Image, ActivityIndicator } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import translate, { calendarNames } from '~/locales';
import { Money, color } from '~/common';
import styles from './styles';
import { FloatingAction } from 'react-native-floating-action';

import RNMonthPicker from 'react-native-month-year-picker';
import moment from 'moment';
import ToggleButton from './components/ToggleButton';
import TimeLine from './components/Timeline';
import PropTypes from 'prop-types';
import Images from '@assets/Images';
import ModalOverlay from '~/components/Web/ModalOverLay/index.web';
const Expenses = ({
  onPressExpense,
  goToForm,
  onChangeDate,
  expenses,
  loading,
  dateSelected,
}) => {
  const [visiblePicker, setVisiblePicker] = useState(false);
  const [activeIn, setActiveIn] = useState(true);
  const [activeOut, setActiveOut] = useState(true);

  const showPicker = useCallback(value => setVisiblePicker(value), []);

  const onValueChange = useCallback(
    (event, newDate) => {
      const selectedDate = newDate || dateSelected;

      showPicker(false);
      onChangeDate(selectedDate);
    },
    [dateSelected, showPicker, onChangeDate],
  );

  const toggleFilterIn = useCallback(
    () => {
      setActiveIn(!activeIn);
    },
    [activeIn],
  );
  const toggleFilterOut = useCallback(
    () => {
      setActiveOut(!activeOut);
    },
    [activeOut],
  );

  const dataRender = useMemo(
    () =>
      expenses?.movements?.filter(i => {
        if (activeIn && i.type === 'in') {
          return true;
        }
        return activeOut && i.type === 'out';
      }),
    [activeIn, activeOut, expenses],
  );

  const handlePressExpense = expense => {
    if (expense.type === 'out') {
      onPressExpense(expense);
    }
  };

  return (
    <SafeAreaView style={{ height: '100%' }}>
      {visiblePicker && (
        <RNMonthPicker
          onChange={onValueChange}
          value={dateSelected}
          mode="short"
        />
      )}
      <View>
        <Touchable
          style={{ width: 212, marginTop: 12, marginLeft: 16 }}
          onPress={() => setVisiblePicker(!visiblePicker)}>
          <View style={[styles.containerInput, { width: '100%' }]}>
            <TextInput
              editable={false}
              value={`${
                calendarNames?.monthNames?.[moment(dateSelected).get('month')]
              } - ${moment(dateSelected).get('year')}`}
              style={styles.input}
            />
            <Image source={Images.appCalendar} style={{ width: 32, height: 32, left: 3, bottom: 2 }} />
          </View>
        </Touchable>

        <View
          style={{
            borderBottomWidth: 0.2,
            borderBottomColor: color.grayA4,
            gap: 4,
            paddingHorizontal: 16,
            paddingVertical: 8,
          }}>
          <View
            style={{
              marginTop: 8,
              gap: 8,
            }}>
            <Text
              style={{
                fontSize: 12,
                fontWeight: '500',
              }}>
              {`${translate('balance')}`}
            </Text>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'flex-end',
              }}>
              <Text style={styles.currency}>{translate('currencySymbol')}</Text>
              <Text style={styles.money}>{Money(expenses?.balance, true)}</Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: 'row',
              marginTop: 8,
            }}>
            <View
              style={{
                paddingRight: 12,
                paddingLeft: 8,
                gap: 4,
                backgroundColor: '#00529E03',
                borderRadius: 4,
                marginRight: 16,
              }}>
              <Text
                style={{
                  color: color.primaryColor,
                }}>
                {`${translate('in')}`}
              </Text>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Image source={Images.iconRightUp} tintColor={color.primaryColor} style={{ width: 18, height: 18,right: 6 }} />
                <Text
                  style={{
                    fontSize: 16,
                    color: color.primaryColor,
                  }}>
                  {Money(expenses?.entry_balance)}
                </Text>
              </View>
            </View>

            <View
              style={{
                gap: 4,
                paddingHorizontal: 8,
                backgroundColor: '#B2150003',
                borderRadius: 4,
                paddingRight: 12,
                paddingLeft: 8,
              }}>
              <Text style={{ color: color.redBadge }}>{`${translate(
                'out',
              )}`}</Text>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Image source={Images.iconLeftDown} tintColor={color.redBadge} style={{ width: 18, height: 18,right: 6 }} />
                <Text
                  style={{
                    fontSize: 16,
                    color: color.redBadge,
                  }}>
                  {Money(expenses?.exit_balance)}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.buttonsView}>
          <ToggleButton
            active={activeIn}
            text={`${translate('in')}`}
            onPress={toggleFilterIn}
          />
          <ToggleButton
            active={activeOut}
            text={`${translate('out')}`}
            onPress={toggleFilterOut}
            activeStyle={styles.activeButtonOut}
          />
        </View>
      </View>

      <TimeLine data={dataRender} onPressExpense={handlePressExpense} />

      <FloatingAction
        onPressMain={goToForm}
        visible
        showBackground={false}
        floatingIcon={<Image source={Images.iconPlus} tintColor={'#FFF'} style={{ width: 18, height: 18 }} />}
      />
      <ModalOverlay
          visible={loading}
          closeOnTouchOutside
        />
      <ActivityIndicator style={{ position: 'absolute', top: '50%', left: '50%', zIndex: 9999 }} animating={loading} size="large" />  
    </SafeAreaView>
  );
};

Expenses.propTypes = {
  onPressExpense: PropTypes.func,
  goToForm: PropTypes.func,
  onChangeDate: PropTypes.func,
  expenses: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool,
  dateSelected: PropTypes.instanceOf(Date),
};

export default Expenses;
