import AsyncStorage from '@react-native-async-storage/async-storage';

class ActivityCache {
  async getTaskListProcessBlock() {
    const data = await AsyncStorage.getItem(
      '@Session:CacheActivityTaskListProcessBlock',
    );
    return data ? JSON.parse(data) : null;
  }

  async setTaskListProcessBlock(taskList) {
    await AsyncStorage.setItem(
      '@Session:CacheActivityTaskListProcessBlock',
      JSON.stringify(taskList),
    );
  }

  async removeTaskListProcessBlock() {
    await AsyncStorage.removeItem('@Session:CacheActivityTaskListProcessBlock');
  }
}

export default new ActivityCache();
