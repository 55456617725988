import _ from 'lodash';
import React, { Component } from 'react';
import {
  View,
  StyleSheet,
  Linking,
  Alert,
  ActivityIndicator,
  TouchableOpacity,
  Image,
} from 'react-native';
import { WebView } from 'react-native-webview';
import { sign } from 'react-native-pure-jwt';
import { color } from '~/common';
import CacheAccount from '~/controllers/Accounts/Cache/CacheAccount';
import CachePolistore from '../../controllers/Accounts/Cache/CachePolistore';
import loginEdi from '../../services/LoginEDI';
import translate from '../../locales';
import Images from '@assets/Images';

import PropTypes from 'prop-types';


const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: color.grayContainer,
  },
  loading: {
    position: 'absolute',
    alignSelf: 'center',
    marginTop: '50%',
  },
});

class WebviewContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uri: '',
      identifyModule: this.props.route.params.key ? this.props.route.params.key : this.props.identifyModule,
      url: this.props.route.params ? this.props.route.params.url : this.props.url,
      token: this.props.route.params.token ? this.props.route.params.token : this.props.token,
      tokenUrlClientDetail: '',
      loading: true,
      finalUrl: this.props.route.params.final_url ? this.props.route.params.final_url : this.props.final_url,
      source: {}
    };
    this.props.navigation.addListener('focus', () => {
      this.externalUrlNavigate();
    });
  }
  async UNSAFE_componentWillMount() {
    this.props.navigation.setOptions({ title: `${this.props.route.params.name}` });
    if (!this.state.finalUrl) {
      this.cacheAccount = new CacheAccount();
      const dataAccount = await this.cacheAccount.getDataAccount();
      const cachePoli = new CachePolistore();
      const loginData = await cachePoli.getLoginData();

      const dataParsed = JSON.parse(loginData);

      const empresa = dataParsed.empresa;
      const password = dataParsed.senha;
      const user = dataAccount.user.username;
      if (dataAccount && dataAccount.user && dataAccount.token) {
        if (this.state.identifyModule === 'edi') {
          const tokenEDI = await loginEdi(empresa, user, password);
          this.setState({
            uri: this.getUrlFromModule(tokenEDI, dataAccount.user.id),
            source: {
              uri: this.getUrlFromModule(tokenEDI, dataAccount.user.id)
            }
          });
        } else {
          this.setState({
            uri: this.getUrlFromModule(dataAccount.token, dataAccount.user.id),
            source: {
              uri: this.getUrlFromModule(dataAccount.token, dataAccount.user.id)
            }
          });
        }
      }
      const { clientModule, clientCurrent } = this.props;
    if (clientModule && clientCurrent) {
      sign(
        {
          cpf: clientCurrent.cnpj_cpf.replace(/(\.|\/|\-)/g, ''),
        },
        clientModule.secret,
        {
          alg: 'HS256',
        },
      )
        .then(token => {
          if (clientModule.params && clientModule.params.length > 0) {
            const queryParams = clientModule.params.reduce(
              (param, params) => `${param}${params.key}=${params.value}&`,
              '?',
            );
            this.setState({
              tokenUrlClientDetail: `${
                clientModule.base_url
              }${queryParams}token=${token}`,
            });
          } else {
            this.setState({
              tokenUrlClientDetail: `${clientModule.base_url}?token=${token}`,
            });
          }
        })
        .catch(err => {
          Alert.alert(
            `${translate('ProblemAccessingThePage')}.`,
            `${translate('ProblemAccessingThePageDescription')}.`,
            [
              {
                text: `${translate('cancel')}`,
                onPress: () => {},
                style: 'cancel',
              },
              {
                text: `${translate('pleaseTryAgain')}`,
                onPress: () => {},
                style: 'default',
              },
            ],
            { cancelable: false },
          );
        });
    }
    } else {
      this.setState({
        uri: this.state.url,
        source: {
          uri: this.state.url,
        }
      });
    }
  }

  componentDidMount() {
    this.setLeftButton();
  }

  externalUrlNavigate() {
    const externalUrl = _.get(
      this.props,
      'props.item.props.externalUrl',
      false,
    );
    const { uri } = this.state;
    if (externalUrl && uri) {
      Linking.canOpenURL(uri).then(supported => {
        if (supported) {
          this.handleBackPress();
          return Linking.openURL(uri);
        }
      });
    }
  }

  getUrlFromModule(token, userId) {
    const { url } = this.state;
    const hideToolbarStr = `&hide_toolbar=true&user_id=${userId}`;
    const hasToken = `${url}`.includes('?token');
    const finalToken = token || this.state.token;

    if (hasToken) {
      return `${url}${hideToolbarStr}`;
    }

    return `${url}?token=${finalToken}${hideToolbarStr}`;
  }

  setLeftButton = () => {
    this.props.navigation.setOptions({
      headerLeft: () => (
        <TouchableOpacity onPress={() => {
          this.props.navigation.pop();
        }}
        >
          <Image
            source={Images.iconBack}
            style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
          />
        </TouchableOpacity>
      ),
    });
  };

  render() {
    const { uri, tokenUrlClientDetail, loading, source } = this.state;
    return (
      <View style={styles.container}>
        <WebView
          style={styles.WebViewStyle}
          source={
            source ? source
            : uri ? { uri } : { uri: tokenUrlClientDetail }
          }
          javaScriptEnabled
          domStorageEnabled
          onLoad={() => this.setState({ loading: false })}
        />
        {loading && <ActivityIndicator style={styles.loading} size="large" />}
      </View>
    );
  }
}

WebviewContainer.propTypes = {
    url: PropTypes.string,
    token: PropTypes.string,
    identifyModule: PropTypes.string,
    componentId: PropTypes.string,
    clientModule: PropTypes.object,
    clientCurrent: PropTypes.object,
    props: PropTypes.object,
};

export default WebviewContainer;
