/* @flow */

import React, { Component } from 'react';
import { View, Image, StyleSheet } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import PropTypes from 'prop-types';

import { color } from '~/common';

const container = {
  width: 56,
  height: 56,
  borderRadius: 28,
};

const styles = StyleSheet.create({
  elevationContainer: {
    elevation: 4,
  },
  outsideContainer: {
    overflow: 'hidden',
  },
  icon: {
    width: 30,
    height: 30,
    tintColor: 'white',
  },
  fabContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color.accentColor,
  },
});

class FAB extends Component {
  state = {};
  render() {
    const { icon, small, style, iconColor, fabStyle } = this.props;
    if (small) {
      container.width = 40;
      container.height = 40;
    }
    if (iconColor) {
      styles.icon.tintColor = iconColor;
    }
    return (
      <View style={[styles.elevationContainer, container, style]}>
        <View style={[styles.outsideContainer, container]}>
          <Touchable
            disabled={this.props.disabled}
            onPress={() => {
              if (this.props.onPress) {
                this.props.onPress();
              }
            }}>
            <View style={[styles.fabContainer, container, fabStyle]}>
              <Image style={styles.icon} source={icon} />
            </View>
          </Touchable>
        </View>
      </View>
    );
  }
}

FAB.propTypes = {
  icon: PropTypes.number.isRequired,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  iconColor: PropTypes.string,
  style: PropTypes.object,
  fabStyle: PropTypes.object,
  onPress: PropTypes.func.isRequired,
};

export default FAB;
