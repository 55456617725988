/* @flow */

import React, { Component } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import CheckBox from 'react-native-check-box';
import style from '../common/style';
import Colors from '../../components_base/src/values/color';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
    labelText: {
        fontSize: 14,
        marginTop: 5,
    }
});

class ClientPattern extends Component {
    renderItem() {
        return (
            this.props.data.map(d => {
                let componentValue = d.tipo === 'checkbox' ?
                    (
                        <CheckBox
                            checkBoxColor={Colors.blueBadge}
                            isChecked={d.valor === '1'}
                            disabled={true}
                        />) : <Text>{d.valor}</Text>
                return (
                    <View
                        key={d.ord}
                        style={[style.spaceBetween,
                        style.rowDirection, { padding: 4, flex: 1 }]}
                    >
                        <Text style={styles.labelText}>{d.texto}:</Text>
                        {componentValue}
                    </View>
                );
            })
        );
    }
    render() {
        return (
            this.renderItem()
        );
    }
}

ClientPattern.propTypes = {
    data: PropTypes.array,
};

export default ClientPattern;