import axios from 'axios';
import { URL_BASE } from '~/services';
import CacheAccount from '~/controllers/Accounts/Cache/CacheAccount';
import _ from 'lodash';
import NetInfo from '@react-native-community/netinfo';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ROUTE_SOLICITATION_CACHE_DATA } from '~/screens/Notifications/typeRouteSolicitationConst';
import moment from 'moment';

export const KIND_ADD_CUSTOMER_ROUTE = 'ADD_CUSTOMER_ROUTE';
export const KIND_REQUEST_ORDER_PENDENCY = 'REQUEST_ORDER_PENDENCY';
export const KIND_REQUEST_STARTING_POINT = 'REQUEST_STARTING_POINT';
export const KIND_REQUEST_CHANGE_CUSTOMER_LOCATION = 'CHANGE_CUSTOMER_LOCATION';

class RouteSolicitationsResource {
  async getConfig() {
    const cacheAccount = new CacheAccount();
    const dataAccount = await cacheAccount.getDataAccount();
    const token = _.get(dataAccount, 'token', '');

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `JWT ${token}`,
      },
    };

    return config;
  }

  getSolicitations() {
    const key = `${ROUTE_SOLICITATION_CACHE_DATA}-${moment().format('YYYY/MM/DD')}`;
    const promise = new Promise(async (resolve) => {
      const config = await this.getConfig();
      const isConnected = await NetInfo.isConnected.fetch();

      if (isConnected) {
        axios
          .get(`${URL_BASE}/routes/change-route-solicitations/`, { ...config })
          .then((response) => {
            const items = response.data;
            AsyncStorage.setItem(key, JSON.stringify(items));

            resolve(items);
          }).catch((err) => {
            resolve([]);
          });
      } else {
        const items = JSON.parse(AsyncStorage.getItem(key) || '[]');
        resolve(items);
      }
    });

    return promise;
  }

  sendSolicitation(customerCode, type, payload = {}) {
    const promise = new Promise(async (resolve, reject) => {
      const config = await this.getConfig();
      const data = {
        u_codcliente: customerCode,
        s_tipo: type,
        j_payload: {
          ...payload,
        },
      };

      axios.post(`${URL_BASE}/solicitations/solicitations/`, JSON.stringify(data), { ...config })
        .then((response) => {
          console.tron.log('NOTIFICATIONS_SUCCESS', response);
          resolve(response.data);
        }).catch((error) => {
          console.tron.log('NOTIFICATIONS_ERROR', error);
          reject(error.response);
        });
    });

    return promise;
  }
}

export default new RouteSolicitationsResource();
