import { StyleSheet } from 'react-native';
import colors from '../../../common/color';

const styles = StyleSheet.create({
  card: {
    padding: 20,
    backgroundColor: colors.navBarButtonColor,
    borderRadius: 4,
    shadowColor: '#000',
    shadowOffset: { width: 0.2, height: 0.6 },
    shadowOpacity: 0.2,
    shadowRadius: 1,
    elevation: 1,
  },
  badgeContainer: {
    width: 22,
    height: 22,
    borderRadius: 12,
    backgroundColor: colors.grayContainerDark,
    justifyContent: 'center',
    alignItems: 'center',
  },
  greenBadgeContainer: {
    width: 22,
    height: 22,
    borderRadius: 12,
    backgroundColor: colors.accentColor,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconBadgeCheck: {
    width: 18,
    height: 18,
    tintColor: '#fff',
  },
  cardTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardTitleText: {
    fontWeight: 'bold',
    fontSize: 17,
    alignSelf: 'center',
  },
  descriptionText: {
    marginTop: 10,
    marginLeft: 12,
    fontSize: 16,
  },
  progressText: {
    fontSize: 14,
    fontWeight: '500',
  },
});

export default styles;
