import React from 'react';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';

const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE1MTkyMzQ0MTEsImVtYWlsIjoiIiwib3JpZ19pYXQiOjE1MTc5Mzg0MTEsInVzZXJuYW1lIjoiZG9uaXpldGUubWFnZW50byIsInVzZXJfaWQiOjIyfQ.iHI5yfb0_zO99yieAMzKcdcdR92aixbAHCQQrMzt2Sc';

class StockerProductResource {

  async getInfo(ean) {

    let url = `https://stocker.isilist.com.br/api/v1/product/search/ean/?q=${ean}`;

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios.defaults.headers.post['Content-Type'] = 'application/json';

    let response = await axios.get(url);

    if (response.status == 200) {
      return response.data;
    } else {
      throw Error(`Product EAN ${ean} not found`);
    }
  }

  getRedirect = async (uri)=>{
    const response = await fetch(uri)
    const data = await response
    return (data.status === 200 ? data.url : '')
  };

  getThumbImage(url, token, ean, isThumb = true) {
    if (url) {
      const urlImage = url.replace('${ean}', ean) + (isThumb ? '/thumb/' : '/');
      return {
        uri: urlImage,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      };
    }
    return {};
  }
}

export default new StockerProductResource;
