import AsyncStorage from '@react-native-async-storage/async-storage';

class CacheOrderActivity {
  static async setOrderActivity(pkey, dateSelected) {
    try {
      await AsyncStorage.setItem(
          '@OrderActivity',
          JSON.stringify({
            pkey,
            dateSelected,
          }),
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async getOrderActivity() {
    try {
      const orderActivity = await AsyncStorage.getItem('@OrderActivity');
      return JSON.parse(orderActivity);
    } catch (error) {
      console.log(error);
    }
  }

  static async existsPreOrderActivity(codigo) {
    try {
      const orderActivity = await AsyncStorage.getItem(
          `@PreOrderActivity-${codigo}`,
      );
      return orderActivity !== undefined;
    } catch (error) {
      console.log(error);
    }
  }

  static async clearOrderActivity() {
    try {
      await AsyncStorage.removeItem('@OrderActivity');
    } catch (error) {
      console.log(error);
    }
  }

  static async setPreOrderActivity(codigo, baseAnswer) {
    try {
      await AsyncStorage.setItem(
          `@PreOrderActivity-${codigo}`,
          JSON.stringify(baseAnswer),
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async getPreOrderActivity(codigo) {
    try {
      const orderActivity = await AsyncStorage.getItem(
          `@PreOrderActivity-${codigo}`,
      );
      return JSON.parse(orderActivity);
    } catch (error) {
      console.log(error);
    }
  }

  static async clearPreOrderActivity(codigo) {
    try {
      await AsyncStorage.removeItem(`@PreOrderActivity-${codigo}`);
    } catch (error) {
      console.log(error);
    }
  }
}

export default CacheOrderActivity;