const GridImages = {
  iconBatteryBlue: require('./battery_blue.png'),
  iconBatteryBlueLight: require('./battery_blue_light.png'),
  iconCarBlue: require('./car_blue.png'),
  iconCarBlueLight: require('./car_blue_light.png'),
  iconMotorcycleBlue: require('./motorcycle_blue.png'),
  iconMotorcycleBlueLight: require('./motorcycle_blue_light.png'),
  iconOilBlue: require('./oil_blue.png'),
  iconOilBlueLight: require('./oil_blue_light.png'),
};

export default GridImages;
