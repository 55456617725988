import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 2,
  },
  groupItemCheckBox: {
    flexDirection: 'row',
    paddingHorizontal: 5,
    paddingVertical: 2,
  },
});

export default styles;
