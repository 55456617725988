import * as RootNavigation from '../routes/RootNavigation';

function filterMessages(messages) {
  const filteredMsgs = [];
  const dataFiltered = [];
  messages.forEach(msg => {
    if (!filteredMsgs.includes(msg.mensagem)) {
      filteredMsgs.push(msg.mensagem);
      dataFiltered.push(msg);
    }
  });
  return dataFiltered;
}

function executeActionOrOpenScreenMessage(
  messages,
  actionMessagesError,
  actionMessagesNotError,
  onPressActionButton = null,
  actionButtonTitle = null,
) {
  if (messages && messages.length > 0) {
    RootNavigation.push('Messages', {
      messages: filterMessages(messages),
      actionMessagesError,
      actionMessagesNotError,
      onPressActionButton,
      actionButtonTitle,
    });
  } else if (actionMessagesNotError) {
    actionMessagesNotError();
  }
}

export function executeActionsMessage(
  messages,
  actionMessagesError,
  actionMessagesNotError,
) {
  const messagesErrors = messages.filter(
    m =>
      m.tipo === 'erro' ||
      (m.tipo === 'question' && m.codigo === 'salv_pedc_tvar'),
  );
  if (messagesErrors && messagesErrors.length > 0) {
    if (actionMessagesError) {
      actionMessagesError();
    }
  } else if (actionMessagesNotError) {
    actionMessagesNotError();
  }
}

export default executeActionOrOpenScreenMessage;
