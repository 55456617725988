import React, { useRef, useEffect, useCallback } from 'react';

import Messages from './Messages';
import onPressHelper from "~/utils/onPressHelper";
import PropTypes from 'prop-types';

function MessagesContainer({
  // messages,
  // actionMessagesError,
  // actionMessagesNotError,
  // onPressActionButton,
  componentId,
  // actionButtonTitle,
  navigation,
  route,
}) {
  const messages = route.params?.messages;
  const actionMessagesError = route.params?.actionMessagesError;
  const actionMessagesNotError = route.params?.actionMessagesNotError;
  const onPressActionButton = route.params?.onPressActionButton;
  const actionButtonTitle = route.params?.actionButtonTitle;
  const refModalMessages = useRef(null);

  const handleCloseSheet = () => {
    if (refModalMessages.current.modalVisible) {
      refModalMessages.current?.close();
    }
  };

  const handleGeneralClose = useCallback(
    async () => {
      navigation.pop();
      onPressHelper.debounce(handleCloseSheet);
    },
    [componentId],
  );
  
  const handlePressExit = useCallback(
    nameContext => {
      handleGeneralClose();
      const messageError = messages.find(
        m =>
          m.tipo === 'erro' ||
          (m.tipo === 'question' && m.codigo === 'salv_pedc_tvar'),
      );

      if (messageError) {
        if (actionMessagesError) {
          actionMessagesError();
        }
      } else if (actionMessagesNotError) {
        if (nameContext !== 'yes' && nameContext !== 'exit') {
          actionMessagesNotError();
        }
      }
    },
    [actionMessagesError, actionMessagesNotError, handleGeneralClose, messages],
  );

  const handleOpenSheet = () => {
    if (refModalMessages.current) {
      refModalMessages.current.open();
    }
  };

useEffect(() => {
  handleOpenSheet();
}, []);

  const handleActionButton = () => {
    handleGeneralClose();
    if (onPressActionButton) {
      onPressActionButton();
    }
  };

  return (
    <Messages
      messages={messages}
      onPressExit={handlePressExit}
      onPressActionButton={handleActionButton}
      hasActionButton={!!onPressActionButton}
      actionButtonTitle={actionButtonTitle}
      actionMessagesNotError={actionMessagesNotError}
    />
  );
}

MessagesContainer.propTypes = {
  messages: PropTypes.array.isRequired,
  actionMessagesError: PropTypes.func,
  actionMessagesNotError: PropTypes.func,
  onPressActionButton: PropTypes.func,
  actionButtonTitle: PropTypes.string,
  componentId: PropTypes.string.isRequired,
};

export default MessagesContainer;
