import { StyleSheet } from 'react-native';

import { color } from '~/common';
import { fonts } from '../../../../utils/Fonts';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    flexDirection: 'row',
    borderBottomColor: color.grayContainer,
    borderBottomWidth: 2,
  },
  description: {
    fontSize: 14,
    marginLeft: 10,
    fontFamily: fonts.QuicksandBold,
    color: color.grayDarkest,
  },
  cod: {
    fontSize: 14,
    marginLeft: 10,
    marginRight: 16,
    fontFamily: fonts.Quicksand,
    color: color.grayMid,
  },
  cod_value: {
    fontSize: 14,
    marginLeft: 10,
    marginRight: 16,
    fontFamily: fonts.Quicksand,
    color: color.grayDarkest,
  },
  buttonStyle: {
    width: 28,
    height: 28,
    borderRadius: 14,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 0,
  },
  buttonImageStyle: {
    width: 24,
    height: 24,
    borderRadius: 12,
    tintColor: color.primaryColor,
  },
  statusProduct: {
    width: 10,
    height: 10,
    marginLeft: 10,
    borderRadius: 10,
  },
  cardImage: {
    marginBottom: 15,
    width: 100,
    height: 100,
  },
});

export default styles;
