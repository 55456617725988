import axios from 'axios';
import RNFS from 'react-native-fs';
import TextUtils from '../../utils/TextUtils';
import store from '~/store/index';
import AnswerController from '../../controllers/AnswerController';
import STAGING from './config';

import NetInfo from '@react-native-community/netinfo';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { SEND_PHOTO_ONLY_WIFI } from '../../constants/Photo';

import UserTaskListController from '../../controllers/UserTaskListController';
import CachePolistore from '~/controllers/Accounts/Cache/CachePolistore';

const URL_RESERVE_IDS = `https://process-server${STAGING}.polisuite.com.br/v1/sync/reserve-ids/`;
const URL_UPLOAD_SYNC = `https://process-server${STAGING}.polisuite.com.br/v1/sync/user/`;
const URL_UPLOAD_PHOTOS_SYNC = `https://process-server${STAGING}.polisuite.com.br/v1/forms/upload/`;
const ERROR_RESERVE_IDS = 'Erro na reserva de ids';
const ERROR_SYN_UPLOAD =
  'Falha ao enviar dados. Verifique sua conexão com a internet e sincronize os dados novamente.';
const TABLES_UPLOAD = ['answers', 'user_task_lists'];

class UploadAtividades {
  constructor(dataAccount, dataUserDevice, sucess, error) {
    this.success = sucess;
    this.error = error;

    this.dataUserDevice = dataUserDevice;

    this.config = {
      headers: {
        Authorization: `JWT ${dataAccount.token}`,
      },
    };

    this.bucketConfig = null;
  }

  setBucketConfig = () => {
    const {
      configLibFetch: { payload },
    } = store.getState();
    const hasConf =
      payload.bucket_config &&
      payload.bucket_config.params &&
      payload.bucket_config.params.url;

    if (hasConf) {
      this.bucketConfig = payload.bucket_config.params;
    }
  };

  async upload() {
    return new Promise(async (resolve, reject) => {
      try {
        this.setBucketConfig();
        // await this.syncUploadPhotos();
        this.success();

        resolve();
      } catch (error) {
        reject();
        this.error(ERROR_RESERVE_IDS);
      }
    });
  }

  async syncUploadTables() {
    const urlFull = `${URL_UPLOAD_SYNC}?device_uuid=${this.dataUserDevice.id}`;
    const data = await this.getDataUploadTables();

    return new Promise((resolve, reject) => {
      axios
        .post(urlFull, data, this.config)
        .then(async response => {
          const tables = Object.keys(response.data);
          for (const indTable in tables) {
            const tableProcess = this.getUploadTable(tables[indTable]);
            const dataTable = response.data[tables[indTable]];

            const successAll = dataTable.success;
            for (const indSuc in successAll) {
              await tableProcess.setSynchronizedSuccess(successAll[indSuc].id);
            }

            const errorsAll = dataTable.errors;
            for (const indErro in errorsAll) {
              const message = JSON.stringify(errorsAll[indErro].errors);
              await tableProcess.setSynchronizedError(
                errorsAll[indErro].id,
                message,
              );
            }
          }
          this.success();
          resolve();
        })
        .catch(error => {
          console.log('LFS error syncUploadTables', error);
          reject();
          this.error(ERROR_SYN_UPLOAD);
        });
    });
  }

  getHeaders() {
    let { headers } = this.config;

    if (this.bucketConfig) {
      headers = {
        credential_access_key: this.bucketConfig.credential_access_key,
        credential_secret_key: this.bucketConfig.credential_secret_key,
      };
    }

    return headers;
  }

  async _sendPhotoOnlyWithWiFi() {
    const value = await AsyncStorage.getItem(SEND_PHOTO_ONLY_WIFI);
    const parsedValue =
      value !== undefined ? (value === 'true' ? true : false) : true;

    return parsedValue;
  }
  async syncUploadPhotos() {
    const data = await this.getDataUploadPhotos();
    const onlyWifi = await this._sendPhotoOnlyWithWiFi();
    const userData = (await new CachePolistore().getParamsSignIn()) || '{}';
    const { rca_codmaq: codmaq } = JSON.parse(userData);

    const connectionType = await NetInfo.fetch();

    const isWifi = connectionType && connectionType.type === 'wifi';

    let canSyncPhotos;
    if (onlyWifi) {
      canSyncPhotos = isWifi;
    } else {
      canSyncPhotos =
        isWifi || (connectionType && connectionType.type === 'cellular');
    }

    if (data.length > 0 && canSyncPhotos) {
      const form = new FormData();
      const typeField = this.bucketConfig ? 'file' : 'image';
      form.append(`${typeField}`, {
        image: data[0].payload.uri,
        uri: data[0].payload.uri,
        type: 'image/jpeg',
        name: data[0].payload.filePath,
      });

      const config = {
        headers: {
          ...this.getHeaders(),
          'Content-Type': 'multipart/form-data',
        },
      };

      const url = this.bucketConfig
        ? `${this.bucketConfig.url}/${codmaq}`
        : URL_UPLOAD_PHOTOS_SYNC;

      return new Promise(async (resolve, reject) => {
        const answerController = new AnswerController();
        const fileExists = await RNFS.exists(data[0].payload.filePath);
        if (!fileExists) {
          await answerController.setPhotoStatusSync(
            data[0].id,
            'file_not_found',
            'Arquivo não encontrado',
          );

          if (data.length > 1) {
            await this.syncUploadPhotos();
          }

          this.success();
          resolve();
          return;
        }

        if (data[0]?.deleted_at) {
          this.success();
          return;
        }

        axios
          .post(url, form, config)
          .then(async res => {
            const isValidResult =
              res.status === 200 && typeof res.data !== 'string';
            if (isValidResult) {
              await answerController.setPhotoSynchronizedSuccess(data[0].id);
              if (data.length > 1) {
                await this.syncUploadPhotos();
              }

              this.success();
              resolve();
              return;
            }
            throw new Error('Não foi possível sincronizar a foto');
          })
          .catch(error => {
            console.log('LFS error syncUploadPhotos', error?.message);
            reject();
            this.error(ERROR_SYN_UPLOAD);
          });
      });
    }

    return new Promise(
      (resolve, reject) => (canSyncPhotos ? resolve() : reject()),
    );
  }

  async getDataUploadPhotos() {
    const answerController = new AnswerController();
    return answerController.getPhotoToSync();
  }

  async getDataUploadTables() {
    const dataAll = {};
    const promise = new Promise(resolve => {
      if (!TABLES_UPLOAD.length) {
        resolve(dataAll);
      }

      TABLES_UPLOAD.forEach(async (key, index) => {
        const uploadTable = this.getUploadTable(key);
        if (uploadTable) {
          const data = await uploadTable.getAllToSync();
          dataAll[key] = data;
        }

        if (TABLES_UPLOAD.length - 1 === index) {
          resolve(dataAll);
        }
      });
    });

    return promise;
  }

  getUploadTable(table) {
    if (table === 'answers') {
      return new AnswerController();
    } else if (table === 'user_task_lists') {
      return new UserTaskListController();
    }
    return null;
  }
}

export default UploadAtividades;
