// @flow

import React, { Component } from 'react';
import { FlatList } from 'react-native';
import CategoryItem from './Category';
import FooterCategories from './FooterCategories';

import PropTypes from 'prop-types';

class Categories extends Component {
  refFlatList = ref => {
    this.flatListRef = ref;
  };

  UNSAFE_componentWillReceiveProps({ scrollToCategory, actualCategory }) {
    /* if (scrollToCategory) {
      setTimeout(() => {
        this.flatListRef.scrollToIndex({
          animated: false,
          viewPosition: 0.5,
          index: actualCategory,
        });
      }, 300);
    } */
  }

  keyExtractor = ({ codigo }) => codigo;

  renderItem = ({ item, index }) => {
    return (
      <CategoryItem
        item={item}
        index={index}
        onPressItem={this.props.onPressItem}
      />
    );
  };

  render() {
    const {
      allCategories,
      categories,
      onPressItem,
      onEndReachedThreshold,
      onEndReached,
      navigation,
    } = this.props;

    return (
      <FlatList
        // estimatedItemSize={108}
        renderItem={this.renderItem}
        keyExtractor={this.keyExtractor}
        data={categories}
        horizontal
        ref={this.refFlatList}
        onEndReachedThreshold={onEndReachedThreshold}
        onEndReached={onEndReached}
        ListFooterComponent={
          <FooterCategories
            componentIdCart={this.props.componentIdCart}
            categories={allCategories}
            onPressItem={onPressItem}
            navigation={this.props.navigation}
          />
        }
      />
    );
  }
}

Categories.propTypes = {
  categories: PropTypes.array.isRequired,
  onPressItem: PropTypes.func,
  extraData: PropTypes.any,
  scrollToCategory: PropTypes.bool,
  actualCategory: PropTypes.number,
  onEndReachedThreshold: PropTypes.number,
  onEndReached: PropTypes.func,
  navigation: PropTypes.func,
};

export default Categories;
