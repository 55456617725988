import { StyleSheet, Dimensions } from 'react-native';
import { color } from '~/common';

export const { height, width } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: color.grayContainer,
  },
  content: {
    backgroundColor: '#fff',
    padding: 0,
    minHeight: 20,
    flex: 1,
    marginTop: 4,
  },
  indicatorStyle: {
    backgroundColor: color.primaryColor,
    padding: 4,
    margin: 4,
  },
  PagerTitleIndicator: {
    justifyContent: 'flex-start',
    paddingHorizontal: 16,
    backgroundColor: color.primaryColor,
  },
  selectedBorderStyle: {
    backgroundColor: color.accentColor,
  },
  indicatorTextBaseStyle: {
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.5)',
  },
});

export default styles;
