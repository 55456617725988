/* @flow */

import React, { Component } from 'react';
import { TouchableOpacity, Image } from 'react-native';
import { connect } from 'react-redux';
import AddClient from './AddClient';
import { newClientsFetch } from '../../store/newClientsFetch/newClientsFetchSlice';
import {
    SYNC_PROCESS_SALES,
} from '../../constants/Sync';
import {
    syncUpdateScreen,
} from '../../store/syncFetch/slice.js';

import { savePhotoAnswer } from '../../store/photoAnswerActivity/slice';
import { SCREEN_ADD_CLIENT } from '../screens';
import { existsAnsweredField } from '../../utils/Form';
import { syncUpload } from '../../TaskSync';
import translate from '../../locales';
import _ from 'lodash';
import PreRegisterLib from '../../services/resources/libFastSeller/PreRegisterLib.web';
import getGpsConfigs from '../../utils/ConfigHelper';
import CacheDataController from '~/utils/CacheDataController';
import { handlePermissions } from '~/store/permission/slice';
import Images from '@assets/Images';

import PropTypes from 'prop-types';
import { showAlert } from '~/components/Web/Alert';
import { createNotifyError } from '~/components/Web/ToastNotify';

class AddClientContainer extends Component {
    constructor(props) {
        super(props);

        this.stringJsonDataForm = {};

        this.state = {
            form: [],
            initialValues: {},
            dataForm: {},
            visible: false,
            formIsValid: false,
            gpsConfig: {},
            consultCNPJRequesting: false,
            location: {
                accuracy: Infinity,
                altitude: 0,
                heading: 0,
                latitude: 0,
                longitude: 0,
                speed: 0.0,
                timestamp: 0,
            },
            validateFields: [],
        };

        this.props.navigation.addListener('focus', () => {
            this.props.navigation.setOptions({
                title: this.props?.route?.params?.item
                    ? this.props?.route?.params?.item.nome
                    : SCREEN_ADD_CLIENT.title,
            });
        });
    }

    componentDidMount() {
        this.setLeftButton();
        this.loadParametersPreRegister();
        const validate_fields_pcad_recad =
            _.get(
                this.props.configLib.payload,
                'configuracoes.validate_fields_pcad_recad',
            ) || [];
        this.setState({ validateFields: validate_fields_pcad_recad });
    }

    componentDidUpdate(prevProps) {
        (() => {
            if (this.props.syncFetch.type === prevProps.syncFetch.type) {
                return;
            }
            if (
                this.props.syncFetch.payload.statusSync !== SYNC_PROCESS_SALES &&
                this.props.syncFetch.payload.screen === SCREEN_ADD_CLIENT.name
            ) {
                this.props.dispatch(syncUpdateScreen(''));
                this.newClientPromise(this.stringJsonDataForm);
            }
        })();
    }

    navigationButtonPressed(event) {
        switch (event.buttonId) {
            case 'backPress':
                this.handleBackPress();
                break;
            default:
                break;
        }
    }

    onLocationChange = location => {
        if (location) {
            this.setState({ location });
        }
    };

    getFormData = dataForm => {
        const newForm = this.state.form;

        newForm.forEach(item => {
            item.value = dataForm[item.field];
        });

        this.setState({ dataForm, form: newForm });
    };

    consultCNPJ = ({ value: cnpj, field: fieldId }) => {
        const {
            configRequest,
            state: { form },
        } = this;
        const stateField = form.find(({ field }) => field === fieldId);
        const isCNPJField = stateField && stateField.type === 'CNPJ';
        const { can_consult: canConsult, config_data: configData } =
            configRequest || {};
        const { url, token, mapping } = configData || {};
        const canDoRequest = canConsult && isCNPJField && cnpj.length > 13;

        if (canDoRequest) {
            this.setState({
                visible: false,
                consultCNPJRequesting: true,
            });
            PreRegisterLib.consultCNPJ({ url, token, cnpj })
                .then(resp => {
                    const filledFields = form.map(field => {
                        const newField = PreRegisterLib.fillFormField(field, resp, mapping);
                        return newField;
                    });

                    const formattedForm = {};
                    for (const fieldName of filledFields) {
                        formattedForm[fieldName.field] =
                            fieldName.value === '-' || fieldName.value === '--'
                                ? ''
                                : fieldName.value;
                    }

                    this.setState({
                        form: filledFields,
                        autoFillData: formattedForm,
                    });
                })
                .catch(err => {
                    createNotifyError('Não foi possível consultar o CNPJ/CPF');
                })
                .finally(() =>
                    this.setState({ visible: true, consultCNPJRequesting: false }),
                );
        }
    };

    getForm = async data => {
        const { item } = this.props?.route?.params || {};  
        let form = JSON.parse(_.get(data, 'form_data', '[]'));

        if (item) {
            const pkey = item.chave || item.pkey;

            const formInCache = await CacheDataController.getData(
                `@pcadForm_${pkey}`,
            );

            const formParsed = JSON.parse(formInCache || '{}');

            if (formParsed) {
                form = form.map(f => {
                    const newField = { ...f };
                    if (formParsed[f.field]) {
                        newField.value = formParsed[f.field];
                    }
                    newField.status_v1 = item ? f.can_edit : true;
                    return newField;
                });
            }
            form = form.map(f => {
                const newField = { ...f };
                if (f.field === 's_nome') {
                    newField.value = item.nome;
                }
                if (f.field === 's_fantasia') {
                    newField.value = item.codigo;
                }
                if (f.type === 'CNPJ' || f.type === 'CPF') {
                    newField.value = item.cnpjcpf;
                }
                newField.status_v1 = item ? f.can_edit : true;
                return newField;
            });

        }

        return form;
    };

    async loadParametersPreRegister() {
        try {
            this.setState({ visible: true });
            const { item = {} } = this.props;
            const pkey = item?.chave || item?.pkey;
            const data = await PreRegisterLib.getParametersPreRegister();
            const dataParsed = JSON.parse(data);
            const form = await this.getForm(dataParsed);
            const autoFillStr = _.get(dataParsed, 'auto_fill', '[]');
            const autoFill = JSON.parse(autoFillStr);
            this.configRequest = autoFill;
            const dataForm = {};

            const newForm = [...form].map(field => {
                const { value, ...rest } = field;
                dataForm[field.field] = value;

                return rest;
            });

            this.setState({ form: newForm, initialValues: dataForm });
        } catch (err) {
            const dataErro = JSON.parse(err);
            createNotifyError(dataErro?.[0]?.mensagem || 'Erro ao carregar parâmetros');
            this.setState({ visible: false });
        }
    }

    handleLocationGPS = () => {
        const { dispatch, permission } = this.props;
        const { gpsConfig } = this.state;
        const permissions = _.get(permission, 'payload.response', {});

        const getGpsConfig = getGpsConfigs(
            'required_in_before_registering',
            'notify_saving_before_registering',
        );

        this.setState({ gpsConfig: getGpsConfig }, () => {
            if (permissions.location !== 'authorized') {
                const {
                    showPermissionLocation,
                    requiredCollectGpsPoint,
                    notifyOnSaving,
                } = gpsConfig;
                if (
                    showPermissionLocation ||
                    requiredCollectGpsPoint ||
                    notifyOnSaving
                ) {
                    dispatch(handlePermissions(['location']));
                }
            }
        });
    };

    isFormValid = valid => {
        this.setState({ formIsValid: valid });
    };

    newClient = () => {
        const { dataForm, autoFillData } = this.state;
        const data =
            autoFillData && Object.values(autoFillData).length > 0
                ? { ...autoFillData, ...dataForm }
                : { ...dataForm };

        const { syncFetch } = this.props;
        const { statusSync } = syncFetch.payload;

        const stringJsonDataForm = JSON.stringify(data);

        if (statusSync !== SYNC_PROCESS_SALES) {
            const formPhotos = Object.values(data).filter(v => Boolean(v && v.uri));

            this.newClientPromise(stringJsonDataForm).then(success => {
                if (success && formPhotos.length > 0) {
                    this.props.dispatch(
                        savePhotoAnswer({
                            payload: {
                                photo: formPhotos,
                            },
                        }),
                    );
                }
            });
        } else {
            this.stringJsonDataForm = stringJsonDataForm;
            this.props.dispatch(syncUpdateScreen(SCREEN_ADD_CLIENT.name));
        }
    };

    async newClientPromise(stringJsonDataForm) {
        const { location, gpsConfig } = this.state;
        const item = _.get(this.props, 'route.params.item', {});
        const pkey = item.pkey || item.chave;

        try {
            const requiredCollectGpsPoint = false;
            const { latitude = 0, longitude = 0 } = location || {};

            if (requiredCollectGpsPoint && (!latitude && !longitude)) {
                const errMsg = [{ mensagem: 'Coleta de ponto obrigatória' }];
                throw JSON.stringify(errMsg);
            }

            const resp = await PreRegisterLib.sendDataForm(
                pkey || '',
                stringJsonDataForm,
            );

            const parseResp = JSON.parse(resp || '{}');

            if (parseResp?.[0]?.mensagem) {
                createNotifyError(parseResp?.[0]?.mensagem);
                return false;
            }

            const { pkey_pcad: pcadKey } = JSON.parse(resp || '{}');
            if (!pkey) {
                await PreRegisterLib.saveEvent(pcadKey, JSON.stringify(location));
            }
            await CacheDataController.saveData(
                `@pcadForm_${pcadKey}`,
                stringJsonDataForm,
            );
            this.props.dispatch(newClientsFetch());
            this.props.navigation.pop();

            // syncUpload();
            return true;
        } catch (err) {
            console.log('ERROR: ', err);
            const dataErro = typeof err === 'string' ? JSON.parse(err) : err;
            createNotifyError(dataErro?.[0]?.mensagem || 'Erro ao adicionar cliente');
            return false;
        }
    }

    handleBackPress = () => {
        if (existsAnsweredField(this.state.dataForm)) {
            showAlert(`${translate('discardRegistration')}`, `${translate('discardChangesInFormAddClient')}`, 
            () => this.props.navigation.pop()
            );
        } else {
            this.props.navigation.pop();
        }
        return true;
    };

    setLeftButton = () => {
        this.props.navigation.setOptions({
          headerLeft: () => (
            <TouchableOpacity onPress={() => {
              this.handleBackPress();
            }}
            >
              <Image
                source={Images.iconBack}
                style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
              />
            </TouchableOpacity>
          ),
        });
      };

    render() {
        const {
            form,
            visible,
            formIsValid,
            location,
            gpsConfig,
            consultCNPJRequesting,
            initialValues,
            validateFields,
        } = this.state;

        return (
            <AddClient
                form={form}
                initialValues={initialValues}
                visible={visible}
                formIsValid={formIsValid}
                isFormValid={this.isFormValid}
                getFormData={this.getFormData}
                onSaveButtonPress={this.newClient}
                gpsConfig={gpsConfig}
                location={location}
                onLocationChange={this.onLocationChange}
                consultCNPJ={this.consultCNPJ}
                consultCNPJRequesting={consultCNPJRequesting}
                validInpts={validateFields}
            />
        );
    }
}

AddClientContainer.propTypes = {
    syncFetch: PropTypes.object,
    configLib: PropTypes.object,
    permission: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        syncFetch: state.syncFetch,
        configLib: state.configLibFetch,
        photoAnswer: state.savePhotoAnswer,
        permission: state.permissionsFetch,
    };
}

export default connect(mapStateToProps)(AddClientContainer);
