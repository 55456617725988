import React, { Component } from 'react';
import { color } from '~/common';
import { View, StyleSheet, Text } from 'react-native';
import Button from '~/components/Button';
import LottieView from 'lottie-react-native';
import Images from '@assets/Images';
import translate from '../../locales';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
  },
  textSuccess: {
    color: color.successColor,
    fontSize: 18,
  },
});

class Finish extends Component {
  componentDidMount() {
    this.anim.play();
  }
  render() {
    const { onPressHome, cart } = this.props;

    return (
      <View style={styles.container}>
        <LottieView
          ref={ref => {
              this.anim = ref;
            }}
          style={{ width: 60, height: 60 }}
          speed={0.5}
          autoPlay
          source={Images.lottieCheck}
          enableMergePathsAndroidForKitKatAndAbove
        />
        <Text style={styles.textSuccess}>{`${translate('succesfullyFinishOrder')}`}</Text>
        <Text style={styles.textSuccess}>{cart.codigo}</Text>
        <Button style={{ margin: 16 }} title={`${translate('goToHome')}`} onPress={onPressHome} />
      </View>
    );
  }
}

Finish.propTypes = {
  onPressHome: PropTypes.func.isRequired,
  cart: PropTypes.object.isRequired,
};

export default Finish;
