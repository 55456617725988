import React, { Component } from 'react';
import { FlatList, View } from 'react-native';
import Spinner from 'react-native-loading-spinner-overlay';
import Images from '../../../assets/Images';

import { EmptyView } from '../../components';
import translate from '../../locales';
import SaleOpportunityItem from './SaleOpportunityItem';

import PropTypes from 'prop-types';

class SalesOpportunity extends Component {
  onPress = (item) => {
    this.props.onPress(item);
  }

  listEmptyComponent = () => (
    <EmptyView
      icon={Images.iconBulletedList}
      message={`${translate('noSalesOpportunity')}`}
    />
  )

  keyExtractor = (item, index) => `${item.u_pkey}-${index}`;

  renderItem = ({ item }) => (
    <SaleOpportunityItem
      saleOpportunityItem={item}
      onPress={this.onPress}
    />
  )

  render() {
    const {
      data,
      loading,
    } = this.props;

    return (
      <View>
        <Spinner
          visible={loading}
          overlayColor="rgba(0, 0, 0, 0.7)"
          textStyle={{ color: '#FFF' }}
        />
        { !loading ? (
          <FlatList
            data={data}
            keyExtractor={this.keyExtractor}
            ListEmptyComponent={this.listEmptyComponent}
            renderItem={this.renderItem}
          />
          )
          : null
        }
      </View>
    );
  }
}

SalesOpportunity.propTypes = {  
    data: PropTypes.array,
    loading: PropTypes.bool,
    onPress: PropTypes.func,
};

export default SalesOpportunity;
