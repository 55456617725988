import React, { useMemo, useCallback } from 'react';
import { View } from 'react-native';
import { FlashList } from '@shopify/flash-list';
import CardResume from './CardResume';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { getCurrencyFormatter, parseStrToFloat } from '~/utils/Currency';
import Masked from 'vanilla-masker';
import translate from '~/locales';
import Images from '@assets/Images';

const defaultResults = {
  orders: [
    {
      id: 1,
      title: `${translate('findedOrders')}`,
      icon: Images.iconCart,
      type: 'length',
      field: '',
    },
    {
      id: 2,
      title: `${translate('totalFatured')}`,
      icon: Images.iconUSD,
      field: 'valor',
      type: 'sum',
      format: 'money',
    },
  ],
};

export default function Resume({ context, config, list }) {
  const keyExtractor = item => `${item.id}`;

  const renderItem = ({ item }) => (
    <CardResume icon={item.icon} result={item.result} title={item.title} />
  );

  const getResult = useCallback(
    ({ type, field, format: formatType }) => {
      let res = '';
      switch (type) {
        case 'sum':
          res = _.sumBy(list, i =>
            parseFloat(parseStrToFloat(i[field])),
          ).toFixed(2);
          break;
        case 'max':
        case 'min':
          res = _[`${type}By`]?.(list, i => {
            if (formatType === 'money') {
              return Number(`${i[field]}`.replace(',', '').replace('.', ''));
            } else if (formatType === 'date') {
              return moment(i[field], 'DD-MM-YYYY HH:mm:ss').unix();
            }
          })?.[field];
          break;
        case 'length':
          res = list.length;
          break;
        default:
          break;
      }

      if (formatType) {
        switch (formatType) {
          case 'date':
            res = moment(res, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YY HH:mm');
            break;
          case 'text':
            res = _.deburr(res);
            break;
          case 'money': {
            const moneyFormatter = getCurrencyFormatter('price', {
              noSymbolCurrency: false,
            });
            res = Masked.toMoney(Masked.toNumber(res), moneyFormatter);
            break;
          }
          default:
            break;
        }
      }
      return res;
    },
    [list],
  );

  const cards = useMemo(
    () => {
      const infos =
        config?.fields?.length > 0
          ? [...defaultResults[context], ...config.fields]
          : defaultResults[context];
      return infos?.map(i => ({
        ...i,
        result: getResult(i),
      }));
    },
    [config, context, getResult],
  );

  return config?.visible ? (
    <View
      style={{
        paddingHorizontal: 16,
        marginTop: 6,
      }}>
      <FlashList
        keyExtractor={keyExtractor}
        data={cards}
        renderItem={renderItem}
        estimatedItemSize={390}
        removeClippedSubviews
        numColumns={2}
      />
    </View>
  ) : null;
}

Resume.propTypes = {
  context: PropTypes.string.isRequired,
  config: PropTypes.objectOf(PropTypes.any),
  list: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};
