/* @flow */
import React, { Component } from 'react';
import { Alert, View, Text, StyleSheet, TouchableOpacity, Image } from 'react-native';
import { connect } from 'react-redux';
import Images from '@assets/Images';

import { setPushingScreen } from '../../store/pushingScreen/slice';
import TextUtils from '../../utils/TextUtils';
import {
  getListItinerary,
  resetItineraryFetch,
  ITINERARY_FAILURE,
  ITINERARY_SUCCESS,
} from '../../store/itineraryFetch/slice';
import moment from 'moment';
import Today from './Today';
import {
  SCREEN_OFF_ROUTE_CUSTOMERS,
  SCREEN_SOLICITATIONS,
  SCREEN_ITINERARY,
} from '../screens';

import translate from '../../locales';
import _ from 'lodash';

import { fonts } from '~/utils/Fonts';
import { SYNC_FINALLY } from '~/constants/Sync';
import { getListWithPagination } from '~/utils/FastSellerUtils';
import ClientsModule from '../../modules/ClientsModule';
import AccountsModule from '../../modules/AccountsModule';
import { colors } from '../../../components_base/src/values';
import ActivitiesScreen from '../Activities/handleScreen';
import onPressHelper from '~/utils/onPressHelper';
import { isShowHTML } from '../../utils/GlobalUtils';
import { Navigation } from 'react-native-navigation';
import NavigationHelper from '../NavigationHelper';
import { SYNC_UPDATE_STATUS } from '~/store/syncFetch/slice';
import { CONFIG_LIB_SUCCESS } from '~/store/configLibFetch/slice';
import { currentScreenVisible } from '~/store/currentScreenVisible/slice';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  badge: {
    flex: 1,
    alignItems: 'flex-start',
    marginLeft: 10,
  },
  badgeContent: {
    fontSize: 10,
    color: '#FFF',
    fontFamily: fonts.QuicksandBold,
    borderRadius: 5,
    backgroundColor: colors.primaryColor,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
});

class TodayContainer extends Component {
  constructor(props) {
    super(props);

    this.dateSelected = '';
    this.showHTML = false;
    this.firstRender = true;
    this.state = {
      showSearch: false,
      searchText: '',
      events: {},
      solicitations: [],
      collapseAgenda: false,
      isMapView: false,
      loading: false,
    };
    this.shouldNotify = false;
    this.localProps = {};
    this.todayItinerary = null;
  }

  updateChildData = data => {
    this.setState({ loading: true });
    this.shouldNotify = false;
    setTimeout(() => {
      this.setState({ events: this.localProps.itinerary.data, loading: false });
    }, 100);
  };

  async loadFirstClientList() {
    if (this.todayItinerary === null) {
      const dateSelected = this.dateSelected;
      const itinerary = await this.props.dispatch(
          getListItinerary(dateSelected),
      );
      if (!itinerary.error) {
        this.todayItinerary = itinerary.data;
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetItineraryFetch());
  }
  componentDidDisappear() {
    this.backHandler?.remove();
  }

  componentDidMount() {
    const [date] = moment()
        .format()
        .split('T');
    this.dateSelected = date;
    this.showHTML = isShowHTML(this.props.configLib?.payload, 'itinerary');
    this.loadFirstClientList();
    this.setCurrentModule(SCREEN_ITINERARY.name.toUpperCase());
    if (this.firstRender) {
      this.dispatchItinerary(this.dateSelected);
      setTimeout(() => {
        this.setRightButtons();
      }, 100);
      this.firstRender = false;
    }
    this.props.dispatch(
        currentScreenVisible({
          screen: this.props.componentId,
          screenName: SCREEN_ITINERARY.name,
        }),
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    (() => {
      if (nextProps.configLib.type === this.props.configLib.type) {
        return;
      }
      if (nextProps.configLib.type !== CONFIG_LIB_SUCCESS) {
        return;
      }
      this.props.dispatch(getListItinerary(this.dateSelected));
    })();
    (() => {
      if (nextProps.itinerary.type === this.props.itinerary.type) {
        return;
      }
      if (nextProps.itinerary.type !== ITINERARY_FAILURE) {
        return;
      }
      this.setState({ loading: false });
    })();
    (async () => {
      if (nextProps.itinerary.type === this.props.itinerary.type) {
        return;
      }
      if (nextProps.itinerary.type !== ITINERARY_SUCCESS) {
        return;
      }

      const eventsDate = { [this.dateSelected]: nextProps.itinerary.data };

      this.setState({ events: eventsDate, loading: false });
    })();
    (async () => {
      const { syncFetch } = nextProps;

      if (
          this.props.itinerary?.payload?.length > 0 &&
          JSON.stringify(this.props.itinerary.payload) !==
          JSON.stringify(nextProps.itinerary.payload)
      ) {
        if (
            !!this.todayItinerary &&
            this.todayItinerary !== JSON.stringify(nextProps.itinerary.payload)
        ) {
          const selectedDate = new Date(nextProps.itinerary.selectedDate);
          const itineraryDay = selectedDate.getUTCDate();
          const today = new Date();
          if (itineraryDay === today.getDate()) {
            this.todayItinerary = JSON.stringify(nextProps.itinerary.payload);
            this.localProps = nextProps;
            this.shouldNotify = true;
          }
        }
      }

      if (syncFetch.type !== SYNC_UPDATE_STATUS) {
        return;
      }
      if (
          syncFetch.payload.statusSync === this.props.syncFetch.payload.statusSync
      ) {
        return;
      }
      if (syncFetch.payload.statusSync === SYNC_FINALLY) {
        const memorySize = this.props.configLib.tam_memoria_paginacao || 0;

        if (!this.props.configLib.tam_memoria_paginacao) {
          return;
        }
        const date = this.dateSelected;
        const dateUnix = date ? `${moment(date).unix()}` : '';

        const scheduling = await getListWithPagination(
            ClientsModule.getScheduleListing,
            memorySize,
            dateUnix,
        );

        if (
            this.state.events[this.dateSelected] &&
            scheduling.length !== this.state.events[this.dateSelected].length
        ) {
          Alert.alert(
              `${translate('changeOcurred')}`,
              `${translate('scriptChange')}`,
              [
                {
                  text: 'Ok',
                  onPress: () => {
                    this.dispatchItinerary(this.dateSelected);
                  },
                },
              ],
          );
        }
      }
    })();
    if (nextProps.configLib) {
      const { configLib } = nextProps;
      this.showHTML = isShowHTML(configLib?.payload, 'itinerary');
    }
  }

  onSelectDate = date => {
    this.dateSelected = date.dateString;
    this.dispatchItinerary(this.dateSelected);
  };

  onChangeText = text => {
    clearTimeout(this);
    setTimeout(() => {
      this.setState({ searchText: text });
    }, 400);
  };

  onSelectOffRouteCustomer = () => {
    this.props.dispatch(setPushingScreen(true));
    NavigationHelper.push(
        this.props.componentId,
        SCREEN_OFF_ROUTE_CUSTOMERS.name,
        SCREEN_OFF_ROUTE_CUSTOMERS.title,
        {
          customersInRoute: this.state.events[this.dateSelected],
          solicitations: this.state.solicitations,
        },
    );
  };

  onClickOnSelectOffRouteCustomer = onPressHelper.debounce(
      this.onSelectOffRouteCustomer,
  );

  pushScreen = (screen, title, props) => {
    this.props.navigation.pop(2);
    setTimeout(() => {
    this.props.navigation.navigate(screen.name, {
        dateSelected: this.dateSelected,
        title,
        ...props,
        });
      }, 2000);
  };

  onPressItem = async event => {
    const activitiesScreen = new ActivitiesScreen(
        this.pushScreen,
        this.props.dispatch,
        this.dateSelected,
        event,
        this.state.events,
        'itinerary',
    );
    activitiesScreen.handleNextScreen(event, this.dateSelected);
  };

  onPressItemHelper = onPressHelper.debounce(this.onPressItem);

  onPressBadgeButton = () => {
    NavigationHelper.push(
        this.props.componentId,
        SCREEN_SOLICITATIONS.name,
        SCREEN_SOLICITATIONS.title,
    );
  };

  componentWillDisappear() {
    this.setState({ collapseAgenda: true });
  }

  async navigationButtonPressed(event) {
    switch (event.buttonId) {
      case 'add':
        this.onClickAdd();
        break;
      case 'search':
        this.setState({ showSearch: !this.state.showSearch, searchText: '' });
        break;
      case 'solicitations':
        this.onPressBadgeButton();
        break;
      case 'bottomTabSelected': {
        this.setCurrentModule(SCREEN_ITINERARY.name.toUpperCase());
        break;
      }
      case 'mapView': {
        this.setState({ isMapView: !this.state.isMapView });
        break;
      }
      default:
        break;
    }
  }

  setRightButtons = () => {
    this.props.navigation.setOptions({
      headerLeft: () => (null),
      headerRight: () => (
          <View style={{ flexDirection: 'row' }}>
            <TouchableOpacity onPress={() => {
              this.setState({ showSearch: !this.state.showSearch, searchText: '' });
            }}
            >
              <Image
                  source={Images.iconSearch}
                  style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 20 }}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => {
              this.setState({ isMapView: !this.state.isMapView });
            }}
            >
              <Image
                  source={Images.appToday}
                  style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 10 }}
              />
            </TouchableOpacity>
          </View>
      ),
    });

    const canRequestServiceOffRoute = _.get(
        this.props.configLib?.payload,
        'configuracoes.can_request_service_off_route',
        false,
    );

    if (canRequestServiceOffRoute) {
      this.props.navigation.setOptions({
        headerLeft: () => (null),
        headerRight: () => (
            <View style={{ flexDirection: 'row' }}>
              <TouchableOpacity onPress={() => {
                this.setState({ showSearch: !this.state.showSearch, searchText: '' });
              }}
              >
                <Image
                    source={Images.iconSearch}
                    style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 10 }}
                />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => {
                this.setState({ isMapView: !this.state.isMapView });
              }}
              >
                <Image
                    source={Images.appToday}
                    style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 10 }}
                />
              </TouchableOpacity>
              <TouchableOpacity onPress={this.onPressBadgeButton}>
                <Image
                    source={Images.iconBellFill}
                    style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 10 }}
                />
              </TouchableOpacity>
            </View>
        ),
        });
    }
  };

  setCurrentModule = currentModule => {
    return new Promise((resolve, reject) => {
      AccountsModule.setCurrentModule(currentModule, resolve, reject);
    });
  };

  getSolicitations = () => {
    ClientsModule.listSolicitations()
        .then(resp => {
          this.setState({ solicitations: JSON.parse(resp) });
        })
        .catch(err => {});
  };

  dispatchItinerary = date => {
    this.props.dispatch(getListItinerary(date));
    this.getSolicitations();
  };

  getFilteredData() {
    const search = TextUtils.slugfy(this.state.searchText);
    const { dateSelected } = this;
    const { itinerary } = this.props;
    let eventsFilters = [];

    if (itinerary.payload) {
      eventsFilters = itinerary?.payload?.filter(event => {
        if (event) {
          return (
              (event.codigo && TextUtils.slugfy(event.codigo).includes(search)) ||
              TextUtils.slugfy(event.nome).includes(search) ||
              TextUtils.slugfy(event.fantasia).includes(search)
          );
        }
        return false;
      });

      if (eventsFilters) {
        return { [dateSelected]: eventsFilters };
      }

      return {};
    }

    return {};
  }

  renderOffRoute = (item, canRequestServiceOffRoute) => {
    const { fora_de_rota: offRoute } = item;
    if ((canRequestServiceOffRoute, offRoute)) {
      return (
          <View style={styles.badge}>
            <Text style={styles.badgeContent}>
              {translate('ClientOffRoute').toUpperCase()}
            </Text>
          </View>
      );
    }
    return null;
  };

  render() {
    const {
      listagem_campos_visibilidade: listFieldsVisibility,
    } = this.props.configLib?.payload;
    const canRequestServiceOffRoute = _.get(
        this.props.configLib?.payload,
        'configuracoes.can_request_service_off_route',
        false,
    );
    const itinerary = listFieldsVisibility
        ? listFieldsVisibility.itinerary
        : undefined;
    const { searchText } = this.state;

    return (
        <Today
            showIsProcessDone={itinerary && itinerary.showIsProcessDone}
            date={this.dateSelected}
            canRequestServiceOffRoute={canRequestServiceOffRoute}
            itineraryConfig={itinerary}
            schedule={this.getFilteredData()}
            showSearch={this.state.showSearch}
            loading={this.props.itinerary?.loading}
            failure={this.props.itinerary.erro}
            onPressItem={this.onPressItemHelper}
            onPressDate={this.onSelectDate}
            onChangeText={this.onChangeText}
            onSelectOffRouteCustomer={this.onClickOnSelectOffRouteCustomer}
            renderOffRoute={item =>
                this.renderOffRoute(item, canRequestServiceOffRoute)
            }
            collapseAgenda={this.state.collapseAgenda}
            isMapView={this.state.isMapView}
            showHTML={this.showHTML}
            updateChildData={this.updateChildData}
            shouldNotify={this.shouldNotify}
            searchText={searchText}
        />
    );
  }
}

TodayContainer.propTypes = {
  dispatch: PropTypes.func,
  itinerary: PropTypes.any,
  navigator: PropTypes.shape({
    addnavigationButtonPressed: PropTypes.func,
    setButtons: PropTypes.func,
    setStyle: PropTypes.func,
    push: PropTypes.func,
    switchToTab: PropTypes.func,
  }),
  configLib: PropTypes.any,
  syncFetch: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    itinerary: state.itineraryFetch,
    configLib: state.configLibFetch,
    syncFetch: state.syncFetch,
  };
}

export default connect(mapStateToProps)(TodayContainer);