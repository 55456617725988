/* @flow */

import React, { Component } from 'react';
import { Platform } from 'react-native';
import SelectMenu from '../../SelectMenu';

import PropTypes from 'prop-types';

class List extends Component {
  state = {};
  UNSAFE_componentWillMount() {
    let dataSource = this.props.data_source;
    if (!Array.isArray(this.props.data_source)) {
      dataSource = this.props.data_source.split(',');
    }

    this.setState(
      { selectedValue: this.props.value ? this.props.value : '-' },
      () =>
        this.props.onChangeValue(
          this.props.field,
          this.state.selectedValue,
          'typed',
        ),
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const formattedValue = nextProps.value;
    const formattedStateValue = this.state.selectedValue;
    if (this.props.forcePropsValue && formattedValue !== formattedStateValue) {
      this.setState({ selectedValue: formattedValue });
    }
  }

  onPressOption = (eventName, index) => {
    if (eventName !== 'itemSelected') {
      return;
    }
    let dataSource = this.props.data_source;
    if (!Array.isArray(this.props.data_source)) {
      dataSource = this.props.data_source.split(',');
    }
    this.setState({ selectedValue: dataSource[index] }, () => {
      this.props.onChangeValue(
        this.props.field,
        this.state.selectedValue,
        'typed',
      );
    });
  };

  onChangeValue = value => {
    this.setState({ selectedValue: value }, () => {
      this.props.onChangeValue(
        this.props.field,
        this.state.selectedValue,
        'typed',
      );
    });
  };

  renderEntry(values, field) {
    let dataSource = this.props.data_source;
    if (!Array.isArray(this.props.data_source)) {
      dataSource = this.props.data_source.split(',');
    }
    return (
      <SelectMenu
        field={field}
        required={this.props.required}
        outline={Platform.OS === 'ios'}
        style={this.props.style}
        title={this.props.title}
        value={this.state.selectedValue}
        actions={dataSource}
        onChangeValue={this.onChangeValue}
        onPress={this.onPressOption}
        editable={this.props.editable}
      />
    );
  }

  render() {
    let dataSource = this.props.data_source;
    if (!Array.isArray(this.props.data_source)) {
      dataSource = this.props.data_source.split(',');
    }
    return this.renderEntry(dataSource, this.props.field);
  }
}

List.propTypes = {
  field: PropTypes.string,
  data_source: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.any,
  required: PropTypes.bool,
  value: PropTypes.string,
  editable: PropTypes.bool,
  onChangeValue: PropTypes.func,
};

List.defaultProps = {
  onChangeValue: () => {},
  editable: true,
};

export default List;
