export default {
  AccessSolicitation: 'Access Solicitation',
  app_name: 'PoliEquipes App',
  Attributes: 'Attributes',
  CampaignReceived: 'Campaign Received',
  cancel: 'Cancel',
  Characteristics: 'Characteristics',
  Choose_Filters: 'Choose Filters',
  complementary: 'Complementary',
  conditionsSalesChild: 'Child Sales Conditions',
  hello: 'Hello',
  moreProductsScreen: 'More Products',
  no: 'No',
  noOpenFilePDF: 'It is not possible to open the pdf file.',
  normal: 'Normal',
  NotifyProductReceived: 'Notify Product Received',
  chooseCopyType: 'Copy order',
  questionCopyComplementary: 'What kind of copy do you want to make?',
  registerAgain: 'Register Again',
  creditAdjustment: 'Update Registration',
  shipping: 'Shipping',
  shippingCompany: 'Shipping Company',
  shippingEstimate: 'Shipping Estimate',
  lostShippingMessage:
    'If you exit, you will lose shipping information that has already been calculated.',
  stay: 'Stay',
  connectionError: 'Connection error.',
  errorListSC: 'It was not possible to list the shipping companies.',
  recalculateShipping: 'Recalculate Shipping',
  priceOrderChangeMSG:
    'The total price of the order has been changed! \nIt is necessary to recalculate the frequency.',
  recalculate: 'Recalculate',
  waitForSync: 'Waiting for sync',
  syncInBackground: 'Syncing in the background',
  syncBackground:
    'Sync running in the background, wait for the sync to finish.',
  yes: 'Yes',
  yesAll: 'Yes to all',
  yesSave: 'Yes, save',
  yesConfirm: 'Yes, confirm',
  yesRemove: 'Yes, remove',
  noRemove: 'No, remove',
  yesDiscard: 'Yes, discard',
  yesCancel: 'Yes, cancel',
  yesClean: 'Yes, clean',
  yesApply: 'Yes, apply',
  SolicitateAccess: 'Solicitate Access',
  Stores: 'Stores',
  Orders: 'Orders',
  order_successful: 'Order Successful',
  Notifications: 'Notifications',
  NotificationsDetail: 'Notifications detail',
  noNotifications: 'No notifications',
  Registrations: 'Registrations',
  priceNotAvailable: 'Price Not Available',
  productNotAvailable: 'Product Not Available',
  Security: 'Security',
  Customers: 'Customers',
  Profile: 'Profile',
  Pay: 'Pay',
  Buy: 'Buy',
  Confirm_Buy: 'Confirm Buy',
  Confirm: 'Confirm',
  Register: 'Register',
  continue: 'Continue',
  Close: 'Close',
  Confirm_your_Buy: 'Confirm you buy',
  Cart: 'Cart',
  Promotions: 'Promotions',
  Sort: 'Sort',
  Home: 'Beginning',
  My_Cards: 'My Cards',
  Settings: 'Settings',
  My_Stores: 'My Stores',
  Near_Stores: 'Near Stores',
  Search_For: 'Search For',
  search: 'Search...',
  StoreSolicitation: 'Store solicitation',
  See_on_Map: 'See on map',
  Enter_or_SignUp: 'Enter or Signup',
  Enter_with_your_credentials: 'Enter with your credentials',
  logOut: 'Logout',
  youReallyWantLeave: 'Do you really want leave?',
  really_confirm_order:
    'Do you really want to go? When you click on Confirm, your order will be sent and it will not change. \nYou want to confirm',
  Order: 'Order',
  no_orders_found: 'No orders found',
  no_campaigns_found: 'No campaings found',
  noStores: 'No Stores.',
  noStoresMaybeSearch:
    'It is possible that no one will access it. \nIntend to search for a store and request access to the "Search" tab',
  noStoresUseSearch:
    'Enter the name of the field in the field "Search for friends ... " and click on "Search " to search for friends.',
  toAccessStore:
    'To access the products of the store and place orders, this store must be accepted as a customer. \n\nTo request your access, click on the button "Request access ", enter the CNPJ or CPF of the company for which you want to place orders and confirm.',
  Solicitations: 'Solicitations',
  Show_QRCode: 'Show QRCode',
  Change_Password: 'Change Password',
  MyWallet: 'My Wallet',
  INFORMATION: 'INFORMATION',
  Payment_Term: 'Payment Term',
  Payment: 'Payment',
  Buyer: 'Buyer',
  Conditions: 'Conditions',
  IntegrationStatus: 'Integration Status',
  PaymentStatus: 'Payment Status',
  original_price: 'Original price',
  changePrice: 'CHANGE PRICE',
  Messages: 'Messages',
  items: 'Items',
  Identity: 'Identity',
  BasicInfo: 'Basic Info',
  EnterYourName: 'Enter Your Name',
  ExhibitionName: 'Exhibition Name',
  identityNumber: 'SSN',
  cnpj: 'NIN',
  addition: 'Addition',
  reduction: 'reduction',
  initial: 'initial',
  taxInfo: 'Tax info',
  priceScale: 'Price Scale',
  scale: 'Scale',
  taxes: 'Taxes',
  name: 'Name',
  CompleteName: 'Complete Name',
  QR_CODE: 'QR CODE',
  Info: 'Info',
  Information: 'Information',
  Description: 'Description',
  ComplementaryInformations: 'Complementary Informations',
  informations: 'informations',
  details: 'Details',
  Alerts: 'Alerts',
  My_Orders: 'My Orders',
  Create_Route: 'Create Route',
  created_at: 'Created at',
  Store: 'Store',
  Campaigns: 'Campaigns',
  Category: 'Category',
  Categories: 'Categories',
  Campaign: 'Campaign',
  Filter: 'Filter',
  Filter_By_Category: 'Filter By Category',
  cleanFilter: 'Clear Filter',
  login: 'LogIn',
  tryAgain: 'Try Again',
  noMessages: 'No Messages',
  emptyCart: 'Empty Cart',
  Open: 'Open',
  Waiting_Acceptance: 'Waiting Acceptance',
  Waiting_Response: 'Waiting Response',
  wait: 'Wait',
  Canceled: 'Canceled',
  Done: 'Done',
  Expired: 'Expired',
  Show_Archived: 'Show Archived',
  hide: 'Hide',
  Only_Near_Stores: 'Only Near Stores',
  send: 'Send',
  Address: 'Address',
  Street: 'Street',
  Number: 'Number',
  City: 'City',
  District: 'District',
  Complement: 'Complement',
  ZipCode: 'ZipCode', // dúvida
  Enter: 'Enter',
  EditProfile: 'Edit Profile',
  ENTER: 'ENTER',
  Email: 'Email',
  password: 'Password',
  NewPassword: 'New Password',
  CardNumber: 'Card Number',
  CardCPF: 'CPF do Titular (só números)', // dúvida
  CardHolder: 'Nombre del titular (como en la tarjeta)',
  CardValidDate: 'Duracion (Ex: 01/2018)',
  CVV: 'CVV',
  AddCard: 'Add Card',
  CardInfo: 'Card Info',
  aditionalInformations: 'Aditional Informations',
  TypeNewPasswordAndConfirm: 'Type New Password And Confirm',
  Confirm_Password: 'Confirm Password',
  Create: 'Create',
  add: 'Add',
  Choose: 'Choose',
  phone: 'Phone',
  phones: 'Phones',
  status: 'Status',
  State: 'State',
  Minimum: 'Minimum',
  Maximum: 'Maximum',
  upUntil: 'Up Until',
  Visited_Stores: 'Visited Stores',
  no_near_store_found: 'No near store found',
  no_title: 'No title',
  didnt_visit_any_store: 'didnt visit any store',
  loading_visited_stores: 'Loading visited stores',
  loading_near_stores: 'Loading near stores',
  loading: 'Loading',
  loadingStores: 'Loading Stores...',
  loadingCustomers: 'Loading Customers...',
  loadingCart: 'Loading Cart...',
  not_informed: 'Not informed',
  total: 'Total',
  save: 'Save',
  saving: 'Saving...',
  Price_Conditions: 'Price Conditions',
  you_nedd_to_set_email_and_password: 'You nedd To Set Email And Password',
  Companies_You_Can_Buy_From: 'Companies You Can Buy From',
  error_login: 'An error occurred while authenticating',
  error_load_categories: 'There was an error loading the categories.',
  error_load_stores: 'An error occurred while loading the stores.',
  error_load_customers: 'There was an error loading the entries.',
  error_load_orders: 'There was an error loading orders.',
  NotifyMe: 'Notify Me',
  version: 'Version',
  versionUpdate: 'Version Update',
  update: 'Update',
  updateLater: 'Update Later',
  lastDataUpdate: 'Last data update',
  lastDataUpload: 'Last data upload',
  today: 'Today',
  moreInfo: 'More Informations',
  image: 'Image',
  goTo: 'Go To',
  newRegister: 'New Register',
  yourLocation: 'Your Location',
  yourCurrentLocation: 'Your Current Location',
  message: 'Message',
  unabledLocation: 'Location disabled. \nClick here to enable.',
  errorRadiusClientRequiredGPS: 'The client radius is not defined.',
  errorPointRequiredGPS:
    'We couldnt get your location. Compulsory collection of points.',
  errorAreaClientGPS: 'You are far from the customer area',
  outOfPlace: 'You are not on the site',
  inPlace: 'You are in the place',
  inThisPlace: 'You are in this location',
  location: 'Location',
  openActivity: 'Open activity',
  atention: 'Attention',
  changeLocation: 'Change location',
  cantSaveAnswer: 'This activity could not be saved.',
  pleaseTryAgain: 'Try again',
  title: 'Titles',
  pluralAddress: 'Addresses',
  totalLimitCredit: 'Total credit limit',
  viewCart: 'View Cart',
  syncInfo: 'Synchronizing information',
  contactingTheServer: 'Contacting the server',
  weFoundYourInformation: 'We found your information',
  completingTheSettings: 'Completing the settings',
  weAreSendingYourInformations: 'We are sending your informations',
  weAreUpdatingYourData:
    'Wait a little longer! We are still updating your details.\n\nFollow the progress of your update in the Profile menu.',
  WeAreBringingYourInformation: 'We are bringing your information',
  openCreditLimit: 'Open credit limit',
  creditLimitBalance: 'Credit limit balance',
  company: 'Business name',
  companyName: 'Company name',
  code: 'Code',
  fantasy: 'Fantasy',
  change: 'Change',
  contact: 'Contact',
  reason: 'Reason',
  utterance: 'Utterance',
  dueDate: 'Due date',
  portion: 'Part',
  noCustomerLocation: 'Client without registered location.',
  updatedAt: 'Updated at',
  precisionOf: 'Accuracy of',
  copy: 'Copy',
  from: 'Since',
  orderStartedAt: 'The order started on',
  situation: 'Situation',
  edit: 'Edit',
  delete: 'Delete',
  yesDelete: 'Yes, delete',
  dataUpdate: 'Data update',
  sendPhotoOnlyWifi: 'Upload photos with WiFi only',
  avaliate: 'To evaluate',
  takePicture: 'Take the photo',
  selectFromGallery: 'Select from gallery',
  theFieldsAreRequired: 'The fields are required',
  putTheValue: 'Enter the value',
  dateRangeBeforeError: 'Enter a date before the end.',
  dateRangeAfterError: 'Enter a date after the start.',
  beginDate: 'Start date',
  endDate: 'Final date',
  generateReport: 'Generate report',
  youDontHaveFilters: 'You have no filters.',
  incentiveAvailable: 'Incentive available',
  applyIncentive: 'Do you want to apply the incentive?',
  incentiveApplied: 'Incentive already applied!',
  salesman: 'Seller',
  released: 'Released',
  clientBlocked: 'Blocked Client',
  noCustomersThisDay: 'No customers for this day',
  noCustomers: 'No customers found',
  noItems: 'No items',
  registeredAt: 'Registered at',
  needCatchPhotoBeforeAndAfter:
    'You need to capture the before and after photo to save.',
  putSomeDataToSave: 'Fill in some information to save this activity',
  productName: 'Product name',
  selectADate: 'Select a date',
  indeterminatedPlace: 'Indeterminate location',
  kilometers: 'Kilometres',
  kilometer: 'Kilómeter',
  meter: 'meter',
  meters: 'meters',
  locationDistance: 'You are to',
  required: 'Required',
  nonRequired: 'Not required',
  process: 'Process',
  discard: 'Discard',
  wantSave: 'Want to save?',
  wantSaveTime: 'Want to save time?',
  exportToPDF: 'Show PDF',
  saveStopwatchRunning:
    '\nCaptured time has not been saved.\nWhen exiting without saving time will be lost.',
  stopwatchPaused: 'Stopwatch paused',
  errorSaveStopwatch: 'You need to capture uptime before saving!',
  openSettings: 'Open Settings',
  exportPDFOrder: 'PDF order export',
  invalidExportPDFOrder:
    'Unable to export order to PDF.\n\nConfiguration for exporting order to PDF is invalid.',
  messageAccesCameraTitle: 'Permission to use camera',
  messageAccesCamera: 'To continue, we need access to your camera',
  messageAccesLocation: 'To continue, we need access to your location.',
  messageAccessCameraAndLocation:
    'To continue, we need access to your camera and location.',
  messageAccesCameraLocationAndFiles:
    'To continue, we need access to your camera, files and media',
  messageAccessPhotosAndVideos:
    'To continue, we need access to your photos and videos.',
  discardChanges: 'Discard changes',
  discardChangesInForm:
    'Some fields are filled.\nWhen you click on discard your changes will be lost.\n\nDo you want to discard?',
  discardRegistration: 'Discard registration',
  discardChangesInFormAddClient:
    '\nThe customer registration was not saved.\nWhen exiting without saving, the information will be lost.\n\nDo you want to exit and discard the registration?',
  fillSchedulingDate: 'Please fill in the appointment date',
  changesSavedSuccesfully: 'Changes were successfully saved.',
  pendingCheckoutInClient: 'Pending check-out on the client',
  answerToContinue: 'Answer to continue',
  errorActivityInProgress: 'In process. Finish it to access other activities.',
  activitiesProgress: 'Activities Progress',
  processWithoutDescription: 'Process without description',
  unnamedProcess: 'Unnamed process',
  timerAlreadyRegistered: 'Time already registered! \ncannot edit it.',
  activitiesSequenceRequired: 'Respond to activities in sequence',
  itsNecessaryToAnswer: 'You need to answer',
  cantFindTaskDependency: 'No dependent activity was found.',
  contactSupport: 'Contact support',
  taskAlreadyAnswered: 'This activity has already been answered',
  competitorAchievement: 'Achievement of the competition',
  campaingTitle: 'Campaign title',
  campaingNumber: 'Campaign number',
  addClient: 'Add customer',
  noProducts: 'No products',
  removeSelectedItems: 'Remove selected items',
  minPrice: 'The lowest price',
  saveBudget: 'Save budget',
  saveOrderAsBudget:
    'It is not possible to return without finalizing the order or saving it as a quote.\n\nDo you want to save this cart as a quote?',
  cancelOrder: 'Cancel order',
  wantDiscardYourChanges:
    'Your order will not be saved and all items in this order will be discarded.\n\nWould you like to cancel your order?',
  wantRemoveSelectedItems: 'Do you really want to remove the selected items?',
  wantRemoveImage:
    'The selected photo will be removed.\n\nDo you really want to remove your photo?',
  removeImage: 'Remove photo',
  yourChangesWillBeLost:
    'When you return your changes they will be lost. Do you want to come back anyway?',
  orderBy: 'Sort by',
  saleHistory: 'Sales history',
  productsSuggestion: 'Product suggestion',
  noProductsAndCategories: 'You dont have products and categories yet',
  newCart: 'New Cart',
  alreadyExistsOpenedCart: 'There is already a cart open. Finish your cart!',
  inStock: 'In stock',
  inStockLCase: 'in stock',
  stock: 'Stock',
  noStock: 'Out of stock',
  unavailableCombos: 'Combos not available',
  noFinancialTitlesEnvironmental: 'No environmental financial values',
  ascending: 'Ascending',
  descending: 'Descending',
  noFinancingTitles: 'Without financial values.',
  financing: 'Financial',
  goToHome: 'Go to start',
  succesfullyFinishOrder: 'Your order has been completed successfully!',
  noInformation: 'No information.',
  orderPending: 'We found a pending order',
  wantContinueEditingOrder: `We have everything under control, we retrieve 
the order for you.`,
  resolvingPendingOrder:
    'To start a new order, it is necessary to resolve this pending issue. What do you want to do?',
  client: 'Client',
  hasPendingOrder: 'There is a pending order',
  saveOrderBudget: 'Save order as quote',
  doYouWantDeleteOrder: 'Do you want to delete the order?',
  onceConfirmAction: 'Once confirmed, the action cannot be undone.',
  decideLater: 'Decide later',
  yesDeletePendingOrder: 'Yes, delete',
  deleteOrder: 'Delete order',
  restoreOrder: 'Restore order',
  cantFindModule: 'The module could not be found: ',
  doDayClosure: 'Close the day',
  wantToDoDayClosure: 'Do you really want to close the day?',
  needToDoLoginAgain: 'You must log in again.',
  failedToSendFile: 'Error loading file!',
  moduleNotFound: 'Module not found',
  ediModuleNotFound: 'EDI module not found!',
  youDontHaveModules: 'You have no modules.',
  file: 'Archive',
  titles: 'Titles',
  deleteSyncronizedPhotoToFreeSpace:
    'Delete already synced photos to free up space: ',
  invalidTokenLogin: 'Invalid access token',
  typeLoginAndPasswordToLogin: 'Enter email and password to login',
  authenticating: 'Authenticating...',
  loginEnter: 'LOG IN',
  somePointsNeedsYourAtention: 'Some points need your attention!',
  observationAdded: 'Observations saved successfully',
  thereAreNotObservationsToThisProduct:
    'There are no observations for this product.',
  observations: 'Observations',
  orderNumber: 'Order number',
  date: 'Date',
  dateToSend: 'Shipping date',
  customer: 'Client',
  billedItems: 'Billed items',
  partial: 'Partial',
  partialBilled: 'Partial billed',
  liquid: 'Liquid',
  billedLiquid: 'Partial liquid',
  taxSubstituiton: 'Tax substitution',
  IPI: 'IPI', // dúvida
  amountOfBoxes: 'Amount of boxes',
  amountOfBilledBoxes: 'Quantity of boxes invoiced',
  requestedQuantity: 'Requested quantity',
  billedQuantity: 'Billed Quantity',
  requestedPrice: 'Requested Price',
  billedPrice: 'Billed Price',
  requestedTotal: 'Requested Total',
  billedTotal: 'Billed Total',
  discountRequested: 'Discount Requested',
  billedDiscount: 'Discount Billed',
  discount: 'Discount',
  increase: 'Increase',
  salesOrganization: 'Sales organization',
  salesType: 'Type of sale',
  salesTypeInvalid: 'Type of sale invalid',
  salesCondition: 'Terms of sale',
  salesConditionInvalid: 'Invalid sales conditions',
  selectLeastOneSaleCondition: 'Select at least one sales condition',
  middle: 'Medium',
  middleInvalid: 'Medium Invalid',
  paymentOptions: 'Payment methods',
  priceTable: 'Price table',
  priceTableInvalid: 'Price table invalid',
  funds: 'Funds',
  promptDelivery: 'Fast delivery',
  gifts: 'Gifts',
  deadline: 'Deadline',
  deadlineInvalid: 'Deadline Invalid',
  budget: 'Budget',
  errorCopyOrder: 'Error copying the order.',
  thereAreAOpenedClientOrCart: 'A customer or cart is already open.',
  deleteCart: 'Delete cart',
  wantDeleteThisCart: 'Are you sure you want to delete this cart?',
  afterDeleteCanNotGoback:
    'After deleting, you will not be able to undo this action.',
  select: 'Please select',
  noneFiles: 'No files',
  dateSave: 'Saved date',
  printDate: 'Printing date',
  removeItem: 'Remove item',
  conditionsPriceTerm: 'Price Conditions',
  noPriceTermConditions: 'No Price Term Conditions',
  remove: 'Remove',
  contains: 'Contains',
  exitPoliEquipes: 'Leave the PoliEquipes',
  or: 'or',
  and: 'and',
  before: 'before',
  lostChangesOnExit: 'When you leave, your changes will be lost.',
  lostChangesOnExitAccount:
    'Upon exit, unsynchronized photos will be lost. \n\nEnter the word Confirm in the field below and click confirm.',
  errorExitPoliEquipes: 'Error exiting PoliEquipes',
  synchronize: 'Sync up',
  swapAccount: 'Swap account',
  noneProspects: 'No prospects found',
  printManagerNotInstaller: 'Print module not installed.',
  noneReports: 'No reports',
  finishOrder: 'Complete order',
  clickConfirmToSendYourOrder:
    'By clicking confirm, your order will be sent and you will no longer be able to change it.\n\nDo you want to confirm?',
  noneRewards: 'No rewards available',
  processBlocked:
    'There is already a process started on another client. Finish the activities of the process already started.',
  yourVersionMustBeUpdated: 'Your version needs to be updated.',
  avaiableVersion:
    'There is a new version available. Do you want to update the version now?',
  after: 'Later',
  openLocaleSettings: 'Open language settings',
  openLocaleSettingsDescription: 'Configure your application language.',
  errorDeviceNotAuthorized:
    'The equipment is no longer authorized to connect. The system will shut down. Contact the company.',
  completeYourChangesToSync: 'Complete your changes to sync.',
  errorUploadGetSerial:
    'Error submitting your information! We couldnt get his last set back.',
  errorUploadToServer:
    'Error submitting your information! The server did not receive your information.',
  errorDownloadFromServer: 'Error receiving your information!',
  errorProcessTable: 'Error processing tables',
  errorInternetConnection:
    'Please check your internet connection and try again.',
  activitiesScreen: 'Activities',
  clientsScreen: 'Customers',
  offRouteCustomersScreen: 'Off route customers',
  tradeScreen: 'Trade',
  addClientScreen: 'Add Customers',
  addProspectScreen: 'Add prospect',
  newClientsScreen: 'New Customers',
  prospectsScreen: 'Prospects',
  enviromentScreen: 'Environmental',
  catalogScreen: 'Catalogue',
  productObservationsScreen: 'Product Notes',
  orderObservationsScreen: 'Order observations',
  productDetailScreen: 'Product Details',
  imageScreen: 'Image',
  cartScreen: 'Products',
  orderScreen: 'Orders',
  orderDetailsScreen: 'Order Details',
  orderResumeScreen: 'Order summary',
  resume: 'Summary',
  finishScreen: 'Order completed',
  titlesScreen: 'Titles',
  clientDetailsScreen: 'Customer details',
  goToClient: 'Go to customer',
  itineraryScreen: 'Itinerary',
  loginScreen: 'Log in',
  accountsLoginScreen: 'Accounts',
  homeScreen: 'Home',
  bonificationsScreen: 'Bonuses',
  profileScreen: 'Profile',
  lightBoxScreen: 'LightBox',
  compoundProductScreen: 'Compound Product',
  promotionDetailsScreen: 'Promotion details',
  generationFormScreen: 'Generation form',
  reportsScreen: 'Reports',
  combosScreen: 'Combs',
  comboDetailScreen: 'Combo Details',
  filtersScreen: 'Filters',
  updateVersionScreen: 'Version update',
  messagesScreen: 'Messages',
  rewardsScreen: 'Rewards',
  printScreen: 'Print',
  gamificationScreen: 'gamification',
  managementScreen: 'management',
  goalsScreen: 'goals',
  screenPriceTermConditions: 'Price Conditions',
  waitALittleLonger: 'Wait a little longer',
  thisFieldIsRequired: 'This field is required',
  maxSizeIs: 'The files {{files}} should be at most {{maxSize}}MB',
  typedIsNotValid: 'typed is not valid',
  fieldIsNotValid: 'Field is not valid',
  changeOcurred: 'Change ocurred',
  scriptChange: 'There has been a change in your script!',
  invalidCredentials: 'Invalid credentials. Check your login and password.',
  paramsError:
    'Your action cannot be done due to lack of parameterization. Contact support.',
  noInternetConnection: 'No internet connection',
  unavailableGPS: 'GPS unavailable',
  unavailableActivities: 'Activities unavailable',
  releaseActivity: 'Make a new checkIn to release this activity.',
  alertPhotoActivity:
    "You took photos but didn't save the activity. If you click on exit the photos will be lost. Do you want to exit?",
  getOut: 'Get out',
  respond: 'Respond',
  lookAnswer: 'Look answer',
  syncPendingPhotos: 'Number of pending photos to sync',
  syncPendingFiles: 'Files pending submission.',
  thereArePhotos: 'There are some files',
  photosWasntSent: "wasn't sent.",
  file: 'file',
  register: 'register',
  activities: 'activities',
  pendingSend: 'pending syncing.',
  sendNow: 'Send now',
  filesSuccessfullySynced: 'Files successfully synced (:',
  errorSyncPhotos: 'Unable to sync your files ):',
  connectionErrorWhenSyncPhotos:
    'Failed to send photos. Please check your internet connection and try again. COD001',
  connectionWifiOnlyWhenSyncPhotos:
    'Failed to send photos. Make sure you are connected to a WIFI network. COD002',
  syncPhotosGeneric:
    'Failed to send photos. An unexpected error occurred. If the issue persists, contact support. COD003',
  notCompleteSyncFiles:
    'Failed to send one or more files. If the problem persists, contact support. COD004',
  photosSuccessfullyDeleted: 'Photos successfully deleted (:',
  errorDeletePhotos: 'No photo found on device :(',
  pressToSync: 'Press to sync',
  errorConnectionGPS:
    'Your point has been collected but you are not connected.Click here to open settings.',
  generalInfo: 'General Info',
  CollectingPoints: 'Collecting GPS points',
  clickToLogin: 'Click on the button to access',
  activityRequiredNotDone: 'Make all activities required first',
  clearSelectedProducts: 'Clear selected',
  removeProductsAndKeepCopyOrder: 'Copy anyway',
  someProductsWillNotBeAdded: 'There are products that will not be added.',
  usernameAndPasswordMustNotBeEmpty: 'Username and password must not be empty',
  nullOrInvalidCode: 'Null or invalid code',
  codeMustHaveOnlyFourDigits: 'Code must have only 4 digits',
  yesRequest: 'Yes, request.',
  offRouteService: 'Off-road service',
  offRouteCustomerService:
    'is out of your route.\n\nDo you want to request assistance?',
  ClientOffRoute: 'off route',
  changingCustomerLocation: "You are changing the client's location.",
  wantToSubmit: 'Do you want to submit a request?',
  sendRequest: 'Request sent',
  successSendRequest: 'Request sent successfully!',
  errorSendRequest: 'Could not send request!',
  startingPointScreen: 'Starting point',
  creatingStartingPoint: 'You are creating your starting point.',
  changeCustomerLocation: 'Change customer location',
  expectedTime: 'Expected time',
  visitTime: 'Visit time',
  displacementTime: 'Displacement time',
  contactUs: 'Contact Us',
  contactUsDescription: 'Any questions or suggestions? Contact Us.',
  contactsAndSocialNetworks: 'Contacts and Social Networks',
  invalidUsername: 'Invalid username',
  customersInvoice: 'Customer Invoice',
  clear: 'Clear',
  clearFields: 'Clear fields',
  wantToClearFields: 'Do you want to clear the form fields?',
  noTabs: 'No tabs...',
  noHTML: 'No template for display',
  createAddress: 'New Address',
  backgroundTrackingTitle: 'PoliEquipes - GPS Monitoring',
  backgroundTrackingMessage: 'Collecting location points',
  comboRemoved: 'Combo removed!',
  ComboSuccessfullyRemoved: 'Combo successfully removed!',
  comboAlertExit:
    'Do you want to exit without applying the Combo? When leaving, the products that are in the cart will be removed.',
  currency: 'USD',
  currencySymbol: '$',
  separatorCurrency: '.',
  delimiterCurrency: ',',
  combs: 'Combs',
  period: 'Period',
  sharedBalance: 'Shared Balance',
  apply: 'Apply',
  methodology: 'Methodology',
  fieldsWithAreMandatory: 'Fields with * are mandatory',
  errorOpeningOrderNote: 'Error opening order note',
  ErrorSavingOrderNote: 'Error saving order note',
  addrAlternativeSucess: 'Address saved successfully',
  addrCheckin: 'Checkin address',
  addrChange: 'Change address',
  addrCreate: 'New address',
  SalesOpportunity: 'Sales Opportunity',
  DetailOpportunity: 'Opportunity Details',
  noSalesOpportunity: 'No Sales Opportunity',
  source: 'Source',
  sourceNumberOrder: 'Origin order number',
  saleOpportunityStartedAt: 'The sale opportunity started on',
  reasonCancel: 'Cancellation reason',
  dateCancel: 'Cancellation date',
  cnpjCpf: 'CNPJ/CPF',
  ean: 'ean',
  quantity: 'Quantity',
  accept: 'Accept',
  enterReason: 'Enter a motive',
  reject: 'Reject',
  saleOpportunitityIsDone: 'Used sales opportunity',
  openLink: 'Open link',
  ProblemAccessingThePage: 'Problem accessing the page',
  ProblemAccessingThePageDescription:
    'Unable to access this resource.\n\nTry accessing again, if the error persists, contact support',
  cartDetailScreen: 'Products and categories',
  pendingOrderCanceled: 'Pending order canceled',
  insertBy: 'Insert by',
  multipleof: 'Multiple of',
  applyDiscount: 'Apply discount',
  goToAllDetail: 'Go to all details',
  noFields: 'No Fields',
  See_All: 'See all',
  SeeAllProducts: 'All the products',
  cannotOpenObservation:
    'When editing a sales condition, it is not possible to add notes to the order',
  errorOnCancelOrder: 'Error canceling cart!',
  locationPermissionTitle: 'Location permission',
  locationPermissionMessage: 'Enable location settings for GPS tracking.',
  waitALittle: 'Please wait a little longer, we are still loading your data...',
  clientNotFound: 'Customer not found! Canceling pending cart...',
  share: 'Share',
  poliInstallerIsNotInstalled: 'The PoliInstaller is not installed',
  doYouWantToInstallNow: 'Do you want to install now?',
  expenses: 'Expenses',
  expenseDetail: 'Release details',
  expensesForm: 'New release',
  expenseSuccess: 'Saved launch',
  expensesResume: 'Release summary',
  theOption: 'the option',
  discardSaleCondition: 'Discard condition of sale',
  noChanges: 'No changes',
  noChangesInForm: 'There are no changes in the form.',
  notListConditionsSales: 'Unable to list sales conditions',
  noBarCodeFound: 'No barcodes found.',
  barCodeFound: 'Barcode detected! Putting it in the search field',
  errorListExpenses: 'Error listing your expenses',
  in: 'In',
  out: 'Out',
  cancelExpense: 'Cancel release',
  cancelExpenseMessage:
    'If this release is cancelled, the information entered will be lost.\n\nDo you really want to exit without saving?',
  exitWithoutSave: 'Exit without saving',
  errorListExpenseTypes: 'Unable to list expense types',
  fieldDate: 'Note date',
  fieldType: 'Release type',
  fieldAmount: 'Total note value',
  subtractFromNote: 'Subtract from note',
  subtractFromNoteValue: 'Subtract from the note value',
  subtractValue: 'Value to subtract',
  fieldReason: 'Reason',
  camera: 'Camera',
  saveExpense: 'Save release',
  backToExpenses: 'Back to releases',
  successSaveExpense: 'Launch saved successfully',
  paymentDate: 'Payment date',
  place: 'Establishment',
  adjustValField: 'Value adjustment',
  reasonField: 'Reason for adjustment',
  attachments: 'Attachments',
  errorOnCreateExpense: 'Error creating expense',
  emptyExpenses: 'There are no releases to display!',
  attachFile: 'Attach receipt',
  days: 'Days',
  errorGenerateReport: 'Error generating report',
  reportsOfLast: 'Reports of lasts',
  other: 'Other',
  balance: 'Balance',
  emptyListToGeneratePdf: "You don't have expenses to generate the report!",
  generateResume: 'Generate resume',
  updateDatas: 'Data update',
  updateFailure: 'Update Failure',
  showDetail: 'See details',
  quickActions: 'Quick actions',
  sendPhotoOnlyWifiDescription:
    'With this option enabled, photos will not be sent using mobile data.',
  files: 'files',
  answerObservations: 'Answer observations',
  requiredObservations: 'It is mandatory to fill observations',
  applySalesConditions: 'Apply sales conditions',
  changeSalesConditions: 'Change sales conditions',
  goToProducts: 'Go to products',
  lostChangeSalesCondition: 'Conditions of sale will be lost.',
  lostChangeSalesConditionMessage:
    'When you return without saving, you lose your changes.',
  confirmLostChangeSalesCondition: 'Are you sure you want to return?',
  yesBack: 'Yes, go back',
  unavailableConfig: 'Configuration unavailable',
  currentPlanNotAllowed:
    'The current plan does not allow you to configure the order\nContact your supervisor.',
  loginRequired: 'You will need to log in again',
  pleaseWait: 'Please wait',
  wePreparingYou: 'We are preparing everything for you!',
  searchingYourInfos: 'Searching for your information...',
  loadingTables: 'Loading tables...',
  organizingYourProducts: 'Organizing your products...',
  knowingYourClients: 'Identifying your clients...',
  mappingYourRoutes: 'Mapping your routes...',
  unableOpenLink: 'Unable to open link',
  waitingData: 'Please wait a little longer, we are loading the data...',
  loadingAllProducts: 'Loading all products...',
  searchForProducts: 'Search products...',
  findedOrders: 'Finded orders',
  totalFatured: 'Total revenue',
  searchForOrders: 'Search for orders...',
  searchForClients: 'Search for customers...',
  orderedBy: 'Sorted by:',
  dateFrom: 'From',
  dateTo: 'To',
  errorWhenLoadAccounts: 'Unable to load accounts!',
  errorWhenSwapAccount: 'Unable to swap account!',
  swapAccountTitle: 'Swap Account',
  swapAccountSubTitle: 'Do you really want to switch accounts?',
  swapAccountDescription:
    'When changing the user, unsynchronized photos will be lost.\n\nEnter the word Confirm in the field below and click confirm. ',
  yesSwap: 'Yes, swap',
  swap: 'Swap',
  removeAccountTitle: 'Remove Account',
  removeAccountDescription:
    'Do you want to remove this account from your device? By doing this, you will need to log in again.',
  removeAccountSuccess: 'Account successfully removed',
  errorOnCheckBase:
    'Unable to check the database. Contact support if the error persists.',

  errorOnRunLib:
    'Unable to start the system. Contact support if the error persists.',
  createActivity: 'Create activity',
  activityName: 'Activity name',
  createActivityTitle: 'Create dynamic activity',
  createActivityDescription:
    'The activity you selected will be used as a basis to create a new one, but you can choose a description of your preference for it.',
  dynamic: 'Dynamic',
  activityCreatedSuccessfully: 'Dynamic activity created successfully.',
  errorWhenCreateActivity:
    'It seems that something went wrong during the creation of this activity. Please try again later.',
  dynamicActivity: 'Dynamic activity',
  activityCannotCreateWithNoProcess:
    'It is only possible to create a dynamic activity after starting the process.',
  errorSavingPhoto: 'An error occurred while saving the photo',
  moreTitleCat: 'more',
  categoriesScreen: 'Categories',
  searchCategories: 'Search categories...',
  errFilterCategories: 'Error filtering categories.',
  noCategories: 'No categories.',
  errOpenCategory: 'Error opening category.',
  flightPlan: 'Flight plan',
  flightPlanVerifyError:
    'It was not possible to verify the actions, if the problem persists, contact support. Cod: PVOO-01',
  totalWithAdjust: 'Total with adjustment',
  listTitlesScreen: 'Select one or more titles',
  errorListTitles: 'Error listing titles.',
  errorFilterTitles: 'Error filtering titles.',
  collectionOfReceivables: 'Receivables',
  selectAtLeastOneTitle: 'Select at least one title.',
  moduleWorkOnlyInternet: 'This module only works with internet.',
  errorExtractTitles: 'Error extracting titles.',
  errorRequest: 'Error requesting.',
  ocurredError: 'An error occurred',
  errorProcessFileToSend: 'Error processing file to send.',
  errorCommunicateWithServer: 'Error communicating with server.',
  maximumPrecisionReached:
    'Your location accuracy exceeds the allowed limit. Please adjust your GPS settings for improved accuracy, and if the problem persists, contact your supervisor.',
  reportingModule:
    'Reporting Module does not have storage or files and media permissions.',
  errorReportingModule: 'An error occurred while generating the report.',
  reportingModuleNotInstalled: 'Reporting module not installed.',
  poliReportsInstall: 'Install PoliReports',
  noCode: 'No code',
  refTitle: 'Title',
  Kg: 'Kg',
  selectProducts: 'Choose products',
  selecteds: 'selected item(s)',
  multiple: 'Multiple',
  price: 'Price',
  table: 'Table',
  new: 'New',
  seeMore: 'More',
  replace: 'Replace',
  replaceMultipleDescription:
    'Replace the current quantity for the New quantity times the multiple of the item.',
  addMultipleDescription:
    'Add the current quantity for the New quantity times the multiple of the item.',
  priceDescription: 'Swaps the item"s current price with the New price',
  replaceDiscountDescription:
    'Replace the percentage value of the item for the New value percentage discount',
  addDiscountDescription:
    'Adds the percentage value of the item + New percentage discount',
  replacePriceDescription: 'Replace the current price item for the New price',
  addPriceDescription:
    'Adds the current value of the item + New price for the item',
  characterInvalid: 'Invalid character',
  outdatedData: 'Outdated data',
  outdatedDataMessage:
    'With the database out of date, this request will be saved as a quote.',
  outdatedDataDescription:
    'Update your database and edit this request to complete it',
  back: 'Go back',
  products: 'Products',
  errorProcessFormData: 'Error processing form data.',
  couldNotBeOpened: 'Could not be opened',
  errorOpenPage: 'Error opening page.',
  errorLoadPageContactSupport:
    'There was an error loading the page. Please contact support',
  codeHttpError: 'Code HTTP error',
  errorMakeUrlPPM: 'Error making URL PPM.',
  cannotTrigFlightPlanAlerts: 'Cannot trigger flight plan alerts',
  openCart: 'Open order',
  errorOnLoadShipping:
    'An error occurred while loading freight. If it persists, contact support.',
  errorOnLoadShippingCompanies:
    'An error occurred while loading the carriers. If it persists, contact support.',
  sucessfullyRejectedOpportunity: 'Opportunity sucessfully rejected.',
  UnableToLoadingListOfActivities: 'Unable to loading list of activities.',
  UnableToOpenActivity:
    'Unable to open this activity.\nPlease contact support.',
  titleNoUpToDate: 'No update data',
  descriptionNoUpToDate:
    'Always stay updated! Don"t forget to synchronize your PoliEquipes with the server.',
  ok: 'Ok',
  cantLoadProducts: 'The products could not be loaded. Try again',
  reload: 'Reload',
  doYouReallyWantToCopyTheOrder: 'Do you really want to copy the order?',
  howDoYouWantToCopyTheOrder: 'How do you want to copy the order?',
  ErrorProcessingCompanySettingsInformation:
    'Error processing company settings information',
  comboSuccessApplied: 'Combo applied successfully',
  addItemsToApplyCombo: 'Add items to apply the combo.',
  deleteNotification: 'Delete notification?',
  doYouReallyWantToDeleteNotification:
    'Do you really want to delete the notification?',
  deleteNotificationSucess: 'Notification deleted successfully!',
  errorDeleteNotification: 'Error when deleting notification',
  deleteAllNotitifications: 'Delete all notifications.',
  doYouReallyWantdeleteAllNotification:
    'Do you really want to delete all notifications?',
  removeNotification: 'Remove notification',
  deleteAllSucess: 'Deleted Successfully!',
  talkingCameNews: 'We will notify you when news arrives',
  standardScale: 'Standard scale',
  errorAddingCustomer: 'Error adding customer',
  errorWhenSetProductActive: 'Error setting active product',
  subTotal: 'Subtotal',
  contactOptions: 'Contact options',
  accessToYourFilesSettings:
    "Access the app's settings and give it storage permission.",
  authorizedDiscount: 'Authorized discount',
  fillingObservationFieldRequired: 'Filling observation field',
  requiredPhotos: 'Required photo',
  incompatible: 'Incompatible',
  expressionRegularSettings: 'Regular expression configured',
  errorOnFieldVerification: 'An error occurred while verifying the field.',
  itinenaryList: 'List',
  itinenaryMap: 'Map',
  selectImage: 'Select Image',
  choiceOption: 'Choose an option: ',
  takePhoto: 'Take photo',
  choiceImageGallery: 'Choose from gallery',
  deselectSelected: 'Deselect selected',
  seeCustomersNearTheRoute: 'See customers near the route',
  copyCart: 'Copy an order',
  attachmentAlert: 'Maximum file size: ',
  attachmentFile: 'File',
  attachmentOpenFolder: 'Open folder',
  attachmentOpenCamera: 'Open camera',
  attachmentCamera: 'Camera',
  ErrorSavingProspects: 'Error when saving prospects. COD001',

  saleOpportunities: 'Sales Opportunities',
  saleOpportunityApproach: 'The promoter informed that this customer has products with a high likelihood of purchase.\n\nDo you want to add these products now?',
  later: 'See later',
  ignoreOpportunity: 'Ignore opportunity',
  insertOpportunity: 'Insert',

  rejectSaleOpportunities: 'Reject opportunities',
  rejectSaleOpportunitiesDescription: "By clicking 'Reject' you will reject {{opportunities}} opportunity(ies).",

  postponeSaleOpportunity: 'Postpone opportunities',
  postponeSaleOpportunitiesDescription: 'You will lose the opportunity to sell these items in this order. Please provide the reason for not making this sale now.\n\nThey will still be available for the next order.',
  seeOpportunities: 'See opportunities',
  requiredResolveOpportunity: 'You must resolve to continue',
  seeProducts: 'See products',
  typeReason: 'Provide the reason',
  existsPendingOpportunityProducts: 'There are still opportunity products',
  addedItens: 'Added items',
  cod: 'Code: {{code}}',
  removeProduct: 'Remove product',
  includeProduct: 'Include product',
  lowerItens: 'items',
  lowerAdded: 'added',
  totalValue: 'Total: {{totalValue}}',

  hour: 'Hour',
  hours: 'Hours',
  minute: 'Minute',
  minutes: 'Minutes',
  second: 'Second',
  seconds: 'Seconds',
  day: 'Day',
  days: 'Days',

  theJourneyWillStarts: 'The journey will start in',
  theJourneyWillStartsDescription: 'Get ready, you will soon be able to start your activities.',
  intervalStartsIn: 'Your break starts in {{minutes}} minutes',
  intervalStartsInWarn: 'Attention\n\nYour break starts in 0{{minutes}}:00 minutes.',

  journeyInterval: 'Journey Interval',
  journeyIntervalDescription: 'Save your activities or orders now.\n\nThe APP will switch to view mode in 1 minute.',
  iamAgree: 'I am aware',
  lunchBreak: 'Break',
  lunchBreakDescription: 'We will notify you when lunch is over.',
  noLunchBreak: 'No lunch break',
  noLunchBreakDescription: 'Get ready, you will soon be able to start your activities.',
  opportunity: 'Opportunity',
  journeyEndsIn: 'Your journey ends in {{minutes}} minutes.',
  journeyEndsInWarn: 'Attention!\n\nYour journey ends in 0{{minutes}}:00 minutes.',

  journeyEndsTitle: 'Very good, journey completed',
  journeyEndsDescription: 'Always keep everything in order.\nIt is important to sync your data.',
  seeModules: 'See Modules',
  journey: 'Journey',

  journeyEndsAlert: 'End of journey',
  journeyEndsAlertDescription: 'Save your activities or orders now.\n\nThe APP will close in 1 minute.',

  cnpjValue: 'CNPJ: {{value}}',
  createdWhen: 'Created on {{value}}',
  shortCode: 'Cod.',
  ean: 'EAN',

  errorSavingProspects: 'Error when saving prospects. COD001',
  emptyProspectsMessage: "You don't have prospects associated with you yet.",
  errorsOnForm: 'The form contains errors. Please correct them and try again.',
  dayoffMessage: 'Today is a day off',
  dayoffDescription: 'Choose another day to see your visits.',
  copyCartScreenTitle: 'Select order',
  osrmError: "We couldn't display the coordinates for the directions on the map.",
  loseInformation: "Lose information",
  doYouReallyWantToExit: "Do you really want to exit?",
  onExitInformationWillBeLost: "Upon exiting, your information will be lost.",
  exitAndLose: "Exit and lose",
  agentMode: "Agent Mode",
  agentModeDescription: "Select an agent to activate the mode and perform activities as the selected agent.",
  selectAgent: "Select Agent",
  managementMode: "Management Mode",
  outOfArea: "Out of area visit",
  requestOutOfArea: "Request release",
  releaseRequestSuccess: "Release request sent successfully!",
  releaseRequestError: "An error occurred while requesting the release.",

  blockedClient: "Blocked client",
  requestBlockedClient: "Request sales release",
  releaseRequestSuccess: "Sales release request sent successfully!",
  releaseRequestError: "An error occurred while requesting sales release.",
  waitALittleForMakeRequest: 'Your request has been registered. Please wait a moment before trying again.',
  requestDenied: 'Your request has been denied. Please wait a moment before trying again.',
  changeClientTitle: 'Remove products',
  changeClientSubTitle: 'When changing the client, the added products may be removed.',
  changeClientDescription: 'Do you want to continue?',
  changeClient: 'Change client',
  important: 'Important',
  all: 'All',
  selectCalendarDate: 'Select a date on the calendar to continue',
};
