import {
  SCREEN_HOME,
  SCREEN_PROFILE,
  SCREEN_CLIENTS,
  SCREEN_TRADE,
  SCREEN_ITINERARY,
  SCREEN_ORDERS,
  SCREEN_PRINT,
  SCREEN_TITLES,
  SCREEN_REPORTS,
  SCREEN_PROSPECTS,
  SCREEN_WEBVIEW,
  SCREEN_WEBVIEW_GAMIFICATION,
  SCREEN_WEBVIEW_MANAGEMENT,
  SCREEN_WEBVIEW_GOALS,
  SCREEN_ENVIROMENTAL,
  SCREEN_CATALOG_PRODUCTS,
  SCREEN_SALES_OPPORTUNITY,
} from '../../../screens/screens';
import Images from '@assets/Images';
import * as config from '../../../config/config';

const navigatorStyle = {
  statusBarTextColorScheme: 'light',
  navBarButtonColor: config.Colors.navBarButtonColor,
  navBarTextColor: '#fff',
  navigationBarColor: '#000',
  navBarBackgroundColor: config.Colors.primaryColor,
  hideBackButtonTitle: true,
  navBarSubtitleColor: config.Colors.navBarSubtitleColor,
  navBarSubtitleFontSize: 12,
  tabBarHidden: false,
};

const modulesConfig = [
  {
    id: 'itinerary',
    screen: SCREEN_ITINERARY.name,
    title: SCREEN_ITINERARY.title,
    label: SCREEN_ITINERARY.title,
    image: Images.appCalendar,
    is_highlight: true,
    tabIndex: 1,
  },
  {
    id: 'clients',
    screen: SCREEN_CLIENTS.name,
    title: SCREEN_CLIENTS.title,
    label: SCREEN_CLIENTS.title,
    image: Images.appClients,
    tabIndex: 2,
  },
  {
    id: 'orders',
    screen: SCREEN_ORDERS.name,
    title: SCREEN_ORDERS.title,
    label: SCREEN_ORDERS.title,
    image: Images.appOrders,
    tabIndex: 3,
  },
  {
    id: 'reports',
    screen: SCREEN_REPORTS.name,
    title: SCREEN_REPORTS.title,
    label: SCREEN_REPORTS.title,
    image: Images.appReports,
  },
  {
    id: 'titles',
    screen: SCREEN_TITLES.name,
    title: SCREEN_TITLES.title,
    label: SCREEN_TITLES.title,
    image: Images.appFinancing,
  },
  {
    id: 'prospects',
    screen: SCREEN_PROSPECTS.name,
    title: SCREEN_PROSPECTS.title,
    label: SCREEN_PROSPECTS.title,
    image: Images.appProspect,
  },
  {
    id: 'webview',
    screen: SCREEN_WEBVIEW.name,
    name: SCREEN_WEBVIEW_GOALS.name,
    title: SCREEN_WEBVIEW_GOALS.title,
    label: SCREEN_WEBVIEW_GOALS.title,
    image: Images.appGoals,
    props: {
      url:
        'http://gamification.polisuite.com.br.s3-website-us-east-1.amazonaws.com/#/goals',
    },
  },
  {
    id: 'webview',
    screen: SCREEN_WEBVIEW.name,
    name: SCREEN_WEBVIEW_MANAGEMENT.name,
    title: SCREEN_WEBVIEW_MANAGEMENT.title,
    label: SCREEN_WEBVIEW_MANAGEMENT.title,
    image: Images.appManagement,
    props: {
      url:
        'http://gamification.polisuite.com.br.s3-website-us-east-1.amazonaws.com/#/management',
    },
  },
  {
    id: 'webview',
    screen: SCREEN_WEBVIEW.name,
    name: SCREEN_WEBVIEW_GAMIFICATION.name,
    title: SCREEN_WEBVIEW_GAMIFICATION.title,
    label: SCREEN_WEBVIEW_GAMIFICATION.title,
    image: Images.appGamification,
    props: {
      url:
        'http://gamification.polisuite.com.br.s3-website-us-east-1.amazonaws.com/#/gamification',
    },
  },
  {
    id: 'environmental',
    screen: SCREEN_ENVIROMENTAL.name,
    title: SCREEN_ENVIROMENTAL.title,
    label: SCREEN_ENVIROMENTAL.title,
    image: Images.iconRecycling,
  },
  {
    id: 'catalog_products',
    screen: SCREEN_CATALOG_PRODUCTS.name,
    title: SCREEN_CATALOG_PRODUCTS.title,
    label: SCREEN_CATALOG_PRODUCTS.title,
    image: Images.iconProduct,
  },
  {
    id: 'print',
    screen: SCREEN_PRINT.name,
    title: SCREEN_PRINT.title,
    label: SCREEN_PRINT.title,
    image: Images.appPrint,
  },
  {
    id: 'trade',
    screen: SCREEN_TRADE.name,
    title: SCREEN_TRADE.title,
    label: SCREEN_TRADE.title,
    image: Images.appTrade,
    props: {
      module: 'trade',
    },
  },
  {
    id: 'SalesOpportunity',
    screen: SCREEN_SALES_OPPORTUNITY.name,
    title: 'SalesOpportunity',
    label: 'SalesOpportunity',
    image: Images.appReports,
  },
];

const standardTabs = [
  {
    required: true,
    title: '',
    stack: {
      id: SCREEN_HOME.name,
      children: [
        {
          component: {
            name: SCREEN_HOME.name,
            options: {
              topBar: {
                visible: false,
                title: {
                  text: SCREEN_HOME.title,
                },
              },
              bottomTab: {
                text: SCREEN_HOME.title,
                icon: Images.iconStore,
                testID: SCREEN_HOME.name,
                selectedIcon: Images.iconStoreFill,
              },
            },
          },
        },
      ],
    },
  },

  {
    title: SCREEN_ITINERARY.title,
    stack: {
      id: SCREEN_ITINERARY.name,
      children: [
        {
          component: {
            name: SCREEN_ITINERARY.name,
            options: {
              topBar: {
                title: {
                  text: SCREEN_ITINERARY.title,
                },
              },
              bottomTab: {
                text: SCREEN_ITINERARY.title,
                icon: Images.appCalendar,
                testID: SCREEN_ITINERARY.name,
                selectedIcon: Images.appCalendar,
              },
            },
          },
        },
      ],
    },
  },

  {
    title: SCREEN_CLIENTS.title,
    stack: {
      id: SCREEN_CLIENTS.name,
      children: [
        {
          component: {
            name: SCREEN_CLIENTS.name,
            options: {
              topBar: {
                title: {
                  text: SCREEN_CLIENTS.title,
                },
              },
              bottomTab: {
                text: SCREEN_CLIENTS.title,
                icon: Images.iconCustomers,
                testID: SCREEN_CLIENTS.name,
                selectedIcon: Images.iconCustomers,
              },
            },
          },
        },
      ],
    },
  },

  {
    title: SCREEN_ORDERS.title,
    stack: {
      id: SCREEN_ORDERS.name,
      children: [
        {
          component: {
            name: SCREEN_ORDERS.name,
            options: {
              topBar: {
                title: {
                  text: SCREEN_ORDERS.title,
                },
              },
              bottomTab: {
                text: SCREEN_ORDERS.title,
                icon: Images.iconOrder,
                testID: SCREEN_ORDERS.name,
                selectedIcon: Images.iconOrder,
              },
            },
          },
        },
      ],
    },
  },

  {
    required: true,
    title: SCREEN_PROFILE.title,
    stack: {
      id: SCREEN_PROFILE.name,
      children: [
        {
          component: {
            name: SCREEN_PROFILE.name,
            options: {
              topBar: {
                title: {
                  text: SCREEN_PROFILE.title,
                },
              },
              bottomTab: {
                text: SCREEN_PROFILE.title,
                icon: Images.iconAccount,
                testID: SCREEN_PROFILE.name,
                selectedIcon: Images.iconAccount,
              },
            },
          },
        },
      ],
    },
  },
];

export { navigatorStyle, standardTabs, modulesConfig };
