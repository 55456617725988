class PushNotification {
  static configure() {
    return null;
  }

  localNotification() {
    return null;
  }

  cancelAllLocalNotifications() {
    return null;
  }

  cancelLocalNotifications() {
    return null;
  }

  setApplicationIconBadgeNumber() {
    return null;
  }

  getApplicationIconBadgeNumber() {
    return null;
  }

  requestPermissions() {
    return null;
  }

  presentLocalNotification() {
    return null;
  }

  scheduleLocalNotification() {
    return null;
  }

  registerNotificationActions() {
    return null;
  }

  clearAllNotifications() {
    return null;
  }

  abandonPermissions() {
    return null;
  }

  checkPermissions() {
    return null;
  }

  cancelNotification() {
    return null;
  }

  popInitialNotification() {
    return null;
  }

  getInitialNotification() {
    return null;
  }

  getDeliveredNotifications() {
    return null;
  }

  getScheduledLocalNotifications() {
    return null;
  }

  invokeApp() {
    return null;
  }

  onNotification() {
    return null;
  }

  onNotificationOpened() {
    return null;
  }

  onRegister() {
    return null;
  }

  onRegistrationError() {
    return null;
  }

  onAction() {
    return null;
  }

  onReply() {
    return null;
  }

  onNotificationDisplayed() {
    return null;
  }

  onNotificationOpenedApp() {
    return null;
  }

  onNotificationRemoved() {
    return null;
  }

  onNotificationClicked() {
    return null;
  }

  onNotificationActionClicked() {
    return null;
  }

  onNotificationReceivedForeground() {
    return null;
  }

  onNotificationReceivedBackground() {
    return null;
  }

  onNotificationOpenedForeground() {
    return null;
  }

  onNotificationOpenedBackground() {
    return null;
  }

  onNotificationReceivedInForeground() {
    return null;
  }

  onNotificationReceivedInApp() {
    return null;
  }

  onNotificationOpenedInForeground() {
    return null;
  }

  onNotificationOpenedInApp() {
    return null;
  }

  onNotificationReceived() {
    return null;
  }
}

export default PushNotification;
