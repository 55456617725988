import { color } from "../../../src/common";

export const TYPE_ROUTE_SOLICITATION_SYNC_DATA_DEVICE = 'SYNC_DATA_DEVICE';
export const TYPE_ROUTE_SOLICITATION_ACCEPTED = 'accepted';
export const TYPE_ROUTE_SOLICITATION_REFUSED = 'refused';
export const TYPE_ROUTE_SOLICITATION_PENDING = 'pending';
export const TYPE_ROUTE_SOLICITATION_RUNNING = 'running';

export const ROUTE_SOLICITATION_CACHE_DATA = 'cache_data_solicitations_route';

export const getColorMessageRouteSolicitation = (typeMessage) => {
  switch (typeMessage) {
    case TYPE_ROUTE_SOLICITATION_RUNNING:
      return color.grayA4;
    case TYPE_ROUTE_SOLICITATION_PENDING:
      return color.grayA4;
    case TYPE_ROUTE_SOLICITATION_ACCEPTED:
      return color.successColor;
    case TYPE_ROUTE_SOLICITATION_REFUSED:
      return color.dangerColor;
    default:
      return '';
  }
};

export const getLabelRouteSolicitation = (typeMessage) => {
  switch (typeMessage) {
    case TYPE_ROUTE_SOLICITATION_ACCEPTED:
      return 'Cliente já adicionado a rota';
    case TYPE_ROUTE_SOLICITATION_PENDING:
      return 'Aguardando análise do supervisor';
    case TYPE_ROUTE_SOLICITATION_REFUSED:
      return 'Supervisor recusou solicitação';
    case TYPE_ROUTE_SOLICITATION_RUNNING:
      return 'Solicitação em andamento';
    default:
      return '';
  }
};
