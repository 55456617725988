export const databaseName = 'activities.db3';
export const databaseVersion = 4;
export const databaseDisplayName = 'activities';
export const databaseSize = 200000;

export const SupportedTypes = {
  INTEGER: { value: 'INTEGER', type: 'INTEGER', default_value: null },
  LONG: { value: 'LONG', type: 'INTEGER', default_value: null },
  DOUBLE: { value: 'DOUBLE', type: 'REAL', default_value: null },
  TEXT: { value: 'TEXT', type: 'TEXT', default_value: null },
  BOOLEAN: { value: 'BOOLEAN', type: 'INTEGER', default_value: null },
  DATETIME: { value: 'DATETIME', type: 'TEXT', default_value: null },
  SYNC_STATUS: { value: 'STATUS', type: 'TEXT', default_value: null },
  JSON: { value: 'JSON', type: 'TEXT', default_value: null },
};

const defaultFields = {
  id: {
    type: SupportedTypes.TEXT,
    primary_key: true,
    default_value: null,
  },
  created_at: {
    type: SupportedTypes.DATETIME,
    default_value: null,
  },
  updated_at: {
    type: SupportedTypes.DATETIME,
    default_value: null,
  },
  deleted_at: {
    type: SupportedTypes.DATETIME,
    default_value: null,
  },
  deleted: {
    type: SupportedTypes.BOOLEAN,
    default_value: null,
  },
  sync_status: {
    type: SupportedTypes.SYNC_STATUS,
    default_value: 'pending_to_reserve',
  },
  sync_error: {
    type: SupportedTypes.TEXT,
    default_value: null,
  },
};

export const Tables = {
  version: {
    version_number: {
      type: SupportedTypes.INTEGER,
    },
  },
  user_task_lists: {
    ...defaultFields,
    execution_start_date: {
      type: SupportedTypes.DATETIME,
      default_value: null,
    },
    execution_end_date: {
      type: SupportedTypes.DATETIME,
      default_value: null,
    },
    status: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    user: {
      type: SupportedTypes.INTEGER,
      default_value: null,
    },
    task_list: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    extra_info: {
      type: SupportedTypes.JSON,
      default_value: null,
    },
  },
  task_lists: {
    ...defaultFields,
    name: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    description: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    created_by: {
      type: SupportedTypes.INTEGER,
      default_value: null,
    },
    company: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    setup: {
      type: SupportedTypes.JSON,
      default_value: null,
    },
  },
  photo_answers: {
    ...defaultFields,
    start_date: {
      type: SupportedTypes.DATETIME,
      default_value: null,
    },
    end_date: {
      type: SupportedTypes.DATETIME,
      default_value: null,
    },
    origin_date: {
      type: SupportedTypes.DATETIME,
      default_value: null,
    },
    extra_info: {
      type: SupportedTypes.JSON,
      default_value: null,
    },
    payload: {
      type: SupportedTypes.JSON,
      default_value: null,
    },
    created_by: {
      type: SupportedTypes.INTEGER,
      default_value: null,
    },
    user_task_list: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    task: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
  },
  answers: {
    ...defaultFields,
    start_date: {
      type: SupportedTypes.DATETIME,
      default_value: null,
    },
    end_date: {
      type: SupportedTypes.DATETIME,
      default_value: null,
    },
    status: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    sequence: {
      type: SupportedTypes.INTEGER,
      default_value: 0,
    },
    latitude: {
      type: SupportedTypes.DOUBLE,
      default_value: null,
    },
    longitude: {
      type: SupportedTypes.DOUBLE,
      default_value: null,
    },
    extra_info: {
      type: SupportedTypes.JSON,
      default_value: null,
    },
    payload: {
      type: SupportedTypes.JSON,
      default_value: null,
    },
    created_by: {
      type: SupportedTypes.INTEGER,
      default_value: null,
    },
    user_task_list: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    task: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
  },
  config: {
    ...defaultFields,
    display_name: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    url_stocker: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    token_stocker: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    logo: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    company: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    modules: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
  },
  custom_lists: {
    ...defaultFields,
    company: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    context: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
    setup: {
      type: SupportedTypes.TEXT,
      default_value: null,
    },
  },
};
