import React, { useRef } from 'react';
import Touchable from 'react-native-platform-touchable';
import translate from '~/locales';
import NavigationHelper from '~/screens/NavigationHelper';
import { SCREEN_PRODUCT_CODE_SCANNER } from '~/screens/screens';
import PropTypes from 'prop-types';
import onPressHelper from '~/utils/onPressHelper';
import { Image, StyleSheet, TextInput, View } from 'react-native';
import Images from '../../../../assets/Images';
import { color } from '~/common';

const style = StyleSheet.create({
  acessory: { marginHorizontal: 16, marginVertical: 6 },
  field: { margin: 16 },
  viewContainer: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    marginTop: 8,
    marginBottom: 8,
  },
  containerInput: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: color.grayMid,
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 4,
    flexShrink: 1,
    width: '100%',
  },
  input: {
    paddingTop: 10,
    paddingRight: 15,
    paddingBottom: 10,
    paddingLeft: 0,
    backgroundColor: '#fff',
    color: '#000',
    marginLeft: 5,
    fontSize: 15,
    padding: 5,
    width: '100%',
    flexShrink: 1,
  },
  iconRightPressable: {
    padding: 10,
  },
  iconRight: {
    tintColor: color.grayMid,
    width: 20,
    height: 20,
  },
});

export default function TextInputScanner({
  label,
  onChangeText,
  value,
  config,
  ...rest
}) {
  const fromBarCode = useRef(false);
  const inputRef = useRef(null);

  const onAccessoryPress = () => {
    NavigationHelper.showOverlay(SCREEN_PRODUCT_CODE_SCANNER.name, {
      onDetect,
    });
  };

  const onDetect = (val = '') => {
    onChangeText(val);
    inputRef?.current?.blur();
    fromBarCode.current = true;
  };

  const openBarCodeScanner = onPressHelper.debounce(onAccessoryPress);
  const isEditable = !(fromBarCode?.current && config?.disable_on_scan);

  return (
    <View style={style.viewContainer}>
      <View
        style={
          isEditable
            ? style.containerInput
            : [
                style.containerInput,
                { borderStyle: 'dotted', borderColor: color.grayA4 },
              ]
        }>
        <TextInput
          selectTextOnFocus
          multiline
          placeholder={`${translate('message')}`}
          value={value}
          returnKeyType="search"
          defaultValue=""
          onChangeText={onChangeText}
          ref={iRef => {
            inputRef.current = iRef;
          }}
          blurOnSubmit
          editable={isEditable}
          style={
            isEditable ? style.input : [style.input, { color: color.grayA4 }]
          }
          placeholderTextColor={color.grayMid}
        />
        {config?.show_scanner ? (
          <Touchable
            onPress={openBarCodeScanner}
            style={style.iconRigthPressable}>
            <Image source={Images.barCode} style={style.iconRigth} />
          </Touchable>
        ) : null}
      </View>
    </View>
  );
}

TextInputScanner.propTypes = {
  label: PropTypes.string,
  onChangeText: PropTypes.func,
  value: PropTypes.string,
  config: PropTypes.objectOf(PropTypes.any),
};
