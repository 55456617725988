/* @flow */

import React, { Component } from 'react';
import { Platform, Text, View } from 'react-native';
import { TextField } from 'react-native-material-textfield';
import colors from '../../common/color';
import SelectPicker from '../SelectPicker';
import Button from '../Button';
import translate from '~/locales';
import axios from 'axios';
import ActivitiesModule from '../../modules/ActivitiesModule';
import _ from 'lodash';
import Snackbar from 'react-native-snackbar';

import PropTypes from 'prop-types';

const STATESCODE = [
  { label: 'AC' },
  { label: 'AL' },
  { label: 'AP' },
  { label: 'AM' },
  { label: 'BA' },
  { label: 'CE' },
  { label: 'DF' },
  { label: 'ES' },
  { label: 'GO' },
  { label: 'MA' },
  { label: 'MT' },
  { label: 'MS' },
  { label: 'MG' },
  { label: 'PA' },
  { label: 'PB' },
  { label: 'PR' },
  { label: 'PE' },
  { label: 'PI' },
  { label: 'RJ' },
  { label: 'RN' },
  { label: 'RS' },
  { label: 'RO' },
  { label: 'RR' },
  { label: 'SP' },
  { label: 'SC' },
  { label: 'SE' },
  { label: 'TO' },
];

class AddrAlternativeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addrTitle: '',
      addrCEP: '',
      addrUF: '',
      addrCity: '',
      addrDistrict: '',
      addrName: '',
      addrComplement: '',
      addrNumber: '',
    };
  }

  onchangeCEP = async cep => {
    const onlyCEPnumbers = cep.replace(/\D/g, '');
    // Padrão de CEP no Brasil até nov/2022
    if (onlyCEPnumbers.length === 8) {
      const { data: addr } = await axios.get(
        `https://viacep.com.br/ws/${cep}/json/`,
      );
      if (addr.cep) {
        this.setState({
          addrUF: addr.uf,
          addrCity: addr.localidade,
          addrDistrict: addr.bairro,
          addrName: addr.logradouro,
          addrComplement: addr.complemento,
        });
      }
    }
    this.setState({ addrCEP: cep });
  };

  onSaveAddresAlt = async () => {
    const { pkeyClient, location } = this.props;
    const {
      latitude: addrLat,
      longitude: addrLong,
      perimetro: addrRaio = '0.05', // Raio padrão
    } = location;

    try {
      await ActivitiesModule.saveAddressAlternative(
        JSON.stringify({
          ...this.state,
          pkey: `${Date.now()}`,
          pkeyClient,
          addrLat: `${addrLat}`,
          addrLong: `${addrLong}`,
          addrRaio,
        }),
      );
      const addrReseted = _.mapValues(this.state, () => '');

      this.setState({ ...addrReseted });

      Snackbar.show({ text: translate('addrAlternativeSucess') });
    } catch (errorToParse) {
      const [err] = JSON.parse(errorToParse.message) || {};
      if (err) {
        Snackbar.show({ text: err?.mensagem ?? 'Erro ao salvar.' });
      }
    } finally {
      this.props.onSaveReached();
    }
  };

  render() {
    const {
      addrTitle,
      addrCEP,
      addrUF,
      addrCity,
      addrDistrict,
      addrName,
      addrComplement,
      addrNumber,
    } = this.state;

    return (
      <View style={{ padding: 10 }}>
        <Text
          style={{
            fontSize: 20,
            fontWeight: 'bold',
            textAlign: 'left',
            marginBottom: 4,
          }}>
          {translate('addrCreate')}
        </Text>
        <TextField
          label={translate('Description')}
          autoCapitalize="none"
          enablesReturnKeyAutomatically
          outline={Platform.OS === 'ios'}
          value={addrTitle}
          tintColor={colors.primaryColor}
          onChangeText={descr => this.setState({ addrTitle: descr })}
          returnKeyType="next"
        />
        <TextField
          label={translate('ZipCode')}
          autoCapitalize="none"
          enablesReturnKeyAutomatically
          outline={Platform.OS === 'ios'}
          value={addrCEP}
          tintColor={colors.primaryColor}
          onChangeText={cep => this.onchangeCEP(cep)}
          returnKeyType="next"
          keyboardType="numeric"
          maxLength={8}
        />
        <SelectPicker
          titleColor="#000026"
          selected={{ label: addrUF }}
          style={{ marginBottom: 8, backgroundColor: '#ebebeb' }}
          title="UF"
          items={STATESCODE}
          onChangeValue={({ label }) => this.setState({ addrUF: label })}
        />
        <TextField
          label={translate('City')}
          autoCapitalize="none"
          enablesReturnKeyAutomatically
          outline={Platform.OS === 'ios'}
          value={addrCity}
          tintColor={colors.primaryColor}
          onChangeText={cityName => this.setState({ addrCity: cityName })}
          returnKeyType="next"
        />
        <TextField
          label={translate('District')}
          autoCapitalize="none"
          enablesReturnKeyAutomatically
          outline={Platform.OS === 'ios'}
          value={addrDistrict}
          tintColor={colors.primaryColor}
          onChangeText={distr => this.setState({ addrDistrict: distr })}
          returnKeyType="next"
        />
        <TextField
          label={translate('Street')}
          autoCapitalize="none"
          enablesReturnKeyAutomatically
          outline={Platform.OS === 'ios'}
          value={addrName}
          tintColor={colors.primaryColor}
          onChangeText={name => this.setState({ addrName: name })}
          returnKeyType="next"
        />
        <TextField
          label={translate('Number')}
          autoCapitalize="none"
          enablesReturnKeyAutomatically
          outline={Platform.OS === 'ios'}
          value={addrNumber}
          tintColor={colors.primaryColor}
          onChangeText={name => this.setState({ addrNumber: name })}
          returnKeyType="next"
        />
        <TextField
          label={translate('Complement')}
          autoCapitalize="none"
          enablesReturnKeyAutomatically
          outline={Platform.OS === 'ios'}
          value={addrComplement}
          tintColor={colors.primaryColor}
          onChangeText={cmplnt => this.setState({ addrComplement: cmplnt })}
          returnKeyType="next"
        />
        <Button
          style={{ margin: 16 }}
          onPress={this.onSaveAddresAlt}
          title={`${translate('save')}`}
          disabled={[
            addrCEP,
            addrUF,
            addrCity,
            addrDistrict,
            addrName,
          ].includes('')}
        />
      </View>
    );
  }
}

AddrAlternativeForm.propTypes = {
    pkeyClient: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    onSaveReached: PropTypes.func.isRequired,
};

export default AddrAlternativeForm;
