import React, { Component } from 'react';
import { View } from 'react-native';

import Carousel from '../../../../components_base/src/components/Carousel';
import ProductEntity from '../../../../components_base/src/entities/Product';
import Product from '../../../../components_base/src/components/Product/ProductContainer';

import PropTypes from 'prop-types';

class ProductsCarousel extends Component {
    addQuantity = (product, quantity) => {
        const { addQuantity } = this.props;

        addQuantity(product, quantity);
    };

    productClick = (product) => {
        const { productClick } = this.props;
        productClick(product);
    };

    ItemSeparatorComponent = () => <View style={{ width: 1 }} />;

    renderItem = ({ item }) => {
        const stockExists = !!item.stock && item.stock > 0;

        if (stockExists || this.props.showUnavailableProducts) {
            return (
                <View>
                    <Product
                        type="grid"
                        productCurrent={this.props.itemUpdate}
                        product={new ProductEntity(item)}
                        addQuantity={this.addQuantity}
                        onPress={this.productClick}
                        showUnavailableProducts={this.props.showUnavailableProducts}
                        productType={this.props.productType}
                    />
                </View>
            );
        }
        return null;
    };

    render() {
        const { title, products, initialNumToRender, onEndReached } = this.props;

        return (
            <Carousel
                title={title}
                data={products}
                renderItem={this.renderItem}
                ItemSeparatorComponent={this.ItemSeparatorComponent}
                onEndReachedThreshold={0.1}
                onEndReached={onEndReached}
                initialNumToRender={initialNumToRender}
                {...this.props}
            />
        );
    }
}

ProductsCarousel.propTypes = {
    title: PropTypes.string.isRequired,
    showUnavailableProducts: PropTypes.bool.isRequired,
    onEndReachedThreshold: PropTypes.number.isRequired,
    initialNumToRender: PropTypes.number.isRequired,
    itemUpdate: PropTypes.object.isRequired,
    products: PropTypes.array.isRequired,
    addQuantity: PropTypes.func.isRequired,
    productClick: PropTypes.func.isRequired,
    onEndReached: PropTypes.func.isRequired,
    productType: PropTypes.string.isRequired,
};

export default ProductsCarousel;
