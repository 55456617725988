import React, { Component } from 'react';
import { Dimensions, Image, StyleSheet, Text, View } from 'react-native';
import Images from '@assets/Images';
import Touchable from 'react-native-platform-touchable';

class InAppNotification extends Component {
  render() {
    return (
      <Touchable
        onPress={() => {
          this.props.onClickNotification?.();
        }}>
        <View style={styles.card}>
          <View style={styles.view}>
            <Image style={styles.logo} source={Images.logoPoli} />

            <View style={{ flex: 1, paddingRight: 16 }}>
              <Text
                style={{
                  backgroundColor: 'transparent',
                  fontWeight: '900',
                  color: '#ffff',
                }}>
                {this.props.title || 'Notificação'}
              </Text>
              <Text
                style={{
                  backgroundColor: 'transparent',
                  color: '#ffff',
                  marginVertical: 3,
                }}
                numberOfLines={2}
                ellipsize={'end'}>
                {this.props.message}
              </Text>
            </View>
          </View>
          <View
            style={{
              margin: 5,
              marginTop: 30,
              width: 35,
              height: 4,
              borderRadius: 2,
              backgroundColor: 'rgba(255,255,255,0.2)',
            }}
          />
        </View>
      </Touchable>
    );
  }
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: '#2f2f2f',
    width: Dimensions.get('window').width,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    height: 50,
    width: 50,
    marginHorizontal: 10,
    borderRadius: 20,
    backgroundColor: '#fff',
  },
  view: {
    marginTop: 15,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default InAppNotification;
