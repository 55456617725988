import { Dimensions, PixelRatio, StyleSheet } from 'react-native';
import { color } from '~/common';
import { fonts } from '../../../src/utils/Fonts';

const { width: viewportWidth, height: viewportHeight } = Dimensions.get(
  'window',
);

const pixelRatioFontScale = PixelRatio.getFontScale();

const styles = StyleSheet.create({
  container: {
    height: 200,
  },
  appHighLight: {
    height: pixelRatioFontScale >= 1.2 ? 300 : 190,
    flexDirection: 'row',
    alignItems: 'center',
    width: viewportWidth > 600 ? 680 : viewportWidth,
    padding: 10,
    backgroundColor: '#FFFEE5',
    bottom: 10,
    borderBottomColor: '#E5DA00',
    borderBottomWidth: 1,
  },
  containerImg: {
    paddingHorizontal: 5,
    bottom: pixelRatioFontScale >= 1.2 ? 120 : 70,
  },
  imageHighLight: {
    bottom: 0,
    width: 25,
    height: 25,
    resizeMode: 'contain',
    tintColor: '#1A1A1A',
  },
  containerTitle: {
    bottom: pixelRatioFontScale >= 1.2 ? 110 : 70,
    alignItems: 'center',
  },
  containerSubtitle: {
    paddingTop: pixelRatioFontScale >= 1.2 ? 10 : 60,
    left: 55,
    right: 35,
    marginRight: 50,
    paddingRight: 30,
    bottom: pixelRatioFontScale >= 1.2 ? 220 : 210,
    paddingBottom: 10,
  },
  containerBtns: {
    flexDirection: 'row',
    bottom: pixelRatioFontScale >= 1.2 ? 220 : 200,
    paddingBottom: 10,
    justifyContent: 'flex-end',
    gap: 8,
  },
  containerBtnLeft: {
    paddingTop: 0,
    paddingRight: 8,
    paddingBottom: 1,
    paddingLeft: 8,
  },
  containerBtnRight: {
    paddingTop: 0,
    paddingRight: 8,
    paddingBottom: 1,
    paddingLeft: 8,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#1A1A1A',
    paddingLeft: 10,
    fontFamily: fonts.Quicksand,
  },
  subtitle: {
    fontSize: 14,
    color: '#666',
    bottom: 10,
    lineHeight: 16,
  },
  textClient: {
    bottom: 7,
    fontSize: 14,
    color: '#666',
    lineHeight: 16,
  },
  textClientValue: {
    fontSize: 14,
    color: '#000',
  },
  textTotal: {
    bottom: 8,
    fontSize: 14,
    color: '#000',
  },
  textButton: {
    color: color.primaryColor,
    fontSize: 16,
  },
});

export default styles;
