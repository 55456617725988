import { StyleSheet } from 'react-native';
import { color } from '~/common';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column-reverse',
    top: 40,
    bottom: 20,
    marginBottom: 20,
  },
  actionsContainer: {
    backgroundColor: color.neutral10,
    paddingHorizontal: 16,
    paddingTop: 8,
    paddingBottom: 12,
    gap: 24,
    borderTopRightRadius: 16,
    borderTopLeftRadius: 16,
    borderBottomWidth: 1,
    borderBottomColor: color.neutral200,
  },
  mainActions: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    bottom: 20,
  },
  mainActionsExpanded: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    top: 20,
  },
  actionItem: {
    gap: 4,
    marginHorizontal: 12,
  },
  actionIcon: {
    width: 24,
    height: 24,
    marginBottom: 4,
    tintColor: color.neutral600,
    alignSelf: 'center',
  },
  actionText: {
    color: color.neutral600,
    fontSize: 10,
    fontWeight: '600',
    lineHeight: 16,
  },
  actionItemExpanded: {
    flexDirection: 'row',
    paddingVertical: 12,
    paddingRight: 16,
    alignContent: 'center',
    alignItems: 'center',
  },
  actionIconExpanded: {
    width: 24,
    height: 24,
    tintColor: color.neutral600,
    marginRight: 8,
  },
  actionTextExpanded: {
    color: color.neutral600,
    fontSize: 10,
    fontWeight: '600',
    lineHeight: 16,
    letterSpacing: 0.2,
    alignSelf: 'center',
    marginLeft: 8,
    marginBottom: 4,
  },
  separator: {
    alignSelf: 'stretch',
    borderBottomWidth: 1,
    borderBottomColor: '#E6E6E6',
    paddingVertical: 8,
  },
});

export default styles;
