import { Dimensions, Platform } from 'react-native';

export const STATUSBAR_HEIGHT = Platform.OS === 'android' ? 24 : 20;

export const { height, width } = Dimensions.get('window');

export const NAVIGATION_BAR_HEIGHT = 54;

export const Size = {
  navBarHeight: Platform.OS === 'ios' ? 64 : 56,
  navBarIconSize: Platform.OS === 'ios' ? 20 : 24,
  navBarIconMargin: Platform.OS === 'ios' ? 10 : 8,
  navBarActionSize: Platform.OS === 'ios' ? 40 : 40,
  navBarActionMargin: Platform.OS === 'ios' ? 0 : 8,
};

const Measures = {};

export default Measures;
