import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import HTML from 'react-native-render-html';
import Spinner from 'react-native-loading-spinner-overlay';

import ProductList from '../../Product/ProductList';
import EmptyView from '../../../components/EmptyView';
import Search from '../../../components/Search';

import styles from './styles';
import Images from '../../../../assets/Images';
import TextUtils from '~/utils/TextUtils';
import translate, { calendarLanguage as language } from '../../../locales';
import { TabBar, TabView } from 'react-native-tab-view';
import { Colors } from '~/config/config';
import { FlashList } from '@shopify/flash-list';
import { color } from '~/common';

import PropTypes from 'prop-types';

const Styles = StyleSheet.create({
  flashlist: {
    backgroundColor: '#fff',
    padding: 0,
    minHeight: 20,
    flex: 1,
    marginTop: 4,
  },
  view: {
    flex: 1,
    backgroundColor: color.grayContainer,
  },
});

function ProductTabs({
  tabs,
  productsTabs,
  cart,
  config,
  configLib,
  showSearch,
  loading,
  onChangeTextSearch,
  itemUpdate,
  onPressObservations,
  onPressConditionsSalesChild,
  addItem,
  onProductClick,
  showProductAsHTML,
}) {
  const refs = [];

  const keyExtractor = (item, index) => `${item.index}-${index}`;
  const [actualIndex, setActualIndex] = useState(0);

  const renderItem = ({ item }) => {
    if (item.tipo === 'produto') {
      return (<ProductList
        ref={(ref) => { refs[TextUtils.slugfy(item.nome)] = ref; }}
        editable
        onPressObservations={onPressObservations}
        onPressConditionsSalesChild={onPressConditionsSalesChild}
        addItem={addItem}
        itemUpdate={itemUpdate}
        // showProductAsHTML={showProductAsHTML}
        item={item}
        cart={cart}
        config={config}
        configLib={configLib}
        itemClick={onProductClick}
      />);
    }

    return <HTML source={{ html: item.html }} />;
  };

  const RenderPropuctsTab = ({ route }) => {
    const { data, id } = route;

    return (
      <View key={id} style={Styles.view}>
        {data && data.length > 0 ? (
          <FlashList
            style={Styles.flashlist}
            data={data}
            keyExtractor={keyExtractor}
            removeClippedSubviews={false}
            refreshing={false}
            extraData={cart}
            renderItem={renderItem}
            estimatedItemSize={390}
          />
        ) : (
          <EmptyView icon={Images.iconBulletedList} />
        )}
      </View>
    );
  };

  const _renderTabBar = props => (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: Colors.stripGreen }}
      indicatorContainerStyle={{ backgroundColor: Colors.primaryColor }}
      inactiveColor={Colors.gray}
      activeColor="white"
    />
  );

  const isFirstRender = loading && !productsTabs?.length;

  return (
      <View style={styles.container}>
        {showSearch && <Search onChangeText={onChangeTextSearch} />}
        <Spinner
          visible={loading}
          overlayColor="rgba(0, 0, 0, 0.7)"
          textStyle={{ color: '#FFF' }}
        />
        {!isFirstRender &&
          (productsTabs && productsTabs.length > 0 ? (
            <TabView
              renderTabBar={_renderTabBar}
              navigationState={{
                index: actualIndex,
                routes: productsTabs.map((tab, index) => {
                  return {
                    ...tab,
                    key: index,
                  };
                }),
              }}
              onIndexChange={idx => setActualIndex(idx)}
              renderScene={({ route, jumpTo }) => {
                switch (route.key) {
                  case actualIndex:
                    return RenderPropuctsTab({ route, jumpTo });
                }
              }}
            />
          ) : (
            <EmptyView
              icon={Images.iconBulletedList}
              message={`${translate('noProducts')}...`}
            />
          ))}
      </View>
  );
}

ProductTabs.propTypes = {
    tabs: PropTypes.array.isRequired,
    productsTabs: PropTypes.array.isRequired,
    cart: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    configLib: PropTypes.object.isRequired,
    itemUpdate: PropTypes.object.isRequired,
    showSearch: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    showProductAsHTML: PropTypes.bool.isRequired,
    onChangeTextSearch: PropTypes.func.isRequired,
    onPressObservations: PropTypes.func.isRequired,
    onPressConditionsSalesChild: PropTypes.func.isRequired,
    addItem: PropTypes.func.isRequired,
    onProductClick: PropTypes.func.isRequired,
};

export default ProductTabs;
