import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Image, StyleSheet, TextInput, View } from 'react-native';
import Touchable from 'react-native-platform-touchable';
import Images from '../../assets/Images';
import { color } from '~/common';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import translate from '~/locales';
import ModalFilter from './FilterGroup/ModalFilter';

const styles = StyleSheet.create({
  containerSearch: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: color.grayMid,
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 4,
    flexShrink: 1,
    width: '100%',
  },
  iconLeft: {
    padding: 10,
    tintColor: color.grayMid,
    width: 10,
    height: 10,
  },
  iconFilter: {
    tintColor: color.primary600,
    width: 22,
    height: 24,
  },
  input: {
    paddingTop: 10,
    paddingRight: 15,
    paddingBottom: 10,
    paddingLeft: 0,
    backgroundColor: '#fff',
    color: '#000',
    marginLeft: 5,
    fontSize: 15,
    padding: 5,
    width: '100%',
    flexShrink: 1,
  },
  iconRigthPressable: {
    padding: 10,
  },
  iconRigth: {
    tintColor: color.grayMid,
    width: 20,
    height: 20,
  },
  btnFilter: {
    borderWidth: 1,
    padding: 12,
    borderRadius: 4,
    marginRight: 16,
    borderColor: color.primary600,
  },
  viewContainer: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    marginTop: 8,
    marginBottom: 8,
  },
});

function SearchBar({
  ref,
  onBlur,
  onFocus,
  placeholder,
  onSearch,
  onPressClear,
  showFilterBtn,
  orders,
  filters,
  onListFiltered,
  setFiltersValues,
  filtersValues,
  initialValue,
}) {
  const [value, setValue] = useState('');
  const [focusedInput, setFocusedInput] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const inputRef = useRef();

  const onChange = useCallback(
    function onChange(text) {
      setValue(text);
      onEndEditing?.(text);
    },
    [onEndEditing],
  );

  useEffect(
    () => {
      setValue(value => {
        if (value === '' && value !== initialValue) {
          return initialValue;
        }
        return value;
      });
    },
    [initialValue],
  );

  const onClear = useCallback(
    function onClear(text) {
      setValue('');
      onEndEditing('');
      inputRef?.current?.clear?.();
    },
    [onEndEditing],
  );

  const onFocusField = () => {
    setFocusedInput(true);
    onFocus?.();
  };

  const onBlurField = () => {
    setFocusedInput(false);
    onBlur?.();
  };

  const onEndEditingField = () => {
    inputRef?.current?.blur?.();
    setFocusedInput(false);
  };

  const onEndEditing = debounce(onSearch, 500);

  const onShowFilter = () => {
    setIsModalVisible(true);
  };

  return (
    <View style={styles.viewContainer}>
      {showFilterBtn &&
        !focusedInput && (
          <Touchable onPress={onShowFilter} style={styles.btnFilter}>
            <Image source={Images.iconFilter} style={styles.iconFilter} />
          </Touchable>
        )}
      <View
        style={[
          styles.containerSearch,
          focusedInput && { borderColor: color.primary600, borderWidth: 2 },
        ]}>
        <Image
          source={Images.iconSearch}
          style={[
            styles.iconLeft,
            focusedInput && { tintColor: color.primary600 },
          ]}
        />
        <TextInput
          selectTextOnFocus
          placeholder={placeholder}
          value={value}
          onEndEditing={onEndEditingField}
          returnKeyType="search"
          defaultValue=""
          onChangeText={onChange}
          ref={iRef => {
            inputRef.current = iRef;
            ref?.(iRef);
          }}
          blurOnSubmit
          style={styles.input}
          placeholderTextColor={color.grayMid}
          onFocus={onFocusField}
          onBlur={onBlurField}
        />
        {value?.length > 0 ? (
          <Touchable onPress={onClear} style={styles.iconRigthPressable}>
            <Image source={Images.iconClose} style={styles.iconRigth} />
          </Touchable>
        ) : null}
      </View>

      {isModalVisible && (
        <ModalFilter
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          list={orders}
          config={filters}
          onListFiltered={onListFiltered}
          saveValues={setFiltersValues}
          filtersValues={filtersValues}
        />
      )}
    </View>
  );
}

SearchBar.defaultProps = {
  showFilterBtn: true,
  placeholder: `${translate('search')}`,
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onPressClear: PropTypes.func,
  ref: PropTypes.func,
  showFilterBtn: PropTypes.bool,
  orders: PropTypes.array,
  filters: PropTypes.array,
  onListFiltered: PropTypes.func,
  setFiltersValues: PropTypes.func,
  filtersValues: PropTypes.object,
};

export default SearchBar;
